/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-children-prop */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { concatStrings } from 'utils';
import { FormButton, FormDatePicker, FormTypeAhead } from 'components/common/Form/Fields';
import { Text } from 'components/styleguide';
import { SecuredActionMenu } from 'components/common/Secured';
import { CUE_SHEET_SUBMISSION_STATUS, CUE_SHEET_SUBMISSION_STATUS_OPTIONS } from 'utils/constants';
import propEq from 'ramda/src/propEq';
import { getLocalDate } from 'utils/format';
import {
  getResponseRapidCueSubmissionCall,
  downloadResponseRapidCueSubmissionCall,
  downloadXMLRapidCueSubmissionCall,
} from 'redux-core/cueSheet/services';
import { downloadFromUrl } from 'redux-core/images/services';
import { requestWithError } from 'utils/request';

const downloadFile = async (requestCall, cueSheetSubmissionId) => {
  const { url, name } = await requestWithError(requestCall, {
    cueSheetSubmissionId,
  });
  await downloadFromUrl(url, name);
};

const parseRapidCueResponse = ({ warningsMessage, warnings, errorsMessage, errors }) => (
  <>
    {warnings && warnings.length > 0 && (
      <>
        <h3>{warningsMessage}</h3>
        <ul>
          {warnings.map((m) => (
            <li>
              &emsp;{m.source}: {m.description}
            </li>
          ))}
        </ul>
      </>
    )}
    {errors && errors.length > 0 && (
      <>
        <h3>{errorsMessage}</h3>
        <ul>
          {errors.map((m) => (
            <li>
              &emsp;{m.source}: {m.description}
            </li>
          ))}
        </ul>
      </>
    )}
  </>
);

const rapidCueSubmissionStatusColors = {
  [CUE_SHEET_SUBMISSION_STATUS.ACCEPTED]: { color: 'green', bold: true },
  [CUE_SHEET_SUBMISSION_STATUS.WARNING]: { color: 'yellow', bold: true },
  [CUE_SHEET_SUBMISSION_STATUS.REJECTED]: { color: 'red', bold: true },
};

const getOptions = ({ submission, setSelected, t, isRapidCueSubmission, hasRapidCueResponse, openPrompt }) => [
  {
    display: t('table.actions.updateSubmission'),
    action: () => setSelected(submission),
    disabled: isRapidCueSubmission,
  },
  {
    display: t('table.actions.viewResponse'),
    action: async () => {
      const { errors, warnings } = await requestWithError(getResponseRapidCueSubmissionCall, {
        cueSheetSubmissionId: submission.id,
      });

      const content = parseRapidCueResponse({
        warningsMessage: t('errorsAndWarnings.warnings'),
        warnings,
        errorsMessage: t('errorsAndWarnings.errors'),
        errors,
      });

      openPrompt({
        maxWidth: 'lg',
        content,
        okLabel: t('errorsAndWarnings.save'),
        cancelLabel: t('errorsAndWarnings.close'),
        onConfirm: () => downloadFile(downloadResponseRapidCueSubmissionCall, submission.id),
      });
    },
    disabled: !(isRapidCueSubmission && hasRapidCueResponse),
  },
  {
    display: t('table.actions.downloadResponse'),
    action: () => downloadFile(downloadResponseRapidCueSubmissionCall, submission.id),
    disabled: !(isRapidCueSubmission && hasRapidCueResponse),
  },
  {
    display: t('table.actions.downloadXML'),
    action: () => downloadFile(downloadXMLRapidCueSubmissionCall, submission.id),
    disabled: !isRapidCueSubmission,
  },
];

const RowActionsMenu = (props) => {
  const options = getOptions(props);
  return <SecuredActionMenu compact vertical objectId={props.objectId} options={options} />;
};

const RowActions = ({ hover, isEditing, setSelected, submission, selected, t, objectId, openPrompt }) => {
  const isRapidCueSubmission = submission?.pro?.toUpperCase() === 'RAPIDCUE';
  const hasRapidCueResponse = [
    CUE_SHEET_SUBMISSION_STATUS.ACCEPTED,
    CUE_SHEET_SUBMISSION_STATUS.REJECTED,
    CUE_SHEET_SUBMISSION_STATUS.WARNING,
  ].includes(submission.submissionStatus);

  if (selected) {
    return (
      <>
        <FormButton
          alwaysEnabled
          children="global.forms.cancel"
          onClick={() => setSelected(undefined)}
          size="xs"
          variant="destructive"
        />
        <FormButton children="global.forms.save" size="xs" type="submit" variant="primary" />
      </>
    );
  }
  if (hover && !isEditing) {
    return (
      <div style={{ float: 'right' }}>
        <RowActionsMenu
          submission={submission}
          setSelected={setSelected}
          t={t}
          isRapidCueSubmission={isRapidCueSubmission}
          hasRapidCueResponse={hasRapidCueResponse}
          objectId={objectId}
          openPrompt={openPrompt}
        />
      </div>
    );
  }
  // This is a simple placeholder for making the Table Row have a consistent height
  return <div style={{ height: 35 }} />;
};

const tStatus = ({ status, pro }) => {
  const submissionStatus = CUE_SHEET_SUBMISSION_STATUS_OPTIONS.find(propEq('id', status))?.name;

  if (pro?.toUpperCase() === 'RAPIDCUE') {
    return (
      <Text {...rapidCueSubmissionStatusColors[submissionStatus]}>
        {submissionStatus === 'Warning' ? 'Accepted' : submissionStatus}
      </Text>
    );
  }

  return submissionStatus;
};

export const columns = ({ setSelected, selectedSubmissionId, t, objectId, openPrompt }) => [
  {
    id: 'versionTitle',
    isSortable: true,
    label: t('table.version'),
    width: '10%',
  },
  {
    id: 'pro',
    isSortable: true,
    label: t('table.recipient'),
    width: '10%',
  },
  {
    dense: true,
    id: 'fileFormat',
    isSortable: true,
    label: t('table.fileType'),
    width: '12%',
  },
  {
    id: 'method',
    isSortable: true,
    label: t('table.method'),
    renderValue: ({ pro }) => (
      <Text>{pro?.toUpperCase() === 'RAPIDCUE' ? t('table.methodElectronically') : t('table.methodManually')}</Text>
    ),
    width: '12%',
  },
  {
    dense: true,
    id: 'submissionStatus',
    isSortable: true,
    label: t('table.submissionStatus'),
    renderValue: ({ id, submissionStatus, pro }) =>
      id !== selectedSubmissionId ? (
        tStatus({ status: submissionStatus, pro })
      ) : (
        <FormTypeAhead isClearable={false} name="submissionStatus" options={CUE_SHEET_SUBMISSION_STATUS_OPTIONS} />
      ),
    width: '17%',
  },
  {
    id: 'submissionUserFirstName',
    isSortable: true,
    label: t('table.user'),
    renderValue: ({ submissionUserFirstName, submissionUserLastName }) =>
      concatStrings(' ')(submissionUserFirstName, submissionUserLastName),
    width: '15%',
  },
  {
    align: 'right',
    id: 'submissionDate',
    isSortable: true,
    label: t('table.dateSubmitted'),
    noPadding: true,
    renderValue: ({ id, submissionDate }) =>
      id !== selectedSubmissionId ? getLocalDate(submissionDate, 'L LT') : <FormDatePicker name="submissionDate" />,
    width: '13%',
  },
  {
    id: 'empty',
    renderValue: (submission, { hover }) => (
      <RowActions
        hover={hover}
        submission={submission}
        setSelected={setSelected}
        selected={submission.id === selectedSubmissionId}
        isEditing={Boolean(selectedSubmissionId)}
        t={t}
        objectId={objectId}
        openPrompt={openPrompt}
      />
    ),
    width: '21%',
  },
];

RowActions.propTypes = {
  hover: PropTypes.bool,
  isEditing: PropTypes.bool,
  setSelected: PropTypes.func.isRequired,
  submission: PropTypes.object,
  selected: PropTypes.bool,
  t: PropTypes.object,
  openPrompt: PropTypes.bool,
};
