import React from 'react';
import Box from '@material-ui/core/Box';
import { Text } from 'components/styleguide';
import { useRootTranslation } from 'utils/hooks';

const rootT = 'drawers.productionSetup.tracks.hint';

function EmptyHint() {
  const t = useRootTranslation(rootT);
  return (
    <Box py={6.5} px={20} textAlign="center">
      <Text bold variant="pageTitle">
        {t('title')}
      </Text>
      <Text variant="pageTitle">{t('subtitle')}</Text>
      <Text variant="pageTitle">{t('legend')}</Text>
    </Box>
  );
}

export default EmptyHint;
