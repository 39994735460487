import { connect } from 'react-redux';
import { getLocationFiltersSelector, getLocationParametersSelector } from 'redux-core/router/selectors';
import { addParams } from 'redux-core/router/actions';

const mapDispatchToProps = {
  addParams,
};

const mapStateToProps = (state) => ({
  params: getLocationParametersSelector(state),
  filters: getLocationFiltersSelector(state),
});

export default connect(mapStateToProps, mapDispatchToProps);
