/* eslint-disable react/destructuring-assignment */
import { Box, Grid } from '@material-ui/core';
import ArchiveIcon from '@material-ui/icons/ArchiveOutlined';

import { TypeIcon } from 'components/styleguide';
import { NORMALIZED_PRODUCTION_TYPES } from 'utils/constants';
import { useRootTranslation } from 'utils/hooks';
import { getNormalizedProductionType } from 'utils/productions';

export const ProductionItem = (props) => {
  const t = useRootTranslation('globalSearch.results');

  const normalizedProdType = getNormalizedProductionType(props.productionType);
  const projectName = props.prodArray[0].name;
  const subprojectActive = props.prodArray[1].active;

  let productionTitle = props.prodArray[1].name;
  if (normalizedProdType === NORMALIZED_PRODUCTION_TYPES.TV) {
    const seasonDesc = t('season', { number: props.prodArray[1].name });
    const releaseDesc = props.prodArray[2].name;
    productionTitle = `${seasonDesc} - ${releaseDesc}`;
  }

  return (
    <Box my={1}>
      <Grid container data-testid="production-search-result">
        <Grid container item xs={5} data-testid="search-result-project-name">
          <Box mr={2}>
            <TypeIcon size={20} type={normalizedProdType} />
          </Box>
          {projectName}
        </Grid>
        <Grid container item xs={5}>
          {productionTitle}
        </Grid>
        <Grid container item xs={2}>
          {!subprojectActive && (
            <Box sx={{ mx: 1, width: '100%' }} display="flex" justifyContent="center" alignItems="center">
              <ArchiveIcon /> {t('inactive')}
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};
