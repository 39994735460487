/* eslint-disable no-unused-expressions */
/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import { ToggleButtonGroup, ToggleButton } from 'components/styleguide';
import { useLocation } from 'react-router';
import enhancer from './enhancer';

const TabsLayoutSwitch = ({ goToLink, routes, onChange, loading }) => {
  const location = useLocation();

  const handleRouteChange = (event, route) => {
    onChange && onChange(event);
    goToLink(route, undefined, { mergeParams: true });
  };

  return (
    <ToggleButtonGroup onChange={handleRouteChange} size="sm" value={location.pathname} disabled={loading}>
      {routes.map(({ route, title }) => (
        <ToggleButton key={route} value={route}>
          {title}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};

TabsLayoutSwitch.defaultProps = {
  routes: [],
};

TabsLayoutSwitch.propTypes = {
  routes: PropTypes.arrayOf(
    PropTypes.shape({
      route: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    }),
  ),
  loading: PropTypes.bool,
  onChange: PropTypes.func,
  goToLink: PropTypes.func.isRequired,
};

export default enhancer(TabsLayoutSwitch);
