/* eslint-disable camelcase */
import { useEffect, useState, useMemo } from 'react';
import * as Yup from 'yup';
import { prop, propEq, find, compose } from 'ramda';
import { yupRightPresetTerritories, yupTimeHHMMSS_Max24H } from 'utils/forms';
import { NORMALIZED_PRODUCTION_TYPES } from 'utils/constants';
import { useFetch } from 'utils/hooks';
import { getCurrenciesCall } from 'redux-core/productions/services';

const televisionValidationSchema = Yup.object().shape({
  divisionId: Yup.number().required(),
  tenantId: Yup.number().required(),
  project: Yup.object()
    .shape({
      project: Yup.string().required(),
      isNew: Yup.boolean().nullable(),
    })
    .required(),
  type: Yup.string().required(),
  synopsis: Yup.string().nullable(),
  categoryId: Yup.number().nullable(),
  duration: yupTimeHHMMSS_Max24H.nullable(),
  season: Yup.string().required('global.forms.validations.required'),
  release: Yup.string().required('global.forms.validations.required'),
  requiredRights: Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string(),
        type: Yup.string(),
        rights: yupRightPresetTerritories(),
      }),
    )
    .min(1)
    .required('global.forms.validations.required'),
  episodes: Yup.array()
    .of(
      Yup.object().shape({
        number: Yup.string().required('global.forms.validations.required'),
        name: Yup.string().required('global.forms.validations.required'),
      }),
    )
    .min(1)
    .required('global.forms.validations.required'),
  masterCurrencyId: Yup.number().required(),
});

const featuresValidationSchema = Yup.object().shape({
  divisionId: Yup.number().required(),
  tenantId: Yup.number().required(),
  project: Yup.object()
    .shape({
      project: Yup.string().required(),
      isNew: Yup.boolean().nullable(),
    })
    .required(),
  type: Yup.string().required(),
  synopsis: Yup.string().nullable(),
  categoryId: Yup.number().nullable(),
  duration: yupTimeHHMMSS_Max24H.nullable(),
  release: Yup.string().required('global.forms.validations.required'),
  requiredRights: Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string(),
        type: Yup.string(),
        rights: yupRightPresetTerritories(),
      }),
    )
    .min(1)
    .required('global.forms.validations.required'),
  masterCurrencyId: Yup.number().required(),
});

const marketingValidationSchema = Yup.object().shape({
  divisionId: Yup.number().required(),
  tenantId: Yup.number().required(),
  project: Yup.object()
    .shape({
      project: Yup.string().required(),
      isNew: Yup.boolean().nullable(),
    })
    .required(),
  type: Yup.string().required(),
  synopsis: Yup.string().nullable(),
  campaign: Yup.string().required('global.forms.validations.required'),
  masterCurrencyId: Yup.number().required(),
});

/* Currencies hook */
const useCurrency = () => {
  const [currencies] = useFetch(getCurrenciesCall);
  const getDefaultValue = compose(prop('id'), find(propEq('code', 'USD')));
  const masterCurrencyId = useMemo(() => getDefaultValue(currencies), [getDefaultValue, currencies]);

  return [currencies, masterCurrencyId];
};

const schemaMapping = {
  [NORMALIZED_PRODUCTION_TYPES.TV]: televisionValidationSchema,
  [NORMALIZED_PRODUCTION_TYPES.FEATURES]: featuresValidationSchema,
  [NORMALIZED_PRODUCTION_TYPES.MKT]: marketingValidationSchema,
};

/* Schema hook */
const useValidationSchema = (type) => {
  const [schema, setSchema] = useState();
  useEffect(() => {
    setSchema(schemaMapping[type]);
  }, [type]);
  return schema;
};

export { useValidationSchema, useCurrency };
