import { createSelector } from 'reselect';
import path from 'ramda/src/path';
import pathOr from 'ramda/src/pathOr';
import propOr from 'ramda/src/propOr';

const getRouterRoot = (state) => state.router;
const getParametersRoot = path(['router']);

export const getLocationSelector = createSelector(getRouterRoot, path(['location', 'pathname']));

export const getLocationKeySelector = createSelector(getRouterRoot, path(['location', 'key']));

export const getLocationParametersSelector = createSelector(getParametersRoot, pathOr({}, ['location', 'state']));

export const getLocationFiltersSelector = createSelector(getLocationParametersSelector, propOr({}, 'filters'));

export const getLocationParametersDivisionId = createSelector(getLocationParametersSelector, propOr('', 'divisionId'));

export const getLocationParametersObjectId = createSelector(getLocationParametersSelector, propOr('', 'objectId'));

export const getLocationParametersId = createSelector(getLocationParametersSelector, propOr('', 'id'));
