import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import withStyles from './styles';

const Division = (props) => {
  const { classes, division } = props;
  return <Avatar className={classes.division}>{division}</Avatar>;
};

Division.propTypes = {
  classes: PropTypes.object.isRequired,
  division: PropTypes.string.isRequired,
};

export default withStyles(Division);
