import { LoadingButton } from '@mui/lab';
import { Button, Grid } from '@mui/material';
import { useAtomValue } from 'jotai';
import { rootTranslation } from 'utils/format';
import { composeLetterAtom } from './composeLetterStore';

const t = rootTranslation('composeLetters.stepper');

export type TComposeLetterStepActionsProps = {
  isSubmitting: boolean;
  goBack: () => Promise<void>;
  skip?: () => Promise<void>;
};

export function ComposeLetterStepActions({
  isSubmitting,
  goBack,
  skip,
}: TComposeLetterStepActionsProps) {
  const composeLetter = useAtomValue(composeLetterAtom);

  return (
    <>
      {
        skip &&
        <Grid display="flex" justifyContent="end">
          <LoadingButton
            type="submit"
            loading={isSubmitting}
            onClick={skip}
          >
            {t('skipToEmail')}
          </LoadingButton>
        </Grid>
      }
      <Grid container sx={{ backgroundColor: '#f3eafb' }} p={2} justifyContent="space-between">
        <Grid>
          <Button type="button" onClick={goBack}>
            {t('back')}
          </Button>
        </Grid>
        <Grid>
          <LoadingButton
            color="primary"
            variant="contained"
            type="submit"
            loading={isSubmitting}
            data-testid="compose-letter-next-step-button"
          >
            {composeLetter.currentStep.stepNumber === 3 ? t('send') : t('next')}
          </LoadingButton>
        </Grid>
      </Grid>
    </>
  );
}
