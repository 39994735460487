/* eslint-disable react/no-children-prop */
/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { Text } from 'components/styleguide';

const TabsLayoutHeader = ({ pageTitle, children }) => (
  <Box width={1} pr={3} pb={2}>
    <Grid container direction="column">
      <Grid item xs={12}>
        <Text children={pageTitle} color="textPrimary" semibold variant="h3" />
      </Grid>
      {children && (
        <Grid item xs={12}>
          <Box width={1} mt={2.5}>
            {children}
          </Box>
        </Grid>
      )}
    </Grid>
  </Box>
);

TabsLayoutHeader.propTypes = {
  pageTitle: PropTypes.string.isRequired,
  children: PropTypes.any,
};

export default TabsLayoutHeader;
