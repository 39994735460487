import { handleActions } from 'redux-actions';
import { append, equals, reject } from 'ramda';
import * as actions from './actions';

const defaultState = [];

const reducer = {
    [actions.resetSmartFlagsFilter]: () => defaultState,
    [actions.addSmartFlagsFilter]: (state, { payload }) => append(payload, state),
    [actions.removeSmartFlagsFilter]: (state, { payload }) => reject(equals(payload), state),
};

export default handleActions(reducer, defaultState);
