/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const PresetStamp = (props) => (
  <SvgIcon {...props} viewBox="0 0 30 30">
    <g clipPath="url(#prefix__clip0)">
      <path
        fill="#009DFB"
        d="M7.263 20.959h14.473v2.04H7.263v-2.04zM22.85 19.846H6.15v-2.413h16.7v2.413zM17.84 16.32h-6.68c.937 0 1.67-.754 1.67-1.717v-.3c0-1.381-.502-2.774-1.492-4.14-.48-.663-.734-1.447-.734-2.267 0-2.421 2.186-4.274 4.624-3.83 1.555.283 2.798 1.535 3.093 3.056.229 1.178-.077 2.37-.838 3.27-.846 1.001-1.313 2.434-1.313 4.032v.226c0 .92.75 1.67 1.67 1.67z"
      />
    </g>
  </SvgIcon>
);

export default PresetStamp;
