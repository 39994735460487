/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable max-len */
import React from 'react';
import { Grid, Chip, Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import { Text, Checkbox, TrackType as TrackTypeIcon } from 'components/styleguide';
import { concatStrings } from 'utils';
import is from 'ramda/src/is';
import { HHMMSStoMMSS } from 'utils/date';
import { useRootTranslation } from 'utils/hooks';
// eslint-disable-next-line import/no-cycle
import { TRACK_OPTIONS } from '../..';
import useStyles from './styles';

const WritersAndPublishers = ({ rightsOwners, label }) => (
  <Text variant="caption">
    {label}:{' '}
    {rightsOwners.map(({ name, pro, share }, index) => (
      <React.Fragment key={name}>
        <Text variant="caption" component="span">
          <b>{name}</b>
          {pro && ` (${pro})`}
          {is(Number, share) && ` ${share}%`}
        </Text>
        {rightsOwners.length !== index + 1 && ', '}
      </React.Fragment>
    ))}
  </Text>
);

WritersAndPublishers.propTypes = {
  rightsOwners: PropTypes.array,
  label: PropTypes.string,
};

const toggleType = (type) => (type === TRACK_OPTIONS.CLEAR ? TRACK_OPTIONS.CUE : TRACK_OPTIONS.CLEAR);

const RequestedUsageGridItem = (props: RequestedUsageGridItemProps) => {
  const {
    dataTestId,
    showRequestedActualLabel,
    requestedActualLabel,
    usageMatch,
    durationMatch,
    usageDuration,
    outlined,
    outOfSyncTooltipText,
    isActual,
  } = props;
  const classes = useStyles();

  return (
    <Grid item sx={{ alignSelf: 'center', textAlign: 'center' }}>
      <Tooltip
        data-testid={`${dataTestId}-tooltip`}
        title={outOfSyncTooltipText}
        placement="right"
      >
        <Chip
          data-testid={`${dataTestId}-chip`}
          size="small"
          variant={outlined ? 'outlined' : 'filled'}
          style={{ backgroundColor: '#EBEBEB' }}
          label={
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div>
                {showRequestedActualLabel && (
                  <div
                    className={classes.usageDurationChipLabel}
                    style={{ backgroundColor: (usageMatch && durationMatch) || isActual ? '#616161' : '#8C292F' }}
                  >
                    {requestedActualLabel}
                  </div>
                )}
              </div>
              <div>{usageDuration}</div>
            </div>
          }
        />
      </Tooltip>
    </Grid>
  );
};
const TrackItem = (props: TrackItemProps) => {
  const { track, index, setCueSelections, type, hideCheckbox, selectedInRow, usageMatch, durationMatch } = props;
  const t = useRootTranslation('drawers.cueSheetSync.trackItem');
  const classes = useStyles();
  if (!track) {
    return (
      <div className={classes.fallbackWrapper}>
        <Text variant="h4" color="grey">
          {t('missingCue')}
        </Text>
      </div>
    );
  }

  const { title, writers = [], publishers = [], artists, cues = [], type: trackType } = track;

  return (
    <div className={classes.globalContainer}>
      <div className={classes.checkboxContainer}>
        {!hideCheckbox && (
          <Checkbox
            value={selectedInRow === type}
            onClick={() =>
              setCueSelections((cueSelections) =>
                // eslint-disable-next-line no-nested-ternary
                cueSelections.map((x, i) => (i === index ? (type === x ? toggleType(x) : type) : x)),
              )
            }
          />
        )}
      </div>
      <div className={classes.contentWrapper}>
        <div className={classes.titleWrapper}>
          <div className={classes.trackTypeIconWrapper}>
            <TrackTypeIcon type={trackType} />
          </div>
          <Text bold>{title}</Text>
        </div>
        {cues.map(
          ({
            actualUseDuration,
            actualUseType,
            requestedUseDuration,
            requestedUseDurationFull,
            requestedUseType,
            usage,
            duration,
          }) => {
            const separator = ' / ';
            /** If the cue is from a Clear Cue, we show requested and actual use. If it comes
             * from a Cue, it only has usage and duration and it should be displayed in the
             * "actual" side. On cue cues, requestedUseDuration and requestedUseType
             * will be undefined
             */
            const actualType = actualUseType || usage;
            const actualDuration = actualUseDuration || duration;
            const reqDuration = requestedUseDurationFull ? 'Full' : HHMMSStoMMSS(requestedUseDuration);

            return (
              <div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  {(reqDuration || requestedUseType) && (
                    <RequestedUsageGridItem
                      dataTestId="requested-usage-duration"
                      showRequestedActualLabel
                      outOfSyncTooltipText={usageMatch && durationMatch ? '' : t('outOfSyncWithCueSheet')}
                      requestedActualLabel={t('requested')}
                      usageMatch={usageMatch}
                      durationMatch={durationMatch}
                      usageDuration={concatStrings(separator)([reqDuration, requestedUseType])}
                    />
                  )}
                  {(actualDuration || duration || actualUseType || usage) && (
                    <RequestedUsageGridItem
                      dataTestId="actual-usage-duration"
                      isActual
                      showRequestedActualLabel={actualUseDuration !== undefined}
                      outOfSyncTooltipText={duration && (!usageMatch || !durationMatch) ? t('outOfSyncWithClearance') : ''}
                      requestedActualLabel={t('actual')}
                      usageMatch={usageMatch}
                      outlined={duration && (!usageMatch || !durationMatch)}
                      durationMatch={durationMatch}
                      usageDuration={concatStrings(separator)([HHMMSStoMMSS(actualDuration), actualType])}
                    />
                  )}
                </div>
              </div>
            );
          },
        )}
        <div className={classes.contentWrapper}>
          <Text variant="caption">
            {t('artists')} {concatStrings(', ')(artists) || '--'}
          </Text>
          <WritersAndPublishers rightsOwners={writers} label={t('writers')} />
          <WritersAndPublishers rightsOwners={publishers} label={t('publishers')} />
        </div>
      </div>
    </div>
  );
};

type TrackItemProps = {
  track?: any,
  index?: number,
  setCueSelections?: (x: any) => void,
  type: string,
  hideCheckbox?: boolean,
  selectedInRow: string,
  usageMatch?: boolean,
  durationMatch?: boolean,
};

type RequestedUsageGridItemProps = {
  dataTestId: string,
  showRequestedActualLabel: boolean,
  requestedActualLabel: string,
  usageMatch: boolean,
  durationMatch: boolean,
  usageDuration: string,
  outlined?: boolean,
  outOfSyncTooltipText?: string,
  isActual?: boolean,
};

export default TrackItem;
