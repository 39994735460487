/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable max-len */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const ScoreIcon = (props) => (
  <SvgIcon {...props} viewBox="0 0 27 30">
    <path stroke="#AAABB1" strokeWidth=".5" d="M0 8.749h27M0 4.248h27M0 13.249h27M0 17.749h27M0 22.249h27" />
    <g clipPath="url(#a)">
      <path
        fillRule="evenodd"
        strokeMiterlimit="10"
        strokeWidth=".25"
        d="M4.275 26.944c0 .934 1.058 1.991 1.879 2.149 0 0 .877.213 1.597.045.135.033 2.318-.372 2.363-3.308l-.461-3.577c.303-.057 1.316-.54 1.62-.72.855-.507 1.226-1.238 1.552-1.778.372-.81.597-1.361.597-2.25a4.043 4.043 0 0 0-4.04-4.039c-.326 0-.63.034-.933.113l-.394-2.869c1.508-1.676 3.038-3.746 3.555-5.636.507-2.486.27-4.174-1.069-4.905-1.226-.281-2.71 1.395-3.588 3.814-.574 1.293.146 3.768.472 4.78-2.396 2.307-4.792 3.95-5.04 7.943a5.656 5.656 0 0 0 5.659 5.66c.304 0 .787.01 1.35-.068l.259 3.566s.101 2.61-1.89 2.959c-.788.135-1.553.303-1.328-.214.619-.315 1.328-.833 1.328-1.665 0-1.046-.788-1.89-1.755-1.89-.945 0-1.733.844-1.733 1.89zM7.83 10.969l.371 2.677a4.034 4.034 0 0 0-2.846 3.86c0 2.058 2.453 3.014 2.385 2.53-.956-.416-1.316-.877-1.316-1.99 0-1.25.855-2.307 2.014-2.61l.9 6.39a5.354 5.354 0 0 1-.642.134c-3.363-.067-4.95-1.856-4.95-4.984-.01-1.755 2.048-3.768 4.084-6.007zm1.766 10.777L8.72 15.39a2.689 2.689 0 0 1 3.105 2.655c-.034 1.204-.09 2.993-2.228 3.701zM7.718 8.37C7.11 6.323 7.66 5.3 8.01 4.511c.844-1.845 1.586-2.52 2.34-3.127.979.382.54 3.127.394 3.127-.461 1.62-2.126 2.903-3.026 3.86z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h11.255v29.159H0z" transform="translate(2.25) scale(1.00658)" />
      </clipPath>
    </defs>
  </SvgIcon>
);

export default ScoreIcon;
