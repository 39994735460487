import { baseApi, TPagination } from 'services';

export type TSearchProductionsFilter = {
  filterBy?: string;
  searchBy?: string;
  types?: Array<string>;
  rightsOwnerTypes?: Array<string>;
  rightsOwnerContact?: string;
  rightsOwnerSubLabel?: string;
  rightsOwnerName?: string;
  dateType?: string;
  dateRange?: string;
  dateStart?: string;
  dateEnd?: string;
  asignee?: number;
  clearanceStatusId?: number;
  idNumber?: string;
  idType?: string;
  isArchived?: boolean;
  clearanceRepUserId?: number;
  tracksIds?: Array<string>;
  trackArtist?: string;
  trackTitle?: string;
  trackLibrary?: string;
  trackWriter?: string;
  trackPublisher?: string;
  episodeName?: string;
  projectName?: string;
  productionNumber?: string;
  divisionId?: number;
  cueSheetSegmentName?: string;
  showInactive?: boolean;
};

export type TSearchProductionsRequest = {
  sortByDirection?: string;
  page?: number;
  limit?: number;
  term?: string;
  filters?: TSearchProductionsFilter;
  divisionId?: number;
};

export type TSearchProductionsResponse = {
  data: Array<{
    rawName: string;
    name: string;
    title: string;
    productionId: number;
    productionObjectId: number;
    prodArray: Array<{
      id: number;
      divisionId: number;
      name: string;
      objectId: number;
      posterId?: number;
      tenandId: number;
      type: string;
    }>;
  }>;
};

export type TGetAllProductionsResponse = {
  data: Array<{ productionId: number; title: string }>;
  pagination: TPagination;
};

type TGetAllProductionsRequest = {
  filters: {
    filterBy: string;
    searchBy: string;
    types: Array<string>;
    showInactive: boolean;
  };
  term: string;
};

export const searchApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    searchProductions: builder.query<TSearchProductionsResponse, TSearchProductionsRequest>({
      query: (body) => ({
        url: 'common/search/get-productions',
        method: 'POST',
        body,
      }),
    }),
    getAllProductions: builder.query<TGetAllProductionsResponse, TGetAllProductionsRequest>({
      query: (body) => ({
        url: 'common/search/get-all',
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const { useSearchProductionsQuery, useLazySearchProductionsQuery, useGetAllProductionsQuery } = searchApi;
