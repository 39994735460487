/* eslint-disable import/no-cycle */
import React from 'react';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import { Text } from 'components/styleguide';
import Image from '../files/Image';
import useStyles from './styles';

const UserData = ({ displayName, profileImageUrl, isQwireEmployee = false }) => {
  const classes = useStyles();

  return (
    <Box display="flex" width={1} justifyContent="flex-end" alignItems="center" boxSizing="border-box" pr={0.5}>
      <Text variant="caption" bold align="right">
        {displayName}
      </Text>
      <Box pl={0.25}>
        <Image
          avatar
          style={isQwireEmployee ? { border: '2px solid red' } : {}}
          className={classes.avatar}
          url={profileImageUrl}
          alt="Profile Image"
        />
      </Box>
    </Box>
  );
};

UserData.propTypes = {
  displayName: PropTypes.string,
  profileImageUrl: PropTypes.string,
  isQwireEmployee: PropTypes.bool,
};

export default UserData;
