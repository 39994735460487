/* eslint-disable import/no-cycle */
import { makeStyles } from '@material-ui/core';
import { appColors } from 'components/styleguide';

const useStyles = makeStyles((theme) => ({
  actions: {
    zIndex: 100,
    height: '21px',
    width: '21px',
    borderRadius: '50%',
    marginLeft: '30px',
    marginRight: '35px',
    border: `1px solid ${theme.palette.primary.main}`,
    backgroundColor: appColors.white,
    '& svg': {
      width: '12px',
      fill: '#666666',
      marginTop: '-2px',
    },
    '&.disabled': {
      borderColor: '#b7b7b7',
    },
    '&.big': {
      height: '32px',
      width: '32px',
      backgroundColor: theme.palette.primary.main,
      boxSizing: 'border-box',
      '& svg': {
        fill: 'white !important',
        width: '22px',
      },
    },
    '&.compact': {
      margin: 0,
    },
  },
  popoverPaper: {
    padding: theme.spacing(2),
    borderRadius: 20,
    boxShadow: '0px 2px 7px 0px rgba(0,0,0,0.3)',
    border: `1px solid ${theme.palette.grey[300]}`,
  },
}));

export default useStyles;
