import * as Yup from 'yup';
import { getContactByIdCall } from 'redux-core/contacts/services';
import { ContactSearch } from 'components/common/ContactSearch/ContactSearch';
import { useEffect, useState } from 'react';

const isValidEmail = (value: string) => Yup.string().email().required().isValidSync(value);

const getContactNameFromValue = (value: string) => {
  const name = value.split(' ');
  const firstName = name[0];
  const lastName = name.slice(1).join(' ');
  return {
    firstName,
    lastName,
  };
};

export function ComposeLetterExistingContactSelect({ divisionId, formBag, setIsExistingContactSelected }) {
  const [searchValue, setSearchValue] = useState(null);
  const formId = formBag.watch('id');

  useEffect(() => {
    if (formBag.getValues('id')) {
     setSearchValue({
       id: formBag.getValues('id'),
       name: `${formBag.getValues('firstName')} ${formBag.getValues('lastName')}`,
       email: formBag.getValues('email'),
       companyName: '',
     });
    } else {
      setSearchValue(null);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formId]);

  const handleContactChange = async (contact) => {
    setSearchValue(contact);
    if (contact === null) {
      return;
    }
    setIsExistingContactSelected(true);

    const retrievedContact = await getContactByIdCall({
      divisionId,
      id: contact.id,
    });

    formBag.reset({ ...contact, ...retrievedContact });
  };

  const handleContactCreate = (contact: string) => {
    setIsExistingContactSelected(true);

    if (isValidEmail(contact)) {
      formBag.reset({
        id: undefined,
        countryName: 'United States',
        email: contact,
        firstName: '',
        lastName: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        postalCode: '',
      });
    } else {
      const { firstName, lastName } = getContactNameFromValue(contact);
      formBag.reset({
        id: undefined,
        countryName: 'United States',
        firstName,
        lastName,
        email: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        postalCode: '',
      });
    }
  };

  return (
    <ContactSearch
      divisionId={divisionId}
      onChange={handleContactChange}
      onCreate={handleContactCreate}
      value={searchValue}
    />
  );
}
