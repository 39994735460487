/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable max-len */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const Verified = (props) => (
  <SvgIcon {...props} viewBox="0 0 23 23">
    <mask id="mask0" maskType="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="23" height="23">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23 0H0V23H23V0ZM10.3203 14.0354L17.215 7.07495L18.2 7.99425L10.3203 15.874L6.64307 12.1968L7.56237 11.2775L10.3203 14.0354ZM13.5382 7.07495L14.4575 7.99425L10.3207 12.1311L9.40137 11.2118L13.5382 7.07495ZM2.8999 12.1969L6.5771 15.8741L7.4964 14.9548L3.8192 11.2776L2.8999 12.1969Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.3 8V3.3H14.6L11.3 0L8 3.3H3.3V8L0 11.3L3.3 14.6V19.3H8L11.3 22.6L14.6 19.3H19.3V14.6L22.6 11.3L19.3 8Z"
        fill="#628C61"
      />
    </g>
  </SvgIcon>
);

export default Verified;
