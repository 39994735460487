import compose from 'ramda/src/compose';
import { connect } from 'react-redux';
import { createClearanceOption, updateClearanceOption } from 'redux-core/clearances/actions';
import { withFormConnected } from 'components/styleguide/HOC';
import { getProductionCurrencySelector } from 'redux-core/productions/selectors';
import { getDialogRightsByNameSelector } from 'redux-core/dialogs/selectors';
// eslint-disable-next-line import/no-named-as-default
import DRAWERS from '../constants';

const mapStateToProps = (state, ownProps) => ({
  currencyInfo: getProductionCurrencySelector(state),
  rights: getDialogRightsByNameSelector(state, ownProps.name),
});

const mapDispatchToProps = {
  createClearanceOption,
  updateClearanceOption,
};

export default compose(connect(mapStateToProps, mapDispatchToProps), (c) =>
  withFormConnected(c, { formId: DRAWERS.CLEARANCE_OPTION }),
);
