/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import { FormTypeAhead } from 'components/common/Form/Fields';
import { getIn, useFormikContext } from 'formik';
import c from 'ramda/src/compose';
import find from 'ramda/src/find';
import prop from 'ramda/src/prop';
import propEq from 'ramda/src/propEq';

const getDefaultCountry = c(prop('name'), find(propEq('code', 'US')));

const TrackCountryDropdown = ({ options, name, ...props }) => {
  const { setFieldValue, values } = useFormikContext();
  const country = getIn(values, name);
  useEffect(() => {
    if (country) return;
    const unitedStates = getDefaultCountry(options);
    setFieldValue(name, unitedStates);
  }, [country, name, options, setFieldValue]);
  return <FormTypeAhead isClearable={false} name={name} options={options} {...props} />;
};

export default TrackCountryDropdown;
