/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-expressions */
import React from 'react';
import PropTypes from 'prop-types';
import { createFileAttachmentCall, updateFileAttachmentCall } from 'redux-core/overview/services';
import { ProductionPropTypes } from 'utils/types';
import { useSnackbar, useRootTranslation } from 'utils/hooks';
import { PRODUCTION_FILE_TYPES_OPTIONS } from 'utils/constants';
import enhancer from './enhancer';
import BaseFilesDrawer from '../BaseFilesDrawer/index';

const OverviewFilesDrawer = ({ name, onClose, payload: { file, onSubmit }, selected, ...props }) => {
  const showSnackbar = useSnackbar();
  const t = useRootTranslation('files.notification');

  const handleOnSubmit = async (values) => {
    const payload = { ...values, productionId: selected.id };
    if (payload.id) {
      await updateFileAttachmentCall(payload);
      showSnackbar({ message: t('update') }, { name: values.fileName });
    } else {
      await createFileAttachmentCall(payload);
      showSnackbar({ message: t('create') });
    }

    onSubmit && (await onSubmit(payload));
    onClose();
  };

  return (
    <BaseFilesDrawer
      {...props}
      name={name}
      title={selected.name}
      initialValues={file}
      onSubmit={handleOnSubmit}
      onClose={onClose}
      fileTypes={PRODUCTION_FILE_TYPES_OPTIONS}
    />
  );
};

OverviewFilesDrawer.propTypes = {
  name: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  selected: ProductionPropTypes,
  payload: PropTypes.shape({
    file: PropTypes.object,
    onSubmit: PropTypes.func,
  }).isRequired,
};

export default enhancer(OverviewFilesDrawer);
