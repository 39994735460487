import omit from 'ramda/src/omit';
import Box from '@material-ui/core/Box';
import { useRootTranslation } from 'utils/useRootTranslation';
import { upsertRights } from 'redux-core/rights/services';
import { updateClearanceCall } from 'redux-core/clearances/services';
import CueRows from './CueRows';
import { getCueRows, getCueContainersToRefetch } from './CueRows/utils';
import { ClearanceBaseForm } from './ClearanceBaseForm';
import { clearanceBaseSchema } from './ClearanceFormAdd';

export function ClearanceFormEdit({
  children,
  productionType,
  initialValues,
  divisionId,
  projectId,
  track,
  objectId,
  onClose,
  clearance,
  cueContainer,
  refetchCueContainersById,
  clearancesFetch,
  showGenericError,
  name,
}) {
  const t = useRootTranslation('drawers.clearance.addEdit');
  const title = t('drawerEditTitle');

  const handleSubmit = async ({ requiredRights, ...values }) => {
    const body = {
      ...omit(['rights', 'territories'], values),
      divisionId,
      projectId,
      trackId: track.id,
    };
    try {
      const cues = getCueRows(values);
      await upsertRights({
        divisionId,
        clearanceId: clearance.id,
        rights: requiredRights,
      });

      await updateClearanceCall(
        {
          ...omit(['objectId'])(body),
          allocations: cues.map(({ id, allocation }) => ({
            qclearCueId: id,
            allocation,
          })),
        },
        initialValues,
      );

      const cueContainerIds = getCueContainersToRefetch(initialValues, values);
      await refetchCueContainersById(cueContainerIds);
      await clearancesFetch();
      await onClose();
    } catch (e) {
      showGenericError(e);
    }
  };

  return (
    <ClearanceBaseForm
      handleSubmit={handleSubmit}
      initialValues={initialValues}
      objectId={objectId}
      onClose={onClose}
      submitButtonTag="global.forms.save"
      title={title}
      name={name}
      validationSchema={clearanceBaseSchema}
    >
      {children}
      <Box mt={5}>
        <CueRows productionType={productionType} track={track} cueContainer={cueContainer} />
      </Box>
    </ClearanceBaseForm>
  );
}
