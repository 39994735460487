import { makeStyles } from '@material-ui/core';
import { appColors } from 'components/styleguide';

const useStyles = makeStyles((theme) => ({
  stepperRoot: {
    marginTop: theme.spacing(4),
    padding: 0,
  },
  stepLabel: {
    textTransform: 'uppercase',
  },
  inputContainer: {
    alignContent: 'space-between',
    alignItems: 'center',
    borderLeft: '1px solid',
    borderLeftColor: appColors.lightGradient,
    display: 'flex',
    margin: 0,
    marginLeft: theme.spacing(1.5),
    '& > :first-child': {
      padding: theme.spacing(),
      width: '100%',
    },
    '& > :last-child': {
      marginLeft: 'auto',
      marginRight: theme.spacing(),
    },
  },
  line: {
    borderColor: appColors.lightGradient,
  },
  lastStep: {
    marginTop: theme.spacing(3),
    marginLeft: 0,
    paddingLeft: 0,
  },
}));

export default useStyles;
