/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-children-prop */
/* eslint-disable import/no-cycle */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonGroup } from '@mui/material';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import MenuPopover from 'components/styleguide/actionMenu/MenuPopover';

const ButtonMenu = ({ children, disabled, menuDisabled, options, variant, testId, size, ...props }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <ButtonGroup data-testid="button-menu-button-group">
        <Button
          variant={variant}
          split="left"
          children={children}
          data-testid={`button-${testId ?? 'menu-1'}`}
          disabled={disabled}
          size={size}
          {...props}
        />
        <Button
          variant={variant}
          onClick={openMenu}
          split="right"
          synchronous
          dense
          data-testid="button-menu-2"
          disabled={menuDisabled}
          size={size}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <MenuPopover
        closeMenu={closeMenu}
        anchorEl={anchorEl}
        options={options}
        popoverProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
        }}
      />
    </>
  );
};

ButtonMenu.propTypes = {
  children: PropTypes.any,
  disabled: PropTypes.bool,
  options: PropTypes.array.isRequired,
  variant: PropTypes.string,
  menuDisabled: PropTypes.bool,
};

ButtonMenu.defaultProps = {
  options: [],
};

export default ButtonMenu;
