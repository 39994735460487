import { createSelector } from 'reselect';
import last from 'ramda/src/last';
import path from 'ramda/src/path';
import pipe from 'ramda/src/pipe';
import length from 'ramda/src/length';
import compose from 'ramda/src/compose';
import propEq from 'ramda/src/propEq';
import prop from 'ramda/src/prop';
import find from 'ramda/src/find';
import findIndex from 'ramda/src/findIndex';

export const getDialogsRoot = (state) => state.dialogs;

export const getOpenDialogSelector = createSelector(getDialogsRoot, pipe(last, path(['name'])));

export const getOpenDialogPayloadSelector = createSelector(getDialogsRoot, pipe(last, path(['payload'])));

export const getIsOpenedDialogFormDirty = createSelector(getDialogsRoot, pipe(last, path(['isDirty'])));

export const getIsDialogDirtyByNameSelector = createSelector(
  (state, drawerName) => compose(find(propEq('name', drawerName)), getDialogsRoot)(state),
  compose(Boolean, prop('isDirty')),
);

export const getDialogRightsByNameSelector = createSelector(
  (state, drawerName) => compose(find(propEq('name', drawerName)), getDialogsRoot)(state),
  prop('rights'),
);

export const getDialogByNameSelector = (state, drawerName) =>
  compose(find(propEq('name', drawerName)), getDialogsRoot)(state);

export const getDialogIndexByNameSelector = (state, drawerName) =>
  compose(findIndex(propEq('name', drawerName)), getDialogsRoot)(state);

export const getIsDialogSubmittedByNameSelector = createSelector(
  getDialogByNameSelector,
  compose(Boolean, prop('submitted')),
);

export const getIsOpenDialogSelector = compose(Boolean, length, getDialogsRoot);
