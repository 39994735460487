import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { FormTypeAhead } from 'components/common/Form/Fields/index';
import { useRootTranslation } from 'utils/hooks';
import { Row } from 'components/styleguide';
import pick from 'ramda/src/pick';
import enhancer from './enhancer';
import CuesFilters from '../Filters/CuesFilters';
// eslint-disable-next-line import/no-named-as-default
import BaseAdvancedFilters from '../BaseAdvancedFilters';
import { FILTERS_LIST_FOR_CUES } from '../Filters/constants';

function ScenesAdvancedFiltersDrawer({ payload, addParams, ...props }) {
  const drawerProps = {
    ...pick(['name', 'open', 'onClose'], props),
    payload,
  };

  const t = useRootTranslation('drawers.advancedFilters.sceneFilters');
  const tGlobal = useRootTranslation('global');

  const handleOnSubmit = (filters) => {
    addParams({ filters });
  };

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <BaseAdvancedFilters {...drawerProps} onSubmit={handleOnSubmit}>
      {({ filtersData, loading }) => (
        <>
          <Row pb={3} divider>
            <Grid item xs={8}>
              <FormTypeAhead
                name="cueContainerIds"
                options={filtersData.scenes}
                label={t('cueContainerIds')}
                placeholder={tGlobal('forms.placeholder.search')}
                isMultiple
                loading={loading}
              />
            </Grid>
          </Row>
          <CuesFilters filtersData={pick(FILTERS_LIST_FOR_CUES, filtersData)} loading={loading} />
        </>
      )}
    </BaseAdvancedFilters>
  );
}

ScenesAdvancedFiltersDrawer.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  payload: PropTypes.object.isRequired,
  addParams: PropTypes.func.isRequired,
};

export default enhancer(ScenesAdvancedFiltersDrawer);
