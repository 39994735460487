/* eslint-disable import/no-cycle */
import makeEntityServiceCalls, { makeServiceCall } from 'redux-core/serviceHelpers';

export const { getAll: getUsersCall, get: getUserCall } = makeEntityServiceCalls('users');
export const sendInviteCall = makeServiceCall('users', 'invite');
export const usersSignupCall = makeServiceCall('users', 'signup');
export const ssoUsersSignupCall = makeServiceCall('users', 'sso-signup');
export const sendMultipleInviteCall = makeServiceCall('users', 'invite-multiple-users');

export const getProductionPermissions = makeServiceCall('user-permissions', 'get-production-permissions');

export const getDefaultRole = makeServiceCall('roles', 'get-default-role');

export const setDefaultRole = makeServiceCall('roles', 'set-default-role');

export const getRoles = makeServiceCall('roles', 'get-all');

export const verifyPartnerTokenCall = makeServiceCall('users', 'verify-partner-token');

export const partnerSignupCall = makeServiceCall('users', 'partner-signup');

export const toggleDivisionAccess = makeServiceCall('users', 'toggle-division-access');

export const getPermitionedUsersEmail = makeServiceCall('users', 'get-permitioned-users-email');
