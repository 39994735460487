/* eslint-disable jsx-a11y/anchor-is-valid */
import { Box, Grid, Link } from '@material-ui/core';
import AddIcon from '@material-ui/icons/AddCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import classnames from 'classnames';

import { FormDropdown, FormInputText } from 'components/common/Form/Fields';
import { useInviteUsersStyle } from './styles';

export const InviteUserRow = ({
  invitedUser,
  rowIndex,
  removeUser,
  addUser,
  roles,
  lastRow,
}) => {
  const { rowAction, rowActionContainer, firstRow } = useInviteUsersStyle();
  return (
    <Box mt={2} key={invitedUser.transientId}>
      <Grid container spacing={2} direction="row" alignItems="stretch">
        <Grid item xs={5}>
          <FormInputText
            name={`inviteList.${rowIndex}.email`}
            testId={`inviteList-${rowIndex}-email`}
            label={rowIndex === 0 ? 'Email' : null}
          />
        </Grid>
        <Grid item xs={5}>
          <FormDropdown
            name={`inviteList.${rowIndex}.defaultProjectRole`}
            placeholder="global.forms.chooseOne"
            options={roles}
            valueKey="id"
            labelKey="name"
            label={rowIndex === 0 ? 'Default Project Role' : null}
            testId={`inviteList-${rowIndex}-defaultProjectRole`}
          />
        </Grid>
        <Grid
          item
          xs={2}
          className={classnames(
            rowActionContainer,
            rowIndex === 0 ? firstRow : '',
          )}
        >
          <Link className={rowAction} onClick={() => removeUser(rowIndex)}>
            <CancelIcon color="primary" />
          </Link>
          {lastRow && (
            <Link className={rowAction} onClick={addUser} data-testId={`inviteList-${rowIndex}-add-new-row-button`}>
              <AddIcon color="primary" /> Users
            </Link>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};
