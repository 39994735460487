import React from 'react';
import { isEmpty } from 'lodash';
import { Autocomplete, Skeleton, TextField } from '@mui/material';
import { useRootTranslation } from 'utils/hooks';
import { palette } from 'components/styleguide/newTheme';
import { useLocation } from 'react-router';
import { RIGHTS_TERRITORIES_TERM_ENUM } from 'components/Settings/Tabs/Rights/constants';
import { TPresetToRight, TRightPreset, useGetRightPresetsQuery } from 'services/clear/rights-presets';
import { FormFieldForwardedProps } from '../Form/Fields/FormField/FormField';

type RightsPresetPickerProps = FormFieldForwardedProps & {
  divisionId?: number;
  testId?: string;
  // new from the form
  productionObjectId?: string;
  showErrorBorder?: boolean;
  fullWidth?: boolean;
};

function isPresetToRight(value: TRightPreset | TPresetToRight): value is TPresetToRight {
  return Object.hasOwn(value, 'rights');
}

export const formatPresetToRights = (value: TRightPreset | TPresetToRight): TPresetToRight => {
  if (isPresetToRight(value)) {
    return value;
  }

  return {
    name: value.name,
    type: value.type,
    descriptionOnLetters: value.descriptionOnLetters,
    rights: value.territories.map(({ id, term, name, otherDetails }) => ({
      name,
      term,
      otherDetails: term === RIGHTS_TERRITORIES_TERM_ENUM.OTHER ? otherDetails : null,
      territoryId: id,
    })),
  };
};

export const processPresets = (values) => {
  const preset = {
    name: values.name,
    descriptionOnLetters: values.descriptionOnLetters,
    territories: values.territories,
  };
  return preset;
};

/**
 * New version of RightsPresetPicker making it form library agnostic.
 * This is part of the formik deprecation and replacement by react-hook-form.
 */
const RightsPresetPickerFormField = ({
  divisionId,
  value,
  onBlur = undefined,
  onChange,
  productionObjectId,
  fullWidth = undefined,
  showErrorBorder = false,
}: RightsPresetPickerProps) => {
  const t = useRootTranslation('drawers.clearance.rightsPresetPicker');
  const { state = {} } = useLocation();
  const objectId = state.objectId || productionObjectId;

  const { data: rightsPresets, isLoading } = useGetRightPresetsQuery(
    { divisionId, productionObjectId: objectId },
    { skip: !divisionId },
  );

  if (isLoading) return <Skeleton />;

  const errorBorder = showErrorBorder ? {
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: palette.error.main,
      },
      '& fieldset': {
        borderColor: palette.error.main,
      },
    },
  } : {};

  return (
    <Autocomplete<TRightPreset, true>
      // Force autocomplete rerender to update the current value when the value updates after the initial render.
      key={`RightsPresetPickerFormField-${value ? 'ready' : 'pending'}`}
      multiple
      id="rightsPresetId"
      loading={isLoading}
      value={value}
      onBlur={onBlur}
      onChange={(_, nextValue: TRightPreset[]) => {
        if (isEmpty(nextValue)) {
          onChange(nextValue);
          return;
        }
        const updatedRights = nextValue?.map(formatPresetToRights);
        onChange(updatedRights);
      }}
      getOptionLabel={(option: TRightPreset) => option?.name ?? ''}
      isOptionEqualToValue={(option, optionValue) => option.name === optionValue.name}
      options={rightsPresets ?? []}
      size="small"
      fullWidth={fullWidth}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={t('placeholder')}
          data-testid="typeahead-field-requiredRights"
          sx={errorBorder}
        />
      )}
    />
  );
};

export default RightsPresetPickerFormField;
