/* eslint-disable @typescript-eslint/no-shadow */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Row } from 'components/styleguide';
import { Grid, FormControlLabel, Checkbox } from '@material-ui/core';
import map from 'ramda/src/map';
import prop from 'ramda/src/prop';
import propEq from 'ramda/src/propEq';
import reject from 'ramda/src/reject';

import { FormDebouncedTypeAhead, FormTypeAhead, FormInputText } from 'components/common/Form/Fields/index';
import any from 'ramda/src/any';
import { getQwireTracksLicensorsCall } from 'redux-core/tracks/services';
import { searchContactsCall } from 'redux-core/contacts/services';
import { useRootTranslation } from 'utils/hooks';
import { LICENSOR_TYPES, LICENSOR_TYPES_OPTIONS, SEARCH_CONTACTS_FIELDS } from 'utils/constants';
import { useFormikContext } from 'formik';
import useStyles from '../styles';
import enhancer from './enhancer';

const CONTACTS_LICENSOR_OPTIONS = reject(propEq('id', LICENSOR_TYPES.ONE_STOP))(LICENSOR_TYPES_OPTIONS);
const PersonalInformation = ({
  readOnly,
  isAdding,
  contact,
  isNewCompanyName,
  setIsNewCompanyName,
  showCompanyTypeAhead,
  setShowCompanyTypeAhead,
  activeDivisionId,
}) => {
  const t = useRootTranslation('drawers.contacts');
  const tGlobal = useRootTranslation('global');
  const classes = useStyles();
  const {
    values: { firstName, lastName, companyName },
    setFieldValue,
  } = useFormikContext();

  const [noRequiredFieldIsTruthy, setNoRequiredFieldIsTruthy] = useState(true);
  useEffect(() => {
    const isRequired = any(Boolean, [firstName, lastName, companyName]);
    setNoRequiredFieldIsTruthy(!isRequired);
  }, [firstName, lastName, companyName]);

  const [licensorChecked, setLicensorChecked] = useState(
    Boolean(isAdding) || Boolean(prop('qwireTracksRightsOwnerId', contact)),
  );

  const isNewRightsOwner = licensorChecked && isNewCompanyName;

  const handleSelectCompany = (company) => {
    const qwireTracksRightsOwnerId = licensorChecked && company ? prop('id', company) : null;
    setFieldValue('qwireTracksRightsOwnerId', qwireTracksRightsOwnerId);
  };

  const handleCreateCompany = (company) => {
    setFieldValue('companyName', company);
    setShowCompanyTypeAhead(false);
    setIsNewCompanyName(true);

    if (licensorChecked) {
      setFieldValue('isNewRightsOwner', true);
    }
  };

  const handleLicensorCheckboxChange = (e) => {
    const isChecked = e.target.checked;
    setLicensorChecked(isChecked);
    setFieldValue('qwireTracksRightsOwnerId', null);

    if (isChecked) {
      setShowCompanyTypeAhead(isChecked);
    }

    /** Refreshing company name */
    setFieldValue('companyName', null);

    if (isNewRightsOwner) {
      setFieldValue('isNewRightsOwner', true);
    } else {
      setFieldValue('isNewRightsOwner', false);
    }
  };

  return (
    <>
      <Row spacing={1}>
        <Grid item xs={6}>
          <FormInputText
            autoFocus
            name="firstName"
            label={t('form.firstName')}
            testId="contacts-drawer-firstName"
            required={noRequiredFieldIsTruthy}
            readOnly={readOnly}
          />
        </Grid>

        <Grid item xs={6}>
          <FormInputText
            name="lastName"
            label={t('form.lastName')}
            testId="contacts-drawer-lastName"
            required={noRequiredFieldIsTruthy}
            readOnly={readOnly}
          />
        </Grid>
      </Row>

      <Row spacing={2} alignItems="flex-end">
        <Grid item xs={6}>
          {showCompanyTypeAhead ? (
            <FormDebouncedTypeAhead
              name="companyName"
              valueKey="name"
              label={t('form.companyName')}
              call={async (term) => {
                if (licensorChecked) {
                  return getQwireTracksLicensorsCall({ term });
                }

                const contacts = await searchContactsCall({
                  divisionId: activeDivisionId,
                  term,
                  fields: [SEARCH_CONTACTS_FIELDS.LICENSOR_COMPANY_NAME],
                });

                return map((contact) => ({
                  id: contact.id,
                  name: contact.companyName || contact.name,
                }))(contacts);
              }}
              creatableText={tGlobal('forms.createNew')}
              onCreate={handleCreateCompany}
              onSelect={handleSelectCompany}
              testId="contacts-drawer-qwireTracksRightsOwnerId"
              required={noRequiredFieldIsTruthy}
              readOnly={readOnly}
            />
          ) : (
            <FormInputText
              name="companyName"
              label={t('form.companyName')}
              testId="contacts-drawer-companyName"
              required={noRequiredFieldIsTruthy}
              readOnly={readOnly}
            />
          )}
        </Grid>
        <Grid item>
          <FormControlLabel
            control={
              <Checkbox
                className={classes.licensorCheckbox}
                checked={licensorChecked}
                onChange={handleLicensorCheckboxChange}
                color="primary"
              />
            }
            label={t('form.licensor')}
            disabled={readOnly}
          />
        </Grid>

        {isNewRightsOwner && (
          <Grid item>
            <FormTypeAhead
              name="rightsOwnerType"
              label={t('form.rightsOwnerType')}
              options={CONTACTS_LICENSOR_OPTIONS}
              placeholder={t('form.selectType')}
              required
            />
          </Grid>
        )}
      </Row>
    </>
  );
};

PersonalInformation.propTypes = {
  readOnly: PropTypes.bool.isRequired,
  isAdding: PropTypes.bool.isRequired,
  contact: PropTypes.object.isRequired,
  isNewCompanyName: PropTypes.bool.isRequired,
  setIsNewCompanyName: PropTypes.func.isRequired,
  showCompanyTypeAhead: PropTypes.bool.isRequired,
  setShowCompanyTypeAhead: PropTypes.func.isRequired,
  activeDivisionId: PropTypes.number.isRequired,
};

export default enhancer(PersonalInformation);
