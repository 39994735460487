import { Autocomplete, TextField } from '@mui/material';
import { PRO_TYPES_OPTIONS } from 'utils/constants';
import { rootTranslation } from 'utils/format';

const tGlobal = rootTranslation('global');

type TOption = {
  id: number;
  name: string;
};

type RolePickerProps = {
  value: number;
  onChange: (nextValue: TOption) => void;
  htmlSectionId: string;
};

export function PROPicker({ htmlSectionId, value, onChange }: RolePickerProps) {
  return (
    <Autocomplete
      fullWidth
      disableClearable
      options={PRO_TYPES_OPTIONS}
      value={{ id: value, name: value as unknown as string }}
      onChange={(_, nextValue: TOption) => {
        onChange(nextValue);
      }}
      getOptionLabel={(option) => option.id}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={tGlobal('forms.chooseOne')}
          inputProps={{
            ...params.inputProps,
            'aria-labelledby': `${htmlSectionId}-pro`,
          }}
        />
      )}
      noOptionsText={tGlobal('noResults')}
    />
  );
}
