import { Box } from '@mui/system';
import { isEmpty } from 'lodash';
import { useSearchTracksQuery } from 'services/tracks/tracks';
import { TSearchTracksFilters } from 'services/tracks/types';
import { SearchFormPanel } from './SearchFormPanel';
import { TrackSummary } from '../TracksList/table/TrackSummary';
import { CreateNewButton } from '../create/CreateNewButton';

type SearchHeaderProps = {
  searchTracksQueryResult: ReturnType<typeof useSearchTracksQuery>;
  searchQuery?: TSearchTracksFilters;
  performSearch: () => void;
  useCueContainerPickerTreeView: boolean;
};

export function SearchHeader({
  searchTracksQueryResult,
  performSearch,
  searchQuery,
  useCueContainerPickerTreeView,
}: SearchHeaderProps) {
  const totalCount = searchTracksQueryResult.data?.pagination?.totalCount;
  const emptySearch = isEmpty(searchQuery?.filters?.titleTerm) && isEmpty(searchQuery?.filters?.artistTerm);

  return (
    <Box>
      <SearchFormPanel onSubmit={performSearch} useCueContainerPickerTreeView={useCueContainerPickerTreeView} />
      <Box sx={{ display: 'flex', alignItems: 'center', p: 2 }}>
        {!emptySearch ? (
          <>
            <TrackSummary searchQuery={searchQuery} totalCount={totalCount} />
            <Box sx={{ ml: 'auto' }}>
              <CreateNewButton />
            </Box>
          </>
        ) : null}
      </Box>
    </Box>
  );
}
