import { Autocomplete, TextField } from '@mui/material';

import { rootTranslation } from 'utils/format';

const tGlobal = rootTranslation('global');

type TOption = {
  id: string;
  name: string;
};

type RolePickerProps = {
  value: string;
  onChange: (nextValue: TOption) => void;
  labelledbyId?: string;
  options: Array<{ id: string; name: string }>;
  dataTestId?: string;
  readOnly?: boolean;
  disabled?: boolean;
};

export function RolePicker({
  options,
  labelledbyId,
  value,
  onChange,
  dataTestId,
  readOnly,
  disabled,
}: RolePickerProps) {
  const selected = options.find((it) => it.id === value);

  return (
    <Autocomplete
      readOnly={readOnly}
      disabled={disabled}
      fullWidth
      disableClearable
      options={options}
      value={selected}
      onChange={(_, nextValue: TOption) => {
        onChange(nextValue);
      }}
      getOptionLabel={(option) => option.id}
      isOptionEqualToValue={(option, optionValue) => option.id === optionValue.id}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={tGlobal('forms.chooseOne')}
          inputProps={{
            ...params.inputProps,
            ...(labelledbyId && { 'aria-labelledby': labelledbyId }),
            'data-testid': `typeahead-field-${dataTestId}`,
          }}
        />
      )}
      renderOption={(props, option) => (
        <li key={option.id} {...props}>
          {option.name}
        </li>
      )}
      noOptionsText={tGlobal('noResults')}
      componentsProps={{
        paper: {
          sx: {
            minWidth: '300px',
            maxHeight: '200px',
            width: '100%',
          },
        },
      }}
    />
  );
}
