/* eslint-disable import/no-cycle */
import makeEntityServiceCalls, { makeServiceCall } from 'redux-core/serviceHelpers';
import { NORMALIZED_PRODUCTION_TYPES } from 'utils/constants';
import always from 'ramda/src/always';
import cond from 'ramda/src/cond';
import equals from 'ramda/src/equals';
import { rootTranslation } from 'utils/format';

const {
  getAll: getReleaseScenesCall,
  get: getReleaseSceneCall,
  create: createReleaseSceneCall,
  update: updateReleaseSceneCall,
  delete: deleteReleaseSceneCall,
} = makeEntityServiceCalls('release-scenes');
export const getReleaseSceneDetailsCall = makeServiceCall('release-scenes', 'get-details');
export const getReleaseScenesLightCall = makeServiceCall('release-scenes', 'get-list');

export const {
  getAll: getCampaignAssetsCall,
  get: getCampaignAssetCall,
  create: createCampaignAssetCall,
  update: updateCampaignAssetCall,
  delete: deleteCampaignAssetCall,
} = makeEntityServiceCalls('materials');
export const getCampaignAssetDetailsCall = makeServiceCall('materials', 'get-details');
export const getCampaignAssetsLightCall = makeServiceCall('materials', 'get-list');

const {
  getAll: getEpisodeScenesCall,
  get: getEpisodeSceneCall,
  create: createEpisodeSceneCall,
  update: updateEpisodeSceneCall,
  delete: deleteEpisodeSceneCall,
} = makeEntityServiceCalls('episode-scenes');
export const getEpisodeSceneDetailsCall = makeServiceCall('episode-scenes', 'get-details');
export const getEpisodeScenesLightCall = makeServiceCall('episode-scenes', 'get-list');

export const getCueContainersCall = cond([
  [
    equals(NORMALIZED_PRODUCTION_TYPES.FEATURES),
    always(({ id, ...payload }) => getReleaseScenesCall({ ...payload, releaseId: id })),
  ],
  [
    equals(NORMALIZED_PRODUCTION_TYPES.MKT),
    always(({ id, ...payload }) => getCampaignAssetsCall({ ...payload, campaignId: id })),
  ],
  [
    equals(NORMALIZED_PRODUCTION_TYPES.TV),
    always(({ id, ...payload }) => getEpisodeScenesCall({ ...payload, episodeId: id })),
  ],
]);

export const getCueContainersLightCall = cond([
  [equals(NORMALIZED_PRODUCTION_TYPES.FEATURES), always(getReleaseScenesLightCall)],
  [equals(NORMALIZED_PRODUCTION_TYPES.MKT), always(getCampaignAssetsLightCall)],
  [equals(NORMALIZED_PRODUCTION_TYPES.TV), always(getEpisodeScenesLightCall)],
]);

export const getCueContainerCall = cond([
  [equals(NORMALIZED_PRODUCTION_TYPES.FEATURES), always(getReleaseSceneCall)],
  [equals(NORMALIZED_PRODUCTION_TYPES.MKT), always(getCampaignAssetCall)],
  [equals(NORMALIZED_PRODUCTION_TYPES.TV), always(getEpisodeSceneCall)],
]);

export const getCueContainerDetailsCall = cond([
  [equals(NORMALIZED_PRODUCTION_TYPES.FEATURES), always(getReleaseSceneDetailsCall)],
  [equals(NORMALIZED_PRODUCTION_TYPES.MKT), always(getCampaignAssetDetailsCall)],
  [equals(NORMALIZED_PRODUCTION_TYPES.TV), always(getEpisodeSceneDetailsCall)],
]);

export const createCueContainerCall = cond([
  [
    equals(NORMALIZED_PRODUCTION_TYPES.FEATURES),
    always(({ productionId, ...payload }) => createReleaseSceneCall({ ...payload, releaseId: productionId })),
  ],
  [
    equals(NORMALIZED_PRODUCTION_TYPES.MKT),
    always(({ productionId, ...payload }) => createCampaignAssetCall({ ...payload, campaignId: productionId })),
  ],
  [
    equals(NORMALIZED_PRODUCTION_TYPES.TV),
    always(({ productionId, ...payload }) => createEpisodeSceneCall({ ...payload, episodeId: productionId })),
  ],
]);

export const updateCueContainerCall = cond([
  [equals(NORMALIZED_PRODUCTION_TYPES.FEATURES), always(updateReleaseSceneCall)],
  [equals(NORMALIZED_PRODUCTION_TYPES.MKT), always(updateCampaignAssetCall)],
  [equals(NORMALIZED_PRODUCTION_TYPES.TV), always(updateEpisodeSceneCall)],
]);

export const deleteCueContainerCall = cond([
  [equals(NORMALIZED_PRODUCTION_TYPES.FEATURES), always(deleteReleaseSceneCall)],
  [equals(NORMALIZED_PRODUCTION_TYPES.MKT), always(deleteCampaignAssetCall)],
  [equals(NORMALIZED_PRODUCTION_TYPES.TV), always(deleteEpisodeSceneCall)],
]);

export const cuesUpdateMove = makeServiceCall('tracks', 'move', 'clear');

export const cuesMoveWithin = makeServiceCall('tracks', 'change-position', 'clear');

const parseFiltersToOptions = (filters, cb) =>
  filters.reduce((acc, curr, index) => {
    const result = cb(acc, curr, index);
    return [...acc, result];
  }, []);

const getFiltersOptions = (filters) => {
  const t = rootTranslation('global');
  const options = Object.keys(filters).reduce((filtersAcc, filterName) => {
    const filter = filters[filterName];

    if (['scenes', 'assets'].includes(filterName)) {
      const cueContainers = parseFiltersToOptions(filter, (acc, { id, number, title }) => ({
        id,
        name: number ? `${number}. ${title}` : title,
      }));
      return { ...filtersAcc, [filterName]: cueContainers };
    }

    if (filterName === 'trackTypes') {
      const trackTypes = parseFiltersToOptions(filter, (acc, option) => ({
        id: option,
        name: t(`trackTypes.${option.toUpperCase()}`),
      }));
      return { ...filtersAcc, [filterName]: trackTypes };
    }

    if (filterName === 'trackUseTypes') {
      const trackTypes = parseFiltersToOptions(filter, (acc, option) => ({
        id: option,
        name: t(`trackUseTypes.${option.toUpperCase()}`),
      }));
      return { ...filtersAcc, [filterName]: trackTypes };
    }

    if (['trackStatuses'].includes(filterName)) {
      const trackTypes = filter.map((status) => ({
        id: status.id,
        name: status.label,
      }));
      return { ...filtersAcc, [filterName]: trackTypes };
    }

    if (['assetStatuses'].includes(filterName)) {
      const trackTypes = parseFiltersToOptions(filter, (acc, option) => ({
        id: option.id,
        name: option.label,
      }));
      return { ...filtersAcc, [filterName]: trackTypes };
    }

    if (filterName === 'trackTags') {
      const trackTypes = parseFiltersToOptions(filter, (acc, option) => ({
        id: option.toLowerCase(),
        name: option,
      }));
      return { ...filtersAcc, [filterName]: trackTypes };
    }

    if (filterName === 'completedStatuses') {
      const completedStatuses = parseFiltersToOptions(filter, (acc, option) => ({
        ...option,
        id: option.key,
        name: option.value,
      }));
      return { ...filtersAcc, [filterName]: completedStatuses };
    }

    /**
     * Default Scenario
     * @example 'clearanceReps' | 'trackLibraries' | 'assetTypes' | 'assetStatuses'
     */
    return { ...filters, ...filtersAcc };
  }, {});

  return options;
};

/**
 * Get the list of options to fill the Advanced Filters Drawer Dropdowns
 */
export const getAdvancedFiltersOptionsCall = async (payload) => {
  const call = makeServiceCall('productions', 'get-advanced-filter-options', 'clear');
  const response = await call(payload);
  const options = getFiltersOptions(response);
  return options;
};

export * from './assets/services';
