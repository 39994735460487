/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const View = (props) => (
  <SvgIcon {...props}>
    <path d="M19.565 7.029l-3.308-3.308C15.864 3.329 15.08 3 14.517 3h-10.5C3.457 3 3 3.456 3 4.018v16.964C3 21.544 3.456 22 4.018 22h15.25c.562 0 1.018-.456 1.018-1.018V8.768c0-.562-.329-1.347-.721-1.739zm-4.708-2.587c.18.064.36.159.435.233l3.318 3.319c.075.074.17.254.234.435h-3.987V4.442z" />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M14.571 15.543h-.548l-.206-.206a4.291 4.291 0 0 0 1.097-2.88A4.448 4.448 0 0 0 10.457 8 4.448 4.448 0 0 0 6 12.457a4.448 4.448 0 0 0 4.457 4.457 4.291 4.291 0 0 0 2.88-1.097l.206.206v.548L16.97 20 18 18.971l-3.429-3.428zm-4.114 0a3.073 3.073 0 0 1-3.085-3.086 3.073 3.073 0 0 1 3.085-3.085 3.073 3.073 0 0 1 3.086 3.085 3.073 3.073 0 0 1-3.086 3.086z"
      clipRule="evenodd"
    />
  </SvgIcon>
);

export default View;
