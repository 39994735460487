/* eslint-disable react/no-children-prop */
import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useFetch, useRootTranslation } from 'utils/hooks';
import { DrawerHeader } from 'components/styleguide';
import Form from 'components/common/Form';
import { FormButton, FormInputText } from 'components/common/Form/Fields';
import { requestWithError } from 'utils/request';
import { updateCueCall, getCueByIdCall } from 'redux-core/cues/services';
import pick from 'ramda/src/pick';
import withBaseDrawer from '../withBaseDrawer';

const rootT = 'drawers.usageNotes';

const validationSchema = Yup.object().shape({
  usageNotes: Yup.string(),
});

const UsageNotesDrawer = ({ name, onClose, payload: { cueId } }) => {
  const t = useRootTranslation(rootT);
  const [cue] = useFetch(() => cueId && getCueByIdCall({ id: cueId }), [cueId], {});
  const handleSubmit = async (values) => {
    await requestWithError(updateCueCall, pick(['id', 'usageNotes'], values), {
      message: {
        success: t('notifications.success'),
        failed: t('notifications.failed'),
      },
    });
    onClose();
  };

  const onCancel = () => {
    onClose();
  };

  return (
    <Form
      drawerName={name}
      enableReinitialize
      initialValues={cue}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      <DrawerHeader title={t('drawerTitle')} onClose={onClose}>
        <FormButton
          alwaysEnabled
          variant="destructive"
          size="xs"
          children="global.forms.cancel"
          type="button"
          onClick={() => onCancel()}
        />
        <FormButton variant="primary" size="xs" children="global.forms.save" type="submit" />
      </DrawerHeader>
      <FormInputText name="usageNotes" label={`${rootT}.label`} textArea testId="usageNotes-text-area" rows={10} />
    </Form>
  );
};

UsageNotesDrawer.propTypes = {
  name: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  payload: PropTypes.shape({
    cueId: PropTypes.number,
  }),
};

export default withBaseDrawer(UsageNotesDrawer);
