/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-unused-expressions */
/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import { useFetch } from 'utils/hooks';
import { Loading } from 'components/styleguide';
import BookmarkList from './BookmarkList';
import enhancer from './enhancer';

const Bookmarks = ({ classes, bookmarkList, fetchBookmarkList, open, setOpen }) => {
  const [, loading] = useFetch(fetchBookmarkList, []);

  const handleToggle = (e) => {
    // Avoids handleToggle to be executed twice
    const eventPath = e && (e.path || (e.composedPath && e.composedPath()));
    const dismissToggle = open && e && eventPath.some((e) => e.id === 'bookmarks');
    !dismissToggle && setOpen(!open);
  };

  return (
    <>
      <BookmarkIcon className={classes.bookmarkIcon} onClick={() => handleToggle()} id="bookmarks" />
      {open && (loading ? <Loading /> : <BookmarkList list={bookmarkList} handleToggle={handleToggle} />)}
    </>
  );
};

Bookmarks.propTypes = {
  bookmarkList: PropTypes.array,
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  fetchBookmarkList: PropTypes.func.isRequired,
};

export default enhancer(Bookmarks);
