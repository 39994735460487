/* eslint-disable import/no-cycle */
import makeEntityServiceCalls, { makeServiceCall } from 'redux-core/serviceHelpers';

export const {
  get: getCampaignByIdCall,
  create: createCampaignCall,
  update: updateCampaignCall,
} = makeEntityServiceCalls('campaigns');

export const createProjectAndCampaignCall = makeServiceCall('campaigns', 'create-project-campaign');
