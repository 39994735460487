import { makeStyles } from '@material-ui/core';

import { NORMALIZED_PRODUCTION_TYPES } from 'utils/constants';
import newTheme from '../newTheme';
import { appColors } from '../theme';

export const placeholderColorMapper = {
  [NORMALIZED_PRODUCTION_TYPES.FEATURES]: newTheme.palette.secondary.light,
  [NORMALIZED_PRODUCTION_TYPES.MKT]: appColors.green,
  [NORMALIZED_PRODUCTION_TYPES.TV]: appColors.orange,
};

const useStyles = makeStyles((theme) => ({
  image: ({ url, size }) => ({
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    position: 'relative',
    backgroundImage: `url(${url})`,
    width: size ? size.width : undefined,
    height: size ? size.height : undefined,
    '&.xs': {
      width: 48,
      height: 48,
    },
    '&.md': {
      width: 57,
      height: 84,
    },
    '&.lg': {
      height: 188,
      width: 128,
    },
    '&.xl': {
      height: 270,
      width: 184,
    },
    '&.isPlaceholder': {
      background: ({ type }) => placeholderColorMapper[type] || theme.palette.grey[400],
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
    },
  }),
  icon: {
    position: 'absolute',
    fontSize: '24px',
    bottom: '3px',
    right: '3px',
    color: appColors.white,
  },
}));

export default useStyles;
