/* eslint-disable no-unused-vars */
import { createSelector } from 'reselect';
import c from 'ramda/src/compose';
import all from 'ramda/src/all';
import filter from 'ramda/src/filter';
import head from 'ramda/src/head';
import includes from 'ramda/src/includes';
import isNil from 'ramda/src/isNil';
import length from 'ramda/src/length';
import map from 'ramda/src/map';
import not from 'ramda/src/not';
import prop from 'ramda/src/prop';
import propOr from 'ramda/src/propOr';
import slice from 'ramda/src/slice';
import { getLocationParametersSelector } from 'redux-core/router/selectors';
import { mapIndexed } from 'utils/object';
import { getSelectedProductionDivisionIdSelector } from 'redux-core/overview/selectors';
import { IMPORT_FIELDS } from './constants';
import { isValidStep, parseMappings, getAllUniqValues } from './utils';

const getImportCueSheetRoot = prop('importCueSheet');
const ROWS_NUMBER = 30;
const PREVIEW_ROWS_NUMBER = 50;

export const getIsCompareQwireTracksLoading = createSelector(
  getLocationParametersSelector,
  prop('isCompareQwireTracksLoading'),
);

export const getIsEDLSavingLoading = createSelector(getLocationParametersSelector, prop('isEDLSavingLoading'));

export const getImportFileNameSelector = createSelector(getImportCueSheetRoot, prop('fileName'));

export const getImportActiveStepSelector = createSelector(getLocationParametersSelector, propOr(0, 'activeStep'));

export const getImportCueSheetSelector = createSelector(getImportCueSheetRoot, prop('cueSheet'));

export const getImportFileDataSelector = createSelector(getImportCueSheetRoot, prop('file'));

export const getImportFileFirstRowSelector = createSelector(getImportCueSheetRoot, prop('firstRow'));

export const getImportFileHeaderRowSelector = createSelector(getImportCueSheetRoot, prop('headerRow'));

export const getImportTracksCompareSelector = createSelector(getImportCueSheetRoot, prop('tracksCompare'));

export const getImportTemplateIdSelector = createSelector(getImportCueSheetRoot, prop('templateId'));

export const getImportFileErrorSelector = createSelector(getImportCueSheetRoot, prop('error'));

export const getImportUploadedImportFileIdSelector = createSelector(
  getImportCueSheetRoot,
  prop('uploadedImportFileId'),
);

export const getIsValidStepSelector = createSelector(getImportActiveStepSelector, getImportCueSheetRoot, isValidStep);

export const getImportFirstRowsSelector = createSelector(getImportFileDataSelector, slice(0, ROWS_NUMBER));

export const getImportHeaderSelector = createSelector(
  getImportFileDataSelector,
  getImportFileHeaderRowSelector,
  (data, header) => data[header],
);

export const getImportContentRowsSelector = createSelector(
  getImportFileDataSelector,
  getImportFileHeaderRowSelector,
  (data, header) => slice(header + 1, header + ROWS_NUMBER)(data),
);

export const getImportFirstRowDataSelector = createSelector(
  getImportFileDataSelector,
  getImportFileFirstRowSelector,
  (data, firstRow) => data[firstRow],
);

export const getImportMappingsSelector = createSelector(getImportCueSheetRoot, prop('mappings'));

export const getImportMappedFieldsSelector = createSelector(getImportCueSheetRoot, prop('mappedFields'));

export const getImportMappingColumnSelector = createSelector(getImportCueSheetRoot, prop('selectedColumn'));

export const getImportUsageMappingsSelector = createSelector(getImportCueSheetRoot, prop('usageMappings'));

export const getImportPreviewRowsSelector = createSelector(getImportCueSheetSelector, slice(0, PREVIEW_ROWS_NUMBER));

/**
 * Indicates which columns are mapped
 * @returns {Array.<Number>}
 */
export const getImportMappedColumnsSelector = createSelector(
  getImportMappingsSelector,
  c(
    map(c(Number, head)),
    filter(([_, value]) => value?.length),
    Object.entries,
  ),
);

export const getActiveMappedFieldsSelector = createSelector(
  getImportMappingsSelector,
  getImportMappingColumnSelector,
  (mappings, current) => mappings[current],
);

export const getImportPreviewingRowSelector = createSelector(getImportCueSheetRoot, prop('previewingRow'));

export const getImportTemplateSelector = createSelector(
  getImportFileFirstRowSelector,
  getImportFileHeaderRowSelector,
  getImportMappingsSelector,
  getImportUsageMappingsSelector,
  getSelectedProductionDivisionIdSelector,
  (firstRow, headerRow, mapping, usage, divisionId) => ({
    divisionId,
    firstRow,
    headerRow,
    mapping,
    usage,
  }),
);

export const getImportCueSheetVersionId = createSelector(getLocationParametersSelector, prop('cueSheetVersionId'));

export const getPreviewingFormattedSelector = createSelector(
  getImportFileDataSelector,
  getActiveMappedFieldsSelector,
  getImportMappingColumnSelector,
  getImportPreviewingRowSelector,
  getImportUsageMappingsSelector,
  (file, mappings, activeColumn, previewingRow, usage) =>
    c(
      map((row) => parseMappings(row?.[activeColumn], mappings, usage)),
      slice(previewingRow, previewingRow + 3),
    )(file),
);

export const getActiveFieldsOnlySelector = createSelector(
  getActiveMappedFieldsSelector,
  // i % 2 because fields are placed at odd positions in the array
  (fields) => (fields || []).filter((_, i) => i % 2 === 0),
);

export const getImportHasDividersSelector = createSelector(
  getActiveMappedFieldsSelector,
  (fields) => fields?.length > 1,
);

export const getTotalFileLengthSelector = createSelector(getImportFileDataSelector, length);

export const getIsUsageBeingMappedSelector = createSelector(getActiveFieldsOnlySelector, includes(IMPORT_FIELDS.USAGE));

export const getImportSelectedDataSelector = createSelector(
  getImportFileDataSelector,
  getImportFileFirstRowSelector,
  (data, firstRow) => slice(firstRow, undefined)(data),
);

/**
 * Returns all the uniq values (trimmed + case insensitive) for the current
 * selected column in the whole uploaded file (starting from the first row)
 */
export const getSelectedColumnUniqueSelector = createSelector(
  getImportSelectedDataSelector,
  getImportMappingColumnSelector,
  getAllUniqValues,
);

export const getMappedColumnsCountSelector = createSelector(
  getImportMappedFieldsSelector,
  c(length, filter(Boolean), Object.values),
);

export const getIsMappingColumnSelector = createSelector(getImportMappingColumnSelector, (column) => !isNil(column));

export const getLightTracksCompareSelector = createSelector(
  getImportTracksCompareSelector,
  mapIndexed((_, i) => i),
);

export const getIsAllSelectedSelector = createSelector(
  c(filter(Boolean), getImportTracksCompareSelector),
  (tracksCompare) => ({
    isAllImportDataSelected: all(c(not, prop('qtrackSelected')))(tracksCompare),
    isAllQTracksDataSelected: all(prop('qtrackSelected'))(tracksCompare),
  }),
);

const getImportCompareItem = (state, props) => c(prop(props.itemNumber), getImportTracksCompareSelector)(state);

export const makeGetImportCompareItemSelector = () =>
  createSelector(getImportCompareItem, (item) => (item?.qwireTracksData ? item : undefined));
