import React, { useState } from 'react';
import { useDebounce, useFetch, useRootTranslation } from 'utils/hooks';
import { FormTypeAhead } from '..';

export type TFormDebouncedTypeAheadProps = {
  call: (value: string) => void;
  defaultInput: string;
  onChange?: (value: string) => void;
  noResultsText: string;
} & React.ComponentProps<typeof FormTypeAhead>;

const FormDebouncedTypeAhead = ({
  call,
  defaultInput,
  onChange = () => {},
  noResultsText,
  ...props
}: TFormDebouncedTypeAheadProps) => {
  const t = useRootTranslation('global');
  const [input, setInput] = useState(defaultInput);
  const debounced = useDebounce(input);
  const [options, loadingOptions] = useFetch(() => (debounced ? call(debounced) : options), [debounced]);

  return (
    <FormTypeAhead
      loading={loadingOptions}
      noResultsText={!debounced ? t('startTyping') : noResultsText}
      onInputChange={(value) => {
        setInput(value);
        onChange(value);
      }}
      options={options}
      {...props}
    />
  );
};

export default FormDebouncedTypeAhead;
