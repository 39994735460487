import { useController } from 'react-hook-form';
import { newTheme } from 'components/styleguide';
import { font } from 'components/styleguide/newTheme';

const themedInput = newTheme.components.MuiOutlinedInput as any;
const BORDER_RADIUS = themedInput.styleOverrides.input.borderRadius;
const FONT_COLOR = themedInput.styleOverrides.input.color;
const FONT_SIZE = themedInput.styleOverrides.input.fontSize;
const FONT_FAMILY = font;

export function ComposeLetterComposeEmailTextArea({ name }) {
  const { field } = useController({
    name,
  });

  return (
    <textarea
      style={{
        width: '100%',
        height: '100%',
        borderRadius: `${BORDER_RADIUS}px`,
        resize: 'none',
        padding: '1rem',
        border: '1px solid #c4c4c4',
        boxSizing: 'border-box',
        fontFamily: FONT_FAMILY,
        fontSize: FONT_SIZE,
        color: FONT_COLOR,
      }}
      {...field}
    />
  );
}
