import { connect } from 'react-redux';
import { openPrompt } from 'redux-core/prompt/actions';
import { showSnackbar } from 'redux-core/global-error/actions';
import { getActiveDivisionId } from 'redux-core/settings/selectors';

const mapStateToProps = (state) => ({
  activeDivisionId: getActiveDivisionId(state),
});

const mapDispatchToProps = { openPrompt, showSnackbar };

export default connect(mapStateToProps, mapDispatchToProps);
