import { Box } from '@mui/material';
import { TCustomStatus } from './types';

export type TColoredCircleProps = {
  status: TCustomStatus;
}

export const ColoredCircle = ({ status }: TColoredCircleProps) => (
  <Box
    sx={{
      width: '24px',
      height: '24px',
      borderRadius: '50%',
      backgroundColor: status.color,
    }}
  />
);
