import { Grid } from '@material-ui/core';

import { Text } from 'components/styleguide';
import { getLocalDate } from 'utils/format';
import { useRootTranslation } from 'utils/hooks';

import { Description } from './Description';

export const FeatureDetails = ({ selected }) => {
  const t = useRootTranslation('globalSearch.details.feature');

  const project = selected.prodArray[0];
  const production = selected.prodArray[1];

  const segments = selected.cueSheetSegments?.length ? (
    <ul style={{ paddingLeft: '2em' }}>
      {selected.cueSheetSegments.map((segment) => (
        <li key={segment}>{segment}</li>
      ))}
    </ul>
  ) : null;

  return (
    <>
      <Grid direction="column" container item xs={12}>
        <Description title={t('project')} value={project.name} />
        <Text variant="caption">{production.name}</Text>
      </Grid>
      <Grid container item xs={6}>
        <Description title={t('usReleaseDate')} value={getLocalDate(production.releaseDate)} />
      </Grid>
      <Grid container item xs={6}>
        <Description title={t('intlReleaseDate')} value={getLocalDate(production.intlReleaseDate)} />
      </Grid>
      <Grid container item xs={12}>
        <Description title={t('cueSheetSegments')} value={segments} />
      </Grid>
    </>
  );
};
