import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { Skeleton } from '@mui/material';
import { FormTypeAhead } from 'components/common/Form/Fields';
import { withFormField } from 'components/common/Form/utils';
import { Button } from 'components/styleguide';
import { getSearchTracksCall } from 'redux-core/header/services';
import { getSearchRightsOwnersCall } from 'redux-core/rights-owners/services';
import { useGetClearanceStatusListQuery } from 'services/clear/clearance-status';
import {
  LICENSOR_TYPES,
  SEARCH_NORMALIZED_PRODUCTION_TYPES_OPTIONS,
  SEARCH_NORMALIZED_PRODUCTION_TYPES_TO_NORMALIZED_PRODUCTION_TYPES,
} from 'utils/constants';
import { useFetch, usePrompt, useRootTranslation } from 'utils/hooks';
import ProductionSearch, { FormProps, NormalizedProductionType } from './ProductionSearch';

const rootT = 'drawers.licensingFilters';

export type TDivision = {
  id: number;
  name: string;
  type: NormalizedProductionType.TV | NormalizedProductionType.FEATURES | NormalizedProductionType.MKT;
  tenantId: number;
};

type FilterFormProps = {
  divisions: TDivision[];
  form: FormProps;
  onClear: () => void;
  productionId: number;
  divisionId: number;
};

const FilterForm = (props: FilterFormProps) => {
  const {
    onClear,
    divisions,
    productionId,
    divisionId: propsDivisionId,
    form: { values, setFieldValue },
  } = props;
  const divisionId = propsDivisionId || values.divisionId;
  const { productionType } = values;
  const openPrompt = usePrompt();

  const { data: clearanceStatusList, isLoading: loadingClearanceStatusList } = useGetClearanceStatusListQuery({
    divisionId,
  });

  const [tracks, loadingTracks] = useFetch(async () => {
    const { data } = await getSearchTracksCall({
      term: null,
      divisionId,
      subprojectId: productionId,
      filters: {
        filterBy: 'tracks',
      },
    });
    // each element in the array is an object that has a prodArray property
    // which is an array of three elements, being:
    // 0: the project
    // 1: the subproject
    // 2: the track
    // 3: the rights owner
    return data.map((d) => d.prodArray[2]);
  }, [divisionId, productionId]);

  const [licensors, loadingLicensors] = useFetch(async () => {
    if (!divisionId || !productionId) return [];

    const { data } = await getSearchRightsOwnersCall({
      divisionId,
      term: null,
      limit: 100,
      subprojectId: productionId,
      filters: {
        filterBy: 'rightsowners',
        rightsOwnerTypes: [LICENSOR_TYPES.MASTER, LICENSOR_TYPES.SYNC, LICENSOR_TYPES.OTHER],
      },
    });
    // each element in the array is an object that has a prodArray property
    // which is an array of three elements, being:
    // 0: the project
    // 1: the subproject
    // 2: the track
    // 3: the rights owner
    return data.map((d) => d.prodArray[3]);
  }, [divisionId, productionId]);

  const t = useRootTranslation(rootT);
  if (loadingClearanceStatusList || loadingTracks || loadingLicensors) return <Skeleton />;

  const handleClear = () =>
    openPrompt({
      content: t('promptClearFilters'),
      onConfirm: onClear,
    });

  const isDisabled = !divisionId && !productionId;

  return (
    <Grid container>
      <Box display="flex" width="100%" pb={35} flexDirection="column">
        {!productionId && (
          <>
            <Grid item xs={3}>
              <Box mb={2}>
                <FormTypeAhead
                  isClearable={false}
                  name="divisionId"
                  testId="division"
                  placeholder="global.forms.chooseOne"
                  options={divisions}
                  label={`${rootT}.division`}
                  readOnly={divisions.length === 1}
                  onSelect={() => {
                    setFieldValue('productionType', null);
                    setFieldValue('seasons', []);
                    setFieldValue('subProjects', []);
                    setFieldValue('productions', []);
                  }}
                />
              </Box>
            </Grid>
            <Box pb={2}>
              <Grid xs={6}>
                <FormTypeAhead
                  name="productionType"
                  testId="productionType"
                  label={t('productionType')}
                  options={SEARCH_NORMALIZED_PRODUCTION_TYPES_OPTIONS}
                  placeholder={t('productionType')}
                  disabled={!divisionId}
                  onSelect={() => {
                    setFieldValue('seasons', []);
                    setFieldValue('subProjects', []);
                    setFieldValue('productions', []);
                  }}
                />
              </Grid>
            </Box>
            <ProductionSearch
              type={SEARCH_NORMALIZED_PRODUCTION_TYPES_TO_NORMALIZED_PRODUCTION_TYPES[productionType]}
              divisionId={divisionId}
            />
          </>
        )}
        <Grid item xs={6}>
          <Box mb={2}>
            <FormTypeAhead
              placeholder={t('track')}
              options={tracks}
              labelKey="name"
              name="tracks"
              testId="licensing-filters-track"
              label={`${rootT}.track`}
              disabled={isDisabled}
              isMultiple
            />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box mb={2}>
            <FormTypeAhead
              placeholder={t('enterLicensorName')}
              options={licensors}
              labelKey="name"
              valueKey="rightsOwnerQwireTracksId"
              name="licensors"
              testId="licensing-filters-licensor"
              label={t('licensor')}
              disabled={isDisabled}
              isMultiple
            />
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box mb={2}>
            <FormTypeAhead
              placeholder={t('status')}
              options={clearanceStatusList}
              labelKey="label"
              name="status"
              testId="licensing-filters-status"
              label={`${rootT}.status`}
              disabled={isDisabled}
              isMultiple
            />
          </Box>
        </Grid>
        <Grid container justify="flex-end">
          <Button onClick={handleClear} size="md" variant="secondary" testId="licensing-filter-clear">
            {t('clearFilters')}
          </Button>
        </Grid>
      </Box>
    </Grid>
  );
};

export default withFormField(FilterForm);
