import { Box, Divider, InputLabel, Skeleton, Typography } from '@mui/material';
import { TypeAhead } from 'components/styleguide';
import { useAtom } from 'jotai';
import { useState } from 'react';
import { searchByProjectIdAndType } from 'redux-core/productions/services';
import { useFetch, useRootTranslation } from 'utils/hooks';
import { getProductionTypeFromDivisionType, TChooseCueSheet } from '.';
import { cueSheetAndParentProductionAtom } from '../drawerStore';
import { AvailableMarketingCueSheetList } from './AvailableMarketingCueSheetList';

export type TCampaign = {
  campaignId: number;
  campaignName: string;
};

export const ChooseMarketingCueSheet = ({
  productionType,
  project,
}: Pick<TChooseCueSheet, 'productionType' | 'project'>) => {
  const [cueSheetAndParentProduction, setCueSheetAndParentProduction] = useAtom(cueSheetAndParentProductionAtom);
  const t = useRootTranslation('drawers.quickSetupProduction.exportSegmentSteps');
  const [marketingCampaign, setMarketingCampaign] = useState(null);
  const [marketingCampaigns, loadingMarketingCampaigns] = useFetch(
    async () =>
      searchByProjectIdAndType({
        projectId: project.id,
        productionType: getProductionTypeFromDivisionType(productionType),
      }),
    [marketingCampaign],
  );

  if (loadingMarketingCampaigns) return <Skeleton />;

  const chooseMarketingCampaign = (campaignId: TCampaign['campaignId']) => {
    setCueSheetAndParentProduction({
      parentProduction: campaignId,
      cueSheetId: null,
    });
    const campaign = marketingCampaigns.find((c) => c.campaignId === campaignId);
    setMarketingCampaign(campaign);
  };

  const setCueSheetId = (cueSheetId: number) => {
    setCueSheetAndParentProduction({
      parentProduction: marketingCampaign.campaignId,
      cueSheetId,
    });
  };

  return (
    <>
      <Box py={1}>
        <InputLabel id="marketing-campaign-dropdown-label">{t('campaign')}</InputLabel>
        <TypeAhead
          isClearable={false}
          options={marketingCampaigns}
          onChange={chooseMarketingCampaign}
          placeholder="Choose a campaign"
          value={marketingCampaign?.campaignId}
          test-id="marketing-campaign-dropdown"
          valueKey="campaignId"
          labelKey="campaignName"
          startAdornment={undefined}
          endAdornment={undefined}
        />
      </Box>
      {marketingCampaign && (
        <>
          <Box py={3}>
            <Divider />
          </Box>
          <Box pb={1}>
            <Typography variant="h6">{t('exportToAssetLabel')}</Typography>
          </Box>
          <AvailableMarketingCueSheetList
            marketingCampaign={marketingCampaign}
            cueSheetId={cueSheetAndParentProduction?.cueSheetId}
            setCueSheetId={setCueSheetId}
          />
        </>
      )}
    </>
  );
};
