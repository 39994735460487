import React from 'react';
import PropTypes from 'prop-types';
import enhancer from './enhancer';
import ProductionModal from './ProductionModal';
import MODALS from './constants';

const modals = {
  [MODALS.PRODUCTION_SETUP_PRODUCTION]: ProductionModal,
};

const Modals = ({ closeModal, open, payload = {} }) =>
  Object.keys(modals).map((modal) => {
    const Component = modals[modal];
    return <Component key={modal} open={open === modal} onClose={closeModal} payload={payload} />;
  });

Modals.propTypes = {
  closeModal: PropTypes.func.isRequired,
  open: PropTypes.string,
  payload: PropTypes.any,
};

export default enhancer(Modals);
