import { Skeleton } from '@mui/material';
import {
  useCreateClearanceStatusMutation,
  useGetClearanceStatusListQuery,
  useMoveClearanceStatusMutation,
  useUpdateClearanceStatusMutation,
} from 'services/clear/clearance-status';
import { CustomStatusItem } from './CustomStatusItem';
import { CustomStatusList } from './CustomStatusList';
import { TCustomStatus } from './types';

export type TClearanceTabProps = {
  divisionId: number;
};

export const ClearanceTab = ({ divisionId }: TClearanceTabProps) => {
  const { data: clearanceStatusList, isLoading } = useGetClearanceStatusListQuery({ divisionId });
  const [createClearanceStatus] = useCreateClearanceStatusMutation();
  const [updateClearanceStatus] = useUpdateClearanceStatusMutation();
  const [moveClearanceStatus] = useMoveClearanceStatusMutation();

  if (isLoading) return <Skeleton />;

  return (
    <CustomStatusList>
      {clearanceStatusList
        .filter((s) => s.isParent)
        .map((status) => {
          const customStatus = {
            ...status,
            childrenStatuses: status.clearanceStatuses as TCustomStatus[],
          };
          return (
            <CustomStatusItem
              key={status.id}
              divisionId={divisionId}
              status={customStatus}
              createCustomStatus={createClearanceStatus}
              updateCustomStatus={updateClearanceStatus}
              moveCustomStatus={moveClearanceStatus}
            />
          );
        })}
    </CustomStatusList>
  );
};
