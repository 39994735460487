/* eslint-disable react/no-children-prop */
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import { concatStrings } from 'utils/index';

const getPercentage = (value) => `${value}%`;

const useStyles = makeStyles((theme) => ({
  root: {
    boxSizing: 'border-box',
    flexGrow: ({ grow }) => grow,
    flexBasis: ({ width }) => getPercentage(width),
    maxWidth: ({ width }) => getPercentage(width),
    paddingLeft: ({ spacing }) => (spacing ? theme.spacing(spacing) : undefined),
    paddingRight: ({ spacing }) => (spacing ? theme.spacing(spacing) : undefined),
  },
}));

const CustomGridItem = ({ children, className, testId, ...props }) => {
  const classes = useStyles(props);
  return (
    <div
      children={children}
      data-testid={concatStrings('-')('grid-item', testId)}
      className={classnames(classes.root, className)}
    />
  );
};

CustomGridItem.propTypes = {
  width: PropTypes.number.isRequired,
  grow: PropTypes.number,
  testId: PropTypes.string,
};

CustomGridItem.defaultProps = {
  grow: 0,
};

export default CustomGridItem;
