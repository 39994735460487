/* eslint-disable react/no-children-prop */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable consistent-return */
import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  FormButton,
  FormDropdown,
  FormImage,
  FormInputFormat,
  FormInputText,
  FormCheckbox,
  FormTypeAhead,
} from 'components/common/Form/Fields';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { BaseDrawer, DrawerHeader, Loading } from 'components/styleguide';
import SectionTitle from 'components/common/SectionTitle';
import SectionRow from 'components/common/SectionRow';
import { useTranslation } from 'react-i18next';
import { useFetch } from 'utils/hooks';
import * as Yup from 'yup';
import {
  getLanguagesCall,
  getVersionsCall,
  getSeasonById,
  getCategoriesCall,
  getCurrenciesCall,
} from 'redux-core/productions/services';
import { getRequiredRights } from 'redux-core/rights/services';
import { getTerritoriesListCall } from 'redux-core/territories/services';
import compose from 'ramda/src/compose';
import find from 'ramda/src/find';
import mergeLeft from 'ramda/src/mergeLeft';
import equals from 'ramda/src/equals';
import prop from 'ramda/src/prop';
import pick from 'ramda/src/pick';
import propOr from 'ramda/src/propOr';
import concat from 'ramda/src/concat';
import __ from 'ramda/src/__';
import propEq from 'ramda/src/propEq';
import { yupTimeHHMMSS, yupRightPresetTerritories } from 'utils/forms';
import { allTruthy } from 'utils/object';
import { TIME_FORMATS } from 'utils/format';
import {
  PRODUCTION_TYPES,
  RATINGS_OPTIONS,
  DISTRIBUTORS,
  NORMALIZED_PRODUCTION_TYPES,
  YEARS_OPTIONS,
} from 'utils/constants';
import {
  SecuredCurrencyPicker,
  SecuredFinancialInputFormat,
  SecuredForm,
  SecuredRightsPresetPicker,
} from 'components/common/Secured';
import { COMMON_ROLES, CLEAR_PERMISSIONS_GROUPS } from 'redux-core/permissions/constants';
import { RIGHTS_HINT_MESSAGE_TYPE } from 'components/common/Drawers/RightPresetDrawer/constants';
import { updateCurrenciesInEntity } from 'utils/currencies';
import InfoIconFilled from '@material-ui/icons/Info';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import useStyles from './styles';
import ItemList from './ItemList';
import { productionInformationItems, personnelInformationItems } from './ItemList/items';
import { CommonDrawerPropTypes } from '../constants';
import enhancer from './enhancer';
import DrawerFormContentGrid from '../DrawerFormContentGrid';

const rootT = 'drawers.productionSetup.release';

const FieldRow = ({ isLast, children }) => <Box mb={isLast ? 6.2 : 2.5}>{children}</Box>;

const initialValues = {
  distributors: [],
  networks: [],
};

const validationSchema = Yup.object().shape({
  masterCurrencyId: Yup.number().required('global.forms.validations.required'),
  cueSheetPreparedByEmail: Yup.string().nullable().email('global.forms.validations.types.email'),
  languageId: Yup.number().nullable(),
  name: Yup.string().required('global.forms.validations.required'),
  versionId: Yup.number().nullable(),
  territories: yupRightPresetTerritories(),
  episodeDuration: yupTimeHHMMSS,
  requiredRights: Yup.object()
    .shape({
      name: Yup.string(),
      rights: yupRightPresetTerritories(),
    })
    .nullable(),
});

const ReleaseDrawer = ({
  name,
  onClose,
  getSeasonRelease,
  saveSeasonRelease,
  updateSeasonRelease,
  keepReleaseInfo,
  divisionId,
  tenantId,
  payload: { id, hideSaveAndAddEpisode, ...payload },
  release: { secondaryOpened: _, ...releaseRaw },
  savePosterId,
  rights,
}) => {
  const release = {
    ...releaseRaw,
    dirty: releaseRaw.dirty || Boolean(rights),
  };
  const { t } = useTranslation();
  const classes = useStyles();
  const getDefaultValue = compose(prop('id'), find(propEq('code', 'USD')));
  const [languages, loading1] = useFetch(getLanguagesCall);
  const [currencies, loading2] = useFetch(getCurrenciesCall);
  const masterCurrencyId = useMemo(() => getDefaultValue(currencies), [getDefaultValue, currencies]);
  const [territories, loading3] = useFetch(() => divisionId && getTerritoriesListCall({ divisionId }), [divisionId]);

  const [versions, loading4] = useFetch(getVersionsCall);
  const [categories, loading5] = useFetch(getCategoriesCall);
  const [distributorsOptions, setDistributors] = useState([]);
  const seasonId = prop('seasonId', release) || prop('seasonId', payload);

  const [requiredRightsRaw, loadingRights] = useFetch(
    () => id && !rights && getRequiredRights({ subprojectId: id }),
    [id, rights],
    null,
  );

  const [parentSeasonRequiredRightsRaw] = useFetch(
    () => !id && seasonId && !rights && getRequiredRights({ subprojectId: seasonId }),
    [seasonId, rights, id],
    null,
  );

  const requiredRights = rights || requiredRightsRaw || parentSeasonRequiredRightsRaw || {};

  useFetch(() => id && getSeasonRelease({ id }), [id], {});

  const handleRatingChange = (value, setFieldValue, { dirty }) => {
    setDistributors(DISTRIBUTORS(value, true));
    if (dirty) {
      const descriptors = DISTRIBUTORS(value, false);
      if (descriptors.length === 1) {
        setFieldValue('descriptors', descriptors);
      } else {
        setFieldValue('descriptors', []);
      }
    }
  };

  const [parentSeason, loading6] = useFetch(
    () => {
      const getSeasonBody = {
        divisionId,
        id: seasonId,
        tenantId,
      };
      return allTruthy(getSeasonBody) && getSeasonById(getSeasonBody);
    },
    [seasonId],
    {},
  );

  /* Using useMemo here because I don't want the object to be re-created
  on each render, as it will reinitialize the form */
  const initialDefaultValues = useMemo(
    () =>
      !id && {
        ...pick(
          [
            'masterCurrencyId',
            'cueSheetPreparedByName',
            'cueSheetPreparedByLastName',
            'cueSheetPreparedByEmail',
            'cueSheetPreparedByPhone',
            'rating',
            'descriptors',
            'animated',
            'description',
            'productionYear',
            'episodeDuration',
            'versionId',
            'categoryId',
            'languageId',
            'territoryId',
            'isanId',
            'eidrContentId',
          ],
          parentSeason || {},
        ),
        additionalData: {
          ...pick(
            [
              'distributors',
              'networks',
              'productionEntities',
              'keyPersonnel',
              'directors',
              'producers',
              'editors',
              'actors',
            ],
            propOr({}, 'additionalData', parentSeason),
          ),
        },
      },
    [parentSeason, id],
  );

  const currencySymbol = useMemo(
    () =>
      compose(
        concat(__, ' '),
        propOr('$', 'symbol'),
        find(compose(equals(prop('masterCurrencyId', parentSeason)), prop('id'))),
      )(currencies),
    [currencies, parentSeason],
  );

  const onCancel = () => {
    onClose();
  };

  const handleSubmit = (addEpisode) => (values) => {
    if (id) {
      return updateSeasonRelease(values, {
        addEpisode,
      });
    }
    saveSeasonRelease(values, addEpisode);
  };

  const handleSecondarySubmit = async (values) => {
    const additionalData = compose(mergeLeft(values), propOr({}, 'additionalData'))(release);
    return updateSeasonRelease(
      { additionalData },
      {
        addEpisode: false,
        continueEditing: true,
        patch: true,
      },
    );
  };

  const handleCurrencySubmit = async (currencies) =>
    updateCurrenciesInEntity(currencies, updateSeasonRelease, {
      addEpisode: false,
      continueEditing: true,
      patch: true,
    });

  if (loading1 || loading2 || loading3 || loading4 || loading5 || loading6 || loadingRights) return <Loading />;

  const requiredPerms = {
    objectId: release.objectId,
    permissions: COMMON_ROLES.EDITOR,
  };

  return (
    <SecuredForm
      drawerName={name}
      enableReinitialize
      initialValues={{
        masterCurrencyId,
        ...initialValues,
        ...initialDefaultValues,
        ...payload,
        ...release,
        requiredRights,
      }}
      onSubmit={handleSubmit(true)}
      validationSchema={validationSchema}
      {...requiredPerms}
    >
      <DrawerHeader title={t(`${rootT}.${id ? 'drawerEditTitle' : 'drawerTitle'}`)} onClose={onClose}>
        <FormButton
          alwaysEnabled
          testId="season-release-drawer-cancel"
          variant="destructive"
          size="xs"
          children="global.forms.cancel"
          type="button"
          onClick={() => onCancel()}
        />
        <FormButton
          testId="season-release-drawer-save"
          variant="primary"
          size="xs"
          children="global.forms.save"
          type="button"
          onClick={handleSubmit(false)}
        />
        {!hideSaveAndAddEpisode && (
          <FormButton
            testId="season-release-drawer-save-and-add-episode"
            variant="primary"
            type="submit"
            size="md"
            children={`${rootT}.saveAndAddEpisode`}
          />
        )}
      </DrawerHeader>
      <DrawerFormContentGrid container spacing={3} className={classes.scrollable}>
        <Grid item xs={3}>
          <FormImage
            name="posterId"
            resizingOptions={{ width: 128, height: 188 }}
            type={NORMALIZED_PRODUCTION_TYPES.TV}
            onUpload={(id) => {
              savePosterId({
                type: PRODUCTION_TYPES.SEASON_RELEASE,
                posterId: id,
              });
            }}
          />
        </Grid>
        <Grid item xs={9}>
          <Grid item>
            <Grid item xs={8}>
              <SectionTitle title={t(`${rootT}.releaseDetails`)} />
              <SectionRow isLast>
                <FormInputText
                  label={`${rootT}.releaseTitle`}
                  placeholder={`${rootT}.releasePlaceholder`}
                  name="name"
                  required
                  endAdornment={
                    <Tooltip title={t('drawers.quickSetupProduction.releaseToolTip')} placement="bottom-start">
                      <IconButton size="small" color="primary">
                        <InfoIconFilled />
                      </IconButton>
                    </Tooltip>
                  }
                />
              </SectionRow>
            </Grid>
          </Grid>

          <Grid item>
            <SectionTitle title={t(`${rootT}.defaultRights`)} />
            <SectionRow isLast>
              <SecuredRightsPresetPicker
                divisionId={divisionId}
                onAdditionalRightsPresetDrawerOpen={(release) => keepReleaseInfo({ ...release, secondaryOpened: true })}
                formId={PRODUCTION_TYPES.RELEASE}
                {...requiredPerms}
                clearPermissions={CLEAR_PERMISSIONS_GROUPS.RIGHTS}
                saveInDrawerName={name}
                hintType={RIGHTS_HINT_MESSAGE_TYPE.SEASON_RELEASE}
                initialRights={requiredRights}
              />
            </SectionRow>
          </Grid>

          <Grid item>
            <SectionTitle title={t(`${rootT}.currency`)} />
            <Grid item xs={9} spacing={2} alignItems="center">
              <SectionRow isLast>
                <SecuredCurrencyPicker
                  disabled={Boolean(id)}
                  currencies={currencies}
                  onSaveCurrencies={handleCurrencySubmit}
                  onAdditionalCurrencyClick={(release) => keepReleaseInfo({ ...release, secondaryOpened: true })}
                  {...requiredPerms}
                />
              </SectionRow>
            </Grid>
          </Grid>

          <Grid item>
            <SectionTitle title={t(`${rootT}.preparedBy.title`)} />
            <Grid item xs={8}>
              <SectionRow>
                <FormInputText label={`${rootT}.preparedBy.firstName`} name="cueSheetPreparedByName" />
              </SectionRow>
              <SectionRow>
                <FormInputText label={`${rootT}.preparedBy.lastName`} name="cueSheetPreparedByLastName" />
              </SectionRow>
              <SectionRow>
                <FormInputText label={`${rootT}.preparedBy.email`} name="cueSheetPreparedByEmail" />
              </SectionRow>
              <SectionRow isLast>
                <FormInputText label={`${rootT}.preparedBy.phone`} name="cueSheetPreparedByPhone" />
              </SectionRow>
            </Grid>
          </Grid>

          <Grid item>
            <SectionTitle title={t(`${rootT}.defaultRating`)} />
            <Grid item xs={8}>
              <SectionRow>
                <FormDropdown
                  label={t(`${rootT}.rating`)}
                  name="rating"
                  options={RATINGS_OPTIONS}
                  placeholder="global.forms.select"
                />
              </SectionRow>
              <SectionRow isLast>
                <FormDropdown
                  disabled={!distributorsOptions.length}
                  isMultiple
                  label={t(`${rootT}.ratingDescriptor`)}
                  name="descriptors"
                  options={distributorsOptions}
                  placeholder="global.forms.select"
                  subscribeAction={handleRatingChange}
                  subscribeToField="rating"
                />
              </SectionRow>
            </Grid>
          </Grid>

          <Grid item>
            <SectionTitle title={t(`${rootT}.defaultAdditionalInformation`)} />
            <Grid item xs={8}>
              <SectionRow>
                <FormInputText label={`${rootT}.description`} name="description" textArea />
              </SectionRow>
              <SectionRow>
                <Grid container spacing={1}>
                  <Grid item>
                    <Box width={120}>
                      <FormTypeAhead
                        label={t(`${rootT}.productionYear`)}
                        name="productionYear"
                        options={YEARS_OPTIONS}
                      />
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box width={120}>
                      <FormInputFormat
                        format={TIME_FORMATS.HHMMSS}
                        label={`${rootT}.duration`}
                        name="episodeDuration"
                        type="time"
                        textAlign="left"
                      />
                    </Box>
                  </Grid>
                </Grid>
              </SectionRow>
              <SectionRow>
                <SecuredFinancialInputFormat
                  label={`${rootT}.episodeBudget`}
                  name="defaultEpisodeBudget"
                  objectId={release.objectId}
                  prefix={currencySymbol}
                  type="currency"
                  textAlign="left"
                />
              </SectionRow>
              <SectionRow>
                <FormTypeAhead
                  label={`${rootT}.version`}
                  name="versionId"
                  options={versions}
                  placeholder="global.forms.select"
                />
              </SectionRow>
              <SectionRow>
                <FormTypeAhead
                  label={`${rootT}.category`}
                  name="categoryId"
                  options={categories}
                  placeholder="global.forms.select"
                />
              </SectionRow>
              <SectionRow>
                <FormTypeAhead
                  label={`${rootT}.language`}
                  name="languageId"
                  options={languages}
                  placeholder="global.forms.select"
                />
              </SectionRow>
              <SectionRow>
                <FormTypeAhead
                  label={`${rootT}.territory`}
                  name="territoryId"
                  options={territories}
                  placeholder="global.forms.select"
                />
              </SectionRow>
              <SectionRow>
                <FormCheckbox name="animated" label={t(`${rootT}.animated`)} />
              </SectionRow>
              <SectionRow>
                <FormInputFormat
                  label={t(`${rootT}.releaseNumber`)}
                  name="releaseNumber"
                  type="plain"
                  textAlign="left"
                />
              </SectionRow>
              <SectionRow>
                <FormInputText label={`${rootT}.eidr`} name="eidrContentId" />
              </SectionRow>
              <SectionRow isLast>
                <FormInputText label={`${rootT}.isan`} name="isanId" />
              </SectionRow>
            </Grid>
          </Grid>

          <Grid item>
            <SectionTitle title={t(`${rootT}.defaultProductionInformation`)} />
            <SectionRow isLast>
              <ItemList setItems={productionInformationItems} onSubmit={handleSecondarySubmit} {...requiredPerms} />
            </SectionRow>
          </Grid>

          <Grid item className={classes.sectionWrapper}>
            <Grid item className={classes.section}>
              <SectionTitle title={t(`${rootT}.defaultPersonnel`)} />
              <ItemList setItems={personnelInformationItems} onSubmit={handleSecondarySubmit} {...requiredPerms} />
            </Grid>
          </Grid>
        </Grid>
      </DrawerFormContentGrid>
    </SecuredForm>
  );
};

FieldRow.propTypes = {
  isLast: PropTypes.bool,
  children: PropTypes.any.isRequired,
};

ReleaseDrawer.propTypes = {
  name: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  getSeasonRelease: PropTypes.func.isRequired,
  saveSeasonRelease: PropTypes.func.isRequired,
  updateSeasonRelease: PropTypes.func.isRequired,
  keepReleaseInfo: PropTypes.func.isRequired,
  divisionId: PropTypes.number,
  payload: PropTypes.object,
  hideSaveAndAddEpisode: PropTypes.bool,
  release: PropTypes.object,
  tenantId: PropTypes.number.isRequired,
  savePosterId: PropTypes.func.isRequired,
  rights: PropTypes.shape({}),
};
const Drawer = ({ open, cleanSetup, closeAllDialogs, ...props }) => {
  const handleClose = async () => {
    await closeAllDialogs(true);
    await cleanSetup();
  };
  return (
    <BaseDrawer open={open} onClose={handleClose}>
      <ReleaseDrawer onClose={handleClose} {...props} />
    </BaseDrawer>
  );
};

Drawer.propTypes = CommonDrawerPropTypes;

export default enhancer(Drawer);
