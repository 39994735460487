import { compose } from 'redux';
import { connect } from 'react-redux';
import { saveSeasonRelease, keepPersonnelInfo, keepPersonnelForm } from 'redux-core/productions/actions';
import { openDrawer } from 'redux-core/dialogs/actions';
import { getTenantId, getDivisionId, getSetupFlowKeyPersonnelListSelector } from 'redux-core/productions/selectors';
import withStyles from './styles';

const mapStateToProps = (state) => ({
  personnelList: getSetupFlowKeyPersonnelListSelector(state),
  setupInfo: {
    tenantId: getTenantId(state),
    divisionId: getDivisionId(state),
  },
});

const mapDispatchToProps = {
  saveSeasonRelease,
  openDrawer,
  keepPersonnelInfo,
  keepPersonnelForm,
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles);
