/* eslint-disable import/no-cycle */
import { Loading, Navbar, Topbar, UserData } from 'components/styleguide';
import T from 'ramda/src/T';
import cond from 'ramda/src/cond';
import equals from 'ramda/src/equals';
import Grid from '@material-ui/core/Grid';
import React, { Suspense } from 'react';
import { getDownloadUrlCall } from 'redux-core/images/services';
import { HOMEPAGE } from 'components/Routes';
import { useFetch } from 'utils/hooks';
import Logo from 'assets/qwire-logo.svg';
import PropTypes from 'prop-types';
import TopBarSlot from 'components/TopBarSlot';
import UserMenu from 'components/UserMenu';
import ROUTES from 'components/Routes/routes';
import propOr from 'ramda/src/propOr';
import enhancer from './enhancer';
import useStyles from './styles';

const ViewsContainer = ({
  children,
  getUserProfile,
  goToLink,
  location,
  openDocument360Tab,
  isAuthenticated,
  setMenuOpen,
  isMenuOpen,
  isQwireEmployee,
  ...styleProps
}) => {
  const classes = useStyles(styleProps);
  const [user] = useFetch(getUserProfile, [], {});
  const [profileImageUrl] = useFetch(() => getDownloadUrlCall(user.imageId), [user.imageId], null);

  return (
    <Grid container className={classes.root} direction="column">
      <Topbar
        brand={<img src={Logo} alt="qwire" />}
        onLogoClick={() => goToLink(HOMEPAGE)}
        slot={<TopBarSlot location={location} isAuthenticated={isAuthenticated} />}
        userMenu={<UserMenu user={user} profileImage={profileImageUrl} />}
        profileAvatar={
          <UserData
            displayName={propOr('', 'firstName', user)}
            profileImageUrl={profileImageUrl}
            isQwireEmployee={isQwireEmployee}
          />
        }
        setMenuOpen={setMenuOpen}
        isMenuOpen={isMenuOpen}
      />
      <div id="dnd-scrollable-container" className={classes.container}>
        <Navbar
          location={location}
          handleClick={cond([
            [equals(ROUTES.HELP), openDocument360Tab],
            [T, goToLink],
          ])}
          setOpen={setMenuOpen}
          open={isMenuOpen}
        />
        <div className={classes.views}>
          <Suspense fallback={<Loading />}>{children}</Suspense>
        </div>
      </div>
    </Grid>
  );
};

ViewsContainer.propTypes = {
  children: PropTypes.any.isRequired,
  getUserProfile: PropTypes.func.isRequired,
  goToLink: PropTypes.func.isRequired,
  openDocument360Tab: PropTypes.func.isRequired,
  location: PropTypes.string.isRequired,
  isAuthenticated: PropTypes.bool,
  setMenuOpen: PropTypes.func.isRequired,
  isMenuOpen: PropTypes.bool,
  isQwireEmployee: PropTypes.bool,
  /** Same aliases from material-ui/core/Box */
  px: PropTypes.number,
  py: PropTypes.number,
  pt: PropTypes.number,
  pb: PropTypes.number,
  pl: PropTypes.number,
  pr: PropTypes.number,
  p: PropTypes.number,
  padding: PropTypes.number,
};

export default enhancer(ViewsContainer);
