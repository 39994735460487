/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import path from 'ramda/src/path';

function SingleValue(props) {
  const displayValue = path(['selectProps', 'extraProps', 'displayValue'])(props);
  return (
    <Typography
      className={props.selectProps.classes.singleValue}
      noWrap
      data-testid="typeahead-single-value"
      {...props.innerProps}
    >
      {displayValue ? displayValue(props.selectProps.value) : props.children}
    </Typography>
  );
}

SingleValue.propTypes = {
  /**
   * The children to be rendered.
   */
  children: PropTypes.node,
  /**
   * Props passed to the wrapping element for the group.
   */
  innerProps: PropTypes.any,
  selectProps: PropTypes.object.isRequired,
};
export default SingleValue;
