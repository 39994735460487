/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable react/no-children-prop */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-shadow */
import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import identity from 'ramda/src/identity';
import FormikForm from './FormikForm';
import enhancer from './enhancer';
import { CustomFormPropTypes } from './types';

const CustomForm = ({
  drawerName,
  initialValues,
  validationSchema,
  onSubmit,
  children,
  promptMessage,
  isDrawerFormDirty,
  isDrawerFormSubmitted,
  setDrawerDirty,
  onConfirm,
  alwaysRenderPrompt,
  /** setIsDirty is meant to sync the form lifecycle on the dirty prop to a state in the parent */
  setIsDirty,
  readOnly,
  testId,
  disablePrompt,
  ...props
}) => (
  <Formik
    initialValues={initialValues}
    validationSchema={validationSchema}
    isInitialValid={({ validationSchema, initialValues }) =>
      (initialValues.dirty || isDrawerFormDirty) && validationSchema && validationSchema.isValidSync(initialValues)
    }
    onSubmit={async (values, actions) => {
      await setDrawerDirty({
        name: drawerName,
        dirty: false,
        submitted: true,
      });
      try {
        await onSubmit(values, actions);
      } catch (e) {
        await setDrawerDirty({
          name: drawerName,
          dirty: true,
          submitted: false,
        });
      }
      actions.setSubmitting(false);
    }}
    {...props}
  >
    {(formikBag) => (
      <FormikForm
        disablePrompt={disablePrompt}
        drawerName={drawerName}
        onConfirm={onConfirm}
        promptMessage={promptMessage}
        children={children}
        setIsDirty={setIsDirty}
        readOnly={readOnly}
        isDrawerFormDirty={isDrawerFormDirty}
        isDrawerFormSubmitted={isDrawerFormSubmitted}
        alwaysRenderPrompt={alwaysRenderPrompt}
        testId={testId}
        {...formikBag}
      />
    )}
  </Formik>
);

CustomForm.propTypes = {
  children: PropTypes.any,
  ...CustomFormPropTypes,
};

CustomForm.defaultProps = {
  disablePrompt: false,
  initialValues: {},
  onSubmit: () => undefined,
  onConfirm: identity,
  isDrawerFormDirty: identity,
  isDrawerFormSubmitted: identity,
  setDrawerDirty: identity,
};
export default enhancer(CustomForm);
