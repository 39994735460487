/* eslint-disable @typescript-eslint/no-shadow */
import prop from 'ramda/src/prop';
import reduce from 'ramda/src/reduce';
import flatten from 'ramda/src/flatten';
import compose from 'ramda/src/compose';
import add from 'ramda/src/add';
import assoc from 'ramda/src/assoc';
import values from 'ramda/src/values';
import groupBy from 'ramda/src/groupBy';
import pick from 'ramda/src/pick';
import difference from 'ramda/src/difference';
import map from 'ramda/src/map';
import head from 'ramda/src/head';
import { TIME_FORMATS } from 'utils/format';
import dayjs from 'dayjs';

export const getCuePath = ({ cueIndex, rightIndex }) => `rights.${rightIndex}.cues.${cueIndex}`;

const getActualUsage = ({ requestedUseDuration, requestedUseType }) =>
  [requestedUseDuration && dayjs(requestedUseDuration, TIME_FORMATS.HHMMSS).format(TIME_FORMATS.MMSS), requestedUseType]
    .filter(Boolean)
    .join(' - ');

const mergeCues = map((cueList) => {
  /**
   * Since they're all the same Cue (but with different usages),
   * take the first cue as a reference for all the cue values
   * and then map every cue to join all the usages into a { usage } prop
   * */
  const firstCue = head(cueList);
  return {
    ...firstCue,
    usage: cueList.map(getActualUsage).join(', '),
  };
});

export const getCuesFromRights = compose(
  mergeCues,
  values,
  groupBy(prop('cueContainerId')),
  /** Flat array of arrays */
  flatten,
  (rights = []) =>
    rights.reduce(
      (acc, right, rightIndex) => [
        ...acc,
        /* custom prop inputName inside a cue is used to build formik Input name path */
        ...right.cues.map((cue, cueIndex) => assoc('inputName', getCuePath({ cueIndex, rightIndex }), cue)),
      ],
      [],
    ),
);

/**
 * Get a dictionary of Cues by CueContainerId
 * @param {object} Clearance
 */
export const getCueRows = compose(getCuesFromRights, prop('rights'));

/**
 * Returns a list of cueContainers to refetch.
 * Those are the ones for which the allocation field has changed
 * @param {object} initialValues
 * @param {object} values
 */
export const getCueContainersToRefetch = (initialValues, values) => {
  const mapAllocations = compose(map(pick(['allocation', 'cueContainerId'])), getCueRows);
  return map(prop('cueContainerId'))(difference(mapAllocations(initialValues), mapAllocations(values)));
};

/**
 * Formik Validations
 */
const sumByProp = (propName) => (rights) => {
  const cues = getCuesFromRights(rights);
  const sum = reduce((acc, cue) => add(acc, prop(propName, cue)), 0, cues);
  return sum;
};

export const validateMaxAllocation =
  (rights) =>
  (max, prop = 'allocation') => {
    const sum = sumByProp(prop)(rights);
    return !(sum > max);
  };

export const validateMinAllocation =
  (rights) =>
  (min, prop = 'allocation') => {
    const sum = sumByProp(prop)(rights);
    return !(sum < min);
  };
