import { withStyles } from '@material-ui/core';

const styles = (theme) => ({
  container: {
    padding: theme.spacing(0.5, 7, 0.5, 2.75),
    '&:hover': {
      backgroundColor: `${theme.palette.action.hover} !important`,
      cursor: 'pointer',
    },
  },
  breadcrumb: {
    alignItems: 'center',
    display: 'flex',
    marginBottom: theme.spacing(0.75),
    '& > :first-child': {
      marginRight: theme.spacing(0.75),
    },
  },
  titleWithClearance: {
    maxWidth: 350,
  },
});

export default withStyles(styles);
