/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { Text } from 'components/styleguide';
import { appColors } from 'components/styleguide/theme';
import { useRootTranslation } from 'utils/hooks';
import classnames from 'classnames';
import useStyles from './styles';

const rootT = 'charts.budget';
const SIZES = ['small', 'medium'];

const allowedColors = {
  customGreen: '#219653',
  ...appColors,
};

const getWidth = (total, value) => {
  if (!value) return 0;
  const percentage = value / (total || 1);
  return Math.min(Math.max(0, percentage), 1) * 100;
};

function Bar({ color, size, children, outlined, total, value, notGhosted, disabled }) {
  const t = useRootTranslation(rootT);
  const width = getWidth(total, value);
  const classes = useStyles({
    notGhosted,
    color: allowedColors[color],
    size,
    width,
  });
  if (!width || disabled) return '--';

  const isOverTotal = value > total;

  return (
    <Grid container className={classnames(classes.barWrapper, size)} data-testid="chart-bar-wrapper">
      <Grid
        container
        justify="space-between"
        alignItems="center"
        className={classnames(classes.bar, size, {
          outlined,
        })}
        data-testid="chart-bar"
      >
        {children}
        {isOverTotal && (
          <Grid container alignItems="center" className={classes.overLabel}>
            <Box display="flex" mr={0.625}>
              <Text variant="tiny" bold>
                {t('over').toUpperCase()}
              </Text>
            </Box>
            <AddCircleIcon className={classes.overIcon} />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

Bar.propTypes = {
  notGhosted: PropTypes.bool,
  color: PropTypes.oneOf(Object.keys(allowedColors)),
  outlined: PropTypes.bool,
  size: PropTypes.oneOf(SIZES),
  total: PropTypes.number,
  children: PropTypes.any,
  value: PropTypes.number,
};

Bar.defaultProps = {
  notGhosted: false,
  size: 'small',
  total: 0,
  value: 0,
};

export default Bar;
