/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { List, ListItem, MenuItem, Popover } from '@mui/material';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { Text } from 'components/styleguide';
import { concatStrings } from 'utils/index';
import useStyles from './styles';

const OptionItem = ({
  closeMenu,
  itemHeight,
  option: { action, disabled, display, description, icon },
  parentDisabled,
  shrink,
  testId,
}) => {
  const classes = useStyles({ itemHeight, shrink });

  const ItemBody = description ? (
    <ListItem>
      <Text variant="body1" color="dark">
        {display}
      </Text>
      <Text variant="body2" color="grey">
        {description}
      </Text>
    </ListItem>
  ) : (
    <Text variant="body1" color="dark">
      {display}
    </Text>
  );

  const buttonBaseClass = description ? classes.actionButtonWithDesc : classes.actionButtonWithoutDesc;

  return (
    <MenuItem
      disabled={parentDisabled || disabled || !action}
      onClick={() => {
        closeMenu();
        action();
      }}
      className={classnames(classes.actionButtonBase, buttonBaseClass)}
      data-testid={concatStrings('-')('option-item', testId).split(' ').join('')}
    >
      {icon}
      {ItemBody}
    </MenuItem>
  );
};

OptionItem.propTypes = {
  closeMenu: PropTypes.func,
  itemHeight: PropTypes.number,
  option: PropTypes.shape({
    action: PropTypes.func,
    disabled: PropTypes.bool,
    display: PropTypes.string,
    icon: PropTypes.node,
  }),
  parentDisabled: PropTypes.bool,
  shrink: PropTypes.bool,
  testId: PropTypes.string,
};

const MenuPopover = React.memo(({ anchorEl, closeMenu, popoverProps, options, itemHeight, shrink }) => {
  const classes = useStyles({ itemHeight });

  if (!anchorEl) return null;

  return (
    <Popover
      data-testid="action-menu"
      open={Boolean(anchorEl)}
      onClose={closeMenu}
      anchorEl={anchorEl}
      transformOrigin={{ horizontal: 221, vertical: 'top' }}
      TransitionProps={{
        mountOnEnter: true,
        unmountOnExit: true,
      }}
      {...popoverProps}
    >
      <List>
        {options?.map((option) => {
          const { Component, display, subOptions, testId, key } = option;

          if (Component) return <Component closeMenu={closeMenu} />;
          if (subOptions) {
            return (
              <React.Fragment key={display}>
                <Grid container alignItems="center">
                  <Box mx={1}>{option.icon}</Box>
                  <Box mb={0.5}>
                    <Text variant="h6">{display}</Text>
                  </Box>
                </Grid>
                <Box ml={4} className={classes.subOptionsWrapper}>
                  {subOptions.map((subOption) => (
                    <OptionItem
                      key={subOption.display}
                      closeMenu={closeMenu}
                      itemHeight={itemHeight}
                      option={subOption}
                      parentDisabled={option.disabled}
                      testId={subOption.display}
                      subOption
                    />
                  ))}
                </Box>
              </React.Fragment>
            );
          }

          return (
            <OptionItem
              key={`MenuPopover-OptionItem_${key}`}
              closeMenu={closeMenu}
              itemHeight={itemHeight}
              option={option}
              shrink={shrink}
              testId={testId}
            />
          );
        })}
      </List>
    </Popover>
  );
});

MenuPopover.propTypes = {
  anchorEl: PropTypes.any,
  closeMenu: PropTypes.func,
  popoverProps: PropTypes.object,
  options: PropTypes.array.isRequired,
  itemHeight: PropTypes.number,
  shrink: PropTypes.bool,
};

export default MenuPopover;
