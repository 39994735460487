import React from 'react';
import { InputLabel } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { isEmpty, kebabCase } from 'lodash';
import { TPartialRightOwner } from 'services/tracks/types';
import { rootTranslation } from 'utils/format';
import { LicensorRowReadOnly } from './LicensorRowReadOnly';

const t = rootTranslation('drawers.productionSetup.tracks.details');
const tGlobal = rootTranslation('global');

type LicensorsSectionProps = {
  sectionLabel: string;
  items: Array<[TPartialRightOwner, number]>;
};

export function LicensorsSection({ items = [], sectionLabel }: LicensorsSectionProps) {
  if (isEmpty(items)) {
    return null;
  }

  const htmlSectionId = kebabCase(sectionLabel);

  return (
    <Grid
      aria-label={`${sectionLabel} ${tGlobal('section')}`}
      xs={12}
      container
      rowSpacing={1}
      columnSpacing={2}
      sx={{ backgroundColor: 'secondary.300', borderRadius: 1 }}
    >
      <Grid xs={10}>
        <InputLabel id={`${htmlSectionId}-name`}>{sectionLabel}</InputLabel>
      </Grid>
      <Grid xs={2}>
        <InputLabel id={`${htmlSectionId}-share`}>{t('share')}</InputLabel>
      </Grid>
      {items.map(([it]) => (
        <LicensorRowReadOnly key={it.id} item={it} htmlSectionId={htmlSectionId} />
      ))}
    </Grid>
  );
}
