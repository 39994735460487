/* eslint-disable react/no-children-prop */
import { Grid } from '@material-ui/core';
import { Box } from '@mui/system';
import { Text } from 'components/styleguide';
import { NORMALIZED_PRODUCTION_TYPES } from 'utils/constants';
import { rootTranslation } from 'utils/format';
import { getNormalizedProductionType } from 'utils/productions';

import { FeatureDetails } from './FeatureDetails';
import { FeaturesAndMarketingDetailsSplitButton } from './FeaturesAndMarketingDetailsSplitButton';
import { MarketingDetails } from './MarketingDetails';

import { TVDetails } from './TVDetails';
import { TVDetailsSplitButton } from './TVDetailsSplitButton';
import { WatchingToggle } from './WatchingToggle';

const t = rootTranslation('globalSearch.details');

export const ProductionDetails = ({
  cleanSetupFlow,
  selected,
  toggleWatchingProduction,
  refetchWatching,
  goToLink,
  closeAllDialogs,
  keepSetupInfo,
  openModal,
  showInactive,
}) => {
  if (!selected) return null;

  const normalizedProdType = getNormalizedProductionType(selected.productionType);

  const DetailsViewHelper = {
    [NORMALIZED_PRODUCTION_TYPES.FEATURES]: FeatureDetails,
    [NORMALIZED_PRODUCTION_TYPES.MKT]: MarketingDetails,
    [NORMALIZED_PRODUCTION_TYPES.TV]: TVDetails,
  };

  const DetailsView = DetailsViewHelper[normalizedProdType];

  return (
    <Grid container spacing={3} alignItems="center">
      <Grid item xs={4}>
        <Text lineHeight="35px" bold children={t('title')} />
      </Grid>
      <Grid item xs={8} container alignItems="center" justify="flex-end">
        {normalizedProdType === NORMALIZED_PRODUCTION_TYPES.TV && (
          <TVDetailsSplitButton
            cleanSetupFlow={cleanSetupFlow}
            closeAllDialogs={closeAllDialogs}
            goToLink={goToLink}
            keepSetupInfo={keepSetupInfo}
            openModal={openModal}
            selected={selected}
          />
        )}
        {normalizedProdType !== NORMALIZED_PRODUCTION_TYPES.TV && (
          <Box sx={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
            <WatchingToggle
              productionId={selected.productionId}
              toggleWatchingProduction={toggleWatchingProduction}
              refetchWatching={refetchWatching}
            />
            <FeaturesAndMarketingDetailsSplitButton
              cleanSetupFlow={cleanSetupFlow}
              closeAllDialogs={closeAllDialogs}
              goToLink={goToLink}
              keepSetupInfo={keepSetupInfo}
              openModal={openModal}
              normalizedProdType={normalizedProdType}
              selected={selected}
            />
          </Box>
        )}
      </Grid>

      <DetailsView
        selected={selected}
        toggleWatchingProduction={toggleWatchingProduction}
        refetchWatching={refetchWatching}
        goToLink={goToLink}
        closeAllDialogs={closeAllDialogs}
        keepSetupInfo={keepSetupInfo}
        openModal={openModal}
        normalizedProdType={normalizedProdType}
        showInactive={showInactive}
      />
    </Grid>
  );
};
