import { Box, Typography } from '@material-ui/core';
import { Divider, Grid, MenuItem, Select, Skeleton } from '@mui/material';
import { FormControlLabelWrapper } from 'components/common/Form/Fields/FormField/FormField';
import { useSetAtom } from 'jotai';
import { useState } from 'react';
import { getSeasonsSeasonReleasesAndEpisodesCall } from 'redux-core/overview/services';
import { useFetch, useRootTranslation } from 'utils/hooks';
import { TChooseCueSheet } from '.';
import { cueSheetAndParentProductionAtom } from '../drawerStore';
import { AvailableTelevisionCueSheetList } from './AvailableTelevisionCueSheetList';

export type TEpisode = {
  id: number;
  name: string;
  parentId: number;
};

export type TSeasonRelease = {
  id: number;
  name: string;
  parentId: number;
  episodes: TEpisode[];
};

export type TSeason = {
  id: number;
  name: string;
  releases: TSeasonRelease[];
};

export const ChooseEpisodeCueSheet = ({ division, project }: TChooseCueSheet) => {
  const t = useRootTranslation('drawers.quickSetupProduction.exportSegmentSteps');
  const setCueSheetAndParentProduction = useSetAtom(cueSheetAndParentProductionAtom);

  const [selectedSeasonId, setSelectedSeasonId] = useState<number | null>(null);
  const [selectedSeasonReleaseId, setSelectedSeasonReleaseId] = useState<number | null>(null);

  const [seasons, loadingSeasons] = useFetch(async () => {
    const fetchedEntities = await getSeasonsSeasonReleasesAndEpisodesCall(+division.id, +project.id);

    const fetchedEpisodes: TEpisode[] = fetchedEntities
      .filter(({ type }) => type === 'Episode')
      .map(({ id, name, parentId, number }) => ({ id, name, parentId, number }));

    const fetchedSeasonReleases: TSeasonRelease[] = fetchedEntities
      .filter(({ type }) => type === 'SeasonRelease')
      .map(({ id, name, parentId }) => {
        const releaseEpisodes = fetchedEpisodes.filter(({ parentId: episodeParentId }) => episodeParentId === id);
        return { id, name, parentId, episodes: releaseEpisodes };
      });

    const fetchedSeasons: TSeason[] = fetchedEntities
      .filter(({ type }) => type === 'Season')
      .map(({ id, name }) => {
        const seasonSeasonReleases = fetchedSeasonReleases.filter(({ parentId }) => parentId === id);
        return { id, name, releases: seasonSeasonReleases };
      });

    return fetchedSeasons;
  }, [project]);

  if (loadingSeasons) return <Skeleton />;

  const changeSelectedSeason = (seasonId: number) => {
    setCueSheetAndParentProduction(null);
    setSelectedSeasonId(seasonId);
    setSelectedSeasonReleaseId(0);
  };

  const { releases: seasonReleases } = seasons.find((s) => s.id === selectedSeasonId) || { releases: [] };

  const seasonRelease = seasonReleases.find((s) => s.id === selectedSeasonReleaseId);

  return (
    <Grid container gap={2} pt={2}>
      <Grid item xs={3}>
        <FormControlLabelWrapper name="season" label={t('season')}>
          <Select
            name="season"
            data-testid="select-season"
            value={selectedSeasonId}
            onChange={(event) => changeSelectedSeason(event.target.value as number)}
          >
            {seasons.map((option) => (
              <MenuItem key={option.id} value={option.id} data-testid="select-season-option">
                {option.name}
              </MenuItem>
            ))}
          </Select>
        </FormControlLabelWrapper>
      </Grid>
      <Grid item xs={6}>
        <FormControlLabelWrapper name="release" label={t('release')}>
          <Select
            name="release"
            data-testid="select-release"
            value={selectedSeasonReleaseId}
            onChange={(event) => setSelectedSeasonReleaseId(event.target.value as number)}
          >
            {seasonReleases.map((option) => (
              <MenuItem key={option.name} value={option.id} data-testid="select-release-option">
                {option.name}
              </MenuItem>
            ))}
          </Select>
        </FormControlLabelWrapper>
      </Grid>
      {!!selectedSeasonReleaseId && !!seasonRelease && (
        <Grid item xs={12}>
          <Divider />
          <Box pt={2}>
            <Typography variant="h6">{t('exportToLabel')}</Typography>
          </Box>
          <AvailableTelevisionCueSheetList seasonRelease={seasonRelease} />
        </Grid>
      )}
    </Grid>
  );
};
