/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { getStatesByCountry } from 'utils/index';
import { FormTypeAhead } from 'components/common/Form/Fields/index';
import find from 'ramda/src/find';
import propEq from 'ramda/src/propEq';
import { useFormikContext, useField } from 'formik';
import { useFormSubscription } from 'utils/hooks';

const StateField = ({ countries, countryName, name, ...props }) => {
  const [{ value: currentValue }] = useField(name);
  const [{ value: selectedCountry }] = useField(countryName);
  const { dirty } = useFormikContext();

  const getCountryByName = (countries = []) => find(propEq('name', selectedCountry), countries);

  const resetState = (_value, setFieldValue) => setFieldValue(name, '');
  // reset state only in case there are some changes made
  useFormSubscription(countryName, dirty && !currentValue ? resetState : undefined);

  return (
    <FormTypeAhead
      isClearable={false}
      name={name}
      options={getStatesByCountry(getCountryByName(countries))}
      valueKey="name"
      {...props}
    />
  );
};

StateField.propTypes = {
  countries: PropTypes.array,
  name: PropTypes.string,
  countryName: PropTypes.string,
};

StateField.defaultProps = {
  name: 'state',
  countryName: 'countryName',
};

export default StateField;
