import { useCallback, useState, useEffect } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import { Grid, IconButton, OutlinedInput } from '@mui/material';
import { Box } from '@mui/system';
import { rootTranslation } from 'utils/format';

const tGlobal = rootTranslation('global');

type TSearchInputProps = {
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
};

export function SearchInput({ value: searchValue, onChange, placeholder }: TSearchInputProps) {
  const [value, setValue] = useState(searchValue);

  useEffect(() => {
    setValue(searchValue);
  }, [searchValue]);

  const debouncedApiCall = useDebouncedCallback((term: string) => {
    onChange(term);
  }, 300);

  const handleClearInput = useCallback(() => {
    setValue('');
    onChange('');
  }, [onChange]);

  return (
    <Grid sx={{ display: 'flex' }}>
      <OutlinedInput
        sx={{ minWidth: '250px', pr: 0 }}
        fullWidth
        size="small"
        placeholder={placeholder ?? tGlobal('search')}
        value={value}
        onChange={(e) => {
          const term = e.target.value;
          setValue(term);
          debouncedApiCall(term);
        }}
        data-testid="search-input"
        endAdornment={<SearchAdornment value={value} clearValue={handleClearInput} />}
      />
    </Grid>
  );
}

type SearchAdornmentProps = {
  value: string;
  clearValue: () => void;
};

function SearchAdornment({ value, clearValue }: SearchAdornmentProps) {
  return (
    <Box>
      {value ? (
        <IconButton aria-label="clear search" onClick={clearValue}>
          <ClearIcon />
        </IconButton>
      ) : (
        <Box
          sx={{
            p: '0.5rem',
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <SearchIcon aria-hidden="true" data-testid="search-icon" />
        </Box>
      )}
    </Box>
  );
}
