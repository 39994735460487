/* eslint-disable import/no-cycle */
import lensProp from 'ramda/src/lensProp';
import set from 'ramda/src/set';
import { handleActions } from 'redux-actions';
import * as actions from './actions';

const defaultFilter = {
  productionIds: [],
  qwireTracksRightsOwnerIds: [],
  qwireTracksTrackIds: [],
  clearanceStatusList: [],
};

const defaultState = {
  activeDraft: {},
  bundle: false,
  activeStep: 0,
  letters: [],
  selectedLettersForStep2: [],
  selectedLettersForStep3: [],
  filter: defaultFilter,
  filtersMetadata: {},
  emailTemplates: [],
  letterTemplates: [],
  extraAttachments: [],
};

const lettersReducer = {
  [actions.cleanLetters]: () => ({ ...defaultState }),
  [actions.setFilter]: (state, { payload }) => set(lensProp('filter'), { ...defaultFilter, ...payload })(state),
  [actions.setFiltersMetadata]: (state, { payload }) => set(lensProp('filtersMetadata'), payload)(state),
  [actions.setLetters]: (state, { payload }) => set(lensProp('letters'), payload)(state),
  [actions.setLettersIsBundle]: (state, { payload }) => set(lensProp('bundle'), payload)(state),
  [actions.setEmailTemplates]: (state, { payload }) => set(lensProp('emailTemplates'), payload)(state),
  [actions.setLetterTemplates]: (state, { payload }) => set(lensProp('letterTemplates'), payload)(state),
  [actions.setSelectedLettersForStep2]: (state, { payload }) =>
    set(lensProp('selectedLettersForStep2'), payload)(state),
  [actions.setSelectedLettersForStep3]: (state, { payload }) =>
    set(lensProp('selectedLettersForStep3'), payload)(state),
  [actions.setActiveStep]: (state, { payload }) => set(lensProp('activeStep'), payload)(state),
  [actions.setActiveDraft]: (state, { payload }) => set(lensProp('activeDraft'), payload)(state),
};

export default handleActions(lettersReducer, defaultState);
