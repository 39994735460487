import { generateRandomDate } from 'utils/mocks';

const MockClearanceLog = [
  {
    id: 1,
    user: 'Annabelle Melville',
    ipAddress: '192.168.100.101',
    timestamp: generateRandomDate(),
    changes: {
      type: 'Requested Use Type',
      value: 'Other',
    },
  },
  {
    id: 2,
    user: 'Samantha Alvarado',
    ipAddress: '192.168.100.101',
    timestamp: generateRandomDate(),
    changes: {
      type: 'Actual Use Type',
      value: '01:31',
    },
  },
  {
    id: 3,
    user: 'Samantha Alvarado',
    ipAddress: '192.168.100.101',
    timestamp: generateRandomDate(),
    changes: {
      type: 'EMI Film and Television Music Licensor Share %',
      value: '32%',
    },
  },
];

export default MockClearanceLog;
