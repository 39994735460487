/* eslint-disable no-unused-vars */
/* eslint-disable no-return-await */
import { getDownloadUrlCall } from 'redux-core/images/services';
import { requestWithError } from 'utils/request';

const fetchImageUrl = async (id) => await requestWithError(getDownloadUrlCall, id);

const getImagesUrl = () => {
  let cache = {};

  return async (imageIdByUserId) => {
    const request = await Promise.all(
      Object.entries(imageIdByUserId).map(async ([userId, imageId]) => {
        if (cache[userId]) {
          return cache[userId];
        }
        const responseUrl = await fetchImageUrl(imageId);
        return [userId, responseUrl];
      }),
    );

    cache = request.reduce(
      (acc, [userId, responseUrl]) => ({
        ...acc,
        [userId]: responseUrl || null,
      }),
      cache,
    );
    return cache;
  };
};

/* Create Cache in closure */
const getCachedImagesUrl = getImagesUrl();

export const getUserImagesInComments = async (comments = []) => {
  const imageIdByUserId = comments.reduce((acc, { commentCreator: { id, image } }) => ({ ...acc, [id]: image }), {});

  const imageUrlByUserIds = await getCachedImagesUrl(imageIdByUserId);

  return comments.map((comment) => ({
    ...comment,
    commentCreator: {
      ...comment.commentCreator,
      image: imageUrlByUserIds[comment.commentCreator.id] || '',
    },
  }));
};
