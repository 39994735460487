import { useAtomValue, useSetAtom } from 'jotai';
import { useEffect, useState } from 'react';
import { TGetAllLetterTemplatesResponse, useGetAllLetterTemplatesQuery } from 'services/clear/letter-templates';
import { composeLetterAtom } from '../composeLetterStore';

export type TOnDefaultTemplateChange = (defaultTemplateId: number) => void;

export function useGetLetterTemplatesAndSetDefault(onDefaultTemplateChange: TOnDefaultTemplateChange) {
  const [filteredTemplates, setFilteredTemplates] = useState<{
    isLoadingTemplates: boolean;
    filteredTemplates: TGetAllLetterTemplatesResponse;
  }>({
    isLoadingTemplates: false,
    filteredTemplates: undefined,
  });

  const composeLetter = useAtomValue(composeLetterAtom);
  const setComposeLetter = useSetAtom(composeLetterAtom);

  const { data: letterTemplates, isLoading } = useGetAllLetterTemplatesQuery({
    divisionId: composeLetter.divisionId,
  });

  const {
    letterType,
    clearanceBundleLicensor: { type: licensorType },
    letter: { templateId: currentTemplateId },
  } = composeLetter;

  useEffect(() => {
    if (isLoading) {
      setFilteredTemplates({ isLoadingTemplates: true, filteredTemplates: undefined });
      return;
    }
    const templatesFilteredByLetterAndLicensorTypes = letterTemplates?.filter(
      (t) => t.letterType === letterType && t.licensorType === licensorType,
    );

    const defaultTemplate = templatesFilteredByLetterAndLicensorTypes?.find((t) => t.isDefault);

    setFilteredTemplates({
      isLoadingTemplates: false,
      filteredTemplates: templatesFilteredByLetterAndLicensorTypes,
    });

    const skipUpdate = currentTemplateId >= 0;
    if (skipUpdate) return;

    const newTemplateId = defaultTemplate?.id || 0;

    setComposeLetter((prev) => ({
      ...prev,
      letter: {
        ...prev.letter,
        templateId: newTemplateId,
      },
    }));

    onDefaultTemplateChange(newTemplateId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isLoading,
    setComposeLetter,
    letterType,
    licensorType,
    currentTemplateId,
    letterTemplates,
    onDefaultTemplateChange,
  ]);

  return filteredTemplates;
}
