/* eslint-disable react/no-children-prop */
import React from 'react';
import PropTypes from 'prop-types';
import { useRootTranslation } from 'utils/hooks';
import { Row, ButtonIcon } from 'components/styleguide';
import { FormFieldArray, FormInputFormat, FormInputText } from 'components/common/Form/Fields';
import Grid from '@material-ui/core/Grid';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import { LICENSOR_TYPES, RIGHTS_OWNER_ROLE } from 'utils/constants';
import { groupLicensorsByType } from 'utils/licensors';
import inc from 'ramda/src/inc';
import { useField } from 'formik';

const emptyRightOwner = {
  type: '',
  name: '',
  role: RIGHTS_OWNER_ROLE.NONE,
  writerAffiliation: null,
  share: 0,
};

const LicensorsField = ({ name, readOnly }) => {
  const t = useRootTranslation('drawers.cueSheetDetails');

  const [{ value: rightsOwners }] = useField('track.rightsOwners');

  const licensorsByType = groupLicensorsByType(rightsOwners, [LICENSOR_TYPES.MASTER, LICENSOR_TYPES.SYNC], false);

  const licensorIndexes = Object.keys(licensorsByType).reduce(
    (acc, type) => [...acc, ...licensorsByType[type].map((licensor) => licensor.index)],
    [],
  );

  const handleAddLicensor = ({ unshift }, index) => {
    const indexExist = licensorIndexes.includes(index);
    /** Call it recursively until the index doesn't exist */
    if (indexExist) return handleAddLicensor({ unshift }, inc(index));
    return (licensorType) => {
      unshift({
        ...emptyRightOwner,
        index,
        type: licensorType,
      });
    };
  };

  const handleRemoveLicensor = ({ remove }, index) => {
    remove(index);
  };

  return (
    <FormFieldArray name={name}>
      {(fieldArrayProps) =>
        Object.keys(licensorsByType).map((licensorType) => (
          <Row divider pb={2} key={licensorType}>
            {licensorsByType[licensorType].map(({ index, ...licensor }, i, list) => {
              const isLast = list.length - 1 === i;
              return (
                <Row alignItems="flex-end" spacing={2} key={licensor.id}>
                  <Grid item xs={8}>
                    <FormInputText
                      readOnly={readOnly}
                      label={t(`${licensorType}Licensor`)}
                      name={`track.rightsOwners.${index}.name`}
                      testId={`${licensorType}-licensor-dropdown`}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <FormInputFormat
                      readOnly={readOnly}
                      type="percentage"
                      name={`track.rightsOwners.${index}.share`}
                      label={t('share')}
                      max={100}
                      decimalScale={5}
                      testId={`${licensorType}-allocation`}
                    />
                  </Grid>
                  <Grid item container xs={1}>
                    {isLast && !readOnly ? (
                      <ButtonIcon
                        children={<AddCircleIcon />}
                        color="primary"
                        dense
                        onClick={() => handleAddLicensor(fieldArrayProps, index)(licensorType)}
                      />
                    ) : (
                      !readOnly && (
                        <ButtonIcon
                          opacity={0.3}
                          children={<CancelIcon />}
                          color="secondary"
                          dense
                          onClick={() => handleRemoveLicensor(fieldArrayProps, index)}
                        />
                      )
                    )}
                  </Grid>
                </Row>
              );
            })}
          </Row>
        ))
      }
    </FormFieldArray>
  );
};

LicensorsField.propTypes = {
  name: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
};

export default LicensorsField;
