import React from 'react';
import { InputLabel, TextField } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { isEmpty, kebabCase } from 'lodash';
import { TPartialRightOwner } from 'services/tracks/types';
import { rootTranslation } from 'utils/format';

const t = rootTranslation('drawers.productionSetup.tracks.details');
const tGlobal = rootTranslation('global');

type CreatorSectionProps = {
  sectionLabel: string;
  items: Array<[TPartialRightOwner, number]>;
};

export function CreatorSection({ items = [], sectionLabel }: CreatorSectionProps) {
  if (isEmpty(items)) {
    return null;
  }

  const htmlSectionId = kebabCase(sectionLabel);

  return (
    <Grid
      aria-label={`${sectionLabel} ${tGlobal('section')}`}
      xs={12}
      container
      rowSpacing={1}
      columnSpacing={2}
      sx={{ backgroundColor: 'secondary.300', m: 0, borderRadius: 1 }}
    >
      <Grid xs={6} lg={7.5}>
        <InputLabel id={`${htmlSectionId}-name`}>{sectionLabel}</InputLabel>
      </Grid>
      <Grid xs={2} lg={1.5}>
        <InputLabel id={`${htmlSectionId}-role`}>{t('role')}</InputLabel>
      </Grid>
      <Grid xs={2} lg={1.5}>
        <InputLabel id={`${htmlSectionId}-pro`}>{t('pro')}</InputLabel>
      </Grid>
      <Grid xs={2} lg={1.5}>
        <InputLabel id={`${htmlSectionId}-share`}>{t('share')}</InputLabel>
      </Grid>
      {items.map(([it]) => (
        <React.Fragment key={`creator_section-${it.name}`}>
          <Grid xs={6} lg={7.5}>
            <TextField
              inputProps={{ readOnly: true, 'aria-labelledby': `${htmlSectionId}-name` }}
              fullWidth
              value={it.name}
            />
          </Grid>
          <Grid xs={2} lg={1.5}>
            <TextField
              inputProps={{ readOnly: true, 'aria-labelledby': `${htmlSectionId}-role` }}
              fullWidth
              value={it.role ?? ''}
            />
          </Grid>
          <Grid xs={2} lg={1.5}>
            <TextField
              inputProps={{ readOnly: true, 'aria-labelledby': `${htmlSectionId}-pro` }}
              fullWidth
              value={it.writerAffiliation ?? ''}
            />
          </Grid>
          <Grid xs={2} lg={1.5}>
            <TextField
              inputProps={{
                readOnly: true,
                'aria-labelledby': `${htmlSectionId}-share`,
                style: { textAlign: 'right' },
              }}
              fullWidth
              value={`${it.share}%`}
            />
          </Grid>
        </React.Fragment>
      ))}
    </Grid>
  );
}
