/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { Dropzone } from 'components/styleguide/fields/index';

const FormDropzone = ({ field, form, fileFieldSize, fileFieldName, onDrop, ...props }) => {
  const handleDropCallback = async (file, reader) => {
    form.setFieldValue(field.name, file.name);
    form.setFieldValue(fileFieldSize, file.size);
    form.setFieldValue(fileFieldName, file.id || file[fileFieldName]);

    if (onDrop) onDrop(file, reader);
  };

  const handleIsLoading = (value) => {
    form.setFieldValue('isParentLoading', value);
    form.setFieldTouched('isParentLoading', value);
  };

  return (
    <Dropzone
      {...props}
      onDrop={handleDropCallback}
      fileFieldName={fileFieldName}
      setIsParentLoading={handleIsLoading}
    />
  );
};

const FormikInput = (props) => <Field component={FormDropzone} {...props} />;

FormikInput.displayName = 'FormDropzone';

FormDropzone.defaultProps = {
  fileFieldName: 'uploadedFileId',
  fileFieldSize: 'fileSize',
};

FormDropzone.propTypes = {
  field: PropTypes.object,
  form: PropTypes.object,
  fileFieldName: PropTypes.string.isRequired,
  fileFieldSize: PropTypes.string.isRequired,
  accept: PropTypes.array,
  labelText: PropTypes.string,
  uploadText: PropTypes.node,
  onDrop: PropTypes.func,
  children: PropTypes.node,
  rounded: PropTypes.bool,
};

export default FormikInput;
