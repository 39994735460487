/* eslint-disable import/no-cycle */
import { connect } from 'react-redux';
import {
  isStep2EmptyQueueSelector,
  isStep3EmptyQueueSelector,
  getLettersActiveStepSelector,
  getLettersIsBundleSelector,
  getSelectedLettersForStep3,
} from 'redux-core/letters/selectors';
import { setActiveStep } from 'redux-core/letters/actions';

const mapStateToProps = (state) => ({
  activeStep: getLettersActiveStepSelector(state),
  isBundle: getLettersIsBundleSelector(state),
  isStep2EmptyQueue: isStep2EmptyQueueSelector(state),
  isStep3EmptyQueue: isStep3EmptyQueueSelector(state),
  letters: getSelectedLettersForStep3(state),
});

const mapDispatchToProps = {
  setActiveStep,
};

export default connect(mapStateToProps, mapDispatchToProps);
