import { connect } from 'react-redux';
import { setAdvancedSearchFilters, setSearchItemDetails } from 'redux-core/header/actions';
import { getAdvancedSearchFiltersSelector } from 'redux-core/header/selectors';

const mapDispatchToProps = {
  setAdvancedSearchFilters,
  setSearchItemDetails,
};

const mapStateToProps = (state) => ({
  advancedFilters: getAdvancedSearchFiltersSelector(state),
});

export default connect(mapStateToProps, mapDispatchToProps);
