import { move } from 'ramda';
import { baseApi } from 'services';

export type TCueSheetStatusRequest = {
  withAll?: boolean;
  divisionId?: number;
};

export type TCueSheetStatus = {
  id: number;
  precedence: number;
  listingOrder: number;
  isParent: boolean;
  parentId?: number;
  label: string;
  color: string;
  cueSheetStatuses?: TCueSheetStatus[];
  divisionId?: number;
};

export type TMoveCueSheetStatus = {
  divisionId: number;
  parentId: number;
  movedId: number;
  targetId: number;
};

const oneDayCache = 60 * 60 * 24;

export const cueSheetStatusApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    createCueSheetStatus: builder.mutation<Partial<TCueSheetStatus>, Partial<TCueSheetStatus>>({
      query: (body) => ({ url: 'cue/cue-sheet-status/create', method: 'POST', body }),
      invalidatesTags: ['cueSheetStatusList'],
    }),
    updateCueSheetStatus: builder.mutation<Partial<TCueSheetStatus>, Partial<TCueSheetStatus>>({
      query: (body) => ({ url: 'cue/cue-sheet-status/update', method: 'POST', body }),
      invalidatesTags: ['cueSheetStatusList'],
    }),
    moveCueSheetStatus: builder.mutation<void, TMoveCueSheetStatus>({
      query: (body) => ({ url: 'cue/cue-sheet-status/move', method: 'POST', body }),
      async onQueryStarted(moveDto, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          cueSheetStatusApi.util.updateQueryData(
            'getCueSheetStatusList',
            { divisionId: moveDto.divisionId },
            (draft) => {
              const parent = draft.find((status) => status.id === moveDto.parentId);
              const from = parent.cueSheetStatuses.findIndex((status) => status.id === moveDto.movedId);
              const to = parent.cueSheetStatuses.findIndex((status) => status.id === moveDto.targetId);
              parent.cueSheetStatuses = move(from, to, parent.cueSheetStatuses);
            },
          ),
        );
        queryFulfilled.catch(patchResult.undo);
      },
    }),
    getCueSheetStatusList: builder.query<TCueSheetStatus[], TCueSheetStatusRequest>({
      query: (body) => ({ url: 'cue/cue-sheet-status/get-all', method: 'POST', body }),
      keepUnusedDataFor: oneDayCache,
      providesTags: ['cueSheetStatusList'],
      transformResponse: (cueSheetStatusList: TCueSheetStatus[]) =>
        cueSheetStatusList.flatMap((status) => {
          if (!status.cueSheetStatuses) return status;
          return [status, ...status.cueSheetStatuses];
        }),
    }),
  }),
});

export const {
  useGetCueSheetStatusListQuery,
  useCreateCueSheetStatusMutation,
  useUpdateCueSheetStatusMutation,
  useMoveCueSheetStatusMutation,
} = cueSheetStatusApi;
