/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import SearchIcon from '@material-ui/icons/Search';

import { ButtonBase } from '@material-ui/core';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import useStyles from './styles';

const Search = (props) => {
  const { customProps, onClick, size } = props;
  const classes = useStyles({ size });
  return (
    <ButtonBase {...customProps} onClick={onClick} className={classnames(classes.button, customProps.className)}>
      <SearchIcon />
    </ButtonBase>
  );
};

Search.propTypes = {
  onClick: PropTypes.func,
  customProps: PropTypes.object,
  size: PropTypes.number.isRequired,
};

Search.defaultProps = {
  customProps: {},
  size: 17.5,
};

export default Search;
