/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import Tune from '@material-ui/icons/Tune';
import { ButtonBase } from '@material-ui/core';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import useStyles from './styles';

const Filter = (props) => {
  const { customProps, onClick, size } = props;
  const classes = useStyles({ size });

  return (
    <ButtonBase {...customProps} onClick={onClick} className={classnames(classes.button, customProps.className)}>
      <Tune color="primary" />
    </ButtonBase>
  );
};

Filter.propTypes = {
  onClick: PropTypes.func,
  customProps: PropTypes.object,
  size: PropTypes.number.isRequired,
};

Filter.defaultProps = {
  customProps: {},
  size: 18,
};

export default Filter;
