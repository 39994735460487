import { TProject } from 'services/common/projects';
import { ChooseEpisodeCueSheet } from './ChooseEpisodeCueSheet';
import { ChooseFeatureCueSheet } from './ChooseFeatureCueSheet';
import { ChooseMarketingCueSheet } from './ChooseMarketingCueSheet';

export type TDivision = {
  id: string;
  name: string;
  type: 'marketing' | 'television' | 'features';
};

export type TChooseCueSheet = {
  division: TDivision;
  project: TProject;
  productionType?: string;
};

export const ChooseCueSheet = ({ division, productionType, project }: TChooseCueSheet) => {
  if (productionType === 'marketing') {
    return <ChooseMarketingCueSheet productionType={productionType} project={project} />;
  }
  if (productionType === 'features') {
    return <ChooseFeatureCueSheet productionType={productionType} project={project} />;
  }
  if (productionType === 'television') {
    return <ChooseEpisodeCueSheet division={division} project={project} />;
  }
  return null;
};

export const getProductionTypeFromDivisionType = (divisionType: string) => {
  switch (divisionType) {
    case 'marketing':
      return 'campaign_campaign';
    case 'television':
      return 'series_season';
    case 'features':
      return 'movie_release';
    default:
      return 'marketing';
  }
};
