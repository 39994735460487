/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react/no-children-prop */
import React from 'react';
import PropTypes from 'prop-types';
import Bar from 'components/common/Charts/Bar';
import Grid from '@material-ui/core/Grid';
import { Text } from 'components/styleguide';
import Tooltip from '@material-ui/core/Tooltip';
import { useRootTranslation } from 'utils/hooks';
import { Trans } from 'react-i18next';

import add from 'ramda/src/add';
import omit from 'ramda/src/omit';
import values from 'ramda/src/values';
import { PRODUCTION_TYPES } from 'utils/constants';
import { LICENSING_TRACK_STATUS, CLEARANCE_CAMPAIGN_STATUS, CLEARANCE_FILMEPISODE_STATUS } from './constants';
import useStyles from './styles';

const rootT = 'charts.tracks.labels';

const TrackTooltip = ({ children, track, value }) => {
  const t = useRootTranslation('charts.tracks.types');
  const classes = useStyles();
  if (!value || !track) return children;
  const trackList = omit(['scores'], track);
  return (
    <Tooltip
      classes={{ tooltip: classes.tooltip }}
      interactive
      placement="right"
      TransitionProps={{
        mountOnEnter: true,
        unmountOnExit: true,
      }}
      title={
        <Grid className={classes.content} container direction="column">
          {Object.entries(trackList).map(([key, value]) => (
            <Grid item container key={key} justify="space-evenly" spacing={1}>
              <Grid item xs={8} container>
                <Text align="right" justify="center" noWrap variant="caption">
                  {t(key)}
                </Text>
              </Grid>
              <Grid item xs>
                <Text bold variant="caption">
                  {value}
                </Text>
              </Grid>
            </Grid>
          ))}
        </Grid>
      }
    >
      <div children={children} />
    </Tooltip>
  );
};

const TrackStatus = ({ color, isClearance, isWide, label, total, track, value }) => (
  <Grid container alignItems="center">
    <Grid item xs={isClearance ? 3 : 4 + isWide}>
      <Trans i18nKey={`${rootT}.${label}`} count={value}>
        <Text variant="caption" noWrap />
        <Text bold variant="tiny" />
      </Trans>
    </Grid>
    <Grid item xs={isClearance ? 9 : 7 - isWide}>
      <TrackTooltip track={track} value={value}>
        <Bar color={color} total={total} value={value} notGhosted />
      </TrackTooltip>
    </Grid>
  </Grid>
);

const STATUS_COLORS = ['darkPurple', 'blue', 'orange', 'customGreen'];

const getStatuses = (isClearance, productionType) => {
  if (!isClearance) return LICENSING_TRACK_STATUS;
  if (productionType?.toLowerCase?.() === PRODUCTION_TYPES.CAMPAIGN) {
    return CLEARANCE_CAMPAIGN_STATUS;
  }
  return CLEARANCE_FILMEPISODE_STATUS;
};

const TracksChart = ({ isClearance, isWide, productionType, progressVariant, tracks }) => {
  const t = useRootTranslation(rootT);
  const classes = useStyles();
  const statuses = getStatuses(isClearance, productionType);
  const trackValues = statuses.reduce(
    (curr, key) => ({
      ...curr,
      [key]: values(tracks[key]).reduce(add, 0),
    }),
    {},
  );
  const total = values(trackValues).reduce(add, 0);
  return (
    <>
      <Grid container alignItems="center" className={classes.total}>
        <Grid item xs={isClearance ? 3 : 4 + isWide}>
          <Text bold children={t('progress')} color="textSecondary" variant={progressVariant} />
        </Grid>
        <Grid item xs={isClearance ? 9 : 7 - isWide}>
          <Bar color="customGreen" outlined size="medium" total={total} value={total}>
            <Text
              bold
              children={t(isClearance ? 'total' : 'totalUsed', {
                count: total,
              })}
              variant="caption"
            />
          </Bar>
        </Grid>
      </Grid>
      {statuses.map((key, i) => (
        <TrackStatus
          color={STATUS_COLORS[i]}
          isClearance={isClearance}
          isWide={isWide}
          key={key}
          label={key}
          total={total}
          track={tracks[key]}
          value={trackValues[key]}
        />
      ))}
    </>
  );
};

TracksChart.propTypes = {
  isClearance: PropTypes.bool,
  isWide: PropTypes.bool,
  productionType: PropTypes.string,
  progressVariant: PropTypes.string,
  tracks: PropTypes.array,
};

TracksChart.defaultProps = {
  isWide: false,
  progressVariant: 'body2',
};

export default TracksChart;
