import { compose } from 'redux';
import { connect } from 'react-redux';

import { closeSnackbar } from 'redux-core/global-error/actions';
import {
  getSnackbarLinkRoute,
  getSnackbarLinkText,
  getSnackbarMessage,
  shouldShowSnackbar,
  getSnackbarProps,
  getSnackbarContentType,
  getSnackbarLinkAction,
  getSnackbarHorizontalPosition,
} from 'redux-core/global-error/selectors';

import withStyles from './styles';

const mapDispatchToProps = {
  closeSnackbar,
};

const mapStateToProps = (state) => ({
  showSnackbar: shouldShowSnackbar(state),
  snackbarMessage: getSnackbarMessage(state),
  snackbarLinkText: getSnackbarLinkText(state),
  snackbarLinkAction: getSnackbarLinkAction(state),
  snackbarHorizontalPosition: getSnackbarHorizontalPosition(state),
  snackbarLinkRoute: getSnackbarLinkRoute(state),
  snackbarProps: getSnackbarProps(state),
  snackbarContentType: getSnackbarContentType(state),
});

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles);
