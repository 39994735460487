import { connect } from 'react-redux';
import { setSelected } from 'redux-core/overview/actions';
import { keepReleaseInfo, keepPersonnelInfo } from 'redux-core/productions/actions';

const mapDispatchToProps = {
  setSelected,
  keepPersonnelInfo,
  keepReleaseInfo,
};

export default connect(undefined, mapDispatchToProps);
