import React from 'react';
import { withStyles } from '@material-ui/core';
import classnames from 'classnames';
import PropTypes from 'prop-types';

const styles = () => ({
  arrowUp: {
    width: 0,
    height: 0,
    position: 'relative',
    left: 0,
    borderLeft: '15px solid transparent',
    borderRight: '15px solid transparent',
    borderBottom: '15px solid white',
    '&.right': {
      left: '20px',
    },
  },
});

const ArrowUp = ({ classes, right = false }) => <div className={classnames(classes.arrowUp, { right })} />;

ArrowUp.propTypes = {
  classes: PropTypes.object,
  right: PropTypes.bool,
};

export default withStyles(styles)(ArrowUp);
