/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import ButtonBase from '@material-ui/core/ButtonBase';

import { Text } from 'components/styleguide';
import useStyles from '../styles';

const UserMenuRow = ({ option }) => {
  const classes = useStyles();

  return (
    <>
      <Grid item>
        <Divider className={classes.divider} />
      </Grid>
      <ButtonBase onClick={option.action} id={option.id}>
        <Grid container item className={classes.menuRow}>
          <Grid item xs={1}>
            {option.icon}
          </Grid>
          <Grid item xs={9} className={classes.menuLinkText}>
            <Text align="left">{option.label}</Text>
          </Grid>
        </Grid>
      </ButtonBase>
    </>
  );
};

UserMenuRow.propTypes = {
  option: PropTypes.shape({
    label: PropTypes.string,
    action: PropTypes.func,
    icon: PropTypes.elementType,
    id: PropTypes.string,
  }).isRequired,
};

export default UserMenuRow;
