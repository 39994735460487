import { IconButton, IconButtonProps } from '@mui/material';
import classnames from 'classnames';
import dissoc from 'ramda/src/dissoc';
import prop from 'ramda/src/prop';
import useStyles from './styles';

export type TButtonIconProps = {
  slim?: boolean;
  dense?: boolean;
  opacity?: number;
  classes?: { root: string; [key: string]: string };
} & IconButtonProps;

const ButtonIcon = ({ dense, slim, opacity, classes, ...props }: TButtonIconProps) => {
  const ownClasses = useStyles({ opacity });
  return (
    <IconButton
      {...props}
      classes={{
        root: classnames(ownClasses.root, prop('root', classes), {
          [ownClasses.dense]: dense,
          [ownClasses.slim]: slim,
        }),
        ...dissoc('root', classes),
      }}
    />
  );
};

export default ButtonIcon;
