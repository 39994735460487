import { TRACK_STATUS_LABEL } from 'utils/constants';

export const CLEARANCE_CAMPAIGN_STATUS = [
  TRACK_STATUS_LABEL.NEW,
  TRACK_STATUS_LABEL.IN_PROGRESS,
  TRACK_STATUS_LABEL.CLEARED,
  TRACK_STATUS_LABEL.CONFIRMED,
];

export const CLEARANCE_FILMEPISODE_STATUS = [
  TRACK_STATUS_LABEL.NEW,
  TRACK_STATUS_LABEL.IN_PROGRESS,
  TRACK_STATUS_LABEL.CLEARED,
  TRACK_STATUS_LABEL.LICENSED,
];

export const LICENSING_TRACK_STATUS = [
  TRACK_STATUS_LABEL.NEEDS_LICENSE,
  TRACK_STATUS_LABEL.LICENSE_SENT,
  TRACK_STATUS_LABEL.CHECK_SENT,
  TRACK_STATUS_LABEL.PAID,
];
