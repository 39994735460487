/* eslint-disable react/no-children-prop */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, DrawerHeader, Text, InputText, TextWithLine } from 'components/styleguide';
import { useRootTranslation } from 'utils/hooks';
import Grid from '@material-ui/core/Grid';
import enhancer from './enhancer';
import TemplatesList from './TemplatesList';

const rootT = 'drawers.importSaveAsTemplate';

const ImportSaveAsTemplateDrawer = ({ onClose, saveImportedData }) => {
  const t = useRootTranslation(rootT);
  const [name, setName] = useState('');
  const [template, setTemplate] = useState();
  const templateId = template?.id;

  const isValid = name.trim().length || templateId;

  const handleSaveAndImport = () => saveImportedData({ name, template });

  return (
    <>
      <DrawerHeader bold hideBackButton onClose={onClose} title={t('drawerTitle')}>
        <Button
          alwaysEnabled
          children={t('cancel')}
          onClick={onClose}
          size="xs"
          synchronous
          testId="save-as-template-cancel"
          variant="destructive"
        />
        <Button
          children={t('saveAndImport')}
          disabled={!isValid}
          onClick={handleSaveAndImport}
          size="sm"
          testId="save-as-template-save"
          type="submit"
          variant="primary"
        />
      </DrawerHeader>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <InputText
            autoFocus
            disabled={Boolean(templateId)}
            label={t('name')}
            name="name"
            onChange={(e) => setName(e.target.value)}
            placeholder={t('namePlaceholder')}
            required
            value={name}
          />
        </Grid>
        <Grid item xs={12}>
          <TextWithLine children={t('or')} />
        </Grid>
        <Grid container item xs={12}>
          <Text bold children={t('replaceExisting')} />
        </Grid>
        <TemplatesList onSelect={setTemplate} templateId={templateId} />
      </Grid>
    </>
  );
};

export default enhancer(ImportSaveAsTemplateDrawer);

ImportSaveAsTemplateDrawer.propTypes = {
  onClose: PropTypes.func.isRequired,
  saveImportedData: PropTypes.func.isRequired,
};
