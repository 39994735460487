/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { TypeAhead } from 'components/styleguide';
import { useDebounce } from 'utils/hooks';
import { useTranslation } from 'react-i18next';
import { searchProductions } from 'redux-core/productions/services';
import prop from 'ramda/src/prop';

const ProductionField = ({ disabled, division, projectTitle, value, ...props }) => {
  const [search, setSearch] = useState(projectTitle);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState();
  const debouncedSearch = useDebounce(search, 300);
  const tenantId = prop('tenantId', division);
  const { t } = useTranslation();

  useEffect(
    () => {
      const fetch = async (term) => {
        const valueTerm = !term && projectTitle ? projectTitle : term;
        setLoading(true);
        // No need to have this in redux store
        const { items } = await searchProductions({
          term: valueTerm,
          ...(tenantId ? { tenantId } : {}),
        });
        setOptions(items);
        setLoading(false);
      };
      fetch(debouncedSearch);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [debouncedSearch, division],
  );

  return (
    <TypeAhead
      disabled={disabled}
      creatableText={t('projects.newProduction.createNew')}
      noResultsText={t('global.noResults')}
      loading={loading}
      options={options}
      onInputChange={setSearch}
      placeholder={t('projects.newProduction.title')}
      tall
      value={value}
      {...props}
    />
  );
};

ProductionField.propTypes = {
  disabled: PropTypes.bool,
  division: PropTypes.shape({
    tenantId: PropTypes.number,
    divisionId: PropTypes.number,
  }),
  projectTitle: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

ProductionField.defaultProps = {
  division: {},
  projectTitle: '',
};

export default ProductionField;
