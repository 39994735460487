/* eslint-disable import/no-cycle */
import Control from './Control';
import { DropdownIndicator, IndicatorSeparator, ClearIndicator } from './Indicators';
import Menu from './Menu';
import MultiValue from './MultiValue';
import NoOptionsMessage from './NoOptionsMessage';
import Option from './Option';
import Placeholder from './Placeholder';
import SingleValue from './SingleValue';
import ValueContainer from './ValueContainer';
import MenuList from './MenuList';

// https://react-select.com/components
const TypeAheadComponents = {
  Control,
  DropdownIndicator,
  Menu,
  MenuList,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
  IndicatorSeparator,
  ClearIndicator,
};

export default TypeAheadComponents;
