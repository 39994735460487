import { useEffect, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { TAddTrackForm } from '../Drawers/AddTrackDrawer/types';

/*
 * 'isUpdating' flag is used as a lock mechanism to avoid an infinite loop problem
 * caused by calling 'setValue' multiple times, inside a 'watch' callback.
 *
 * Q. Why not call setValue and update the entire rightsOwners field?
 * A - setting the rightsOwners with a new updated value makes the current field rows on the form
 * to lose its selected values for Autocomplete fields like name.
 *
 */
export function useWatchRightsOwnersField({ watch, setValue }: UseFormReturn<TAddTrackForm, any>) {
  const [isUpdating, setIsUpdating] = useState(false);

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name?.includes('rightsOwners')) {
        if (!isUpdating) {
          value.rightsOwners.forEach((item, index, list) => {
            if (item != null) {
              setIsUpdating(true);
              if (item.type === 'publisher' && item.relatedSyncPublisherId != null) {
                const relatedExist = list.find((it) => it.id === item.relatedSyncPublisherId);
                if (!relatedExist) {
                  setValue(`rightsOwners.${index}.relatedSyncPublisherId`, null);
                }
              }
            }
          });
        }
        setIsUpdating(false);
      }
    });

    return () => subscription.unsubscribe();
  }, [isUpdating, setValue, watch]);
}
