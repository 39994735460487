/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { usePermissions } from 'utils/hooks';
import { CLEAR_PERMISSIONS } from 'redux-core/permissions/constants';
import { withPermissionsPropTypes } from '../types';

const withFinancialPermissions = (WrappedComponent) => {
  const Component = ({
    disabled = undefined,
    objectId = undefined,
    placeholder = undefined,
    prefix = undefined,
    readOnly = undefined,
    ...props
  }) => {
    const canView = usePermissions({
      clearPermissions: CLEAR_PERMISSIONS.VIEW_FINANCIAL_INFO,
      objectId,
    });
    const canEdit = usePermissions({
      clearPermissions: CLEAR_PERMISSIONS.UPDATE_FINANCIAL_INFO,
      objectId,
    });

    return (
      <WrappedComponent
        {...props}
        disabled={disabled || !canEdit}
        readOnly={readOnly || !canEdit}
        placeholder={canView ? placeholder : '--'}
        prefix={canView ? prefix : ''}
      />
    );
  };
  Component.propTypes = withPermissionsPropTypes;
  Component.displayName = `Secured${WrappedComponent.displayName}`;
  return Component;
};

export default withFinancialPermissions;
