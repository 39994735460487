import { Box } from '@mui/material';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useCreateClearanceStatusMutation, useUpdateClearanceStatusMutation } from 'services/clear/clearance-status';
import { useCreateCueSheetStatusMutation, useUpdateCueSheetStatusMutation } from 'services/cue/cue-sheet-status';
import { CustomStatus } from './CustomStatus';
import { TDndResult } from './CustomStatusItem';
import { CustomStatusList } from './CustomStatusList';
import { CustomStatusListItem } from './CustomStatusListItem';
import { TCustomStatus } from './types';

export type TDndCustomStatusListProps = {
  divisionId: number;
  existingCustomStatuses: TCustomStatus[];
  newCustomStatus: TCustomStatus;
  createCustomStatus:
    | ReturnType<typeof useCreateClearanceStatusMutation>[0]
    | ReturnType<typeof useCreateCueSheetStatusMutation>[0];
  updateCustomStatus:
    | ReturnType<typeof useUpdateClearanceStatusMutation>[0]
    | ReturnType<typeof useUpdateCueSheetStatusMutation>[0];
  onMoveCustomStatus: (result: TDndResult) => void;
};

export const DndCustomStatusList = ({
  divisionId,
  existingCustomStatuses,
  newCustomStatus,
  createCustomStatus,
  updateCustomStatus,
  onMoveCustomStatus,
}: TDndCustomStatusListProps) => {
  const coreStatuses = existingCustomStatuses.filter((status) => status.coreStatus);
  const customStatuses = existingCustomStatuses.filter((status) => !status.coreStatus);
  return (
    <Box pl={4}>
      <DragDropContext onDragEnd={onMoveCustomStatus}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <CustomStatusList
              data-testid="custom-status-drop-area"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {coreStatuses.map((status) => (
                <CustomStatusListItem hasParent>
                  <CustomStatus key={`core-status-${status.id}`} status={status} />
                </CustomStatusListItem>
              ))}
              {customStatuses?.map((childStatus, index) => (
                <Draggable
                  key={`custom-status-${childStatus.id}`}
                  draggableId={`custom-status-${childStatus.id}`}
                  index={index}
                >
                  {(draggableProvided) => (
                    <CustomStatusListItem
                      hasParent
                      style={draggableProvided.draggableProps.style}
                      ref={draggableProvided.innerRef}
                      {...draggableProvided.draggableProps}
                      {...draggableProvided.dragHandleProps}
                    >
                      <CustomStatus
                        divisionId={divisionId}
                        status={childStatus}
                        createCustomStatus={createCustomStatus}
                        updateCustomStatus={updateCustomStatus}
                      />
                    </CustomStatusListItem>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
              <CustomStatusListItem hasParent>
                <CustomStatus
                  key={`new-custom-status-${Date.now()}`}
                  divisionId={divisionId}
                  status={newCustomStatus}
                  createCustomStatus={createCustomStatus}
                />
              </CustomStatusListItem>
            </CustomStatusList>
          )}
        </Droppable>
      </DragDropContext>
    </Box>
  );
};
