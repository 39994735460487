import { connect } from 'react-redux';
import { getTerritoryPresets } from 'redux-core/territories/actions';
import { compose } from 'ramda';
import withBaseDrawer from '../withBaseDrawer';

const mapDispatchToProps = {
  getTerritoryPresets,
};

export default compose(connect(undefined, mapDispatchToProps), withBaseDrawer);
