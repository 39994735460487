import { Visibility, VisibilityOff } from '@mui/icons-material';
import { IconButton, InputAdornment, OutlinedInput } from '@mui/material';
import { FormField } from 'components/common/Form/Fields/FormField/FormField';
import { MouseEvent, useState } from 'react';
import { rootTranslation } from 'utils/format';
import { PasswordFieldRules } from './PasswordFieldRules';

const t = rootTranslation('apmSignup');

export const PasswordField = () => {
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <FormField
      fullWidth
      label={t('password')}
      name="password"
      hideError
      render={({ field, fieldState, muiprops }) => (
        <>
          <OutlinedInput
            {...field}
            {...muiprops}
            id="password"
            data-testid="input-text-password"
            placeholder={t('password')}
            type={showPassword ? 'text' : 'password'}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                  sx={{ paddingRight: '15px' }}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
          <PasswordFieldRules currentValue={field.value} fieldState={fieldState} />
        </>
      )}
    />
  );
};
