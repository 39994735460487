/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import { Text } from 'components/styleguide';
import { concatStrings } from 'utils';
import classnames from 'classnames';
import useStyles from './styles';
import BackIcon from '../../icons/Back';

const getChildren = (child) => <Grid item>{child}</Grid>;

const DrawerHeader = ({ children, bold, onClose, title, hideBackButton, subtitle, testId }) => {
  const classes = useStyles();
  return (
    <Toolbar className={classes.toolbar} data-testid={testId}>
      <Grid container justify="space-between" spacing={2} alignItems="center">
        <Grid className={classes.item} item>
          {!hideBackButton && (
            <IconButton
              className={classnames(classes.backButton, {
                [classes.hasSubtitle]: subtitle,
              })}
              color="inherit"
              aria-label="menu"
              onClick={onClose}
              data-testid="drawer-button-back"
            >
              <BackIcon size={24} />
            </IconButton>
          )}
          {subtitle ? (
            <Grid container direction="column">
              <Text variant="h5" bold>
                {title}
              </Text>
              <Text variant="subtitle2" bold>
                {subtitle}
              </Text>
            </Grid>
          ) : (
            <Text bold={bold} variant="h5">
              {title}
            </Text>
          )}
        </Grid>
        <Grid className={classes.item} item>
          <Grid container spacing={2} data-testid={concatStrings('-')(testId, 'items-container')}>
            {React.Children.map(children, (child) => {
              if (!child) return null;
              /**
               * React treats Fragments as children
               * therefore, any children wrapped in a Fragment will result
               * in a one-time iteration
               */
              if (child.type === React.Fragment) {
                return React.Children.map(child.props.children, (child) => getChildren(child));
              }
              return getChildren(child);
            })}
          </Grid>
        </Grid>
      </Grid>
    </Toolbar>
  );
};

DrawerHeader.propTypes = {
  bold: PropTypes.bool,
  children: PropTypes.node,
  onClose: PropTypes.func,
  title: PropTypes.node.isRequired,
  hideBackButton: PropTypes.bool,
  subtitle: PropTypes.any,
  testId: PropTypes.string,
};

DrawerHeader.defaultProps = {
  hideBackButton: false,
};

export default DrawerHeader;
