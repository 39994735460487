const styles = (theme) => ({
  seeAll: {
    width: 'auto',
    cursor: 'pointer',
  },
  button: {
    zIndex: theme.zIndex.two,
    borderRadius: 6,
    cursor: 'pointer',
    marginLeft: -6,
    padding: '2px 0 2px 6px',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
});

export default styles;
