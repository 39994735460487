/* eslint-disable func-names */
/* eslint-disable consistent-return */
import * as Yup from 'yup';
import { RIGHTS_TERRITORIES_TERM_ENUM } from 'components/Settings/Tabs/Rights/constants';
import i18n from 'locales/i18n';
import dayjs from 'dayjs';
import pathOr from 'ramda/src/pathOr';

const rootT = 'global.forms.validations';

const globalMinDate = '1900-01-01T00:00:00.000Z';
const globalMaxDate = '2100-01-02T00:00:00.000Z';

export const getAdditionalData = (name) => pathOr([], ['additionalData', name]);

const getPasswordValidationText = (validation) => {
  const contain = i18n.t(`${rootT}.password.shouldContain`);

  return i18n.t(`${rootT}.password.${validation}`, { contain });
};

export const yupDate = Yup.date()
  .nullable()
  .min(globalMinDate, `${rootT}.types.dateIsBefore`)
  .max(globalMaxDate, `${rootT}.types.dateIsAfter`)
  .typeError(`${rootT}.types.date`);

export const yupTimeHHMMSS = Yup.string()
  .nullable()
  .matches(/([0-9]{2}:[0-5][0-9]:[0-5][0-9])/, `${rootT}.types.time`);

// eslint-disable-next-line camelcase
export const yupTimeHHMMSS_Max24H = Yup.string()
  .nullable()
  .matches(/(\b(2[0-3]|[0-1]?[0-9])\b:[0-5][0-9]:[0-5][0-9])/, `${rootT}.types.timeMax24`);

export const yupTimeMMSS = Yup.string()
  .nullable()
  .matches(/([0-5][0-9]:[0-5][0-9])/, `${rootT}.types.time`);

// TODO: Remove? Not beign used anywhere.
export const yupStartDateBeforeEndDate = ({ required }) => {
  const test = Yup.string()
    .test('start', '{rootT}.types.dateIsBefore', (startDate) => dayjs(startDate).isAfter(globalMinDate))
    .test('start', '{rootT}.types.dateIsAfter', (startDate) => dayjs(startDate).isBefore(globalMaxDate))
    .test('start', '{rootT}.types.dateStartIsBefore', function (startDate) {
      if (!required && !this.options.parent.start) return true;
      const endDate = this.options.parent.end;
      return endDate ? dayjs(startDate).isSameOrBefore(endDate) : true;
    });
  return Yup.array().of(
    Yup.object({
      id: required ? Yup.number().required('{rootT}.required') : Yup.number().nullable(),
      start: required ? test.required('{rootT}.required').nullable() : test.nullable(),
      end: Yup.string()
        .test('end', '{rootT}.types.dateIsBefore', (endDate) => dayjs(endDate).isAfter(globalMinDate))
        .test('end', '{rootT}.types.dateIsAfter', (endDate) => dayjs(endDate).isBefore(globalMaxDate))
        .nullable(),
    }),
  );
};

/**
 * Return true if the value is a non-empty object
 * @example Schema.propName = yupObjectLength(propName, feedbackMessage)
 */
export const yupObjectLength = (name, message) =>
  Yup.object().test(name, message, (value = {}) => {
    if (Object.keys(value).filter((v) => value[v]).length) return true;
  });

export const yupUserPassword = (min, required = true) => {
  // eslint-disable-next-line no-use-before-define
  const schema = minLength(min)
    .matches(/[a-z]/, getPasswordValidationText('atLeastLowercase'))
    .matches(/[A-Z]/, getPasswordValidationText('atLeastUppercase'))
    .matches(/\d/, getPasswordValidationText('atLeastNumber'))
    .matches(/[!@#$%^&*(),.?":{}|<>]/, getPasswordValidationText('atLeastSpecial'));

  return required ? schema.required(`${rootT}.required`) : schema.nullable();
};

/*
  Usage:
    Yup.array().of(Yup.object().shape({ id: Yup.number() }).test(unique('id', 'Error message'))
*/
export const unique = (propertyName, message) =>
  function (value) {
    const { path } = this;
    const options = [...this.parent];
    const currentIndex = options.indexOf(value);
    if (!value || !value[propertyName]) {
      return true;
    }
    const subOptions = options.slice(0, currentIndex);
    if (subOptions.some((option) => option[propertyName] === value[propertyName])) {
      throw this.createError({
        path: `${path}.${propertyName}`,
        message,
      });
    }
    return true;
  };

export const yupRightPresetTerritories = ({ required = false } = {}) => {
  const array = Yup.array().of(
    Yup.object().shape({
      start: yupDate.when('term', {
        is: RIGHTS_TERRITORIES_TERM_ENUM.DATE_RANGE,
        then: yupDate.when('end', {
          is: (end) => Boolean(end),
          then: yupDate.required(`${rootT}.required`),
          otherwise: required ? yupDate.required(`${rootT}.required`) : yupDate,
        }),
        otherwise: required ? yupDate.required(`${rootT}.required`) : yupDate,
      }),
      end: yupDate.when('term', {
        is: RIGHTS_TERRITORIES_TERM_ENUM.DATE_RANGE,
        then: yupDate.when('start', {
          is: (start) => Boolean(start),
          then: yupDate.required(`${rootT}.required`),
          otherwise: required ? yupDate.required(`${rootT}.required`) : yupDate,
        }),
        otherwise: yupDate,
      }),
    }),
  );

  return required ? array.required(`${rootT}.required`) : array;
};

export const minLength = (min) => Yup.string().min(min, i18n.t(`${rootT}.min`, { min }));

export const maxLength = (max) => Yup.string().max(max, i18n.t(`${rootT}.max`, { max }));

export const lowerThan = (min) => i18n.t(`${rootT}.lower`, { min });

export const greaterThan = (max) => i18n.t(`${rootT}.higher`, { max });
