import compose from 'ramda/src/compose';
import { connect } from 'react-redux';
import { openPromptAsync } from 'redux-core/prompt/actions';
import { userIdSelector } from 'redux-core/auth/selectors';
import withBaseDrawer from '../withBaseDrawer';

const mapStateToProps = (state) => ({
  currentUserId: userIdSelector(state),
});

const mapDispatchToProps = {
  openPromptAsync,
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withBaseDrawer,
);
