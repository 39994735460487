import pick from 'lodash/pick';
import { baseApi } from 'services';

type TCurrency = {
  id: string;
  code: string;
  symbol: string;
  exchangeRate: string;
  currencyId: number;
};

export type TCurrencyNormalized = TCurrency & {
  currencyId: number;
  label: string;
};

export const currenciesApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getCurrencies: builder.query<TCurrencyNormalized[], void>({
      query: () => ({
        url: 'common/currencies/get-all',
        method: 'POST',
        body: {
          limit: 9999,
        },
      }),
      transformResponse: (currencies: TCurrencyNormalized[]) => {
        const result = currencies.map((currency) => ({
          ...pick(currency, ['id', 'exchangeRate', 'code', 'symbol']),
          currencyId: Number(currency.id), // currency id is coming as a string from the API
          label: `${currency.code} (${currency.symbol})`,
        }));
        return result;
      },
    }),
  }),
});

export const { useGetCurrenciesQuery } = currenciesApi;
