/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { BaseDrawer } from 'components/styleguide';
import EditDeleteItemsList from 'components/common/EditDeleteItemsList/';
import { useRootTranslation } from 'utils/hooks';
import { CommonDrawerPropTypes } from '../constants';

const DistributorsDrawer = ({
  name,
  onClose,
  payload: { onSubmit, distributors = [], rootT = 'drawers.productionSetup.distributors', readOnly },
}) => {
  const t = useRootTranslation(rootT);
  const handleSubmit = (values) => {
    onSubmit({
      distributors: values,
    });
  };
  return (
    <EditDeleteItemsList
      name={name}
      deletePromptContent={(distributor) => t('delete', distributor)}
      drawerTitle={`${rootT}.drawerTitle`}
      inputPlaceholderName="drawers.nameForm.placeholder"
      onClose={onClose}
      onSubmit={handleSubmit}
      readOnly={readOnly}
      initialValues={distributors}
    />
  );
};

DistributorsDrawer.propTypes = {
  name: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  payload: PropTypes.object.isRequired,
  rootT: PropTypes.string.isRequired,
};

const Drawer = ({ open, onClose, ...props }) => (
  <BaseDrawer open={open} onClose={onClose}>
    <DistributorsDrawer onClose={onClose} {...props} />
  </BaseDrawer>
);

Drawer.propTypes = CommonDrawerPropTypes;

export default Drawer;
