/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable react/no-children-prop */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import InfoIconOutlined from '@material-ui/icons/InfoOutlined';
import InfoIconFilled from '@material-ui/icons/Info';
import { Loading } from 'components/styleguide';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import pick from 'ramda/src/pick';
import useStyles from './styles';

const AlignToPropType = PropTypes.oneOf(['input', 'button']);

const fontSize = {
  sm: 'small',
  md: 'default',
  lg: 'large',
  small: 'small',
  medium: 'default',
};

const buttonIconSize = {
  small: 'sm',
  medium: 'md',
};

const iconVariant = {
  primary: InfoIconFilled,
  secondary: InfoIconOutlined,
};

const alignments = {
  input: 3.25,
  button: 1,
};

const Container = ({ children, alignTo }) => {
  if (!alignTo) return children;

  return (
    <Box data-testid="infotooltip-container" mt={alignments[alignTo]}>
      {children}
    </Box>
  );
};

Container.propTypes = {
  alignTo: AlignToPropType,
  children: PropTypes.any,
};

const InfoTooltip = ({ variant, color, iconSize, onClick, opacity, alignTo, loading, ...props }) => {
  const classes = useStyles({ opacity });

  const onClickProps = pick(
    [
      'disableFocusListener',
      'disableHoverListener',
      'disableTouchListener',
      'onOpen',
      'onClose',
      'open',
      'interactive',
    ],
    props,
  );
  const tooltipProps = pick(['title', 'placement'], props);
  const arrowTooltipProps = props.arrow ? { arrow: true, [props.arrow]: true } : {};

  const IconComponent = iconVariant[variant];

  return (
    <Container alignTo={alignTo}>
      <Tooltip {...tooltipProps} {...arrowTooltipProps} {...onClickProps} data-testid="infotooltip-tooltip">
        {onClick ? (
          <IconButton
            size={buttonIconSize[iconSize]}
            style={{ padding: 0 }}
            color={color}
            children={loading ? <Loading size={16} /> : <IconComponent />}
            onClick={onClick}
            data-testid="infotooltip-icon"
          />
        ) : (
          <IconComponent
            variant={color}
            fontSize={fontSize[iconSize]}
            className={classnames(classes.icon)}
            data-testid="infotooltip-icon"
          />
        )}
      </Tooltip>
    </Container>
  );
};

InfoTooltip.propTypes = {
  alignTo: AlignToPropType,
  opacity: PropTypes.number,
  onClick: PropTypes.func,
  iconSize: PropTypes.oneOf(['sm', 'md', 'lg']),
  /** <InfoIcon /> Variant */
  variant: PropTypes.oneOf(['primary', 'secondary']),
  /** <InfoIcon /> Color */
  color: PropTypes.oneOf(['primary', 'secondary']),
  // tooltip
  loading: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  placement: PropTypes.oneOf([
    'bottom-end',
    'bottom-start',
    'bottom',
    'left-end',
    'left-start',
    'left',
    'right-end',
    'right-start',
    'right',
    'top-end',
    'top-start',
    'top',
  ]),

  arrow: PropTypes.oneOf(['top', 'right', 'bottom', 'left']),
};

InfoTooltip.defaultProps = {
  title: '',
  variant: 'secondary',
  color: 'primary',
  size: 'md',
};

export default InfoTooltip;
