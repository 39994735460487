import { Box, FormControlLabel, Radio, RadioGroup, Skeleton, Typography } from '@mui/material';
import { getCampaignAssetsCall } from 'redux-core/cueContainers/services';
import { getAllCueSheetVersionsCall } from 'redux-core/cueSheet/services';
import { useFetch, useRootTranslation } from 'utils/hooks';
import { TCampaign } from './ChooseMarketingCueSheet';

export type TAssets = {
  id: string;
  title: string;
};

export type TAvailableMarketingCueSheetList = {
  marketingCampaign: TCampaign;
  cueSheetId?: number;
  setCueSheetId: (cueSheet: number) => void;
};

export const AvailableMarketingCueSheetList = ({
  marketingCampaign,
  cueSheetId,
  setCueSheetId,
}: TAvailableMarketingCueSheetList) => {
  const t = useRootTranslation();
  const [availableCueSheets, loadingAvailableCueSheets] = useFetch(async () => {
    if (!marketingCampaign) return null;
    const { data: assets } = await getCampaignAssetsCall({ campaignId: marketingCampaign.campaignId });
    const { data: cueSheets } = await getAllCueSheetVersionsCall({
      productionId: marketingCampaign.campaignId,
    });
    return assets
      .filter((asset) => !asset.unassignedBin)
      .map((asset) => {
        const activeCueSheet = cueSheets.find((cueSheet) =>
          cueSheet.parentCueContainerId === asset.id && cueSheet.isCurrentCueSheetVersion,
        );
        return {
          cueSheetId: activeCueSheet.id,
          cueSheetDescription: `${asset.title} - ${activeCueSheet.title} - ${activeCueSheet.cueSheetStatus}`,
          unassignedBin: asset.unassignedBin,
        };
      });
  }, [marketingCampaign]);

  if (loadingAvailableCueSheets) return <Skeleton />;

  return (
    <RadioGroup
      value={cueSheetId}
      onChange={(_, newValue) => {
        setCueSheetId(Number(newValue));
      }}
    >
      {!!availableCueSheets?.length && availableCueSheets.map((availableCueSheet) => (
        <Box pt={1} key={`cue-sheet-target-${availableCueSheet.cueSheetId}`}>
          <FormControlLabel
            value={`${availableCueSheet.cueSheetId}`}
            control={<Radio />}
            label={availableCueSheet.cueSheetDescription}
          />
        </Box>
      ))}
      {!availableCueSheets?.length && (
        <Typography variant="body1">{t('drawers.quickSetupProduction.exportSegmentSteps.noAssetsMessage')}</Typography>
      )}
    </RadioGroup>
  );
};
