import { MutableRefObject } from 'react';
import { useAtom, useAtomValue } from 'jotai';
import LoadingButton from '@mui/lab/LoadingButton';
import { TSegment } from 'components/ProductionOverview/CueSheet/CueSheetTable/CueSheetTableContent/types';
import { useTranslation } from 'react-i18next';
import { NORMALIZED_PRODUCTION_TYPES } from 'utils/constants';
import {
  buttonEnabledAtom,
  cueSheetAndParentProductionAtom,
  DrawerSteps,
  formStepAtom,
  submittingFormAtom,
  TFormStep,
} from './drawerStore';

export type TDrawerPrimaryButton = {
  divisionId?: number;
  production?: number | string;
  productionType?: keyof typeof NORMALIZED_PRODUCTION_TYPES;
  selectedSegments?: TSegment[];
  exportSegments?: () => Promise<void>;
  formRef: MutableRefObject<HTMLFormElement>;
};

export const DrawerPrimaryButton = (props: TDrawerPrimaryButton) => {
  const { divisionId, production, productionType, selectedSegments, exportSegments, formRef } = props;
  const { t } = useTranslation();
  const [formStep, setFormStep] = useAtom(formStepAtom);
  const currentFormStep = formStep as TFormStep;
  const enabled = useAtomValue(buttonEnabledAtom);
  const cueSheetAndParent = useAtomValue(cueSheetAndParentProductionAtom);
  const submittingForm = useAtomValue(submittingFormAtom);
  const cueSheetId = cueSheetAndParent?.cueSheetId;
  const label = t(ButtonLabel[currentFormStep]);
  const testId = formStep === 'SelectProject' ? 'button-next' : 'button-save';
  const buttonEnabled = enabled || isEnabled(currentFormStep, divisionId, productionType, production, cueSheetId);
  const handleClick = buildClickHandler(
    currentFormStep,
    selectedSegments,
    exportSegments,
    production,
    setFormStep,
    formRef,
  );

  return (
    <LoadingButton
      loading={submittingForm}
      color="primary"
      variant="contained"
      data-testid={testId}
      onClick={handleClick}
      disabled={!buttonEnabled}
      type="button"
    >
      {label}
    </LoadingButton>
  );
};

const ButtonLabel = {
  SelectProductionType: 'global.forms.next',
  SelectProject: 'global.forms.next',
  InputProductionDetails: 'global.forms.save',
  ChooseCueSheet: 'drawers.quickSetupProduction.exportSegmentSteps.export',
};

function buildClickHandler(
  formStep: TFormStep,
  selectedSegments: TDrawerPrimaryButton['selectedSegments'],
  exportSegments: TDrawerPrimaryButton['exportSegments'],
  production: TDrawerPrimaryButton['production'],
  setFormStep: (step: TFormStep) => void,
  formRef: TDrawerPrimaryButton['formRef'],
): () => void | Promise<void> {
  return async () => {
    if (formStep === 'SelectProductionType') {
      setFormStep('SelectProject');
    } else if (formStep === 'SelectProject' && !selectedSegments?.length) {
      setFormStep('InputProductionDetails');
    } else if (formStep === 'SelectProject' && typeof production === 'string') {
      setFormStep('InputProductionDetails');
    } else if (formStep === 'SelectProject' && typeof production === 'number') {
      setFormStep('ChooseCueSheet');
    } else if (formStep === 'ChooseCueSheet') {
      await exportSegments();
    } else if (formRef.current) {
      formRef.current.requestSubmit();
    }
  };
}

function isEnabled(
  formStep: TFormStep,
  divisionId: TDrawerPrimaryButton['divisionId'],
  productionType: TDrawerPrimaryButton['productionType'],
  production: TDrawerPrimaryButton['production'],
  cueSheetId: number | null,
) {
  if (formStep === DrawerSteps.SelectProductionType) {
    return divisionId && productionType;
  }
  if (formStep === DrawerSteps.SelectProject) {
    return divisionId && productionType && production;
  }
  if (formStep === DrawerSteps.ChooseCueSheet) {
    return !!cueSheetId;
  }
  return false;
}
