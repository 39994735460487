/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/default-props-match-prop-types */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, BoxProps, Grid, GridSpacing } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    borderBottom: ({ divider }: { divider?: boolean }) => divider && `1px solid ${theme.palette.divider}`,
    width: ({ divider }: { divider?: boolean }) => divider && '100%',
  },
}));

type RowProps = BoxProps & {
  divider?: boolean;
  testId?: string;
  spacing?: GridSpacing;
};

const Row = ({
  children,
  mb = 2,
  width = 1,
  height,
  px = 0,
  pb,
  pt,
  flex,
  divider,
  testId = 'qClear-Row',
  ...props
}: RowProps) => {
  const classes = useStyles({ divider });
  return (
    <Box
      mb={mb}
      width={width}
      height={height}
      px={px}
      pb={pb}
      pt={pt}
      flex={flex}
      data-testid={testId}
      className={classes.root}
    >
      <Grid {...props} container>
        {children}
      </Grid>
    </Box>
  );
};

Row.displayName = 'Row';

export default Row;
