import { useAtomValue, useSetAtom } from 'jotai';
import { useEffect, useState } from 'react';
import { useGetAllEmailTemplatesQuery } from 'services/clear/email-templates';
import { TEmailTemplate } from 'services/clear/types';
import { composeLetterAtom } from '../composeLetterStore';

export type TOnDefaultTemplateChange = (defaultTemplateId: number) => void;

export function useGetEmailTemplatesAndSetDefault(onDefaultTemplateChange: TOnDefaultTemplateChange) {
  const [filteredTemplates, setFilteredTemplates] = useState<{
    isLoadingTemplates: boolean;
    filteredTemplates: TEmailTemplate[];
  }>({
    isLoadingTemplates: false,
    filteredTemplates: undefined,
  });

  const composeLetter = useAtomValue(composeLetterAtom);
  const setComposeLetter = useSetAtom(composeLetterAtom);

  const { data: emailTemplates, isLoading } = useGetAllEmailTemplatesQuery({
    divisionId: composeLetter.divisionId,
  });

  const {
    letterType,
    clearanceBundleLicensor: { type: licensorType },
    email: { templateId: currentTemplateId },
  } = composeLetter;

  useEffect(() => {
    if (isLoading) {
      setFilteredTemplates({ isLoadingTemplates: true, filteredTemplates: undefined });
      return;
    }

    const templatesFilteredByLetterAndLicensorTypes = emailTemplates?.filter(
      (e) => e.letterType === letterType && e.licensorType === licensorType,
    );

    const defaultTemplate = templatesFilteredByLetterAndLicensorTypes?.find((e) => e.isDefault);

    setFilteredTemplates({
      isLoadingTemplates: false,
      filteredTemplates: templatesFilteredByLetterAndLicensorTypes,
    });

    const skipUpdate = currentTemplateId >= 0;
    if (skipUpdate) return;

    const newTemplateId = defaultTemplate?.id || 0;

    setComposeLetter((prev) => ({
      ...prev,
      email: {
        ...prev.email,
        templateId: newTemplateId,
      },
    }));

    onDefaultTemplateChange(newTemplateId);
  }, [
    isLoading,
    setComposeLetter,
    letterType,
    licensorType,
    currentTemplateId,
    emailTemplates,
    onDefaultTemplateChange,
  ]);

  return filteredTemplates;
}
