/* eslint-disable react/no-children-prop */
import React from 'react';
import { useRootTranslation } from 'utils/hooks';
import PropTypes from 'prop-types';
import { DrawerHeader, Row, ToggleButton, InfoTooltip } from 'components/styleguide';
import {
  FormButton,
  FormCheckbox,
  FormInputFormat,
  FormInputText,
  FormItemsList,
  FormToggleButtonGroup,
} from 'components/common/Form/Fields';
import Form from 'components/common/Form';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import * as Yup from 'yup';
import { yupTimeMMSS } from 'utils/forms';
import { TIME_FORMATS } from 'utils/format';
import propEq from 'ramda/src/propEq';
import omit from 'ramda/src/omit';
import prop from 'ramda/src/prop';
import assoc from 'ramda/src/assoc';
import compose from 'ramda/src/compose';
import { v4 as uuidv4 } from 'uuid';
import DrawerFormContentGrid from '../DrawerFormContentGrid';
import enhancer from './enhancer';

const rootT = 'drawers.versionManagerDuplicate';
// @ Todo implement Drawers
const getItems = () => [
  {
    label: `${rootT}.items.finishingVendors`,
    name: 'additionalData.vendors',
    action: () => {},
  },

  {
    label: `${rootT}.items.finishers`,
    name: 'additionalData.finishers',
    action: () => {},
  },
  {
    label: `${rootT}.items.creativeVendors`,
    name: 'additionalData.creativeVendors',
    action: () => {},
  },
];

const validationSchema = Yup.object().shape({
  type: Yup.string().required('global.forms.validations.required'),
  title: Yup.string().required('global.forms.validations.required'),
  version: Yup.number().required('global.forms.validations.required'),
  material: Yup.object().when('type', {
    is: 'newAsset',
    then: Yup.object().shape({
      title: Yup.string().required('global.forms.validations.required'),
    }),
    otherwise: Yup.object().shape({
      title: Yup.string().nullable(),
    }),
  }),
  duration: yupTimeMMSS,
});

const VersionManagerDuplicate = ({
  name,
  onClose,
  payload: { version, asset, onSubmit, campaignId, copyAsset, isDirty },
}) => {
  const t = useRootTranslation(rootT);
  const handleSubmit = (values) => {
    const payload = propEq('type', 'current', values)
      ? compose(
          assoc('isCurrentVersion', values.currentVersion),
          assoc('id', `new-${uuidv4()}`),
          omit(['material', 'type', 'currentVersion', 'id']),
        )(values)
      : omit(['type', 'cues'], values);
    onClose();
    return propEq('type', 'current', values) ? onSubmit(payload) : copyAsset(payload);
  };
  // @TODO: replace type and rights when api is implemented
  const handleChange = ({ setFieldValue }, field, value) => {
    if (value === 'newAsset') {
      setFieldValue('currentVersion', true);
      setFieldValue('material', {
        type: 'Material',
        rights: '',
        title: `${asset.title} ${t('copy')}`,
        divisionId: prop('divisionId', version),
        campaignId,
      });
    }
    setFieldValue(field.name, value);
  };
  const onCancel = () => {
    onClose();
  };
  const initialValues = {
    ...omit(['isCurrentVersion', 'title'], version),
    title: version ? `${version.title} ${t('copy')}` : '',
    currentVersion: prop('isCurrentVersion', version),
    material: {},
  };
  return (
    <Form
      drawerName={name}
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {(formik) => {
        const { values } = formik;
        return (
          <>
            <DrawerHeader title={t('drawerTitle')} onClose={onClose}>
              <FormButton
                testId="season-drawer-cancel"
                alwaysEnabled
                variant="destructive"
                size="xs"
                children="global.forms.cancel"
                onClick={() => onCancel()}
              />
              <FormButton
                testId="season-drawer-save"
                children="global.forms.save"
                size="xs"
                type="submit"
                variant="primary"
              />
            </DrawerHeader>
            <DrawerFormContentGrid>
              <Row direction="row">
                <Grid item xs={6}>
                  <FormToggleButtonGroup name="type" data-test-id="type" size="sm" onChange={handleChange}>
                    <ToggleButton value="current">{t('currentAsset')}</ToggleButton>
                    <ToggleButton value="newAsset" disabled={isDirty}>
                      {t('newAsset')}
                    </ToggleButton>
                    {isDirty && (
                      <Box ml={1}>
                        <InfoTooltip iconSize="sm" arrow="right" title={t('unsavedWarning')} opacity={0.3} />
                      </Box>
                    )}
                  </FormToggleButtonGroup>
                </Grid>
                <Grid item xs={3}>
                  <FormCheckbox
                    name="currentVersion"
                    data-test-id="currentVersion"
                    label={`${rootT}.currentVersion`}
                    disabled={values.type === 'newAsset'}
                  />
                </Grid>
              </Row>
              {values.type === 'newAsset' && (
                <>
                  <Row>
                    <FormInputText
                      name="material.title"
                      label={`${rootT}.assetTitle`}
                      data-test-id="asset-title"
                      required
                    />
                  </Row>
                  {/* @TODO: uncomment this when required-rights for material is implemented */}
                  {/* <Row> */}
                  {/*  <FormDropdown */}
                  {/*    name="assetType" */}
                  {/*    label={`${rootT}.assetType`} */}
                  {/*    data-test-id="asset-type" */}
                  {/*    options={[ */}
                  {/*      //@Todo: implement options */}
                  {/*      { id: 1, name: 'option 1' }, */}
                  {/*      { id: 2, name: 'option 2' } */}
                  {/*    ]} */}
                  {/*    valueKey="name" */}
                  {/*    required */}
                  {/*  /> */}
                  {/* </Row> */}
                  {/* <Row> */}
                  {/*  <FormDropdown */}
                  {/*    name="assetRequiredRight" */}
                  {/*    label={`${rootT}.assetRequiredRight`} */}
                  {/*    data-test-id="asset-required-right" */}
                  {/*    options={[ */}
                  {/*      //@Todo: implement options */}
                  {/*      { id: 1, name: 'option 1' }, */}
                  {/*      { id: 2, name: 'option 2' } */}
                  {/*    ]} */}
                  {/*    valueKey="name" */}
                  {/*  /> */}
                  {/* </Row> */}
                </>
              )}
              <Row>
                <FormInputText name="title" label={`${rootT}.versionTitle`} data-test-id="version-title" required />
              </Row>
              <Row direction="row" spacing={2}>
                <Grid item xs={2}>
                  <FormInputFormat
                    data-test-id="version"
                    label={`${rootT}.version`}
                    name="version"
                    required
                    type="string"
                  />
                </Grid>
                <Grid item xs={2}>
                  <FormInputFormat
                    data-test-id="duration"
                    format={TIME_FORMATS.MMSS}
                    label={`${rootT}.duration`}
                    name="duration"
                    type="time"
                  />
                </Grid>
              </Row>
              {values.type === 'newAsset' && (
                <Row>
                  <FormItemsList items={getItems()} />
                </Row>
              )}
            </DrawerFormContentGrid>
          </>
        );
      }}
    </Form>
  );
};

VersionManagerDuplicate.propTypes = {
  name: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  payload: PropTypes.shape({}),
};

export default enhancer(VersionManagerDuplicate);
