/* eslint-disable no-unused-expressions */
/* eslint-disable no-use-before-define */
/* eslint-disable no-nested-ternary */
import c from 'ramda/src/compose';
import isNil from 'ramda/src/isNil';
import join from 'ramda/src/join';
import length from 'ramda/src/length';
import prop from 'ramda/src/prop';
import replace from 'ramda/src/replace';
import splitEvery from 'ramda/src/splitEvery';
import T from 'ramda/src/T';
import { format as formatDate, isValid, parse, startOfDay } from 'date-fns';
import { isEmptyObject } from 'utils/object';
import validate, { validateMax, validateMin } from './validations';

export const FORMATS = {
  CURRENCY: 'currency',
  NUMBER: 'number',
  PERCENTAGE: 'percentage',
  PLAIN_NUMBER: 'plain',
  STRING: 'string',
  TIME: 'time',
};

const API_TIME_FORMAT = 'HH:mm:ss';

export const getPropsForType = ({ type = FORMATS.NUMBER, ...props }) => {
  const getProps = defaultPropsForType[type];
  const propForType = getProps(props);
  return { ...props, ...propForType };
};

const defaultPropsForType = {
  [FORMATS.NUMBER]: ({
    decimalScale = 2,
    fixedDecimalScale = true,
    onChange,
    placeholder,
    separators: [thousandSeparator, decimalSeparator],
    ...props
  }) => ({
    ...props,
    placeholder: isNil(placeholder)
      ? decimalScale
        ? `0${decimalSeparator}${'0'.repeat(decimalScale)}`
        : '0'
      : placeholder,
    decimalScale,
    decimalSeparator,
    fixedDecimalScale,
    thousandSeparator,
    onChange: c(onChange, prop('floatValue')),
  }),
  [FORMATS.CURRENCY]: ({ prefix = '$', ...props }) => getPropsForType({ type: FORMATS.NUMBER, prefix, ...props }),
  [FORMATS.PERCENTAGE]: ({ suffix = '%', ...props }) =>
    getPropsForType({
      type: FORMATS.NUMBER,
      fixedDecimalScale: false,
      suffix,
      ...props,
    }),
  [FORMATS.PLAIN_NUMBER]: ({ onChange, ...props }) => ({
    decimalScale: 0,
    textAlign: 'left',
    ...props,
    onChange: c(onChange, prop('floatValue')),
  }),
  [FORMATS.STRING]: ({ onChange, ...props }) => ({
    decimalScale: 0,
    textAlign: 'left',
    ...props,
    onChange: c(onChange, prop('value')),
  }),
  [FORMATS.TIME]: ({
    direction = 'rtl',
    format = 'mm:ss',
    mask = '_',
    onBlur,
    onChange,
    placeholder,
    value: valueRaw,
    ...props
  }) => {
    const value = valueRaw || '';
    const getValue = () => {
      const dateValue = parse(value, API_TIME_FORMAT, new Date());
      if (isValid(dateValue)) return formatDate(dateValue, format);
      return value;
    };
    return {
      ...props,
      direction,
      format: format.replace(/[a-zA-Z]/g, '#'),
      mask,
      placeholder: placeholder || format.toUpperCase(),
      value: getValue(),
      onChange: ({ formattedValue }) => {
        const date = parse(formattedValue, format, startOfDay(new Date()));
        if (isValid(date)) {
          const formatted = formatDate(date, API_TIME_FORMAT);
          onChange(formatted);
        } else onChange(formattedValue || undefined);
      },
      onBlur: (e) => {
        /**
         * Explanation with example:
         * Input: _1:23
         * 1. Replaces the ':' with empty spaces. eg: _123
         * 2. Takes the length. eg: 4
         * 3. a) Replaces all the non-number occurrencies in value with empty spaces. eg: 123
         *    b) Fill with 0s at the start of the string to match length. eg: 0123
         * 4. Chunks the input. eg: ["01", "23"]
         * 5. Joins it.
         * Output: 01:23
         */
        const parsedValue = c(
          join(':'),
          splitEvery(2),
          (size) => value.replace(/[^0-9]/g, '').padStart(size, '0'),
          length,
          replace(/[:]/g, ''),
        )(value);
        onChange(parsedValue || undefined);
        onBlur && onBlur(e);
      },
    };
  },
};

export const getIsAllowedForType = ({ type = FORMATS.NUMBER, ...props }) => {
  if (isEmptyObject(props)) return {};
  const getProps = defaultPropsForIsAllowed[type];
  const propForType = getProps(props);
  return propForType;
};

const defaultPropsForIsAllowed = {
  [FORMATS.NUMBER]: ({ min, max }) => ({
    isAllowed: ({ floatValue }) => validate([validateMin(min), validateMax(max)], floatValue),
  }),
  [FORMATS.CURRENCY]: ({ min, max }) => getIsAllowedForType({ type: FORMATS.NUMBER, min, max }),
  [FORMATS.PERCENTAGE]: ({ min = 0, max }) => getIsAllowedForType({ type: FORMATS.NUMBER, min, max }),
  [FORMATS.PLAIN_NUMBER]: ({ min, max }) => getIsAllowedForType({ type: FORMATS.NUMBER, min, max }),
  [FORMATS.STRING]: () => ({
    isAllowed: T,
  }),
  [FORMATS.TIME]: () => ({
    isAllowed: T,
  }),
};
