import { isEmpty } from 'lodash';
import { TRACK_TYPES } from 'utils/constants';
import { rootTranslation } from 'utils/format';
import { TableRowText } from 'components/common/Grid/TableRowText';
import { TSearchTrack } from 'services/tracks/types';
import { useAddTrackDrawerFilters } from '../../addTrackDrawerStore';

const tRoot = rootTranslation('drawers.productionSetup.tracks.headers');

const useTrackSearchType = () => {
  const filters = useAddTrackDrawerFilters();
  return (filters.searchQuery && 'song' in (filters.searchQuery?.types ?? {})) || isEmpty(filters.searchQuery)
    ? TRACK_TYPES.SONG
    : TRACK_TYPES.LIBRARY;
};

export function TrackContributorColumn({ track }: { track: TSearchTrack }) {
  const searchQueryType = useTrackSearchType();

  if (searchQueryType === TRACK_TYPES.SONG) {
    return <TableRowText>{track.artists}</TableRowText>;
  }
  return <TableRowText>{track.writers}</TableRowText>;
}

export function RenderTrackContributorHeader() {
  const searchQueryType = useTrackSearchType();

  if (searchQueryType === TRACK_TYPES.SONG) {
    return <span>{tRoot('artist')}</span>;
  }
  return <span>{tRoot('writers')}</span>;
}
