/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import { en, es } from './translations';

const resources = {
  en,
  es,
};
// you can switch language either
// - from chrome://settings/languages, moving the desired language to the top of the list
// - from developers tools: localStorage i18nextLng key

i18n
  .use(
    new LanguageDetector({
      order: ['navigator'],
    }),
  )
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    fallbackLng: 'en',
  });

export default i18n;
