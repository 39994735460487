import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  modal: {
    width: '100%',
  },
  content: {
    width: 400,
    background: 'white',
    padding: '22px 36px',
  },
  select: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  title: {
    marginBottom: theme.spacing(3),
  },
  buttonContainer: {
    marginTop: theme.spacing(4),
  },
}));
