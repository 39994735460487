/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-children-prop */
/* eslint-disable import/no-cycle */
import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { Button, Poster } from 'components/styleguide';
import { useTranslation } from 'react-i18next';
import { getDownloadUrlCall } from 'redux-core/images/services';
import { uploadFileCall } from 'redux-core/images/actions';
import { useFetch, useSnackbar } from 'utils/hooks';
import compose from 'ramda/src/compose';
import includes from 'ramda/src/includes';
import propOr from 'ramda/src/propOr';
import { NORMALIZED_PRODUCTION_TYPES } from 'utils/constants';
import values from 'ramda/src/values';

const isImage = compose(includes('image'), propOr('', 'type'));

const ImageUploader = ({
  acceptedFormats,
  buttonLabel,
  removeLabel,
  buttonSize,
  disabled,
  onChange,
  value,
  uploadable,
  posterSize,
  ButtonWrapper,
  resizingOptions,
  type,
  containerProps,
  testId,
}) => {
  const { t } = useTranslation();
  const ref = useRef();
  const [isUploading, setIsUploading] = useState(false);
  const showSnackbar = useSnackbar();

  const handleChange = async (event) => {
    const {
      target: {
        files: [file],
      },
    } = event;
    if (!isImage(file)) {
      showSnackbar({ message: t('global.forms.files.image') });
      return;
    }
    setIsUploading(true);
    const id = await uploadFileCall(file, { kind: 'IMAGE', resizingOptions });
    onChange(id);
    setIsUploading(false);
  };

  const handleRemove = () => onChange(null);

  const [url] = useFetch(() => getDownloadUrlCall(value), [value], null);

  const poster = <Poster size={posterSize} url={url || undefined} type={type} testId={testId} />;
  if (!uploadable) return poster;

  return (
    <Grid container justify="center" spacing={2} data-testid="image-uploader" {...containerProps}>
      {poster}
      <input
        accept={acceptedFormats.join(', ')}
        id="image-input"
        ref={ref}
        style={{ display: 'none' }}
        type="file"
        onChange={handleChange}
      />
      <ButtonWrapper>
        <Button
          testId={value ? 'image-uploader-remove' : 'image-uploader-upload'}
          size={buttonSize}
          variant="text"
          disabled={disabled}
          isSubmitting={isUploading}
          children={value ? t(removeLabel) : t(buttonLabel)}
          onClick={() => (value ? handleRemove() : ref.current.click())}
        />
      </ButtonWrapper>
    </Grid>
  );
};

ImageUploader.propTypes = {
  acceptedFormats: PropTypes.arrayOf(PropTypes.string),
  buttonLabel: PropTypes.string,
  removeLabel: PropTypes.string,
  buttonSize: PropTypes.string,
  posterSize: PropTypes.oneOfType([
    PropTypes.oneOf(['xs', 'md', 'lg', 'xl']),
    PropTypes.shape({
      width: PropTypes.number,
      height: PropTypes.number,
    }),
  ]),
  uploadable: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.number,
  ButtonWrapper: PropTypes.func,
  resizingOptions: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
  }),
  type: PropTypes.oneOf(values(NORMALIZED_PRODUCTION_TYPES)),
  containerProps: PropTypes.shape({}),
  testId: PropTypes.string,
};

ImageUploader.defaultProps = {
  acceptedFormats: ['image/*'],
  posterSize: 'lg',
  uploadable: true,
  buttonLabel: 'global.forms.uploadPicture',
  removeLabel: 'global.forms.removePicture',
  ButtonWrapper: (p) => <div {...p} />,
};

export default ImageUploader;
