import { makeStyles } from '@material-ui/core/styles';
// eslint-disable-next-line import/no-cycle
import { appColors } from 'components/styleguide';

const useStyles = makeStyles((theme) => ({
  root: ({ hideBorders }) => ({
    display: 'flex',
    height: '100%',
    width: '100%',
    ...(hideBorders && {
      '& .MuiTableCell-root': {
        border: 'none',
      },
    }),
    '& .MuiIcon-root': {
      translate: `transform ${theme.transitions.duration.standard} ease`,
      transform: 'rotate(0deg)',
    },
    '& .MuiTableCell-head[aria-sort=descending] .MuiIcon-root': {
      transform: 'rotate(180deg)',
    },
  }),
  tableWrapper: {
    overflow: 'auto',
    overflowX: 'hidden',
    width: '100%',
  },
  numberCell: {
    paddingRight: theme.spacing(3),
  },
  headerCell: {
    zIndex: 4,
  },
  footerCell: {
    zIndex: 4,
  },
  cell: {
    padding: theme.spacing(1, 2),
  },
  dense: {
    paddingLeft: theme.spacing(),
    paddingRight: theme.spacing(),
  },
  noPadding: {
    padding: 0,
  },
  fixedTable: {
    tableLayout: 'fixed',
  },
  dark: {
    backgroundColor: appColors.lightGrey,
  },
}));

export default useStyles;
