/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-cycle
import { Text } from 'components/styleguide';

export const getMultilineStyles = (ellipsisColumns) => ({
  display: '-webkit-box',
  overflow: 'hidden',
  WebkitBoxOrient: 'vertical',
  /** @see https://caniuse.com/#search=webkit-line-clamp */
  // we should use string instead of number here in order to make it work properly
  WebkitLineClamp: ellipsisColumns.toString(),
  whiteSpace: 'break-spaces',
});

const MultilineText = ({ ellipsisColumns, ...textProps }) => (
  <div data-testid="multiline-text" style={getMultilineStyles(ellipsisColumns)}>
    <Text {...textProps} />
  </div>
);

MultilineText.defaultProps = {
  ellipsisColumns: 2,
};

MultilineText.propTypes = {
  ellipsisColumns: PropTypes.number,
  children: PropTypes.node,
};

export default MultilineText;
