/* eslint-disable import/no-cycle */
import { makeStyles } from '@material-ui/core';
import { appColors } from 'components/styleguide';

export default makeStyles({
  icon: ({ opacity }) => ({
    color: appColors.darkBlue,
    opacity,
  }),
});
