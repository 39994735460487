import { fetchFooterInfo, getSmartFlags } from 'redux-core/overview/actions';
import { baseApi, TBaseEntityDto, TPagination } from 'services';
import { TClearanceStatus } from './clearance-status';
import { TLicensor, TRights } from './licensors';

export enum TrackUseType {
  BI = 'BI',
  BV = 'BV',
  'BI/BV' = 'BI/BV',
  VI = 'VI',
  VV = 'VV',
  ET = 'ET',
  OT = 'OT',
  CT = 'CT',
  FTC = 'FTC',
  FTCBI = 'FTCBI',
  FTCBV = 'FTCBV',
  FTCVI = 'FTCVI',
  FTCVV = 'FTCVV',
  FTO = 'FTO',
  FTOBI = 'FTOBI',
  FTOBV = 'FTOBV',
  FTOVI = 'FTOVI',
  FTOVV = 'FTOVV',
  ST = 'ST',
  TIC = 'TIC',
  TIO = 'TIO',
  TVC = 'TVC',
  TVI = 'TVI',
  BUMP = 'BUMP',
  IND = 'IND',
  DAN = 'DAN',
  OTHER = 'OTHER',
  L = 'L',
  MT = 'MT',
  BG = 'BG',
  VPA = 'VPA',
}

export type TMfn = {
  licensorType: string;
  enabled: boolean;
};

export type TClearance = {
  id: number;
  name: string;
  clearanceStatus: TClearanceStatus;
  qclearTrackId: number;
  manual: boolean;
  auto: boolean;
  expirationDate: Date;
  isOption: boolean;
  option: boolean;
  exercised: boolean;
  active: boolean;
  optionFee: number;
  estimatedFees: number;
  approvedFees: number;
  optionUseDuration: string;
  optionUseType: string;
  grandTotalFee: number;
  mfn: TMfn[];
  licensors?: TLicensor[];
  qclearLicensors?: TLicensor[];
  rights: TRights[];
  qclearRights?: TRights[];
  rightsName: string;
  descriptionOnLetters?: string;
  isEditable: boolean;
  using?: boolean;
  used?: boolean;
  bundleId?: number;
  isUnassigned?: boolean;
};

export type TClearanceStatusResponse = TBaseEntityDto & {
  color: string;
};

type TLicensorResponse = TBaseEntityDto & {
  approvedFees?: number;
  quotedFees?: number;
  recordLabels?: TBaseEntityDto[];
};

type TRightsResponse = {
  id: number;
  name: string;
  term: number;
};

type TOptionResponse = TBaseEntityDto & {
  optionFee: number;
};

type TCueContainerResponse = {
  id: number;
  title: string;
  description?: string;
};

type TTrack = {
  id: number;
  title: string;
  objectId: number;
  using: boolean;
  notes?: string;
  tags?: string[];
  subprojectId?: number;
};

type TProduction = TBaseEntityDto & {
  type: 'FilmRelease' | 'Episode' | 'Campaign';
};

export type TGetClearanceClearancesView = {
  id: number;
  name: string;
  track: TTrack;
  division: TBaseEntityDto;
  production: TProduction;
  project: TBaseEntityDto;
  artists?: TBaseEntityDto[];
  clearanceStatus?: TClearanceStatusResponse;
  masterLicensors?: TLicensorResponse[];
  syncLicensors?: TLicensorResponse[];
  writers?: TBaseEntityDto[];
  publishers?: TBaseEntityDto[];
  rightsName: string;
  rights: TRightsResponse[];
  options?: TOptionResponse[];
  cueContainers: TCueContainerResponse[];
};

export type TGetAllClearancesViewFilters = {
  divisionIds?: number[];
  projectIds?: number[];
  productionIds?: number[];
  cueContainerTitles?: string[];
  trackTitles?: string[];
  artistNames?: string[];
  statuses?: string[];
  masterLicensorNames?: string[];
  labelNames?: string[];
  syncLicensorNames?: string[];
  publisherNames?: string[];
  writerNames?: string[];
  using?: boolean;
  tags?: string[];
  smartFlags?: string[];
};

export type TGetAllClearancesViewPayload = {
  page: number;
  limit: number;
  filters?: TGetAllClearancesViewFilters;
  searchTerm?: string;
  showInactiveProductions?: boolean;
};

export type TGetAllClearancesViewResponse = {
  data: TGetClearanceClearancesView[];
  pagination: TPagination;
};

export const clearanceApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    createClearance: builder.mutation<Partial<TClearance>, Partial<TClearance>>({
      query: (body) => ({ url: 'clear/clearances/create', method: 'POST', body }),
      invalidatesTags: (_, _2, { qclearTrackId }) => [{ type: 'clearances', qclearTrackId }],
    }),
    updateClearancesCues: builder.mutation<
      Partial<void>,
      { trackId: number; cues: [{ id: number; deleted: boolean; qclearClearanceId: number; cueContainerId: number }] }
    >({
      query: (body) => ({ url: 'clear/clearances/update-clearances-cues', method: 'POST', body }),
    }),
    updateActive: builder.mutation<Partial<void>, { id: number; active: boolean; clearanceBundleId: number }>({
      query: (body) => ({ url: 'clear/clearances/update-active', method: 'POST', body }),
      invalidatesTags: ['clearanceBundle'],
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        await queryFulfilled;
        dispatch(fetchFooterInfo());
        dispatch(getSmartFlags());
      },

    }),
    update: builder.mutation<Partial<void>, { id: number; oneStopCombined: boolean }>({
      query: (body) => ({ url: 'clear/clearances/update', method: 'POST', body }),
      invalidatesTags: ['clearanceBundle', 'getTrack'],
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        await queryFulfilled;
        dispatch(fetchFooterInfo());
        dispatch(getSmartFlags());
      },
    }),
    getClearances: builder.query<TClearance[], number>({
      query: (trackId) => ({ url: 'clear/clearances/get-all', method: 'POST', body: { trackId } }),
      providesTags: ['clearances'],
    }),
    getActiveClearances: builder.query<TClearance[], number>({
      query: (trackId) => ({ url: 'clear/clearances/get-all', method: 'POST', body: { trackId, activeOnly: true } }),
      providesTags: ['clearances'],
      keepUnusedDataFor: 0,
    }),
    getAllClearancesView: builder.query<TGetAllClearancesViewResponse, TGetAllClearancesViewPayload>({
      query: (body) => ({
        url: 'clear/clearances/get-all-clearances-view',
        method: 'POST',
        body,
      }),
      providesTags: ['clearancesView'],
      keepUnusedDataFor: 0,
    }),
  }),
});

export const {
  useCreateClearanceMutation,
  useUpdateClearancesCuesMutation,
  useUpdateMutation,
  useUpdateActiveMutation,
  useGetClearancesQuery,
  useGetActiveClearancesQuery,
  useGetAllClearancesViewQuery,
} = clearanceApi;
