import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
  icon: {
    padding: 0,
    margin: theme.spacing(),
    marginLeft: 0,
  },
  nested: { padding: theme.spacing(0.25, 2) },
  qclearDetails: { margin: 'auto' },
  qTracksDetails: { cursor: 'pointer' },
  container: { width: '100%' },
}));

export default styles;
