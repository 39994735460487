/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react/no-children-prop */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useFetch } from 'utils/hooks';
import { Grid, Divider, Typography } from '@material-ui/core';
import { Button, BaseDrawer, DrawerHeader } from 'components/styleguide';
import { useTranslation } from 'react-i18next';
import { getPersonnelPositionsCall } from 'redux-core/divisions/services';
import EditDeleteMenuWrapperGrid from 'components/common/EditDeleteMenuWrapperGrid';
import { is, pick, prop, append, remove } from 'ramda';
import { concatStrings } from 'utils/index';
import enhancer from './enhancer';
// eslint-disable-next-line import/no-named-as-default
import DRAWERS, { CommonDrawerPropTypes } from '../constants';

const rootT = 'drawers.productionSetup.personnel';

const PersonnelDrawer = (props) => {
  const { t } = useTranslation();
  const {
    classes,
    personnelList = [],
    onClose,
    openDrawer,
    setupInfo,
    keepPersonnelInfo,
    keepPersonnelForm,
    payload: { onSubmit, readOnly },
  } = props;
  const [editableIndex, setEditableIndex] = useState(null);

  const [positions] = useFetch(() => getPersonnelPositionsCall(pick(['tenantId'])(setupInfo)));

  const handleSubmit = (values) => {
    keepPersonnelInfo(values);
    onSubmit({ keyPersonnel: values });
  };

  const handleClose = () => {
    keepPersonnelInfo([]);
    onClose();
  };
  const handleSecondarySubmit = async (values) => {
    await handleSubmit(values);
    setEditableIndex(null);
  };
  return (
    <>
      <DrawerHeader title={t(`${rootT}.drawerTitle`)} onClose={handleClose}>
        {!readOnly && (
          <Button
            testId="personnel-drawer-add"
            children={t('global.forms.add')}
            size="xs"
            type="button"
            variant="primary"
            onClick={() =>
              openDrawer(DRAWERS.PERSONNEL_ADD_KEY_PERSONNEL, {
                onSubmit: (keyPersonnel) => {
                  handleSubmit(append(keyPersonnel, personnelList));
                },
                personnelList,
              })
            }
          />
        )}
      </DrawerHeader>
      <Grid container direction="column" alignItems="center">
        {personnelList.map((person, index) => {
          const position = positions.find((position) => position.id === Number(person.position));

          return (
            <React.Fragment key={`person-${index}`}>
              <EditDeleteMenuWrapperGrid
                hoverAlignItems="center"
                disableHover={readOnly || is(Number, editableIndex)}
                setEditable={() => {
                  setEditableIndex(index);
                  keepPersonnelForm(person);
                  openDrawer(DRAWERS.PERSONNEL_ADD_KEY_PERSONNEL, {
                    onSubmit: (keyPersonnel) => {
                      const payload = [...personnelList];
                      payload[index] = keyPersonnel;
                      handleSecondarySubmit(payload);
                    },
                    personnelList,
                  });
                }}
                onConfirm={() => {
                  const payload = remove(editableIndex, 1, [...personnelList]);
                  handleSecondarySubmit(payload);
                }}
                content={t(`${rootT}.delete`, {
                  name: concatStrings(' ')(person.firstName, person.lastName),
                })}
              >
                <Grid container item className={classes.personnelInfoContainer}>
                  <Grid container item direction="column" xs={6}>
                    <Typography variant="inherit" className={classes.personnelName} gutterBottom>
                      {concatStrings(' ')(person.firstName, person.lastName)}
                    </Typography>
                    <Typography variant="inherit" gutterBottom>
                      {person.phoneNumber}
                    </Typography>
                  </Grid>
                  <Grid container item direction="column" xs={6}>
                    <Typography variant="inherit" gutterBottom>
                      {person.email}
                    </Typography>
                    <Grid container item justify="space-between">
                      <Typography variant="inherit" gutterBottom>
                        {prop('name', position)}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Divider className={classes.divider} />
              </EditDeleteMenuWrapperGrid>
            </React.Fragment>
          );
        })}
      </Grid>
    </>
  );
};

PersonnelDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
  payload: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  openDrawer: PropTypes.func.isRequired,
  personnelList: PropTypes.array,
  setupInfo: PropTypes.object.isRequired,
  keepPersonnelInfo: PropTypes.func,
  keepPersonnelForm: PropTypes.func,
};

const Drawer = ({ open, onClose, ...props }) => (
  <BaseDrawer open={open} onClose={onClose}>
    <PersonnelDrawer onClose={onClose} {...props} />
  </BaseDrawer>
);

Drawer.propTypes = CommonDrawerPropTypes;

export default enhancer(Drawer);
