import compose from 'ramda/src/compose';
import { connect } from 'react-redux';
import { setLicensingFilter, clearFilters } from 'redux-core/licensing/actions';
import { getLicensingFilters } from 'redux-core/licensing/selectors';
import { getDivisionListSelector } from 'redux-core/divisions/selectors';
import { getLocationParametersDivisionId } from 'redux-core/router/selectors';
import { getSelectedProductionIdSelector, getSelectedProductionTypeSelector } from 'redux-core/overview/selectors';
import withBaseDrawer from '../withBaseDrawer';

const mapStateToProps = (state) => ({
  divisionId: getLocationParametersDivisionId(state),
  filters: getLicensingFilters(state),
  divisions: getDivisionListSelector(state),
  productionId: getSelectedProductionIdSelector(state),
  productionType: getSelectedProductionTypeSelector(state),
});

const mapDispatchToProps = {
  setLicensingFilter,
  clearFilters,
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withBaseDrawer);
