import { useFormContext, useWatch } from 'react-hook-form';
import { isEmpty } from 'lodash';
import { usePrompt } from 'utils/hooks';
import { Button, ButtonProps } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { rootTranslation } from 'utils/format';
import { useFormIsDirty, useSetAddTrackDrawerFilters } from './addTrackDrawerStore';

const t = rootTranslation('drawers.productionSetup.tracks');
const tGlobal = rootTranslation('global');

const fieldsToReset = ['type', 'library', 'artist', 'oneStopCombined', 'rightsOwners'];

export function BackToSearchButton({ variant }: { variant: ButtonProps['variant'] }) {
  const { setValue, resetField, clearErrors } = useFormContext();
  const openPrompt = usePrompt();
  const setAddTrackDrawerFilters = useSetAddTrackDrawerFilters();
  const rightsOwners = useWatch({ name: 'rightsOwners' });
  const [, setFormIsDirty] = useFormIsDirty();

  const rightsOwnersIsDirty = !isEmpty(rightsOwners);

  setFormIsDirty(rightsOwnersIsDirty);

  const handleNavigateBack = () => {
    clearErrors();
    setValue('contentMode', 'search');
    setAddTrackDrawerFilters({ searchQuery: null });
  };

  return (
    <Button
      variant={variant}
      color="secondary"
      size="small"
      startIcon={<ArrowBackIcon />}
      onClick={() => {
        if (rightsOwnersIsDirty) {
          openPrompt({
            content: tGlobal('forms.unsavedChanges'),
            onConfirm: () => {
              handleNavigateBack();

              fieldsToReset.forEach((fieldName) => resetField(fieldName));
            },
          });
        } else {
          handleNavigateBack();
        }
      }}
    >
      {t('filter.backToSearch')}
    </Button>
  );
}
