import withBaseDrawer from 'components/common/Drawers/withBaseDrawer';
import compose from 'ramda/src/compose';
import { connect } from 'react-redux';
import { getSelectedProductionIdSelector } from 'redux-core/overview/selectors';
import { openPrompt } from 'redux-core/prompt/actions';

const mapStateToProps = (state) => ({
  productionId: getSelectedProductionIdSelector(state),
});

const mapDispatchToProps = { openPrompt };

export default compose(connect(mapStateToProps, mapDispatchToProps), withBaseDrawer);
