import { styled } from '@mui/material';
import { newTheme } from 'components/styleguide';
import { ReactText } from 'react';
import { cssTransition, toast, ToastContainer, ToastOptions } from 'react-toastify';

const Fade = cssTransition({
  enter: 'fade-in-enter',
  exit: 'fade-out-exit',
  collapseDuration: 0,
});

export const toastDefaultConfig: ToastOptions = {
  position: toast.POSITION.TOP_RIGHT,
  closeOnClick: false,
  pauseOnHover: true,
  draggable: false,
  hideProgressBar: true,
  theme: 'colored',
  transition: Fade,
};

export function showSuccessToast(message: string, options?: Partial<ToastOptions>): ReactText {
  return toast.success(message, { ...toastDefaultConfig, ...options });
}

export function showErrorToast(message: string, options?: Partial<ToastOptions>): ReactText {
  return toast.error(message, { ...toastDefaultConfig, ...options });
}

export function dismissToast(id: string | number) {
  toast.dismiss(id);
}

/**
 * Accessing the theme file directly because MUI theme cannot be accessed directly
 * within the styled component for ToastContainer. This is due to ToastContainer
 * passing its own props, which includes a 'theme' property that conflicts with the MUI theme.
 */
export const StyledToastContainer = styled(ToastContainer)({
  '.Toastify__toast-theme--colored.Toastify__toast--success': {
    backgroundColor: newTheme.palette.success.main,
  },
  '.Toastify__toast-theme--colored.Toastify__toast--warning': {
    backgroundColor: newTheme.palette.warning.main,
  },
  '.Toastify__toast-theme--colored.Toastify__toast--error': {
    backgroundColor: newTheme.palette.error.dark,
  },
});
