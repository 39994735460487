import { handleActions } from 'redux-actions';
import omit from 'ramda/src/omit';
import assoc from 'ramda/src/assoc';
import set from 'ramda/src/set';
import lensPath from 'ramda/src/lensPath';
import is from 'ramda/src/is';
import propOr from 'ramda/src/propOr';
import { getIn, setIn } from 'formik';
import { isEmptyObject } from 'utils/object';
import * as actions from './actions';

const defaultState = {};

const reducer = {
  [actions.saveForm]: (state, { payload: { formId, ...payload } }) => {
    if (!formId) return state;
    const form = {
      isEditing: payload.isEditing || true,
      dirty: payload.dirty || false,
      values: payload.values || {},
    };
    return assoc(formId, form, state);
  },
  /** Update the whole { values } key at once */
  [actions.saveValues]: (state, { payload: { formId, ...values } }) => {
    if (!formId) return state;
    const form = getIn(state, formId);
    const nextForm = {
      ...form,
      dirty: true,
      values: {
        ...form.values,
        ...values,
        dirty: true,
      },
    };
    return set(lensPath([formId]), nextForm)(state);
  },
  /** Update one value / value.path at a time */
  [actions.updateIn]: (state, { payload: { path = '', value, formId } }) => {
    if (!formId) return state;
    const form = getIn(state, formId);
    if (isEmptyObject(form)) return state;

    const pathToUpdate = is(Array, path) ? path.join('.') : path;
    const nextValues = propOr({}, 'values', setIn(form, `values.${pathToUpdate}`, value));
    const nextForm = {
      ...form,
      dirty: true,
      values: {
        ...nextValues,
        dirty: true,
      },
    };
    return set(lensPath([formId]), nextForm)(state);
  },
  [actions.clearForm]: (state, { payload: { formId } }) => omit([formId], state),
  [actions.clearForms]: () => defaultState,
};

export default handleActions(reducer, defaultState);
