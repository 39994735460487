/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import { Text } from 'components/styleguide';
import { getBreadcrumbString } from 'utils/format';

function Breadcrumbs({ defaultLabel, joinBy, path, ...rest }) {
  const breadcrumbs = getBreadcrumbString(path, joinBy);

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Text {...rest}>{breadcrumbs || defaultLabel}</Text>;
}

Breadcrumbs.propTypes = {
  path: PropTypes.array.isRequired,
  defaultLabel: PropTypes.string,
  joinBy: PropTypes.string,
};

Breadcrumbs.defaultProps = {
  path: [],
  joinBy: ' > ',
};

export default Breadcrumbs;
