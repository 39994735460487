import { FormInput } from './ProductionDrawerChild';

type ParseFormOutputArgs = FormInput & {
  project: {
    project: string;
    isNew: boolean;
  };
};

function containsOnlyNumbers(str: string) {
  return /^\d+$/.test(str);
}

export function parseFormOutput(values: ParseFormOutputArgs) {
  const { project } = values;

  const projectValue = project.project;
  const isAnExistentProjectId = containsOnlyNumbers(projectValue);

  return {
    ...values,
    project: {
      ...project,
      project: isAnExistentProjectId ? Number(projectValue) : projectValue,
    },
  };
}
