/* eslint-disable */
import i18next from 'i18next';
import { createClearanceCall, updateClearanceCall } from './services';
import { requestWithError } from 'utils/request';
import prop from 'ramda/src/prop';
import { getLocationParametersSelector } from 'redux-core/router/selectors';
import { upsertRights } from 'redux-core/rights/services';

/**
 * Create Option
 */
export const createClearanceOption =
  ({ rights, ...payload }) =>
  async (_, getState) => {
    const flowInfo = getLocationParametersSelector(getState());
    const body = {
      ...payload,
      isOption: true,
      divisionId: prop('divisionId', flowInfo),
      projectId: prop('projectId', flowInfo),
    };

    const newOption = await requestWithError(createClearanceCall, body, {
      message: {
        success: i18next.t('tracks.notifications.option.create.success'),
        failed: i18next.t('tracks.notifications.option.create.failed'),
      },
    });
    await upsertRights({
      divisionId: body.divisionId,
      clearanceId: newOption.id,
      rights,
    });

    return newOption;
  };

/**
 * Update Option
 */
export const updateClearanceOption =
  ({ rights, ...payload } = {}) =>
  async (_, getState) => {
    const body = {
      ...payload,
      isOption: true,
    };
    const updatedOption = await requestWithError(updateClearanceCall, body, {
      message: {
        success: i18next.t('tracks.notifications.option.update.success'),
        failed: i18next.t('tracks.notifications.option.update.failed'),
      },
    });
    const state = getState();
    const flowInfo = getLocationParametersSelector(state);
    await upsertRights({
      divisionId: prop('divisionId', flowInfo),
      clearanceId: updatedOption.id,
      rights,
    });

    return updatedOption;
  };
