import { MouseEvent, useState } from 'react';
import { FormProvider, useFieldArray, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { isEmpty } from 'lodash';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { Button } from '@mui/material';
import { rootTranslation } from 'utils/format';
import { useSearchTracksQuery } from 'services/tracks/tracks';
import { TSearchTracksTypes } from 'services/tracks/types';
import { TCreateTrackRequest } from 'services/clear/types';
import { TRACK_CUE_POPOVER_COOKIE, TRACK_TYPES } from 'utils/constants';
import { useWatchRightsOwnersField } from 'components/common/rightOwners/useWatchRightsOwners';
import CuePopover from 'components/common/CuePopover';
import { useLocalStorage, usePrompt } from 'utils/hooks';
import { BaseDrawerPanel } from './BaseDrawerPanel';
import { useAddTrackDrawerFilters, useSetAddTrackDrawerFilters } from './addTrackDrawerStore';
import { TAddTrackForm, TSetDurationAndUsagePopoverSubmit } from './types';
import { SearchContent } from './search/SearchContent';
import { CreateContent } from './create/CreateContent';
import { SearchHeader } from './search/SearchHeader';
import { CreateHeader } from './create/CreateHeader';
import { useDuplicateTrack } from './hooks/useDuplicateTrack';

const t = rootTranslation('drawers.productionSetup.tracks');
const tGlobal = rootTranslation('global');

const validationSchema = Yup.object().shape({
  title: Yup.string().when(['contentMode'], (contentMode, schema) =>
    contentMode === 'search' ? schema : schema.required(),
  ),
  rightsOwners: Yup.array().when(['contentMode'], (contentMode, schema) =>
    contentMode === 'search'
      ? schema.min(0)
      : schema.min(0).of(
        Yup.object().shape({
          name: Yup.string()
            .nullable()
            .test(
              'is-null',
              'drawers.productionSetup.tracks.createLicensors.licensorNameRequired',
              (value) => value !== null,
            )
            .required('drawers.productionSetup.tracks.createLicensors.licensorNameRequired'),
        }),
      ),
  ),
  cueContainerId: Yup.number().required('drawers.productionSetup.tracks.createTrack.scenePicker.cueContainerRequired'),
});

type UnifiedAddTrackDrawerProps = {
  onClose: () => void;
  onSubmit: (values: Partial<TCreateTrackRequest>) => Promise<void>;
  cueContainerId: number | null;
  generateOptionId: () => string;
  useCueContainerPickerTreeView?: boolean;
};

export function UnifiedAddTrackDrawer({
  onSubmit,
  onClose,
  cueContainerId,
  generateOptionId,
  useCueContainerPickerTreeView = false,
}: UnifiedAddTrackDrawerProps) {
  const openPrompt = usePrompt();
  const filters = useAddTrackDrawerFilters();
  const setAddTrackDrawerFilters = useSetAddTrackDrawerFilters();
  const [hideTrackCuePopover, setTrackCuePopover] = useLocalStorage(TRACK_CUE_POPOVER_COOKIE, false);
  const [anchorEl, setAnchorEl] = useState(null);

  const searchTracksQueryResult = useSearchTracksQuery(
    { ...filters.searchQuery, page: filters.page + 1, limit: filters.limit },
    { skip: isEmpty(filters.searchQuery) },
  );

  const formBag = useForm<TAddTrackForm>({
    resolver: yupResolver(validationSchema),
    mode: 'onBlur',
    defaultValues: {
      contentMode: 'search',
      cueContainerId,
      cueContainerPath: null,
      type: 'song',
      library: null,
      title: '',
      artist: null,
      oneStopCombined: false,
      rightsOwners: [],
      optionsToUpdateHidden: [],
    },
  });

  const contentMode = useWatch({
    name: 'contentMode',
    control: formBag.control,
  });
  const inSearchMode = contentMode === 'search';
  const inCreateMode = contentMode === 'create';

  const { fields, append, remove } = useFieldArray({
    control: formBag.control,
    name: 'rightsOwners',
  });

  const { duplicateTrack } = useDuplicateTrack(formBag, generateOptionId, append);

  useWatchRightsOwnersField(formBag);

  const { dirtyFields } = formBag.formState;
  const isRightsOwnersDirty = Object.hasOwn(dirtyFields, 'rightsOwners');

  const handleClose = () => {
    if (inCreateMode && isRightsOwnersDirty) {
      openPrompt({
        content: tGlobal('forms.unsavedChanges'),
        onConfirm: onClose,
      });
    } else {
      onClose();
    }
  };

  const performSearch = () => {
    const values = formBag.getValues();
    if (values?.cueContainerId == null) {
      formBag.setError('cueContainerId', {
        type: 'manual',
        message: 'drawers.productionSetup.tracks.createTrack.scenePicker.cueContainerRequired',
      });
    } else {
      const types =
        values.type === TRACK_TYPES.LIBRARY
          ? ({ [TRACK_TYPES.LIBRARY]: true, [TRACK_TYPES.SOUND_EFFECT]: true } as TSearchTracksTypes)
          : ({ [values.type]: true } as TSearchTracksTypes);
      const searchFilters = normalizeSearchFields(values);

      setAddTrackDrawerFilters({
        searchQuery: { types, filters: searchFilters },
      });
    }
  };

  const handleCreate = async (usageForm?: TSetDurationAndUsagePopoverSubmit) => {
    const { hide, ...cueValues } = usageForm;
    if (hide) {
      setTrackCuePopover(true);
    }

    const formValues = formBag.getValues();

    /**
     * This conditional is to make the TS compiler happy since we have a union type for the form
     */
    if (formValues.contentMode === 'create') {
      const payload = {
        cueContainerId: formValues.cueContainerId,
        type: formValues.type,
        title: formValues.title,
        ...(formValues.artist?.id && { qwireTracksArtistId: formValues.artist.id }),
        ...(formValues.library?.id && {
          library: {
            qwireTracksLibraryId: formValues.library.id,
          },
        }),
        ...(cueValues.duration && { duration: cueValues.duration }),
        ...(cueValues.useType && { useType: cueValues.useType }),
        durationFull: cueValues.durationFull,
        ...(!isEmpty(formValues.rightsOwners) && {
          rightsOwners: normalizeRightsOwners(formValues.rightsOwners, formValues.oneStopCombined),
        }),
        oneStopCombined: formValues.oneStopCombined,
        optionsToUpdateHidden: formValues?.optionsToUpdateHidden,
      };

      await onSubmit(payload);

      // @ts-ignore
      window.freshpaint.track('Created New Performance', payload);

      setAnchorEl(null);

      formBag.reset({
        contentMode: 'create',
        type: 'song',
        library: null,
        title: '',
        artist: null,
        oneStopCombined: false,
        rightsOwners: [],
        optionsToUpdateHidden: [],
      });
    }
  };

  const handleSaveTrack = async (event: MouseEvent<HTMLButtonElement>) => {
    formBag.handleSubmit(() => {
      if (hideTrackCuePopover) {
        handleCreate();
        return;
      }
      setAnchorEl(event.target);
      window.freshpaint.track('Clicked Save & Add Track');
    })();
  };

  return (
    <FormProvider {...formBag}>
      {inSearchMode ? (
        <BaseDrawerPanel
          title={t('drawerTitle')}
          headerComponent={
            <SearchHeader
              searchTracksQueryResult={searchTracksQueryResult}
              performSearch={performSearch}
              searchQuery={filters?.searchQuery}
              useCueContainerPickerTreeView={useCueContainerPickerTreeView}
            />
          }
          actionsComponent={
            <>
              <Button color="secondary" variant="outlined" onClick={handleClose}>
                {tGlobal('cancel')}
              </Button>
              <Button color="primary" variant="contained" onClick={handleClose}>
                {tGlobal('forms.done')}
              </Button>
            </>
          }
        >
          <SearchContent
            searchTracksQueryResult={searchTracksQueryResult}
            searchQuery={filters?.searchQuery}
            duplicateTrack={duplicateTrack}
          />
        </BaseDrawerPanel>
      ) : (
        <BaseDrawerPanel
          title={t('drawerTitle')}
          headerComponent={
            <CreateHeader
              remove={remove}
              append={append}
              generateOptionId={generateOptionId}
              useCueContainerPickerTreeView={useCueContainerPickerTreeView}
            />
          }
          actionsComponent={
            <>
              <Button color="secondary" variant="outlined" onClick={handleClose}>
                {tGlobal('cancel')}
              </Button>
              <LoadingButton
                data-testid="button-saveTrack"
                color="primary"
                variant="contained"
                loading={formBag.formState.isSubmitting}
                onClick={handleSaveTrack}
              >
                {tGlobal('forms.save')}
              </LoadingButton>
            </>
          }
        >
          <CreateContent fields={fields} remove={remove} />
        </BaseDrawerPanel>
      )}
      <CuePopover
        anchorEl={anchorEl}
        id="create-track-cue-popover"
        onSubmit={handleCreate}
        setAnchorEl={setAnchorEl}
        transformOriginH="right"
      />
    </FormProvider>
  );
}

function normalizeSearchFields(values: TAddTrackForm) {
  const cueContainerId = values?.cueContainerId ?? null;

  return Object.entries(values).reduce((acc, [key, value]) => {
    if (['title', 'artist'].includes(key) && !isEmpty(value)) {
      return {
        ...acc,
        [`${key}Term`]: value,
      };
    }
    if (key === 'library' && !isEmpty(value)) {
      return {
        ...acc,
        partnerTermExact: values[key].name,
      };
    }
    if (key === 'cueContainerId' && cueContainerId !== undefined) {
      return {
        ...acc,
        cueContainerId,
      };
    }
    return acc;
  }, {});
}

function normalizeRightsOwners(rightsOwners, oneStopCombined) {
  if (oneStopCombined) {
    return rightsOwners.map((it) => {
      if (it.type === 'one_stop') {
        return { ...it, type: 'sync' };
      }
      return it;
    });
  }
  return rightsOwners;
}
