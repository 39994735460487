/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/no-children-prop */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FieldArray } from 'formik';
import Form from 'components/common/Form';
import { FormButton } from 'components/common/Form/Fields';
import MultiInputEditField from 'components/common/Form/Fields/FormMultiInputEditField';
import { Divider, Grid } from '@material-ui/core';
import { BaseDrawer } from 'components/styleguide';
import { useTranslation } from 'react-i18next';
import propOr from 'ramda/src/propOr';
import is from 'ramda/src/is';
import remove from 'ramda/src/remove';
import * as Yup from 'yup';
import BreadcrumsDrawerHeader from 'components/common/BreadcrumsDrawerHeader';
import withStyles from './styles';
import EditDeleteMenuWrapperGrid from '../../EditDeleteMenuWrapperGrid';

const rootT = 'drawers.productionSetup.akaTitles';
const rootTProd = 'drawers.productionSetup.drawers';

const validationSchema = Yup.object().shape({
  akaTitles: Yup.array().of(
    Yup.object().shape({
      territory: Yup.string().required('global.forms.validations.required'),
      title: Yup.string().required('global.forms.validations.required'),
    }),
  ),
});

function AkaTitlesDrawer({ name, classes, onClose, payload: { onSubmit, akaTitles = [], parentName, readOnly } }) {
  const { t } = useTranslation();
  const [editableIndex, setEditableIndex] = useState(null);
  const [isAdding, setIsAdding] = useState(false);
  const [editingFieldCache, setEditingFieldCache] = useState(null);

  return (
    <Form
      drawerName={name}
      onSubmit={(values) => {
        onSubmit(values);
        setEditableIndex(null);
        setIsAdding(false);
      }}
      enableReinitialize
      initialValues={{
        akaTitles: akaTitles || [],
      }}
      validationSchema={validationSchema}
      readOnly={readOnly}
    >
      {(formik) => (
        <FieldArray name="akaTitles">
          {(fieldArrayProps) => (
            <>
              <BreadcrumsDrawerHeader
                currentStep={t(`${rootT}.drawerTitle`)}
                prevStep={parentName || t(`${rootTProd}.newProject`)}
                onClose={onClose}
              >
                {editableIndex !== null ? (
                  <>
                    <FormButton
                      alwaysEnabled
                      variant="destructive"
                      size="xs"
                      children={t('global.forms.cancel')}
                      testId="aka-titles-drawer-cancel"
                      onClick={() => {
                        isAdding
                          ? fieldArrayProps.remove(0)
                          : fieldArrayProps.replace(editableIndex, editingFieldCache);
                        setIsAdding(false);
                        setEditableIndex(null);
                      }}
                    />
                    <FormButton
                      testId="aka-titles-drawer-save"
                      children={t('global.forms.save')}
                      size="xs"
                      type="submit"
                      variant="primary"
                    />
                  </>
                ) : (
                  <FormButton
                    alwaysEnabled
                    testId="aka-titles-drawer-add"
                    children={t('global.forms.add')}
                    size="xs"
                    variant="primary"
                    onClick={() => {
                      fieldArrayProps.unshift({ territory: '', title: '' });
                      setIsAdding(true);
                      setEditableIndex(0);
                    }}
                  />
                )}
              </BreadcrumsDrawerHeader>
              <Grid container>
                {formik.values.akaTitles.map((aka, index) => (
                  <>
                    <EditDeleteMenuWrapperGrid
                      disableHover={readOnly || is(Number, editableIndex)}
                      setEditable={() => {
                        setEditingFieldCache(formik.values.akaTitles[index]);
                        setEditableIndex(index);
                      }}
                      key={propOr('id', 'new', aka.title) + index}
                      onConfirm={() => {
                        onSubmit({
                          akaTitles: remove(editableIndex, 1, formik.values.akaTitles),
                        });

                        fieldArrayProps.remove(editableIndex);
                        setEditableIndex(null);
                      }}
                      content={t(`${rootT}.delete`, aka)}
                    >
                      <MultiInputEditField
                        name={`akaTitles.${index}`}
                        inputOneProps={{
                          placeholder: t(`${rootT}.placeholderCountry`),
                          className: classes.labelColor,
                        }}
                        fieldOne={{ name: 'territory', editable: true }}
                        inputTwoProps={{
                          placeholder: t(`${rootT}.placeholderTitle`),
                        }}
                        fieldTwo={{ name: 'title', editable: true }}
                        editable={editableIndex === index}
                      />
                    </EditDeleteMenuWrapperGrid>
                    <Divider className={classes.divider} />
                  </>
                ))}
              </Grid>
            </>
          )}
        </FieldArray>
      )}
    </Form>
  );
}

AkaTitlesDrawer.propTypes = {
  name: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  payload: PropTypes.object.isRequired,
  classes: PropTypes.object,
};

function Drawer({ open, onClose, ...props }) {
  return (
    <BaseDrawer open={open} onClose={onClose}>
      <AkaTitlesDrawer onClose={onClose} {...props} />
    </BaseDrawer>
  );
}

Drawer.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default withStyles(Drawer);
