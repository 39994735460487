/* eslint-disable react/no-children-prop */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { DrawerHeader, Text } from 'components/styleguide';
import Grid from '@material-ui/core/Grid';
import { FormButton, FormCheckbox, FormInputText } from 'components/common/Form/Fields';
import Form from 'components/common/Form';
import { useRootTranslation, useFetch } from 'utils/hooks';
import {
  createCueSheetVersionCall,
  deleteCueSheetVersionCall,
  duplicateCueSheetVersionCall,
  getCueSheetVersionCall,
  updateCueSheetVersionCall,
} from 'redux-core/cueSheets/services';
import { requestWithError } from 'utils/request';
import DeleteIcon from '@material-ui/icons/Delete';
import dayjs from 'dayjs';
import enhancer from './enhancer';

const rootT = 'drawers.cueSheetVersion';

const validationSchema = Yup.object().shape({
  title: Yup.string().required('global.forms.validations.required'),
});

const CueSheetVersionDrawer = ({
  onClose,
  openPrompt,
  payload: { duplicate, id, onSubmit, assetId },
  productionId,
}) => {
  const t = useRootTranslation(rootT);

  const [cueSheetVersion, loading] = useFetch(() => id && getCueSheetVersionCall({ id }), [id], {});

  const handleSubmit = async (values) => {
    const payload = {
      ...values,
      // Only send setAsCurrent true if the isCurrentCueSheetVersion changed from false to true
      setAsCurrentCueSheetVersion: !cueSheetVersion.isCurrentCueSheetVersion && values.isCurrentCueSheetVersion,
      ...(id && duplicate ? { id: values.currentCueContainerVersionId } : {}),
    };
    const call = id
      ? duplicate
        ? duplicateCueSheetVersionCall
        : updateCueSheetVersionCall
      : createCueSheetVersionCall;
    const success = id
      ? duplicate
        ? t('notifications.duplicate')
        : t('notifications.edit')
      : t('notifications.create');
    const response = await requestWithError(call, payload, {
      message: { success },
    });
    onSubmit && onSubmit(response);
    onClose();
  };

  const onDelete = ({ title }) => {
    openPrompt({
      content: t('notifications.deletePrompt', { title }),
      onConfirm: async () => {
        await deleteCueSheetVersionCall({ id });
        onSubmit && onSubmit();
        onClose();
      },
    });
  };

  const drawerTitle = id ? (duplicate ? t('duplicateVersion') : t('editVersion')) : t('newVersion');

  const defaultVersionTitle = loading
    ? ''
    : t('defaultVersion', {
        date: dayjs().format('MM-DD-YYYY'),
      });
  const defaultDuplicateTitle = loading
    ? ''
    : t('defaultDuplicate', {
        title: cueSheetVersion.title,
      });

  return (
    <Form
      initialValues={{
        // Make the Form initial valid when the user is creating a new version or duplicating an existent
        dirty: !id || duplicate,
        productionId,
        parentCueContainerId: assetId,
        title: defaultVersionTitle,
        ...cueSheetVersion,
        ...(duplicate ? { title: defaultDuplicateTitle } : {}),
      }}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      enableReinitialize
      readOnly={loading}
    >
      <DrawerHeader hideBackButton onClose={onClose} title={drawerTitle}>
        <FormButton
          alwaysEnabled
          children="global.forms.cancel"
          size="xs"
          variant="destructive"
          onClick={() => onClose()}
        />
        <FormButton
          children={duplicate ? `${rootT}.duplicate` : 'global.forms.save'}
          size="xs"
          type="submit"
          variant="primary"
        />
      </DrawerHeader>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Text children={t('hint')} variant="caption" />
        </Grid>
        <Grid item xs={7}>
          <FormInputText autofocus label={t('name')} name="title" testId="version-drawer-name" required />
        </Grid>
        <Grid item xs={7}>
          <FormInputText
            label={t('notes')}
            name="notes"
            placeholder={t('notesPlaceholder')}
            testId="version-drawer-notes"
            textArea
          />
        </Grid>
        {id && !duplicate && (
          <Grid item>
            <FormCheckbox
              disabled={cueSheetVersion.isCurrentCueSheetVersion}
              label={t('makeCurrent')}
              name="isCurrentCueSheetVersion"
            />
          </Grid>
        )}
        {id && !duplicate && !cueSheetVersion.isCurrentCueSheetVersion && (
          <Grid item container justify="flex-end">
            <FormButton
              alwaysEnabled
              children={t('deleteVersion')}
              onClick={onDelete}
              size="sm"
              startIcon={<DeleteIcon />}
              variant="destructive"
            />
          </Grid>
        )}
      </Grid>
    </Form>
  );
};

CueSheetVersionDrawer.propTypes = {
  onClose: PropTypes.func.isRequired,
  openPrompt: PropTypes.func.isRequired,
  payload: PropTypes.object.isRequired,
  productionId: PropTypes.number.isRequired,
};

export default enhancer(CueSheetVersionDrawer);
