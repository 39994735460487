import { PROJECT_VIEW_MODES } from 'utils/constants';

const ROUTES = {
  LICENSING: {
    BASE_PATH: '/licensing',
    ALL_LICENSES: '/licensing/all',
  },
  LETTERS: {
    BASE_PATH: '/letters',
    CLEARANCES: '/letters/clearances',
    REVIEW: '/letters/review',
    SEND: '/letters/send',
  },
  MAIL: {
    BASE_PATH: '/mail',
  },
  COMPOSE_LETTER: '/compose-letter',
  PRODUCTION: {
    BASE_PATH: '/production',
    OVERVIEW: '/production',
    DETAILS: '/production/details',
    BUDGET: '/production/budget',
    LICENSING: '/production/licensing',
    CUE_SHEET: '/production/cue-sheet',
    IMPORT_CUE_SHEET: '/production/cue-sheet/import',
    TASKS: {
      INBOX: '/production/tasks/inbox',
      SENT: '/production/tasks/sent',
      COMPLETED: '/production/tasks/completed',
      MY_TEAM: '/production/tasks/my-team',
    },
    FILES: {
      ATTACHMENTS: '/production/files/attachments',
      MAIL: '/production/files/mail',
    },
    SCENARIO: `/production/${PROJECT_VIEW_MODES.SCENARIO}`,
    TRACKS: `/production/${PROJECT_VIEW_MODES.TRACKS}`,
    SCENES: `/production/${PROJECT_VIEW_MODES.SCENES}`,
    VERSIONS: `/production/${PROJECT_VIEW_MODES.VERSIONS}`,
    ASSETS: `/production/${PROJECT_VIEW_MODES.ASSETS}`,
  },
  TASKS: {
    BASE_PATH: '/tasks',
    INBOX: '/tasks/inbox',
    SENT: '/tasks/sent',
    COMPLETED: '/tasks/completed',
    MY_TEAM: '/tasks/my-team',
  },
  PROJECTS: {
    BASE_PATH: '/projects',
    CLEARANCE: '/projects/clearance',
    LICENSING: '/projects/licensing',
    CUE_SHEET: '/projects/cue-sheet',
  },
  TRACK: {
    BASE_PATH: '/production/track',
    CLEARANCES: '/production/track/clearances',
    LICENSING: '/production/track/licensing',
    DETAILS: '/production/track/details',
    CUE_SHEET: '/production/track/cue-sheet',
    TASKS: {
      INBOX: '/production/track/tasks/inbox',
      SENT: '/production/track/tasks/sent',
      COMPLETED: '/production/track/tasks/completed',
      MY_TEAM: '/production/track/tasks/my-team',
    },
    FILES: {
      ATTACHMENTS: '/production/track/files/attachments',
      MAIL: '/production/track/files/mail',
    },
    HISTORY: '/production/track/history',
    CLEARANCE_LOG: '/production/track/clearance-log',
  },
  LOGIN: '/login',
  LOGOUT: '/logout',
  LOGIN_CALLBACK: '/loginCallback',
  ACCOUNT: '/signup/:token',
  PARTNER: '/apmsignup',
  SETTINGS: {
    BASE_PATH: '/settings',
    AUDIT_LOG: '/settings/audit',
    CONTACTS: '/settings/contacts',
    DIVISION: '/settings/division',
    EMAIL_TEMPLATES: '/settings/email-templates',
    LETTER_TEMPLATES: '/settings/letter-templates',
    SCHEDULE_A_TEMPLATES: '/settings/schedule-a-templates',
    RIGHTS_PRESETS: '/settings/rights-presets',
    TERRITORY_PRESETS: '/settings/territory-presets',
    USERS: '/settings/users',
    CONTACTS_PREFERENCE: '/settings/contacts-preference',
    CUE_SHEET: '/settings/cue-sheet',
  },
  HELP: '/help',
  USER_PROFILE: '/user-profile',
  QWIRE_ADMIN: '/qwire-admin',
  // REPORTS: '/reports',
  REPORTS: '/reports',
  ADMIN_REPORTS: '/admin-reports',
  MAIN_CUE_SHEET: '/main-cue-sheet',
  LICENSORS: '/licensors',
  TRACKS: '/tracks',
};

export default ROUTES;
