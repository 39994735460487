import { KeyboardArrowDown, KeyboardArrowRight } from '@mui/icons-material';
import { Box } from '@mui/material';
import { useState } from 'react';
import {
  useCreateClearanceStatusMutation,
  useMoveClearanceStatusMutation,
  useUpdateClearanceStatusMutation,
} from 'services/clear/clearance-status';
import {
  useCreateCueSheetStatusMutation,
  useMoveCueSheetStatusMutation,
  useUpdateCueSheetStatusMutation,
} from 'services/cue/cue-sheet-status';
import { CustomStatus } from './CustomStatus';
import { CustomStatusListItem } from './CustomStatusListItem';
import { DndCustomStatusList } from './DndCustomStatusList';
import { TCustomStatus } from './types';

export type TCustomStatusItemProps = {
  divisionId: number;
  status: TCustomStatus;
  createCustomStatus:
    | ReturnType<typeof useCreateClearanceStatusMutation>[0]
    | ReturnType<typeof useCreateCueSheetStatusMutation>[0];
  updateCustomStatus:
    | ReturnType<typeof useUpdateClearanceStatusMutation>[0]
    | ReturnType<typeof useUpdateCueSheetStatusMutation>[0];
  moveCustomStatus:
    | ReturnType<typeof useMoveClearanceStatusMutation>[0]
    | ReturnType<typeof useMoveCueSheetStatusMutation>[0];
};

export type TDndResult = {
  source: {
    index: number;
  };
  destination: {
    index: number;
  };
};

const newCustomStatusTemplate = {
  id: undefined,
  label: '',
  priority: 0,
  precedence: 0,
  listingOrder: 0,
  isParent: false,
  childrenStatuses: undefined,
  clearanceStatusList: undefined,
};

export const CustomStatusItem = ({
  divisionId,
  status,
  createCustomStatus,
  updateCustomStatus,
  moveCustomStatus,
}: TCustomStatusItemProps) => {
  const canHaveChildren = status.isParent;
  const hasParent = !canHaveChildren;

  const newCustomStatus: TCustomStatus = {
    ...newCustomStatusTemplate,
    parentId: status.id,
    color: status.color,
  };

  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const existingCustomStatuses = status.childrenStatuses.filter((s) => !!s.id);

  const onMoveCustomStatus = async (result: TDndResult) => {
    const customStatusList = status.childrenStatuses.filter((s) => !s.coreStatus);
    const from = result.source.index;
    const to = result.destination.index;
    const moved = customStatusList[from];
    const target = customStatusList[to];
    const moveDto = {
      divisionId,
      parentId: status.id,
      movedId: moved.id,
      targetId: target.id,
    };

    await moveCustomStatus(moveDto);
  };

  return (
    <CustomStatusListItem hasParent={hasParent}>
      <Box display="flex" gap={1}>
        {!isExpanded && (
          <button
            data-testid={`open-custom-status-children-${status.id}`}
            onClick={() => setIsExpanded(true)}
            style={{ all: 'unset', cursor: 'pointer' }}
            type="button"
          >
            <KeyboardArrowRight color="primary" />
          </button>
        )}
        {isExpanded && (
          <KeyboardArrowDown sx={{ cursor: 'pointer' }} color="primary" onClick={() => setIsExpanded(false)} />
        )}
        <CustomStatus status={status} />
      </Box>
      {canHaveChildren && isExpanded && (
        <DndCustomStatusList
          divisionId={divisionId}
          existingCustomStatuses={existingCustomStatuses}
          newCustomStatus={newCustomStatus}
          createCustomStatus={createCustomStatus}
          updateCustomStatus={updateCustomStatus}
          onMoveCustomStatus={onMoveCustomStatus}
        />
      )}
    </CustomStatusListItem>
  );
};
