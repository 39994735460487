/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import { Text } from 'components/styleguide';
import { HHMMSStoMMSS } from 'utils/date';
import ButtonBase from '@material-ui/core/ButtonBase';
import classnames from 'classnames';
import Tooltip from '@material-ui/core/Tooltip';
import { getTrackUseTypeName } from 'utils/constants';
import useStyles from './styles';

const CHIP_SIZES = {
  SMALL: 'sm',
  MEDIUM: 'md',
};
const CustomChip = ({ classes, label, size }) => (
  <Chip
    label={label}
    classes={{
      root: classnames(size, {
        [classes.chip]: label,
        [classes.emptyChip]: !label,
      }),
      label: classnames(classes.chipLabel, size),
    }}
    data-testid="custom-chip"
  />
);

CustomChip.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  label: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
};

const UseDurationChips = ({ list, onClick, size, toolTip = '' }) => {
  const classes = useStyles();

  const stringList = list.map(({ use, duration, durationFull }) => {
    const expUse = use === 'OTHER' ? 'OTH' : getTrackUseTypeName(use);
    if (!duration && !durationFull) return expUse;
    const durationDescription = !durationFull ? HHMMSStoMMSS(duration) : 'Full';

    return `${expUse ? `${expUse} / ` : ''}${durationDescription}`;
  });
  const [first, second, ...rest] = stringList;
  return (
    <Tooltip title={toolTip} placement="bottom-start">
      <ButtonBase onClick={onClick} data-testid="chips-button-wrapper">
        <Box maxWidth={110} height={size === CHIP_SIZES.MEDIUM ? 50 : 42} display="flex">
          <Box
            width={size === CHIP_SIZES.MEDIUM ? 110 : 110}
            height="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexWrap="wrap"
            className={classes.pillContainer}
            data-testid="chips-container"
          >
            <Box mb={second && 0.75} width="100%">
              <CustomChip label={first} classes={classes} size={size} />
            </Box>
            {second && <CustomChip label={second} classes={classes} size={size} />}
          </Box>
          {Boolean(rest.length) && (
            <Box boxSizing="border-box" height="100%" width={40} overflow="none" display="flex" alignItems="flex-end">
              <Text
                variant="caption"
                color="primary"
                bold
                noWrap
                lineHeight="1.25"
                testId="chips-remaining-text"
                align="left"
              >
                {`+${rest.length}`}
              </Text>
            </Box>
          )}
        </Box>
      </ButtonBase>
    </Tooltip>
  );
};

UseDurationChips.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      use: PropTypes.string,
      duration: PropTypes.string,
    }),
  ).isRequired,
  onClick: PropTypes.func,
  toolTip: PropTypes.bool,
  size: PropTypes.oneOf([CHIP_SIZES.SMALL, CHIP_SIZES.MEDIUM]),
};

UseDurationChips.defaultProps = {
  onClick: () => {},
  size: CHIP_SIZES.SMALL,
};

export default UseDurationChips;
