/* eslint-disable import/no-cycle */
import { getSetupFlowInfoSelector, getSetupFlowProjectSelector } from 'redux-core/productions/selectors';
import {
  cleanSetup,
  keepProjectInfo,
  keepSetupInfo,
  setPosterId,
  getPatchMessage,
  getUpdateMessage,
} from 'redux-core/productions/actions/actionDefinition';
import { insertMultipleRights } from 'redux-core/rights/services';
import always from 'ramda/src/always';
import call from 'ramda/src/call';
import cond from 'ramda/src/cond';
import equals from 'ramda/src/equals';
import mergeAll from 'ramda/src/mergeAll';
import omit from 'ramda/src/omit';
import prop from 'ramda/src/prop';
import find from 'ramda/src/find';
import propEq from 'ramda/src/propEq';
import pathOr from 'ramda/src/pathOr';
import map from 'ramda/src/map';
import pick from 'ramda/src/pick';
import compose from 'ramda/src/compose';
import uniqBy from 'ramda/src/uniqBy';
import concat from 'ramda/src/concat';
import sortBy from 'ramda/src/sortBy';
import filter from 'ramda/src/filter';
import { closeAllDialogs, openDrawer, closeDrawer } from 'redux-core/dialogs/actions';
// eslint-disable-next-line import/no-named-as-default
import DRAWERS from 'components/common/Drawers/constants';
import { goToLink, addParams, removeParams } from 'redux-core/router/actions';
import { NORMALIZED_PRODUCTION_TYPES, PRODUCTION_TYPES } from 'utils/constants';
import i18next from 'i18next';
import { showSnackbar } from 'redux-core/global-error/actions';
import ROUTES from 'components/Routes/routes';
import { getSelectedSelector } from 'redux-core/overview/selectors';
import { getFilmReleaseById, getEpisodeById } from 'redux-core/overview/services';
import { keyBy, get, isEmptyObject } from 'utils/object';
import { requestWithError } from 'utils/request';
import { setSelected, setProject } from 'redux-core/overview/actions';
import {
  updateProjectCall,
  getCurrenciesCall,
  setWatchingCall,
  unsetWatchingCall,
  quickCreateProjectCall,
  getProjectById,
} from 'redux-core/productions/services';
import { getCampaignByIdCall } from 'redux-core/overview/production-marketing/services';
import { fetchRecentSearchList, fetchBookmarkList } from 'redux-core/header/actions';
import { duplicateSegment } from 'redux-core/cueSheet/services';
import { isEmpty } from 'lodash';

/**
 * ===============================
 * Thunks
 * ===============================
 */
export const initProductionSetup =
  ({ tenantId, divisionId, type }, projectId, name) =>
  (dispatch) => {
    const openTypeDrawer = (drawer) => (values) => dispatch(openDrawer(drawer, values));
    // Next step to follow (Series, Campaign or Film) after Create Project drawer
    const gotToNextStep = cond([
      [
        equals(NORMALIZED_PRODUCTION_TYPES.FEATURES),
        always((values) => {
          dispatch(closeAllDialogs());
          dispatch(
            goToLink(ROUTES.PRODUCTION.OVERVIEW, {
              type,
              name,
              ...values,
              divisionId,
              projectId,
              tenantId,
            }),
          );
        }),
      ],
      [
        equals(NORMALIZED_PRODUCTION_TYPES.MKT),
        always((values) => {
          dispatch(closeAllDialogs());
          dispatch(
            goToLink(ROUTES.PRODUCTION.OVERVIEW, {
              type,
              name,
              ...values,
              divisionId,
              projectId,
              tenantId,
            }),
          );
        }),
      ],
      [equals(NORMALIZED_PRODUCTION_TYPES.TV), always(openTypeDrawer(DRAWERS.PRODUCTION_SETUP_SEASON))],
    ])(type);

    dispatch(keepSetupInfo(divisionId, projectId, tenantId));
    const payload = { name, gotToNextStep, projectId, dirty: Boolean(name) };
    if (projectId) gotToNextStep(payload);
    else dispatch(openDrawer(DRAWERS.PRODUCTION_SETUP_PROJECT, payload));
  };

/// UPDATES
export const fetchUpdateProject = (payload, continueEditing, patch) => async (dispatch, getState) => {
  const state = getState();
  const setupInfo = omit(['projectId'], getSetupFlowInfoSelector(state));
  const project = getSetupFlowProjectSelector(state);

  if (payload.id || project.id) {
    const data = patch ? { id: project.id, name: project.name, ...payload } : mergeAll([project, payload]);
    await updateProjectCall(omit(['poster'], mergeAll([data, setupInfo])));
    const messageFn = patch ? getPatchMessage : getUpdateMessage;
    const message = messageFn('project', payload.name);
    dispatch(showSnackbar({ message }));
  }

  dispatch(keepProjectInfo(mergeAll([project, payload])));
  if (continueEditing) return;
  // Refetch recent list
  dispatch(fetchRecentSearchList());
  // Refetch bookmarks list
  dispatch(fetchBookmarkList());
  dispatch(closeDrawer());
  dispatch(cleanSetup());
};

/**
 * Returns a list of ALL formatted currencies
 */
export const getCurrencyOptions =
  (payload = { currencies: [] }) =>
  async () => {
    const options = payload.currencies.length ? payload.currencies : await requestWithError(getCurrenciesCall);
    const result = map(
      (currency) => ({
        ...pick(['id', 'exchangeRate', 'code', 'symbol'], currency),
        currencyId: Number(currency.currencyId), // currency id is coming as a string from the API
        label: `${currency.code} (${currency.symbol})`,
      }),
      options,
    );

    return result;
  };

export const toggleWatchingProduction =
  ({ isBeingWatched, id: productionId }) =>
  async () => {
    await call(isBeingWatched ? unsetWatchingCall : setWatchingCall, {
      productionId,
    });
  };

/**
 * Returns a list of the current Production currencies formatted
 */
export const getProductionCurrencyOptions =
  (payload = { currencies: [] }) =>
  async (dispatch, getState) => {
    const state = getState();
    const currencies = await dispatch(getCurrencyOptions(payload));
    const selected = getSelectedSelector(state);
    const defaultCurrency = find(propEq('code', 'USD'), currencies);

    if (isEmptyObject(selected)) {
      return [defaultCurrency];
    }

    const additionalCurrencies = pathOr([], ['additionalData', 'currencies'], selected);

    const masterCurrency = find(propEq('id', get('masterCurrencyId')(selected)), currencies);

    const indexed = keyBy('id')(currencies);

    const result = compose(
      sortBy(prop('code')),
      map((c) => {
        const currency = get(`${c.currencyId || c.id}`)(indexed);
        return {
          ...c,
          currencyId: Number(currency.currencyId),
          symbol: currency.symbol,
          code: currency.code,
          label: `${currency.code} (${currency.symbol})`,
        };
      }),
      uniqBy(prop('id')),
      filter(Boolean),
      concat([defaultCurrency, masterCurrency]),
    )(additionalCurrencies);

    return result;
  };

/*
 * Updates the selected production in Production Overview
 */
export const updateLocalProduction = (payload, nextPayload) => async (dispatch, getState) => {
  const state = getState();
  const selected = getSelectedSelector(state);
  const updatedSelected = mergeAll([selected, payload]);

  dispatch(setSelected(mergeAll([updatedSelected, nextPayload])));
};

/*
 * Set/unset Watching property in a Production
 */
export const toggleWatching = (payload) => async (dispatch) => {
  const newStatus = !payload.isBeingWatched;
  await call(newStatus ? setWatchingCall : unsetWatchingCall, {
    productionId: payload.productionId || payload.production.id,
  });

  const message = i18next.t(`productionOverview.notifications.${newStatus ? 'watch' : 'unwatch'}`);
  dispatch(showSnackbar({ message }));
  dispatch(updateLocalProduction({ isBeingWatched: newStatus }));
};

const availablePosterIdTypes = [
  PRODUCTION_TYPES.SEASON_RELEASE,
  PRODUCTION_TYPES.FILM_RELEASE,
  PRODUCTION_TYPES.EPISODE,
  PRODUCTION_TYPES.CAMPAIGN,
  PRODUCTION_TYPES.PROJECT,
  PRODUCTION_TYPES.SEASON,
];

export const savePosterId =
  ({ type, posterId }) =>
  async (dispatch) => {
    if (!availablePosterIdTypes.includes(type)) return;
    dispatch(setPosterId({ type, posterId }));
  };

export const quickCreateProject = (values) => async (dispatch) => {
  const { divisionId, exportedSegments, requiredRights, showWhereToGoOptions, type } = values;
  const rights = requiredRights;

  let goToProduction = null;

  if (type === 'features') {
    if (isEmpty(rights)) {
      return null;
    }

    const response = await quickCreateProjectCall(values);
    await insertMultipleRights({
      divisionId,
      subprojectId: response.subprojectId,
      rights,
    });

    const project = await getProjectById({ id: response.projectId });
    const filmRelease = await getFilmReleaseById({ id: response.subprojectId });

    if (exportedSegments) {
      const currentCueSheetVersionId = filmRelease.currentCueSheetVersion.id;
      exportCueSheetSegments(currentCueSheetVersionId, exportedSegments);
    }

    goToProduction = async () => {
      dispatch(closeAllDialogs());
      await dispatch(addParams({ id: response.subprojectId, objectId: response.objectId }));
      dispatch(setProject(project));
      dispatch(setSelected(filmRelease));
      dispatch(
        goToLink(ROUTES.PRODUCTION.DETAILS, {
          releaseId: filmRelease.id,
          type: NORMALIZED_PRODUCTION_TYPES.FEATURES,
          ...filmRelease,
        }),
      );
      await dispatch(removeParams(['filters']));
    };
  }

  if (type === 'marketing') {
    const response = await quickCreateProjectCall(values);

    const campaign = await getCampaignByIdCall({ id: response.subprojectId });

    if (exportedSegments) {
      throw new Error(
        `You can't export segments to new marketing campaigns because, on that type of production,
        segments are linked to assets instead of the productions itself. As brand new campaigns don't have assets yet,
        there is no target (no assets) to export segments to.`,
      );
    }

    dispatch(closeAllDialogs());
    await dispatch(addParams({ id: campaign.id }));
    dispatch(setProject(campaign));
    dispatch(setSelected(campaign));
    dispatch(
      goToLink(ROUTES.PRODUCTION.DETAILS, {
        releaseId: response.subprojectId,
        type: NORMALIZED_PRODUCTION_TYPES.MKT,
        ...campaign,
      }),
    );
    await dispatch(removeParams(['filters']));
    return null;
  }

  if (type === 'television') {
    if (isEmpty(rights)) {
      return null;
    }

    const response = await quickCreateProjectCall(values);

    const amountOfNewEpisodes =
      response.episodeIds.length - values.episodes.filter((episode) => episode.id).length || 0;

    await Promise.all(
      [response.subprojectId, response.seasonReleaseId].map((sId) =>
        insertMultipleRights({
          divisionId,
          subprojectId: sId,
          rights,
        }),
      ),
    );

    // rights should only apply to the lastest created episodes
    await Promise.all(
      response.episodeIds.slice(-amountOfNewEpisodes).map((episodeId) =>
        insertMultipleRights({
          divisionId,
          subprojectId: episodeId,
          rights,
        }),
      ),
    );

    const project = await getProjectById({ id: response.projectId });
    const episode = await getEpisodeById({ id: response.episodeIds[0] });

    if (exportedSegments) {
      const currentCueSheetVersionId = episode.currentCueSheetVersion.id;
      exportCueSheetSegments(currentCueSheetVersionId, exportedSegments);
    }

    goToProduction = async () => {
      dispatch(closeAllDialogs());
      dispatch(addParams({ seasonId: response.subprojectId }));
      dispatch(setProject(project));
      dispatch(setSelected(episode));
      dispatch(
        goToLink(ROUTES.PRODUCTION.DETAILS, {
          releaseId: response.seasonReleaseId,
          type: NORMALIZED_PRODUCTION_TYPES.TV,
          ...episode,
        }),
      );
      await dispatch(removeParams(['filters']));
    };
  }

  if (showWhereToGoOptions) {
    return goToProduction;
  }
  await goToProduction();
  return null;
};

export const exportCueSheetSegments = async (cueContainerTargetId, segments) => {
  // eslint-disable-next-line no-restricted-syntax
  for (const segment of segments) {
    // eslint-disable-next-line no-await-in-loop
    await duplicateSegment({
      cueSheetSegmentId: segment.id,
      cueContainerSourceId: segment.cueContainerId,
      cueContainerTargetId,
    });
  }
};
