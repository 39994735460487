import PropTypes from 'prop-types';

import { ClearancePropTypes, LicensorPropTypes, PathPropTypes } from 'utils/types';
import { TRACK_TYPES } from 'utils/constants';

export const STEPS = {
  FILTERS: 0,
  REVIEW_QUEUE: 1,
  CONTACT: 2,
  LETTER: 3,
  EMAIL: 4,
};

export const LetterPropTypes = {
  artists: PropTypes.arrayOf(PropTypes.string),
  clearance: ClearancePropTypes,
  licensor: LicensorPropTypes,
  productionPath: PathPropTypes,
  trackId: PropTypes.number,
  trackTitle: PropTypes.string,
  trackType: PropTypes.oneOf(Object.values(TRACK_TYPES)),
};
export const LettersListPropTypes = PropTypes.arrayOf(LetterPropTypes);
