import { Check, Clear, FiberManualRecord } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { ControllerFieldState } from 'react-hook-form';
import { rootTranslation } from 'utils/format';

const t = rootTranslation('apmSignup');

export type TPasswordFieldRulesProps = {
  currentValue: string;
  fieldState: ControllerFieldState;
};

export const PasswordFieldRules = ({ currentValue, fieldState }: TPasswordFieldRulesProps) => {
  const missingColor = fieldState.error ? 'error.main' : 'default';
  const missingIconTestIdSuffix = fieldState.error ? 'not-passing' : 'missing';
  const MissingIcon = fieldState.error ? Clear : FiberManualRecord;

  const oneLower = /[a-z]/.test(currentValue);
  const oneUpper = /[A-Z]/.test(currentValue);
  const oneNumber = /\d/.test(currentValue);
  const oneSpecial = /[!@#$%^&*(),.?":{}|<>]/.test(currentValue);
  const atLeast8Char = currentValue.length >= 8;

  return (
    <ul style={{ listStyle: 'none', paddingLeft: 0, marginBottom: 0 }}>
      <li>
        <Box display="flex" gap={1} pb={1} sx={{ color: oneLower ? 'success.main' : missingColor }}>
          {oneLower && <Check data-testid="rule-one-lower-passing" fontSize="small" />}
          {!oneLower && <MissingIcon data-testid={`rule-one-lower-${missingIconTestIdSuffix}`} fontSize="small" />}
          <Typography variant="body2">{t('oneLower')}</Typography>
        </Box>
      </li>
      <li>
        <Box display="flex" gap={1} pb={1} sx={{ color: oneUpper ? 'success.main' : missingColor }}>
          {oneUpper && <Check data-testid="rule-one-upper-passing" fontSize="small" />}
          {!oneUpper && <MissingIcon data-testid={`rule-one-upper-${missingIconTestIdSuffix}`} fontSize="small" />}
          <Typography variant="body2">{t('oneUpper')}</Typography>
        </Box>
      </li>
      <li>
        <Box display="flex" gap={1} pb={1} sx={{ color: oneNumber ? 'success.main' : missingColor }}>
          {oneNumber && <Check data-testid="rule-one-number-passing" fontSize="small" />}
          {!oneNumber && <MissingIcon data-testid={`rule-one-number-${missingIconTestIdSuffix}`} fontSize="small" />}
          <Typography variant="body2">{t('oneNumber')}</Typography>
        </Box>
      </li>
      <li>
        <Box display="flex" gap={1} pb={1} sx={{ color: oneSpecial ? 'success.main' : missingColor }}>
          {oneSpecial && <Check data-testid="rule-one-special-passing" fontSize="small" />}
          {!oneSpecial && <MissingIcon data-testid={`rule-one-special-${missingIconTestIdSuffix}`} fontSize="small" />}
          <Typography variant="body2">{t('oneSpecial')}</Typography>
        </Box>
      </li>
      <li>
        <Box display="flex" gap={1} sx={{ color: atLeast8Char ? 'success.main' : missingColor }}>
          {atLeast8Char && <Check data-testid="rule-8-chars-passing" fontSize="small" />}
          {!atLeast8Char && <MissingIcon data-testid={`rule-8-chars-${missingIconTestIdSuffix}`} fontSize="small" />}
          <Typography variant="body2">{t('atLeast8Char')}</Typography>
        </Box>
      </li>
    </ul>
  );
};
