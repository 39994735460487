import { handleActions } from 'redux-actions';
import * as actions from './actions';

const defaultState = {};

const permissionsReducer = {
  [actions.addPermissions]: (state, { payload }) => ({ ...state, ...(payload || {}) }),
  [actions.clearPermissions]: () => defaultState,
};

export default handleActions(permissionsReducer, defaultState);
