/* eslint-disable consistent-return */
/* eslint-disable import/no-cycle */
import { createActions } from 'redux-actions';
import i18next from 'i18next';
import identity from 'ramda/src/identity';
import mergeAll from 'ramda/src/mergeAll';
import compose from 'ramda/src/compose';
import prepend from 'ramda/src/prepend';
import path from 'ramda/src/path';
import reject from 'ramda/src/reject';
import equals from 'ramda/src/equals';
import pick from 'ramda/src/pick';
import { showSnackbar } from 'redux-core/global-error/actions';
import { TERRITORY_IS_NEW_ID } from 'components/Settings/Tabs/Territory/constants';
import { getActiveDivisionId } from 'redux-core/settings/selectors';
import { getDivisionId } from 'redux-core/productions/selectors';
import { getTerritoryPresetsSelector } from 'redux-core/territories/selectors';
import { requestWithError } from 'utils/request';
import { DEFAULT_PAGINATION_RESPONSE } from 'utils/constants';
import {
  getTerritoriesCall,
  createTerritoryPresetCall,
  updateTerritoryPresetCall,
  deleteTerritoryPresetCall,
} from './services';

const actionsDefinition = {
  SET_TERRITORY_PRESETS: identity,
  CLEAN_TERRITORY_PRESETS: identity,
  NEW_TERRITORY_PRESET: identity,
  ADD_TERRITORY_PRESET: identity,
  REMOVE_TERRITORY_PRESET: identity,
};

export const {
  setTerritoryPresets,
  addTerritoryPreset,
  cleanTerritoryPresets,
  newTerritoryPreset,
  removeTerritoryPreset,
} = createActions(actionsDefinition);

export const newBlankTerritoryPreset = () => async (dispatch, getState) => {
  const state = getState();

  const emptyTerritoryPreset = {
    id: TERRITORY_IS_NEW_ID,
  };

  const newState = compose(prepend(emptyTerritoryPreset), path(['presets']))(state.territories);
  dispatch(newTerritoryPreset(newState));
};

export const removeBlankTerritoryPreset = () => async (dispatch, getState) => {
  const state = getState();

  const newState = compose(
    reject(({ id }) => equals(id, TERRITORY_IS_NEW_ID)),
    path(['presets']),
  )(state.territories);

  dispatch(removeTerritoryPreset(newState));
};
/**
 * Fetch Territories
 */
export const getTerritoryPresets =
  (filters = {}) =>
  async (dispatch, getState) => {
    const state = getState();

    const divisionId = getDivisionId(state);
    if (!divisionId) return DEFAULT_PAGINATION_RESPONSE;

    const territories = getTerritoryPresetsSelector(state);

    const response = await requestWithError(
      getTerritoriesCall,
      { divisionId, ...filters },
      {
        message: {
          failed: 'territory.notification.get.failed',
        },
      },
    );

    const newTerritories = !filters.page ? response.data : territories.concat(response.data);

    dispatch(setTerritoryPresets(newTerritories));

    return response;
  };

/**
 * Create
 */
export const createTerritoryPreset = (payload) => async (dispatch, getState) => {
  const state = getState();

  const divisionId = await getActiveDivisionId(state);
  const headers = { divisionId };
  const cleanPayload = pick(['strategy', 'name', 'nameOnLetters', 'countryIds'], payload);

  const extendedPayload = mergeAll([headers, cleanPayload]);

  try {
    const data = await createTerritoryPresetCall(extendedPayload);
    const message = i18next.t('territory.notification.add.success');
    /* Remove blank territory row */
    const newState = compose(
      prepend(data),
      reject(({ id }) => equals(id, TERRITORY_IS_NEW_ID)),
      path(['presets']),
    )(state.territories);

    await dispatch(addTerritoryPreset(newState));
    await dispatch(showSnackbar({ message }));

    return data;
  } catch (error) {
    const message = i18next.t('territory.notification.add.failed');
    await dispatch(showSnackbar({ message }));
  }
};

/**
 * Update / Edit
 */
export const updateTerritoryPreset = (payload) => async (dispatch, getState) => {
  const state = getState();

  const divisionId = await getActiveDivisionId(state);
  const headers = { divisionId };
  const cleanPayload = pick(['id', 'strategy', 'name', 'nameOnLetters', 'countryIds'], payload);

  const extendedPayload = mergeAll([headers, cleanPayload]);

  try {
    const data = await updateTerritoryPresetCall(extendedPayload);
    const message = i18next.t('territory.notification.edit.success', {
      name: data.name,
    });
    await dispatch(showSnackbar({ message }));
  } catch (error) {
    const message = i18next.t('territory.notification.edit.failed');
    await dispatch(showSnackbar({ message }));
  }
};

/**
 * Delete
 */
export const deleteTerritoryPreset = (payload) => async (dispatch, getState) => {
  const state = getState();

  const divisionId = await getActiveDivisionId(state);
  const headers = { divisionId };
  const cleanPayload = pick(['id'], payload);

  const extendedPayload = mergeAll([headers, cleanPayload]);

  try {
    await deleteTerritoryPresetCall(extendedPayload);
    const message = i18next.t('territory.notification.delete.success');
    await dispatch(showSnackbar({ message }));
  } catch (error) {
    const message = i18next.t('territory.notification.delete.failed');
    await dispatch(showSnackbar({ message }));
  }
};
