/* eslint-disable consistent-return */
/* eslint-disable import/no-cycle */
import { RIGHTS_TERRITORIES_TERM_ENUM } from 'components/Settings/Tabs/Rights/constants';
import makeEntityServiceCalls, { makeServiceCall } from 'redux-core/serviceHelpers';
import propOr from 'ramda/src/propOr';
import dayjs from 'dayjs';

export const {
  getAll: getRightsCall,
  get: getRightByIdCall,
  create: createRightPresetCall,
  update: updateRightPresetCall,
  delete: deleteRightPresetCall,
} = makeEntityServiceCalls('rights-presets', 'clear');

/* @TODO Update next endpoint once BE functionality is in place */
export const updateRightsTerritoriesCall = makeServiceCall('territories', 'fake-update-endpoint');

/** Material Types */
export const getMaterialTypesCall = makeServiceCall('material-types', 'get-all', 'clear');

export const getRequiredRights = makeServiceCall('rights', 'get-required-rights', 'clear');

const upsertRightsCall = makeServiceCall('rights', 'upsert', 'clear');

const insertMultipleRightsCall = makeServiceCall('rights', 'insert-multiple', 'clear');

type RightItemType = {
  territoryId: number;
  start: unknown;
  end: unknown;
  term: string;
  otherDetails: string;
};

export type RightsType = {
  name: string;
  dirty: boolean;
  descriptionOnLetters: string;
  rights: RightItemType[];
};

type UpsertRightsBaseType = {
  divisionId: number;
  rights: RightsType;
  overwriteExistingClearancesRights?: boolean;
};

type OneOfIDType =
  | { subprojectId: number; cueContainerId: never; clearanceId: never }
  | (UpsertRightsBaseType & { cueContainerId: number; subprojectId: never; clearanceId: never })
  | (UpsertRightsBaseType & { clearanceId: number; cueContainerId: never; subprojectId: never });

type UpsertRightsType = UpsertRightsBaseType & OneOfIDType;

/** This call should only receive one of the following:
 * - subprojectId
 * - cueContainerId,
 * - clearanceId
 */
export const upsertRights = ({
  divisionId,
  rights,
  subprojectId,
  cueContainerId,
  clearanceId,
  overwriteExistingClearancesRights = false,
}: UpsertRightsType) => {
  /**
   * Only update rights that exist and are dirty. A right is dirty when it differes from it's initial status.
   * This is set in <RightsPresetPicker /> and <RightPresetDrawer />
   */
  if (!rights || !rights.rights?.length || !rights.dirty) return;
  return upsertRightsCall({
    divisionId,
    subprojectId,
    clearanceId,
    cueContainerId,
    name: rights.name,
    descriptionOnLetters: rights.descriptionOnLetters,
    territories: rights.rights.map(({ territoryId, start, end, term, otherDetails }) => ({
      id: territoryId,
      start,
      end,
      term,
      otherDetails,
    })),
    overwriteExistingClearancesRights,
  });
};

export const insertMultipleRights = async ({ divisionId, subprojectId, rights, cueContainerId, clearanceId }) => {
  const payload = rights.map((r) => ({
    divisionId,
    subprojectId,
    clearanceId,
    cueContainerId,
    type: r.type,
    name: r.name,
    descriptionOnLetters: r.descriptionOnLetters,
    territories: r.rights.map(({ territoryId, start, end, term, otherDetails }) => ({
      id: territoryId,
      start,
      end,
      term,
      otherDetails,
    })),
  }));
  return insertMultipleRightsCall({ rightsOptionsGroups: payload });
};

const getTermByDate = ({ start, end }) => {
  const mStart = dayjs(start);
  const mEnd = dayjs(end);
  if (mStart.isSame(mEnd)) {
    return RIGHTS_TERRITORIES_TERM_ENUM.ONE_DAY;
  }

  if (!end) {
    return RIGHTS_TERRITORIES_TERM_ENUM.INPERPETUITY;
  }

  return RIGHTS_TERRITORIES_TERM_ENUM.DATE_RANGE;
};

export const extractRightsPresets = ({ rights = [], rightsPresetId = null }) => {
  const territories = rights.map(({ end, start, term, territory, id, name }) => ({
    id: propOr(id, 'id', territory),
    name: propOr(name, 'name', territory),
    start: propOr(start, 'start', territory),
    end: propOr(end, 'end', territory),
    term: term || getTermByDate({ start, end }),
  }));

  return {
    rightsPresetId,
    territories,
  };
};
