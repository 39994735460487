/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { appColors, Edit } from 'components/styleguide';
import {
  FormRadioGroup,
  FormInputFormat,
  FormInputText,
  FormDebouncedTypeAhead,
  StateField,
} from 'components/common/Form/Fields';
// eslint-disable-next-line import/no-named-default
import { default as CountryDropdown } from 'components/TrackLayout/Tabs/TrackDetails/TrackCountryDropdown';
import { useRootTranslation, usePrompt, useFetch, useSnackbar, usePermissions } from 'utils/hooks';
import PropTypes from 'prop-types';
import { withFormField } from 'components/common/Form/utils';
import { PAYEE_TYPES_OPTIONS, PAYEE_TYPES, SEARCH_CONTACTS_FIELDS } from 'utils/constants';
import { getCountriesCall } from 'redux-core/territories/services';
import DeleteIcon from '@material-ui/icons/Delete';
import { searchContactsCall } from 'redux-core/contacts/services';
import { COMMON_ROLES } from 'redux-core/permissions/constants';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import { IconButton } from '@material-ui/core';
import CompanyTypeahead from './CompanyTypeAhead';
import { SecuredIconButton } from '../../../Secured';
import DrawerFormContentGrid from '../../DrawerFormContentGrid';

const rootT = 'drawers.payeesAddEdit';

const PayeeForm = ({ objectId, onDelete, form: { values, setFieldValue, resetForm }, divisionId }) => {
  const [isCreatingContact, setIsCreatingContact] = useState(false);
  const [isEditingContact, setIsEditingContact] = useState(false);
  const openPrompt = usePrompt();
  const openSnackbar = useSnackbar();
  const [countries] = useFetch(getCountriesCall);
  const { type, id, contact } = values;
  const sharedProps = { disabled: !values.name && !values.companyName };
  const globalT = useRootTranslation('global');
  const t = useRootTranslation(rootT);
  const isEditor = usePermissions({
    clearPermissions: COMMON_ROLES.EDITOR,
    objectId,
  });

  const handleContactChange = (value) => {
    if (value) {
      setFieldValue('contact.id', value.contactId);
      setFieldValue('contact.firstName', value.firstName);
      setFieldValue('contact.lastName', value.lastName);
      setFieldValue('contact.email', value.email);
      setIsCreatingContact(false);
    }
  };
  const handleContactCreate = (value) => {
    setFieldValue('contact.firstName', value);
    setFieldValue('contact.id', null);
    setFieldValue('contact.lastName', null);
    setFieldValue('contact.email', null);
    setIsCreatingContact(true);
  };
  return (
    <DrawerFormContentGrid container spacing={2} direction="column">
      <Grid item xs={6}>
        <FormRadioGroup name="type" options={PAYEE_TYPES_OPTIONS} horizontal valueType="string" />
      </Grid>
      <Grid item container direction="row" spacing={2}>
        <CompanyTypeahead
          canEdit={isEditor}
          contactKey="contact"
          divisionId={divisionId}
          name="name"
          label={type === PAYEE_TYPES.COMPANY ? `${rootT}.companyName` : `${rootT}.name`}
          testId="name"
          required
        />
        <Grid item xs={3} xl={2}>
          <FormInputFormat
            testId="share"
            name="share"
            label={`${rootT}.share`}
            type="percentage"
            decimalScale={5}
            required
            {...sharedProps}
          />
        </Grid>
      </Grid>
      <Grid item container direction="row" spacing={2}>
        <Grid item xs={6}>
          <FormInputText name="dba" label={`${rootT}.dba`} testId="dba" {...sharedProps} />
        </Grid>
        <Grid item xs={3} xl={2}>
          <FormInputText name="tin" label={`${rootT}.tin`} testId="tin" {...sharedProps} />
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <FormInputText
          inputProps={{
            maxLength: 255,
          }}
          name="vendorNumber"
          label={`${rootT}.vendorNumber`}
          testId="vendorNumber"
          {...sharedProps}
        />
      </Grid>
      <Grid container item alignItems="center">
        <Grid item xs={6}>
          {isCreatingContact ? (
            <FormInputText
              label={`${rootT}.firstName`}
              name="contact.firstName"
              testId="payee-contact-first-name"
              {...sharedProps}
            />
          ) : isEditingContact ? (
            <FormInputText
              label={`${rootT}.firstName`}
              name="contact.newfirstName"
              testId="payee-contact-first-name"
              {...sharedProps}
            />
          ) : (
            <FormDebouncedTypeAhead
              label={`${rootT}.firstName`}
              name="contact.firstName"
              labelKey="firstName"
              valueKey="firstName"
              onSelect={handleContactChange}
              creatableText={globalT('forms.createNew')}
              onCreate={(firstName) => handleContactCreate(firstName)}
              call={(term) =>
                searchContactsCall({
                  fields: [SEARCH_CONTACTS_FIELDS.PAYEE_COMPANY_NAME],
                  term,
                  divisionId,
                  useCurrentDivision: true,
                })
              }
              testId="payee-contact-first-name"
              alwaysRenderValue
              {...sharedProps}
            />
          )}
        </Grid>
        <Grid item xs={6}>
          {isEditingContact ? (
            <Box mt={2.5}>
              <IconButton
                size="small"
                disabled={false}
                onClick={() => {
                  openPrompt({
                    content: t('updateContactPrompt'),
                    onConfirm: async () => {
                      try {
                        setFieldValue('contact.firstName', contact.newfirstName);
                        setFieldValue('contact.lastName', contact.newlastName);
                        setFieldValue('contact.email', contact.newemail);
                        setFieldValue('contact.newfirstName', undefined);
                        setFieldValue('contact.newlastName', undefined);
                        setFieldValue('contact.newemail', undefined);
                        setIsEditingContact(false);
                      } catch {
                        openSnackbar({ message: globalT('genericError') });
                      }
                    },
                  });
                }}
              >
                <CheckIcon color="primary" />
              </IconButton>
              <IconButton
                size="small"
                disabled={false}
                onClick={() => {
                  setIsEditingContact(false);
                }}
              >
                <ClearIcon color="error" />
              </IconButton>
            </Box>
          ) : (
            isEditor && (
              <Box mt={2.5}>
                <Edit
                  size={20}
                  onClick={() => {
                    setFieldValue('contact.newfirstName', contact.firstName);
                    setFieldValue('contact.newlastName', contact.lastName);
                    setFieldValue('contact.newemail', contact.email);
                    setIsEditingContact(true);
                  }}
                />
              </Box>
            )
          )}
        </Grid>
      </Grid>
      <Grid item xs={6}>
        {isCreatingContact ? (
          <FormInputText
            label={`${rootT}.lastName`}
            name="contact.lastName"
            testId="payee-contact-last-name"
            {...sharedProps}
          />
        ) : isEditingContact ? (
          <FormInputText
            label={`${rootT}.lastName`}
            name="contact.newlastName"
            testId="payee-contact-last-name"
            {...sharedProps}
          />
        ) : (
          <FormDebouncedTypeAhead
            label={`${rootT}.lastName`}
            name="contact.lastName"
            labelKey="lastName"
            valueKey="lastName"
            onSelect={handleContactChange}
            call={(term) =>
              searchContactsCall({
                fields: [SEARCH_CONTACTS_FIELDS.PAYEE_COMPANY_NAME],
                term,
                divisionId,
                useCurrentDivision: true,
              })
            }
            testId="payee-contact-last-name"
            alwaysRenderValue
            {...sharedProps}
          />
        )}
      </Grid>
      <Grid item xs={6}>
        {isCreatingContact ? (
          <FormInputText label={`${rootT}.email`} name="contact.email" testId="payee-contact-email" {...sharedProps} />
        ) : isEditingContact ? (
          <FormInputText
            label={`${rootT}.email`}
            name="contact.newemail"
            testId="payee-contact-email"
            {...sharedProps}
          />
        ) : (
          <FormDebouncedTypeAhead
            label={`${rootT}.email`}
            name="contact.email"
            labelKey="email"
            valueKey="email"
            onSelect={handleContactChange}
            call={(term) =>
              searchContactsCall({
                fields: [SEARCH_CONTACTS_FIELDS.PAYEE_COMPANY_NAME],
                term,
                divisionId,
                useCurrentDivision: true,
              })
            }
            testId="payee-contact-email"
            alwaysRenderValue
            {...sharedProps}
          />
        )}
      </Grid>
      <Grid item xs={6}>
        <FormInputText name="contact.address1" label={`${rootT}.address1`} testId="address1" {...sharedProps} />
      </Grid>
      <Grid item xs={6}>
        <FormInputText name="contact.address2" label={`${rootT}.address2`} testId="address1" {...sharedProps} />
      </Grid>
      <Grid item xs={6}>
        <FormInputText name="contact.city" label={`${rootT}.city`} testId="city" {...sharedProps} />
      </Grid>
      <Grid item container direction="row" spacing={2}>
        <Grid item xs={3}>
          <StateField
            testId="state"
            countries={countries}
            name="contact.state"
            countryName="contact.countryName"
            label={`${rootT}.state`}
            {...sharedProps}
          />
        </Grid>
        <Grid item xs={3}>
          <FormInputText name="contact.postalCode" label={`${rootT}.zipCode`} testId="postalCode" {...sharedProps} />
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <CountryDropdown
          isClearable={false}
          name="contact.countryName"
          options={countries}
          valueKey="name"
          label={`${rootT}.country`}
          {...sharedProps}
        />
      </Grid>
      {id && (
        <Box display="flex" justifyContent="flex-end" mb={30}>
          <SecuredIconButton
            objectId={objectId}
            permissions={COMMON_ROLES.EDITOR}
            bold
            Icon={DeleteIcon}
            text={t('delete')}
            color={appColors.red}
            onClick={() =>
              openPrompt({
                content: t('deletePrompt'),
                onConfirm: async () => {
                  await resetForm();
                  onDelete();
                },
              })
            }
          />
        </Box>
      )}
    </DrawerFormContentGrid>
  );
};
PayeeForm.propTypes = {
  onDelete: PropTypes.func.isRequired,
  objectId: PropTypes.number,
  divisionId: PropTypes.number,
  form: PropTypes.shape({
    values: PropTypes.object,
  }),
};
export default withFormField(PayeeForm);
