import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  label: ({ disabled }) => ({
    '&:hover': {
      cursor: disabled ? 'default' : 'pointer',
    },
  }),
});

export default useStyles;
