export const RIGHT_PRESET_IS_NEW_ID = 'isNew';

export const RIGHTS_TERRITORIES_TERM_ENUM = {
  ONE_DAY: 1,
  DATE_RANGE: 2,
  INPERPETUITY: 3,
  OTHER: 4,
};
export const RIGHTS_TERRITORIES_TERM = {
  ONE_DAY: 'oneDay',
  DATE_RANGE: 'dateRange',
  INPERPETUITY: 'inPerpetuity',
  OTHER: 'other',
};
