/* eslint-disable react/no-children-prop */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-cycle */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import MuiTable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import MuiTableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableFooter from '@material-ui/core/TableFooter';
import classnames from 'classnames';
import { Text } from 'components/styleguide';
import { get } from 'utils/object';
import Paginator from 'components/common/Paginator/';
import is from 'ramda/src/is';
import toLower from 'ramda/src/toLower';
import Icon from '@material-ui/core/Icon';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import useStyles from './styles';
import TableRow from './TableRow';
import NoResults from '../NoResults/index';

function desc(a, b, orderBy) {
  const getValueForSort = (val) => {
    const value = get(orderBy)(val) || '';
    if (is(String, value)) {
      return value.toLowerCase();
    }
    if (Object.hasOwn(value, 'label')) {
      return value.label.toLowerCase();
    }
    return value;
  };
  const aValue = getValueForSort(a);
  const bValue = getValueForSort(b);
  if (bValue < aValue) {
    return -1;
  }
  if (bValue > aValue) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'DESC' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

export const TableSortIcon = (props) => <Icon color="primary" {...props} children={<KeyboardArrowUpIcon />} />;

const Table = ({
  columns,
  defaultOrder,
  defaultOrderBy,
  elevation,
  itemsPerPage,
  onSort,
  onRowClick,
  rows,
  fixedLayout,
  hoverable,
  footer,
  paginate,
  showNoResults,
  noWrapRowText,
  selected,
  CollapseComponent,
  collapseOpenIndex,
  dark,
  shouldRowScrollOnClick,
  onPaginatorClick,
  hideBorders,
}) => {
  const classes = useStyles({ hideBorders });
  const [order, setOrder] = React.useState(defaultOrder);
  const [orderBy, setOrderBy] = React.useState(defaultOrderBy);
  const [sortedRows, setSortedRows] = useState([]);
  const [paginatedRows, setPaginatedRows] = useState([]);

  const handleRequestSort = (property) => () => {
    const sortAsc = orderBy !== property || order === 'DESC';
    const sortByDirection = sortAsc ? 'ASC' : 'DESC';
    if (onSort) onSort({ sortBy: property, sortByDirection });
    setOrder(sortByDirection);
    setOrderBy(property);
  };

  useEffect(
    () => {
      if (!onSort) setSortedRows(stableSort(rows, getSorting(order, orderBy)));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [order, orderBy, rows],
  );

  useEffect(() => {
    if (!paginate) setPaginatedRows(rows);
  }, [paginate, rows]);

  const getLabel = ({ label }) => (is(Function, label) ? label(paginatedRows) : label);

  return (
    <Paper className={classes.root} elevation={elevation}>
      <div className={classes.tableWrapper}>
        <MuiTable stickyHeader className={classnames({ [classes.fixedTable]: !!fixedLayout })}>
          <TableHead>
            <MuiTableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  data-testid="table-header-cell"
                  size="small"
                  className={classnames(classes.headerCell, {
                    [classes.dense]: column.dense,
                    [classes.noPadding]: column.noPadding,
                  })}
                  sortDirection={column.isSortable && orderBy === column.id ? toLower(order || '') : false}
                  width={column.width}
                >
                  {column.isSortable ? (
                    <TableSortLabel
                      active={orderBy === column.id}
                      direction={toLower(order || '')}
                      onClick={handleRequestSort(column.id)}
                      IconComponent={TableSortIcon}
                      classes={classes.TableSortLabel}
                      hideSortIcon
                    >
                      <Text bold variant="caption" testId="table-header-cell-label">
                        {getLabel(column)}
                      </Text>
                    </TableSortLabel>
                  ) : (
                    <Text bold variant="caption" testId="table-header-cell-label">
                      {getLabel(column)}
                    </Text>
                  )}
                </TableCell>
              ))}
            </MuiTableRow>
          </TableHead>
          <TableBody>
            {showNoResults && !paginatedRows.length && (
              <MuiTableRow>
                <TableCell colSpan={columns.length}>
                  <NoResults justify="center" />
                </TableCell>
              </MuiTableRow>
            )}
            {paginatedRows.map((row, index) => (
              <TableRow
                orderBy={orderBy}
                noWrap={noWrapRowText}
                hover={hoverable}
                role="checkbox"
                tabIndex={-1}
                onClick={(e) => onRowClick(row, e)}
                columns={columns}
                row={row}
                index={index}
                key={row.id || index}
                selected={selected && selected === row.id}
                CollapseComponent={CollapseComponent}
                collapseOpenIndex={collapseOpenIndex}
                dark={dark}
                shouldRowScrollOnClick={shouldRowScrollOnClick}
              />
            ))}
          </TableBody>
          {footer && <TableFooter>{footer(footer, rows)}</TableFooter>}
        </MuiTable>
        {paginate && (
          <Grid container justify="flex-end">
            <Paginator
              // Passing a non-fixed key will re-mount the Pagination component everytime
              // the `order` or `orderBy` value change
              // moving the list from current page to the first page (resetting the pagination)
              key={order + orderBy}
              amount={itemsPerPage}
              callback={(visibleRows, currentPage, firstItem, lastItem) => {
                if (onPaginatorClick) onPaginatorClick(visibleRows, currentPage, firstItem, lastItem);
                setPaginatedRows(visibleRows);
              }}
              elements={sortedRows}
            />
          </Grid>
        )}
      </div>
    </Paper>
  );
};

Table.propTypes = {
  columns: PropTypes.array,
  defaultOrder: PropTypes.oneOf(['ASC', 'DESC']),
  defaultOrderBy: PropTypes.string,
  elevation: PropTypes.number,
  fixedLayout: PropTypes.bool,
  itemsPerPage: PropTypes.number,
  onRowClick: PropTypes.func,
  onSort: PropTypes.func,
  rows: PropTypes.array,
  hoverable: PropTypes.bool,
  showNoResults: PropTypes.bool,
  dark: PropTypes.bool,
  noWrapRowText: PropTypes.bool,
  paginate: PropTypes.bool,
  shouldRowScrollOnClick: PropTypes.bool,
  onPaginatorClick: PropTypes.func,
  selected: PropTypes.number,
  CollapseComponent: PropTypes.element,
  collapseOpenIndex: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /** Footer Columns */
  footer: PropTypes.func,
  hideBorders: PropTypes.bool,
};

Table.defaultProps = {
  itemsPerPage: 25,
  onRowClick: () => undefined,
  hoverable: true,
  showNoResults: false,
  shouldRowScrollOnClick: false,
  onPaginatorClick: () => undefined,
  paginate: true,
  dark: false,
};

export default Table;
