/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-children-prop */
import React from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import Toolbar from '@material-ui/core/Toolbar';
import { useRootTranslation } from 'utils/hooks';
import { Text } from 'components/styleguide';
import useStyles from './styles';
import GlobalSearchFilters from '../GlobalSearchFilters';

const rootT = 'globalSearch';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const GlobalSearchModal = ({ open, onClose, children, payload }) => {
  const classes = useStyles();
  const t = useRootTranslation(rootT);

  return (
    <Dialog
      classes={{ root: classes.dialog }}
      fullScreen
      onClose={onClose}
      open={open}
      TransitionComponent={Transition}
    >
      <Toolbar>
        <IconButton aria-label="close" color="primary" edge="start" onClick={onClose}>
          <CloseIcon />
        </IconButton>
        <Text children={t('advancedSearch')} semibold variant="h3" />
      </Toolbar>
      <DialogTitle className={classes.filtersSection}>
        <GlobalSearchFilters term={payload.search} />
      </DialogTitle>
      <DialogContent className={classes.content}>{children}</DialogContent>
    </Dialog>
  );
};

GlobalSearchModal.propTypes = {
  children: PropTypes.element,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
  payload: PropTypes.shape({
    search: PropTypes.string,
  }),
};

export default GlobalSearchModal;
