import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { Text, appColors } from 'components/styleguide';
import React from 'react';
import CueContainers from './CueContainers';

const Clearances = ({ clearances, readOnly, trackId, qTrackId, refetch }) => clearances.map((clearance) => (
  <Grid container justify="center" key={clearance.id}>
    <Grid item xs={12}>
      <Box m={1}>
        <Text bold>{clearance.name}</Text>
      </Box>
      <Box mb={1}>
        <hr style={{ borderColor: appColors.lightBlue }} />
      </Box>
    </Grid>
    <CueContainers
      cueContainers={clearance.containers}
      readOnly={readOnly}
      clearanceId={clearance.id}
      trackId={trackId}
      qTrackId={qTrackId}
      refetch={refetch}
    />
    {/* <Grid container> */}
    {/*  <Grid container item xs={2}> */}
    {/*    <FieldArray name="cues"> */}
    {/*      {(arrayHelpers) => ( */}
    {/*        <FormButton */}
    {/*          alwaysEnabled */}
    {/*          size="sm" */}
    {/*          variant="secondary" */}
    {/*          startIcon={<AddCircleIcon />} */}
    {/*          onClick={() => { */}
    {/*                arrayHelpers.push({ */}
    {/*                    id: `newCue-${uuidv4()}`, */}
    {/*                    qclearClearanceId: clearance.id, */}
    {/*                    cueContainerIds: [cueContainerId], */}
    {/*                }); */}
    {/*                arrayHelpers.form.submitForm(); */}
    {/*            }} */}
    {/*        > */}
    {/*          {t('addNew')} */}
    {/*        </FormButton> */}
    {/*                )} */}
    {/*    </FieldArray> */}
    {/*  </Grid> */}
    {/* </Grid> */}
  </Grid>
    ));
export default Clearances;
