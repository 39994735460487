import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  form: {
    '& > *': {
      marginTop: theme.spacing(2),
    },
  },
  treeView: {
    width: '100%',
  },
  treeRow: {
    '&:focus > $allContent': {
      background: 'none',
    },
  },
  allContent: {
    padding: theme.spacing(2, 0),
    fontSize: '18px',
    width: 'auto',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  projectRoot: {
    borderBottom: '1px solid #ddd',
  },
  nestedItem: {
    '& *': {
      fontSize: '14px',
    },
    '& > *': {
      padding: theme.spacing(1.5, 1),
    },
  },
}));

export default useStyles;
