/* eslint-disable import/no-cycle */
import { handleActions } from 'redux-actions';
import { lensPath, set, always } from 'ramda';
import { clearData } from 'redux-core/auth/actions';
import * as actions from './actions';

const defaultState = {
  list: [],
  loading: false,
};

const cueContainersReducer = {
  [actions.clearCueContainers]: () => defaultState,
  [actions.setCueContainers]: (state, { payload }) => set(lensPath(['list']), payload)(state),
  [actions.setCcPageCount]: (state, { payload }) => set(lensPath(['pageCount']), payload)(state),
  [actions.setLoading]: (state, { payload }) => set(lensPath(['loading']), payload)(state),
  [clearData]: always(defaultState),
};

export default handleActions(cueContainersReducer, defaultState);
