/* eslint-disable import/no-cycle */
import { handleActions } from 'redux-actions';
import { lensPath, set } from 'ramda';
import * as actions from './actions';

const defaultState = {
  productions: [],
  count: 0,
  loading: false,
};

const setupFlowReducer = {
  [actions.setLoading]: (state, { payload }) => set(lensPath(['loading']), payload)(state),
  [actions.setWatching]: (state, { payload }) => set(lensPath(['productions']), payload)(state),
  [actions.setWatchingCount]: (state, { payload }) => set(lensPath(['count']), payload)(state),
};

export default handleActions(setupFlowReducer, defaultState);
