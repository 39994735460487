import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
  fallbackWrapper: {
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(6),
  },
  globalContainer: { display: 'flex', alignItems: 'flex-start' },
  checkboxContainer: {
    padding: theme.spacing(),
    minWidth: 32,
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.spacing(),
    width: '100%',
  },
  titleWrapper: {
    paddingRight: theme.spacing(),
    display: 'flex',
    width: '100%',
  },
  trackTypeIconWrapper: { paddingRight: theme.spacing(2) },
  useDurationWrapper: {
    display: 'flex',
    aligntItems: 'center',
    paddingRight: theme.spacing(2),
    marginLeft: -theme.spacing(), // negative margin to align the checkbox which itself has a padding of 1 for hover effect
  },
  usageDurationChipLabel: {
    backgroundColor: '#616161',
    color: '#fff',
    padding: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    fontSize: '0.5rem',
    borderRadius: 3,
  },
  usesColumn: {
    flexBasis: '20%',
    width: '20%',
    display: 'flex',
    alignItems: 'center',
  },
}));

export default styles;
