import { handleActions } from 'redux-actions';
import { omit, always } from 'ramda';
// eslint-disable-next-line import/no-cycle
import * as actions from './actions';

const defaultState = {
  userData: {},
  interceptedRequests: [],
};

const homePageReducer = {
  [actions.setUserData]: (state, { payload }) => ({ ...state, userData: payload }),
  [actions.setBearerToken]: (state, { payload: bearerToken }) => ({
    ...state,
    userData: { ...state.userData, bearerToken },
  }),
  [actions.removeUserData]: (state) => omit('userData', state),
  [actions.setLocked]: (state, { payload }) => ({
    ...state,
    userData: { ...state.userData, locked: payload },
  }),
  [actions.addInterceptedRequest]: (state, { payload }) => ({
    ...state,
    interceptedRequests: [...state.interceptedRequests, payload],
  }),
  [actions.clearInterceptedRequests]: (state) => ({
    ...state,
    interceptedRequests: [],
  }),
  [actions.clearData]: always(defaultState),
};

export default handleActions(homePageReducer, defaultState);
