/* eslint-disable import/no-cycle */
import makeEntityServiceCalls, { makeServiceCall } from 'redux-core/serviceHelpers';

export const { getAll: getDivisions, get: getDivision } = makeEntityServiceCalls('divisions');

export const {
  getAll: getPersonnelPositionsCall,
  create: createPersonnelPositionsCall,
  update: updatePersonnelPositionsCall,
} = makeEntityServiceCalls('personnel-positions');

export const { create: createDivisionCall, update: updateDivisionCall } = makeEntityServiceCalls('divisions');

export const getAllActiveDivisionsCall = makeServiceCall('divisions', 'get-all-active');

export const getDivisionSettingsCall = makeServiceCall('divisions', 'get-division-settings');

export const updateDivisionSettingsCall = makeServiceCall('divisions', 'update-division-settings');
