/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import EditIcon from '@material-ui/icons/Edit';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import useStyles from './styles';
import StyledIconButton from '../StyledIconButton';

const Edit = (props) => {
  const { customProps, onClick, disableRipple, size, testId } = props;
  const classes = useStyles({ size });
  return (
    <StyledIconButton
      {...customProps}
      onClick={onClick}
      disableRipple={disableRipple}
      className={classnames(classes.button, customProps.className)}
      testId={testId}
    >
      <EditIcon color="primary" />
    </StyledIconButton>
  );
};

Edit.propTypes = {
  onClick: PropTypes.func,
  customProps: PropTypes.object,
  disableRipple: PropTypes.bool,
  size: PropTypes.number.isRequired,
};

Edit.defaultProps = {
  customProps: {},
  size: 18,
};

export default Edit;
