/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const Marketing = (props) => (
  <SvgIcon {...props}>
    <path d="M20.571 8.714V4.43c0-.782-.647-1.429-1.428-1.429-2.143 1.786-5.714 4.286-10 4.286H3.786C2.804 7.286 2 8.089 2 9.07v2.143C2 12.196 2.804 13 3.786 13h1.361c-.78 2.511.179 4.498.826 6.484 1.027.949 3.906.927 4.598-.435-1.183-.926-2.187-1.83-1.406-3.114-.87-.893-.413-2.444.915-2.89 3.873.323 7.076 2.589 9.063 4.24.781 0 1.428-.647 1.428-1.428v-4.286c.793 0 1.429-.636 1.429-1.428 0-.793-.636-1.429-1.429-1.429zm-1.428 6.741c-2.913-2.232-5.737-3.493-8.572-3.805V8.636c2.835-.312 5.659-1.596 8.572-3.828v10.647z" />
  </SvgIcon>
);

export default Marketing;
