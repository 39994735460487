/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable import/no-cycle */
import React from 'react';
import i18n from 'locales/i18n';
import ChevronRight from '@material-ui/icons/ChevronRight';
import toUpper from 'ramda/src/toUpper';
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { Button } from '@mui/material';
import PropTypes from 'prop-types';
import styles from './styles';

const SeeAll = ({ classes, onClick, label, showCaretLabel }) => (
  <Grid container item direction="row" alignItems="center" justify="flex-end" className={classes.seeAll}>
    <Button
      data-testId="see-all"
      size="small"
      disableRipple
      onClick={onClick}
      variant="text"
      endIcon={<ChevronRight color="primary" />}
    >
      {showCaretLabel ? label : null}
    </Button>
  </Grid>
);

SeeAll.propTypes = {
  classes: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  label: PropTypes.string,
  showCaretLabel: PropTypes.bool.isRequired,
};

SeeAll.defaultProps = {
  onClick: () => undefined,
  label: toUpper(i18n.t('global.seeAll')),
  showCaretLabel: true,
};

export default withStyles(styles)(SeeAll);
