/* eslint-disable no-return-await */
/* eslint-disable consistent-return */
/* eslint-disable import/no-cycle */
import i18next from 'i18next';
import c from 'ramda/src/compose';
import dissoc from 'ramda/src/dissoc';
import filter from 'ramda/src/filter';
import findIndex from 'ramda/src/findIndex';
import identity from 'ramda/src/identity';
import inc from 'ramda/src/inc';
import lensIndex from 'ramda/src/lensIndex';
import map from 'ramda/src/map';
import path from 'ramda/src/path';
import pathEq from 'ramda/src/pathEq';
import prop from 'ramda/src/prop';
import set from 'ramda/src/set';
import { batch } from 'react-redux';
import { createActions } from 'redux-actions';
import {
  changeCuePositionCall,
  changeSegmentPositionCall,
  createCueCall,
  createSegmentCall,
  deleteCuesCall,
  deleteSegmentCall,
  duplicateCueCall,
  getAllCuesCall,
  getCueCall,
  getSegmentsByCueContainer,
  updateCueCall,
  updateSegmentCall,
} from 'redux-core/cueSheet/services';
import { updatePayloadByName } from 'redux-core/dialogs/actions';
import { getDialogByNameSelector } from 'redux-core/dialogs/selectors';
import { showGenericError, showGenericSuccess } from 'redux-core/global-error/actions';
import { getSelectedProductionIdSelector } from 'redux-core/overview/selectors';
import { openPromptAsync } from 'redux-core/prompt/actions';
import { TRACK_TYPES } from 'utils/constants';
import { reorder } from './reorder';
import { getCuesSelector, getDraggableItemsSelector, getSelectedVersionIdSelector } from './selectors';

const actionsDefinition = {
  CLEAR_CUE_SHEET: identity,
  SET_CUES_AFTER_KEY: identity,
  SET_PAGINATION: identity,
  SET_CUES: identity,
  SET_SEGMENTS: identity,
  SET_EXPANDED_CUE_ID: identity,
  SET_CUES_LOADING: identity,
  SET_NEW_CUE: identity,
  SET_HIDE_IN_OUT: Boolean,
  SET_SELECTED_VERSION: identity,
  SET_IS_FORM_DIRTY: identity,
  SET_CUES_SYNC_FLAG: identity,
};

export const {
  clearCueSheet,
  setPagination,
  setCues,
  setSegments,
  setExpandedCueId,
  setHideInOut,
  setNewCue,
  setSelectedVersion,
  setIsFormDirty,
  setCuesSyncFlag,
} = createActions(actionsDefinition);

export const CUE_SHEET_PAGE_LIMIT = 100;
export const CUE_SHEET_NEW_CUE = {
  cue: { id: 'new' },
  track: { artists: [], type: TRACK_TYPES.SCORE },
};

export const fetchCues = (payload) => async (dispatch, getState) => {
  if (!payload?.limit) return;
  const state = getState();
  const response = await getAllCuesCall(payload);
  const cues = getCuesSelector(state);
  const updatedCues = !payload.page ? response.data.cues : cues.concat(response.data.cues);
  const selectedVersionId = getSelectedVersionIdSelector(state);
  if (!selectedVersionId) return;
  const segments = await getSegmentsByCueContainer({ cueContainerId: selectedVersionId });

  batch(() => {
    dispatch(setCuesSyncFlag(response.data.isSync));
    dispatch(setCues(updatedCues));
    dispatch(setSegments(segments));
    // eslint-disable-next-line no-unsafe-optional-chaining
    dispatch(setPagination(response.pagination));
  });
  return response;
};

export const refetchCues = () => async (dispatch, getState) => {
  const state = getState();
  const cues = getCuesSelector(state);
  const subprojectId = getSelectedProductionIdSelector(state);
  const cuesLength = cues.length || 1;
  const cueSheetVersionId = getSelectedVersionIdSelector(state);
  return await dispatch(
    fetchCues({
      cueSheetVersionId,
      subprojectId,
      limit: Math.ceil(cuesLength / CUE_SHEET_PAGE_LIMIT) * CUE_SHEET_PAGE_LIMIT,
    }),
  );
};

export const addNewCue = () => async (dispatch) => {
  batch(() => {
    dispatch(setNewCue(true));
    dispatch(setExpandedCueId('new'));
  });
};

/**
 * Receives an array of cues and updates the already existing information
 * with the new one
 * @param  {Array} cues
 */
const updateCuesInStore =
  (...args) =>
  async (dispatch, getState) => {
    const cuesToUpdate = args.flat();
    const state = getState();
    const cues = getCuesSelector(state);
    let updatedCues = [...cues];
    cuesToUpdate.forEach((payload) => {
      const index = findIndex(pathEq(['cue', 'id'], payload.cue.id))(cues);
      if (index >= 0) {
        const lens = lensIndex(index);
        updatedCues = set(lens, payload)(updatedCues);
      }
    });
    dispatch(setCues(updatedCues));
    return updatedCues;
  };

/**
 * Refetch all the information for the given cues in the store
 */
export const refreshCuesById =
  (...args) =>
  async (dispatch, getState) => {
    const ids = args.flat();
    if (!ids.length) return;
    const state = getState();
    const cues = getCuesSelector(state);
    if (!cues?.length) return;
    const cuesToUpdate = await Promise.all(ids.map((id) => getCueCall({ id })));
    await dispatch(updateCuesInStore(cuesToUpdate));
  };

/**
 * Refreshes all the cues for a certain qtrack
 */
export const refreshCuesByTrackId = (trackId) => async (dispatch, getState) => {
  const state = getState();
  const cues = getCuesSelector(state);
  const cuesToUpdate = c(map(path(['cue', 'id'])), filter(pathEq(['cue', 'trackId'], trackId)))(cues);
  if (!cuesToUpdate.length) return;
  dispatch(refreshCuesById(cuesToUpdate));
};

export const updateCue = (cue) => async (dispatch) => {
  await updateCueCall(cue);
  await dispatch(refetchCues());
  // We need to refetch all the cues that contain the same track,
  // because the track information is shared.
  dispatch(refreshCuesByTrackId(cue.trackId));
};

export const createCue = (cue) => async (dispatch) => {
  await createCueCall(dissoc('id', cue));

  dispatch(setExpandedCueId(null));
  dispatch(setNewCue(false));
  await dispatch(refetchCues());
};

export const expandNextCue = (cueIndex) => (dispatch, getState) => {
  const state = getState();
  const nextCueId = c(path([inc(cueIndex), 'cue', 'id']), getCuesSelector)(state);
  if (!nextCueId) {
    dispatch(setExpandedCueId(null));
  } else {
    dispatch(setExpandedCueId(nextCueId));
  }
};

/**
 *
 * @param {Array} cueIds
 * @param {String=} trackTitle
 */
export const deleteCues = (cueIds, trackTitle, clearCueSelection) => async (dispatch, getState) => {
  const state = getState();
  const productionId = getSelectedProductionIdSelector(state);
  const cueContainerId = getSelectedVersionIdSelector(state);
  const rootT = 'productionOverview.tabs.cueSheet.cueRowMenu';
  const content =
    trackTitle && cueIds.length === 1
      ? i18next.t(`${rootT}.deleteCuePrompt`, { trackTitle })
      : i18next.t(`${rootT}.deleteCuesPrompt`, { count: cueIds.length });
  await dispatch(openPromptAsync({ content }));
  await deleteCuesCall({
    cueIds,
    subprojectId: productionId,
    cueSheetVersionId: cueContainerId,
  });
  clearCueSelection();
  await dispatch(refetchCues());
  dispatch(showGenericSuccess());
};

export const createSegment =
  ({ name, cuesIds }) =>
  async (dispatch, getState) => {
    const state = getState();
    const cueContainerId = getSelectedVersionIdSelector(state);
    try {
      await createSegmentCall({
        cueContainerId,
        name,
        cuesIds,
      });
      dispatch(showGenericSuccess());
    } catch (err) {
      dispatch(showGenericError(err));
    }
    await dispatch(refetchCues());
  };

export const updateSegment =
  ({ id, name }) =>
  async (dispatch, getState) => {
    const state = getState();
    const cueContainerId = getSelectedVersionIdSelector(state);
    await updateSegmentCall({
      cueContainerId,
      id,
      name,
    });
    await dispatch(refetchCues());
    dispatch(showGenericSuccess());
  };

export const deleteSegments = (segments) => async (dispatch, getState) => {
  const state = getState();
  const cueContainerId = getSelectedVersionIdSelector(state);
  await dispatch(
    openPromptAsync({
      content: i18next.t('productionOverview.tabs.cueSheet.cueSheetTable.menu.deleteSegmentsPrompt'),
    }),
  );
  await Promise.allSettled(segments.map((segment) => deleteSegmentCall({ cueContainerId, id: segment.id })));
  await dispatch(refetchCues());
  dispatch(showGenericSuccess());
};

/**
 * @param {Number} cueId
 */
export const duplicateCue = (cueId) => async (dispatch) => {
  await duplicateCueCall({ sourceCueId: cueId });
  await dispatch(refetchCues());
  dispatch(showGenericSuccess());
};

export const changeCuePosition = (dragObj) => async (dispatch) => {
  const { cueContainerId, originId, targetId, targettingSegment } = dragObj;
  try {
    if (originId !== targetId) {
      await changeCuePositionCall({
        cueContainerId,
        cueId: originId,
        targetCueId: targetId,
        targettingSegment,
      });
    }
  } finally {
    await dispatch(refetchCues());
  }
};

export const changeSegmentPosition = (dragObj) => async (dispatch) => {
  const { cueContainerId, originId, targetId } = dragObj;
  try {
    if (originId !== targetId) {
      await changeSegmentPositionCall({
        cueContainerId,
        segmentId: originId,
        targetCueId: targetId,
      });
    }
  } finally {
    await dispatch(refetchCues());
  }
};

export const updateQtrackInDialog =
  ({ name, qTrack }) =>
  (dispatch, getState) => {
    const state = getState();
    const previousPayload = prop('payload', getDialogByNameSelector(state, name));
    const updatedPayload = { ...previousPayload, qTrack };
    dispatch(updatePayloadByName({ name, payload: updatedPayload }));
  };

export const reorderRow =
  ({ originSequence, targetSequence }) =>
  async (dispatch, getState) => {
    const state = getState();
    const cueContainerId = getSelectedVersionIdSelector(state);
    const draggableItems = getDraggableItemsSelector(state);

    const { positionChangePayload, originType } = reorder({
      initialItems: draggableItems,
      originSequence,
      targetSequence,
    });
    const changePayload = {
      cueContainerId,
      ...positionChangePayload,
    };

    if (originType === 'segment') {
      dispatch(changeSegmentPosition(changePayload));
    } else {
      dispatch(changeCuePosition(changePayload));
    }
  };
