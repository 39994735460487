import { yupResolver } from '@hookform/resolvers/yup';
import { Box, FormControl, FormControlLabel, Grid, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import { FormField } from 'components/common/Form/Fields/FormField/FormField';
import { formatPresetToRights } from 'components/common/RightsPresetPicker/RightsPresetPickerFormField';
import { SecuredCurrencySelect } from 'components/common/Secured';
import { Row } from 'components/styleguide';
import { useAtomValue, useSetAtom } from 'jotai';
import none from 'ramda/src/none';
import React, { MutableRefObject, useEffect } from 'react';
import { FormProvider, useFieldArray, useForm, UseFormSetValue } from 'react-hook-form';
import { COMMON_ROLES } from 'redux-core/permissions/constants';
import { TPresetToRight, TRightPreset, useGetRightPresetsQuery } from 'services/clear/rights-presets';
import { useGetSeasonsQuery } from 'services/common/seasons';
import { NORMALIZED_PRODUCTION_TYPES, PRODUCTION_TYPES } from 'utils/constants';
import { rootTranslation } from 'utils/format';
import { capitalize } from 'utils/object';
import { v4 as uuidv4 } from 'uuid';
import CampaignDetails from '../CampaignDetails';
import { buttonEnabledAtom } from '../drawerStore';
import ReleaseDetails from '../ReleaseDetails';
import SeasonDetails from '../SeasonDetails';
import { AddNewButton } from '../SeasonDetails/EpisodeList';
import EpisodeRow from '../SeasonDetails/EpisodeList/EpisodeRow';
import { releaseIdAtom } from '../SeasonDetails/seasonStore';

export type FormInput = {
  synopsis: string;
  masterCurrencyId: string;
  watchProduction?: 'true' | 'false';
  season?: string;
  categoryId?: number;
  duration?: number;
  requiredRights?: TRightPreset[] | TPresetToRight[];
  release?: string;
  posterId?: number;
  episodes?: ReturnType<typeof useGetSeasonsQuery>['data'];
};

const tGlobal = rootTranslation('global');

export type ProductionDrawerChildProps = {
  formRef: MutableRefObject<HTMLFormElement>;
  validationSchema;
  divisionId;
  productionType: keyof typeof NORMALIZED_PRODUCTION_TYPES;
  rights: TRightPreset;
  productionSeasons;
  handleSubmit: (args: FormInput) => void;
  savePosterId: (args: { type: string; posterId: number }) => void;
  initialValues: FormInput;
  readOnlyCurrencyPicker: boolean;
};

const t = rootTranslation('drawers.quickSetupProduction');

const ProductionDrawerChild = ({
  formRef,
  validationSchema,
  divisionId,
  productionType,
  productionSeasons,
  handleSubmit: onSubmit,
  initialValues,
  readOnlyCurrencyPicker,
}: ProductionDrawerChildProps) => {
  const setButtonEnabled = useSetAtom(buttonEnabledAtom);

  const formBag = useForm<FormInput>({
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
    mode: 'onChange',
  });

  const formIsDirty = formBag.formState.isDirty;

  useLoadSeasonEpisodes(formBag.setValue);

  const { rightsPresets, requiredRights } = useDefaultRightsPreset(divisionId, formBag.setValue, formIsDirty);

  setButtonEnabled(formBag.formState.isValid);

  const {
    fields: episodesFields,
    append,
    remove,
  } = useFieldArray({
    name: 'episodes',
    control: formBag.control,
  });

  const addNew = React.useCallback(
    () =>
      append({
        uuid: `new-${uuidv4()}`,
        number: '',
        name: '',
      }),
    [append],
  );

  const lastIndex = episodesFields.length - 1;

  return (
    <FormProvider {...formBag}>
      <form ref={formRef} onSubmit={formBag.handleSubmit(onSubmit)}>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }} mb={2}>
          <FormControl>
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }} mb={1}>
              <Typography style={{ fontSize: '0.875rem', fontWeight: 600 }}>{t('watchProduction')}</Typography>
            </Box>
            <Box pl={2}>
              <RadioGroup defaultValue="true" {...formBag.register('watchProduction')}>
                <FormControlLabel
                  value="true"
                  control={<Radio data-testid="watch-production-true" sx={{ padding: '4px' }} size="small" />}
                  label={tGlobal('forms.checkbox.checked')}
                />
                <FormControlLabel
                  value="false"
                  control={<Radio data-testid="watch-production-false" sx={{ padding: '4px' }} size="small" />}
                  label={tGlobal('forms.checkbox.unchecked')}
                />
              </RadioGroup>
            </Box>
          </FormControl>
          <Box>
            <FormField
              label={rootTranslation('productionOverview.tabs.details')('masterCurrency')}
              name="masterCurrencyId"
              required
              render={({ field, muiprops }) => (
                <SecuredCurrencySelect
                  {...field}
                  {...muiprops}
                  placeholder={tGlobal('forms.choose')}
                  data-testid="masterCurrencyId"
                  permissions={COMMON_ROLES.EDITOR}
                  disabled={readOnlyCurrencyPicker}
                />
              )}
            />
            <span style={{ color: '#9A57DD', fontSize: 12, fontStyle: 'italic' }}>{t('clearanceOnly')}</span>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <FormField
            fullWidth
            label={t('synopsis')}
            name="synopsis"
            render={({ field, muiprops }) => (
              <TextField
                {...field}
                {...muiprops}
                id="synopsis"
                data-testid="input-text-synopsis"
                multiline
                rows={4}
                maxRows={4}
                placeholder={t('synopsis')}
                InputLabelProps={{ shrink: true }}
              />
            )}
          />
          <Box>
            <Row divider />
            {productionType === NORMALIZED_PRODUCTION_TYPES.TV && (
              <>
                <SeasonDetails
                  divisionId={divisionId}
                  defaultRightsPreset={requiredRights}
                  seasonList={productionSeasons}
                  rightsPresets={rightsPresets}
                />
                <Grid item xs={12}>
                  <Grid display="flex" direction="row" justifyContent="flex-start" paddingBottom={1}>
                    {lastIndex < 0 &&
                    <Typography alignSelf="start" fontSize={12} fontWeight={700}>
                      {t('oneOrMoreEpisodes')}
                    </Typography>
                    }
                  </Grid>
                  <Grid container direction="row">
                    <AddNewButton emptyFields={none(Boolean, episodesFields)} addNew={addNew} />
                    {episodesFields.map((field, index) => (
                      <EpisodeRow
                        key={field.id}
                        index={index}
                        isLast={index === lastIndex}
                        isFirst={index === 0}
                        remove={() => remove(index)}
                        addNew={addNew}
                      />
                    ))}
                  </Grid>
                </Grid>
              </>
            )}
            {productionType === NORMALIZED_PRODUCTION_TYPES.FEATURES && <ReleaseDetails divisionId={divisionId} />}
            {productionType === NORMALIZED_PRODUCTION_TYPES.MKT && <CampaignDetails />}
          </Box>
        </Box>
      </form>
    </FormProvider>
  );
};

export default ProductionDrawerChild;

function useDefaultRightsPreset(divisionId: number, setValue: UseFormSetValue<FormInput>, formIsDirty: boolean) {
  const { data: presets, isLoading } = useGetRightPresetsQuery({ divisionId }, { skip: !divisionId });
  const defaultRightsPresets: TPresetToRight[] = presets?.filter((f) => f.default).map(formatPresetToRights);

  useEffect(() => {
    if (!isLoading && !formIsDirty && defaultRightsPresets?.length) {
      setValue('requiredRights', defaultRightsPresets, { shouldValidate: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formIsDirty, isLoading, setValue]);

  return { rightsPresets: presets, requiredRights: defaultRightsPresets };
}

function useLoadSeasonEpisodes(setValue: UseFormSetValue<FormInput>) {
  const releaseId = useAtomValue(releaseIdAtom) as number;

  const { data: episodeList, isLoading } = useGetSeasonsQuery(
    {
      parentId: releaseId,
      // @ts-ignore
      type: capitalize(PRODUCTION_TYPES.EPISODE),
    },
    { skip: !releaseId },
  );

  useEffect(() => {
    if (!isLoading && episodeList?.length) {
      setValue('episodes', episodeList, { shouldValidate: true });
    }
  }, [setValue, episodeList, isLoading]);
}
