/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '@material-ui/core/SvgIcon';
import always from 'ramda/src/always';
import cond from 'ramda/src/cond';
import equals from 'ramda/src/equals';
import T from 'ramda/src/T';
import newTheme from 'components/styleguide/newTheme';

const getSvg = cond([
  [
    equals(1),
    always(
      <>
        <path data-testid="qtracks-strength-1" d="M0 12h6v6H0z" />
        <path fill="#fff" stroke="#AAABB1" d="M8.5 6.5h5v11h-5zM16.5.5h5v17h-5z" />
      </>,
    ),
  ],
  [
    equals(2),
    always(
      <>
        <path data-testid="qtracks-strength-2" d="M0 12h6v6H0zM8 6h6v12H8z" />
        <path fill="#fff" stroke="#AAABB1" d="M16.5.5h5v17h-5z" />
      </>,
    ),
  ],
  [(v) => v > 2, always(<path data-testid="qtracks-strength-full" d="M0 12h6v6H0zM8 6h6v12H8zM16 0h6v18h-6z" />)],
  [
    T,
    always(
      <path
        data-testid="qtracks-strength-empty"
        fill="#fff"
        stroke="#AAABB1"
        d="M.5 12.5h5v5h-5zM8.5 6.5h5v11h-5zM16.5.5h5v17h-5z"
      />,
    ),
  ],
]);

const StrengthBars = ({ level, ...props }) => (
  <SvgIcon {...props} viewBox="0 0 22 18" data-testid="strength-bars-icon">
    {getSvg(level)}
  </SvgIcon>
);

StrengthBars.propTypes = {
  level: PropTypes.oneOf([0, 1, 2, 3]),
};

StrengthBars.defaultProps = {
  htmlColor: newTheme.palette.primary.main,
  level: 0,
};

export default StrengthBars;
