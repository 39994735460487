import React, { useEffect } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { v4 as uuidv4 } from 'uuid';
import { rootTranslation } from 'utils/format';
import { useSearchContactsQuery } from 'services/clear/contacts';
import { Autocomplete, Skeleton, TextField, ListItem, ListItemText, Typography } from '@mui/material';

const tGlobal = rootTranslation('global');
const tContactSearch = rootTranslation('contactSearch');

type TContact = {
  id: number;
  name: string;
  email: string;
  companyName: string;
};

type FieldProps = {
  value?: Pick<TContact, 'id' | 'name' | 'email' | 'companyName'>;
  onChange: (nextValue: TContact) => void;
  onCreate: (name: string) => void;
  divisionId: number;
};

type AutoCompleteValue = TContact | (TContact & { inputValue: string }) | null;

export function ContactSearch({ value, onChange, onCreate, divisionId }: FieldProps) {
  const [searchTerm, setSearchTerm] = React.useState('');
  const [options, setOptions] = React.useState([]);
  const { data, isLoading, isFetching } = useSearchContactsQuery({
    fields: ['name', 'email', 'licensor_company_name'],
    term: searchTerm,
    useCurrentDivision: false,
    divisionId,
  });

  useEffect(() => {
    if (!data) return;
    setOptions(data?.map((contact) => ({
      id: contact.contactId,
      name: contact.fullName,
      email: contact.email,
      companyName: contact.companyName,
    })));
  }, [data]);

  const debouncedSetSearch = useDebouncedCallback((term) => {
    setSearchTerm(term);
  }, 300);

  if (isLoading) {
    return <Skeleton />;
  }

  return (
    <Autocomplete<AutoCompleteValue, false, false, true>
      options={options}
      value={value}
      onChange={(_, newValue) => {
            if (newValue == null) {
              // @ts-ignore
              onChange(newValue);
              return;
            }
            if (typeof newValue === 'string') {
              onCreate(newValue);
            } else if ('inputValue' in newValue) {
              // Create a new value from the user input
              onCreate(newValue.inputValue);
            } else {
              onChange(newValue);
            }
          }}
      onInputChange={(_, newInputValue) => {
            debouncedSetSearch(newInputValue);
          }}
      loading={isFetching}
      noOptionsText={tGlobal('noResults')}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={tContactSearch('placeholder')}
          inputProps={{ ...params.inputProps, 'data-testid': 'contact-search' }}
        />
          )}
      getOptionLabel={(option) => {
            if (typeof option === 'string') {
              return option;
            }

            if ('inputValue' in option) {
              return option?.inputValue;
            }

            return option?.name;
          }}
      isOptionEqualToValue={(option, valueOption) => option?.id === valueOption?.id}
      filterOptions={(currentOptions, params) => {
            const filtered = currentOptions;
            const { inputValue } = params;
            const isExisting = currentOptions
                .some((option) => inputValue === option.name || inputValue === option.email);
            if (inputValue !== '' && !isExisting) {
              filtered.push({
                id: uuidv4(),
                name: `${tGlobal('forms.createNew')} "${inputValue}"`,
                email: '',
                companyName: '',
                inputValue,
              });
            }
            return filtered;
          }}
      renderOption={(optionProps, option) => (
        <ListItem
          {...optionProps}
          style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}
          key={option.id}
        >
          <ListItemText
            primary={option.name}
            secondary={
              <>
                <Typography component="p" variant="body2" color="text.primary">
                  {option.email}
                </Typography>
                <Typography component="p" variant="caption" color="text.primary" textTransform="uppercase">
                  {option.companyName}
                </Typography>
              </>
                    }
          />
        </ListItem>
          )}
    />
  );
}
