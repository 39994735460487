/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-children-prop */
/* eslint-disable @typescript-eslint/no-shadow */
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { FormScenesDropdown, FormButton, FormInputText, FormInputFormat } from 'components/common/Form/Fields';
import { optionBoundaries } from 'components/common/Form/Fields/FormScenesDropdown';
import { Grid, Box } from '@material-ui/core';
import { BaseDrawer, DrawerHeader } from 'components/styleguide';
import * as Yup from 'yup';
import { useFetch, useRootTranslation } from 'utils/hooks';
import { getCueContainerCall } from 'redux-core/cueContainers/services';
import { TIME_FORMATS } from 'utils/format';
import { yupTimeMMSS } from 'utils/forms';
import { fuse } from 'utils/object';
import findIndex from 'ramda/src/findIndex';
import propEq from 'ramda/src/propEq';
import { SecuredForm } from 'components/common/Secured';
import { COMMON_ROLES } from 'redux-core/permissions/constants';
import enhancer from './enhancer';
import { CommonDrawerPropTypes } from '../constants';
import { SCENE_DRAWER_FORM_FIELD } from './constants';

const rootT = 'drawers.productionSetup.scenes';

const validationSchema = Yup.object().shape({
  [SCENE_DRAWER_FORM_FIELD.TITLE]: Yup.string().required('global.forms.validations.required'),
  duration: yupTimeMMSS,
});

const SceneDrawer = ({
  name,
  onClose,
  createCueContainer,
  fetchCueContainersLight,
  updateCueContainer,
  payload: { id, ...payload },
  locationKey,
  params: { type, divisionId, tenantId },
}) => {
  const t = useRootTranslation(rootT);
  const sceneNumberInputRef = useRef();

  const [scenes] = useFetch(fetchCueContainersLight);

  const [values, loading] = useFetch(
    () => id && getCueContainerCall(type)({ id, divisionId, tenantId }),
    [id, locationKey],
  );

  const isEditing = Boolean(id);
  const getPosition = () => {
    if (isEditing) return undefined;
    const index = findIndex(propEq('id', id))(scenes);
    return index < 1 ? optionBoundaries.BOTTOM.value : scenes[index - 1].id;
  };
  const initialValues = { ...payload, ...values, position: getPosition() };

  const handleClose = () => {
    onClose({ content: t('notification.prompt') });
  };

  const handleSubmit = async (values) => {
    const action = id ? updateCueContainer : createCueContainer;
    await action(values);
    await handleClose();
  };

  const handleSubmitAndAddNew = (actions) => async (values) => {
    const action = id ? updateCueContainer : createCueContainer;
    await action(fuse(values, { duration: null }));
    await actions.resetForm();
    sceneNumberInputRef.current.focus();
  };

  return (
    <SecuredForm
      drawerName={name}
      enableReinitialize
      initialValues={initialValues}
      objectId={initialValues.objectId}
      onSubmit={handleSubmit}
      permissions={COMMON_ROLES.EDITOR}
      readOnly={loading}
      validationSchema={validationSchema}
    >
      {(formikBag) => (
        <>
          <DrawerHeader title={t(`${id ? 'drawerEditTitle' : 'drawerTitle'}`)} onClose={() => handleClose()} />
          <Box mb={1}>
            <Grid container>
              {!isEditing && (
                <Grid container>
                  <Grid item xs={12}>
                    <Box mb={1}>
                      <FormScenesDropdown
                        name={SCENE_DRAWER_FORM_FIELD.POSITION}
                        label={`${rootT}.form.${SCENE_DRAWER_FORM_FIELD.POSITION}.label`}
                        placeholder={`${rootT}.form.${SCENE_DRAWER_FORM_FIELD.POSITION}.placeholder`}
                        divider={t(`form.${SCENE_DRAWER_FORM_FIELD.POSITION}.divider`)}
                        options={scenes}
                        isEditing={isEditing}
                      />
                    </Box>
                  </Grid>
                </Grid>
              )}
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <Box mb={1}>
                    <FormInputText
                      autoFocus
                      testId={SCENE_DRAWER_FORM_FIELD.NUMBER}
                      label={`${rootT}.form.${SCENE_DRAWER_FORM_FIELD.NUMBER}.label`}
                      placeholder={`${rootT}.form.${SCENE_DRAWER_FORM_FIELD.NUMBER}.placeholder`}
                      name={SCENE_DRAWER_FORM_FIELD.NUMBER}
                      inputRef={sceneNumberInputRef}
                    />
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box mb={1}>
                    <FormInputFormat
                      format={TIME_FORMATS.MMSS}
                      testId={SCENE_DRAWER_FORM_FIELD.LENGTH}
                      label={`${rootT}.form.${SCENE_DRAWER_FORM_FIELD.LENGTH}.label`}
                      name={SCENE_DRAWER_FORM_FIELD.LENGTH}
                      type="time"
                    />
                  </Box>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12}>
                  <Box mb={1}>
                    <FormInputText
                      testId={SCENE_DRAWER_FORM_FIELD.TITLE}
                      label={`${rootT}.form.${SCENE_DRAWER_FORM_FIELD.TITLE}.label`}
                      placeholder={`${rootT}.form.${SCENE_DRAWER_FORM_FIELD.TITLE}.placeholder`}
                      name={SCENE_DRAWER_FORM_FIELD.TITLE}
                      required
                    />
                  </Box>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12}>
                  <Box mb={1}>
                    <FormInputText
                      testId={SCENE_DRAWER_FORM_FIELD.DESCRIPTION}
                      label={`${rootT}.form.${SCENE_DRAWER_FORM_FIELD.DESCRIPTION}.label`}
                      placeholder={`${rootT}.form.${SCENE_DRAWER_FORM_FIELD.DESCRIPTION}.placeholder`}
                      name={SCENE_DRAWER_FORM_FIELD.DESCRIPTION}
                      textArea
                    />
                  </Box>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12}>
                  <Box mb={1}>
                    <FormInputText
                      testId={SCENE_DRAWER_FORM_FIELD.NOTES}
                      label={`${rootT}.form.${SCENE_DRAWER_FORM_FIELD.NOTES}.label`}
                      placeholder={`${rootT}.form.${SCENE_DRAWER_FORM_FIELD.NOTES}.placeholder`}
                      name={SCENE_DRAWER_FORM_FIELD.NOTES}
                      textArea
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Box>

          <Grid container justify="space-around">
            <Grid item xs={6}>
              <FormButton
                testId="scene-drawer-cancel"
                variant="destructive"
                color="secondary"
                size="xs"
                children="global.forms.cancel"
                type="button"
                alwaysEnabled
                onClick={() => handleClose()}
              />
            </Grid>
            <Grid item xs={6}>
              <Grid container justify="flex-end" spacing={2}>
                {id ? (
                  <Grid item>
                    <FormButton
                      testId="scene-drawer-save"
                      variant="primary"
                      size="sm"
                      children="global.forms.save"
                      type="submit"
                    />
                  </Grid>
                ) : (
                  <>
                    <Grid item>
                      <FormButton
                        testId="scene-drawer-save-and-close"
                        variant="secondary"
                        color="primary"
                        size="sm"
                        children="global.forms.saveAndClose"
                        type="submit"
                      />
                    </Grid>
                    <Grid item>
                      <FormButton
                        testId="scene-drawer-save-and-add-new"
                        variant="primary"
                        size="sm"
                        children="global.forms.saveAndAddNew"
                        type="button"
                        onClick={handleSubmitAndAddNew(formikBag)}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </SecuredForm>
  );
};

SceneDrawer.propTypes = {
  createCueContainer: PropTypes.func,
  fetchCueContainersLight: PropTypes.func,
  params: PropTypes.object,
  name: PropTypes.string,
  locationKey: PropTypes.string.isRequired,
  onClose: CommonDrawerPropTypes.onClose,
  updateCueContainer: PropTypes.func,
  payload: PropTypes.shape({
    id: PropTypes.number,
  }),
};

const Drawer = ({ open, onClose, ...props }) => (
  <BaseDrawer open={open} onClose={onClose}>
    <SceneDrawer onClose={onClose} {...props} />
  </BaseDrawer>
);

Drawer.propTypes = CommonDrawerPropTypes;

export default enhancer(Drawer);
