/* eslint-disable import/no-cycle */
import makeEntityServiceCalls, { makeServiceCall } from 'redux-core/serviceHelpers';
import { setValue } from 'utils/object';
import { LICENSOR_TYPES } from 'utils/constants';

export const { update: updateLettersCall } = makeEntityServiceCalls('letters', 'clear');

export const getBatchLetters = async (payload) => {
  const letters = await makeServiceCall('letters', 'get-batch', 'clear')(payload);
  return letters.map((letter) =>
    letter.trackOneStop ? setValue('licensor.type', LICENSOR_TYPES.ONE_STOP, letter) : letter,
  );
};
export const getLetterDocument = makeServiceCall('letters', 'get-document', 'clear');
export const getLetterEmail = makeServiceCall('letters', 'get-email', 'clear');
export const sendLetterCall = makeServiceCall('letters', 'send', 'clear');
export const getLetterRepEmails = makeServiceCall('letters', 'get-rep-emails', 'clear');

export const getDraftLetterCall = makeServiceCall('letters', 'get-draft', 'clear');

export const saveDraftLetterCall = makeServiceCall('letters', 'save-draft', 'clear');

export const updateStatusAndDateCall = makeServiceCall('letters', 'update-status-and-date', 'clear');

export const saveLicensorContactCall = makeServiceCall('letters', 'save-licensor-contact', 'clear');
