import React from 'react';
import { InputLabel } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { kebabCase } from 'lodash';
import { rootTranslation } from 'utils/format';

const t = rootTranslation('drawers.productionSetup.tracks.details');
const tGlobal = rootTranslation('global');

type PublisherSectionProps = {
  sectionLabel: string;
  children: React.ReactNode;
};

export function PublisherFormSection({ sectionLabel, children }: PublisherSectionProps) {
  const htmlSectionId = kebabCase(sectionLabel);

  return (
    <Grid
      aria-label={`${sectionLabel} ${tGlobal('section')}`}
      xs={16}
      container
      columns={16}
      rowSpacing={1}
      columnSpacing={2}
      sx={{ backgroundColor: 'secondary.300', m: 0, borderRadius: 1, pb: 2 }}
    >
      <Grid xs={4}>
        <InputLabel id={`${htmlSectionId}-name`}>{sectionLabel}</InputLabel>
      </Grid>
      <Grid xs={4}>
        <InputLabel id={`${htmlSectionId}-sync`}>{t('syncLicensor')}</InputLabel>
      </Grid>
      <Grid xs={2}>
        <InputLabel id={`${htmlSectionId}-role`}>{t('role')}</InputLabel>
      </Grid>
      <Grid xs={3}>
        <InputLabel id={`${htmlSectionId}-pro`}>{t('pro')}</InputLabel>
      </Grid>
      <Grid xs={2}>
        <InputLabel id={`${htmlSectionId}-share`}>{t('share')}</InputLabel>
      </Grid>
      <Grid xs={1} />
      {children}
    </Grid>
  );
}
