import { Alert, Box, Grid, InputLabel, Skeleton, Typography } from '@mui/material';
import { TInputFile } from 'components/Licensors/drawer/upload-file/UploadFilePanel';
import { Dropzone } from 'components/styleguide';
import { useRef } from 'react';
import { rootTranslation } from 'utils/format';
import { useGetProductionAttachmentsByLicensorsQuery } from 'services/clear/production-attachments';
import { usePatchUpdateLicensorsMutation } from 'services/clear/licensors';
import { useAtomValue, useSetAtom } from 'jotai';
import { composeLetterAtom } from '../composeLetterStore';
import { ComposeLetterComposeEmailFileAttachment } from './ComposeLetterComposeEmailFileAttachment';

const tUpload = rootTranslation('mainLicensors.drawer.uploadFile');
const t = rootTranslation('composeLetters.attachments');

export function ComposeLetterComposeEmailFileAttachments() {
  const composeLetter = useAtomValue(composeLetterAtom);
  const setComposeLetter = useSetAtom(composeLetterAtom);
  const [updateLicensors] = usePatchUpdateLicensorsMutation();

  const ref = useRef();
  const {
    data: attachments,
    isLoading,
    refetch: refetchAttachments,
  } = useGetProductionAttachmentsByLicensorsQuery({
    clearanceBundleLicensorsIds: [composeLetter.clearanceBundleLicensor.id],
  });

  return (
    <Grid item flexGrow={1} container flexDirection="column" gap={2}>
      <InputLabel>{t('fieldLabel')}</InputLabel>
      {(composeLetter.letter.docxUrl || composeLetter.letter.pdfUrl) &&
        <Alert severity="info">
          {t('letterInfo')}
        </Alert>
      }
      <Grid item flexGrow={1} container flexDirection="column" gap={3}>
        {isLoading && <Skeleton />}
        {!isLoading && (
          <>
            <Grid sx={{ height: '150px' }}>
              <Dropzone
                ref={ref}
                uploadText={
                  <Box sx={{ textAlign: 'center' }}>
                    <Typography variant="subtitle1">{tUpload('filePickerPlaceholder')}</Typography>
                    <Typography variant="body2" color="text.secondary">
                      {tUpload('filePickerPlaceholderSecondary')}
                    </Typography>
                  </Box>
                }
                onDrop={async (file: TInputFile) => {
                  const { fileName, fileSize, uploadedFileId } = file;
                  await updateLicensors({
                    clearanceBundleLicensorsIds: composeLetter.clearanceBundleLicensorsIds,
                    filesInfo: [{ fileName, fileSize, uploadedFileId, fileType: 'Other' }],
                  }).unwrap();
                  refetchAttachments();
                }}
                setIsParentLoading={(loading) => {
                  setComposeLetter((prev) => ({ ...prev, email: { ...prev.email, editorReady: !loading } }));
                }}
              />
            </Grid>
            <Grid flexGrow={1} container gap={3}>
              {!attachments?.length && (
                <Typography variant="body2" color="text.secondary">
                  {t('noAttachments')}
                </Typography>
              )}
              {attachments?.map((attachment) => (
                <ComposeLetterComposeEmailFileAttachment
                  id={attachment.id}
                  fileName={attachment.fileName}
                  createdAt={attachment.createdAt}
                />
              ))}
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  );
}
