/* eslint-disable react/no-children-prop */
// THIS IS NO LONGER USED THE ENTIRE CLEARANCEOTHERUSEANDDURATIONDRAWER CAN BE DELETED
import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Form from 'components/common/Form';
import { useRootTranslation } from 'utils/hooks';
import { FormButton, FormInputText } from 'components/common/Form/Fields';
import { DrawerHeader } from 'components/styleguide';
import * as Yup from 'yup';
import enhancer from './enhancer';

const validationSchema = Yup.object().shape({
  usageNotes: Yup.string().required('global.forms.validations.required'),
});

const ClearanceOtherUseAndDurationDrawer = ({
  name,
  onClose,
  payload: {
    initialValues: { useTypeOtherFieldName, ...initialValues },
  },
  ...formConnectedProps
}) => {
  const t = useRootTranslation('drawers.clearanceOtherUseAndDuration');

  const handleSubmit = async ({ usageNotes }) => {
    await formConnectedProps.updateIn(useTypeOtherFieldName, usageNotes);
    onClose();
  };

  return (
    <Form
      drawerName={name}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      enableReinitialize
      initialValues={initialValues}
    >
      <DrawerHeader title={t('drawerTitle')} hideBackButton onClose={onClose}>
        <FormButton alwaysEnabled children="global.forms.cancel" size="xs" variant="destructive" onClick={onClose} />
        <FormButton children="global.forms.save" size="xs" type="submit" variant="primary" />
      </DrawerHeader>
      <Grid container>
        <Grid item xs={12}>
          <FormInputText textArea label={t('usageNotes')} name="usageNotes" testId="cue-other-usageNotes-textarea" />
        </Grid>
      </Grid>
    </Form>
  );
};

ClearanceOtherUseAndDurationDrawer.propTypes = {
  name: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  payload: PropTypes.shape({
    onSubmit: PropTypes.func.isRequired,
    initialValues: PropTypes.object,
  }),
  searchForUsers: PropTypes.func.isRequired,
  updateIn: PropTypes.func.isRequired,
};

export default enhancer(ClearanceOtherUseAndDurationDrawer);
