import { connect } from 'react-redux';
import { getLocationParametersSelector } from 'redux-core/router/selectors';
import { updateReleaseOrEpisode } from 'redux-core/overview/actions';
import { updateCampaign } from 'redux-core/overview/production-marketing/actions';
import { getSelectedProductionIdSelector } from 'redux-core/overview/selectors';
import compose from 'ramda/src/compose';
import withBaseDrawer from '../withBaseDrawer';

const mapStateToProps = (state) => ({
  params: getLocationParametersSelector(state),
  productionId: getSelectedProductionIdSelector(state),
});

const mapDispatchToProps = {
  updateReleaseOrEpisode,
  updateCampaign,
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withBaseDrawer);
