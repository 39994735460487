/* eslint-disable react/no-children-prop */
/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import { FormButton, FormTypeAhead, FormCheckbox } from 'components/common/Form/Fields';
import { DrawerHeader, Text } from 'components/styleguide';
import { useRootTranslation } from 'utils/hooks';
import {
  exportCsvCall,
  exportPdfCall,
  exportXlsCall,
  exportFormattedXlsxCall,
  exportRapidCueXMLCall,
} from 'redux-core/cueSheet/services';
import { downloadFromUrl } from 'redux-core/images/services';
import Form from 'components/common/Form';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import * as Yup from 'yup';
import { requestWithError } from 'utils/request';
import { translatedOptions } from 'utils/constants';
import enhancer from './enhancer';

export const RIGHT_OWNERS_PRESET_DRAWER_ACTIONS = {
  APPLY: 'APPLY',
  SAVE: 'SAVE',
};

const validationSchema = Yup.object().shape({
  selectedType: Yup.string().required('global.forms.validations.required'),
  includeNotes: Yup.boolean(),
});

const FILE_TYPES = {
  FormattedXLSX: 'Formatted XLSX',
  PDF: 'PDF',
  XLSX: 'XLSX',
  CSV: 'CSV',
  RapidCueXML: 'RapidCue XML',
};

const FILE_TYPES_OPTIONS = translatedOptions('global.fileFormats')(FILE_TYPES);

const FILE_TYPE_ENDPOINTS = {
  [FILE_TYPES.CSV]: exportCsvCall,
  [FILE_TYPES.PDF]: exportPdfCall,
  [FILE_TYPES.XLSX]: exportXlsCall,
  [FILE_TYPES.FormattedXLSX]: exportFormattedXlsxCall,
  [FILE_TYPES.RapidCueXML]: exportRapidCueXMLCall,
};

const FORMATTED_XLS_EXTENSION = 'XLSX';
const RAPIDCUE_XML_EXTENSION = 'XML';

const CueSheetExportDrawer = ({ onClose, name, payload: { selectedVersionId }, projectName, productionName }) => {
  const t = useRootTranslation('drawers.cueSheetExport');

  return (
    <Form
      drawerName={name}
      initialValues={{
        selectedType: FILE_TYPES.FormattedXLSX,
        generateNotes: true,
        addSegmentInfo: true,
      }}
      validationSchema={validationSchema}
      onSubmit={async ({ selectedType, generateNotes, addSegmentInfo }) => {
        const call = FILE_TYPE_ENDPOINTS[selectedType];

        const supportsSegmentInfo = [FILE_TYPES.PDF, FILE_TYPES.FormattedXLSX, FILE_TYPES.CSV].includes(selectedType);
        const supportsNotes = selectedType === FILE_TYPES.PDF;

        const exportCueSheetDto = {
          cueContainerId: selectedVersionId,
          generateNotes: supportsNotes ? generateNotes : undefined,
          addSegmentInfo: supportsSegmentInfo ? addSegmentInfo : undefined,
        };

        const res = await requestWithError(call, exportCueSheetDto);

        await downloadFromUrl(
          res.url,
          `${projectName}-${productionName}-${t('cueSheet')}.${
            selectedType === FILE_TYPES.FormattedXLSX
              ? FORMATTED_XLS_EXTENSION.toLowerCase()
              : selectedType === FILE_TYPES.RapidCueXML
              ? RAPIDCUE_XML_EXTENSION.toLowerCase()
              : selectedType.toLowerCase()
          }`,
        );
        onClose();
      }}
      enableReinitialize
    >
      {({ values: { selectedType } }) => (
        <>
          <DrawerHeader title={t('title')} hideBackButton onClose={onClose}>
            <FormButton
              alwaysEnabled
              children="global.forms.cancel"
              size="xs"
              testId="cancel-button"
              onClick={() => {
                onClose();
              }}
              variant="destructive"
            />
            <FormButton
              alwaysEnabled
              children="global.forms.save"
              size="xs"
              testId="save-button"
              type="submit"
              variant="primary"
            />
          </DrawerHeader>
          <Grid container alignItems="center">
            <Grid item xs={4}>
              <Text>{t('selectFileType')}</Text>
            </Grid>
            <Grid item xs={8}>
              <FormTypeAhead name="selectedType" testId="exportType" options={FILE_TYPES_OPTIONS} />
            </Grid>
          </Grid>
          {selectedType === FILE_TYPES.PDF && (
            <Grid container alignItems="center">
              <Box width={35}>
                <FormCheckbox name="generateNotes" />
              </Box>
              <Text>{t('includeNotes')}</Text>
            </Grid>
          )}
          {[FILE_TYPES.PDF, FILE_TYPES.FormattedXLSX, FILE_TYPES.CSV].includes(selectedType) && (
            <Grid container alignItems="center">
              <Box width={35}>
                <FormCheckbox name="addSegmentInfo" />
              </Box>
              <Text>{t('addSegmentInfo')}</Text>
            </Grid>
          )}
        </>
      )}
    </Form>
  );
};

CueSheetExportDrawer.propTypes = {
  onClose: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  projectName: PropTypes.string.isRequired,
  productionName: PropTypes.string.isRequired,
  payload: PropTypes.shape({}),
};

export default enhancer(CueSheetExportDrawer);
