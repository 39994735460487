/* eslint-disable max-len */
import { useEffect, useState } from 'react';
import { useAtomValue, useSetAtom } from 'jotai';
import { useResetAtom } from 'jotai/utils';
import { Redirect } from 'react-router-dom';
import ROUTES from 'components/Routes/routes';
import { useFindDraftQuery } from 'services/clear/letters';
import { Skeleton } from '@mui/material';
import { useGetClearanceStatusListQuery } from 'services/clear/clearance-status';
import { ComposeLetterSelectContactStep } from './ComposeLetterSelectContactStep/ComposeLetterSelectContactStep';
import { composeLetterAtom } from './composeLetterStore';
import { ComposeLetterWriteLetterContentStep } from './ComposeLetterWriteLetterContentStep/ComposeLetterWriteLetterContentStep';
import { ComposeLetterComposeEmailStep } from './ComposeLetterComposeEmailStep/ComposeLetterComposeEmailStep';

const Steps = {
  0: <Step0 />,
  1: <ComposeLetterSelectContactStep />,
  2: <ComposeLetterWriteLetterContentStep />,
  3: <ComposeLetterComposeEmailStep />,
};

function ComposeLetterSteps({ clearanceBundleLicensorsIds, letterType, stepNumber, divisionId }) {
  const [draftRequestFulfilled, setDraftRequestFulfilled] = useState(false);
  const setComposeLetter = useSetAtom(composeLetterAtom);
  const getComposeLetter = useAtomValue(composeLetterAtom);
  const { data, isLoading: isLoadingDraft } = useFindDraftQuery(
    { clearanceBundleLicensorsIds, letterType, divisionId },
    { skip: draftRequestFulfilled },
  );
  const { data: clearanceStatusList, isLoading: isLoadingClearanceStatus } = useGetClearanceStatusListQuery({
    divisionId,
    withAll: true,
  });

  useEffect(() => {
    if (isLoadingClearanceStatus) return;
    const mapLetterType = {
      QUOTE_REQUEST: 'Quote Request Sent',
      CONFIRMATION: 'Confirmation Sent',
      QUOTE_RENEWAL: 'Quote Request Sent',
      LICENSE_REQUEST: 'License Request Sent',
      LICENSE: 'License Sent',
      CHECK_REQUEST: 'Check Request Sent',
    };
    const clearanceStatusId =
      clearanceStatusList.filter(
        (item) => item.label === mapLetterType[getComposeLetter.letterType],
      ).pop().id;

    setComposeLetter((prev) => ({
      ...prev,
      letter: {
        ...prev.letter,
        clearanceStatusId,
      },
      email: {
        ...prev.email,
        clearanceStatusId,
      },
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingClearanceStatus]);

  useEffect(() => {
    if (draftRequestFulfilled) return;
    if (isLoadingDraft) return;

    if (!data) {
      setDraftRequestFulfilled(true);
      return;
    }

    const draftData = data as any;
    setComposeLetter((prev) => ({
      ...prev,
      contact: {
        ...draftData.contact,
      },
      letter: {
        ...prev.letter,
        templateId: draftData.letterTemplateId,
        draftId: undefined,
        editorReady: false,
      },
    }));
    setDraftRequestFulfilled(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [draftRequestFulfilled, setDraftRequestFulfilled, isLoadingDraft, isLoadingClearanceStatus, data, setComposeLetter]);

  if (!draftRequestFulfilled) return <Skeleton />;

  return Steps[stepNumber];
}

function Step0() {
  const composeLetter = useAtomValue(composeLetterAtom);
  if (composeLetter.goToLink) {
    composeLetter.goToLink();
    return null;
  }
  return <Redirect to={ROUTES.LICENSORS} />;
}

export function ComposeLetter() {
  const composeLetter = useAtomValue(composeLetterAtom);
  const resetComposeLetterAtom = useResetAtom(composeLetterAtom);

  // reset atom on unmount
  // eslint-disable-next-line arrow-body-style
  useEffect(() => {
    return () => {
      resetComposeLetterAtom();
    };
  }, [resetComposeLetterAtom]);

  const redirectToLicensors = !composeLetter?.clearanceBundleLicensorsIds;
  if (redirectToLicensors) {
    return <Step0 />;
  }

  return (
    <ComposeLetterSteps
      divisionId={composeLetter.divisionId}
      clearanceBundleLicensorsIds={composeLetter.clearanceBundleLicensorsIds}
      letterType={composeLetter.letterType}
      stepNumber={composeLetter.currentStep.stepNumber}
    />
  );
}
