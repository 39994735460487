import * as Yup from 'yup';
import Form from 'components/common/Form';
import { rootTranslation } from 'utils/format';
import { requestWithError } from 'utils/request';
import { Role } from 'utils/roles';
import { inviteMultipleProductionUsers } from 'redux-core/productions/services';
import { DynamicShareWithUsersFields } from './DynamicShareWithUsersFields';
import { ShareWithUserDrawerHeader } from './ShareWithUserDrawerHeader';

export type ShareWithUserFormProps = {
  drawerName: string;
  divisionId: number;
  objectId: number;
  isSeasonRelease: boolean;
  isSeasonEpisode: boolean;
  openPromptAsync: (config: {
    cancelLabel: string;
    okLabel: string;
    content: string;
    maxWidth: string;
  }) => Promise<void>;
  refetchProductionUsers: () => Promise<void>;
  onClose: () => void;
};

export type ShareWithUser = {
  transientId: number;
  email: string;
  defaultProjectRole?: Role;
};

export type ShareWithUserFormValues = {
  shareList: ShareWithUser[];
};

const t = rootTranslation('drawers');

export const ShareWithUserForm = ({
  drawerName,
  divisionId,
  objectId,
  isSeasonRelease,
  isSeasonEpisode,
  refetchProductionUsers,
  openPromptAsync,
  onClose,
}: ShareWithUserFormProps) => {
  const submitInvites = async (values: any) => {
    if (isSeasonRelease || isSeasonEpisode) {
      const promptContent = isSeasonRelease ? 'seasonReleasePrompt' : 'seasonEpisodePrompt';
      await openPromptAsync({
        cancelLabel: t('shareWithUser.notifications.cancel'),
        content: t(`shareWithUser.notifications.${promptContent}`),
        maxWidth: 'sm',
        okLabel: t('shareWithUser.notifications.ok'),
      });
    }
    const inviteList = values.shareList.map((item) => ({
      ...item,
      objectId,
    }));
    const body = {
      inviteList,
      objectId,
      divisionId,
    };
    await requestWithError(inviteMultipleProductionUsers, body, {
      message: {
        success: t('inviteUsers.notifications.multipleInvite.success'),
        failed: t('inviteUsers.notifications.multipleInvite.failed'),
      },
    });
    await refetchProductionUsers();
  };

  const initialValues: ShareWithUserFormValues = {
    shareList: [{ transientId: Date.now(), email: '' }],
  };

  return (
    <Form
      drawerName={drawerName}
      onSubmit={submitInvites}
      enableReinitialize
      initialValues={initialValues}
      validate={validateInvitesUsers}
    >
      <ShareWithUserDrawerHeader onClose={onClose} />
      <DynamicShareWithUsersFields divisionId={divisionId} objectId={objectId} />
    </Form>
  );
};

const validateInvitesUsers = ({ shareList }: ShareWithUserFormValues) => {
  const shareListErrors = shareList.map((shareItem) => {
    const isEmailValid = Yup.string().email().required().isValidSync(shareItem.email);
    const isRoleDefined = !!shareItem.defaultProjectRole;

    return {
      email: isEmailValid ? null : 'global.forms.validations.types.email',
      defaultProjectRole: isRoleDefined ? null : 'global.forms.validations.required',
    };
  });

  const isValidForm = shareListErrors.every(({ email, defaultProjectRole }) => !email && !defaultProjectRole);
  if (isValidForm) return null;

  return {
    shareList: shareListErrors,
  };
};
