import { Autocomplete, TextField } from '@mui/material';
import { TPartialRightOwner } from 'services/tracks/types';
import { rootTranslation } from 'utils/format';

const tGlobal = rootTranslation('global');

type RolePickerProps = {
  value: TPartialRightOwner;
  onChange: (nextValue: TPartialRightOwner) => void;
  htmlSectionId: string;
  options: TPartialRightOwner[];
};

export function RelatedSyncPublisherPicker({ htmlSectionId, value, onChange, options }: RolePickerProps) {
  return (
    <Autocomplete
      key={`RelatedSyncPublisherPicker-${value?.id}`}
      fullWidth
      disableClearable
      disablePortal
      options={options}
      value={value}
      onChange={(_, nextValue: typeof options[number]) => {
        onChange(nextValue);
      }}
      getOptionLabel={(option) => option.name}
      isOptionEqualToValue={(option, optionValue) => option?.id === optionValue?.id}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={tGlobal('forms.chooseOne')}
          inputProps={{
            ...params.inputProps,
            'aria-labelledby': `${htmlSectionId}-sync`,
          }}
        />
      )}
      noOptionsText={tGlobal('noResults')}
      componentsProps={{
        paper: {
          sx: {
            minWidth: '580px',
            width: '100%',
          },
        },
      }}
    />
  );
}
