import React from 'react';
import PropTypes from 'prop-types';
import { Dropzone } from 'components/styleguide';
import Box from '@material-ui/core/Box';
import enhancer from './enhancer';

const ACCEPTED_FORMATS = '.xls,.xlsx,.csv,.edl';

const FileInput = ({ uploadCueSheetFile }) => {
  const onDrop = ({ fileName }, file) => {
    uploadCueSheetFile(file, fileName);
  };

  return (
    <Box height={400} width={1}>
      <Dropzone accept={ACCEPTED_FORMATS} name="fileName" onDrop={onDrop} returnBlob uploadFile={false} />
    </Box>
  );
};

FileInput.propTypes = {
  uploadCueSheetFile: PropTypes.func.isRequired,
};

export default enhancer(FileInput);
