import { makeStyles } from '@material-ui/core';
import { appColors } from '../../theme';

const styles = makeStyles((theme) => ({
  withBorder: {
    border: '1px solid',
    borderColor: appColors.grey,
    padding: theme.spacing(0, 1),
    flex: 1,
  },
}));

export default styles;
