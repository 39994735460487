/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable @typescript-eslint/no-shadow */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/common/Modal';
import { Box, Grid, DialogContent, Typography } from '@material-ui/core';
import { Button } from 'components/styleguide';
import { useTranslation } from 'react-i18next';
import { pick, pipe } from 'ramda';
import DivisionDropdown from 'components/common/DivisionDropdown/index';
import { COMMON_PERMISSIONS } from 'redux-core/permissions/constants';
import { useSecuredDivisions } from 'utils/hooks';
import enhancer from './enhancer';
import ProjectField from './ProjectField';
import useStyles from './styles';

const Content = enhancer(({ handleClose, initProductionSetup }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const divisions = useSecuredDivisions(COMMON_PERMISSIONS.CREATE);
  const [production, setProduction] = useState();

  const [division, setDivision] = useState(divisions.length === 1 ? divisions[0] : null);

  useEffect(() => {
    setProduction(null);
  }, [division]);

  /** Get { tenantId, divisionId, type } */
  const [initProductionSetupArguments, setArguments] = useState({});
  useEffect(() => {
    if (!division) return;

    const values = pipe(
      (division) => ({ ...division, divisionId: division.id }),
      pick(['tenantId', 'type', 'divisionId']),
    )(division);

    setArguments(values);
  }, [division]);

  const handleChange = (division) => setDivision(division);
  const divisionId = division ? division.id : '';

  return (
    <DialogContent className={classes.content}>
      <Typography variant="h5" gutterBottom className={classes.title}>
        {t('projects.newProduction.title')}
      </Typography>
      <Box width={1} mb={3}>
        <DivisionDropdown
          divisions={divisions}
          placeholder={t('projects.newProduction.division')}
          onChange={handleChange}
          value={divisionId}
        />
      </Box>
      <ProjectField
        disabled={!division}
        division={division && initProductionSetupArguments}
        onChange={setProduction}
        onCreate={(title) => initProductionSetup(initProductionSetupArguments, null, title)}
        value={production}
      />
      <Grid container justify="space-between" className={classes.buttonContainer}>
        <Button variant="destructive" size="md" onClick={handleClose} testId="production-modal-cancel">
          {t('projects.newProduction.cancel')}
        </Button>
        <Button
          disabled={!division || !production}
          onClick={() => initProductionSetup(initProductionSetupArguments, production)}
          size="md"
          variant="primary"
          testId="production-modal-next"
        >
          {t('projects.newProduction.next')}
        </Button>
      </Grid>
    </DialogContent>
  );
});

const CreateProductionModal = ({ onClose, open }) => (
  <Modal open={open} handleClose={onClose} content={<Content handleClose={onClose} />} />
);

CreateProductionModal.defaultProps = {
  onClose: () => undefined,
  payload: {},
};

CreateProductionModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
};

Content.propTypes = {
  handleClose: PropTypes.func.isRequired,
};

export default CreateProductionModal;
