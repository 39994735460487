/* eslint-disable import/no-cycle */
import { Loading, Topbar } from 'components/styleguide';
import React, { Suspense } from 'react';
import Grid from '@mui/system/Unstable_Grid/Grid';
import { Paper, styled } from '@mui/material';

import Logo from 'assets/qwire-logo.svg';

const StyledPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: 'white',
  borderRadius: '20px',
  boxShadow: `0px 0px 0px 1px ${theme.palette.grey[300]}`,
}));

function LoginContainer({ children }: { children: React.ReactNode }) {
  return (
    <>
      <Topbar brand={<img src={Logo} alt="qwire" />} noMargin />
      <Grid
        container
        sx={{ backgroundColor: 'grey.50', height: '100%', justifyContent: 'space-between', alignItems: 'center' }}
      >
        <Grid xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
          <StyledPaper elevation={0}>
            <Suspense fallback={<Loading />}>{children}</Suspense>
          </StyledPaper>
        </Grid>
      </Grid>
    </>
  );
}

export default LoginContainer;
