import React from 'react';
import { useWatch } from 'react-hook-form';
import { rootTranslation } from 'utils/format';
import { TextField } from '@mui/material';
import { Grid } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import { ButtonIcon } from 'components/styleguide';
import { FormField } from 'components/common/Form/Fields/FormField/FormField';
import classnames from 'classnames';
import useStyles from './styles';

type EpisodeRowProps = {
  index: number;
  isLast: boolean;
  isFirst: boolean;
  remove: () => void;
  addNew: () => void;
};

const t = rootTranslation('drawers.quickSetupProduction');

const EpisodeRow = React.memo(({ index, isLast, isFirst, remove, addNew }: EpisodeRowProps) => {
  const classes = useStyles();

  return (
    <Grid
      container
      alignItems={isLast && !isFirst ? 'flex-start' : 'flex-end'}
      classes={{ root: classes.containerRoot }}
    >
      <Grid container item xs={10} spacing={3}>
        <Grid item xs={5}>
          <FormField
            required
            label={isFirst && t('episodeNumber')}
            name={`episodes.${index}.number`}
            render={({ field, muiprops }) => (
              <TextField {...field} {...muiprops} size="small" data-testid="input-text-episodeNumber" />
            )}
          />
        </Grid>
        <Grid item xs={5}>
          <FormField
            required
            label={isFirst && t('episodeName')}
            name={`episodes.${index}.name`}
            render={({ field, muiprops }) => (
              <TextField {...field} {...muiprops} size="small" data-testid="input-text-episodeName" />
            )}
          />
        </Grid>
      </Grid>
      <Grid
        item
        xs={2}
        spacing={3}
        container
        classes={{
          root: classnames({ [classes.buttonContainerLast]: isLast }),
        }}
      >
        <Grid item xs={1}>
          <DeleteButton index={index} remove={remove} classes={classes} />
        </Grid>
        <Grid item xs={1}>
          {isLast && (
            <ButtonIcon aria-label="add new episode" color="primary" data-testid="addNew" dense onClick={addNew}>
              <AddCircleIcon />
            </ButtonIcon>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
});

type DeleteButtonProps = {
  index: number;
  remove: EpisodeRowProps['remove'];
  classes: ReturnType<typeof useStyles>;
};
const DeleteButton = React.memo(({ index, remove, classes }: DeleteButtonProps) => {
  const value = useWatch({
    name: 'episodes',
  });

  return (
    <ButtonIcon
      dense
      color="primary"
      opacity={0.3}
      onClick={remove}
      classes={{ root: classes.deleteButton }}
      disabled={value?.[index]?.id}
      aria-label="delete episode"
    >
      <CancelIcon />
    </ButtonIcon>
  );
});

export default EpisodeRow;
