/* eslint-disable no-promise-executor-return */
import { createActions } from 'redux-actions';
import identity from 'ramda/src/identity';

const actionsDefinition = {
  OPEN_PROMPT: identity,
  CLOSE_PROMPT: () => undefined,
  SET_MENU_OPEN: identity,
};

export const { openPrompt, closePrompt, setMenuOpen } = createActions(actionsDefinition);

export const CLOSE_PROMPT_ERROR = 'CLOSE_PROMPT_ERROR';

/**
 * @returns {Promise}
 * @param {Object} promptProps
 * @param {String} promptProps.content
 * @param {String} promptProps.title
 * @param {Function} promptProps.onClose
 */
export const openPromptAsync =
  (promptProps = {}) =>
  async (dispatch) =>
    new Promise((resolve, reject) =>
      dispatch(
        openPrompt({
          ...promptProps,
          onConfirm: resolve,
          onClose: () => reject(CLOSE_PROMPT_ERROR),
          onCancel: () => reject(CLOSE_PROMPT_ERROR),
        }),
      ),
    );
