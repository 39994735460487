/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';

function Placeholder(props) {
  const { selectProps, innerProps = {}, children } = props;
  return (
    <Typography className={selectProps.classes.placeholder} {...innerProps} data-testid="typeahead-placeholder" noWrap>
      {children}
    </Typography>
  );
}

Placeholder.propTypes = {
  /**
   * The children to be rendered.
   */
  children: PropTypes.node,
  /**
   * props passed to the wrapping element for the group.
   */
  innerProps: PropTypes.object,
  selectProps: PropTypes.object.isRequired,
};

export default Placeholder;
