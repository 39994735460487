/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { BaseDrawer } from 'components/styleguide';
import { useRootTranslation } from 'utils/hooks';
import { concatStrings } from 'utils/index';
import map from 'ramda/src/map';
import { CommonDrawerPropTypes } from '../constants';
import ActorDirectorDrawer from '../ActorDirectorDrawer';

const DirectorsDrawer = ({
  name,
  onClose,
  payload: { onSubmit, directors = [], readOnly, rootT = 'drawers.productionSetup.directors' },
}) => {
  const t = useRootTranslation(rootT);
  const arrayToOptions = map((v) => ({ id: v, name: v }));

  const ROLES = [t('director'), t('actorDirector')];
  const ROLE_OPTIONS = arrayToOptions(ROLES);

  return (
    <ActorDirectorDrawer
      name={name}
      options={ROLE_OPTIONS}
      onClose={onClose}
      rootT={rootT}
      deletePromptContent={({ firstName, lastName }) => t('delete', { name: concatStrings(' ')(firstName, lastName) })}
      payload={{
        onSubmit: (values) =>
          onSubmit({
            directors: values,
          }),
        people: directors,
      }}
      readOnly={readOnly}
    />
  );
};

DirectorsDrawer.propTypes = {
  name: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  payload: PropTypes.object.isRequired,
  rootT: PropTypes.string.isRequired,
};

const Drawer = ({ open, onClose, ...props }) => (
  <BaseDrawer open={open} onClose={onClose}>
    <DirectorsDrawer onClose={onClose} {...props} />
  </BaseDrawer>
);

Drawer.propTypes = CommonDrawerPropTypes;

export default Drawer;
