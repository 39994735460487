/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-children-prop */
/* eslint-disable no-unused-expressions */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FieldArray } from 'formik';
import { is, remove } from 'ramda';
import { DrawerHeader } from 'components/styleguide';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { FormButton } from 'components/common/Form/Fields';
import MultiInputEditField from 'components/common/Form/Fields/FormMultiInputEditField';
import EditDeleteMenuWrapperGrid from 'components/common/EditDeleteMenuWrapperGrid';
import Form from 'components/common/Form';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import useStyles from './styles';

const fullNameValidationSchema = Yup.object().shape({
  people: Yup.array().of(
    Yup.object().shape(
      {
        firstName: Yup.string().when('lastName', {
          is: (val) => !val,
          then: Yup.string().required('global.forms.validations.required'),
          otherwise: Yup.string(),
        }),
        lastName: Yup.string().when('firstName', {
          is: (val) => !val,
          then: Yup.string().required('global.forms.validations.required'),
          otherwise: Yup.string(),
        }),
      },
      [['firstName', 'lastName']],
    ),
  ),
});

const FormNameDrawer = ({
  name,
  onClose,
  payload: { onSubmit, people = [] },
  readOnly,
  rootT,
  deletePromptContent,
}) => {
  const { t } = useTranslation();
  const [editableIndex, setEditableIndex] = useState(null);
  const [isAdding, setIsAdding] = useState(false);
  const [editingFieldCache, setEditingFieldCache] = useState(null);
  const defaultValue = { firstName: '', lastName: '' };
  const classes = useStyles();
  return (
    <Box pb={4}>
      <Form
        drawerName={name}
        className={classes.form}
        onSubmit={async (values) => {
          await onSubmit(values.people);
          setEditableIndex(null);
          setIsAdding(false);
        }}
        enableReinitialize
        initialValues={{
          people,
        }}
        readOnly={readOnly}
        validationSchema={fullNameValidationSchema}
      >
        {(formik) => (
          <FieldArray name="people">
            {(fieldArrayProps) => (
              <>
                <DrawerHeader title={t(`${rootT}.drawerTitle`)} onClose={onClose}>
                  {is(Number, editableIndex) ? (
                    <>
                      <FormButton
                        alwaysEnabled
                        variant="destructive"
                        size="xs"
                        children="global.forms.cancel"
                        testId="name-drawer-cancel"
                        onClick={() => {
                          isAdding
                            ? fieldArrayProps.remove(0)
                            : fieldArrayProps.replace(editableIndex, editingFieldCache);
                          setIsAdding(false);
                          setEditableIndex(null);
                        }}
                      />
                      <FormButton
                        children="global.forms.save"
                        testId="name-drawer-save"
                        size="xs"
                        type="submit"
                        variant="primary"
                      />
                    </>
                  ) : (
                    <FormButton
                      alwaysEnabled
                      children="global.forms.add"
                      testId="name-drawer-add"
                      size="xs"
                      variant="primary"
                      onClick={() => {
                        fieldArrayProps.unshift(defaultValue);
                        setIsAdding(true);
                        setEditableIndex(0);
                      }}
                    />
                  )}
                </DrawerHeader>
                <Grid container>
                  {formik.values.people.map((person, index) => (
                    <React.Fragment key={index}>
                      <EditDeleteMenuWrapperGrid
                        disableHover={readOnly || is(Number, editableIndex)}
                        setEditable={() => {
                          setEditingFieldCache(person);
                          setEditableIndex(index);
                        }}
                        onConfirm={() => {
                          const people = remove(editableIndex, 1, formik.values.people);
                          onSubmit(people);

                          fieldArrayProps.remove(editableIndex);
                          setEditableIndex(null);
                        }}
                        content={is(Function, deletePromptContent) ? deletePromptContent(person) : deletePromptContent}
                      >
                        <MultiInputEditField
                          name={`people.${index}`}
                          inputOneProps={{
                            placeholder: t('drawers.firstNameLastName.placeholderFirstName'),
                          }}
                          fieldOne={{ name: 'firstName', editable: true }}
                          inputTwoProps={{
                            placeholder: t('drawers.firstNameLastName.placeholderLastName'),
                          }}
                          fieldTwo={{ name: 'lastName', editable: true }}
                          editable={editableIndex === index}
                        />
                      </EditDeleteMenuWrapperGrid>
                      <Divider className={classes.divider} />
                    </React.Fragment>
                  ))}
                </Grid>
              </>
            )}
          </FieldArray>
        )}
      </Form>
    </Box>
  );
};

FormNameDrawer.propTypes = {
  name: PropTypes.string,
  deletePromptContent: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  onClose: PropTypes.func.isRequired,
  payload: PropTypes.object.isRequired,
  rootT: PropTypes.string.isRequired,
};

export default FormNameDrawer;
