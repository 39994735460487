/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'locales/i18n';
import { searchContactsCall } from 'redux-core/contacts/services';
import { FormDebouncedTypeAheadWithInput } from 'components/common/Form/Fields';
import { useFormikContext } from 'formik';
import { SEARCH_CONTACTS_FIELDS } from 'utils/constants';
import enhancer from './enhancer';

const CompaniesSearchField = ({ name, divisionId, ...props }) => {
  const { setFieldValue } = useFormikContext();

  const handleCreate = (value) => {
    setFieldValue(name, value);
  };

  return (
    <FormDebouncedTypeAheadWithInput
      {...props}
      name={name}
      required
      creatableText={i18n.t('global.addItem')}
      noResultsText={i18n.t('global.noResults')}
      placeholder="drawers.productionSetup.productionCompanies.namePlaceholder"
      onCreate={handleCreate}
      valueKey="companyName"
      labelKey="companyName"
      call={(term) =>
        searchContactsCall({
          divisionId,
          term,
          fields: [SEARCH_CONTACTS_FIELDS.LICENSOR_COMPANY_NAME],
        })
      }
      testId="productionEntities-name"
    />
  );
};

CompaniesSearchField.propTypes = {
  name: PropTypes.string,
  divisionId: PropTypes.oneOf([PropTypes.string, PropTypes.number]).isRequired,
};

CompaniesSearchField.defaultProps = {
  name: 'name',
};

export default enhancer(CompaniesSearchField);
