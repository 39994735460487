/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Switch } from '@mui/material';
import { withFormField } from 'components/common/Form/utils';

const Input = ({ disabled, field, form, onChange, ...props }) => {
  const { isSubmitting, setFieldValue } = form;
  const handleChange = onChange ? (e, value) => onChange(form, field, value) : (e, v) => setFieldValue(field.name, v);
  return <Switch disabled={disabled || isSubmitting} {...field} onChange={handleChange} {...props} />;
};

Input.displayName = 'InputSwitch';

export default withFormField(Input, { type: 'disabled' });
