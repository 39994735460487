/* eslint-disable react/jsx-props-no-spreading */
import Dialog from '@material-ui/core/Dialog';
import React from 'react';
import Slide from '@material-ui/core/Slide';

import Login from 'components/Login';
import PropTypes from 'prop-types';

import LoginContainer from 'components/Routes/Layout/LoginContainer';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const LockedScreenDialog = ({ open }) => (
  <Dialog fullScreen open={open} TransitionComponent={Transition}>
    <LoginContainer>
      <Login lockedMessage />
    </LoginContainer>
  </Dialog>
);

LockedScreenDialog.propTypes = {
  open: PropTypes.bool,
};

export default LockedScreenDialog;
