import { connect } from 'react-redux';
import { searchForUsers } from 'redux-core/users/actions';
import { getLocationParametersSelector } from 'redux-core/router/selectors';
import { goToLink } from 'redux-core/router/actions';
import { closeAllDialogs } from 'redux-core/dialogs/actions';

const mapStateToProps = (state) => ({
  params: getLocationParametersSelector(state),
});

const mapDispatchToProps = { goToLink, searchForUsers, closeAllDialogs };

export default connect(mapStateToProps, mapDispatchToProps);
