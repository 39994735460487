/* eslint-disable react/no-children-prop */
/* eslint-disable react/jsx-props-no-spreading */
import { Box, Grid } from '@material-ui/core';

import { Text } from 'components/styleguide';
import { useRootTranslation } from 'utils/hooks';
import { GLOBAL_SEARCH_FILTER_BY } from './utils';

import GlobalSearchModal from './GlobalSearchModal';
import SearchResults from './SearchResults';
import SearchDetails from './SearchDetails';
import useStyles from './styles';
import enhancer from './enhancer';

const rootT = 'globalSearch';

const GlobalSearchFilter = ({ searchResults, filterBy }) => {
  const t = useRootTranslation(rootT);
  const classes = useStyles();

  return (
    <Grid container spacing={1}>
      <Grid item xs={8}>
        <Box className={classes.counterLabelHeader}>
          {filterBy === GLOBAL_SEARCH_FILTER_BY.PRODUCTIONS.value && (
            <Text children={t('projectsSearchResults', { count: searchResults || 0 })} bold />
          )}
          {filterBy !== GLOBAL_SEARCH_FILTER_BY.PRODUCTIONS.value && (
            <Text children={t('searchResults', { count: searchResults || 0 })} bold />
          )}
        </Box>
      </Grid>
      <Grid item xs={8}>
        <Grid item xs={12}>
          {filterBy === 'productions' && (
            <Box my={1} className={classes.productionsHeader} pr={1}>
              <Grid container>
                <Grid container item xs={5}>
                  <Text bold>Project</Text>
                </Grid>
                <Grid container item xs={5}>
                  <Text bold>Production</Text>
                </Grid>
                <Grid container item xs={2} />
              </Grid>
            </Box>
          )}
        </Grid>
        <Grid item xs={12} className={classes.panelContainer}>
          <SearchResults />
        </Grid>
      </Grid>
      <Grid id="search-details" item xs={4} className={classes.panelContainer} role="tabpanel">
        <SearchDetails />
      </Grid>
    </Grid>
  );
};

const Dialog = ({ open, onClose, ...props }) => (
  <GlobalSearchModal open={open || false} onClose={onClose} {...props}>
    <GlobalSearchFilter {...props} />
  </GlobalSearchModal>
);

export default enhancer(Dialog);
