import { compose } from 'redux';
import { connect } from 'react-redux';
import { getProductionUseInfo } from 'redux-core/overview/actions';
import { openPromptAsync } from 'redux-core/prompt/actions';
import withBaseDrawer from 'components/common/Drawers/withBaseDrawer';

const mapDispatchToProps = {
  getProductionUseInfo,
  openPromptAsync,
};

export default compose(connect(null, mapDispatchToProps), (c) => withBaseDrawer(c, { large: true }));
