/* eslint-disable react/no-children-prop */
import React from 'react';
import { useRootTranslation } from 'utils/hooks';
import { withFormField } from 'components/common/Form/utils';
import { DrawerHeader } from 'components/styleguide';
import { FormButton } from 'components/common/Form/Fields';
import PropTypes from 'prop-types';

const rootT = 'drawers.licensingFilters';

// eslint-disable-next-line no-unused-vars
const FilterFormHeader = ({ onClose }) => {
  const t = useRootTranslation(rootT);

  const handleClose = () => {
    onClose();
  };

  return (
    <DrawerHeader title={t('title')} onClose={handleClose} hideBackButton>
      <FormButton
        testId="licensing-filter-cancel"
        variant="destructive"
        color="secondary"
        size="xs"
        children="global.forms.cancel"
        type="button"
        alwaysEnabled
        onClick={() => handleClose()}
      />
      <FormButton
        testId="licensing-filter-apply"
        variant="primary"
        size="xs"
        children="global.forms.apply"
        type="submit"
      />
    </DrawerHeader>
  );
};
FilterFormHeader.propTypes = {
  form: PropTypes.shape({}),
  onClose: PropTypes.func.isRequired,
};
export default withFormField(FilterFormHeader);
