import { Autocomplete, Skeleton, TextField, TextFieldProps } from '@mui/material';
import { useMemo } from 'react';
import { TCurrencyNormalized, useGetCurrenciesQuery } from 'services/common/currency';

type CurrencySelectProps = TextFieldProps & {
  value: number;
  onChange: (value: number) => void;
};

const findDefaultCurrency = (currency: TCurrencyNormalized) => currency.code === 'USD';

export function CurrencySelect({ value, onChange, disabled, ...rest }: CurrencySelectProps) {
  const { data, isLoading } = useGetCurrenciesQuery();
  const defaultCurrency = useMemo(() => data?.find(findDefaultCurrency), [data]);
  const selected = value ? data?.find((currency) => currency.currencyId === value) : defaultCurrency;

  if (isLoading && !data) {
    return <Skeleton variant="rectangular" width="100%" height="40" />;
  }

  return (
    <Autocomplete<TCurrencyNormalized, false, true>
      size="small"
      disableClearable
      value={selected}
      loading={isLoading}
      isOptionEqualToValue={(option, optionValue) => option.currencyId === optionValue.currencyId}
      getOptionLabel={(option: TCurrencyNormalized) => option.label}
      options={data ?? []}
      disabled={disabled}
      onChange={(_, newValue: TCurrencyNormalized) => {
        onChange(newValue.currencyId);
      }}
      renderInput={(params) => <TextField {...params} {...rest} />}
    />
  );
}
