import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

type GridProps = React.ComponentProps<typeof Grid>;
type BoxProps = React.ComponentProps<typeof Box>;

type LayoutProps = GridProps & BoxProps;

export default function Layout({
  children,
  // Grid Props
  pr = 4,
  pl = 4,
  alignContent = 'stretch',
  alignItems = 'stretch',
  container = false,
  direction = 'row',
  item = false,
  justify = 'flex-start',
  spacing = 0,
  ...boxProps
}: LayoutProps) {
  return (
    <Box width="auto" {...boxProps} pr={pr} pl={pl} data-testid="qClear-Layout">
      <Grid
        item={item}
        container={container}
        alignContent={container ? alignContent : undefined}
        alignItems={container ? alignItems : undefined}
        direction={container ? direction : undefined}
        justify={container ? justify : undefined}
        spacing={container ? spacing : undefined}
      >
        {children}
      </Grid>
    </Box>
  );
}
