import { makeStyles } from '@material-ui/core/styles';
import { appColors } from 'components/styleguide';

const useStyles = makeStyles((theme) => ({
  root: {
    border: '1px solid',
    borderColor: 'transparent',
    borderRadius: 3,
    cursor: 'pointer',
    height: 52,
    padding: theme.spacing(1.625, 1, 1.625, 1.25),
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
      '& $icon': {
        display: 'block',
      },
    },
    '&.selected': {
      backgroundColor: theme.palette.action.selected,
      borderColor: appColors.darkGreen,
    },
    '&.disabled': {
      backgroundColor: '#CDCDCD',
      cursor: 'not-allowed',
    },
  },
  icon: {
    display: 'none',
    marginLeft: theme.spacing(0.75),
    '&.visible': {
      display: 'block',
    },
  },
}));

export default useStyles;
