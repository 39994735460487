/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-array-index-key */
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { appColors } from 'components/styleguide';
import { FormDebouncedTypeAheadWithInput } from '..';

const getHighlightedText = (highlight) => (text) => {
  // Split text on higlight term, include term itself into parts, ignore case
  const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
  return parts.map((part, index) => (
    <React.Fragment key={index}>
      {part.toLowerCase() === highlight.toLowerCase() ? (
        <b style={{ backgroundColor: appColors.yellow }}>{part}</b>
      ) : (
        part
      )}
    </React.Fragment>
  ));
};

const FormHighlightedTypeAhead = ({ renderOption, ...props }) => {
  const handleFormat = useCallback(
    (option, { inputValue, context }) => {
      if (context !== 'value') {
        return renderOption(option, getHighlightedText(inputValue));
      }
      return option.name;
    },
    [renderOption],
  );

  return <FormDebouncedTypeAheadWithInput {...props} formatOptionLabel={handleFormat} />;
};

FormHighlightedTypeAhead.propTypes = {
  renderOption: PropTypes.func.isRequired,
};

FormHighlightedTypeAhead.defaultProps = {
  renderOption: (option, highlight) => highlight(option.name),
};

export default FormHighlightedTypeAhead;
