/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Field } from 'formik';

export const isReadOnly = (form) => form.status === 'readOnly';

/**
 *
 * @param {Component} WrappedField
 * @param {Object} settings
 * @param {String} settings.type One of readOnly or disabled
 * @description Receives a Component and returns it as Formik Field with its respective props.
 * If the form is in readOnly status, then the inner component will receive that prop as true.
 */
export const withFormField = (WrappedField, { type = 'readOnly' } = {}) => {
  const Component = ({ [type]: field, ...props }) => (
    <WrappedField {...props} {...{ [type]: field || isReadOnly(props.form) }} />
  );

  const FormikInput = (props) => <Field component={Component} {...props} />;
  FormikInput.displayName = `Form${WrappedField.displayName}`;

  return FormikInput;
};
