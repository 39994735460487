import PropTypes from 'prop-types';

export const WATCHING_TYPE = {
  CLEARANCE: 'clearance',
  LICENSING: 'licensing',
  CUE_SHEET: 'cueSheet',
};

export const defaultFilter = {
  sortBy: 'projectName',
};

export const TrackPropTypes = PropTypes.shape({
  songs: PropTypes.number,
  effects: PropTypes.number,
  library: PropTypes.number,
  scores: PropTypes.number,
});

const TrackListPropTypes = PropTypes.shape({
  new: TrackPropTypes,
  inProgress: TrackPropTypes,
  cleared: TrackPropTypes,
  confirmed: TrackPropTypes,
  licensed: TrackPropTypes,
  other: TrackPropTypes,
});

export const AssetPropTypes = PropTypes.shape({
  title: PropTypes.string,
  type: PropTypes.string,
  version: PropTypes.string,
  versionName: PropTypes.string,
  clearanceStatus: PropTypes.number,
  creativeVendor: PropTypes.string,
  approvedFees: PropTypes.number,
  tracks: TrackListPropTypes,
});

export const ProdInfoPropTypes = PropTypes.shape({
  type: PropTypes.string,
  productionId: PropTypes.number,
  clearanceStatus: PropTypes.number,
  quotesExpiring: PropTypes.number,
  airReleaseDate: PropTypes.string,
  path: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      type: PropTypes.string,
      objectId: PropTypes.number,
    }),
  ),
  budget: PropTypes.shape({
    productionBudget: PropTypes.number,
    commitedFees: PropTypes.number,
    uncommitedFees: PropTypes.number,
  }),
  tracks: TrackListPropTypes,
  assets: PropTypes.arrayOf(AssetPropTypes),
});
