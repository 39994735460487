import omit from 'ramda/src/omit';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { Row } from 'components/styleguide';
import { upsertRights } from 'redux-core/rights/services';
import { useRootTranslation } from 'utils/useRootTranslation';
import i18n from 'locales/i18n';
import { lowerThan, greaterThan } from 'utils/forms';
import { refetchCueContainers, refetchCueContainersById } from 'redux-core/cueContainers/actions';
import { CueContainerPicker } from 'components/common/CueContainerPicker';
import { useCreateClearanceMutation } from 'services/clear/clearances';
import { ClearanceBaseForm } from './ClearanceBaseForm';
import { validateMaxAllocation, validateMinAllocation } from './CueRows/utils';

/**
 * Leaving types here for future usage
  type FormAddProps = FormCommonProps & {
    trackId: number;
  };
 */

export function ClearanceFormAdd({
  children,
  onClose,
  clearancesFetch,
  track,
  showGenericError,
  divisionId,
  projectId,
  initialValues,
  objectId,
  name,
  cueContainer,
  clearanceBundleId,
}) {
  const t = useRootTranslation('drawers.clearance.addEdit');
  const title = t('drawerTitle');
  const dispatch = useDispatch();

  const [createClearance] = useCreateClearanceMutation();

  const handleSubmit = async ({ requiredRights, ...values }) => {
    const body = {
      ...omit(['rights', 'territories'], values),
      divisionId,
      projectId,
      trackId: track.id,
    };

    try {
      const { data: newClearance } = await createClearance({ ...body, bundleId: clearanceBundleId });

      await upsertRights({
        divisionId,
        clearanceId: newClearance.id,
        rights: requiredRights,
      });

      await clearancesFetch();
      if (cueContainer?.id && body?.cueContainerId) {
        await dispatch(refetchCueContainersById([cueContainer.id, body.cueContainerId]));
      } else {
        await dispatch(refetchCueContainers());
      }
      await onClose();
    } catch (e) {
      showGenericError(e);
    }
  };

  return (
    <ClearanceBaseForm
      handleSubmit={handleSubmit}
      initialValues={initialValues}
      objectId={objectId}
      onClose={onClose}
      submitButtonTag="global.forms.add"
      title={title}
      name={name}
      validationSchema={validationAddSchema()}
    >
      <Row>
        <CueContainerPicker trackId={track.id} getAll />
      </Row>
      {children}
    </ClearanceBaseForm>
  );
}

export const clearanceBaseSchema = Yup.object().shape({
  name: Yup.string().required('global.forms.validations.required'),
  rights: Yup.array()
    .of(
      Yup.object().shape({
        cues: Yup.array().of(
          Yup.object().shape({
            allocation: Yup.number()
              .min(0, lowerThan(0))
              .max(100, greaterThan(100))
              .required('global.forms.validations.required'),
          }),
        ),
      }),
    )
    .test('rights', i18n.t('drawers.clearance.validations.allocationsMin'), (rights) =>
      validateMinAllocation(rights)(0),
    )
    .test('rights', i18n.t('drawers.clearance.validations.allocationsMax'), (rights) =>
      validateMaxAllocation(rights)(100),
    ),
});

const validationAddSchema = () =>
  clearanceBaseSchema.shape({
    rightsPresetId: Yup.number().required('global.forms.validations.required'),
    cueContainerId: Yup.number().required('global.forms.validations.required'),
  });
