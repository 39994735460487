import { makeStyles } from '@material-ui/core';
import { InputFieldHeight } from 'components/styleguide/newTheme';

const useStyles = makeStyles((theme) => ({
  datepicker: {
    '& > label': {
      left: -13,
      position: 'relative',
      top: 5,
    },
    '& > div': {
      padding: 0,
      height: InputFieldHeight.medium,
      minWidth: '9rem !important',
      '& > div': {
        marginRight: 0,
      },
      '& button': {
        padding: theme.spacing(0.875),
      },
      '& svg': {
        fontSize: '1em',
      },
      '& input': {
        fontSize: '1rem',
      },
    },
  },
  readOnlyInput: {
    minWidth: '5.375rem !important',
  },
}));

export default useStyles;
