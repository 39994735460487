/* eslint-disable react/no-children-prop */
import React from 'react';
import PropTypes from 'prop-types';
import { DrawerHeader } from 'components/styleguide';
import { useFormikContext } from 'formik';

const FormDrawerHeader = ({ title, onClose, children }) => {
  const formik = useFormikContext();

  const handleClose = () => {
    onClose(formik);
  };

  return <DrawerHeader title={title} onClose={handleClose} children={children} />;
};

FormDrawerHeader.propTypes = {
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.any,
};

export default FormDrawerHeader;
