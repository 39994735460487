import { makeStyles } from '@material-ui/core';
import { hexWithOpacity } from 'utils';

const styles = makeStyles((theme) => ({
  iconButton: {
    color: hexWithOpacity(theme.palette.primary.main, 0.4),
    padding: 0,
  },
  container: {
    '&:hover': {
      '& $deleteButtonWrapper': {
        paddingTop: theme.spacing(0.75),
        display: 'block',
      },
    },
  },
  duration: {
    '& > *': {
      maxWidth: 124,
      '& > label': { overflow: 'initial' },
    },
  },
  usage: {
    '& > *': {
      '& > label': { overflow: 'initial' },
    },
  },
  deleteButtonWrapper: {
    display: 'none',
  },
}));

export default styles;
