/* eslint-disable react/jsx-props-no-spreading */
import { useCallback, useMemo } from 'react';
import { BaseDrawer } from 'components/styleguide';
import { getRequiredRights } from 'redux-core/rights/services';
import prop from 'ramda/src/prop';
import { useFetch } from 'utils/hooks';
import { useRootTranslation } from 'utils/useRootTranslation';
import { SecuredRightsPresetPicker } from 'components/common/Secured';
import { COMMON_ROLES, CLEAR_PERMISSIONS_GROUPS } from 'redux-core/permissions/constants';
import { RIGHTS_HINT_MESSAGE_TYPE } from 'components/common/Drawers/RightPresetDrawer/constants';
import enhancer from './enhancer';
import { ClearanceFormEdit } from './ClearanceFormEdit';
import { ClearanceFormAdd } from './ClearanceFormAdd';

/*
  * Keeping the type definition to be used once we migrate this file to TS.
  type DrawerProps = BaseFormProps & {
    open: boolean;
    clearForms: () => void;
  };
*/

const Drawer = ({
  open,
  onClose,
  clearForms,
  formId,
  rights,
  name,
  productionType,
  savedValues,
  showGenericError,
  projectId,
  payload,
  refetchCueContainersById,
}) => {
  const { clearance, divisionId, track, clearancesFetch, objectId, cueContainer, clearanceBundleId } = payload;
  const isEditing = Boolean(clearance?.id);
  const t = useRootTranslation('drawers.clearance.addEdit');

  const [requiredRightsRaw] = useFetch(
    () => clearance?.id && !rights && getRequiredRights({ clearanceId: clearance.id }),
    [clearance?.id, rights],
    {},
  );

  const requiredRights = rights || requiredRightsRaw;

  const initialValues = {
    objectId,
    ...clearance,
    ...savedValues,
    requiredRights,
    dirty: prop('dirty', requiredRights),
  };

  const handleClose = useCallback(async () => {
    await clearForms();
    onClose();
  }, [clearForms, onClose]);

  const rightsPresetPicker = useMemo(
    () => (
      <SecuredRightsPresetPicker
        divisionId={divisionId}
        required={!isEditing}
        rightsLabel={t('requiredRights')}
        formId={formId}
        showDefaultPreset={false}
        testId="clearance-drawer-required-rights"
        permissions={COMMON_ROLES.EDITOR}
        clearPermissions={CLEAR_PERMISSIONS_GROUPS.RIGHTS}
        objectId={objectId}
        saveInDrawerName={name}
        hintType={RIGHTS_HINT_MESSAGE_TYPE.CLEARANCE}
        initialRights={requiredRights}
      />
    ),
    [divisionId, formId, isEditing, name, objectId, requiredRights, t],
  );

  const sharedProps = {
    track,
    clearance,
    cueContainer,
    divisionId,
    clearancesFetch,
    objectId,
    onClose,
    showGenericError,
    projectId,
    initialValues,
    clearanceBundleId,
  };

  return (
    <BaseDrawer open={open} onClose={handleClose} large={isEditing}>
      {isEditing ? (
        <ClearanceFormEdit
          productionType={productionType}
          {...sharedProps}
          refetchCueContainersById={refetchCueContainersById}
        >
          {rightsPresetPicker}
        </ClearanceFormEdit>
      ) : (
        <ClearanceFormAdd {...sharedProps}>{rightsPresetPicker}</ClearanceFormAdd>
      )}
    </BaseDrawer>
  );
};

export default enhancer(Drawer);
