/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-use-before-define */
/* eslint-disable react/default-props-match-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { Row, LabelControl, Loading } from 'components/styleguide';
import {
  FormInputText,
  FormDebouncedTypeAhead,
  FormDatePicker,
} from 'components/common/Form/Fields/index';
import Form from 'components/common/Form';
import Breadcrumbs from 'components/common/Breadcrumbs/index';
import { useRootTranslation, useFetch } from 'utils/hooks';
import * as Yup from 'yup';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import { NORMALIZED_PRODUCTION_TYPES } from 'utils/constants';
import ROUTES from 'components/Routes/routes';
import { ViewModesPropType } from 'components/ProductionOverview/components/types';
import { getTaskByIdCall } from 'redux-core/tasks/services';
import { get } from 'utils/object';
import is from 'ramda/src/is';
import { FormTaskSubject } from './components';
import enhancer from './enhancer';

const TaskDrawer = ({
  name,
  payload,
  readOnly,
  onSubmit,
  headerContent,
  goToLink,
  searchForUsers,
  closeAllDialogs,
  children,
}) => {
  const [values, loading] = useFetch(
    () => taskId && getTaskByIdCall({ id: taskId, startProgress: false }),
    [],
    {},
  );

  const {
    title = '',
    breadcrumbs = [],
    params = {},
    getTabTasks,
    taskId,
  } = payload;

  const t = useRootTranslation('drawers.tasks.create');

  const handleSubmit = async ({ recipientUser, ...values }) => {
    const formattedValues = {
      ...values,
      tenantId: params.tenantId,
      productionObjectId: params.productionObjectId,
      trackObjectId: payload.trackObjectId,
      /**
       * @TODO Remove this once below ticket it's done
       * @see https://qwireholdings.atlassian.net/browse/CLR-1440
       */
      recipientUserId: is(Number, recipientUser)
        ? recipientUser
        : get('id')(recipientUser),
    };
    await onSubmit(formattedValues);
  };

  const breadcrumbPathWithTrack = [
    ...breadcrumbs,
    ...(title ? [{ name: title }] : []),
  ];

  const goToProduction = async () => {
    await closeAllDialogs();
    await goToLink(
      ROUTES.PRODUCTION.OVERVIEW,
      {
        ...params,
        dirty: false,
      },
      { mergeParams: true },
    );
  };

  return (
    <Form
      drawerName={name}
      enableReinitialize
      initialValues={values}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {headerContent()}
      {loading ? (
        <Loading />
      ) : (
        <>
          <Row>
            <Breadcrumbs
              path={breadcrumbPathWithTrack}
              type={params.type}
              onClick={goToProduction}
              joinBy=" - "
            />
          </Row>
          <Row>
            <FormTaskSubject
              required
              readOnly={readOnly}
              getTabTasks={getTabTasks}
            />
          </Row>
          <Row spacing={2}>
            <Grid item xs={8}>
              {readOnly ? (
                <LabelControl label={t('form.recipientUserId')}>
                  {get('recipientUser.displayName')(values)}
                </LabelControl>
              ) : (
                <FormDebouncedTypeAhead
                  label={t('form.recipientUserId')}
                  name="recipientUser"
                  required
                  labelKey="displayName"
                  call={(term) =>
                    searchForUsers({ term, divisionId: params.divisionId })
                  }
                  adornment={
                    <IconButton color="primary">
                      <SearchIcon />
                    </IconButton>
                  }
                />
              )}
            </Grid>
            <Grid item xs={4}>
              <FormDatePicker
                label={t('form.dueDate')}
                name="dueDate"
                readOnly={readOnly}
              />
            </Grid>
          </Row>
          {(!readOnly || values.description) && (
            <Row mb={3}>
              <FormInputText
                textArea
                label={t('form.description')}
                name="description"
                readOnly={readOnly}
              />
            </Row>
          )}
          {readOnly && (
            <Row spacing={2}>
              <Grid item xs={8}>
                <FormInputText
                  readOnly
                  name="creatorUser.displayName"
                  label={t('form.requestor')}
                />
              </Grid>
              <Grid item xs={4}>
                <FormDatePicker
                  readOnly
                  name="createdAt"
                  label={t('form.createdDate')}
                  format="global.format.timestamp"
                />
              </Grid>
            </Row>
          )}
        </>
      )}
      {children}
    </Form>
  );
};

const validationSchema = Yup.object().shape({
  subject: Yup.string().required('global.forms.validations.required'),
  recipientUser: Yup.string().required('global.forms.validations.required'),
});

export const TaskDrawerPayloadPropTypes = PropTypes.shape({
  name: PropTypes.string,
  title: PropTypes.string.isRequired,
  trackObjectId: PropTypes.number,
  breadcrumbs: PropTypes.array.isRequired,
  taskId: PropTypes.number,
  getTabTasks: PropTypes.func,
  /**
   * Params that will be used internally by the drawer but
   * they will land in the store => router.state after a goToLink() call
   */
  params: PropTypes.shape({
    type: PropTypes.oneOf(Object.values(NORMALIZED_PRODUCTION_TYPES)).isRequired,
    viewMode: ViewModesPropType,
    projectId: PropTypes.number,
    divisionId: PropTypes.number,
    objectId: PropTypes.number,
    productionObjectId: PropTypes.number,
    tenantId: PropTypes.number,
  }).isRequired,
});

TaskDrawer.propTypes = {
  name: PropTypes.string.isRequired,
  headerContent: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  payload: TaskDrawerPayloadPropTypes.isRequired,
  goToLink: PropTypes.func.isRequired,
  searchForUsers: PropTypes.func.isRequired,
  closeAllDialogs: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  children: PropTypes.any,
};

TaskDrawer.displayName = 'TaskDrawer';

TaskDrawer.defaultProps = {
  readOnly: false,
  headerContent: () => undefined,
  onSubmit: () => undefined,
};

export default enhancer(TaskDrawer);
