import PropTypes from 'prop-types';

export const CustomFormPropTypes = {
  /**
   * Allows react-router prompt to be called during a route change
   * even if the current form is not dirty
   * Does nothing if disablePrompt is set to true.
   */
  alwaysRenderPrompt: PropTypes.bool,
  // If true, will never show the router prompt.
  disablePrompt: PropTypes.bool,
  drawerName: PropTypes.string,
  initialValues: PropTypes.object,
  isDrawerFormDirty: PropTypes.bool,
  isDrawerFormSubmitted: PropTypes.bool,
  onConfirm: PropTypes.func,
  onSubmit: PropTypes.func,
  promptMessage: PropTypes.string,
  readOnly: PropTypes.bool,
  setDrawerDirty: PropTypes.func,
  setIsDirty: PropTypes.func,
  validationSchema: PropTypes.object,
  testId: PropTypes.string,
};
