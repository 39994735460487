/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable @typescript-eslint/no-shadow */
import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { MuiPickersUtilsProvider, KeyboardDatePicker as Picker } from '@material-ui/pickers';
import { format as defaultFormatDate } from 'date-fns';
import Calendar from '@material-ui/icons/DateRange';
import MomentUtils from '@date-io/moment';
import { FormInputPropTypes } from 'components/styleguide/fields/types';
import PropTypes from 'prop-types';
import withFormControl from 'components/styleguide/fields/withFormControl';
import InputText from '../InputText';
import useStyles from './styles';
import StaticPicker from '../StaticPicker';

const DatePicker = ({
  clearable,
  disabled,
  error,
  format,
  fullWidth,
  onBlur,
  onChange,
  name,
  placeholder,
  formatDate,
  tabIndex,
  value,
  variant,
  readOnly,
  label,
  popover,
  minDate,
  testId,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const handleOpen = (event) => {
    if (!popover || disabled) return;
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const closePopover = (value) => {
    setAnchorEl(null);
    if (value) onChange(value);
  };
  if (readOnly) {
    return (
      <Grid container alignItems="center" wrap="nowrap" className={classes.readOnly}>
        <Box mr={0.625} pt={1}>
          <Calendar color="primary" />
        </Box>
        <InputText
          name={name}
          readOnly
          fullWidth={false}
          value={value ? formatDate(value, format) : ''}
          classesOverrides={{
            input: classes.readOnlyInput,
          }}
        />
      </Grid>
    );
  }

  const handleKeyDown = (e) => {
    if (value) return;
    // If !value and user pressed Backspace or Surp, do nothing
    if (e.keyCode === 8 || e.keyCode === 46) e.preventDefault();
  };

  const handleBlur = () => {
    const event = { ...new Event('blur'), target: { value, name } };
    onBlur(event);
  };

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      {popover && (
        <StaticPicker anchorEl={anchorEl} minDate={minDate} onUpdate={closePopover} label={label} value={value} />
      )}
      <Picker
        autoOk
        onKeyDown={handleKeyDown}
        className={classes.datepicker}
        clearable={clearable}
        data-testid={testId}
        disabled={disabled}
        disableToolbar
        emptyLabel={placeholder || format.toUpperCase()}
        format={format}
        invalidDateMessage=""
        minDate={minDate}
        minDateMessage=""
        maxDateMessage=""
        InputProps={{
          fullWidth,
          notched: false,
          variant: 'outlined',
          onBlur,
          inputProps: {
            'aria-describedby': name,
            id: name,
            name,
            tabIndex,
          },
        }}
        InputAdornmentProps={{ position: 'start' }}
        InputLabelProps={{
          /** @see https://material-ui.com/components/text-fields/#shrink */
          shrink: true,
        }}
        onClose={handleBlur}
        inputVariant="outlined"
        keyboardIcon={<Calendar color="primary" />}
        onMouseDown={handleOpen}
        onClick={handleOpen}
        onChange={onChange}
        error={!!error}
        value={value || null}
        variant={variant}
      />
    </MuiPickersUtilsProvider>
  );
};

DatePicker.propTypes = {
  ...FormInputPropTypes,
  clearable: PropTypes.bool,
  format: PropTypes.string.isRequired,
  formatDate: PropTypes.func,
  tabIndex: PropTypes.number,
  // If true, will open the popover static picker onClick
  popover: PropTypes.bool,
  variant: PropTypes.oneOf(['dialog', 'inline', 'static']),
  testId: PropTypes.string,
};

DatePicker.defaultProps = {
  format: 'MM/dd/yyyy',
  formatDate: defaultFormatDate,
  name: '',
  variant: 'inline',
  testId: 'datepicker-field',
};

export default withFormControl(DatePicker);
