import React from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import useStyles from './styles';

const Expand = (props) => {
  const classes = useStyles(props);
  return <ExpandMoreIcon classes={classes} />;
};

export default Expand;
