import React from 'react';
import { length } from 'ramda';
import PropTypes from 'prop-types';
import { ALL_OPTION, NORMALIZED_PRODUCTION_TYPES } from 'utils/constants';
import { TypeAhead } from 'components/styleguide';
import { useRootTranslation } from 'utils/hooks';

const rootT = 'drawers.searchProductions';

const getValueForHandleOnChange = (prevProductionIds, productionIds) => {
  const prevProductionIdsIsOnlyAll = length(prevProductionIds) === 1 && prevProductionIds[0] === ALL_OPTION.id;
  const productionIdsHasMoreThanOneValue = length(productionIds) > 1;
  const lastProductionIdIsAll = length(productionIds) && productionIds[length(productionIds) - 1] === ALL_OPTION.id;

  if (productionIdsHasMoreThanOneValue) {
    if (prevProductionIdsIsOnlyAll) {
      return productionIds.filter((id) => id !== ALL_OPTION.id);
    }
    if (lastProductionIdIsAll) {
      return [ALL_OPTION.id];
    }
    return productionIds;
  }
  return productionIds;
};

const ProductionFilter = ({ disabled, onChange, productionIds, productions, type, testId = 'main-production' }) => {
  const t = useRootTranslation(rootT);

  const handleChange = (nextProductionIds) => {
    const value = getValueForHandleOnChange(productionIds, nextProductionIds);

    onChange({ productionIds: value });
  };

  return (
    <TypeAhead
      testId={testId}
      disabled={disabled}
      label={t(type)}
      noResultsText={t('noResults')}
      onChange={handleChange}
      options={length(productions) ? [ALL_OPTION, ...productions] : []}
      value={productionIds}
      isMultiple
    />
  );
};

ProductionFilter.propTypes = {
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  productionIds: PropTypes.array,
  productions: PropTypes.array,
  type: PropTypes.oneOf(Object.keys(NORMALIZED_PRODUCTION_TYPES)),
};

ProductionFilter.defaultProps = {
  productions: [],
};

export default ProductionFilter;
