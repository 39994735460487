/* eslint-disable consistent-return */
import { v4 as uuidv4 } from 'uuid';

import InfiniteScroll from 'components/common/InfiniteScroll';
import { getProductionsCall, getSearchListCall } from 'redux-core/header/services';

import { GLOBAL_SEARCH_FILTER_BY, GLOBAL_SEARCH_LIMIT } from '../utils';
import enhancer from './enhancer';
import useStyles from './styles';
import SearchItem from './SearchItem';
import { NoResultsForProduction } from './NoResultsForProduction';

const WRAPPER_ID = 'search-container';

const SearchResults = ({
    advancedFilters,
    setSearchResults,
    searchResults,
    updateSearchResponse,
  }) => {
  const classes = useStyles();
  return (
    <div className={classes.root} id={WRAPPER_ID} data-testid={WRAPPER_ID} role="listbox">
      <InfiniteScroll
        key={advancedFilters?.version}
        // eslint-disable-next-line react/no-children-prop
        children={({ items }) => {
          if (searchResults === 0) {
            return <NoResultsForProduction />;
          }
          return items.map((item) => <SearchItem item={item} key={item.uuid} />);
        }}
        id="advanced-search"
        filters={advancedFilters}
        limit={GLOBAL_SEARCH_LIMIT}
        onScroll={async (payload) => {
          if (!payload.filters?.filterBy) return;

          let searchResponse;
          const searchPayload = {
            ...payload,
            page: payload.page + 1,
          };
          if (payload.filters.filterBy === GLOBAL_SEARCH_FILTER_BY.PRODUCTIONS.value) {
            searchResponse = await getProductionsCall(searchPayload);
          } else {
            searchResponse = await getSearchListCall(searchPayload);
          }

          searchResponse.data = searchResponse.data.map((item) => ({
            ...item,
            uuid: uuidv4(),
          }));

          setSearchResults(searchResponse.pagination.totalCount);
          updateSearchResponse(searchResponse.data);

          return searchResponse;
        }}
      />
    </div>
  );
};

export default enhancer(SearchResults);
