/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Box, CircularProgress } from '@material-ui/core';
import { getDisplayName } from 'utils';

export const ADORNMENT_POSITION = {
  START: 'start',
  END: 'end',
};

const Loading = (
  <Box px={1} data-testid="loading-adornment">
    <CircularProgress size={16} />
  </Box>
);

function withAdornments(WrappedComponent) {
  const Component = ({ startAdornment, endAdornment, ...props }) => {
    const { adornment, adornmentPosition } = props;
    /**
     * @TODO: Unify API
     * These checks are necessary for using the HOC by passing:
     * - adornmentPosition & adornment
     * - or startAdornment
     * - or endAdornment
     */
    const isEnd = adornmentPosition === ADORNMENT_POSITION.END || !!endAdornment;
    const isStart = adornmentPosition === ADORNMENT_POSITION.START || !!startAdornment || (adornment && !isEnd);

    const Adornment = adornment || (isEnd ? endAdornment : startAdornment);
    return (
      <WrappedComponent
        {...props}
        startAdornment={isStart && Adornment && (props.loading ? Loading : Adornment)}
        endAdornment={isEnd && Adornment && (props.loading ? Loading : Adornment)}
      />
    );
  };

  Component.displayName = `withAdornments(${getDisplayName(WrappedComponent)})`;

  Component.propTypes = {
    adornment: PropTypes.node,
    loading: PropTypes.bool,
    adornmentPosition: PropTypes.oneOf([ADORNMENT_POSITION.START, ADORNMENT_POSITION.END]),
  };

  return Component;
}

export default withAdornments;
