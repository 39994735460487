/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { Text } from 'components/styleguide';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: `-${theme.spacing(6)}px`,
  },
}));

const CollapsePanelOffsetIcon = ({ icon: IconComponent, onClick, disabled, children }) => {
  const classes = useStyles();
  const Children = (
    <Text bold variant="h6">
      {children}
    </Text>
  );

  if (disabled) {
    return Children;
  }

  return (
    <>
      <IconButton onClick={onClick} classes={classes}>
        <IconComponent />
      </IconButton>
      {Children}
    </>
  );
};

CollapsePanelOffsetIcon.propTypes = {
  icon: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  children: PropTypes.any,
};

CollapsePanelOffsetIcon.defaultProps = {
  icon: AddIcon,
};

export default CollapsePanelOffsetIcon;
