/* eslint-disable react/no-children-prop */
import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';

const ClickStopper = ({ children, component = Box, bypass }) => {
  const Component = component;

  const onClick = (e) => {
    if (bypass) return;
    e.stopPropagation();
  };
  return <Component onClick={onClick} children={children} />;
};

ClickStopper.propTypes = {
  component: PropTypes.node,
};

export default ClickStopper;

export const stopPropagationWrapper = (callback) => (event) => {
  event.stopPropagation();
  return callback();
};
