import { makeStyles } from '@material-ui/core';

const styles = makeStyles({
  avatar: {
    margin: 10,
    backgroundColor: '#06D6A0',
    border: '1.5px solid #fff',
    width: '25px',
    height: '25px',
  },
});

export default styles;
