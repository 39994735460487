import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(2.25),
  },
  infoContainer: {
    marginTop: theme.spacing(),
  },
}));

export default useStyles;
