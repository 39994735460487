import { baseApi } from 'services';

export type TCountry = {
  id: number,
  name: string;
  code: string;
  tisNumber: number;
}

export type TGetAllCountriesResponse = TCountry[];

export const countriesApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllCountries: builder.query<TGetAllCountriesResponse, {}>({
      query: (body) => ({ url: 'common/countries/get-all', method: 'POST', body }),
    }),
  }),
});

export const { useGetAllCountriesQuery } = countriesApi;
