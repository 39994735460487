/* eslint-disable no-use-before-define */
import { push, replace } from 'connected-react-router';
import is from 'ramda/src/is';
import mergeAll from 'ramda/src/mergeAll';
import omit from 'ramda/src/omit';
import { getLocationParametersSelector, getLocationSelector } from './selectors';

/**
 * @param {string} link
 * @param {object} params
 * @param {object} config
 * @param {boolean} config.mergeParams
 */
export const goToLink =
  (link, params = {}, config = {}) =>
    async (dispatch, getState) => {
      const state = getState();
      const nextParams = config.mergeParams ? mergeAll([getLocationParametersSelector(state), params]) : params;
      // Functions can't be serialized so they can't be sent in the react-router state
      dispatch(push(link, removeFunctions(nextParams)));
    };

export const addParams =
  (params = {}) =>
    (dispatch, getState) => {
      const state = getState();
      const prevParams = getLocationParametersSelector(state);
      const newParams = mergeAll([prevParams, params]);

      const location = getLocationSelector(state);
      dispatch(replace(location, removeFunctions(newParams)));
    };
export const removeParams =
  (params = []) =>
    (dispatch, getState) => {
      const state = getState();
      const currentParams = getLocationParametersSelector(state);
      const newParams = omit(params, currentParams);

      const location = getLocationSelector(state);
      dispatch(replace(location, removeFunctions(newParams)));
    };

const removeFunctions = (params) =>
  Object.entries(params).reduce((acc, [key, val]) => (is(Function, val) ? acc : { ...acc, [key]: val }), {});
