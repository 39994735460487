/* eslint-disable import/no-cycle */
import React from 'react';
import { Loading } from 'components/styleguide';
import Grid from '@material-ui/core/Grid';

const LoadingWrapper = ({ size }) => (
  <Grid container>
    <Grid container item alignContent="center">
      <Loading size={size} />
    </Grid>
  </Grid>
);

export default LoadingWrapper;
