import { connect } from 'react-redux';
import { compose } from 'redux';
import { getOpenDialogSelector } from 'redux-core/dialogs/selectors';
import { getIsOpenPromptSelector } from 'redux-core/prompt/selectors';

import { connect as withFormik } from 'formik';

const mapStateToProps = (state) => ({
  openDialog: getOpenDialogSelector(state),
  isOtherPromptOpen: getIsOpenPromptSelector(state),
});

export default compose(connect(mapStateToProps), withFormik);
