import { makeStyles } from '@material-ui/core';
import { appColors } from 'components/styleguide';

const budgetHistoryExtraSpacing = '100px';
const budgetMainSectionHeight = '261px';
const budgetHeaderSpacing = '119px';
const budgetHistoryExtraPaddingBottom = '40px';

export default makeStyles((theme) => ({
  divider: {
    margin: theme.spacing(4, 0),
  },
  drawerHeaderSpace: {
    marginBottom: theme.spacing(5),
  },
  budgetRow: {
    marginBottom: theme.spacing(3),
  },
  budgetField: {
    marginRight: theme.spacing(1.625),
    '& > span': {
      display: 'block',
      marginBottom: theme.spacing(1.125),
    },
    '&.withMargins': {
      '& > span': {
        display: 'block',
        marginBottom: theme.spacing(1.875),
      },
    },
  },
  budgetInputWrapper: {
    maxWidth: 138,
    '& input': {
      textAlign: 'left',
      fontSize: 12,
    },
  },
  label: {
    marginBottom: theme.spacing(1.5),
  },
  reasonLabel: {
    fontWeight: 'bold',
    color: appColors.dark,
    marginBottom: theme.spacing(1.5),
  },
  budgetHistory: {
    maxHeight: `calc(100vh - ${budgetHeaderSpacing} - ${budgetHistoryExtraPaddingBottom})`,
    paddingBottom: theme.spacing(5),
    overflow: 'auto',
    '&.isEditing': {
      maxHeight: `calc(100vh - ${budgetMainSectionHeight} - ${budgetHistoryExtraSpacing})`,
      paddingBottom: theme.spacing(2.5),
    },
  },
}));
