import { makeStyles } from '@material-ui/core';
import { appColors } from 'components/styleguide';
import { hexWithOpacity } from 'utils';

const styles = makeStyles((theme) => ({
  textInput: ({ showList }) => ({
    zIndex: showList ? theme.zIndex.two : 0,
    width: '100%',
  }),
  valueList: {
    width: '100%',
  },
  listItem: {
    alignItems: 'flex-start',
    color: appColors.grey,
    padding: theme.spacing(1),
    paddingLeft: 0,
    '& > svg': {
      cursor: 'pointer',
      marginLeft: theme.spacing(1.5),
      marginTop: theme.spacing(0.75),
    },
  },
  itemIcon: {
    color: hexWithOpacity(theme.palette.primary.main, 0.3),
  },
}));

export default styles;
