import { makeStyles } from '@material-ui/core/styles';
import { appColors } from 'components/styleguide/theme';

const useStyles = makeStyles((theme) => ({
  total: {
    marginBottom: theme.spacing(1.25),
  },
  tooltip: {
    backgroundColor: appColors.dark,
    margin: theme.spacing(0, 0.5),
    minWidth: 90,
  },
}));

export default useStyles;
