import { SecuredForm } from 'components/common/Secured';
import { DrawerHeader, Row } from 'components/styleguide';
import { FormButton, FormInputText } from 'components/common/Form/Fields';
import { useRootTranslation } from 'utils/useRootTranslation';
import { COMMON_ROLES } from 'redux-core/permissions/constants';

export function ClearanceBaseForm({
  children,
  submitButtonTag,
  initialValues,
  objectId,
  handleSubmit,
  title,
  onClose,
  name,
  validationSchema,
}) {
  const t = useRootTranslation('drawers.clearance.addEdit');

  return (
    <SecuredForm
      drawerName={name}
      initialValues={initialValues}
      enableReinitialize
      objectId={objectId}
      onSubmit={handleSubmit}
      permissions={COMMON_ROLES.EDITOR}
      validationSchema={validationSchema}
    >
      <DrawerHeader title={title} onClose={onClose}>
        <FormButton
          alwaysEnabled
          variant="destructive"
          size="xs"
          testId="clearance-add-edit-drawer-cancel"
          onClick={onClose}
        >
          global.forms.cancel
        </FormButton>
        <FormButton variant="primary" testId="clearance-add-edit-drawer-save" size="xs" type="submit">
          {submitButtonTag}
        </FormButton>
      </DrawerHeader>
      <Row>
        <FormInputText required name="name" label={t('form.name')} testId="clearance-add-edit-drawer-name" />
      </Row>
      {children}
    </SecuredForm>
  );
}
