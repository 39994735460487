/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { InputFormat } from 'components/styleguide';
import { useNumberFormat } from 'utils/hooks';
import isNil from 'ramda/src/isNil';

type InputFormatFieldProps = React.InputHTMLAttributes<HTMLInputElement> & {
  value: number;
  onChange: (value: number) => void;
  disabled?: boolean;
  fullWidth?: boolean;
  label?: string;
  placeholder?: string;
  testId?: string;
  type: 'currency' | 'percentage' | 'time';
  decimalScale?: number;
  name: string;
  format?: string;
  textAlign?: string;
  id?: string;
};

const InputFormatField = ({ disabled, fullWidth, label, placeholder, onChange, ...props }: InputFormatFieldProps) => {
  const [thousand, decimal] = useNumberFormat();
  const { t } = useTranslation();

  const inputProps = {
    disabled,
    fullWidth,
    label: t(label),
    placeholder: isNil(placeholder) ? undefined : t(placeholder),
  };

  return <InputFormat {...props} {...inputProps} separators={[thousand, decimal]} onChange={onChange} />;
};

InputFormatField.displayName = 'FormInputFormat';

export default InputFormatField;
