/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Text } from 'components/styleguide';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import path from 'ramda/src/path';
import { Grid, ListItemButton, Paper } from '@mui/material';

export const CreateItem = ({
  selectProps: {
    classes,
    inputValue,
    extraProps: { creatableText, onCreate, createDisabled },
  },
}) => {
  // Added !createDisabled check because click goes through even if the listItem is disabled, looks like a MU Bug
  const handleCreate = () => !createDisabled && onCreate(inputValue);
  return onCreate ? (
    <ListItemButton
      className={classes.create}
      onClick={handleCreate}
      disabled={createDisabled}
      data-testid="typeahead-create-new"
    >
      <Grid container alignItems="flex-start" spacing={1}>
        <Grid item>
          <AddCircleIcon color="primary" />
        </Grid>
        <Grid item>
          <Text bold color="primary" variant="caption">
            {creatableText}
          </Text>
        </Grid>
      </Grid>
    </ListItemButton>
  ) : null;
};

function Menu(props) {
  const MenuPrepend = path(['selectProps', 'extraProps', 'MenuPrepend'], props);

  return (
    <Paper square className={props.selectProps.classes.paper} data-testid="typeahead-menu" {...props.innerProps}>
      <>
        {MenuPrepend && <MenuPrepend {...props} />}
        {props.children}
        <CreateItem {...props} />
      </>
    </Paper>
  );
}

Menu.propTypes = {
  /**
   * The children to be rendered.
   */
  children: PropTypes.element.isRequired,
  /**
   * Props to be passed to the menu wrapper.
   */
  innerProps: PropTypes.object.isRequired,
  selectProps: PropTypes.object.isRequired,
};
export default Menu;
