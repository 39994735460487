/* eslint-disable react/no-children-prop */
import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import { DrawerHeader, Button, Text } from 'components/styleguide';
import { useRootTranslation } from 'utils/hooks';
// eslint-disable-next-line import/no-named-as-default
import DRAWERS from 'components/common/Drawers/constants';
import { Tooltip } from '@material-ui/core';
import enhancer from './enhancer';
import SubmissionList from './SubmissionList';

const rootT = 'drawers.cueSheetSubmissionHistory';

const CueSheetSubmissionHistoryDrawer = ({
  isDirty,
  name: drawerName,
  onClose,
  openDrawer,
  openPrompt,
  payload: { onSubmit, cueContainerId },
}) => {
  const t = useRootTranslation(rootT);
  const tGlobal = useRootTranslation('global');

  const goToSubmitCueSheet = async () => {
    const open = () => openDrawer(DRAWERS.SUBMIT_CUE_SHEET, { onSubmit, cueContainerId });
    if (isDirty) {
      openPrompt({
        content: t('goToSubmit'),
        onConfirm: open,
      });
    } else open();
  };

  const disableSubmitButton = !onSubmit;

  return (
    <>
      <DrawerHeader bold hideBackButton onClose={onClose} title={t('drawerTitle')}>
        <Button
          children={tGlobal('forms.cancel')}
          onClick={() => onClose()}
          size="xs"
          testId="cuesheet-submission-history-cancel"
          variant="destructive"
        />
        <Tooltip title={disableSubmitButton ? t('disabledSubmitTooltip') : ''}>
          <span>
            <Button
              children={t('newSubmission')}
              onClick={goToSubmitCueSheet}
              disabled={disableSubmitButton}
              size="md"
              testId="cuesheet-submission-history-new-submission"
              type="submit"
              variant="primary"
            />
          </span>
        </Tooltip>
      </DrawerHeader>
      <Box display="flex" flexWrap="wrap" mb={4} width={1}>
        <Box display="flex" mb={2} flexDirection="column">
          <Text bold children={t('hint')} gutterBottom />
          <Text children={t('hintText')} />
        </Box>
        <Text children={t('hintSubtext')} />
        <Box mt={2} width={1}>
          <SubmissionList drawerName={drawerName} cueContainerId={cueContainerId} />
        </Box>
      </Box>
    </>
  );
};

CueSheetSubmissionHistoryDrawer.propTypes = {
  isDirty: PropTypes.string || PropTypes.bool,
  name: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  openDrawer: PropTypes.func.isRequired,
  openPrompt: PropTypes.func.isRequired,
  payload: PropTypes.shape({}),
};

export default enhancer(CueSheetSubmissionHistoryDrawer);
