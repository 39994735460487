import { makeStyles } from '@material-ui/core';
import { appColors } from 'components/styleguide';

const styles = makeStyles((theme) => ({
  header: {
    backgroundColor: appColors.lightGrey4,
    position: 'sticky',
    top: 0,
    zIndex: 1,
    '& > *': {
      '& > *': {
        marginBottom: theme.spacing(2.25),
      },
      '& > :last-child': {
        marginBottom: 0,
      },
    },
  },
  logo: {
    marginRight: theme.spacing(1.5),
    marginTop: theme.spacing(0.5),
  },
}));

export default styles;
