import { connect } from 'react-redux';
import { openPrompt } from 'redux-core/prompt/actions';
import compose from 'ramda/src/compose';
import withBaseDrawer from '../withBaseDrawer';

const mapDispatchToProps = {
  openPrompt,
};

export default compose(connect(null, mapDispatchToProps), withBaseDrawer);
