import { useEffect, useState } from 'react';
import { useGetTrackQuery } from 'services/tracks/tracks';

export const useDuplicateTrack = (formBag, generateOptionId, append) => {
    const [selectedTrack, setSelectedTrack] = useState(null);
    const { data: trackData } = useGetTrackQuery({ id: selectedTrack }, { skip: !selectedTrack });

    useEffect(() => {
        if (!trackData) return;

        trackData.rightsOwners.forEach(({ id, type, name, share, role, writerAffiliation }) => {
            const item = {
                id: generateOptionId(),
                qwireTracksRightsOwnerId: id,
                type: (trackData.oneStopCombined && ['master', 'sync'].includes(type)) ? 'one_stop' : type,
                name,
                share,
                role,
                writerAffiliation,
            };
            if (['master', 'sync'].includes(type)) delete item.role;

            if (!['publisher', 'writer'].includes(type)) delete item.writerAffiliation;

            append(item);
        });

        if (trackData.oneStopCombined) {
            formBag.setValue('oneStopCombined', true);
        }

        if (trackData.type === 'library') {
            formBag.setValue('type', 'library');
            formBag.setValue('library', { id: trackData.library.id, name: trackData.library.name });
        } else {
            formBag.setValue('artist', { name: trackData.artists[0]?.name, id: trackData.artists[0]?.id });
            formBag.setValue('type', 'song');
        }

        formBag.setValue('title', `[Duplicated] ${trackData.title}`);
        formBag.setValue('contentMode', 'create');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTrack, trackData]);

    return { duplicateTrack: setSelectedTrack };
};
