/* eslint-disable react/jsx-props-no-spreading */
import { useState } from 'react';
import { Grid, Chip, IconButton } from '@mui/material';
// eslint-disable-next-line import/no-extraneous-dependencies
import clsx from 'clsx';
import UsingIcon from '@material-ui/icons/Check';
import NotUsingIcon from '@material-ui/icons/CheckOutlined';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import BlockIcon from '@material-ui/icons/Block';
import { Draggable } from 'react-beautiful-dnd';
import { makeStyles } from '@material-ui/core';
import { appColors, newTheme, Text } from 'components/styleguide';
import { HHMMSStoMMSS } from 'utils/date';
import { usageChipLabel } from 'utils/format';
import { SummaryActions } from './SummaryActions';

const useStyles = makeStyles(() => ({
  smallIcon: {
    height: '20px',
    width: '20px',
  },
  notUsingIcon: {
    color: appColors.disabled,
    border: '1px solid',
    borderRadius: '50%',
    borderColor: appColors.disabled,
  },
  usingIcon: {
    color: appColors.white,
    backgroundColor: '#27ae60',
    border: '1px solid',
    borderRadius: '50%',
    borderColor: '#27ae60',
  },
  gridOutline: {
    borderTop: 'solid 1px #CDCDCD',
  },
  red: {
    color: 'red',
  },
}));

const CenteredGridItem = ({ children, ...props }) => (
  <Grid item sx={{ alignSelf: 'center' }} {...props}>
    {children}
  </Grid>
);

const StartIcon = ({ hover, active }) => {
  const classes = useStyles();
  if (!active) {
    return <BlockIcon className={classes.red} size="small" />;
  }
  if (hover) {
    return (
      <IconButton className={classes.smallIcon} size="small" color="primary">
        <DragIndicatorIcon fontSize="small" />
      </IconButton>
    );
  }
  return null;
};

export const Body = ({ cue, index, isAllowedToEdit, ...rest }) => {
  const {
    cueContainer,
    requestedUseType,
    requestedUseDuration,
    requestedUseDurationFull,
    actualUseDuration,
    actualUseType,
    used,
    active,
    allocation,
  } = cue;
  const classes = useStyles();
  const [hover, setHover] = useState(false);

  const requestedDuration = requestedUseDurationFull ? 'Full' : HHMMSStoMMSS(requestedUseDuration);

  return (
    <Draggable draggableId={`${cue.id}`} index={index}>
      {(provided) => (
        <Grid
          container
          item
          direction="row"
          justify="center"
          mt={1}
          p={1}
          className={classes.gridOutline}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          ref={provided.innerRef}
          {...provided.draggableProps}
          sx={{
            '&:hover': {
              backgroundColor: newTheme.palette.action.hover,
            },
          }}
        >
          <CenteredGridItem
            xs={1}
            {...provided.dragHandleProps}
            justifyContent="center"
            style={{ textAlign: 'center' }}
          >
            <StartIcon hover={hover} active={active} />
          </CenteredGridItem>
          <CenteredGridItem xs={1}>
            <Text align="left">{cueContainer.number}</Text>
          </CenteredGridItem>
          <CenteredGridItem xs={3}>
            <Text align="left" color={active ? 'textPrimary' : 'greyEmptyState'}>
              {cueContainer.title}
            </Text>
          </CenteredGridItem>
          <CenteredGridItem xs={1} justifyContent="center" style={{ textAlign: 'center' }}>
            <IconButton disabled size="small" color="primary">
              {used ? (
                <UsingIcon className={clsx(classes.usingIcon, classes.smallIcon)} fontSize="small" />
              ) : (
                <NotUsingIcon className={clsx(classes.notUsingIcon, classes.smallIcon)} fontSize="small" />
              )}
            </IconButton>
          </CenteredGridItem>
          <CenteredGridItem xs={2} sx={{ textAlign: 'center' }}>
            {requestedDuration ? (
              <Chip label={usageChipLabel(requestedDuration, requestedUseType)} />
            ) : null}
          </CenteredGridItem>
          <CenteredGridItem xs={2} sx={{ textAlign: 'center' }}>
            {actualUseDuration ? <Chip label={usageChipLabel(HHMMSStoMMSS(actualUseDuration), actualUseType)} /> : null}
          </CenteredGridItem>
          <CenteredGridItem xs={1}>
            <Text align="center">{allocation}%</Text>
          </CenteredGridItem>
          <Grid container item xs={1} justifyContent="flex-end">
            <SummaryActions
              hover={hover}
              isAllowedToEdit={isAllowedToEdit}
              name={cueContainer.title}
              cue={cue}
              {...rest}
            />
          </Grid>
        </Grid>
      )}
    </Draggable>
  );
};
