/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import Loading from '../../loading/Loading';

function NoOptionsMessage(props) {
  const { loading, noResultsText } = props.selectProps.extraProps;
  return (
    <Typography className={props.selectProps.classes.noOptionsMessage} {...props.innerProps} component="div">
      {loading ? <Loading size={25} /> : noResultsText}
    </Typography>
  );
}

NoOptionsMessage.propTypes = {
  /**
   * The children to be rendered.
   */
  children: PropTypes.node,
  /**
   * Props to be passed on to the wrapper.
   */
  innerProps: PropTypes.object,
  selectProps: PropTypes.object.isRequired,
};

export default NoOptionsMessage;
