/* eslint-disable react/require-default-props */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { useField } from 'formik';
import { FormTypeAhead, FormInputFormat, FormInputText } from 'components/common/Form/Fields';
import { TIME_FORMATS } from 'utils/format';
import { useRootTranslation } from 'utils/hooks';
import { InputLabel } from '@mui/material';
import RightsPresetPickerFormField from 'components/common/RightsPresetPicker/RightsPresetPickerFormField';
import useStyles from './styles';

const rootT = 'drawers.assets';

function DetailsAsset({ divisionId, materialTypes, objectId, id }) {
  const classes = useStyles();
  const [isDirty, setIsDirty] = useState(false);
  const [{ value }, , { setValue }] = useField('requiredRights');
  const t = useRootTranslation(rootT);

  const showError = isDirty && !value?.length;

  return (
    <Grid item xs={5}>
      <Grid item container className={classes.detailsRow}>
        <FormInputText testId="asset-title" label={t('labels.title')} name="title" required />
      </Grid>
      <Grid item container className={classes.detailsRow}>
        <FormTypeAhead
          label={t('labels.type')}
          testId="asset-drawer-material-types"
          name="materialTypeId"
          placeholder="global.forms.chooseOne"
          options={materialTypes}
        />
      </Grid>
      <Grid item container className={classes.detailsRow}>
        {!id ? (
          <>
            <InputLabel required sx={{ marginBottom: '0.5rem' }}>
              Rights & Options
            </InputLabel>
            <RightsPresetPickerFormField
              value={value}
              onBlur={() => {
                setIsDirty(true);
              }}
              onChange={(values) => {
                setValue(values);
              }}
              productionObjectId={objectId}
              divisionId={divisionId}
              showErrorBorder={showError}
              fullWidth
            />
          </>
        ) : null}
      </Grid>
      <Grid item container className={classes.detailsRow}>
        <FormInputText
          testId="asset-drawer-version-name"
          label={t('labels.versionName')}
          name="currentCueContainerVersion.title"
        />
      </Grid>
      <Grid item container className={classes.detailsRow} spacing={1}>
        <Grid item xs={3}>
          <FormInputText
            label={t('labels.version')}
            testId="asset-drawer-version-number"
            name="currentCueContainerVersion.version"
          />
        </Grid>
        <Grid item xs={4}>
          <FormInputFormat
            format={TIME_FORMATS.MMSS}
            label={t('labels.versionTRT')}
            name="currentCueContainerVersion.duration"
            testId="asset-drawer-version-duration"
            type="time"
          />
        </Grid>
      </Grid>
      <Grid item container className={classes.detailsRow}>
        <FormInputText label={t('labels.notes')} testId="asset-drawer-notes" name="notes" textArea />
      </Grid>
    </Grid>
  );
}

DetailsAsset.propTypes = {
  divisionId: PropTypes.number.isRequired,
  materialTypes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
  ).isRequired,
  objectId: PropTypes.number,
  id: PropTypes.number,
};

export default DetailsAsset;
