/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable import/no-cycle */
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Text, Row, LocationIcon } from 'components/styleguide';
import CreateIcon from '@material-ui/icons/Create';
import RemoveIcon from '@material-ui/icons/Remove';
import { Grid, Box, IconButton } from '@material-ui/core';
import { useRootTranslation, useDrawer } from 'utils/hooks';
// eslint-disable-next-line import/no-named-as-default
import DRAWERS from 'components/common/Drawers/constants';
import compose from 'ramda/src/compose';
import { useFormikContext, getIn, setIn } from 'formik';
import enhancer from './enhancer';
import CollapsePanelOffsetIcon from '../CollapsePanelOffsetIcon';
import useStyles from '../styles';

const Territory = ({ index, onRemoveRow, territory, isEditing, setRightPreset }) => {
  const classes = useStyles();
  const t = useRootTranslation('rights.rowExpanded');
  const openDrawer = useDrawer();
  const { values, dirty } = useFormikContext();
  const territories = getIn(values, 'territories');

  const getFieldName = useCallback((field) => `territories.${index}.${field}`, [index]);

  const handleOpenDrawer = useCallback(() => {
    setRightPreset({ ...values, secondaryOpened: true, isEditing, dirty });
    openDrawer(DRAWERS.SETTINGS_RIGHTS_PRESET_TERRITORIES, {
      onSubmit: ({ id, name }) => {
        const newValues = compose(
          (values) => setIn(values, getFieldName('id'), id),
          (values) => setIn(values, getFieldName('name'), name),
        )(values);
        setRightPreset({
          ...newValues,
          secondaryOpened: true,
          isEditing,
          dirty: true,
        });
      },
      territoryId: territory.id,
    });
  }, [values, openDrawer, setRightPreset, getFieldName, isEditing, dirty, territory.id]);

  const removeRowIsDisabled = territories.length === 1;

  const handleRemoveRow = useCallback(onRemoveRow, [onRemoveRow]);

  return (
    <Row spacing={6}>
      <Grid item xs={4} className={classes.avoidOverlap}>
        <Row mb={0} display="flex" alignItems="center">
          <CollapsePanelOffsetIcon icon={RemoveIcon} onClick={handleRemoveRow} disabled={removeRowIsDisabled}>
            <Box display="flex" alignItems="center">
              <Box mr={1}>
                <LocationIcon />
              </Box>
              <Text bold variant="h6">
                {t('territoryTitle')}
              </Text>
              {isEditing && (
                <IconButton color="primary" onClick={handleOpenDrawer}>
                  <CreateIcon />
                </IconButton>
              )}
            </Box>
          </CollapsePanelOffsetIcon>
        </Row>
        <Row>
          <Text variant="subtitle1" testId="territory">
            {territory.name || t('selectTerritory')}
          </Text>
        </Row>
      </Grid>
    </Row>
  );
};

Territory.propTypes = {
  index: PropTypes.number.isRequired,
  onRemoveRow: PropTypes.func.isRequired,
  territory: PropTypes.object.isRequired,
  isEditing: PropTypes.bool,
  setRightPreset: PropTypes.func.isRequired,
};

export default enhancer(Territory);
