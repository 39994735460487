import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-premium';
import { Tooltip, Typography } from '@mui/material';
import { getLocalDate, rootTranslation } from 'utils/format';
import { TSearchTrack } from 'services/tracks/types';
import { TableRowText } from 'components/common/Grid/TableRowText';
import React from 'react';
import { ScoreColumn } from './ScoreColumn';
import { ActionColumn } from './ActionColumn';
import { RenderTrackContributorHeader, TrackContributorColumn } from './TrackContributorColumn';

const tRoot = rootTranslation('drawers.productionSetup.tracks.headers');

export const getColumns = (
  hideAddTrackButton: boolean,
  isLibraryType: boolean,
  onCueContainerIdMissing: () => void,
  duplicateTrack: () => void,
): GridColDef<TSearchTrack>[] => {
  const columns = [
    {
      field: 'title',
      flex: 1,
      headerName: tRoot('title'),
      valueGetter: ({ row }) => row.title,
      renderCell: (params: GridRenderCellParams<TSearchTrack>) => <TableRowText>{params.value}</TableRowText>,
      filterable: false,
      sortable: false,
    },
    ...(isLibraryType
      ? [
          {
            field: 'trackId',
            minWidth: 150,
            flex: 1,
            headerName: tRoot('trackId'),
            valueGetter: ({ row }) => row.librarytrackid,
            renderCell: (params: GridRenderCellParams<TSearchTrack>) => (
              <TableRowText>
                <Tooltip title={params.value} placement="top">
                  <Typography variant="body2" component="span" display="block">
                    {params.value}
                  </Typography>
                </Tooltip>
              </TableRowText>
            ),
            filterable: false,
            sortable: false,
          },
        ]
      : []),
    {
      field: 'artistOrWriter',
      flex: 1,
      renderHeader: RenderTrackContributorHeader,
      valueGetter: ({ row }) => row,
      renderCell: (params: GridRenderCellParams<TSearchTrack>) => <TrackContributorColumn track={params.value} />,
      filterable: false,
      sortable: false,
    },
    {
      field: 'lastUpdated',
      minWidth: 140,
      headerName: tRoot('lastUpdated'),
      valueGetter: ({ row }) => row.updatedat,
      renderCell: (params: GridRenderCellParams<TSearchTrack>) => (
        <TableRowText>{getLocalDate(params.value, 'MMM DD, YYYY')}</TableRowText>
      ),
      filterable: false,
      sortable: false,
    },
    {
      field: 'score',
      minWidth: 240,
      renderHeader: () => <span> </span>,
      valueGetter: ({ row }) => row,
      renderCell: (params: GridRenderCellParams<TSearchTrack>) => <ScoreColumn track={params.value} />,
      filterable: false,
      sortable: false,
    },
  ];

  if (!hideAddTrackButton) {
    columns.push({
      field: 'addTrack',
      minWidth: 150,
      renderHeader: () => <span> </span>,
      valueGetter: ({ row }) => row,
      renderCell: (params: GridRenderCellParams<TSearchTrack>) => (
        <ActionColumn
          track={params.value}
          onCueContainerIdMissing={onCueContainerIdMissing}
          duplicateTrack={duplicateTrack}
        />
      ),
      filterable: false,
      sortable: false,
    });
  }

  return columns;
};
