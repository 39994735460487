import * as React from 'react';
import Grid from '@mui/system/Unstable_Grid/Grid';
import { Box, styled } from '@mui/system';
import { FieldArrayWithId, UseFieldArrayRemove, useWatch } from 'react-hook-form';
import { kebabCase } from 'lodash';
import { LICENSOR_TYPES } from 'utils/constants';
import { groupRightsOwners } from 'components/common/rightOwners/groupRightsOwners';
import { LicensorsFormSection } from 'components/common/rightOwners/licensors/LicensorsFormSection';
import { rootTranslation } from 'utils/format';
import { LicensorRowForm } from 'components/common/rightOwners/licensors/LicensorRowForm';
import { CreatorFormSection } from 'components/common/rightOwners/creator/CreatorFormSection';
import { CreatorRowForm } from 'components/common/rightOwners/creator/CreatorRowForm';
import { PublisherFormSection } from 'components/common/rightOwners/publisher/PublisherFormSection';
import { PublisherRowForm } from 'components/common/rightOwners/publisher/PublisherRowForm';
import { TFormRightOwners } from 'components/common/rightOwners/types';
import { LicensorTotalsList } from 'components/common/rightOwners/LicensorTotalsList';
import { TAddTrackForm } from '../types';

const t = rootTranslation('drawers.productionSetup.tracks.createLicensors');

export type THandleSubmitFormArgs = {
  rightsOwners: TFormRightOwners;
  oneStopCombined: boolean;
};

type CreateLicensorsFormProps = {
  fields: FieldArrayWithId<TAddTrackForm, 'rightsOwners', 'id'>[];
  remove: UseFieldArrayRemove;
};

const StyledGridContainer = styled(Grid)(({ theme }) => ({
  marginBottom: 2,
  '& > .MuiGrid2-root': {
    padding: '1rem 0.5rem',
    margin: 0,
  },
  '& > .MuiGrid2-root:not(:first-of-type)': {
    marginTop: theme.spacing(2),
  },
}));

export function CreateLicensorsForm({ fields, remove }: CreateLicensorsFormProps) {
  const oneStopCombined = useWatch({ name: 'oneStopCombined' });

  const rightsOwnersMap = groupRightsOwners(fields);
  const masterFields = rightsOwnersMap.get(LICENSOR_TYPES.MASTER);
  const syncFields = rightsOwnersMap.get(LICENSOR_TYPES.SYNC);
  const otherFields = rightsOwnersMap.get(LICENSOR_TYPES.OTHER);
  const oneStopFields = rightsOwnersMap.get(LICENSOR_TYPES.ONE_STOP);
  const writerFields = rightsOwnersMap.get('writer');
  const publisherFields = rightsOwnersMap.get('publisher');

  return (
    <Grid container>
      <Box sx={{ width: '100%', height: '100%' }}>
        <Grid container xs={12} spacing={2}>
          <StyledGridContainer xs={10}>
            {oneStopCombined ? (
              oneStopFields && (
                <LicensorsFormSection sectionLabel={t('one_stop')}>
                  {oneStopFields.map(([field, index]) => (
                    <LicensorRowForm
                      key={field.id}
                      type="one_stop"
                      htmlSectionId={kebabCase(t('one_stop'))}
                      index={index}
                      removeField={() => remove(index)}
                    />
                  ))}
                </LicensorsFormSection>
              )
            ) : (
              <>
                {masterFields && (
                  <LicensorsFormSection sectionLabel={t('masterLicensor')}>
                    {masterFields.map(([field, index]) => (
                      <LicensorRowForm
                        key={field.id}
                        type="master"
                        htmlSectionId={kebabCase(t('masterLicensor'))}
                        index={index}
                        removeField={() => remove(index)}
                      />
                    ))}
                  </LicensorsFormSection>
                )}
                {syncFields && (
                  <LicensorsFormSection sectionLabel={t('syncLicensor')}>
                    {syncFields.map(([field, index]) => (
                      <LicensorRowForm
                        key={field.id}
                        type="sync"
                        htmlSectionId={kebabCase(t('syncLicensor'))}
                        index={index}
                        removeField={() => remove(index)}
                      />
                    ))}
                  </LicensorsFormSection>
                )}
              </>
            )}
            {writerFields && (
              <CreatorFormSection sectionLabel={t('writer')}>
                {writerFields.map(([field, index]) => (
                  <CreatorRowForm
                    key={field.id}
                    type="writer"
                    htmlSectionId={kebabCase(t('writer'))}
                    index={index}
                    removeField={() => remove(index)}
                  />
                ))}
              </CreatorFormSection>
            )}
            {publisherFields && (
              <PublisherFormSection sectionLabel={t('publisher')}>
                {publisherFields.map(([field, index]) => (
                  <PublisherRowForm
                    key={field.id}
                    type="publisher"
                    htmlSectionId={kebabCase(t('publisher'))}
                    index={index}
                    removeField={() => remove(index)}
                    oneStopCombined={oneStopCombined}
                  />
                ))}
              </PublisherFormSection>
            )}
            {!oneStopCombined && otherFields && (
              <LicensorsFormSection sectionLabel={t('other')}>
                {otherFields.map(([field, index]) => (
                  <LicensorRowForm
                    key={field.id}
                    type="other"
                    htmlSectionId={kebabCase(t('other'))}
                    index={index}
                    removeField={() => remove(index)}
                  />
                ))}
              </LicensorsFormSection>
            )}
          </StyledGridContainer>
          <Grid xs={2}>
            <LicensorTotalsList />
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
}
