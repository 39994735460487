/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/no-children-prop */
import React from 'react';
import PropTypes from 'prop-types';
import { FormButton } from 'components/common/Form/Fields/index';
import { useRootTranslation } from 'utils/hooks';
import FormDrawerHeader from 'components/common/Form/Fields/FormDrawerHeader/index';
import enhancer from './enhancer';
import TaskDrawer, { TaskDrawerPayloadPropTypes } from '../index';

const CreateTaskDrawerHeader = ({ onClose }) => {
  const t = useRootTranslation('drawers.tasks.create');

  return (
    <FormDrawerHeader title={t('createTask')} onClose={onClose}>
      <FormButton
        alwaysEnabled
        variant="destructive"
        size="xs"
        testId="create-task-drawer-cancel"
        children="global.forms.cancel"
        onClick={() => {
          onClose();
        }}
      />

      <FormButton
        variant="primary"
        testId="create-task-drawer-save"
        size="xs"
        type="submit"
        children="global.forms.save"
      />
    </FormDrawerHeader>
  );
};

const CreateTaskDrawer = ({ createTask, onClose, ...props }) => {
  const handleSubmit = async (values) => {
    await createTask(values);
    props.payload.refetch && (await props.payload.refetch());
    onClose();
  };

  return (
    <TaskDrawer {...props} headerContent={() => <CreateTaskDrawerHeader onClose={onClose} />} onSubmit={handleSubmit} />
  );
};

CreateTaskDrawer.propTypes = {
  onClose: PropTypes.func.isRequired,
  createTask: PropTypes.func.isRequired,
  payload: TaskDrawerPayloadPropTypes.isRequired,
};

CreateTaskDrawerHeader.propTypes = {
  onClose: CreateTaskDrawer.propTypes.onClose,
};

export default enhancer(CreateTaskDrawer);
