import { useAtomValue, useSetAtom } from 'jotai';
import { useResetAtom } from 'jotai/utils';
import { composeLetterAtom, composeLetterSteps } from '../composeLetterStore';
import { rootTranslation } from '../../../utils/format';
import { capitalizeFirstLetter, composeLetterFileName } from '../helpers';

const tGlobal = rootTranslation('global');

export const useChangeSelectedLicensor = () => {
    const composeLetter = useAtomValue(composeLetterAtom);
    const updateComposeLetter = useSetAtom(composeLetterAtom);
    const resetComposeLetter = useResetAtom(composeLetterAtom);

    const updateState = (selectedLicensor, remainingLicensors) => {
        updateComposeLetter((prev) => ({
            ...prev,
            licensorQueue: remainingLicensors,
            clearanceBundleLicensor: {
                ...selectedLicensor,
            },
            clearanceBundleLicensorsIds: [selectedLicensor.id],
            contact: undefined,
            letter: {
                templateId: undefined,
                draftId: undefined,
                clearanceStatusId: prev.letter.clearanceStatusId,
                letterFileType: 'pdf',
                docxUrl: undefined,
                pdfUrl: undefined,
                setStatus: true,
                setSendDate: true,
                editorReady: false,
                ignoreDraft: false,
            },
            email: {
                templateId: undefined,
                clearanceStatusId: prev.email.clearanceStatusId,
                toRecipients: undefined,
                ccRecipients: undefined,
                bccRecipients: undefined,
                from: prev.email.from,
                subject: undefined,
                body: undefined,
                editorReady: true,
                extraAttachments: [],
            },
            currentStep: composeLetterSteps[1],
            documentTitle: composeLetterFileName(
                prev.track.title,
                prev.project.name,
                selectedLicensor.rightsOwner.name.replaceAll('.', ''),
                capitalizeFirstLetter(selectedLicensor.type === 'one_stop' ? 'One Stop' : selectedLicensor.type),
                tGlobal(`fileTypes.${prev.letterType}`),
            ),
        }));
    };

    const loadSpecificLicensor = (licensorId: number) => {
        const remainingLicensors = composeLetter.licensorQueue;

        const selectedLicensor = composeLetter.licensorQueue.filter((item) => item.id === licensorId)[0];
        updateState(selectedLicensor, remainingLicensors);
    };

    const loadNextLicensor = () => {
        const remainingLicensors = composeLetter.licensorQueue
            .filter((item) => item.id !== composeLetter.clearanceBundleLicensor.id);

        if (remainingLicensors.length === 0) {
            composeLetter.goToLink();
            resetComposeLetter();
            return;
        }

        const selectedLicensor = remainingLicensors[0];
        updateState(selectedLicensor, remainingLicensors);
    };

    return {
        loadNextLicensor,
        loadSpecificLicensor,
    };
};
