import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
  root: {
    '& > *': {
      marginLeft: theme.spacing(1.25),
    },
  },
  divider: {
    height: 38,
  },
  dateDropdown: {
    width: '15%',
  },
  date: {
    width: '13%',
  },
  status: {
    width: '232px',
  },
}));

export default styles;
