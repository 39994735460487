/* eslint-disable no-restricted-globals */
/* eslint-disable default-param-last */
import compose from 'ramda/src/compose';
import split from 'ramda/src/split';
import path from 'ramda/src/path';
import addIndex from 'ramda/src/addIndex';
import map from 'ramda/src/map';
import head from 'ramda/src/head';
import values from 'ramda/src/values';
import filter from 'ramda/src/filter';
import is from 'ramda/src/is';
import isEmpty from 'ramda/src/isEmpty';
import isNil from 'ramda/src/isNil';
import curry from 'ramda/src/curry';
import reduce from 'ramda/src/reduce';
import assoc from 'ramda/src/assoc';
import keys from 'ramda/src/keys';
import indexBy from 'ramda/src/indexBy';
import prop from 'ramda/src/prop';
import all from 'ramda/src/all';
import any from 'ramda/src/any';
import join from 'ramda/src/join';
import over from 'ramda/src/over';
import toLower from 'ramda/src/toLower';
import toUpper from 'ramda/src/toUpper';
import lensIndex from 'ramda/src/lensIndex';
import eqProps from 'ramda/src/eqProps';
import mergeRight from 'ramda/src/mergeRight';
import ramdaInnerJoin from 'ramda/src/innerJoin';
import mergeWith from 'ramda/src/mergeWith';
import { sortAlphabetically } from 'utils';
import { setIn } from 'formik';

/**
 * Get a value from an object based on a dot path
 * @example get('children.0.name')(schoolBus)
 */
export const get = compose(path, split('.'), (v) => (v ? String(v) : ''));

export const filterIndexed = addIndex(filter);

export const mapIndexed = addIndex(map);

export const first = compose(head, values);

export const renameKeys = curry((keysMap, obj) =>
  reduce((acc, key) => assoc(keysMap[key] || key, obj[key], acc), {}, keys(obj)),
);

/**
 * Creates an object composed of keys generated from the results of running each element of a List thru an iterator.
 * @example { id-1: Object, id-2: Object, id-3: Object }
 */
export const keyBy = compose(indexBy, prop);

export const allTruthy = compose(all(Boolean), values);

export const anyTruthy = compose(any(Boolean), values);

const compare = (a, b) => {
  if (isNil(b)) return true;
  if (is(String, a) && is(String, b)) {
    return a.toLowerCase().includes(b.toLowerCase());
  }
  return a === b;
};

/**
 * Returns a function to filter an array
 * @param {object} filterObject
 * @param {object} settings
 * @param {'every' | 'some'} settings.method
 * Comparison method used across all filters.
 * This function will focus on matching either 'every' filter against the provided term
 * or it will return the matched value if there's at least 'some' matching filter.
 * @return {Function}
 */
export const filterSearch = (filterObject, settings = {}) => {
  const filters = Object.entries(filterObject || {});
  const { method = 'every' } = settings;
  return filter((row) => filters[method](([key, value]) => compare(get(key)(row), value)));
};

/**
 * Returns true if the object is null, empty or if all its values are null, undefined or ""
 * @param {object} object
 * @return {Boolean}
 */
export const isEmptyObject = (object) =>
  !object || isEmpty(object) || Object.values(object).every((v) => (!is(Boolean, v) && !v) || isEmpty(v));

export const capitalize = compose(join(''), over(lensIndex(0), toUpper));

export const firstLetterToLowercase = compose(join(''), over(lensIndex(0), toLower));

export const snakeCase = (str) =>
  str &&
  str
    .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
    .map((x) => x.toLowerCase())
    .join('_');

export const enumCase = compose(toUpper, snakeCase);

/**
 * A simpler version of the R.innerJoin
 * @example innerJoin(id, [{ id: 'some-id' }], [{ id: 'some-id', another: 'value' }])
 * @return {array}
 */
export const innerJoin = curry((key, list1, list2) => {
  const indexed = indexBy(prop(key), list1);
  const intersection = ramdaInnerJoin(eqProps(key), list2, list1);
  return intersection.map((e) => {
    const item = indexed[e[key]];
    return mergeRight(item, e);
  });
});

export const getSortByPath = (pathArray) => (a, b) => sortAlphabetically(path(pathArray, a), path(pathArray, b));

/**
 * Merges two objects from left to right
 * preserving the prop with a truthy value at comparisson time
 * @example
 * fuse({ a: 'some value' }, { a: undefined });
 * return { a: 'some value' }
 */
export const fuse = mergeWith((value1, value2) => value1 || value2);

/**
 * Merges an array of objects from left to right
 * preserving the prop with a truthy value at comparisson time
 * @example
 * fuseAll([{ b: undefined }, { b: 100 }, { b: 'some value' }]);
 * return { b: 100 }
 */
export const fuseAll = reduce(fuse, {});

/**
 * Update an object by providing a dot separated path, a value and the object itself
 * Only works for string, number and objects
 *
 * @param {string} path
 * @param {string|number|object} value
 * @param {object} initial
 */
// eslint-disable-next-line @typescript-eslint/no-shadow
export const setValue = curry((path = '', value, initial = {}) => setIn(initial, path, value));

/**
 * Same implementation used in jQuery
 *
 * @see https://api.jquery.com/jQuery.isNumeric/
 * @param {*} n - Any type of value
 * @return {boolean}
 */
export const isNumeric = (n) => !isNaN(parseFloat(n)) && isFinite(n);
