/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/no-children-prop */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-shadow */
import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import Form from 'components/common/Form';
import { BaseDrawer, DrawerHeader, Text } from 'components/styleguide';
import * as Yup from 'yup';
import { Breadcrumbs, Grid, Link, Typography } from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { useRootTranslation, useSnackbar, useLocalStorage } from 'utils/hooks';
import { FormButton, FormDebouncedTypeAhead, FormInputText, FormTrackType } from 'components/common/Form/Fields';
import {
  getQwireTracksArtistsCall,
  getQwireTracksLibrariesCall,
  createQwireTrackArtistsCall,
} from 'redux-core/tracks/services';
import { TRACK_CUE_POPOVER_COOKIE, TRACK_TYPES } from 'utils/constants';
import omit from 'ramda/src/omit';
import { CommonDrawerPropTypes } from '../constants';
import useStyles from './styles';
import enhancer from './enhancer';
import CreateTrackButton from './CreateTrackButton';
import CreateLicensors from './CreateLicensors';
import { ScenePicker } from '../AddTrackDrawer/fields/ScenePicker';

const rootT = 'drawers.productionSetup.tracks.createTrack';

const validationSchema = Yup.object().shape({
  title: Yup.string().required('global.forms.validations.required'),
  type: Yup.string().required('global.forms.validations.required'),
});

const DrawerTitle = ({ isNewTrack, onClose }) => {
  const t = useRootTranslation(rootT);
  return (
    <Breadcrumbs separator={<NavigateNextIcon />}>
      <Link color="inherit" onClick={onClose}>
        <Text semibold variant="drawerTitle">
          {t('addTrack')}
        </Text>
      </Link>
      <Typography color="textPrimary">
        <Text variant="drawerTitle" semibold>
          {isNewTrack ? t('newTrack') : t('newPerformance')}
        </Text>
      </Typography>
    </Breadcrumbs>
  );
};

const CreateTrackDrawer = ({
  name,
  onClose,
  closeAllDialogs,
  createTrack,
  setDrawerDirty,
  setSelectedTrack,
  payload: { isNewTrack, ...payload },
}) => {
  const classes = useStyles();
  const t = useRootTranslation(rootT);
  const globalT = useRootTranslation('global');
  const showSnackbar = useSnackbar();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [hideTrackCuePopover] = useLocalStorage(TRACK_CUE_POPOVER_COOKIE, false);

  const [isTrackCreated, setIsTrackCreated] = React.useState(false);

  const handleCreateArtists = async (setFieldValue, value) => {
    try {
      const result = await createQwireTrackArtistsCall({ name: value });
      setFieldValue('artist', result);
      setFieldValue('qwireTracksArtistId', result.id);

      freshpaint.track('Created Artist', { name: value });
    } catch {
      showSnackbar({ message: globalT('genericError') });
    }
  };

  const onCancel = () => {
    setSelectedTrack({});
    closeAllDialogs();

    freshpaint.track('Closed New Performance Drawer');
  };

  const handleSelectArtist = (setFieldValue, value) => {
    if (value) {
      setFieldValue('qwireTracksArtistId', value.id);
      freshpaint.track('Selected Artist', value);
    }
  };

  const handleSubmit = async (values) => {
    const payload = omit(['artist'], values);
    const newTrack = await createTrack(payload, {
      isNewPerformance: !isNewTrack,
    });
    await setDrawerDirty({
      name,
      dirty: false,
      submitted: true,
    });
    if (!isNewTrack) {
      onClose();
    } else {
      setSelectedTrack(newTrack);
      setIsTrackCreated(true);
    }

    freshpaint.track('Created New Performance', values);
  };

  const handleClick = async (values, _, __, event, pureEvent) => {
    if (hideTrackCuePopover) {
      return handleSubmit(values);
    }
    setAnchorEl(pureEvent.currentTarget);
    event.stopPropagation();

    freshpaint.track('Clicked Save & Add Track', values);
  };

  const initialValues = {
    type: TRACK_TYPES.SONG,
    ...payload,
  };

  const trackTypes = [TRACK_TYPES.LIBRARY, TRACK_TYPES.SOUND_EFFECT];

  return (
    <>
      <Form
        drawerName={name}
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        isInitialValid={() => validationSchema.isValidSync(initialValues)}
      >
        {({ setFieldValue, values: { type } }) => (
          <>
            <DrawerHeader hideBackButton title={<DrawerTitle isNewTrack={isNewTrack} onClose={onClose} />}>
              {!isTrackCreated && (
                <>
                  <FormButton
                    alwaysEnabled
                    variant="destructive"
                    size="sm"
                    children="global.forms.cancel"
                    onClick={() => onCancel()}
                  />
                  <CreateTrackButton
                    anchorEl={anchorEl}
                    testId="saveTrack"
                    children={isNewTrack ? t('form.saveTrack') : t('form.savePerformance')}
                    onClick={handleClick}
                    onSubmit={handleSubmit}
                    setAnchorEl={setAnchorEl}
                    size="sm"
                    variant="primary"
                  />
                </>
              )}
            </DrawerHeader>
            <Grid container className={classes.container}>
              <Grid item xs={12}>
                <Text bold>{t('helpfulHint')}</Text>
                <Text color="textSecondary">{t('helpfulDetail')}</Text>
              </Grid>
              <Typography variant="h3">{t('form.addTrackTo')}</Typography>
              <Field name="cueContainerId">
                {({ field: { value }, form: { setFieldValue } }) => (
                  <ScenePicker
                    value={value}
                    onChange={(nextValue) => setFieldValue('cueContainerId', nextValue.id)}
                    disabled={isTrackCreated}
                  />
                )}
              </Field>
              {isNewTrack && (
                <Grid item xs={2}>
                  <FormTrackType required name="type" label={t('form.type')} readOnly={isTrackCreated} />
                </Grid>
              )}
              <Grid item xs={isNewTrack ? 10 : 12}>
                <FormInputText
                  autoFocus
                  label={t('form.title')}
                  name="title"
                  testId="title"
                  required
                  readOnly={isTrackCreated}
                />
              </Grid>
              <Grid item xs={isNewTrack ? 6 : 12}>
                <FormDebouncedTypeAhead
                  call={(term) => getQwireTracksArtistsCall({ term })}
                  label={t('form.artist')}
                  name="artist"
                  testId="artist"
                  noResultsText={t('form.noResults')}
                  placeholder=""
                  valueKey="name"
                  onSelect={(value) => handleSelectArtist(setFieldValue, value)}
                  onCreate={(value) => handleCreateArtists(setFieldValue, value)}
                  readOnly={isTrackCreated}
                />
              </Grid>
              {trackTypes.includes(type) && (
                <Grid item xs={6} spacing={1} className={classes.libraryInput}>
                  <FormDebouncedTypeAhead
                    call={(term) => getQwireTracksLibrariesCall({ term })}
                    label={t('form.libraryType')}
                    name="library.qwireTracksLibraryId"
                    noResultsText={t('form.noResults')}
                    placeholder=""
                    readOnly={isTrackCreated}
                  />
                </Grid>
              )}
            </Grid>
          </>
        )}
      </Form>
      {isTrackCreated && <CreateLicensors drawerName={name} setDrawerDirty={setDrawerDirty} onCancel={onClose} />}
    </>
  );
};

DrawerTitle.propTypes = {
  isNewTrack: PropTypes.bool,
  onClose: PropTypes.func,
};

CreateTrackDrawer.propTypes = {
  name: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  payload: PropTypes.shape({
    // True if creating a Track. Otherwise, you are creating a performance
    isNewTrack: PropTypes.bool,
    cueContainerId: PropTypes.number,
    title: PropTypes.string,
  }),
  closeAllDialogs: PropTypes.func.isRequired,
  fetchCueContainersLight: PropTypes.func.isRequired,
  createTrack: PropTypes.func.isRequired,
  setDrawerDirty: PropTypes.func.isRequired,
  openDrawer: PropTypes.func.isRequired,
  setSelectedTrack: PropTypes.object,
};

const Drawer = ({ open, onClose, ...props }) => (
  <BaseDrawer open={open} onClose={onClose} xlarge>
    <CreateTrackDrawer onClose={onClose} {...props} />
  </BaseDrawer>
);

Drawer.propTypes = CommonDrawerPropTypes;

export default enhancer(Drawer);
