/* eslint-disable import/no-cycle */
import makeEntityServiceCalls, { makeServiceCall } from 'redux-core/serviceHelpers';

export const getAllCueSheetVersionsCall = makeServiceCall('cue-sheet-versions', 'get-all', 'cue');

/**
 * @param {number} productionId
 * @returns {Object} The current cue sheet for the production
 */
export function getCurrentCueSheetVersionCall(productionId) {
  return makeServiceCall('cue-sheet-versions', 'get-current', 'cue')({ productionId });
}

export const updateCueSheetVersionStatusCall = makeServiceCall('cue-sheet-versions', 'assign-status', 'cue');

export const deleteCuesCall = makeServiceCall('cues', 'bulk-delete-cues', 'cue');

export const createSegmentCall = makeServiceCall('cue-sheet-segments', '', 'cue');

export const updateSegmentCall = makeServiceCall('cue-sheet-segments', 'update', 'cue');

export const duplicateSegment = makeServiceCall('cue-sheet-segments', 'duplicate', 'cue');

export const deleteSegmentCall = makeServiceCall('cue-sheet-segments', 'delete', 'cue');

export const getSegmentsByCueContainer = makeServiceCall('cue-sheet-segments', 'get-by-cue-container', 'cue');

export const changeCuePositionCall = makeServiceCall('cues', 'change-position', 'cue');

export const changeSegmentPositionCall = makeServiceCall('cues', 'change-segment-position', 'cue');

export const duplicateCueCall = makeServiceCall('cues', 'duplicate', 'cue');

export const assignRepToCueSheetCall = makeServiceCall('cue-sheets', 'assign-rep', 'cue');

export const getCueSheetRepCall = makeServiceCall('cue-sheets', 'get', 'cue');

export const exportCsvCall = makeServiceCall('cue-sheets', 'export-csv', 'cue');

export const exportPdfCall = makeServiceCall('cue-sheets', 'export-pdf', 'cue');

export const exportXlsCall = makeServiceCall('cue-sheets', 'export-xls', 'cue');

export const exportFormattedXlsxCall = makeServiceCall('cue-sheets', 'export-formatted-xlsx', 'cue');

export const exportRapidCueXMLCall = makeServiceCall('cue-sheets', 'export-rapidcue-xml', 'cue');

export const getAvailableProsCall = makeServiceCall('cue-sheet-submissions', 'get-available-pros', 'cue');

export const syncCuesGetCall = makeServiceCall('sync-clearance-cue-sheet', 'get', 'cue');

export const syncCuesSyncCall = makeServiceCall('sync-clearance-cue-sheet', 'sync', 'cue');

export const {
  getAll: getAllCuesCall,
  get: getCueCall,
  create: createCueCall,
  update: updateCueCall,
} = makeEntityServiceCalls('cues', 'cue');

export const {
  create: createCueSheetSubmissionCall,
  getAll: getAllCueSheetSubmissionsCall,
  update: updateCueSheetSubmissionsCall,
} = makeEntityServiceCalls('cue-sheet-submissions', 'cue');

export const {
  create: createCuePresetCall,
  get: getCuePresetCall,
  getAll: getAllCuePresetsCall,
  update: updateCuePresetCall,
  delete: deleteCuePresetCall,
  apply: applyCuePresetCall,
} = makeEntityServiceCalls('cue-presets', 'cue');

export const bulkUpdateUsageTypeCall = makeServiceCall('cues', 'bulk-update-usage-type', 'cue');

export const validateRapidCueSubmissionCall = makeServiceCall('rapid-cue-submissions', 'validate-submission', 'cue');

export const getResponseRapidCueSubmissionCall = makeServiceCall('rapid-cue-submissions', 'get-response', 'cue');

export const downloadResponseRapidCueSubmissionCall = makeServiceCall(
  'rapid-cue-submissions',
  'download-response',
  'cue',
);

export const downloadXMLRapidCueSubmissionCall = makeServiceCall('rapid-cue-submissions', 'download-xml', 'cue');

export const getMainCueSheetsCall = makeServiceCall('cue-sheets', 'get-all', 'cue');

export const bulkUpdateCueSheetsCall = makeServiceCall('cue-sheets', 'bulk-update', 'cue');

export const bulkSubmitCueSheetsCall = makeServiceCall('rapid-cue-submissions', 'bulk-submit', 'cue');

export const bulkExportCueSheetsCall = makeServiceCall('cue-sheets', 'export-sesac-csv', 'cue');

export const getSubmissionErrorsCall = makeServiceCall('rapid-cue-submissions', 'get-submission-errors', 'cue');

export const getAssignableUsers = makeServiceCall('cue-sheets', 'get-assignable-users', 'cue');
