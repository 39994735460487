import { handleActions } from 'redux-actions';
import { lensPath, set } from 'ramda';
import * as actions from './actions';

const defaultState = {
  activeDivisionId: '',
  activeTenantId: '',
};

const reducer = {
  [actions.setActiveDivisionId]: (state, { payload }) => set(lensPath(['activeDivisionId']), payload)(state),
  [actions.setActiveTenantId]: (state, { payload }) => set(lensPath(['activeTenantId']), payload)(state),
};

export default handleActions(reducer, defaultState);
