import withBaseDrawer from 'components/common/Drawers/withBaseDrawer';
import { connect } from 'react-redux';
import compose from 'ramda/src/compose';
import { showGenericError } from 'redux-core/global-error/actions';
import { refetchCues } from 'redux-core/cueSheet/actions';
import { getSelectedProductionIdSelector } from 'redux-core/overview/selectors';

const mapStateToProps = (state) => ({
  productionId: getSelectedProductionIdSelector(state),
});

const mapDispatchToProps = { refetchCues, showGenericError };

export default compose(connect(mapStateToProps, mapDispatchToProps), withBaseDrawer);
