/* eslint-disable react/no-children-prop */
import React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import { Text, DrawerHeader, Button, TrackType } from 'components/styleguide';
import { useRootTranslation } from 'utils/hooks';
import { concatStrings } from 'utils';
import withBaseDrawer from '../withBaseDrawer';

function BundleDrawer({ onClose, payload: { track, title, cues, artists } }) {
  const t = useRootTranslation('drawers.bundle');
  /** @Todo will be implemented when BE provides an endpoint. CLR-2347 */
  const handleUnBundle = () => undefined;
  return (
    <>
      <DrawerHeader title={t('title')} hideBackButton onClose={onClose}>
        <Button variant="destructive" testId="un-bundle" size="sm" children={t('unBundle')} onClick={handleUnBundle} />
      </DrawerHeader>
      <Grid item container>
        <Text variant="h5">{`${cues.length} ${t('cues')}`}</Text>
        <Box display="flex" width="100%" my={3}>
          <Grid item container>
            <Grid item xs={4}>
              <Text variant="caption" bold>
                {t('titleArtist')}
              </Text>
            </Grid>
            <Grid item xs={4}>
              <Text variant="caption" bold>
                {t('production')}
              </Text>
            </Grid>
            <Grid item xs={4}>
              <Text variant="caption" bold>
                {t('usage')}
              </Text>
            </Grid>
          </Grid>
        </Box>
        {cues.map((cue) => (
          <Grid item container key={cue.id}>
            <Box display="flex" width="100%" mb={2}>
              <Grid item xs={4}>
                <Box display="flex" flexDirection="row" alignItems="flex-start">
                  <Box pt={1}>
                    <TrackType fontSize="small" color="action" type={track.type} />
                  </Box>
                  <Box flexDirection="column" pl={1}>
                    <Text variant="body2">{track.title}</Text>
                    <Text variant="caption">{artists}</Text>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={4} container alignItems="center">
                <Text variant="body2">{title}</Text>
              </Grid>
              <Grid item xs={4} container alignItems="center">
                <Text variant="body2">{concatStrings(' / ')(cue.actualUseType, cue.actualUseDuration)}</Text>
              </Grid>
            </Box>
          </Grid>
        ))}
      </Grid>
    </>
  );
}
BundleDrawer.propTypes = {
  onClose: PropTypes.func,
  payload: PropTypes.shape({
    track: PropTypes.object,
    title: PropTypes.string,
    clearanceId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    artists: PropTypes.string,
  }),
};
export default withBaseDrawer(BundleDrawer);
