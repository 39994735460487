/* eslint-disable react/no-children-prop */
import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { DrawerHeader, Button, Text } from 'components/styleguide';
import { rootTranslation } from 'utils/format';
import enhancer from './enhancer';
import FileStepper from './FileStepper';

const t = rootTranslation('drawers.importCueSheet');
const tGlobal = rootTranslation('global');

const ImportCueSheetDrawer = ({ clearImportCueSheetWorkflow, fileName, goToNextStep, onClose }) => {
  const goToImportCueSheet = async () => {
    const executedWithSuccess = await goToNextStep();
    if (executedWithSuccess) {
      onClose();
    }
  };

  const handleClose = async () => {
    // Redux thunk that handles the dirty status and opens prompt if suitable
    await clearImportCueSheetWorkflow();
    onClose();
  };

  return (
    <>
      <DrawerHeader bold hideBackButton onClose={handleClose} title={t('drawerTitle')}>
        <Button
          children={tGlobal('forms.cancel')}
          onClick={handleClose}
          size="xs"
          synchronous
          testId="import-cuesheet-cancel"
          variant="destructive"
        />
        <Button
          children={tGlobal('forms.continue')}
          disabled={!fileName}
          onClick={goToImportCueSheet}
          size="sm"
          testId="import-cuesheet-continue"
          type="submit"
          variant="primary"
        />
      </DrawerHeader>
      <Grid container>
        <Box minWidth={50} maxWidth={80}>
          <Text bold children={t('hintTitle')} component="span" variant="body2" />
        </Box>
        <Box width={0.85}>
          <Text children={t(fileName ? 'templatesHint' : 'fileHint')} component="span" variant="body2" />
        </Box>
      </Grid>
      <FileStepper fileName={fileName} />
    </>
  );
};

ImportCueSheetDrawer.propTypes = {
  clearImportCueSheetWorkflow: PropTypes.func.isRequired,
  fileName: PropTypes.string,
  goToNextStep: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  payload: PropTypes.shape({}),
};

export default enhancer(ImportCueSheetDrawer);
