import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@mui/system';
import { Autocomplete, Grid, InputLabel, MenuItem, Select, Skeleton, TextField } from '@mui/material';
import { FormField } from 'components/common/Form/Fields/FormField/FormField';
import { useAtomValue, useSetAtom } from 'jotai';
import { useGetEmailQuery, useGetRepEmailsQuery } from 'services/clear/letters';
import { userDataSelector } from 'redux-core/auth/selectors';
import { UseFormReturn } from 'react-hook-form';
import { rootTranslation } from 'utils/format';
import { getPermitionedUsersEmail } from 'redux-core/users/services';
import { composeLetterAtom, TComposeLetterWriteEmailContentStepForm } from '../composeLetterStore';
import { ComposeLetterComposeEmailTextArea } from './ComposeLetterComposeEmailTextArea';
import { MissingTagsDialog } from '../MissingTagsDialog';

const t = rootTranslation('composeLetters.composeEmail');

export type TComposeLetterComposeEmailRightPanelProps = {
  formBag: UseFormReturn<TComposeLetterWriteEmailContentStepForm>;
};

export function ComposeLetterComposeEmailRightPanel({ formBag }: TComposeLetterComposeEmailRightPanelProps) {
  const [usersEmailsCC, setUsersEmailsCC] = useState(null);

  const updateComposeLetter = useSetAtom(composeLetterAtom);
  const composeLetter = useAtomValue(composeLetterAtom);
  const user = useSelector(userDataSelector);

  const { data: repEmails, isLoading: isLoadingRepEmails } = useGetRepEmailsQuery({
    clearanceBundleLicensorsIds: composeLetter.clearanceBundleLicensorsIds,
  });

  const { data: filledEmail, isLoading: isLoadingEmail } = useGetEmailQuery(
    {
      letterType: composeLetter.letterType,
      templateId: composeLetter.email.templateId,
      clearanceBundleLicensorsIds: composeLetter.clearanceBundleLicensorsIds,
      contact: composeLetter.contact,
    },
    { skip: !composeLetter.email.templateId },
  );

  useEffect(() => {
    const fetchPermitionedUsers = async () => {
      const rawUsers = await getPermitionedUsersEmail({ divisionId: composeLetter.divisionId }) as any;
      setUsersEmailsCC(rawUsers?.map((item) => item?.email));
    };
    fetchPermitionedUsers();
  }, [composeLetter.divisionId]);

  useEffect(() => {
    if (!filledEmail) return;

    updateComposeLetter((prev) => ({
      ...prev,
      email: {
        ...prev.email,
        subject: filledEmail.email.subject,
        toRecipients: filledEmail.email.to?.length ? filledEmail.email.to : prev.email.toRecipients,
        ccRecipients: filledEmail.email.cc?.length ? filledEmail.email.cc : prev.email.ccRecipients,
        bccRecipients: filledEmail.email.bcc?.length ? filledEmail.email.bcc : prev.email.bccRecipients,
        body: filledEmail.email.body,
        editorReady: true,
      },
    }));
    formBag.setValue('subject', filledEmail.email.subject);
    if (filledEmail.email.to?.length) formBag.setValue('toRecipients', filledEmail.email.to);
    if (filledEmail.email.cc?.length) formBag.setValue('ccRecipients', filledEmail.email.cc);
    if (filledEmail.email.bcc?.length) formBag.setValue('bccRecipients', filledEmail.email.bcc);
    formBag.setValue('body', filledEmail.email.body);
  }, [updateComposeLetter, filledEmail, formBag]);

  if (isLoadingEmail) return <Skeleton />;

  return (
    <>
      <MissingTagsDialog missedTags={filledEmail?.missedTags} />
      <Grid
        container
        flexDirection="column"
        gap={2}
        padding={3}
        sx={{
          backgroundColor: '#eee',
          border: '1px solid #ddd',
          height: '100%',
        }}
      >
        <Grid item>
          <FormField
            name="toRecipients"
            label={t('toLabel')}
            required
            render={({ field, muiprops }) => (
              <Autocomplete
                {...field}
                {...muiprops}
                multiple
                limitTags={6}
                selectOnFocus
                clearOnBlur
                autoSelect
                sx={{
                  backgroundColor: 'white',
                }}
                onChange={(_event, newValue: string[]) => {
                  updateComposeLetter((prev) => ({
                    ...prev,
                    email: { ...composeLetter.email, toRecipients: newValue },
                  }));
                  formBag.setValue('toRecipients', newValue);
                }}
                freeSolo
                handleHomeEndKeys
                options={[]}
                renderInput={(params) => <TextField data-testid="compose-letter-to-recipient" {...params} />}
              />
            )}
          />
        </Grid>
        <Grid item>
          <FormField
            name="ccRecipients"
            label={t('ccLabel')}
            render={({ field, muiprops }) => (
              <Autocomplete
                {...field}
                {...muiprops}
                multiple
                autoComplete
                loading={!!usersEmailsCC}
                options={usersEmailsCC}
                limitTags={6}
                selectOnFocus
                clearOnBlur
                autoSelect
                sx={{
                  backgroundColor: 'white',
                }}
                onChange={(_event, newValue: string[]) => {
                  updateComposeLetter((prev) => ({
                    ...prev,
                    email: { ...composeLetter.email, ccRecipients: newValue },
                  }));
                  formBag.setValue('ccRecipients', newValue);
                }}
                value={formBag.getValues('ccRecipients') || []}
                handleHomeEndKeys
                freeSolo
                renderInput={(params) => <TextField data-testid="compose-letter-to-cc-recipient" {...params} />}
                renderOption={(props, option) => (
                  <MenuItem {...props} sx={{ display: 'flex' }} aria-label="user email">
                    <Box sx={{ width: '87%' }}>{option}</Box>
                  </MenuItem>
                )}
              />
            )}
          />
        </Grid>
        <Grid item>
          <FormField
            name="subject"
            label={t('subjectLabel')}
            required
            render={({ field, muiprops }) => <TextField {...field} {...muiprops} />}
          />
        </Grid>
        <Grid item>
          <FormField
            name="from"
            label={t('fromLabel')}
            required
            render={({ field, muiprops }) => {
              if (isLoadingRepEmails) return <Skeleton />;
              return (
                <Select {...field} {...muiprops}>
                  {[...repEmails.clearanceRepEmails, user.userEmail].map((repEmail) => (
                    <MenuItem key={`rep-email-${repEmail}`} value={repEmail}>
                      {repEmail}
                    </MenuItem>
                  ))}
                </Select>
              );
            }}
          />
        </Grid>
        <Grid item flexGrow={1} container flexDirection="column" gap={1}>
          <Grid item>
            <InputLabel htmlFor="email-body" required>
              {t('bodyLabel')}
            </InputLabel>
          </Grid>
          <Grid item flexGrow={1}>
            <ComposeLetterComposeEmailTextArea name="body" />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
