/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import Comment from '@material-ui/icons/ChatBubbleOutline';

import { Badge } from '@material-ui/core';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import useStyles from './styles';

const Message = (props) => {
  const { newContent, customProps } = props;
  const classes = useStyles();
  return (
    <Badge
      invisible={!newContent}
      color="error"
      className={classnames(classes.badge, customProps.className)}
      {...customProps}
    >
      <Comment />
    </Badge>
  );
};

Message.propTypes = {
  newContent: PropTypes.bool,
  customProps: PropTypes.object,
};

Message.defaultProps = {
  customProps: {},
};

export default Message;
