import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import withStyles from './styles';

const SubtitleWithLine = ({ children, classes, variant }) => {
  const { t } = useTranslation();
  return (
    <>
      <Typography variant={variant} className={classes.text}>
        {t(children)}
      </Typography>
      <div className={classes.greyLine} />
    </>
  );
};

SubtitleWithLine.propTypes = {
  children: PropTypes.string.isRequired,
  variant: PropTypes.string,
};

SubtitleWithLine.defaultProps = {
  variant: 'subtitle1',
};

export default withStyles(SubtitleWithLine);
