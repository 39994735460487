import { useState } from 'react';
import { Grid, Typography, Button } from '@mui/material';
import { formatDate } from 'utils/date';
import { StaticPicker } from 'components/styleguide';
import { useRootTranslation } from 'utils/hooks';
import { LETTER_TYPES } from 'utils/typed_constants';

type TNullableDate = Date | null;

type TLetterRow = {
  type: typeof LETTER_TYPES;
  status: 'Sent' | 'Received' | 'Expires';
  readOnly?: boolean;
  canSendLetters?: boolean;
  onChange: (arg0: TNullableDate) => void;
  value: TNullableDate; // date
};

export const LetterRow = ({ type, status, readOnly = false, canSendLetters = true, onChange, value }: TLetterRow) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const rootT = useRootTranslation('letters');

  const letterTypeText = rootT(`types.${type}`);
  const displayDate = formatDate(value);

  const removeDate = () => {
    if (readOnly || anchorEl) {
      return;
    }
    onChange(null);
  };

  const handleOpen = (event) => {
    if (!canSendLetters) return;
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const closePopover = (dateValue) => {
    setAnchorEl(null);
    if (dateValue) onChange(dateValue);
  };

  return (
    <Grid container direction="row">
      <Grid item xs={2}>
        <Typography fontWeight="bold">{status}</Typography>
      </Grid>
      <Grid item xs={3}>
        {displayDate}
      </Grid>
      <Grid item xs={2}>
        {!readOnly && canSendLetters && (
          <Button variant="text" onClick={handleOpen} data-testid="button-set-date">
            {rootT('buttons.SET_DATE')}
          </Button>
        )}
      </Grid>
      <Grid item xs={2}>
        {displayDate !== '' && !readOnly && canSendLetters && (
          <Button variant="text" onClick={removeDate} color="error" data-testid="button-remove-date">
            {rootT('buttons.REMOVE_DATE')}
          </Button>
        )}
      </Grid>
      <Grid item xs={3} />
      <StaticPicker anchorEl={anchorEl} onUpdate={closePopover} label={`${letterTypeText} ${status}`} value={value} />
    </Grid>
  );
};
