/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { RadioGroup } from 'components/styleguide';
import { getIn } from 'formik';
import { useTranslation } from 'react-i18next';
import { withFormField } from 'components/common/Form/utils';

const Input = ({ disabled, label, field, form: { errors, isSubmitting, setFieldValue, touched }, ...props }) => {
  const { t } = useTranslation();
  const handleChange = (v) => setFieldValue(field.name, v);
  return (
    <RadioGroup
      disabled={disabled || isSubmitting}
      label={t(label)}
      error={t(getIn(touched, field.name) && getIn(errors, field.name))}
      {...props}
      {...field}
      onChange={handleChange}
    />
  );
};

Input.displayName = 'RadioGroup';

export default withFormField(Input, { type: 'disabled' });
