/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-expressions */
/* eslint-disable import/no-cycle */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { Text } from 'components/styleguide';
import { useRootTranslation, useLocalStorage } from 'utils/hooks';
import Collapse from '@material-ui/core/Collapse';

const Instructions = ({ instructions, rootT, cookie }) => {
  const [showInstructions, setCookie] = useLocalStorage(cookie, true);
  const [expanded, setExpanded] = useState(showInstructions);
  const t = useRootTranslation(rootT);
  const tGlobal = useRootTranslation('global.instructions');
  const onToggle = () => {
    setExpanded(!expanded);
    cookie && setCookie(!expanded);
  };
  return (
    <Grid container direction="column">
      <Collapse in={expanded} timeout={350}>
        <Grid item>
          {instructions.map(({ label, text }, index) => (
            <React.Fragment key={index}>
              <Text bold>{t(label)}</Text>
              <Text>{t(text)}</Text>
              <Box mb={2} />
            </React.Fragment>
          ))}
          <Box mb={2} />
        </Grid>
      </Collapse>
      <Grid item container spacing={1}>
        <Grid item>
          <Text color="info">
            <InfoIcon fontSize="small" />
          </Text>
        </Grid>
        <Grid item>
          <Text color="info" underlined onClick={onToggle}>
            {expanded ? tGlobal('hide') : tGlobal('show')}
          </Text>
        </Grid>
      </Grid>
    </Grid>
  );
};

Instructions.propTypes = {
  cookie: PropTypes.string,
  instructions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    }),
  ),
  rootT: PropTypes.string.isRequired,
};

Instructions.defaultProps = {
  instructions: [],
};

export default Instructions;
