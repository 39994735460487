import { translatedOptions } from 'utils/constants';

export const tracksNotExpanded = [null, null];

export const CUE_FILTERS = {
  ALL: 'all',
  ARTIST: 'artist',
  TRACKS: 'track',
  LICENSORS: 'licensor',
};
export const CUE_FILTERS_OPTIONS = translatedOptions('productionOverview.header.menu.filters')(CUE_FILTERS);
