/* eslint-disable no-bitwise */
/* eslint-disable no-use-before-define */
import countryRegionData from 'country-region-data';
import c from 'ramda/src/compose';
import find from 'ramda/src/find';
import flatten from 'ramda/src/flatten';
import map from 'ramda/src/map';
import toLower from 'ramda/src/toLower';
import prop from 'ramda/src/prop';
import is from 'ramda/src/is';
import propEq from 'ramda/src/propEq';
import toString from 'ramda/src/toString';
import { COMMON_PERMISSIONS } from 'redux-core/permissions/constants';

export const sortAlphabetically = (a, b) => {
  const lowerA = toLower(a);
  const lowerB = toLower(b);
  if (lowerA < lowerB) {
    return -1;
  }
  if (lowerA > lowerB) {
    return 1;
  }
  return 0;
};

export const sortAlphaNumeric = (a, b) => {
  // convert to strings and force lowercase
  const convertValues = map((value) => (is(String, value) ? toLower(value) : toString(value)));
  const [aConverted, bConverted] = convertValues([a, b]);

  return aConverted.localeCompare(bConverted, undefined, { numeric: true });
};

export const getStatesByCountry = (country) => {
  const currentCountry = country ? find(propEq('countryShortCode', country.code))(countryRegionData) : null;
  if (currentCountry) {
    return currentCountry.regions;
  }
  return [];
};

/**
 *
 * @param {Integer} secs
 * Takes a number representing seconds and returns the time in "HH:MM" format
 */
export const secondsToHHMM = (secs) => {
  if (!secs) return '';
  const minutes = Math.floor(secs / 60);
  const h = String(Math.floor(minutes / 60));
  const m = String(Math.floor(minutes % 60));
  const hh = h.length === 1 ? `0${h}` : h;
  const mm = m.length === 1 ? `0${m}` : m;
  return `${hh}:${mm}`;
};

export const secondsToMMSS = (seconds) => new Date(seconds * 1000).toISOString().substring(11, 19);

/**
 * MM:SS to seconds. (Also handles HH:mm:ss)
 *
 * @param {string} mmss - HH:mm:ss or mm:ss
 * @returns {int} total seconds
 */
export const MMSSToSeconds = (mmss) => mmss.split(':').reduce((acc, time) => 60 * acc + +time);

/**
 *
 * @param {String} hhmm
 * Takes a string in the 'HH:MM' format and returns the given time in seconds
 */
export const HHMMToSeconds = (hhmm) => {
  const [hours = 0, mins = 0] = timeStrToArray(hhmm || '00:00');
  return (hours * 60 + mins) * 60;
};
/**
 *
 * @param {String} str
 *
 * Takes a string in the '00:00:00' format an returns the numbers within
 * that string in an array
 */
export const timeStrToArray = (str) => str.split(':').map((x) => Number(x));

/**
 *
 * @param {String} concatenator
 * @param {Array} args
 *
 * Takes a concatenator string and as many string as you want as args
 * @example fn(" ")("Nahuel", null, "López") returns "Nahuel López"
 */
export const concatStrings =
  (concatenator) =>
  (...args) =>
    flatten(args).filter(Boolean).join(concatenator);

/**
 *
 * @param {String} str
 *
 * Takes a string as an argument and checks if the string is empty or if it's just blank spaces.
 */
export const isEmptyOrSpaces = (str) => str === null || str.match(/^ *$/) !== null;
/**
 *
 * @param {String} concatenator
 * @param {String} prop
 * @param {Array} args
 *
 * Takes a concatenator string and as many object as you want as args
 * and concatenates the object by a certain prop
 * @example fn(", ")({name: "Artist 1"}, {name: "Artist 2"}) returns "Artist 1, Artist 2"
 */
export const concatObjects =
  (concatenator, attr = 'name') =>
  (...args) =>
    c(concatStrings(concatenator), map(prop(attr)), flatten)(args);

/**
 *
 * @param {Object} object
 * @param {Array} array
 *
 * Receives and object and a flatten array and compares which are the best matches based on its keys
 */
export const bestMatch = (obj, arr) => {
  if (!arr || !arr.length) return [];
  const keys = Object.keys(obj || {});
  if (!keys.length) return arr;
  const scores = arr.map((o) => keys.reduce((acc, key) => acc + Number(o[key] === obj[key]), 0));
  const maxScore = Math.max(...scores);
  const candidates = [];
  scores.forEach((score, i) => {
    if (score === maxScore) candidates.push(arr[i]);
  });
  return candidates;
};

/*
 * @param {String} hex
 * Turns a hex string into an RGB string in 'r, g, b'  format.
 */
export const hexToRgb = (hexRaw) => {
  if (!hexRaw) return '0, 0, 0';

  const hex = hexRaw.replace('#', '');
  const rgb = parseInt(hex, 16); // convert rrggbb to decimal
  const r = (rgb >> 16) & 0xff; // extract red
  const g = (rgb >> 8) & 0xff; // extract green
  const b = (rgb >> 0) & 0xff; // extract blue
  return `${r}, ${g}, ${b}`;
};

/**
 * @param {Number} value
 * @returns {Boolean}
 * */
export const isNegative = (value) => Math.sign(value) === -1;

/**
 *
 * @param {String} hexFull
 * @param {Float} opacity
 * Takes a HEX color and an opacity value and returns it in rgba format
 */
export const hexWithOpacity = (hex, opacity) => {
  if (opacity < 0 || opacity > 1) return hex;
  return `rgba(${hexToRgb(hex)}, ${opacity})`;
};

export const getHasPermission =
  (permissions, all) =>
  (...args) => {
    const expectedPermissions = flatten(args).reduce((prev, curr) => prev | curr, 0);
    if (!expectedPermissions) return true;
    if (all) return (permissions & expectedPermissions) === expectedPermissions;
    return Boolean(permissions & expectedPermissions);
  };

/**
 *
 * @param {Object} expectedPermissions
 * @param {Object} userPermissions
 * @param {Boolean} all Indicates if the user has to have access to All of the permissions.
 */
export const hasPermissions = (
  expectedPermissions,
  userPermissions = {},
  allRequired = true,
  isQwireEmployee = false,
) => {
  if (isQwireEmployee) return true;

  const hasCommonPermissions = getHasPermission(
    userPermissions.commonPermissions,
    allRequired,
  )(expectedPermissions.commonPermissions);

  const isDivisionAdmin = getHasPermission(userPermissions.commonPermissions)(COMMON_PERMISSIONS.DIVISION_ADMIN);

  const hasCommonPermissionsOrIsDivisonAdmin = hasCommonPermissions || isDivisionAdmin;

  const hasClearPermissions = getHasPermission(
    userPermissions.clearPermissions,
    allRequired,
  )(expectedPermissions.clearPermissions);

  const hasCuePermissions = getHasPermission(
    userPermissions.cuePermissions,
    allRequired,
  )(expectedPermissions.cuePermissions);

  return hasCommonPermissionsOrIsDivisonAdmin && hasClearPermissions && hasCuePermissions;
};

/**
 * Loads script dynamically to the body of the DOM,
 * returns a promise that resolves when the script
 * loads.
 */
export const loadScriptDynamically = ({ id, url }) =>
  new Promise((resolve, reject) => {
    const existingScript = document.getElementById(id);
    if (existingScript) {
      resolve();
    } else {
      const script = document.createElement('script');
      script.onload = () => resolve();
      script.onerror = () => reject();
      script.src = url;
      script.id = id;
      document.body.appendChild(script);
    }
  });

/**
 * Removes a script tag from the DOM based on id
 */
export const removeScriptDynamically = (id) => {
  const script = document.getElementById(id);
  if (script) script.remove();
};

/**
 * @see https://reactjs.org/docs/higher-order-components.html#convention-wrap-the-display-name-for-easy-debugging
 */
export function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'WrappedComponent';
}

export const toFixedWithoutTrailingZeros = (number, decimals = 0) => Number(number.toFixed(decimals)).toString();
