/* eslint-disable import/no-cycle */
import { makeStyles } from '@material-ui/core';
import { appColors } from 'components/styleguide';

const useStyles = makeStyles((theme) => ({
  stepIcon: {
    alignItems: 'center',
    backgroundImage: 'linear-gradient(to top, #fb2c5d, #fa7437)',
    borderRadius: 16,
    color: appColors.white,
    display: 'flex',
    fontSize: 14,
    height: 24,
    justifyContent: 'center',
    width: 24,
    '&.inactive': {
      backgroundColor: theme.palette.grey[400],
      backgroundImage: 'none',
    },
  },
}));

export default useStyles;
