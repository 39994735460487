import { connect } from 'react-redux';
import { closeAllDialogs, setDrawerDirty } from 'redux-core/dialogs/actions';
import { createTrack, setSelectedTrack } from 'redux-core/tracks/actions';
import { fetchCueContainersLight } from 'redux-core/cueContainers/actions';

const mapDispatchToProps = {
  closeAllDialogs,
  createTrack,
  fetchCueContainersLight,
  setDrawerDirty,
  setSelectedTrack,
};

export default connect(null, mapDispatchToProps);
