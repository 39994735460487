/* eslint-disable react/no-children-prop */
import Grid from '@material-ui/core/Grid';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import IconButton from '@material-ui/core/IconButton';
import classnames from 'classnames';

import { useRootTranslation } from 'utils/hooks';
import { FormDropdown } from 'components/common/Form/Fields';

import { newTheme, InputText } from 'components/styleguide';
import useStyles from './styles';
import {
  GLOBAL_LICENSOR_SEARCH_OPTIONS,
  GLOBAL_SEARCH_FILTER_BY,
  GLOBAL_TRACK_SEARCH_OPTIONS,
  GLOBAL_PRODUCTION_SEARCH_OPTIONS,
} from '../../utils';

type TFilterBy = {
  value: string;
  sortBy: string;
  sortByDirection: 'ASC' | 'DESC';
};

type SearchInputProps = {
  filterBy: TFilterBy;
  disabled: boolean;
  onChangeTerm?: (e: any) => void;
  onChangeFilterBy?: (e: any) => void;
  value?: string;
};

const SearchInput = (props: SearchInputProps) => {
  const { filterBy, disabled, onChangeTerm, onChangeFilterBy, value } = props;
  const classes = useStyles();
  const t = useRootTranslation();

  let filterByOptions;
  switch (filterBy) {
    case GLOBAL_SEARCH_FILTER_BY.TRACKS:
      filterByOptions = GLOBAL_TRACK_SEARCH_OPTIONS;
      break;
    case GLOBAL_SEARCH_FILTER_BY.RIGHTS_OWNERS:
      filterByOptions = GLOBAL_LICENSOR_SEARCH_OPTIONS;
      break;
    default:
      filterByOptions = GLOBAL_PRODUCTION_SEARCH_OPTIONS;
  }

  const adornmentIcon = value ? <ClearIcon color="error" /> : <SearchIcon />;

  return (
    <div className={classnames(classes.root)}>
      <FormDropdown
        name="filters.searchBy"
        menuWidth={300}
        options={filterByOptions}
        variant="primary"
        onSelect={onChangeFilterBy}
        sx={{
          backgroundColor: newTheme.palette.primary.main,
          color: newTheme.palette.primary.contrastText,
          maxWidth: '100%',
          '& .MuiSelect-icon': {
            color: 'currentColor',
          },
          '& .MuiSelect-outlined': {
            color: 'currentColor',
            backgroundColor: newTheme.palette.primary.main,
          },
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
          },
        }}
      />
      <InputText
        hideErrorHint
        disabled={disabled}
        name="term"
        placeholder={t('global.search')}
        split="right"
        adornmentPosition="end"
        testId="advancedSearchInput"
        adornment={
          <Grid item>
            <IconButton
              className={classes.iconButton}
              color="primary"
              onClick={() => onChangeTerm({ target: { value: '' } })}
            >
              {adornmentIcon}
            </IconButton>
          </Grid>
        }
        onChange={onChangeTerm}
        value={value}
        error={false}
      />
    </div>
  );
};

export default SearchInput;
