/* eslint-disable react/no-unstable-nested-components */
import { Grid } from '@material-ui/core';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Tabs } from 'components/styleguide';
import { useRootTranslation } from 'utils/hooks';

import enhancer from './enhancer';
import useStyles from './styles';
import { GLOBAL_LICENSOR_SEARCH, GLOBAL_SEARCH_FILTER_BY, GLOBAL_TRACK_SEARCH } from '../utils';

import LicensorsAndTracksFilters from './LicensorsAndTracksFilters';
import ProductionsFilters from './ProductionsFilters';

const rootT = 'globalSearch.filters';

const tabs = [
  {
    title: 'filterBy.productions',
    filterBy: GLOBAL_SEARCH_FILTER_BY.PRODUCTIONS,
    filteringByProductions: true,
  },
  {
    title: 'filterBy.tracks',
    filterBy: GLOBAL_SEARCH_FILTER_BY.TRACKS,
    filteringByProductions: false,
    searchByDefault: GLOBAL_TRACK_SEARCH.TRACK_TITLE,
  },
  {
    title: 'filterBy.licensors',
    filterBy: GLOBAL_SEARCH_FILTER_BY.RIGHTS_OWNERS,
    filteringByProductions: false,
    searchByDefault: GLOBAL_LICENSOR_SEARCH.LICENSOR_NAME,
  },
];

const GlobalSearchFilters = ({ setAdvancedSearchFilters, setSearchItemDetails, term }) => {
  const t = useRootTranslation(rootT);
  const classes = useStyles();

  const [filterBy, setFilterBy] = useState(GLOBAL_SEARCH_FILTER_BY.PRODUCTIONS);
  const [currentTabIndex, setCurrentTabIndex] = useState(0);

  useEffect(
    () => () => {
      setAdvancedSearchFilters(null);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const currentTab = tabs[currentTabIndex];

  const FilterForm = () =>
    currentTab.filteringByProductions ? (
      <ProductionsFilters filterBy={filterBy} term={term} setAdvancedSearchFilters={setAdvancedSearchFilters} />
    ) : (
      <LicensorsAndTracksFilters
        currentTab={currentTab}
        filterBy={filterBy}
        term={term}
        setAdvancedSearchFilters={setAdvancedSearchFilters}
      />
    );

  return (
    <Grid container className={classes.root}>
      <Tabs
        onTabChange={(tabIndex) => {
          if (tabIndex !== currentTabIndex) {
            const nextTab = tabs[tabIndex];
            setAdvancedSearchFilters(null);
            setSearchItemDetails(null);
            setFilterBy(nextTab.filterBy);
            setCurrentTabIndex(tabIndex);
          }
        }}
        currentTab={currentTabIndex}
        tabList={tabs.map((tab) => t(tab.title))}
      />
      <FilterForm />
    </Grid>
  );
};

GlobalSearchFilters.propTypes = {
  setAdvancedSearchFilters: PropTypes.func.isRequired,
  term: PropTypes.string,
};

export default enhancer(GlobalSearchFilters);
