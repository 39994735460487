/* eslint-disable max-len */
import React, { useMemo, useState } from 'react';
import { Typography } from '@mui/material';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import { last, lowerFirst } from 'lodash';
import { TClearanceStatus } from 'services/clear/clearance-status';
import { Text } from 'components/styleguide';
import Paginator from 'components/common/Paginator';
import equals from 'ramda/src/equals';
import { WATCHING_TYPE } from 'components/Projects/WatchingTab/constants';
import { TProductionCueSheet } from 'components/Projects/WatchingTab/ProductionCard/ProductionCardFooter/CampaignCardFooter';
import { rootTranslation } from 'utils/format';
import { PRODUCTION_TYPES } from 'utils/constants';
import useStyles from './styles';
import ProductionTitle from './ProductionTitle';
import AssetItem from './AssetItem';
import enhancer from './enhancer';
import CampaignAssetItem from './AssetItem/CampaignAssetItem';

const t = rootTranslation('drawers.watchingAssets');

const isClearanceView = equals(WATCHING_TYPE.CLEARANCE);
const isCueSheetView = equals(WATCHING_TYPE.CUE_SHEET);

export type TAsset = {
  approvedFees: number;
  clearanceStatus: TClearanceStatus;
  creativeVendor: string;
  id: number;
  title: string;
  tracks: unknown;
  type: string;
  version: string | null;
  versionName: string | null;
  unassignedBin: boolean;
  cueSheet?: TProductionCueSheet;
};

export type TProduction = {
  assets: TAsset[];
  assetsPending: number;
  currency: string;
  path: TAsset[];
  masterCurrencyCode: string;
  cueSheet: TProductionCueSheet;
};

type WatchingAssetsDrawerProps = {
  payload: TProduction & {
    posterId: number;
    onUnwatchProduction: unknown;
  };
  watchingView: string;
};

type TProductionType = 'release' | 'filmRelease' | 'episode' | 'campaign' | 'project' | 'season' | 'seasonRelease';

export const useAssets = (assets, productionType: TProductionType, watchingView) =>
  useMemo(() => {
    if (!isCueSheetView(watchingView)) {
      return assets;
    }
    const isMarketingCampaign = productionType === PRODUCTION_TYPES.CAMPAIGN;
    return isMarketingCampaign ? assets.filter((asset) => !asset.unassignedBin) : assets;
  }, [assets, productionType, watchingView]);

export const WatchingAssetsDrawer = ({
  payload: { posterId, onUnwatchProduction, ...production },
  watchingView,
}: WatchingAssetsDrawerProps) => {
  const classes = useStyles();
  const [assetsPaginated, setDataPaginated] = useState([]);
  const isClearance = isClearanceView(watchingView);
  const isCueSheet = isCueSheetView(watchingView);

  const { assets, assetsPending, masterCurrencyCode: currency, path: productionPath } = production;
  const productionType = lowerFirst(last(productionPath)?.type) as TProductionType;
  const cueSheet = production?.cueSheet;
  const isMarketingCampaign = productionType === PRODUCTION_TYPES.CAMPAIGN;
  const assetsSanitized = useAssets(assets, productionType, watchingView);
  const totalAssets = assetsSanitized.length;

  return (
    <>
      <ProductionTitle posterId={posterId} productionPath={productionPath} unwatchProduction={onUnwatchProduction} />
      <Text bold gutterBottom variant="h4">
        {t('total', { count: totalAssets })}
      </Text>
      {isCueSheet && isMarketingCampaign ? (
        <>
          <Typography variant="body2">
            ({`${cueSheet.totalCues} ${t('cues')} | ${cueSheet.cuesWithErrors} ${t('incomplete')}`})
          </Typography>
          {assetsPaginated?.map((asset, i) => (
            <React.Fragment key={asset.id}>
              {i !== 0 && <Divider />}
              <CampaignAssetItem {...asset} />
            </React.Fragment>
          ))}
        </>
      ) : (
        <>
          <Text variant="body2">{t('pending', { count: assetsPending || 0 })}</Text>
          {assetsPaginated?.map((asset, i) => (
            <React.Fragment key={asset.id}>
              {i !== 0 && <Divider />}
              <AssetItem currency={currency} key={asset.id} {...asset} isClearance={isClearance} />
            </React.Fragment>
          ))}
        </>
      )}
      <Grid container justify="flex-end" className={classes.paginator}>
        <Paginator elements={assetsSanitized} callback={setDataPaginated} amount={30} />
      </Grid>
    </>
  );
};

export default enhancer(WatchingAssetsDrawer);
