import { useState } from 'react';
import { Autocomplete, Skeleton, TextField } from '@mui/material';
import { useDebouncedCallback } from 'use-debounce';
import { rootTranslation } from 'utils/format';
import { FormField } from 'components/common/Form/Fields/FormField/FormField';
import { useSearchTracksPartnersQuery } from 'services/tracks/tracks';

const t = rootTranslation('drawers.productionSetup.tracks.filter');

export function LibraryField() {
  const [term, setTerm] = useState('');
  const { data, isLoading } = useSearchTracksPartnersQuery({ term });

  const debouncedSetSearch = useDebouncedCallback((_, inputValue) => {
    setTerm(inputValue);
  }, 300);

  if (isLoading) {
    return <Skeleton />;
  }

  return (
    <FormField
      label={t('library')}
      name="library"
      render={({ field }) => (
        <Autocomplete
          loading={isLoading}
          value={field.value ?? ''}
          options={data.content ?? []}
          disabled={false}
          onChange={(_, newValue) => {
            field.onChange(newValue);
          }}
          renderInput={(params) => <TextField {...params} />}
          onInputChange={debouncedSetSearch}
          getOptionLabel={(option) => option?.name ?? ''}
          isOptionEqualToValue={(option, optionValue) => option.id === optionValue.id}
          data-testid="library-field"
        />
      )}
    />
  );
}
