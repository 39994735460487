/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import Cancel from '@material-ui/icons/Cancel';

import PropTypes from 'prop-types';
import classnames from 'classnames';
import StyledIconButton from '../StyledIconButton';
import useStyles from './styles';

const Clear = (props) => {
  const { customProps, onClick, size, color, iconOpacity } = props;
  const classes = useStyles({ size });
  return (
    <StyledIconButton {...customProps} onClick={onClick} className={classnames(classes.button, customProps.className)}>
      <Cancel color={color} className={classnames({ [classes.iconOpacity]: iconOpacity })} />
    </StyledIconButton>
  );
};

Clear.propTypes = {
  onClick: PropTypes.func,
  customProps: PropTypes.object,
  size: PropTypes.number.isRequired,
  color: PropTypes.string,
  iconOpacity: PropTypes.bool,
};

Clear.defaultProps = {
  color: 'secondary',
  customProps: {},
  size: 14,
};

export default Clear;
