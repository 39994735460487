/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { BaseDrawer } from 'components/styleguide';
import EditDeleteItemsList from 'components/common/EditDeleteItemsList/';
import { useRootTranslation } from 'utils/hooks';
import BreadcrumsDrawerHeader from 'components/common/BreadcrumsDrawerHeader';
import { CommonDrawerPropTypes } from '../constants';

const i18nRoot = 'drawers.productionSetup';

const FkaTitlesDrawer = ({ name, onClose, payload }) => {
  const onSubmit = (values) => payload.onSubmit({ fkaTitles: values });
  const t = useRootTranslation(i18nRoot);

  return (
    <EditDeleteItemsList
      name={name}
      CustomHeader={(props) => (
        <BreadcrumsDrawerHeader
          {...props}
          currentStep={t('fkaTitles.drawerTitle')}
          prevStep={payload.parentName || t('drawers.newProject')}
        />
      )}
      inputPlaceholderName={`${i18nRoot}.fkaTitles.placeholderEnterTitle`}
      deletePromptContent={(fka) => t('fkaTitles.delete', fka)}
      onClose={onClose}
      onSubmit={onSubmit}
      initialValues={payload.fkaTitles}
      readOnly={payload.readOnly}
    />
  );
};

FkaTitlesDrawer.propTypes = {
  name: PropTypes.string,
  onClose: CommonDrawerPropTypes.onClose,
  payload: PropTypes.object.isRequired,
};

const Drawer = ({ open, onClose, ...props }) => (
  <BaseDrawer open={open} onClose={onClose}>
    <FkaTitlesDrawer onClose={onClose} {...props} />
  </BaseDrawer>
);

Drawer.propTypes = CommonDrawerPropTypes;

export default Drawer;
