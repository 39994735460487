import { useController } from 'react-hook-form';
import Grid from '@mui/system/Unstable_Grid/Grid';
import { IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useRootTranslation } from 'utils/hooks';
import { TLicensorType, TLicensorWithOneStop } from 'services/tracks/types';
import InputFormatField from 'components/common/Form/Fields/FormInputFormat/InputFormatField';
import { FormControlLabelWrapper } from 'components/common/Form/Fields/FormField/FormField';
import { extractArrayFieldError } from 'components/common/Form/Fields/FormField/extractArrayFieldError';
import { WRITERS_COMPOSERS_TYPES_OPTIONS } from 'utils/constants';
import { RolePicker } from '../RolePicker';
import LicensorField from '../licensors/LicensorField';
import { PROPicker } from '../PROPicker';
import { normalizeLicensorOutput } from '../normalizeLicensorOutput';
import { getLicensorValue } from '../getLicensorValue';

type CreatorRowFormProps = {
  index: number;
  htmlSectionId: string;
  removeField: () => void;
  type: TLicensorWithOneStop;
};

export function CreatorRowForm({ index, removeField, htmlSectionId, type }: CreatorRowFormProps) {
  const t = useRootTranslation();
  const { field, fieldState, formState } = useController({ name: `rightsOwners.${index}` });
  const nameError = extractArrayFieldError({
    fieldState,
    formState,
    arrayFieldName: 'rightsOwners',
    index,
    name: 'name',
  });

  const licensorValue = getLicensorValue(field.value);

  return (
    <>
      <Grid xs={5}>
        <FormControlLabelWrapper name="name" error={Boolean(nameError)} helperText={t(nameError)}>
          <LicensorField
            types={[type] as TLicensorType[]}
            value={licensorValue}
            onChange={(nextValue) => {
              const result = normalizeLicensorOutput(field.value, nextValue);
              field.onChange(result);
            }}
            onCreate={(name: string) => {
              field.onChange({ ...field.value, name, qwireTracksRightsOwnerId: null, writerAffiliation: null });
            }}
            onEdit={(name) => {
              field.onChange({ ...field.value, name });
            }}
            htmlSectionId={htmlSectionId}
            hasError={Boolean(nameError)}
          />
        </FormControlLabelWrapper>
      </Grid>
      <Grid xs={2}>
        <RolePicker
          options={WRITERS_COMPOSERS_TYPES_OPTIONS}
          labelledbyId={`${htmlSectionId}-role`}
          value={field.value?.role}
          onChange={(role) => {
            field.onChange({ ...field.value, role: role.id });
          }}
        />
      </Grid>
      <Grid xs={2}>
        <PROPicker
          htmlSectionId={htmlSectionId}
          value={field.value?.writerAffiliation ?? ''}
          onChange={(pro) => {
            field.onChange({ ...field.value, writerAffiliation: pro.id });
          }}
        />
      </Grid>
      <Grid xs={2}>
        <InputFormatField
          type="percentage"
          decimalScale={2}
          name="share"
          value={field.value?.share}
          onChange={(value) => {
            field.onChange({ ...field.value, share: value });
          }}
          fullWidth
          aria-labelledby={`${htmlSectionId}-share`}
        />
      </Grid>
      <Grid xs={1}>
        <IconButton onClick={removeField} aria-label={t('global.actions.removeButton')}>
          <DeleteIcon />
        </IconButton>
      </Grid>
    </>
  );
}
