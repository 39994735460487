/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-cycle */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import ButtonBase from '@material-ui/core/ButtonBase';
import More from '@material-ui/icons/MoreHoriz';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import MenuPopover from 'components/styleguide/actionMenu/MenuPopover';
import { concatStrings } from 'utils/index';
import useStyles from './styles';

const ActionMenuButton = ({ vertical, onClick, disabled, testId, ...buttonProps }) => {
  if (vertical) {
    return (
      <IconButton
        color="primary"
        size="small"
        onClick={onClick}
        disabled={disabled}
        data-testid={concatStrings('-')('action-menu-button', testId)}
      >
        <MoreVertIcon />
      </IconButton>
    );
  }
  return (
    <ButtonBase
      {...buttonProps}
      data-testid={concatStrings('-')('action-menu-button', testId)}
      onClick={onClick}
      disabled={disabled}
    >
      <More />
    </ButtonBase>
  );
};

ActionMenuButton.propTypes = {
  vertical: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  testId: PropTypes.string,
};

const ActionMenu = (props) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const openMenu = (event) => {
    if (event.stopPropagation) event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const { disabled, customProps, options, big, vertical, compact, CustomMenuButton, shrinkMenu, testId } = props;
  const { buttonProps, popoverProps } = customProps;
  return (
    <>
      {CustomMenuButton ? (
        <CustomMenuButton onClick={openMenu} />
      ) : (
        <ActionMenuButton
          tabIndex={-1}
          disabled={disabled}
          className={classnames(classes.actions, { disabled, big, compact })}
          onClick={openMenu}
          vertical={vertical}
          testId={testId}
          {...buttonProps}
        />
      )}
      <MenuPopover
        open={Boolean(anchorEl)}
        onClose={closeMenu}
        anchorEl={anchorEl}
        closeMenu={closeMenu}
        popoverProps={popoverProps}
        options={options}
        shrink={shrinkMenu}
      />
    </>
  );
};

ActionMenu.propTypes = {
  big: PropTypes.bool,
  CustomMenuButton: PropTypes.any,
  customProps: PropTypes.shape({
    buttonProps: PropTypes.any,
    popoverProps: PropTypes.any,
  }),
  disabled: PropTypes.bool,
  options: PropTypes.array.isRequired,
  vertical: PropTypes.bool,
  compact: PropTypes.bool,
  shrinkMenu: PropTypes.bool,
};

ActionMenu.defaultProps = {
  big: false,
  disabled: false,
  customProps: {
    buttonProps: {},
    popoverProps: {},
  },
  options: [],
};

export default ActionMenu;
