import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    '& svg': {
      color: theme.palette.text.secondary,
      height: '24px',
    },
  },
  badge: {
    '& > span': {
      width: '13.7px',
      height: '13.7px',
      minWidth: 'auto',
      top: '8px',
      right: '3px',
    },
  },
}));

export default useStyles;
