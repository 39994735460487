/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { SearchInput } from 'components/styleguide';
import Box from '@material-ui/core/Box';
import { rootTranslation } from 'utils/format';
import { useDebounce, useFetch, useRootTranslation } from 'utils/hooks';
import {
  deleteCueSheetTemplate,
  getAllCueSheetTemplates,
  likeCueSheetTemplate,
} from 'redux-core/import-cuesheet/services';
import InfiniteScroll from 'components/common/InfiniteScroll';
import { DEFAULT_PAGINATION_RESPONSE } from 'utils/constants';
import equals from 'ramda/src/equals';
import { requestWithError } from 'utils/request';
import Template from './Template';
import enhancer from './enhancer';

const t = rootTranslation('drawers.importCueSheet.notifications');

const getSuccessMessage = (key, params) => ({
  message: { success: t(key, params) },
});

const isFileExtEDL = (fileName) => {
  const ext = fileName.split('.').pop().toUpperCase();
  return ext === 'EDL';
};

const isEDLTemplate = (f) => f.name === 'EDL';

const TEMPLATES_LIMIT = 15;

const DEFAULT_TEMPLATE = {
  locked: true,
  name: rootTranslation('drawers.importCueSheet')('noTemplate'),
};

const Templates = React.memo(
  ({ templates, templateId, fileName, ...actions }) => (
    <>
      <Template
        {...DEFAULT_TEMPLATE}
        {...actions}
        selected={!templateId}
        disabled={fileName && isFileExtEDL(fileName)}
      />
      {templates.map((props) => (
        <Template {...props} {...actions} key={props.id} selected={props.id === templateId} />
      ))}
    </>
  ),
  (prev, next) => equals(prev.templates, next.templates) && prev.templateId === next.templateId,
);

const TemplatesList = ({ divisionId, openPromptAsync, setTemplateId, templateId, fileName }) => {
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search);
  const globalT = useRootTranslation('global');

  const [{ data: templates, pagination }, , getTemplates] = useFetch(
    async (payload) => {
      if (!payload?.limit || !payload.divisionId) return false;
      const response = await getAllCueSheetTemplates(payload);
      if (!payload?.page) return response;

      return { ...response, data: templates.concat(response.data) };
    },
    [],
    DEFAULT_PAGINATION_RESPONSE,
  );

  const [importTemplates, setImportTemplates] = useState(templates);

  useEffect(() => {
    if (fileName) {
      if (isFileExtEDL(fileName)) {
        const edlTemplateId = templates.find(isEDLTemplate)?.id;
        setTemplateId(edlTemplateId);
      }

      const templatesWithDisabled = templates.map((template) => ({
        ...template,
        disabled: isFileExtEDL(fileName) ? !isEDLTemplate(template) : template.locked,
      }));

      setImportTemplates(templatesWithDisabled);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileName, templates]);

  const filters = { term: debouncedSearch, divisionId };

  const refetchTemplates = () =>
    getTemplates({
      ...filters,
      limit: (pagination.page + 1) * pagination.size,
      page: 0,
    });

  const onDelete = async ({ id, name }) => {
    await requestWithError(
      async () => {
        await openPromptAsync({ content: t('remove', { name }) });
        await deleteCueSheetTemplate({ id });
        // If it was the selected template, set selected template back to undefined
        if (id === templateId) await setTemplateId(undefined);
        await refetchTemplates();
      },
      undefined,
      getSuccessMessage('removed'),
    );
  };

  const onFav = async (payload) => {
    await requestWithError(
      async () => {
        await likeCueSheetTemplate(payload);
        await refetchTemplates();
      },
      undefined,
      getSuccessMessage(payload.like ? 'addFav' : 'removeFav'),
    );
  };

  return (
    <>
      <SearchInput
        adornmentPosition="end"
        autoComplete="off"
        clearable
        onChange={setSearch}
        placeholder={globalT('searchPlaceholder')}
        value={search}
        variant="search"
      />
      <Box height="calc(100vh - 420px)" mt={2} overflow="auto" width={1}>
        <InfiniteScroll filters={filters} id="import-templates" limit={TEMPLATES_LIMIT} onScroll={getTemplates}>
          <Templates
            templates={importTemplates}
            onDelete={onDelete}
            onFav={onFav}
            onSelect={setTemplateId}
            templateId={templateId}
            fileName={fileName}
          />
        </InfiniteScroll>
      </Box>
    </>
  );
};

TemplatesList.propTypes = {
  divisionId: PropTypes.number.isRequired,
  openPromptAsync: PropTypes.func.isRequired,
  setTemplateId: PropTypes.func.isRequired,
  templateId: PropTypes.number,
  fileName: PropTypes.string,
};

Templates.propTypes = {
  onDelete: PropTypes.func.isRequired,
  onFav: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  templateId: PropTypes.number,
  templates: PropTypes.array.isRequired,
  fileName: PropTypes.string.isRequired,
};

export default enhancer(TemplatesList);
