import { Box, Tab, Tabs } from '@mui/material';
import { DrawerHeader } from 'components/styleguide';
import { useState } from 'react';
import { rootTranslation } from 'utils/format';
import withBaseDrawer from '../withBaseDrawer';
import { ClearanceTab } from './ClearanceTab';
import { CueSheetTab } from './CueSheetTab';

export type TCustomStatusDrawerProps = {
  drawerTitle: string;
  onClose: () => void;
};

const t = rootTranslation('drawers.customStatuses');

export const CustomStatusDrawer = withBaseDrawer(({ payload: { divisionId } }) => {
  const [currentTab, setCurrentTab] = useState<'clear' | 'cue'>('clear');

  return (
    <>
      <DrawerHeader title={t('title')} />
      <Tabs
        value={currentTab}
        onChange={(_, newTab) => setCurrentTab(newTab)}
        textColor="inherit"
      >
        <Tab value="clear" label="Licensing" sx={{ color: 'inherit' }} />
        <Tab value="cue" label="Cue Sheets" sx={{ color: 'inherit' }} />
      </Tabs>
      <Box pt={4}>
        {currentTab === 'cue' && <CueSheetTab divisionId={divisionId} />}
        {currentTab === 'clear' && <ClearanceTab divisionId={divisionId} />}
      </Box>
    </>
  );
});
