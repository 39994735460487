import { compose } from 'redux';
import { connect } from 'react-redux';
import { getDivisionId } from 'redux-core/productions/selectors';
import { getMaterialTypes } from 'redux-core/rights/actions';
import { getSelectedProductionIdSelector } from 'redux-core/overview/selectors';
import { createCampaignAsset, getCampaignAsset, updateCampaignAsset } from 'redux-core/cueContainers/assets/actions';
import { withFormConnected } from 'components/styleguide/HOC/index';
import { getDialogRightsByNameSelector } from 'redux-core/dialogs/selectors';
import withBaseDrawer from '../withBaseDrawer';
// eslint-disable-next-line import/no-named-as-default
import DRAWERS from '../constants';

const mapStateToProps = (state, ownProps) => ({
  campaignId: getSelectedProductionIdSelector(state),
  divisionId: getDivisionId(state),
  rights: getDialogRightsByNameSelector(state, ownProps.name),
});

const mapDispatchToProps = {
  createCampaignAsset,
  getCampaignAsset,
  getMaterialTypes,
  updateCampaignAsset,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  (c) => withBaseDrawer(c, { xlarge: true }),
  (c) => withFormConnected(c, { formId: DRAWERS.PRODUCTION_SETUP_ASSETS }),
);
