/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-no-constructed-context-values */
import React from 'react';
import PropTypes from 'prop-types';
import MuiToggleButtonGroup from '@mui/material/ToggleButtonGroup';
// eslint-disable-next-line import/no-cycle
import { buttonSizes } from 'components/styleguide/Button/index';
import keys from 'ramda/src/keys';
import propOr from 'ramda/src/propOr';

const defaultContext = { disabled: undefined, size: undefined };
export const ToggleButtonGroupContext = React.createContext(defaultContext);

const ToggleButtonGroup = ({ disabled, size, ...props }) => (
  <ToggleButtonGroupContext.Provider value={{ disabled, size }}>
    <MuiToggleButtonGroup {...props} color="secondary" />
  </ToggleButtonGroupContext.Provider>
);

ToggleButtonGroup.propTypes = {
  size: PropTypes.oneOf([...keys(buttonSizes), 'auto']),
  disabled: PropTypes.bool,
};

ToggleButtonGroup.defaultProps = {
  exclusive: true,
  size: propOr('md', 'md', buttonSizes),
};

export default ToggleButtonGroup;
