import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { rootTranslation } from 'utils/format';

const t = rootTranslation('drawers.productionSetup.tracks.createTrack');

export function NoResults() {
  return (
    <Box sx={{ display: 'grid', placeContent: 'center', mt: '3rem' }}>
      <Typography variant="body2" color="textSecondary">
        {t('scenePicker.noResults')}
      </Typography>
    </Box>
  );
}
