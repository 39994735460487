/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import ArrowBack from '@material-ui/icons/ArrowBack';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import useStyles from './styles';

const Back = ({ size }) => {
  const classes = useStyles({ size });
  return <ArrowBack color="primary" className={classnames(classes.button)} />;
};

Back.propTypes = {
  onClick: PropTypes.func,
  customProps: PropTypes.object,
  size: PropTypes.number.isRequired,
};

Back.defaultProps = {
  customProps: {},
  size: 16,
};

export default Back;
