/* eslint-disable react/no-children-prop */
import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { Text } from 'components/styleguide';
import DeleteIcon from '@material-ui/icons/Delete';
import FavIcon from '@material-ui/icons/Favorite';
import FavBorderIcon from '@material-ui/icons/FavoriteBorder';
import LockIcon from '@material-ui/icons/LockOutlined';
import classnames from 'classnames';
import useStyles from './styles';

const Template = ({ id, liked, locked, name, onDelete, onFav, onSelect, selected, disabled }) => {
  const classes = useStyles();

  return (
    <Grid
      alignContent="space-between"
      alignItems="center"
      className={classnames(classes.root, { selected, disabled })}
      container
      onClick={selected || disabled ? undefined : () => onSelect(id)}
      data-testid="cuesheet-template"
    >
      <Grid item xs={10}>
        <Text children={name} noWrap />
      </Grid>
      <Grid item xs={2} container justify="flex-end" onClick={(e) => e.stopPropagation()}>
        {locked ? (
          <LockIcon
            className={classnames(classes.icon, { visible: true })}
            color="action"
            data-testid="lock-template"
          />
        ) : (
          <>
            <DeleteIcon
              className={classes.icon}
              color="primary"
              data-testid="remove-template"
              onClick={() => onDelete({ id, name })}
            />
            {liked ? (
              <FavIcon
                className={classnames(classes.icon, { visible: true })}
                color="primary"
                data-testid="unlike-template"
                onClick={() => onFav({ id, like: false })}
              />
            ) : (
              <FavBorderIcon
                className={classes.icon}
                color="primary"
                data-testid="like-template"
                onClick={() => onFav({ id, like: true })}
              />
            )}
          </>
        )}
      </Grid>
    </Grid>
  );
};

Template.propTypes = {
  id: PropTypes.number,
  liked: PropTypes.bool,
  locked: PropTypes.bool,
  name: PropTypes.string,
  onDelete: PropTypes.func.isRequired,
  onFav: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  selected: PropTypes.bool,
};

export default Template;
