import { atom } from 'jotai';
import { TProject } from 'services/common/projects';

export type TCueSheetAndParentProduction = { parentProduction: number; cueSheetId: number };

export type TDivision = {
  id: number;
  name: string;
  tenantId: number;
  type: 'marketing' | 'television' | 'features';
};

export type TInitArguments = {
  divisionId: number;
  type: TDivision['type'];
  tenantId: number;
};

export type TFormStep = keyof typeof DrawerSteps;

export type TProductionSeason = {
  id: number;
  name: string;
  masterCurrencyId: number;
  rightsName: string;
  categoryId: number;
  duration: number;
};

export const cueSheetAndParentProductionAtom = atom<TCueSheetAndParentProduction>({
  parentProduction: null,
  cueSheetId: null,
});

export const projectNameAtom = atom<string>('');

export const initialValuesAtom = atom({});

export const formStepAtom = atom<TFormStep | false>(false);

export const buttonEnabledAtom = atom<boolean>(false);

export const submittingFormAtom = atom<boolean>(false);

export const existingProjectAtom = atom<TProject | false>(false);

export const DrawerSteps = {
  SelectProductionType: 'SelectProductionType',
  SelectProject: 'SelectProject',
  InputProductionDetails: 'InputProductionDetails',
  SelectWhereToGo: 'SelectWhereToGo',
  ChooseCueSheet: 'ChooseCueSheet',
} as const;
