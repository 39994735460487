import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
  popover: {
    maxWidth: '10vw !important',
    minWidth: '410px !important',
    overflow: 'hidden',
    padding: theme.spacing(3),
    '&.fullSize': {
      minWidth: '50vw !important',
    },
  },
}));

export default styles;
