/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import Tv from '@material-ui/icons/LiveTvOutlined';
import Film from '@material-ui/icons/LocalMovies';
import Cue from '@material-ui/icons/MusicNote';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Marketing from '../Marketing';
import Project from '../Project';
import useStyles from './styles';

const types = {
  film: Film,
  filmRelease: Film,
  movie: Film,
  seriesRelease: Tv,
  episode: Tv,
  seriesSeason: Tv,
  tv: Tv,
  television: Tv,
  cue: Cue,
  marketing: Marketing,
  campaign: Marketing,
  project: Project,
  features: Film,
};

const getIcon = (type) => types[type] || Tv;

const TypeIcon = (props) => {
  const { customProps, type, size, color } = props;
  const classes = useStyles({ size, color });
  const Component = getIcon(type);
  return (
    <span {...customProps} className={classnames(classes.button, customProps.classnames, classes.wrapper)}>
      <Component />
    </span>
  );
};

TypeIcon.propTypes = {
  type: PropTypes.oneOf(Object.keys(types)),
  customProps: PropTypes.object,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  color: PropTypes.string,
};

TypeIcon.defaultProps = {
  customProps: {},
  size: 16,
  type: 'tv',
};

export default TypeIcon;
