/* eslint-disable react/no-children-prop */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import { Text, DrawerHeader } from 'components/styleguide';
import { Breadcrumbs, Link } from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

function BreadcrumsDrawerHeader({ currentStep, onClose, prevStep, ...props }) {
  return (
    <DrawerHeader
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      hideBackButton
      title={
        <Breadcrumbs separator={<NavigateNextIcon />}>
          <Link color="inherit" onClick={onClose}>
            <Text semibold variant="drawerTitle" children={prevStep} noWrap />
          </Link>
          <Text children={currentStep} color="textPrimary" semibold variant="drawerTitle" />
        </Breadcrumbs>
      }
    />
  );
}

BreadcrumsDrawerHeader.propTypes = {
  currentStep: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  prevStep: PropTypes.string.isRequired,
};

export default BreadcrumsDrawerHeader;
