/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { Avatar } from '@material-ui/core';
import Placeholder from 'assets/placeholder.png';
import PropTypes from 'prop-types';

const AlternativeImage = (props) => {
  const { alt, className, style, onClick, avatar, fallback } = props;
  // TODO: style props may be removed
  return (
    fallback ||
    (avatar ? (
      <Avatar src={Placeholder} className={className} style={style} />
    ) : (
      <img alt={alt} src={Placeholder} style={style} onClick={onClick} />
    ))
  );
};

AlternativeImage.propTypes = {
  /** Used if no file.name sent */
  alt: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func,
  avatar: PropTypes.bool,
};

export default AlternativeImage;
