/* eslint-disable import/no-cycle */
import { handleActions } from 'redux-actions';
import { lensPath, set } from 'ramda';
import * as actions from './actions';

const defaultState = {
  projects: [],
};

const projectsReducer = {
  [actions.clearProjectHierarchy]: () => defaultState,
  [actions.setProjectsAfterKey]: (state, { payload }) => set(lensPath(['afterKey']), payload)(state),
  [actions.setProjectsPageCount]: (state, { payload }) => set(lensPath(['pageCount']), payload)(state),
  [actions.setProjects]: (state, { payload }) => set(lensPath(['projects']), payload)(state),
};

export default handleActions(projectsReducer, defaultState);
