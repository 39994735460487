/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable consistent-return */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import { FormInputPropTypes } from 'components/styleguide/fields/types';
import { IconButton } from '@material-ui/core';
import useStyles from './styles';
import { ADORNMENT_POSITION } from '../HOC/withAdornments';
import InputText from '../fields/InputText';

const DefaultAdornment = ({ onSearch, ...props }) => {
  const classes = useStyles(props);

  // Prevents input from blur on icon click
  const handleMouseDownSearch = (event) => event.preventDefault();
  return (
    <SearchIcon
      onClick={onSearch}
      {...(props.color ? { color: props.color } : { className: classes.icon })}
      onMouseDown={handleMouseDownSearch}
      data-testid="default-adornment"
    />
  );
};

DefaultAdornment.displayName = 'DefaultAdornment';

const SearchInput = ({
  adornmentPosition = ADORNMENT_POSITION.END,
  autoComplete,
  children,
  clearable,
  color,
  endAdornment,
  handleSearch,
  onChange,
  onFocus,
  startAdornment,
  value,
  testId,
  ...props
}) => {
  const handleChange = (event) => onChange(event.target.value);

  const onKeyPress = (event) => {
    if (Boolean(handleSearch) && event.key === 'Enter') {
      handleSearch(value);
      return false;
    }
  };

  const Adornment = <DefaultAdornment onSearch={() => handleSearch(value)} color={color} />;

  const adornment =
    clearable && value ? (
      <IconButton data-testid="search-clear" onClick={() => onChange('')} size="small">
        <ClearIcon color="error" />
      </IconButton>
    ) : (
      startAdornment || endAdornment || Adornment
    );

  return (
    <>
      <InputText
        adornment={adornment}
        adornmentPosition={adornmentPosition}
        fullWidth
        onChange={handleChange}
        onFocus={() => onFocus(value)}
        onKeyPress={onKeyPress}
        value={value || ''}
        testId={testId}
        inputProps={{
          autoComplete,
        }}
        {...props}
      />
      {children}
    </>
  );
};

SearchInput.displayName = 'SearchInput';
SearchInput.propTypes = {
  ...FormInputPropTypes,
  children: PropTypes.node,
  clearable: PropTypes.bool,
  color: PropTypes.oneOf(['primary', 'secondary']),
  handleSearch: PropTypes.func,
  adornmentPosition: PropTypes.oneOf(Object.values(ADORNMENT_POSITION)),
  startAdornment: PropTypes.oneOfType([PropTypes.node, PropTypes.bool]),
  endAdornment: PropTypes.oneOfType([PropTypes.node, PropTypes.bool]),
};

SearchInput.defaultProps = {
  name: 'search-input',
  onFocus: () => undefined,
  onBlur: () => undefined,
  autoComplete: 'on',
};

export default SearchInput;
