/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Grid } from '@material-ui/core';
import Checkbox from '../../checkbox/Checkbox';
import useStyles from './styles';

const InputCheckbox = ({ name, small, disabled, label, noWrap, readOnly, gridProps, ...props }) => {
  const classes = useStyles({ disabled });
  return (
    <Grid {...gridProps}>
      <Checkbox {...props} name={name} small={small} disabled={disabled} label={label} readOnly={readOnly} />
      <Typography
        htmlFor={name}
        className={classes.label}
        color="textSecondary"
        component="label"
        variant={small ? 'overline' : 'subtitle2'}
        data-testid="input-checkbox-label"
        noWrap={noWrap}
        style={{ maxWidth: noWrap ? '75%' : 'initial' }}
      >
        {!readOnly ? label : ''}
      </Typography>
    </Grid>
  );
};

InputCheckbox.defaultProps = {
  gridProps: {
    container: true,
    alignItems: 'center',
  },
};

InputCheckbox.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  gridProps: PropTypes.object,
  readOnly: PropTypes.bool,
  noWrap: PropTypes.bool,
  small: PropTypes.bool,
};

export default InputCheckbox;
