import { compose } from 'redux';
import { connect } from 'react-redux';
import { getSelectedSelector, getProjectSelector } from 'redux-core/overview/selectors';
import prop from 'ramda/src/prop';
import withBaseDrawer from 'components/common/Drawers/withBaseDrawer';

const mapStateToProps = (state) => ({
  productionName: compose(prop('name'), getSelectedSelector)(state),
  projectName: compose(prop('name'), getProjectSelector)(state),
});

export default compose(connect(mapStateToProps, null), withBaseDrawer);
