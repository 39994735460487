/* eslint-disable jsx-a11y/anchor-is-valid */
import { Box, Link, TableCell, TableRow } from '@material-ui/core';
import ArchiveIcon from '@material-ui/icons/ArchiveOutlined';

import ROUTES from 'components/Routes/routes';
import { CLEAR_PERMISSIONS, COMMON_PERMISSIONS } from 'redux-core/permissions/constants';
import { getPermissionSelector } from 'redux-core/permissions/selectors';
import { useSelector } from 'react-redux';
import { getIsQwireEmployeeSelector } from 'redux-core/auth/selectors';
import { hasPermissions } from 'utils';

import { useTranslation } from 'react-i18next';
import { WatchingToggle } from './WatchingToggle';
import SearchDetailsSplitButton from './SearchDetailsSplitButton';
import useStyles from './styles';

export function TVDetailsEpisode({
  closeAllDialogs,
  episode,
  goToLink,
  keepSetupInfo,
  openModal,
  normalizedProdType,
  refetchWatching,
  selected,
  toggleWatchingProduction,
}) {
  const { t } = useTranslation();
  const { productionObjectId } = selected;
  const userPermissionsOnObject = useSelector(getPermissionSelector(productionObjectId));
  const isQwireEmployee = useSelector(getIsQwireEmployeeSelector);

  const hasClearPermission = hasPermissions(
    { clearPermissions: CLEAR_PERMISSIONS.CAN_ACCESS_CLEAR },
    userPermissionsOnObject,
    true,
    isQwireEmployee,
  );

  const { episodeRow, episodeLink: episodeLinkClassName } = useStyles();
  const mainAction = async () => {
    const productionView = hasClearPermission ? ROUTES.PRODUCTION.OVERVIEW : ROUTES.PRODUCTION.CUE_SHEET;
    await goToLink(productionView, {
      id: episode.id,
      type: normalizedProdType,
      divisionId: selected.divisionId,
      projectId: selected.prodArray[0].id,
    });
    return closeAllDialogs();
  };

  return (
    <TableRow className={episodeRow} data-testid={`episode-number-${episode.number}`}>
      <TableCell
        style={{
          verticalAlign: 'bottom',
        }}
      >
        <WatchingToggle
          projectId={selected.prodArray[0].id}
          productionId={episode.id}
          toggleWatchingProduction={toggleWatchingProduction}
          refetchWatching={refetchWatching}
        />
      </TableCell>
      <TableCell
        style={{
          width: '25px',
          textAlign: 'center',
        }}
      >
        {episode.number}
      </TableCell>
      <TableCell data-testid="episode-name">
        <Link className={episodeLinkClassName} onClick={mainAction}>
          {episode.name}
        </Link>
      </TableCell>
      <TableCell>
        {!episode.active && (
          <Box sx={{ mx: 1, width: '100%' }} display="flex" justifyContent="center" alignItems="center">
            <ArchiveIcon /> {t('episodeDetail.inactive')}
          </Box>
        )}
      </TableCell>
      <TableCell
        style={{
          width: '100px',
          textAlign: 'center',
        }}
      >
        <SearchDetailsSplitButton
          tenantId={selected.tenantId}
          productionType={selected.productionType}
          projectId={selected.prodArray[0].id}
          productionId={episode.id}
          productionActive={episode.active}
          objectId={selected.productionObjectId}
          divisionId={selected.divisionId}
          keepSetupInfo={keepSetupInfo}
          openModal={openModal}
          variant="secondary"
          size="xs"
          mainLabel="options.view"
          mainAction={mainAction}
          mainPermissions={COMMON_PERMISSIONS.CREATE}
          isEpisode
        />
      </TableCell>
    </TableRow>
  );
}
