/* eslint-disable react/no-children-prop */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import { StepIcon, Button } from 'components/styleguide';
import { StepConnector } from '@material-ui/core';
import { TextField } from '@mui/material';
import { useSelector } from 'react-redux';
import { styled } from '@mui/system';
import { rootTranslation } from 'utils/format';
import { getImportFileErrorSelector } from 'redux-core/import-cuesheet/selectors';
import FileInput from './FileInput';
import TemplatesList from './TemplatesList';
import enhancer from './enhancer';
import useStyles from './styles';

// i18n keys
const STEPS = ['yourFile', 'matchingMethod'];

const t = rootTranslation('drawers.importCueSheet');

const StyledTextInput = styled(TextField)({
  '& .MuiFormHelperText-root': {
    fontSize: '0.875rem',
  },
});

const useImportErrorMessage = () => {
  const errorTag = useSelector(getImportFileErrorSelector);
  return errorTag ? t(errorTag) : null;
};

const FileStepper = ({ fileName, removeImportedFile }) => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const errorMessage = useImportErrorMessage();

  useEffect(() => {
    setActiveStep(fileName ? 1 : 0);
  }, [fileName]);

  return (
    <Stepper
      activeStep={activeStep}
      orientation="vertical"
      classes={{ root: classes.stepperRoot }}
      connector={<StepConnector classes={{ line: classes.line }} />}
    >
      {STEPS.map((label, index) => (
        <Step key={label}>
          <StepLabel classes={{ label: classes.stepLabel }} StepIconComponent={StepIcon}>
            {t(label)}
          </StepLabel>
          <StepContent classes={{ root: classes.line, last: classes.lastStep }}>
            {index === 0 ? <FileInput /> : <TemplatesList />}
          </StepContent>
          {activeStep === 1 && index === 0 && (
            <div className={classes.inputContainer}>
              <StyledTextInput disabled defaultValue={fileName} error={!!errorMessage} helperText={errorMessage} />
              <Button
                children={t('removeFile')}
                testId="remove-file"
                onClick={removeImportedFile}
                size="xs"
                variant="destructive"
              />
            </div>
          )}
        </Step>
      ))}
    </Stepper>
  );
};

FileStepper.propTypes = {
  fileName: PropTypes.string,
  removeImportedFile: PropTypes.func.isRequired,
};

export default enhancer(FileStepper);
