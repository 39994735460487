/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-unused-expressions */
import React, { useMemo } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { find, propEq } from 'ramda';
import { FormInputPropTypes } from 'components/styleguide/fields/types';
import withFormControl from 'components/styleguide/fields/withFormControl';

const RadioButton = ({
  customComponent,
  fullWidth,
  label,
  labelKey,
  name,
  onBlur,
  onChange,
  options,
  value,
  valueKey,
  valueType,
  horizontal,
}) => {
  const mappedOptions = useMemo(
    () =>
      options.map((opt) => ({
        value: opt[valueKey],
        label: opt[labelKey],
      })),
    [options, labelKey, valueKey],
  );

  const getLabel = (value, label) =>
    customComponent ? customComponent(find(propEq(valueKey, value))(options)) : label;

  const handleChange = (e) => {
    valueType === 'number' ? onChange(Number(e.target.value)) : onChange(e.target.value);
  };

  return (
    <RadioGroup
      aria-label={label}
      name={name}
      onBlur={onBlur}
      onChange={handleChange}
      value={value || ''}
      row={horizontal}
    >
      {mappedOptions.map(({ value, label }) => (
        <FormControlLabel
          classes={{ label: classnames({ fullWidth }) }}
          control={<Radio color="primary" />}
          key={value}
          label={getLabel(value, label)}
          value={value}
        />
      ))}
    </RadioGroup>
  );
};

RadioButton.propTypes = {
  ...FormInputPropTypes,
  customComponent: PropTypes.func,
  labelKey: PropTypes.string,
  options: PropTypes.array.isRequired,
  valueKey: PropTypes.string,
  valueType: PropTypes.oneOf(['number', 'string']),
  horizontal: PropTypes.bool,
};

RadioButton.defaultProps = {
  labelKey: 'name',
  options: [],
  valueKey: 'id',
  valueType: 'number',
  horizontal: false,
};

export default withFormControl(RadioButton);
