/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable import/no-cycle */
import React, { lazy, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import always from 'ramda/src/always';
import cond from 'ramda/src/cond';
import equals from 'ramda/src/equals';
import map from 'ramda/src/map';
import prop from 'ramda/src/prop';
import { useLocation } from 'react-router';
import { useHistory, useParams, Redirect } from 'react-router-dom';

import ROUTES from 'components/Routes/routes';

import { getAllClearancesCall } from 'redux-core/clearances/services';
import { isEmptyObject } from 'utils/object';
import { useFetch } from 'utils/hooks';
import ViewsContainer from 'components/Routes/Layout/ViewsContainer';
import enhancer from './enhancer';
import BatchStepper from './BatchStepper';

const AddClearances = lazy(() => import('components/Letters/AddClearances'));
const ReviewQueue = lazy(() => import('components/Letters/ReviewQueue'));
const SendLetter = lazy(() => import('components/Letters/SendLetter'));

export const STEPS = {
  CLEARANCES: 'clearances',
  REVIEW: 'review',
  SEND: 'send',
};

const STEPS_NUMBERS = {
  [STEPS.CLEARANCES]: 0,
  [STEPS.REVIEW]: 1,
  [STEPS.SEND]: 2,
};

const CurrentStep = ({ step }) =>
  cond([
    [equals(STEPS.CLEARANCES), always(<AddClearances step={step} />)],
    [equals(STEPS.REVIEW), always(<ReviewQueue step={step} />)],
    [equals(STEPS.SEND), always(<SendLetter />)],
  ])(step);

const Letters = ({ cleanLetters, setFilter }) => {
  const { step } = useParams();
  const { state } = useLocation();
  const { push } = useHistory();
  const [trackId, setTrackId] = useState(null);
  const [clearances] = useFetch(() => trackId && getAllClearancesCall({ trackId }), [trackId]);

  useEffect(() => {
    if (!isEmptyObject(state)) {
      setTrackId(state.trackId);
      setFilter({
        ...state,
        clearanceIds:
          state.clearanceIds && state.clearanceIds.length ? state.clearanceIds : map(prop('id'))(clearances),
      });
    }
  }, [clearances, setFilter, state]);

  useEffect(() => () => cleanLetters(), [cleanLetters]);

  const onChange = (step) => {
    if (step === 0) push(STEPS.CLEARANCES, state);
    else if (step === 1) push(STEPS.REVIEW, state);
    else push(STEPS.SEND, state);
  };

  if (!Object.values(STEPS).includes(step)) {
    return <Redirect to={{ pathname: ROUTES.LETTERS.CLEARANCES, state }} />;
  }
  const shouldShowQueuedCounter = step === STEPS.SEND;
  return (
    <ViewsContainer display="flex" flexDirection="column" minHeightThemeMinus={24} pl={1.5} pr={2.5}>
      <BatchStepper onChange={onChange} step={STEPS_NUMBERS[step]} shouldShowQueuedCounter={shouldShowQueuedCounter} />
      <Box flexGrow={1} display="flex">
        <CurrentStep step={step} />
      </Box>
    </ViewsContainer>
  );
};

Letters.propTypes = {
  cleanLetters: PropTypes.func.isRequired,
  setFilter: PropTypes.func.isRequired,
};

export default enhancer(Letters);
