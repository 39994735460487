/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import compose from 'ramda/src/compose';
import Typeahead from 'components/styleguide/Typeahead';

const Dropdown = (props) => <Typeahead {...props} isDropdown />;

Dropdown.displayName = 'Dropdown';

export default compose(Dropdown);
