/* eslint-disable @typescript-eslint/no-shadow */
import propOr from 'ramda/src/propOr';
import { useState, useCallback, useEffect } from 'react';
import { useFormikContext, getIn } from 'formik';
import { usePrompt } from 'utils/hooks';

export const getRightPresetInitialValues = ({ right, alwaysRenderValue, rightsPresetName }) => {
  if (!right) return {};

  const initialValues = {
    ...right,
    name: alwaysRenderValue ? rightsPresetName : right.name,
    territories: propOr([], 'territories', right),
  };

  return initialValues;
};

export const useAllCheckbox = (checkboxValues, field) => {
  const { values, setFieldValue } = useFormikContext();
  const [allData, setAllDataState] = useState(false);

  const handleAllDataCheckbox = useCallback(
    async (event, value) => {
      const newValues = checkboxValues.reduce((acc, { id }) => ({ ...acc, [id]: value }), {});
      await setFieldValue(field, newValues);
      setAllDataState(value);
    },
    [checkboxValues, setFieldValue, setAllDataState, field],
  );

  const formValues = getIn(values, field);

  useEffect(() => {
    const values = Object.values(formValues);
    const allTrue = values.length === checkboxValues.length && values.every(Boolean);
    if (allTrue !== allData) {
      setAllDataState(allTrue);
    }
  }, [formValues, allData, checkboxValues.length]);

  return [allData, handleAllDataCheckbox];
};

export const useRightPresetPrompt = ({ content, onConfirm }) => {
  const openPrompt = usePrompt();

  const handleConfirm = useCallback(onConfirm, [onConfirm]);

  const openRightPresetPrompt = useCallback(() => {
    openPrompt({
      content,
      onConfirm: handleConfirm,
    });
  }, [content, handleConfirm, openPrompt]);

  return openRightPresetPrompt;
};
