/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-children-prop */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { BaseDrawer, Button, DrawerHeader, InputCheckbox, SearchInput, Text } from 'components/styleguide';
import { symmetricDifference } from 'ramda';
import { useDebounce, useFetch, usePrompt, useRootTranslation } from 'utils/hooks';
import Grid from '@material-ui/core/Grid';
import Paginator from 'components/common/Paginator';
import { CommonDrawerPropTypes } from '../constants';

const tFallback = 'drawers.search';

const SearchDrawer = ({
  onClose,
  payload: {
    cachedValues: initialCached,
    fetch,
    idKey = 'id',
    labelKey = 'name',
    hasSearch = true,
    rootT = '',
    onSubmit,
    values,
  },
}) => {
  const t = useRootTranslation(rootT, tFallback);
  const tGlobal = useRootTranslation('global.forms');
  const openPrompt = usePrompt();
  // Wrapping with an array because it creates a new instance every time, keeping the same mutable data structure
  // The other solution would be creating a new Set object on every change
  const [[selected], setSelected] = useState([new Set(values || [])]);
  const [cachedValues] = useState(new Map((initialCached || []).map((v) => [v[idKey], v])));
  const [term, setTerm] = useState('');
  const debouncedTerm = useDebounce(term);
  const [data, loading] = useFetch(
    () => (!hasSearch || debouncedTerm) && fetch({ term: debouncedTerm }),
    [debouncedTerm],
  );
  const [dataPaginated, setDataPaginated] = useState([]);

  const handleChange = (id, item) => {
    if (selected.has(id)) {
      selected.delete(id);
      cachedValues.delete(id);
    } else {
      selected.add(id);
      cachedValues.set(id, item);
    }
    setSelected([selected]);
  };

  const handleSubmit = async () => {
    await onSubmit(Array.from(selected), Array.from(cachedValues.values()));
    onClose();
  };

  const handleClose = () => {
    if (symmetricDifference(values || [], Array.from(selected)).length) {
      openPrompt({
        onConfirm: onClose,
        content: t('cancelPrompt'),
      });
    } else onClose();
  };

  return (
    <>
      <DrawerHeader hideBackButton title={t('drawerTitle')}>
        <Button
          testId="search-drawer-cancel"
          alwaysEnabled
          variant="destructive"
          size="xs"
          children={tGlobal('cancel')}
          onClick={handleClose}
        />
        <Button
          disabled={!selected.size}
          testId="search-drawer-apply"
          children={tGlobal('apply')}
          onClick={handleSubmit}
          size="xs"
          type="button"
          variant="primary"
        />
      </DrawerHeader>
      <Grid container spacing={2} direction="column">
        {hasSearch && (
          <Grid item>
            <SearchInput
              adornmentPosition="start"
              color="primary"
              name="template-name-search"
              onChange={setTerm}
              placeholder={t('searchPlaceholder')}
              value={term}
              loading={loading}
            />
          </Grid>
        )}
        <Grid item container>
          {!dataPaginated.length ? (
            <Text>{t('searchHint')}</Text>
          ) : (
            dataPaginated.map((item) => (
              <InputCheckbox
                key={item[idKey]}
                name={`${item[idKey]}`}
                label={item[labelKey]}
                onChange={() => handleChange(item[idKey], item)}
                value={selected.has(item[idKey])}
              />
            ))
          )}
        </Grid>
        <Grid container justify="flex-end">
          <Paginator elements={data} callback={setDataPaginated} amount={25} />
        </Grid>
      </Grid>
    </>
  );
};

SearchDrawer.propTypes = {
  payload: PropTypes.shape({
    cachedValues: PropTypes.array,
    fetch: PropTypes.func,
    // Indicates if there is a search input or not
    hasSearch: PropTypes.bool,
    idKey: PropTypes.string,
    labelKey: PropTypes.string,
    rootT: PropTypes.string,
    onSubmit: PropTypes.func,
    values: PropTypes.arrayOf(PropTypes.oneOfType(PropTypes.number, PropTypes.string)),
  }),
};

const Drawer = ({ open, onClose, ...props }) => (
  <BaseDrawer open={open} onClose={onClose}>
    <SearchDrawer onClose={onClose} {...props} />
  </BaseDrawer>
);

Drawer.propTypes = CommonDrawerPropTypes;

export default Drawer;
