import { compose } from 'redux';
import { connect } from 'react-redux';
import { getDivisionId } from 'redux-core/productions/selectors';
import { getCampaignAsset, updateCampaignAsset } from 'redux-core/cueContainers/assets/actions';
import { withFormConnected } from 'components/styleguide/HOC/index';
import { getDialogRightsByNameSelector } from 'redux-core/dialogs/selectors';
import withBaseDrawer from '../withBaseDrawer';
// eslint-disable-next-line import/no-named-as-default
import DRAWERS from '../constants';

const mapStateToProps = (state, ownProps) => ({
  divisionId: getDivisionId(state),
  rights: getDialogRightsByNameSelector(state, ownProps.name),
});

const mapDispatchToProps = {
  getCampaignAsset,
  updateCampaignAsset,
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withBaseDrawer, (c) =>
  withFormConnected(c, { formId: DRAWERS.UNASSIGNED_BIN }),
);
