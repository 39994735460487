/* eslint-disable import/no-cycle */
import { connect } from 'react-redux';

import { getLocationSelector } from 'redux-core/router/selectors';
import { goToLink } from 'redux-core/router/actions';
import { isAuthenticatedSelector, userNameSelector, getIsQwireEmployeeSelector } from 'redux-core/auth/selectors';
import { logOut } from 'redux-core/auth/actions';
import { openDocument360Tab } from 'redux-core/dialogs/actions';
import { getIsMenuOpenSelector } from 'redux-core/prompt/selectors';
import { setMenuOpen } from 'redux-core/prompt/actions';
import { getUserProfile } from 'redux-core/user-profile/actions';

const mapDispatchToProps = {
  getUserProfile,
  goToLink,
  openDocument360Tab,
  logOut,
  setMenuOpen,
};

const mapStateToProps = (state) => ({
  location: getLocationSelector(state),
  isAuthenticated: isAuthenticatedSelector(state),
  userName: userNameSelector(state),
  isMenuOpen: getIsMenuOpenSelector(state),
  isQwireEmployee: getIsQwireEmployeeSelector(state),
});

export default connect(mapStateToProps, mapDispatchToProps);
