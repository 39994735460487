/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '@material-ui/core/SvgIcon';

const icons = {
  top: (
    <path
      fill="#5F6368"
      fillRule="evenodd"
      d="M1.4 10.4L5 6.8V21h2V6.8l3.6 3.6L12 9 6 3 0 9l1.4 1.4zM0 2h12V0H0v2z"
      clipRule="evenodd"
    />
  ),
  bottom: (
    <path
      fill="#5F6368"
      fillRule="evenodd"
      d="M10.6 10.6L7 14.2V0H5v14.2l-3.6-3.6L0 12l6 6 6-6-1.4-1.4zM12 19H0v2h12v-2z"
      clipRule="evenodd"
    />
  ),
};

const MoveTo = ({ direction = 'top', ...props }) => (
  <SvgIcon viewBox="0 0 20 20" height="48" width="48" {...props}>
    {icons[direction]}
  </SvgIcon>
);

MoveTo.propTypes = {
  direction: PropTypes.oneOf(['top', 'bottom']),
};

export default MoveTo;
