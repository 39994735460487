import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    marginBottom: theme.spacing(3),
    minHeight: theme.spacing(4),
    padding: 0,
  },
  backButton: {
    marginRight: theme.spacing(2),
    padding: 0,
  },
  hasSubtitle: {
    height: '50%',
    marginTop: theme.spacing(1.5),
  },
  item: {
    display: 'flex',
  },
}));

export default useStyles;
