/* eslint-disable react/default-props-match-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, Grid } from '@material-ui/core';
import withStyles from './styles';

const colors = {
  primary: 'primary',
  secondary: 'secondary',
};

const Loading = ({ classes, color, size, testId }) => (
  <Grid container justify="center" alignContent="center" alignItems="center" className={classes.container}>
    <Grid item>
      <CircularProgress size={size} color={colors[color] ?? 'primary'} data-testid={testId} />
    </Grid>
  </Grid>
);

Loading.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.oneOf(Object.keys(colors)),
  size: PropTypes.number.isRequired,
  testId: PropTypes.string,
};

Loading.defaultProps = {
  size: 60,
  color: 'primary',
  testId: 'circularProgress',
};

export default withStyles(Loading);
