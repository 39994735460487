import { VerifiedUser } from '@mui/icons-material';
import { Tooltip, Typography } from '@mui/material';
import { Box } from '@mui/system';
import Grid from '@mui/system/Unstable_Grid/Grid';
import { TSearchTrack } from 'services/tracks/types';
import { rootTranslation } from 'utils/format';

const tRoot = rootTranslation('drawers.productionSetup.tracks.headers');

const getScoreTooltipLabel = (score) => {
  if (score === 3) return 'dataCompletenessHigh';
  if (score === 2) return 'dataCompletenessMedium';

  return 'dataCompletenessLow';
};

const getScoreColor = (score) => {
  if (score === 3) return 'success.light';
  if (score === 2) return 'warning.light';

  return 'error.light';
};

export function ScoreColumn({ track }: { track: TSearchTrack }) {
  const { score, tenantname, preClearance } = track;

  if (score === 4) {
    return (
      <>
        <Grid>
          <Box
            sx={{
            bgcolor: 'secondary.300',
            color: 'secondary.main',
            fontWeight: 700,
            fontSize: '0.75em',
            padding: '4px',
            borderRadius: '4px',
            textTransform: 'uppercase',
            marginRight: '8px',
          }}
          >
            <VerifiedUser
              sx={{
              '&&': {
                fontSize: '1em',
                color: 'secondary.main',
                padddingLeft: '0.3em',
                paddingRight: '0.3em',
              },
            }}
            />
            {tRoot('verified')}
          </Box>
        </Grid>

        {preClearance && (
        <Grid>
          <Tooltip title={track.description}>
            <Box
              sx={{
                padding: '4px',
                borderRadius: '4px',
                marginRight: '8px',
                backgroundColor: track.allProjects ? 'success.light' : 'warning.light',
              }}
            >
              <Typography
                noWrap
                variant="caption"
                sx={{
                  color: 'text.primary',
                  fontWeight: 700,
                  textTransform: 'uppercase',
                }}
              >
                {tRoot('preCleared')}
              </Typography>
            </Box>
          </Tooltip>
        </Grid>
      )}
      </>
    );
  }

  return (
    <Grid container alignItems="center">
      <Grid>
        <Tooltip title={tRoot(getScoreTooltipLabel(score))} placement="top-end">
          <Box
            sx={{
              width: '0.5rem',
              marginRight: '0.5rem',
              bgcolor: getScoreColor(score),
              borderRadius: '5px',
            }}
          />
        </Tooltip>
      </Grid>
      {preClearance && (
        <Grid>
          <Tooltip title={track.description}>
            <Box
              sx={{
                padding: '4px',
                borderRadius: '4px',
                marginRight: '8px',
                backgroundColor: track.allProjects ? 'success.light' : 'warning.light',
              }}
            >
              <Typography
                noWrap
                variant="caption"
                sx={{
                  color: 'text.primary',
                  fontWeight: 700,
                  textTransform: 'uppercase',
                }}
              >
                {tRoot('preCleared')}
              </Typography>
            </Box>
          </Tooltip>
        </Grid>
      )}
      <Grid>
        <Tooltip title={tenantname}>
          <Box
            sx={{
              backgroundColor: 'grey.200',
              padding: '4px',
              borderRadius: '4px',
            }}
          >
            <Typography
              noWrap
              variant="caption"
              sx={{
                color: 'text.primary',
                fontWeight: 700,
                textTransform: 'uppercase',
              }}
            >
              {tenantname}
            </Typography>
          </Box>
        </Tooltip>
      </Grid>
    </Grid>
  );
}
