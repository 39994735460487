/* eslint-disable import/no-cycle */
import { handleActions } from 'redux-actions';
import { set, lensPath } from 'ramda';
import * as actions from './actions';

const defaultState = {
  preset: {},
  presets: [],
};

const setupFlowReducer = {
  [actions.setRightPreset]: (state, { payload }) => set(lensPath(['preset']), payload)(state),
  [actions.setRightsPresets]: (state, { payload }) => set(lensPath(['presets']), payload)(state),
  [actions.cleanRightsPresets]: (state) => set(lensPath(['presets']), defaultState.presets)(state),
  // Adds a blank new right preset row
  [actions.newRightPreset]: (state, { payload }) => set(lensPath(['presets']), payload)(state),
  [actions.addRightPreset]: (state, { payload }) => set(lensPath(['presets']), payload)(state),
};

export default handleActions(setupFlowReducer, defaultState);
