/* eslint-disable import/no-cycle */

import { createActions } from 'redux-actions';
import { getLocationSelector } from 'redux-core/router/selectors';
import { identity, always } from 'ramda';
import ROUTES from 'components/Routes/routes';
import { HOMEPAGE } from 'components/Routes';
import { getAllDivisions } from 'redux-core/divisions/actions';
import LogRocket from 'logrocket';
import { getCurrentUserData, lockUser } from './services';
import { goToLink } from '../router/actions';
import { interceptedRequestsSelector, userIdSelector, getBearerTokenSelector, userDataSelector } from './selectors';

const actionsDefinition = {
  SET_USER_DATA: identity,
  REMOVE_USER_DATA: always(undefined),
  SET_LOCKED: identity,
  SET_BEARER_TOKEN: identity,
  ADD_INTERCEPTED_REQUEST: identity,
  CLEAR_INTERCEPTED_REQUESTS: always(undefined),
  CLEAR_DATA: always(undefined),
};

export const {
  setUserData,
  removeUserData,
  setLocked,
  setBearerToken,
  addInterceptedRequest,
  clearInterceptedRequests,
  clearData,
} = createActions(actionsDefinition);

export const logOut = () => async (dispatch, getState) => {
  sessionStorage.clear();
  localStorage.clear();
  const bearerToken = getBearerTokenSelector(getState());
  try {
    /** This call fails if the user was already locked out */
    await lockUser(bearerToken);
  } finally {
    dispatch(clearInterceptedRequests());
    dispatch(clearData());
  }
};

export const saveUserData = (userData) => async (dispatch, getState) => {
  /** Get the current id in case the user was logged in and locked */
  const currentId = userIdSelector(getState());

  if (currentId && currentId !== userData.userId) {
    /** If a different user than the one that was locked tried to relogin, log them out */
    dispatch(logOut());
  } else {
    sessionStorage.setItem('bearerToken', userData.bearerToken);
    dispatch(setUserData(userData));

    const pendingRequests = interceptedRequestsSelector(getState());
    /** Resolve all requests that failed due to a 401 error, stored by the interceptor in request.js */
    pendingRequests.forEach(
      // eslint-disable-next-line no-return-await
      async ({ resolveRequest }) => await resolveRequest(),
    );
    dispatch(clearInterceptedRequests());
    dispatch(setLocked(false));
    const location = getLocationSelector(getState());
    if (location === ROUTES.LOGIN) dispatch(goToLink(HOMEPAGE));
  }
};

export const getUserData = (bearerToken) => async (dispatch) => {
  /** Save bearerToken in store to make call */
  await dispatch(setBearerToken(bearerToken));
  dispatch(getAllDivisions());
  const data = await getCurrentUserData();

  const userData = {
    ...data,
    bearerToken,
    locked: false,
    displayName: data.userName,
  };

  /** Send the user's info to freshpaint */
  // eslint-disable-next-line no-console
  console.log('userdata', data);
  freshpaint.identify(data.userEmail, data);

  if (process.env.REACT_APP_LOGROCKET_APP_ID) {
    LogRocket.identify(process.env.REACT_APP_LOGROCKET_APP_ID, {
      name: userData.displayName,
      email: userData.userEmail,
    });
  }

  dispatch(saveUserData(userData));

  return userData;
};

export const refreshUserData = () => async (dispatch, getState) => {
  const currentUserData = userDataSelector(getState());
  dispatch(getAllDivisions());
  const data = await getCurrentUserData();

  const userData = {
    ...currentUserData,
    ...data,
    displayName: data.userName,
  };

  /** Send the user's info to freshpaint */
  freshpaint.identify(data.userEmail, data);

  if (process.env.REACT_APP_LOGROCKET_APP_ID) {
    LogRocket.identify(process.env.REACT_APP_LOGROCKET_APP_ID, {
      name: userData.displayName,
      email: userData.userEmail,
    });
  }

  dispatch(saveUserData(userData));
};

export const lockUserOut = () => async (dispatch, getState) => {
  const bearerToken = getBearerTokenSelector(getState());
  try {
    /** This call fails if the user was already locked out */
    await lockUser(bearerToken);
  } finally {
    dispatch(setLocked(true));
  }
};
