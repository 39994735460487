import { Box } from '@mui/system';
import { TSearchTracksFilters } from 'services/tracks/types';
import { rootTranslation } from 'utils/format';

const t = rootTranslation('drawers.productionSetup.tracks.headers');

type TrackSummaryProps = {
  searchQuery?: TSearchTracksFilters;
  totalCount: number;
};

export function TrackSummary({ searchQuery, totalCount }: TrackSummaryProps) {
  const isSongType = Object.hasOwn(searchQuery?.types, 'song');

  if (totalCount === 0) {
    return <Box>{t('noResults')}</Box>;
  }

  return <Box>{isSongType ? t('totalCountSong', { totalCount }) : t('totalCountLibrary', { totalCount })}</Box>;
}
