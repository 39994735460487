import { atomWithReset } from 'jotai/utils';
import { New } from 'services/clear/clearance-status';
import { TLetterContact } from 'services/clear/letters';
import { TClearanceBundleLicensor } from 'services/clear/licensors';
import { TLetterType } from 'services/clear/types';

export type ComposeLetterStep = {
  stepNumber: number;
  label: string;
};

export type TComposeLetterWriteEmailContentStepForm = {
  templateId?: number;
  clearanceStatusId?: number;
  toRecipients: string[];
  ccRecipients: string[];
  bccRecipients: string[];
  from: string;
  subject: string;
  body: string;
  editorReady: boolean;
  extraAttachments: { id: number; name: string; }[];
}

export type TComposeLetterWriteLetterContentStepForm = {
  templateId?: number;
  ignoreDraft?: boolean;
  draftId?: number;
  clearanceStatusId: number;
  letterFileType: 'docx' | 'pdf';
  docxUrl: string;
  pdfUrl: string;
  setStatus: boolean;
  setSendDate: boolean;
  editorReady: boolean;
};

export const composeLetterSteps: ComposeLetterStep[] = [
  {
    stepNumber: 0,
    label: 'selectLicensor',
  },
  {
    stepNumber: 1,
    label: 'selectContact',
  },
  {
    stepNumber: 2,
    label: 'composeLetter',
  },
  {
    stepNumber: 3,
    label: 'composeEmail',
  },
];

export const emptyContact: TLetterContact = {
  id: undefined,
  firstName: '',
  lastName: '',
  email: '',
  address1: '',
  address2: '',
  city: '',
  state: '',
  postalCode: '',
  countryName: 'United States',
  licensorName: '',
  usedLast: undefined,
};

export type TComposeLetterStore = {
  leftPanelHidden: boolean;
  divisionId: number;
  clearanceBundleLicensor: TClearanceBundleLicensor;
  documentTitle: string;
  letterType: TLetterType;
  clearanceBundleLicensorsIds: TClearanceBundleLicensor['id'][];
  currentStep: ComposeLetterStep;
  contact: TLetterContact;
  letter: TComposeLetterWriteLetterContentStepForm;
  email: TComposeLetterWriteEmailContentStepForm;
  goToLink?: Function;
  licensorQueue: any;
  track: {
    title: string
  },
  production: {
    name: string
  },
  project: {
    name: string
  },
};

export const composeLetterAtom = atomWithReset<TComposeLetterStore>({
  divisionId: undefined,
  clearanceBundleLicensor: undefined,
  documentTitle: undefined,
  letterType: undefined,
  clearanceBundleLicensorsIds: undefined,
  currentStep: composeLetterSteps[1],
  contact: undefined,
  leftPanelHidden: false,
  licensorQueue: undefined,
  track: undefined,
  production: undefined,
  project: undefined,
  letter: {
    templateId: undefined,
    draftId: undefined,
    clearanceStatusId: New.id,
    letterFileType: 'pdf',
    docxUrl: undefined,
    pdfUrl: undefined,
    setStatus: true,
    setSendDate: true,
    editorReady: false,
    ignoreDraft: false,
  },
  email: {
    templateId: undefined,
    clearanceStatusId: New.id,
    toRecipients: undefined,
    ccRecipients: undefined,
    bccRecipients: undefined,
    from: undefined,
    subject: undefined,
    body: undefined,
    editorReady: true,
    extraAttachments: [],
  },
  goToLink: undefined,
});
