import { useWatch } from 'react-hook-form';
import { isEmpty } from 'lodash';
import { CreateLicensorsForm } from '../createLicensors/CreateLicensorsForm';
import { CreateEmptyState } from './CreateEmptyState';

export function CreateContent({ fields, remove }) {
  const title = useWatch({ name: 'title' });

  if (isEmpty(title) && isEmpty(fields)) {
    return <CreateEmptyState />;
  }

  return <CreateLicensorsForm fields={fields} remove={remove} />;
}
