/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import { ListItemButton } from '@mui/material';
import Box from '@material-ui/core/Box';
import path from 'ramda/src/path';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  selected: {
    backgroundColor: theme.palette.action.selected,
  },
  root: {
    '& .dotted': {
      width: '1rem',
      height: '1rem',
      backgroundColor: theme.palette.primary.main,
      borderRadius: '50%',
      marginRight: theme.spacing(1),
    },
  },
}));

function Option(props) {
  const dense = path(['selectProps', 'extraProps', 'denseItem'])(props);
  const dotId = path(['selectProps', 'extraProps', 'dotId'])(props);

  const classes = useStyles();
  return (
    <ListItemButton
      ref={props.innerRef}
      selected={props.isSelected}
      component="div"
      data-testid="typeahead-option"
      dense={dense}
      {...props.innerProps}
      classes={classes}
    >
      {dotId && dotId === props.value ? <Box className="dotted" /> : null}
      {props.children}
    </ListItemButton>
  );
}

Option.propTypes = {
  /**
   * The children to be rendered.
   */
  children: PropTypes.node,
  /**
   * props passed to the wrapping element for the group.
   */
  innerProps: PropTypes.shape({
    id: PropTypes.string.isRequired,
    key: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    onMouseMove: PropTypes.func.isRequired,
    onMouseOver: PropTypes.func.isRequired,
    tabIndex: PropTypes.number.isRequired,
  }).isRequired,
  /**
   * Inner ref to DOM Node
   */
  innerRef: PropTypes.oneOfType([
    PropTypes.oneOf([null]),
    PropTypes.func,
    PropTypes.shape({
      current: PropTypes.any.isRequired,
    }),
  ]),
  /**
   * Whether the option is focused.
   */
  isFocused: PropTypes.bool.isRequired,
  /**
   * Whether the option is selected.
   */
  isSelected: PropTypes.bool.isRequired,
};

export default Option;
