import * as React from 'react';
import { TextField } from '@mui/material';
import Grid from '@mui/system/Unstable_Grid/Grid';
import { TPartialRightOwner } from 'services/tracks/types';

type LicensorRowReadOnlyProps = {
  htmlSectionId: string;
  item: TPartialRightOwner;
};

export function LicensorRowReadOnly({ item, htmlSectionId }: LicensorRowReadOnlyProps) {
  return (
    <>
      <Grid xs={10}>
        <TextField
          inputProps={{ readOnly: true, 'aria-labelledby': `${htmlSectionId}-name` }}
          fullWidth
          value={item.name}
        />
      </Grid>
      <Grid xs={2}>
        <TextField
          inputProps={{
            readOnly: true,
            'aria-labelledby': `${htmlSectionId}-share`,
            style: { textAlign: 'right' },
          }}
          fullWidth
          value={`${item.share}%`}
          sx={{ textAlign: 'right' }}
        />
      </Grid>
    </>
  );
}
