import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid, Typography } from '@mui/material';
import { useAtomValue, useSetAtom } from 'jotai';
import { FormProvider, useForm, UseFormReturn } from 'react-hook-form';
import { useSaveLicensorContactMutation } from 'services/clear/letters';
import { TLicensorContact } from 'services/clear/types';
import { rootTranslation } from 'utils/format';
import * as yup from 'yup';
import { ComposeLetterLeftPanel } from '../ComposeLetterLeftPanel';
import { ComposeLetterRequestActionMessage } from '../ComposeLetterRequestActionMessage';
import { ComposeLetterRightPanel } from '../ComposeLetterRightPanel';
import { composeLetterAtom, composeLetterSteps } from '../composeLetterStore';
import { ComposeLetterExistingContactForm } from './ComposeLetterExistingContactForm';

const t = rootTranslation('composeLetters.selectContact');

const schema = yup.object().shape({
  id: yup.number().nullable(),
  firstName: yup.string().nullable().required('global.forms.validations.required'),
  lastName: yup.string().nullable().required('global.forms.validations.required'),
  email: yup
    .string()
    .email('global.forms.validations.types.email')
    .nullable()
    .required('global.forms.validations.required'),
  address1: yup.string().nullable(),
  address2: yup.string().nullable(),
  city: yup.string().nullable(),
  state: yup.string().nullable(),
  postalCode: yup.string().nullable(),
  countryName: yup.string().nullable(),
  licensorName: yup.string().nullable(),
  usedLast: yup.boolean().nullable(),
});

export function ComposeLetterSelectContactStep() {
  const composeLetter = useAtomValue(composeLetterAtom);
  const updateComposeLetter = useSetAtom(composeLetterAtom);
  const [saveLicensorContact] = useSaveLicensorContactMutation();

  const formBag: UseFormReturn<TLicensorContact> = useForm<TLicensorContact>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: composeLetter?.contact ? { ...composeLetter.contact } : undefined,
  });

  const { isSubmitting } = formBag.formState;

  const goBack = async () => {
    updateComposeLetter((prev) => ({ ...prev, currentStep: composeLetterSteps[0] }));
  };

  const skip = async (contact) => {
    const { divisionId, clearanceBundleLicensor } = composeLetter;
    const updatedContact = await saveLicensorContact({
      contact: {
        ...contact,
        id: contact.id || null,
        divisionId,
      },
      divisionId,
      clearanceBundleLicensorsIds: [clearanceBundleLicensor.id],
    }).unwrap();

    updateComposeLetter((prev) => ({
      ...prev,
      contact: updatedContact,
      email: {
        ...prev.email,
        toRecipients: [updatedContact.email],
      },
      currentStep: composeLetterSteps[3],
    }));
  };

  const submitAndGoNext = async (contact) => {
    const { divisionId, clearanceBundleLicensor } = composeLetter;
    const updatedContact = await saveLicensorContact({
      contact: {
        ...contact,
        id: contact.id || null,
        divisionId,
      },
      divisionId,
      clearanceBundleLicensorsIds: [clearanceBundleLicensor.id],
    }).unwrap();

    updateComposeLetter((prev) => ({
      ...prev,
      contact: updatedContact,
      email: {
        ...prev.email,
        toRecipients: [updatedContact.email],
      },
      currentStep: composeLetterSteps[2],
    }));
  };

  return (
    <FormProvider {...formBag}>
      <form style={{ height: '100%' }} onSubmit={formBag.handleSubmit(submitAndGoNext)}>
        <Grid container flexDirection="row" sx={{ width: '100%', height: '100%' }}>
          <ComposeLetterLeftPanel isSubmitting={isSubmitting} goBack={goBack} skip={formBag.handleSubmit(skip)}>
            <Grid item container flexDirection="column" gap={3}>
              <Grid item>
                <Typography variant="h5">{t('stepTitle')}</Typography>
              </Grid>
              <Grid container item flexGrow={1}>
                <Box display="flex" flexDirection="column" width="100%" gap={2}>
                  <ComposeLetterExistingContactForm
                    formBag={formBag}
                    rightsOwnerId={composeLetter?.clearanceBundleLicensor?.rightsOwnerId}
                  />
                </Box>
              </Grid>
            </Grid>
          </ComposeLetterLeftPanel>
          <ComposeLetterRightPanel>
            <ComposeLetterRequestActionMessage message={t('selectContactMessage')} />
          </ComposeLetterRightPanel>
        </Grid>
      </form>
    </FormProvider>
  );
}
