/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import pick from 'ramda/src/pick';
// eslint-disable-next-line import/no-named-as-default
import BaseAdvancedFilters from '../BaseAdvancedFilters';
import CuesFilters from '../Filters/CuesFilters';
import enhancer from './enhancer';
import { FILTERS_LIST_FOR_CUES } from '../Filters/constants';

function TracksAdvancedFiltersDrawer({ payload, addParams, ...props }) {
  const drawerProps = {
    ...pick(['name', 'open', 'onClose'], props),
    payload,
  };

  const handleOnSubmit = (filters) => {
    addParams({ filters });
  };

  return (
    <BaseAdvancedFilters {...drawerProps} onSubmit={handleOnSubmit}>
      {({ filtersData, loading }) => (
        <CuesFilters filtersData={pick(FILTERS_LIST_FOR_CUES, filtersData)} loading={loading} />
      )}
    </BaseAdvancedFilters>
  );
}

TracksAdvancedFiltersDrawer.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  payload: PropTypes.object.isRequired,
  addParams: PropTypes.func.isRequired,
};

export default enhancer(TracksAdvancedFiltersDrawer);
