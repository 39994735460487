import { connect } from 'react-redux';
import { getAdvancedSearchFiltersSelector, getSearchResultsSelector } from 'redux-core/header/selectors';
import { setSearchResults, setSearchItemDetails, updateSearchResponse } from 'redux-core/header/actions';

const mapStateToProps = (state) => ({
  searchResults: getSearchResultsSelector(state),
  advancedFilters: getAdvancedSearchFiltersSelector(state),
});

const mapDispatchToProps = { setSearchResults, setSearchItemDetails, updateSearchResponse };

export default connect(mapStateToProps, mapDispatchToProps);
