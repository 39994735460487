/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import concat from 'ramda/src/concat';
import head from 'ramda/src/head';

import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import propOr from 'ramda/src/propOr';

import { Image, Text } from 'components/styleguide';
import UserMenuRow from './components/UserMenuRow';
import ArrowUp from './components/ArrowUp';
import useStyles from './styles';

const UserMenu = ({ options, profileImage, user }) => {
  const classes = useStyles();
  const getInitials = (firstName, lastName) => concat(head(firstName || ''), head(lastName || ''));

  const userInitials = getInitials(user.firstName, user.lastName);

  return (
    <Grid container className={classes.root} direction="column" alignItems="center">
      <ArrowUp />
      <Paper className={classes.menu} square>
        <Grid container className={classes.menuContainer} direction="column">
          <Grid container item className={classes.menuRow} alignItems="center">
            <Grid item xs={2}>
              {profileImage ? <Image avatar url={profileImage} alt="Profile Image" /> : <Avatar>{userInitials}</Avatar>}
            </Grid>
            <Grid container item xs={10} direction="column">
              <Grid item>
                <Text bold>{user.displayName}</Text>
              </Grid>
              <Grid item>
                {propOr(false, 'title', user) ? (
                  <Text color="textSecondary" variant="caption">
                    {user.title}
                  </Text>
                ) : null}
              </Grid>
            </Grid>
          </Grid>
          {options.map((option) => (
            <UserMenuRow option={option} />
          ))}
        </Grid>
      </Paper>
    </Grid>
  );
};

UserMenu.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      action: PropTypes.func,
      icon: PropTypes.elementType,
    }),
  ).isRequired,
  profileImage: PropTypes.string.isRequired,
  user: PropTypes.shape({}),
};

export default UserMenu;
