/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable max-len */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const CompleteAssetIcon = (props) => (
  <SvgIcon {...props} viewBox="0 0 30 30">
    <path
      fillRule="evenodd"
      d="M10.9 13.1l-1.4 1.4L14 19 24 9l-1.4-1.4-8.6 8.6-3.1-3.1zM23 15c0 4.4-3.6 8-8 8s-8-3.6-8-8 3.6-8 8-8c.8 0 1.5.1 2.2.3l1.6-1.6C17.6 5.3 16.3 5 15 5 9.5 5 5 9.5 5 15s4.5 10 10 10 10-4.5 10-10h-2z"
      clipRule="evenodd"
    />
  </SvgIcon>
);

export default CompleteAssetIcon;
