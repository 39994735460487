import { withStyles } from '@material-ui/core';

const styles = () => ({
  bookmarkIcon: {
    color: 'white',
    fontSize: '24px',
    margin: '0px 2px 0 15px',
    position: 'relative',
    top: '5px',
    cursor: 'pointer',
  },
});

export default withStyles(styles);
