import { PROJECT_VIEW_MODES } from 'utils/constants';
import ROUTES from 'components/Routes/routes';

export const ROUTE_TO_VIEW_MODE = {
  [ROUTES.PRODUCTION.SCENARIO]: PROJECT_VIEW_MODES.SCENARIO,
  [ROUTES.PRODUCTION.TRACKS]: PROJECT_VIEW_MODES.TRACKS,
  [ROUTES.PRODUCTION.SCENES]: PROJECT_VIEW_MODES.SCENES,
  [ROUTES.PRODUCTION.VERSIONS]: PROJECT_VIEW_MODES.VERSIONS,
  [ROUTES.PRODUCTION.ASSETS]: PROJECT_VIEW_MODES.ASSETS,
};
