/* eslint-disable import/no-cycle */
import makeEntityServiceCalls, { makeServiceCall } from 'redux-core/serviceHelpers';
import map from 'ramda/src/map';
import pickBy from 'ramda/src/pickBy';
import { isEmptyObject, renameKeys } from 'utils/object';
import { LICENSOR_TYPES } from 'utils/constants';
import { getLocationParametersSelector } from 'redux-core/router/selectors';
import { baseApi } from 'services';
import { fetchFooterInfo, getSmartFlags } from 'redux-core/overview/actions';
import mockClearanceLogData from './mocks/mock-clearance-log-data';

const idToQwireTracksArtistId = renameKeys({ id: 'qwireTracksArtistId' });

export const getTrackHistory = makeServiceCall('tracks', 'get-history', 'clear');

/**
    body: { id }
  return data;
 * Gets a Clearance Log data
 */

/** @TODO make real request to get clearance log data */
export const getClearanceLog = async () =>
  new Promise((resolve) => {
    setTimeout(() => resolve(mockClearanceLogData), 100);
  });

export const getQwireTrackDetailsCall = makeServiceCall('tracks', 'get', 'tracks');

export const updateClearanceRep = makeServiceCall('tracks', 'update-clearance-rep-user', 'clear');

const qTracksSearchCall = (endpoint) => async (payload) => {
  const { content } = await makeServiceCall(endpoint, 'search', 'tracks')(payload);
  return content;
};

const qTracksUpdateHiddenRightsOwners = (endpoint) => async (payload) => {
  const { content } = await makeServiceCall('rights-owners', endpoint, 'tracks')(payload);
  return content;
};

const qclearUpdateIgnoreLicensorsCall = (endpoint) => async (payload) => {
  const { content } = await makeServiceCall('tracks', endpoint, 'clear')(payload);
  return content;
};

const qTracksSearchPaginatedCall = (endpoint) => async (payload) => {
  const { data, pagination } = await makeServiceCall(endpoint, 'search', 'tracks')(payload);
  return { data, pagination };
};

export const createQwireTrackArtistsCall = makeServiceCall('artists', 'create', 'tracks');

export const createArtistUsingQwireTracksArtistId = async (payload) => {
  const queryRes = await createQwireTrackArtistsCall(payload);
  return idToQwireTracksArtistId(queryRes);
};

export const createQwireTrackAlbumsCall = makeServiceCall('albums', 'create', 'tracks');
export const createQwireTracksArrangersCall = makeServiceCall('arrangers', 'create', 'tracks');

export const getLightTracksCall = ({ types, filters, ...payload } = {}) => {
  const clean = (object) => {
    const cleanObject = pickBy(Boolean)(object);
    if (isEmptyObject(cleanObject)) return undefined;
    return cleanObject;
  };
  // Backend doesn't filter properly if passing falsy values in filters or types
  const cleanPayload = {
    ...payload,
    filters: clean(filters),
    types: clean(types),
  };
  return qTracksSearchPaginatedCall('tracks')(cleanPayload);
};

export const updateQwireTracksHiddenLicensors = (payload = {}) =>
  async (dispatch, getState) => {
  const state = getState();
  const { divisionId } = getLocationParametersSelector(state);
  const { qwireTracksRightsOwners } = payload;
  const body = { qwireTracksRightsOwners, userDivisionId: divisionId };

  if (qwireTracksRightsOwners?.length <= 0) return;

  await qTracksUpdateHiddenRightsOwners('manage-rights-owners')(body);
};

export const updateIgnoreLicensors = (payload = {}) =>
  async (dispatch) => {
  const body = { trackId: payload.trackId, ignoreLicensorType: payload.ignoreLicensorType };
  await qclearUpdateIgnoreLicensorsCall('ignore-licensor')(body);
  await dispatch(baseApi.util.invalidateTags([
    'clearanceBundle',
    'getTrackClearanceStatus',
    'getTrackClearanceFees',
    'getBundleLicenses',
  ]));
  dispatch(getSmartFlags());
  dispatch(fetchFooterInfo());
};

export const getQwireTracksArtistsCall = qTracksSearchCall('artists');
export const getQwireTracksAlbumsCall = qTracksSearchCall('albums');
export const getQwireTracksArrangersCall = qTracksSearchCall('arrangers');
export const getQwireTracksLibrariesCall = qTracksSearchCall('libraries');
export const getQwireTracksLicensorsCall = async (payload) => {
  // Default Licensors to be Master, Sync & Other
  const body = {
    types: [LICENSOR_TYPES.MASTER, LICENSOR_TYPES.SYNC, LICENSOR_TYPES.OTHER],
    ...payload,
  };
  const resp = await qTracksSearchCall('rights-owners')(body);
  return resp;
};

export const getArtistsUsingQwireTracksArtistId = async (payload) => {
  const queryRes = await qTracksSearchCall('artists')(payload);
  return map(idToQwireTracksArtistId)(queryRes);
};

export const {
  getAll: getTracksCall,
  get: getTrackByIdCall,
  create: createTrackCall,
  update: updateTrackCall,
} = makeEntityServiceCalls('tracks', 'clear');

export const getTrackDetailsCall = makeServiceCall('tracks', 'get-details', 'clear');

export const {
  get: getTrackFileByIdCall,
  create: createTrackFileCall,
  update: updateTrackFileCall,
  getAll: getTrackFilesCall,
  delete: deleteTrackFileCall,
} = makeEntityServiceCalls('production-attachments', 'clear');

export const { getAll: getGenresCall, create: createGenreCall } = makeEntityServiceCalls('genres', 'clear');

export const getTrackVersions = makeServiceCall('tracks', 'get-versions', 'tracks');

export const updateTrackUsing = makeServiceCall('tracks', 'update-used', 'clear');

export const updateNoteAndTags = makeServiceCall('tracks', 'update-notes-and-tags', 'clear');

export const updateTrackActive = makeServiceCall('tracks', 'update-active', 'clear');

export const getProductionTagsCall = makeServiceCall('productions', 'get-track-tags', 'clear');

export const saveTrackRightsOwnersCall = makeServiceCall('tracks', 'save-track-rights-owners', 'clear');
