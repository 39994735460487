/* eslint-disable react/no-children-prop */
/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useState } from 'react';
import Proptypes from 'prop-types';
import Box from '@material-ui/core/Box';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import { Edit, Text } from 'components/styleguide';
import { useFormikContext } from 'formik';
import { searchContactsCall, getContactByIdCall } from 'redux-core/contacts/services';
import { FormDebouncedTypeAhead, FormInputText } from 'components/common/Form/Fields';
import { useRootTranslation } from 'utils/hooks';
import prop from 'ramda/src/prop';
import { SEARCH_CONTACTS_FIELDS } from 'utils/constants';
import useStyles from './styles';

export const ADDRESS_FIELDS = ['address1', 'address2', 'city', 'countryName', 'postalCode', 'state'];

const Label = ({ companyName, email, name }) => {
  const classes = useStyles();
  return (
    <Grid container spacing={1} className={classes.displayValue}>
      <Grid item xs={4}>
        <Text variant="body2" noWrap children={companyName} />
      </Grid>
      <Grid item xs={4}>
        <Text variant="body2" noWrap children={name} />
      </Grid>
      <Grid item xs={4}>
        <Text children={email} variant="body2" noWrap />
      </Grid>
    </Grid>
  );
};

const CompanyTypeahead = ({ contactKey, disabled, divisionId, name, testId, label, canEdit }) => {
  const { setFieldValue, values } = useFormikContext();
  const t = useRootTranslation('licensing.typeahead');
  const globalT = useRootTranslation('global');
  const isEditingPayee = Boolean(values.id);
  const [isEditingName, setIsEditingName] = useState(false);
  const [cacheName, setCacheName] = useState('');

  useEffect(() => {
    if (!values.name) setFieldValue('isCreatingCompany', undefined);
  }, [values.name, setFieldValue]);

  const handleCompanyChange = async (value) => {
    if (value) {
      setFieldValue(name, value.payeeCompanyName);
      setFieldValue(`${contactKey}.id`, value.contactId);
      setFieldValue(`${contactKey}.firstName`, value.firstName);
      setFieldValue(`${contactKey}.lastName`, value.lastName);
      setFieldValue(`${contactKey}.email`, value.email);
      setFieldValue('isCreatingCompany', undefined);
      const contact = await getContactByIdCall({
        divisionId,
        id: value.contactId,
      });
      ADDRESS_FIELDS.forEach((field) => setFieldValue(`${contactKey}.${field}`, contact[field]));
      setFieldValue('dba', contact.payeeDba);
      setFieldValue('tin', contact.payeeTinNumber);
      setFieldValue('vendorNumber', contact.payeeVendorNumber);
    }
  };

  const handleCompanyCreate = (value) => {
    setFieldValue(name, value);
    setFieldValue('isCreatingCompany', true);
  };

  const textInput = <FormInputText autoFocus label={label} name={name} testId={testId} />;

  const typeahead = (
    <FormDebouncedTypeAhead
      autoFocus
      alwaysRenderValue
      call={(term) =>
        term &&
        searchContactsCall({
          fields: [SEARCH_CONTACTS_FIELDS.PAYEE_COMPANY_NAME],
          term,
          divisionId,
          useCurrentDivision: true,
        })
      }
      creatableText={globalT('forms.createNew')}
      disabled={disabled}
      displayValue={prop('payeeCompanyName')}
      formatOptionLabel={({ email, fullName, payeeCompanyName }) => (
        <Label email={email} name={fullName} companyName={payeeCompanyName} />
      )}
      headerLabel={() => <Label email={t('email')} name={t('name')} companyName={t('companyName')} />}
      label={label}
      labelKey="payeeCompanyName"
      menuWidth={600}
      name={name}
      onCreate={handleCompanyCreate}
      onSelect={handleCompanyChange}
      testId={testId}
      valueKey="payeeCompanyName"
    />
  );

  if (values.isCreatingCompany) {
    return <Grid item xs={6} children={textInput} />;
  }

  if (isEditingPayee) {
    return (
      <Grid container item xs={6} alignItems="center">
        <Grid item xs={10} children={isEditingName ? textInput : typeahead} />
        <Grid container item xs={2} alignItems="center">
          <Grid item>
            {isEditingName ? (
              <Box mt={2.5}>
                <IconButton size="small" onClick={() => setIsEditingName(false)}>
                  <CheckIcon color="primary" />
                </IconButton>
                <IconButton
                  size="small"
                  onClick={() => {
                    setFieldValue(name, cacheName);
                    setCacheName('');
                    setIsEditingName(false);
                  }}
                >
                  <ClearIcon color="error" />
                </IconButton>
              </Box>
            ) : (
              canEdit && (
                <Box mt={2.5}>
                  <Edit
                    size={20}
                    onClick={() => {
                      setCacheName(values.name);
                      setIsEditingName(true);
                    }}
                  />
                </Box>
              )
            )}
          </Grid>
        </Grid>
      </Grid>
    );
  }

  return <Grid item xs={6} children={typeahead} />;
};

CompanyTypeahead.propTypes = {
  contactKey: Proptypes.string,
  label: Proptypes.string,
  disabled: Proptypes.bool,
  divisionId: Proptypes.number.isRequired,
  name: Proptypes.string.isRequired,
  testId: Proptypes.string.isRequired,
};

export default CompanyTypeahead;

Label.propTypes = {
  companyName: Proptypes.string,
  email: Proptypes.string,
  name: Proptypes.string,
};
