import { Skeleton, TreeItem, TreeView } from '@mui/lab';
import { styled } from '@mui/system';
import { isEmpty } from 'lodash';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useSearchCueContainersQuery } from 'services/common/cue-containers';
import {
  TSearchCueContainer,
  TSearchCueContainersProject,
  TSearchCueContainersSubproject,
} from 'services/common/types';
import { NoResults } from './NoResults';

const StyledTreeItem = styled(TreeItem)({
  '& .MuiTreeItem-content': {
    width: 'auto',
    borderRadius: '4px',
  },
  '& .MuiTreeItem-content > .MuiTreeItem-label': {
    lineHeight: 2,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});

export function CueContainerPickerContent({ searchTerm, onSelectItem, defaultExpanded, defaultSelected }) {
  const { data, isLoading } = useSearchCueContainersQuery({ searchTerm });

  const renderTree = (node, pathArray) => {
    const isCueContainer = node.type === 'cueContainer';
    const name = isCueContainer ? node.title : node.name;
    const nodeItem = isCueContainer ? { id: node.id, name, divisionId: node.divisionId } : { id: node.id, name };
    const updatedPathArray = pathArray.concat(nodeItem);
    const children = getNodeChildren(node);

    return (
      <StyledTreeItem
        key={node.id}
        nodeId={node.id}
        label={name}
        onClick={() => {
          if (isCueContainer) {
            onSelectItem(updatedPathArray);
          } else {
            onSelectItem(null);
          }
        }}
      >
        {Array.isArray(children) ? children.map((child) => renderTree(child, updatedPathArray)) : null}
      </StyledTreeItem>
    );
  };

  if (isLoading) {
    return <Skeleton />;
  }

  if (!isLoading && isEmpty(data)) {
    return <NoResults />;
  }

  return (
    <TreeView
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
      defaultExpanded={defaultExpanded}
      defaultSelected={defaultSelected}
      sx={{ height: '100%', flexGrow: 1, width: '100%', overflow: 'hidden' }}
    >
      {data.map((it) => renderTree(it, []))}
    </TreeView>
  );
}

function getNodeChildren(node: TSearchCueContainersProject | TSearchCueContainersSubproject | TSearchCueContainer) {
  if (node.type === 'project') {
    return node.subprojects;
  }
  if (node.type !== 'cueContainer') {
    return isEmpty(node.subprojects) ? node.cueContainers : node.subprojects;
  }
  return null;
}
