export const IMPORT_STEPS = {
  IMPORT_FILE: 0,
  SELECT_HEADER_ROW: 1,
  SELECT_FIRST_ROW: 2,
  MAP_DATA: 3,
  QTRACKS_COMPARE: 4,
  PREVIEW: 5,
  IMPORT: 6,
};

export const IMPORT_RIGHTS_OWNERS_PILLS = {
  ROLE: 'ROLE',
  WRITER: 'WRITER',
  WRITER_PRO: 'WRITER_PRO',
  WRITER_SHARE: 'WRITER_SHARE',
  WRITER_FIRST_NAME: 'WRITER_FIRST_NAME',
  WRITER_LAST_NAME: 'WRITER_LAST_NAME',
  PUBLISHER: 'PUBLISHER',
  PUBLISHER_PRO: 'PUBLISHER_PRO',
  PUBLISHER_SHARE: 'PUBLISHER_SHARE',
  WRITER_PUBLISHER: 'WRITER_PUBLISHER',
  WRITER_PUBLISHER_PRO: 'WRITER_PUBLISHER_PRO',
  WRITER_PUBLISHER_SHARE: 'WRITER_PUBLISHER_SHARE',
};

export const IMPORT_FIELDS = {
  CUE_NUMBER: 'CUE_NUMBER',
  CUE_TITLE: 'CUE_TITLE',
  USAGE: 'USAGE',
  ARTIST: 'ARTIST',
  TRACK_ID: 'TRACK_ID',
  TIME_IN: 'TIME_IN',
  TIME_IN_HH: 'TIME_IN_HH',
  TIME_IN_MM: 'TIME_IN_MM',
  TIME_IN_SS: 'TIME_IN_SS',
  TIME_OUT: 'TIME_OUT',
  TIME_OUT_HH: 'TIME_OUT_HH',
  TIME_OUT_MM: 'TIME_OUT_MM',
  TIME_OUT_SS: 'TIME_OUT_SS',
  DURATION: 'DURATION',
  DURATION_MM: 'DURATION_MM',
  DURATION_SS: 'DURATION_SS',
  ...IMPORT_RIGHTS_OWNERS_PILLS,
};

export const IMPORT_DIVIDERS = {
  SLASH: '/',
  OPEN_PARENTHESIS: '(',
  CLOSE_PARENTHESIS: ')',
  OPEN_SQUARE_BRACKET: '[',
  CLOSE_SQUARE_BRACKET: ']',
  DASH: '-',
  COLON: ':',
  SEMI_COLON: ';',
  SPACE: ' ',
  COMMA: ',',
  PERIOD: '.',
};

export const COMPARE_SECTIONS = {
  IMPORTED_DATA: 'IMPORTED_DATA',
  QTRACKS_DATA: 'QTRACKS_DATA',
};

export const IMPORT_REPEATED_ARRAY = 'IMPORT_REPEATED_ARRAY';

export const DUPLICATED_TEMPLATE_MESSAGE = 'Duplicated template';

export const IMPORT_ERRORS = {
  EDL_MAX_LINES_EXCEEDED: 'edlMaxLinesExceeded',
};
