/* eslint-disable no-bitwise */
import i18n from 'locales/i18n';

// eslint-disable-next-line @typescript-eslint/no-shadow
export enum CommonContextualPermissions {
  DELETE = 1,
  UPDATE = 2,
  READ = 4,
  CREATE = 8,
  GRANT_DELETE = 16,
  GRANT_UPDATE = 32,
  GRANT_READ = 64,
  GRANT_CREATE = 128,
  CREATE_TASKS = 256,
  ASSIGN_TASKS = 512,
  DIVISION_ADMIN = 1024,
  VIEW_REPORTS = 2048,
}

export const CommonProductionPermissions =
  CommonContextualPermissions.DELETE |
  CommonContextualPermissions.UPDATE |
  CommonContextualPermissions.READ |
  CommonContextualPermissions.CREATE |
  CommonContextualPermissions.GRANT_DELETE |
  CommonContextualPermissions.GRANT_UPDATE |
  CommonContextualPermissions.GRANT_READ |
  CommonContextualPermissions.GRANT_CREATE;

export type Role = {
  id: number,
  commonPermissions: number,
  name: string,
}

export const DivisionAdminRole: Role = {
  id: 0,
  commonPermissions: 1024,
  name: i18n.t('global.roles.divisionAdmin'),
};

export const OwnerRole: Role = {
  id: 2,
  commonPermissions: 3071,
  name: i18n.t('global.roles.owner'),
};

export const EditorRole: Role = {
  id: 3,
  commonPermissions: 2863,
  name: i18n.t('global.roles.editor'),
};

export const ReviewerRole: Role = {
  id: 4,
  commonPermissions: 772,
  name: i18n.t('global.roles.reviewer'),
};

export const NoneRole: Role = {
  id: 1,
  commonPermissions: 0,
  name: i18n.t('global.roles.none'),
};

export const Roles: Role[] = [
  DivisionAdminRole,
  OwnerRole,
  EditorRole,
  ReviewerRole,
  NoneRole,
];

export const ProductionRoles: Role[] = [
  OwnerRole,
  EditorRole,
  ReviewerRole,
];

export const RolesWithoutDivisionLevel = ProductionRoles.map(
  (role) => {
      const roleWithoutDivisionLevel: Role = {
        id: role.id,
        name: role.name,
        commonPermissions: role.commonPermissions & CommonProductionPermissions,
      };
      return roleWithoutDivisionLevel;
    },
);

export const getRole = (roleCommonPermissions: number) => {
  const role = Roles.find(
    (r) => {
      const { commonPermissions } = r;
      return (commonPermissions & roleCommonPermissions) === commonPermissions;
    },
  );
  return role || NoneRole;
};

export const getRoleWithoutDivisionLevel = (roleCommonPermissions: number) => {
  const role = RolesWithoutDivisionLevel.find(
    (r) => {
      const { commonPermissions } = r;
      return (commonPermissions & roleCommonPermissions) === commonPermissions;
    },
  );
  return role || NoneRole;
};

export type DefaultRoleDto = {
  id?: number;
  userId?: number;
  name: string;
  description: string;
  commonPermissions: number;
  cuePermissions: number;
  clearPermissions: number;
  isDivisionAdmin?: boolean;
}
