/* eslint-disable consistent-return */
/* eslint-disable no-bitwise */
/* eslint-disable no-unused-expressions */
/* eslint-disable import/no-cycle */
import { createActions } from 'redux-actions';

import identity from 'ramda/src/identity';

const actionsDefinition = {
  CLEAR_PROJECT_HIERARCHY: identity,
  SET_PROJECTS_AFTER_KEY: identity,
  SET_PROJECTS_PAGE_COUNT: identity,
  SET_PROJECTS: identity,
};

export const PROJECTS_LIMIT = 40;

export const { clearProjectHierarchy, setProjects, setProjectsAfterKey, setProjectsPageCount } =
  createActions(actionsDefinition);
