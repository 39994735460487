import { useSetAtom } from 'jotai';
import { Autocomplete, createFilterOptions, TextField } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { formatPresetToRights } from 'components/common/RightsPresetPicker';
import { FormFieldForwardedProps } from 'components/common/Form/Fields/FormField/FormField';
import { rootTranslation } from 'utils/format';
import { TPresetToRight, TRightPreset } from 'services/clear/rights-presets';
import { TProductionSeason } from '../drawerStore';
import { releaseIdAtom, seasonAtom } from './seasonStore';

type SeasonFieldProps = FormFieldForwardedProps & {
  seasonList: TProductionSeason[];
  rightsPresets: TRightPreset[];
  defaultRightsPreset: TPresetToRight;
};

type TCreateOption = { name: string; inputValue: string };

const globalT = rootTranslation('global');

const filter = createFilterOptions<Partial<TProductionSeason> | TCreateOption>();

export function SeasonField({
  value,
  onChange,
  rightsPresets,
  defaultRightsPreset,
  seasonList,
  ...rest
}: SeasonFieldProps) {
  const { setValue } = useFormContext();
  const setSeason = useSetAtom(seasonAtom);
  const setReleaseId = useSetAtom(releaseIdAtom);

  const handleSeasonSelect = (season: Partial<TProductionSeason>) => {
    onChange(season.name);
    setSeason(season);
    setValue('seasonId', season.id);
    setValue('categoryId', season.categoryId);
    setValue('duration', season.duration);

    const rightsPresetsSelected = rightsPresets.find((r) => r.name === season.rightsName);

    if (rightsPresetsSelected) {
      const rights = formatPresetToRights(rightsPresetsSelected);
      setValue('requiredRights', rights);
    } else {
      setValue('requiredRights', defaultRightsPreset);
    }

    setValue('episodes', []);
    setReleaseId(false);
  };

  const handleSeasonCreate = (title: string) => {
    onChange(title);
    setSeason({ name: title });
    setValue('season', title);
    setValue('seasonId', null);
    setValue('episodes', []);
    setReleaseId(false);
  };

  return seasonList && seasonList.length ? (
    <Autocomplete<Partial<TProductionSeason> | TCreateOption, false, false, true>
      size="small"
      value={value}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
          return option;
        }
        // Add "xxx" option created dynamically
        if ('inputValue' in option) {
          return option.inputValue;
        }
        // Regular option
        return option.name;
      }}
      options={seasonList ?? []}
      onChange={(_, newValue) => {
        if (typeof newValue === 'string') {
          handleSeasonCreate(newValue);
        } else if ('inputValue' in newValue) {
          // Create a new value from the user input
          handleSeasonCreate(newValue.inputValue);
        } else {
          handleSeasonSelect(newValue);
        }
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        const { inputValue } = params;
        // Suggest the creation of a new value
        const isExisting = options.some((option) => inputValue === option.name);
        if (inputValue !== '' && !isExisting) {
          filtered.push({
            name: `${globalT('forms.createNew')} "${inputValue}"`,
            inputValue,
          });
        }
        return filtered;
      }}
      renderInput={(params) => <TextField data-testid="typeahead-field-season" {...params} {...rest} />}
      freeSolo
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      renderOption={(props, option) => <li {...props}>{option.name}</li>}
    />
  ) : (
    <TextField
      data-testid="input-text-season"
      size="small"
      value={value}
      onChange={(e) => {
        const nextValue = e.target.value;
        onChange(nextValue);
      }}
      {...rest}
    />
  );
}
