/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { BaseDrawer } from 'components/styleguide';
import { useRootTranslation } from 'utils/hooks';
import { concatStrings } from 'utils/index';
import FormNameDrawer from '../Generic/FormNameDrawer';
import { CommonDrawerPropTypes } from '../constants';

const ProducersDrawer = ({
  name,
  onClose,
  payload: { onSubmit, producers = [], readOnly, rootT = 'drawers.productionSetup.producers' },
}) => {
  const t = useRootTranslation(rootT);
  return (
    <FormNameDrawer
      name={name}
      deletePromptContent={({ firstName, lastName }) => t('delete', { name: concatStrings(' ')(firstName, lastName) })}
      onClose={onClose}
      rootT={rootT}
      payload={{
        onSubmit: (values) =>
          onSubmit({
            producers: values,
          }),
        people: producers,
      }}
      readOnly={readOnly}
    />
  );
};

ProducersDrawer.propTypes = {
  name: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  payload: PropTypes.object.isRequired,
  rootT: PropTypes.string.isRequired,
};

const Drawer = ({ open, onClose, ...props }) => (
  <BaseDrawer open={open} onClose={onClose}>
    <ProducersDrawer onClose={onClose} {...props} />
  </BaseDrawer>
);

Drawer.propTypes = CommonDrawerPropTypes;

export default Drawer;
