import { connect } from 'react-redux';
import { getIsDialogDirtyByNameSelector, getIsDialogSubmittedByNameSelector } from 'redux-core/dialogs/selectors';
import { setDrawerDirty } from 'redux-core/dialogs/actions';

const mapStateToProps = (state, ownProps) => ({
  isDrawerFormDirty: getIsDialogDirtyByNameSelector(state, ownProps.drawerName),
  isDrawerFormSubmitted: getIsDialogSubmittedByNameSelector(state, ownProps.drawerName),
});

const mapDispatchToProps = { setDrawerDirty };

export default connect(mapStateToProps, mapDispatchToProps);
