/* eslint-disable import/no-cycle */
import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import Box from '@material-ui/core/Box';
import { Text } from 'components/styleguide';
import { InputLabel } from '@mui/material';

type LabelControlProps = {
  children: React.ReactNode;
  label: string;
  noWrap?: boolean;
  childrenAlign?: 'left' | 'center' | 'right' | 'justify';
  fullWidth?: boolean;
};

const LabelControl = ({ children, label, fullWidth, noWrap = true, childrenAlign }: LabelControlProps) => (
  <FormControl fullWidth={fullWidth} data-testid="qClear-LabelControl">
    <InputLabel>{label}</InputLabel>
    <Box alignItems="center" display="flex">
      <Text variant="subtitle2" noWrap={noWrap} align={childrenAlign}>
        {children}
      </Text>
    </Box>
  </FormControl>
);

export default LabelControl;
