/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { InputText } from 'components/styleguide';
import { useTranslation } from 'react-i18next';
import { get } from 'utils/object';
import { withFormField } from 'components/common/Form/utils';
import { FieldPropTypes } from '../prop-types';

export const Input = ({
  field,
  form,
  disabled,
  fullWidth,
  label,
  placeholder,
  readOnly,
  textArea,
  testId,
  type,
  endAdornment,
  ...props
}) => {
  const { t } = useTranslation();
  const { touched, errors, isSubmitting } = form;

  return (
    <InputText
      disabled={disabled || isSubmitting}
      error={t(get(field.name)(touched) && get(field.name)(errors))}
      fullWidth={fullWidth}
      label={t(label)}
      placeholder={t(placeholder)}
      readOnly={readOnly}
      textArea={textArea}
      type={type}
      testId={testId}
      endAdornment={endAdornment}
      {...props}
      {...field}
    />
  );
};

Input.propTypes = {
  field: FieldPropTypes.field,
  form: FieldPropTypes.form,
  disabled: FieldPropTypes.disabled,
  fullWidth: FieldPropTypes.fullWidth,
  label: FieldPropTypes.label,
  placeholder: FieldPropTypes.placeholder,
  textArea: FieldPropTypes.textArea,
  readOnly: FieldPropTypes.readOnly,
  type: FieldPropTypes.type,
  testId: PropTypes.string,
  endAdornment: PropTypes.node,
};

Input.displayName = 'InputText';

export default withFormField(Input);
