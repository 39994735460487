/* eslint-disable react/destructuring-assignment */
import { Button } from 'components/styleguide';

type CommonProps = {
  onlyOk?: boolean;
  okVariant?: 'primary' | 'secondary';
  okLabel?: string;
  t: (key: string) => string;
  onClose: (...args: unknown[] | []) => void;
  onConfirm: (...args: unknown[] | []) => void | Promise<void>;
};

type OnlyOkProps = CommonProps & {
  onlyOk: true;
  cancelLabel?: never;
  onCancel?: never;
};

type NotOnlyOkProps = CommonProps & {
  onlyOk: false;
  cancelLabel?: string;
  onCancel?: (...args: unknown[] | []) => void;
};

export type ConfirmCancelProps = OnlyOkProps | NotOnlyOkProps;

export function ConfirmCancel({
  onlyOk,
  okVariant,
  okLabel,
  cancelLabel,
  t,
  onConfirm,
  onClose,
  onCancel,
}: ConfirmCancelProps) {
  const defaultOkVariant = 'primary';

  return (
    <>
      {!onlyOk && (
        <Button
          testId="confirmation-dialog-no"
          onClick={(e) => {
            onClose(e);
            if (onCancel) onCancel(e);
          }}
          variant="dark"
          size="sm"
        >
          {cancelLabel || t('global.confirmDialog.no')}
        </Button>
      )}
      <Button testId="confirmation-dialog-yes" onClick={onConfirm} variant={okVariant || defaultOkVariant} size="sm">
        {okLabel || t('global.confirmDialog.yes')}
      </Button>
    </>
  );
}
