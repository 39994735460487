import { FieldValues, UseControllerReturn } from 'react-hook-form';
import { get } from 'lodash';

type ExtractArrayFieldErrorArgs = {
  fieldState: UseControllerReturn<FieldValues>['fieldState'];
  formState: UseControllerReturn<FieldValues>['formState'];
  arrayFieldName: string;
  index: number;
  name?: string;
};

export function extractArrayFieldError({
  fieldState,
  formState,
  arrayFieldName,
  index,
  name,
}: ExtractArrayFieldErrorArgs) {
  const formError = get(formState?.errors, [arrayFieldName, index]);
  const fieldError = fieldState.error;

  return name
    ? get(fieldError, [name, 'message']) ?? get(formError, [name, 'message'])
    : {
        fieldError,
        formError,
      };
}
