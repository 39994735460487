import { Skeleton } from '@mui/material';
import BaseOnlyOffice from 'components/common/BaseOnlyOffice';
import { useAtomValue, useSetAtom } from 'jotai';
import { composeLetterAtom } from '../composeLetterStore';
import { MissingTagsDialog } from '../MissingTagsDialog';
import { useGetLetterDocumentUrl } from './useGetLetterDocumentUrl';

export function ComposeLetterOnlyOfficeEditor({ ignoreDraft }) {
  const composeLetter = useAtomValue(composeLetterAtom);
  const setComposeLetter = useSetAtom(composeLetterAtom);

  const { isLoading, documentUrl, missedTags } = useGetLetterDocumentUrl({
    divisionId: composeLetter.divisionId,
    templateId: composeLetter.letter.templateId,
    contact: composeLetter.contact,
    letterType: composeLetter.letterType,
    clearanceBundleLicensorsIds: composeLetter.clearanceBundleLicensorsIds,
    ignoreDraft,
  });

  if (isLoading) return <Skeleton />;

  return (
    <>
      <MissingTagsDialog missedTags={missedTags} />
      <BaseOnlyOffice
        key={documentUrl}
        document={{ url: documentUrl, title: composeLetter.documentTitle }}
        events={{
          onDocumentReady: () => {
            setComposeLetter((prev) => ({ ...prev, letter: { ...prev.letter, editorReady: true } }));
          },
        }}
        onDisabled={() => {
          setComposeLetter((prev) => ({ ...prev, letter: { ...prev.letter, editorReady: false } }));
        }}
        disabled={false}
        editorConfig={undefined}
        height={undefined}
        width={undefined}
        token={undefined}
        type={undefined}
      />
    </>
  );
}
