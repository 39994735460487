import { makeStyles } from '@material-ui/core/styles';
import { topBarHeight } from 'components/styleguide/Topbar/styles';

const useStyles = makeStyles((theme) => ({
  dialog: {
    top: `${topBarHeight}!important`,
    '& > :first-child': {
      backgroundColor: 'transparent',
    },
  },
  filtersSection: {
    borderBottom: '1px solid #DDDDDD',
    marginBottom: theme.spacing(0.75),
    padding: theme.spacing(0.75, 1.25, 1, 1.5),
  },
  content: {
    padding: theme.spacing(0.75, 1.25, 1, 1.5),
  },
}));

export default useStyles;
