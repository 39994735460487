import { makeStyles } from '@material-ui/core';

const styles = makeStyles(() => ({
  root: {
    display: 'flex',
    width: '100%',
    '& > :first-child': {
      textAlign: 'center',
      textTransform: 'uppercase',
      width: '25%',
    },
    '& > :last-child': {
      width: '75%',
    },
    '&.isFilteringByProduction': {
      '& > :last-child': {
        width: '100%',
      },
    },
  },
  iconButton: {
    padding: 0,
  },
}));

export default styles;
