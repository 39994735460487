/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const LibraryTrackIcon = (props) => (
  <SvgIcon {...props} viewBox="0 0 28 28">
    <rect width="20.78" height="20.78" x="6.97" y=".25" fill="#fff" stroke="#5F6368" strokeWidth=".5" rx="1.75" />
    <rect width="20.78" height="20.78" x="3.61" y="3.669" fill="#fff" stroke="#5F6368" strokeWidth=".5" rx="1.75" />
    <rect width="20.78" height="20.78" x=".25" y="6.97" fill="#fff" stroke="#5F6368" strokeWidth=".5" rx="1.75" />
    <path
      fill="#5F6368"
      stroke="#5F6368"
      d="M10.546 11.025c0-.359 1.424-.833 1.774-.944v11.798c0 1.205-1.847 1.642-2.8 1.642-.954 0-2.8-.437-2.8-1.642 0-1.206 1.193-1.642 2.146-1.642.578 0 1.155.103 1.68.334v-9.546z"
    />
  </SvgIcon>
);

export default LibraryTrackIcon;
