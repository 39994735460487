import React, { useState } from 'react';
import { Button, Tab, Tabs as MuiTabs, Typography, SxProps, Theme } from '@mui/material';
import Arrow from '@material-ui/icons/KeyboardArrowDown';
import classnames from 'classnames';

import useStyles from './styles';

type TabsProps = {
  tabSx?: SxProps<Theme>;
  style?: React.CSSProperties;
  /** Tab titles */
  tabList: string[];
  /** function to execute when a tab is clicked. Params: tab */
  onTabChange: (value: unknown) => void;
  isTabDisabled?: (index: number) => boolean;
  /** Enables collapsable content */
  hideVisible?: boolean;
  /** Current active tab */
  currentTab: string;
  disabled?: boolean;
  /** Function to call when content is collapsed or expanded */
  onToggleOpen?: () => void;
  /** Render a component on the right */
  EndAdornment?: React.FunctionComponent<{ currentTab: string }>;
  variant?: 'fullWidth' | 'scrollable' | 'standard';
  children: React.ReactNode;
  hideLabel: string;
  showLabel: string;
};

const Tabs = ({
  children,
  currentTab,
  disabled,
  EndAdornment,
  hideLabel = 'HIDE',
  hideVisible,
  isTabDisabled = () => false,
  showLabel = 'SHOW',
  tabList = [],
  variant = 'standard',
  onTabChange,
  onToggleOpen = () => {},
  style,
  tabSx,
}: TabsProps) => {
  const [open, setOpen] = useState(true);
  const classes = useStyles();

  const handleChange = (_, value) => {
    onTabChange?.(value);
    setOpen(true);
  };

  const handleToggle = () => {
    setOpen(!open);
    onToggleOpen();
  };

  return (
    <div className={classes.tabs}>
      <MuiTabs value={currentTab} onChange={handleChange} variant={variant} style={style}>
        {tabList.map((tab, index) => (
          <Tab
            data-testid={`tab-${tab}`}
            disabled={disabled || isTabDisabled(index)}
            key={`tab-${tab}`}
            label={tab}
            sx={tabSx}
          />
        ))}
      </MuiTabs>
      {hideVisible ? (
        <span className={classes.hideContainer} data-testid="hide-visible">
          <Button onClick={handleToggle} endIcon={<Arrow className={classnames(classes.arrow, { open })} />}>
            <Typography className={classes.hideButton}>{open ? hideLabel : showLabel}</Typography>
          </Button>
        </span>
      ) : null}
      {EndAdornment && (
        <div
          className={classnames({
            [classes.endAdornment]: !hideVisible,
            [classes.endAdornmentPullLeft]: hideVisible,
          })}
          data-testid="end-adornment-wrapper"
        >
          <EndAdornment currentTab={currentTab} />
        </div>
      )}
      {open && children}
    </div>
  );
};

export default Tabs;
