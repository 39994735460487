import { useState } from 'react';
import { Box } from '@mui/material';
import { useGetEpisodesQuery } from 'services/common/episodes';
import { NORMALIZED_PRODUCTION_TYPES } from 'utils/constants';
import ProductionFilter from '../ProductionFilter';
import { SeasonFilter } from './SeasonFilter';
import { SeasonReleaseFilter } from './SeasonReleaseFilter';

type TOnChangePayload = {
  seasonId?: number;
  releaseId?: number;
} | {
  productionIds: number[] | 'all';
}

type TOnChangeEpisodes = {
  id: number;
  name: string;
}

type TTVFilterProps = {
  divisionId: number;
  projectId?: number;
  onChange: (payload: TOnChangePayload, episodes?: TOnChangeEpisodes[]) => void;
}

const TVFilter = ({
  divisionId,
  projectId,
  ...props
}: TTVFilterProps) => {
  const [season, setSeason] = useState<{ id: number, name: string }>(null);
  const [seasonRelease, setSeasonRelease] = useState<{ id: number, name: string }>(null);

  const { data: episodes, isLoading: isLoadingEpisodes } = useGetEpisodesQuery(
    { parentId: seasonRelease?.id, divisionId },
    { skip: !seasonRelease || !seasonRelease.id },
  );

  const handleSeasonChange = (nextValue: { id: number, name: string }) => {
    setSeason(nextValue);
    props.onChange({ seasonId: nextValue.id });
  };

  const handleSeasonReleaseChange = (nextValue: { id: number, name: string }) => {
    setSeasonRelease(nextValue);
    props.onChange({ releaseId: nextValue.id });
  };

  return (
    <>
      <Box pb={2}>
        <SeasonFilter value={season} divisionId={divisionId} projectId={projectId} onChange={handleSeasonChange} />
      </Box>
      {season && season.id &&
      <Box pb={2}>
        <SeasonReleaseFilter
          value={seasonRelease}
          seasonId={season.id}
          onChange={handleSeasonReleaseChange}
        />
      </Box>
      }
      {seasonRelease && seasonRelease.id && (
        <ProductionFilter
          {...props}
          testId="episode"
          onChange={(event) => {
            props.onChange({ productionIds: event.productionIds }, episodes);
          }}
          disabled={isLoadingEpisodes || !episodes}
          productions={episodes}
          type={NORMALIZED_PRODUCTION_TYPES.TV}
        />
      )}
    </>
  );
};

export default TVFilter;
