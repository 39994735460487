/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { Text, Row, ToggleButton } from 'components/styleguide';
import DateRangeIcon from '@material-ui/icons/DateRange';
import { Grid, Box } from '@material-ui/core';
import { useRootTranslation } from 'utils/hooks';
import { FormToggleButtonGroup, FormInputText } from 'components/common/Form/Fields/index';
import { RIGHTS_TERRITORIES_TERM, RIGHTS_TERRITORIES_TERM_ENUM } from '../../constants';
import useStyles from '../styles';

const { ONE_DAY, DATE_RANGE, INPERPETUITY, OTHER } = RIGHTS_TERRITORIES_TERM;

const TermOptions = ({ index, isEditing, readOnly, placeholder }) => {
  const t = useRootTranslation('rights.rowExpanded');
  const tRow = useRootTranslation('rights.row');
  const classes = useStyles();
  const {
    values: {
      territories: {
        [index]: { term },
      },
    },
  } = useFormikContext();
  const getFieldName = (field) => `territories.${index}.${field}`;

  return (
    <Row divider>
      <Grid container className={classes.avoidOverlap}>
        <Grid item xs={12}>
          <Row display="flex" alignItems="center">
            <Box mr={1}>
              <DateRangeIcon />
            </Box>
            <Text bold variant="h6">
              {t('term')}
            </Text>
          </Row>
        </Grid>
        <Grid item xs={12}>
          <Row>
            <FormToggleButtonGroup name={getFieldName('term')}>
              <ToggleButton
                value={RIGHTS_TERRITORIES_TERM_ENUM.ONE_DAY}
                disabled={!isEditing}
                name={ONE_DAY}
                testId={ONE_DAY}
              >
                {tRow(ONE_DAY)}
              </ToggleButton>
              <ToggleButton
                value={RIGHTS_TERRITORIES_TERM_ENUM.DATE_RANGE}
                disabled={!isEditing}
                name={DATE_RANGE}
                testId={DATE_RANGE}
              >
                {tRow(DATE_RANGE)}
              </ToggleButton>
              <ToggleButton
                value={RIGHTS_TERRITORIES_TERM_ENUM.INPERPETUITY}
                disabled={!isEditing}
                name={INPERPETUITY}
                testId={INPERPETUITY}
              >
                {tRow(INPERPETUITY)}
              </ToggleButton>
              <ToggleButton
                value={RIGHTS_TERRITORIES_TERM_ENUM.OTHER}
                disabled={!isEditing}
                name={OTHER}
                testId={OTHER}
              >
                {tRow(OTHER)}
              </ToggleButton>
            </FormToggleButtonGroup>
          </Row>
        </Grid>
        {term === RIGHTS_TERRITORIES_TERM_ENUM.OTHER && (
          <Grid item xs={12} pb={5}>
            <Row>
              <Text bold variant="subtitle2">
                {t('otherDetails')}
              </Text>
              <FormInputText
                name={getFieldName('otherDetails')}
                testId="otherDetails"
                textArea
                rows={2}
                readOnly={readOnly}
                placeholder={placeholder}
              />
            </Row>
          </Grid>
        )}
      </Grid>
    </Row>
  );
};

TermOptions.propTypes = {
  index: PropTypes.number.isRequired,
  isEditing: PropTypes.bool,
  readOnly: PropTypes.bool,
  placeholder: PropTypes.string,
};

export default TermOptions;
