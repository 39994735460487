/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-duplicates */
import React from 'react';
import PropTypes from 'prop-types';
import { useRootTranslation } from 'utils/hooks';
import { Grid, IconButton } from '@material-ui/core';
import { Text } from 'components/styleguide';
import ClickWrapper from 'components/common/ClickWrapper';
import classnames from 'classnames';
// Icons
import { LibraryTrack, SoundEffect, Track } from 'components/styleguide';
import { TRACK_TYPES } from 'utils/constants';
import useStyles from './styles';

const Icon = ({ icon, label, onChange, selected, rootT, name, readOnly }) => {
  const t = useRootTranslation(rootT);
  const classes = useStyles();
  const handleClick = () => !readOnly && onChange(!selected);
  return (
    <Grid alignItems="center" container direction="column" item name={name} xs={3}>
      <ClickWrapper onClick={handleClick}>
        <IconButton
          variant="outlined"
          size="small"
          className={classnames(classes.icon, {
            selected,
          })}
          disableRipple
          disabled={readOnly}
        >
          {icon}
        </IconButton>
        <Text variant="tiny" color="textSecondary">
          {t(label)}
        </Text>
      </ClickWrapper>
    </Grid>
  );
};

const icons = [
  { icon: <Track />, label: 'tracks', name: TRACK_TYPES.SONG },
  { icon: <LibraryTrack />, label: 'libraryTracks', name: TRACK_TYPES.LIBRARY },
  {
    icon: <SoundEffect />,
    label: 'soundEffects',
    name: TRACK_TYPES.SOUND_EFFECT,
  },
];

const TrackTypeFilter = ({ onChange, filter = {}, rootT, readOnly }) => {
  const classes = useStyles();
  const handleOnChangeType = (name) => (value) => {
    onChange(name)(value);
    freshpaint.track('Selected Track Type', { type: name });
  };

  return (
    <Grid item container className={classes.iconsContainer}>
      {icons.map(({ name, ...props }) => (
        <Icon
          readOnly={readOnly}
          key={name}
          {...props}
          onChange={handleOnChangeType(name)}
          selected={Boolean(filter[name])}
          rootT={rootT}
        />
      ))}
    </Grid>
  );
};

TrackTypeFilter.propTypes = {
  filter: PropTypes.shape({
    library: PropTypes.bool,
    score: PropTypes.bool,
    sound_effects: PropTypes.bool,
    tracks: PropTypes.bool,
  }),
  name: PropTypes.string,
  onChange: PropTypes.func,
  rootT: PropTypes.string,
};

TrackTypeFilter.defaultProps = {
  rootT: 'drawers.productionSetup.tracks.filter',
  filter: {},
};

export default TrackTypeFilter;
