/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/no-children-prop */
import React from 'react';
import PropTypes from 'prop-types';
import { FormButton } from 'components/common/Form/Fields/index';
import { useDrawer, usePrompt, useRootTranslation } from 'utils/hooks';
import { Button } from 'components/styleguide';
import FormDrawerHeader from 'components/common/Form/Fields/FormDrawerHeader/index';
// eslint-disable-next-line import/no-named-as-default
import DRAWERS from '../../constants';
import enhancer from './enhancer';
import TaskDrawer, { TaskDrawerPayloadPropTypes } from '../index';
import { TaskComments } from '../components';

const ViewTaskDrawerHeader = ({ onClose, payload, deleteTask }) => {
  const tGlobal = useRootTranslation('global');
  const t = useRootTranslation('drawers.tasks.create');
  const openDrawer = useDrawer();
  const openPrompt = usePrompt();

  const handleClose = () => {
    onClose();
  };

  const handleDelete = () => {
    openPrompt({
      onConfirm: async () => {
        await deleteTask({ id: payload.taskId });
        await payload.getTabTasks();
        onClose();
      },
      content: tGlobal('prompt.delete'),
    });
  };

  return (
    <FormDrawerHeader title={t('task')} onClose={handleClose}>
      <FormButton
        alwaysEnabled
        variant="destructive"
        size="xs"
        testId="view-task-drawer-delete"
        children={tGlobal('forms.delete')}
        onClick={handleDelete}
      />
      <Button
        variant="primary"
        testId="view-task-drawer-edit"
        size="xs"
        children={tGlobal('forms.edit')}
        onClick={() => openDrawer(DRAWERS.TASK_EDIT, payload)}
      />
    </FormDrawerHeader>
  );
};

const ViewTaskDrawer = ({ onClose, deleteTask, payload }) => (
  <TaskDrawer
    payload={payload}
    readOnly
    headerContent={() => <ViewTaskDrawerHeader payload={payload} onClose={onClose} deleteTask={deleteTask} />}
  >
    <TaskComments taskId={payload.taskId} getTabTasks={payload.getTabTasks} />
  </TaskDrawer>
);

ViewTaskDrawer.propTypes = {
  onClose: PropTypes.func.isRequired,
  deleteTask: PropTypes.func.isRequired,
  payload: TaskDrawerPayloadPropTypes.isRequired,
};

ViewTaskDrawerHeader.propTypes = {
  ...ViewTaskDrawer.propTypes,
  getTabTasks: PropTypes.func.isRequired,
};

export default enhancer(ViewTaskDrawer);
