import { useCallback } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { useTranslation } from 'react-i18next';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { Typography } from '@material-ui/core';
import { Box } from '@mui/system';
import { useLazySearchProjectsQuery, TProject } from 'services/common/projects';
import { TextField } from '@mui/material';
import { FormControlLabelWrapper } from 'components/common/Form/Fields/FormField/FormField';
import { TDivision } from './drawerStore';

type AutocompleteProjectFieldProps = {
  division: TDivision | null;
  label: string;
  testId: string;
  value: string | number;
  onChange: (value: TProject) => void;
  disabled: boolean;
  existingOnly: boolean;
  existingProject: TProject | false;
};

type TOption = TProject & { label?: string };

const AutocompleteProjectField = ({
  division = null,
  value,
  label,
  onChange,
  testId,
  disabled,
  existingOnly,
  existingProject,
}: AutocompleteProjectFieldProps) => {
  const tenantId = division?.tenantId;
  const { t } = useTranslation();
  const filter = createFilterOptions();
  const [searchProjects, { data, isLoading }] = useLazySearchProjectsQuery();

  const debouncedCallback = useDebouncedCallback(async (term: string) => {
    searchProjects({ term, ...(tenantId && { tenantId }) });
  }, 300);

  const handleFilterOptions = useCallback(
    (options, params) => {
      const filtered = filter(options, params);
      const { inputValue } = params;
      const inputTrimed = inputValue.trim();

      if (inputTrimed.length > 0 && !existingOnly) {
        filtered.push({
          name: inputTrimed,
          label: `${t('projects.newProduction.createNew')} '${inputTrimed}'`,
        });
      }

      return filtered;
    },
    [existingOnly, filter, t],
  );

  return (
    <>
      <FormControlLabelWrapper name={label} label={label} disabled={disabled}>
        <Autocomplete<TOption, false, true, true>
          loading={isLoading}
          // @ts-ignore
          value={value}
          isOptionEqualToValue={(option, optionValue) => option.name === optionValue.name}
          options={data?.items ?? []}
          disabled={disabled}
          onChange={(event, option: TProject) => {
            onChange(option);
          }}
          filterOptions={(options, params) => handleFilterOptions(options, params)}
          onInputChange={(event, val) => {
            if (!event || event.type === 'click') return;
            // @ts-ignore
            onChange(val);
            debouncedCallback(val);
          }}
          getOptionLabel={(option) => {
            if (typeof option === 'string') {
              return option;
            }
            return option.name;
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              disabled={disabled}
              placeholder={t('projects.newProduction.title')}
              data-testid={`autocomplete-field-${testId}`}
            />
          )}
          /**
           * The renderOption is used to display the creatable option label: Create New 'value' –
           * added on handleFilterOptions
           */
          renderOption={(props, option) => (
            <li {...props} key={option.id} data-testid={option.id ? 'existing-project' : 'new-project'}>
              {option.label || option.name}
            </li>
          )}
          handleHomeEndKeys
          freeSolo
          openOnFocus
          disableClearable
        />
      </FormControlLabelWrapper>
      {existingOnly && !existingProject && (
        <Box py={1}>
          <Typography display="block" gutterBottom>
            {t('drawers.quickSetupProduction.exportSegmentSteps.existingOnly')}
          </Typography>
        </Box>
      )}
    </>
  );
};

export default AutocompleteProjectField;
