/* eslint-disable react/no-children-prop */
/* eslint-disable @typescript-eslint/no-shadow */
import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useRootTranslation } from 'utils/hooks';
import { Row, Button, DrawerHeader } from 'components/styleguide';
import {
  FormButton,
  FormSearchList,
  FormInputText,
  FormCheckbox,
  FormInputFormat,
} from 'components/common/Form/Fields';
import { SecuredForm } from 'components/common/Secured';
import Grid from '@material-ui/core/Grid';
import { getQwireTracksArtistsCall } from 'redux-core/tracks/services';
import { TRACK_TYPES, NONE_OPTION, ENTER_KEY, ESC_KEY } from 'utils/constants';
import { COMMON_ROLES } from 'redux-core/permissions/constants';
import { isNumeric } from 'utils/object';
import identity from 'ramda/src/identity';
import not from 'ramda/src/not';
import propEq from 'ramda/src/propEq';
import LicensorsField from './LicensorsField';
import enhancer from './enhancer';

const parseArtist = ({ id, ...rest }) => ({ ...rest, qwireTracksArtistId: id });

const validationSchema = Yup.object().shape({
  track: Yup.object().shape({
    rightsOwners: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required('global.forms.validations.required'),
      }),
    ),
  }),
});

const CueSheetDetailsDrawer = ({
  name,
  payload: { cue, objectId, track, autoFocus, onSubmit },
  onClose,
  updateCue,
}) => {
  const t = useRootTranslation('drawers.cueSheetDetails');
  const tGlobal = useRootTranslation('global');

  const isReadOnly = propEq('isEditable', false, track);

  const titleFieldRef = useRef(null);

  useEffect(() => {
    if (titleFieldRef.current) {
      setTimeout(() => titleFieldRef.current.focus(), 1);
    }
  }, []);

  const handleClose = () => {
    onClose();
  };
  const onKeyDown = (keyEvent) => {
    const { charCode, keyCode } = keyEvent;

    switch (charCode || keyCode) {
      case ENTER_KEY:
        return keyEvent.preventDefault();

      case ESC_KEY:
        return handleClose();

      default:
        return null;
    }
  };

  const handleSubmit = async ({ cue, track }) => {
    const parsedValues = {
      ...cue,
      cdNumber: isNumeric(cue.cdNumber) ? Number(cue.cdNumber) : cue.cdNumber,
      cutNumber: isNumeric(cue.cutNumber) ? Number(cue.cutNumber) : cue.cutNumber,
      theme: cue.theme !== NONE_OPTION.id ? cue.theme : null,
      usageDetail: cue.usageDetail !== NONE_OPTION.id ? cue.usageDetail : null,
      track,
    };
    if (isNumeric(cue.id)) await updateCue(parsedValues);
    if (onSubmit) {
      const { track, ...cue } = parsedValues;
      await onSubmit({ cue, track });
    }
    onClose();
  };

  const initialValues = {
    cue: {
      ...cue,
      theme: cue.theme ?? NONE_OPTION.id,
      usageDetail: cue.usageDetail ?? NONE_OPTION.id,
    },
    track,
  };

  return (
    <SecuredForm
      onKeyDown={onKeyDown}
      drawerName={name}
      enableReinitialize
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
      objectId={objectId}
      permissions={COMMON_ROLES.EDITOR}
    >
      <DrawerHeader title={t('drawerTitle')} hideBackButton>
        <Button
          variant="destructive"
          testId="close-button"
          size="xs"
          children={tGlobal('forms.cancel')}
          type="button"
          onClick={handleClose}
        />
        <FormButton variant="primary" size="xs" children={tGlobal('forms.save')} testId="save-button" type="submit" />
      </DrawerHeader>
      <Grid container>
        <Row xs={8} spacing={2}>
          <Grid item xs={12}>
            <FormInputText
              readOnly={isReadOnly}
              label={t('title')}
              name="track.title"
              testId="cue-sheet-track-title"
              autoFocus={autoFocus === 'track.title'}
              inputRef={titleFieldRef}
            />
          </Grid>
        </Row>
        <Row xs={8} spacing={2}>
          <Grid item xs={12}>
            <FormSearchList
              readOnly={isReadOnly}
              call={getQwireTracksArtistsCall}
              name="track.artists"
              isMultiple
              label={t('artists')}
              testId="cue-sheet-track-info-artists"
              parseItem={parseArtist}
              disabled={track.type === TRACK_TYPES.SCORE}
              createCall={identity}
              isCreateDisabled={not}
            />
          </Grid>
        </Row>
        <Row xs={8} spacing={2}>
          <Grid item xs={12}>
            <FormInputText
              label={t('notes')}
              name="cue.usageNotes"
              textArea
              testId="cue-sheet-usageNotes"
              autoFocus={autoFocus === 'cue.usageNotes'}
            />
          </Grid>
        </Row>
        <Row xs={8} spacing={2}>
          <Grid item xs={12}>
            <FormInputText readOnly={isReadOnly} label={t('iswc')} name="cue.iswc" testId="cue-sheet-iswc" />
          </Grid>
        </Row>
        <Row xs={8} spacing={2}>
          <Grid item xs={12}>
            <FormInputText readOnly={isReadOnly} label={t('isrc')} name="track.isrc" testId="cue-sheet-track-isrc" />
          </Grid>
        </Row>
        {track.type === TRACK_TYPES.LIBRARY && (
          <Row xs={8} spacing={2}>
            <Grid item xs={6}>
              <FormInputFormat
                readOnly={isReadOnly}
                decimalScale={0}
                type="string"
                label={t('cutNumber')}
                name="cue.cutNumber"
                testId="cue-sheet-cutNumber"
              />
            </Grid>
            <Grid item xs={6}>
              <FormInputFormat
                readOnly={isReadOnly}
                decimalScale={0}
                type="string"
                label={t('cdNumber')}
                name="cue.cdNumber"
                testId="cue-sheet-cdNumber"
              />
            </Grid>
          </Row>
        )}
        <Row xs={8} spacing={2}>
          <Grid item xs={12}>
            <FormCheckbox
              readOnly={isReadOnly}
              label={t('explicit')}
              name="cue.explicit"
              testId="cue-sheet-cue-explicit"
            />
          </Grid>
        </Row>
        <LicensorsField readOnly={isReadOnly} name="track.rightsOwners" />
      </Grid>
    </SecuredForm>
  );
};

CueSheetDetailsDrawer.propTypes = {
  name: PropTypes.string.isRequired,
  payload: PropTypes.shape({
    cue: PropTypes.object,
    track: PropTypes.object,
  }),
  onClose: PropTypes.func.isRequired,
  updateCue: PropTypes.func.isRequired,
};

export default enhancer(CueSheetDetailsDrawer);
