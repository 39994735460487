import { createTheme } from '@material-ui/core/styles';
import { font, palette } from './newTheme';

export const BORDER_RADIUS = '19px';

export const appColors = {
  black: '#000000',
  blue: '#56CCF2',
  calmBlue: '#5D8FB1',
  cerulean: '#009DFB',
  dark: '#312733',
  darkBlue: '#0891E3',
  darkGreen: '#628C61',
  darkOrange: '#E66339',
  darkPurple: '#9B51E0',
  disabled: '#CDCDCD',
  green: '#70AE6F',
  grey: '#5F6368',
  greyEmptyState: '#B4B4B4',
  rowhoverBlue: '#e6f2fa',
  lightBlue: '#E5F4FF',
  lightGrey: '#E9E9E9',
  darkWhite: '#f4f4f4',
  lightPurple: '#BB6BD9',
  lightRed: '#EB5757',
  navbarGrey: '#C0C0C0',
  orange: '#F2994A',
  red: '#C83C44',
  tab: '#FA3657',
  white: '#FFFFFF',
  yellow: '#E8BB49',
  qwireGradientOne: '#282078',
  qwireGradientTwo: '#11103b',
  lightGradient: '#fb2c5d',
  successDark: '#1B5E20',
};

export const customShadows = {
  onlyBottom: '0px 6px 6px -5px rgba(0,0,0,0.75)',
};

export const zIndexLevels = {
  zero: 0,
  one: 100,
  two: 200,
  three: 300,
  four: 400,
  five: 500,
  six: 600,
  seven: 700,
  eight: 800,
  nine: 900,
  ten: 1000,
  // below this comment are the default MU values
  mobileStepper: 1000,
  speedDial: 1050,
  appBar: 1100,
  drawer: 1200,
  modal: 1300,
  snackbar: 1400,
  tooltip: 1500,
};

export const getSplitRadius = ({ split }) => {
  switch (split) {
    case 'left':
      return `${BORDER_RADIUS} 0 0 ${BORDER_RADIUS}`;
    case 'right':
      return `0 ${BORDER_RADIUS} ${BORDER_RADIUS} 0`;
    case 'middle':
      return 0;
    default:
      return undefined;
  }
};

const theme = createTheme({
  transitions: {
    duration: {
      standard: 300,
    },
  },
  palette,
  typography: {
    h3: { fontSize: 23, lineHeight: 'unset' },
    h4: { fontSize: 22 },
    h5: { fontSize: 20 },
    h6: { fontSize: 18 },
    button: { fontSize: 12 },
    overline: {
      fontSize: 10,
      letterSpacing: 'normal',
      lineHeight: 'normal',
      textTransform: 'none',
    },
    allVariants: {
      fontFamily: font,
    },
  },
  overrides: {
    MuiTabs: {
      root: {
        backgroundColor: appColors.white,
      },
      indicator: {
        backgroundImage: 'linear-gradient(to top, #fb2c5d, #fa7437)',
        height: '4px',
      },
    },
    MuiOutlinedInput: {
      input: {
        borderRadius: 4,
        fontSize: '1rem',
        padding: '0.5315rem 0.75rem',
        '&.rtl': {
          direction: 'rtl',
        },
      },
      adornedEnd: {
        paddingRight: 4,
      },
      adornedStart: {
        paddingRight: 20,
      },
      multiline: {
        color: appColors.grey,
        fontSize: '1rem',
        padding: '1rem 0.75rem',
      },
      root: {
        backgroundColor: appColors.white,
        borderRadius: 4,
        '&, & > fieldset': {
          borderRadius: 4,
        },
        '&.noBorder $notchedOutline': {
          borderColor: 'transparent',
        },
        '&:hover $notchedOutline': {
          borderColor: appColors.grey,
        },
        '&$focused $notchedOutline': {
          borderColor: palette.primary.main,
          borderWidth: 2,
        },
        '&.Mui-disabled': {
          cursor: 'not-allowed',
          color: palette.text.disabled,
        },
        '&.readOnly': {
          backgroundColor: 'transparent',
          '&, & > fieldset': {
            border: 0,
            paddingLeft: 0,
          },
          '&, & > input': {
            paddingLeft: 0,
            paddingRight: 0,
          },
        },
      },
    },
    MuiFormControl: {
      root: {
        '&.xs': { width: 85 },
        '&.sm': { width: 106 },
        '&.xl': { width: 286 },
      },
    },
    MuiInputLabel: {
      formControl: {
        color: appColors.dark,
        fontSize: '0.875rem',
        fontWeight: 600,
        marginBottom: 8,
        position: 'initial',
        // ellipsis styles
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        '&$focused': {
          color: appColors.dark,
        },
        '&$error': {
          color: appColors.dark,
        },
        '&$disabled': {
          color: appColors.dark,
        },
      },
      asterisk: {
        '&$error': {
          color: appColors.dark,
        },
      },
    },
    MuiFormHelperText: {
      root: {
        letterSpacing: 'normal',
        fontSize: '0.875rem',
        lineHeight: 1.4,
      },
    },
    MuiTab: {
      root: {
        textTransform: 'capitalize',
        height: '50px',
        fontWeight: 600,
        minWidth: '170px',
        fontSize: '14px',
        borderBottom: `4px solid ${appColors.darkWhite}`,
        '&:hover': {
          borderBottom: `4px solid ${appColors.tab}`,
          '& > span > span > span': {
            position: 'relative',
            top: '1px',
          },
        },
      },
      textColorInherit: {
        fontSize: '14px',
        opacity: 1,
        '&$selected': {
          color: appColors.tab,
          backgroundColor: appColors.white,
        },
      },
    },
    MuiTableCell: {
      stickyHeader: {
        backgroundColor: appColors.white,
        zIndex: 1,
      },
    },
    MuiTableRow: {
      hover: {
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: `${palette.action.hover} !important`,
        },
      },
      root: {
        '&$selected': {
          backgroundColor: appColors.lightBlue,
          '& > td': {
            borderBottom: '1.5px solid',
            borderBottomColor: palette.primary.main,
            borderTop: '1.5px solid',
            borderTopColor: palette.primary.main,
          },
        },
      },
    },
    MuiTableSortLabel: {
      icon: {
        color: `${palette.primary.main}!important`,
      },
    },
    MuiTypography: {
      root: {
        '&.bold': { fontWeight: 700 },
        '&.clickable': { cursor: 'pointer' },
        '&.italic': { fontStyle: 'italic' },
        '&.link': { color: appColors.blue },
        '&.semibold': { fontWeight: 600 },
        '&.underlined': { textDecoration: 'underline' },
      },
      noWrap: {
        width: '100%',
      },
    },
    MuiDivider: {
      root: {
        width: '100%',
      },
    },
    MuiExpansionPanelSummary: {
      root: {
        backgroundColor: appColors.white,
      },
    },
    MuiFormControlLabel: {
      label: {
        '&.fullWidth': {
          width: '100%',
        },
      },
    },
    MuiStepIcon: {
      root: {
        color: `${palette.primary.main}!important`,
      },
    },
    MuiPickersDay: {
      dayDisabled: { borderColor: 'transparent' },
      daySelected: { backgroundColor: `${appColors.darkPurple}!important` },
      current: {
        border: '1px solid',
        borderColor: appColors.darkPurple,
        color: 'inherit',
      },
    },
    MuiPickersCalendarHeader: {
      iconButton: {
        color: palette.primary.main,
      },
    },
  },
  zIndex: zIndexLevels,
});

export default theme;
