import { baseApi } from 'services';

export type GetAllAttachmentsByLicensorsDto = {
  clearanceBundleLicensorsIds: Array<number>;
};

export type ProductionAttachmentResponseDto = {
  id: number;
  productionObjectId: number;
  fileName: string;
  licensorName: string;
  quoteExpiration: string;
  qclearClearanceId: number;
  qclearTrackId: number;
  qclearTrackTitle: string;
  qclearTrackArtists: Array<string>;
  qclearLicensorId: number;
  qclearLetterId: number;
  fileDescription: string;
  clearanceName: string;
  fileSize: number;
  uploadedFileId: number;
  hasLetter: boolean;
  hasEmail: boolean;
  createdAt: string;
};

export const productionAttachmentsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    // eslint-disable-next-line max-len
    getProductionAttachmentsByLicensors: builder.query<ProductionAttachmentResponseDto[], GetAllAttachmentsByLicensorsDto>({
      query: (body) => ({ url: 'clear/production-attachments/get-all-by-licensors', method: 'POST', body }),
    }),
  }),
});

export const { useGetProductionAttachmentsByLicensorsQuery } = productionAttachmentsApi; // change this
