import { connect } from 'react-redux';
import { closeAllDialogs } from 'redux-core/dialogs/actions';
import {
  cleanSetup,
  saveSeasonRelease,
  updateSeasonRelease,
  keepReleaseInfo,
  getSeasonRelease,
  savePosterId,
} from 'redux-core/productions/actions';
import {
  getSetupFlowInfoSelector,
  getSetupFlowSeasonReleaseSelector,
  getDivisionId,
  getTenantId,
} from 'redux-core/productions/selectors';
import { getLocationKeySelector } from 'redux-core/router/selectors';
import { updateReleaseOrEpisode } from 'redux-core/overview/actions';
import { openPrompt } from 'redux-core/prompt/actions';
import { getDialogRightsByNameSelector } from 'redux-core/dialogs/selectors';

const mapStateToProps = (state, ownProps) => ({
  setupInfo: getSetupFlowInfoSelector(state),
  locationKey: getLocationKeySelector(state),
  release: getSetupFlowSeasonReleaseSelector(state),
  divisionId: getDivisionId(state),
  tenantId: getTenantId(state),
  rights: getDialogRightsByNameSelector(state, ownProps.name),
});

const mapDispatchToProps = {
  cleanSetup,
  closeAllDialogs,
  saveSeasonRelease,
  updateSeasonRelease,
  openPrompt,
  updateReleaseOrEpisode,
  keepReleaseInfo,
  getSeasonRelease,
  savePosterId,
};

export default connect(mapStateToProps, mapDispatchToProps);
