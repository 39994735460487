/* eslint-disable import/no-cycle */
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { fetchBookmarkList } from 'redux-core/header/actions';
import { getBookmarkListSelector } from 'redux-core/header/selectors';
import withStyles from './styles';

const mapDispatchToProps = (dispatch) => bindActionCreators({ fetchBookmarkList }, dispatch);

const mapStateToProps = (state) => ({
  bookmarkList: getBookmarkListSelector(state),
});

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles);
