/* eslint-disable consistent-return */
import { Box, Grid, Table, TableBody, TableHead, TableCell, TableRow } from '@material-ui/core';

import { Text } from 'components/styleguide';
import { getCampaignAssetsCall } from 'redux-core/cueContainers/services';
import { useFetch, useRootTranslation, useSnackbar } from 'utils/hooks';

export const MarketingDetails = ({ selected }) => {
  const t = useRootTranslation('globalSearch.details.marketing');

  const showSnackbar = useSnackbar();

  const [{ data: assets }, loading] = useFetch(
    () => {
      if (!selected?.productionId) {
        return;
      }
      return getCampaignAssetsCall({
        campaignId: selected.productionId,
        active: true,
      });
    },
    [selected],
    {},
  );

  if (loading || !assets) {
    return null;
  }

  if (assets.length === 0) {
    showSnackbar({
      message: 'Unable to build screen due to missing assets.',
    });

    return null;
  }

  return (
    <Grid item xs={12}>
      <Box mt={2}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Text bold>{t('assetName')}</Text>
              </TableCell>
              <TableCell>
                <Text bold>{t('assetType')}</Text>
              </TableCell>
              <TableCell>
                <Text bold>{t('version')}</Text>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {assets.map((asset) => {
              const { version, title: versionTitle } = asset.currentCueContainerVersion;
              return (
                <TableRow>
                  <TableCell>{asset.title}</TableCell>
                  <TableCell>{asset.materialType?.name || '--'}</TableCell>
                  <TableCell>
                    {version} - {versionTitle}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Box>
    </Grid>
  );
};
