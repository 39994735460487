import { useCallback } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Grid, Skeleton } from '@mui/material';
import { getDownloadURL } from 'components/common/BaseOnlyOffice/utils';
import { useAtomValue, useSetAtom } from 'jotai';
import { FormProvider, useForm } from 'react-hook-form';
import { TExistingContact, TSaveDraftLetterRequest, useSaveLetterDraftMutation } from 'services/clear/letters';
import * as yup from 'yup';
import { ComposeLetterLeftPanel } from '../ComposeLetterLeftPanel';
import { ComposeLetterRightPanel } from '../ComposeLetterRightPanel';
import { composeLetterAtom, TComposeLetterWriteLetterContentStepForm, composeLetterSteps } from '../composeLetterStore';
import { ComposeLetterOnlyOfficeEditor } from './ComposeLetterOnlyOfficeEditor';
import { ComposeLetterSelectLetterTemplate } from './ComposeLetterSelectLetterTemplate';
import { useGetLetterTemplatesAndSetDefault } from './useGetLetterTemplatesAndSetDefault';

const schema = yup.object().shape({
  templateId: yup.number().required('global.forms.validations.required'),
  clearanceStatusId: yup.number().required('global.forms.validations.required'),
  letterFileType: yup.string().oneOf(['pdf', 'docx']).required('global.forms.validations.required'),
  docxUrl: yup.string(),
  pdfUrl: yup.string(),
});

export function ComposeLetterWriteLetterContentStep() {
  const composeLetter = useAtomValue(composeLetterAtom);
  const updateComposeLetter = useSetAtom(composeLetterAtom);
  const [saveDraft] = useSaveLetterDraftMutation();

  const formBag = useForm<TComposeLetterWriteLetterContentStepForm>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: composeLetter.letter,
  });

  const onDefaultTemplateChange = useCallback(
    (defaultTemplateId: number) => {
      formBag.setValue('templateId', defaultTemplateId);
    },
    [formBag],
  );
  const { filteredTemplates, isLoadingTemplates } = useGetLetterTemplatesAndSetDefault(onDefaultTemplateChange);

  const saveDraftAndUpdateState = useCallback(
    async (letter: TComposeLetterWriteLetterContentStepForm) => {
      const docxUrl = await getDownloadURL('docx');
      await new Promise<void>((res) => {
        setTimeout(() => {
          res();
        }, 300);
      });
      const pdfUrl = await getDownloadURL('pdf');

      const letterDraft: TSaveDraftLetterRequest = {
        divisionId: composeLetter.divisionId,
        letterTemplateId: letter.templateId >= 0 ? letter.templateId : undefined,
        clearanceBundleLicensorsIds: composeLetter.clearanceBundleLicensorsIds,
        letterType: composeLetter.letterType,
        letterFormat: 'WORD',
        bundle: false,
        id: composeLetter.letter.draftId,
        licensorStatus: letter.clearanceStatusId,
        contact: {
          divisionId: composeLetter.divisionId,
          ...(composeLetter.contact as TExistingContact),
        },
        letterUrl: docxUrl,
        letterDocxUrl: docxUrl,
        extraAttachments: null,
        expirationDate: null,
        email: null,
      };
      const letterDraftDetails = await saveDraft(letterDraft).unwrap();
      updateComposeLetter((prev) => ({
        ...prev,
        letter: {
          ...prev.letter,
          docxUrl,
          pdfUrl,
          draftId: letterDraftDetails.id,
          editorReady: false,
          ignoreDraft: false,
        },
        email: {
          ...prev.email,
          clearanceStatusId: letter.clearanceStatusId,
        },
      }));
    },
    [composeLetter, saveDraft, updateComposeLetter],
  );

  const saveDraftAndGoBack = async () => {
    updateComposeLetter((prev) => ({
      ...prev,
      currentStep: composeLetterSteps[1],
    }));
  };

  const saveDraftAndGoNext = async (letter) => {
    updateComposeLetter((prev) => ({
      ...prev,
      letter: {
        ...prev.letter,
        editorReady: false,
      },
    }));
    await saveDraftAndUpdateState(letter);
    updateComposeLetter((prev) => ({
      ...prev,
      letter: {
        ...prev.letter,
        editorReady: true,
      },
      currentStep: composeLetterSteps[3],
    }));
  };

  const skip = async () => {
    updateComposeLetter((prev) => ({
      ...prev,
      letter: {
        ...prev.letter,
        editorReady: true,
      },
      currentStep: composeLetterSteps[3],
    }));
  };

  return (
    <FormProvider {...formBag}>
      <form style={{ height: '100%' }} onSubmit={formBag.handleSubmit(saveDraftAndGoNext)}>
        <Grid container flexDirection="row" sx={{ width: '100%', height: '100%' }}>
          <ComposeLetterLeftPanel
            isSubmitting={formBag.formState.isSubmitting || !composeLetter.letter?.editorReady}
            goBack={saveDraftAndGoBack}
            skip={formBag.handleSubmit(skip)}
          >
            {!isLoadingTemplates ? (
              <ComposeLetterSelectLetterTemplate
                formBag={formBag}
                onlyOfficeEditorReady={composeLetter.letter?.editorReady}
                isLoadingTemplates={isLoadingTemplates}
                letterTemplates={filteredTemplates}
              />
            ) : (
              <Skeleton sx={{ height: '1rem', width: '100%' }} />
            )}
          </ComposeLetterLeftPanel>
          <ComposeLetterRightPanel>
            {!isLoadingTemplates ? (
              <ComposeLetterOnlyOfficeEditor
                key={`oo-editor-${composeLetter.letter.templateId}`}
                ignoreDraft={composeLetter.letter.ignoreDraft}
              />
            ) : (
              <Skeleton />
            )}
          </ComposeLetterRightPanel>
        </Grid>
      </form>
    </FormProvider>
  );
}
