import curry from 'ramda/src/curry';
import all from 'ramda/src/all';

/**
 * @param {array} rules
 * @param {any} value
 */
const validate = curry((rules, value) => all((rule) => rule(value), rules));

export const validateMin = curry((min, value) => {
  if (!min || !value) return true;
  return min <= value;
});

export const validateMax = curry((max, value) => {
  if (!max || !value) return true;
  return max >= value;
});

export default validate;
