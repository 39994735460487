import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

function ValueContainer({ hasValue, ...props }) {
  return (
    <div
      data-testid="typeahead-value-container"
      className={classnames(props.selectProps.classes.valueContainer, {
        hasValue,
      })}
    >
      {props.children}
    </div>
  );
}

ValueContainer.propTypes = {
  /**
   * The children to be rendered.
   */
  children: PropTypes.node,
  selectProps: PropTypes.object.isRequired,
};
export default ValueContainer;
