import { Component } from 'react';
import { theme, newTheme } from 'components/styleguide';
import { Provider } from 'react-redux';
import { Provider as JotaiProvider } from 'jotai';
import { ConnectedRouter } from 'connected-react-router';
import { store, history } from 'redux-core/store';
import { saveUserData, getUserData, refreshUserData } from 'redux-core/auth/actions';
import { getLocationSelector } from 'redux-core/router/selectors';
import { showSnackbar } from 'redux-core/global-error/actions';
import i18n from 'locales/i18n';
import { MuiThemeProvider } from '@material-ui/core';
import { ThemeProvider } from '@mui/system';
import { LicenseInfo } from '@mui/x-license-pro';
import Routes from 'components/Routes';
import Snackbar from 'components/Snackbar';
import Drawers from 'components/common/Drawers';
import Modals from 'components/common/Modals';
import IdleTimer from 'components/IdleTimer';
import PromptDialog from 'components/PromptDialog';
import ROUTES from 'components/Routes/routes';
import { StyledToastContainer } from 'components/common/toastNotifications';
import LogRocket from 'logrocket';
import ErrorBoundary from './ErrorBoundary';

import 'react-toastify/dist/ReactToastify.min.css';
import './App.css';

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_PRO_LICENSE_KEY);

class App extends Component {
  constructor() {
    super();
    /** Check if there's a bearerToken stored in sessionStorage, if so, get userData */
    const location = getLocationSelector(store.getState());
    if (location === ROUTES.LOGIN_CALLBACK) return;
    const bearerToken = sessionStorage.getItem('bearerToken');
    if (bearerToken) {
      store.dispatch(getUserData(bearerToken));
    } else {
      /** If no bearerToken is present, send a message to
       * the other windows running the app to get token */
      localStorage.setItem('getBearerToken', 'true');
      localStorage.removeItem('getBearerToken');
      sessionStorage.setItem('askedForToken', 'true');
    }

    /** We send messages from different tabs and windows running the app via localStorage
     * cases:
     *    "userData": The user has logged in and got a new bearerToken
     *    "getBearerToken": A tab running the application asked for a
     * bearerToken, if there is one in sessionStorage, send it.
     *    "receiveBearerToken": The tab receieved a bearerToken, if it's not present, save it.
     */
    window.addEventListener('storage', (storageEvent) => {
      if (storageEvent.key === 'userData') {
        const userData = JSON.parse(storageEvent.newValue);
        if (userData) {
          localStorage.removeItem('userData');
          store.dispatch(saveUserData(userData));
          store.dispatch(refreshUserData());
        }
      }
      if (storageEvent.key === 'getBearerToken') {
        // eslint-disable-next-line @typescript-eslint/no-shadow
        const bearerToken = sessionStorage.getItem('bearerToken');
        if (bearerToken) {
          localStorage.setItem('receiveBearerToken', bearerToken);
        } else {
          sessionStorage.removeItem('askedForToken');
        }
      }
      if (storageEvent.key === 'receiveBearerToken') {
        // eslint-disable-next-line @typescript-eslint/no-shadow
        const bearerToken = storageEvent.newValue;
        localStorage.removeItem('receiveBearerToken');
        const askedForToken = sessionStorage.getItem('askedForToken');
        if (bearerToken && askedForToken) {
          store.dispatch(getUserData(bearerToken));
        }
        sessionStorage.removeItem('askedForToken');
      }
      if (storageEvent.key === 'loginProblem' && storageEvent.newValue === 'true') {
        store.dispatch(showSnackbar({ message: i18n.t('login.loginError') }));
      }
    });
  }

  componentDidMount() {
    if (process.env.REACT_APP_LOGROCKET_APP_ID) {
      LogRocket.init(process.env.REACT_APP_LOGROCKET_APP_ID, {
        release: process.env.NODE_ENV,
      });
    }
  }

  render() {
    return (
      <JotaiProvider>
        <ErrorBoundary>
          <Provider store={store}>
            <ConnectedRouter history={history}>
              <ThemeProvider theme={newTheme}>
                <MuiThemeProvider theme={theme}>
                  <StyledToastContainer data-testid="toaster-container" />
                  <Routes />
                  <Snackbar />
                  <Drawers />
                  <Modals />
                  <PromptDialog />
                  <IdleTimer />
                </MuiThemeProvider>
              </ThemeProvider>
            </ConnectedRouter>
          </Provider>
        </ErrorBoundary>
      </JotaiProvider>
    );
  }
}

export default App;
