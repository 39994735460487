/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { MenuItem, Select } from '@mui/material';
import { getIn } from 'formik';
import { useTranslation } from 'react-i18next';
import { useFormSubscription } from 'utils/hooks';
import { withFormField } from 'components/common/Form/utils';
import { FieldPropTypes } from '../prop-types';
import { FormControlLabelWrapper } from '../FormField/FormField';

const CustomDropdown = ({
  field,
  form: { touched, errors, isSubmitting, setFieldValue, setValues, values: formValues, validateForm },
  disabled,
  label,
  fullWidth,
  options,
  subscribeToField,
  subscribeAction,
  onSelect,
  name,
  testId,
  sx,
}) => {
  const { t } = useTranslation();
  useFormSubscription(subscribeToField, subscribeAction);

  const handleChange = (value) => {
    setFieldValue(field.name, value);
    handleSelect(value);
  };

  const handleSelect = (values) => {
    if (onSelect) {
      onSelect(values, {
        field,
        setFieldValue,
        values,
        setValues,
        formValues,
        validateForm,
      });
    }
  };

  const isDisabled = disabled || isSubmitting;
  const error = t(getIn(touched, field.name) && getIn(errors, field.name));

  return (
    <FormControlLabelWrapper
      name={name}
      label={t(label)}
      fullWidth={fullWidth}
      disabled={isDisabled}
      error={!!error}
      helperText={error}
    >
      <Select
        name={name}
        value={field.value ?? ''}
        onChange={(event) => handleChange(event.target.value)}
        disabled={isDisabled}
        data-testId={`typeahead-field-${testId}`}
        sx={sx}
      >
        {options.map((option) => (
          <MenuItem key={option.id} value={option.id}>
            {option.name}
          </MenuItem>
        ))}
      </Select>
    </FormControlLabelWrapper>
  );
};

CustomDropdown.propTypes = {
  field: FieldPropTypes.field,
  form: FieldPropTypes.form,
};

CustomDropdown.defaultProps = {
  options: [],
};

CustomDropdown.displayName = 'Dropdown';

export default withFormField(CustomDropdown);
