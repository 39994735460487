import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({ palette }) => ({
  root: {
    display: 'flex',
  },
  dot: {
    height: '12px',
    width: '12px',
    borderRadius: '50%',
  },
  badge: {
    position: 'relative',
    transform: 'none',
    display: 'inline-flex',
    backgroundColor: ({ outline, color }) => {
      if (!color) {
        return 'transparent';
      }
      if (!outline) {
        return color;
      }

      return 'transparent';
    },
    color: ({ outline, color }) => {
      if (!outline && color && color !== 'default') {
        return palette.getContrastText(palette[color].main);
      }
      if (!color || color === 'default') {
        return 'inherit';
      }
      return palette[color].main;
    },
    border: ({ outline, color }) => {
      if (!outline) {
        return '';
      }
      if (!color) return '1px solid';
      const borderColor = color !== 'default' ? palette[color].main : 'transparent';
      return `1px solid ${borderColor}`;
    },
  },
}));

export default useStyles;
