import { connect } from 'react-redux';
import { compose } from 'redux';
import { withFormConnected } from 'components/styleguide/HOC/index';
// eslint-disable-next-line import/no-named-as-default
import DRAWERS from 'components/common/Drawers/constants';
import { getSelectedProductionDivisionIdSelector } from 'redux-core/overview/selectors';
import withBaseDrawer from '../withBaseDrawer';

const mapStateToProps = (state) => ({
  divisionId: getSelectedProductionDivisionIdSelector(state),
});

export default compose(connect(mapStateToProps), withBaseDrawer, (c) =>
  withFormConnected(c, { formId: DRAWERS.CLEARANCES_USES_AND_DURATIONS }),
);
