/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-shadow */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Collapse from '@material-ui/core/Collapse';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import classnames from 'classnames';
import { useRootTranslation } from 'utils/hooks';
import DiffItem from '../DiffItem';
import useStyles from './styles';

const rootT = 'drawers.qtracksSync.diff';

const FieldGroup = ({ fields, group, nested, position }) => {
  const t = useRootTranslation(`${rootT}.${group}`);
  const classes = useStyles();
  const [expanded, setExpanded] = useState(true);
  return (
    <Grid container className={classnames({ [classes.nested]: nested })}>
      <Grid className={classes.qTracksDetails} item onClick={() => setExpanded(!expanded)} xs={6}>
        <IconButton classes={{ root: classes.icon }}>{expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}</IconButton>
        {t('group', { position })}
      </Grid>
      <Grid item xs={6} className={classes.qclearDetails}>
        {t('group', { position })}
      </Grid>
      <Collapse classes={{ container: classes.container }} in={expanded} unmountOnExit>
        {fields.map(({ nested, ...props }) =>
          nested ? (
            <FieldGroup nested {...props} group={`${group}.${props.group}`} />
          ) : (
            <DiffItem key={props.field} {...props} t={t} />
          ),
        )}
      </Collapse>
    </Grid>
  );
};

FieldGroup.propTypes = {
  fields: PropTypes.array.isRequired,
  group: PropTypes.string.isRequired,
};
export default FieldGroup;
