/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';

import { Grid } from '@material-ui/core';
import { useRootTranslation } from 'utils/hooks';
import { Text } from 'components/styleguide';
import { getLocalDate, formatCurrency } from 'utils/format';

function BudgetHistory({ classes, version }) {
  const rootT = 'drawers.productionSetup.budget';
  const t = useRootTranslation(rootT);

  return (
    <Grid item container xs={12} direction="column" spacing={1}>
      <Grid item container xs={12} className={classes.budgetRow}>
        <Grid item container xs={3}>
          <Grid item container xs={10} direction="column">
            <div className={classes.label}>
              <Text color="textPrimary" variant="formLabel" bold>
                {t('budget')}
              </Text>
            </div>
            <Text color="textPrimary" variant="formLabel">
              {formatCurrency(version.productionBudget)}
            </Text>
          </Grid>
        </Grid>
        <Grid item container xs={3}>
          <Grid item container xs={10} direction="column">
            <div className={classes.label}>
              <Text color="textPrimary" variant="formLabel" bold>
                {t('date')}
              </Text>
            </div>
            <Text color="textPrimary" variant="formLabel">
              {getLocalDate(version.date)}
            </Text>
          </Grid>
        </Grid>
        <Grid item container xs={6}>
          <Grid item container xs={10} direction="column">
            <div className={classes.label}>
              <Text color="textPrimary" variant="formLabel" bold>
                {t('user')}
              </Text>
            </div>
            <Text color="textPrimary" variant="formLabel">
              {`${version.userFirstName}
            ${version.userLastName}`}
            </Text>
          </Grid>
        </Grid>
      </Grid>
      <Grid container item direction="column" xs={12}>
        <div className={classes.label}>
          <Text color="textPrimary" variant="formLabel" bold>
            {t('reasonForChange')}
          </Text>
        </div>
        <Text color="textPrimary" variant="formLabel">
          {version.reasonForChange}
        </Text>
      </Grid>
    </Grid>
  );
}

BudgetHistory.propTypes = {
  classes: PropTypes.object.isRequired,
  version: PropTypes.shape({
    productionBudget: PropTypes.number.isRequired,
    reasonForChange: PropTypes.string.isRequired,
    userFirstName: PropTypes.string.isRequired,
    userLastName: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
  }),
};

export default BudgetHistory;
