/* eslint-disable import/no-cycle */
import AssignmentIcon from '@material-ui/icons/Assignment';
import HelpIcon from '@material-ui/icons/Help';
import HomeIcon from '@material-ui/icons/Home';
import InsertChartIcon from '@material-ui/icons/InsertChart';
import SettingsIcon from '@material-ui/icons/Settings';
import BusinessIcon from '@mui/icons-material/Business';
import LibraryMusicIcon from '@mui/icons-material/LibraryMusic';
import { Divider, Drawer, List } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { Box } from '@mui/system';

import { HOMEPAGE } from 'components/Routes';
import ROUTES from 'components/Routes/routes';
import React from 'react';
import { useSelector } from 'react-redux';
import { getIsQwireEmployeeSelector } from 'redux-core/auth/selectors';
import { getCanAccessDivisionSelector } from 'redux-core/divisions/selectors';
import {
  CLEAR_PERMISSIONS_GROUPS,
  COMMON_PERMISSIONS,
  CUE_PERMISSIONS,
  CLEAR_PERMISSIONS,
} from 'redux-core/permissions/constants';
import { useRootTranslation } from 'utils/hooks';
import { QwireBars } from '../icons';

type TMenuItem = {
  route: string;
  text: string;
  icon: React.ReactNode;
  useIsRouteAllowed: (isQwireEmployee: boolean) => boolean;
};

const menuItems: TMenuItem[] = [
  {
    route: HOMEPAGE,
    text: 'home',
    icon: <HomeIcon />,
    useIsRouteAllowed: () => true,
  },
  {
    route: ROUTES.TRACKS,
    text: 'tracks',
    icon: <LibraryMusicIcon />,
    useIsRouteAllowed: () =>
      useSelector(getCanAccessDivisionSelector({ clearPermissions: CLEAR_PERMISSIONS.CAN_ACCESS_CLEAR })),
  },
  {
    route: ROUTES.LICENSORS,
    text: 'licensors',
    icon: <BusinessIcon />,
    useIsRouteAllowed: () => {
      const canAccessClear = useSelector(
        getCanAccessDivisionSelector({ clearPermissions: CLEAR_PERMISSIONS.CAN_ACCESS_CLEAR }),
      );
      const hasExpectedClearPermissions = useSelector(
        getCanAccessDivisionSelector({ clearPermissions: CLEAR_PERMISSIONS_GROUPS.LETTERS }),
      );

      return canAccessClear && hasExpectedClearPermissions;
    },
  },
  {
    route: ROUTES.MAIN_CUE_SHEET,
    text: 'cueSheets',
    icon: <AssignmentIcon />,
    useIsRouteAllowed: () =>
      useSelector(getCanAccessDivisionSelector({ cuePermissions: CUE_PERMISSIONS.CAN_ACCESS_CUE })),
  },
  {
    route: ROUTES.REPORTS,
    text: 'reports',
    icon: <InsertChartIcon />,
    useIsRouteAllowed: () =>
      useSelector(getCanAccessDivisionSelector({ commonPermissions: COMMON_PERMISSIONS.VIEW_REPORTS })),
  },
  {
    route: ROUTES.ADMIN_REPORTS,
    text: 'adminReports',
    icon: <InsertChartIcon />,
    useIsRouteAllowed: (isQwireEmployee: boolean) => isQwireEmployee,
  },
];

const bottomMenuItems: TMenuItem[] = [
  {
    route: ROUTES.SETTINGS.BASE_PATH,
    text: 'settings',
    icon: <SettingsIcon />,
    useIsRouteAllowed: () => true,
  },
  {
    route: ROUTES.HELP,
    text: 'help',
    icon: <HelpIcon />,
    useIsRouteAllowed: () => true,
  },
  {
    route: ROUTES.QWIRE_ADMIN,
    text: 'qwire',
    icon: <QwireBars />,
    useIsRouteAllowed: (isQwireEmployee: boolean) => isQwireEmployee,
  },
];

type MenuButtonProps = {
  text: string;
  testId: string;
  icon: TMenuItem['icon'];
  selected: boolean;
  onClick: () => void;
  isQwireEmployee: boolean;
  useIsRouteAllowed: (isQwireEmployee: boolean) => boolean;
};

const MenuButton = ({ icon, isQwireEmployee, onClick, selected, text, testId, useIsRouteAllowed }: MenuButtonProps) => {
  const isAllowed = useIsRouteAllowed(isQwireEmployee);
  if (!isAllowed) {
    return null;
  }

  return (
    <MenuItem
      selected={selected}
      onClick={onClick}
      data-testid={testId}
      sx={{
        gap: '1rem',
        fontWeight: '600',
      }}
    >
      <Box sx={{ display: 'flex' }}>{icon}</Box>
      {text}
    </MenuItem>
  );
};

type NavbarProps = {
  /** Current browser location */
  location: string;
  /** Handler for each menu item click browser location */
  handleClick: (route: string) => void;
  open: boolean;
  setOpen: (open: boolean) => void;
};

const Navbar = ({ location, handleClick, open, setOpen }: NavbarProps) => {
  const t = useRootTranslation('global.navbar');
  const isQwireEmployee = useSelector(getIsQwireEmployeeSelector);

  const getRouteChange = (route) => () => {
    handleClick(route);
    setOpen(false);
  };

  return (
    <Drawer
      open={open}
      onClose={() => setOpen(false)}
      /** This style needs to be set inline for the navbar to be below the header */
      style={{ zIndex: '1000' }}
    >
      <Box width={240} height="100%" display="flex" flexDirection="column" alignItems="center">
        <Box width={1} mt="80px">
          <List component="nav" aria-label="main mailbox folders">
            {menuItems.map(({ route, text, ...rest }) => (
              <MenuButton
                isQwireEmployee={isQwireEmployee}
                key={route}
                onClick={getRouteChange(route)}
                selected={location.includes(route)}
                text={t(text)}
                testId={`nav-item-${text}`}
                {...rest}
              />
            ))}
          </List>
        </Box>
        <Box width={1} mt="auto">
          <Divider />
          <List component="nav" aria-label="main mailbox folders">
            {bottomMenuItems.map(({ route, text, ...rest }) => (
              <MenuButton
                isQwireEmployee={isQwireEmployee}
                key={route}
                onClick={getRouteChange(route)}
                selected={location.includes(route)}
                text={t(text)}
                testId={`nav-item-${text}`}
                {...rest}
              />
            ))}
          </List>
        </Box>
      </Box>
    </Drawer>
  );
};

export default Navbar;
