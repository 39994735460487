import { createActions } from 'redux-actions';
import identity from 'ramda/src/identity';
import i18next from 'i18next';

const actionsDefinition = {
  SETUP_FLOW: {
    KEEP_DIVISION_INFO: (tenantId, divisionId) => ({ tenantId, divisionId }),
    KEEP_FILM_INFO: identity,
    KEEP_SEASON_INFO: identity,
    KEEP_RELEASE_INFO: identity,
    KEEP_SERIES_INFO: identity,
    KEEP_PERSONNEL_INFO: identity,
    KEEP_PERSONNEL_FORM: identity,
    CLEAN_SETUP: identity,
    CLEAN_SETUP_FLOW: identity,
    KEEP_PROJECT_INFO: identity,
    KEEP_SETUP_INFO: (divisionId, projectId, tenantId) => ({
      divisionId,
      projectId,
      tenantId,
    }),
    SET_PRODUCTION_COMPANIES: identity,
    SET_SEASON_CURRENCIES: identity,
    SET_SEASON_MASTER_CURRENCY_ID: identity,
  },
  /* @TODO ensure this action is still used */
  UPDATE_PROJECT_IN_HIERARCHY: identity,
  SET_POSTER_ID: identity,
};

export const getSaveMessage = (entity, name) =>
  i18next.t(`drawers.productionSetup.${entity}.notification.create`, { name });

export const getUpdateMessage = (entity, name) =>
  i18next.t(`drawers.productionSetup.${entity}.notification.edit`, { name });

export const getPatchMessage = () => i18next.t('global.forms.notification.save');

export const {
  setupFlow: {
    keepDivisionInfo,
    keepFilmInfo,
    keepSeasonInfo,
    keepReleaseInfo,
    keepSeriesInfo,
    keepPersonnelInfo,
    keepPersonnelForm,
    cleanSetup,
    cleanSetupFlow,
    keepProjectInfo,
    keepSetupInfo,
    setProductionCompanies,
    setSeasonCurrencies,
    setSeasonMasterCurrencyId,
  },
  setPosterId,
} = createActions(actionsDefinition);
