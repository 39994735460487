import { baseApi, TPagination } from 'services';
import { TSearchCueContainersResponse } from './types';

export type TGetCueContainersAllowedByUserResponse = {
  data: Array<{ title: string }>;
  pagination: TPagination;
};

export type TSearchCueContainersRequest = {
  searchTerm?: string;
  parentId?: number;
  limit?: number;
};

export const projectsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getCueContainersAllowedByUser: builder.query<TGetCueContainersAllowedByUserResponse, { searchTerm?: string }>({
      query: (body) => ({
        url: 'common/cue-containers/get-all-allowed-by-user',
        method: 'POST',
        body,
      }),
    }),
    searchCueContainers: builder.query<TSearchCueContainersResponse, TSearchCueContainersRequest>({
      query: (body) => ({
        url: 'common/cue-containers/search',
        method: 'POST',
        body,
      }),
      keepUnusedDataFor: 0,
    }),
  }),
});

export const { useGetCueContainersAllowedByUserQuery, useSearchCueContainersQuery } = projectsApi;
