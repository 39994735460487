import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  noOverlay: {
    '& .MuiBackdrop-root': {
      backgroundColor: 'transparent',
    },
  },
}));
export default useStyles;
