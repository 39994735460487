/* eslint-disable import/no-cycle */
import { handleActions } from 'redux-actions';
import lensPath from 'ramda/src/lensPath';
import lensProp from 'ramda/src/lensProp';
import pick from 'ramda/src/pick';
import set from 'ramda/src/set';
import merge from 'ramda/src/merge';
import * as actions from './actions';

const defaultState = {
  cueSheet: [],
  file: [],
  fileName: undefined,
  mappings: {},
  mappedFields: {},
  usageMappings: {},
  selectedColumn: undefined,
  templateId: undefined,
  tracksCompare: [],
  error: null,
};

const importCueSheetReducer = {
  [actions.clearImportCueSheet]: () => defaultState,
  [actions.clearImportMappings]: (state) => ({
    ...state,
    ...pick(['cueSheet', 'mappedFields', 'mappings', 'previewingRow', 'selectedColumn', 'usageMappings'])(defaultState),
  }),
  [actions.setImportCueSheet]: (state, { payload }) => set(lensProp('cueSheet'), payload)(state),
  [actions.setImportCueSheetTemplate]: (state, { payload }) => ({ ...state, ...payload }),
  [actions.setImportFile]: (state, { payload }) => merge(state, { file: payload, error: null }),
  [actions.setImportFileName]: (state, { payload }) => set(lensProp('fileName'), payload)(state),
  [actions.setImportFirstRow]: (state, { payload }) => set(lensProp('firstRow'), payload)(state),
  [actions.setImportHeaderRow]: (state, { payload }) => set(lensProp('headerRow'), payload)(state),
  [actions.setImportMappedFields]: (state, { payload }) => set(lensProp('mappedFields'), payload)(state),
  [actions.setImportMappings]: (state, { payload }) => set(lensProp('mappings'), payload)(state),
  [actions.setImportUsageMappings]: (state, { payload }) => set(lensProp('usageMappings'), payload)(state),
  [actions.setImportPreviewingRow]: (state, { payload }) => set(lensProp('previewingRow'), payload)(state),
  [actions.setImportSelectedColumn]: (state, { payload }) => set(lensProp('selectedColumn'), payload)(state),
  [actions.setImportTracksCompare]: (state, { payload }) => set(lensProp('tracksCompare'), payload)(state),
  [actions.setImportSelectedTrack]: (state, { payload: { position, qtrackSelected } }) =>
    set(lensPath(['tracksCompare', position, 'qtrackSelected']), qtrackSelected)(state),
  [actions.setTemplateId]: (state, { payload }) => set(lensProp('templateId'), payload)(state),
  [actions.setUploadedImportFileId]: (state, { payload }) => set(lensProp('uploadedImportFileId'), payload)(state),
  [actions.setImportError]: (state, { payload }) => set(lensProp('error'), payload)(state),
};

export default handleActions(importCueSheetReducer, defaultState);
