import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  button: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    '&.justifyCenter': {
      justifyContent: 'center',
    },
  },
}));

export default useStyles;
