/* eslint-disable import/no-cycle */
import { createSelector } from 'reselect';
import prop from 'ramda/src/prop';
import { getLocationParametersSelector } from 'redux-core/router/selectors';
import { NORMALIZED_PRODUCTION_TYPES } from 'utils/constants';
import { ASSETS_LIMIT, CUE_CONTAINERS_LIMIT } from './actions';

const getCueContainersRoot = (state) => state.cueContainers;

export const getCueContainersSelector = createSelector(getCueContainersRoot, prop('list'));

export const getIsLoadingCueContainersSelector = createSelector(getCueContainersRoot, prop('loading'));

export const getCueContainersPageCountSelector = createSelector(getCueContainersRoot, prop('pageCount'));

export const getCueContainersLimitSelector = (state) => {
  const { type } = getLocationParametersSelector(state);
  if (type === NORMALIZED_PRODUCTION_TYPES.MKT) return ASSETS_LIMIT;
  return CUE_CONTAINERS_LIMIT;
};
