import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: '40%',
    minWidth: '576px',
    padding: `${theme.spacing(3)}px ${theme.spacing(4)}px`,
    '&.dense': {
      padding: 0,
    },
    '&.wide': {
      width: '55.5%',
    },
    '&.large': {
      width: '63%',
    },
    '&.xlarge': {
      width: '72%',
    },
    '&.fullWidth': {
      width: '95%',
    },
  },
}));

export default useStyles;
