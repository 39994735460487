import { makeStyles } from '@material-ui/core';

export const useInviteUsersStyle = makeStyles(() => ({
  rowActionContainer: {
    paddingTop: '14px!important',
    display: 'flex',
    gap: '6px',
    alignItems: 'baseline',
  },
  firstRow: {
    paddingTop: '37px!important',
  },
  rowAction: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.5em',
  },
}));
