/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-cycle */
import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Dropdown, MoveTo, Text } from 'components/styleguide';
import { Field } from 'formik';
import { findIndex, propEq, reject } from 'ramda';
import c from 'ramda/src/compose';
import map from 'ramda/src/map';
import { concatStrings } from 'utils/index';
import MenuItemContent from './MenuItemContent';
import useStyles from './styles';
import FormTypeAhead from '../FormTypeAhead';

/**
 * @typedef {Object} OptionBoundaries
 * @property {Object} TOP
 * @property {string} TOP.value
 * @property {number} TOP.label
 * @property {JSX.Element} TOP.icon
 * @property {Object} BOTTOM
 * @property {string} BOTTOM.value
 * @property {number} BOTTOM.label
 * @property {JSX.Element} BOTTOM.icon
 */
export const optionBoundaries = {
  TOP: {
    id: 'top',
    value: 'top',
    label: 'Top',
    icon: <MoveTo direction="top" />,
  },
  BOTTOM: {
    id: 'bottom',
    value: 'bottom',
    label: 'Bottom',
    icon: <MoveTo direction="bottom" />,
  },
  ADD_AFTER_PLACEHOLDER: {
    id: 'addAfter',
    name: 'addAfter',
    value: 'addAfter',
  },
};

/**
 * Dropdown
 */
const FormScenesDropdown = ({
  field,
  options,
  alwaysEnabled,
  placeholder,
  divider,
  form: { setFieldValue, values: scene },
  ...props
}) => {
  const classes = useStyles();
  const isDisabled = !alwaysEnabled && !options.length;

  const scenesOptions = useMemo(
    () =>
      c(
        (cueContainer) => [...Object.values(optionBoundaries), ...cueContainer],
        map((cueContainer) => ({
          ...cueContainer,
          name: concatStrings(' - ')(cueContainer.number, cueContainer.title),
        })),
        reject((s) => s.id === scene.id),
      )(options),
    [scene.id, options],
  );

  useEffect(() => {
    const index = findIndex(propEq('id', scene.id))(options);
    setFieldValue(field.name, index < 1 ? optionBoundaries.BOTTOM.value : options[index - 1].id);
  }, [scene.id, options, field.name, setFieldValue]);

  const formatOptionLabel = (option) => {
    if (option.id === optionBoundaries.TOP.id || option.id === optionBoundaries.BOTTOM.id) {
      return <MenuItemContent option={option} />;
    }

    if (option.id === optionBoundaries.ADD_AFTER_PLACEHOLDER.id) {
      return (
        <div className={classes.divider}>
          <Text
            variant="subtitle1"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}
          >
            {divider}
          </Text>
        </div>
      );
    }
    return option.name;
  };

  return (
    <FormTypeAhead
      isClearable={false}
      {...field}
      {...props}
      MenuProps={{
        PaperProps: {
          className: classnames(classes.menu),
        },
      }}
      disabled={isDisabled}
      placeholder={isDisabled ? placeholder : undefined}
      options={scenesOptions}
      formatOptionLabel={formatOptionLabel}
    />
  );
};

FormScenesDropdown.propTypes = {
  divider: PropTypes.string.isRequired,
  alwaysEnabled: PropTypes.bool,
  ...Dropdown.propTypes,
};

FormScenesDropdown.defaultProps = {
  alwaysEnabled: false,
  ...Dropdown.defaultProps,
};

const FormikInput = (props) => <Field component={FormScenesDropdown} {...props} />;

FormikInput.displayName = 'FormScenesDropdown';

export default FormikInput;
