/* eslint-disable import/no-cycle */
import makeEntityServiceCalls, { makeServiceCall } from 'redux-core/serviceHelpers';

export const {
  getAll: getCampaignAssetVersionsCall,
  get: getCampaignAssetVersionByIdCall,
  update: updateCampaignAssetVersionCall,
  create: createCampaignAssetVersionCall,
} = makeEntityServiceCalls('material-versions', 'clear');

export const copyCampaignAssetCall = (payload) => makeServiceCall('material-versions', 'copy', 'clear')(payload);

export const completeCampaignAssetCall = makeServiceCall('assets', 'complete', 'clear');
