import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
  emptyChip: {
    backgroundColor: 'transparent',
    minWidth: 50,
    cursor: 'pointer',
  },
  chip: {
    cursor: 'pointer',
    backgroundColor: theme.palette.grey[300],
    width: '100%',
    '&.md': {
      height: 22,
    },
    '&.sm': {
      height: 15,
    },
  },
  chipLabel: {
    '&.md': {
      fontSize: 14,
    },
    '&.sm': {
      fontSize: 11,
    },
  },
}));

export default styles;
