import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
  inputRoot: {
    padding: `${theme.spacing(0.5)}px!important`,
  },
  input: {
    margin: '0!important',
    marginLeft: theme.spacing(0.25),
    padding: `${theme.spacing(1.25)}px!important`,
  },
  chip: {
    margin: theme.spacing(0.25),
  },
}));

export default styles;
