import { appColors } from 'components/styleguide/theme';

export const topBarHeight = '56px';

const Theme = (theme) => ({
  header: {
    width: '100%',
    height: topBarHeight,
    backgroundImage: `linear-gradient(to right, ${appColors.qwireGradientOne}, ${appColors.qwireGradientTwo})`,
    boxShadow: '0 4px 4px 0 rgba(0, 0, 0, 0.06)',
    top: 0,
    zIndex: 1001,
    position: 'fixed',
  },
  headerMargin: {
    '& + *': {
      marginTop: topBarHeight,
    },
  },
  qwireLogo: {
    cursor: 'pointer',
    marginTop: 5,
  },
  qwireLogoMargin: {
    marginLeft: theme.spacing(2),
  },
  menuButton: {
    color: appColors.white,
    fontSize: '1rem',
  },
  content: {
    marginRight: theme.spacing(2),
    fontSize: '12px',
    lineHeight: '28px',
    fontWeight: '400',
    color: theme.palette.primary.contrastText,
    height: '100%',
  },
  slot: {
    flexGrow: 1,
  },
  notification: {
    fill: '#979797',
  },
  popoverColor: {
    backgroundColor: 'transparent',
    overflow: 'hidden',
    zIndex: theme.zIndex.three,
  },
  badge: {
    minWidth: 15,
    height: 15,
    padding: 0,
    fontSize: '0.65rem',
  },
  profileAvatarButton: {
    minWidth: 100,
    maxWidth: 150,
  },
});
export default Theme;
