import ROUTES from 'components/Routes/routes';

import { COMMON_PERMISSIONS } from 'redux-core/permissions/constants';
import { NORMALIZED_PRODUCTION_TYPES } from 'utils/constants';

import SearchDetailsSplitButton from './SearchDetailsSplitButton';

export const TVDetailsSplitButton = ({
  cleanSetupFlow,
  closeAllDialogs,
  goToLink,
  keepSetupInfo,
  openModal,
  selected,
}) => {
  const { productionObjectId } = selected;
  const splitButtonMainLabel = 'options.add.episode';

  const splitButtonMainAction = async () => {
    cleanSetupFlow();
    keepSetupInfo(selected.divisionId, selected.prodArray[0].id, selected.tenantId);

    await goToLink(ROUTES.PRODUCTION.DETAILS, {
      releaseId: selected.prodArray[2].id,
      type: NORMALIZED_PRODUCTION_TYPES.TV,
      divisionId: selected.divisionId,
      projectId: selected.prodArray[0].id,
      tenantId: selected.tenantId,
      objectId: productionObjectId,
    });
    return closeAllDialogs();
  };
  return (
    <SearchDetailsSplitButton
      tenantId={selected.tenantId}
      productionType={selected.productionType}
      projectId={selected.prodArray[0].id}
      productionId={selected?.prodArray[1]?.id}
      productionActive={selected?.prodArray[1]?.active}
      objectId={selected.productionObjectId}
      divisionId={selected.divisionId}
      keepSetupInfo={keepSetupInfo}
      openModal={openModal}
      mainAction={splitButtonMainAction}
      mainLabel={splitButtonMainLabel}
      mainPermissions={COMMON_PERMISSIONS.READ}
    />
  );
};
