/* eslint-disable react/no-children-prop */
import Grid from '@material-ui/core/Grid';
import last from 'ramda/src/last';

import { Text } from 'components/styleguide';
import { concatStrings } from 'utils';
import { useRootTranslation } from 'utils/hooks';

import { Description } from './Description';
import { TrackExtraDetails } from './TrackExtraDetails';
import { ViewButton } from './ViewButton';

const concatArrays = concatStrings(', ');

export const TrackDetails = ({ closeAllDialogs, goToLink, filterBy, selected }) => {
  const t = useRootTranslation('globalSearch.details');

  if (!selected) return null;

  const trackTitle = selected.title;
  const trackInfo = last(selected.prodArray);
  const artists = concatArrays(trackInfo.artists);
  const { library } = trackInfo;
  const masters = concatArrays(trackInfo.masters);
  const syncs = concatArrays(trackInfo.syncs);
  const writers = concatArrays(trackInfo.writers);

  return (
    <Grid container spacing={3} alignItems="center">
      <Grid item xs={6}>
        <Text lineHeight="35px" bold children={t('title')} />
      </Grid>
      <Grid item xs={6} container alignItems="center" justify="flex-end">
        <ViewButton filterBy={filterBy} selected={selected} closeAllDialogs={closeAllDialogs} goToLink={goToLink} />
      </Grid>
      <Grid container item xs={12} spacing={2}>
        <Description title={t('track.trackTitle')} value={trackTitle} />
        {library && <Description title={t('track.library')} value={library} />}
      </Grid>

      <Grid container item xs={12} spacing={2}>
        <Description title={t('track.artists')} value={artists} />
        <Description title={t('track.writers')} value={writers} />
      </Grid>

      <Grid container item xs={12} spacing={2}>
        <Description title={t('track.master')} value={masters} />
        <Description title={t('track.sync')} value={syncs} />
      </Grid>

      <TrackExtraDetails selected={selected} />
    </Grid>
  );
};
