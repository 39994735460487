/* eslint-disable no-unused-vars */
/* eslint-disable import/no-cycle */
import React, { useEffect, useState, useMemo, useCallback, useRef } from 'react';
import Grid from '@material-ui/core/Grid';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import NavigateNext from '@material-ui/icons/NavigateNext';
import NavigateBefore from '@material-ui/icons/NavigateBefore';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import { useTranslation } from 'react-i18next';
import { Text } from 'components/styleguide';
import { splitEvery, nth, inc, dec, length, multiply, subtract, last } from 'ramda';
import { isEqual } from 'lodash';

type PaginatorProps = {
  elements: unknown[];
  callback: (pageData: unknown[], pageNumber: number, firstItem: number, lastItem: number) => void;
  amount: number;
};

const Paginator = ({ elements, callback, amount }: PaginatorProps) => {
  const pages = useMemo(() => splitEvery(amount, elements), [amount, elements]);
  const [page, setPage] = useState(1);
  const [t] = useTranslation();

  /* Used to avoid unnecessary re-renders */
  const prevPage = useRef<number>();
  const prevPageData = useRef<unknown[]>();

  const lastPage = length(pages);
  const elementsTotal = length(elements);

  const { firstItem, lastItem } = useMemo(() => {
    if (page === length(pages)) {
      /** Special logic for last page */
      const elementsInLastPage = length(last(pages));
      if (elementsInLastPage > 1) {
        const first = subtract(elementsTotal, elementsInLastPage) + 1;
        const lastI = elementsTotal;
        return { firstItem: first, lastItem: lastI };
      }

      return {
        firstItem: elementsTotal,
        lastItem: elementsTotal,
      };
    }

    const lastI = multiply(page, amount);
    const first = subtract(lastI, dec(amount));

    return { firstItem: first, lastItem: lastI };
  }, [amount, elementsTotal, page, pages]);

  const handleCallback = useCallback(() => {
    const currentPage = nth(dec(page), pages) || [];
    callback(currentPage, page, firstItem, lastItem);
  }, [callback, page, pages, firstItem, lastItem]);

  useEffect(() => {
    if (prevPage.current !== page || !isEqual(prevPageData.current, pages)) {
      prevPage.current = page;
      prevPageData.current = pages;

      handleCallback();
    }
  }, [page, pages, handleCallback]);

  useEffect(() => {
    if (page > 1 && amount >= elementsTotal) {
      setPage(1);
      handleCallback();
    }
  }, [amount, elementsTotal, page, handleCallback]);

  return (
    elementsTotal > amount && (
      <Box minWidth="280px" px={1} data-testid="paginator">
        <Grid container justify="space-between" alignItems="center">
          <Text variant="main" testId="paginator-interval">
            {t('global.paginator.intervalIndicator', {
              interval: `${firstItem}-${lastItem}`,
              total: elementsTotal,
            })}
          </Text>
          <IconButton onClick={() => setPage(1)} disabled={page === 1} data-testid="paginator-control-first">
            <FirstPage />
          </IconButton>
          <IconButton onClick={() => setPage(dec(page))} disabled={page === 1} data-testid="paginator-control-before">
            <NavigateBefore />
          </IconButton>
          <IconButton
            onClick={() => setPage(inc(page))}
            disabled={page === lastPage}
            data-testid="paginator-control-next"
          >
            <NavigateNext />
          </IconButton>
          <IconButton
            onClick={() => setPage(lastPage)}
            disabled={page === lastPage}
            data-testid="paginator-control-last"
          >
            <LastPage />
          </IconButton>
        </Grid>
      </Box>
    )
  );
};

export default Paginator;
