import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  hideContainer: {
    position: 'absolute',
    right: '11px',
    top: '11px',
  },
  endAdornment: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  endAdornmentPullLeft: {
    position: 'absolute',
    top: 0,
    right: 100,
  },
  arrow: {
    transform: 'rotate(0deg)',
    transition: 'transform 0.3s ease-in',
    '&.open': {
      transform: 'rotate(180deg)',
      transition: 'transform 0.3s ease-in',
    },
  },
  hideButton: {
    fontSize: '11px',
    color: theme.palette.text.secondary,
  },
  tabs: {
    position: 'relative',
    width: '100%',
    backgroundColor: '#fff',
  },
}));

export default useStyles;
