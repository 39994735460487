import { createActions } from 'redux-actions';
import identity from 'ramda/src/identity';
import { updateLicensorCall } from './services';

const actionsDefinition = {
  SET_UPDATE_LETTER_DATE: identity,
  SET_LOADING_UPDATE_LETTER_DATE: identity,
};

export const { setUpdateLetterDate, setLoadingUpdateLetterDate } = createActions(actionsDefinition);

export const setLicensorCall = (payload) => async (dispatch) => {
  dispatch(setLoadingUpdateLetterDate(true));
  try {
    const res = await updateLicensorCall(payload);
    dispatch(setUpdateLetterDate(res));
  } finally {
    dispatch(setLoadingUpdateLetterDate(false));
  }
};
