/* eslint-disable react/no-children-prop */
/* eslint-disable import/no-cycle */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useIntersect, useFetch, useDeepCompareMemoize } from 'utils/hooks';
import { Loading } from 'components/styleguide';
import is from 'ramda/src/is';
import { DEFAULT_PAGINATION_RESPONSE } from 'utils/constants';
import { concatStrings } from 'utils';

const SCROLLER_ID = 'infinite-scroller';

const List = React.memo(({ children, items }) => children({ items }));

const InfiniteScroll = ({
  children,
  EmptyState,
  filters,
  id,
  limit,
  onScroll,
  pageCount,
  showInitialLoader,
  dataProperty,
}) => {
  const scrollerId = concatStrings('-')(SCROLLER_ID, id);
  const [page, setPage] = useState(0);
  const memoFilters = useDeepCompareMemoize(filters);
  const mounted = useRef();

  const [{ data: items, pagination }, loading, refetch] = useFetch(
    async () => {
      const response = await onScroll({ ...memoFilters, limit, page });
      if (!page) return response;
      const newData = dataProperty
        ? items[dataProperty].concat(response.data[dataProperty])
        : items.concat(response.data);
      return { ...response, data: newData };
    },
    [limit, page],
    DEFAULT_PAGINATION_RESPONSE,
  );

  useEffect(
    () => {
      // Avoid refetching on first mount
      if (!page && mounted.current) refetch();
      else setPage(0);
      mounted.current = true;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [memoFilters],
  );

  const [setNodes] = useIntersect((entry) => {
    if ((pageCount ?? pagination.pageCount ?? 0) <= page + 1) return;
    if (entry?.[0]?.isIntersecting && !loading) setPage((p) => p + 1);
  });

  const showLoading = Boolean(loading && (showInitialLoader || page));
  const showEmptyState = Boolean(!loading && !pagination.totalCount && EmptyState);

  useEffect(() => {
    const scroller = document.getElementById(scrollerId);
    if (!scroller) return;
    setNodes([scroller]);
  }, [scrollerId, setNodes]);

  return (
    <>
      {is(Function, children) ? <List children={children} items={items} /> : children}
      {showEmptyState && <EmptyState />}
      <div id={scrollerId} style={{ height: 20, width: '100%' }} />
      {showLoading && <Loading size={40} />}
    </>
  );
};

InfiniteScroll.defaultProps = {
  filters: {},
  limit: 50,
  showInitialLoader: true,
};

InfiniteScroll.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]).isRequired,
  filters: PropTypes.object,
  // Used to diferentiate scrollers that are in the same page
  id: PropTypes.string,
  limit: PropTypes.number,
  /**
   * @function
   * @returns {Object} An object containing pagination and data.
   */
  onScroll: PropTypes.func.isRequired,
  pageCount: PropTypes.number,
  // Indicates whether or not the loading indicator should be shown during first fetch
  showInitialLoader: PropTypes.bool,
  // Because generic backend response is { data: [] }
  // But there are some endpoints which response is { data: { dataProperty: [] } }
  dataProperty: PropTypes.string,
};

export default InfiniteScroll;
