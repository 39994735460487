/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable react/no-children-prop */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { FormButton, FormTypeAhead } from 'components/common/Form/Fields';
import { useRootTranslation, useFetch } from 'utils/hooks';
import pathOr from 'ramda/src/pathOr';
import pick from 'ramda/src/pick';
import map from 'ramda/src/map';
import always from 'ramda/src/always';
// eslint-disable-next-line import/no-named-as-default
import DRAWERS from 'components/common/Drawers/constants';
import { isEmptyObject } from 'utils/object';
import { Text } from 'components/styleguide';
import enhancer from './enhancer';

const CurrencyPicker = ({
  currencies,
  disabled,
  onSaveCurrencies,
  selected,
  setSelected,
  getProductionCurrencyOptions,
  getCurrencyOptions,
  onAdditionalCurrencyClick,
  openDrawer,
  readOnly,
  showAddedCurrencies,
  testId,
}) => {
  const t = useRootTranslation('productionOverview.tabs.details');
  const [dropdownCurrencies, loading] = useFetch(
    () => {
      if (isEmptyObject(selected) || !selected.id) {
        // Is Creating
        return getCurrencyOptions({ currencies });
      }
      return getProductionCurrencyOptions({ currencies });
    },
    [selected, currencies],
    [],
  );
  /** Map Currencies */
  const additionalCurrencies = pathOr([], ['additionalData', 'currencies'])(selected);
  const currencyOptions = map(pick(['id', 'exchangeRate', 'code', 'symbol', 'label']), dropdownCurrencies);
  const handleOnClick = (values) => {
    const current = {
      ...selected,
      ...values,
    };
    setSelected({
      dirty: true,
      ...current,
    });
    const additionalCurrencies = pathOr([], ['additionalData', 'currencies'])(current);
    if (onAdditionalCurrencyClick) onAdditionalCurrencyClick(values);

    openDrawer(DRAWERS.PRODUCTION_SETUP_CURRENCIES, {
      isEditing: disabled,
      values: additionalCurrencies,
      onSubmit: onSaveCurrencies,
      masterCurrencyId: current.masterCurrencyId,
      readOnly,
    });
  };

  const showAdditionalCurrencyLink = Boolean(onAdditionalCurrencyClick) || showAddedCurrencies;

  return (
    <Grid container spacing={1} alignItems="flex-end" data-testid={testId}>
      <Grid item xs={5}>
        <FormTypeAhead
          isClearable={false}
          required
          label={t('masterCurrency')}
          name="masterCurrencyId"
          placeholder="global.forms.choose"
          labelKey="label"
          options={currencyOptions}
          readOnly={disabled}
          disabled={disabled}
          loading={loading}
          testid="currency-picker-currencies-dropdown"
        />
      </Grid>
      <Grid item xs={7} style={{ zIndex: 201 }}>
        {showAdditionalCurrencyLink && (
          <Box marginTop={2.5} pl={1}>
            <FormButton
              testid="currency-picker-details-additional-currencies"
              alwaysEnabled
              children={t('additionalCurrencies')}
              variant="secondary"
              onClick={handleOnClick}
              showIfReadOnly
              size="sm"
            />
          </Box>
        )}
      </Grid>
      {showAddedCurrencies && (
        <Grid container direction="column">
          <Text variant="caption" bold>
            {t('addedCurrencies')}
          </Text>
          {additionalCurrencies.map((currency) => (
            <Grid item key={currency.id}>
              <Text variant="body2">
                {`
            ${currency.exchangeRate} 
            ${currency.code} = ${t('masterCurrencyExchange', {
                  code: selected.masterCurrencyCode,
                })}`}
              </Text>
            </Grid>
          ))}
        </Grid>
      )}
    </Grid>
  );
};

CurrencyPicker.propTypes = {
  /**
   * CurrencyPicker will skip fetching currencies if props.currencies is provided
   */
  currencies: PropTypes.arrayOf(PropTypes.shape({})),
  disabled: PropTypes.bool,
  onAdditionalCurrencyClick: PropTypes.func,
  selected: PropTypes.shape({}),
  getProductionCurrencyOptions: PropTypes.func.isRequired,
  getCurrencyOptions: PropTypes.func.isRequired,
  onSaveCurrencies: PropTypes.func,
  setSelected: PropTypes.func.isRequired,
  openDrawer: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  showAddedCurrencies: PropTypes.bool,
  testId: PropTypes.string,
};

CurrencyPicker.defaultProps = {
  isEditing: false,
  showAddedCurrencies: false,
  getProductionCurrencyOptions: [],
  onSaveCurrencies: always,
};

export default enhancer(CurrencyPicker);
