import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
  container: {
    '& > *': {
      marginBottom: theme.spacing(2),
    },
  },
  libraryInput: {
    paddingLeft: theme.spacing(),
  },
}));

export default styles;
