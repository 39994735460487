/* eslint-disable react/no-children-prop */
import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

import classnames from 'classnames';
import { concatStrings } from 'utils';
import { appColors } from '../theme';

const muiColors = {
  initial: 'initial',
  inherit: 'inherit',
  primary: 'primary',
  secondary: 'secondary',
  textPrimary: 'textPrimary',
  textSecondary: 'textSecondary',
  error: 'error',
};
export const variants = {
  // Material
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
  subtitle1: 'subtitle1',
  subtitle2: 'subtitle2',
  body1: 'body1',
  body2: 'body2',
  caption: 'caption',
  button: 'button',
  overline: 'overline',
  srOnly: 'srOnly',
  inherit: 'inherit',
  // Qwire style guide
  main: 'body2',
  formLabel: 'caption',
  sectionHeader: 'body1',
  pageTitle: 'h6',
  drawerTitle: 'h4',
  tiny: 'overline',
};

const getMuiColor = (color) => muiColors[color] || 'inherit';
const getColor = (color) => appColors[color];
const getVariant = (variant) => variants[variant] || 'body2';

const Text = ({
  color,
  variant,
  bold,
  semibold,
  link,
  children,
  noWrap,
  htmlFor,
  onClick,
  component,
  underlined,
  align,
  italic,
  gutterBottom,
  display,
  testId,
  lineHeight,
  whiteSpace,
  className,
}) => (
  <Typography
    children={children}
    className={classnames(
      {
        bold,
        italic,
        semibold,
        link,
        underlined,
        clickable: Boolean(onClick),
      },
      className,
    )}
    style={{ color: getColor(color), lineHeight, whiteSpace }}
    component={component}
    align={align}
    color={getMuiColor(color)}
    noWrap={noWrap}
    onClick={onClick}
    variant={getVariant(variant)}
    htmlFor={htmlFor}
    gutterBottom={gutterBottom}
    display={display}
    data-testid={concatStrings('-')('text', testId)}
  />
);

const allowedColors = Object.keys(muiColors).concat(Object.keys(appColors));
export const allowedVariants = Object.keys(variants);

Text.defaultProps = {
  display: 'initial',
  testId: 'mui-typography',
};

Text.propTypes = {
  color: PropTypes.oneOf(allowedColors),
  /**
   * 23px h3
   * 22px h4
   * 20px h5
   * 18px h6
   * 14px body2/subtitle2
   * 12px caption/button
   * 10px overline
   */
  align: PropTypes.oneOf(['inherit', 'left', 'center', 'right', 'justify']),
  variant: PropTypes.oneOf(allowedVariants),
  bold: PropTypes.bool,
  italic: PropTypes.bool,
  semibold: PropTypes.bool,
  noWrap: PropTypes.bool,
  link: PropTypes.bool,
  htmlFor: PropTypes.string,
  underlined: PropTypes.bool,
  gutterBottom: PropTypes.bool,
  onClick: PropTypes.func,
  component: PropTypes.string,
  display: PropTypes.oneOf(['initial', 'block', 'inline']),
  children: PropTypes.any,
  testId: PropTypes.string,
  lineHeight: PropTypes.string,
  whiteSpace: PropTypes.string,
  className: PropTypes.string,
};

export default Text;
