/* eslint-disable import/no-cycle */
import { connect } from 'react-redux';
import { getTerritoryLetterNameById } from 'redux-core/rights/selectors';
import { setRightPreset } from 'redux-core/rights/actions';

const mapStateToProps = (state, { territory }) => ({
  territoryLetterName: getTerritoryLetterNameById(territory.id)(state),
});

const mapDispatchToProps = {
  setRightPreset,
};

export default connect(mapStateToProps, mapDispatchToProps);
