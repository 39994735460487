/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const Project = (props) => (
  <SvgIcon {...props}>
    <path fill="#009DFB" d="M3 8H21V21H3z" />
    <path fill="#EEE" stroke="#009DFB" d="M4.04 7.5l3.2-4h9.52l3.2 4H4.04z" />
    <path
      fill="#fff"
      d="M11.377 13.709h.697c.652 0 1.14-.128 1.463-.383.324-.26.486-.636.486-1.128 0-.497-.137-.863-.41-1.1-.27-.237-.693-.356-1.272-.356h-.964v2.967zm4.785-1.586c0 1.076-.337 1.898-1.012 2.468-.67.57-1.624.854-2.864.854h-.909V19h-2.12V9.006h3.193c1.212 0 2.133.262 2.762.786.633.52.95 1.296.95 2.331z"
    />
  </SvgIcon>
);

export default Project;
