import { Box } from '@mui/system';
import { Divider } from '@material-ui/core';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';

import Form from 'components/common/Form';
import { FormButton, FormDatePicker, FormDropdown } from 'components/common/Form/Fields';
import { useRootTranslation } from 'utils/hooks';

import { All } from 'services/clear/clearance-status';
import { Grid, Typography } from '@mui/material';
import { LicensorClearanceStatus } from 'components/styleguide/ClearanceStatusTypeAhead';
import { getSearchPayload, GLOBAL_SEARCH_FILTER_BY, SEARCH_DATE_TYPES_OPTIONS } from '../../utils';
import SearchInput from '../SearchInput';
import useStyles from '../styles';

const validationSchema = Yup.object().shape({});

type TFilterBy = {
  value: string;
  sortBy: string;
  sortByDirection: 'ASC' | 'DESC';
};

type TSearchPayload = {
  term: string;
  filters: {
    filterBy: TFilterBy;
  };
};

export type LicensorsAndTracksFiltersProps = {
  term?: string;
  currentTab: {
    title: string;
    filterBy: TFilterBy;
    filteringByProductions: boolean;
    searchByDefault: string;
  };
  setAdvancedSearchFilters: (filters: TSearchPayload) => void;
  filterBy: TFilterBy;
};

const LicensorsAndTracksFilters = ({
  currentTab,
  filterBy,
  setAdvancedSearchFilters,
  term,
}: LicensorsAndTracksFiltersProps) => {
  const [termValue, setTerm] = useState(term);
  const rootT = 'globalSearch.filters';
  const t = useRootTranslation(rootT);
  const classes = useStyles();

  const onSubmit = (values) => {
    const payload = getSearchPayload({
      term: termValue,
      filters: {
        ...values.filters,
        filterBy,
      },
    });
    setAdvancedSearchFilters(payload);
  };

  const initialValues = {
    term,
    filters: {
      searchBy: currentTab.searchByDefault,
      dateType: SEARCH_DATE_TYPES_OPTIONS[0].id,
      clearanceStatusId: All.id,
    },
    dirty: true,
  };

  const onClear: any = (_v, _, { setValues }) => {
    const initialValuesWithEmptyTerm = {
      ...initialValues,
      term: '',
    };
    setValues(initialValuesWithEmptyTerm);
    setTerm('');
    onSubmit(initialValuesWithEmptyTerm);
  };

  useEffect(() => {
    onSubmit(initialValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Form disablePrompt initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
      <Grid container item justifyContent="space-between">
        <div>
          <Grid container alignItems="flex-end" sx={{ gap: '1rem' }}>
            <Box sx={{ width: '30%' }}>
              <SearchInput
                disabled={false}
                filterBy={filterBy}
                value={termValue}
                onChangeTerm={(e) => {
                  setTerm(e.target.value);
                }}
              />
            </Box>
            <Divider className={classes.divider} orientation="vertical" />
            <div className={classes.dateDropdown}>
              <FormDropdown label={t('date')} name="filters.dateType" options={SEARCH_DATE_TYPES_OPTIONS} />
            </div>
            <div className={classes.date}>
              <FormDatePicker label={t('startDate')} name="filters.dateStart" />
            </div>
            <div className={classes.date}>
              <FormDatePicker label={t('endDate')} name="filters.dateEnd" />
            </div>
            <div className={classes.status}>
              <Box>
                <Typography marginBottom="3px" sx={{ fontWeight: 700, fontSize: '0.8rem' }} variant="subtitle2">
                  {t('status')}
                </Typography>
                <LicensorClearanceStatus
                  name="filters.clearanceStatusId"
                  showAllOption
                  showParentOnly={currentTab.filterBy === GLOBAL_SEARCH_FILTER_BY.TRACKS}
                />
              </Box>
            </div>
          </Grid>
        </div>
        <Box mt={3} sx={{ display: 'flex', gap: '1rem' }}>
          <FormButton alwaysEnabled size="xs" onClick={onClear} variant="destructive">
            global.clear
          </FormButton>
          <FormButton size="xs" type="submit" variant="primary">
            global.forms.apply
          </FormButton>
        </Box>
      </Grid>
    </Form>
  );
};

export default LicensorsAndTracksFilters;
