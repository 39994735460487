import { Box, Step, StepLabel, Stepper } from '@mui/material';
import { useAtomValue } from 'jotai';
import { rootTranslation } from 'utils/format';
import { composeLetterAtom, composeLetterSteps } from './composeLetterStore';

const t = rootTranslation('composeLetters.stepper');

export function ComposeLetterStepper() {
  const composeLetter = useAtomValue(composeLetterAtom);

  return (
    <Box sx={{ width: '100%' }}>
      <Stepper activeStep={composeLetter.currentStep.stepNumber} alternativeLabel>
        {composeLetterSteps.map(({ stepNumber, label }) => (
          <Step data-testid={`compose-letter-step-${stepNumber}`} key={`compose-letter-step-${stepNumber}`}>
            <StepLabel>{t(label)}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}
