import { GLOBAL_SEARCH_FILTER_BY } from '../utils';
import { TrackDetails } from './TrackDetails';
import { LicensorDetails } from './LicensorDetails';

import enhancer from './enhancer';
import useStyles from './styles';
import { ProductionDetails } from './ProductionDetails';

const SearchDetails = ({
  cleanSetupFlow,
  filterBy,
  selected,
  closeAllDialogs,
  goToLink,
  keepSetupInfo,
  openModal,
  refetchWatching,
  toggleWatchingProduction,
  showInactive,
}) => {
  const classes = useStyles();

  if (!selected) return null;

  if (filterBy === GLOBAL_SEARCH_FILTER_BY.TRACKS.value) {
    return (
      <div className={classes.root}>
        <TrackDetails closeAllDialogs={closeAllDialogs} goToLink={goToLink} filterBy={filterBy} selected={selected} />
      </div>
    );
  }

  if (filterBy === GLOBAL_SEARCH_FILTER_BY.RIGHTS_OWNERS.value) {
    return (
      <div className={classes.root}>
        <LicensorDetails
          closeAllDialogs={closeAllDialogs}
          goToLink={goToLink}
          filterBy={filterBy}
          selected={selected}
        />
      </div>
    );
  }

  if (filterBy === GLOBAL_SEARCH_FILTER_BY.PRODUCTIONS.value) {
    return (
      <div className={classes.root}>
        <ProductionDetails
          cleanSetupFlow={cleanSetupFlow}
          selected={selected}
          toggleWatchingProduction={toggleWatchingProduction}
          refetchWatching={refetchWatching}
          goToLink={goToLink}
          closeAllDialogs={closeAllDialogs}
          keepSetupInfo={keepSetupInfo}
          openModal={openModal}
          showInactive={showInactive}
        />
      </div>
    );
  }

  return null;
};

export default enhancer(SearchDetails);
