import { BaseQueryFn, createApi } from '@reduxjs/toolkit/query/react';
import { AxiosError, AxiosRequestConfig } from 'axios';
import { toast, ToastOptions } from 'react-toastify';
import { rootTranslation } from 'utils/format';

import { postCallAPI } from 'utils/request';

const t = rootTranslation('global');

const toasterConfig: ToastOptions = {
  position: 'top-center',
  autoClose: 30000,
  hideProgressBar: true,
  closeOnClick: false,
  pauseOnHover: true,
  draggable: false,
  progress: undefined,
  theme: 'colored',
};

const axiosBaseQuery =
  (): BaseQueryFn<
    {
      url: string;
      method: AxiosRequestConfig['method'];
      body?: AxiosRequestConfig['data'];
      params?: AxiosRequestConfig['params'];
    },
    unknown,
    unknown
  > =>
    async ({ url, body }) => {
      try {
        const result = await postCallAPI({ path: url, body });
        return { data: result };
      } catch (error) {
        const status = error instanceof Number ? error : (error as AxiosError).response?.status;
        if (status && status !== 401 && status !== 409) {
          toast.error(t('genericError'), toasterConfig);
        }
        return { error: { status, data: error.response?.data } };
      }
    };

export const baseApi = createApi({
  reducerPath: 'qwireApi',
  baseQuery: axiosBaseQuery(),
  endpoints: () => ({}),
  refetchOnFocus: false,
  tagTypes: [
    'getProductionRights',
    'clearanceStatusList',
    'clearanceBundle',
    'cueSheetStatusList',
    'clearances',
    'licensorsContacts',
    'licensorsGetAll',
    'getLetterDraft',
    'clearancesView',
    'user-ui-configs',
    'unions',
    'tracksArtistsSearch',
    'getAllRightsPresets',
    'getAllUngroupedLicenses',
    'getAllPayees',
    'getBundleLicenses',
    'getTrack',
    'getTrackClearanceStatus',
    'getTrackClearanceFees',
  ],
});

export type TQwireAccessToken = Partial<{
  userId: number;
  userName: string;
  userEmail: string;
  divisionId: number;
  tenantId: number;
  originApp: 'qclear' | 'qcue';
}>;

export type TPagination = {
  totalCount: number;
  pageCount: number;
  page: number;
  size: number;
};

export type TBaseEntityDto = {
  id: number;
  name: string;
};
