import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { ButtonBase, Grid } from '@mui/material';
import { useAtomValue, useSetAtom } from 'jotai';
import React, { ReactNode } from 'react';
import { Text } from 'components/styleguide';
import { Trans } from 'react-i18next';
import Box from '@material-ui/core/Box';
import { ComposeLetterStepActions } from './ComposeLetterStepActions';
import { composeLetterAtom } from './composeLetterStore';
import ComposeLetterLicensorSelector from './ComposeLetterLicensorSelector';
import { useRootTranslation } from '../../utils/hooks';

export type TComposeLetterStepActionsProps = {
  children: ReactNode;
  isSubmitting: boolean;
  goBack: () => Promise<void>;
  skip?: () => Promise<void>;
};

export function ComposeLetterLeftPanel({ children, isSubmitting, goBack, skip }: TComposeLetterStepActionsProps) {
 const t = useRootTranslation('composeLetters.stepper');
  const composeLetter = useAtomValue(composeLetterAtom);
  const setComposeLetter = useSetAtom(composeLetterAtom);
  const width = composeLetter.leftPanelHidden ? '60px' : '25%';

  return (
    <Grid
      item
      sx={{ borderRight: '1px solid #eee', maxHeight: '100%', position: 'relative', width }}
      container
      flexDirection="column"
    >
      <ButtonBase
        onClick={() => setComposeLetter((prev) => ({ ...prev, leftPanelHidden: !prev.leftPanelHidden }))}
        sx={{ position: 'absolute', right: '15px', top: '20px' }}
        type="button"
      >
        {!composeLetter.leftPanelHidden && <ChevronLeftIcon />}
        {composeLetter.leftPanelHidden && <ChevronRightIcon />}
      </ButtonBase>
      {!composeLetter.leftPanelHidden && (
        <>
          <Grid container overflow="auto" px={3} py={2}>
            <Text semibold variant="h3">
              {t('title')}
            </Text>
            <Grid container>
              <Trans i18nKey="letters.generate.queuedLetters" count={composeLetter.licensorQueue.length || 0}>
                <Text variant="caption" />
                <Box ml={0.5} />
                <Text bold variant="caption" />
              </Trans>
            </Grid>
          </Grid>
          <Grid container overflow="auto" px={3} py={1}>
            <ComposeLetterLicensorSelector />
          </Grid>
          <Grid flex={1} p={3} gap={2} container overflow="auto">
            {children}
          </Grid>
          <Grid>
            <ComposeLetterStepActions isSubmitting={isSubmitting} goBack={goBack} skip={skip} />
          </Grid>
        </>
      )}
    </Grid>
  );
}
