import { baseApi } from 'services';

export type TSeasonRelease = {
  id: number;
  name: string;
};

type TGetAllPayload = {
  archived?: number;
  id?: number;
  projectId?: number;
  parentId?: number;
  type?: string;
};

export const seasonReleasesApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getSeasonReleases: builder.query<TSeasonRelease[], TGetAllPayload>({
      query: (body) => ({
        url: 'common/season-releases/get-all',
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const { useGetSeasonReleasesQuery } = seasonReleasesApi;
