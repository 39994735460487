import { Box } from '@mui/material';
import { FormControlLabelWrapper } from 'components/common/Form/Fields/FormField/FormField';
import { useGetSeasonReleasesQuery } from 'services/common/season-releases';
import { rootTranslation } from 'utils/format';
import { AutocompleteBaseField } from './AutocompleteBaseField';

const t = rootTranslation('drawers.searchProductions');

type TItem = {
  id: number;
  name: string;
};

type TSeasonReleaseFilterProps = {
  value?: TItem;
  seasonId: number;
  onChange: (nextValue: TItem) => void;
};

export function SeasonReleaseFilter({ value, seasonId, onChange }: TSeasonReleaseFilterProps) {
  const {
    data: seasonReleases,
    isLoading,
    isFetching,
  } = useGetSeasonReleasesQuery({ parentId: seasonId }, { skip: !seasonId });

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
      <FormControlLabelWrapper name="releaseId" label={t('release')}>
        <AutocompleteBaseField
          value={value}
          onChange={onChange}
          items={seasonReleases}
          idKey="id"
          labelKey="name"
          isLoading={isLoading}
          isFetching={isFetching}
          testId="season-release-filter"
        />
      </FormControlLabelWrapper>
    </Box>
  );
}
