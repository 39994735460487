import { TDraggableItem } from 'components/ProductionOverview/CueSheet/CueSheetTable/CueSheetTableContent/types';
import { cond, stubTrue } from 'lodash';

type TReorderParams = {
  initialItems: TDraggableItem[];
  originSequence: number;
  targetSequence: number;
};

const findCueBySequence = (sequence: number) => (item: TDraggableItem) => item.itemIndex === sequence - 1;

export function reorder({ initialItems, originSequence, targetSequence }: TReorderParams) {
  const allItems = initialItems.flatMap((item) => {
    if (item.type === 'segment') {
      const items = item.segment.draggableItems.map((segmentItem) => ({
        ...segmentItem,
        segmentId: item.segment.id,
      }));
      return items;
    }
    return item;
  });

  const nextTargetSequence = cond([
    [(value) => value > allItems.length, () => allItems.length],
    [(value) => value <= 0, () => 1],
    [stubTrue, () => targetSequence],
  ])(targetSequence);

  const origin = allItems.find(findCueBySequence(originSequence));
  const target = allItems.find(findCueBySequence(nextTargetSequence));

  const positionChangePayload = {
    originId: origin.type === 'segment' ? origin.segment.id : origin.cue.cue.id,
    targetId: target.cue.cue.id,
    targettingSegment: target?.segmentId != null,
  };

  return { positionChangePayload, originType: origin.type };
}
