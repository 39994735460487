/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { useRootTranslation } from 'utils/hooks';
import { Row, Text } from 'components/styleguide';
import pick from 'ramda/src/pick';
// eslint-disable-next-line import/no-named-as-default
import BaseAdvancedFilters from '../BaseAdvancedFilters';
import AssetsFilters from '../Filters/AssetsFilters';
import CuesFilters from '../Filters/CuesFilters';
import enhancer from './enhancer';
import { FILTERS_LIST_FOR_CUES, FILTERS_LIST_FOR_ASSETS } from '../Filters/constants';

function AssetsAdvancedFiltersDrawer({ payload, ...props }) {
  const drawerProps = {
    ...pick(['name', 'open', 'onClose'], props),
    payload,
  };

  const t = useRootTranslation('drawers.advancedFilters');

  return (
    <BaseAdvancedFilters {...drawerProps}>
      {({ filtersData, loading }) => (
        <>
          <Row pb={2} divider>
            <Text variant="subtitle1" bold>
              {t('assetsTitle')}
            </Text>
          </Row>
          <AssetsFilters filtersData={pick(FILTERS_LIST_FOR_ASSETS, filtersData)} loading={loading} />
          <Row pb={2} divider>
            <Text variant="subtitle1" bold>
              {t('cuesTitle')}
            </Text>
          </Row>
          <CuesFilters filtersData={pick(FILTERS_LIST_FOR_CUES, filtersData)} loading={loading} />
        </>
      )}
    </BaseAdvancedFilters>
  );
}

AssetsAdvancedFiltersDrawer.propTypes = {
  payload: PropTypes.object.isRequired,
  addParams: PropTypes.func.isRequired,
};

export default enhancer(AssetsAdvancedFiltersDrawer);
