/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import MuiBadge from '@material-ui/core/Badge';
import useStyles from './styles';

export const BADGE_VARIANT = {
  DOT: 'dot',
  STANDARD: 'standard',
  INLINE: 'inline',
};

const Badge = (props) => {
  const { variant, outline: _, ...muiProps } = props;
  const classes = useStyles(props);

  if (variant === BADGE_VARIANT.INLINE) {
    return <MuiBadge {...muiProps} classes={classes} data-testid="custom-badge" />;
  }

  return (
    <MuiBadge
      {...muiProps}
      variant={variant}
      classes={{
        dot: classes.dot,
      }}
      data-testid="mui-badge"
    />
  );
};

Badge.propTypes = {
  variant: PropTypes.oneOf(Object.values(BADGE_VARIANT)),
  outline: PropTypes.bool,
  color: PropTypes.string,
};

export default Badge;
