/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-return-await */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Drawer } from '@mui/material';

import classnames from 'classnames';
import useStyles from './styles';

const BaseDrawer = ({ anchor, children, onClose, open, dense, large, xlarge, wide, fullWidth, ...rest }) => {
  const classes = useStyles();
  const [isTransitioning, setIsTransitioning] = useState(false);

  return (
    <Drawer
      ModalProps={{
        disableBackdropClick: isTransitioning,
      }}
      SlideProps={{
        onEnter: async () => await setIsTransitioning(true),
        onEntered: async () => await setIsTransitioning(false),
        onExit: async () => await setIsTransitioning(true),
        onExited: async () => await setIsTransitioning(false),
      }}
      anchor={anchor}
      open={open}
      onClose={onClose}
      classes={{
        paper: classnames(classes.drawer, { dense, wide, large, xlarge, fullWidth }),
      }}
      data-testid="base-drawer"
      {...rest}
    >
      {open && children}
    </Drawer>
  );
};

BaseDrawer.propTypes = {
  anchor: PropTypes.oneOf(['left', 'top', 'right', 'bottom']),
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  open: PropTypes.bool,
  dense: PropTypes.bool,
  large: PropTypes.bool,
  xlarge: PropTypes.bool,
  wide: PropTypes.bool,
};

BaseDrawer.defaultProps = {
  anchor: 'right',
  dense: false,
  wide: false,
};
export default BaseDrawer;
