/* eslint-disable react/no-children-prop */
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import compose from 'ramda/src/compose';
import nth from 'ramda/src/nth';
import prop from 'ramda/src/prop';
import reject from 'ramda/src/reject';

import { formatCurrency } from 'utils/format';
import { Text } from 'components/styleguide';
import { getNormalizedProductionType } from 'utils/productions';
import { concatObjects } from 'utils';
import { NORMALIZED_PRODUCTION_TYPES } from 'utils/constants';
import { useRootTranslation } from 'utils/hooks';

import { SearchUsageChip } from '../SearchUsageChip';
import { getUseAndDuration } from '../utils';
import { Description } from './Description';

export const LicensorExtraDetails = ({ selected }) => {
  const t = useRootTranslation('globalSearch.details.licensor');

  const projectPath = compose(
    concatObjects(' - '),
    reject((item) => ['Track', 'Rights Owner'].includes(item.type)),
  )(selected.prodArray);

  const normalizedProdType = getNormalizedProductionType(selected.productionType);
  const isCampaign = normalizedProdType === NORMALIZED_PRODUCTION_TYPES.MKT;

  const trackInfo = selected.prodArray[selected.prodArray.length - 2];

  const { cueContainers } = trackInfo;
  const cueContainersTitle = concatObjects(', ', 'cueContainerName')(cueContainers);
  const cueContainersDescription = concatObjects(', ', 'cueContainerDescription')(cueContainers);

  const requestedUses = getUseAndDuration(cueContainers, 'requested');
  const actualUses = getUseAndDuration(cueContainers, 'actual');

  const approvedFees = Number(trackInfo.approvedFees);
  const estimatedFees = Number(trackInfo.estimatedFees);

  const currency = compose(prop('selectedCurrency'), nth(-2))(selected.prodArray);

  return (
    <>
      <Grid container item xs={12}>
        <Divider />
      </Grid>

      <Grid container item xs={12} spacing={2}>
        <Description title={t('project')} value={projectPath} />
        <Description
          title={isCampaign ? t('assetTitle') : t('sceneTitle')}
          value={cueContainersTitle}
          testId="sceneTitle"
        />
      </Grid>

      <Grid container item xs={12} spacing={2}>
        <Description
          title={isCampaign ? t('assetDescription') : t('sceneDescription')}
          value={cueContainersDescription}
        />
        <Grid item xs={3} container direction="column">
          <Text bold variant="caption" children={t('requestedUse')} />
          {requestedUses.map((usage) => (
            <SearchUsageChip usage={usage} key={usage} />
          ))}
        </Grid>
        <Grid item xs={3} container direction="column">
          <Text bold variant="caption" children={t('actualUse')} />
          {actualUses.map((usage) => (
            <SearchUsageChip usage={usage} key={usage} />
          ))}
        </Grid>
      </Grid>

      <Grid container item xs={7} spacing={1}>
        <Description title={t('estimatedFees')} value={formatCurrency(estimatedFees, currency)} />
        <Description title={t('approvedFees')} value={formatCurrency(approvedFees, currency)} />
      </Grid>
    </>
  );
};
