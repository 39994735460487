import { compose } from 'redux';
import { connect } from 'react-redux';
import { openDrawer } from 'redux-core/dialogs/actions';
import { getTenantId, getDivisionId } from 'redux-core/productions/selectors';
import withStyles from './styles';

const mapStateToProps = (state) => ({
  setupInfo: {
    tenantId: getTenantId(state),
    divisionId: getDivisionId(state),
  },
});

const mapDispatchToProps = { openDrawer };

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles);
