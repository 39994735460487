import React from 'react';
import PropTypes from 'prop-types';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';
import useStyles from '../styles';

const MenuItemContent = ({ option }) => {
  const classes = useStyles();
  return (
    <>
      <ListItemIcon className={classes.icon}>{option.icon}</ListItemIcon>
      <Typography variant="inherit">{option.label}</Typography>
    </>
  );
};

MenuItemContent.propTypes = {
  option: PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }),
};

export default MenuItemContent;
