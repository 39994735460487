/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable max-len */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const Import = (props) => (
  <SvgIcon {...props} viewBox="4 3 24 24">
    <path d="M15 19l4-4h-3V6h-2v9h-3l4 4zm9-13h-6v1.99h6v14.03H6V7.99h6V6H6c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h18c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2z" />
  </SvgIcon>
);

export default Import;
