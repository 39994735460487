/* eslint-disable react/default-props-match-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { OutlinedInput } from '@mui/material';
import { FormInputPropTypes } from 'components/styleguide/fields/types';
import compose from 'ramda/src/compose';
import withFormControl from 'components/styleguide/fields/withFormControl';
import withAdornments from 'components/styleguide/HOC/withAdornments';
import { concatStrings } from 'utils/index';
import useStyles from './styles';

const InputText = ({
  classesOverrides,
  autoFocus,
  direction,
  endAdornment,
  inputProps,
  inputRef,
  name,
  onBlur,
  onChange,
  onFocus,
  onKeyDown,
  onKeyPress,
  placeholder,
  readOnly,
  rows,
  startAdornment,
  testId,
  textAlign,
  textArea,
  type,
  value,
  split,
  fullWidth,
  disabled,
  error,
  ...props
}) => {
  const classes = useStyles({ split, textAlign, error });

  return (
    <OutlinedInput
      classes={{
        root: classes.root,
        ...classesOverrides,
      }}
      disabled={disabled}
      aria-describedby={name}
      autoFocus={autoFocus}
      fullWidth={fullWidth}
      className={classnames({ readOnly })}
      endAdornment={endAdornment}
      data-testid={concatStrings('-')('input-text', testId)}
      id={name}
      multiline={textArea}
      name={name}
      onBlur={(event) => {
        /**
         * Notice that the first argument (event) might be undefined.
         * If the event is undefined, calling onBlur event from Formik field
         * causes a Formik internal error, which crashes the app.
         * Solution inspired by: https://github.com/stackworx/formik-mui/issues/172
         */
        if (event) {
          onBlur(event);
        }
      }}
      onChange={onChange}
      onFocus={onFocus}
      onKeyDown={onKeyDown}
      onKeyPress={onKeyPress}
      placeholder={placeholder}
      readOnly={readOnly}
      rows={rows}
      startAdornment={startAdornment}
      style={props.style}
      type={type}
      value={value || ''}
      inputProps={{
        ...inputProps,
        className: classnames(inputProps.className, classes.input, {
          rtl: direction === 'rtl',
        }),
      }}
      inputRef={inputRef}
    />
  );
};

InputText.propTypes = {
  ...FormInputPropTypes,
  classesOverrides: PropTypes.shape({}),
  direction: PropTypes.oneOf(['ltr', 'rtl']),
  textAlign: PropTypes.oneOf(['center', 'left', 'right']),
  textArea: PropTypes.bool,
  /** Remove border-radius to stack along other components */
  split: PropTypes.oneOf(['left', 'right', 'middle']),
};

InputText.defaultProps = {
  value: '',
  direction: 'ltr',
  inputProps: {},
  rows: 5,
  textAlign: 'left',
};

export default compose(withFormControl, withAdornments)(InputText);
