import PropTypes from 'prop-types';

export const DRAWERS = {
  LETTERS_EXTRA_ATTACHMENTS: 'LETTERS_EXTRA_ATTACHMENTS',
  LETTERS_SET_DATES: 'LETTERS_SET_DATES',
  LICENSOR_LETTER_SET_DATES: 'LICENSOR_LETTER_SET_DATES',
  PRODUCTION_ADD_TRACK: 'PRODUCTION_ADD_TRACK',
  PRODUCTION_CREATE_TRACK: 'PRODUCTION_CREATE_TRACK',
  PRODUCTION_SETUP_ASSETS: 'PRODUCTION_SETUP_ASSETS',
  PRODUCTION_SETUP_COMPANIES: 'PRODUCTION_SETUP_COMPANIES',
  PRODUCTION_SETUP_COMPANY: 'PRODUCTION_SETUP_COMPANY',
  PRODUCTION_SETUP_PROJECT: 'PRODUCTION_SETUP_PROJECT',
  PRODUCTION_SETUP_CURRENCIES: 'PRODUCTION_SETUP_CURRENCIES',
  PRODUCTION_SETUP_RELEASE: 'PRODUCTION_SETUP_RELEASE',
  PRODUCTION_SETUP_SEASON: 'PRODUCTION_SETUP_SEASON',
  PRODUCTION_SETUP_SCENES: 'PRODUCTION_SETUP_SCENES',
  PERSONNEL_ADD_EDIT_POSITION: 'PERSONNEL_ADD_EDIT_POSITION',
  PERSONNEL_LIST_PERSONNEL: 'PERSONNEL_LIST_PERSONNEL',
  PERSONNEL_ADD_KEY_PERSONNEL: 'PERSONNEL_ADD_KEY_PERSONNEL',
  PRODUCTION_OVERVIEW_BUDGET: 'PRODUCTION_OVERVIEW_BUDGET',
  PRODUCTION_OVERVIEW_FILE: 'PRODUCTION_OVERVIEW_FILE',
  PRODUCTION_SETUP_AKA_TITLES: 'PRODUCTION_SETUP_AKA_TITLES',
  PRODUCTION_SETUP_FKA_TITLES: 'PRODUCTION_SETUP_FKA_TITLES',
  PRODUCTION_SETUP_BLANKET_AGREEMENTS: 'PRODUCTION_SETUP_BLANKET_AGREEMENTS',
  PRODUCTION_QUICK_SETUP_PRODUCTION: 'PRODUCTION_QUICK_SETUP_PRODUCTION',
  PRODUCTION_DETAILS_EDITORS: 'PRODUCTION_DETAILS_EDITORS',
  PRODUCTION_DETAILS_DISTRIBUTORS: 'PRODUCTION_DETAILS_DISTRIBUTORS',
  PRODUCTION_DETAILS_PRODUCERS: 'PRODUCTION_DETAILS_PRODUCERS',
  PRODUCTION_DETAILS_DIRECTORS: 'PRODUCTION_DETAILS_DIRECTORS',
  PRODUCTION_DETAILS_ACTORS: 'PRODUCTION_DETAILS_ACTORS',
  PRODUCTION_DETAILS_NETWORKS: 'PRODUCTION_DETAILS_NETWORKS',
  PRODUCTION_DETAILS_CURRENCIES: 'PRODUCTION_DETAILS_CURRENCIES',
  PRODUCTION_DETAILS_COMPANIES: 'PRODUCTION_DETAILS_COMPANIES',
  SETTINGS_RIGHTS_PRESET_TERRITORIES: 'SETTINGS_RIGHTS_PRESET_TERRITORIES',
  SETTINGS_CONTACTS: 'SETTINGS_CONTACTS',
  CLEARANCE_OPTION: 'CLEARANCE_OPTION',
  CLEARANCE_ADD_EDIT: 'CLEARANCE_ADD_EDIT',
  CLEARANCE_FULL_LIST: 'CLEARANCE_FULL_LIST',
  SEARCH_DRAWER: 'SEARCH_DRAWER',
  SEARCH_PRODUCTION_DRAWER: 'SEARCH_PRODUCTION_DRAWER',
  TRACK_FILE: 'TRACK_FILE',
  VERSION_MANAGER_ADD_EDIT: 'VERSION_MANAGER_ADD_EDIT',
  VERSION_MANAGER_DUPLICATE: 'VERSION_MANAGER_DUPLICATE',
  CAMPAIGN_COMPLETE_ASSET: 'CAMPAIGN_COMPLETE_ASSET',
  QWIRE_TRACKS_SYNC: 'QWIRE_TRACKS_SYNC',
  TASK_VIEW: 'TASK_VIEW',
  TASK_CREATE: 'TASK_CREATE',
  TASK_EDIT: 'TASK_EDIT',
  SCENARIO_MANAGER_ADD_EDIT: 'SCENARIO_MANAGER_ADD_EDIT',
  SHARE_WITH_A_USER: 'SHARE_WITH_A_USER',
  INVITE_USERS: 'INVITE_USERS',
  GLOBAL_SEARCH_FILTER: 'GLOBAL_SEARCH_FILTER',
  UNASSIGNED_BIN: 'UNASSIGNED_BIN',
  USAGE_NOTES: 'USAGE_NOTES',
  RIGHT_PRESET: 'RIGHT_PRESET',
  WATCHING_ASSETS_DRAWER: 'WATCHING_ASSETS_DRAWER',
  LICENSORS_ADD_EDIT: 'LICENSORS_ADD_EDIT',
  CHANGE_REP_DRAWER: 'CHANGE_REP_DRAWER',
  CLEARANCES_USES_AND_DURATIONS: 'CLEARANCES_USES_AND_DURATIONS',
  CLEARANCE_OTHER_USE_AND_DURATION: 'CLEARANCE_OTHER_USE_AND_DURATION',
  SEARCH_ASSETS_ADVANCED_DRAWER: 'SEARCH_ASSETS_ADVANCED_DRAWER',
  SEARCH_SCENES_ADVANCED_DRAWER: 'SEARCH_SCENES_ADVANCED_DRAWER',
  SEARCH_TRACKS_ADVANCED_DRAWER: 'SEARCH_TRACKS_ADVANCED_DRAWER',
  LICENSING_FILTER: 'LICENSING_FILTER',
  DROPZONE_FILES_DRAWER: 'DROPZONE_FILES_DRAWER',
  CUE_SHEET_DETAILS_DRAWER: 'CUE_SHEET_DETAILS_DRAWER',
  CUE_SHEET_VERSION: 'CUE_SHEET_VERSION',
  CUE_ADVANCED_SEARCH_DRAWER: 'CUE_ADVANCED_SEARCH_DRAWER',
  CUE_SHEET_SUBMISSION_HISTORY: 'CUE_SHEET_SUBMISSION_HISTORY',
  SUBMIT_CUE_SHEET: 'SUBMIT_CUE_SHEET',
  IMPORT_CUE_SHEET: 'IMPORT_CUE_SHEET',
  PAYEES_ADD_EDIT: 'PAYEES_ADD_EDIT',
  BUNDLE_DRAWER: 'BUNDLE_DRAWER',
  CHANGE_CUE_DETAILS: 'CHANGE_CUE_DETAILS',
  CUE_RIGHT_OWNERS_PRESET: 'CUE_RIGHT_OWNERS_PRESET',
  CUE_SHEET_EXPORT: 'CUE_SHEET_EXPORT',
  CUE_SHEET_SYNC: 'CUE_SHEET_SYNC',
  IMPORT_SAVE_AS_TEMPLATE: 'IMPORT_SAVE_AS_TEMPLATE',
  MAIN_CUE_SHEETS: 'MAIN_CUE_SHEETS',
  CUE_SHEET_SEGMENT_UPSERT: 'CUE_SHEET_SEGMENT_UPSERT',
  CUSTOM_STATUS: 'CUSTOM_STATUS',
};

/**
 * @TODO replace with BaseDrawerPropTypes
 * @see https://qwireholdings.atlassian.net/browse/CLR-2253
 */
export const CommonDrawerPropTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  clearForms: PropTypes.func, // withFormConnected
};

export default DRAWERS;
