import { Divider } from '@mui/material';

import { useFetch } from 'utils/hooks';
import { getProductionsUsers } from 'redux-core/productions/services';

import { Box } from '@mui/system';
import enhancer from './enhancer';
import { ProductionUsers } from './ProductionUsers';
import { ShareWithUserForm } from './ShareWithUserForm';

const ShareWithUserDrawer = ({
  name,
  onClose,
  openPromptAsync,
  currentUserId,
  payload: { divisionId, objectId, isSeasonRelease, isSeasonEpisode },
}) => {
  const [
    productionUsers,
    loadingProductionUsers,
    refetchProductionUsers,
  ] = useFetch(() => getProductionsUsers({ objectId }));

  return (
    <>
      <ShareWithUserForm
        drawerName={name}
        isSeasonRelease={isSeasonRelease}
        isSeasonEpisode={isSeasonEpisode}
        openPromptAsync={openPromptAsync}
        onClose={onClose}
        divisionId={divisionId}
        objectId={objectId}
        refetchProductionUsers={refetchProductionUsers}
      />
      <Box my={4}>
        <Divider />
      </Box>
      <ProductionUsers
        currentUserId={currentUserId}
        productionUsers={productionUsers}
        loadingProductionUsers={loadingProductionUsers}
        refetchProductionUsers={refetchProductionUsers}
        objectId={objectId}
        divisionId={divisionId}
      />
    </>
  );
};

export default enhancer(ShareWithUserDrawer);
