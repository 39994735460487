import { makeStyles, withStyles } from '@material-ui/core';

const listHeight = 115;
const searchStyles = (theme) => ({
  root: {
    position: 'relative',
  },
  paper: {
    position: 'absolute',
    top: 44,
    right: 0,
    left: 0,
    borderRadius: 4,
    width: '100%',
  },
  magnifyIcon: {
    color: theme.palette.white,
    position: 'absolute',
    top: -23,
    right: -15,
  },
  animate: {
    width: '100% !important',
    opacity: '1 !important',
  },
  maskedView: {
    backgroundColor: theme.palette.background.default,
    borderRadius: 4,
    width: 30,
    right: 0,
    top: -16,
    position: 'absolute',
    opacity: 0,
    transitionProperty: 'width, opacity',
    transitionDuration: '0.5s',
    transitionDelay: '0.01s',
  },
  clearIcon: {
    cursor: 'pointer',
  },
  advancedSearch: {
    backgroundColor: theme.palette.info.light,
    borderTop: '1px solid',
    borderTopColor: theme.palette.divider,
    cursor: 'pointer',
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(2.375),
  },
  noResults: {
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(2.375),
  },
});
export default withStyles(searchStyles);

export const listStyles = (theme) => ({
  title: {
    fontSize: '14px',
    color: theme.palette.text.primary,
    marginLeft: 19,
  },
  body: {
    width: '100%',
    maxHeight: listHeight * 4,
    overflow: 'auto',
    borderTop: '1px solid',
    borderTopColor: theme.palette.divider,
    marginTop: theme.spacing(1),
    paddingBottom: 0,
    paddingTop: 0,
    '& > :not(:last-child)': {
      borderBottom: '1px solid',
      borderBottomColor: theme.palette.divider,
    },
  },
  box: {
    backgroundColor: 'white',
    boxShadow: '0 8px 8px 0 rgba(0, 0, 0, 0.08), 0 16px 16px 0 rgba(0, 0, 0, 0.06)',
    maxHeight: '85vh',
    overflow: 'auto',
  },
});

export const useRecentsStyle = makeStyles((theme) => ({
  recentsHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '1em 1em 0 1em',
  },
  advancedSearchLink: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '1em',
    gap: '0.2em',
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    letterSpacing: '0.025em',
  },
}));
