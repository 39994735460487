import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { Alert, DialogActions, DialogContent, DialogContentText, DialogTitle, Fade, Tooltip } from '@mui/material';
import { Box } from '@mui/system';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import EditIcon from '@mui/icons-material/Edit';
import { SearchInput } from 'components/common/Grid/Search/SearchInput';
import { rootTranslation } from 'utils/format';
import { CueContainerPickerContent } from './CueContainerPickerContent';

const tGlobal = rootTranslation('global');
const t = rootTranslation('drawers.productionSetup.tracks.createTrack');

export type TPathElement = {
  id: number;
  name: string;
  divisionId?: number;
};

type CueContainerPickerProps = {
  value: TPathElement[] | null;
  onChange: (args: { selectedPath: TPathElement[] }) => void;
};

type TViewState = {
  term: string;
  open: boolean;
  selectedPath: TPathElement[] | null;
  showNoAssetSelectedMessage: boolean;
};

type TViewReducer = (state: TViewState, newState: Partial<TViewState>) => TViewState;

const viewReducer: TViewReducer = (state, newState) => ({ ...state, ...newState });

export function CueContainerPickerTreeView({ onChange, value = null }: CueContainerPickerProps) {
  const [viewState, setViewState] = React.useReducer(viewReducer, {
    term: '',
    open: false,
    selectedPath: value,
    showNoAssetSelectedMessage: false,
  });

  const selectedState = value != null;
  const selectedCueContainer = value?.at(-1);
  const editButtonTooltip = value?.map((it) => it.name).join(' / ');
  const defaultExpanded = value ? value.map((it) => it.id) : [];

  const handleOpen = () => {
    setViewState({ open: true });
  };

  const handleClose = () => {
    setViewState({ open: false, showNoAssetSelectedMessage: false, term: '' });
  };

  const handleAdd = () => {
    if (viewState.selectedPath === null) {
      setViewState({ showNoAssetSelectedMessage: true });
    } else {
      onChange({ selectedPath: viewState.selectedPath });
      handleClose();
    }
  };

  return (
    <Box sx={{ backgroundColor: 'white' }}>
      {selectedState ? (
        <DisclosureButtonEdit handleOpen={handleOpen} label={selectedCueContainer.name} tooltip={editButtonTooltip} />
      ) : (
        <DisclosureButtonDefault handleOpen={handleOpen} />
      )}
      <Dialog
        sx={{ '& .MuiDialog-paper': { width: 500, height: 700 } }}
        open={viewState.open}
        onClose={handleClose}
        scroll="paper"
      >
        <DialogTitle variant="h3" sx={{ pt: 4 }}>
          {t('scenePicker.disclosureButton')}
        </DialogTitle>
        <Box sx={{ px: 3 }}>
          <SearchInput
            value=""
            onChange={(term) => setViewState({ term })}
            placeholder={t('scenePicker.searchPlaceholder')}
          />
        </Box>
        <DialogContent sx={{ pb: 3, py: 2, pt: 0, mt: 2 }}>
          <DialogContentText id="scroll-dialog-description">
            <CueContainerPickerContent
              searchTerm={viewState.term}
              onSelectItem={(selectedPath) => setViewState({ selectedPath, showNoAssetSelectedMessage: false })}
              defaultExpanded={defaultExpanded}
              defaultSelected={selectedCueContainer?.id}
            />
          </DialogContentText>
        </DialogContent>
        {viewState.showNoAssetSelectedMessage && (
          <Fade in={viewState.showNoAssetSelectedMessage}>
            <Box sx={{ mx: 3 }}>
              <Alert severity="info">{t('scenePicker.noAssetSelected')}</Alert>
            </Box>
          </Fade>
        )}
        <DialogActions disableSpacing sx={{ display: 'flex', gap: '1rem', px: '1.5rem', pt: '1rem', pb: 4 }}>
          <Button color="secondary" variant="outlined" onClick={handleClose}>
            {tGlobal('forms.cancel')}
          </Button>
          <Button color="primary" variant="contained" sx={{ minWidth: '4rem' }} onClick={handleAdd}>
            {tGlobal('forms.add')}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

function DisclosureButtonDefault({ handleOpen }) {
  return (
    <Button
      variant="contained"
      color="primary"
      onClick={handleOpen}
      startIcon={<OpenInNewIcon />}
      sx={{ width: '100%' }}
    >
      {t('scenePicker.disclosureButton')}
    </Button>
  );
}

function DisclosureButtonEdit({ handleOpen, label, tooltip }) {
  return (
    <Tooltip title={tooltip}>
      <Button
        variant="outlined"
        color="primary"
        onClick={handleOpen}
        startIcon={<EditIcon />}
        sx={{
          width: '100%',
          backgroundColor: 'white',
        }}
      >
        <Box
          sx={{
            maxWidth: '268px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {label}
        </Box>
      </Button>
    </Tooltip>
  );
}
