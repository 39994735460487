/* eslint-disable no-nested-ternary */
import { usePrompt, useRootTranslation } from 'utils/hooks';
import { Button, Typography } from '@mui/material';
import { requestWithError } from 'utils/request';
import { exerciseOption, unexerciseOption } from 'redux-core/clearances/services';

export const ExerciseButton = ({
  isOption,
  clearance,
  // clearancesFetch,
  reload,
}) => {
  const { exercised, licensors, id, name, active } = clearance;
  const openPrompt = usePrompt();
  const t = useRootTranslation('clearanceDetails');

  if (!isOption || !active) return null;

  return (
    <Button
      onClick={(e) => {
        e.stopPropagation();
        openPrompt({
          onConfirm: async () => {
            await requestWithError(exercised ? unexerciseOption : exerciseOption, { id });
            reload();
          },
          content: t(
            exercised
              ? licensors?.letters?.length > 0
                ? 'optionUnexerciseWithLettersPrompt'
                : 'optionUnexercisePrompt'
              : 'optionExercisePrompt',
            {
              name,
            },
          ),
        });
      }}
    >
      <Typography align="center" sx={{ fontWeight: 'bold' }} color={exercised ? 'red' : 'darkGreen'}>
        {exercised ? t('unexercise') : t('exercise')}
      </Typography>
    </Button>
  );
};
