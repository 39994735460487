import { DragIndicator } from '@mui/icons-material';
import { Box, Button, TextField } from '@mui/material';
import { rootTranslation } from 'utils/format';
import { ColoredCircle } from './ColoredCircle';
import { TCustomStatus } from './types';

export type TEditableCustomStatusProps = {
  status: TCustomStatus;
  setStatusDetails: (status: TCustomStatus) => void;
  onSaveHandler: () => void;
};

const t = rootTranslation('drawers.customStatuses');

export const EditableCustomStatus = ({ status, setStatusDetails, onSaveHandler }: TEditableCustomStatusProps) => {
  const isNew = !status.id;
  return (
    <Box display="flex" gap={2} sx={{ width: '100%', alignItems: 'center' }}>
      {!status.isParent && <DragIndicator htmlColor={!isNew ? 'gray' : 'transparent'} />}
      <ColoredCircle status={status} />
      <TextField
        size="small"
        data-testid="custom-status-name"
        label={isNew ? 'New Status' : 'Label'}
        onChange={(event) => setStatusDetails({ ...status, label: event.target.value })}
        value={status.label}
        sx={{ flexGrow: '2' }}
        inputProps={{ maxLength: 30 }}
      />
      <Button data-testid="save-custom-status" variant="contained" onClick={onSaveHandler}>
        {t('save')}
      </Button>
    </Box>
  );
};
