/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const QwireBars = (props) => (
  <SvgIcon {...props}>
    <path d="M17.73 19.21V0h-2.244v19.711c.175.031.36.048.558.048.674 0 1.187-.177 1.685-.549zM12.157 16.71V5.848h-2.31v8.628c.946.645 1.686 1.465 2.31 2.234zM6.515 13.324V2.064H4.27v11.432a7.132 7.132 0 0 1 1.685-.194c.192 0 .377.01.559.022z" />
    <path d="M20.176 18.623c-1.102 1.388-2.164 2.512-4.132 2.512-1.968 0-3.03-1.122-4.132-2.512-.115-.144-.23-.292-.348-.441-1.282-1.642-2.737-3.504-5.608-3.504-2.871 0-4.326 1.862-5.608 3.504-.118.151-.233.297-.348.441h1.824c1.102-1.388 2.164-2.512 4.132-2.512 1.968 0 3.03 1.124 4.132 2.512.115.144.23.292.348.441 1.282 1.642 2.737 3.504 5.608 3.504 2.871 0 4.326-1.86 5.608-3.504.118-.149.233-.297.348-.441h-1.824z" />
  </SvgIcon>
);

export default QwireBars;
