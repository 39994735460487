import { MenuItem, Select } from '@mui/material';
import { FormField } from 'components/common/Form/Fields/FormField/FormField';
import { useFormContext } from 'react-hook-form';
import { TRACK_TYPES, TRACK_TYPES_OPTIONS } from 'utils/constants';
import { rootTranslation } from 'utils/format';

const t = rootTranslation('drawers.productionSetup.tracks.createTrack.form');

const filteredTrackTypeOptions = TRACK_TYPES_OPTIONS.filter(({ id }) => [TRACK_TYPES.LIBRARY].includes(id));

const options = [{ id: 'song', name: t('songType') }, ...filteredTrackTypeOptions];

export function TrackTypeSelect() {
  const { resetField } = useFormContext();

  return (
    <FormField
      name="type"
      label={t('type')}
      render={({ field }) => (
        <Select
          name="type"
          fullWidth
          {...field}
          onChange={(event) => {
            const nextValue = event.target.value;
            field.onChange(nextValue);

            if (nextValue === 'song') {
              resetField('library');
            }
          }}
        >
          {options.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.name}
            </MenuItem>
          ))}
        </Select>
      )}
    />
  );
}
