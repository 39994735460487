/* eslint-disable react/no-children-prop */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable consistent-return */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { DrawerHeader, Text } from 'components/styleguide';
import Form from 'components/common/Form';
import { useFetch, useRootTranslation } from 'utils/hooks';
import { Grid } from '@material-ui/core';
import { FormButton, FormRadioGroup } from 'components/common/Form/Fields';
import find from 'ramda/src/find';
import prop from 'ramda/src/prop';
import propEq from 'ramda/src/propEq';
import InfiniteScroll from 'components/common/InfiniteScroll/index';
import { DEFAULT_PAGINATION_RESPONSE } from 'utils/constants';
import useStyles from './styles';
import enhancer from './enhancer';

const rootT = 'drawers.settings.rights.territory';

const RadioLabel = ({ name, nameOnLetters }) => (
  <Grid container alignItems="center">
    <Grid item xs={6}>
      <Text>{name}</Text>
    </Grid>
    <Grid item xs={6}>
      <Text noWrap>{nameOnLetters}</Text>
    </Grid>
  </Grid>
);

const TerritoriesDrawer = ({ name, onClose, getTerritoryPresets, payload: { onSubmit, territoryId } }) => {
  const t = useRootTranslation(rootT);
  const classes = useStyles();
  const [territories, setTerritories] = useState([]);
  const [, , getTerritories] = useFetch(
    async (payload) => {
      if (!payload?.limit) return;
      const response = await getTerritoryPresets(payload);
      setTerritories((terr) => terr.concat(response.data));
      return response;
    },
    [],
    DEFAULT_PAGINATION_RESPONSE,
  );

  const handleSubmit = async ({ territoryId }) => {
    await onSubmit({
      id: territoryId,
      name: prop('name', find(propEq('id', territoryId), territories)),
    });
    await onClose();
  };
  const onCancel = () => {
    onClose();
  };

  return (
    <Form drawerName={name} onSubmit={handleSubmit} enableReinitialize initialValues={{ territoryId }}>
      <DrawerHeader title={t('title')} onClose={onClose}>
        <FormButton
          testId="territories-drawer-cancel"
          alwaysEnabled
          variant="destructive"
          size="xs"
          children="global.forms.cancel"
          onClick={() => onCancel()}
        />
        <FormButton
          testId="territories-drawer-save"
          children="global.forms.save"
          size="xs"
          type="submit"
          variant="primary"
        />
      </DrawerHeader>
      <Grid container className={classes.container}>
        <Grid container className={classes.header}>
          <Grid item xs={6}>
            <Text color="textSecondary">{t('presetName')}</Text>
          </Grid>
          <Grid item xs={6}>
            <Text color="textSecondary">{t('populateLetter')}</Text>
          </Grid>
        </Grid>
        <InfiniteScroll limit={20} onScroll={getTerritories}>
          <FormRadioGroup fullWidth customComponent={RadioLabel} name="territoryId" options={territories} />
        </InfiniteScroll>
      </Grid>
    </Form>
  );
};

TerritoriesDrawer.propTypes = {
  name: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  payload: PropTypes.shape({
    territoryId: PropTypes.number,
    onSubmit: PropTypes.func,
  }).isRequired,
  getTerritoryPresets: PropTypes.func.isRequired,
};

export default enhancer(TerritoriesDrawer);
