/* eslint-disable jsx-a11y/anchor-is-valid */
import { SnackbarContent } from '@material-ui/core';
import { Text } from 'components/styleguide';
import Link from '@material-ui/core/Link';
import React from 'react';

import { SUPPORT_EMAIL, SUPPORT_PHONE } from 'utils/constants';
import { useRootTranslation } from 'utils/hooks';
import PropTypes from 'prop-types';

const rootT = 'topbar.userMenu.help';

const HelpMenuSnackbarContent = ({ classes, closeSnackbar }) => {
  const t = useRootTranslation(rootT);
  const globalT = useRootTranslation('global');

  return (
    <SnackbarContent
      classes={{
        message: classes.message,
      }}
      message={
        <>
          <Text component="span">{t('emailUs')}</Text>&nbsp;
          <Link href={`mailto:${SUPPORT_EMAIL}`} underline="always">
            {SUPPORT_EMAIL}
          </Link>
          <Text display="block">{t('callUs', { phone: SUPPORT_PHONE })}</Text>
        </>
      }
      action={
        <Link underline="none" link onClick={closeSnackbar}>
          {globalT('ok')}
        </Link>
      }
    />
  );
};

HelpMenuSnackbarContent.propTypes = {
  closeSnackbar: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

export default HelpMenuSnackbarContent;
