/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable max-len */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const AddTrack = (props) => (
  <SvgIcon {...props} viewBox="0 0 30 30">
    <path d="M19.484 15.648V5.124c.001-.147-.028-.293-.085-.429-.057-.136-.142-.26-.249-.365-.107-.104-.234-.187-.374-.244-.14-.056-.29-.085-.442-.085-.116-.004-.231.01-.342.043L7.982 7.032c-.235.073-.44.216-.587.408-.147.193-.227.425-.23.664v11.325c-.727-.312-1.515-.47-2.31-.461-1.312 0-3.855.6-3.855 2.239 0 1.637 2.543 2.247 3.855 2.247 1.312 0 3.846-.6 3.846-2.247v-8.303l9.246-2.805v6.273c-.728-.31-1.514-.466-2.309-.462-1.312 0-3.855.6-3.855 2.247 0 1.647 2.543 2.24 3.855 2.24.238-.003.475-.02.71-.053.128-.96.476-1.88 1.018-2.693.543-.812 1.267-1.497 2.118-2.003z" />
    <path
      fillRule="evenodd"
      d="M22.98 15.326c-1.192 0-2.356.343-3.346.984-.99.642-1.762 1.554-2.218 2.621-.455 1.067-.574 2.241-.34 3.374.232 1.133.807 2.172 1.65 2.989.842.815 1.916 1.37 3.085 1.595 1.168.224 2.379.107 3.479-.336s2.04-1.192 2.7-2.154c.66-.96 1.012-2.09 1.01-3.245-.005-1.546-.64-3.027-1.77-4.119-1.127-1.092-2.656-1.707-4.25-1.709zm3.01 6.412h-2.408v2.335h-1.204v-2.326h-2.409v-1.194h2.409v-2.309h1.204v2.31h2.408v1.184z"
      clipRule="evenodd"
    />
  </SvgIcon>
);

export default AddTrack;
