/* eslint-disable react/no-children-prop */
import { FormButton } from 'components/common/Form/Fields';
import { DrawerHeader } from 'components/styleguide';
import { useRootTranslation } from 'utils/hooks';

export const ShareWithUserDrawerHeader = ({ onClose }) => {
  const t = useRootTranslation('drawers.shareWithUser');
  return (
    <DrawerHeader title={t('drawerTitle')} onClose={onClose}>
      <FormButton
        alwaysEnabled
        variant="destructive"
        size="xs"
        children="global.forms.cancel"
        testId="share-invitation-drawer-cancel"
        onClick={onClose}
      />
      <FormButton
        testId="share-invitation-drawer-save"
        children="global.forms.save"
        size="xs"
        type="submit"
        variant="primary"
      />
    </DrawerHeader>
  );
};
