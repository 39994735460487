/* eslint-disable import/no-cycle */
import { connect } from 'react-redux';

import { getProductionCurrencyOptions, getCurrencyOptions } from 'redux-core/productions/actions';
import { getSelectedSelector } from 'redux-core/overview/selectors';
import { setSelected } from 'redux-core/overview/actions';
import { openDrawer } from 'redux-core/dialogs/actions';

const mapStateToProps = (state) => ({
  selected: getSelectedSelector(state),
});

const mapDispatchToProps = {
  setSelected,
  getProductionCurrencyOptions,
  getCurrencyOptions,
  openDrawer,
};

export default connect(mapStateToProps, mapDispatchToProps);
