import { createSelector } from 'reselect';
import compose from 'ramda/src/compose';
import length from 'ramda/src/length';
import prop from 'ramda/src/prop';
import { getPermissionsSelector } from 'redux-core/permissions/selectors';
import { hasPermissions } from 'utils/index';
import { getIsQwireEmployeeSelector } from 'redux-core/auth/selectors';
// import { getHasPermission } from 'utils/index';
// import { COMMON_PERMISSIONS } from 'redux-core/permissions/constants';

const getDivisionRoot = prop('divisions');

export const getDivisionListSelector = createSelector(getDivisionRoot, prop('list'));

export const getLoadingDivisionsSelector = createSelector(getDivisionRoot, prop('loading'));

export const getSecuredDivisionsSelector =
  ({ permissions: commonPermissions, ...rest }) =>
  (state) => {
    const isQwireEmployee = getIsQwireEmployeeSelector(state);

    const expectedPermissions = { ...rest, ...commonPermissions };
    return createSelector(getDivisionListSelector, getPermissionsSelector, (divisions, userPermissions) =>
      divisions.filter(({ objectId }) =>
        hasPermissions(expectedPermissions, userPermissions[objectId], false, isQwireEmployee),
      ),
    )(state);
  };

export const getCanAccessDivisionSelector =
  (permissions, bypassLoading = true) =>
  (state) => {
    const isLoading = getLoadingDivisionsSelector(state);
    // Don't remove this line. Returning false in some cases is a non-reversible action. For example, blocking certain url and
    // redirecting the user to the Homepage.
    if (isLoading && bypassLoading) return true;
    return compose(Boolean, length, getSecuredDivisionsSelector(permissions))(state);
  };
