/* eslint-disable @typescript-eslint/no-shadow */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { InputFormat, Text } from 'components/styleguide';
import { useNumberFormat } from 'utils/hooks';
import isNil from 'ramda/src/isNil';
import { convertCurrency, convertCurrencyBack, formatCurrency } from 'utils/format';
import Box from '@material-ui/core/Box';
import { NumberFormatProps } from 'react-number-format';
import { TextFieldProps } from '@mui/material';

type InputCurrencyProps = NumberFormatProps<TextFieldProps> & {
  value: number;
  displayConversion: boolean;
  exchangeRate: number;
  masterCurrencyCode: string;
  masterCurrencySymbol: string;
  selectedCurrencySymbol: string;
  onChange: (value: number) => void;
  label: string;
};

const InputCurrency = ({
  value,
  disabled,
  displayConversion = true,
  exchangeRate: rate = 1,
  fullWidth,
  label,
  masterCurrencyCode,
  masterCurrencySymbol,
  onChange,
  placeholder,
  selectedCurrencySymbol,
  decimalScale = 2,
  prefix,
  ...props
}: InputCurrencyProps) => {
  // Safe against null values
  const exchangeRate = rate || 1;
  const [thousand, decimal] = useNumberFormat();
  const { t } = useTranslation();
  const displayValue = convertCurrency(value, exchangeRate, decimalScale);

  const handleChange = (value) => {
    const actualValue = convertCurrencyBack(value, exchangeRate, 8);
    onChange?.(actualValue);
  };

  const inputProps = {
    disabled,
    fullWidth,
    label: t(label),
    placeholder: isNil(placeholder) ? undefined : t(placeholder),
  };

  return (
    <>
      <InputFormat
        {...props}
        {...inputProps}
        type="currency"
        value={isNil(value) ? undefined : displayValue}
        separators={[thousand, decimal]}
        onChange={handleChange}
        // @ts-ignore
        prefix={prefix ?? (selectedCurrencySymbol || masterCurrencySymbol)}
      />
      {exchangeRate !== 1 && displayConversion && (
        <Box textAlign="right" pr={props.readOnly ? 0 : 2}>
          <Text color="darkGreen" noWrap variant="caption">
            {formatCurrency(value, masterCurrencyCode, decimalScale)}
          </Text>
        </Box>
      )}
    </>
  );
};

InputCurrency.displayName = 'InputCurrency';

export default InputCurrency;
