import always from 'ramda/src/always';
import compose from 'ramda/src/compose';
import concat from 'ramda/src/concat';
import cond from 'ramda/src/cond';
import ifElse from 'ramda/src/ifElse';
import includes from 'ramda/src/includes';
import is from 'ramda/src/is';
import map from 'ramda/src/map';
import prop from 'ramda/src/prop';
import range from 'ramda/src/range';
import reverse from 'ramda/src/reverse';
import T from 'ramda/src/T';
import find from 'ramda/src/find';
import propEq from 'ramda/src/propEq';
import i18n from 'locales/i18n';

const OO_URL = process.env.REACT_APP_OO_HOST;

export const ENV = {
  OO_HOST: OO_URL,
  OO_INCLUDE_URL: `${OO_URL}/web-apps/apps/api/documents/api.js`,
  SSO_SITE: process.env.REACT_APP_SSO_SITE,
  JASPER_URL: process.env.REACT_APP_JASPER_SERVER_URL,
  QUICKSIGHT_ENV: process.env.REACT_APP_QUICKSIGHT_ENV,
};

export const SUPPORT_EMAIL = 'support@qwireclear.com';
export const SUPPORT_PHONE = '424-282-8000';

export const arrayToOptions = map((v) => ({ id: v, name: v }));
export const objectToOptions = compose(
  map(([name, id]) => ({ id, name })),
  Object.entries,
);

export const translatedOptions = (rootT) =>
  compose(
    map(({ id, name }) => ({ id, name: i18n.t(`${rootT}.${name}`) })),
    ifElse(is(Array), arrayToOptions, objectToOptions),
  );

export const RATINGS = ['TV-Y', 'TV-Y7', 'TV-G', 'TV-PG', 'TV-14', 'TV-MA'];
export const RATINGS_OPTIONS = arrayToOptions(RATINGS);

export const DISTRIBUTORS = (rating, isDropdown = false) => {
  if (!rating) return [];
  const t = (id) => ({ id, name: i18n.t(`global.descriptors.${id}`) });
  const defaultDescriptors = [t('L'), t('S'), t('V')];
  const isIncluded = (arr) => (val) => includes(val)(arr);
  // Business logic
  const values = cond([
    [isIncluded(['TV-Y7']), always([t('FV')])],
    [isIncluded(['TV-14', 'TV-PG']), always(concat(defaultDescriptors)([t('D')]))],
    [T, always(defaultDescriptors)],
  ])(rating);
  if (!isDropdown) return map(prop('id'))(values);
  return values;
};

export const NORMALIZED_PRODUCTION_TYPES = {
  FEATURES: 'features',
  MKT: 'marketing',
  TV: 'television',
};

export const CUE_SHEET_TYPES = ['campaign_cuesheet', 'movie_cuesheet', 'series_cuesheet'];

export const PRODUCTION_TYPES_OPTIONS = [
  { id: NORMALIZED_PRODUCTION_TYPES.FEATURES, name: 'Features' },
  { id: NORMALIZED_PRODUCTION_TYPES.MKT, name: 'Marketing' },
  { id: NORMALIZED_PRODUCTION_TYPES.TV, name: 'TV' },
];

export const PROJECT_VIEW_MODES = {
  SCENARIO: 'scenario',
  TRACKS: 'tracks',
  SCENES: 'scenes',
  VERSIONS: 'versions',
  ASSETS: 'assets',
};

export const PRODUCTION_TYPES = {
  /** @TODO Ensure that RELEASE const actually exist in the BE, then delete this comment */
  RELEASE: 'release',
  FILM_RELEASE: 'filmRelease',
  EPISODE: 'episode',
  CAMPAIGN: 'campaign',
  PROJECT: 'project',
  SEASON: 'season',
  SEASON_RELEASE: 'seasonRelease',
};

export const PRE_CLEARANCE_TYPES = {
  DATERANGE: 'DateRange',
  PERPETUITY: 'Perpetuity',
};

export const PRODUCTION_TO_NORMALIZED_PRODUCTION_TYPES = {
  [PRODUCTION_TYPES.FILM_RELEASE]: NORMALIZED_PRODUCTION_TYPES.FEATURES,
  [PRODUCTION_TYPES.EPISODE]: NORMALIZED_PRODUCTION_TYPES.TV,
  [PRODUCTION_TYPES.CAMPAIGN]: NORMALIZED_PRODUCTION_TYPES.MKT,
};

export const SEARCH_NORMALIZED_PRODUCTION_TYPES = {
  CAMPAIGN: 'Campaign',
  MOVIE_RELEASE: 'FilmRelease',
  EPISODE: 'Episode',
  TRACK: 'Track',
};

export const SEARCH_NORMALIZED_PRODUCTION_TYPES_OPTIONS = [
  { id: SEARCH_NORMALIZED_PRODUCTION_TYPES.MOVIE_RELEASE, name: 'Features' },
  { id: SEARCH_NORMALIZED_PRODUCTION_TYPES.CAMPAIGN, name: 'Marketing' },
  { id: SEARCH_NORMALIZED_PRODUCTION_TYPES.EPISODE, name: 'TV' },
];

export const SEARCH_NORMALIZED_PRODUCTION_TYPES_TO_NORMALIZED_PRODUCTION_TYPES = {
  [SEARCH_NORMALIZED_PRODUCTION_TYPES.MOVIE_RELEASE]: NORMALIZED_PRODUCTION_TYPES.FEATURES,
  [SEARCH_NORMALIZED_PRODUCTION_TYPES.EPISODE]: NORMALIZED_PRODUCTION_TYPES.TV,
  [SEARCH_NORMALIZED_PRODUCTION_TYPES.CAMPAIGN]: NORMALIZED_PRODUCTION_TYPES.MKT,
};

export const CUE_CONTAINER_TYPES = {
  EPISODE_SCENE: 'EpisodeScene',
  RELEASE_SCENE: 'ReleaseScene',
  ASSET: 'Material',
};

export const RIGHTS_OWNER_TYPES = {
  MASTER: 'master',
  PUBLISHER: 'publisher',
  WRITER: 'writer',
  COMPOSER: 'composer',
  SYNC: 'sync',
};
export const RIGHTS_OWNER_TYPES_OPTIONS = translatedOptions('rightsOwners.types')(Object.values(RIGHTS_OWNER_TYPES));

export const RIGHTS_OWNER_ROLE = {
  None: 'N',
  Adapter: 'AD',
  Arranger: 'AR',
  Composer: 'C',
  ComposerAuthor: 'CA',
  SubArranger: 'SR',
  SubAuthor: 'SA',
  AssociatedPerformer: 'PR',
  Translator: 'TR',
  AuthorWriterLyricist: 'A',
};
export const RIGHTS_OWNER_ROLE_OPTIONS = translatedOptions('rightsOwners.roles')(Object.values(RIGHTS_OWNER_ROLE));

/*
Licensors = Master + Sync + Other
Rights Owners = Licensors + Publisher + Writer
*/

export const LICENSOR_TYPES = {
  MASTER: 'master',
  SYNC: 'sync',
  OTHER: 'other',
  ONE_STOP: 'one_stop',
};
export const LICENSOR_TYPES_OPTIONS = translatedOptions('licensors.types')(Object.values(LICENSOR_TYPES));

export const LICENSOR_FUNCTION = {
  SIDE_ARTIST: 'Side Artist',
  ESTATE: 'Estate',
  LIKENESS: 'Likeness',
};
export const LICENSOR_FUNCTION_OPTIONS = translatedOptions('licensors.functionOptions')(LICENSOR_FUNCTION);

export const LETTER_TYPES = {
  QUOTE_REQUEST: 'QUOTE_REQUEST',
  CONFIRMATION: 'CONFIRMATION',
  LICENSE: 'LICENSE',
  LICENSE_REQUEST: 'LICENSE_REQUEST',
  QUOTE_RENEWAL: 'QUOTE_RENEWAL',
  CHECK_REQUEST: 'CHECK_REQUEST',
};
export const LETTER_TYPES_OPTIONS = translatedOptions('letters.types')(Object.values(LETTER_TYPES));

export const LETTER_TYPE_FIELDS = {
  QUOTE_REQUEST: 'quote',
  CONFIRMATION: 'confirmation',
  LICENSE: 'license',
  LICENSE_REQUEST: 'licenseRequest',
  CHECK_REQUEST: 'checkRequest',
};

export const LETTER_STATUS_FIELDS = {
  Sent: 'SentAt',
  Received: 'ReceivedAt',
  Expires: 'ExpiresAt',
};

export const PHONE_TYPES = {
  PHONE: 'Phone',
  MOBILE: 'Mobile',
  OFFICE: 'Office',
  FAX: 'Fax',
};
export const PHONE_TYPES_OPTIONS = translatedOptions('phone.types')(Object.values(PHONE_TYPES));

export const WRITERS_COMPOSERS_TYPES = {
  AD: 'AD',
  AR: 'AR',
  C: 'C',
  CA: 'CA',
  SR: 'SR',
  SA: 'SA',
  PR: 'PR',
  TR: 'TR',
  A: 'A',
};
export const WRITERS_COMPOSERS_TYPES_OPTIONS = translatedOptions('writersComposers.types')(WRITERS_COMPOSERS_TYPES);

export const PUBLISHERS_TYPES = {
  E: 'E',
  SE: 'SE',
  ES: 'ES',
  PA: 'PA',
  AM: 'AM',
};
export const PUBLISHERS_TYPES_OPTIONS = translatedOptions('publishers.types')(PUBLISHERS_TYPES);

export const PRO_TYPES = Object.freeze({
  ASCAP: 'ASCAP',
  BMI: 'BMI',
  SESAC: 'SESAC',
  ABRAMUS: 'ABRAMUS',
  ACAM: 'ACAM',
  ACDAM: 'ACDAM',
  ACEMLA: 'ACEMLA',
  ACINPRO: 'ACINPRO',
  ACUM: 'ACUM',
  ADAGP: 'ADAGP',
  ADDAF: 'ADDAF',
  AEPI: 'AEPI',
  AGADU: 'AGADU',
  AGAYC: 'AGAYC',
  AKKA: 'AKKA',
  AKM: 'AKM',
  ALBAUTOR: 'ALBAUTOR',
  ALCS: 'ALCS',
  AMAR: 'AMAR',
  AMCOS: 'AMCOS',
  AMRA: 'AMRA',
  APA: 'APA',
  APDASPAC: 'APDASPAC',
  APDAYC: 'APDAYC',
  APRA: 'APRA',
  ARGENTOR: 'ARGENTOR',
  ARS: 'ARS',
  ARTISJUS: 'ARTISJUS',
  ASSIM: 'ASSIM',
  AUME: 'AUME',
  AWA: 'AWA',
  AYCO: 'AYCO',
  BBDA: 'BBDA',
  BCDA: 'BCDA',
  BEELDRECH: 'BEELDRECH',
  BGDA: 'BGDA',
  BILD_KUN: 'BILD-KUN',
  BMDA: 'BMDA',
  BNDA: 'BNDA',
  BONO: 'BONO',
  BSCAP: 'BSCAP',
  BUBEDRA: 'BUBEDRA',
  BUCUDA: 'BUCUDA',
  BUMA: 'BUMA',
  BUMDA: 'BUMDA',
  BURIDA: 'BURIDA',
  BUS: 'BUS',
  BUTODRA: 'BUTODRA',
  CAPAC: 'CAPAC',
  CASH: 'CASH',
  CASP: 'CASP',
  CHA: 'CHA',
  CMRRA: 'CMRRA',
  COMPASS: 'COMPASS',
  COPY_DAN: 'COPY-DAN',
  COSCAP: 'COSCAP',
  COSGA: 'COSGA',
  COSOMA: 'COSOMA',
  COSON: 'COSON',
  COSOTA: 'COSOTA',
  COTT: 'COTT',
  DACS: 'DACS',
  DALRO: 'DALRO',
  DP: 'DP',
  DPRS: 'DPRS',
  DSS: 'DSS',
  EAU: 'EAU',
  ECAD: 'ECAD',
  ECCO: 'ECCO',
  FILMAUTOR: 'FILMAUTOR',
  FILSCAP: 'FILSCAP',
  FOX: 'FOX',
  GEMA: 'GEMA',
  GESATCH: 'GESATCH',
  GHAMRO: 'GHAMRO',
  GMBH: 'GMBH',
  GMR: 'GMR',
  HAA: 'HAA',
  HDS: 'HDS',
  HUNGART: 'HUNGART',
  IMRO: 'IMRO',
  IPRS: 'IPRS',
  JACAP: 'JACAP',
  JASRAC: 'JASRAC',
  KCI: 'KCI',
  KODA: 'KODA',
  KOMCA: 'KOMCA',
  KOPIOSTO: 'KOPIOSTO',
  KOSCAP: 'KOSCAP',
  LAA: 'LAA',
  LATGA: 'LATGA',
  'LATGA-A': 'LATGA-A',
  MASA: 'MASA',
  MACP: 'MACP',
  MCPS: 'MCPS',
  MCSC: 'MCSC',
  MCSK: 'MCSK',
  MCSN: 'MCSN',
  MCT: 'MCT',
  MESAM: 'MESAM',
  MESRAM: 'MESRAM',
  MRCSN: 'MRCSN',
  MRS: 'MRS',
  MSG: 'MSG',
  MUSICAUT: 'MUSICAUT',
  MUST: 'MUST',
  NASCAM: 'NASCAM',
  NCB: 'NCB',
  NS: 'NS',
  OMDA: 'OMDA',
  ONDA: 'ONDA',
  OSA: 'OSA',
  PD: 'PD',
  PPCA: 'PPCA',
  PPI: 'PPI',
  PPL: 'PPL',
  PROCAN: 'PROCAN',
  PRS: 'PRS',
  RAO: 'RAO',
  SABAM: 'SABAM',
  SACD: 'SACD',
  SACEM: 'SACEM',
  SACERAU: 'SACERAU',
  SACM: 'SACM',
  SACVEN: 'SACVEN',
  SADA: 'SADA',
  SADAIC: 'SADAIC',
  SADEMBRA: 'SADEMBRA',
  SADH: 'SADH',
  SAIF: 'SAIF',
  SAMO: 'SAMO',
  SAMRO: 'SAMRO',
  SARRAL: 'SARRAL',
  SAS: 'SAS',
  SAYCE: 'SAYCE',
  SAYCO: 'SAYCO',
  SAZAS: 'SAZAS',
  SBACEM: 'SBACEM',
  SBAT: 'SBAT',
  SCAU: 'SCAU',
  SCD: 'SCD',
  SDRM: 'SDRM',
  SENAPI: 'SENAPI',
  SESAM: 'SESAM',
  SGAE: 'SGAE',
  SIAE: 'SIAE',
  SICAM: 'SICAM',
  SLPRS: 'SLPRS',
  SOBODAYC: 'SOBODAYC',
  SOCAN: 'SOCAN',
  SOCINADA: 'SOCINADA',
  SOCINPRO: 'SOCINPRO',
  SODACT: 'SODACT',
  SODART: 'SODART',
  SODRAC: 'SODRAC',
  SOFAM: 'SOFAM',
  SOKOJ: 'SOKOJ',
  SOMAAP: 'SOMAAP',
  SONECA: 'SONECA',
  SOZA: 'SOZA',
  SPA: 'SPA',
  SPAC: 'SPAC',
  SPACEMF: 'SPACEMF',
  STEF: 'STEF',
  STEMRA: 'STEMRA',
  STIM: 'STIM',
  SUISA: 'SUISA',
  TEATERAU: 'TEATERAU',
  TEOSTO: 'TEOSTO',
  TONO: 'TONO',
  UACRR: 'UACRR',
  UBC: 'UBC',
  UCMR: 'UCMR',
  UK: 'UK',
  Unknown: 'Unknown',
  UPRS: 'UPRS',
  USA: 'USA',
  VAGA: 'VAGA',
  VBK: 'VBK',
  VCPMC: 'VCPMC',
  VEGAP: 'VEGAP',
  VGWORT: 'VG WORT',
  VISCOPY: 'VISCOPY',
  WORT: 'WORT',
  ZAIKS: 'ZAIKS',
  ZAMCOPS: 'ZAMCOPS',
  ZAMP: 'ZAMP',
  ZAMP_SOCOM: 'ZAMP (SOCOM)',
  ZIMRA: 'ZIMRA',
  ZIMURA: 'ZIMURA',
});
export const PRO_TYPES_OPTIONS = Object.freeze(objectToOptions(PRO_TYPES));

export const CUES_THEMES_TYPES = {
  Opening: 'Opening Theme',
  Closing: 'Closing Theme',
  EndTitle: 'End Title Theme',
  Bumper: 'Bumper',
  Logo: 'Logo',
  MainTitle: 'Main Title Theme',
  Segment: 'Segment Theme',
  Note: 'Note Theme',
};
export const CUES_THEMES_TYPES_OPTIONS = translatedOptions('cueThemes.types')(CUES_THEMES_TYPES);

export const CUE_USAGE_DETAILS_TYPES = {
  A_CAPELLA: 'a cappella',
  DANCE: 'dance',
  HUMMING: 'humming',
  INDISTINGUISHABLE: 'indistinguishable',
  LIVE_PERFORMANCE_SYMPHONIC: 'live performance symphonic',
  LYRIC_LESS_VOCAL: 'lyric-less vocal',
  NOODLING: 'noodling',
  SPOKEN: 'spoken',
  WHISTLING: 'whistling',
};
export const CUE_USAGE_DETAILS_TYPES_OPTIONS = translatedOptions('cuesUsages.types')(CUE_USAGE_DETAILS_TYPES);

export const FILE_TYPES = {
  QUOTE_REQUEST_SENT: 'QuoteRequest',
  QUOTE: 'Quote',
  CONFIRMATION: 'Confirmation',
  APPROVAL: 'Approval',
  LICENSE: 'License',
  LICENSE_REQUEST_SENT: 'LicenseRequest',
  PAYMENT: 'Payment',
  CHECK_REQUEST_SENT: 'CheckRequest',
  ADDITIONAL_CORRESPONDENCE: 'AdditionalCorrespondence',
  END_TITLE_APPROVALS: 'EndTitleApprovals',
  SHEET_MUSIC: 'SheetMusic',
  LYRICS: 'Lyrics',
  AUDIOFILE: 'AudioFile',
  QUOTE_RENEWAL: 'QuoteRenewal',
  CHECK_REQUEST: 'CheckRequest',
  LICENSE_REQUEST: 'LicenseRequest',
  QUOTE_REQUEST: 'QuoteRequest',
  INVOICE: 'Invoice',
  OTHER: 'Other',
  CUE_SHEET: 'CueSheet',
  PRODUCTION_INFO: 'ProductionInfo',
  CALENDAR: 'Calendar',
  SCRIPT: 'Script',
};
export const FILES_TYPES_OPTIONS = translatedOptions('global.fileTypes')(FILE_TYPES);

export const PRODUCTION_FILE_TYPES = {
  CUE_SHEET: 'CueSheet',
  PRODUCTION_INFO: 'ProductionInfo',
  CALENDAR: 'Calendar',
  SCRIPT: 'Script',
  OTHER: 'Other',
};
export const PRODUCTION_FILE_TYPES_OPTIONS = translatedOptions('global.fileTypes')(PRODUCTION_FILE_TYPES);

// 1895: year of the invention of cinematography
// 21: 20 to include 20 future years, and 1 for ramda range
export const YEARS = range(1895, new Date().getFullYear() + 21);
export const YEARS_OPTIONS = compose(arrayToOptions, reverse)(YEARS);

export const TRACK_TYPES = {
  SONG: 'song',
  SCORE: 'score',
  LIBRARY: 'library',
  SOUND_EFFECT: 'sound_effect',
};

export const TRACK_TYPES_OPTIONS = translatedOptions('global.trackTypes')(TRACK_TYPES);

export const COMMON_TRACK_USE_TYPES = Object.freeze({
  BI: 'BI',
  BV: 'BV',
  'BI/BV': 'BI/BV',
  VI: 'VI',
  VV: 'VV',
  MT: 'MT',
  OT: 'OT',
  ST: 'ST',
  ET: 'ET',
  OTHER: 'OTHER',
});

/**
 * Only the 'requested' type does show the BI/BV option
 */
export const TRACK_USE_REQUESTED_OPTIONS = Object.freeze(objectToOptions(COMMON_TRACK_USE_TYPES));

/**
 *
 * @param {String} useKey usage type key
 * @returns {String} option name
 */
export function getTrackUseTypeName(useKey) {
  return TRACK_USE_REQUESTED_OPTIONS.find((option) => option.id === useKey)?.name;
}

export const TRACK_USE_ACTUAL_OPTIONS = Object.freeze(
  objectToOptions(COMMON_TRACK_USE_TYPES).filter((option) => option.id !== 'BI/BV'),
);

const THEME_USE_TYPES_SHORT = Object.freeze({
  L: 'L',
  MT: 'MT',
  CT: 'CT',
  ET: 'ET',
  FTC: 'FTC',
  FTCBI: 'FTCBI',
  FTCBV: 'FTCBV',
  FTCVI: 'FTCVI',
  FTCVV: 'FTCVV',
  FTO: 'FTO',
  FTOBI: 'FTOBI',
  FTOBV: 'FTOBV',
  FTOVI: 'FTOVI',
  FTOVV: 'FTOVV',
  OT: 'OT',
  ST: 'ST',
  TIC: 'TIC',
  TIO: 'TIO',
  TVC: 'TVC',
  TVI: 'TVI',
});

export const THEME_USE_TYPES = Object.freeze({
  'L - LOGO': 'L',
  'MT - Main Theme': 'MT',
  'OT - Opening Theme': 'OT',
  'CT - Closing Theme': 'CT',
  'ET - End Title Theme': 'ET',
  'FTC - Film Theme Close': 'FTC',
  'FTCBI - Film Theme Close Background Instrumental': 'FTCBI',
  'FTCBV - Film Theme Close Background Vocal': 'FTCBV',
  'FTCVI - Film Theme Close Visual Instrumental': 'FTCVI',
  'FTCVV - Film Theme Close Visual Vocal': 'FTCVV',
  'FTO - Film Theme Open': 'FTO',
  'FTOBI - Film Theme Open Background Instrumental': 'FTOBI',
  'FTOBV - Film Theme Open Background Vocal': 'FTOBV',
  'FTOVI - Film Theme Open Visual Instrumental': 'FTOVI',
  'FTOVV - Film Theme Open Visual Vocal': 'FTOVV',
  'ST - Segment Theme': 'ST',
  'TIC - Theme Instrumental Close': 'TIC',
  'TIO - Theme Instrumental Open': 'TIO',
  'TVC - Theme Vocal Close': 'TVC',
  'TVI - Theme Vocal Open': 'TVI',
});
export const THEME_USE_TYPES_OPTIONS = Object.freeze(objectToOptions(THEME_USE_TYPES));

const OTHER_USE_TYPES_SHORT = Object.freeze({
  BUMP: 'BUMP',
  IND: 'IND',
  DAN: 'DAN',
  BG: 'BG',
  VPA: 'VPA',
});

export const OTHER_USE_TYPES = Object.freeze({
  'BUMP - Bumper': 'BUMP',
  'IND - Indistinguishable': 'IND',
  'DAN - Visual Dance': 'DAN',
  'BG - Background': 'BG',
  'VPA - Visual Performance Accompaniment': 'VPA',
});

export const OTHER_USE_TYPES_OPTIONS = Object.freeze(objectToOptions(OTHER_USE_TYPES));

export const ADDITIONAL_TRACK_USE_TYPES = Object.freeze({
  THEME: {
    ...THEME_USE_TYPES,
  },
  OTHER: {
    ...OTHER_USE_TYPES,
  },
});

export const ADDITIONAL_TRACK_USE_TYPES_OPTIONS = Object.freeze(
  Object.keys(ADDITIONAL_TRACK_USE_TYPES).map((type) => ({
    label: type,
    options: objectToOptions(ADDITIONAL_TRACK_USE_TYPES[type]),
  })),
);

export const VALID_CUESHEET_USE_TYPES = Object.freeze({
  ...COMMON_TRACK_USE_TYPES,
  ...THEME_USE_TYPES_SHORT,
  ...OTHER_USE_TYPES_SHORT,
});

export const TRACK_STATUS = {
  NEW: 1,
  CHECK_REQUEST_SENT: 10,
  CLEARED: 6,
  CONF_SENT: 7,
  DENIED: 3,
  EXPIRED: 4,
  LICENSE_REQUEST_SENT: 8,
  LICENSE_SENT: 9,
  PAID: 11,
  QUOTE_REQ_SENT: 5,
  REQUOTE: 2,
  STUDIO_OWNED: 12,
};

export const TRACK_STATUS_LABEL = {
  NEW: 'new',
  IN_PROGRESS: 'inProgress',
  CLEARED: 'cleared',
  CONFIRMED: 'confirmed',
  LICENSED: 'licensed',
  NEEDS_LICENSE: 'licensing',
  LICENSE_SENT: 'licenseSent',
  CHECK_SENT: 'checkSent',
  PAID: 'paid',
  LICENSE_REQUEST_SENT: 'licenseRequest',
};

/**
 * Returns Track Status key based on a Track Status value
 * @param {Number} statusValue
 */
export const getStatusByValue = (statusValue) => {
  const status = Object.keys(TRACK_STATUS).find((key) => TRACK_STATUS[key] === statusValue);
  return status || 'NEW';
};

export const getTranslatedTrackStatus = compose((s) => i18n.t(`global.trackStatuses.${s}`), getStatusByValue);

export const TRACK_STATUS_OPTIONS = translatedOptions('global.trackStatuses')(TRACK_STATUS);

export const validImageFormats = ['image/jpeg', 'image/jpg', 'image/png', 'image/bmp'];

export const GLOBAL_SEARCH_TYPES = {
  PRODUCTIONS: 'productions',
  TRACK: 'tracks',
};

export const PERMISSION_TYPES = {
  COMMON: 'common',
  CLEAR: 'clear',
  CUE: 'cue',
};

export const getDefaultCountryName = compose(prop('name'), find(propEq('code', 'US')));

export const getDefaultCurrency = compose(prop('id'), find(propEq('code', 'USD')));

export const DEFAULT_PAGINATION_RESPONSE = { data: [], pagination: {} };

export const ALL_OPTION = {
  id: 'all',
  name: i18n.t('global.forms.allOption'),
};

export const NONE_OPTION = {
  id: 'none',
  name: i18n.t('global.forms.noneOption'),
};

export const PAYEE_TYPES = {
  COMPANY: 'Company',
  INDIVIDUAL: 'Individual',
};
export const PAYEE_TYPES_OPTIONS = translatedOptions('global.payeeTypes')(PAYEE_TYPES);

export const CUE_SHEET_SUBMISSION_STATUS = {
  NOT_SENT: 'NotSent',
  EMAILED: 'Emailed',
  SENT: 'Sent',
  SENT_REVISED: 'SentRevised',
  REJECTED: 'Rejected',
  ACCEPTED: 'Accepted',
  WARNING: 'Warning',
};

export const CUE_SHEET_SUBMISSION_STATUS_OPTIONS = translatedOptions('global.cueSheetSubmissionStatus')(
  CUE_SHEET_SUBMISSION_STATUS,
);

export const CUE_CORRECTNESS_STATUS = {
  MissingBasicData: 'missing_basic_data',
  IncorrectNumberOfRightsOwners: 'incorrect_number_of_rights_owners',
  NeedsAtLeastOneWriter: 'needs_at_least_one_writer',
  NeedsAtLeastOnePublisher: 'needs_at_least_one_publisher',
  WriterHasIncompleteData: 'writer_has_incomplete_data',
  PublisherHasIncompleteData: 'publisher_has_incomplete_data',
  WritersSharesAreIncorrect: 'writer_shares_are_incorrect',
  PublishersSharesAreIncorrect: 'publisher_shares_are_incorrect',
  Success: 'success',
};

export const SEARCH_FILTERS_TYPES = {
  CAMPAIGN: 'campaign',
  EPISODE: 'episode',
  MOVIE: 'movie',
  TRACK: 'track',
};

export const SEARCH_CONTACTS_FIELDS = {
  EMAIL: 'email',
  LICENSOR_COMPANY_NAME: 'licensor_company_name',
  NAME: 'name',
  PAYEE_COMPANY_NAME: 'payee_company_name',
};

export const TRACK_CUE_POPOVER_COOKIE = 'trackCuePopoverCookie';

export const MFN_TYPES = Object.freeze({
  MFN_BASE: 'BASE',
  MFN_WAIVED: 'WAIVED',
  MFN_NONE: 'NONE',
});

export const RAPID_CUE_SENDER_TYPES = {
  CABLE_SOURCE: 'CableSource',
  DISTRIBUTOR: 'Distributor',
  NETWORK: 'Network',
  PRODUCTION_COMPANY: 'ProductionCompany',
  WRITER: 'Writer',
  PUBLISHER: 'Publisher',
  OTHER: 'Other',
};
export const RAPID_CUE_SENDER_TYPES_OPTIONS = translatedOptions('rapidCueSender.types')(RAPID_CUE_SENDER_TYPES);

export const MFN_TYPES_OPTIONS = objectToOptions(MFN_TYPES);
export const ENTER_KEY = 13;
export const TAB_KEY = 9;
export const ESC_KEY = 27;
