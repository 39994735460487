/* eslint-disable react/no-unused-prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { Avatar } from '@material-ui/core';
import PropTypes from 'prop-types';

const ImageFromUrl = (props) => {
  const { style, onClick, alt, avatar, onError, url } = props;

  return avatar ? (
    <Avatar src={url} style={style} onError={onError} />
  ) : (
    <img alt={alt} src={url} style={style} onError={onError} onClick={onClick} />
  );
};

ImageFromUrl.propTypes = {
  onError: PropTypes.func,
  style: PropTypes.object,
  onClick: PropTypes.func,
  alt: PropTypes.string,
  avatar: PropTypes.bool,
  fallback: PropTypes.node,
  url: PropTypes.string,
};

ImageFromUrl.defaultProps = {
  onError: () => null,
  onClick: () => null,
};

export default ImageFromUrl;
