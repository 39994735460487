/* eslint-disable import/no-cycle */
import always from 'ramda/src/always';
import append from 'ramda/src/append';
import dropLast from 'ramda/src/dropLast';
import update from 'ramda/src/update';
import { handleActions } from 'redux-actions';
import { clearData } from 'redux-core/auth/actions';
import * as actions from './actions';

const defaultState = [];

const dialogsReducer = {
  [actions.openDrawer]: (state, { payload: { name, payload = {} } }) => append({ name, payload })(state),
  [actions.openModal]: (state, { payload: { name, payload = {} } }) => append({ name, payload })(state),
  [actions.closeDrawer]: (state) => dropLast(1)(state),
  [actions.closeModal]: (state) => dropLast(1)(state),
  [actions.closeAllDialogsHandler]: () => [],
  [actions.updateDialog]: (state, { payload: { index, drawer } }) => {
    const newState = update(index, drawer, state);

    return newState;
  },
  [actions.saveRight]: (state, { payload: { name, rights } }) =>
    state.map((dialog) => {
      if (dialog.name === name) return { ...dialog, rights };
      return dialog;
    }),
  [clearData]: always(defaultState),
};

export default handleActions({ ...dialogsReducer }, defaultState);
