import { FieldArray, FieldArrayRenderProps } from 'formik';
import { ShareWithUser } from '../ShareWithUserForm';

import { ShareWithUserRow } from './ShareWithUserRow';

export type DynamicShareWithUsersFieldsProps = {
  divisionId: number;
  objectId: number;
};

export const DynamicShareWithUsersFields = ({
  divisionId,
  objectId,
}: DynamicShareWithUsersFieldsProps) => (
  <FieldArray name="shareList">
    {(arrayHelpers: FieldArrayRenderProps) => {
        const { form } = arrayHelpers;
        const { shareList } = form.values;

        const addUser = () => {
          arrayHelpers.insert(shareList.length, {
            transientId: Date.now(),
            email: '',
            defaultProjectRole: null,
          });
        };

        const removeUser = (index: number) => {
          if (shareList.length === 1) {
            arrayHelpers.replace(index, {
              transientId: Date.now(),
              email: '',
              defaultProjectRole: null,
            });
            return;
          }
          arrayHelpers.remove(index);
        };

        return shareList.map((shareWithUserItem: ShareWithUser, rowIndex: number) => {
          const lastRow = rowIndex === shareList.length - 1;
          const updateShareWithUserEmail = (email: string) => {
            arrayHelpers.replace(rowIndex, {
              ...shareWithUserItem,
              email,
            });
          };
          return (
            <ShareWithUserRow
              shareWithUserItem={shareWithUserItem}
              divisionId={divisionId}
              objectId={objectId}
              updateShareWithUserEmail={updateShareWithUserEmail}
              rowIndex={rowIndex}
              removeUser={removeUser}
              addUser={addUser}
              lastRow={lastRow}
              key={shareWithUserItem.transientId}
            />
          );
        });
      }}
  </FieldArray>
  );
