import { makeStyles } from '@material-ui/core/styles';
import { hexWithOpacity } from 'utils';

const useStyles = makeStyles((theme) => ({
  barWrapper: ({ color, notGhosted }) => ({
    '&.small': {
      backgroundColor: notGhosted ? 'transparent' : hexWithOpacity(color, 0.5),
      borderRadius: 8,
    },
  }),
  bar: ({ color, width }) => ({
    backgroundColor: color,
    width: `${width}%`,
    '&.small': {
      padding: theme.spacing(0, 0.5, 0, 1),
      borderRadius: 8,
      height: 18,
    },
    '&.medium': {
      borderRadius: 14,
      padding: theme.spacing(0, 1.75),
      height: 25,
    },
    '&.outlined': {
      backgroundColor: 'transparent',
      border: '2px solid',
      borderColor: color,
      color,
      justifyContent: 'center',
    },
  }),
  overLabel: {
    width: 'auto',
    color: theme.palette.primary.contrastText,
  },
  overIcon: {
    fontSize: 12,
  },
}));

export default useStyles;
