import { Grid } from '@material-ui/core';
// eslint-disable-next-line import/no-extraneous-dependencies
import { styled } from '@material-ui/styles';

const DrawerFormContentGrid = styled(Grid)(({ theme }) => ({
  marginBottom: theme.spacing(5),
  paddingBottom: 0,
  paddingTop: 0,
  // Applies to the image uploader "column"
  '& > :first-child': {
    '& > *': {
      margin: 'auto',
      maxWidth: '100%',
    },
    '& > button': {
      marginTop: theme.spacing(2),
    },
  },
  // Applies to the Form itself
  '& > :last-child': {
    // Adds a margin bottom to every single line
    '& > *': {
      marginBottom: theme.spacing(1.5),
    },
  },
}));

export default DrawerFormContentGrid;
