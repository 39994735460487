/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDebounce, useFetch } from 'utils/hooks';
import { TypeAhead } from 'components/styleguide';
import c from 'ramda/src/compose';
import filter from 'ramda/src/filter';
import find from 'ramda/src/find';
import prop from 'ramda/src/prop';
import uniqBy from 'ramda/src/uniqBy';
import { useTranslation } from 'react-i18next';
import { getIn } from 'formik';
import { filterSearch } from 'utils/object';
import { withFormField } from 'components/common/Form/utils';

const QTracksTypeAhead = ({
  alwaysShowCreateButton,
  call,
  isCreatable,
  label,
  isMultiple,
  form: { isSubmitting, setFieldValue, values },
  field: { name },
  removeDuplicatesFn,
  ...props
}) => {
  const value = getIn(values, name);
  const { t } = useTranslation();
  const [input, setInput] = useState();
  const [cachedOptions, setCachedOptions] = useState(value ? (isMultiple ? value : [value]) : []);
  const debounced = useDebounce(input);
  const [options, loadingOptions] = useFetch(() => (debounced ? call(debounced) : options), [debounced]);

  const handleCreate = async (payload) => {
    const newValue = { name: payload };
    if (name) {
      setFieldValue(name, isMultiple ? [...(value || []), newValue] : newValue);
    }
  };

  const handleChange = (val) => {
    const getValue = isMultiple ? filter(({ name }) => val.includes(name)) : find(({ name }) => name === val);
    if (name) c((v) => setFieldValue(name, v), getValue)(cachedOptions);
  };

  const handleRemoveDuplicates = removeDuplicatesFn || uniqBy(prop('name'));

  useEffect(() => {
    setCachedOptions((prevCached) =>
      handleRemoveDuplicates([...(value ? (isMultiple ? value : [value]) : []), ...prevCached, ...options]).filter(
        ({ name }) => !!name,
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, value, isMultiple]);

  return (
    <TypeAhead
      disabled={isSubmitting}
      isMultiple={isMultiple}
      label={t(label)}
      loading={loadingOptions}
      name={name}
      noResultsText={input ? t('global.noResults') : t('global.startTyping')}
      onChange={handleChange}
      onCreate={
        isCreatable &&
        input &&
        !loadingOptions &&
        (alwaysShowCreateButton || !filterSearch({ name: input })(cachedOptions).length) &&
        handleCreate
      }
      onInputChange={setInput}
      options={cachedOptions}
      placeholder=""
      value={isMultiple ? (value || []).map(prop('name')) : prop('name')(value)}
      valueKey="name"
      {...props}
    />
  );
};

QTracksTypeAhead.propTypes = {
  alwaysShowCreateButton: PropTypes.bool,
  isCreatable: PropTypes.bool,
  call: PropTypes.func.isRequired,
  defaultInput: PropTypes.string,
  removeDuplicatesFn: PropTypes.func, // To remove duplicates entities in a different way
};

QTracksTypeAhead.defaultProps = {
  alwaysShowCreateButton: false,
  isCreatable: true,
};

QTracksTypeAhead.displayName = 'FormQTracksTypeAhead';

export default withFormField(QTracksTypeAhead);
