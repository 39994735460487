import { Grid } from '@mui/material';
import { useAtomValue } from 'jotai';
import { ComposeLetterStepper } from './ComposeLetterStepper';
import { composeLetterAtom } from './composeLetterStore';

export function ComposeLetterRightPanel({ children }) {
  const composeLetter = useAtomValue(composeLetterAtom);
  const width = composeLetter.leftPanelHidden ? 'calc(100% - 60px)' : '75%';
  return (
    <Grid container item sx={{ background: '#fff', width }} padding={3} flexDirection="column" gap={2}>
      <Grid item>
        <ComposeLetterStepper />
      </Grid>
      <Grid item sx={{ flexGrow: 1 }}>
        {children}
      </Grid>
    </Grid>
  );
}
