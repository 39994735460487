import { IconButton, MenuItem, Select, TableRow } from '@mui/material';
import CancelIcon from '@material-ui/icons/Cancel';
import { useRootTranslation } from 'utils/hooks';
import { requestWithError } from 'utils/request';
import { changeUserRoleCall, removeProductionUserAccess } from 'redux-core/productions/services';
import { ProductionRoles } from 'utils/roles';
import { ProductionUserCell } from './ProductionUserCell';
import useStyles from './styles';

export const ProductionUserRow = ({ currentUserId, productionUser, refetchProductionUsers, objectId, divisionId }) => {
  const { row } = useStyles();
  const t = useRootTranslation('drawers.shareWithUser');
  const { isDivisionAdmin } = productionUser;
  const allowedRoles = ProductionRoles.filter(
    (role) => !productionUser.defaultRole || role.id <= productionUser.defaultRole.id,
  );

  const isCurrentUser = currentUserId === productionUser.user.id;

  const changeUserRole = async (newRoleId) => {
    const { user } = productionUser;
    const changeUserRolePayload = {
      divisionId,
      userId: user.id,
      roleId: newRoleId,
      objectId,
    };
    await requestWithError(changeUserRoleCall, changeUserRolePayload, {
      message: {
        success: t('roleChanged'),
        failed: t('changingRoleFailed'),
      },
    });
    await refetchProductionUsers();
  };

  const removeAccess = async () => {
    const { user } = productionUser;
    const changeUserRolePayload = {
      divisionId,
      userId: user.id,
      objectId,
    };
    await requestWithError(removeProductionUserAccess, changeUserRolePayload, {
      message: {
        success: t('accessRemoved'),
        failed: t('removingAccessFailed'),
      },
    });
    await refetchProductionUsers();
  };

  const isOwner = allowedRoles.length === 1;
  const showRoleSelectionDropdown = !isDivisionAdmin && !isCurrentUser && !isOwner;
  const showRoleDescriptionStatically = !isDivisionAdmin && (isCurrentUser || isOwner);
  const enableDeleteButton = !!productionUser.defaultRole;

  return (
    <TableRow className={row}>
      <ProductionUserCell trim>
        <IconButton onClick={removeAccess} disabled={enableDeleteButton}>
          <CancelIcon />
        </IconButton>
      </ProductionUserCell>
      <ProductionUserCell>{productionUser.user.displayName || '--'}</ProductionUserCell>
      <ProductionUserCell>{productionUser.user.email}</ProductionUserCell>
      <ProductionUserCell>
        {isDivisionAdmin && t('divisionAdmin')}
        {showRoleDescriptionStatically && productionUser.activeRole.name}
        {showRoleSelectionDropdown && (
          <Select value={productionUser.activeRole.id} onChange={(event) => changeUserRole(event.target.value)}>
            {allowedRoles.map((option) => (
              <MenuItem key={option.name} value={option.id}>
                {option.name}
              </MenuItem>
            ))}
          </Select>
        )}
      </ProductionUserCell>
    </TableRow>
  );
};
