/* eslint-disable import/no-cycle */
import { makeStyles } from '@material-ui/core';
import { appColors } from 'components/styleguide';
import { getSplitRadius } from '../theme';

const useStyles = makeStyles({
  button: {
    'white-space': 'nowrap',
    overflow: 'hidden',
    borderRadius: ({ split }) => getSplitRadius({ split }),
    borderLeft: ({ split }) => (split === 'right' || split === 'middle') && `1px solid ${appColors.white}`,
    border: ({ noBorder }) => (noBorder ? 'none' : undefined),
  },
  dense: {
    padding: 0,
  },
});

export default useStyles;
