/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable react/no-children-prop */
/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import Step from '@material-ui/core/Step';
import StepConnector from '@material-ui/core/StepConnector';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import { StepIcon, Text } from 'components/styleguide';
import useStyles from './styles';

const HorizontalStepper = ({ activeStep, steps }) => {
  const classes = useStyles();

  return (
    <Stepper
      activeStep={activeStep}
      alternativeLabel
      classes={{ root: classes.root }}
      connector={<StepConnector classes={{ active: classes.line, completed: classes.line }} />}
    >
      {steps.map((label) => (
        <Step key={label}>
          <StepLabel classes={{ label: classes.label }} StepIconComponent={StepIcon}>
            <Text children={label} variant="body2" />
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

HorizontalStepper.defaultProps = {
  activeStep: 0,
  steps: [],
};

HorizontalStepper.propTypes = {
  activeStep: PropTypes.number,
  steps: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default HorizontalStepper;
