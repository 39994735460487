/* eslint-disable react/no-children-prop */
import Grid from '@material-ui/core/Grid';
import compose from 'ramda/src/compose';
import reject from 'ramda/src/reject';
import prop from 'ramda/src/prop';
import nth from 'ramda/src/nth';
import last from 'ramda/src/last';

import { Text, TrackType, TypeIcon } from 'components/styleguide';
import { concatObjects, concatStrings } from 'utils';
import { formatCurrency } from 'utils/format';
import { getNormalizedProductionType } from 'utils/productions';
import { useRootTranslation } from 'utils/hooks';
import { ClearanceStatusLabel } from 'components/styleguide/ClearanceStatusLabel';

import { getUseAndDuration } from '../../utils';
import { SearchUsageChip } from '../../SearchUsageChip';
import useStyles from './styles';

const rootT = 'globalSearch.results';

export const TrackItem = ({ title, prodArray, productionType }) => {
  const t = useRootTranslation(rootT);

  const trackInfo = last(prodArray);
  const artists = compose(concatStrings(', '), prop('artists'))(trackInfo);
  const normalizedProdType = getNormalizedProductionType(productionType);
  const projectName = prodArray[0].name;
  const productionPath = compose(
    concatObjects(' - '),
    reject((item) => ['Project', 'Track'].includes(item.type)),
  )(prodArray);
  const clearanceStatus = trackInfo.trackClearanceStatus;
  const approvedFees = Number(trackInfo.approvedFees);
  const requestedUses = getUseAndDuration(trackInfo.cueContainers);

  const currency = compose(prop('selectedCurrency'), nth(-2))(prodArray);

  const { trackType } = trackInfo;

  const classes = useStyles();

  return (
    <Grid container>
      <Grid container item xs={6}>
        <Grid container item alignItems="center">
          <Grid item xs={1} container alignItems="center">
            <TrackType type={trackType} />
          </Grid>
          <Grid container item xs={11} direction="column">
            <Text bold children={title} testId="search-result" noWrap />
            <Text variant="caption" children={artists} noWrap />
          </Grid>
        </Grid>

        <Grid container item alignItems="center">
          <Grid item xs={1} container alignItems="center">
            <TypeIcon type={normalizedProdType} size={24} />
          </Grid>
          <Grid container item xs={11} direction="column">
            <Text bold variant="caption" children={projectName} />
            <Text variant="caption" testId="production-path" children={productionPath} />
          </Grid>
        </Grid>
      </Grid>

      <Grid container item xs={6}>
        <Grid item xs={5} className={classes.dataWrapper}>
          <Text bold variant="caption" children={t('status')} gutterBottom />
          {clearanceStatus && <ClearanceStatusLabel clearanceStatus={clearanceStatus} />}
        </Grid>
        <Grid item xs={3} className={classes.dataWrapper}>
          <Text bold children={t('approvedFees')} gutterBottom variant="caption" />

          <Text children={formatCurrency(approvedFees, currency)} />
        </Grid>
        <Grid item xs={4} className={classes.dataWrapper}>
          <Text bold children={t('requestedUse')} gutterBottom variant="caption" />
          {requestedUses[0] && <SearchUsageChip usage={requestedUses[0]} />}
          {requestedUses[1] && <SearchUsageChip usage={requestedUses[1]} />}
        </Grid>
      </Grid>
    </Grid>
  );
};
