/* eslint-disable react/require-default-props */
import React from 'react';
import { Button } from 'components/styleguide';
import { buttonSizes } from 'components/styleguide/Button';

export type MultiOptionProps = {
  firstOptionLabel: string;
  secondOptionLabel: string;
  buttonSize?: keyof typeof buttonSizes;
  okVariant?: 'primary' | 'secondary';
  firstOptionConfirm: () => void | Promise<void>;
  secondOptionConfirm: () => void | Promise<void>;
};

export function MultiOption({
  firstOptionLabel,
  secondOptionLabel,
  okVariant,
  buttonSize,
  firstOptionConfirm,
  secondOptionConfirm,
}: MultiOptionProps) {
  const [loading, setLoading] = React.useState(false);

  const defaultButtonSize = 'sm';
  const defaultOkVariant = 'primary';

  const handleFirstOptionConfirm = async () => {
    setLoading(true);
    await firstOptionConfirm();
    setLoading(false);
  };

  return (
    <>
      <Button
        testId="confirmation-dialog-first"
        onClick={handleFirstOptionConfirm}
        variant="secondary"
        size={buttonSize || defaultButtonSize}
        isSubmitting={loading}
      >
        {firstOptionLabel}
      </Button>
      <Button
        testId="confirmation-dialog-second"
        onClick={secondOptionConfirm}
        variant={okVariant || defaultOkVariant}
        size={buttonSize || defaultButtonSize}
        disabled={loading}
      >
        {secondOptionLabel}
      </Button>
    </>
  );
}
