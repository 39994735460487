import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  dropZone: {
    height: '85vh',
  },
  emptyState: {
    height: '85vh',
  },
  fileName: {
    overflowWrap: 'break-word',
  },
}));

export default useStyles;
