/* eslint-disable import/no-cycle */
import makeEntityServiceCalls, { makeServiceCall } from 'redux-core/serviceHelpers';

export const searchProductions = makeServiceCall('projects', 'search');

export const { get: getProjectById, update: updateProjectCall } = makeEntityServiceCalls('projects');

export const {
  create: createSeasonCall,
  get: getSeasonById,
  getAll: getAllSeasons,
  update: updateSeasonCall,
} = makeEntityServiceCalls('seasons');

export const createProjectAndSeason = makeServiceCall('seasons', 'create-project-season');

export const {
  create: createSeasonReleaseCall,
  get: getSeasonReleaseById,
  update: updateSeasonReleaseCall,
} = makeEntityServiceCalls('season-releases');

export const getCategoriesCall = makeServiceCall('categories', 'get-all');

export const getCurrenciesCall = (payload) => makeServiceCall('currencies', 'get-all')({ limit: 9999, ...payload });

export const getProductionCurrenciesCall = (productionId) =>
  makeServiceCall('productions', 'get-currencies', 'clear')({ productionId });

export const getLanguagesCall = makeServiceCall('languages', 'get-all');

export const getVersionsCall = makeServiceCall('versions', 'get-all');

export const { update: updateFilmReleaseCall } = makeEntityServiceCalls('film-releases');

export const setWatchingCall = makeServiceCall('productions', 'set-watching');

export const searchByProjectIdAndType = makeServiceCall('productions', 'search-by-project-id-and-type');

export const getProductionsUsers = makeServiceCall('productions', 'get-users');

export const changeUserRoleCall = makeServiceCall('productions', 'change-user-role');

export const removeProductionUserAccess = makeServiceCall('productions', 'remove-production-user-access');

export const getInvitableUsers = makeServiceCall('productions', 'get-invitable-users');

export const inviteMultipleProductionUsers = makeServiceCall('productions', 'invite-multiple-users');

export const unsetWatchingCall = makeServiceCall('productions', 'unset-watching');

export const getEpisodesBySeason = makeServiceCall('season-releases', 'get-episodes');

export const getFooterInfo = makeServiceCall('productions', 'get-footer', 'clear');

export const quickCreateProjectCall = makeServiceCall('projects', 'quick-create');

export const getProductionCompanies = makeServiceCall('productions', 'get-production-companies');

export const getProductionDistributors = makeServiceCall('productions', 'get-distributors');
