import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  header: {
    marginBottom: theme.spacing(),
    '& > :first-child': {
      paddingLeft: theme.spacing(4),
    },
    '& > :last-child': {
      paddingLeft: theme.spacing(),
    },
  },
  container: {
    marginBottom: theme.spacing(6),
  },
}));

export default useStyles;
