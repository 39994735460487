/* eslint-disable import/no-cycle */
import { ActionMenu, Button, ButtonMenu, Dropzone, HoverMenu } from 'components/styleguide';
import { FormInputFormat, FormItemsList, FormInputCurrency } from 'components/common/Form/Fields';
import TabsLayout from 'components/Routes/Layout/TabsLayout';
import HoverWrapper from 'components/common/EditDeleteMenuWrapperGrid';
import TextBar from 'components/common/Charts/TextBar';
import Form from 'components/common/Form';
import CurrencyPicker from 'components/ProductionOverview/components/CurrencyPicker';
import RightsPresetPicker from 'components/common/RightsPresetPicker';
import compose from 'ramda/src/compose';
import TabsLayoutSwitch from 'components/Routes/Layout/TabsLayout/TabsLayoutSwitch';
import IconBaseButton from 'components/ClearanceDetails/IconBaseButton';
import { CurrencySelect } from 'components/ProductionOverview/components/CurrencyPicker/CurrencySelect';
import withPermissionsList from './withPermissionsList';
import withFinancialPermissions from './withFinancialPermissions';
import withPermissions from './withPermissions';
import InputCurrency from '../Form/Fields/FormInputCurrency/InputCurrency';

export const SecuredIconButton = withPermissions(IconBaseButton);
export const SecuredButton = withPermissions(Button);
export const SecuredTabsLayout = withPermissionsList(TabsLayout, {
  filter: 'tabs',
});

export const SecuredTabsLayoutSwitch = withPermissionsList(TabsLayoutSwitch, {
  filter: 'routes',
});

export const SecuredActionMenu = withPermissionsList(ActionMenu, {
  strategy: 'disable',
});
export const SecuredButtonMenu = compose(
  (component) => withPermissionsList(component, { strategy: 'disabled' }),
  (component) => withPermissions(component, { strategy: 'disabled' }),
)(ButtonMenu);

export const SecuredHoverMenu = withPermissionsList(HoverMenu);

export const SecuredForm = withPermissions(Form, { strategy: 'disabled' });
export const SecuredFormItemsList = withPermissions(FormItemsList, {
  strategy: 'disabled',
});

export const SecuredFinancialTextBar = withFinancialPermissions(TextBar);

export const SecuredFinancialInputFormat = withFinancialPermissions(FormInputFormat);
export const SecuredFormInputCurrency = withFinancialPermissions(FormInputCurrency);
export const SecuredInputCurrency = withFinancialPermissions(InputCurrency);

export const SecuredHoverWrapper = withPermissions(HoverWrapper, {
  strategy: 'disabled',
});
export const SecuredCurrencyPicker = withPermissions(CurrencyPicker, {
  strategy: 'disabled',
});
export const SecuredCurrencySelect = withPermissions(CurrencySelect, {
  strategy: 'disabled',
});
export const SecuredRightsPresetPicker = withPermissions(RightsPresetPicker, {
  strategy: 'disabled',
});

export const SecuredDropzone = withPermissions(Dropzone, {
  strategy: 'disabled',
});

export const SecuredHideDropzone = withPermissions(Dropzone);
