import { withStyles } from '@material-ui/core';

const styles = () => ({
  division: {
    width: '25px',
    height: '25px',
    backgroundColor: '#70ae6f',
    color: '#fff',
    fontSize: '12px',
    margin: '0 auto',
  },
});

export default withStyles(styles);
