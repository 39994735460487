import PropTypes from 'prop-types';
import { LICENSOR_TYPES, NORMALIZED_PRODUCTION_TYPES } from 'utils/constants';

/* General convention:
 * All const names in this file are capitalized
 * For prop objects the const name would be PropNamePropTypes
 * For prop arrays the const name would be PropNamePluralListPropTypes
 * For example:
 *    TerritoryPropTypes (prop object)
 *    TerritoriesListPropTypes (prop array)
 */

/** @example "features", "marketing", "television" */
export const ProductionTypePropTypes = PropTypes.oneOf(Object.values(NORMALIZED_PRODUCTION_TYPES));

export const DropdownOptionPropTypes = PropTypes.exact({
  id: PropTypes.oneOf([PropTypes.number, PropTypes.string]).isRequired,
  name: PropTypes.string,
});
export const DropdownOptionsPropTypes = PropTypes.arrayOf(DropdownOptionPropTypes);

export const ProductionPropTypes = PropTypes.shape({
  id: PropTypes.number,
  objectId: PropTypes.number,
  divisionId: PropTypes.oneOf([PropTypes.number, PropTypes.string]),
  divisionName: PropTypes.string,
  projectId: PropTypes.number,
  name: PropTypes.string,
  path: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      type: PropTypes.string,
    }),
  ),
  masterCurrencyId: PropTypes.number,
  selectedCurrencyId: PropTypes.number,
  additionalData: PropTypes.object,
  number: PropTypes.string,
  usReleaseDate: PropTypes.string,
  intlReleaseDate: PropTypes.string,
  mixDate: PropTypes.string,
  archived: PropTypes.bool,
  description: PropTypes.string,
  duration: PropTypes.string,
  notes: PropTypes.string,
  categoryId: PropTypes.number,
  territoryId: PropTypes.number,
  versionId: PropTypes.number,
  languageId: PropTypes.number,
  isanId: PropTypes.number,
  eidrContentId: PropTypes.number,
  episodeDuration: PropTypes.string,
  rightsPresetId: PropTypes.number,
  defaultEpisodeBudget: PropTypes.number,
  cueSheetPreparedByName: PropTypes.string,
  cueSheetPreparedByLastName: PropTypes.string,
  cueSheetPreparedByEmail: PropTypes.string,
  cueSheetInstructions: PropTypes.string,
  cueSheetPreparedByCountryId: PropTypes.number,
  cueSheetPreparedByPhone: PropTypes.string,
  productionYear: PropTypes.number,
  releaseNumber: PropTypes.number,
  internalId: PropTypes.number,
  keyPersonnel: PropTypes.number,
  budget: PropTypes.object,
  isBeingWatched: PropTypes.bool,
  territories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      start: PropTypes.string,
      end: PropTypes.string,
      term: PropTypes.number,
    }),
  ),
});

export const TerritoryPropTypes = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  start: PropTypes.string,
  end: PropTypes.string,
  term: PropTypes.number,
});
export const TerritoriesListPropTypes = PropTypes.arrayOf(TerritoryPropTypes);

export const PathPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    type: PropTypes.string,
    objectId: PropTypes.number,
  }),
);

export const DivisionIdPropTypes = PropTypes.oneOfType([PropTypes.number, PropTypes.string]);

export const BaseDrawerPropTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  large: PropTypes.bool,
  xlarge: PropTypes.bool,
  dense: PropTypes.bool,
};

export const CueContainerPropTypes = {
  id: PropTypes.number.isRequired,
  number: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  duration: PropTypes.string,
  notes: PropTypes.string,
  clearanceStatus: PropTypes.object,
  currentCueContainerVersionId: PropTypes.number,
  cueContainerVersionPositions: PropTypes.array,
  unassignedBin: PropTypes.bool,
  objectId: PropTypes.number,
  currentCueContainerVersion: PropTypes.shape({
    id: PropTypes.number,
    approvedFees: PropTypes.number,
    estimatedFees: PropTypes.number,
    title: PropTypes.string,
  }),
  feesApplied: PropTypes.bool,
  using: PropTypes.bool,
  deactivated: PropTypes.bool,
  tracks: PropTypes.array,
};

export const CueContainerPropType = PropTypes.shape(CueContainerPropTypes);
export const CueContainersPropType = PropTypes.arrayOf(CueContainerPropType);

export const LicensorPropTypes = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  type: PropTypes.oneOf(Object.values(LICENSOR_TYPES)),
  contact: {
    email: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  },
  qwireTracksRightsOwnerId: PropTypes.number,
});

export const ClearancePropTypes = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  expires: PropTypes.string,
  status: PropTypes.number,
});

export const SplitPropTypes = PropTypes.oneOf(['left', 'right', 'middle']);
