import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useFetch, useRootTranslation } from 'utils/hooks';
import Table from 'components/common/Table';
import { getAllCueSheetSubmissionsCall, updateCueSheetSubmissionsCall } from 'redux-core/cueSheet/services';
import Form from 'components/common/Form';
import { requestWithError } from 'utils/request';
import pick from 'ramda/src/pick';
import enhancer from './enhancer';
import { columns } from './utils';

const rootT = 'drawers.cueSheetSubmissionHistory';

const SubmissionList = ({ cueContainerId, drawerName, objectId, openPrompt }) => {
  const t = useRootTranslation(rootT);
  const [{ submissions }, , refetch] = useFetch(
    () => cueContainerId && getAllCueSheetSubmissionsCall({ cueContainerId }),
    [cueContainerId],
    { submissions: [] },
  );

  const [selectedSubmission, setSelectedSubmission] = useState();

  const onUpdateSubmission = async (values) => {
    const payload = pick(['id', 'submissionDate', 'submissionStatus'])(values);
    const settings = { message: { success: t('successMessage') } };
    await requestWithError(updateCueSheetSubmissionsCall, payload, settings);
    await refetch();
    setSelectedSubmission(undefined);
  };

  /**
   * This form presents a List of submissions. You can only edit one submission at a time.
   * For performance reasons, the Form only keeps the status of the current selected submission, which is being updated.
   * When selecting a submission, the initialValues change, thus the form reinitializes. Just 2 components (the 2 fields)
   * re render on Form status change. The rest of the Rows (and even the columns inside the selected row that are not
   * Form fields) will not rerender. That means that, formik-wise, having 1 row or 500 on screen, is exactly the same.
   */
  return (
    <Form drawerName={drawerName} enableReinitialize initialValues={selectedSubmission} onSubmit={onUpdateSubmission}>
      <Table
        columns={columns({
          selectedSubmissionId: selectedSubmission?.id,
          setSelected: setSelectedSubmission,
          t,
          objectId,
          openPrompt,
        })}
        defaultOrder="DESC"
        defaultOrderBy="submissionDate"
        elevation={0}
        itemsPerPage={100}
        rows={submissions}
        showNoResults
      />
    </Form>
  );
};

SubmissionList.propTypes = {
  cueContainerId: PropTypes.number,
  drawerName: PropTypes.string,
  objectId: PropTypes.number,
  openPrompt: PropTypes.func,
};

export default enhancer(SubmissionList);
