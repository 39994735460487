export const LETTER_TYPE_FIELDS = {
  QUOTE_REQUEST: 'quote',
  CONFIRMATION: 'confirmation',
  LICENSE: 'license',
  LICENSE_REQUEST: 'licenseRequest',
  CHECK_REQUEST: 'checkRequest',
} as const;

export const LETTER_STATUS_FIELDS = {
  Sent: 'SentAt',
  Received: 'ReceivedAt',
  Expires: 'ExpiresAt',
} as const;

export const LETTER_TYPES = {
  QUOTE_REQUEST: 'QUOTE_REQUEST',
  CONFIRMATION: 'CONFIRMATION',
  LICENSE: 'LICENSE',
  LICENSE_REQUEST: 'LICENSE_REQUEST',
  QUOTE_RENEWAL: 'QUOTE_RENEWAL',
  CHECK_REQUEST: 'CHECK_REQUEST',
} as const;
