import { Box, MenuItem, Skeleton, Tooltip } from '@mui/material';
import Select, { SelectChangeEvent, SelectProps } from '@mui/material/Select';
import { TClearanceStatus, useGetClearanceStatusListQuery } from 'services/clear/clearance-status';

export type TClearanceStatusSelectProps = SelectProps & {
  clearanceStatus: TClearanceStatus | number;
  divisionId?: number;
  letterType?: string;
  maxLabelWidth?: number;
  showAllOption?: boolean;
  readOnly?: boolean;
};

export function ClearanceStatusSelect(props: TClearanceStatusSelectProps) {
  const { divisionId, clearanceStatus, onChange, maxLabelWidth, showAllOption, readOnly } = props;

  const { data: clearanceStatusList, isLoading } = useGetClearanceStatusListQuery({
    divisionId,
    withAll: showAllOption,
  });

  if (isLoading) return <Skeleton />;

  const value =
    typeof clearanceStatus === 'number'
      ? clearanceStatus
      : clearanceStatus?.id;

  const onChangeSelectedStatus = (event: SelectChangeEvent) => {
    const statusId = Number(event.target.value);
    const status: TClearanceStatus = clearanceStatusList.find((s) => s.id === statusId);
    onChange(event, status);
  };

  return (
    <Select
      renderValue={(currentStatus: TClearanceStatusSelectProps['clearanceStatus']) => {
        if (!currentStatus) return null;
        const status =
          typeof currentStatus === 'number' ? clearanceStatusList.find((s) => s.id === currentStatus) : currentStatus;
        const actualLabelBiggerThanMax = maxLabelWidth && status?.label.length > maxLabelWidth;
        const label = actualLabelBiggerThanMax ? status.label.slice(0, maxLabelWidth) : status.label;
        return (
          <StatusLabel
            color={status.color}
            label={label}
            showTooltip={actualLabelBiggerThanMax}
            completeLabel={status.label}
          />
        );
      }}
      value={value}
      {...props}
      disabled={readOnly}
      onChange={onChangeSelectedStatus}
      data-testid="select-clearance-status"
      id="status-select"
    >
      {clearanceStatusList.map((status: TClearanceStatus) => (
        <MenuItem data-testid="licensor-status-option" key={`licensor-status-option-${status.id}`} value={status.id}>
          <StatusLabel color={status.color} label={status.label} />
        </MenuItem>
      ))}
    </Select>
  );
}

type TStatusLabelProps = { color: string; label: string; showTooltip?: boolean; completeLabel?: string };

const StatusLabel = ({ color, label, showTooltip, completeLabel }: TStatusLabelProps) => (
  <Box display="flex" gap={0.5} alignItems="center">
    <Box
      sx={{
        width: '0.7em',
        height: '0.7em',
        borderRadius: '50%',
        backgroundColor: color,
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: color === 'transparent' ? 'theme.palette.grey[700]' : color,
      }}
    />
    <Box display="flex">
      {!showTooltip && <span data-testid="licensor-status">{label}</span>}
      {showTooltip && (
        <Tooltip title={completeLabel} placement="top">
          <span data-testid="licensor-status">{label}...</span>
        </Tooltip>
      )}
    </Box>
  </Box>
);
