import { DragIndicator } from '@mui/icons-material';
import { Box, Button, Grid, Typography } from '@mui/material';
import { useState } from 'react';
import { useCreateClearanceStatusMutation, useUpdateClearanceStatusMutation } from 'services/clear/clearance-status';
import { useCreateCueSheetStatusMutation, useUpdateCueSheetStatusMutation } from 'services/cue/cue-sheet-status';
import { rootTranslation } from 'utils/format';
import { ColoredCircle } from './ColoredCircle';
import { EditableCustomStatus } from './EditableCustomStatus';
import { TCustomStatus } from './types';

export type TCustomStatusProps = {
  status: TCustomStatus;
  divisionId?: number;
  createCustomStatus?:
    | ReturnType<typeof useCreateClearanceStatusMutation>[0]
    | ReturnType<typeof useCreateCueSheetStatusMutation>[0];
  updateCustomStatus?:
    | ReturnType<typeof useUpdateClearanceStatusMutation>[0]
    | ReturnType<typeof useUpdateCueSheetStatusMutation>[0];
};

const t = rootTranslation('drawers.customStatuses');

export function CustomStatus({ status, divisionId, createCustomStatus, updateCustomStatus }: TCustomStatusProps) {
  const [isEditing, setIsEditing] = useState<boolean>(!status.id);
  const [statusDetails, setStatusDetails] = useState<TCustomStatus>({ ...status });

  const isNew = !status.id;
  const editable = !status.isParent && !status.coreStatus;

  const onSaveHandler = async () => {
    if (!editable) return;
    if (isNew) {
      await createCustomStatus({
        divisionId,
        parentId: status.parentId,
        label: statusDetails.label,
      });
    } else {
      await updateCustomStatus({
        divisionId,
        id: status.id,
        label: statusDetails.label,
      });
    }
    setIsEditing(false);
  };

  return (
    <Grid data-testid={`custom-status-container-${status.id}`} container direction="row">
      {!isEditing && (
        <Box display="flex" gap={2} sx={{ width: '100%', alignItems: 'center' }}>
          {!status.isParent && (
            <DragIndicator
              data-testid="custom-status-drag-handle"
              htmlColor={!isNew && editable ? 'gray' : 'transparent'}
            />
          )}
          <ColoredCircle status={status} />
          <Typography sx={{ flexGrow: '2' }}>{statusDetails.label}</Typography>
          {editable && (
            <Button variant="contained" onClick={() => setIsEditing(true)}>
              {t('edit')}
            </Button>
          )}
        </Box>
      )}
      {editable && isEditing && (
        <EditableCustomStatus
          status={statusDetails}
          setStatusDetails={setStatusDetails}
          onSaveHandler={onSaveHandler}
        />
      )}
    </Grid>
  );
}
