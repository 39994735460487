import { connect } from 'react-redux';
import { goToLink } from 'redux-core/router/actions';
import { getSelectedSelector } from 'redux-core/overview/selectors';

const mapStateToProps = (state) => ({
  selected: getSelectedSelector(state),
});

const mapDispatchToProps = {
  goToLink,
};

export default connect(mapStateToProps, mapDispatchToProps);
