/* eslint-disable react/no-children-prop */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { Clear, Edit, Button } from 'components/styleguide';
import classnames from 'classnames';
import { usePrompt } from 'utils/hooks';
import withStyles from './styles';

const EditDeleteMenuWrapperGrid = ({
  classes,
  setEditable,
  children,
  readOnly,
  disableHover,
  className,
  removable,
  hoverAlignItems,
  onConfirm,
  isEditBtn,
  btnText,
  promptContent,
  editable,
  showPrompt,
  ...props
}) => {
  const openPrompt = usePrompt();

  return (
    <Grid {...props} className={classnames(className, classes.container)}>
      {!disableHover && !readOnly && (
        <Grid container className={classes.actionWrapper} justify="flex-end">
          <Grid item container justify="flex-end" alignItems={hoverAlignItems} className={classes.actionMenu}>
            {!editable ? null : isEditBtn && btnText ? (
              <Button variant="primary" onClick={setEditable} size="sm" children={btnText} />
            ) : (
              <Edit size={20} onClick={setEditable} />
            )}

            {removable && (
              <Clear
                size={20}
                color="primary"
                onClick={() =>
                  showPrompt
                    ? openPrompt({
                        content: promptContent,
                        onConfirm,
                      })
                    : onConfirm()
                }
              />
            )}
          </Grid>
        </Grid>
      )}
      {children}
    </Grid>
  );
};

EditDeleteMenuWrapperGrid.defaultProps = {
  alignItems: 'center',
  hoverAlignItems: 'flex-start',
  // eslint-disable-next-line react/default-props-match-prop-types
  container: true,
  editable: true,
  removable: true,
  showPrompt: true,
  isEditBtn: false,
};

EditDeleteMenuWrapperGrid.propTypes = {
  classes: PropTypes.object.isRequired,
  setEditable: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  editable: PropTypes.bool,
  isEditBtn: PropTypes.bool,
  btnText: PropTypes.string,
  promptContent: PropTypes.func.isRequired,
  hoverAlignItems: PropTypes.string,
  children: PropTypes.any.isRequired,
  readOnly: PropTypes.bool,
  disableHover: PropTypes.bool,
  className: PropTypes.string,
  removable: PropTypes.bool,
  showPrompt: PropTypes.bool,
  alignItems: PropTypes.string,
};

export default withStyles(EditDeleteMenuWrapperGrid);
