import { Grid } from '@material-ui/core';
import { rootTranslation } from 'utils/format';
import { TextField } from '@mui/material';
import { FormField } from 'components/common/Form/Fields/FormField/FormField';

const t = rootTranslation('drawers.quickSetupProduction');

const CampaignDetails = () => (
  <Grid direction="row" alignItems="flex-start" justify="space-between" item>
    <FormField
      name="campaign"
      required
      fullWidth
      label={t('campaign')}
      render={({ field, muiprops }) => <TextField {...field} {...muiprops} data-testid="input-text-campaign" />}
    />
    <span style={{ color: '#9A57DD', fontSize: 12, fontStyle: 'italic' }}>{t('requiredProduction')}</span>
  </Grid>
);

export default CampaignDetails;
