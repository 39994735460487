import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
  icon: {
    border: '1px solid',
    borderColor: theme.palette.grey[50],
    borderRadius: 2,
    marginBottom: theme.spacing(0.5),
    padding: theme.spacing(0.75),
    '&.selected': {
      backgroundColor: theme.palette.action.selected,
      color: theme.palette.white,
    },
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
      color: theme.palette.white,
    },
  },
  iconsContainer: {
    '& > *': {
      textAlign: 'center',
    },
  },
}));

export default styles;
