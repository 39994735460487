import { baseApi } from 'services';

const fields = ['name', 'email', 'licensor_company_name', 'payee_company_name'] as const;

type TSearchContactsRequestFields = typeof fields[number][];

type TSearchContactsRequest = {
  divisionId: number;
  fields?: TSearchContactsRequestFields;
  term: string;
  useCurrentDivision: boolean;
  qwireTracksRightsOwnerId?: number;
  orderBy?: string;
  orderDirection?: 'ASC' | 'DESC';
  page?: number;
};

type TContactResponse = {
  contactId: number;
  fullName: string;
  firstName: string;
  lastName: string;
  companyName: string;
  payeeCompanyName: string;
  email: string;
  phone: string;
};

export const contactsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    searchContacts: builder.query<TContactResponse[], TSearchContactsRequest>({
      query: (body) => ({
        url: 'clear/contacts/search',
        method: 'POST',
        body,
      }),
      keepUnusedDataFor: 0,
    }),
  }),
});

export const { useSearchContactsQuery } = contactsApi;
