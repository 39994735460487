import { omit } from 'lodash';

export function normalizeLicensorOutput(value, nextValue) {
  if (nextValue == null) {
    return {
      ...value,
      name: null,
    };
  }

  const updatedNextValue = omit(nextValue, ['id', 'type', 'pro']);
  const mergedValue = {
    ...updatedNextValue,
    qwireTracksRightsOwnerId: nextValue.id,
    writerAffiliation: nextValue.pro,
  };

  return {
    ...value,
    ...mergedValue,
  };
}
