import { connect } from 'react-redux';
import { openDrawer } from 'redux-core/dialogs/actions';
import { getKeyPersonnelFormSelector, getTenantId, getDivisionId } from 'redux-core/productions/selectors';
import { keepPersonnelForm } from 'redux-core/productions/actions';

const mapStateToProps = (state) => ({
  initialValues: getKeyPersonnelFormSelector(state),
  setupInfo: {
    tenantId: getTenantId(state),
    divisionId: getDivisionId(state),
  },
});

const mapDispatchToProps = { keepPersonnelForm, openDrawer };

export default connect(mapStateToProps, mapDispatchToProps);
