/* eslint-disable import/no-cycle */
import { createActions } from 'redux-actions';
import identity from 'ramda/src/identity';
import { getDivisions } from './services';

const actionsDefinition = {
  SET_DIVISIONS: identity,
  SET_LOADING_DIVISIONS: identity,
};

export const { setDivisions, setLoadingDivisions } = createActions(actionsDefinition);

export const getAllDivisions = () => async (dispatch) => {
  dispatch(setLoadingDivisions(true));
  try {
    const divisions = await getDivisions();
    dispatch(setDivisions(divisions));
  } finally {
    dispatch(setLoadingDivisions(false));
  }
};
