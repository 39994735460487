import React from 'react';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import Grid from '@material-ui/core/Grid';
import { Text } from 'components/styleguide';
import { concatStrings } from 'utils/index';
import { rootTranslation } from 'utils/format';
import { CueSheetStatus } from 'components/styleguide/CueSheetStatus';
import useStyles from './styles';
import { TAsset } from '..';

const t = rootTranslation('drawers.watchingAssets');

const AssetInfo = ({ label, value }) => (
  <Grid container direction="column" item>
    <Typography sx={{ fontWeight: 'bold' }} noWrap variant="caption">
      {label}
    </Typography>
    <Typography noWrap variant="caption">
      {value || '--'}
    </Typography>
  </Grid>
);

type CampaignAssetItemProps = TAsset;

const CampaignAssetItem = ({
  id,
  creativeVendor,
  title,
  type,
  version,
  versionName,
  cueSheet,
}: CampaignAssetItemProps) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12}>
        <Text bold gutterBottom variant="body2">
          {title}
        </Text>
      </Grid>
      <Grid item xs={12}>
        <Text bold variant="caption" gutterBottom>
          {t('status')}
        </Text>
        <CueSheetStatus color={cueSheet?.status.color} label={cueSheet?.status.label} />
      </Grid>
      <Box
        data-testid={`campaign-asset-${id}`}
        sx={{ width: '100%', mt: 2, display: 'grid', gridTemplateColumns: 'repeat(3, 33%)', gap: '1rem' }}
      >
        <Box>
          <Box display="flex" flexDirection="column" gap={2}>
            <AssetInfo label={t('version')} value={concatStrings(' - ')(version, versionName)} />
            <AssetInfo label={t('assetType')} value={type} />
          </Box>
        </Box>
        <Box alignContent="space-around">
          <AssetInfo label={t('creativeVendor')} value={creativeVendor} />
        </Box>
        <Box>
          <Typography variant="body2" sx={{ fontWeight: 'bold', textTransform: 'uppercase' }}>
            {t('cues')}
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Box>
              <span>
                <Typography id="totalCues" variant="caption" color="textSecondary" mr={0.5}>
                  {t('totalCues')}
                </Typography>
                <Typography aria-labelledby="totalCues" variant="caption" color="textSecondary">
                  (<strong>{cueSheet?.totalCues ?? 0}</strong>)
                </Typography>
              </span>
            </Box>
            <Box>
              <span>
                <Typography id="cueWithErrors" variant="caption" color="textSecondary" mr={0.5}>
                  {t('incompleteCues')}
                </Typography>
                <Typography aria-labelledby="cueWithErrors" variant="caption" color="textSecondary">
                  (<strong>{cueSheet?.cuesWithErrors ?? 0}</strong>)
                </Typography>
              </span>
            </Box>
          </Box>
        </Box>
      </Box>
    </Grid>
  );
};

export default CampaignAssetItem;
