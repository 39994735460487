import { makeStyles } from '@material-ui/core';

const styles = makeStyles(() => ({
  content: {
    maxHeight: '45%',
    overflow: 'scroll',
    position: 'fixed',
  },
}));

export default styles;
