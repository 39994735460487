/* eslint-disable import/no-cycle, react/require-default-props, react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import ButtonBase from '@material-ui/core/ButtonBase';
import Box from '@material-ui/core/Box';
import { Text } from 'components/styleguide';
import { allowedVariants } from 'components/styleguide/Text';
import { concatStrings } from 'utils/index';
import useStyles from './styles';

function IconBaseButton({ Icon, text, onClick, bold, color, width, disabled, variant, p, mr, justifyCenter, testId }) {
  const classes = useStyles();
  return (
    <Box height={1} mr={mr} width={width}>
      <ButtonBase
        disabled={disabled}
        onClick={onClick}
        className={classnames(classes.button, { justifyCenter })}
        data-testid={concatStrings('-')('button', testId)}
      >
        <Box alignItems="center" color={color} display="flex" p={p}>
          <Icon />
          <Box ml={1}>
            <Text bold={bold} variant={variant}>
              {text}
            </Text>
          </Box>
        </Box>
      </ButtonBase>
    </Box>
  );
}

IconBaseButton.propTypes = {
  Icon: PropTypes.any,
  text: PropTypes.string,
  onClick: PropTypes.func,
  bold: PropTypes.bool,
  disabled: PropTypes.bool,
  justifyCenter: PropTypes.bool,
  color: PropTypes.string,
  mr: PropTypes.number,
  p: PropTypes.number,
  variant: PropTypes.oneOf(allowedVariants),
  width: PropTypes.number,
  testId: PropTypes.string,
};

IconBaseButton.defaultProps = {
  mr: 2,
  p: 1,
};

export default IconBaseButton;
