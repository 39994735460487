/* eslint-disable import/no-cycle */
import { CSSProperties, MouseEventHandler, ReactChild, ReactNode } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import classnames from 'classnames';
import { concatStrings } from 'utils/index';
import useStyles from './styles';

/**
 * Sizes
 */
export const buttonSizes = {
  xxs: 'small',
  xs: 'small',
  sm: 'small',
  md: 'medium',
  lg: 'large',
  xl: 'large',
};

const getSize = (size) => buttonSizes[size] || 'medium';
/**
 * Variants
 */
export const buttonVariants = {
  addProjectButton: 'contained',
  dark: 'outlined',
  destructive: 'outlined',
  secondary: 'outlined',
  primary: 'contained',
  status: 'text',
  text: 'text',
  contained: 'contained',
};

export const getVariant = (variant) => buttonVariants[variant] || 'outlined';

/**
 * Colors
 */
const buttonColors = {
  secondary: 'primary',
  primary: 'primary',
};

const getColor = (variant) => buttonColors[variant] || 'primary';

export type ButtonType = 'button' | 'submit';

export type ButtonProps = {
  callback?: () => Promise<void>;
  children: ReactNode;
  disabled?: boolean;
  isSubmitting?: boolean;
  onClick?: (
    event: MouseEventHandler<HTMLButtonElement>,
    pureEvent: MouseEventHandler<HTMLButtonElement>,
  ) => void | Promise<void>;
  size?: keyof typeof buttonSizes;
  testId?: string;
  type?: ButtonType;
  variant?: keyof typeof buttonVariants | 'findProjectOnWatchingTab';
  startIcon?: ReactChild;
  endIcon?: ReactChild;
  split?: 'left' | 'right' | 'middle';
  synchronous?: boolean;
  dense?: boolean;
  noBorder?: boolean;
  tabIndex?: number;
  style?: CSSProperties;
};

const Button = ({
  callback,
  children,
  disabled,
  isSubmitting,
  onClick,
  size,
  testId,
  type,
  variant,
  startIcon,
  endIcon,
  split,
  synchronous,
  dense,
  noBorder,
  tabIndex,
  style,
}: ButtonProps) => {
  const classes = useStyles({ size, split, noBorder });
  const isLoading = isSubmitting;

  const handleClick = async (e) => {
    if (!onClick) return;
    // Some properties of the event object changes while propagating
    const pureEvent = { ...e };
    if (synchronous) {
      // eslint-disable-next-line consistent-return
      return onClick(e, pureEvent);
    }
    await onClick(e, pureEvent);
    await callback?.();
  };

  return (
    <LoadingButton
      className={classnames(classes.button, {
        [variant]: !disabled,
        disabled,
      })}
      loading={isSubmitting}
      size={getSize(size)}
      color={getColor(variant)}
      data-testid={concatStrings('-')('button', testId)}
      disabled={disabled}
      onClick={handleClick}
      startIcon={!isLoading ? startIcon : undefined}
      endIcon={!isLoading ? endIcon : undefined}
      type={type}
      variant={getVariant(variant)}
      classes={{
        root: classnames({
          [classes.dense]: dense,
        }),
      }}
      tabIndex={tabIndex}
      style={style}
    >
      {children}
    </LoadingButton>
  );
};

export default Button;
