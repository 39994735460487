/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable max-len */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const AddToTemplate = (props) => (
  <SvgIcon {...props} viewBox="0 0 30 30">
    <path
      fillRule="evenodd"
      d="M4 3.968C4 2.885 4.885 2 5.968 2h7.87l5.903 5.903v7.06c-3.58 0-6.482 2.902-6.482 6.481 0 .078.002.155.004.232H5.968C4.885 21.676 4 20.79 4 19.708V3.968zm8.854-.492v5.41h5.411l-5.41-5.41z"
      clipRule="evenodd"
    />
    <path
      fillRule="evenodd"
      d="M19.74 15.889c-3.055 0-5.555 2.5-5.555 5.556 0 3.055 2.5 5.555 5.556 5.555 3.055 0 5.555-2.5 5.555-5.555 0-3.056-2.5-5.556-5.555-5.556zM22.52 22h-2.223v2.222h-1.11V22h-2.223v-1.111h2.222v-2.222h1.111v2.222h2.223V22z"
      clipRule="evenodd"
    />
  </SvgIcon>
);

export default AddToTemplate;
