/* Temporary id used during local creation */
export const TERRITORY_IS_NEW_ID = 'isNew';

/** Possible strategy values for adding countries on Territories */
export const TERRITORY_STRATEGY = {
  WORLDWIDE: 'exclude',
  SPECIFIC: 'include',
};

export const TERRITORY_ROW_COLUMN_WIDTH = {
  NAME: 3,
  LETTER_AS: 4,
  TERRITORIES: 5,
};

export const TERRITORIES_WORLDWIDE_ID = 1;
export const TERRITORIES_WORLDWIDE_NAME = 'Worldwide';

export const TERRITORIES_LIMIT = 25;
