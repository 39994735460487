import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
  dense: {
    padding: theme.spacing(0.5),
  },
  slim: {
    padding: 0,
  },
  root: {
    opacity: ({ opacity }) => opacity,
  },
}));

export default styles;
