/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-children-prop */
import { useState } from 'react';
import { Button, Stack, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';

import { LETTER_TYPES, LETTER_TYPE_FIELDS, LETTER_STATUS_FIELDS } from 'utils/typed_constants';
import { setLicensorCall } from 'redux-core/licensors/actions';
import { useTranslation } from 'react-i18next';
import { BaseDrawer, DrawerHeader, TextWithLine } from 'components/styleguide';
import { LetterRow } from './LetterRow';

/* Types for emptyTypeStatusObject
 * type TTypeValues = typeof LETTER_TYPE_FIELDS[keyof typeof LETTER_TYPE_FIELDS]
 * type TStatusValues = typeof LETTER_STATUS_FIELDS[keyof typeof LETTER_STATUS_FIELDS]
 * type TTypeStatusMixValues = `${TTypeValues}${TStatusValues}`;
 */

const emptyTypeStatusObject = Object.values(LETTER_TYPE_FIELDS)
  .map((type) => Object.values(LETTER_STATUS_FIELDS).map((status) => type + status))
  .flat()
  .reduce((accum, val) => ({ ...accum, [val]: null }), {});

type TLettersSetDatesPayload = {
  licensorId: string | number;
  name: string;
  initialDates: any;
  canSendLetters: boolean;
  licensorPath: string;
  setLicensor: (arg0: any) => void;
  refetch?: () => void;
  onSave?: (arg0: any) => void;
  onSubmit?: (arg0: any) => void;
};

type TLicensorLettersSetDatesProps = {
  name: string;
  onClose: () => void;
  payload: TLettersSetDatesPayload;
};

const LettersSetDates = ({
  onClose,
  payload: { licensorId, name, initialDates = emptyTypeStatusObject, canSendLetters, refetch, onSave, onSubmit },
}: TLicensorLettersSetDatesProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [dates, setDates] = useState(initialDates);

  const onRowDateChange = (row: string) => (value: string) => {
    setDates((prev) => ({ ...prev, [row]: value }));
  };

  const handleOnSave = async () => {
    if (!onSave) {
      await dispatch(setLicensorCall({ id: licensorId, dates }));
    } else {
      await onSave({ dates });
    }
    refetch?.();
    onSubmit?.(dates);
    onClose();
  };

  const handleOnClose = () => onClose();

  const commonRowProps = {
    canSendLetters,
    onRowDateChange,
    dates,
  };

  return (
    <>
      <DrawerHeader title="Set Letter Dates" onClose={onClose} testId="project-drawer-header">
        {canSendLetters && (
          <>
            <Button variant="contained" onClick={handleOnSave}>
              {t('global.forms.save')}
            </Button>
            <Button variant="text" onClick={handleOnClose} color="error">
              {t('global.forms.close')}
            </Button>
          </>
        )}
      </DrawerHeader>
      <Typography variant="h5" sx={{ marginBottom: '20px' }}>
        {name}
      </Typography>

      <Stack spacing={3}>
        <Stack spacing={0}>
          <TextWithLine children="Quote Request" textPosition="left" />
          <LetterRowWrapper type={LETTER_TYPES.QUOTE_REQUEST} status="Sent" {...commonRowProps} />
          <LetterRowWrapper type={LETTER_TYPES.QUOTE_REQUEST} status="Received" {...commonRowProps} />
          <LetterRowWrapper type={LETTER_TYPES.QUOTE_REQUEST} status="Expires" {...commonRowProps} />
        </Stack>

        <Stack spacing={0}>
          <TextWithLine children="Confirmation" textPosition="left" />
          <LetterRowWrapper type={LETTER_TYPES.CONFIRMATION} status="Sent" {...commonRowProps} />
        </Stack>

        <Stack spacing={0}>
          <TextWithLine children="License Request" textPosition="left" />
          <LetterRowWrapper type={LETTER_TYPES.LICENSE_REQUEST} status="Sent" {...commonRowProps} />
          <LetterRowWrapper type={LETTER_TYPES.LICENSE_REQUEST} status="Received" {...commonRowProps} />
        </Stack>

        <Stack spacing={0}>
          <TextWithLine children="License" textPosition="left" />
          <LetterRowWrapper type={LETTER_TYPES.LICENSE} status="Sent" {...commonRowProps} />
          <LetterRowWrapper type={LETTER_TYPES.LICENSE} status="Received" {...commonRowProps} />
          <LetterRowWrapper type={LETTER_TYPES.LICENSE} status="Expires" {...commonRowProps} />
        </Stack>

        <Stack spacing={0}>
          <TextWithLine children="Check Request" textPosition="left" />
          <LetterRowWrapper type={LETTER_TYPES.CHECK_REQUEST} status="Sent" {...commonRowProps} />
        </Stack>
      </Stack>
    </>
  );
};

const LetterRowWrapper = ({ type, status, onRowDateChange, dates, ...rest }) => {
  const rowName = LETTER_TYPE_FIELDS[type] + LETTER_STATUS_FIELDS[status];
  return <LetterRow type={type} status={status} onChange={onRowDateChange(rowName)} value={dates[rowName]} {...rest} />;
};

const Drawer = ({ open, onClose, ...props }) => (
  // @ts-ignore
  <BaseDrawer open={open} onClose={onClose}>
    {/* @ts-ignore */}
    <LettersSetDates onClose={onClose} {...props} />
  </BaseDrawer>
);

export default Drawer;
