/* eslint-disable import/no-cycle */
import { handleActions } from 'redux-actions';
import lensPath from 'ramda/src/lensPath';
import set from 'ramda/src/set';
import * as actions from './actions';

const defaultState = {
  cues: [],
  pagination: {
    page: 0,
    pageCount: 0,
    size: 100,
    totalCount: 100,
  },
  expandedCueId: null,
  hideInOut: false,
  newCue: false,
  selectedVersionId: undefined,
  isFormDirty: false,
  isSync: true,
};

const cueSheetReducer = {
  [actions.clearCueSheet]: () => defaultState,
  [actions.setPagination]: (state, { payload }) => set(lensPath(['pagination']), payload)(state),
  [actions.setSelectedVersion]: (state, { payload }) => set(lensPath(['selectedVersionId']), payload)(state),
  [actions.setCues]: (state, { payload }) => set(lensPath(['cues']), payload)(state),
  [actions.setSegments]: (state, { payload }) => set(lensPath(['segments']), payload)(state),
  [actions.setExpandedCueId]: (state, { payload }) => set(lensPath(['expandedCueId']), payload)(state),
  [actions.setHideInOut]: (state, { payload }) => set(lensPath(['hideInOut']), payload)(state),
  [actions.setNewCue]: (state, { payload }) => set(lensPath(['newCue']), payload)(state),
  [actions.setIsFormDirty]: (state, { payload }) => set(lensPath(['isFormDirty']), payload)(state),
  [actions.setCuesSyncFlag]: (state, { payload }) => set(lensPath(['isSync']), payload)(state),
};

export default handleActions(cueSheetReducer, defaultState);
