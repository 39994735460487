/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-expressions */
import React from 'react';
import PropTypes from 'prop-types';
import ImageUploader from 'components/common/ImageUploader';
import { withFormField } from 'components/common/Form/utils';
import { FieldPropTypes } from '../prop-types';

const Input = ({ field, form: { isSubmitting, setFieldValue }, disabled, onUpload, ...props }) => {
  const handleChange = (id) => {
    setFieldValue(field.name, id);
    onUpload && onUpload(id);
  };
  return <ImageUploader disabled={isSubmitting || disabled} {...props} {...field} onChange={handleChange} />;
};

Input.propTypes = {
  field: FieldPropTypes.field,
  form: FieldPropTypes.form,
  disabled: FieldPropTypes.disabled,
  onUpload: PropTypes.func,
};

Input.displayName = 'InputImage';

export default withFormField(Input, { type: 'disabled' });
