/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-unused-expressions */
import React from 'react';
import { Checkbox as MuiCheckbox } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { useRootTranslation } from 'utils/hooks';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import CheckBoxOutlineCircle from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircle from '@material-ui/icons/CheckCircle';
import { concatStrings } from 'utils';
import InputText from '../fields/InputText';
import styles from './styles';

const Checkbox = ({ testId, ...props }) => {
  const { value, small, name, classes, customProps, circle, onClick, label, disabled, readOnly, style } = props;

  const t = useRootTranslation('global.forms.checkbox');
  const handleChange = (event, value) => {
    const { onChange } = props;
    onChange && onChange(event, value);
  };

  if (readOnly) {
    return <InputText readOnly label={label} value={value ? t('checked') : t('unchecked')} />;
  }

  return (
    <MuiCheckbox
      id={name}
      inputProps={{ name, 'aria-describedby': name }}
      {...customProps}
      style={style}
      icon={circle && <CheckBoxOutlineCircle fontSize="small" className={classes.radiusBlank} />}
      checkedIcon={circle && <CheckCircle fontSize="small" className={classes.radiusBlank} />}
      disabled={disabled}
      className={classnames(classes.root, { small }, customProps.classnames)}
      checked={Boolean(value)}
      onChange={handleChange}
      onClick={onClick}
      color="primary"
      data-testid={concatStrings('-')('checkbox-field', testId)}
    />
  );
};

Checkbox.propTypes = {
  value: PropTypes.bool,
  small: PropTypes.bool,
  circle: PropTypes.bool,
  readOnly: PropTypes.bool,
  classes: PropTypes.object,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  style: PropTypes.object,
  customProps: PropTypes.object,
  name: PropTypes.string,
  label: PropTypes.string,
  testId: PropTypes.string,
};

Checkbox.defaultProps = {
  customProps: {},
  onClick: () => undefined,
  disabled: false,
};

export default withStyles(styles)(Checkbox);
