import { useCallback } from 'react';
import { PrimitiveAtom, useAtom, useSetAtom } from 'jotai';
import { focusAtom } from 'jotai-optics';
import { OpticFor } from 'optics-ts';
import { useHydrateAtoms } from 'jotai/utils';

export function useFocusAtom<T>(anAtom: PrimitiveAtom<T>, prop: keyof T, store?: any) {
  return useAtom(
    focusAtom(
      anAtom,
      useCallback((optic: OpticFor<T>) => optic.prop(prop), [prop]),
    ),
    { store },
  );
}

export function useFocusSetAtom<T>(anAtom: PrimitiveAtom<T>, prop: keyof T) {
  return useSetAtom(
    focusAtom(
      anAtom,
      useCallback((optic: OpticFor<T>) => optic.prop(prop), [prop]),
    ),
  );
}

export const HydrateAtoms = ({ initialValues, children }) => {
  useHydrateAtoms(initialValues);
  return children;
};
