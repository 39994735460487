import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  chip: {
    height: 17,
    fontSize: 11,
    marginBottom: theme.spacing(0.5),
    width: 'fit-content',
  },
}));

export default useStyles;
