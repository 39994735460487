import React from 'react';
import dayjs from 'dayjs';
import { Button, Typography } from '@mui/material';
import ROUTES from 'components/Routes/routes';
import { Box } from '@mui/system';
import { rootTranslation } from 'utils/format';

type LoginProps = {
  lockedMessage: boolean;
};

const t = rootTranslation('login');

function loginCallback() {
  window.open(
    `${window.location.origin}${ROUTES.LOGIN_CALLBACK}?to=login`,
    'QWIRE_loginCallback',
    `height=600,width=600, left=${(window.screen.width - 600) / 2}, top=${(window.screen.height - 600) / 2}`,
  );
}

const Login = ({ lockedMessage }: LoginProps) => {
  const currentYear = dayjs().format('YYYY');

  if (lockedMessage) {
    return <LockedScreen currentYear={currentYear} />;
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', minHeight: '320px', width: '415px' }}>
      <Box sx={{ mt: 8 }}>
        <Typography variant="h2" align="center">
          {t('title')}
        </Typography>
      </Box>
      <Box sx={{ mt: 2 }}>
        <Typography variant="body1" align="center">
          {t('content')}
        </Typography>
      </Box>
      <Box sx={{ mt: 4, width: '160px' }}>
        <Button data-testid="log-in" variant="contained" fullWidth onClick={loginCallback}>
          {t('logIn')}
        </Button>
      </Box>
      <Box sx={{ mt: 8 }}>
        <Typography variant="caption">{t('trademark', { currentYear })}</Typography>
      </Box>
    </Box>
  );
};

function LockedScreen({ currentYear }) {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', minHeight: '358px', width: '512px' }}>
      <Box sx={{ mt: 8 }}>
        <Typography variant="h2" align="center">
          {t('title')}
        </Typography>
      </Box>
      <Box sx={{ mt: 2 }}>
        <Typography variant="body1" align="center">
          {t('lockedContentFirst')}
        </Typography>
      </Box>
      <Box sx={{ mt: 1 }}>
        <Typography variant="body1" align="center">
          {t('lockedContentSecond')}
        </Typography>
      </Box>
      <Box sx={{ mt: 4, width: '160px' }}>
        <Button data-testid="log-in" variant="contained" fullWidth onClick={loginCallback}>
          {t('logIn')}
        </Button>
      </Box>
      <Box sx={{ mt: 4 }}>
        <Typography variant="caption">{t('trademark', { currentYear })}</Typography>
      </Box>
    </Box>
  );
}

export default Login;
