/* eslint-disable react/default-props-match-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Text, InputText } from 'components/styleguide';
import { Grid } from '@material-ui/core';
import { useField } from 'formik';
import useStyles from './styles';

const FileNameField = ({ name, extensionName, label, placeholder }) => {
  const classes = useStyles();
  const [{ value: fileName }, , { setValue }] = useField(name);
  const [{ value: extension }] = useField(extensionName);

  return (
    <>
      <Grid item xs={8}>
        <InputText
          label={label}
          placeholder={placeholder}
          name={name}
          data-testid="file-name"
          required
          textAlign="right"
          value={fileName}
          onChange={(e) => setValue(e.target.value)}
        />
      </Grid>
      <Grid item xs={4} alignItems="flex-end" classes={{ root: classes.extension }}>
        <Text variant="body1" color="grey">
          .{extension}
        </Text>
      </Grid>
    </>
  );
};

FileNameField.defaultProps = {
  extensionName: 'extension',
};

FileNameField.propTypes = {
  name: PropTypes.string.isRequired,
  extensionName: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
};

export default FileNameField;
