import { atom, useAtomValue, useSetAtom, createStore, useAtom } from 'jotai';
import { createContext, useContext } from 'react';
import { TSearchTracksFilters } from 'services/tracks/types';

type TAddTrackDrawerStore = {
  cueContainerId: number;
  searchQuery: TSearchTracksFilters;
  page: number;
  limit: number;
};

export const addTrackDrawerStoreAtom = atom<TAddTrackDrawerStore>({
  cueContainerId: null,
  searchQuery: null,
  page: 0,
  limit: 20,
});

const addTrackDrawerFiltersReadAtom = atom<TAddTrackDrawerStore>((get) => get(addTrackDrawerStoreAtom));
const addTrackDrawerFiltersWriteAtom = atom(null, (_, set, newFilters: Partial<TAddTrackDrawerStore>) => {
  const page = newFilters?.page ?? 0;
  set(addTrackDrawerStoreAtom, (prev) => ({ ...prev, ...newFilters, page }));
});

export const useAddTrackDrawerFilters = () => useAtomValue(addTrackDrawerFiltersReadAtom);
export const useSetAddTrackDrawerFilters = () => useSetAtom(addTrackDrawerFiltersWriteAtom);

export const FormAwareContext = createContext(null);
export const formAwareStore = createStore();

export const addNewTrackFormIsDirtyAtom = atom(false);

export function useFormIsDirty() {
  const store = useContext(FormAwareContext);
  return useAtom(addNewTrackFormIsDirtyAtom, { store });
}
