/* eslint-disable react/jsx-props-no-spreading */
import { usePermissions } from 'utils/hooks';
import { getDisplayName } from 'utils';
import { withPermissionsPropTypes } from '../types';

const withPermissions = (WrappedComponent, { strategy = 'hide' } = {}) => {
  const Component = ({
    clearPermissions = undefined,
    cuePermissions = undefined,
    disabled = undefined,
    objectId = undefined,
    permissions = undefined,
    readOnly = undefined,
    ...props
  }) => {
    const isAllowed = usePermissions({
      permissions,
      clearPermissions,
      cuePermissions,
      objectId,
    });
    if (strategy === 'hide' && !isAllowed) return null;
    return <WrappedComponent {...props} disabled={disabled || !isAllowed} readOnly={readOnly || !isAllowed} />;
  };

  Component.propTypes = withPermissionsPropTypes;
  Component.displayName = `Secured${getDisplayName(WrappedComponent)}`;
  return Component;
};

export default withPermissions;
