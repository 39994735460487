import { emphasize } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core';
import { InputFieldHeight } from '../newTheme';
import { getSplitRadius } from '../theme';

export const OPTION_HEIGHT = 37.5;

const getVariant = (variant, theme) => {
  if (variant === 'primary') {
    return {
      color: theme.palette.primary.contrastText,
      background: theme.palette.primary.main,
    };
  }
  return {};
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  input: ({ hasError }) => ({
    display: 'flex !important',
    padding: '0!important',
    minHeight: InputFieldHeight.medium,
    height: 'auto',
    overflow: 'hidden',
    '&.tall': {
      minHeight: InputFieldHeight.medium,
    },
    ...(hasError && { border: '1px solid #C83C44' }),
  }),
  inputRoot: {
    '&, & > fieldset': {
      borderRadius: ({ split }) => getSplitRadius({ split }),
    },
  },
  controlContainer: {
    position: 'relative',
    width: '100%',
  },
  controlMenuOpenLines: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    backgroundColor: 'transparent',
    width: 'calc(100% - 2px)',
    height: '50%',
  },
  valueContainer: ({ isMultiple, overflow, variant }) => ({
    display: `${isMultiple && !overflow ? 'block' : 'flex'}`,
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
    marginLeft: theme.spacing(),
    '&.hasValue': {
      paddingLeft: variant ? theme.spacing(0.25) : 0,
      marginLeft: !variant ? theme.spacing(0.25) : 0,
    },
    /**
     * Writing Area
     */
    ...(isMultiple && {
      '& > :last-child': {
        minWidth: 50,
        display: 'inline-flex',
        alignItems: 'center',
        height: 30,
        top: 1,
        position: 'relative',
      },
    }),
    ...getVariant(variant, theme),
  }),
  dropdownIndicator: ({ variant }) => getVariant(variant, theme),
  chip: {
    margin: theme.spacing(0.5, 0.25),
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
      0.08,
    ),
  },
  noOptionsMessage: {
    backgroundColor: theme.palette.white,
    minHeight: InputFieldHeight.medium,
    padding: theme.spacing(0, 2),
    paddingTop: theme.spacing(0.5),
  },
  singleValue: {
    fontSize: '1rem',
    marginLeft: `${theme.spacing()}px !important`,
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    top: 0,
    bottom: 0,
    margin: 'auto',
    fontSize: '1rem',
    height: '100%',
    marginLeft: `${theme.spacing(1)}px !important`,
    width: 'calc(100% - 40px)',
    display: 'inline-flex',
    alignItems: 'center',
  },
  divider: {
    height: theme.spacing(2),
  },
  create: {
    color: theme.palette.primary.main,
    textTransform: 'uppercase',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
    overflow: 'hidden',
  },
}));

export default useStyles;
