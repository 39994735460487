/* eslint-disable @typescript-eslint/no-shadow */
import { Grid } from '@material-ui/core';
import { FormDatePicker, FormTypeAhead } from 'components/common/Form/Fields';
import { LicensorClearanceStatus } from 'components/styleguide/ClearanceStatusTypeAhead';
import { Row } from 'components/styleguide/index';
import { getIn, useFormikContext } from 'formik';
import { getAllLicensorsFromTrackCall } from 'redux-core/licensors/services';
import { useGetClearanceBundlesQuery } from 'services/clear/clearances-bundle';
import { FILE_TYPES } from 'utils/constants';
import { useFetch, useRootTranslation } from 'utils/hooks';

const rootT = 'drawers.fileAttachments';

export type ExtraFieldsProps = {
  track: { id: number };
  readOnlyProps: string[];
  clearanceBundled: boolean;
  divisionId?: number;
  rightsOwner?: { rightsOwnerName: string, clearanceBundleLicensorId: number };
  clearanceBundle?: { clearanceBundleId: number, clearanceBundleName: string };
};

const ExtraFields = ({
  track,
  readOnlyProps = [],
  clearanceBundled,
  divisionId,
  rightsOwner,
  clearanceBundle,
}: ExtraFieldsProps) => {
  const t = useRootTranslation(rootT);
  const { values, setFieldValue } = useFormikContext<{ clearanceBundleLicensorId: number }>();
  const fileType = getIn(values, 'fileType');

  const { data: clearancesBundles } = useGetClearanceBundlesQuery(track.id);

  // eslint-disable-next-line consistent-return
  const [, loadingClearance, setClearanceId] = useFetch(() => {
    // If there is only one clearance, the Clearance field should default to that clearance
    if (clearancesBundles?.length === 1) {
      const { id } = clearancesBundles[0];
      setFieldValue('qclearClearancesBundleId', id);
      return id;
    }
    const [filteredClearanceBundle] = clearancesBundles.filter(({ id }) => id === clearanceBundle.clearanceBundleId);
    const { id } = filteredClearanceBundle;
    setFieldValue('qclearClearancesBundleId', id);
    return id;
  }, [clearancesBundles, setFieldValue]);

  const [licensors, loadingLicensors] = useFetch(() => {
    if (track) {
      if (rightsOwner) {
        setFieldValue('clearanceBundleLicensorId', rightsOwner.clearanceBundleLicensorId);
      }
      return getAllLicensorsFromTrackCall({ trackId: track.id });
    }
    return null;
  },
    [track]);

  return (
    <>
      <Row>
        <Grid item xs={8}>
          {clearanceBundled ? (
            <FormTypeAhead
              isClearable={false}
              label={`${rootT}.clearance`}
              readOnly
              name="qclearClearancesBundleId"
              testId="file-clearance"
              loading={loadingClearance}
              options={clearancesBundles}
              onChange={() => undefined}
              alwaysRenderValue
              value={t('bundled')}
            />
          ) : (
            <FormTypeAhead
              isClearable={false}
              label={`${rootT}.clearance`}
              readOnly={readOnlyProps.includes('qclearClearancesBundleId')}
              name="qclearClearancesBundleId"
              placeholder={`${rootT}.chooseClearance`}
              testId="file-clearance"
              loading={loadingClearance}
              options={clearancesBundles}
              onChange={(e) => {
                const clearanceId = e.target.value;
                setFieldValue('qclearClearancesBundleId', clearanceId);
                setClearanceId(clearanceId);
              }}
            />
          )}
        </Grid>
      </Row>
      <Row>
        <Grid item xs={8}>
          <FormTypeAhead
            allowEmpty
            label={`${rootT}.licensor`}
            readOnly={readOnlyProps.includes('clearanceBundleLicensorId')}
            name="clearanceBundleLicensorId"
            placeholder={`${rootT}.noLicensor`}
            loading={loadingLicensors}
            options={licensors}
            testId="file-licensor"
          />
        </Grid>
      </Row>
      <Row>
        <Grid item xs={8}>
          <LicensorClearanceStatus
            disabled={!values.clearanceBundleLicensorId}
            divisionId={divisionId}
            testId="file-qclearLicensorStatusId"
            label={t('qclearLicensorStatusId')}
            name="qclearLicensorStatusId"
            placeholder={t('noLicensor')}
            allowEmpty
          />
        </Grid>
      </Row>
      {fileType === FILE_TYPES.QUOTE && (

        <Row>
          <Grid item xs={8}>
            <FormDatePicker
              readOnly={readOnlyProps.includes('quoteExpiration')}
              testId="file-quoteExpiration"
              label={`${rootT}.quoteExpiration`}
              name="quoteExpiration"
              placeholder={`${rootT}.noLicensor`}
            />
          </Grid>
        </Row>
      )}
    </>
  );
};

export default ExtraFields;
