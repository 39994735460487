import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { rootTranslation } from 'utils/format';
import { TLicensorWithOneStop } from 'services/tracks/types';
import { useWatch } from 'react-hook-form';

const t = rootTranslation('drawers.productionSetup.tracks.createLicensors');

const oneStopCombinedMenuItems: Array<{ label: string; type: TLicensorWithOneStop }> = [
  { label: 'one_stop', type: 'one_stop' },
  { label: 'writers', type: 'writer' },
  { label: 'publishers', type: 'publisher' },
];

const masterSyncMenuItems: Array<{ label: string; type: TLicensorWithOneStop }> = [
  { label: 'master', type: 'master' },
  { label: 'sync', type: 'sync' },
  { label: 'writers', type: 'writer' },
  { label: 'publishers', type: 'publisher' },
  { label: 'other', type: 'other' },
];

type AddRightsHoldersMenuProps = {
  onAddItem: (type: TLicensorWithOneStop) => void;
};

export function AddRightsHoldersMenu({ onAddItem }: AddRightsHoldersMenuProps) {
  const oneStopCombined = useWatch({ name: 'oneStopCombined' });

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleItemClick = (type: TLicensorWithOneStop) => {
    onAddItem(type);
    handleClose();
  };

  const menuItems = oneStopCombined ? oneStopCombinedMenuItems : masterSyncMenuItems;

  return (
    <div>
      <Button
        id="add-holder-button"
        aria-controls={open ? 'add-holders-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        variant="outlined"
        color="secondary"
        size="small"
      >
        {t('disclosureButton')}
      </Button>
      <Menu
        id="add-holders-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'add-holder-button',
        }}
      >
        {menuItems.map((it) => (
          <MenuItem key={it.type} onClick={() => handleItemClick(it.type)}>
            {t(it.label)}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
