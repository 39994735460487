/* eslint-disable react/no-children-prop */
import { FormButton } from 'components/common/Form/Fields';
import { DrawerHeader } from 'components/styleguide';
import { useTranslation } from 'react-i18next';

export const InviteUsersDrawerHeader = ({ onClose, onCancel }) => {
  const { t } = useTranslation();

  return (
    <DrawerHeader
      title={t('drawers.inviteUsers.drawerTitle')}
      onClose={onClose}
    >
      <FormButton
        alwaysEnabled
        variant="destructive"
        size="xs"
        children="global.forms.cancel"
        type="button"
        onClick={() => onCancel()}
      />
      <FormButton
        variant="primary"
        size="xs"
        children="drawers.inviteUsers.buttonInvite"
        type="submit"
        testId="invite-new-division-users-button"
      />
    </DrawerHeader>
  );
};
