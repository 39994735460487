/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import { Row, LabelControl } from 'components/styleguide';
import { Grid, Tooltip } from '@material-ui/core';
import { FormDatePicker } from 'components/common/Form/Fields/index';
import { RIGHTS_TERRITORIES_TERM_ENUM } from 'components/Settings/Tabs/Rights/constants';
import { useRootTranslation } from 'utils/hooks';
import { useFormikContext } from 'formik';
import Box from '@material-ui/core/Box';
import propOr from 'ramda/src/propOr';
import is from 'ramda/src/is';
import prop from 'ramda/src/prop';
import { get } from 'utils/object';
import dayjs from 'dayjs';
import useStyles from './styles';

const formDatePickerSharedProps = (required, readOnly) => ({
  required,
  readOnly,
  fullWidth: true,
});

const { ONE_DAY, INPERPETUITY, DATE_RANGE, OTHER } = RIGHTS_TERRITORIES_TERM_ENUM;

/**
 * Deduce a term based on the rights start and end date
 * Fallback to INPERPETUITY if both are null
 */
// eslint-disable-next-line consistent-return
export const getTerm = ({ start, end }) => {
  if (!start && !end) return INPERPETUITY;
  if (start && !end) return INPERPETUITY;
  if (start === end) return ONE_DAY;
  if (is(String, start) && is(String, end) && start !== end) return DATE_RANGE;
};

const TerritoryDates = ({ required, readOnly, fieldName }) => {
  const t = useRootTranslation('drawers.clearance.option');
  const classes = useStyles();
  const { values, setFieldValue, validateForm } = useFormikContext();

  const rightsFull = prop(fieldName, values);
  /** The BE is returning an empty object when the rights are empty */
  const rights = is(Array, prop('rights', rightsFull)) ? prop('rights', rightsFull) : [];

  return rights.map(({ id, name, start, end, term: termRaw, territory, otherDetails }, index) => {
    // termRaw is one of RIGHTS_TERRITORIES_TERM_ENUM, if there's no termRaw, it means
    // the rights are coming from a get-[required/requested]-rights call and we must
    // deduce them with getTerm
    const term = termRaw || getTerm({ start, end });
    const startName = `${fieldName}.rights.${index}.start`;
    const endName = `${fieldName}.rights.${index}.end`;
    const territoryName = name || propOr('', 'name', territory);

    const getHandleOneDay = (name) => async (date) => {
      if (date && date.isValid()) {
        const isoDate = date.toISOString();
        await setFieldValue(startName, isoDate);
        await setFieldValue(endName, isoDate);
      } else {
        await setFieldValue(startName, date || undefined);
        await setFieldValue(endName, date || undefined);
      }
      /** If there's a date difference, set rights to dirty */
      const formatDate = dayjs(date).format('YYYY-MM-DD');
      const currentDate = get(name)(values);
      if (formatDate !== currentDate) {
        await setFieldValue(`${fieldName}.dirty`, true);
      }
      validateForm();
    };

    const getHandleRest = (name) => async (date) => {
      /** If there's a date difference, set rights to dirty */
      const formatDate = dayjs(date).format('YYYY-MM-DD');
      const currentDate = get(name)(values);
      if (formatDate !== currentDate) {
        setFieldValue(`${fieldName}.dirty`, true);
      }
      /** Set Date */
      const isoDate = date.toISOString();
      await setFieldValue(name, isoDate);
      validateForm();
    };

    return (
      <React.Fragment key={`${id}-${name}`}>
        <Row mb={1} spacing={1}>
          <Grid item xs={3} className={classes.labelControlWrap}>
            <LabelControl label={t('territoryTitle')}>{territoryName}</LabelControl>
          </Grid>
        </Row>
        <Row key={id} mb={1} spacing={1}>
          {term === ONE_DAY && (
            <Grid item justify="flex-end" xs={6}>
              <Box width={160}>
                <FormDatePicker
                  {...formDatePickerSharedProps(required, readOnly)}
                  name={startName}
                  onChange={getHandleOneDay(startName)}
                  label={t('form.date')}
                />
              </Box>
            </Grid>
          )}
          {term === INPERPETUITY && (
            <Grid item justify="flex-end" xs={6}>
              <Box width={160}>
                <FormDatePicker
                  {...formDatePickerSharedProps(required, readOnly)}
                  name={startName}
                  label={t('form.start')}
                  onChange={getHandleRest(startName)}
                />
              </Box>
            </Grid>
          )}
          {term === DATE_RANGE && (
            <>
              <Grid item justify="flex-end" xs={6}>
                <Box width={160}>
                  <FormDatePicker
                    {...formDatePickerSharedProps(required || Boolean(start || end), readOnly)}
                    name={startName}
                    label={t('form.start')}
                    onChange={getHandleRest(startName)}
                  />
                </Box>
              </Grid>
              <Grid item justify="flex-end" xs={6}>
                <Box width={160}>
                  <FormDatePicker
                    {...formDatePickerSharedProps(required || Boolean(start || end), readOnly)}
                    name={endName}
                    label={t('form.end')}
                    onChange={getHandleRest(endName)}
                  />
                </Box>
              </Grid>
            </>
          )}
          {term === OTHER && (
            <Grid item xs={4}>
              <LabelControl label={t('form.details')}>
                <Tooltip title={otherDetails && otherDetails.length > 40 ? otherDetails : ''} placement="bottom-start">
                  <p style={{ maxWidth: 300 }} title>
                    {otherDetails && otherDetails.length > 40 ? `${otherDetails.substring(0, 40)}...` : otherDetails}
                  </p>
                </Tooltip>
              </LabelControl>
            </Grid>
          )}
        </Row>
      </React.Fragment>
    );
  });
};

TerritoryDates.propTypes = {
  rightsPresets: PropTypes.arrayOf(PropTypes.object),
  required: PropTypes.bool,
  readOnly: PropTypes.bool,
  fieldName: PropTypes.isRequired,
};

TerritoryDates.defaultProps = {
  rightsPresets: [],
  readOnly: false,
};

export default TerritoryDates;
