/* eslint-disable no-unused-expressions */
/* eslint-disable react/no-children-prop */
import React from 'react';
import PropTypes from 'prop-types';
import { Text, Button, Row, Dropzone, DrawerHeader } from 'components/styleguide';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import InputLabel from '@material-ui/core/InputLabel';
import { useRootTranslation } from 'utils/hooks';
import enhancer from './enhancer';

const rootT = 'drawers.fileAttachments';

const DropzoneFilesDrawer = ({ onClose, payload: { title, onSubmit } }) => {
  const t = useRootTranslation(rootT);
  const tGlobal = useRootTranslation('global');

  const handleClose = () => onClose({ content: t(`${rootT}.confirmDismiss`) });

  const handleOnUpload = async (file) => {
    await onClose(); // close it before opening another drawer
    onSubmit && (await onSubmit(file));
  };

  return (
    <>
      <DrawerHeader title={t('drawerTitle')} hideBackButton>
        <Button
          alwaysEnabled
          variant="destructive"
          testId="close-button"
          size="xs"
          children={tGlobal('forms.cancel')}
          type="button"
          onClick={handleClose}
        />
      </DrawerHeader>
      {title && (
        <Row>
          <Grid item>
            <InputLabel disableAnimation shrink position="top" margin={0} testId="file-attached-label">
              {t('fileAttachedTo', { title })}
            </InputLabel>

            <Box>
              <Text variant="caption" bold>
                {title}
              </Text>
            </Box>
          </Grid>
        </Row>
      )}
      <Row mb={5}>
        <Grid item container>
          <Box height="87vh" width={1}>
            <Dropzone name="fileName" onDrop={handleOnUpload} />
          </Box>
        </Grid>
      </Row>
    </>
  );
};

DropzoneFilesDrawer.propTypes = {
  onClose: PropTypes.func.isRequired,
  payload: PropTypes.shape({
    title: PropTypes.string,
    onSubmit: PropTypes.func,
  }).isRequired,
};

export default enhancer(DropzoneFilesDrawer);
