import { connect } from 'react-redux';
import { idleTiemoutSelector, isLockedSelector } from 'redux-core/auth/selectors';
import { getLocationSelector } from 'redux-core/router/selectors';
import { lockUserOut, logOut } from 'redux-core/auth/actions';

const mapStateToProps = (state) => ({
  idleTimeout: idleTiemoutSelector(state),
  locked: isLockedSelector(state),
  location: getLocationSelector(state),
});

const mapDispatchToPRops = {
  lockUserOut,
  logOut,
};

export default connect(mapStateToProps, mapDispatchToPRops);
