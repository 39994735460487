/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const InactiveTrack = (props) => (
  <SvgIcon {...props} viewBox="0 0 20 20">
    <path
      fill="#C83C44"
      fillRule="evenodd"
      d="M10 0C4.5 0 0 4.5 0 10s4.5 10 10 10 10-4.5 10-10S15.5 0 10 0zM2 10c0-4.4 3.6-8 8-8 1.8 0 3.5.6 4.9 1.7L3.7 14.9C2.6 13.5 2 11.8 2 10zm8 8c-1.8 0-3.5-.6-4.9-1.7L16.3 5.1C17.4 6.5 18 8.2 18 10c0 4.4-3.6 8-8 8z"
      clipRule="evenodd"
    />
  </SvgIcon>
);

export default InactiveTrack;
