import { handleActions } from 'redux-actions';
import mergeRight from 'ramda/src/mergeRight';
import * as actions from './actions';

const defaultState = {
  filters: {
    divisionId: null,
    groupByLicensors: true,
    tracks: [],
    licensors: [],
    subProjects: [],
    seasons: [],
    productions: [],
    status: null,
    projectId: null,
    normalizedProdType: null,
  },
};

const licensingReducer = {
  [actions.setLicensingFilter]: (state, { payload }) => ({
    ...state,
    filters: mergeRight(defaultState.filters, payload),
  }),
  [actions.clearFilters]: () => defaultState,
};

export default handleActions(licensingReducer, defaultState);
