/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { useDrawer } from 'utils/hooks';
import { SecuredFormItemsList } from 'components/common/Secured';
import AddItemsList from 'components/common/AddItemsList';
import enhancer from './enhancer';

const ItemList = ({ setItems, keepReleaseInfo, btnLabel, onSubmit, keepPersonnelInfo, setSelected, ...rest }) => {
  const openDrawer = useDrawer();

  return (
    <SecuredFormItemsList
      btnLabel={btnLabel}
      items={setItems({
        keepReleaseInfo,
        keepPersonnelInfo,
        setSelected,
        openDrawer,
        onSubmit,
      })}
      {...rest}
    />
  );
};

ItemList.propTypes = {
  setItems: PropTypes.func.isRequired,
  keepReleaseInfo: PropTypes.func.isRequired,
  btnLabel: AddItemsList.propTypes.btnLabel,
  onSubmit: PropTypes.func.isRequired,
  keepPersonnelInfo: PropTypes.func.isRequired,
  setSelected: PropTypes.func.isRequired,
  companies: PropTypes.array.isRequired,
};

ItemList.defaultProps = {
  btnLabel: 'addItem',
};

export default enhancer(ItemList);
