/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-expressions */
import React, { useCallback } from 'react';
// eslint-disable-next-line import/no-cycle
import { BaseDrawer } from 'components/styleguide'; // note: dont remove getDisplayName, is being used on commented code for devs
import mergeAll from 'ramda/src/mergeAll';
import { CommonDrawerPropTypes } from './constants';

const defaultProps = {
  open: false,
  onClose: () => {},
  large: false,
  xlarge: false,
  dense: false,
};

function withBaseDrawer(WrappedComponent, options = {}) {
  const Component = ({ clearForms, ...props }) => {
    const { onClose, ...baseProps } = mergeAll([defaultProps, options, props]);

    const handleClose = useCallback(async () => {
      clearForms && (await clearForms());
      onClose();
    }, [clearForms, onClose]);

    /*
     * If you need to hardcode a Drawer to be opened for dev purposes
     * replace 'YOUR_DRAWER_CONSTANT_STRING' with the name of the Drawer you need to keep open
     */
    // if (
    //   baseProps.name === 'YOUR_DRAWER_CONSTANT_STRING' &&
    //   process.env.NODE_ENV === 'development'
    // ) {
    //   baseProps.open = true;
    // }
    return (
      <BaseDrawer {...baseProps} onClose={handleClose}>
        <WrappedComponent onClose={handleClose} {...props} />
      </BaseDrawer>
    );
  };

  Component.propTypes = CommonDrawerPropTypes;

  return Component;
}

export default withBaseDrawer;
