/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { withFormField } from 'components/common/Form/utils';
import { FormInputText } from '..';

const FileNameCloseButton = ({ form, field }) => {
  const refreshState = () => form.setFieldValue(field.name, '');

  return (
    <>
      {field.value && (
        <IconButton size="small" color="greenCTA">
          <CloseIcon onClick={refreshState} />
        </IconButton>
      )}
    </>
  );
};

export const Input = ({ field, form, ...props }) => (
  <FormInputText
    endAdornment={props.disabled ? undefined : <FileNameCloseButton form={form} field={field} />}
    {...props}
    {...field}
  />
);

Input.displayName = 'FormCancelableInput';

Input.propTypes = {
  disabled: PropTypes.bool,
};

export default withFormField(Input, { type: 'disabled' });
