/* eslint-disable no-use-before-define */
/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable import/no-cycle */
import { createActions } from 'redux-actions';
import compose from 'ramda/src/compose';
import filter from 'ramda/src/filter';
import find from 'ramda/src/find';
import head from 'ramda/src/head';
import identity from 'ramda/src/identity';
import map from 'ramda/src/map';
import pathEq from 'ramda/src/pathEq';
import prop from 'ramda/src/prop';
import propEq from 'ramda/src/propEq';
import reject from 'ramda/src/reject';
import uniq from 'ramda/src/uniq';
import i18next from 'i18next';
import { showSnackbar } from 'redux-core/global-error/actions';
import { goToLink } from 'redux-core/router/actions';
import ROUTES from 'components/Routes/routes';
import { v4 as uuidv4 } from 'uuid';
import { bestMatch, concatStrings } from 'utils/index';
import { getLetterTemplates, getEmailTemplates } from 'redux-core/templates/services';
import { getFilteredTemplates } from 'components/Letters/SendLetter/utils';
import { getTranslatedTrackStatus } from 'utils/constants';
import { batch } from 'react-redux';
import { STEPS } from 'components/Letters/constants';
import { getLocationParametersDivisionId } from 'redux-core/router/selectors';
import {
  getFilterSelector,
  getSelectedLettersForStep2,
  getSelectedLettersForStep3,
  getLettersActiveStepSelector,
  getLetterTypeSelector,
  getLettersIsBundleSelector,
  getDivisionIdSelector,
  getLetterTemplatesSelector,
  getEmailTemplatesSelector,
  getPathFromClearanceSelector,
} from './selectors';
import { getBatchLetters, saveDraftLetterCall, sendLetterCall, updateStatusAndDateCall } from './services';

const actionsDefinition = {
  CLEAN_LETTERS: identity,
  SET_ACTIVE_DRAFT: identity,
  SET_ACTIVE_STEP: identity,
  SET_FILTER: identity,
  SET_LETTERS: identity,
  SET_LETTERS_IS_BUNDLE: identity,
  SET_SELECTED_LETTERS_FOR_STEP2: identity,
  SET_SELECTED_LETTERS_FOR_STEP3: identity,
  SET_FILTERS_METADATA: identity,
  SET_EMAIL_TEMPLATES: identity,
  SET_LETTER_TEMPLATES: identity,
};

export const {
  cleanLetters,
  setActiveDraft,
  setActiveStep,
  setLetters,
  setLettersIsBundle,
  setFilter,
  setSelectedLettersForStep2,
  setSelectedLettersForStep3,
  setFiltersMetadata,
  setEmailTemplates,
  setLetterTemplates,
} = createActions(actionsDefinition);

const rootT = 'letters.notifications';

export const fetchBatchLetters = (preferStore) => async (dispatch, getState) => {
  const state = getState();
  const filters = getFilterSelector(state);
  const selectedLettersStep2 = getSelectedLettersForStep2(state);
  const selectedLettersStep3 = getSelectedLettersForStep3(state);

  if (preferStore && selectedLettersStep3 && selectedLettersStep3.length) {
    return selectedLettersStep3;
  }

  const letters = await getBatchLetters(filters);

  compose(
    (letters) => {
      batch(() => {
        dispatch(setLetters(letters));
        dispatch(setSelectedLettersForStep2(preferStore ? letters : selectedLettersStep2));
        dispatch(setSelectedLettersForStep3(preferStore ? letters : selectedLettersStep3));
      });
    },
    map((letter) => ({
      ...letter,
      id: uuidv4(),
      clearanceId: letter.clearance.id,
      clearanceStatus: getTranslatedTrackStatus(letter.clearance.status),
      licensorId: letter.licensor.id,
    })),
  )(letters);
  if (!letters || !letters.length) {
    dispatch(goToLink(ROUTES.LETTERS.CLEARANCES));
  }
};

export const filterBatchLetters = (filter, config) => async (dispatch, getState) => {
  const { isFromStep1, preferStore } = config;
  const stateFilters = getFilterSelector(getState());
  const filtersToDispatch = isFromStep1 ? filter : { ...stateFilters, ...filter };

  await dispatch(setFilter(filtersToDispatch));
  return dispatch(fetchBatchLetters(preferStore));
};

export const removeLetterFromQueue = (licensorId) => async (dispatch, getState) => {
  const state = getState();
  const letters = getSelectedLettersForStep3(state);
  const remainingLetters = reject(pathEq(['licensor', 'id'], licensorId))(letters);
  await dispatch(checkLettersQueue());
  await dispatch(setSelectedLettersForStep3(remainingLetters));
  return remainingLetters;
};

export const checkLettersQueue = () => async (dispatch, getState) => {
  const state = getState();
  const letters = getSelectedLettersForStep3(state);
  if (letters.length) return;
  const pathFromClearance = getPathFromClearanceSelector(state);
  if (pathFromClearance) {
    await dispatch(goToLink(pathFromClearance, undefined, { mergeParams: true }));
  } else await dispatch(goToLink(ROUTES.LETTERS.CLEARANCES));
  dispatch(cleanLetters());
};

export const goBackToPreviousFlow = () => async (dispatch, getState) => {
  const state = getState();
  const activeStep = getLettersActiveStepSelector(state);
  const pathFromClearance = getPathFromClearanceSelector(state);

  if ((activeStep === STEPS.CONTACT || activeStep === STEPS.LETTER) && pathFromClearance) {
    await dispatch(goToLink(pathFromClearance, undefined, { mergeParams: true }));
  }
};

const getQclearLicensorIds = (state, optionsQclearLicensorIds, licensorId, bundle) => {
  const selectedLettersIds = map((letter) => letter.licensorId)(getSelectedLettersForStep3(state));
  const optionalClearancesSelected = compose(
    map((clearance) => clearance.licensorId),
    filter((clearance) => clearance.checked),
  )(optionsQclearLicensorIds);

  if (bundle) {
    return uniq([...selectedLettersIds, ...optionalClearancesSelected]);
  }

  return uniq([licensorId, ...optionalClearancesSelected]);
};

const buildLetterOrDraftPayload = ({ values, state, isDraft = false }) => {
  let divisionId = getDivisionIdSelector(state);
  if (!divisionId) {
    divisionId = getLocationParametersDivisionId(state);
  }
  const bundle = getLettersIsBundleSelector(state);
  const {
    contact,
    email,
    extraAttachments,
    letter: { licensorStatus, formattedUrl, letterFormat, letterUrl },
    letterType,
    licensorId,
    optionsQclearLicensorIds,
    expirationDate,
  } = values;
  const attachmentName = concatStrings('.')(email.attachmentName, letterFormat);
  const clearanceBundleLicensorsIds = getQclearLicensorIds(state, optionsQclearLicensorIds, licensorId, bundle);

  const payload = {
    bundle,
    contact,
    clearanceBundleLicensorsIds: values.clearanceBundleLicensorsIds ?? clearanceBundleLicensorsIds,
    licensorStatus,
    extraAttachments,
    letterType,
    expirationDate,
    divisionId,
    email: { ...email, attachmentName },
  };

  if (isDraft) return { ...payload, letterUrl };

  return {
    ...payload,
    letterDocxUrl: letterUrl,
    letterUrl: formattedUrl || letterUrl,
  };
};

export const sendLetter = (values) => async (dispatch, getState) => {
  const state = getState();
  const payload = buildLetterOrDraftPayload({ values, state });

  await sendLetterCall(payload);
  const letters = await dispatch(removeLetterFromQueue(values.licensorId));

  const message = i18next.t(`${rootT}.${letters.length ? 'sent' : 'completed'}`);
  dispatch(showSnackbar({ message }));
};

export const saveDraft = (values) => async (dispatch, getState) => {
  const state = getState();
  const isDraft = true;
  const payload = buildLetterOrDraftPayload({ values, state, isDraft });
  const savedLetter = await saveDraftLetterCall(payload);

  compose(
    dispatch,
    setSelectedLettersForStep3,
    map((letter) => ({
      ...letter,
      draftId:
        letter.clearanceId === values.clearanceId && payload.clearanceBundleLicensorsIds.includes(letter.licensorId)
          ? savedLetter.id
          : letter.draftId,
    })),
    getSelectedLettersForStep3,
  )(state);

  const message = i18next.t(`${rootT}.draft`);
  dispatch(showSnackbar({ message }));

  return savedLetter;
};

export const setActiveDraftLetter = (licensorId, clearanceId) => async (dispatch, getState) => {
  const state = getState();
  const letters = getSelectedLettersForStep3(state);
  const letter = letters.find((l) => l.licensorId === licensorId && l.clearanceId === clearanceId);

  const activeDraft = letter
    ? {
        clearanceId: letter.clearanceId,
        draftId: letter.draftId,
        id: letter.licensorId,
        qwireTracksRightsOwnerId: letter.licensor.qwireTracksRightsOwnerId,
      }
    : {};
  dispatch(setActiveDraft(activeDraft));
  return activeDraft;
};

export const fetchTemplates = () => async (dispatch, getState) => {
  const state = getState();
  const divisionId = getDivisionIdSelector(state);
  const payload = { divisionId };
  const [emails, letters] = await Promise.all([getEmailTemplates(payload), getLetterTemplates(payload)]);
  dispatch(setEmailTemplates(emails));
  dispatch(setLetterTemplates(letters));
};

export const getDefaultTemplates = (licensorId) => async (dispatch, getState) => {
  const state = getState();
  const emails = getEmailTemplatesSelector(state);
  const letters = getLetterTemplatesSelector(state);

  // eslint-disable-next-line no-return-await
  return await Promise.all([
    dispatch(getDefaultTemplate(licensorId, emails)),
    dispatch(getDefaultTemplate(licensorId, letters)),
  ]);
};

const getDefaultTemplate = (licensorId, templates) => (_, getState) => {
  if (!licensorId) return null;

  const state = getState();
  const letterType = getLetterTypeSelector(state);
  const selectedLetter = getSelectedLetterMapped(licensorId, letterType)(state);
  const mappedTemplates = getMappedTemplates(letterType, selectedLetter.licensorType, templates);
  const candidates = bestMatch(selectedLetter, mappedTemplates);

  if (!candidates.length) return null;

  return (candidates.find(propEq('isDefault', true)) || candidates[0]).id;
};

const getSelectedLetterMapped = (licensorId, letterType) =>
  compose(
    ({ productionPath, licensor: { qwireTracksRightsOwnerId: licensorId, type: licensorType } }) => ({
      projectId: compose(prop('id'), head)(productionPath),
      licensorId,
      licensorType,
      letterType,
    }),
    find(propEq('licensorId', licensorId)),
    getSelectedLettersForStep3,
  );

const getMappedTemplates = (letterType, licensorType, templates) => {
  const filteredTemplates = getFilteredTemplates({
    letterType,
    licensorType,
    templates,
  });

  return map(
    ({ licensorType, project, qwireTracksLicensor, isDefault, id }) => ({
      licensorType,
      projectId: project?.id,
      isDefault,
      id,
      licensorId: qwireTracksLicensor?.id,
    }),
  )(filteredTemplates);
};

export const getTemplateLetterType = (id) => (_, getState) => {
  const state = getState();
  const letterTemplates = getLetterTemplatesSelector(state);
  return compose(prop('letterType'), find(propEq('id', id)))(letterTemplates);
};

export const updateStatusAndDate = (values) => async (dispatch, getState) => {
  const state = getState();

  const { setStatus, setSendDate, ...rest } = values;

  const payload = buildLetterOrDraftPayload({ values: rest, state });

  await updateStatusAndDateCall({ setStatus, setSendDate, ...payload });

  await dispatch(removeLetterFromQueue(values.licensorId));
  const message = i18next.t(rootT.downloaded);
  dispatch(showSnackbar({ message }));
};

export const downloadLetterWithoutUpdate = (values) => async (dispatch) => {
  await dispatch(removeLetterFromQueue(values.licensorId));
  const message = i18next.t(rootT.downloaded);
  dispatch(showSnackbar({ message }));
};
