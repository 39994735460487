/* eslint-disable import/no-cycle */
import { createBrowserHistory } from 'history';
import { connectRouter, routerMiddleware } from 'connected-react-router';

import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';

import authReducer from 'redux-core/auth/reducer';
import cueContainersReducer from 'redux-core/cueContainers/reducer';
import dialogsReducer from 'redux-core/dialogs/reducer';
import headerReducer from 'redux-core/header/reducer';
import lettersReducer from 'redux-core/letters/reducer';
import overviewReducer from 'redux-core/overview/reducer';
import productionsReducer from 'redux-core/productions/reducer';
import promptReducer from 'redux-core/prompt/reducer';
import rightsPresetsReducer from 'redux-core/rights/reducer';
import settingsReducer from 'redux-core/settings/reducer';
import territoriesReducer from 'redux-core/territories/reducer';
import tracksReducer from 'redux-core/tracks/reducer';
import SnackbarReducer from 'redux-core/global-error/reducer';
import divisionsReducer from 'redux-core/divisions/reducer';
import formReducer from 'redux-core/form/reducer';
import permissionsReducer from 'redux-core/permissions/reducer';
import projectsReducer from 'redux-core/projects/reducer';
import watchingReducer from 'redux-core/watching/reducer';
import licensingReducer from 'redux-core/licensing/reducer';
import cueSheetReducer from 'redux-core/cueSheet/reducer';
import importCueSheetReducer from 'redux-core/import-cuesheet/reducer';
import smartFlagsFilterReducer from 'redux-core/overview/smartFlags/reducer';

import LogRocket from 'logrocket';
import { baseApi } from '../services';

export const history = createBrowserHistory();

export const buildStore = (preloadedState = {}) =>
  configureStore({
    reducer: {
      // Add the generated reducer as a specific top-level slice
      [baseApi.reducerPath]: baseApi.reducer,
      router: connectRouter(history),
      auth: authReducer,
      cueContainers: cueContainersReducer,
      cueSheet: cueSheetReducer,
      dialogs: dialogsReducer,
      divisions: divisionsReducer,
      form: formReducer,
      header: headerReducer,
      importCueSheet: importCueSheetReducer,
      letters: lettersReducer,
      licensing: licensingReducer,
      overview: overviewReducer,
      permissions: permissionsReducer,
      productions: productionsReducer,
      projects: projectsReducer,
      prompt: promptReducer,
      rights: rightsPresetsReducer,
      settings: settingsReducer,
      snackbar: SnackbarReducer,
      territories: territoriesReducer,
      tracks: tracksReducer,
      watching: watchingReducer,
      smartFlagsFilter: smartFlagsFilterReducer,
    },
    // Adding the api middleware enables caching, invalidation, polling,
    // and other useful features of `rtk-query`.
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware()
        .concat(baseApi.middleware)
        .concat(routerMiddleware(history))
        .concat(LogRocket.reduxMiddleware()),
    preloadedState,
  });

const store = buildStore();

setupListeners(store.dispatch);

export type RootState = {
  auth?: {
    userData?: {
      bearerToken?: string;
    };
  };
};

export { store };
export default store;
