/* eslint-disable import/no-cycle */
import { connect } from 'react-redux';
import { setRightPreset } from 'redux-core/rights/actions';
import compose from 'ramda/src/compose';
import { withFormConnected } from 'components/styleguide/HOC';

const mapDispatchToProps = {
  setRightPreset,
};

export default compose(withFormConnected, connect(null, mapDispatchToProps));
