/* eslint-disable import/no-cycle */
import makeEntityServiceCalls, { makeServiceCall } from '../serviceHelpers';

export const { create: createEmployeeCall, getAll: getEmployeesCall } = makeEntityServiceCalls('employees');

export const getTenantCall = makeServiceCall('tenants', 'get');

export const getAllTenants = makeServiceCall('tenants', 'get-all');

export const createTenantCall = makeServiceCall('tenants', 'create');

export const updateTenantCall = makeServiceCall('tenants', 'update');

export const getAllUserPermissions = makeServiceCall('user-permissions', 'get-all');

export const getPermissionsDefinitions = makeServiceCall('permissions', 'get-definitions');
export const toggleDivisionAccess = makeServiceCall('user-permissions', 'toggle-division-access');

export const permissionsUpsert = makeServiceCall('permissions', 'upsert');
