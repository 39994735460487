import { createSelector } from 'reselect';
import { compose, prop, find, propEq } from 'ramda';

const getRightsRoot = (state) => state.rights;

/** Single */
export const getRightPresetSelector = createSelector(getRightsRoot, prop('preset'));

export const getRightPresetIsEditingSelector = createSelector(getRightPresetSelector, prop('isEditing'));

/** List */
export const getRightsPresetsSelector = createSelector(getRightsRoot, prop('presets'));

export const getRightPresetsMediaTypesSelector = createSelector(getRightsRoot, prop('mediaTypes'));

export const getTerritoryLetterNameById =
  (id) =>
  ({ territories }) => {
    const result = compose(prop('nameOnLetters'), find(propEq('id', id)), prop('presets'))(territories);
    return result;
  };
