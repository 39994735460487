import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  button: ({ size }) => ({
    '& > *': {
      height: size,
      width: size,
    },
  }),
  iconOpacity: {
    opacity: 0.3,
  },
});

export default useStyles;
