/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/no-children-prop */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FieldArray } from 'formik';
import Form from 'components/common/Form';
import { FormButton, FormInputText } from 'components/common/Form/Fields';
import {
  getPersonnelPositionsCall,
  createPersonnelPositionsCall,
  updatePersonnelPositionsCall,
} from 'redux-core/divisions/services';
import { useFetch } from 'utils/hooks';
import { Grid } from '@material-ui/core';
import { BaseDrawer, DrawerHeader } from 'components/styleguide';
import { useTranslation } from 'react-i18next';
import { propOr, pick, is } from 'ramda';
import * as Yup from 'yup';
import EditDeleteMenuWrapperGrid from '../../EditDeleteMenuWrapperGrid';
import enhancer from './enhancer';
import { CommonDrawerPropTypes } from '../constants';

const rootT = 'drawers.productionSetup.position';

const validationSchema = Yup.object().shape({
  positions: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required('global.forms.validations.required'),
    }),
  ),
});

const PositionDrawer = ({ name, classes, onClose, setupInfo }) => {
  const { t } = useTranslation();
  const [editingFieldCache, setEditingFieldCache] = useState(null);
  const [editableIndex, setEditableIndex] = useState(null);
  const [isAdding, setIsAdding] = useState(false);
  const tenantId = pick(['tenantId'])(setupInfo);
  const [positions, , refetchPositions] = useFetch(() => getPersonnelPositionsCall(tenantId));

  const callCreateOrEditEndpoint = (formValues) => {
    const positionValues = formValues.positions;
    const newOrUpdatedName = positionValues[editableIndex].name;
    const positionId = positionValues[editableIndex].id || null;

    if (positionId) {
      const payload = {
        id: positionId,
        name: newOrUpdatedName,
      };

      updatePersonnelPositionsCall(payload);
    } else {
      const payload = {
        name: newOrUpdatedName,
        tenantId: tenantId.tenantId,
      };
      createPersonnelPositionsCall(payload);
    }

    refetchPositions();
  };

  return (
    <Form
      drawerName={name}
      onSubmit={(values, formikBag) => {
        formikBag.setSubmitting(false);
        callCreateOrEditEndpoint(values);
        setEditableIndex(null);
      }}
      validationSchema={validationSchema}
      enableReinitialize
      initialValues={{ positions }}
    >
      {(formik) => (
        <FieldArray name="positions">
          {(fieldArrayProps) => (
            <>
              <DrawerHeader title={t(`${rootT}.drawerTitle`)} onClose={onClose}>
                {editableIndex !== null ? (
                  <>
                    <FormButton
                      testId="personnel-drawer-cancel"
                      variant="destructive"
                      size="xs"
                      children="global.forms.cancel"
                      onClick={() => {
                        isAdding
                          ? fieldArrayProps.remove(0)
                          : fieldArrayProps.replace(editableIndex, editingFieldCache.name);
                        setIsAdding(false);
                        setEditableIndex(null);
                      }}
                    />
                    <FormButton
                      children="global.forms.save"
                      testId="personnel-drawer-cancel"
                      size="xs"
                      type="submit"
                      variant="primary"
                    />
                  </>
                ) : (
                  <FormButton
                    alwaysEnabled
                    children="global.forms.add"
                    testId="personnel-drawer-add"
                    size="xs"
                    type="button"
                    variant="primary"
                    onClick={() => {
                      fieldArrayProps.unshift({
                        position: '',
                      });
                      setIsAdding(true);
                      setEditableIndex(0);
                    }}
                  />
                )}
              </DrawerHeader>
              <Grid className={classes.container} container direction="column">
                {formik.values.positions.map((position, index) => (
                  <EditDeleteMenuWrapperGrid
                    disableHover={is(Number, editableIndex)}
                    setEditable={() => {
                      setEditingFieldCache(formik.values.positions[index]);
                      setEditableIndex(index);
                    }}
                    // @TODO CLR-3672 This isn't removing things from backend
                    onConfirm={() => fieldArrayProps.remove(index)}
                    key={propOr('id', 'new', position) + index}
                  >
                    {editableIndex === index ? (
                      <FormInputText autofocus value={position.name} name={`positions.${index}.name`} />
                    ) : (
                      position.name
                    )}
                  </EditDeleteMenuWrapperGrid>
                ))}
              </Grid>
            </>
          )}
        </FieldArray>
      )}
    </Form>
  );
};

PositionDrawer.propTypes = {
  name: PropTypes.string,
  classes: PropTypes.object,
  onClose: CommonDrawerPropTypes.onClose,
  setupInfo: PropTypes.object.isRequired,
};

const Drawer = ({ open, onClose, ...props }) => (
  <BaseDrawer open={open} onClose={onClose}>
    <PositionDrawer onClose={onClose} {...props} />
  </BaseDrawer>
);

Drawer.propTypes = CommonDrawerPropTypes;

export default enhancer(Drawer);
