/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';
import { BaseDrawer } from 'components/styleguide';
import { useRootTranslation } from 'utils/hooks';
import map from 'ramda/src/map';
import ActorDirectorDrawer from '../ActorDirectorDrawer';
import { CommonDrawerPropTypes } from '../constants';

function ActorsDrawer({
  name,
  onClose,
  payload: { onSubmit, actors = [], readOnly, rootT = 'drawers.productionSetup.actors' },
}) {
  const t = useRootTranslation(rootT);
  const arrayToOptions = map((v) => ({ id: v, name: v }));

  const ROLES = [t('actor'), t('actorDirector')];
  const ROLE_OPTIONS = arrayToOptions(ROLES);

  return (
    <ActorDirectorDrawer
      name={name}
      options={ROLE_OPTIONS}
      onClose={onClose}
      rootT={rootT}
      payload={{
        onSubmit: (values) =>
          onSubmit({
            actors: values,
          }),
        people: actors,
      }}
      readOnly={readOnly}
    />
  );
}

ActorsDrawer.propTypes = {
  name: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  payload: PropTypes.object.isRequired,
  rootT: PropTypes.string,
};

function Drawer({ open, onClose, ...props }) {
  return (
    <BaseDrawer open={open} onClose={onClose}>
      <ActorsDrawer onClose={onClose} {...props} />
    </BaseDrawer>
  );
}

Drawer.propTypes = CommonDrawerPropTypes;

export default Drawer;
