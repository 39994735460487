import { makeStyles } from '@material-ui/core';

const headerHeight = '35px';
const headerExtraSpacing = '52px';

export default makeStyles(() => ({
  scrollable: {
    maxHeight: `calc(100vh - ${headerHeight} - ${headerExtraSpacing})`,
    overflow: 'auto',
  },
}));
