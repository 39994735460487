/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-children-prop */
/* eslint-disable @typescript-eslint/no-shadow */
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { BaseDrawer, DrawerHeader, Row } from 'components/styleguide';
import { useRootTranslation, useFetch } from 'utils/hooks';
import { FormButton, FormInputText, FormTypeAhead, FormInputFormat, FormCheckbox } from 'components/common/Form/Fields';
import Grid from '@material-ui/core/Grid';
import * as Yup from 'yup';
import { TIME_FORMATS } from 'utils/format';
import { yupTimeMMSS, yupRightPresetTerritories } from 'utils/forms';
import { TRACK_USE_REQUESTED_OPTIONS } from 'utils/constants';
import { requestWithError } from 'utils/request';
import { SecuredForm, SecuredFormInputCurrency, SecuredRightsPresetPicker } from 'components/common/Secured';
import { CueContainerPicker } from 'components/common/CueContainerPicker';
import { COMMON_ROLES, CLEAR_PERMISSIONS_GROUPS } from 'redux-core/permissions/constants';
import { getRequiredRights, upsertRights } from 'redux-core/rights/services';
import prop from 'ramda/src/prop';
import omit from 'ramda/src/omit';
import { RIGHTS_HINT_MESSAGE_TYPE } from 'components/common/Drawers/RightPresetDrawer/constants';
import enhancer from './enhancer';

const validationSchema = (isEditing) =>
  Yup.object().shape({
    name: Yup.string().required('global.forms.validations.required'),
    cueContainerId: Yup.number(),
    requiredRights: isEditing
      ? Yup.object().shape({
          name: Yup.string(),
          rights: yupRightPresetTerritories(),
        })
      : Yup.object().shape({
          name: Yup.string().required('global.forms.validations.required'),
          rights: yupRightPresetTerritories().required('global.forms.validations.required'),
        }),
    optionFee: Yup.number(),
    optionUseDuration: yupTimeMMSS,
    optionUseType: Yup.string().nullable(),
  });

const ClearanceOptionDrawer = ({
  currencyInfo,
  name,
  onClose,
  payload: { divisionId, option = {}, trackId, clearancesFetch, objectId, onSubmit, clearanceBundleId },
  createClearanceOption,
  updateClearanceOption,
  formId,
  savedValues,
  rights,
}) => {
  const t = useRootTranslation('drawers.clearance.option');
  const isEditing = Boolean(option.id);
  const [requiredRightsRaw] = useFetch(
    () => option.id && !rights && getRequiredRights({ clearanceId: option.id }),
    [option.id, rights],
    {},
  );

  const requiredRights = rights || requiredRightsRaw;

  const initialValues = {
    ...option,
    ...savedValues,
    requiredRights,
    dirty: prop('dirty', rights),
  };
  const onCancel = () => {
    onClose();
  };
  const handleSubmit = async (values) => {
    const body = { ...omit(['rights', 'territories'], values), trackId };
    if (isEditing) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { rights, ...updatePayload } = body;
      await updateClearanceOption(updatePayload);
      await upsertRights({
        divisionId,
        clearanceId: updatePayload.id,
        rights: updatePayload.requiredRights,
      });
    } else {
      const createRes = await createClearanceOption({ ...body, bundleId: clearanceBundleId });
      await upsertRights({
        divisionId,
        clearanceId: createRes.id,
        rights: body.requiredRights,
      });
    }

    if (!onSubmit) await requestWithError(clearancesFetch, body);
    if (onSubmit) await onSubmit();
    onClose();
  };

  return (
    <SecuredForm
      drawerName={name}
      enableReinitialize
      initialValues={initialValues}
      objectId={objectId}
      onSubmit={handleSubmit}
      permissions={COMMON_ROLES.EDITOR}
      validationSchema={validationSchema(isEditing)}
    >
      {({ values }) => (
        <>
          <DrawerHeader title={t(!isEditing ? 'drawerTitle' : 'drawerEditTitle')} onClose={onClose}>
            <FormButton
              alwaysEnabled
              variant="destructive"
              size="xs"
              testId="clearance-option-drawer-cancel"
              children="global.forms.cancel"
              onClick={() => onCancel()}
            />
            <FormButton
              variant="primary"
              testId="clearance-option-drawer-save"
              size="xs"
              children={!isEditing ? 'global.forms.add' : 'global.forms.save'}
              type="submit"
            />
          </DrawerHeader>
          <Row>
            <FormInputText required name="name" label={t('form.name')} />
          </Row>
          {!isEditing && (
            <Row>
              <CueContainerPicker trackId={trackId} />
            </Row>
          )}
          <SecuredRightsPresetPicker
            divisionId={divisionId}
            showDefaultPreset={false}
            formId={formId}
            permissions={COMMON_ROLES.EDITOR}
            clearPermissions={CLEAR_PERMISSIONS_GROUPS.RIGHTS}
            objectId={objectId}
            required={!isEditing}
            saveInDrawerName={name}
            hintType={RIGHTS_HINT_MESSAGE_TYPE.CLEARANCE}
            initialRights={requiredRights}
          />
          {!option.exercised && (
            <Row spacing={1}>
              <Grid item xs={6}>
                <SecuredFormInputCurrency
                  label={t('form.fee')}
                  name="optionFee"
                  testId="clearance-option-drawer-fee"
                  type="currency"
                  objectId={objectId}
                  {...currencyInfo}
                />
              </Grid>
            </Row>
          )}
          <Row spacing={1}>
            {!values.optionUseDurationFull && (
              <Grid item xs={3}>
                <FormInputFormat
                  format={TIME_FORMATS.MMSS}
                  label={t('form.duration')}
                  name="optionUseDuration"
                  testId="clearance-option-drawer-duration"
                  type="time"
                />
              </Grid>
            )}
            <Grid item xs={values.optionUseDurationFull ? 6 : 3}>
              <FormTypeAhead
                name="optionUseType"
                label={!isEditing ? t('form.use') : t('form.requested')}
                options={TRACK_USE_REQUESTED_OPTIONS}
                testId="clearance-option-drawer-use"
              />
            </Grid>
          </Row>
          <Row spacing={1}>
            <Grid item xs={12}>
              <FormCheckbox
                name="optionUseDurationFull"
                label={t('form.fullUse')}
                testId="clearance-option-drawer-duration-full"
              />
            </Grid>
          </Row>
        </>
      )}
    </SecuredForm>
  );
};

ClearanceOptionDrawer.displayName = 'ClearanceOptionDrawer';

ClearanceOptionDrawer.propTypes = {
  name: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  payload: PropTypes.shape({
    divisionId: PropTypes.number,
    option: PropTypes.object,
    usageOptions: PropTypes.array,
  }).isRequired,
  currencyInfo: PropTypes.object,
  rightsPresets: PropTypes.arrayOf(PropTypes.object),
  divisionId: PropTypes.number,
  createClearanceOption: PropTypes.func.isRequired,
  updateClearanceOption: PropTypes.func.isRequired,
  formId: PropTypes.string.isRequired,
  savedValues: PropTypes.func.isRequired,
  rights: PropTypes.shape({}),
};

const Drawer = ({ open, onClose, clearForms, ...props }) => {
  const handleClose = useCallback(async () => {
    await clearForms();
    onClose();
  }, [clearForms, onClose]);

  return (
    <BaseDrawer open={open} onClose={handleClose}>
      <ClearanceOptionDrawer onClose={handleClose} {...props} />
    </BaseDrawer>
  );
};

Drawer.propTypes = ClearanceOptionDrawer;

export default enhancer(Drawer);
