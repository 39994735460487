import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
  root: {
    '& input[readOnly]': {
      padding: theme.spacing(1.25, 0),
    },
  },
  licensorCheckbox: {
    padding: theme.spacing(0.5),
  },
}));

export default styles;
