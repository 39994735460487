/* eslint-disable import/no-cycle */
import { useState } from 'react';
import classnames from 'classnames';
import ButtonBase from '@material-ui/core/ButtonBase';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Popover from '@material-ui/core/Popover';
import { withStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import { USER_MENU_HELP } from 'components/UserMenu';
import styles from './styles';

/**
 * @param {Element} element
 * @description Receives an element an checks if it is the Help button or a child node of the Help button
 * @returns {Boolean}
 */
const isHelp = (element) => element && (element.id === USER_MENU_HELP || isHelp(element.parentElement));

const Topbar = (props) => {
  const {
    brand,
    onLogoClick,
    slot,
    userMenu,
    profileAvatar,
    noMargin,
    classes,
    setMenuOpen,
    isMenuOpen,
  } = props;

  const [anchorElUser, setAnchorElUser] = useState(null);

  const openPopoverUser = ({ currentTarget }) => {
    setAnchorElUser(currentTarget);
  };

  const closePopoverUser = () => {
    setAnchorElUser(null);
  };

  const openUser = Boolean(anchorElUser);

  return (
    <Grid
      id="topbar"
      container
      className={classnames(classes.header, {
        [classes.headerMargin]: !noMargin,
      })}
      justify="space-between"
      alignItems="center"
      data-testid="wrapper"
      onClick={() => setMenuOpen && setMenuOpen(false)}
    >
      {setMenuOpen && (
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            setMenuOpen(!isMenuOpen);
          }}
          className={classes.menuButton}
          data-testid="toggle-vertical-menu-button"
        >
          <MenuIcon size="inherit" />
        </IconButton>
      )}
      <Grid
        item
        xs={2}
        className={classnames(classes.qwireLogo, {
          [classes.qwireLogoMargin]: !setMenuOpen,
        })}
        onClick={onLogoClick}
        data-testid="logo"
      >
        {brand || null}
      </Grid>
      <Grid container item xs className={classes.content} alignItems="center" justify="flex-end">
        <Grid container item xs className={classes.slot}>
          {slot}
        </Grid>
        {profileAvatar ? (
          <ButtonBase className={classes.profileAvatarButton} onClick={openPopoverUser}>
            <Grid container justify="flex-end">
              {profileAvatar}
            </Grid>
          </ButtonBase>
        ) : null}
        <Popover
          classes={{ paper: classes.popoverColor }}
          open={openUser}
          anchorEl={anchorElUser}
          onClick={(e) => isHelp(e.target) && closePopoverUser()}
          onClose={closePopoverUser}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          {userMenu}
        </Popover>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(Topbar);
