/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import List from '@mui/material/List';
import Grid from '@material-ui/core/Grid';
import { Text } from 'components/styleguide';
import { useTranslation } from 'react-i18next';
import ListItem from './ListItem';

const ListContainer = ({ classes, list, title, navigate, hasImage, hideStatus, onDelete }) => {
  const { t } = useTranslation();

  return (
    <Grid item className={classes.box}>
      <Grid container>
        <div className={classes.title}>
          <Text variant="h5" color="textPrimary">
            {t(title)}
          </Text>
        </div>
        <Grid container item className={classes.listContainer}>
          <List className={classes.body} role="listbox">
            {list.map((row, index) => (
              <ListItem
                alignItems="flex-start"
                index={index}
                key={row.id}
                row={row}
                testId={index}
                hideStatus={hideStatus}
                hasImage={hasImage}
                navigate={navigate}
                className={classes.row}
                onDelete={onDelete}
              />
            ))}
          </List>
        </Grid>
      </Grid>
    </Grid>
  );
};

ListContainer.propTypes = {
  classes: PropTypes.object.isRequired,
  onDelete: PropTypes.func,
  navigate: PropTypes.func.isRequired,
  list: PropTypes.array,
  hasImage: PropTypes.bool,
  hideStatus: PropTypes.bool,
  title: PropTypes.string.isRequired,
};

ListContainer.defaultProps = {
  list: [],
};

export default ListContainer;
