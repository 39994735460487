import { move } from 'ramda';
import { baseApi } from 'services';

export type TClearanceStatusRequest = {
  withAll?: boolean;
  divisionId?: number;
  onlyAllowedByUser?: boolean;
};

export type TClearanceStatus = {
  id: number;
  precedence: number;
  listingOrder: number;
  isParent: boolean;
  parentId?: number;
  label: string;
  color: string;
  clearanceStatuses?: any[];
  divisionId?: number;
};

export type TMoveClearanceStatus = {
  divisionId: number;
  parentId: number;
  movedId: number;
  targetId: number;
};

const oneDayCache = 60 * 60 * 24;

export const clearanceStatusApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    createClearanceStatus: builder.mutation<Partial<TClearanceStatus>, Partial<TClearanceStatus>>({
      query: (body) => ({ url: 'clear/clearance-status/create', method: 'POST', body }),
      invalidatesTags: ['clearanceStatusList'],
    }),
    updateClearanceStatus: builder.mutation<Partial<TClearanceStatus>, Partial<TClearanceStatus>>({
      query: (body) => ({ url: 'clear/clearance-status/update', method: 'POST', body }),
      invalidatesTags: ['clearanceStatusList'],
    }),
    moveClearanceStatus: builder.mutation<void, TMoveClearanceStatus>({
      query: (body) => ({ url: 'clear/clearance-status/move', method: 'POST', body }),
      async onQueryStarted(moveDto, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          clearanceStatusApi.util.updateQueryData(
            'getClearanceStatusList',
            { divisionId: moveDto.divisionId },
            (draft) => {
              const parent = draft.find((status) => status.id === moveDto.parentId);
              const from = parent.clearanceStatuses.findIndex((status) => status.id === moveDto.movedId);
              const to = parent.clearanceStatuses.findIndex((status) => status.id === moveDto.targetId);
              parent.clearanceStatuses = move(from, to, parent.clearanceStatuses);
            },
          ),
        );
        queryFulfilled.catch(patchResult.undo);
      },
    }),
    getClearanceStatusList: builder.query<TClearanceStatus[], TClearanceStatusRequest>({
      query: (body) => ({ url: 'clear/clearance-status/get-all', method: 'POST', body }),
      keepUnusedDataFor: oneDayCache,
      providesTags: ['clearanceStatusList'],
      transformResponse: (clearanceStatusList: TClearanceStatus[], _, arg) => {
        const statuses = clearanceStatusList.flatMap((status) => {
          if (!status.clearanceStatuses) return status;
          return [status, ...status.clearanceStatuses];
        });
        if (arg.withAll) {
          return [All, ...statuses];
        }
        return statuses;
      },
    }),
  }),
});

export const {
  useGetClearanceStatusListQuery,
  useCreateClearanceStatusMutation,
  useUpdateClearanceStatusMutation,
  useMoveClearanceStatusMutation,
} = clearanceStatusApi;

export const All: TClearanceStatus = {
  id: -1,
  label: 'All',
  listingOrder: 0,
  precedence: 0,
  isParent: false,
  color: 'transparent',
};

export const New: TClearanceStatus = {
  id: 1,
  label: 'New',
  listingOrder: 1,
  precedence: 5,
  isParent: true,
  color: '#616161',
};

export const Cleared: TClearanceStatus = {
  id: 6,
  label: 'Cleared',
  listingOrder: 3,
  precedence: 7,
  isParent: true,
  color: '#0891e3',
};
