import { connect } from 'react-redux';
import { compose } from 'redux';
import { fetchGetTrack } from 'redux-core/tracks/actions';
import { getSelectedLettersForStep3 } from 'redux-core/letters/selectors';
import { openDrawer } from 'redux-core/dialogs/actions';
import withBaseDrawer from 'components/common/Drawers/withBaseDrawer';

const mapStateToProps = (state) => ({
  letters: getSelectedLettersForStep3(state),
});

const mapDispatchToProps = {
  fetchGetTrack,
  openDrawer,
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withBaseDrawer);
