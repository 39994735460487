import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: ({ expanded }) => ({
    transition: `transform ${theme.transitions.duration.standard} ease`,
    transform: expanded ? 'rotate(0deg)' : 'rotate(-90deg)',
  }),
}));

export default useStyles;
