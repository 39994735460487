import React from 'react';

// eslint-disable-next-line consistent-return
const format = (arr, message) => {
  if (arr.length > 0) {
    return (
      <>
        <p>{message}</p>
        {arr.map((m) => (
          <p>&emsp;{m}</p>
        ))}
      </>
    );
  }
};

export const formatValidationMessage = ({
  t,
  divisionErrors,
  productionErrors,
  cuesWithoutDuration,
  cuesWithoutUsage,
  tracksWithoutRightsOwnerRole,
  tracksWithInvalidShares,
  tracksWithInvalidChars,
  tracksWithoutWriterOrPublisher,
}) => (
  <>
    {t('rapidCueFailedValidationMessage')}
    <hr />
    {format(divisionErrors, t('rapidCueFailedValidationDivision'))}
    {format(productionErrors, t('rapidCueFailedValidationProduction'))}
    {format(cuesWithoutDuration, t('rapidCueFailedValidationWithoutDuration'))}
    {format(cuesWithoutUsage, t('rapidCueFailedValidationWithoutUsage'))}
    {format(tracksWithoutRightsOwnerRole, t('rapidCueFailedValidationWithoutRole'))}
    {format(tracksWithInvalidShares, t('rapidCueFailedValidationWithInvalidShares'))}
    {tracksWithInvalidChars}
    {format(tracksWithoutWriterOrPublisher, t('rapidCueFailedValidationWithoutPublisherOrWriter'))}
  </>
);
