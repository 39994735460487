import { baseApi, TPagination, TQwireAccessToken } from 'services';

export type TProject = {
  id?: number;
  tenantId?: number;
  name?: string;
  mpmNumber?: number;
  projectNumberMarketing?: string;
  filmSynopsis?: string;
  filmAbbreviation?: string;
  notes?: string;
  archived?: boolean;
  companyNumber?: string;
  description?: string;
  animated?: boolean;
  versionId?: number;
  categoryId?: number;
  isanId?: string;
  eidrContentId?: string;
  additionalData?: unknown;
  commonLanguages?: string;
  languageId?: number;
  internalProdId?: string;
  internalProdIdForMarketing?: string;
  poster?: unknown;
  posterId?: number;
  entityVersion?: number;
};

type TRequestPayload = TQwireAccessToken & {
  term: string;
  includeProject?: number;
};

type TResponsePayload = {
  items: TProject[];
};

export type TGetProjectsAllowedByUserResponse = {
  data: Array<{ id: number; name: string }>;
  pagination: TPagination;
};

export const projectsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    searchProjects: builder.query<TResponsePayload, TRequestPayload>({
      query: (body) => ({
        url: 'common/projects/search',
        method: 'POST',
        body,
      }),
    }),
    getProjectsAllowedByUser: builder.query<TGetProjectsAllowedByUserResponse, { searchTerm?: string }>({
      query: (body) => ({
        url: 'common/projects/get-all-allowed-by-user',
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const { useLazySearchProjectsQuery, useSearchProjectsQuery, useGetProjectsAllowedByUserQuery } = projectsApi;
