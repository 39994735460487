/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-children-prop */
/* eslint-disable import/no-cycle */
import React from 'react';
import CancelIcon from '@material-ui/icons/Close';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import path from 'ramda/src/path';
import prop from 'ramda/src/prop';
import { IconButton } from '@mui/material';

export const DropdownIndicator = (props) => {
  const {
    allowEmpty,
    isDropdown,
    isOpen,
    showCaret,
    indicatorTabIndex: tabIndex,
  } = path(['selectProps', 'extraProps'], props);
  const variant = path(['selectProps', 'variant'], props);
  const hasValue = prop('hasValue', props);
  const { isDisabled } = props;
  /** Only show Clear icon when Dropdown has a selected value and allowEmpty */
  if (allowEmpty && hasValue) return null;

  return isDropdown || (showCaret && !isOpen) ? (
    <IconButton
      size="small"
      disabled={isDisabled}
      variant={variant}
      tabIndex={tabIndex}
      data-testId="button-arrow-icon"
    >
      <ArrowDropDownIcon color={isDisabled ? 'disabled' : 'inherit'} style={{ height: '0.9em' }} />
    </IconButton>
  ) : null;
};
export const IndicatorSeparator = () => null;

export const ClearIndicator = (props) => {
  const variant = path(['selectProps', 'variant'], props);
  const { isOpen, showCaret, indicatorTabIndex: tabIndex } = path(['selectProps', 'extraProps'], props);

  // The X button to remove the selected value will only be hidden if
  // the menu is closed and the typeahead has the caret icon visible.
  if (!isOpen && showCaret) return null;

  const {
    innerProps: { ref, ...restInnerProps },
  } = props;

  return (
    <div {...restInnerProps} ref={ref}>
      <IconButton
        variant={variant}
        children={<CancelIcon style={{ height: '0.9em' }} />}
        data-testid="button-cancel-icon"
        tabIndex={tabIndex}
      />
    </div>
  );
};
