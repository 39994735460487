import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { buttonSizes } from 'components/styleguide/Button';
import { ConfirmCancel } from './ConfirmCancel';
import { MultiOption } from './MultiOption';
import useStyles from './styles';
import enhancer, { ReduxProps } from './enhancer';

type CommonProps = {
  content: string | React.ReactNode;
  title?: string;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg';
  open: boolean;
  withoutButtons?: boolean;
  buttonSize?: keyof typeof buttonSizes;
  onClose: (...args: unknown[] | []) => void;
  onConfirm: (...args: unknown[] | []) => void | Promise<void>;
};

type OnlyOkProps = CommonProps & {
  onlyOk: true;
  multiOk?: never;
  okVariant?: 'primary' | 'secondary';
  okLabel: string;
  cancelLabel?: never;
  onCancel?: never;
  firstOptionLabel?: never;
  secondOptionLabel?: never;
  firstOptionConfirm?: never;
  secondOptionConfirm?: never;
};

type MultiOkProps = CommonProps & {
  multiOk: true;
  onlyOk?: never;
  okVariant?: 'primary' | 'secondary';
  firstOptionLabel: string;
  secondOptionLabel: string;
  firstOptionConfirm: () => void | Promise<void>;
  secondOptionConfirm: () => void | Promise<void>;
  okLabel?: never;
  cancelLabel?: never;
  onCancel?: never;
};

type NeitherOkProps = CommonProps & {
  onlyOk?: false;
  multiOk?: false;
  okVariant?: 'primary' | 'secondary';
  okLabel: string;
  cancelLabel: string;
  onCancel: (...args: unknown[] | []) => void;
  firstOptionLabel?: never;
  secondOptionLabel?: never;
  firstOptionConfirm?: never;
  secondOptionConfirm?: never;
};

export type ConfirmationDialogProps = OnlyOkProps | MultiOkProps | NeitherOkProps;

function ConfirmationDialog({
  content,
  title,
  open,
  okLabel,
  okVariant,
  cancelLabel,
  maxWidth,
  onlyOk,
  multiOk,
  firstOptionLabel,
  secondOptionLabel,
  buttonSize,
  withoutButtons,
  isDialogOpen,
  onClose,
  onConfirm,
  firstOptionConfirm,
  secondOptionConfirm,
  onCancel,
}: ConfirmationDialogProps & ReduxProps) {
  const { t } = useTranslation();
  const classes = useStyles();
  const contentChildren = content || t('global.confirmDialog.message');

  const defaultMaxWidth = 'xs';
  const defaultOkVariant = 'primary';

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth={maxWidth || defaultMaxWidth}
      className={classnames({
        [classes.noOverlay]: isDialogOpen,
      })}
    >
      <DialogTitle id="alert-dialog-title" data-testid="alert-dialog-title">
        {title ? t(title) : null}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" data-testid="alert-dialog-description">
          {contentChildren}
        </DialogContentText>
      </DialogContent>
      {!withoutButtons && (
        <DialogActions disableSpacing sx={{ display: 'flex', gap: '1rem', px: '1.5rem', pb: '1.5rem' }}>
          {!multiOk && (
            <ConfirmCancel
              onlyOk={onlyOk}
              onClose={onClose}
              cancelLabel={cancelLabel}
              t={t}
              onConfirm={onConfirm}
              okVariant={okVariant || defaultOkVariant}
              okLabel={okLabel}
              onCancel={onCancel}
            />
          )}
          {multiOk && (
            <MultiOption
              firstOptionLabel={firstOptionLabel}
              secondOptionLabel={secondOptionLabel}
              firstOptionConfirm={firstOptionConfirm}
              secondOptionConfirm={secondOptionConfirm}
              okVariant={okVariant || defaultOkVariant}
              buttonSize={buttonSize}
            />
          )}
        </DialogActions>
      )}
    </Dialog>
  );
}

const EnhancedConfirmationDialog: React.FC<ConfirmationDialogProps> = enhancer(ConfirmationDialog);

export default EnhancedConfirmationDialog;
