/* eslint-disable consistent-return */
import compose from 'ramda/src/compose';
import cond from 'ramda/src/cond';
import equals from 'ramda/src/equals';
import always from 'ramda/src/always';
import last from 'ramda/src/last';
import toLower from 'ramda/src/toLower';
import includes from 'ramda/src/includes';
import prop from 'ramda/src/prop';
import propOr from 'ramda/src/propOr';
import propEq from 'ramda/src/propEq';
import find from 'ramda/src/find';
import T from 'ramda/src/T';
import __ from 'ramda/src/__';
import { firstLetterToLowercase } from 'utils/object';

import { PRODUCTION_TYPES, NORMALIZED_PRODUCTION_TYPES, PROJECT_VIEW_MODES } from './constants';
import { getBreadcrumbString } from './format';

/**
 * There's a difference on the first character case
 * in between the value stored in the PRODUCTION_TYPE enum
 * and the value received inside the path[] from the BE
 *
 * This function helps normalize such value
 */
const typeToLower = compose(firstLetterToLowercase, prop('type'));

export class ProductionPath {
  constructor(path) {
    this.path = path;
  }

  getProjectId = () => propOr('', 'id')(find(propEq('type', 'Project'))(this.path));

  /**
   * Returns a path.id given a path.type
   */
  getProductionId = () => propOr('', 'id', last(this.path));

  toString = (joinBy) => getBreadcrumbString(this.path, joinBy);

  /**
   * Returns the path object filtered by type
   * @return {{ id: number, name: string, type: PRODUCTION_TYPE }}
   */
  getPathByType = (expectedType) => {
    const result = this.path.find((segment) => {
      const currentType = typeToLower(segment);
      return currentType === expectedType;
    });
    if (!result) return;
    return result;
  };

  /**
   * Returns the PRODUCTION_TYPE string in the path
   * that matches the first from the list of passed Available Types
   * @return {string} PRODUCTION_TYPE
   */
  getProductionType = (
    availableTypes = [PRODUCTION_TYPES.FILM_RELEASE, PRODUCTION_TYPES.EPISODE, PRODUCTION_TYPES.CAMPAIGN],
  ) => {
    const result = this.path.find((segment) => {
      const type = typeToLower(segment);
      return includes(type, availableTypes);
    });
    if (!result) return '';
    return firstLetterToLowercase(result.type);
  };
}

/**
 * List of possible responses on each notification[].body.productionType
 * Took from authorization/src/common/dtos/production-type.ts
 */
const API_PRODUCTION_TYPE = {
  [PRODUCTION_TYPES.EPISODE]: [
    'series',
    'series_season',
    'series_release',
    'series_episode',
    'series_qcuecue',
    'series_qcleartrack',
    'series_rightsowner',
  ],
  [PRODUCTION_TYPES.RELEASE]: ['movie', 'movie_release', 'movie_qcuecue', 'movie_qcleartrack', 'movie_rightsowner'],
  [PRODUCTION_TYPES.CAMPAIGN]: ['campaign_campaign', 'campaign_qcleartrack', 'campaign_rightsowner'],
};

/**
 * @param {string} productionType - a given productionType string
 * @return {string} - NORMALIZED_PRODUCTION_TYPES
 */
export const getNormalizedProductionType = compose(
  cond([
    [equals(PRODUCTION_TYPES.EPISODE), always(NORMALIZED_PRODUCTION_TYPES.TV)],
    [equals(PRODUCTION_TYPES.RELEASE), always(NORMALIZED_PRODUCTION_TYPES.FEATURES)],
    [equals(PRODUCTION_TYPES.CAMPAIGN), always(NORMALIZED_PRODUCTION_TYPES.MKT)],
    // @TODO Remove below checks once the API normalizes it's responses
    // returning 'productionType' as either 'episode', 'release' or 'campaign'
    [includes(__, API_PRODUCTION_TYPE[PRODUCTION_TYPES.EPISODE]), always(NORMALIZED_PRODUCTION_TYPES.TV)],
    [includes(__, API_PRODUCTION_TYPE[PRODUCTION_TYPES.RELEASE]), always(NORMALIZED_PRODUCTION_TYPES.FEATURES)],
    [includes(__, API_PRODUCTION_TYPE[PRODUCTION_TYPES.CAMPAIGN]), always(NORMALIZED_PRODUCTION_TYPES.MKT)],
    [T, always(NORMALIZED_PRODUCTION_TYPES.FEATURES)],
  ]),
  toLower,
);

/**
 * @param {string} productionType - a given productionType string
 * @return {string} - PROJECT_VIEW_MODES
 */
export const getProductionViewModeByProductionType = compose(
  cond([
    [equals(PRODUCTION_TYPES.EPISODE), always(PROJECT_VIEW_MODES.SCENES)],
    [equals(PRODUCTION_TYPES.RELEASE), always(PROJECT_VIEW_MODES.SCENES)],
    [equals(PRODUCTION_TYPES.CAMPAIGN), always(PROJECT_VIEW_MODES.ASSETS)],
    // @TODO Remove below checks once the API normalizes it's responses
    // returning 'productionType' as either 'episode', 'release' or 'campaign'
    [includes(__, API_PRODUCTION_TYPE[PRODUCTION_TYPES.EPISODE]), always(PROJECT_VIEW_MODES.SCENES)],
    [includes(__, API_PRODUCTION_TYPE[PRODUCTION_TYPES.RELEASE]), always(PROJECT_VIEW_MODES.SCENES)],
    [includes(__, API_PRODUCTION_TYPE[PRODUCTION_TYPES.CAMPAIGN]), always(PROJECT_VIEW_MODES.ASSETS)],
    [T, always(PROJECT_VIEW_MODES.SCENES)],
  ]),
  toLower,
);

/**
 * @param {'series_cuesheet' | 'movie_cuesheet' | 'campaign_cuesheet'} cueSheetType
 * @returns {'features' | 'marketing' | 'television'}
 */
export const getProductionTypeFromCueSheetType = (cueSheetType) =>
  ({
    series_cuesheet: NORMALIZED_PRODUCTION_TYPES.TV,
    movie_cuesheet: NORMALIZED_PRODUCTION_TYPES.FEATURES,
    campaign_cuesheet: NORMALIZED_PRODUCTION_TYPES.MKT,
  }[cueSheetType]);
