import { Grid, Typography } from '@mui/material';
import { TPartnerVerification } from 'services/common/users';
import { rootTranslation } from 'utils/format';

const t = rootTranslation('apmSignup');

export type TPartnerInformationProps = {
  partnerInfo: TPartnerVerification;
};

export const PartnerInformation = ({ partnerInfo }) => (
  <>
    <Grid item xs={3}>
      <Typography variant="body1">{t('firstName')}</Typography>
    </Grid>
    <Grid item xs={9}>
      <Typography variant="body1" fontWeight="bold">
        {partnerInfo.firstName}
      </Typography>
    </Grid>
    <Grid item xs={3}>
      <Typography variant="body1">{t('lastName')}</Typography>
    </Grid>
    <Grid item xs={9}>
      <Typography variant="body1" fontWeight="bold">
        {partnerInfo.lastName}
      </Typography>
    </Grid>
    <Grid item xs={3}>
      <Typography variant="body1">{t('email')}</Typography>
    </Grid>
    <Grid item xs={9} pb={2}>
      <Typography variant="body1" fontWeight="bold">
        {partnerInfo.email}
      </Typography>
    </Grid>
  </>
);
