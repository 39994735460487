/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable import/no-cycle */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { TypeAhead } from 'components/styleguide';
import { useSecuredDivisions } from 'utils/hooks';
import propEq from 'ramda/src/propEq';
import enhancer from './enhancer';

function DivisionDropdown({ divisions, placeholder, onChange, value, loading, objectId, ...permissions }) {
  const securedDivisions = useSecuredDivisions(permissions);
  const selectedDivision = divisions || securedDivisions;

  useEffect(
    () => {
      /**
       * If no prop.placeholder and no value is passed, set the first \
       * division as the default value
       */
      if (selectedDivision.length && !placeholder && (!value || !selectedDivision.some(propEq('id', value)))) {
        onChange(selectedDivision[0]);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedDivision, objectId],
  );

  const changeDivision = (divisionId) => {
    const division = selectedDivision.find(({ id }) => id === divisionId);
    onChange(division);
  };

  return (
    <TypeAhead
      isClearable={false}
      loading={loading}
      options={selectedDivision}
      onChange={changeDivision}
      placeholder={placeholder}
      value={value}
      testId="division-dropdown"
    />
  );
}

DivisionDropdown.defaultProps = {
  name: 'division',
};

DivisionDropdown.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  divisions: PropTypes.array,
  getAllDivisions: PropTypes.func.isRequired,
  label: PropTypes.string,
};

export default enhancer(DivisionDropdown);
