import { connect } from 'react-redux';
import { updateTrack } from 'redux-core/tracks/actions';
import { getTenantId } from 'redux-core/productions/selectors';

const mapStateToProps = (state) => ({
  tenantId: getTenantId(state),
});

const mapDispatchToProps = {
  updateTrack,
};

export default connect(mapStateToProps, mapDispatchToProps);
