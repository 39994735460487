import { Skeleton } from '@material-ui/lab';
import { Box } from '@mui/system';
import { FormTypeAhead } from 'components/common/Form/Fields';
import { TClearanceStatus, useGetClearanceStatusListQuery } from 'services/clear/clearance-status';
import newTheme from '../newTheme';

export type LicensorClearanceStatusProps = {
  readOnly?: boolean;
  divisionId?: number;
  disabled?: boolean;
  testId?: string;
  placeholder?: string;
  label?: string;
  name: string;
  allowEmpty?: boolean;
  showAllOption?: boolean;
  showParentOnly?: boolean;
};

export const LicensorClearanceStatus = ({
  readOnly,
  divisionId,
  disabled,
  testId,
  label,
  name,
  placeholder,
  allowEmpty,
  showAllOption,
  showParentOnly,
}: LicensorClearanceStatusProps) => {
  const { data: clearanceStatusList, isLoading } = useGetClearanceStatusListQuery({
    divisionId,
    withAll: showAllOption,
  });

  if (isLoading) {
    return <Skeleton />;
  }

  const availableStatuses = clearanceStatusList.filter((status) =>
    !showParentOnly ? status : status.parentId === null || status.id < 0,
  );

  const optionLabelFormater = (option: TClearanceStatus) => (
    <Box sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
      <Box
        sx={{
          width: '1rem',
          height: '1rem',
          backgroundColor: option.color,
          borderWidth: '2px',
          borderStyle: 'solid',
          borderColor: option.color === 'transparent' ? newTheme.palette.grey[500] : option.color,
          borderRadius: '50%',
          flexShrink: 0,
        }}
      />
      {option.label}
    </Box>
  );

  return (
    <FormTypeAhead
      readOnly={readOnly}
      allowEmpty={allowEmpty}
      labelKey="label"
      disabled={disabled}
      label={label}
      name={name}
      placeholder={placeholder}
      options={availableStatuses}
      formatOptionLabel={optionLabelFormater}
      testId={testId}

    />
  );
};
