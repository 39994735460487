/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-children-prop */
import React, { useState, useEffect } from 'react';
import { Provider } from 'jotai';
import { HydrateAtoms } from 'atomUtils';
import PropTypes from 'prop-types';
import { DrawerHeader, Button, Loading } from 'components/styleguide';
import { Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import mergeDeepLeft from 'ramda/src/mergeDeepLeft';
import EmptyHint from 'components/common/Drawers/AddTrackDrawer/EmptyHint';
import TracksFilter from 'components/common/Drawers/AddTrackDrawer/TracksFilter';
import { TrackListTable } from 'components/common/Drawers/AddTrackDrawer/TracksList/table/TrackListTable';
import { useSearchTracksQuery } from 'services/tracks/tracks';
import find from 'ramda/src/find';
import propEq from 'ramda/src/propEq';
import { addTrackDrawerStoreAtom, useAddTrackDrawerFilters } from '../AddTrackDrawer/addTrackDrawerStore';
import { TrackSummary } from '../AddTrackDrawer/TracksList/table/TrackSummary';
import enhancer from './enhancer';

const CueAdvancedSearchDrawer = ({
  getProductionUseInfo,
  onClose,
  openPromptAsync,
  payload: { onSubmit, hasRightsOwners },
}) => {
  const { t } = useTranslation();
  const [selectedTrackId, setSelectedTrackId] = useState();
  const addTrackDrawerFilters = useAddTrackDrawerFilters();

  useEffect(() => {
    getProductionUseInfo();
  }, [getProductionUseInfo]);

  const { data: tracksInfo, isLoading: loading } = useSearchTracksQuery(
    { ...addTrackDrawerFilters.searchQuery, page: addTrackDrawerFilters.page + 1, limit: addTrackDrawerFilters.limit },
    { skip: isEmpty(addTrackDrawerFilters.searchQuery) },
  );

  useEffect(() => {
    setSelectedTrackId(undefined);
  }, [addTrackDrawerFilters]);

  const handleOnSubmit = async () => {
    if (hasRightsOwners) {
      await openPromptAsync({
        content: t('drawers.cueAdvancedSearch.warningMessage'),
      });
    }
    await onSubmit(selectedTrackId, find(propEq('id', selectedTrackId))(tracksInfo.data));
    onClose();
  };

  return (
    <>
      <DrawerHeader hideBackButton title={t('drawers.cueAdvancedSearch.drawerTitle')}>
        <Button
          children={t('global.cancel')}
          onClick={onClose}
          size="xs"
          testId="cue-advanced-search-cancel"
          type="button"
          variant="destructive"
        />
        <Button
          children={t('global.select')}
          disabled={!selectedTrackId}
          onClick={handleOnSubmit}
          size="xs"
          variant="primary"
        />
      </DrawerHeader>
      <TracksFilter filter={addTrackDrawerFilters?.searchQuery} />
      <Box>
        {!loading && tracksInfo.data.length > 0 && (
          <>
            <TrackSummary
              searchQuery={addTrackDrawerFilters?.searchQuery}
              totalCount={tracksInfo?.pagination?.totalCount}
            />
            <Box sx={{ mb: 6 }}>
              <TrackListTable
                loading={loading}
                onRowClick={({ id }) => setSelectedTrackId(id)}
                tracks={tracksInfo?.data}
                hideAddTrackButton
                pagination={tracksInfo?.pagination}
              />
            </Box>
          </>
        )}
        {!tracksInfo?.data.length && !loading && <EmptyHint />}
      </Box>
      {loading && <Loading />}
    </>
  );
};

CueAdvancedSearchDrawer.propTypes = {
  payload: PropTypes.shape({
    filter: PropTypes.shape({}),
    onSubmit: PropTypes.func,
    hasRightsOwners: PropTypes.bool,
    track: PropTypes.shape({
      title: PropTypes.string,
      type: PropTypes.string,
    }),
  }),
  getProductionUseInfo: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  openPromptAsync: PropTypes.func.isRequired,
};

function CueAdvancedSearchDrawerContainer({ payload, ...rest }) {
  const types = payload.track?.type === 'library' ? { library: true, sound_effect: true } : { song: true };
  const initialSearchQuery = mergeDeepLeft(payload?.filter || { filters: { titleTerm: payload.track?.title } }, {
    types,
  });

  return (
    <Provider>
      <HydrateAtoms
        initialValues={[[addTrackDrawerStoreAtom, { cueContainerId: null, searchQuery: initialSearchQuery }]]}
      >
        <CueAdvancedSearchDrawer payload={payload} {...rest} />
      </HydrateAtoms>
    </Provider>
  );
}

export default enhancer(CueAdvancedSearchDrawerContainer);
