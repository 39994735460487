/* eslint-disable no-param-reassign */
/* eslint-disable default-param-last */
/* eslint-disable no-restricted-globals */
import i18next from 'i18next';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import compose from 'ramda/src/compose';
import isNil from 'ramda/src/isNil';
import is from 'ramda/src/is';
import head from 'ramda/src/head';
import concat from 'ramda/src/concat';
import last from 'ramda/src/last';
import curry from 'ramda/src/curry';
import join from 'ramda/src/join';
import map from 'ramda/src/map';
import propOr from 'ramda/src/propOr';
import trim from 'ramda/src/trim';
import i18n from 'locales/i18n';

dayjs.extend(customParseFormat);

export function formatNumber(number, fractionDigits = 0, minimumFractionDigits = 0) {
  if (typeof number !== 'number') return '';

  return number.toLocaleString(i18next.language, {
    maximumFractionDigits: fractionDigits,
    minimumFractionDigits: minimumFractionDigits || fractionDigits,
  });
}

export const formatCurrency = (number, currency = 'USD', fractionDigits = 2, lng = i18next.language) => {
  if (isNil(number) || isNaN(number)) return '--';
  return number.toLocaleString(lng, {
    style: 'currency',
    currency,
    maximumFractionDigits: fractionDigits,
    minimumFractionDigits: fractionDigits,
  });
};

/**
 * Return either decimal or group separator
 * @param {string} locale
 * @param {'decimal' | 'group' | 'fraction' | 'integer'} separatorType
 * @see https://stackoverflow.com/a/51411310
 */
export function getSeparator(locale, separatorType) {
  const numberWithGroupAndDecimalSeparator = 10000.1;
  return Intl.NumberFormat(locale)
    .formatToParts(numberWithGroupAndDecimalSeparator)
    .find((part) => part.type === separatorType).value;
}

export const formatTime = (value, toFormat, fromFormat = 'HH:mm:ss') => {
  if (!value) return '';
  return dayjs(value, fromFormat).format(toFormat);
};

export const getLocalDate = (date, toFormat = 'L') => (isNil(date) ? '--' : dayjs(date).format(toFormat));

/* File sizes format */
export const formatSize = (size) => {
  const BYTES_IN_KILOBYTE = 1000;
  const BYTES_IN_MEGABYTE = 1e6;

  if (size > BYTES_IN_KILOBYTE) {
    return size > BYTES_IN_MEGABYTE
      ? `${(size / BYTES_IN_MEGABYTE).toFixed(0)} mb`
      : `${(size / BYTES_IN_KILOBYTE).toFixed(0)} kb`;
  }
  return `${size}b`;
};

export const getInitials = (fullName) => {
  const arr = fullName.split(' ');
  if (arr.length === 1) {
    return head(fullName);
  }

  return concat(head(head(arr)), head(last(arr)));
};

export const toCamelcase = (string) => {
  string = string
    .toLowerCase()
    .replace(/(?:(^.)|([-_\s]+.))/g, (match) => match.charAt(match.length - 1).toUpperCase());
  return string.charAt(0).toLowerCase() + string.substring(1);
};

/**
 * Joins an array of objects into a Breadcrumb Style string
 * @params {array} path
 * @returns {string}
 */
export const getBreadcrumbString = curry((array, joinBy = ' - ') =>
  is(Array, array) ? compose(join(joinBy), map(compose(trim, propOr('', 'name'))))(array) : '',
);

export const TIME_FORMATS = {
  HHMM: 'HH:mm',
  HHMMSS: 'HH:mm:ss',
  MMSS: 'mm:ss',
};

/** Same as useRootTranslation but not as a hook */
export const rootTranslation = (rootT) => (s, params) => i18n.t(`${rootT}.${s}`, params);

/**
 * Converts a value given an exchange rate
 * @param {Number} value Origin value
 * @param {Number} exchangeRate Exchange rate
 * @param {Number} decimalScale Expected decimalScale
 */
export const convertCurrency = (value = 0, exchangeRate = 1, decimalScale) =>
  Number((value * exchangeRate).toFixed(decimalScale));

/**
 * Converts a value back to its original value given an exchange rate
 * @param {Number} value Origin value
 * @param {Number} exchangeRate Exchange rate
 * @param {Number} decimalScale Expected decimalScale
 */
export const convertCurrencyBack = (value, exchangeRate = 1, decimalScale) =>
  convertCurrency(value, 1 / exchangeRate, decimalScale);

export const usageChipLabel = (use, type) => {
  if (type && use) {
    return `${type} / ${use}`;
  }
  return type || use || '';
};
