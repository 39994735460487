/* eslint-disable import/no-cycle */
import { UserMenu } from 'components/styleguide';
import AccountCircleOutlined from '@material-ui/icons/AccountCircleOutlined';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import React from 'react';

import { useRootTranslation } from 'utils/hooks';
import PropTypes from 'prop-types';
import ROUTES from 'components/Routes/routes';

import enhancer from './enhancer';

export const USER_MENU_HELP = 'user-menu-help';

const rootT = 'topbar.userMenu';

const UserMenuWrapper = ({ goToLink, openDocument360Tab, profileImage, user }) => {
  const t = useRootTranslation(rootT);

  const options = [
    {
      label: t('profile'),
      action: () => goToLink(ROUTES.USER_PROFILE),
      icon: <AccountCircleOutlined />,
    },
    {
      id: USER_MENU_HELP,
      label: t('help.title'),
      action: openDocument360Tab,
      icon: <HelpOutlineIcon />,
    },
    {
      label: t('logout'),
      action: () => goToLink(ROUTES.LOGOUT),
      icon: <ExitToAppIcon />,
    },
  ];

  return <UserMenu user={user} profileImage={profileImage} options={options} />;
};

UserMenuWrapper.propTypes = {
  goToLink: PropTypes.func.isRequired,
  openDocument360Tab: PropTypes.func.isRequired,
  profileImage: PropTypes.func.isRequired,
  user: PropTypes.shape({}),
};

export default enhancer(UserMenuWrapper);
