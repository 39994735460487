/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { InputCheckbox } from 'components/styleguide';
import { useTranslation } from 'react-i18next';
import { withFormField } from 'components/common/Form/utils';

const Input = ({ disabled, label, field, form, onChange, ...props }) => {
  const { isSubmitting, setFieldValue } = form;
  const { t } = useTranslation();
  const handleChange = onChange ? (e, value) => onChange(form, field, value) : (e, v) => setFieldValue(field.name, v);
  return (
    <InputCheckbox disabled={disabled || isSubmitting} label={t(label)} {...field} onChange={handleChange} {...props} />
  );
};

Input.displayName = 'InputCheckbox';

export default withFormField(Input, { type: 'disabled' });
