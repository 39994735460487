/* eslint-disable react/no-children-prop */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { DrawerHeader } from 'components/styleguide';
import { FormButton } from 'components/common/Form/Fields';
import { useFetch, useRootTranslation } from 'utils/hooks';
import * as Yup from 'yup';
import { yupRightPresetTerritories } from 'utils/forms';
import { SecuredRightsPresetPicker, SecuredForm } from 'components/common/Secured';
import { COMMON_ROLES, CLEAR_PERMISSIONS_GROUPS } from 'redux-core/permissions/constants';
import { getRequiredRights } from 'redux-core/rights/services';
import prop from 'ramda/src/prop';
import { RIGHTS_HINT_MESSAGE_TYPE } from 'components/common/Drawers/RightPresetDrawer/constants';
import enhancer from './enhancer';

const rootT = 'drawers.unassignedBin';

const validationSchema = Yup.object().shape({
  territories: yupRightPresetTerritories(),
});

const UnassignedBinDrawer = ({
  divisionId,
  formId,
  getCampaignAsset,
  name,
  onClose,
  savedValues,
  payload: { id },
  updateCampaignAsset,
  rights,
}) => {
  const t = useRootTranslation(rootT);

  const [asset, loading] = useFetch(() => id && getCampaignAsset({ id }), [id], {});

  const [requiredRightsRaw] = useFetch(
    () => id && !rights && getRequiredRights({ cueContainerId: id }),
    [id, rights],
    {},
  );

  const requiredRights = rights || requiredRightsRaw;

  const initialValues = {
    ...asset,
    ...savedValues,
    requiredRights,
    dirty: prop('dirty', requiredRights),
  };

  const handleSubmit = async (values) => {
    await updateCampaignAsset({ ...values, id }, asset);
    onClose();
  };

  const requiredPerms = {
    objectId: asset.objectId,
    permissions: COMMON_ROLES.EDITOR,
  };

  return (
    <SecuredForm
      drawerName={name}
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      {...requiredPerms}
      readOnly={loading}
    >
      <DrawerHeader title={t('edit')} onClose={onClose}>
        <FormButton
          alwaysEnabled
          children="global.forms.cancel"
          onClick={onClose}
          size="xs"
          testId="unassigned-drawer-cancel"
          variant="destructive"
        />
        <FormButton
          children="global.forms.save"
          size="xs"
          testId="unassigned-drawer-save"
          type="submit"
          variant="primary"
        />
      </DrawerHeader>
      <Grid container>
        <Grid item container xs={8}>
          <SecuredRightsPresetPicker
            divisionId={divisionId}
            formId={formId}
            rightsLabel={t('assetRights')}
            testId="unassigned-drawer-required-rights"
            required
            {...requiredPerms}
            clearPermissions={CLEAR_PERMISSIONS_GROUPS.RIGHTS}
            saveInDrawerName={name}
            hintType={RIGHTS_HINT_MESSAGE_TYPE.PRODUCTION}
            initialRights={requiredRights}
          />
        </Grid>
      </Grid>
    </SecuredForm>
  );
};

UnassignedBinDrawer.propTypes = {
  divisionId: PropTypes.number.isRequired,
  formId: PropTypes.string.isRequired,
  getCampaignAsset: PropTypes.func.isRequired,
  name: PropTypes.string,
  savedValues: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  payload: PropTypes.shape({ id: PropTypes.number }),
  updateCampaignAsset: PropTypes.func.isRequired,
  rights: PropTypes.shape({}),
  requiredRights: Yup.object().shape({
    name: Yup.string().required(),
    rights: Yup.array().required(),
  }),
};

export default enhancer(UnassignedBinDrawer);
