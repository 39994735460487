import { makeStyles } from '@material-ui/core/styles';
import { appColors } from 'components/styleguide';

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    display: 'flex',
    alignContent: 'space-between',
    border: '1px solid',
    borderColor: 'transparent',
    borderRadius: 3,
    cursor: 'pointer',
    padding: theme.spacing(1.625, 1, 1.625, 1.25),
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
    '&.selected': {
      backgroundColor: theme.palette.action.selected,
      borderColor: appColors.darkGreen,
    },
  },
}));

export default useStyles;
