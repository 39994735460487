/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import AlternativeImage from './AlternativeImage';
import ImageFromUrl from './ImageFromUrl';

const Image = (props) => {
  const { url } = props;
  return url ? <ImageFromUrl {...props} /> : <AlternativeImage {...props} />;
};

Image.propTypes = {
  url: PropTypes.string,
  onError: PropTypes.func,
  style: PropTypes.object,
  onClick: PropTypes.func,
  alt: PropTypes.string,
  avatar: PropTypes.bool,
  fallback: PropTypes.node,
};

export default Image;
