/* eslint-disable import/no-cycle */
import makeEntityServiceCalls, { makeServiceCall } from 'redux-core/serviceHelpers';

export const { update: updateLicensorCall } = makeEntityServiceCalls('licensors', 'clear');

// export const updateLicensorCall = makeServiceCall(
//   'licensors',
//   'update',
//   'clear'
// );

export const getAllLicensorsFromTrackCall = makeServiceCall('licensors', 'get-all-from-track', 'clear');

export const bulkUpdateLicensorCall = makeServiceCall('licensors', 'bulk-update', 'clear');
