/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import prop from 'ramda/src/prop';
import { createSelector } from 'reselect';
import * as formActions from 'redux-core/form/actions';

const getForms = (state) => state.form;

const getFormConnectedById = (formId) => createSelector(getForms, prop(formId));

/**
 * Higher Order Component designed to smooth the process of
 * sharing form values using redux as a context pivot
 *
 * By passing a props.formId in the enhancer, the WrappedComponent
 * will be connected and listening to the form values stored in the
 * store.form[formId] key of the store and it'll be provided with a
 * set of utility functions to save/update/remove any form values
 * as well as the form.dirty or form.isEditing state of a given form
 */
function withFormConnected(WrappedComponent, { formId } = {}) {
  const Component = (props) => <WrappedComponent formId={formId} {...props} />;

  Component.propTypes = {
    saveForm: PropTypes.func.isRequired,
    saveValues: PropTypes.func.isRequired,
    updateValue: PropTypes.func.isRequired,
    clearForm: PropTypes.func.isRequired,
    clearForms: PropTypes.func.isRequired,
  };

  const mapStateToProps = (state) => {
    const form = getFormConnectedById(formId)(state) || {};

    return {
      savedValues: form.values || {},
      isEditing: form.isEditing || false,
      dirty: form.dirty || false,
    };
  };

  /**
   * mapDispatchToProps as function
   * @see https://react-redux.js.org/using-react-redux/connect-mapdispatch#defining-mapdispatchtoprops-as-a-function
   *
   * Binding `{ formId }` to each form related action
   * if it's passed during component enhancement.
   * It's still possible to override this value at dispatch time
   */
  const mapDispatchToProps = (dispatch) => ({
    saveForm: (payload) => dispatch(formActions.saveForm({ formId, ...payload })),
    /** Save a snapshot of the current Formik values in the store */
    saveValues: (payload) => dispatch(formActions.saveValues({ formId, ...payload })),
    /** Updates one or more { values } in the form.[formId] key of the store */
    updateIn: (path, value) => dispatch(formActions.updateIn(path, value, formId)),
    /** Removes a form key from the store by formId */
    clearForm: ({ formId: providedFormId } = {}) =>
      dispatch(formActions.clearForm({ formId: providedFormId ?? formId })),
    clearForms: () => dispatch(formActions.clearForms()),
  });

  return connect(mapStateToProps, mapDispatchToProps)(Component);
}

export default withFormConnected;
