import { makeStyles } from '@material-ui/core';
import { appColors } from 'components/styleguide';

const styles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(),
    width: '100%',
  },
  formGroup: {
    display: 'flex',
    justifyContent: 'space-between',
    '& > *': {
      alignItems: 'stretch',
      margin: 0,
      width: '45%',
    },
    '& > :last-child': {
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  radio: {
    padding: theme.spacing(0.5),
  },
  label: {
    borderRadius: 5,
    border: '1px solid',
    borderColor: theme.palette.grey[50],
    backgroundColor: appColors.lightGrey,
    '&.qTracks': {
      backgroundColor: appColors.lightBlue,
      borderColor: appColors.blue,
    },
  },
  noChanges: {
    cursor: 'default',
    marginLeft: 'auto',
    minHeight: 50,
    '& > :first-child': {
      display: 'none',
    },
  },
}));

export default styles;
