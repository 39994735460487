import { connect } from 'react-redux';
import { saveProductionBudgetVersion } from 'redux-core/overview/actions';
import { getSelectedBudgetVersionsSelector } from 'redux-core/overview/selectors';
import { userNameSelector } from 'redux-core/auth/selectors';

const mapStateToProps = (state) => ({
  versions: getSelectedBudgetVersionsSelector(state),
  userName: userNameSelector(state),
});

const mapDispatchToProps = {
  saveProductionBudgetVersion,
};

export default connect(mapStateToProps, mapDispatchToProps);
