import { Box, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import { useAtom } from 'jotai';
import { getCurrentCueSheetVersionCall } from 'redux-core/cueSheet/services';
import { useRootTranslation } from 'utils/hooks';
import { cueSheetAndParentProductionAtom } from '../drawerStore';
import { TSeasonRelease } from './ChooseEpisodeCueSheet';

export type TAvailableTelevisionCueSheetList = {
  seasonRelease: TSeasonRelease;
};

export const AvailableTelevisionCueSheetList = ({ seasonRelease }: TAvailableTelevisionCueSheetList) => {
  const t = useRootTranslation();
  const [cueSheetAndParentProduction, setCueSheetAndParentProduction] = useAtom(cueSheetAndParentProductionAtom);

  return (
    <RadioGroup
      value={cueSheetAndParentProduction?.cueSheetId}
      data-testid="select-episode"
      onChange={async (_, newValue) => {
        const parentProduction = Number(newValue);
        const currentCueSheet = await getCurrentCueSheetVersionCall(parentProduction);
        setCueSheetAndParentProduction({
          parentProduction,
          cueSheetId: currentCueSheet.id,
        });
      }}
    >
      {!!seasonRelease?.episodes?.length &&
        seasonRelease.episodes.map((episode) => (
          <Box pt={1} key={`cue-sheet-target-${episode.id}`}>
            <FormControlLabel
              value={`${episode.id}`}
              control={<Radio data-testid="select-episode-option" />}
              label={episode.name}
            />
          </Box>
        ))}
      {!seasonRelease?.episodes?.length && (
        <Typography variant="body1">
          {t('drawers.quickSetupProduction.exportSegmentSteps.noEpisodesMessage')}
        </Typography>
      )}
    </RadioGroup>
  );
};
