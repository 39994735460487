import { makeStyles } from '@material-ui/core/styles';
import { appColors } from '../theme';

export default makeStyles((theme) => ({
  card: {
    minWidth: 280,
    minHeight: 80,
    borderRadius: 10,
    boxShadow: '0 3px 4px 0 rgba(33, 130, 204, 0.43);',
  },
  circle: {
    height: 16,
    width: 16,
    borderRadius: 12,
    cursor: 'pointer',
  },
  using: {
    color: appColors.green,
  },
  notUsing: {
    color: appColors.grey,
  },
  content: {
    /**
     * Material-UI own styles are overriding our custom styles from makeStyles
     * This is happening in Dev Environment but that's not the case in our local env
     * After attempting multiple fixes here: https://github.com/qwirecloud/qwire-fe/pull/869 and here too: https://github.com/qwirecloud/qwire-fe/pull/873
     * this is by now the most feasible way
     */
    padding: `${theme.spacing(0.5)}px!important`,
  },
  totalContainer: {
    backgroundColor: appColors.lightBlue,
    minHeight: 24,
    marginTop: theme.spacing(1),
    fontSize: 13,
    fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"',
    padding: theme.spacing(0.25, 2),
  },
  clearIcon: {
    backgroundColor: appColors.lightBlue,
    borderRadius: theme.spacing(2),
    height: theme.spacing(2.25),
    width: theme.spacing(2.25),
    color: appColors.white,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: appColors.darkBlue,
    },
  },
}));
