/* eslint-disable react/jsx-props-no-spreading */
import { useState } from 'react';
import { Grid, Accordion, AccordionSummary, AccordionDetails, IconButton, Box } from '@mui/material';
import { Text, Expand, newTheme } from 'components/styleguide';
import { Droppable } from 'react-beautiful-dnd';
import BlockIcon from '@material-ui/icons/Block';
import { Expanded } from './Expanded/Children';
import { SummaryActions } from './SummaryActions';
import { ExerciseButton } from './ExerciseButton';
import { RightsDateDetails } from './RightsDateDetails';

const Name = ({ active, children }) => {
  if (active) {
    return (
      <Text align="left" color="textPrimary">
        {children}
      </Text>
    );
  }
  return (
    <>
      <Grid item>
        <Text align="left" color="greyEmptyState">
          {children}
        </Text>
      </Grid>
      <Grid item>
        <BlockIcon style={{ color: 'red' }} />
      </Grid>
    </>
  );
};

export const ExpandableClearanceRow = ({
  clearance,
  cueContainer,
  track,
  isAllowedToEdit,
  reload,
  productionType,
  ...rest

  // trackId,
  // objectId,
  // divisionId,
  // clearance,
  // clearancesFetch
  // loading
}) => {
  const { name, isOption, active = true } = clearance;
  let { rights, rightsName } = clearance;
  if (!rights) {
    rights = [];
  }
  if (!rightsName) {
    rightsName = '-';
  }
  const hasCues = rights.length > 0 && rights[0]?.cues?.length > 0;
  const territoryName = rights.length > 0 ? rights.map((right) => right?.name).join(', ') : '-';

  const [isExpanded, setIsExpanded] = useState(false);
  const [hover, setHover] = useState(false);

  const handleOnExpandClick = () => {
    if (!hasCues) return;
    setIsExpanded((state) => !state);
  };

  return (
    <Droppable droppableId={`${clearance.id}`}>
      {(provided) => (
        <Accordion expanded={isExpanded} ref={provided.innerRef} {...provided.droppableProps}>
          <AccordionSummary
            onClick={handleOnExpandClick}
            onMouseLeave={() => setHover(false)}
            onMouseOver={() => setHover(true)}
            sx={{
              '&:hover': {
                backgroundColor: newTheme.palette.action.hover,
              },
            }}
            aria-label={`${isExpanded ? 'Collapse' : 'Expand'} clearance row`}
          >
            {hasCues ? (
              <IconButton size="small" color="primary">
                <Expand expanded={isExpanded} />
              </IconButton>
            ) : (
              <Box sx={{ height: '34px', width: '34px' }} />
            )}
            <Grid container direction="row" spacing={2} justifyContent="flex-start" alignItems="center">
              <Grid item container direction="row" justifyContent="space-between" alignItems="center" xs={2}>
                <Name active={active}>{name}</Name>
              </Grid>
              <Grid item xs={2}>
                <Text align="left">{rightsName}</Text>
              </Grid>
              <Grid item xs={1} />
              <Grid item xs={2}>
                <Text align="left">{territoryName}</Text>
              </Grid>
              <Grid item xs={2}>
                {rights.length > 0 && rights.map((right) => <RightsDateDetails rights={right} key={right.id} />)}
                {rights.length === 0 && <Text align="left">-</Text>}
              </Grid>
              <Grid item xs={1}>
                <ExerciseButton isOption={isOption} clearance={clearance} reload={reload} {...rest} />
              </Grid>
              <Grid container item xs={2} justifyContent="flex-end">
                <Grid item xs={3} />
                <SummaryActions
                  hover={hover}
                  isAllowedToEdit={isAllowedToEdit}
                  reload={reload}
                  clearance={clearance}
                  cueContainer={cueContainer}
                  track={track}
                  {...rest}
                />
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            {hasCues ? (
              <Expanded
                cue={rights[0]}
                isAllowedToEdit={isAllowedToEdit}
                productionType={productionType}
                reload={reload}
              />
            ) : null}
          </AccordionDetails>
          {provided.placeholder}
        </Accordion>
      )}
    </Droppable>
  );
};
