/* eslint-disable no-param-reassign */
/* eslint-disable no-use-before-define */
/* eslint-disable import/no-cycle */
import { createActions } from 'redux-actions';
import { unsetWatchingCall } from 'redux-core/productions/services';
import { openPrompt } from 'redux-core/prompt/actions';
import { showSnackbar } from 'redux-core/global-error/actions';
import { closeAllDialogs } from 'redux-core/dialogs/actions';
import identity from 'ramda/src/identity';
import i18next from 'i18next';
import { isEmpty } from 'lodash';
import { getWatchingFilterSelector, getWatchingSelector } from './selectors';
import { getAllWatchedProductionsCall } from './services';

const actionsDefinition = {
  SET_LOADING: identity,
  SET_WATCHING: identity,
  SET_WATCHING_COUNT: identity,
};

export const { setLoading, setWatching, setWatchingCount } = createActions(actionsDefinition);

export const fetchWatching = (filters) => async (dispatch, getState) => {
  dispatch(setLoading(true));
  const state = getState();
  const productions = getWatchingSelector(state);
  try {
    if (isEmpty(filters.sortBy)) {
      filters.sortBy = 'projectName';
    }

    const response = await getAllWatchedProductionsCall(filters);
    if (!filters.page) dispatch(setWatching(response.data));
    else dispatch(setWatching(productions.concat(response.data)));
    dispatch(setWatchingCount(response.pagination?.totalCount));
    return response;
  } finally {
    dispatch(setLoading(false));
  }
};

export const unwatchProduction = (productionId) => async (dispatch) => {
  dispatch(
    openPrompt({
      content: i18next.t('projects.watchingTab.unwatch'),
      onConfirm: async () => {
        await unsetWatchingCall({ productionId });
        dispatch(refetchWatching());
        dispatch(
          showSnackbar({
            message: i18next.t('projects.watchingTab.notifications.unwatched'),
          }),
        );
        dispatch(closeAllDialogs());
      },
    }),
  );
};

export const refetchWatching = () => async (dispatch, getState) => {
  const state = getState();
  const filters = getWatchingFilterSelector(state);
  dispatch(fetchWatching({ ...filters }));
};
