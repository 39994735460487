/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-cycle */
import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import path from 'ramda/src/path';
import pathOr from 'ramda/src/pathOr';
import propEq from 'ramda/src/propEq';

import { appColors, Text, zIndexLevels } from 'components/styleguide';
import { LICENSOR_TYPES_OPTIONS } from 'utils/constants';
import { useAtomValue } from 'jotai';
import { composeLetterAtom } from './composeLetterStore';
import { useRootTranslation } from '../../utils/hooks';
import { useChangeSelectedLicensor } from './hooks/useChangeSelectedLicensor';

const ComposeLetterLicensorSelector = () => {
  const t = useRootTranslation('composeLetters.stepper');

  const composeLetter = useAtomValue(composeLetterAtom);
  const [selectedLicensor, setSelectedLicensor] = useState(composeLetter.clearanceBundleLicensor.id);
  const { loadSpecificLicensor } = useChangeSelectedLicensor();

  useEffect(() => {
    if (selectedLicensor === null || selectedLicensor === composeLetter.clearanceBundleLicensor.id) {
      return;
    }
    loadSpecificLicensor(selectedLicensor);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLicensor]);

  const options = composeLetter.licensorQueue
    .map((item, index) => ({
      id: index,
      licensorId: item.id,
      productionName: composeLetter.production.name,
      trackTitle: composeLetter.track.title,
      licensor: {
        type: item.type,
        name: item.rightsOwner.name,
      },
    }));

  return (
    <>
      <Text bold testId="letterCurrentOfTotal">
        {t('letterCurrentOfTotal', {
          current: (composeLetter.licensorQueue.findIndex((item) => item.id === selectedLicensor)) + 1,
          total: composeLetter.licensorQueue.length || 0,
        })}
      </Text>
      <Box
        bgcolor={appColors.white}
        position="sticky"
        pt={1}
        top={0}
        width={1}
        zIndex={zIndexLevels.one}
      >
        <Select
          IconComponent={(props) => <ExpandMoreIcon {...props} color="primary" />}
          onChange={(e) => setSelectedLicensor(e.target.value as number)}
          value={selectedLicensor}
          style={{ width: '100%' }}
        >
          {options.map((option) => (
            <MenuItem value={option.licensorId} key={option.id}>
              <Grid container direction="column">
                <Text display="block" variant="caption" testId="letterDropdown-productionName">
                  {option.productionName}
                </Text>
                <Text display="block" bold testId="letterDropdown-trackTitle">
                  {option.trackTitle}
                </Text>
                <Text bold variant="caption" testId="letterDropdown-licensorType">
                  {LICENSOR_TYPES_OPTIONS.find(propEq('id', path(['licensor', 'type'], option)))?.name || 'N/A'}
                </Text>
                <Text
                  variant="caption"
                  testId="letterDropdown-licensorName"
                >
                  {pathOr('N/A', ['licensor', 'name'], option)}
                </Text>
              </Grid>
            </MenuItem>
          ))}
        </Select>
      </Box>
    </>
  );
};

export default ComposeLetterLicensorSelector;
