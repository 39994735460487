import { getAdditionalData } from 'utils/forms';
import path from 'ramda/src/path';
import { getSetupFlowPrCompaniesSelector } from 'redux-core/productions/selectors';
import { concatObjects } from 'utils/index';
// eslint-disable-next-line import/no-named-as-default
import DRAWERS from '../../constants';

const rootT = 'drawers.productionSetup.season.items';

export const productionInformationItems = ({ keepSeasonInfo, openDrawer, onSubmit }) => [
  /* @TODO will be done in @see https://jira.qwire.com/browse/CLR-622
  this opens a drawer but the drawer doesn't saves.
  Also we should revise the appearance of this item */
  {
    label: `${rootT}.blanketAgreements`,
    name: 'additionalData.blanketAgreements',
    action: (values, { readOnly }) => {
      const blanketAgreements = getAdditionalData('blanketAgreements')(values);
      keepSeasonInfo(values);
      openDrawer(DRAWERS.PRODUCTION_SETUP_BLANKET_AGREEMENTS, {
        readOnly,
        onSubmit,
        blanketAgreements,
      });
    },
  },
  {
    label: `${rootT}.distributors`,
    name: 'additionalData.distributors',
    action: (values, { readOnly }) => {
      keepSeasonInfo(values);
      openDrawer(DRAWERS.PRODUCTION_DETAILS_DISTRIBUTORS, {
        readOnly,
        edit: true,
        distributors: path(['additionalData', 'distributors'])(values),
        onSubmit,
      });
    },
  },
  {
    label: `${rootT}.networkBroadcaster`,
    name: 'additionalData.networks',
    action: (values, { readOnly }) => {
      keepSeasonInfo(values);
      openDrawer(DRAWERS.PRODUCTION_DETAILS_NETWORKS, {
        readOnly,
        rootT: 'drawers.productionSetup.defaultNetworks',
        edit: true,
        networks: path(['additionalData', 'networks'])(values),
        onSubmit,
      });
    },
  },
  {
    label: `${rootT}.productionCompanies`,
    name: 'additionalData.productionEntities',
    rootT: `${rootT}.productionEntities`,
    renderValues: concatObjects(', ', 'companyName'),
    action: (values, { readOnly }) => {
      keepSeasonInfo(values);
      openDrawer(DRAWERS.PRODUCTION_SETUP_COMPANIES, {
        readOnly,
        onSubmit,
        getCompaniesSelector: getSetupFlowPrCompaniesSelector,
      });
    },
  },
];

export const personnelInformationItems = ({ keepPersonnelInfo, keepSeasonInfo, openDrawer, onSubmit }) => [
  {
    label: `${rootT}.keyPersonnel`,
    name: 'additionalData.keyPersonnel',
    action: (values, { readOnly }) => {
      const keyPersonnel = getAdditionalData('keyPersonnel')(values);
      keepPersonnelInfo(keyPersonnel);
      keepSeasonInfo(values);
      openDrawer(DRAWERS.PERSONNEL_LIST_PERSONNEL, {
        readOnly,
        onSubmit,
        keyPersonnel,
      });
    },
  },
  {
    label: `${rootT}.directors`,
    name: 'additionalData.directors',
    action: (values, { readOnly }) => {
      keepSeasonInfo(values);
      openDrawer(DRAWERS.PRODUCTION_DETAILS_DIRECTORS, {
        readOnly,
        rootT: 'drawers.productionSetup.defaultDirectors',
        edit: true,
        directors: getAdditionalData('directors')(values),
        onSubmit,
      });
    },
  },
  {
    label: `${rootT}.producers`,
    name: 'additionalData.producers',
    action: (values, { readOnly }) => {
      keepSeasonInfo(values);
      openDrawer(DRAWERS.PRODUCTION_DETAILS_PRODUCERS, {
        readOnly,
        rootT: 'drawers.productionSetup.defaultProducers',
        edit: true,
        producers: getAdditionalData('producers')(values),
        onSubmit,
      });
    },
  },
  {
    label: `${rootT}.editors`,
    name: 'additionalData.editors',
    action: (values, { readOnly }) => {
      keepSeasonInfo(values);
      openDrawer(DRAWERS.PRODUCTION_DETAILS_EDITORS, {
        readOnly,
        edit: true,
        rootT: `${rootT}.editors`,
        editors: getAdditionalData('editors')(values),
        onSubmit,
      });
    },
  },
  {
    label: `${rootT}.actors`,
    name: 'additionalData.actors',
    action: (values, { readOnly }) => {
      keepSeasonInfo(values);
      openDrawer(DRAWERS.PRODUCTION_DETAILS_ACTORS, {
        readOnly,
        rootT: 'drawers.productionSetup.defaultActors',
        edit: true,
        actors: getAdditionalData('actors')(values),
        onSubmit,
      });
    },
  },
];
