/* eslint-disable func-names */
/* eslint-disable no-nested-ternary */
import omit from 'ramda/src/omit';
import prop from 'ramda/src/prop';
import assoc from 'ramda/src/assoc';
import compose from 'ramda/src/compose';
import find from 'ramda/src/find';
import flatten from 'ramda/src/flatten';
import map from 'ramda/src/map';
import propEq from 'ramda/src/propEq';
import reject from 'ramda/src/reject';
import { LICENSOR_TYPES, MFN_TYPES } from 'utils/constants';
import { getAllClearancesCall } from 'redux-core/clearances/services';
import { rootTranslation } from 'utils/format';
import * as Yup from 'yup';

const validateRightsOwnerInTrack = async ({ licensorId, trackId, qTracksRightsOwnerId }) => {
  const clearances = await getAllClearancesCall({ trackId });
  return compose(
    Boolean,
    find(propEq('qwireTracksRightsOwnerId', qTracksRightsOwnerId)),
    reject(propEq('id', licensorId)),
    flatten,
    map(prop('licensors')),
  )(clearances);
};

export const getPayload = ({ values, type, isEditing, trackId, originalType, divisionId, licensorsExist }) => {
  const { licensor } = values;
  const payload = {
    ...omit(['qTrack'], licensor),
    divisionId,
    type: isEditing
      ? originalType
      : type === LICENSOR_TYPES.ONE_STOP
      ? LICENSOR_TYPES.SYNC // one_stop always should be treated as sync
      : type,
    name: prop('name', licensor.qTrack),
    mfn: licensorsExist ? MFN_TYPES.MFN_NONE : MFN_TYPES.MFN_BASE,
    qwireTracksRightsOwnerId: prop('qwireTracksRightsOwnerId', licensor.qTrack),
  };
  if (!isEditing) return assoc('qclearTrackId', trackId, payload);
  return payload;
};

const t = rootTranslation('drawers.licensorsAddEdit.basicInfo');
const getValidationSchema = (schema) =>
  /**
   *
   * This closure is necessary to avoid running the async Yup validation
   * on every form change. We need to remember the last checked value,
   * so that we only fetch the backend to perform the validation when the
   * rights owner id actually changes.
   */
  function ({ initialRightsOwnerId, licensorId, trackId }) {
    let prevRightsOwnerId = initialRightsOwnerId;
    let hasError = false;

    return () =>
      Yup.object().shape({
        duplicatedLicensor: Yup.string()
          .nullable()
          .test('duplicatedLicensor', '', async function () {
            const qTracksRightsOwnerId = this.parent.licensor.qTrack.qwireTracksRightsOwnerId;

            const setError = () =>
              this.createError({
                path: this.path,
                message: t('repeatedLicensor'),
              });

            // If the qTracksRightsOwnerId didn't change, then we keep the
            // same error status we had before
            if (qTracksRightsOwnerId === prevRightsOwnerId || qTracksRightsOwnerId === initialRightsOwnerId) {
              if (!hasError) return true;
              return setError();
            }
            // If qTracksRightsOwnerId, perform validation
            hasError = await validateRightsOwnerInTrack({
              licensorId,
              trackId,
              qTracksRightsOwnerId,
            });
            prevRightsOwnerId = qTracksRightsOwnerId;

            if (!hasError) return true;
            return setError();
          }),
        ...schema,
      });
  };

export const getMasterValidationSchema = getValidationSchema({
  licensor: Yup.object().shape({
    contact: Yup.object().shape({
      email: Yup.string().nullable().email('global.forms.validations.types.email'),
    }),
    qTrack: Yup.object().shape({
      name: Yup.string().required('global.forms.validations.required'),
    }),
    recordLabels: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required('global.forms.validations.required'),
        contact: Yup.object().shape({
          email: Yup.string().nullable().email('global.forms.validations.types.email'),
        }),
      }),
    ),
  }),
});
