/* eslint-disable import/no-cycle */
import { handleActions } from 'redux-actions';
import set from 'ramda/src/set';
import lensProp from 'ramda/src/lensProp';
import always from 'ramda/src/always';
import { clearData } from 'redux-core/auth/actions';
import * as actions from './actions';

const defaultState = { list: [], loading: true };

export default handleActions(
  {
    [actions.setDivisions]: (state, { payload }) => set(lensProp('list'), payload)(state),
    [actions.setLoadingDivisions]: (state, { payload }) => set(lensProp('loading'), payload)(state),
    [clearData]: always(defaultState),
  },
  defaultState,
);
