export const CONTAINER_ID = 'onlyOfficeContainerId';
export const EVENTS = {
  PROCESS_URL: 'PROCESS_URL',
  DOWNLOAD_AS: 'onDownloadAs',
};

/*
 * @param {'txt' | 'odt' | 'docx' | 'html', 'pdf', ''} extension
 */
export const getDownloadURL = (extension) => {
  const event = new CustomEvent(EVENTS.PROCESS_URL, { detail: extension });
  window.dispatchEvent(event);
  return new Promise((resolve) => {
    window.addEventListener(EVENTS.DOWNLOAD_AS, (e) => resolve(e.detail), {
      once: true,
    });
  });
};

const postMessage = (contentWindow, text, frameEditorId) => {
  contentWindow.postMessage(
    JSON.stringify({
      frameEditorId,
      guid: 'asc.{A8705DEE-7544-4C33-B3D5-168406D92F72}',
      type: 'onExternalPluginMessage',
      data: { type: 'insertText', text },
    }),
    '*',
  );
};

export const addTag =
  (frameEditorId) =>
  ({ detail: tag }) => {
    const onlyOffice = document.getElementById(CONTAINER_ID);
    const iframe = onlyOffice.querySelector('iframe');
    if (iframe && tag) {
      const { contentWindow } = iframe;
      postMessage(contentWindow, tag, frameEditorId);
    }
  };

export const toggleOverlay = (disabled) => {
  const onlyOffice = document.getElementById(CONTAINER_ID);
  const iframe = onlyOffice.querySelector('iframe');

  if (!iframe) return;
  // if the state is disabled - disable the iframe visually and functionally
  iframe.style.opacity = disabled ? 0.6 : 1;
  iframe.style.pointerEvents = disabled ? 'none' : 'initial';
  iframe.style.filter = disabled ? 'blur(1px)' : '';
};
