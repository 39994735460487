/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { InputChip } from 'components/styleguide';
import { Field, getIn } from 'formik';
import { useTranslation } from 'react-i18next';
import flow from 'lodash/flow';
import { FieldPropTypes } from '../prop-types';

export const FormInputChip = ({ field, disabled, label, placeholder, form, ...props }) => {
  const { t } = useTranslation();
  const { touched, errors, isSubmitting, setFieldValue, setFieldTouched } = form;

  const handleChange = async (v) => {
    await setFieldValue(field.name, v);
    setFieldTouched(field.name, true, true);
  };

  const getFieldError = flow(
    (it) => getIn(it, field.name),
    (it) => it?.filter(Boolean),
  );
  const error = getIn(touched, field.name) && t(getFieldError(errors));

  return (
    <InputChip
      disabled={disabled || isSubmitting}
      error={error}
      label={t(label)}
      placeholder={t(placeholder)}
      {...props}
      {...field}
      onChange={handleChange}
    />
  );
};

FormInputChip.propTypes = {
  field: FieldPropTypes.field,
  form: FieldPropTypes.form,
  disabled: FieldPropTypes.disabled,
  label: FieldPropTypes.label,
  placeholder: FieldPropTypes.placeholder,
};

FormInputChip.displayName = 'InputChip';

const FormikInput = (props) => <Field component={FormInputChip} {...props} />;

FormikInput.displayName = 'FormInputChip';

export default FormikInput;
