const listHeight = 115.5;

export const styles = (theme) => ({
  title: {
    marginLeft: theme.spacing(2.75),
    marginBottom: theme.spacing(0.5),
  },
  body: {
    width: '100%',
    maxHeight: listHeight * 4,
    overflow: 'auto',
    paddingTop: 0,
    borderTop: '1px solid',
    borderTopColor: theme.palette.divider,
    marginTop: theme.spacing(1),
    '& > :not(:last-child)': {
      borderBottom: '1px solid',
      borderBottomColor: theme.palette.divider,
    },
  },
  listContainer: {
    position: 'relative',
  },
  box: {
    position: 'absolute',
    top: '50px',
    right: '-60px',
    width: 700,
    paddingTop: theme.spacing(2.625),
    maxHeight: '85vh',
    backgroundColor: theme.palette.primary.contrastText,
    overflow: 'auto',
    boxShadow: '0 8px 8px 0 rgba(0, 0, 0, 0.08), 0 16px 16px 0 rgba(0, 0, 0, 0.06)',
  },
});

export const outsideStyles = () => ({
  arrowBox: {
    position: 'relative',
    background: '#fff',
    top: -20,
    right: -20,
    '&::after': {
      bottom: '100%',
      left: '50%',
      border: 'solid transparent',
      content: "' '",
      height: '0',
      width: '0',
      top: '-10px',
      position: 'absolute',
      pointerEvents: 'none',
      borderColor: 'rgba(255, 255, 255, 0)',
      borderBottomColor: '#fff',
      borderWidth: '30px',
      marginLeft: '-44px',
    },
  },
});
