import React, { useCallback, useEffect } from 'react';
import { Provider } from 'jotai';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch } from 'react-redux';
import { BaseDrawer } from 'components/styleguide';
import { HydrateAtoms } from 'atomUtils';
import { getProductionUseInfo } from 'redux-core/overview/actions';
import { createTrack } from 'redux-core/tracks/actions';
import { usePrompt } from 'utils/hooks';
import { rootTranslation } from 'utils/format';
import { FormAwareContext, addTrackDrawerStoreAtom, formAwareStore, useFormIsDirty } from './addTrackDrawerStore';
import { UnifiedAddTrackDrawer } from './UnifiedAddTrackDrawer';

const tGlobal = rootTranslation('global');

function AddTrackDrawer({ onClose, cueContainerId, useCueContainerPickerTreeView }) {
  const dispatch = useDispatch();

  useEffect(() => {
    if (!useCueContainerPickerTreeView) {
      dispatch(getProductionUseInfo());
    }
  }, [dispatch, useCueContainerPickerTreeView]);

  const onSubmit = async (values) => {
    await dispatch(
      createTrack(values, {
        isNewPerformance: false,
      }),
    );
  };

  return (
    <UnifiedAddTrackDrawer
      onSubmit={onSubmit}
      onClose={onClose}
      cueContainerId={cueContainerId}
      generateOptionId={uuidv4}
      useCueContainerPickerTreeView={useCueContainerPickerTreeView}
    />
  );
}

function Drawer({ open, onClose, ...props }) {
  return (
    <FormAwareContext.Provider value={formAwareStore}>
      <FormAwareDrawer open={open} onClose={onClose}>
        <Provider>
          <HydrateAtoms
            initialValues={[
              [
                addTrackDrawerStoreAtom,
                {
                  cueContainerId: props.payload?.cueContainerId,
                  searchQuery: props.payload?.filter,
                  page: 0,
                  limit: 20,
                },
              ],
            ]}
          >
            <AddTrackDrawer
              cueContainerId={props.payload?.cueContainerId}
              onClose={onClose}
              useCueContainerPickerTreeView={props.payload?.useCueContainerPickerTreeView}
            />
          </HydrateAtoms>
        </Provider>
      </FormAwareDrawer>
    </FormAwareContext.Provider>
  );
}

function FormAwareDrawer({ children, open, onClose }) {
  const openPrompt = usePrompt();
  const [formIsDirty] = useFormIsDirty();

  const handleClose = useCallback(() => {
    if (formIsDirty) {
      openPrompt({
        content: tGlobal('forms.unsavedChanges'),
        onConfirm: onClose,
      });
    } else {
      onClose();
    }
  }, [formIsDirty, onClose, openPrompt]);

  return (
    <BaseDrawer open={open} onClose={handleClose} dense fullWidth>
      {children}
    </BaseDrawer>
  );
}

export default Drawer;
