import React, { useState, useRef, useEffect } from 'react';
import { Box } from '@mui/system';
import { Field } from 'formik';
import CloseIcon from '@mui/icons-material/Close';
import { Divider, IconButton, Popover, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { COMMON_TRACK_USE_TYPES } from 'utils/constants';
import { TIME_FORMATS, rootTranslation } from 'utils/format';
import classnames from 'classnames';
import * as Yup from 'yup';
import { yupTimeMMSS } from 'utils/forms';
import Form from 'components/common/Form';
import { FormInputText, FormInputFormat } from 'components/common/Form/Fields';
import useStyles from './styles';
import { FormControlLabelWrapper } from '../Form/Fields/FormField/FormField';
import { FormTrackUseTypeSelect } from '../TrackUseTypeSelect';

const t = rootTranslation('drawers.productionSetup.tracks.cuePopover');
const tGlobal = rootTranslation('global');

type CuePopoverProps = {
  anchorEl: HTMLElement;
  id: string;
  onSubmit: (args: unknown) => Promise<void>;
  setAnchorEl: (el: HTMLElement | null) => void;
  transformOriginH?: 'left' | 'right';
};

function CuePopover({ anchorEl, id, onSubmit, setAnchorEl, transformOriginH = 'left' }: CuePopoverProps) {
  const classes = useStyles();
  const popoverRef = useRef();
  const [durationFull, setDurationFull] = useState(false);
  const [selectedType, setSelectedType] = useState('');

  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);

    window.freshpaint.track('Closed Set Duration & Usage Box', { id });
  };

  const handleOnEnterDuration = (event) => {
    window.freshpaint.track('Entered Duration', { duration: event.target.value });
  };

  const handleOnSelectType = (selected) => {
    setSelectedType(selected.id);

    window.freshpaint.track('Selected Usage', { usage: selected.name });
  };

  const open = Boolean(anchorEl);
  const isUseTypeOther = selectedType === COMMON_TRACK_USE_TYPES.OTHER;

  useEffect(() => {
    if (open && isUseTypeOther) {
      /**
       * The standard way to update the Popover position after the component is mounted is using the
       * popoverActions.current.updatePosition(), however as it is not working in here we use this
       * hack taken from https://github.com/mui/material-ui/issues/10595#issuecomment-403130358
       */
      window.dispatchEvent(new CustomEvent('resize'));
    }
  }, [isUseTypeOther, open]);

  const validationSchema = Yup.object().shape({
    duration: yupTimeMMSS,
  });

  const submitForm = async (values) => {
    await onSubmit({
      ...values,
      durationFull,
    });
  };

  return (
    <Popover
      action={popoverRef}
      anchorEl={anchorEl}
      id={open ? id : undefined}
      onClose={handleClose}
      open={open}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: transformOriginH,
      }}
      PaperProps={{
        className: classnames(classes.popover, {
          fullSize: isUseTypeOther,
        }),
      }}
    >
      <Form onSubmit={submitForm} validationSchema={validationSchema}>
        {({ isSubmitting }) => (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
            <Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box>
                  <Typography variant="h5">{t('setDuration')}</Typography>
                </Box>
                <Box sx={{ ml: 'auto' }}>
                  <IconButton aria-label={tGlobal('forms.close')} onClick={handleClose}>
                    <CloseIcon />
                  </IconButton>
                </Box>
              </Box>
              <Divider sx={{ width: '100%' }} />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                {!durationFull && (
                  <Box sx={{ display: 'flex', alignItems: 'center', width: '50%' }}>
                    <FormInputFormat
                      fullWidth
                      format={TIME_FORMATS.MMSS}
                      label={t('duration')}
                      testId="duration"
                      name="duration"
                      onBlur={handleOnEnterDuration}
                      type="time"
                    />
                  </Box>
                )}
                <Box sx={{ width: durationFull ? '100%' : '50%' }}>
                  <FormControlLabelWrapper name="useType" label={t('use')}>
                    <FormTrackUseTypeSelect
                      name="useType"
                      onChange={(nextValue) => {
                        handleOnSelectType(nextValue);
                      }}
                      listStyles={{ maxHeight: 100 }}
                      testId="useType"
                    />
                  </FormControlLabelWrapper>
                </Box>
              </Box>
              {isUseTypeOther && (
                <Box>
                  <FormInputText name="usageNotes" textArea rows={10} label={t('usageDetails')} />
                </Box>
              )}
              <Box>
                <FormControlLabel
                  control={
                    <Checkbox checked={durationFull} onChange={() => setDurationFull(!durationFull)} color="primary" />
                  }
                  label={t('fullUse')}
                />
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <LoadingButton
                  color="secondary"
                  variant="outlined"
                  sx={{ minWidth: '88px' }}
                  loading={isSubmitting}
                  type="submit"
                >
                  {tGlobal('forms.skip')}
                </LoadingButton>
                <LoadingButton
                  data-testid="button-set-new-track-duration"
                  color="primary"
                  variant="contained"
                  type="submit"
                  sx={{ minWidth: '88px' }}
                  loading={isSubmitting}
                >
                  {tGlobal('forms.save')}
                </LoadingButton>
              </Box>
            </Box>
            <Box>
              <Box sx={{ backgroundColor: 'info.200', px: 2, py: 1, borderRadius: '4px' }}>
                <Field name="hide">
                  {({ field, form: { setFieldValue } }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={field.value}
                          onChange={() => {
                            setFieldValue('hide', !field.value);
                          }}
                        />
                      }
                      label={t('dontShowAgain')}
                    />
                  )}
                </Field>
              </Box>
            </Box>
          </Box>
        )}
      </Form>
    </Popover>
  );
}

export default CuePopover;
