import React from 'react';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import { useFetch, useSnackbar, useRootTranslation } from 'utils/hooks';
import { getLicensingPayee, updatePayee, createPayee, deletePayee } from 'redux-core/licensing/services';
import * as Yup from 'yup';
import { Loading } from 'components/styleguide';
import { greaterThan } from 'utils/forms';
import { COMMON_ROLES } from 'redux-core/permissions/constants';
import { PAYEE_TYPES } from 'utils/constants';
import { SecuredForm } from '../../Secured';
import enhancer from './enhancer';
import PayeeForm from './PayeeForm';
import PayeeHeader from './PayeeHeader';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('global.forms.validations.required').nullable(),
  share: Yup.number()
    .required('global.forms.validations.required')
    .max(100, greaterThan(100))
    .typeError('global.forms.validations.types.number'),
});

const PayeesAddEdit = ({
  divisionId: prodDivisionId,
  filters,
  payload: { payeeId, trackTitle, onSubmit, rightsOwnerId, clearanceBundleId, clearanceBundleLicensorId, objectId },
  onClose,
  name,
}) => {
  const divisionId = filters.divisionId || prodDivisionId;
  const showSnackbar = useSnackbar();
  const t = useRootTranslation('drawers.payeesAddEdit');
  const globalT = useRootTranslation('global');
  const [payee, loading] = useFetch(() => payeeId && getLicensingPayee({ id: payeeId }), [payeeId], {});
  const handleSubmit = async (values) => {
    try {
      if (payeeId) {
        await updatePayee(values);
        showSnackbar({ message: t('notifications.update') });
      } else {
        await createPayee({
          ...values,
          rightsOwnerId,
          clearanceBundleId,
          clearanceBundleLicensorId,
        });
        showSnackbar({ message: t('notifications.create') });
      }
      await onSubmit();
      await onClose();
    } catch (e) {
      showSnackbar({ message: globalT('genericError') });
    }
  };

  const handleDelete = async () => {
    try {
      await deletePayee({ id: payeeId });
      await showSnackbar({ message: t('notifications.delete') });
      await onSubmit();
      await onClose();
    } catch {
      await showSnackbar({ message: globalT('genericError') });
    }
  };

  if (loading) return <Loading />;
  return (
    <Grid container>
      <SecuredForm
        objectId={objectId}
        permissions={COMMON_ROLES.EDITOR}
        drawerName={name}
        onSubmit={handleSubmit}
        enableReinitialize
        initialValues={{ type: PAYEE_TYPES.COMPANY, ...payee }}
        validationSchema={validationSchema}
      >
        <PayeeHeader onClose={onClose} trackTitle={trackTitle} />
        <PayeeForm onDelete={handleDelete} divisionId={divisionId} objectId={objectId} />
      </SecuredForm>
    </Grid>
  );
};

PayeesAddEdit.displayName = 'PayeesAddEdit';

PayeesAddEdit.propTypes = {
  name: PropTypes.string,
  divisionId: PropTypes.number,
  onClose: PropTypes.func,
  filters: PropTypes.object,
  payload: PropTypes.shape({
    payeeId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    trackTitle: PropTypes.string.isRequired,
    onSubmit: PropTypes.func,
  }),
};
PayeesAddEdit.defaultProps = {
  payload: {
    onSubmit: () => undefined,
  },
};
export default enhancer(PayeesAddEdit);
