import { Typography } from '@mui/material';
import { Box, styled } from '@mui/system';
import { isEmpty } from 'lodash';
import { useWatch } from 'react-hook-form';
import WarningIcon from '@mui/icons-material/Warning';
import { TPartialRightOwner } from 'services/tracks/types';
import { LICENSOR_TYPES } from 'utils/constants';
import { rootTranslation } from 'utils/format';
import { groupRightsOwners } from './groupRightsOwners';

const t = rootTranslation('drawers.productionSetup.tracks.createLicensors');

const StyledBox = styled(Box)(({ theme }) => ({
  '& > .MuiBox-root:not(:first-of-type)': {
    marginTop: theme.spacing(2),
  },
}));

export function LicensorTotalsList() {
  const fields = useWatch({ name: 'rightsOwners' });
  const rightsOwnersMap = groupRightsOwners(fields);

  const masterFields = rightsOwnersMap.get(LICENSOR_TYPES.MASTER);
  const syncFields = rightsOwnersMap.get(LICENSOR_TYPES.SYNC);
  const otherFields = rightsOwnersMap.get(LICENSOR_TYPES.OTHER);
  const oneStopFields = rightsOwnersMap.get(LICENSOR_TYPES.ONE_STOP);
  const writerFields = rightsOwnersMap.get('writer');
  const publisherFields = rightsOwnersMap.get('publisher');

  return (
    <StyledBox>
      <LicensorTotalsCard sectionLabel={t('master')} items={masterFields} />
      <LicensorTotalsCard sectionLabel={t('sync')} items={syncFields} />
      <LicensorTotalsCard sectionLabel={t('other')} items={otherFields} />
      <LicensorTotalsCard sectionLabel={t('one_stop')} items={oneStopFields} />
      <LicensorTotalsCard sectionLabel={t('writer')} items={writerFields} />
      <LicensorTotalsCard sectionLabel={t('publisher')} items={publisherFields} />
    </StyledBox>
  );
}

type LicensorTotalsCardProps = {
  sectionLabel: string;
  items: Array<[TPartialRightOwner, number]>;
};

function LicensorTotalsCard({ items, sectionLabel }: LicensorTotalsCardProps) {
  if (isEmpty(items)) {
    return null;
  }

  const total = items.reduce((acc: number, [item]) => {
    const newValue = acc + Number(item?.share ?? 0);
    return newValue;
  }, 0);

  const isTotalInvalid = total !== 100;

  return (
    <Box
      sx={{
        padding: '1rem 1.25rem',
        backgroundColor: isTotalInvalid ? 'warning.300' : 'success.200',
        borderRadius: '4px',
      }}
    >
      <Box sx={{ mb: 1 }}>
        <Typography variant="h6" color="text.secondary">
          <span>{sectionLabel}</span> {t('totalShare')}
        </Typography>
      </Box>
      <Box sx={{ display: 'inline-flex', gap: 0.5 }}>
        <Typography variant="h6">{total}%</Typography>
        {isTotalInvalid && <WarningIcon color="warning" fontSize="small" />}
      </Box>
    </Box>
  );
}
