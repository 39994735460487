/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import isNil from 'ramda/src/isNil';

const HoverMenu = ({ children, options, outerHover, Wrapper }) => {
  const [hover, setHover] = useState(null);
  const isHovering = outerHover ?? hover;
  // Don't update state if the outerHover prop is passed
  const onHover = (bool) => () =>
    isNil(outerHover) ? setHover(bool) : undefined;

  return (
    <Wrapper
      data-testid="hover-menu-wrapper"
      onMouseOut={onHover(false)}
      onMouseOver={onHover(true)}
    >
      {isHovering ? (
        <Grid
          container
          data-testid="hover-action-menu"
          alignItems="center"
          justify="flex-end"
          onMouseOver={(e) => e.stopPropagation()}
          onMouseOut={(e) => e.stopPropagation()}
        >
          {options.map(({ action, disabled, display, icon }, index) => (
            <Tooltip title={display} aria-label={display} key={display}>
              <div
                style={{
                  // add marginRight to each element but last
                  marginRight: index === options.length - 1 ? 0 : 12,
                }}
              >
                <IconButton
                  color="primary"
                  disabled={disabled || !action}
                  key={display}
                  onClick={(e) => {
                    e.stopPropagation();
                    action(e);
                  }}
                  size="small"
                  data-testid="hover-menu-button"
                >
                  {icon}
                </IconButton>
              </div>
            </Tooltip>
          ))}
        </Grid>
      ) : (
        children
      )}
    </Wrapper>
  );
};

HoverMenu.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      action: PropTypes.func,
      disabled: PropTypes.bool,
      display: PropTypes.string.isRequired,
      icon: PropTypes.node.isRequired,
    }),
  ),
  children: PropTypes.any,
  outerHover: PropTypes.bool,
  Wrapper: PropTypes.func,
};

HoverMenu.defaultProps = {
  options: [],
  Wrapper: (props) => <div {...props} />,
};

export default HoverMenu;
