/* eslint-disable consistent-return */
/* eslint-disable import/no-cycle */
import { createActions } from 'redux-actions';
import i18next from 'i18next';
import { identity, mergeAll, pick, omit, compose, reject, path, prepend, propEq } from 'ramda';
import { showSnackbar } from 'redux-core/global-error/actions';
import { RIGHT_PRESET_IS_NEW_ID, RIGHTS_TERRITORIES_TERM_ENUM } from 'components/Settings/Tabs/Rights/constants';
import { TERRITORIES_WORLDWIDE_ID, TERRITORIES_WORLDWIDE_NAME } from 'components/Settings/Tabs/Territory/constants';
import { requestWithError } from 'utils/request';
import { getDivisionId } from 'redux-core/productions/selectors';
import { v4 as uuidv4 } from 'uuid';
import { rightsPresetsApi } from 'services/clear/rights-presets';
import {
  getRightsCall,
  updateRightsTerritoriesCall,
  createRightPresetCall,
  getRightByIdCall,
  getMaterialTypesCall,
  updateRightPresetCall,
  deleteRightPresetCall,
} from './services';

const actionsDefinition = {
  SET_RIGHT_PRESET: identity,
  SET_RIGHTS_PRESETS: identity,
  CLEAN_RIGHTS_PRESETS: identity,
  NEW_RIGHT_PRESET: identity,
  ADD_RIGHT_PRESET: identity,
  SET_MATERIAL_TYPES: identity,
};

export const {
  setRightPreset,
  setRightsPresets,
  cleanRightsPresets,
  newRightPreset,
  addRightPreset,
  setMaterialTypes,
} = createActions(actionsDefinition);

export const blankTerritoryRow = {
  id: TERRITORIES_WORLDWIDE_ID,
  name: TERRITORIES_WORLDWIDE_NAME,
  term: RIGHTS_TERRITORIES_TERM_ENUM.INPERPETUITY,
  key: uuidv4(),
};

export const getRightsPreset = (payload) => async (dispatch, getState) => {
  const state = getState();

  const divisionId = await getDivisionId(state);
  const headers = { divisionId };
  const extendedPayload = mergeAll([headers, payload]);

  try {
    const data = await getRightsCall(extendedPayload);

    dispatch(setRightsPresets(data));
    return data;
  } catch (error) {
    const message = i18next.t('rights.notification.get.failed');
    await dispatch(showSnackbar({ message }));
  }
};

export const getRightPresetById = (payload) => async () => {
  const right = await requestWithError(getRightByIdCall, payload, {
    message: {
      failed: 'rights.notification.get.failed',
    },
  });
  return right;
};

export const newBlankRightPreset = () => async (dispatch, getState) => {
  const state = getState();

  const emptyRightPreset = {
    id: RIGHT_PRESET_IS_NEW_ID,
  };

  const newState = compose(prepend(emptyRightPreset), path(['presets']))(state.rights);

  dispatch(newRightPreset(newState));
};

/**
 *  Remove non-saved Right Preset
 */
export const removeBlankRightPreset = () => async (dispatch, getState) => {
  const state = getState();

  const newState = compose(reject(propEq('id', RIGHT_PRESET_IS_NEW_ID)), path(['presets']))(state.rights);

  dispatch(setRightsPresets(newState));
};

/**
 * Create
 */
export const createRightPreset = (payload) => async (dispatch, getState) => {
  const state = getState();

  const divisionId = await getDivisionId(state);
  const headers = { divisionId };

  const cleanPayload = omit(['id'], payload);

  const extendedPayload = mergeAll([headers, cleanPayload]);

  try {
    const data = await createRightPresetCall(extendedPayload);
    const message = i18next.t('rights.notification.add.success');

    await dispatch(removeBlankRightPreset());
    await dispatch(showSnackbar({ message }));
    return data;
  } catch (error) {
    const message = i18next.t('rights.notification.add.failed');
    await dispatch(showSnackbar({ message }));
  }
};

/**
 * Update / Edit
 * @param {Object} payload
 */
export const updateRightPreset = (payload) => async (dispatch, getState) => {
  const state = getState();

  const divisionId = await getDivisionId(state);
  const headers = { divisionId };

  const extendedPayload = mergeAll([headers, payload]);

  try {
    const data = await updateRightPresetCall(extendedPayload);
    const message = i18next.t('rights.notification.edit.success', {
      name: data.name,
    });
    await dispatch(showSnackbar({ message }));
    return data;
  } catch (error) {
    const message = i18next.t('rights.notification.edit.failed');
    await dispatch(showSnackbar({ message }));
  }
};

/**
 * Update
 */
export const updateRightTerritories = (payload) => async (dispatch, getState) => {
  const state = getState();
  const divisionId = await getDivisionId(state);
  const headers = { divisionId };
  const extendedPayload = mergeAll([headers, payload]);

  await requestWithError(updateRightsTerritoriesCall, extendedPayload, {
    message: {
      success: i18next.t('rights.notification.territoryDrawer.edit.success'),
      failed: i18next.t('territory.notification.edit.failed'),
    },
  });
};

/**
 * Delete
 * @param {Object} payload
 */
export const deleteRightPreset = (payload) => async (dispatch, getState) => {
  const state = getState();

  const divisionId = await getDivisionId(state);
  const headers = { divisionId };
  const cleanPayload = pick(['id'], payload);

  const extendedPayload = mergeAll([headers, cleanPayload]);

  await requestWithError(deleteRightPresetCall, extendedPayload, {
    message: {
      success: i18next.t('rights.notification.delete.success'),
      failed: i18next.t('rights.notification.delete.failed'),
    },
  });

  dispatch(rightsPresetsApi.util.invalidateTags(['getAllRightsPresets']));
};

/**
 * Material Types
 */
export const getMaterialTypes = (payload) => async (dispatch, getState) => {
  const state = getState();
  const divisionId = await getDivisionId(state);
  const headers = { divisionId };
  const extendedPayload = mergeAll([headers, payload]);

  try {
    const data = await getMaterialTypesCall(extendedPayload);
    dispatch(setMaterialTypes(data));
    return data;
  } catch (error) {
    const message = i18next.t('global.genericError');
    await dispatch(showSnackbar({ message }));
  }
};
