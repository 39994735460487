import { connect } from 'react-redux';
import {
  getSelectedProductionObjectIdSelector,
} from 'redux-core/overview/selectors';
import { openPrompt } from 'redux-core/prompt/actions';

const mapStateToProps = (state) => ({
  objectId: getSelectedProductionObjectIdSelector(state),
});

const mapDispatchToProps = {
  openPrompt,
};

export default connect(mapStateToProps, mapDispatchToProps);
