import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  form: {
    marginTop: theme.spacing(7),
    '& > *': {
      marginBottom: theme.spacing(3.5),
    },
  },
}));

export default useStyles;
