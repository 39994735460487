/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const Template = (props) => (
  <SvgIcon {...props} viewBox="0 0 20 25">
    <path
      clipRule="evenodd"
      d="M3 3c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V9l-6-6H3zm7 7V4.5l5.5 5.5H10zM7.566 20h1.817v-7.055h2.326v-1.511H5.24v1.511h2.326V20z"
      fill="#5F6368"
      fillRule="evenodd"
    />
  </SvgIcon>
);

export default Template;
