import { withStyles } from '@material-ui/core';
import { topBarHeight } from 'components/styleguide/Topbar/styles';

const styles = () => ({
  snackbarLink: {
    marginLeft: '1em',
    fontWeight: 600,
    textAlign: 'right',
    '& > a': {
      color: 'white',
    },
    textTransform: 'uppercase',
  },
  message: {
    width: '100%',
  },
  snackbar: {
    margin: `calc(${topBarHeight} + 5px) 5px 5px 5px`,
  },
});

export default withStyles(styles);
