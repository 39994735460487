import { connect } from 'react-redux';
import {
  getAdvancedSearchFilterBySelector,
  getSearchItemDetailsSelector,
  getAdvancedSearchShowInactiveSelector,
} from 'redux-core/header/selectors';
import { goToLink } from 'redux-core/router/actions';
import { closeAllDialogs, openModal } from 'redux-core/dialogs/actions';
import {
  cleanSetupFlow,
  keepSetupInfo,
  toggleWatchingProduction,
} from 'redux-core/productions/actions';
import { refetchWatching } from 'redux-core/watching/actions';
import { updateAdvancedFilterVersion } from 'redux-core/header/actions';

const mapStateToProps = (state) => ({
  filterBy: getAdvancedSearchFilterBySelector(state),
  selected: getSearchItemDetailsSelector(state),
  showInactive: getAdvancedSearchShowInactiveSelector(state),
});

const mapDispatchToProps = {
  goToLink,
  cleanSetupFlow,
  closeAllDialogs,
  keepSetupInfo,
  openModal,
  refetchWatching,
  toggleWatchingProduction,
  updateAdvancedFilterVersion,
};

export default connect(mapStateToProps, mapDispatchToProps);
