import { TPartialRightOwner } from 'services/tracks/types';

type TItems = Array<TPartialRightOwner>;

/**
 * Groups the right owners based on their type and returns a Map of type to an array of tuples.
 * Each tuple contains the right owner item and its index, which is meant to be used in forms with `useFieldArray` –
 * the remove action from useFieldArray requires the index as argument.
 * @param rightOwners - An array of right owner items.
 * @returns A Map where the key is the right owner type and the value is an array of tuples [item, index].
 */
export function groupRightsOwners(rightOwners: TItems = []): Map<string, Array<[TItems[number], number]>> {
  return rightOwners.reduce((typeMap, item, index) => {
    if (typeMap.has(item.type)) {
      typeMap.get(item.type).push([item, index]);
    } else {
      typeMap.set(item.type, [[item, index]]);
    }
    return typeMap;
  }, new Map());
}
