import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
  trackRowWrapper: {
    borderTop: `1px solid ${theme.palette.divider}`,
    width: '100%',
    paddingTop: theme.spacing(),
    marginBottom: theme.spacing(),
  },
  selectAllButton: {
    width: 120,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  trackRowHeaderWrapper: {
    paddingLeft: theme.spacing(2),
    marginBottom: theme.spacing(),
  },
}));

export default styles;
