import { connect } from 'react-redux';
import { cleanSetup, fetchUpdateProject, keepProjectInfo, savePosterId } from 'redux-core/productions/actions';
import { getSetupFlowProjectSelector, getSetupFlowInfoSelector } from 'redux-core/productions/selectors';
import { openDrawer } from 'redux-core/dialogs/actions';

const mapStateToProps = (state) => ({
  initialValues: getSetupFlowProjectSelector(state),
  setupInfo: getSetupFlowInfoSelector(state),
});

const mapDispatchToProps = {
  cleanSetup,
  fetchUpdateProject,
  keepProjectInfo,
  openDrawer,
  savePosterId,
};

export default connect(mapStateToProps, mapDispatchToProps);
