/* eslint-disable import/no-cycle */
import { makeStyles } from '@material-ui/core';
import { appColors } from 'components/styleguide';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'transparent',
    padding: 0,
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  label: {
    marginTop: `${theme.spacing()}px !important`,
    whiteSpace: 'nowrap',
  },
  line: {
    '& > *': {
      borderColor: appColors.lightGradient,
    },
  },
}));

export default useStyles;
