import { Skeleton } from '@mui/material';
import { AutocompleteWithEdit } from 'components/common/AutocompleteWithEdit/AutocompleteWithEdit';
import { FormField } from 'components/common/Form/Fields/FormField/FormField';
import { useState } from 'react';
import { useController } from 'react-hook-form';
import { useCreateTrackArtistMutation, useSearchTracksArtistsQuery } from 'services/tracks/tracks';
import { useDebouncedCallback } from 'use-debounce';
import { rootTranslation } from 'utils/format';
import { useSnackbar } from 'utils/hooks';

const t = rootTranslation('drawers.productionSetup.tracks.filter');
const tGlobal = rootTranslation('global');

type TOption = {
  id: number;
  name: string;
};

export function ArtistField() {
  const showSnackbar = useSnackbar();
  const [term, setTerm] = useState('');
  const { data, isLoading } = useSearchTracksArtistsQuery({ term });
  const { field: artistField } = useController({ name: 'artist' });
  const [createTrackArtist] = useCreateTrackArtistMutation();

  const debouncedSetSearch = useDebouncedCallback((_, inputValue) => {
    setTerm(inputValue);
  }, 300);

  const handleCreateArtist = async (value: string) => {
    try {
      const result = await createTrackArtist({ name: value }).unwrap();
      artistField.onChange(result);

      window.freshpaint.track('Created Artist', { name: value });
    } catch {
      showSnackbar({ message: tGlobal('genericError') });
    }
  };

  if (isLoading) {
    return <Skeleton />;
  }

  return (
    <FormField
      label={t('artist')}
      name="artist"
      render={({ field, muiprops }) => (
        <AutocompleteWithEdit
          options={data.content ?? []}
          value={field.value}
          onChange={(nextValue: TOption) => {
            field.onChange(nextValue);
          }}
          onCreate={handleCreateArtist}
          onInputChange={debouncedSetSearch}
          loading={isLoading}
          noOptionsText={tGlobal('noResults')}
          inputProps={{
            error: Boolean(muiprops.error),
            id: 'artist',
          }}
        />
      )}
    />
  );
}
