import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  thead: {
    '&.MuiTableHead-root > tr > th': {
      fontWeight: 'bold',
    },
  },
  row: {
    '&:last-child > .MuiTableCell-root': {
      borderBottom: 'none',
    },
  },
  cell: {
    '&.MuiTableCell-root': {
      borderBottom: '1px solid #c4c4c4',
    },
  },
  searchFieldContainer: {
    width: '50%',
  },
}));

export default useStyles;
