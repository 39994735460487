/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Text, Verified as VerifiedIcon, StrengthBars } from 'components/styleguide';
import { Badge, Box, Grid } from '@material-ui/core';
import { MenuItem, Select } from '@mui/material';
import classnames from 'classnames';
import { useFetch, useRootTranslation } from 'utils/hooks';
import { getTrackVersions } from 'redux-core/tracks/services';
import { getLocalDate } from 'utils/format';
import useStyles from './styles';

const rootT = 'drawers.productionSetup.tracks.details';

const RenderComponent = ({ isCurrentVersion, score, version: date, tenant, user }) => {
  const classes = useStyles();
  const t = useRootTranslation(rootT);

  return (
    <Grid container alignItems="center" spacing={1} className={classes.option}>
      <Box>
        <Badge className={classnames(classes.dot, { visible: isCurrentVersion })} variant="dot" />
      </Box>
      <Text>
        {`${getLocalDate(date, 'L')} 
        ${getLocalDate(date, 'hh:mm A')} 
        `}
      </Text>
      <StrengthBars level={score} />
      {score >= 4 && (
        <Grid alignItems="center" container item spacing={1} xs>
          <Grid item>
            <Box display="flex">
              <VerifiedIcon />
            </Box>
          </Grid>
          <Grid item xs>
            <Text bold>{t('verifiedByPublisher')}</Text>
          </Grid>
        </Grid>
      )}
      {tenant && (
        <>
          <Text variant="caption" bold>
            {`${tenant.name}: `}
          </Text>
          <Text variant="caption">{user.displayName}</Text>
        </>
      )}
    </Grid>
  );
};

const TrackVersion = ({ onChange, qwireTracksId, tenantId, value, ...props }) => {
  const [options] = useFetch(() => qwireTracksId && getTrackVersions({ id: qwireTracksId, tenantId }), [qwireTracksId]);

  return (
    <Select name="version" value={value} onChange={(event) => onChange(event.target.value)} {...props} fullWidth>
      {options.map(({ id, ...option }) => (
        <MenuItem key={id} value={id}>
          <RenderComponent key={option} {...option} />
        </MenuItem>
      ))}
    </Select>
  );
};

TrackVersion.propTypes = {
  onChange: PropTypes.func.isRequired,
  qwireTracksId: PropTypes.number,
  tenantId: PropTypes.number.isRequired,
  value: PropTypes.number,
};

export default TrackVersion;
