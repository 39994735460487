import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid } from '@material-ui/core';
import { Field } from 'formik';
import { InputText } from 'components/styleguide';
import prop from 'ramda/src/prop';
import withStyles from './styles';

const FormMultiInputEditField = ({ name, fieldOne, inputOneProps, fieldTwo, inputTwoProps, classes, editable }) => {
  const { name: fieldOneName, editable: fieldOneEditable } = fieldOne || {};
  const { name: fieldTwoName, editable: fieldTwoEditable } = fieldTwo || {};
  const editOne = editable && fieldOneEditable;
  const editTwo = editable && fieldTwoEditable;

  return (
    <Field name={name}>
      {({ field: { value }, form: { setFieldValue } }) => {
        // helper to set the value in the form state
        const getChangeValue = (fieldName) => (val) =>
          setFieldValue(name, {
            ...value,
            [fieldName]: val,
          });

        return (
          <Grid container className={classes.container} justify="space-between">
            <Grid item container xs={5}>
              <Box mr={3.5} width={1}>
                <InputText
                  name={fieldOneName}
                  readOnly={!editOne}
                  value={prop(fieldOneName)(value)}
                  onChange={(e) => getChangeValue(fieldOneName)(e.target.value)}
                  inputProps={inputOneProps}
                />
              </Box>
            </Grid>
            <Grid item container xs={7}>
              <InputText
                name={fieldTwoName}
                readOnly={!editTwo}
                value={prop(fieldTwoName)(value)}
                onChange={(e) => getChangeValue(fieldTwoName)(e.target.value)}
                inputProps={inputTwoProps}
              />
            </Grid>
          </Grid>
        );
      }}
    </Field>
  );
};

FormMultiInputEditField.propTypes = {
  name: PropTypes.string.isRequired,
  fieldOne: PropTypes.object.isRequired,
  inputOneProps: PropTypes.object,
  fieldTwo: PropTypes.object.isRequired,
  inputTwoProps: PropTypes.object,
  classes: PropTypes.object,
  editable: PropTypes.bool,
};

export default withStyles(FormMultiInputEditField);
