/* eslint-disable consistent-return */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Box } from '@mui/system';
import classnames from 'classnames';

import { GLOBAL_SEARCH_FILTER_BY } from '../../utils';
import { LicensorItem } from '../LicensorItem';
import { ProductionItem } from '../ProductionItem';
import { TrackItem } from '../TrackItem';

import enhancer from './enhancer';

const getComponent = (filterBy) => {
  // eslint-disable-next-line default-case
  switch (filterBy) {
    case GLOBAL_SEARCH_FILTER_BY.TRACKS.value:
      return TrackItem;
    case GLOBAL_SEARCH_FILTER_BY.PRODUCTIONS.value:
      return ProductionItem;
    case GLOBAL_SEARCH_FILTER_BY.RIGHTS_OWNERS.value:
      return LicensorItem;
  }
};

const SearchItem = ({ filterBy, item, selected, setSearchItemDetails }) => {
  const onSelect = () => setSearchItemDetails(item);
  const Component = getComponent(filterBy);
  return (
    <Box
      component="li"
      role="tab"
      aria-selected={selected}
      aria-controls="search-details"
      onClick={onSelect}
      className={classnames({ selected })}
      sx={{ listStyle: 'none' }}
    >
      <Component {...item} />
    </Box>
  );
};

export default enhancer(SearchItem);
