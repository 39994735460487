/* eslint-disable import/no-cycle */
import { handleActions } from 'redux-actions';
import lensPath from 'ramda/src/lensPath';
import omit from 'ramda/src/omit';
import pipe from 'ramda/src/pipe';
import set from 'ramda/src/set';
import always from 'ramda/src/always';
import mergeDeepLeft from 'ramda/src/mergeDeepLeft';
import { clearData } from 'redux-core/auth/actions';
import { PRODUCTION_TYPES } from 'utils/constants';
import * as actions from './actions/actionDefinition';

const { SEASON_RELEASE, FILM_RELEASE, EPISODE, CAMPAIGN, PROJECT, SEASON } = PRODUCTION_TYPES;

export const defaultState = {
  setupFlow: {
    project: {},
    season: {
      currencies: [],
      additionalData: {
        productionEntities: [],
      },
    },
    release: {},
    keyPersonnel: [],
    keyPersonnelForm: {},
    division: {},
  },
  setupInfo: {},
  productionsList: [],
  hierarchyUpdateProject: null,
  posterIds: {
    [SEASON_RELEASE]: null,
    [FILM_RELEASE]: null,
    [EPISODE]: null,
    [CAMPAIGN]: null,
    [PROJECT]: null,
    [SEASON]: null,
  },
};

const setupFlowReducer = {
  [actions.keepSetupInfo]: (state, { payload }) => set(lensPath(['setupInfo']), payload)(state),
  [actions.keepFilmInfo]: (state, { payload }) => set(lensPath(['setupFlow', 'film']), payload)(state),
  [actions.keepSeasonInfo]: (state, { payload }) => set(lensPath(['setupFlow', 'season']), payload)(state),
  [actions.keepReleaseInfo]: (state, { payload }) => set(lensPath(['setupFlow', 'release']), payload)(state),
  [actions.keepProjectInfo]: (state, { payload }) => set(lensPath(['setupFlow', 'project']), payload)(state),
  [actions.keepPersonnelInfo]: (state, { payload }) => set(lensPath(['setupFlow', 'keyPersonnel']), payload)(state),
  [actions.keepPersonnelForm]: (state, { payload }) => set(lensPath(['setupFlow', 'keyPersonnelForm']), payload)(state),
  [actions.cleanSetup]: (state) => pipe(omit(['setupFlow', 'setupInfo']), mergeDeepLeft(defaultState))(state),
  [actions.cleanSetupFlow]: (state) => pipe(omit(['setupFlow']), mergeDeepLeft(defaultState))(state),
  [actions.setProductionCompanies]: (state, { payload }) =>
    set(lensPath(['setupFlow', 'season', 'additionalData', 'productionEntities']), payload)(state),
  [actions.setPosterId]: (state, { payload }) => {
    const { type, posterId } = payload;
    return set(lensPath(['posterIds', type]), posterId)(state);
  },
  [clearData]: always(defaultState),
};

export default handleActions(setupFlowReducer, defaultState);
