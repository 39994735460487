/* eslint-disable import/no-cycle */
import { withStyles } from '@material-ui/core';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import React from 'react';

import PropTypes from 'prop-types';
import ROUTES from 'components/Routes/routes';
import { useLocation } from 'react-router';
import { requestWithError } from 'utils/request';
import { deleteBookmarkCall } from 'redux-core/header/services';
import { useRootTranslation } from 'utils/hooks';
import { styles, outsideStyles } from './styles';
import ListContainer from '../../ListContainer';
import enhancer from './enhancer';

const List = withStyles(styles)(ListContainer);
List.propTypes = {
  list: PropTypes.array,
  title: PropTypes.string.isRequired,
};

const rootT = 'topbar';

const BookmarkList = (props) => {
  const { classes, handleToggle, list, goToLink, fetchBookmarkList } = props;
  const { state } = useLocation();
  const t = useRootTranslation(rootT);
  const navigate = async (row) => {
    await goToLink(ROUTES.TRACK.CLEARANCES, {
      ...row,
      ...state,
      trackId: row.qclearTrackId,
    });
    handleToggle();
  };

  const handleDelete = async ({ id }) => {
    await requestWithError(deleteBookmarkCall, { id }, { message: { success: t('bookmarkDeleted') } });
    await fetchBookmarkList();
  };

  return (
    <ClickAwayListener onClickAway={handleToggle}>
      <div className={classes.arrowBox}>
        <List
          list={list}
          title={list.length ? 'topbar.bookmarks' : 'topbar.noTracksBookmarked'}
          hasImage
          onDelete={handleDelete}
          navigate={navigate}
        />
      </div>
    </ClickAwayListener>
  );
};

BookmarkList.defaultProps = {
  list: [],
};

BookmarkList.propTypes = {
  classes: PropTypes.object.isRequired,
  handleToggle: PropTypes.func.isRequired,
  fetchBookmarkList: PropTypes.func.isRequired,
  goToLink: PropTypes.func.isRequired,
  list: PropTypes.array,
};

export default enhancer(withStyles(outsideStyles)(BookmarkList));
