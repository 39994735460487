import * as React from 'react';
import { Popover } from '@mui/material';

type EditOptionPopoverProps = {
  open: boolean;
  onClose: () => void;
  anchorElRef: React.RefObject<HTMLElement>;
  children: React.ReactNode;
  paperMinWidth: string;
};

export function EditOptionPopover({ open, onClose, anchorElRef, children, paperMinWidth }: EditOptionPopoverProps) {
  const id = open ? 'simple-popover' : undefined;

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorElRef.current}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      PaperProps={{
        sx: {
          // 32px offset to account for padding x
          width: `calc(${anchorElRef?.current?.offsetWidth}px - 32px)`,
          padding: '8px 16px',
          overflow: 'auto',
          minWidth: paperMinWidth,
        },
      }}
    >
      {children}
    </Popover>
  );
}
