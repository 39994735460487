/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-children-prop */
import { Box } from '@mui/system';
import Grid from '@material-ui/core/Grid';
import { Skeleton, Switch } from '@mui/material';
import { Button, DrawerHeader, Dropdown, Text } from 'components/styleguide';
import PropTypes from 'prop-types';
import prop from 'ramda/src/prop';
import uniqBy from 'ramda/src/uniqBy';
import { useState } from 'react';
import ProjectField from 'components/common/Modals/ProductionModal/ProjectField';
import { useSearchProductionsQuery } from 'services/common/search';
import { NORMALIZED_PRODUCTION_TYPES, PRODUCTION_TYPES_OPTIONS } from 'utils/constants';
import { rootTranslation } from 'utils/format';
import { useRootTranslation } from 'utils/hooks';
import withBaseDrawer from '../withBaseDrawer';
import ProductionFilter from './ProductionFilter';
import TVFilter from './TVFilter';

const t = rootTranslation('drawers.searchProductions');

const NormalizedProductionTypeToSearchProductionSchema = {
  [NORMALIZED_PRODUCTION_TYPES.FEATURES]: 'movie',
  [NORMALIZED_PRODUCTION_TYPES.MKT]: 'campaign',
  [NORMALIZED_PRODUCTION_TYPES.TV]: 'season',
};

const SearchProductionDrawer = ({ onClose, payload: { cachedValues, onSubmit, divisionId } }) => {
  const tGlobal = useRootTranslation('global.forms');
  const [filter, setFilter] = useState({});
  const [projectId, setProjectId] = useState();
  const [productionType, setProductionType] = useState();
  const [seasonReleaseEpisodes, setSeasonReleaseEpisodes] = useState([]);
  const [showInactive, setShowInactive] = useState(false);

  const { data: searchResponse, isLoading } = useSearchProductionsQuery(
    {
      filters: { types: [NormalizedProductionTypeToSearchProductionSchema[productionType]], divisionId, showInactive },
    },
    { skip: !productionType },
  );

  const data = searchResponse?.data
    .filter((prod) => !projectId || projectId === prod.prodArray[0]?.id)
    .map((prod) => ({ id: prod.productionId, name: prod.rawName }));

  const isTV = productionType === NORMALIZED_PRODUCTION_TYPES.TV;

  const handleSubmit = (apply) => async () => {
    const productions = isTV ? seasonReleaseEpisodes : data;
    const cachedAndSelectedProductions = uniqBy(prop('id'))([
      ...cachedValues,
      ...productions.filter((d) => filter.productionIds.includes(d.id)),
    ]);

    const cachedAndSelectedProductionsIds = cachedAndSelectedProductions.map((p) => p.id);

    await onSubmit(cachedAndSelectedProductionsIds, cachedAndSelectedProductions, apply);

    onClose();
  };

  const handleChange = (payload, episodes) => {
    if (isTV && episodes) setSeasonReleaseEpisodes(episodes);
    setFilter((selectedItem) => ({ ...selectedItem, ...payload }));
  };

  return (
    <>
      <DrawerHeader hideBackButton title={t('drawerTitle')}>
        <Button
          testId="search-drawer-cancel"
          alwaysEnabled
          variant="destructive"
          size="xs"
          children={tGlobal('cancel')}
          onClick={onClose}
        />
        <Button
          testId="search-drawer-apply"
          disabled={!filter.productionIds}
          children={tGlobal('apply')}
          onClick={handleSubmit(true)}
          size="xs"
          type="button"
          variant="primary"
        />
        <Button
          testId="search-drawer-add-continue"
          disabled={!filter.productionIds}
          children={tGlobal('addAndContinue')}
          onClick={handleSubmit(false)}
          size="sm"
          type="button"
          variant="primary"
        />
      </DrawerHeader>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid xs={6}>
            <ProjectField label={t('project')} onChange={setProjectId} value={projectId} testId="project" />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid xs={6}>
            <Box display="flex" alignItems="center">
              <Switch
                name="filters.showArchived"
                checked={showInactive}
                onChange={() => setShowInactive(!showInactive)}
              />
              <Text variant="formLabel" bold>
                {t('showInactived')}
              </Text>
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid xs={6}>
            <Dropdown
              testId="productionType"
              label={t('productionType')}
              onChange={(p) => setProductionType(p)}
              options={PRODUCTION_TYPES_OPTIONS}
              placeholder={t('productionType')}
              value={productionType}
            />
          </Grid>
        </Grid>
        {productionType && isLoading && <Skeleton />}
        {productionType && !isLoading && (
          <Grid item xs={12}>
            {isTV ? (
              <TVFilter divisionId={divisionId} projectId={projectId} onChange={handleChange} />
            ) : (
              <ProductionFilter
                productionIds={filter.productionIds}
                productions={data}
                onChange={handleChange}
                type={productionType}
              />
            )}
          </Grid>
        )}
      </Grid>
    </>
  );
};

SearchProductionDrawer.propTypes = {
  onClose: PropTypes.func,
  payload: PropTypes.shape({
    type: PropTypes.oneOf(Object.values(NORMALIZED_PRODUCTION_TYPES)),
    onSubmit: PropTypes.func,
    values: PropTypes.arrayOf(PropTypes.oneOfType(PropTypes.number, PropTypes.string)),
  }),
};

export default withBaseDrawer(SearchProductionDrawer);
