import { baseApi } from 'services';
import { TLetterType, TTemplateLicensorType } from './types';

export type TGetAllLetterTemplatesRequest = {
  divisionId?: number;
};

export type TGetAllLetterTemplatesResponse = {
  id: number;
  name: string;
  divisionId: number;
  isDefault: boolean;
  licensorType: TTemplateLicensorType;
  letterType: TLetterType;
}[];

export type TCacheUrlRequest = {
  divisionId?: number;
};

export type TCacheUrlResponse = {
  cacheFileKey: string;
  url: string;
};

export const letterTemplatesApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllLetterTemplates: builder.query<TGetAllLetterTemplatesResponse, TGetAllLetterTemplatesRequest>({
      query: (body) => ({ url: 'clear/letter-templates/get-all', method: 'POST', body }),
    }),
    getCacheUrl: builder.query<TCacheUrlResponse, TCacheUrlRequest>({
      query: (body) => ({ url: 'clear/letter-templates/get-cache-url', method: 'POST', body }),
    }),
  }),
});

export const { useGetAllLetterTemplatesQuery, useGetCacheUrlQuery } = letterTemplatesApi;
