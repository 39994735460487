/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const CheckSquare = (props) => (
  <SvgIcon {...props}>
    <path d="M17.6496 3.17411C17.6496 2.89509 17.5379 2.61607 17.3371 2.41518L15.8192 0.897321C15.6183 0.696428 15.3393 0.584821 15.0603 0.584821C14.7813 0.584821 14.5022 0.696428 14.3013 0.897321L6.97991 8.22991L3.69866 4.9375C3.49777 4.73661 3.21875 4.625 2.93973 4.625C2.66071 4.625 2.3817 4.73661 2.1808 4.9375L0.662946 6.45536C0.462054 6.65625 0.350446 6.93527 0.350446 7.21429C0.350446 7.4933 0.462054 7.77232 0.662946 7.97321L4.70312 12.0134L6.22098 13.5312C6.42188 13.7321 6.70089 13.8438 6.97991 13.8438C7.25893 13.8438 7.53795 13.7321 7.73884 13.5312L9.2567 12.0134L17.3371 3.93304C17.5379 3.73214 17.6496 3.45312 17.6496 3.17411Z" />
  </SvgIcon>
);

export default CheckSquare;
