/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-children-prop */
/* eslint-disable import/no-cycle */
import { Button } from '@mui/material';
import { withStyles, Grid } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { useTranslation } from 'react-i18next';

import { Loading, Text } from 'components/styleguide';
import ListContainer from 'components/TopBarSlot/ListContainer';

// eslint-disable-next-line import/no-named-as-default
import DRAWERS from 'components/common/Drawers/constants';
import { listStyles, useRecentsStyle } from '../styles';

const List = withStyles(listStyles)(ListContainer);

const RecentProjects = ({ recentsList, navigate, isLoading, classes, openDrawer }) => {
  const { t } = useTranslation();
  const { advancedSearchLink, recentsHeader } = useRecentsStyle();

  if (isLoading) {
    return <Loading />;
  }
  if (!recentsList?.length || recentsList.length === 0) {
    return (
      <Grid className={classes.noResults} item xs={12}>
        <Text variant="subtitle2" children={t('global.noResults')} />
      </Grid>
    );
  }

  const openAdvancedSearch = (event) => {
    event.preventDefault();
    openDrawer(DRAWERS.GLOBAL_SEARCH_FILTER);
  };

  return (
    <>
      <div className={recentsHeader}>
        <Text variant="h5" color="textPrimary">
          {t('topbar.recent')}
        </Text>
        <div>
          <Button
            onClick={openAdvancedSearch}
            className={advancedSearchLink}
            startIcon={<SearchIcon data-testid="search-icon-on-recent-list" />}
            variant="text"
            size="small"
          >
            {t('globalSearch.advancedSearch')}
          </Button>
        </div>
      </div>
      <List list={recentsList} hasImage hideStatus navigate={navigate} />
    </>
  );
};

export default RecentProjects;
