/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-children-prop */
/* eslint-disable no-nested-ternary */
import { TypeAhead, Text, InputText } from 'components/styleguide';
import React, { useState, useMemo, useEffect } from 'react';
import { useFetch, useDebounce, useSnackbar, useRootTranslation } from 'utils/hooks';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import DeleteIcon from '@material-ui/icons/Cancel';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
import reject from 'ramda/src/reject';
import differenceWith from 'ramda/src/differenceWith';
import { getIn } from 'formik';
import { withFormField } from 'components/common/Form/utils';
import trim from 'ramda/src/trim';
import compose from 'ramda/src/compose';
import pluck from 'ramda/src/pluck';
import is from 'ramda/src/is';
import map from 'ramda/src/map';
import last from 'ramda/src/last';
import prop from 'ramda/src/prop';
import filter from 'ramda/src/filter';
import always from 'ramda/src/always';
import { concatStrings } from 'utils/index';
import { FieldPropTypes } from '../prop-types';
import useStyles from './styles';

const FormSearchList = ({
  form: { setFieldValue, values, isSubmitting },
  field: { name },
  call,
  isEditable,
  isMultiple,
  createCall,
  readOnly,
  disabled,
  label,
  filterListProp, // this is used to filter by a custom prop the dropdown list.
  parseItem,
  isCreateDisabled,
  testId,
  ...props
}) => {
  const showSnackbar = useSnackbar();
  /**
   * getting form value based on name
   */
  const labelKey = props.labelKey || 'name';
  const value = getIn(values, name);
  const displayList = isMultiple ? value || [] : value.id ? [value] : [];
  const t = useRootTranslation('global');
  const [query, setQuery] = useState('');
  const [showList, toggleList] = useState(false);
  const [editableIndex, setEditableIndex] = useState();
  const [editingFieldCache, setEditingFieldCache] = useState();
  const isEditing = is(Number, editableIndex);
  const isValid = Boolean(editingFieldCache?.trim());
  const createDisabled = isCreateDisabled(query);

  useEffect(() => {
    if (!readOnly) return;
    // If the form changed to readOnly status, reset component to display values.
    setEditableIndex(undefined);
    setEditingFieldCache(undefined);
  }, [readOnly]);

  const debouncedSearch = useDebounce(query, 300);
  const classes = useStyles({ showList });
  const isValidSearchInput = Boolean(trim(debouncedSearch).length);
  const [result, loading] = useFetch(
    () => isValidSearchInput && call({ term: debouncedSearch }),
    [debouncedSearch, isValidSearchInput],
  );
  /**
   * setFieldValue, clean query
   */
  const handleSelect = (item) => {
    if (!item) return;
    const parsedItem = parseItem ? parseItem(item) : item;
    setFieldValue(name, isMultiple ? [...value, parsedItem] : parsedItem);
    setQuery(' ');
  };

  const filteredList = useMemo(() => {
    const filterCriteria = filterListProp || labelKey;
    const resultList = result.content || result;
    const prodArray = filter(prop('prodArray'), resultList);
    const list = prodArray.length ? compose(map(last), pluck(['prodArray']))(resultList) : resultList;
    if (isMultiple) {
      const cmp = (x, y) => x[filterCriteria] === y[filterCriteria];
      return differenceWith(cmp, list, value);
    }
    return reject((item) => item[labelKey] === value[labelKey], list);
  }, [result, value, isMultiple, labelKey, filterListProp]);

  const handleDelete = (item) => {
    if (isMultiple) {
      setFieldValue(
        name,
        reject((val) => val.id === item.id, value),
      );
    } else {
      setFieldValue(name, {});
    }
  };

  const handleConfirmChange = () => {
    const fieldName = `${name}.${editableIndex}.${props.labelKey}`;
    setFieldValue(fieldName, editingFieldCache);
    setEditingFieldCache(undefined);
    setEditableIndex(undefined);
  };

  const handleEnter = (e) => {
    if (e.keyCode !== 13) return;
    // Don't update form if the value is empty
    if (isValid) handleConfirmChange();
    // Prevent the form from being submitted on Enter key.
    e.preventDefault();
  };

  const renderValueList = () => (
    <List dense className={classes.valueList}>
      {displayList.map((item, index) => {
        const displayValue = item[labelKey];
        const fieldName = `${name}.${index}.${props.labelKey}`;
        const isBeingEdited = index === editableIndex;

        return (
          <ListItem className={classes.listItem} key={item[props.valueKey] || item.id}>
            {isBeingEdited ? (
              <InputText
                name={fieldName}
                onChange={(e) => setEditingFieldCache(e.target.value)}
                onKeyDown={handleEnter}
                value={editingFieldCache}
                testId="editListItem"
              />
            ) : (
              <ListItemText children={displayValue} data-testid={concatStrings('-')('ListItemText', testId)} />
            )}
            {isBeingEdited && (
              <>
                <DoneIcon
                  disabled
                  color={isValid ? 'primary' : 'disabled'}
                  onClick={() => isValid && handleConfirmChange()}
                />
                <CloseIcon
                  color="error"
                  onClick={() => {
                    setEditableIndex(undefined);
                    setEditingFieldCache(undefined);
                  }}
                />
              </>
            )}

            {!readOnly && !isEditing && (
              <>
                {isEditable && (
                  <EditIcon
                    className={classes.itemIcon}
                    onClick={() => {
                      setEditingFieldCache(displayValue);
                      setEditableIndex(index);
                    }}
                  />
                )}
                <DeleteIcon className={classes.itemIcon} onClick={() => handleDelete(item)} />
              </>
            )}
          </ListItem>
        );
      })}
    </List>
  );

  const handleCreate = async () => {
    try {
      // eslint-disable-next-line @typescript-eslint/no-shadow
      const result = await createCall({ name: query });
      setFieldValue(name, isMultiple ? [...value, result] : result);
      setQuery(' ');
      toggleList(false);
    } catch {
      showSnackbar({ message: t('genericError') });
    }
  };

  return (
    <Grid container style={{ position: 'relative' }}>
      {readOnly && (
        <Text variant="formLabel" bold>
          {label}
        </Text>
      )}
      {!readOnly && (
        <Box className={classes.textInput}>
          <TypeAhead
            name={name}
            label={label}
            loading={loading}
            placeholder=""
            readOnly={readOnly}
            disabled={disabled || isSubmitting}
            options={filteredList}
            onInputChange={setQuery}
            onSelect={handleSelect}
            value={query}
            creatableText={createCall ? t('forms.createNew') : null}
            onCreate={createCall ? handleCreate : null}
            createDisabled={createDisabled}
            testId={testId}
            {...props}
          />
        </Box>
      )}
      {renderValueList()}
    </Grid>
  );
};

FormSearchList.propTypes = {
  call: PropTypes.func.isRequired,
  createCall: PropTypes.func,
  field: FieldPropTypes.field,
  form: FieldPropTypes.form,
  isEditable: PropTypes.bool,
  isMultiple: PropTypes.bool,
  label: PropTypes.string,
  disabled: FieldPropTypes.disabled,
  // Allows to implement a custom parser to define the values that are going to be set in the form onSelect
  parseItem: PropTypes.func,
  readOnly: FieldPropTypes.readOnly,
  labelKey: PropTypes.string,
  valueKey: PropTypes.string,
  filterListProp: PropTypes.string,
  isCreateDisabled: PropTypes.func,
};

FormSearchList.defaultProps = {
  labelKey: 'name',
  isEditable: false,
  isMultiple: false,
  valueKey: 'id',
  isCreateDisabled: always(false),
};
export default withFormField(FormSearchList);
