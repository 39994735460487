/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import { Row, Text } from 'components/styleguide';
import { blankTerritoryRow } from 'redux-core/rights/actions';
import { useFormikContext } from 'formik';
import { Grid, Box } from '@material-ui/core';
import { useRootTranslation } from 'utils/hooks';
import { FormInputText, FormCheckbox, FormFieldArray } from 'components/common/Form/Fields/index';
import Territory from '../Territory';
import TermOptions from '../TermColumn';
import enhancer from './enhancer';
import CollapsePanelOffsetIcon from '../CollapsePanelOffsetIcon';

const RightsPanelGridExpanded = ({ showDefaultPreset, isEditing, spacing, hideSettingsFields }) => {
  const t = useRootTranslation('rights.rowExpanded');
  const tGlobalForms = useRootTranslation('global.forms');

  const { validateForm } = useFormikContext();

  const sharedProps = {
    placeholder: isEditing ? '' : '-',
    readOnly: !isEditing,
  };

  return (
    <>
      <Row spacing={spacing} py={2} pb={showDefaultPreset ? undefined : 4}>
        <Grid item xs={4}>
          <Row>
            <Text bold variant="h6">
              {hideSettingsFields ? t('name') : t('presetName')}
            </Text>
            <FormInputText {...sharedProps} label="" name="name" testId="name" />
          </Row>
          {showDefaultPreset && !hideSettingsFields && (
            <Row>
              <FormCheckbox {...sharedProps} label={t('defaultPreset')} name="default" />
            </Row>
          )}
        </Grid>
        <Grid item xs={8}>
          <Text bold variant="h6">
            {t('descriptionOnLetters')}
          </Text>
          <FormInputText
            {...sharedProps}
            label=""
            name="descriptionOnLetters"
            textArea
            rows={6}
            testId="descriptionOnLetters"
          />
        </Grid>
      </Row>
      <FormFieldArray name="territories">
        {(arrayHelpers, formik) => (
          <>
            {formik.values.territories.map((terr, index) => (
              <React.Fragment key={`${terr.id}${index}`}>
                <Territory
                  {...sharedProps}
                  isEditing={isEditing}
                  index={index}
                  onRemoveRow={async () => {
                    await arrayHelpers.remove(index);
                    validateForm();
                  }}
                  territory={terr}
                />
                <TermOptions
                  {...sharedProps}
                  isEditing={isEditing}
                  index={index}
                  setFieldValue={formik.setFieldValue}
                />
              </React.Fragment>
            ))}
            {isEditing && (
              <Box display="flex" alignItems="center">
                <CollapsePanelOffsetIcon
                  onClick={async () => {
                    await arrayHelpers.push(blankTerritoryRow);
                    validateForm();
                  }}
                >
                  {tGlobalForms('add')}
                </CollapsePanelOffsetIcon>
              </Box>
            )}
          </>
        )}
      </FormFieldArray>
    </>
  );
};

RightsPanelGridExpanded.propTypes = {
  isEditing: PropTypes.bool,
  showDefaultPreset: PropTypes.bool,
  spacing: PropTypes.number,
  hideSettingsFields: PropTypes.bool,
};

RightsPanelGridExpanded.defaultProps = {
  right: {},
  spacing: 6,
  isEditing: true,
  showDefaultPreset: true,
};

export default enhancer(RightsPanelGridExpanded);
