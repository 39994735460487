const ChipStyles = () => ({
  label: {
    fontSize: '11px',
    color: '#0D2549',
  },
  root: {
    opacity: '0.55',
  },
  clickable: {
    opacity: '1',
  },
});

export default ChipStyles;
