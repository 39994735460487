import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
  finishLabel: {
    marginTop: theme.spacing(),
  },

  finishRow: {
    marginBottom: theme.spacing(),
  },
  finishTile: {
    marginBottom: theme.spacing(),
  },
}));

export default styles;
