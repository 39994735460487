/* eslint-disable react/no-children-prop */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable import/no-cycle */
import { Box } from '@mui/system';
import { withStyles, Grid } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import findIndex from 'ramda/src/findIndex';
import propEq from 'ramda/src/propEq';
import { Suspense, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DRAWERS } from 'components/common/Drawers/constants';
import { Button, Loading, Text, Tabs } from 'components/styleguide';
import ListContainer from 'components/TopBarSlot/ListContainer';

import { listStyles } from '../styles';
import { SEARCH_TYPES } from '../index';

const List = withStyles(listStyles)(ListContainer);

const SearchResults = ({
  isLoading,
  searchResults,
  navigate,
  classes,
  currentSearchTypes,
  setSearchTypes,
  openDrawer,
  search,
}) => {
  const { t } = useTranslation();

  const tabs = [
    {
      label: 'globalSearch.tabs.productions',
      searchTypes: SEARCH_TYPES.PRODUCTION,
    },
    {
      label: 'globalSearch.tabs.tracks',
      searchTypes: SEARCH_TYPES.TRACK,
    },
  ];

  const [currentTabIndex, setCurrentTab] = useState(findIndex(propEq('searchTypes', currentSearchTypes))(tabs));

  if (isLoading) {
    return <Loading />;
  }

  const showResultsList = searchResults?.length > 0;

  return (
    <Tabs
      onTabChange={(index) => {
        const tabClicked = tabs[index];
        setSearchTypes(tabClicked.searchTypes);
        setCurrentTab(index);
      }}
      currentTab={currentTabIndex}
      tabList={tabs.map((tab) => t(tab.label))}
      EndAdornment={() => (
        <Box sx={{ display: 'grid', placeItems: 'center', height: '100%', pr: '1rem', pt: '1rem' }}>
          <Button
            variant="text"
            size="small"
            onClick={() => {
              openDrawer(DRAWERS.GLOBAL_SEARCH_FILTER, { search });
            }}
            startIcon={<SearchIcon data-testid="search-icon" />}
          >
            {t('globalSearch.advancedSearch')}
          </Button>
        </Box>
      )}
    >
      <Suspense fallback={<Loading />}>
        {showResultsList && (
          <Box mt={1}>
            <List
              data-testid="topbar-search-results"
              title="topbar.results"
              list={searchResults}
              hasImage
              hideStatus
              navigate={navigate}
            />
          </Box>
        )}
        {!showResultsList && (
          <Grid className={classes.noResults} item xs={12}>
            <Text variant="subtitle2" children={t('global.noResults')} />
          </Grid>
        )}
      </Suspense>
    </Tabs>
  );
};

export default SearchResults;
