/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { BaseDrawer } from 'components/styleguide';
import EditDeleteItemsList from 'components/common/EditDeleteItemsList/';
import { useRootTranslation } from 'utils/hooks';
import { FormDebouncedTypeAhead } from 'components/common/Form/Fields';
import { getQwireTracksLibrariesCall } from 'redux-core/tracks/services';
import { CommonDrawerPropTypes } from '../constants';

const rootT = 'drawers.productionSetup.blanketAgreements';

function CustomInput(props) {
  // eslint-disable-next-line react/destructuring-assignment
  const [defaultInput] = useState(props.value);
  return (
    <FormDebouncedTypeAhead
      {...props}
      call={(term) => getQwireTracksLibrariesCall({ term })}
      defaultInput={defaultInput}
      valueKey="name"
    />
  );
}

function BlanketAgreementsDrawer({ name, onClose, payload: { onSubmit, blanketAgreements = [], readOnly } }) {
  const t = useRootTranslation(rootT);
  const handleSubmit = (values) => {
    onSubmit({ blanketAgreements: values });
  };
  return (
    <EditDeleteItemsList
      name={name}
      deletePromptContent={(blanket) => t('delete', blanket)}
      drawerTitle={`${rootT}.drawerTitle`}
      onClose={onClose}
      onSubmit={handleSubmit}
      initialValues={blanketAgreements}
      CustomInput={CustomInput}
      readOnly={readOnly}
    />
  );
}

BlanketAgreementsDrawer.propTypes = {
  name: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  payload: PropTypes.object.isRequired,
};

function Drawer({ open, onClose, ...props }) {
  return (
    <BaseDrawer open={open} onClose={onClose}>
      <BlanketAgreementsDrawer onClose={onClose} {...props} />
    </BaseDrawer>
  );
}

Drawer.propTypes = CommonDrawerPropTypes;

export default Drawer;
