import { connect } from 'react-redux';
import { quickCreateProject, savePosterId, keepSetupInfo, cleanSetupFlow } from 'redux-core/productions/actions';
import { getDialogRightsByNameSelector } from 'redux-core/dialogs/selectors';

const mapStateToProps = (state, ownProps) => ({
  rights: getDialogRightsByNameSelector(state, ownProps.name),
});

const mapDispatchToProps = {
  quickCreateProject,
  savePosterId,
  keepSetupInfo,
  cleanSetupFlow,
};

export default connect(mapStateToProps, mapDispatchToProps);
