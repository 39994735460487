import { useCallback, useEffect } from 'react';
import { useAtomValue, useSetAtom } from 'jotai';
import { useFormContext, useWatch } from 'react-hook-form';
import { Autocomplete, createFilterOptions, TextField } from '@mui/material';
import InfoIconFilled from '@material-ui/icons/Info';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { TSeason, useGetSeasonsQuery } from 'services/common/seasons';
import { capitalize } from 'utils/object';
import { FormFieldForwardedProps } from 'components/common/Form/Fields/FormField/FormField';
import { PRODUCTION_TYPES } from 'utils/constants';
import { rootTranslation } from 'utils/format';
import { releaseIdAtom, seasonAtom } from '../seasonStore';
import { TProductionSeason } from '../../drawerStore';

const t = rootTranslation('drawers.quickSetupProduction');
const globalT = rootTranslation('global');

type TCreateOption = { name: string; inputValue: string };

const filter = createFilterOptions<TSeason | TCreateOption>();

type SeasonReleaseProps = FormFieldForwardedProps;

const SeasonRelease = ({ value, onChange, ...rest }: SeasonReleaseProps) => {
  const season = useAtomValue(seasonAtom) as TProductionSeason;
  const seasonId = season?.id;
  const setReleaseId = useSetAtom(releaseIdAtom);
  const { setValue: setFieldValue } = useFormContext();
  const seasonFormValue = useWatch({
    name: 'season',
  });

  const { data: seasonReleases = [], isLoading } = useGetSeasonsQuery(
    // @ts-ignore
    { parentId: seasonId, type: capitalize(PRODUCTION_TYPES.SEASON_RELEASE) },
    {
      skip: !seasonId,
    },
  );

  useEffect(() => {
    if (seasonReleases?.length) {
      const firstSeason = seasonReleases.at(0);
      onChange(firstSeason.name);
      setFieldValue('releaseId', firstSeason.id);
      setReleaseId(firstSeason.id);
    }
  }, [onChange, seasonReleases, setFieldValue, setReleaseId]);

  const handleSelect = useCallback(
    (release) => {
      if (release) {
        setFieldValue('releaseId', release.id);
        setFieldValue('episodes', undefined);
      }
      setReleaseId(release?.id);
    },
    [setFieldValue, setReleaseId],
  );

  const handleCreate = useCallback(
    (release) => {
      onChange(release);
      setFieldValue('releaseId', undefined);
      setFieldValue('episodes', undefined);
    },
    [onChange, setFieldValue],
  );

  return seasonReleases && seasonReleases.length ? (
    <Autocomplete<TSeason | TCreateOption, false, false, true>
      size="small"
      disabled={!seasonFormValue}
      loading={isLoading}
      value={value}
      isOptionEqualToValue={(option, optionValue) => option.name === optionValue.name}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
          return option;
        }
        // Add "xxx" option created dynamically
        if ('inputValue' in option) {
          return option.inputValue;
        }
        // Regular option
        return option.name;
      }}
      options={seasonReleases ?? []}
      onChange={(_, newValue) => {
        if (typeof newValue === 'string') {
          handleCreate(newValue);
        } else if ('inputValue' in newValue) {
          // Create a new value from the user input
          handleCreate(newValue.inputValue);
        } else {
          handleSelect(newValue);
        }
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        const { inputValue } = params;
        // Suggest the creation of a new value
        const isExisting = options.some((option) => inputValue === option.name);
        if (inputValue !== '' && !isExisting) {
          filtered.push({
            name: `${globalT('forms.createNew')} "${inputValue}"`,
            inputValue,
          });
        }
        return filtered;
      }}
      renderInput={(params) => <TextField {...params} {...rest} />}
      freeSolo
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      renderOption={(props, option) => <li {...props}>{option.name}</li>}
    />
  ) : (
    <TextField
      {...rest}
      value={value}
      onChange={onChange}
      name="release"
      data-testid="input-text-release"
      placeholder={t('releasePlaceholder')}
      disabled={!seasonFormValue}
      InputProps={{
        endAdornment: (
          <Tooltip title={t('releaseToolTip')} placement="bottom-start">
            <IconButton size="small" color="primary">
              <InfoIconFilled />
            </IconButton>
          </Tooltip>
        ),
      }}
    />
  );
};

export default SeasonRelease;
