import { Box, Grid } from '@material-ui/core';
import { Text } from 'components/styleguide';

// eslint-disable-next-line import/no-named-as-default
import DRAWERS from 'components/common/Drawers/constants';
import { COMMON_PERMISSIONS } from 'redux-core/permissions/constants';
import { rootTranslation } from 'utils/format';

import { useCanAccessDivisionSelector, useDrawer } from 'utils/hooks';
import useStyles from './styles';

const t = rootTranslation('globalSearch.noResults');

export const NoResultsForProduction = () => {
  const classes = useStyles();

  const CREATE_PERMISSIONS = { permissions: COMMON_PERMISSIONS.CREATE };
  const canCreate = useCanAccessDivisionSelector(CREATE_PERMISSIONS, false);
  const openDrawer = useDrawer();

  return (
    <Grid className={classes.noResults} container justifyContent="center" alignItems="center">
      <Box my={10}>
        <Grid xs={12} container justifyContent="center">
          <Box mb={1}>{t('description')}</Box>
        </Grid>
        <Grid xs={12} container justifyContent="center">
          <Box mb={1}>
            {t('clear')} {t('yourSearch')}
          </Box>
        </Grid>
        {canCreate && (
          <Grid xs={12} container justifyContent="center">
            <Box>
              {t('or')}{' '}
              <Text color="blue" display="inline" onClick={() => openDrawer(DRAWERS.PRODUCTION_QUICK_SETUP_PRODUCTION)}>
                {t('addProject')}
              </Text>
            </Box>
          </Grid>
        )}
      </Box>
    </Grid>
  );
};
