import { handleActions } from 'redux-actions';
import * as actions from './actions';

const defaultState = {
  open: false,
  menuOpen: false,
};

const promptReducer = {
  [actions.openPrompt]: (state, { payload: prompt }) => ({ ...state, ...prompt, open: true }),
  [actions.closePrompt]: () => ({ ...defaultState }),
  [actions.setMenuOpen]: (state, { payload }) => ({ ...state, menuOpen: payload }),
};

export default handleActions(promptReducer, defaultState);
