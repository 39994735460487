import { FieldArray } from 'formik';

import { InviteUserRow } from './InviteUserRow';

export const DynamicInviteUsersFields = ({ roles }) => (
  <FieldArray name="inviteList">
    {(arrayHelpers) => {
        const addUser = () => {
          arrayHelpers.insert(arrayHelpers.form.values.inviteList.length, {
            transientId: Date.now(),
            email: null,
            defaultProjectRole: null,
          });
        };

        const removeUser = (index) => {
          if (arrayHelpers.form.values.inviteList.length === 1) {
            arrayHelpers.replace(index, {
              transientId: Date.now(),
              email: null,
              defaultProjectRole: null,
            });
            return;
          }
          arrayHelpers.remove(index);
        };

        return arrayHelpers.form.values.inviteList.map(
          (invitedUser, rowIndex) => {
            const lastRow =
              rowIndex === arrayHelpers.form.values.inviteList.length - 1;
            return (
              <InviteUserRow
                invitedUser={invitedUser}
                rowIndex={rowIndex}
                removeUser={removeUser}
                addUser={addUser}
                roles={roles}
                lastRow={lastRow}
              />
            );
          },
        );
      }}
  </FieldArray>
  );
