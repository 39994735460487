/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const SoundEffect = (props) => (
  <SvgIcon {...props} viewBox="0 0 28 23">
    <path stroke="#5F6368" strokeWidth="2" d="M0 14.179h5l2-4.5 3 12.5 4-22 4 22 3-12.5 2 4.5h5" />
  </SvgIcon>
);

export default SoundEffect;
