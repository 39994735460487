/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-nested-ternary */
/* eslint-disable consistent-return */
/* eslint-disable import/no-cycle */
import React, { useMemo, useRef, useState, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Select from 'react-select';
import prop from 'ramda/src/prop';
import compose from 'ramda/src/compose';
import { FormInputPropTypes } from 'components/styleguide/fields/types';
import withFormControl from 'components/styleguide/fields/withFormControl';
import { zIndexLevels } from 'components/styleguide';
import { SplitPropTypes } from 'utils/types';
import { concatStrings, isEmptyOrSpaces } from 'utils';
import withAdornments, { ADORNMENT_POSITION } from '../HOC/withAdornments';
import theme, { appColors } from '../theme';
import { OptionsPropTypes } from '../prop-types';
import InputText from '../fields/InputText';
import ReactSelectComponents from './components';
import useStyles, { OPTION_HEIGHT } from './styles';

const ADORNMENT_DEFAULT_SIZE = 24;

export const ACTION_TYPE = {
  SELECT: 'select-option',
  DESELECT: 'deselect-option',
  REMOVE: 'remove-value',
  POP_VALUE: 'pop-value',
  SET_VALUE: 'set-value',
  CLEAR: 'clear',
  CREATE: 'create-option',
};

/** Default set of components used in the Select */
const customComponents = {
  Control: ReactSelectComponents.Control,
  DropdownIndicator: ReactSelectComponents.DropdownIndicator,
  ClearIndicator: ReactSelectComponents.ClearIndicator,
  IndicatorSeparator: ReactSelectComponents.IndicatorSeparator,
  Menu: ReactSelectComponents.Menu,
  MenuList: ReactSelectComponents.MenuList,
  MultiValue: ReactSelectComponents.MultiValue,
  NoOptionsMessage: ReactSelectComponents.NoOptionsMessage,
  Option: ReactSelectComponents.Option,
  Placeholder: ReactSelectComponents.Placeholder,
  SingleValue: ReactSelectComponents.SingleValue,
  ValueContainer: ReactSelectComponents.ValueContainer,
};

const selectStylesForGroups = {
  container: (base) => ({
    ...base,
    position: 'relative',
  }),
  menuList: (base) => ({
    ...base,
    overflowX: 'hidden',
    overflowY: 'auto',
    position: 'static',
  }),
  group: (base) => ({
    ...base,
    '& > div:nth-child(2)': {
      transform: 'translate(9999px)',
      opacity: 0,
      position: 'absolute',
      left: 'calc(100% - 8px)',
      overflowY: 'auto',
      maxHeight: `${6 * OPTION_HEIGHT}px`,
      zIndex: zIndexLevels.one,
      border: '1px solid #c4c4c4',
      backgroundColor: appColors.white,
      transition: 'opacity 0.2s ease',
      transitionDelay: '1s',
      transitionProperty: 'transform',
    },
    '&:hover > div:nth-child(2)': {
      opacity: 1,
      /**
       * This is a temporary fix! The common Typeahead will be replaced by the MUI 5 Autocomplete.
       * Hardcoding translateY value since the submenu is only used in a single place - and changing
       * the it to `position: relative;` that would be the natural fix for the positioning,
       * messes the entire menu listbox.
       */
      transform: 'translateY(-110.5px)',
      transition: 'opacity 0.2s ease',
    },
  }),
  groupHeading: (base) => ({
    ...base,
    fontSize: '1em',
    margin: 'unset',
    position: 'relative',
    color: theme.palette.text.primary,
    cursor: 'pointer',

    '&::after': {
      content: '">"',
      display: 'block',
      position: 'absolute',
      right: '1em',
      top: 0,
      bottom: 0,
      zIndex: zIndexLevels.one,
      color: theme.palette.primary.main,
      fontWeight: 'bold',
    },
  }),
};

const TypeAhead = ({ testId, ...props }) => {
  const {
    autoFocus,
    disabled,
    isClearable,
    isMultiple,
    formatOptionLabel,
    loading,
    noResultsText,
    onBlur,
    onChange,
    creatableText,
    onCreate,
    createDisabled,
    onSelect,
    options,
    onInputChange,
    placeholder,
    readOnly,
    value,
    valueKey,
    labelKey,
    denseItem,
    headerLabel,
    displayValue,
    startAdornment,
    endAdornment,
    adornmentPosition,
    isDropdown,
    allowEmpty,
    maxVisibleItems,
    filterOption,
    alwaysRenderValue,
    showCaret,
    variant,
    dotId,
    menuPosition,
    name,
    menuPortalTarget,
    menuWidth,
    openOnMount,
    cleanOnMenuClose,
    textAlign,
    onFocus,
    forwardedRef,
    // inputProps,
    indicatorTabIndex,
    defaultValue,
    className,
    groupToMenu,
  } = props;
  const classes = useStyles(props);
  const [inputValue, setInputValue] = useState('');
  const AdornmentWrapper = (props) => <Box {...props} width={ADORNMENT_DEFAULT_SIZE} height={ADORNMENT_DEFAULT_SIZE} />;

  const innerRef = useRef();
  const selectRef = forwardedRef || innerRef;
  const controlContainerName = `menu-${name}`;
  const [topPosition, setTopPosition] = useState(undefined);
  const [isOpen, setIsOpen] = useState(false);

  const selectStyles = {
    input: (base) => ({
      ...base,
      color: theme.palette.text.primary,
      '& input': {
        font: 'inherit',
      },
    }),
    menuPortal: (base) => ({
      ...base,
      top: topPosition ?? base.top,
      zIndex: zIndexLevels.modal,
      width: 'max-content',
      minWidth: adornmentPosition ? base.width + ADORNMENT_DEFAULT_SIZE + 4 : base.width,
    }),
  };

  let newStyles = selectStyles;

  // If prop is passed as true, merge styles to _transform_ react-select's groups to submenus
  if (groupToMenu === true) {
    newStyles = Object.assign(selectStyles, selectStylesForGroups);
  }

  useEffect(() => {
    if (openOnMount) {
      selectRef.current.focus();
      setIsOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openOnMount]);

  const forceRerenderToRealignDropdownOptions = useReducer(() => ({}))[1];
  useEffect(() => {
    const resizeListener = () => {
      forceRerenderToRealignDropdownOptions();
    };

    window.addEventListener('resize', resizeListener);
    return () => {
      window.removeEventListener('resize', resizeListener);
    };
  }, [forceRerenderToRealignDropdownOptions]);

  const handleChange = (selected, { action }) => {
    if (action === ACTION_TYPE.CREATE) {
      return onCreate(selected);
    }

    if (!selected) {
      onSelect(isMultiple ? [] : null);
      return onChange(isMultiple ? [] : null);
    }

    if (isMultiple) {
      /** in this case, selected will be an array of selected options */
      const newValues = selected.map((option) => option[valueKey]);
      onSelect(selected);
      return onChange(newValues);
    }
    onSelect(selected);
    onChange(selected[valueKey]);
  };

  const selected = useMemo(() => {
    if (value && alwaysRenderValue) {
      return {
        [labelKey]: value,
      };
    }
    if (!value && value !== false) {
      return '';
    }
    if (!options.length) {
      return value;
    }
    if (isMultiple) {
      return options.filter((o) => value.includes(o[valueKey]));
    }

    let selectedOption = null;
    // eslint-disable-next-line array-callback-return
    options.some((o) => {
      if (o[valueKey] === value) {
        selectedOption = o;

        return true;
      }
      if (o.options) {
        // eslint-disable-next-line array-callback-return
        o.options.some((subOption) => {
          if (subOption[valueKey] === value) {
            selectedOption = subOption;

            return true;
          }
        });
      }
    });

    return selectedOption;
  }, [options, value, isMultiple, valueKey, alwaysRenderValue, labelKey]);

  if (readOnly) {
    return (
      <InputText
        readOnly
        textAlign={textAlign}
        testId={testId}
        value={selected ? (isMultiple ? selected.map(prop(labelKey)).join(', ') : prop(labelKey, selected)) : ''}
      />
    );
  }

  const adornments = {
    [ADORNMENT_POSITION.START]: {
      startAdornment: <AdornmentWrapper>{startAdornment}</AdornmentWrapper>,
    },
    [ADORNMENT_POSITION.END]: {
      endAdornment: <AdornmentWrapper>{endAdornment}</AdornmentWrapper>,
    },
  };

  const Components = {
    Control: props.ComponentControl,
    DropdownIndicator: props.ComponentDropdownIndicator,
    ClearIndicator: props.ComponentClearIndicator,
    IndicatorSeparator: props.ComponentIndicatorSeparator,
    Menu: props.ComponentMenu,
    MenuList: props.ComponentMenuList,
    MultiValue: props.ComponentMultiValue,
    NoOptionsMessage: props.ComponentNoOptionsMessage,
    Option: props.ComponentOption,
    Placeholder: props.ComponentPlaceholder,
    SingleValue: props.ComponentSingleValue,
    ValueContainer: props.ComponentValueContainer,
  };

  const handleCloseMenu = () => {
    setTopPosition(undefined);
    setIsOpen(false);
    if (cleanOnMenuClose) setInputValue('');
    // Return true, so that react-select closes the menu onScroll
    return true;
  };

  const handleScroll = () => {
    if (!isOpen) return;
    const el = document.getElementById(controlContainerName);
    // If can't find the element, then close the menu on scroll.
    if (!el) return handleCloseMenu();
    const { top, bottom, height } = el.getBoundingClientRect();
    // If the typeahead is out of the viewport, close it.
    if (top < 0 || bottom > (window.innerHeight || document.documentElement.clientHeight)) {
      return handleCloseMenu();
    }
    // If the user scrolled and the typeahead is still inside the viewport, update its fixed position.
    setTopPosition(top + height);
    return false;
  };

  const handleOnCreate = async (val) => {
    await onCreate(val);
    setInputValue('');
    handleCloseMenu();
  };

  const handleOnBlur = async (e) => {
    const { value: val } = e.target;
    // onCreate is defined and the value is not empty and the value is not all blank spaces
    if (onBlur) onBlur(e);
    if (onCreate && !isEmptyOrSpaces(val)) await handleOnCreate(val);
  };
  return (
    <Select
      autoFocus={autoFocus}
      classes={classes}
      className={className}
      components={Components}
      isDisabled={disabled}
      menuIsOpen={isOpen}
      extraProps={{
        dotId,
        creatableText,
        denseItem,
        displayValue,
        loading,
        noResultsText,
        isDropdown,
        isOpen,
        allowEmpty,
        name,
        showCaret,
        controlContainerName,
        menuWidth,
        onCreate: onCreate ? handleOnCreate : undefined,
        createDisabled,
        indicatorTabIndex,
      }}
      isClearable={allowEmpty ? true : isDropdown ? false : isClearable}
      formatGroupLabel={headerLabel}
      isMulti={isMultiple}
      menuPortalTarget={menuPortalTarget}
      menuPosition={menuPosition}
      onBlur={handleOnBlur}
      onChange={handleChange}
      onMenuClose={handleCloseMenu}
      onMenuOpen={() => setIsOpen(true)}
      closeMenuOnScroll={handleScroll}
      inputProps={{
        ...adornments[adornmentPosition],
        'data-testid': concatStrings('-')('typeahead-field', testId),
      }}
      inputValue={inputValue}
      onInputChange={(input, action) => {
        // Keep input text after leaving the field
        if (action.action !== 'input-blur' && action.action !== 'menu-close') {
          onInputChange(input);
          setInputValue(input);
        }
      }}
      options={headerLabel ? [{ options }] : options}
      placeholder={placeholder || ''}
      styles={newStyles}
      inputId={name}
      value={selected || defaultValue}
      formatOptionLabel={formatOptionLabel}
      getOptionLabel={prop(labelKey)}
      getOptionValue={prop(valueKey)}
      isSearchable={!isDropdown}
      ref={selectRef}
      maxMenuHeight={maxVisibleItems ? OPTION_HEIGHT * maxVisibleItems : undefined}
      filterOption={filterOption}
      variant={variant}
      onFocus={onFocus}
      captureMenuScroll={!groupToMenu}
    />
  );
};

TypeAhead.propTypes = {
  ...FormInputPropTypes,
  creatableText: PropTypes.string,
  denseItem: PropTypes.bool,
  displayValue: PropTypes.func,
  formatOptionLabel: PropTypes.func,
  headerLabel: PropTypes.func,
  isClearable: PropTypes.bool,
  isMultiple: PropTypes.bool,
  openOnMount: PropTypes.bool,
  loading: PropTypes.bool,
  noResultsText: PropTypes.string.isRequired,
  onSelect: PropTypes.func,
  onCreate: PropTypes.func,
  dotId: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
  options: OptionsPropTypes,
  onInputChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
  /** If true and menu is NOT open, will display a caret in the typeahead
   * @default 'true'
   */
  showCaret: PropTypes.bool,
  /**
   * Name of the key property inside each { option } object
   * @default 'value'
   */
  valueKey: PropTypes.string,
  /**
   * Name of the value property inside each { option } object. Can be a function
   * that accepts an option object and returns a string
   * @default 'label'
   */
  labelKey: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  /** Whether typing for search is allowed */
  groupToMenu: PropTypes.bool,
  isDropdown: PropTypes.bool,
  filterOption: PropTypes.func,
  /** Remove border-radius to stack along other components */
  split: SplitPropTypes,
  /** Type of button  */
  variant: PropTypes.oneOf(['primary']),
  alwaysRenderValue: PropTypes.bool,
  menuPortalTarget: PropTypes.any,
  /** Components used by react-select */
  ComponentControl: PropTypes.any,
  ComponentDropdownIndicator: PropTypes.any,
  ComponentClearIndicator: PropTypes.any,
  ComponentIndicatorSeparator: PropTypes.any,
  ComponentMenu: PropTypes.any,
  ComponentMenuList: PropTypes.any,
  ComponentMultiValue: PropTypes.any,
  ComponentNoOptionsMessage: PropTypes.any,
  ComponentOption: PropTypes.any,
  ComponentPlaceholder: PropTypes.any,
  ComponentSingleValue: PropTypes.any,
  ComponentValueContainer: PropTypes.any,
  /** Custom Menu Width in Pixels, defaults to the width of the input */
  menuWidth: PropTypes.number,
  createDisabled: PropTypes.bool,
};

TypeAhead.defaultProps = {
  creatableText: 'Create new',
  isClearable: true,
  noResultsText: 'No results',
  onInputChange: () => undefined,
  onSelect: () => undefined,
  onRemove: () => undefined,
  options: [],
  valueKey: 'id',
  labelKey: 'name',
  dotId: undefined,
  alwaysRenderValue: false,
  maxVisibleItems: 8,
  menuPosition: 'fixed',
  menuPortalTarget: document.body,
  showCaret: true,
  groupToMenu: false,
  ComponentControl: customComponents.Control,
  ComponentDropdownIndicator: customComponents.DropdownIndicator,
  ComponentClearIndicator: customComponents.ClearIndicator,
  ComponentIndicatorSeparator: customComponents.IndicatorSeparator,
  ComponentMenu: customComponents.Menu,
  ComponentMenuList: customComponents.MenuList,
  ComponentMultiValue: customComponents.MultiValue,
  ComponentNoOptionsMessage: customComponents.NoOptionsMessage,
  ComponentOption: customComponents.Option,
  ComponentPlaceholder: customComponents.Placeholder,
  ComponentSingleValue: customComponents.SingleValue,
  ComponentValueContainer: customComponents.ValueContainer,
};

export default compose(withAdornments, withFormControl)(TypeAhead);
