/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-shadow */
import React from 'react';
import { Field } from 'formik';
import { useTranslation } from 'react-i18next';
import TrackTypeFilter from 'components/common/TrackTypeFilter/index';
import { Box, FormControl, InputLabel } from '@material-ui/core';
import { withFormField } from '../../utils';

const Input = ({ field, name, form: { setFieldValue }, label, required, readOnly }) => {
  const { t } = useTranslation();
  const handleChange = (name) => () => setFieldValue(field.name, name);
  return (
    <FormControl fullWidth required={required}>
      {label && (
        <InputLabel disableAnimation shrink htmlFor={name} position="top">
          {t(label)}
        </InputLabel>
      )}
      <Box mt={0.25} ml={-0.75}>
        <TrackTypeFilter
          filter={{
            [field.value]: true,
          }}
          onChange={handleChange}
          readOnly={readOnly}
        />
      </Box>
    </FormControl>
  );
};

const FormikInput = (props) => <Field component={Input} {...props} />;

FormikInput.displayName = 'FormTrackFilter';

export default withFormField(FormikInput);
