import { Autocomplete, TextField } from '@mui/material';
import { FormFieldMUIProps } from 'components/common/Form/Fields/FormField/FormField';
import { TCategory, useGetCategoriesQuery } from 'services/common/categories';
import { rootTranslation } from 'utils/format';

const tGlobal = rootTranslation('global');

type CategoryFieldProps = FormFieldMUIProps & {
  value: number | null;
  onChange: (value: number) => void;
};

export function CategoryField({ value, onChange, ...rest }: CategoryFieldProps) {
  const { data, isLoading } = useGetCategoriesQuery();
  const selected = data?.find((it) => it.id === value);

  return (
    <Autocomplete<TCategory>
      id="categoryId"
      size="small"
      loading={isLoading}
      value={selected}
      onChange={(_, nextValue: TCategory) => onChange(nextValue.id)}
      disablePortal
      options={data ?? []}
      isOptionEqualToValue={(option, optionValue) => option?.id === optionValue?.id}
      getOptionLabel={(option: TCategory) => option?.name ?? ''}
      renderInput={(params) => <TextField {...params} {...rest} placeholder={tGlobal('forms.select')} />}
      ListboxProps={{
        style: {
          maxHeight: 100,
        },
      }}
    />
  );
}
