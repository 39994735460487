/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import ReactNumberFormat from 'react-number-format';

const NumberFormat = ({ inputComponent, onChange, placeholder, prefix, suffix, ...props }) => (
  <ReactNumberFormat
    {...props}
    customInput={inputComponent}
    onValueChange={onChange}
    placeholder={[prefix, placeholder, suffix].filter(Boolean).join('')}
    prefix={prefix}
    suffix={suffix}
  />
);

NumberFormat.propTypes = {
  allowNegative: PropTypes.bool,
  decimalScale: PropTypes.number,
  displayType: PropTypes.oneOf(['input', 'text']),
  inputComponent: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  prefix: PropTypes.string,
  suffix: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  placeholder: PropTypes.string,
};

NumberFormat.defaultProps = {
  allowNegative: false,
  displayType: 'input',
};

export default NumberFormat;
