/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-cycle */
import React from 'react';
import { TypeAhead } from 'components/styleguide';
import { getIn } from 'formik';
import { useTranslation } from 'react-i18next';
import { withFormField } from 'components/common/Form/utils';
import { FieldPropTypes } from '../prop-types';

const CustomTypeAhead = ({
  field,
  form: { touched, errors, isSubmitting, setFieldValue, setValues, values: formValues, validateForm },
  disabled,
  label,
  fullWidth,
  placeholder,
  options,
  onSelect,
  onCreate,
  testId,
  ...props
}) => {
  const { t } = useTranslation();
  const handleChange = (value) => {
    setFieldValue(field.name, value);
  };

  const handleSelect = (values) => {
    if (onSelect) {
      onSelect(values, {
        setFieldValue,
        values,
        formValues,
        setValues,
        validateForm,
      });
    }
  };

  const handleCreate = (values) => onCreate(values, { setFieldValue, values, formValues, setValues });

  return (
    <TypeAhead
      {...field}
      {...props}
      disabled={disabled || isSubmitting}
      error={t(getIn(touched, field.name) && getIn(errors, field.name))}
      fullWidth={fullWidth}
      label={t(label)}
      name={field.name}
      testId={testId}
      options={options}
      placeholder={t(placeholder)}
      onChange={handleChange}
      onSelect={handleSelect}
      onCreate={onCreate ? handleCreate : undefined}
    />
  );
};

CustomTypeAhead.propTypes = {
  field: FieldPropTypes.field,
  form: FieldPropTypes.form,
};

CustomTypeAhead.defaultProps = {
  // eslint-disable-next-line react/default-props-match-prop-types
  hideErrorHint: true,
};

export default withFormField(CustomTypeAhead);
