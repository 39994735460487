/* eslint-disable react/jsx-props-no-spreading */
// import { InputLabel } from '@material-ui/core';
import React from 'react';
import { ToggleButtonGroup } from 'components/styleguide';
import { withFormField } from 'components/common/Form/utils';
import { FormControlLabelWrapper } from '../FormField/FormField';

const ToggleButtonGroupWrapper = ({ children, disabled, field, form, label, onChange, ...props }) => {
  const { isSubmitting, setFieldValue } = form;
  const handleChange = onChange
    ? (e, value) => onChange(form, field, value)
    : (e, value) => setFieldValue(field.name, value);

  return (
    <FormControlLabelWrapper label={label} disabled={disabled} name={field.name}>
      <ToggleButtonGroup {...field} disabled={disabled || isSubmitting} onChange={handleChange} {...props}>
        {children}
      </ToggleButtonGroup>
    </FormControlLabelWrapper>
  );
};

ToggleButtonGroupWrapper.propTypes = ToggleButtonGroup.propTypes;

ToggleButtonGroupWrapper.displayName = 'ToggleButtonGroup';

export default withFormField(ToggleButtonGroupWrapper, { type: 'disabled' });
