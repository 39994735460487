/* eslint-disable no-console */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-use-before-define */
/* eslint-disable consistent-return */
/* eslint-disable default-param-last */
import { useCallback, useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { showGenericError, showSnackbar } from 'redux-core/global-error/actions';
import { useDispatch, useSelector } from 'react-redux';
import { getIn, useFormikContext } from 'formik';
import { getLocationKeySelector } from 'redux-core/router/selectors';
import { openPrompt } from 'redux-core/prompt/actions';
import equals from 'ramda/src/equals';
import filter from 'ramda/src/filter';
import isEmpty from 'ramda/src/isEmpty';
import isNil from 'ramda/src/isNil';
import map from 'ramda/src/map';
import { openDrawer } from 'redux-core/dialogs/actions';
import { getPermissionSelector, getPermissionsSelector } from 'redux-core/permissions/selectors';
import { hasPermissions } from 'utils';
import { getSecuredDivisionsSelector, getCanAccessDivisionSelector } from 'redux-core/divisions/selectors';
import { getProductionCurrencySelector } from 'redux-core/productions/selectors';
import { getIsQwireEmployeeSelector } from 'redux-core/auth/selectors';
import { DEFAULT_PAGINATION_RESPONSE } from './constants';
import { get } from './object';
import { getSeparator, convertCurrency, formatCurrency } from './format';

export const useDebounce = (value = '', delay = 750) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    // Set debouncedValue to value (passed in) after the specified delay
    const timeout = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(timeout);
    };
  }, [value, delay]);

  return debouncedValue;
};

export const useNumberFormat = () => {
  const { i18n } = useTranslation();
  const thousand = getSeparator(i18n.language, 'group');
  const decimal = getSeparator(i18n.language, 'decimal');
  return [thousand, decimal];
};

/**
 * @param {function} func
 * @param {array} [dependencies=[]] dependencies
 * @param {array} [defaultValue=[]] defaultValue
 * @param {function} [fallback=undefined] fallback
 * @return {Array<any | boolean | Function>}
 */
export const useFetch = (func, dependencies = [], defaultValue = [], fallback) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [value, setValue] = useState(defaultValue);

  const fetch = async (params) => {
    try {
      await setLoading(true);
      const res = await func(params);
      setValue(res || defaultValue);
      return res || defaultValue;
    } catch (e) {
      console.log('useFetch', e);
      if (fallback) return fallback();
      const status = get('request.status')(e);
      if (status !== 401) dispatch(showGenericError());
    } finally {
      await setLoading(false);
    }
  };

  useEffect(() => {
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);
  return [value, loading, fetch, setValue];
};

/**
 * This hook is useful to avoid refetching Drawers information when you come back from a Secondary Drawer
 * but still handle entity version errors
 */
export const useDrawerFormFetch = ({ id, secondaryOpened }, func, fallback) => {
  const locationKey = useSelector(getLocationKeySelector);
  const prevLocationKey = usePrevious(locationKey);
  const shouldRefetch =
    id &&
    // Previous reference gets lost when drawer is closed
    (!secondaryOpened || (prevLocationKey && locationKey !== prevLocationKey));

  return useFetch(() => shouldRefetch && func(), [id, locationKey], {}, fallback);
};

/**
 * Translation hook
 * @param {string} tRoot
 */
export const useRootTranslation = (tRoot = '', tFallback = null) => {
  const { t } = useTranslation();
  return useCallback(
    /**
     * @param {string} s a path to the i18n object
     */
    (s, params = null) => {
      const path = isEmpty(tRoot) ? s : `${tRoot}.${s}`;
      if (tFallback) return t([path, `${tFallback}.${s}`], params);
      return t(path, params);
    },
    [t, tRoot, tFallback],
  );
};

// @See https://usehooks.com/useLocalStorage/
export const useLocalStorage = (key, initialValue) => {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key);
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      // If error also return initialValue
      return initialValue;
    }
  });

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to local storage
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
      // Dispatch "storage" event
      window.dispatchEvent(new Event('storage'));
    } catch (error) {
      // A more advanced implementation would handle the error case
    }
  };

  const onStorageChange = useCallback(() => {
    const newValue = window.localStorage.getItem(key);
    setStoredValue(newValue);
  }, [setStoredValue, key]);
  useEffect(() => {
    window.addEventListener('storage', onStorageChange, true);
    return () => window.removeEventListener('storage', onStorageChange);
  }, [onStorageChange]);

  return [storedValue, setValue];
};

export const useFormSubscription = (field, callback, deps = []) => {
  const { dirty, resetForm, setFieldValue, values, initialValues } = useFormikContext();
  const value = getIn(values, field);
  useEffect(
    () => {
      if (!field || !callback) return;
      callback(value, setFieldValue, {
        initialValues,
        dirty,
        resetForm,
        subscribedFieldName: field,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [value, ...deps],
  );
};

/**
 * @see https://usehooks.com/usePrevious/
 */
export function usePrevious(value) {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef();

  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes

  // Return previous value (happens before update in useEffect above)
  return ref.current;
}

export const usePrompt = () => {
  const dispatch = useDispatch();
  const open = useCallback((props) => dispatch(openPrompt(props)), [dispatch]);
  return open;
};

export const useSnackbar = () => {
  const dispatch = useDispatch();
  const open = useCallback((props) => dispatch(showSnackbar(props)), [dispatch]);
  return open;
};

export const useDrawer = () => {
  const dispatch = useDispatch();
  const open = useCallback((drawer, payload) => dispatch(openDrawer(drawer, payload)), [dispatch]);
  return open;
};

/**
 * @see https://usehooks.com/useEventListener/
 */
export const useEventListener = (eventName, handler, element = window) => {
  // Create a ref that stores handler
  const savedHandler = useRef();

  // Update ref.current value if handler changes.
  // This allows our effect below to always get latest handler ...
  // ... without us needing to pass it in effect deps array ...
  // ... and potentially cause effect to re-run every render.
  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(
    () => {
      // Make sure element supports addEventListener
      const isSupported = element && element.addEventListener;
      if (!isSupported) return;

      // Create event listener that calls handler function stored in ref
      const eventListener = (event) => savedHandler.current(event);

      // Add event listener
      element.addEventListener(eventName, eventListener);

      return () => {
        element.removeEventListener(eventName, eventListener);
      };
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [eventName, element], // Re-run if eventName or element changes
  );
};

/**
 * Returns an event dispatcher function
 * @return {Function}
 */
export const useEventEmitter = (eventName, element = window) =>
  useCallback(
    (detail) => {
      const event = new CustomEvent(eventName, {
        detail,
      });
      window.dispatchEvent(event);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [eventName, element],
  );

export const useScript = (src) => {
  const [loading, setLoading] = useState(false);
  const [{ loaded, error }, setState] = useState({
    loaded: false,
    error: false,
  });

  useEffect(() => {
    // Don't load it again if already loaded
    if (document.getElementById(src)) {
      setState({
        loaded: true,
        error: false,
      });
      return;
    }
    setLoading(true);
    // Create script
    const script = document.createElement('script');
    script.setAttribute('id', src);
    script.setAttribute('src', src);
    script.async = true;
    // Script event listener callbacks for load and error
    const onScriptLoad = () => {
      setState({
        loaded: true,
        error: false,
      });
      setLoading(false);
    };
    const onScriptError = () => {
      setState({
        loaded: true,
        error: true,
      });
      setLoading(false);
    };
    script.addEventListener('load', onScriptLoad);
    script.addEventListener('error', onScriptError);
    // Add script to document body
    document.body.appendChild(script);
    // Remove event listeners on cleanup
    return () => {
      script.removeEventListener('load', onScriptLoad);
      script.removeEventListener('error', onScriptError);
    };
  }, [src]);

  return [loaded, loading, error];
};

export const useIntersect = (handler) => {
  const [nodes, setNodes] = useState([]);
  const observer = useRef(null);

  useEffect(() => {
    if (observer.current) observer.current.disconnect();
    observer.current = new window.IntersectionObserver(handler);
    const { current: currentObserver } = observer;
    nodes && nodes.forEach((n) => currentObserver.observe(n));
    return () => currentObserver.disconnect();
  }, [nodes, handler]);

  return [setNodes];
};

/**
 * Use it to handle Keyboard inputs
 * @see https://dev.to/spaciecat/keyboard-input-with-react-hooks-3dkm
 * @param {number} keyCode KeyCode @see https://css-tricks.com/snippets/javascript/javascript-keycodes/#article-header-id-1
 */
export const useKey = (keyCode) => {
  // Keep track of key state
  const [pressed, setPressed] = useState(false);

  // Bind and unbind events
  useEffect(() => {
    // Does an event match the key we're watching?
    const match = (event) => keyCode === event.keyCode;

    // Event handlers
    const onDown = (event) => {
      if (match(event)) setPressed(true);
    };

    const onUp = (event) => {
      if (match(event)) setPressed(false);
    };

    window.addEventListener('keydown', onDown);
    window.addEventListener('keyup', onUp);
    return () => {
      window.removeEventListener('keydown', onDown);
      window.removeEventListener('keyup', onUp);
    };
  }, [keyCode]);

  return [pressed];
};

export const useDeepCompareMemoize = (value) => {
  const ref = useRef();
  if (!equals(value, ref.current)) ref.current = value;
  return ref.current;
};

/**
 * Check if user has the required permissions over a given resource.
 * @param {{
 *  permissions?: number,
 *  clearPermissions?: number,
 *  cuePermissions?: number,
 *  objectId: number,
 * }} config
 */
export const usePermissions = ({ permissions: commonPermissions, clearPermissions, cuePermissions, objectId }) => {
  const userPermissions = useSelector(getPermissionSelector(objectId));
  const isQwireEmployee = useSelector(getIsQwireEmployeeSelector);

  if (!objectId) return true;
  if (!userPermissions) {
    if (process.env.NODE_ENV !== 'production') {
      console.log(`PERMISSION FOR OBJECT ${objectId} NOT FOUND IN STORE`);
    }
    return false;
  }
  const isAllowed = hasPermissions(
    { commonPermissions, clearPermissions, cuePermissions },
    userPermissions,
    true,
    isQwireEmployee,
  );
  return isAllowed;
};

export const useSecuredFilteredList = ({ objectId: defaultId, strategy = 'hide' }) => {
  const userPermissions = useSelector(getPermissionsSelector, equals);
  const isQwireEmployee = useSelector(getIsQwireEmployeeSelector);

  if (strategy === 'hide') {
    return filter(
      ({ permissions, cuePermissions, clearPermissions, objectId }) =>
        !(objectId || defaultId) ||
        hasPermissions(
          {
            commonPermissions: permissions,
            cuePermissions,
            clearPermissions,
          },
          userPermissions[objectId || defaultId],
          true,
          isQwireEmployee,
        ),
    );
  }

  return map(({ permissions, cuePermissions, clearPermissions, ...object }) => ({
    ...object,
    disabled:
      object.disabled ||
      !hasPermissions(
        {
          commonPermissions: permissions,
          cuePermissions,
          clearPermissions,
        },
        userPermissions[object.objectId || defaultId],
        true,
        isQwireEmployee,
      ),
  }));
};

export const useSecuredDivisions = (permissions = {}) => {
  const divisions = useSelector(getSecuredDivisionsSelector(permissions), equals);
  return divisions;
};

export const useCanAccessDivisionSelector = (permissions = {}, bypassLoading) => {
  const allowed = useSelector(getCanAccessDivisionSelector(permissions, bypassLoading));
  return allowed;
};

export const useProductionCurrency = (decimalScale = 2) => {
  const currency = useSelector(getProductionCurrencySelector, equals);
  return (value) => {
    if (isNil(value)) return '--';
    const convertedValue = convertCurrency(value, currency.exchangeRate, decimalScale);
    return formatCurrency(convertedValue, currency.selectedCurrencyCode || currency.masterCurrencyCode, decimalScale);
  };
};

/**
 * @param {function} call
 * @param {array} limit
 * @return {[Array<Object>, { page: Number, pageCount: Number, size: Number, totalCount: Number}, function, Boolean]}
 * @description This hook is intended to be used when you have to do controlled pagination, where you need to refetch data after creating, editing, deleting, etc.
 */
export const usePagination = (call, limit) => {
  const [{ pagination, data: items }, loading, refetch] = useFetch(
    async (params) => {
      if (!params?.limit) return;
      const response = await call(params);
      if (response) {
        /**
         * Set page count to the default paginated limit
         * This is because when refetching N pages, pageCount will be less because
         * the limit is greater (limit * pages)
         */
        response.pagination.pageCount = Math.ceil(response.pagination.totalCount / limit);
      }

      if (!response || response.pagination.page === 0) return response;
      return {
        ...response,
        data: items.concat(response.data),
      };
    },
    [],
    DEFAULT_PAGINATION_RESPONSE,
  );

  const fetchData = async (params) => {
    const results = await refetch({
      limit: Math.ceil(items.length / limit) * limit,
      ...params,
    });
    return results;
  };

  return [items, pagination, fetchData, loading];
};

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};

/**
 * @return {Object, { width: Number, height: Number }}
 * @description Returns window size.
 */
export const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    const handleResize = () => setWindowDimensions(getWindowDimensions());

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
};
