/* eslint-disable react/react-in-jsx-scope */
import { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useRootTranslation } from 'utils/hooks';
import { FormTypeAhead } from 'components/common/Form/Fields';
import { getAllClearancesWithUsesAndDuration } from 'redux-core/clearances/services';
import { fetchCueContainersLight } from 'redux-core/cueContainers/actions';

/**
 * Lists available scene/assets for track.
 * "getAll" param toggles queries and gets all
 *  project scenes
 *
 * @param {int} trackId - Track ID
 * @param {bool} getAll - Toggle between scenes/assets where the track is used and all available scenes/assets
 * @returns {array} list of scenes/assets
 */
const useGetCuesUsage = (trackId, getAll) => {
  const [cuesArray, setCuesArray] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const getAllClearances = useCallback(async () => {
    setLoading(true);
    const cueContainers = await dispatch(fetchCueContainersLight());
    setCuesArray(cueContainers);
    setLoading(false);
  }, [dispatch]);

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const getFilteredClearances = useCallback(async (trackId) => {
    // Clearance fetch
    setLoading(true);
    const allClearancesRes = await getAllClearancesWithUsesAndDuration({
      trackId,
    });

    // Get all individual Containers from Clearances
    const newCuesArr = [];
    allClearancesRes?.clearances?.forEach((clearance) => clearance?.containers?.forEach((val) => newCuesArr.push(val)));
    // Remove dupe entries
    const filteredCuesArr = newCuesArr.filter(
      (container, index, array) => array.findIndex((c) => c.id === container.id) === index,
    );

    setCuesArray(filteredCuesArr);
    setLoading(false);
  }, []);

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    getAll ? getAllClearances() : getFilteredClearances(trackId);
  }, [trackId, getFilteredClearances, getAllClearances, getAll]);

  return [cuesArray, loading];
};

export function CueContainerPicker({ trackId, getAll = false }) {
  const t = useRootTranslation();
  const [cuesArray, loading] = useGetCuesUsage(trackId, getAll);

  return (
    <FormTypeAhead
      isClearable={false}
      required
      name="cueContainerId"
      label={t('drawers.clearance.addEdit.sceneOrAsset')}
      disabled={loading}
      loading={loading}
      placeholder={t('global.forms.select')}
      options={cuesArray}
      labelKey="title"
      valueKey="id"
    />
  );
}
