import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/Cancel';
import { FormCheckbox, FormInputFormat, FormInputText } from 'components/common/Form/Fields';
import { FormTrackUseTypeSelect } from 'components/common/TrackUseTypeSelect';
import { CustomGridItem } from 'components/styleguide';
import { getIn, useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import { COMMON_TRACK_USE_TYPES } from 'utils/constants';
import { TIME_FORMATS } from 'utils/format';
import { useFetch, useRootTranslation } from 'utils/hooks';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import c from 'ramda/src/compose';
import filter from 'ramda/src/filter';
import flatten from 'ramda/src/flatten';
import map from 'ramda/src/map';
import dissoc from 'ramda/src/dissoc';
import { Autocomplete, TextField } from '@mui/material';
import { fetchCueContainersLight, refetchCueContainers } from 'redux-core/cueContainers/actions';
import { updateClearancesCues } from '../../../../../../../redux-core/clearances/services';
import { moveTrack } from '../../../../../../../redux-core/tracks/actions';
import useStyles from './styles';
import enhancer from './enhancer';

export const ScenesContainer = ({ cueContainers, currentContainerId, trackId, cue, qTrackId, refetch }) => {
  const options = cueContainers.map(({ id, title, number }) => ({ id, name: `${number || ''} - ${title}` }));
  const [selected, setSelected] = useState(options.filter((i) => i.id === currentContainerId)[0] || options[0]);
  const dispatch = useDispatch();

  const saveForm = async ({ cues: cuesRaw }) => {
    const cues = c(
      filter(({ id, deleted }) => !(id === null && deleted)) /** Remove NEW cues that were deleted */,
      flatten,
      map((cueIterable) =>
        cueIterable.cueContainerIds.map((id) => ({
          /** cue.cueContainerIds is an array of the containers the cue is at, create a new cue for each container */
          ...dissoc('cueContainerIds', cueIterable),
          cueContainerId: id,
          actualUseDuration: cueIterable.actualUseDuration || null,
          id: /newCue/.test(cueIterable.id)
            ? null
            : cueIterable.id /** If regex pass, it means it is a NEW cue and shouldn't carry an id */,
        })),
      ),
    )(cuesRaw);
    await updateClearancesCues({ trackId, cues });
  };

  const onChange = async (cueContainerFrom, cueContainerTo, toSceneName) => {
    await dispatch(moveTrack({
      cueContainerId: cueContainerTo,
      durationFull: cue.requestedUseDurationFull,
      duration: cue.requestedUseDuration,
      qwireTracksId: qTrackId,
      useType: cue.requestedUseType,
      actualUseType: cue.actualUseType,
      actualUseDuration: cue.actualUseDuration,
      qclearClearanceId: cue.qclearClearanceId,
      isMoveUsage: true,
      toSceneName,
    }, { isAdding: true }));
    await saveForm({ cues: [{ ...cue, deleted: true }] });
    await dispatch(refetchCueContainers());
    await refetch();
  };

  return (
    <Autocomplete
      fullWidth
      key={1}
      id="select"
      value={selected}
      onChange={(_, nextValue) => {
        onChange(selected.id, nextValue.id, nextValue.name.split('-')[1].trim());
        setSelected(nextValue);
      }}
      options={options}
      isOptionEqualToValue={(option, optionValue) => option?.id === optionValue?.id}
      getOptionLabel={(option) => option?.name ?? ''}
      renderInput={(params) => (
        /* eslint-disable-next-line react/jsx-props-no-spreading, no-restricted-globals */
        <TextField fullWidth {...params} name={name} />
      )}
      ListboxProps={{ width: 200 }}
    />
  );
};

// eslint-disable-next-line no-unused-vars
const CueRow = ({ name, canDelete, container, trackId, qTrackId, popCue, refetch }) => {
  const { values, setFieldValue } = useFormikContext();

  const classes = useStyles();
  const t = useRootTranslation('drawers.clearanceUsesAndDurations');

  const dispatch = useDispatch();
  const [allCueContainers, loading] = useFetch(() => dispatch(fetchCueContainersLight()));

  const isUseTypeOther = (field) => getIn(values, field) === COMMON_TRACK_USE_TYPES.OTHER;

  const cue = getIn(values, name);

  return (
    <>
      <Grid container className={classes.container} style={{ marginTop: '1em', marginBottom: '1em' }}>
        <Grid container className={classes.container}>
          <Grid item xs={4}>
            <Typography variant="body1">{t('scene')}</Typography>
          </Grid>
        </Grid>
        <Grid container className={classes.container}>
          <Grid item xs={4}>
            {!loading && <ScenesContainer
              cueContainers={allCueContainers}
              currentContainerId={container.id}
              trackId={trackId}
              cue={cue}
              qTrackId={qTrackId}
              popCue={popCue}
              refetch={refetch}
            />}
          </Grid>
        </Grid>
      </Grid>
      <Grid container className={classes.container}>
        <Grid item xs={4}>
          <Typography variant="body1">{t('reqUseAndDuration')}</Typography>
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={4}>
          <Typography variant="body1">{t('actUseAndDuration')}</Typography>
        </Grid>
      </Grid>
      <Grid container spacing={1} className={classes.container}>
        {!cue.requestedUseDurationFull && (
          <Grid item xs={2}>
            <CustomGridItem className={classes.duration}>
              <FormInputFormat format={TIME_FORMATS.MMSS} name={`${name}.requestedUseDuration`} type="time" />
            </CustomGridItem>
          </Grid>
        )}
        <Grid item xs={cue.requestedUseDurationFull ? 4 : 2}>
          <CustomGridItem>
            <Grid className={classes.usage}>
              <FormTrackUseTypeSelect name={`${name}.requestedUseType`} />
            </Grid>
          </CustomGridItem>
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={2}>
          <CustomGridItem className={classes.duration}>
            <FormInputFormat
              format={TIME_FORMATS.MMSS}
              name={`${name}.actualUseDuration`}
              usageType="requested"
              type="time"
            />
          </CustomGridItem>
        </Grid>
        <Grid item xs={2}>
          <CustomGridItem className={classes.usage}>
            <FormTrackUseTypeSelect name={`${name}.actualUseType`} usageType="actual" />
          </CustomGridItem>
        </Grid>
        <Grid item xs={1} alignItems="flex-end">
          {canDelete && (
            <div className={classes.deleteButtonWrapper}>
              <IconButton onClick={() => setFieldValue(name, { ...cue, deleted: true })} className={classes.iconButton}>
                <CancelIcon />
              </IconButton>
            </div>
          )}
        </Grid>
        <Grid item xs={12}>
          <FormCheckbox name={`${name}.requestedUseDurationFull`} label="Full Use Duration" />
        </Grid>
        {(isUseTypeOther(`${name}.requestedUseType`) || isUseTypeOther(`${name}.actualUseType`)) && (
          <>
            <Grid item xs={5}>
              {isUseTypeOther(`${name}.requestedUseType`) && (
                <FormInputText textArea label={t('requestedUsageNotes')} name={`${name}.requestedUsageNotes`} />
              )}
            </Grid>
            <Grid item xs={5}>
              {isUseTypeOther(`${name}.actualUseType`) && (
                <FormInputText textArea label={t('actualUsageNotes')} name={`${name}.actualUsageNotes`} />
              )}
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

CueRow.propTypes = {
  name: PropTypes.string.isRequired,
  canDelete: PropTypes.bool,
};

export default enhancer(CueRow);
