/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { always, cond, equals } from 'ramda';
import Score from '../Score';
import LibraryTrack from '../LibraryTrack';
import SoundEffect from '../SoundEffect';
import Track from '../Track';

const TRACK_TYPES = {
  SONG: 'song',
  SCORE: 'score',
  LIBRARY: 'library',
  SOUND_EFFECT: 'sound_effect',
};

const TrackTypeIcon = ({ type, ...props }) =>
  cond([
    [equals(TRACK_TYPES.LIBRARY), always(<LibraryTrack {...props} />)],
    [equals(TRACK_TYPES.SCORE), always(<Score {...props} />)],
    [equals(TRACK_TYPES.SONG), always(<Track {...props} />)],
    [equals(TRACK_TYPES.SOUND_EFFECT), always(<SoundEffect {...props} />)],
  ])(type);

TrackTypeIcon.propTypes = {
  type: PropTypes.oneOf(Object.values(TRACK_TYPES)),
};

TrackTypeIcon.defaultProps = {
  type: TRACK_TYPES.SONG,
};

export default TrackTypeIcon;
