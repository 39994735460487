import { connect } from 'react-redux';
import { closePrompt } from 'redux-core/prompt/actions';
import { getPromptDialogSelector } from 'redux-core/prompt/selectors';

const mapStateToProps = (state) => ({
  prompt: getPromptDialogSelector(state),
});

const mapDispatchToProps = {
  closePrompt,
};

export default connect(mapStateToProps, mapDispatchToProps);
