/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/no-children-prop */
import React from 'react';
import PropTypes from 'prop-types';
import { FormButton } from 'components/common/Form/Fields/index';
import { useRootTranslation } from 'utils/hooks';
import FormDrawerHeader from 'components/common/Form/Fields/FormDrawerHeader/index';
import enhancer from './enhancer';
import TaskDrawer, { TaskDrawerPayloadPropTypes } from '../index';
import { TaskComments } from '../components';

const EditTaskDrawerHeader = ({ onClose }) => {
  const t = useRootTranslation('drawers.tasks.create');

  const handleClose = () => {
    onClose();
  };

  return (
    <FormDrawerHeader title={t('editTask')} onClose={handleClose}>
      <FormButton
        alwaysEnabled
        variant="destructive"
        size="xs"
        testId="create-task-drawer-cancel"
        children="global.forms.cancel"
        onClick={() => handleClose}
      />

      <FormButton
        variant="primary"
        testId="create-task-drawer-save"
        size="xs"
        type="submit"
        children="global.forms.save"
      />
    </FormDrawerHeader>
  );
};

const EditTaskDrawer = ({ updateTask, onClose, closeAllDialogs, payload }) => {
  const handleSubmit = async (values) => {
    await updateTask(values);
    await payload.getTabTasks();
    closeAllDialogs();
  };

  return (
    <TaskDrawer
      payload={payload}
      headerContent={() => <EditTaskDrawerHeader onClose={onClose} />}
      onSubmit={handleSubmit}
    >
      <TaskComments taskId={payload.taskId} getTabTasks={payload.getTabTasks} />
    </TaskDrawer>
  );
};

EditTaskDrawer.propTypes = {
  closeAllDialogs: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  updateTask: PropTypes.func.isRequired,
  payload: TaskDrawerPayloadPropTypes.isRequired,
};

EditTaskDrawerHeader.propTypes = {
  onClose: EditTaskDrawer.propTypes.onClose,
};

export default enhancer(EditTaskDrawer);
