/* eslint-disable react/no-children-prop */
/* eslint-disable @typescript-eslint/no-shadow */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import find from 'ramda/src/find';
import compose from 'ramda/src/compose';
import filter from 'ramda/src/filter';
import last from 'ramda/src/last';
import map from 'ramda/src/map';
import mergeAll from 'ramda/src/mergeAll';
import prop from 'ramda/src/prop';
import propEq from 'ramda/src/propEq';
import propOr from 'ramda/src/propOr';
import values from 'ramda/src/values';

import { Button, DrawerHeader, Loading, SearchInput, Text } from 'components/styleguide';
import { DRAWERS } from 'components/common/Drawers/constants';
import { Dropzone } from 'components/styleguide/fields';
import { FILES_TYPES_OPTIONS } from 'utils/constants';
import { getFileAttachmentsCall } from 'redux-core/overview/services';
import { LettersListPropTypes } from 'components/Letters/constants';
import { useFetch, useDebounce, useRootTranslation } from 'utils/hooks';
import useStyles from './styles';
import enhancer from './enhancer';

const LettersExtraAttachmentsDrawer = ({
  fetchGetTrack,
  letters,
  onClose,
  openDrawer,
  payload: { licensorId, selectedAttachments, onSubmit },
}) => {
  const classes = useStyles();
  const t = useRootTranslation('drawers.lettersExtraAttachmentsDrawer');
  const globalT = useRootTranslation('global');
  const [term, setTerm] = useState('');
  const [filteredTrackFiles, setFilteredTrackFiles] = useState([]);
  const [filesWithCheckbox, setFilesWithCheckbox] = useState({});
  const debouncedFilterTerm = useDebounce(term, 400);

  const selectedLetter = find(propEq('licensorId', licensorId))(letters);
  const selectedLetterProdId = compose(prop('id'), last, propOr([], ['productionPath']))(selectedLetter);

  const [track] = useFetch(
    () => selectedLetter.trackId && fetchGetTrack({ id: selectedLetter.trackId }),
    [selectedLetter.trackId],
    {},
  );
  const [trackFiles, loading] = useFetch(
    () =>
      getFileAttachmentsCall({
        limit: 50,
        productionId: selectedLetterProdId,
        trackId: selectedLetter.trackId,
      }),
    [selectedLetter.trackId],
  );

  const trackFilesList = propOr([], 'data', trackFiles);
  const emptyList = !filteredTrackFiles.length;

  const translatedFileTypes = Object.assign(
    ...map(
      ({ id, name }) => ({
        [id]: name,
      }),
      FILES_TYPES_OPTIONS,
    ),
  );

  const selectedFiles = compose(filter(propEq('selected', true)), values)(filesWithCheckbox);

  const handleDropFile = (file) => {
    openDrawer(DRAWERS.TRACK_FILE, {
      track,
      file,
      productionId: selectedLetterProdId,
    });
  };

  useEffect(() => {
    const buildListWithCheckboxes = (track) => ({
      [track.id]: {
        ...track,
        selected: compose(propOr(false, 'selected'), find(propEq('id', track.id)))(selectedAttachments),
      },
    });

    setFilesWithCheckbox(
      compose(
        // Build an object from the list with files adding a selected key to create a dictionary of files
        // and populate/manage the checkboxes statuses easier
        mergeAll,
        map(buildListWithCheckboxes),
      )(trackFilesList),
    );
  }, [selectedAttachments, setFilesWithCheckbox, trackFilesList]);

  useEffect(() => {
    if (!debouncedFilterTerm) {
      setFilteredTrackFiles(trackFilesList);
    } else {
      setFilteredTrackFiles(
        compose(filter((track) => track.fileName.toLowerCase().includes(debouncedFilterTerm.toLowerCase())))(
          trackFilesList,
        ),
      );
    }
  }, [debouncedFilterTerm, setFilteredTrackFiles, trackFilesList]);

  return (
    <>
      <DrawerHeader title={t('title')} onClose={onClose}>
        <Button
          testId="letters-extra-attachments-drawer-cancel"
          variant="destructive"
          size="small"
          children={globalT('forms.cancel')}
          onClick={onClose}
        />
        <Button
          disabled={!selectedFiles.length}
          testId="letters-extra-attachments-drawer-attach"
          children={globalT('forms.attach')}
          size="small"
          variant="primary"
          onClick={() => {
            onSubmit(selectedFiles);
            onClose();
          }}
        />
      </DrawerHeader>
      <Grid container>
        <Grid item xs={7}>
          <SearchInput
            onChange={setTerm}
            value={term}
            placeholder={globalT('search')}
            adornmentPosition="end"
            variant="search"
            autoComplete="off"
          />
        </Grid>
        {loading ? (
          <Loading />
        ) : (
          <Box mt={2} width={1} className={classnames({ [classes.emptyState]: emptyList })}>
            {emptyList ? (
              <Dropzone
                name="fileName"
                onDrop={handleDropFile}
                className={classes.dropZone}
                testId="letters-extra-attachments-drawer-dropzone"
              />
            ) : (
              <Grid container spacing={1}>
                <Grid item xs={1} />
                <Grid item xs={5}>
                  <Text bold variant="formLabel">
                    {t('file.name')}
                  </Text>
                </Grid>
                <Grid item xs={5}>
                  <Text bold variant="formLabel">
                    {t('file.type')}
                  </Text>
                </Grid>
                <Dropzone
                  name="fileName"
                  onDrop={handleDropFile}
                  className={classes.dropZone}
                  testId="letters-extra-attachments-drawer-dropzone"
                  showChildrensAndDropzone
                >
                  {filteredTrackFiles.map((track) => (
                    <Grid container spacing={1} key={track.id} alignItems="center">
                      <Grid item xs={1}>
                        <Checkbox
                          checked={filesWithCheckbox[track.id].selected}
                          color="primary"
                          onChange={() =>
                            setFilesWithCheckbox({
                              ...filesWithCheckbox,
                              [track.id]: {
                                ...filesWithCheckbox[track.id],
                                selected: !filesWithCheckbox[track.id].selected,
                              },
                            })
                          }
                        />
                      </Grid>
                      <Grid item xs={5} className={classes.fileName}>
                        <Text>{track.fileName}</Text>
                      </Grid>
                      <Grid item xs={5}>
                        <Text>{translatedFileTypes[track.fileType]}</Text>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                    </Grid>
                  ))}
                </Dropzone>
              </Grid>
            )}
          </Box>
        )}
      </Grid>
    </>
  );
};

LettersExtraAttachmentsDrawer.propTypes = {
  fetchGetTrack: PropTypes.func.isRequired,
  letters: LettersListPropTypes,
  onClose: PropTypes.func.isRequired,
  openDrawer: PropTypes.func.isRequired,
  payload: PropTypes.shape({
    licensorId: PropTypes.number.isRequired,
    selectedAttachments: PropTypes.array,
    onSubmit: PropTypes.func.isRequired,
  }),
};

export default enhancer(LettersExtraAttachmentsDrawer);
