import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => {
  const MENU_ITEM_HEIGHT = theme.spacing(6);
  const DIVIDER_ITEM_HEIGHT = theme.spacing(3);
  const MAX_VISIBLE_ITEMS = 6;
  const DEFAULT_VERTICAL_PADDING = theme.spacing();
  return {
    menu: {
      maxHeight: `${MENU_ITEM_HEIGHT * MAX_VISIBLE_ITEMS + DIVIDER_ITEM_HEIGHT + DEFAULT_VERTICAL_PADDING * 2}px`,
    },
    icon: {
      justifyContent: 'center',
    },
    selected: {
      display: 'flex',
      alignItems: 'center',
    },
    divider: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      width: '100%',
    },
  };
});
