import { Skeleton, TextField, styled } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { FormControlLabelWrapper } from 'components/common/Form/Fields/FormField/FormField';
import { useGetTrackQuery } from 'services/tracks/tracks';
import { TGetTrack } from 'services/tracks/types';
import { LICENSOR_TYPES } from 'utils/constants';
import { rootTranslation } from 'utils/format';
import { LicensorsSection } from 'components/common/rightOwners/licensors/LicensorsSection';
import { CreatorSection } from 'components/common/rightOwners/creator/CreatorSection';
import { groupRightsOwners } from 'components/common/rightOwners/groupRightsOwners';

const t = rootTranslation('drawers.productionSetup.tracks.details');

type TrackDetailsProps = {
  trackId: number;
};

const StyledGridContainer = styled(Grid)(({ theme }) => ({
  padding: '1rem',
  backgroundColor: theme.palette.secondary[100],
  '& > .MuiGrid2-container:not(:first-of-type)': {
    margin: 0,
    marginTop: theme.spacing(2),
    padding: '1rem 0.5rem',
  },
}));

export function TrackDetails({ trackId }: TrackDetailsProps) {
  const { data, isFetching } = useGetTrackQuery({ id: trackId }, { skip: !trackId });

  if (isFetching) {
    return <LoadingPlaceholder />;
  }

  const rightOwnersMap = groupRightsOwners(data?.rightsOwners);

  return (
    <StyledGridContainer container>
      <Grid container spacing={2} xs={12}>
        <Grid xs={6}>
          <FormControlLabelWrapper name="title" label={t('title')}>
            <TextField id="title" inputProps={{ readOnly: true }} fullWidth value={data?.title} />
          </FormControlLabelWrapper>
        </Grid>
        <Grid xs={6}>
          <FormControlLabelWrapper name="artist" label={t('artist')}>
            <TextField id="artist" inputProps={{ readOnly: true }} fullWidth value={formatArtists(data?.artists)} />
          </FormControlLabelWrapper>
        </Grid>
      </Grid>

      {/* Master licensor */}
      <LicensorsSection sectionLabel={t('masterLicensor')} items={rightOwnersMap.get(LICENSOR_TYPES.MASTER)} />

      {/* Sync licensor */}
      <LicensorsSection sectionLabel={t('syncLicensor')} items={rightOwnersMap.get(LICENSOR_TYPES.SYNC)} />

      {/* Writer */}
      <CreatorSection sectionLabel={t('writer')} items={rightOwnersMap.get('writer')} />

      {/* Publisher */}
      <CreatorSection sectionLabel={t('publisher')} items={rightOwnersMap.get('publisher')} />
    </StyledGridContainer>
  );
}

function LoadingPlaceholder() {
  return (
    <Grid container xs={12}>
      <Grid xs={6}>
        <Skeleton variant="text" width={100} height={40} />
      </Grid>
      <Grid xs={6}>
        <Skeleton variant="text" width={100} height={40} />
      </Grid>
    </Grid>
  );
}

const formatArtists = (artists: TGetTrack['artists']) => artists?.map((it) => it.name).join(', ');
