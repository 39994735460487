import React from 'react';
import PropTypes from 'prop-types';
import { Text } from 'components/styleguide';
import { useTranslation } from 'react-i18next';
import { Box, Grid } from '@material-ui/core';
import { getIn, useFormikContext } from 'formik';
import { FormInputText } from '..';

const defaultComponent = <FormInputText />;

const LeftLabelInput = ({
  align,
  bold,
  color,
  label,
  labelWidth,
  textArea,
  customComponent,
  required = false,
  ...props
}) => {
  const { t } = useTranslation();
  const { errors, touched } = useFormikContext();
  const hasError = getIn(errors, props.name) && getIn(touched, props.name);
  const children = React.cloneElement(customComponent || defaultComponent, {
    ...props,
    textArea,
  });

  return (
    <Box mb={0.5} width={1}>
      <Grid container alignItems={textArea || hasError ? 'flex-start' : 'center'}>
        <Grid item xs={labelWidth}>
          <Box pt={textArea || (hasError && 1)} w={1} pr={1.5} textAlign={align}>
            <Text bold={bold} color={color} component="label" variant="body2">
              {`${t(label)} ${required ? '*' : ''}`}
            </Text>
          </Box>
        </Grid>
        <Grid item xs>
          {children}
        </Grid>
      </Grid>
    </Box>
  );
};

LeftLabelInput.propTypes = {
  align: PropTypes.string,
  bold: PropTypes.bool,
  color: PropTypes.string,
  label: PropTypes.string,
  labelWidth: PropTypes.number,
  required: PropTypes.bool,
  textArea: PropTypes.bool,
  customComponent: PropTypes.element,
};

LeftLabelInput.defaultProps = {
  labelWidth: 4,
  color: 'textSecondary',
};

export default LeftLabelInput;
