/* eslint-disable import/no-cycle */
import makeEntityServiceCalls, { makeServiceCall } from 'redux-core/serviceHelpers';
import { extractRightsPresets } from 'redux-core/rights/services';

export const {
  create: createClearanceCall,
  update: updateClearanceCall,
  getAll: getAllClearancesCallOriginal,
} = makeEntityServiceCalls('clearances', 'clear');

export const getAllClearancesCall = async (payload) => {
  const clearances = await getAllClearancesCallOriginal(payload);
  /**
   * Map { rightsPresetId, territories } to match <RightPresetsPicker /> API
   */
  const result = clearances.map((clearance) => ({
    ...clearance,
    ...extractRightsPresets(clearance),
  }));
  return result;
};

export const cuesAddUse = makeServiceCall('cues', 'add-use', 'clear');

export const cuesRemoveUse = makeServiceCall('cues', 'remove-use', 'clear');

export const cuesUpdate = makeServiceCall('cues', 'update', 'clear');

export const cuesUpdateUsing = makeServiceCall('cues', 'update-used', 'clear');

export const cuesTransfer = makeServiceCall('cues', 'transfer', 'clear');

export const cuesUpdateActive = makeServiceCall('cues', 'update-active', 'clear');

export const clearancesUpdateActive = makeServiceCall('clearances', 'update-active', 'clear');

export const exerciseOption = makeServiceCall('clearances', 'exercise-option', 'clear');

export const unexerciseOption = makeServiceCall('clearances', 'unexercise-option', 'clear');

export const getAllClearancesWithUsesAndDuration = makeServiceCall('clearances', 'get-all-by-track', 'clear');

export const updateClearancesCues = makeServiceCall('clearances', 'update-clearances-cues', 'clear');

export const deleteCue = makeServiceCall('clearances', 'delete', 'clear');
