import { connect } from 'react-redux';
import { getSelectedTrackId, getSelectedQwireTracksId } from 'redux-core/tracks/selectors';
import { getSelectedProductionDivisionIdSelector } from 'redux-core/overview/selectors';
import { refreshCueContainersByTrackProp } from 'redux-core/cueContainers/actions';

const mapStateToProps = (state) => ({
  divisionId: getSelectedProductionDivisionIdSelector(state),
  trackId: getSelectedTrackId(state),
  qwireTracksId: getSelectedQwireTracksId(state),
});

const mapDispatchToProps = {
  refreshCueContainersByTrackProp,
};

export default connect(mapStateToProps, mapDispatchToProps);
