import { createTheme } from '@mui/material/styles';
import type {} from '@mui/lab/themeAugmentation';
import type {} from '@mui/x-data-grid-premium/themeAugmentation';

export const palette = {
  primary: {
    main: '#282078', // Light/Primary/Main
    dark: '#1c1654', // Light/Primary/Dark
    light: '#534c93', // Light/Primary/Light
    gradient: '#11103b', // Light/Primary/Gradient
    contrastText: '#ffffff', // Light/Primary/Contrast
    100: '#F7F6FA', // Light/Primary/Shades/4p
    200: '#EEEEF5', // Light/Primary/Shades/8p
    300: '#E5E4EE', // Light/Primary/Shades/12p
    400: '#BEBCD6', // Light/Primary/Shades/30p
    500: '#938FBB', // Light/Primary/Shades/50p
  },
  secondary: {
    main: '#9b51e0', // Light/Secondary/Main
    dark: '#6C389C', // Light/Secondary/Dark
    light: '#AF73E6', // Light/Secondary/Light
    contrastText: '#ffffff', // Light/Secondary/Contrast
    100: '#FBF8FE', // Light/Secondary/Shades/4p
    200: '#F7F1FD', // Light/Secondary/Shades/8p
    300: '#F3EAFB', // Light/Secondary/Shades/12p
    400: '#E1CAF5', // Light/Secondary/Shades/30p
    500: '#CDA7EF', // Light/Secondary/Shades/50p
  },
  error: {
    main: '#c83c44', // Light/Error/Main
    dark: '#8C2A2F', // Light/Error/Dark
    light: '#D36369', // Light/Error/Light
    contrastText: '#ffffff',
    100: '#FDF7F8', // Light/Error/Shades/4p
    200: '#F8E7E8', // Light/Error/Shades/12p
    300: '#EEC4C6', // Light/Error/Shades/30p
    500: '#E39DA1', // Light/Error/Shades/50p
  },
  info: {
    main: '#0078d2', // Light/Info/Main
    dark: '#005493', // Light/Info/Dark
    light: '#3393db', // Light/Info/Light
    contrastText: '#ffffff', // Light/Info/Contrast
    100: '#F5FAFD', // Light/Info/Shades/4p
    200: '#E0EEF9', // Light/Info/Shades/12p
    300: '#B2D6F1', // Light/Info/Shades/30p
    500: '#7FBBE8', // Light/Info/Shades/50p
  },
  success: {
    main: '#2e7d32', // Light/Success/Main
    dark: '#1b5e20', // Light/Success/Dark
    light: '#4caf50', // Light/Success/Light
    contrastText: '#ffffff', // Light/Success/Contrast
    100: '#F7FAF7', // Light/Success/Shades/4p
    150: '#EAF2EA', // Light/Success/Shades/190p
    200: '#E6EFE6', // Light/Success/Shades/12p
    300: '#C0D8C1', // Light/Success/Shades/30p
    500: '#96BE98', // Light/Success/Shades/50p
    900: '#123214', // Light/Success/Shades/160p
  },
  warning: {
    main: '#f59400', // Light/Warning/Main
    dark: '#9b3303', // Light/Warning/Dark
    light: '#ffc342', // Light/Warning/Light
    contrastText: '#ffffff', // Light/Warning/Contrast
    100: '#FFFDF8', // Light/Warning/Shades/4p
    200: '#FEF4E5', // Light/Warning/Shades/190p
    300: '#FEF2E0', // Light/Warning/Shades/12p
    400: '#FCDFB2', // Light/Warning/Shades/30p
    500: '#FAC980', // Light/Warning/Shades/50p
    900: '#623B00', // Light/Warning/Shades/160p
  },
  text: {
    primary: '#4C434D', // Light/Text/Primary
    secondary: '#757575', // Light/Text/Secondary
    disabled: '#B1ADB1', // Light/Text/Disabled
  },
  background: {
    default: '#FFFFFF', // Light/Background/Default
    paper: '#FFFFFF', // Light/Background/Paper
  },
  action: {
    hover: '#F5F5F5', // Light/Action/Hover (4p)
    selected: '#EBEBEB', // Light/Action/Selected (8p)
    disabledBackground: '#E0E0E0', // Light/Action/Disabled Background (12p)
    focus: '#E0E0E0', // Light/Action/Focus (12p)
    disabled: '#BDBDBD', // Light/Action/Disabled (26p)
    active: '#757575', // Light/Action/Active (54p)
  },
  other: {
    divider: '#E0E0E0', // Light/Other/Divider
    outlinedBorder: '#C4C4C4', // Light/Other/Outlined Border (23p)
    backdropOverlay: '#808080', // Light/Other/Backdrop Overlay
    filledInputBgColor: '#F0F0F0', // Light/Other/Filled Input Background
    standardInputLine: '#949494', // Light/Other/Standard Input Line
    snackbar: '#323232', // Light/Other/Snackbar
    ratingActive: '#FFB400', // Light/Other/Rating Active
    focusRingColor: '#005FCC', // Light/Other/focus-ring-color
    activeTabUnderline: '#FA3657', // Light/Other/activeTabUnderline
  },
  white: '#ffffff',
  grey: {
    50: '#FAFAFA', // Grey/50
    100: '#F5F5F5', // Grey/100
    200: '#EEEEEE', // Grey/200
    300: '#E0E0E0', // Grey/300
    400: '#BDBDBD', // Grey/400
    500: '#9E9E9E', // Grey/500
    600: '#757575', // Grey/600
    700: '#616161', // Grey/700
    800: '#424242', // Grey/800
    900: '#212121', // Grey/900
  },
  black: {
    default: '#000000', // Common/Black/100p
    100: '#F5F5F5', // Common/Black/4p
    200: '#E0E0E0', // Common/Black/12p
    300: '#B3B3B3', // Common/Black/30p
  },
};

export const InputFieldHeight = {
  medium: '2.5rem',
  small: '2.25rem',
  large: '3.5rem',
} as const;

export const font = "'Open Sans', sans-serif";

const baseTheme = createTheme({
  transitions: {
    duration: {
      standard: 300,
    },
  },
  palette,
  typography: {
    h1: { fontSize: '2.125rem', fontWeight: 700 }, // 34px
    h2: { fontSize: '1.5rem', fontWeight: 700 }, // 24px
    h3: { fontSize: '1.3rem', lineHeight: 'unset', fontWeight: 700 }, // 20px
    h4: { fontSize: '1.125rem', fontWeight: 700 }, // 18px
    h5: { fontSize: '1rem', fontWeight: 600 }, // 16px
    h6: { fontSize: '0.875rem', fontWeight: 600 }, // 14px
    body1: { fontSize: '1rem' }, // 16px
    body2: { fontSize: '0.875rem' }, // 14px
    subtitle1: { fontSize: '1rem' }, // 16px
    subtitle2: { fontSize: '1rem', fontWeight: 600 }, // 16px
    caption: { fontSize: '0.75rem' }, // 12px
    overline: {
      fontSize: '0.75rem', // 12px
      letterSpacing: 'normal',
      lineHeight: 'normal',
      textTransform: 'none',
    },
    allVariants: {
      fontFamily: font,
    },
  },
  shape: {
    borderRadius: 4,
  },
});

const newTheme = createTheme(baseTheme, {
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          borderRadius: 4,
          fontWeight: '600',
          fontSize: '0.875rem',
          minHeight: InputFieldHeight.medium,
        },
        sizeLarge: {
          fontSize: '0.9375rem',
          minHeight: InputFieldHeight.large,
        },
        sizeSmall: {
          fontSize: '0.8125rem',
          minHeight: InputFieldHeight.small,
        },
        startIcon: {
          marginRight: 4,
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          backgroundColor: palette.background.default,
        },
        indicator: {
          backgroundImage: 'linear-gradient(to top, #fb2c5d, #fa7437)',
          height: '4px',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          borderRadius: 4,
          backgroundColor: palette.background.default,
          color: palette.text.primary,
          fontSize: '1rem',
          padding: '0.5315rem 0.75rem',
          '&.rtl': {
            direction: 'rtl',
          },
          '& .Mui-disabled': {
            cursor: 'not-allowed',
            color: palette.text.disabled,
          },
          '&::placeholder': {
            color: palette.text.secondary,
            opacity: 1,
          },
          '&[readonly]': {
            backgroundColor: palette.grey[100],
          },
        },
        root: {
          borderRadius: 4,
          '&, & > fieldset': {
            borderRadius: 4,
          },
          '&.readOnly': {
            borderColor: palette.text.disabled,
            '&, & > fieldset': {
              borderColor: `${palette.text.disabled} !important`,
            },
            '&, & > input, & > textarea': {
              backgroundColor: palette.grey[100],
            },
          },
        },
        multiline: {
          padding: 0,
        },
        sizeSmall: {
          fontSize: '0.8125rem',
        },
        readOnly: {
          backgroundColor: palette.grey[100],
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: palette.text.primary,
          fontSize: '0.875rem',
          fontWeight: 600,
          position: 'initial',
          // ellipsis styles
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          asterisk: {
            '&$error': {
              color: palette.text.primary,
            },
          },
          '&.Mui-disabled': {
            color: palette.text.disabled,
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: '0.875rem',
          letterSpacing: 'normal',
          marginLeft: 0,
          marginRight: 0,
          lineHeight: 1.4,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'capitalize',
          height: '50px',
          fontWeight: 600,
          minWidth: '170px',
          fontSize: '0.875rem',
          borderBottom: `4px solid ${palette.grey[100]}`,
          '&:hover': {
            borderBottom: `4px solid ${palette.other.activeTabUnderline}`,
            '& > span > span > span': {
              position: 'relative',
              top: '1px',
            },
          },
        },
        textColorInherit: {
          fontSize: '0.875rem',
          opacity: 1,
          '&$selected': {
            color: palette.other.activeTabUnderline,
            backgroundColor: palette.white,
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        stickyHeader: {
          backgroundColor: palette.white,
          zIndex: 1,
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        hover: {
          cursor: 'pointer',
          '&:hover': {
            backgroundColor: `${palette.action.hover} !important`,
          },
        },
        root: {
          '&$selected': {
            backgroundColor: palette.info[200],
            '& > td': {
              borderBottom: '1.5px solid',
              borderBottomColor: palette.primary.main,
              borderTop: '1.5px solid',
              borderTopColor: palette.primary.main,
            },
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          '&.bold': { fontWeight: 700 },
          '&.clickable': { cursor: 'pointer' },
          '&.italic': { fontStyle: 'italic' },
          '&.link': { color: palette.info.main },
          '&.semibold': { fontWeight: 600 },
          '&.underlined': { textDecoration: 'underline' },
        },
        noWrap: {
          width: '100%',
        },
      },
    },
    MuiExpansionPanelSummary: {
      styleOverrides: {
        root: {
          backgroundColor: palette.white,
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          '&.fullWidth': {
            width: '100%',
          },
        },
      },
    },
    MuiLoadingButton: {
      defaultProps: {
        loadingPosition: 'end',
      },
      styleOverrides: {
        root: {
          borderRadius: 4,
          fontWeight: '600',
          '& > .MuiLoadingButton-loadingIndicator': {
            position: 'static',
          },
          '&:has(> .MuiLoadingButton-loadingIndicator)': {
            gap: '0.5rem',
          },
        },
        sizeMedium: {
          fontSize: '0.875rem',
          minHeight: InputFieldHeight.medium,
        },
        sizeLarge: {
          minHeight: `${InputFieldHeight.large} !important`,
          fontSize: '0.9375rem !important',
        },
        sizeSmall: {
          minHeight: `${InputFieldHeight.small} !important`,
          fontSize: '0.8125rem !important',
        },
      },
    },
    MuiAutocomplete: {
      /**
       * Defaults to small size so we get the correct size for the internal Chip/Tags
       */
      defaultProps: {
        size: 'small',
      },
      styleOverrides: {
        root: {
          backgroundColor: palette.white,
        },
        inputRoot: {
          padding: 0,
          ':has(> input[readonly])': {
            backgroundColor: palette.grey[100],
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: palette.black.default,
          fontSize: '0.75rem',
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        MenuProps: {
          PaperProps: {
            style: {
              overflow: 'auto',
              maxHeight: 48 * 7, // 48px is the default height of an item
            },
          },
        },
      },
    },
  },
});

export default newTheme;
