import React from 'react';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import { LICENSOR_TYPES } from 'utils/constants';
import cond from 'ramda/src/cond';
import equals from 'ramda/src/equals';
import always from 'ramda/src/always';
import T from 'ramda/src/T';
import withBaseDrawer from '../withBaseDrawer';
import SyncLicensor from './SyncLicensor';
import MasterOtherLicensor from './MasterOtherLicensor';

const LicensorsAddEditDrawer = ({
  name,
  payload: {
    isEditing = false,
    type,
    licensorId,
    clearancesFetch,
    originalType,
    isVerifiedTrack,
    licensorsExist,
    readOnly,
  },
  onClose,
}) => (
  <Grid item container>
    {cond([
      [
        equals(LICENSOR_TYPES.SYNC),
        always(
          <SyncLicensor
            name={name}
            onClose={onClose}
            isEditing={isEditing}
            licensorId={licensorId}
            type={type}
            originalType={originalType}
            clearancesFetch={clearancesFetch}
            isVerifiedTrack={isVerifiedTrack}
            licensorsExist={licensorsExist}
            readOnly={readOnly}
          />,
        ),
      ],
      [
        T,
        always(
          <MasterOtherLicensor
            name={name}
            isEditing={isEditing}
            licensorId={licensorId}
            type={type}
            originalType={originalType}
            clearancesFetch={clearancesFetch}
            onClose={onClose}
            isVerifiedTrack={isVerifiedTrack}
            licensorsExist={licensorsExist}
            readOnly={readOnly}
          />,
        ),
      ],
    ])(type)}
  </Grid>
);

LicensorsAddEditDrawer.propTypes = {
  name: PropTypes.string,
  onClose: PropTypes.func,
  payload: PropTypes.shape({
    isEditing: PropTypes.bool,
    clearancesFetch: PropTypes.func,
    originalType: PropTypes.string,
  }),
};

export default withBaseDrawer(LicensorsAddEditDrawer);
