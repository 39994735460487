import { connect } from 'react-redux';
import compose from 'ramda/src/compose';
import { searchForUsers } from 'redux-core/users/actions';
import withBaseDrawer from '../withBaseDrawer';

const mapDispatchToProps = {
  searchForUsers,
};

export default compose(connect(undefined, mapDispatchToProps), withBaseDrawer);
