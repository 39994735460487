import React from 'react';
import Grid from '@material-ui/core/Grid';
import { FieldArray, useField } from 'formik';
import equals from 'ramda/src/equals';
import Divider from '@material-ui/core/Divider';

import AddCircleIcon from '@material-ui/icons/AddCircle';
import { v4 as uuidv4 } from 'uuid';
import Box from '@material-ui/core/Box';
import CueRow from './CueRow';
import { useRootTranslation } from '../../../../../../utils/hooks';
import { FormButton } from '../../../../Form/Fields';
import { appColors } from '../../../../../styleguide';

const CueContainers = ({ cueContainers, readOnly, clearanceId, trackId, qTrackId, refetch }) => {
  const t = useRootTranslation('drawers.clearanceUsesAndDurations');

  /* eslint-disable-next-line no-unused-vars */
    const [{ value: cues = [] }, _meta] = useField('cues');

  return cueContainers.map((container) => {
    const cuesInContainer = cues

      /** We need to keep the index of the cues in the complete cue array */
      .map((cue, index) => ({ ...cue, index }))
      /** Remove deleted cues and cues that do not include the current cueContainer */
      /** Remove cues that are not in the current clearance */
      .filter(
        ({ deleted, cueContainerIds, qclearClearanceId }) =>
          !deleted && cueContainerIds.some(equals(container.id)) && qclearClearanceId === clearanceId,
      );
    return (
      <Grid container item>
        {cuesInContainer.map(({ id, index }) => (
          <>
            <CueRow
              key={id}
              name={`cues.${index}`}
              canDelete={cuesInContainer.length > 1 && !readOnly}
              container={container}
              trackId={trackId}
              qTrackId={qTrackId}
              refetch={refetch}
            />
            <Grid container item>
              <Divider className="light" style={{ marginTop: '1em', marginBottom: '1em' }} />
            </Grid>
          </>
        ))}
        <Grid container>
          <Grid container item xs={2}>
            <FieldArray name="cues">
              {(arrayHelpers) => (
                <FormButton
                  alwaysEnabled
                  size="sm"
                  variant="secondary"
                  startIcon={<AddCircleIcon />}
                  onClick={() => {
                        arrayHelpers.push({
                          id: `newCue-${uuidv4()}`,
                          qclearClearanceId: clearanceId,
                          cueContainerIds: [container.id],
                        });
                        arrayHelpers.form.submitForm();
                      }}
                >
                  {t('addNew')}
                </FormButton>
              )}
            </FieldArray>
          </Grid>
        </Grid>
        <Box width={1} my={1}>
          <hr style={{ borderColor: appColors.lightBlue }} />
        </Box>
      </Grid>
    );
  });
};

export default CueContainers;
