/* eslint-disable react/no-children-prop */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable import/no-cycle */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { DrawerHeader, Text, Button, Checkbox, Loading } from 'components/styleguide';
import Box from '@material-ui/core/Box';
import ButtonBase from '@material-ui/core/ButtonBase';
import { useRootTranslation, useFetch } from 'utils/hooks';
import { syncCuesGetCall, syncCuesSyncCall } from 'redux-core/cueSheet/services';
import { concatStrings } from 'utils';
import Grid from '@material-ui/core/Grid';
import prop from 'ramda/src/prop';
import isNil from 'ramda/src/isNil';
import all from 'ramda/src/all';
import equals from 'ramda/src/equals';
import TrackRow from './TrackRow';
import enhancer from './enhancer';
import useStyles from './styles';

export const TRACK_OPTIONS = {
  CUE: 'CUE',
  CLEAR: 'CLEAR',
};

const getTracks = (sync) => {
  const { partialMatchingTrackPairByTitleAndArtist, missingClearTracks, missingCueTracks } = sync;
  const clear = missingClearTracks.map((clearTrack) => ({ clearTrack }));
  const cue = missingCueTracks.map((cueTrack) => ({ cueTrack }));

  return [...partialMatchingTrackPairByTitleAndArtist, ...clear, ...cue];
};

const areAllSelected = (cueSelectionsRaw, option) => {
  const cueSelections = cueSelectionsRaw.filter(Boolean);
  if (!cueSelections.length) return false;
  return all(equals(option), cueSelections);
};

const SelectAllButton = ({ option, setCueSelections, cueSelections }) => {
  const t = useRootTranslation('drawers.cueSheetSync');
  const classes = useStyles();
  return (
    <ButtonBase
      onClick={() => setCueSelections((cueSelections) => cueSelections.map((x) => (x === null ? x : option)))}
      className={classes.selectAllButton}
    >
      <Text variant="caption" bold color="cerulean" component="span" children={t('selectAll')} />
      <Checkbox value={areAllSelected(cueSelections, option)} />
    </ButtonBase>
  );
};

SelectAllButton.propTypes = {
  option: PropTypes.string.isRequired,
  setCueSelections: PropTypes.func.isRequired,
  cueSelections: PropTypes.array.isRequired,
};

const CueSheetSyncDrawer = ({
  onClose,
  payload: { selectedVersionId, assetId },
  refetchCues,
  showGenericError,
  productionId,
}) => {
  const t = useRootTranslation('drawers.cueSheetSync');
  const tGlobal = useRootTranslation('global');
  const classes = useStyles();
  const [sync, loading] = useFetch(
    () =>
      syncCuesGetCall({
        subprojectId: productionId,
        cueSheetVersionId: selectedVersionId,
        cueContainerId: assetId,
        exactMatch: false,
        matchByQWireTrackId: false,
      }),
    [selectedVersionId, assetId],
    {
      partialMatchingTrackPairByTitleAndArtist: [],
      missingClearTracks: [],
      missingCueTracks: [],
    },
  );

  const [cueSelections, setCueSelections] = useState([]);

  const tracks = getTracks(sync);
  const changesAmount = tracks.length;
  useEffect(
    () => {
      /* When there's no clearTrack there's no selection, hence null */
      const selectionsInitial = tracks.map(({ clearTrack }) => (clearTrack ? TRACK_OPTIONS.CLEAR : null));
      setCueSelections(selectionsInitial);
    },
    // disable next line so tracks (which is a simple computed value from sync) is not required as a dep
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sync],
  );

  /**
   * We only sync when there are 'CLEAR' track selected, so we need the indexes
   * of only TRACK_OPTIONS.CLEAR. The sync button is always enabled, but if there are no
   * trackIndexesToChange, the sync button just closes the drawer.
   */

  const onSubmit = async () => {
    const trackIndexesToChange = cueSelections
      .map((x, i) => (x === TRACK_OPTIONS.CLEAR ? i : null))
      .filter((x) => !isNil(x));

    const syncTracks = trackIndexesToChange.map((index) => {
      const { clearTrack = {}, cueTrack = {} } = tracks[index];
      return {
        clearTrackId: clearTrack?.id,
        cueSheetTrackId: cueTrack?.id,
        ...(cueTrack?.cues?.length && { syncCues: clearTrack?.cues?.map((cue, i) => ({
            clearCueId: cue?.id,
            cueSheetCueId: cueTrack?.cues[i]?.id,
          })),
        }),
      };
    });

    const params = {
      subprojectId: productionId,
      cueSheetVersionId: selectedVersionId,
      cueContainerId: assetId,
      syncTracks,
    };

    try {
      await syncCuesSyncCall(params);
    } catch {
      showGenericError();
    } finally {
      await refetchCues();
      onClose();
    }
  };

  if (loading) return <Loading />;

  return (
    <>
      <Box pl={2} width={1}>
        <DrawerHeader title={t('title')} hideBackButton onClose={onClose}>
          <Button
            alwaysEnabled
            children={tGlobal('forms.cancel')}
            size="xs"
            onClick={() => {
              onClose();
            }}
            variant="destructive"
          />
          <Button
            children={tGlobal('forms.save')}
            size="xs"
            variant="primary"
            onClick={onSubmit}
            testId="sync-button-save"
          />
        </DrawerHeader>
      </Box>
      <Box mb={2} pl={2}>
        <Text variant="caption" component="p" children={t('howTo1')} />
        <Text variant="caption" component="p" children={t('howTo2')} />
        <Text variant="caption" component="p" children={t('howTo3')} />
        <Box mt={3}>
          <Text variant="caption" component="p" color="cerulean" bold>
            {t('changes', { count: changesAmount })}
          </Text>
        </Box>
      </Box>
      <Grid container>
        <Grid item container xs={6} direction="column" className={classes.trackRowHeaderWrapper}>
          <Text variant="caption" bold children={t('clearData')} />
          <SelectAllButton
            option={TRACK_OPTIONS.CLEAR}
            setCueSelections={setCueSelections}
            cueSelections={cueSelections}
          />
        </Grid>
        <Grid item container xs={6} direction="column" className={classes.trackRowHeaderWrapper}>
          <Text variant="caption" bold children={t('cueData')} />
          <SelectAllButton
            option={TRACK_OPTIONS.CUE}
            setCueSelections={setCueSelections}
            cueSelections={cueSelections}
          />
        </Grid>
      </Grid>
      <Grid container alignItems="center">
        {!tracks.length && <Text variant="h3" color="cerulean" align="center" children={t('fallback')} />}
        {tracks.map(({ clearTrack, cueTrack, usageMatch, durationMatch }, index) => (
          <div
            className={classes.trackRowWrapper}
            key={concatStrings('')([prop('id', clearTrack), prop('id', cueTrack)])}
          >
            <TrackRow
              clearTrack={clearTrack}
              cueTrack={cueTrack}
              usageMatch={usageMatch}
              durationMatch={durationMatch}
              index={index}
              setCueSelections={setCueSelections}
              selectedInRow={cueSelections[index]}
              classes={classes}
            />
          </div>
        ))}
      </Grid>
    </>
  );
};

CueSheetSyncDrawer.propTypes = {
  onClose: PropTypes.func.isRequired,
  payload: PropTypes.shape({}),
  refetchCues: PropTypes.func,
  showGenericError: PropTypes.func.isRequired,
  productionId: PropTypes.number,
};

export default enhancer(CueSheetSyncDrawer, { xlarge: true });
