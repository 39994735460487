/* eslint-disable import/no-cycle */
import axios from 'axios';
import { axiosInstanceConfig } from 'utils/request';
import { makeServiceCall } from 'redux-core/serviceHelpers';

export const exchangeToken = async (payload) => {
  // Exchange the Auth0 token for a qwire token.
  const {
    access_token: bearerToken,
    userId,
    displayName,
    qwireEmployee,
    image,
    defaultIdleTimeout,
  } = await makeServiceCall('token/v2', 'exchange')(payload);

  return {
    locked: false,
    bearerToken,
    image,
    userId,
    displayName,
    qwireEmployee,
    defaultIdleTimeout,
  };
};

export const getCurrentUserData = makeServiceCall('users', 'get-current-user-data');

/**
 * lockUser needs a new instance of axios since we don't
 * want it to be affected by the interceptors. This call will
 * fail if the user has already been locked out by the backend.
 */
export const lockUser = (bearerToken) => {
  const requester = axios.create(axiosInstanceConfig);
  return requester.post('/common/sessions/lock', null, {
    headers: { Authorization: `Bearer ${bearerToken}` },
  });
};
