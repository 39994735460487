import * as React from 'react';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, styled } from '@mui/system';

const dialogFixedElementsHeightOffset = 180;
const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  height: `calc(100vh - ${dialogFixedElementsHeightOffset}px)`,
  overflowY: 'hidden',
  padding: theme.spacing(2, 4),
}));

const StyledBoxHeader = styled(Box)({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
});

const StyledBoxContent = styled(Box)(() => ({
  height: '100%',
  width: '100%',
  overflowY: 'auto',
}));

type BaseDrawerPanelProps = {
  children: React.ReactNode;
  title: string;
  headerComponent: React.ReactNode;
  actionsComponent: React.ReactNode;
};

export function BaseDrawerPanel({ children, title, headerComponent, actionsComponent }: BaseDrawerPanelProps) {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', flex: '1 1 auto' }}>
      <DialogTitle sx={{ fontWeight: 'bold', fontSize: '1.5rem' }}>{title}</DialogTitle>
      <StyledDialogContent dividers>
        <Box tabIndex={-1} sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
          <StyledBoxHeader>{headerComponent}</StyledBoxHeader>
          <StyledBoxContent>{children}</StyledBoxContent>
        </Box>
      </StyledDialogContent>
      <DialogActions disableSpacing sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2, p: 2 }}>
        {actionsComponent}
      </DialogActions>
    </Box>
  );
}
