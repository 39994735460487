import { compose } from 'redux';
import { connect } from 'react-redux';
import withBaseDrawer from 'components/common/Drawers/withBaseDrawer';
import { getSelectedProductionObjectIdSelector, getSelectedProductionIdSelector } from 'redux-core/overview/selectors';
import { getSelectedVersionIdSelector } from 'redux-core/cueSheet/selectors';
import { refetchCues } from 'redux-core/cueSheet/actions';

const mapStateToProps = (state) => ({
  productionObjectId: getSelectedProductionObjectIdSelector(state),
  subprojectId: getSelectedProductionIdSelector(state),
  cueSheetVersionId: getSelectedVersionIdSelector(state),
});

const mapDispatchToProps = { refetchCues };

export default compose(connect(mapStateToProps, mapDispatchToProps), withBaseDrawer);
