/* eslint-disable react/no-children-prop */
import React from 'react';
import { Text, TypeIcon } from 'components/styleguide';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Grid from '@material-ui/core/Grid';
import Breadcrumbs from 'components/common/Breadcrumbs';
import ImageUploader from 'components/common/ImageUploader';
import init from 'ramda/src/init';
import last from 'ramda/src/last';
import useStyles from './styles';

const ProductionTitle = ({ posterId, productionPath, unwatchProduction }) => {
  const classes = useStyles();
  return (
    <Grid container className={classes.root}>
      <ImageUploader posterSize={{ height: 69, width: 44 }} uploadable={false} value={posterId} />
      <Grid container xs={10} alignItems="center" alignContent="center">
        <Grid container item className={classes.title} alignItems="flex-end">
          <TypeIcon type="marketing" size={20} />
          <Grid item xs={10}>
            <Breadcrumbs color="textSecondary" joinBy=" - " noWrap path={init(productionPath)} />
          </Grid>
        </Grid>
        <Grid container item className={classes.title} alignItems="center">
          <VisibilityIcon className={classes.icon} color="primary" fontSize="small" onClick={unwatchProduction} />
          <Grid item xs={10}>
            <Text bold children={last(productionPath).name} color="textPrimary" variant="h6" noWrap />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ProductionTitle;
