import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: '100%',
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.grey[400],
    lineHeight: '0.1em',
    margin: '10px 0 20px',
    textAlign: 'center',
    // @see https://stackoverflow.com/a/5214204
    '& > *': {
      background: '#fff',
      lineHeight: 0,
      padding: theme.spacing(0, 2.5),
    },
    '&.left': {
      textAlign: 'left',
      '& > *': {
        paddingLeft: 0,
      },
    },
    '&.right': {
      textAlign: 'right',
      '& > *': {
        paddingRight: 0,
      },
    },
  },
}));

export default useStyles;
