/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormInputPropTypes } from 'components/styleguide/fields/types';
import pick from 'ramda/src/pick';
import { get } from 'lodash';
import NumberFormat from '../NumberFormat';
import InputText from '../InputText';
import { FORMATS, getPropsForType, getIsAllowedForType } from './utils';

const FORM_CONTROL_PROPS = Object.keys(FormInputPropTypes);
const InputFormat = (props) => {
  const formControlProps = pick(FORM_CONTROL_PROPS)(props);
  const { autoComplete, name, ...formatProps } = props;
  const ariaLabelledBy = get(props, ['aria-labelledby']);

  const { type } = formatProps;

  const isAllowed = useMemo(() => getIsAllowedForType({ type, ...formatProps }), [type, formatProps]);

  return (
    <NumberFormat
      textAlign="right"
      {...formControlProps}
      {...getPropsForType(formatProps)}
      {...isAllowed}
      aria-describedby={name}
      id={name}
      inputComponent={InputText}
      inputProps={{
        autoComplete,
        ...(ariaLabelledBy && { 'aria-labelledby': ariaLabelledBy }),
      }}
      name={name}
    />
  );
};

InputFormat.propTypes = {
  ...FormInputPropTypes,
  max: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  min: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  textArea: PropTypes.bool,
  /**
   * This prop expects an array of two strings
   * since their values usually came from `useNumberFormat` hook
   */
  separators: PropTypes.arrayOf(PropTypes.string),
  type: PropTypes.oneOf(Object.values(FORMATS)),
};

InputFormat.defaultProps = {
  autoComplete: 'off',
  separators: [',', '.'],
  value: '',
};

InputFormat.displayName = 'InputFormat';

export default InputFormat;
