import { makeStyles } from '@material-ui/core';
import { appColors } from 'components/styleguide';

const useStyles = makeStyles((theme) => ({
  row: {
    backgroundColor: appColors.white,
    borderBottom: `1px solid ${appColors.lightGrey}`,
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
    padding: theme.spacing(1, 0),
  },
}));

export default useStyles;
