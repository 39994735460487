import { createActions } from 'redux-actions';
import identity from 'ramda/src/identity';

const actionsDefinition = {
  SET_ACTIVE_DIVISION_ID: identity,
  SET_ACTIVE_TENANT_ID: identity,
};

/**
 * @deprecated 1.87
 * Set the divisionId & tenantId from common/actions
 * Get the divisionId & tenantId from common.parameters
 * @todo remove those below
 */
export const { setActiveDivisionId, setActiveTenantId } = createActions(actionsDefinition);
