/* eslint-disable import/no-cycle */
import makeEntityServiceCalls, { makeServiceCall } from 'redux-core/serviceHelpers';

export const {
  getAll: getTasksCall,
  get: getTaskByIdCall,
  create: createTaskCall,
  update: updateTaskCall,
  delete: deleteTaskCall,
} = makeEntityServiceCalls('tasks');

/**
 * Complete a task (change { completed } prop from true to false (and vice-versa) )
 */
export const completeTaskCall = makeServiceCall('tasks', 'completed');

/**
 * Mark all Task comments as read
 */
export const readTaskCommentsCall = makeServiceCall('tasks', 'read');

export const {
  getAll: getTaskCommentsCall,
  get: getTaskCommentByIdCall,
  create: createTaskCommentCall,
  update: updateTaskCommentCall,
  delete: deleteTaskCommentCall,
} = makeEntityServiceCalls('task-comments');
