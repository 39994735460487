/* eslint-disable import/no-cycle */
import { MouseEventHandler } from 'react';
import ButtonBase from '@material-ui/core/ButtonBase';
import Avatar from '@material-ui/core/Avatar';
import { Poster } from 'components/styleguide';
import { useFetch, useRootTranslation } from 'utils/hooks';
import { getDownloadUrlCall } from 'redux-core/images/services';
import useStyles from './styles';

export type RepAvatarProps = {
  disabled?: boolean,
  repInitials?: string,
  tooltip?: string,
  repImageId?: number,
  onClick?: MouseEventHandler<HTMLButtonElement> | undefined,
}

const RepAvatar = ({
  disabled,
  repInitials,
  tooltip,
  repImageId,
  onClick,
}: RepAvatarProps) => {
  const classes = useStyles();
  const [repImageUrl] = useFetch(() => repImageId && getDownloadUrlCall(repImageId), [repImageId], null);
  const t = useRootTranslation('styleguide.repAvatar');

  return (
    <ButtonBase className={classes.buttonWrapper} onClick={onClick} data-testid="rep-button" disabled={disabled}>
      {repImageId && repImageUrl ? (
        <Avatar className={classes.avatar} title={t('picture')}>
          <Poster url={repImageUrl} size="xs" />
        </Avatar>
      ) : (
        <Avatar className={classes.avatar} data-testid="rep-initials" title={tooltip || t('initials')}>
          {repInitials}
        </Avatar>
      )}
    </ButtonBase>
  );
};

export default RepAvatar;
