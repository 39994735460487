/* eslint-disable react/no-children-prop */
/* eslint-disable @typescript-eslint/no-shadow */
import React from 'react';
import PropTypes from 'prop-types';
import { DrawerHeader, Text } from 'components/styleguide';
import { useRootTranslation, useFetch, useSnackbar } from 'utils/hooks';
import Form from 'components/common/Form';
import { FormButton, FormTypeAhead, FormDatePicker, FormCheckbox } from 'components/common/Form/Fields';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import {
  CUE_SHEET_SUBMISSION_STATUS_OPTIONS,
  translatedOptions,
  arrayToOptions,
  CUE_SHEET_SUBMISSION_STATUS,
} from 'utils/constants';
import {
  createCueSheetSubmissionCall,
  getAvailableProsCall,
  validateRapidCueSubmissionCall,
} from 'redux-core/cueSheet/services';
import { getTenantCall } from 'redux-core/qwire-admin/services';
import * as Yup from 'yup';
import { lowerThan } from 'utils/forms';
import { requestWithError } from 'utils/request';
import FileSaver from 'file-saver';
import enhancer from './enhancer';
import { formatValidationMessage } from './utils';

const rootT = 'drawers.submitCueSheet';

const rapidCueStatusForTranslation = {
  file_too_big: 'rapidCueFileTooBig',
  submitted: 'rapidCueSubmitted',
  error: 'rapidCueSubmissionError',
  pending: 'rapidCueSubmissionPending',
};

const validationSchema = Yup.object().shape({
  cueContainerId: Yup.number().required('global.forms.validations.required'),
  pros: Yup.array()
    .of(Yup.string().required('global.forms.validations.required'))
    .min(1, lowerThan(1))
    .required('global.forms.validations.required'),
  fileFormat: Yup.string().required('global.forms.validations.required'),
  submissionStatus: Yup.string().required('global.forms.validations.required'),
  submissionDate: Yup.string().required('global.forms.validations.required'),
  submissionToTenant: Yup.boolean(),
});

const FILE_TYPES = {
  FormattedXLSX: 'Formatted XLSX',
  PDF: 'PDF',
  XLSX: 'XLS',
  CSV: 'CSV',
  RapidCue: 'RapidCue',
};

const FILE_TYPES_OPTIONS = translatedOptions('global.fileFormats')(FILE_TYPES);

const SubmitCueSheetDrawer = ({
  name,
  onClose,
  refetchProduction,
  tenantId,
  cueContainerId,
  productionId,
  divisionId,
  openPromptAsync,
  payload: { onSubmit: onCueSheetSubmit },
}) => {
  const t = useRootTranslation(rootT);
  const showSnackbar = useSnackbar();

  const [{ divisionPros, pros }] = useFetch(
    async () => {
      const { pros, divisionPros } = await getAvailableProsCall({
        cueContainerId,
      });
      return {
        pros: arrayToOptions(pros),
        divisionPros,
      };
    },
    [cueContainerId],
    [{ pros: [], divisionPros: [] }],
  );

  const [tenant] = useFetch(() => tenantId && getTenantCall({ id: tenantId }), [tenantId]);

  const initialValues = {
    cueContainerId,
    pros: divisionPros,
    submissionStatus: CUE_SHEET_SUBMISSION_STATUS.NOT_SENT,
    submissionToTenant: tenant.cueSheetSubmit,
    submissionDate: new Date(),
    fileFormat: FILE_TYPES[tenant.cueSheetPreferredFileType] || FILE_TYPES.RapidCue,
  };

  const proContainsRapidCue = (pros) =>
    pros?.some((pro) => (typeof pro === 'string' ? pro === 'RAPIDCUE' : pro.name === 'RAPIDCUE'));

  const isOnlyRapidCue = (pros) => {
    if (pros && pros.length === 1) {
      return typeof pros[0] === 'string' ? pros[0] === 'RAPIDCUE' : pros[0]?.name === 'RAPIDCUE';
    }

    return false;
  };

  const handleSelect = (pros, setFieldValue) => {
    if (isOnlyRapidCue(pros)) {
      setFieldValue('submissionDate', new Date().toLocaleString());
      setFieldValue('fileFormat', FILE_TYPES.RapidCue);
      setFieldValue('submissionStatus', 'Sent');
    }
  };

  const onSubmit = async (values) => {
    if (proContainsRapidCue(values?.pros)) {
      const {
        divisionErrors,
        productionErrors,
        cueErrors: {
          cuesWithoutDuration,
          cuesWithoutUsage,
          tracksWithoutRightsOwnerRole,
          tracksWithoutWriterOrPublisher,
          tracksWithInvalidShares,
          tracksWithInvalidChars,
        },
      } = await requestWithError(
        validateRapidCueSubmissionCall,
        { divisionId, cueContainerId, subprojectId: productionId },
        null,
      );

      const hasErrors =
        divisionErrors.length ||
        productionErrors.length ||
        cuesWithoutDuration.length ||
        cuesWithoutUsage.length ||
        tracksWithoutRightsOwnerRole.length ||
        tracksWithInvalidShares.length ||
        tracksWithInvalidChars.length ||
        tracksWithoutWriterOrPublisher.length;

      if (hasErrors) {
        const content = formatValidationMessage({
          t,
          divisionErrors,
          productionErrors,
          cuesWithoutDuration,
          cuesWithoutUsage,
          tracksWithoutRightsOwnerRole,
          tracksWithInvalidShares,
          tracksWithInvalidChars,
          tracksWithoutWriterOrPublisher,
        });

        await openPromptAsync({
          maxWidth: 'md',
          onlyOk: true,
          multiOk: false,
          okLabel: 'Ok',
          content,
        });

        return;
      }
    }

    const settings = { message: { success: t('successMessage') } };

    // We need to remove blank space in order to match dto
    // eslint-disable-next-line no-param-reassign
    values.fileFormat = values.fileFormat.replace(' ', '');

    const { url, rapidCueStatus } = await requestWithError(
      createCueSheetSubmissionCall,
      {
        ...values,
        cueContainerId,
        productionId,
      },
      settings,
    );

    if (rapidCueStatus) {
      showSnackbar({
        message: t(rapidCueStatusForTranslation[rapidCueStatus] || rapidCueStatusForTranslation.submitted),
      });
    }

    refetchProduction();
    if (url) FileSaver.saveAs(url);
    if (onCueSheetSubmit) await onCueSheetSubmit();
    await onClose();
  };

  return (
    <Form
      drawerName={name}
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      isInitialValid={() => validationSchema.isValidSync(initialValues)}
    >
      {({ values }) => (
        <>
          <DrawerHeader bold hideBackButton onClose={onClose} title={t('drawerTitle')}>
            <FormButton
              alwaysEnabled
              children="global.forms.cancel"
              onClick={onClose}
              size="xs"
              testId="submit-cuesheet-cancel"
              variant="destructive"
            />
            <FormButton
              children={t('submit')}
              size="sm"
              testId="submit-cuesheet-save"
              type="submit"
              variant="primary"
            />
          </DrawerHeader>
          <Grid container spacing={3}>
            <Grid item container>
              <Box display="flex" mb={2} flexDirection="column">
                <Text bold children={t('hint')} gutterBottom />
                <Text children={t('hintText')} />
              </Box>
              <Text children={t('hintSubtext')} />
            </Grid>
            <Grid item container>
              {tenant.cueSheetSubmit && (
                <Box mb={1} width={0.25} display="flex" alignItems="center">
                  <Text bold variant="formLabel">
                    {t('recipient')}
                  </Text>
                  <FormCheckbox
                    gridProps={{
                      container: true,
                      direction: 'row-reverse',
                      alignItems: 'center',
                    }}
                    label={tenant.name}
                    disabled={!tenant.cueSheetRemovable}
                    name="submissionToTenant"
                  />
                </Box>
              )}
              <Box mb={1} width={0.8}>
                <FormTypeAhead
                  isMultiple
                  label={`${rootT}.pro`}
                  onChange={(pros, { setFieldValue }) => {
                    handleSelect(pros, setFieldValue);
                  }}
                  name="pros"
                  options={pros}
                  required
                  testId="cue-sheet-submission-pro"
                />
              </Box>
              <Box mb={1} width={0.5}>
                <FormTypeAhead
                  label={`${rootT}.fileType`}
                  name="fileFormat"
                  options={FILE_TYPES_OPTIONS}
                  disabled={isOnlyRapidCue(values?.pros)}
                  required
                />
              </Box>
              <Box mb={1} width={1} display="flex" flexDirection="row">
                <Box mb={1} width={0.5} mr="5%">
                  <FormTypeAhead
                    label={`${rootT}.submissionStatus`}
                    name="submissionStatus"
                    options={CUE_SHEET_SUBMISSION_STATUS_OPTIONS}
                    required
                    disabled={isOnlyRapidCue(values?.pros)}
                    placeholder={`${rootT}.submissionStatusPlaceholder`}
                  />
                </Box>
                <Box width={0.25}>
                  <FormDatePicker
                    label={`${rootT}.datestamp`}
                    name="submissionDate"
                    disabled={isOnlyRapidCue(values?.pros)}
                    required
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </>
      )}
    </Form>
  );
};

SubmitCueSheetDrawer.propTypes = {
  payload: PropTypes.shape({}),
};

export default enhancer(SubmitCueSheetDrawer);

SubmitCueSheetDrawer.propTypes = {
  name: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  refetchProduction: PropTypes.func.isRequired,
  openPromptAsync: PropTypes.func,
  tenantId: PropTypes.number,
  cueContainerId: PropTypes.number,
  productionId: PropTypes.number,
  divisionId: PropTypes.number,
};
