import { compose } from 'redux';
import { connect } from 'react-redux';
import withBaseDrawer from 'components/common/Drawers/withBaseDrawer';
import { openDrawer } from 'redux-core/dialogs/actions';
import { openPrompt } from 'redux-core/prompt/actions';
import { getIsOpenedDialogFormDirty } from 'redux-core/dialogs/selectors';

const mapStateToProps = (state) => ({
  isDirty: getIsOpenedDialogFormDirty(state),
});

const mapDispatchToProps = {
  openPrompt,
  openDrawer,
};

export default compose(connect(mapStateToProps, mapDispatchToProps), (c) => withBaseDrawer(c, { large: true }));
