/* eslint-disable react/jsx-props-no-spreading */
import values from 'ramda/src/values';
import Fade from '@material-ui/core/Fade';
import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import pick from 'ramda/src/pick';

import PropTypes from 'prop-types';

import DefaultSnackbarContent from './DefaultSnackbarContent';
import HelpMenuSnackbarContent from './HelpMenuSnackbarContent';
import enhancer from './enhancer';

export const SNACKBAR_TYPES = { DEFAULT: 'DEFAULT', HELP: 'HELP' };

// This component should be moved to common-fe
const Toast = (props) => {
  const {
    classes,
    showSnackbar,
    closeSnackbar,
    snackbarProps,
    snackbarContentType,
    snackbarHorizontalPosition,
  } = props;

  const defaultContentProps = pick(
    ['classes', 'snackbarLinkRoute', 'snackbarLinkAction', 'snackbarLinkText', 'snackbarMessage'],
    props,
  );

  const contentTypes = {
    [SNACKBAR_TYPES.DEFAULT]: <DefaultSnackbarContent {...defaultContentProps} />,
    [SNACKBAR_TYPES.HELP]: <HelpMenuSnackbarContent closeSnackbar={closeSnackbar} classes={classes} />,
  };

  return (
    <Snackbar
      data-testid="mui-snackbar"
      className={classes.snackbar}
      anchorOrigin={{
        vertical: 'top',
        horizontal: snackbarHorizontalPosition || 'center',
      }}
      TransitionComponent={Fade}
      autoHideDuration={6000}
      {...snackbarProps}
      open={showSnackbar}
      onClose={closeSnackbar}
    >
      {contentTypes[snackbarContentType]}
    </Snackbar>
  );
};

Toast.defaultProps = {
  snackbarContentType: SNACKBAR_TYPES.DEFAULT,
};

Toast.propTypes = {
  showSnackbar: PropTypes.bool.isRequired,
  snackbarMessage: PropTypes.string.isRequired,
  closeSnackbar: PropTypes.func.isRequired,
  snackbarContentType: PropTypes.oneOf(values(SNACKBAR_TYPES)),
  classes: PropTypes.object.isRequired,
  snackbarLinkText: PropTypes.string,
  snackbarLinkAction: PropTypes.object,
  snackbarLinkRoute: PropTypes.string,
  snackbarHorizontalPosition: PropTypes.string,
  snackbarProps: PropTypes.object,
};

export default enhancer(Toast);
