/* eslint-disable react/require-default-props */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/no-children-prop */
/* eslint-disable @typescript-eslint/no-shadow */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FieldArray } from 'formik';
import { is, remove } from 'ramda';
import { DrawerHeader } from 'components/styleguide';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { FormButton, FormDropdown } from 'components/common/Form/Fields';
import MultiInputEditField from 'components/common/Form/Fields/FormMultiInputEditField';
import EditDeleteMenuWrapperGrid from 'components/common/EditDeleteMenuWrapperGrid';
import Form from 'components/common/Form';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import { concatStrings } from 'utils/index';
import useStyles from './styles';

function ActorDirectorDrawer({ name, options, onClose, payload: { onSubmit, people = [] }, readOnly, rootT }) {
  const { t } = useTranslation();
  const [editableIndex, setEditableIndex] = useState(null);
  const [enableDropdown, setEnableDropdown] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [editingFieldCache, setEditingFieldCache] = useState(null);
  const defaultValue = { firstName: '', lastName: '', role: options[0].id };
  const classes = useStyles();

  const handleSubmit = async (people) => {
    await onSubmit(people);
    setEditableIndex(null);
    setIsAdding(false);
    setEnableDropdown(false);
  };

  const fullNameValidationSchema = Yup.object({
    people: Yup.array().of(
      Yup.object().shape({
        firstName: Yup.string().required('global.forms.validations.required'),
        lastName: Yup.string().required('global.forms.validations.required'),
      }),
    ),
  });

  return (
    <Box pb={4}>
      <Form
        drawerName={name}
        className={classes.form}
        onSubmit={(values) => {
          handleSubmit(values.people);
        }}
        enableReinitialize
        initialValues={{
          people,
        }}
        readOnly={readOnly}
        validationSchema={fullNameValidationSchema}
      >
        {(formik) => (
          <FieldArray name="people">
            {(fieldArrayProps) => (
              <>
                <DrawerHeader title={t(`${rootT}.drawerTitle`)} onClose={onClose}>
                  {is(Number, editableIndex) ? (
                    <>
                      <FormButton
                        alwaysEnabled
                        variant="destructive"
                        size="xs"
                        children="global.forms.cancel"
                        testId="name-drawer-cancel"
                        onClick={() => {
                          isAdding
                            ? fieldArrayProps.remove(0)
                            : fieldArrayProps.replace(editableIndex, editingFieldCache);
                          setIsAdding(false);
                          setEditableIndex(null);
                          setEnableDropdown(false);
                        }}
                      />
                      <FormButton
                        children="global.forms.save"
                        testId="name-drawer-save"
                        size="xs"
                        type="submit"
                        variant="primary"
                      />
                    </>
                  ) : (
                    <FormButton
                      alwaysEnabled
                      children="global.forms.add"
                      testId="name-drawer-add"
                      size="xs"
                      variant="primary"
                      onClick={() => {
                        fieldArrayProps.unshift(defaultValue);
                        setIsAdding(true);
                        setEditableIndex(0);
                        setEnableDropdown(true);
                      }}
                    />
                  )}
                </DrawerHeader>
                <Grid item container xs={12}>
                  {formik.values.people.map((person, index) => (
                    <React.Fragment key={index}>
                      <EditDeleteMenuWrapperGrid
                        spacing={2}
                        disableHover={readOnly || is(Number, editableIndex)}
                        setEditable={() => {
                          setEditingFieldCache(person);
                          setEditableIndex(index);
                          setEnableDropdown(true);
                        }}
                        onConfirm={() => {
                          const people = remove(index, 1, formik.values.people);
                          handleSubmit(people);

                          fieldArrayProps.remove(index);
                          setEditableIndex(null);
                          setEnableDropdown(false);
                        }}
                        promptContent={t(`${rootT}.delete`, {
                          name: concatStrings(' ')(person.firstName, person.lastName),
                        })}
                      >
                        <Grid item xs={6}>
                          <MultiInputEditField
                            name={`people.${index}`}
                            inputOneProps={{
                              placeholder: t('drawers.firstNameLastName.placeholderFirstName'),
                            }}
                            fieldOne={{ name: 'firstName', editable: true }}
                            inputTwoProps={{
                              placeholder: t('drawers.firstNameLastName.placeholderLastName'),
                            }}
                            fieldTwo={{ name: 'lastName', editable: true }}
                            editable={editableIndex === index}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <FormDropdown
                            name={`people.${index}.role`}
                            valueKey="id"
                            options={options}
                            readOnly={!enableDropdown || editableIndex !== index}
                          />
                        </Grid>
                      </EditDeleteMenuWrapperGrid>
                      <Divider style={{ marginTop: '0.4rem' }} className={classes.divider} />
                    </React.Fragment>
                  ))}
                </Grid>
              </>
            )}
          </FieldArray>
        )}
      </Form>
    </Box>
  );
}

ActorDirectorDrawer.propTypes = {
  name: PropTypes.string,
  options: PropTypes.array,
  readOnly: PropTypes.bool,
  deletePromptContent: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  nameAndLastName: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  payload: PropTypes.object.isRequired,
  rootT: PropTypes.string.isRequired,
};

export default ActorDirectorDrawer;
