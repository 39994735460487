import { createSelector } from 'reselect';
import prop from 'ramda/src/prop';

const getSnackbarOptions = prop('snackbar');

export const getSnackbarMessage = createSelector(getSnackbarOptions, prop('message'));

export const shouldShowSnackbar = createSelector(getSnackbarOptions, prop('show'));

export const getSnackbarLinkText = createSelector(getSnackbarOptions, prop('linkText'));

export const getSnackbarLinkAction = createSelector(getSnackbarOptions, prop('linkAction'));

export const getSnackbarHorizontalPosition = createSelector(getSnackbarOptions, prop('horizontalPosition'));

export const getSnackbarLinkRoute = createSelector(getSnackbarOptions, prop('snackbarLinkRoute'));

export const getSnackbarProps = createSelector(getSnackbarOptions, prop('snackbarProps'));

export const getSnackbarContentType = createSelector(getSnackbarOptions, prop('snackbarContentType'));
