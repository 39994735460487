/* eslint-disable import/no-cycle */
import makeEntityServiceCalls, { makeServiceCall } from 'redux-core/serviceHelpers';

export const getLicensesUngroupedCall = makeServiceCall('licenses', 'get-all-ungrouped', 'clear');

export const {
  getAll: getLicensingPayees,
  get: getLicensingPayee,
  update: updatePayee,
  delete: deletePayee,
  create: createPayee,
} = makeEntityServiceCalls('payees', 'clear');

export const upsertChecks = makeServiceCall('payees', 'bulk-upsert-checks', 'clear');
