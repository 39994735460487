/* eslint-disable no-bitwise */
import compose from 'ramda/src/compose';
import dropLast from 'ramda/src/dropLast';
import split from 'ramda/src/split';
import head from 'ramda/src/head';
import when from 'ramda/src/when';
import is from 'ramda/src/is';
import join from 'ramda/src/join';

/** @see https://stackoverflow.com/a/12900504 */
export const getFileExtension = (fileName = '') => fileName.slice(((fileName.lastIndexOf('.') - 1) >>> 0) + 2);

export const getFileName = compose(when(is(Array), head, join('')), dropLast(1), split('.'));

/**
 * Parse file for easier later use
 */
export const normalizeFile = (file) => {
  const fileName = file.name || file.fileName;
  const fileSize = file.size || file.fileSize || 0;
  const type = file.type || file.fileType;
  const path = file.path || fileName;

  return {
    ...file,
    fileName,
    fileSize,
    type,
    path,
    lastModified: file.lastModified,
    lastModifiedDate: file.lastModifiedDate,
    webkitRelativePath: file.webkitRelativePath,
    extension: getFileExtension(fileName),
    plainName: getFileName(fileName),
  };
};
