import compose from 'ramda/src/compose';
import mergeRight from 'ramda/src/mergeRight';
import map from 'ramda/src/map';
import sort from 'ramda/src/sort';
import groupBy from 'ramda/src/groupBy';
import prop from 'ramda/src/prop';
import filter from 'ramda/src/filter';
import curry from 'ramda/src/curry';
import assoc from 'ramda/src/assoc';
import when from 'ramda/src/when';
import { sortAlphabetically } from 'utils';
import { LICENSOR_TYPES } from './constants';
import { mapIndexed } from './object';

/** All existing licensors by default */
const defaultLicensors = Object.values(LICENSOR_TYPES);

export const groupLicensorsByType = curry((licensors = [], allowedLicensors = defaultLicensors, sortByName = true) => {
  const emptyLicensors = allowedLicensors.reduce((acc, licensor) => ({ ...acc, [licensor]: [] }), {});

  return compose(
    mergeRight(emptyLicensors),
    when(() => sortByName, map(sort((a, b) => sortAlphabetically(a.name, b.name)))),
    groupBy(prop('type')),
    filter((licensor) => allowedLicensors.includes(licensor.type)),
    mapIndexed((licensor, index) => assoc('index', index, licensor)), // add array index to use it in formik
  )(licensors);
});
