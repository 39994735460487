/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-cycle */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { useDrawer, useRootTranslation } from 'utils/hooks';
import { FormButton, FormCheckbox, FormDatePicker, QTracksTypeAhead } from 'components/common/Form/Fields';
import { getLocalDate } from 'utils/format';
import { Text } from 'components/styleguide';
import { useFormikContext } from 'formik';
import { searchContactsCall } from 'redux-core/contacts/services';
import filter from 'ramda/src/filter';
import { SEARCH_CONTACTS_FIELDS } from 'utils/constants';
import { getIsFinished, getFinishersList } from '../index';
// eslint-disable-next-line import/no-named-as-default
import DRAWERS from '../../constants';
import enhancer from './enhancer';
import useStyles from './styles';

const rootT = 'drawers.assets';

const typeAheadSharedProps = {
  isMultiple: true,
  loading: false,
  tall: true,
};

const call = (divisionId, fields) => async (term) => {
  const contacts = await searchContactsCall({ divisionId, term, fields });
  return contacts.map(({ fullName: name, ...rest }) => ({
    ...rest,
    name,
  }));
};

function DetailsFinish({ divisionId }) {
  const classes = useStyles();
  const openDrawer = useDrawer();
  const formik = useFormikContext();
  const t = useRootTranslation(rootT);

  const [isFinished, setIsFinished] = useState(false);

  // only display finishedValues
  const finishers = filter((v) => v.finishingDate, getFinishersList(formik.values));

  useEffect(() => {
    if (formik.values.killed) {
      setIsFinished(false);
      return;
    }
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const isFinished = getIsFinished(formik.values);
    setIsFinished(isFinished);
  }, [formik.values]);

  return (
    <Grid item xs={6} container={isFinished ? true : undefined} spacing={isFinished ? 1 : undefined}>
      <Grid item container className={!isFinished ? classes.finishRow : undefined}>
        <QTracksTypeAhead
          call={call(divisionId, [SEARCH_CONTACTS_FIELDS.LICENSOR_COMPANY_NAME])}
          label={t('labels.finishingVendors')}
          testId="asset-drawer-finishing-vendors"
          name="additionalData.finishingVendors"
          {...typeAheadSharedProps}
        />
      </Grid>
      <Grid item container className={!isFinished ? classes.finishRow : undefined}>
        <QTracksTypeAhead
          call={call(divisionId, [SEARCH_CONTACTS_FIELDS.NAME, SEARCH_CONTACTS_FIELDS.EMAIL])}
          label={t('labels.finishers')}
          testId="asset-drawer-finishers"
          name="additionalData.finishers"
          {...typeAheadSharedProps}
        />
      </Grid>
      <Grid item container className={!isFinished ? classes.finishRow : undefined}>
        <QTracksTypeAhead
          call={call(divisionId, [SEARCH_CONTACTS_FIELDS.LICENSOR_COMPANY_NAME])}
          label={t('labels.creativeVendors')}
          testId="asset-drawer-creative-vendors"
          name="additionalData.creativeVendors"
          {...typeAheadSharedProps}
        />
      </Grid>
      {isFinished ? (
        <>
          <Grid item xs={6}>
            <FormDatePicker testId="asset-drawer-dateUsed" label={`${rootT}.labels.dateUsed`} name="dateUsed" />
          </Grid>
          <Grid item container justify="space-between">
            <Grid item xs={3}>
              <Text variant="h6" color="textSecondary">
                {t('labels.finishes')}
              </Text>
            </Grid>
            <Grid item xs={3}>
              <FormButton
                testId="asset-drawer-details"
                alwaysEnabled
                variant="secondary"
                size="xs"
                // eslint-disable-next-line react/no-children-prop
                children="global.forms.details"
                onClick={() =>
                  openDrawer(DRAWERS.CAMPAIGN_COMPLETE_ASSET, {
                    assetId: formik.values.id,
                    viewOnly: true,
                  })
                }
              />
            </Grid>
          </Grid>
          {finishers.map((finish, index) => (
            <Grid item container className={classes.finishTile} key={`${finish.isci}-${finish.name}`}>
              <Grid item container>
                <Grid item xs={1}>
                  <FormCheckbox
                    testId="asset-drawer-finisher-checked"
                    name={`additionalData.finishers.${index}.active`}
                  />
                </Grid>
                <Grid item xs={3} className={classes.finishLabel}>
                  <Text variant="formLabel" color="textSecondary">
                    {t('labels.isciNumber')}
                  </Text>
                </Grid>
                <Grid item xs={4} className={classes.finishLabel}>
                  <Text variant="formLabel" color="textSecondary">
                    {t('labels.finisher')}
                  </Text>
                </Grid>
                <Grid item xs={4} className={classes.finishLabel}>
                  <Text variant="formLabel" color="textSecondary">
                    {t('labels.finishingDate')}
                  </Text>
                </Grid>
              </Grid>
              <Grid item container>
                <Grid item xs={1} />
                <Grid item xs={3}>
                  <Text>{finish.isci}</Text>
                </Grid>
                <Grid item xs={4}>
                  <Text>{finish.name}</Text>
                </Grid>
                <Grid item xs={4}>
                  <Text>{getLocalDate(finish.finishingDate)}</Text>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </>
      ) : null}
    </Grid>
  );
}

DetailsFinish.propTypes = {
  divisionId: PropTypes.number.isRequired,
  finishers: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      isci: PropTypes.number,
      finishingDate: PropTypes.string,
      dateUsed: PropTypes.string,
    }),
  ).isRequired,
  isFinished: PropTypes.bool.isRequired,
};

export default enhancer(DetailsFinish);
