import Grid from '@material-ui/core/Grid';
import { Typography } from '@mui/material';

export const Description = ({ title, value, testId }) => (
  <Grid item xs={6} container direction="column">
    <Typography sx={{ fontWeight: 'bold' }} variant="caption" id={title}>
      {title}
    </Typography>
    <Typography variant="caption" data-testid={`text-${testId}`} aria-labelledby={title}>
      {value || '--'}
    </Typography>
  </Grid>
);
