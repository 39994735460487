import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  labelWrapper: {
    marginBottom: theme.spacing(),
  },
  chip: {
    marginBottom: theme.spacing(0.25),
    marginRight: theme.spacing(0.5),
    maxWidth: '90%',
    '& > span': {
      overflow: 'hidden',
    },
  },
}));

export default useStyles;
