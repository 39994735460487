/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { Layout, Text } from 'components/styleguide';
import { useRootTranslation } from 'utils/hooks';
import { FormCheckbox } from 'components/common/Form/Fields';
import { concatStrings } from 'utils/index';
import FieldGroup from './FieldGroup';
import useStyles from './styles';

const rootT = 'drawers.qtracksSync.diff';

const TracksDiff = ({ groups, count, onSelectAll }) => {
  const t = useRootTranslation(rootT);
  const [selectAll, setSelectAll] = useState();
  const onChange = ({ setValues }, _, value) => {
    setSelectAll(value);
    onSelectAll(setValues, value);
  };
  const classes = useStyles();

  return (
    <Layout className={classes.content} p={3.5} pb={4} container>
      <Grid item xs={6} container alignItems="center" spacing={2}>
        <Grid item>
          <Text bold>{t('qTracksDetails')}</Text>
        </Grid>
        <Grid item>
          <Text variant="caption">{t('changes', { count })}</Text>
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <Text bold>{t('productionDetails')}</Text>
      </Grid>
      <Box ml={1}>
        <FormCheckbox label={t('selectAll')} name="selectAll" onChange={onChange} value={selectAll} />
      </Box>
      {groups.map((props) => (
        <FieldGroup {...props} key={concatStrings(props.group, props.position)} />
      ))}
    </Layout>
  );
};

TracksDiff.propTypes = {
  qclearTrack: PropTypes.object,
  qtTrack: PropTypes.object,
};

export default TracksDiff;
