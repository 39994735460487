import React from 'react';
import { Grid } from '@material-ui/core';
import { rootTranslation, TIME_FORMATS } from 'utils/format';
import InfoIconFilled from '@material-ui/icons/Info';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { TextField } from '@mui/material';
import RightsPresetPickerFormField from 'components/common/RightsPresetPicker/RightsPresetPickerFormField';
import InputFormatField from 'components/common/Form/Fields/FormInputFormat/InputFormatField';
import { FormField } from 'components/common/Form/Fields/FormField/FormField';
import { CategoryField } from '../SeasonDetails/CategoryField';

const t = rootTranslation('drawers.quickSetupProduction');

type ReleaseDetailsProps = {
  divisionId: number;
};

function ReleaseDetails({ divisionId }: ReleaseDetailsProps) {
  return (
    <Grid direction="row" alignItems="center" justify="space-between" container item spacing={2}>
      <Grid container item direction="column" justify="space-between" spacing={2} xs={12}>
        <Grid item xs={12}>
          <FormField
            label={t('release')}
            name="release"
            required
            render={({ field, muiprops }) => (
              <TextField
                {...field}
                {...muiprops}
                data-testid="input-text-release"
                placeholder={t('releasePlaceholder')}
                InputProps={{
                  endAdornment: (
                    <Tooltip title={t('releaseToolTip')} placement="bottom-start">
                      <IconButton size="small" color="primary" aria-label="">
                        <InfoIconFilled />
                      </IconButton>
                    </Tooltip>
                  ),
                }}
              />
            )}
          />
          <span style={{ color: '#9A57DD', fontSize: 12, fontStyle: 'italic' }}>{t('requiredProduction')}</span>
        </Grid>
        <Grid item xs={12}>
          <FormField
            name="requiredRights"
            required
            label={t('releaseRequiredRights')}
            render={({ field, muiprops }) => (
              <RightsPresetPickerFormField {...field} {...muiprops} divisionId={divisionId} />
            )}
          />
          <span style={{ color: '#9A57DD', fontSize: 12, fontStyle: 'italic' }}>{t('requiredProduction')}</span>
        </Grid>
      </Grid>
      <Grid container direction="row" item xs={12} spacing={2}>
        <Grid item xs={4}>
          <FormField
            label={t('category')}
            name="categoryId"
            render={({ field, muiprops }) => <CategoryField {...field} {...muiprops} />}
          />
          <span style={{ color: '#9A57DD', fontSize: 12, fontStyle: 'italic' }}>{t('cueSheetOnly')}</span>
        </Grid>
        <Grid item xs={4}>
          <FormField
            label={t('duration')}
            name="duration"
            render={({ field }) => (
              <InputFormatField id="duration" {...field} format={TIME_FORMATS.HHMMSS} type="time" textAlign="left" />
            )}
          />
          <span style={{ color: '#9A57DD', fontSize: 12, fontStyle: 'italic' }}>{t('cueSheetOnly')}</span>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default ReleaseDetails;
