import { makeStyles } from '@material-ui/core/styles';
import { appColors } from 'components/styleguide/theme';

const useStyles = makeStyles({
  badge: {
    '& svg': {
      color: appColors.blue,
      height: '20px',
    },
    '& > span': {
      width: '12px',
      height: '12px',
      minWidth: 'auto',
      top: '5px',
      right: '3px',
    },
  },
});

export default useStyles;
