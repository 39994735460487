import { baseApi, TPagination } from 'services';
import { TClearanceStatus } from './clearance-status';
import { TCreateTrackRequest, TCreateTrackResponse } from './types';

export type TMoveTrackToProductionRequestDto = {
  trackId: number;
  targetCueContainerId: number;
};

export type TUpdateUsingTrackRequestDto = {
  id: number;
  cueContainerId?: number;
  cueId?: number;
  used: boolean;
};

export type TGetTracksAllowedByUserResponse = {
  data: Array<{ title: string }>;
  pagination: TPagination;
};

export type TGetTrackTagsAllowedByUserResponse = {
  data: Array<{ name: string }>;
  pagination: TPagination;
};

export const clearTracksApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    moveToProduction: builder.mutation<void, TMoveTrackToProductionRequestDto>({
      query: (body) => ({
        url: 'clear/tracks/move-to-production',
        method: 'POST',
        body,
      }),
    }),
    updateUsed: builder.mutation<void, TUpdateUsingTrackRequestDto>({
      query: (body) => ({
        url: 'clear/tracks/update-used',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['getBundleLicenses', 'clearances', 'getTrackClearanceStatus'],
    }),
    getTracksAllowedByUser: builder.query<TGetTracksAllowedByUserResponse, { searchTerm?: string }>({
      query: (body) => ({
        url: 'clear/tracks/get-all-allowed-by-user',
        method: 'POST',
        body,
      }),
    }),
    getTrackTagsAllowedByUser: builder.query<
      TGetTrackTagsAllowedByUserResponse,
      { searchTerm?: string; trackId?: number }
    >({
      query: (body) => ({
        url: 'clear/tracks/get-all-track-tags-allowed-by-user',
        method: 'POST',
        body,
      }),
    }),
    createTrack: builder.mutation<TCreateTrackResponse, TCreateTrackRequest>({
      query: (body) => ({ url: 'clear/tracks/create', method: 'POST', body }),
    }),
    getTrackClearanceStatus: builder.query<TClearanceStatus, { trackId: number }>({
      query: (body) => ({
        url: 'clear/tracks/get-track-clearance-status',
        method: 'POST',
        body,
      }),
      providesTags: (result, error, arg) => result
          ? [({ type: 'getTrackClearanceStatus' as const, id: arg.trackId }), 'getTrackClearanceStatus']
          : ['getTrackClearanceStatus'],
    }),
    getTrackClearanceFees: builder.query<any, { trackId: number }>({
      query: (body) => ({
        url: 'clear/tracks/get-track-clearance-fees',
        method: 'POST',
        body,
      }),
      providesTags: (result, error, arg) => result
        ? [({ type: 'getTrackClearanceFees' as const, id: arg.trackId }), 'getTrackClearanceFees']
        : ['getTrackClearanceFees'],
    }),
  }),
});

export const {
  useMoveToProductionMutation,
  useUpdateUsedMutation,
  useGetTracksAllowedByUserQuery,
  useGetTrackTagsAllowedByUserQuery,
  useGetTrackClearanceStatusQuery,
  useGetTrackClearanceFeesQuery,
  useCreateTrackMutation,
} = clearTracksApi;
