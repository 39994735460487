/* eslint-disable import/no-cycle */
import { makeStyles } from '@material-ui/core';
import { appColors } from 'components/styleguide';

const styles = makeStyles((theme) => ({
  avatar: {
    width: 28,
    height: 28,
    color: appColors.white,
    backgroundColor: theme.palette.primary.main,
    fontSize: 13,
    fontWeight: 'bold',
  },
  buttonWrapper: {
    borderRadius: '50%',
  },
}));

export default styles;
