import { makeStyles } from '@material-ui/core/styles';
import { appColors } from 'components/styleguide';

const useStyles = makeStyles((theme) => ({
  root: {
    // The 100% minus the vertical padding
    height: `calc(100% - ${theme.spacing(4)}px)`,
    overflow: 'auto',
    overflowX: 'hidden',
    padding: theme.spacing(2, 1, 2, 2),
  },
  icon: {
    cursor: 'pointer',
  },
  episodeRow: {
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  episodeLink: {
    cursor: 'pointer',
    textDecoration: 'none',
    color: appColors.black,
  },
}));

export default useStyles;
