import { useController, useFormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { FormField } from 'components/common/Form/Fields/FormField/FormField';
import { CueContainerPickerTreeView } from 'components/CueContainerPickerTreeView/CueContainerPickerTreeView';
import { addParams } from 'redux-core/router/actions';
import { getProductionUseInfo } from 'redux-core/overview/actions';
import { rootTranslation } from 'utils/format';
import { ScenePicker } from './ScenePicker';
import { useSetAddTrackDrawerFilters } from '../addTrackDrawerStore';

const t = rootTranslation('drawers.productionSetup.tracks.createTrack');

export function SceneField({ useCueContainerPickerTreeView }: { useCueContainerPickerTreeView: boolean }) {
  const dispatch = useDispatch();
  const setAddTrackDrawerFilters = useSetAddTrackDrawerFilters();
  const { field: cueContainerPathField } = useController({ name: 'cueContainerPath' });
  const { clearErrors } = useFormContext();
  const label = useCueContainerPickerTreeView ? 'scenePicker.label' : 'form.scene';

  return (
    <FormField
      fullWidth
      label={t(label)}
      name="cueContainerId"
      render={({ field, muiprops }) => {
        if (useCueContainerPickerTreeView) {
          return (
            <CueContainerPickerTreeView
              onChange={({ selectedPath }) => {
                cueContainerPathField.onChange(selectedPath);
                const cueContainer = selectedPath.at(-1);
                field.onChange(cueContainer.id);
                setAddTrackDrawerFilters({ cueContainerId: cueContainer.id });

                clearErrors('cueContainerId');

                dispatch(
                  addParams({
                    // cueContainer first parent is the production
                    id: selectedPath.at(-2).id,
                    divisionId: cueContainer.divisionId,
                  }),
                );
                dispatch(getProductionUseInfo());
              }}
              value={cueContainerPathField.value}
            />
          );
        }

        return (
          <ScenePicker
            {...field}
            {...muiprops}
            onChange={(nextValue) => {
              field.onChange(nextValue.id);
              setAddTrackDrawerFilters({ cueContainerId: nextValue.id });
            }}
          />
        );
      }}
    />
  );
}
