/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useMemo } from 'react';
import { BaseDrawer, Loading } from 'components/styleguide';
import Form from 'components/common/Form';
import { useFetch } from 'utils/hooks';
import { getIn, setIn } from 'formik';
import { getTrackByIdCall, getQwireTrackDetailsCall } from 'redux-core/tracks/services';
import enhancer from './enhancer';
import Header from './Header';
import TracksDiff from './TracksDiff';
import ChangesSlider from './ChangesSlider';
import { calculateDiff, parseTracks, unparseTrack } from './utils';

const QwireTracksSync = ({ name, onClose, payload: { id: trackId, qwireTracksId }, updateTrack, tenantId }) => {
  const [versionId, setVersion] = useState();
  const [qclearTrack, loading1] = useFetch(
    () => trackId && getTrackByIdCall({ id: trackId, includeRightsOwners: true }),
    [trackId],
    {},
  );

  const [qtTrack, loading2] = useFetch(
    () =>
      qwireTracksId &&
      getQwireTrackDetailsCall({
        id: qwireTracksId,
        versionId: versionId || undefined,
      }),
    [qwireTracksId, versionId],
    {},
  );

  const [parsedQClearTrack, parsedQtTrack] = useMemo(() => parseTracks(qclearTrack, qtTrack), [qclearTrack, qtTrack]);

  const [groups, diff] = useMemo(
    () => calculateDiff(parsedQClearTrack, parsedQtTrack),
    [parsedQClearTrack, parsedQtTrack],
  );

  const loading = (loading1 || loading2) && !diff.length;

  const onSubmit = async (payload) => {
    // syncToQTracks will prevent sync'ing to QTracks
    await updateTrack({ ...unparseTrack(payload), syncToQTracks: false });
    onClose();
  };

  const onCancel = () => {
    onClose();
  };

  const fullyUpdatedTrack = useMemo(
    () => diff.reduce((acc, path) => setIn(acc, path, getIn(parsedQtTrack, path)), parsedQClearTrack),
    [diff, parsedQClearTrack, parsedQtTrack],
  );
  const onSelectAll = (setValues, selectAll) => (selectAll ? setValues(fullyUpdatedTrack) : setValues(qclearTrack));

  return (
    <Form drawerName={name} enableReinitialize initialValues={{ ...parsedQClearTrack, versionId }} onSubmit={onSubmit}>
      <Header
        onCancel={onCancel}
        qwireTracksId={qwireTracksId}
        setVersion={setVersion}
        version={versionId}
        tenantId={tenantId}
      />
      {loading ? <Loading /> : <TracksDiff count={diff.length} groups={groups} onSelectAll={onSelectAll} />}
      <ChangesSlider diff={diff} />
    </Form>
  );
};

const Drawer = ({ open, onClose, ...props }) => (
  <BaseDrawer open={open} onClose={onClose} xlarge dense>
    <QwireTracksSync onClose={onClose} {...props} />
  </BaseDrawer>
);

export default enhancer(Drawer);
