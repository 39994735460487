/* eslint-disable react/no-children-prop */
/* eslint-disable @typescript-eslint/no-shadow */
import React from 'react';
import { Box } from '@mui/system';
import PropTypes from 'prop-types';
import map from 'ramda/src/map';
import { v4 as uuidv4 } from 'uuid';
import { requestWithError } from 'utils/request';
import { useRootTranslation } from 'utils/hooks';
import { LICENSOR_TYPES } from 'utils/constants';
import { saveTrackRightsOwnersCall } from 'redux-core/tracks/services';
import { CreateLicensorsForm } from 'components/common/Drawers/AddTrackDrawer/createLicensors/CreateLicensorsForm';
import enhancer from './enhancer';

const CreateLicensors = ({
  drawerName,
  setDrawerDirty,
  divisionId,
  trackId,
  qwireTracksId,
  onCancel,
  refreshCueContainersByTrackProp,
}) => {
  const t = useRootTranslation('drawers.productionSetup.tracks.createLicensors');

  const handleSubmit = async ({ rightsOwners, oneStopCombined }) => {
    await requestWithError(
      saveTrackRightsOwnersCall,
      {
        divisionId,
        id: trackId,
        oneStopCombined,
        rightsOwners: map(
          (c) => (c.type === LICENSOR_TYPES.ONE_STOP ? { ...c, type: LICENSOR_TYPES.SYNC } : c),
          rightsOwners,
        ),
      },
      { message: { success: t('form.successMessage') } },
    );

    await setDrawerDirty({
      name: drawerName,
      dirty: false,
      submitted: true,
    });

    await refreshCueContainersByTrackProp(qwireTracksId, 'qwireTracksId');

    onCancel();
  };

  const handleCancel = async ({ isDirty }) => {
    if (isDirty) {
      await setDrawerDirty({
        name: drawerName,
        dirty: true,
        submitted: false,
      });
    }
    onCancel();
  };

  return (
    <Box sx={{ mb: 6 }}>
      <CreateLicensorsForm onSubmit={handleSubmit} onCancel={handleCancel} generateOptionId={uuidv4} />
    </Box>
  );
};

CreateLicensors.propTypes = {
  divisionId: PropTypes.number.isRequired,
  trackId: PropTypes.number.isRequired,
  qwireTracksId: PropTypes.number.isRequired,
  onCancel: PropTypes.func.isRequired,
  refreshCueContainersByTrackProp: PropTypes.func.isRequired,
  drawerName: PropTypes.string,
  setDrawerDirty: PropTypes.func.isRequired,
};

export default enhancer(CreateLicensors);
