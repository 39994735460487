/* eslint-disable react/no-children-prop */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContentMUI from '@material-ui/core/SnackbarContent';
import ConfirmationDialog from 'components/common/ConfirmationDialog';
import classnames from 'classnames';
import { useRootTranslation } from 'utils/hooks';
import { FormButton } from '..';
import useStyles from './styles';
import enhancer from './enhancer';

const rootT = 'global';

const FormSnackbar = ({
  formik: { dirty, resetForm, isValid, values, setFieldValue },
  onCancel,
  openDialog,
  isOtherPromptOpen,
  embedded,
}) => {
  const t = useRootTranslation(rootT);
  const classes = useStyles({ embedded });
  const [open, setOpen] = React.useState(false);
  const [openPrompt, setOpenPrompt] = React.useState(false);

  useEffect(() => {
    setOpen(dirty || values.dirty);
  }, [dirty, values.dirty]);

  const handleClose = (_e, reason) => {
    if (reason === 'clickaway') return;
    setOpenPrompt(true);
  };

  const handleCancel = async () => {
    setOpenPrompt(false);
    await resetForm();
    if (values.dirty) await setFieldValue('dirty', false);
    setOpen(false);
    onCancel();
  };

  return (
    <>
      <Snackbar
        classes={{ root: classnames(classes.root, { embedded }) }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={open && !openDialog && !openPrompt && !isOtherPromptOpen}
        onClose={handleClose}
      >
        <SnackbarContentMUI
          classes={{
            root: classnames(classes.content, { embedded }),
            message: classes.message,
          }}
          message={t('forms.unsavedChanges')}
          action={[
            <FormButton
              testId="form-snackbar-cancel"
              alwaysEnabled
              children="global.forms.cancel"
              key="cancel"
              onClick={handleClose}
              size="sm"
              type="button"
              variant="destructive"
            />,
            <Box ml={2} key="submit">
              <FormButton
                testId="form-snackbar-save"
                children="global.forms.save"
                size="sm"
                type="submit"
                variant="primary"
                disabled={!isValid}
              />
            </Box>,
          ]}
        />
      </Snackbar>
      <ConfirmationDialog
        onClose={() => setOpenPrompt(false)}
        onConfirm={handleCancel}
        open={openPrompt}
        content={t('confirmDialog.cancel')}
      />
    </>
  );
};

FormSnackbar.propTypes = {
  onCancel: PropTypes.func,
  formik: PropTypes.shape({}),
  openDialog: PropTypes.string,
  isOtherPromptOpen: PropTypes.bool,
  embedded: PropTypes.bool,
};

FormSnackbar.defaultProps = {
  onCancel: () => undefined,
};

export default enhancer(FormSnackbar);
