import { Box, InputLabel, Skeleton } from '@mui/material';
import { TypeAhead } from 'components/styleguide';
import { useSetAtom } from 'jotai';
import { useState } from 'react';
import { getCurrentCueSheetVersionCall } from 'redux-core/cueSheet/services';
import { searchByProjectIdAndType } from 'redux-core/productions/services';
import { useFetch, useRootTranslation } from 'utils/hooks';
import { getProductionTypeFromDivisionType, TChooseCueSheet } from '.';
import { cueSheetAndParentProductionAtom } from '../drawerStore';

export type TFeature = {
  featureId: number;
  featureName: string;
};

export const ChooseFeatureCueSheet = ({
  productionType,
  project,
}: Pick<TChooseCueSheet, 'productionType' | 'project'>) => {
  const t = useRootTranslation('drawers.quickSetupProduction.exportSegmentSteps');
  const [release, setRelease] = useState(null);
  const setCueSheetAndParentProduction = useSetAtom(cueSheetAndParentProductionAtom);
  const [releases, loadingReleases] = useFetch(
    async () =>
      searchByProjectIdAndType({
        projectId: project.id,
        productionType: getProductionTypeFromDivisionType(productionType),
      }),
    [project],
  );

  if (loadingReleases) return <Skeleton />;

  const chooseRelease = async (featureId: TFeature['featureId']) => {
    const currentCueSheet = await getCurrentCueSheetVersionCall(featureId);
    setCueSheetAndParentProduction({
      parentProduction: featureId,
      cueSheetId: currentCueSheet.id,
    });
    const feature = releases.find((c) => c.featureId === featureId);
    setRelease(feature);
  };

  return (
    <Box py={1}>
      <InputLabel id="release-dropdown-label">{t('release')}</InputLabel>
      <TypeAhead
        isClearable={false}
        options={releases}
        onChange={chooseRelease}
        placeholder="Choose a release"
        value={release?.filmReleaseId}
        testId="release-dropdown"
        valueKey="filmReleaseId"
        labelKey="filmReleaseName"
        startAdornment={undefined}
        endAdornment={undefined}
      />
    </Box>
  );
};
