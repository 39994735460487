/* eslint-disable jsx-a11y/anchor-is-valid */
import { Box, Grid, Link } from '@material-ui/core';
import AddIcon from '@material-ui/icons/AddCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import classnames from 'classnames';

import { FormDropdown } from 'components/common/Form/Fields';
import { useRootTranslation } from 'utils/hooks';
import { ProductionRoles } from 'utils/roles';
import { ShareWithUser } from '../ShareWithUserForm';
import { SelectUserField } from './SelectUserField';
import { useShareWithUsersStyle } from './styles';

export type ShareWithUserRowProps = {
  shareWithUserItem: ShareWithUser;
  divisionId: number;
  objectId: number;
  updateShareWithUserEmail: (email: string) => void;
  rowIndex: number;
  removeUser: (index: number) => void;
  addUser: () => void;
  lastRow: boolean;
};

export const ShareWithUserRow = ({
  shareWithUserItem,
  divisionId,
  objectId,
  updateShareWithUserEmail,
  rowIndex,
  removeUser,
  addUser,
  lastRow,
}: ShareWithUserRowProps) => {
  const { rowAction, rowActionContainer, firstRow } = useShareWithUsersStyle();
  const t = useRootTranslation('drawers.shareWithUser');
  return (
    <Box mt={2}>
      <Grid container spacing={2} direction="row" alignItems="stretch">
        <Grid item xs={5}>
          <SelectUserField
            value={shareWithUserItem.email}
            divisionId={divisionId}
            objectId={objectId}
            onSelectUser={updateShareWithUserEmail}
            showLabel={rowIndex === 0}
            testId={`share-with-user-autocomplete-${rowIndex}`}
          />
        </Grid>
        <Grid item xs={5}>
          <FormDropdown
            name={`shareList.${rowIndex}.defaultProjectRole`}
            placeholder="global.forms.chooseOne"
            options={ProductionRoles}
            valueKey="id"
            labelKey="name"
            label={rowIndex === 0 ? t('labels.role') : null}
            testId={`shareList-${rowIndex}-defaultProjectRole`}
          />
        </Grid>
        <Grid
          item
          xs={2}
          className={classnames(
            rowActionContainer,
            rowIndex === 0 ? firstRow : '',
          )}
        >
          <Link className={rowAction} onClick={() => removeUser(rowIndex)}>
            <CancelIcon color="primary" />
          </Link>
          {lastRow && (
            <Link className={rowAction} onClick={addUser}>
              <AddIcon color="primary" /> Users
            </Link>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};
