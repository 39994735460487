import { connect } from 'react-redux';
import { getLicensingFilters } from 'redux-core/licensing/selectors';
import compose from 'ramda/src/compose';
import { getSelectedProductionDivisionIdSelector } from 'redux-core/overview/selectors';
import withBaseDrawer from '../withBaseDrawer';

const mapStateToProps = (state) => ({
  divisionId: getSelectedProductionDivisionIdSelector(state),
  filters: getLicensingFilters(state),
});

export default compose(connect(mapStateToProps), (c) => withBaseDrawer(c, { wide: true }));
