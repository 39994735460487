/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Proptypes from 'prop-types';
import { FormInputText } from 'components/common/Form/Fields';
import { useFormikContext } from 'formik';
import pathOr from 'ramda/src/pathOr';
import { appColors } from 'components/styleguide';
import IconBaseButton from 'components/ClearanceDetails/IconBaseButton';
import { useRootTranslation } from 'utils/hooks';
import { Delete as DeleteIcon } from '@material-ui/icons';
import { isReadOnly } from 'components/common/Form/utils';
import ContactForm from '../ContactForm';

const rootT = 'drawers.licensorsAddEdit.subLabel';

const SubLabelForm = ({ index, rootKey, remove, ...props }) => {
  const t = useRootTranslation(rootT);
  const {
    values: { licensor = {} },
    ...form
  } = useFormikContext();
  const qTrack = pathOr({}, ['qTrack'])(licensor);
  const readOnly = isReadOnly(form);
  const [disabled, setDisabled] = useState(!qTrack.name);

  useEffect(() => setDisabled(!qTrack.name), [qTrack]);

  return (
    <Grid item container spacing={2}>
      <Grid item xs={8}>
        <FormInputText
          required
          name={`${rootKey}.recordLabels.${index}.name`}
          label={`${rootT}.name`}
          testId="sub-label-name"
        />
      </Grid>
      <Grid item xs={2}>
        <FormInputText
          testId="sub-label-uniqueId"
          name={`${rootKey}.recordLabels.${index}.uniqueId`}
          label={`${rootT}.labelId`}
        />
      </Grid>
      <ContactForm
        disabled={disabled}
        {...props}
        contactKey={`${rootKey}.recordLabels.${index}.contact`}
        rootT={rootT}
      />
      {!readOnly && (
        <Grid container justify="flex-end" alignItems="center">
          <IconBaseButton
            bold
            Icon={DeleteIcon}
            text={t('deleteSubLabel')}
            color={appColors.red}
            width={170}
            onClick={remove}
          />
        </Grid>
      )}
    </Grid>
  );
};

SubLabelForm.propTypes = {
  index: Proptypes.number,
};
export default SubLabelForm;
