import { connect } from 'react-redux';
import { openPromptAsync } from 'redux-core/prompt/actions';
import { getSelectedProductionDivisionIdSelector } from 'redux-core/overview/selectors';
import { getImportTemplateIdSelector, getImportFileNameSelector } from 'redux-core/import-cuesheet/selectors';
import { setTemplateId } from 'redux-core/import-cuesheet/actions';

const mapStateToProps = (state) => ({
  divisionId: getSelectedProductionDivisionIdSelector(state),
  templateId: getImportTemplateIdSelector(state),
  fileName: getImportFileNameSelector(state),
});

const mapDispatchToProps = {
  setTemplateId,
  openPromptAsync,
};

export default connect(mapStateToProps, mapDispatchToProps);
