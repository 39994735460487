import { withStyles } from '@material-ui/core';

const styles = (theme) => ({
  personnelInfoContainer: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  personnelName: {
    fontWeight: 'bold',
  },
  divider: {
    width: '100%',
  },
  container: {
    marginBottom: theme.spacing(4),
  },
});

export default withStyles(styles);
