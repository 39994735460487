const CheckboxStyles = (theme) => ({
  root: {
    padding: theme.spacing(0.5),
    '&.small svg': {
      fontSize: '16px',
    },
  },
});

export default CheckboxStyles;
