import identity from 'ramda/src/identity';
import { createActions } from 'redux-actions';

const actionsDefinition = {
    RESET_SMART_FLAGS_FILTER: identity,
    ADD_SMART_FLAGS_FILTER: identity,
    REMOVE_SMART_FLAGS_FILTER: identity,
};

export const {
    resetSmartFlagsFilter,
    addSmartFlagsFilter,
    removeSmartFlagsFilter,
} = createActions(actionsDefinition);
