/* eslint-disable import/no-cycle */
import { handleActions } from 'redux-actions';
import lensPath from 'ramda/src/lensPath';
import set from 'ramda/src/set';
import * as actions from './actions';

const defaultState = {
  presets: [],
};

const setupFlowReducer = {
  [actions.setTerritoryPresets]: (state, { payload }) => set(lensPath(['presets']), payload)(state),
  [actions.cleanTerritoryPresets]: (state) => set(lensPath(['presets']), defaultState.presets)(state),
  // Adds a blank new territory row
  [actions.newTerritoryPreset]: (state, { payload }) => set(lensPath(['presets']), payload)(state),
  [actions.addTerritoryPreset]: (state, { payload }) => set(lensPath(['presets']), payload)(state),
  [actions.removeTerritoryPreset]: (state, { payload }) => set(lensPath(['presets']), payload)(state),
};

export default handleActions(setupFlowReducer, defaultState);
