/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Chip as MuiChip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import styles from './styles';

const Chip = withStyles(styles)((props) => {
  const { classes, onClick, label, clickable, onDelete, variant, deleteIcon, customProps = {} } = props;
  return (
    <MuiChip
      onClick={onClick}
      label={label}
      clickable={clickable}
      color="primary"
      variant={variant}
      onDelete={onDelete}
      deleteIcon={deleteIcon}
      classes={{
        root: classes.root,
        label: classes.label,
        clickable: classes.clickable,
      }}
      {...customProps}
    />
  );
});

Chip.propTypes = {
  onClick: PropTypes.func,
  onDelete: PropTypes.func,
  label: PropTypes.string.isRequired,
  /** Whether the chip will be clickable or not.
   * If not clickable - opacity will be set as 0.55,
   * set to false can be used as disabled
   */
  clickable: PropTypes.bool,
  /** Icon to override the default delete button */
  deleteIcon: PropTypes.node,
  /** Chip styling */
  variant: PropTypes.oneOf(['outlined', 'default']),
  customProps: PropTypes.object,
};

Chip.defaultProps = {
  clickable: true,
  variant: 'default',
};

export default Chip;
