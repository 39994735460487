/* eslint-disable import/no-cycle */
import { handleActions } from 'redux-actions';
import { lensPath, set } from 'ramda';
import * as actions from './actions';

const defaultState = {
  loading: false,
  tags: [],
  tracks: [],
  pageCount: null,
  totalCount: null,
  selectedTrack: {},
  clearanceFormDirty: false,
  sort: {
    sortBy: 'title',
    sortByDirection: 'ASC',
  },
};

const tracksReducer = {
  [actions.clearTracks]: (state) => ({
    ...defaultState,
    selectedTrack: state.selectedTrack,
    sort: state.sort,
  }),
  [actions.setTrackSort]: (state, { payload }) => set(lensPath(['sort']), payload)(state),
  [actions.setLoading]: (state, { payload }) => set(lensPath(['loading']), payload)(state),
  [actions.setTracksPageCount]: (state, { payload }) => set(lensPath(['pageCount']), payload)(state),
  [actions.setTracksTotalCount]: (state, { payload }) => set(lensPath(['totalCount']), payload)(state),
  [actions.setTags]: (state, { payload }) => set(lensPath(['tags']), payload)(state),
  [actions.setTracks]: (state, { payload }) => set(lensPath(['tracks']), payload)(state),
  [actions.setSelectedTrack]: (state, { payload }) => set(lensPath(['selectedTrack']), payload)(state),
  [actions.setClearanceFormDirty]: (state, { payload }) => set(lensPath(['clearanceFormDirty']), payload)(state),
};

export default handleActions(tracksReducer, defaultState);
