import { Box, Table, TableBody, TableHead, TableRow } from '@mui/material';
import { Loading } from 'components/styleguide';
import { useState } from 'react';
import { useRootTranslation } from 'utils/hooks';
import useStyles from './styles';
import { SearchProductionUser } from './SearchProductionUser';
import { ProductionUserCell } from './ProductionUserCell';
import { ProductionUserRow } from './ProductionUserRow';

export const ProductionUsers = ({
  currentUserId,
  productionUsers,
  loadingProductionUsers,
  refetchProductionUsers,
  objectId,
  divisionId,
}) => {
  const { thead } = useStyles();
  const [searchTerm, setSearchTerm] = useState('');
  const t = useRootTranslation('drawers.shareWithUser');

  const filteredProductionUsers = productionUsers
    .filter((productionUser) => {
      const name = productionUser.user.displayName?.toLowerCase();
      const email = productionUser.user.email?.toLowerCase();
      const term = searchTerm.toLowerCase();
      return name?.includes(term) || email?.includes(term);
    })
    .sort((productionUser1, productionUser2) => {
      if (!productionUser1.user.displayName) {
        return 1;
      }
      if (!productionUser2.user.displayName) {
        return -1;
      }
      return productionUser1.user.displayName.localeCompare(
        productionUser2.user.displayName,
      );
    });

  if (loadingProductionUsers) {
    return <Loading />;
  }

  return (
    <Box mb={5}>
      <SearchProductionUser
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
      />
      <Table>
        <TableHead className={thead}>
          <TableRow>
            <ProductionUserCell />
            <ProductionUserCell>
              {t('productionUsersTable.user')}
            </ProductionUserCell>
            <ProductionUserCell>
              {t('productionUsersTable.email')}
            </ProductionUserCell>
            <ProductionUserCell>
              {t('productionUsersTable.role')}
            </ProductionUserCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredProductionUsers.map((productionUser) => (
            <ProductionUserRow
              currentUserId={currentUserId}
              productionUser={productionUser}
              refetchProductionUsers={refetchProductionUsers}
              objectId={objectId}
              divisionId={divisionId}
            />
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};
