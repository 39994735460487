/* eslint-disable react/no-children-prop */
import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { DrawerHeader } from 'components/styleguide';
import { SecuredForm } from 'components/common/Secured';
import { useRootTranslation } from 'utils/hooks';
import { COMMON_ROLES } from 'redux-core/permissions/constants';
import { FormButton, FormTypeAhead } from 'components/common/Form/Fields';
import { ADDITIONAL_TRACK_USE_TYPES_OPTIONS, TRACK_USE_ACTUAL_OPTIONS } from 'utils/constants';
import { rootTranslation } from 'utils/format';
import { requestWithError } from 'utils/request';
import { bulkUpdateUsageTypeCall } from 'redux-core/cueSheet/services';
import useStyles from './styles';
import enhancer from './enhancer';
import { useClearSelectedCues } from '../../../ProductionOverview/CueSheet/cueStore';

const rootT = 'drawers.changeCueDetails';

const DONOTCHANGE_OPTION = {
  id: 'donot_change',
  name: rootTranslation(rootT)('donotChange'),
};
const USAGE_OPTIONS = [DONOTCHANGE_OPTION, ...TRACK_USE_ACTUAL_OPTIONS, ...ADDITIONAL_TRACK_USE_TYPES_OPTIONS];

const ChangeCueDetailsDrawer = ({
  name,
  onClose,
  payload: { cueIds },
  productionObjectId,
  subprojectId,
  cueSheetVersionId,
  refetchCues,
}) => {
  const t = useRootTranslation(rootT);
  const classes = useStyles();
  const clearCueSelection = useClearSelectedCues();

  const onSubmit = async ({ usage }) => {
    const payload = {
      cueIds,
      usage: usage === DONOTCHANGE_OPTION.id ? undefined : usage,
      subprojectId,
      cueSheetVersionId,
    };
    const settings = { message: { success: t('successMessage') } };
    await requestWithError(bulkUpdateUsageTypeCall, payload, settings);
    await refetchCues();
    clearCueSelection();
    onClose();
  };

  const handleClose = async () => {
    onClose();
  };

  return (
    <SecuredForm
      drawerName={name}
      enableReinitialize
      initialValues={{
        // type: DONOTCHANGE_OPTION.id,
        usage: DONOTCHANGE_OPTION.id,
      }}
      objectId={productionObjectId}
      onSubmit={onSubmit}
      permissions={COMMON_ROLES.EDITOR}
    >
      <DrawerHeader bold hideBackButton onClose={handleClose} title={t('drawerTitle')}>
        <FormButton
          alwaysEnabled
          children="global.forms.cancel"
          onClick={onClose}
          size="sm"
          synchronous
          type="button"
          variant="destructive"
        />
        <FormButton children="global.forms.save" size="sm" type="submit" variant="primary" />
      </DrawerHeader>
      <Grid container className={classes.form}>
        <FormTypeAhead label={t('usage')} name="usage" maxVisibleItems="20" options={USAGE_OPTIONS} />
        {/* Hidden for now. CLR-5039 */}
        {/* <FormTypeAhead
          label={t('trackType')}
          name="type"
          options={TRACK_TYPE_OPTIONS}
        /> */}
      </Grid>
    </SecuredForm>
  );
};

ChangeCueDetailsDrawer.propTypes = {
  name: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  payload: PropTypes.shape({}),
  productionObjectId: PropTypes.number,
  refetchCues: PropTypes.func.isRequired,
};

export default enhancer(ChangeCueDetailsDrawer);
