import { TPagination, baseApi } from 'services';

export type TActivateProductionRequest = {
  ids: number[];
};

export type TProduction = {
  id: number;
  name: string;
  active: boolean;
};

export type TActivateProductionResponsePayload = {
  subprojects: TProduction[];
};

export type TGetSubprojectsAllowedByUserResponse = {
  data: Array<{ id: number; name: string }>;
  pagination: TPagination;
};

export const productionsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    toggleActiveStatus: builder.mutation<TActivateProductionRequest, TActivateProductionResponsePayload>({
      query: (body) => ({
        url: 'common/productions/toggle-active-status',
        method: 'POST',
        body,
      }),
    }),
    getSubprojectsAllowedByUser: builder.query<TGetSubprojectsAllowedByUserResponse, { searchTerm?: string }>({
      query: (body) => ({
        url: 'common/productions/get-all-allowed-by-user',
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const { useToggleActiveStatusMutation, useGetSubprojectsAllowedByUserQuery } = productionsApi;
