import * as React from 'react';
import { makeStyles } from '@material-ui/core';
import { MultilineText } from 'components/styleguide';
import { Typography } from '@mui/material';
import { SxProps } from '@mui/system';

const useStyles = makeStyles(() => ({
  text: {
    whiteSpace: 'normal',
  },
}));

type TableRowTextProps = {
  children: React.ReactNode;
  sx?: SxProps;
  'data-testid'?: string;
};

export function TableRowText({ children, sx, 'data-testid': testId }: TableRowTextProps) {
  const styles = useStyles();

  return (
    <Typography data-testid={testId} variant="body2" component="span" className={styles.text} display="block" sx={sx}>
      <MultilineText ellipsisColumns={2}>{children}</MultilineText>
    </Typography>
  );
}
