/* eslint-disable @typescript-eslint/no-shadow */
import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import { FormInputText, FormCheckbox } from 'components/common/Form/Fields/index';
import { useFormikContext } from 'formik';
import { completeTaskCall } from 'redux-core/tasks/services';
import { useRootTranslation } from 'utils/hooks';
import { requestWithError } from 'utils/request';

const FormTaskSubject = ({ readOnly, required, getTabTasks }) => {
  const t = useRootTranslation('drawers.tasks.create');
  const formik = useFormikContext();

  if (!readOnly) {
    return <FormInputText label={t('form.subject')} name="subject" required={required} />;
  }

  const handleCompleteTask = async (formik, field, value) => {
    formik.setFieldValue(field.name, value);
    await requestWithError(
      completeTaskCall,
      {
        id: formik.values.id,
        completed: value,
      },
      {
        message: {
          success: value ? t('notification.complete.success') : t('notification.uncomplete.success'),
          failed: t('notification.complete.failed'),
        },
      },
    );
    await getTabTasks();
  };

  return (
    <Box width={1} p={3} bgcolor="background.listItem" border={1} borderColor="grey.500">
      <FormCheckbox name="completed" label={formik.values.subject} onChange={handleCompleteTask} />
    </Box>
  );
};

FormTaskSubject.propTypes = {
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  getTabTasks: PropTypes.func.isRequired,
};

export default FormTaskSubject;
