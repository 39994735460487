/* eslint-disable react/no-children-prop */
import Grid from '@material-ui/core/Grid';
import last from 'ramda/src/last';

import { Text } from 'components/styleguide';
import { concatStrings } from 'utils';
import { useRootTranslation } from 'utils/hooks';

import { Box } from '@material-ui/core';
import { Description } from './Description';
import { LicensorExtraDetails } from './LicensorExtraDetails';
import { ViewButton } from './ViewButton';

const concatArrays = concatStrings(', ');

export const LicensorDetails = ({ closeAllDialogs, goToLink, filterBy, selected }) => {
  const t = useRootTranslation('globalSearch.details');

  if (!selected) return null;

  const licensorInfo = last(selected.prodArray);
  const licensorName = licensorInfo.rightsOwnerName;
  const licensorType = licensorInfo.rightsOwnerType;
  const trackInfo = selected.prodArray[selected.prodArray.length - 2];
  const trackTitle = trackInfo.name;
  const writers = concatArrays(trackInfo.writers);

  return (
    <Grid container spacing={3} alignItems="center">
      <Grid item xs={6}>
        <Text lineHeight="35px" bold children={t('title')} />
      </Grid>
      <Grid item xs={6} container alignItems="center" justify="flex-end">
        <Box ml={2}>
          <ViewButton filterBy={filterBy} selected={selected} closeAllDialogs={closeAllDialogs} goToLink={goToLink} />
        </Box>
      </Grid>
      <Grid container item xs={12} spacing={2}>
        <Description title={t('licensor.licensorName')} value={licensorName} />
        <Description title={t('licensor.licensorType')} value={licensorType} />
      </Grid>

      <Grid container item xs={12} spacing={2}>
        <Description title={t('licensor.trackTitle')} value={trackTitle} />
        <Description title={t('licensor.writers')} value={writers} />
      </Grid>

      <LicensorExtraDetails selected={selected} />
    </Grid>
  );
};
