/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-cycle */
import React, { useEffect, useState } from 'react';
import { Button } from 'components/styleguide';
import { ButtonProps } from 'components/styleguide/Button';
import { connect, FormikHelpers, FormikProps, FormikState } from 'formik';
import { useTranslation } from 'react-i18next';
import { isReadOnly } from '../../utils';

type FormButtonProps<Values> = {
  alwaysEnabled?: boolean;
  children: string | TemplateStringsArray | (string | TemplateStringsArray)[];
  disabled?: boolean;
  formik?: FormikProps<Values>;
  isDisabled?: (formik: FormikProps<Values>) => boolean;
  onClick?: (
    values: FormikState<Values>['values'],
    setFieldValue: FormikHelpers<Values>['setFieldValue'],
    formikProps: Partial<FormikProps<Values>> & { readOnly: boolean },
    event?: React.MouseEventHandler<HTMLButtonElement>,
    pureEvent?: React.MouseEventHandler<HTMLButtonElement>,
  ) => Promise<void> | void;
  testId?: string;
  showIfReadOnly?: boolean;
  synchronous?: boolean;
} & ButtonProps;

const ButtonWrapper = ({
  alwaysEnabled,
  children,
  disabled,
  isDisabled,
  formik: {
    isSubmitting,
    isValid,
    values,
    setFieldValue,
    setValues,
    resetForm,
    dirty,
    touched,
    setFieldError,
    setFieldTouched,
    ...form
  },
  formik,
  onClick,
  testId,
  type = 'button',
  showIfReadOnly,
  ...props
}: FormButtonProps<unknown>) => {
  const { t } = useTranslation();
  const readOnly = isReadOnly(form);
  const [localSubmitting, setLocalSubmitting] = useState(false);
  useEffect(() => {
    if (!isSubmitting) {
      setLocalSubmitting(false);
    }
  }, [isSubmitting]);
  const handleClick = async (
    event: React.MouseEventHandler<HTMLButtonElement>,
    pureEvent: React.MouseEventHandler<HTMLButtonElement>,
  ) => {
    if (!props.synchronous) setLocalSubmitting(true);
    try {
      await onClick(
        values,
        setFieldValue,
        {
          dirty,
          setValues,
          resetForm,
          readOnly,
          isValid,
          touched,
          setFieldError,
          setFieldTouched,
        },
        event,
        pureEvent,
      );
    } finally {
      setLocalSubmitting(false);
    }
  };

  if ((!showIfReadOnly || type === 'submit') && readOnly) return null;

  return (
    <Button
      disabled={disabled || (!(alwaysEnabled || isValid)) || isSubmitting || isDisabled?.(formik)}
      isSubmitting={localSubmitting || (type === 'submit' && isSubmitting)}
      {...props}
      {...(type === 'button' ? { onClick: handleClick } : {})}
      testId={testId}
      type={type}
    >
      {t(children)}
    </Button>
  );
};

ButtonWrapper.displayName = 'FormButton';

export default connect(ButtonWrapper);
