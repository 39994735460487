/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { Text } from 'components/styleguide';
import { useRootTranslation } from 'utils/hooks';

const NoResults = ({ justify, children }) => {
  const tGlobal = useRootTranslation('global');
  return (
    <Grid container justify={justify}>
      <Text align="center" semibold variant="h6" testId="no-results">
        {tGlobal(children)}
      </Text>
    </Grid>
  );
};

NoResults.propTypes = {
  justify: PropTypes.string,
};

NoResults.defaultProps = {
  children: 'noResults',
};

export default NoResults;
