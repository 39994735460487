/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import { Text, Check } from 'components/styleguide';
import reduce from 'ramda/src/reduce';
import compose from 'ramda/src/compose';
import propOr from 'ramda/src/propOr';
import add from 'ramda/src/add';
import find from 'ramda/src/find';
import isNil from 'ramda/src/isNil';
import slice from 'ramda/src/slice';
import last from 'ramda/src/last';
import pathOr from 'ramda/src/pathOr';
import propEq from 'ramda/src/propEq';
import partition from 'ramda/src/partition';
import split from 'ramda/src/split';
import { FormInputFormat, FormButton } from 'components/common/Form/Fields/index';
import { useFormikContext } from 'formik';
import i18n from 'locales/i18n';
import { useRootTranslation } from 'utils/hooks';
import { getCueRows } from './utils';
import CueInputFormat from './CueInputFormat';

const useCueDisabled = (id) => {
  const { values } = useFormikContext();

  const cues = getCueRows(values);

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const [used, notUsed] = partition(({ used }) => used, cues);

  const allCuesAreNotUsed = notUsed.length === cues.length;
  const currentCueIsUsed = Boolean(find(propEq('id', id), used));
  const isEditable = currentCueIsUsed || allCuesAreNotUsed;

  return !isEditable;
};

const CueColumnComponentPropTypes = {
  inputName: PropTypes.string.isRequired,
  id: PropTypes.number,
  used: PropTypes.string,
};

const SetMaxPercentageButton = ({ inputName, id }) => {
  const t = useRootTranslation('drawers.clearance.addEdit');
  const isDisabled = useCueDisabled(id) || !inputName;
  const name = `${inputName}.allocation`;

  const handleClick = (_values, setFieldValue) => {
    const inputIndex = parseInt(last(inputName), 10);
    const formikInputPath = slice(0, -2, inputName);
    const formikInputPathArray = split('.', formikInputPath);
    const formikCueList = pathOr([], formikInputPathArray, _values);

    setFieldValue(name, 100);

    if (formikCueList.length > 1) {
      formikCueList.forEach((_, cueIndex) => {
        if (cueIndex !== inputIndex) {
          setFieldValue(`${formikInputPath}.${cueIndex}.allocation`, 0);
        }
      });
    }
  };

  return (
    <FormButton size="sm" variant="secondary" onClick={handleClick} alwaysEnabled={!isDisabled}>
      {t('setToOneHundred')}
    </FormButton>
  );
};

SetMaxPercentageButton.propTypes = CueColumnComponentPropTypes;

const AssociatedFee = ({ percentage = 0 }) => {
  const { values } = useFormikContext();
  const totalFees = values.grandTotalFee;
  const associatedFeeValue = isNil(totalFees) ? undefined : (percentage * totalFees) / 100;

  return (
    <Box mr={1}>
      <CueInputFormat name="totalFees" type="currency" value={associatedFeeValue} />
    </Box>
  );
};

AssociatedFee.propTypes = {
  percentage: PropTypes.number,
};

const DifferenceAssociatedFee = () => {
  const { values, errors } = useFormikContext();

  const allocationSum = compose(
    reduce((acc, cue) => add(acc, propOr(0, 'allocation', cue)), 0),
    getCueRows,
  )(values);

  const color = allocationSum > 101 || allocationSum < 99 ? 'error' : 'initial';

  /* Defined in ClearanceAddEdit/index.jsx */
  const errorMessage = pathOr(propOr('', 'rights', errors), ['rights', 0, 'cues', 0, 'allocation'], errors);

  return (
    <Tooltip title={errorMessage} arrow="top">
      <Box mr={1}>
        <Text align="right" color={color} component="span">
          {allocationSum}%
        </Text>
      </Box>
    </Tooltip>
  );
};

const FeeAllocation = ({ inputName, id }) => {
  const isDisabled = useCueDisabled(id) || !inputName;
  const placeholder = isDisabled ? 100 : undefined;

  return (
    <FormInputFormat
      type="percentage"
      name={`${inputName}.allocation`}
      disabled={isDisabled}
      placeholder={placeholder}
      max={100}
      decimalScale={5}
      data-testid={`${inputName}-allocation`}
    />
  );
};

FeeAllocation.propTypes = CueColumnComponentPropTypes;

const UsingButton = ({ used }) => (
  <Box display="flex" justify="center">
    <Check fontSize="small" color={used ? 'primary' : 'action'} />
  </Box>
);

UsingButton.propTypes = CueColumnComponentPropTypes;

const TotalFees = () => {
  const { values } = useFormikContext();
  const allocationSum = compose(
    reduce((acc, cue) => add(acc, propOr(0, 'allocation', cue)), 0),
    getCueRows,
  )(values);
  const totalPercentage = isNil(values.grandTotalFee) ? undefined : (allocationSum * values.grandTotalFee) / 100;
  return <CueInputFormat type="currency" value={totalPercentage} />;
};

const columnWidthById = {
  'cueContainer.number': '10%',
  'cueContainer.title': '20%',
  used: '10%',
  /* Prop added in Drawers/ClearanceAddEdit/CueRows/utils.js */
  usage: '20%',
  totalFees: '15%',
  allocation: '15%',
  setToOneHundred: '10%',
};

/**
 * Columns
 */
export const getColumns = ({ isMarketing, t }) => [
  {
    id: 'cueContainer.number',
    label: isMarketing ? t('assetNumber') : t('sceneNumber'),
    width: columnWidthById['cueContainer.number'],
  },
  {
    id: 'cueContainer.title',
    label: isMarketing ? t('assetName') : t('sceneName'),
    width: columnWidthById['cueContainer.title'],
  },
  {
    id: 'used',
    label: t('using'),
    width: columnWidthById.used,
    renderValue: (props) => <UsingButton {...props} />,
  },
  {
    /* Prop added in Drawers/ClearanceAddEdit/CueRows/utils.js */
    id: 'usage',
    label: t('usage'),
    width: columnWidthById.usage,
  },
  {
    id: 'totalFees',
    label: t('associatedFee'),
    width: columnWidthById.totalFees,
    renderValue: ({ allocation }) => <AssociatedFee percentage={allocation} />,
  },
  {
    id: 'allocation',
    label: t('percentage'),
    width: columnWidthById.allocation,
    renderValue: (props) => <FeeAllocation {...props} />,
  },
  {
    id: 'setToOneHundred',
    width: columnWidthById.setToOneHundred,
    renderValue: (props) => <SetMaxPercentageButton {...props} />,
  },
];

/**
 * Footer Columns
 */
export const footerColumns = [
  {
    id: 'cueContainer.number',
    width: columnWidthById['cueContainer.number'],
  },
  {
    id: 'cueContainer.title',
    width: columnWidthById['cueContainer.title'],
  },
  {
    id: 'used',
    width: columnWidthById.used,
  },
  {
    id: 'usage',
    width: columnWidthById.usage,
    align: 'right',
    renderValue: () => <Text bold>{i18n.t('drawers.clearance.addEdit.totalFees')}</Text>,
  },
  {
    id: 'totalFees',
    width: columnWidthById.totalFees,
    align: 'right',
    renderValue: () => <TotalFees />,
  },
  {
    id: 'allocation',
    width: columnWidthById.allocation,
    align: 'right',
    renderValue: () => <DifferenceAssociatedFee />,
  },
  {
    id: 'setToOneHundred',
    width: columnWidthById.setToOneHundred,
  },
];
