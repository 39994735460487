/* eslint-disable no-bitwise */
export const COMMON_PERMISSIONS = {
  DELETE: 1,
  UPDATE: 2,
  READ: 4,
  CREATE: 8,
  GRANT_DELETE: 16,
  GRANT_UPDATE: 32,
  GRANT_READ: 64,
  GRANT_CREATE: 128,
  CREATE_TASKS: 256,
  ASSIGN_TASKS: 512,
  DIVISION_ADMIN: 1024,
  VIEW_REPORTS: 2048,
};

export const COMMON_ROLES = {
  REVIEWER: 4,
  EDITOR: 15,
  OWNER: 255,
};

export const CLEAR_PERMISSIONS = {
  CAN_ACCESS_CLEAR: 1,
  VIEW_FINANCIAL_INFO: 2,
  UPDATE_FINANCIAL_INFO: 4,
  CREATE_CONTACT: 8,
  UPDATE_CONTACT: 16,
  VIEW_TRACK_HISTORY: 32,
  CREATE_RIGHTS: 64,
  UPDATE_RIGHTS: 128,
  CREATE_LETTERS: 256,
  UPDATE_LETTERS: 512,
  CREATE_LETTERS_TEMPLATES: 1024,
  UPDATE_LETTERS_TEMPLATES: 2048,
};

export const CUE_PERMISSIONS = {
  CAN_ACCESS_CUE: 1,
  LOCK_CUES: 2,
  CREATE_CUE_SHEET_TEMPLATES: 4,
  UPDATE_CUE_SHEET_TEMPLATES: 8,
};

export const COMMON_PERMISSIONS_GROUPS = {
  TASKS: COMMON_PERMISSIONS.CREATE_TASKS | COMMON_PERMISSIONS.ASSIGN_TASKS,
};

export const CLEAR_PERMISSIONS_GROUPS = {
  CONTACTS: CLEAR_PERMISSIONS.CREATE_CONTACT | CLEAR_PERMISSIONS.UPDATE_CONTACT,
  FINANCIAL: CLEAR_PERMISSIONS.VIEW_FINANCIAL_INFO | CLEAR_PERMISSIONS.UPDATE_FINANCIAL_INFO,
  RIGHTS: CLEAR_PERMISSIONS.CREATE_RIGHTS | CLEAR_PERMISSIONS.UPDATE_RIGHTS,
  LETTERS: CLEAR_PERMISSIONS.CREATE_LETTERS | CLEAR_PERMISSIONS.UPDATE_LETTERS,
  LETTER_TEMPLATES: CLEAR_PERMISSIONS.CREATE_LETTERS_TEMPLATES | CLEAR_PERMISSIONS.UPDATE_LETTERS_TEMPLATES,
  SCHEDULE_A_TEMPLATES: CLEAR_PERMISSIONS.CREATE_LETTERS_TEMPLATES | CLEAR_PERMISSIONS.UPDATE_LETTERS_TEMPLATES,
};
