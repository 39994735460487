/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/default-props-match-prop-types */
import React, { ReactNode } from 'react';
import {
  FormControlLabelWrapper,
  FormControlLabelWrapperProps,
} from 'components/common/Form/Fields/FormField/FormField';
import { isString } from 'lodash';

type WithFormControlProps = Omit<FormControlLabelWrapperProps, 'children'> & {
  error: boolean | string;
  hideErrorHint?: boolean;
  FieldWrapper?: React.FunctionComponent<{ children: React.ReactNode }>;
  size?: string;
  placeholder?: string;
  split?: 'left' | 'right' | 'middle';
  adornmentPosition?: 'start' | 'end';
  testId?: string;
  adornment?: ReactNode;
  value?: string;
};

const withFormControl = (WrappedComponent) => {
  const Component = (props: WithFormControlProps) => {
    const { FieldWrapper, disabled, error, fullWidth, label, name, required, hideErrorHint } = props;
    const showError = !hideErrorHint && !!error;

    return (
      <FormControlLabelWrapper
        error={showError}
        helperText={isString(error) ? error : ''}
        data-testid="input-field"
        disabled={disabled}
        fullWidth={fullWidth}
        required={required}
        name={name}
        label={label}
      >
        {FieldWrapper ? (
          <FieldWrapper>
            <WrappedComponent {...props} />
          </FieldWrapper>
        ) : (
          <WrappedComponent {...props} />
        )}
      </FormControlLabelWrapper>
    );
  };

  /**
   * We're keeping this defaultProps to not break the current implementation of TypeAhead component,
   * handleChange function calls onChange(selected[valueKey]) and it is not safe to remove that now.
   * Once we replace the Typeahead by the MUI Autocomplete we can remove this.
   */
  Component.defaultProps = {
    onBlur: (_e: any) => undefined,
    onChange: (_e: any) => undefined,
    onFocus: (_e: any) => undefined,
  };

  Component.displayName = `withFormControl(${WrappedComponent.displayName})`;
  return Component;
};

export default withFormControl;
