import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useAtomCallback } from 'jotai/utils';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getCuesSelector } from 'redux-core/cueSheet/selectors';
import { TCue } from './CueSheetTable/CueSheetTableContent/types';

export const selectedCueAtom = atom(new Set<number>());

export function useSelectCue() {
  const [selectedList, setCueSelected] = useAtom(selectedCueAtom);

  const handleSelect = (id: number) => {
    const newValue = new Set(selectedList);
    if (selectedList.has(id)) {
      newValue.delete(id);
    } else {
      newValue.add(id);
    }
    setCueSelected(newValue);
  };

  return [selectedList, handleSelect];
}

export function useClearSelectedCues() {
  const setCueSelected = useSetAtom(selectedCueAtom);
  return useCallback(() => setCueSelected(new Set()), [setCueSelected]);
}

export const useReadSelectedCueAtom = () => useAtomCallback(useCallback((get) => get(selectedCueAtom), []));

export const useAllCuesSelect = () => {
  const cues = useSelector(getCuesSelector);
  const [selectedList, setSelectedList] = useAtom(selectedCueAtom);
  const allSelected = selectedList.size === cues?.length;
  const indexList = useMemo(() => Array.from({ length: cues.length }, (_, index) => index), [cues.length]);

  const callback = useCallback(() => {
    if (allSelected) {
      setSelectedList(new Set());
    } else {
      setSelectedList(new Set(indexList));
    }
  }, [allSelected, indexList, setSelectedList]);

  return useMemo(() => [allSelected, callback], [allSelected, callback]);
};

export const useSelectedCuesIds = (): number[] => {
  const cues: TCue[] = useSelector(getCuesSelector);
  const selectedCueIndexList = useAtomValue(selectedCueAtom);

  return useMemo(
    () => cues.filter((_, index) => selectedCueIndexList.has(index)).map((cue) => cue.cue.id),
    [cues, selectedCueIndexList],
  );
};
