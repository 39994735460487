/* eslint-disable react/no-children-prop */
import ArchiveIcon from '@material-ui/icons/ArchiveOutlined';
import UnarchiveIcon from '@material-ui/icons/UnarchiveOutlined';
import Share from '@material-ui/icons/Share';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';

import { DRAWERS } from 'components/common/Drawers/constants';
import { SecuredButtonMenu } from 'components/common/Secured';
import { COMMON_PERMISSIONS, COMMON_ROLES } from 'redux-core/permissions/constants';
import { TypeIcon } from 'components/styleguide';
import { rootTranslation } from 'utils/format';
import { getNormalizedProductionType } from 'utils/productions';
import { NORMALIZED_PRODUCTION_TYPES } from 'utils/constants';
import { useSecuredDivisions } from 'utils/hooks';
import { useToggleActiveStatusMutation } from '../../../../../services/common/productions';
import enhancer from './enhancer';

const t = rootTranslation('globalSearch.details');

const getTypeIcon = (type) => <TypeIcon type={type} size={20} />;

const getOptions = (
  divisions,
  projectId,
  productionId,
  productionActive,
  tenantId,
  divisionId,
  normalizedProdType,
  objectId,
  keepSetupInfo,
  openModal,
  showInactivateButton,
  toggleProductionActiveStatus,
  isEpisode,
) => {
  const options = [
    {
      action: () => {
        keepSetupInfo(null, projectId, tenantId);
        openModal(DRAWERS.PRODUCTION_SETUP_PROJECT, { id: projectId });
      },
      display: t('options.viewEditProjectDetails'),
      icon: <SettingsApplicationsIcon />,
    },
    {
      display: t('options.share'),
      icon: <Share />,
      permissions: COMMON_ROLES.OWNER,
      action: () => {
        openModal(DRAWERS.SHARE_WITH_A_USER, {
          divisionId,
          isSeasonEpisode: normalizedProdType === NORMALIZED_PRODUCTION_TYPES.TV,
          objectId,
        });
      },
    },
  ];

  if (showInactivateButton) {
    let inactivateText;
    if (isEpisode) {
      inactivateText = productionActive ? t('options.inactivateEpisode') : t('options.activateEpisode');
    } else {
      inactivateText = productionActive ? t('options.inactivateProduction') : t('options.activateProduction');
    }

    options.push({
      action: async () => {
        await toggleProductionActiveStatus({ ids: [productionId] });
      },
      display: inactivateText,
      icon: productionActive ? <ArchiveIcon /> : <UnarchiveIcon />,
    });
  }

  if (normalizedProdType === NORMALIZED_PRODUCTION_TYPES.TV) {
    options.push(
      ...divisions
        .filter((division) => division.tenantId === tenantId)
        .map((division) => {
          const addSeasonAction = async () => {
            keepSetupInfo(division.id, projectId, division.tenantId);
            openModal(DRAWERS.PRODUCTION_SETUP_SEASON);
          };

          return {
            key: `add-season-${division.id}`,
            icon: getTypeIcon('episode'),
            display: t('options.add.television'),
            action: addSeasonAction,
            description: division.name,
            permissions: COMMON_ROLES.EDITOR,
            objectId: division.objectId,
          };
        }),
    );
  }

  return options;
};

type SearchDetailsSplitButtonProps = {
  productionType: string;
  projectId: number;
  productionId: number;
  productionActive: boolean;
  objectId: number;
  divisionId: number;
  tenantId: number;
  keepSetupInfo: string;
  openModal: (string, any) => void;
  mainPermissions: number;
  mainAction: () => void;
  mainLabel: string;
  variant?: string;
  size?: string;
  showInactivateButton: boolean;
  isEpisode: boolean;
  updateAdvancedFilterVersion: () => void;
};

const SearchDetailsSplitButton = (props: SearchDetailsSplitButtonProps) => {
  const {
    productionType,
    projectId,
    productionId,
    productionActive,
    objectId,
    divisionId,
    tenantId,
    keepSetupInfo,
    openModal,
    mainPermissions,
    mainAction,
    mainLabel,
    variant = 'contained',
    size = 'medium',
    updateAdvancedFilterVersion,
    showInactivateButton = true,
    isEpisode = false,
  } = props;
  const normalizedProdType = getNormalizedProductionType(productionType);
  const divisions = useSecuredDivisions(COMMON_PERMISSIONS.READ);
  const [toggleProductionActiveStatus, toggleProductionActiveStatusResult] = useToggleActiveStatusMutation();
  if (toggleProductionActiveStatusResult.isSuccess) {
    toggleProductionActiveStatusResult.reset();
    updateAdvancedFilterVersion();
  }

  return (
    <SecuredButtonMenu
      options={getOptions(
        divisions,
        projectId,
        productionId,
        productionActive,
        tenantId,
        divisionId,
        normalizedProdType,
        objectId,
        keepSetupInfo,
        openModal,
        showInactivateButton,
        toggleProductionActiveStatus,
        isEpisode,
      )}
      variant={variant}
      onClick={mainAction}
      size={size}
      children={t(mainLabel)}
      objectId={objectId}
      permissions={mainPermissions}
    />
  );
};

export default enhancer(SearchDetailsSplitButton);
