/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import classnames from 'classnames';

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

inputComponent.propTypes = {
  inputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
      current: PropTypes.any.isRequired,
    }),
  ]),
};

function Control(props) {
  const {
    children,
    innerProps,
    innerRef,
    selectProps: {
      classes,
      extraProps: { controlContainerName, tall },
      menuIsOpen,
      inputProps,
    },
    isDisabled,
  } = props;

  return (
    <div className={classes.controlContainer} id={controlContainerName}>
      <OutlinedInput
        fullWidth
        disabled={isDisabled}
        data-testid="typeahead-control"
        inputComponent={inputComponent}
        classes={{ root: classes.inputRoot }}
        inputProps={{
          className: classnames(classes.input, { tall }),
          ref: innerRef,
          children,
          ...innerProps,
        }}
        {...inputProps}
      />
      {menuIsOpen && <div className={classes.controlMenuOpenLines} />}
    </div>
  );
}

Control.propTypes = {
  /**
   * Children to render.
   */
  children: PropTypes.node,
  /**
   * The mouse down event and the innerRef to pass down to the controller element.
   */
  innerProps: PropTypes.shape({
    onMouseDown: PropTypes.func.isRequired,
  }).isRequired,
  innerRef: PropTypes.oneOfType([
    PropTypes.oneOf([null]),
    PropTypes.func,
    PropTypes.shape({
      current: PropTypes.any.isRequired,
    }),
  ]).isRequired,
  selectProps: PropTypes.object.isRequired,
  isDisabled: PropTypes.bool,
};
export default Control;
