import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  subOption: {
    borderBottom: 'none',
  },
  subOptionsWrapper: {
    backgroundColor: theme.palette.grey[50],
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    borderTop: `1px solid ${theme.palette.grey[300]}`,
  },
  actionButtonBase: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'flex-start !important',
    gap: '0.5rem',
  },
  actionButtonWithDesc: {
    padding: '10px 0',
  },
  actionButtonWithoutDesc: {
    padding: '20px 0',
  },
}));

export default useStyles;
