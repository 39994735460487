import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

const Modal = ({ open = true, handleClose, title, content, actions }) => (
  <Dialog open={open} onClose={handleClose} disableEnforceFocus>
    {title && <DialogTitle>{title}</DialogTitle>}
    <DialogContent>{content}</DialogContent>
    {actions && <DialogActions>{actions}</DialogActions>}
  </Dialog>
);

Modal.propTypes = {
  actions: PropTypes.node,
  content: PropTypes.node.isRequired,
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
  title: PropTypes.node,
};

export default Modal;
