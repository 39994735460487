import { get } from 'lodash';
import { useDebouncedCallback } from 'use-debounce';
import { Autocomplete, Skeleton, TextField } from '@mui/material';
import { rootTranslation } from 'utils/format';

const tGlobal = rootTranslation('global');

type FieldProps<TItem> = {
  value: TItem;
  onChange: (nextValue: TItem) => void;
  items: TItem[];
  isLoading: boolean;
  isFetching: boolean;
  setSearchTerm?: (term: string) => void;
  idKey?: keyof TItem;
  labelKey?: keyof TItem;
  testId?: string;
};

export function AutocompleteBaseField<TItem>({
  value,
  onChange,
  idKey,
  labelKey,
  items,
  isLoading,
  isFetching,
  setSearchTerm,
  testId,
}: FieldProps<TItem>) {
  const debouncedSetSearch = useDebouncedCallback((term) => {
    if (setSearchTerm) {
      setSearchTerm(term);
    }
  }, 300);

  if (isLoading) {
    return <Skeleton />;
  }

  return (
    <Autocomplete
      autoComplete
      value={value}
      loading={isFetching}
      size="small"
      options={items ?? []}
      onChange={(_, nextValue: TItem) => {
        onChange(nextValue);
      }}
      onInputChange={(event, newInputValue) => {
        debouncedSetSearch(newInputValue);
      }}
      getOptionLabel={(option) => (labelKey ? get(option, [labelKey]) : option)}
      isOptionEqualToValue={(option, valueOption) =>
        idKey ? get(option, [idKey]) === get(valueOption, [idKey]) : option === valueOption
      }
      renderInput={(params) => <TextField {...params} placeholder={tGlobal('startTyping')} />}
      noOptionsText={tGlobal('noResults')}
      data-testid={testId}
    />
  );
}
