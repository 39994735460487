/* eslint-disable react/no-children-prop */
/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { Trans } from 'react-i18next';
import { useLocation } from 'react-router';

import { Button, Text } from 'components/styleguide';
import ROUTES from 'components/Routes/routes';
import Stepper from 'components/common/HorizontalStepper';
import { useRootTranslation } from 'utils/hooks';
import useStyles from './styles';
import { LettersListPropTypes, STEPS } from '../constants';
import enhancer from './enhancer';

const rootT = 'letters.stepper';
const steps = ['addClearances', 'reviewQueue', 'selectContact', 'composeLetter', 'composeEmail'];

const BatchStepper = ({
  activeStep,
  isBundle,
  isStep2EmptyQueue,
  isStep3EmptyQueue,
  letters,
  onChange,
  setActiveStep,
  shouldShowQueuedCounter,
  step,
}) => {
  const classes = useStyles();
  const { pathname } = useLocation();
  const t = useRootTranslation(rootT);

  const handleChange = (stepNumber) => {
    setActiveStep(stepNumber);
    onChange(stepNumber);
  };

  return (
    <Grid container alignItems="center" className={classes.root}>
      <Grid item xs={3}>
        <Text semibold variant="h3">
          {t('title')}
        </Text>
        {shouldShowQueuedCounter && (
          <Grid container>
            <Trans i18nKey="letters.generate.queuedLetters" count={isBundle ? 1 : letters.length}>
              <Text variant="caption" />
              <Box ml={0.5} />
              <Text bold variant="caption" />
            </Trans>
          </Grid>
        )}
      </Grid>
      <Grid item xs container justify="space-between" alignItems="center">
        <Grid item xs={8}>
          <Stepper activeStep={activeStep} steps={steps.map(t)} />
        </Grid>
        {pathname !== ROUTES.LETTERS.SEND &&
          (step === 0 ? (
            <Button
              children={t('nextStepReviewQueue')}
              disabled={isStep2EmptyQueue}
              onClick={() => handleChange(STEPS.REVIEW_QUEUE)}
              size="md"
              variant="primary"
            />
          ) : (
            <>
              <Button
                children={t('backToFilters')}
                onClick={() => handleChange(STEPS.FILTERS)}
                size="sm"
                variant="destructive"
              />
              <Button
                children={t('nextStepLetters')}
                disabled={isStep3EmptyQueue}
                onClick={() => handleChange(STEPS.CONTACT)}
                size="md"
                variant="primary"
              />
            </>
          ))}
      </Grid>
    </Grid>
  );
};

BatchStepper.propTypes = {
  activeStep: PropTypes.number,
  isBundle: PropTypes.bool,
  isStep2EmptyQueue: PropTypes.bool,
  isStep3EmptyQueue: PropTypes.bool,
  letters: LettersListPropTypes,
  onChange: PropTypes.func,
  setActiveStep: PropTypes.func.isRequired,
  shouldShowQueuedCounter: PropTypes.bool,
  step: PropTypes.number,
};

export default enhancer(BatchStepper);
