/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';
import { Text, Row } from 'components/styleguide';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import { getLocalDate, getInitials } from 'utils/format';

function CommentBox({ userName, image, message, createdAt }) {
  return (
    <Grid item container spacing={1}>
      <Grid item xs={1}>
        <Avatar src={image}>{getInitials(userName)}</Avatar>
      </Grid>
      <Grid item xs={11}>
        <Box p={2} bgcolor="grey.300" borderRadius={3}>
          <Row container justify="space-between">
            <Grid item xs>
              <Text variant="body1">{userName}</Text>
            </Grid>
            <Grid item xs>
              <Text variant="body1" align="right">
                {getLocalDate(createdAt, 'L LT')}
              </Text>
            </Grid>
          </Row>
          <Text>{message}</Text>
        </Box>
      </Grid>
    </Grid>
  );
}

CommentBox.propTypes = {
  image: PropTypes.string,
  userName: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
};

CommentBox.defaultProps = {
  userName: '',
  createdAt: '',
};

export default CommentBox;
