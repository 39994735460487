/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-array-index-key */
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { FormInputFormat, FormTypeAhead } from 'components/common/Form/Fields';
import { Text } from 'components/styleguide';
import { useRootTranslation } from 'utils/hooks';
import EditDeleteMenuWrapperGrid from 'components/common/EditDeleteMenuWrapperGrid';
import { differenceWith, isNil, propEq, reject } from 'ramda';

const rootT = 'drawers.productionSetup.currency.table.header';

const disabledProps = {
  readOnly: true,
};

const CurrencyTable = ({
  options,
  defaultCurrency,
  formik: {
    values: { currencies = [] },
  },
  onRemove,
  isAdding,
  isEditing,
  editableIndex,
  setEditableIndex,
  readOnly,
}) => {
  const t = useRootTranslation(rootT);

  const getOptions = useCallback(
    (id, index) =>
      isAdding && editableIndex === index
        ? differenceWith((x, y) => x.id === y.id, options, reject(propEq('id', id))(currencies))
        : options,
    [options, currencies, editableIndex, isAdding],
  );

  return (
    <Grid container alignItems="center" spacing={2}>
      <Grid item xs={4}>
        <Text>{t('currency')}</Text>
      </Grid>
      <Grid item xs={8}>
        <Text>{t('exchangeRate')}</Text>
      </Grid>
      {currencies.map((c, index) => (
        <EditDeleteMenuWrapperGrid
          hoverAlignItems="center"
          onConfirm={() => onRemove(index)}
          disableHover={readOnly || !isNil(editableIndex)}
          key={index}
          removable={!isEditing}
          setEditable={() => setEditableIndex(index, c)}
          spacing={2}
        >
          <Grid item xs={4}>
            <FormTypeAhead
              isClearable={false}
              name={`currencies[${index}].currencyId`}
              labelKey="label"
              placeholder="global.forms.chooseOne"
              options={getOptions(c.id, index)}
              {...((index !== editableIndex || (isEditing && c.id && !isAdding)) && disabledProps)}
            />
          </Grid>
          <Grid item xs={4}>
            <FormInputFormat
              {...(index !== editableIndex && disabledProps)}
              decimalScale={5}
              name={`currencies[${index}].exchangeRate`}
              type="number"
            />
          </Grid>
          <Grid item xs={4} container alignItems="center">
            <Text color="textSecondary">{defaultCurrency.label}</Text>
          </Grid>
        </EditDeleteMenuWrapperGrid>
      ))}
    </Grid>
  );
};

CurrencyTable.propTypes = {
  defaultCurrency: PropTypes.shape({
    id: PropTypes.number,
    exchangeRate: PropTypes.string,
  }),
  edit: PropTypes.bool,
  options: PropTypes.array,
  formik: PropTypes.object,
  onRemove: PropTypes.func.isRequired,
  isAdding: PropTypes.bool,
  isEditing: PropTypes.bool,
  editableIndex: PropTypes.number,
  setEditableIndex: PropTypes.func,
};

export default CurrencyTable;
