import { Grid, MenuItem, Select, Skeleton, Typography } from '@mui/material';
import { FormField } from 'components/common/Form/Fields/FormField/FormField';
import { ClearanceStatusSelect } from 'components/styleguide/ClearanceStatusSelect';
import { useAtomValue, useSetAtom } from 'jotai';
import { UseFormReturn } from 'react-hook-form';
import { TClearanceStatus } from 'services/clear/clearance-status';
import { TEmailTemplate } from 'services/clear/types';
import { rootTranslation } from 'utils/format';
import { composeLetterAtom, TComposeLetterWriteEmailContentStepForm } from '../composeLetterStore';
import { ComposeLetterComposeEmailFileAttachments } from './ComposeLetterComposeEmailFileAttachments';

const t = rootTranslation('composeLetters.composeEmail');

export type TComposeLetterComposeEmailLeftPanelProps = {
  formBag: UseFormReturn<TComposeLetterWriteEmailContentStepForm>;
  isLoadingTemplates: boolean;
  emailTemplates: TEmailTemplate[];
};

export function ComposeLetterComposeEmailLeftPanel({
  formBag,
  isLoadingTemplates,
  emailTemplates,
}: TComposeLetterComposeEmailLeftPanelProps) {
  const composeLetter = useAtomValue(composeLetterAtom);
  const setComposeLetter = useSetAtom(composeLetterAtom);

  formBag.watch(({ templateId }, { name, type }) => {
    if (type !== 'change') return;

    if (name === 'templateId') {
      setComposeLetter((prev) => ({
        ...prev,
        email: {
          ...prev.email,
          templateId,
          subject: templateId === 0 ? undefined : prev.email.subject,
          ccRecipients: templateId === 0 ? undefined : prev.email.ccRecipients,
          body: templateId === 0 ? undefined : prev.email.body,
        },
      }));
      if (templateId === 0) {
        formBag.setValue('subject', '');
        formBag.setValue('templateId', 0);
        formBag.setValue('body', '');
        formBag.setValue('ccRecipients', []);
      }
    }
  });

  return (
    <Grid item container flexDirection="column" gap={3}>
      <Grid item>
        <Typography variant="h5">{t('stepTitle')}</Typography>
      </Grid>
      <Grid item sx={{ width: '100%' }} container gap={2}>
        <FormField
          name="templateId"
          label={t('templateLabel')}
          key={`select-email-template-${composeLetter.email.templateId}`}
          render={({ field, muiprops }) => (
            <>
              {isLoadingTemplates && <Skeleton />}
              {!isLoadingTemplates && (
                <Select {...field} {...muiprops} data-testid="compose-letter-select-email-template">
                  <MenuItem key="email-template-empty" value={0}>
                    {t('emptyEmailOption')}
                  </MenuItem>
                  {emailTemplates?.map(({ id, name }) => (
                    <MenuItem
                      key={`email-template-${id}`}
                      data-testid="compose-letter-select-email-template-option"
                      value={id}
                    >
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              )}
            </>
          )}
        />
        <FormField
          name="clearanceStatusId"
          label={t('licensorStatusLabel')}
          render={({ field }) => (
            <ClearanceStatusSelect
              divisionId={composeLetter.divisionId}
              clearanceStatus={field.value}
              onChange={(_, status: TClearanceStatus) => {
                field.onChange(status.id);
              }}
            />
          )}
        />
        <ComposeLetterComposeEmailFileAttachments />
      </Grid>
    </Grid>
  );
}
