/* eslint-disable no-case-declarations */
/* eslint-disable import/no-cycle */
import { exchangeToken } from 'redux-core/auth/services';
import { ENV } from 'utils/constants';

const getBearerToken = async (data) => {
  try {
    const userData = await exchangeToken(data);
    localStorage.setItem('userData', JSON.stringify(userData));
  } catch {
    localStorage.setItem('loginProblem', 'true');
    localStorage.removeItem('loginProblem');
  } finally {
    // close pop-up after storing userData
    window.self.close();
  }
};

const LoginCallback = () => {
  const searchString = window.location.search;
  const searchParams = new URLSearchParams(searchString);
  const to = searchParams.get('to');
  switch (to) {
    case 'login': {
      window.location = `${ENV.SSO_SITE}/?to=login&from=${window.location.origin}${window.location.pathname}`;
      break;
    }
    case 'getToken':
      const code = searchParams.get('code');
      const redirectUri = searchParams.get('redirectUri');
      getBearerToken({ code, redirectUri });
      break;
    default:
  }

  return null;
};

export default LoginCallback;
