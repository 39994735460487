/* eslint-disable react/no-children-prop */
import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Form from 'components/common/Form';
import Box from '@material-ui/core/Box';
import SearchIcon from '@material-ui/icons/Search';
import { useRootTranslation } from 'utils/hooks';
import { FormButton, FormDebouncedTypeAhead } from 'components/common/Form/Fields';
import { DrawerHeader } from 'components/styleguide';
import * as Yup from 'yup';
import { requestWithError } from 'utils/request';
import enhancer from './enhancer';

const validationSchema = Yup.object().shape({
  userId: Yup.number('global.forms.validations.types.number').nullable(),
});

const ChangeRepDrawer = ({ name, onClose, payload: { onSubmit }, searchForUsers }) => {
  const t = useRootTranslation('drawers.changeClearanceRep');

  const handleSubmit = async (values) => {
    await requestWithError(onSubmit, values);
    onClose();
  };
  const onCancel = () => {
    onClose();
  };

  return (
    <Form
      drawerName={name}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      enableReinitialize
      initialValues={{
        userId: null,
      }}
    >
      <DrawerHeader title={t('title')} onClose={onClose}>
        <FormButton
          alwaysEnabled
          children="global.forms.cancel"
          size="xs"
          variant="destructive"
          onClick={() => onCancel()}
        />
        <FormButton children="global.forms.assign" size="xs" type="submit" variant="primary" />
      </DrawerHeader>
      <Grid container>
        <FormDebouncedTypeAhead
          name="userId"
          required
          labelKey="displayName"
          call={(term) => searchForUsers({ term, includeLoggedUser: true })}
          endAdornment={
            <Box color="text.primary" display="flex" alignContent="center" justifyContent="center">
              <SearchIcon />
            </Box>
          }
          adornmentPosition="end"
        />
      </Grid>
    </Form>
  );
};

ChangeRepDrawer.propTypes = {
  name: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  payload: PropTypes.shape({
    onSubmit: PropTypes.func,
  }),
  searchForUsers: PropTypes.func.isRequired,
};

export default enhancer(ChangeRepDrawer);
