import { AddCircle } from '@material-ui/icons';
import { Button, Box } from '@mui/material';
// eslint-disable-next-line import/no-named-as-default
import DRAWERS from 'components/common/Drawers/constants';
import { useDrawer, useRootTranslation } from 'utils/hooks';

export const NewOption = ({ divisionId, track, clearancesFetch, objectId }) => {
  const openDrawer = useDrawer();
  const t = useRootTranslation('drawers.clearance.fullList');

  return (
    <Box mt={2}>
      <Button
        onClick={() => {
          openDrawer(DRAWERS.CLEARANCE_OPTION, {
            divisionId,
            track,
            clearancesFetch,
            objectId,
          });
        }}
        startIcon={<AddCircle />}
      >
        {t('newOption')}
      </Button>
      <Button
        onClick={() => {
          openDrawer(DRAWERS.CLEARANCE_ADD_EDIT, {
            divisionId,
            track,
            clearancesFetch,
            objectId,
          });
        }}
        startIcon={<AddCircle />}
      >
        {t('newClearance')}
      </Button>
    </Box>
  );
};
