/* eslint-disable no-return-await */
/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
/* eslint-disable import/no-cycle */
import mergeAll from 'ramda/src/mergeAll';
import omit from 'ramda/src/omit';
import { closeAllDialogs, openDrawer, closeDrawer } from 'redux-core/dialogs/actions';
// eslint-disable-next-line import/no-named-as-default
import DRAWERS from 'components/common/Drawers/constants';
import {
  getSetupFlowInfoSelector,
  getSetupFlowProjectSelector,
  getSetupFlowSeasonSelector,
  getSetupFlowSeasonReleaseSelector,
  getPosterIdsSelector,
} from 'redux-core/productions/selectors';
import { goToLink, addParams } from 'redux-core/router/actions';
import { NORMALIZED_PRODUCTION_TYPES, PRODUCTION_TYPES } from 'utils/constants';
import { showSnackbar } from 'redux-core/global-error/actions';
import ROUTES from 'components/Routes/routes';
import { upsertRights } from 'redux-core/rights/services';
import { getLocationParametersSelector } from 'redux-core/router/selectors';
import { fuse } from 'utils/object';
import { getProjectOnly, refetchProduction } from 'redux-core/overview/actions';
import {
  cleanSetup,
  cleanSetupFlow,
  keepSeasonInfo,
  keepReleaseInfo,
  keepSetupInfo,
  getSaveMessage,
  getPatchMessage,
  getUpdateMessage,
} from 'redux-core/productions/actions/actionDefinition';
import { savePosterId } from 'redux-core/productions/actions/production';
import {
  createSeasonReleaseCall,
  createSeasonCall,
  createProjectAndSeason,
  updateSeasonCall,
  getSeasonById,
  getSeasonReleaseById,
  updateSeasonReleaseCall,
} from 'redux-core/productions/services';
import { ProductionPath } from 'utils/productions';

/**
 * Return an object literal representing an error
 * to show such error in the UI
 * (no Error class is return since it'll be caught by the error middleware)
 * @param {Error} err
 */
function makeErrorObject(err) {
  const error = { ...err };
  const errorMessage = error.response.data.message;
  if (errorMessage.includes('season_number_uniqueness')) {
    const customError = {
      error: true, // flag used at component level
      message: 'drawers.productionSetup.season.validations.duplicateNumber',
    };
    return customError;
  }
  return {
    error: true,
    message: 'global.genericError',
  };
}

export const fetchGetSeason = (id) => async (dispatch, getState) => {
  if (!id) return;
  const { tenantId, divisionId } = getSetupFlowInfoSelector(getState());
  const season = await getSeasonById({ divisionId, id, tenantId });

  dispatch(keepSeasonInfo(season));
  dispatch(
    savePosterId({
      type: PRODUCTION_TYPES.SEASON,
      posterId: season.posterId,
    }),
  );
};

export const getSeasonRelease =
  ({ id }) =>
  async (dispatch, getState) => {
    const state = getState();
    const setupFlowInfo = getSetupFlowInfoSelector(state);
    const params = getLocationParametersSelector(state);

    const { divisionId, tenantId } = fuse(setupFlowInfo, params);

    const release = await getSeasonReleaseById({ divisionId, id, tenantId });

    const pathUtils = new ProductionPath(release.path);
    const { id: seasonId } = pathUtils.getPathByType(PRODUCTION_TYPES.SEASON_RELEASE);

    dispatch(addParams({ releaseId: id, seasonId }));
    dispatch(
      savePosterId({
        type: PRODUCTION_TYPES.SEASON_RELEASE,
        posterId: release.posterId,
      }),
    );
    dispatch(keepReleaseInfo(release));

    return release;
  };

export const saveSeason =
  ({ requiredRights }, options) =>
  async (dispatch, getState) => {
    const state = getState();
    const { addRelease } = options;
    const { projectId, ...setupInfo } = getSetupFlowInfoSelector(state);
    const project = omit(['projectId'], getSetupFlowProjectSelector(state));
    const season = getSetupFlowSeasonSelector(state);

    const getSeasonId = async () => {
      if (projectId) {
        try {
          const { id } = await createSeasonCall(mergeAll([setupInfo, { ...season, origin: 'qclear' }, { projectId }]));
          await upsertRights({
            divisionId: setupInfo.divisionId,
            subprojectId: id,
            rights: requiredRights,
          });
          return { projectId, seasonId: id };
        } catch (err) {
          const customError = makeErrorObject(err);
          return customError;
        }
      }

      try {
        const {
          season: { id: seasonId },
          project: { id },
        } = await createProjectAndSeason(mergeAll([setupInfo, { project, season: { ...season, origin: 'qclear' } }]));
        dispatch(keepSetupInfo(setupInfo.divisionId, id, setupInfo.tenantId));
        await upsertRights({
          divisionId: setupInfo.divisionId,
          subprojectId: seasonId,
          rights: requiredRights,
        });
        return { seasonId, projectId: id };
      } catch (err) {
        const customError = makeErrorObject(err);
        return customError;
      }
    };

    const response = await getSeasonId();
    if (response?.error) {
      return response;
    }

    const message = getSaveMessage('season', season.number);

    dispatch(addParams({ seasonId: season.id }));
    dispatch(showSnackbar({ message }));
    dispatch(closeAllDialogs());
    if (addRelease) {
      dispatch(
        openDrawer(DRAWERS.PRODUCTION_SETUP_RELEASE, {
          ...response,
        }),
      );
    } else {
      dispatch(cleanSetup());
    }
  };

export const saveSeasonRelease =
  ({ requiredRights, ...payload }, addEpisode = false) =>
  async (dispatch, getState) => {
    const setupInfo = getSetupFlowInfoSelector(getState());
    const extendedPayload = mergeAll([payload, setupInfo]);
    const { id: releaseId } = await createSeasonReleaseCall(extendedPayload);
    /** If the rights came from the season (default rights), dirty won't be set to true, so we set it now imperatively */
    requiredRights.dirty = true;
    await upsertRights({
      divisionId: setupInfo.divisionId,
      subprojectId: releaseId,
      rights: requiredRights,
    });

    dispatch(closeAllDialogs());
    dispatch(cleanSetupFlow());
    const message = getSaveMessage('release', payload.name);
    dispatch(showSnackbar({ message }));
    if (addEpisode) {
      dispatch(
        goToLink(ROUTES.PRODUCTION.OVERVIEW, {
          releaseId,
          type: NORMALIZED_PRODUCTION_TYPES.TV,
          ...setupInfo,
        }),
      );
    }
  };

export const updateSeason =
  ({ requiredRights, ...payload }, options) =>
  async (dispatch, getState) => {
    const { addRelease, continueEditing, patch } = options;
    const state = getState();
    const setupInfo = getSetupFlowInfoSelector(state);
    const season = getSetupFlowSeasonSelector(state);
    let updatedSeason = null;

    if (season.id) {
      const data = patch ? { id: season.id, name: season.name, ...payload } : mergeAll([season, payload]);

      try {
        updatedSeason = await updateSeasonCall(mergeAll([data, setupInfo]));
      } catch (err) {
        const customError = makeErrorObject(err);
        return customError;
      }

      dispatch(refetchProduction());

      const messageFn = patch ? getPatchMessage : getUpdateMessage;
      const message = messageFn('season', payload.number);

      await upsertRights({
        divisionId: setupInfo.divisionId,
        subprojectId: season.id,
        rights: requiredRights,
      });

      dispatch(showSnackbar({ message }));
    }

    if (continueEditing) {
      return await dispatch(keepSeasonInfo(mergeAll([season, updatedSeason])));
    }
    dispatch(closeDrawer());
    if (addRelease) {
      dispatch(
        openDrawer(DRAWERS.PRODUCTION_SETUP_RELEASE, {
          seasonId: payload.id,
        }),
      );
    } else dispatch(cleanSetup());

    return {
      error: false,
      message: '',
    };
  };

export const updateSeasonRelease =
  ({ requiredRights, ...payload }, options) =>
  async (dispatch, getState) => {
    const { addEpisode, continueEditing, patch } = options;

    const state = getState();
    const { projectId, ...setupInfo } = getSetupFlowInfoSelector(state);
    const release = getSetupFlowSeasonReleaseSelector(state);
    let updatedSeasonRelease = null;
    if (release.id) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { rightsPresetId, territories, ...data } = patch
        ? {
            id: release.id,
            projectId: release.projectId,
            seasonId: release.seasonId,
            name: release.name,
            ...payload,
          }
        : mergeAll([release, payload]);

      const [responseUpdatedSeasonRelease] = await Promise.all([
        updateSeasonReleaseCall(mergeAll([data, setupInfo])),
        !patch &&
          upsertRights({
            divisionId: setupInfo.divisionId,
            subprojectId: release.id,
            rights: requiredRights,
          }),
      ]);

      updatedSeasonRelease = responseUpdatedSeasonRelease;

      dispatch(refetchProduction());

      const messageFn = patch ? getPatchMessage : getUpdateMessage;
      const message = messageFn('release', payload.name);
      dispatch(showSnackbar({ message }));
    }

    if (continueEditing) {
      return await dispatch(keepReleaseInfo(mergeAll([release, updatedSeasonRelease || payload])));
    }

    dispatch(closeDrawer());
    dispatch(cleanSetupFlow());
    if (addEpisode) {
      dispatch(
        goToLink(ROUTES.PRODUCTION.OVERVIEW, {
          releaseId: payload.id,
          type: NORMALIZED_PRODUCTION_TYPES.TV,
          projectId,
          ...setupInfo,
        }),
      );
    }
  };

export const getEpisodePosterId = () => async (dispatch, getState) => {
  const state = getState();
  const posterIds = getPosterIdsSelector(state);

  /* EPISODE posterId exist */
  if (posterIds[PRODUCTION_TYPES.EPISODE]) {
    return posterIds[PRODUCTION_TYPES.EPISODE];
  }

  /* SEASON_RELEASE posterId exist */
  if (posterIds[PRODUCTION_TYPES.SEASON_RELEASE]) {
    return posterIds[PRODUCTION_TYPES.SEASON_RELEASE];
  }

  /* PROJECT posterId exist */
  if (posterIds[PRODUCTION_TYPES.PROJECT]) {
    return posterIds[PRODUCTION_TYPES.PROJECT];
  }

  const { releaseId, projectId } = getLocationParametersSelector(state);
  if (releaseId) {
    const { posterId } = await getSeasonReleaseById({
      id: releaseId,
    });
    if (posterId) {
      dispatch(savePosterId({ type: PRODUCTION_TYPES.SEASON_RELEASE, posterId }));
      return posterId;
    }
  }

  if (projectId) {
    const [project] = await dispatch(getProjectOnly());
    const { posterId } = project;
    if (posterId) {
      dispatch(savePosterId({ type: PRODUCTION_TYPES.PROJECT, posterId }));
      return posterId;
    }
  }

  return null;
};
