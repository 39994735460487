import { connect } from 'react-redux';
import {
  saveSeason,
  keepSeasonInfo,
  cleanSetup,
  fetchGetSeason,
  updateSeason,
  savePosterId,
  keepPersonnelInfo,
} from 'redux-core/productions/actions';
import { getDivisionId, getSetupFlowSeasonSelector } from 'redux-core/productions/selectors';
import { getDialogRightsByNameSelector } from 'redux-core/dialogs/selectors';

const mapStateToProps = (state, ownProps) => ({
  divisionId: getDivisionId(state),
  values: getSetupFlowSeasonSelector(state),
  rights: getDialogRightsByNameSelector(state, ownProps.name),
});

const mapDispatchToProps = {
  cleanSetup,
  fetchGetSeason,
  saveSeason,
  updateSeason,
  keepSeasonInfo,
  keepPersonnelInfo,
  savePosterId,
};

export default connect(mapStateToProps, mapDispatchToProps);
