/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import ROUTES from 'components/Routes/routes';
import enhancer from './enhancer';
import { HOMEPAGE } from '..';

const PrivateRoute = ({ component: Component, path, isAuthenticated, isAllowed, ...rest }) => {
  if (!isAuthenticated) {
    return <Redirect to={ROUTES.LOGIN} />;
  }
  if (!isAllowed) return <Redirect to={HOMEPAGE} />;
  return <Route {...rest} path={path} component={Component} />;
};

PrivateRoute.propTypes = {
  component: PropTypes.func.isRequired,
  path: PropTypes.string.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
};

export default enhancer(PrivateRoute);
