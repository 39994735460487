import { makeStyles } from '@material-ui/core';
import { appColors } from 'components/styleguide';

const styles = makeStyles((theme) => ({
  slider: {
    backgroundColor: appColors.dark,
    bottom: 0,
    minHeight: 55,
    position: 'absolute',
  },
  arrow: {
    borderRadius: 0,
    color: appColors.grey,
    backgroundColor: appColors.white,
    margin: theme.spacing(0, 1),
    '&:hover': {
      backgroundColor: appColors.white,
    },
  },
}));

export default styles;
