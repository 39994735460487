/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable no-use-before-define */
/* eslint-disable react/no-children-prop */
import React from 'react';
import PropTypes from 'prop-types';
import { useFetch } from 'utils/hooks';
import Form from 'components/common/Form';
import { FormButton, FormLeftLabelInput, FormDropdown } from 'components/common/Form/Fields';
import { getPersonnelPositionsCall } from 'redux-core/divisions/services';
import { Grid } from '@material-ui/core';
import { BaseDrawer, DrawerHeader } from 'components/styleguide';
import { useTranslation } from 'react-i18next';
import { pick } from 'ramda';
import * as Yup from 'yup';
// eslint-disable-next-line import/no-named-as-default
import DRAWERS, { CommonDrawerPropTypes } from '../constants';
import enhancer from './enhancer';

const rootT = 'drawers.productionSetup.addKeyPersonnel';

function AddKeyPersonnelDrawer({
  name,
  onClose,
  openDrawer,
  setupInfo,
  initialValues: { position, ...initialValues },
  keepPersonnelForm,
  payload: { onSubmit },
}) {
  const { t } = useTranslation();
  const [positions] = useFetch(() => getPersonnelPositionsCall(pick(['tenantId'])(setupInfo)));

  const customPositionInputComponent = (
    <Grid container item justify="space-between" alignItems="flex-start">
      <Grid item xs={9}>
        <FormDropdown
          name="position"
          placeholder="global.forms.chooseOne"
          options={positions}
          valueKey="id"
          labelKey="name"
        />
      </Grid>
      <Grid item xs={3} container justify="flex-end">
        <FormButton
          alwaysEnabled
          variant="secondary"
          size="xs"
          children="global.forms.edit"
          type="button"
          onClick={(values) => {
            keepPersonnelForm({ ...values, dirty: true });
            openDrawer(DRAWERS.PERSONNEL_ADD_EDIT_POSITION);
          }}
          testId="add-key-personnel-drawer-edit"
        />
      </Grid>
    </Grid>
  );
  return (
    <Form
      drawerName={name}
      enableReinitialize
      initialValues={{ ...initialValues, position: Number(position) }}
      onSubmit={async (values) => {
        await onSubmit(values);
        await onClose();
      }}
      validationSchema={validationSchema}
    >
      <>
        <DrawerHeader title={t(`${rootT}.drawerTitle`)} onClose={onClose}>
          <FormButton
            alwaysEnabled
            variant="destructive"
            size="xs"
            children="global.forms.cancel"
            type="button"
            // we need this in order to avoid forever loading by clicking on it. it's related to the 'src\components\styleguide\Button\index.jsx' component
            onClick={() => {
              onClose();
            }}
            testId="add-key-personnel-drawer-cancel"
          />
          <FormButton
            variant="primary"
            size="xs"
            children="global.forms.save"
            type="submit"
            testId="add-key-personnel-drawer-save"
          />
        </DrawerHeader>
        <Grid container item xs={12} direction="column">
          <FormLeftLabelInput label={`${rootT}.firstName`} name="firstName" required />
          <FormLeftLabelInput label={`${rootT}.lastName`} name="lastName" />
          <FormLeftLabelInput label={`${rootT}.phoneNumber`} name="phoneNumber" />
          <FormLeftLabelInput label={`${rootT}.email`} name="email" />
          <FormLeftLabelInput
            label={`${rootT}.position`}
            name="position"
            customComponent={customPositionInputComponent}
          />
          <FormLeftLabelInput label={`${rootT}.notes`} name="notes" textArea />
        </Grid>
      </>
    </Form>
  );
}

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required('global.forms.validations.required'),
  email: Yup.string().email('global.forms.validations.types.email').nullable(),
});

AddKeyPersonnelDrawer.propTypes = {
  name: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  payload: PropTypes.object.isRequired,
  initialValues: PropTypes.object,
  openDrawer: PropTypes.func.isRequired,
  keepPersonnelForm: PropTypes.func.isRequired,
  setupInfo: PropTypes.object.isRequired,
};

function Drawer({ open, onClose, ...props }) {
  const handleClose = async () => {
    // Clean personnel form on close
    await onClose();
    props.keepPersonnelForm({});
  };
  return (
    <BaseDrawer open={open} onClose={handleClose}>
      <AddKeyPersonnelDrawer onClose={handleClose} {...props} />
    </BaseDrawer>
  );
}

Drawer.propTypes = CommonDrawerPropTypes;

export default enhancer(Drawer);
