/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable react/no-children-prop */
/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-unused-expressions */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import pathOr from 'ramda/src/pathOr';
import prop from 'ramda/src/prop';
import { DrawerHeader } from 'components/styleguide';
import { FormButton } from 'components/common/Form/Fields';
import { useFetch, usePrompt, useRootTranslation } from 'utils/hooks';
import * as Yup from 'yup';
import { yupTimeMMSS, yupRightPresetTerritories } from 'utils/forms';
import { fuse } from 'utils/object';
import { COMMON_ROLES } from 'redux-core/permissions/constants';
import { SecuredForm } from 'components/common/Secured';
import { getRightsCall } from 'redux-core/rights/services';
import { formatPresetToRights } from 'components/common/RightsPresetPicker';
import enhancer from './enhancer';
// eslint-disable-next-line import/no-cycle
import DetailsFinish from './DetailsFinish';
import DetailsAsset from './DetailsAsset';

const rootT = 'drawers.assets';

export const getFinishersList = pathOr([], ['additionalData', 'finishers']);

const validationSchema = Yup.object().shape({
  title: Yup.string().required('global.forms.validations.required'),
  materialTypeId: Yup.number().nullable(),
  territories: yupRightPresetTerritories(),
  currentCueContainerVersion: Yup.object().shape({
    title: Yup.string(),
    version: Yup.string(),
    duration: yupTimeMMSS,
  }),
});

export const getIsFinished = (values) => {
  const finishers = getFinishersList(values);
  return finishers.some((finisher) => !!finisher.finishingDate);
};

function AssetDrawer({
  name,
  campaignId,
  getMaterialTypes,
  getCampaignAsset,
  createCampaignAsset,
  updateCampaignAsset,
  onClose,
  formId,
  payload: { id, onSubmit, ...payload },
  savedValues,
  clearForm,
  rights,
  ...props
}) {
  const openPrompt = usePrompt();
  const { divisionId } = fuse(payload, props);

  const t = useRootTranslation(rootT);
  const [materialTypes] = useFetch(() => divisionId && getMaterialTypes({ divisionId }), [divisionId]);
  const [asset, loading] = useFetch(() => id && getCampaignAsset({ id, divisionId }), [id], {});

  const [rightsPresets] = useFetch(
    () => !id && !rights && divisionId && getRightsCall({ divisionId }),
    [id, rights, divisionId],
  );

  const defaultRightsPreset = useMemo(() => {
    const def = rightsPresets.filter((f) => f.default);
    let retDef;
    if (def) {
      retDef = def.map((d) => ({
        ...formatPresetToRights(d),
      }));
    }

    return retDef;
  }, [rightsPresets]);

  const requiredRights = defaultRightsPreset || rights;

  const initialValues = {
    ...payload,
    ...asset,
    ...savedValues,
    requiredRights,
    dirty: prop('dirty', requiredRights),
  };

  const onCancel = () => {
    onClose();
  };

  const clearSubmitAndClose = async (cueContainer, formId) => {
    await clearForm({ formId });
    onSubmit && (await onSubmit(cueContainer));
    onClose();
  };

  const submitUpdateCampaignAsset = async (values, asset, formId, overwriteExistingClearancesRights) => {
    const cueContainer = await updateCampaignAsset(values, asset, overwriteExistingClearancesRights);
    clearSubmitAndClose(cueContainer, formId);
  };

  const handleCampaignAssetUpdate = async (values, asset, formId) => {
    const { requiredRights } = values;
    const rightsChanged = requiredRights && requiredRights.dirty;

    if (!rightsChanged) {
      return submitUpdateCampaignAsset(values, asset, formId);
    }

    openPrompt({
      content: t('form.confirmRightsUpdateForAsset'),
      onCancel: async () => {
        const overwriteExistingClearancesRights = false;
        submitUpdateCampaignAsset(values, asset, formId, overwriteExistingClearancesRights);
      },
      onConfirm: async () => {
        const overwriteExistingClearancesRights = true;
        submitUpdateCampaignAsset(values, asset, formId, overwriteExistingClearancesRights);
      },
    });
  };

  const handleSubmit = async (values) => {
    const isFinished = getIsFinished(values);
    const update = isFinished || id;
    if (!update) {
      const cueContainer = await createCampaignAsset({ ...values, campaignId });
      return clearSubmitAndClose(cueContainer, formId);
    }

    return handleCampaignAssetUpdate({ ...values, id }, asset, formId);
  };

  return (
    <SecuredForm
      drawerName={name}
      onSubmit={handleSubmit}
      enableReinitialize
      initialValues={initialValues}
      objectId={asset.objectId}
      permissions={COMMON_ROLES.EDITOR}
      readOnly={loading}
      validationSchema={validationSchema}
    >
      <DrawerHeader title={id ? t('titles.edit') : t('titles.new')} onClose={onClose}>
        <FormButton
          testId="asset-drawer-cancel"
          alwaysEnabled
          variant="destructive"
          size="xs"
          children="global.forms.cancel"
          onClick={() => onCancel()}
        />
        <FormButton
          testId="asset-drawer-save"
          children="global.forms.save"
          size="xs"
          type="submit"
          variant="primary"
          onClick={handleSubmit}
        />
      </DrawerHeader>
      <Grid container>
        <DetailsAsset
          materialTypes={materialTypes}
          divisionId={divisionId}
          formId={formId}
          objectId={asset.objectId}
          drawerName={name}
          id={asset.id}
        />
        <Grid item xs={1} container justify="space-around">
          <Divider orientation="vertical" />
        </Grid>
        <DetailsFinish divisionId={divisionId} />
      </Grid>
    </SecuredForm>
  );
}

AssetDrawer.propTypes = {
  name: PropTypes.string,
  payload: PropTypes.shape({
    id: PropTypes.number,
    divisionId: PropTypes.number,
    createCampaignAsset: PropTypes.func.isRequired,
    updateCampaignAsset: PropTypes.func.isRequired,
  }),
  campaignId: PropTypes.number.isRequired,
  divisionId: PropTypes.number.isRequired,
  formId: PropTypes.string.isRequired,
  savedValues: PropTypes.object.isRequired,
  getCampaignAsset: PropTypes.func.isRequired,
  getMaterialTypes: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  createCampaignAsset: PropTypes.func.isRequired,
  updateCampaignAsset: PropTypes.func.isRequired,
  clearForm: PropTypes.func.isRequired,
  rights: PropTypes.shape({}),
};

export default enhancer(AssetDrawer);
