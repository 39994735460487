import { Box } from '@mui/system';
import { UseFieldArrayRemove } from 'react-hook-form';
import { TLicensorType } from 'services/tracks/types';
import { AddRightsHoldersMenu } from './AddRightsHoldersMenu';
import { OneStopCombinedField } from './OneStopCombinedField';

type FormToolbarProps = {
  onAddItem: (type: TLicensorType) => void;
  remove: UseFieldArrayRemove;
};

export function FormToolbar({ onAddItem, remove }: FormToolbarProps) {
  return (
    <Box sx={{ display: 'flex', gap: 2, width: '100%' }}>
      <Box sx={{ flexShrink: 0 }}>
        <AddRightsHoldersMenu onAddItem={onAddItem} />
      </Box>
      <Box>
        <OneStopCombinedField remove={remove} />
      </Box>
    </Box>
  );
}
