import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { rootTranslation } from 'utils/format';
import { BackToSearchButton } from '../BackToSearchButton';

const t = rootTranslation('drawers.productionSetup.tracks');

export function CreateEmptyState() {
  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          py: 3,
          mx: 'auto',
          width: '600px',
          textAlign: 'center',
          mt: 3,
        }}
      >
        <Typography variant="h4">{t('createPlaceholder.heading')}</Typography>
        <Typography variant="body1" sx={{ textWrap: 'balance' }}>
          {t('createPlaceholder.bodyPrimary')}
        </Typography>
        <Typography variant="body1">{t('createPlaceholder.bodySecondary')}</Typography>
        <div>
          <BackToSearchButton variant="outlined" />
        </div>
      </Box>
    </Box>
  );
}
