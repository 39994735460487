import { Skeleton } from '@mui/material';
import { composeLetterAtom } from 'components/ComposeLetter/composeLetterStore';
import { useAtomValue } from 'jotai';
import { useEffect, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { getContactByIdCall } from 'redux-core/contacts/services';
import { useGetPreferredContactQuery } from 'services/clear/licensors';
import { TLicensorContact } from 'services/clear/types';
import { ComposeLetterContactFormFields } from './ComposeLetterContactFormFields';
import { ComposeLetterExistingContactSelect } from './ComposeLetterExistingContactSelect';

export type TComposeLetterExistingContactFormProps = {
  formBag: UseFormReturn<TLicensorContact>;
  rightsOwnerId: number;
};

export function ComposeLetterExistingContactForm({ formBag, rightsOwnerId }: TComposeLetterExistingContactFormProps) {
  const [isExistingContactSelected, setIsExistingContactSelected] = useState(!!formBag?.getValues('id'));
  const { data: preferredContactResponse, isLoading } = useGetPreferredContactQuery(
    { rightsOwnerId },
    { skip: !rightsOwnerId },
  );
  const { divisionId } = useAtomValue(composeLetterAtom);

  useEffect(() => {
      (async () => {
        if (!preferredContactResponse?.contactId) {
          formBag.reset({
            id: undefined,
            firstName: '',
            lastName: '',
            email: '',
            address1: '',
            address2: '',
            city: '',
            state: '',
            postalCode: '',
            countryName: 'United States',
          });
          setIsExistingContactSelected(false);
          return;
        }

        const retrievedContact = await getContactByIdCall({
          divisionId,
          id: preferredContactResponse.contactId,
        }) as any;

        formBag.reset({
          id: retrievedContact.id || undefined,
          firstName: retrievedContact.firstName || '',
          lastName: retrievedContact.lastName || '',
          email: retrievedContact.email || '',
          address1: retrievedContact.address1 || '',
          address2: retrievedContact.address2 || '',
          city: retrievedContact.city || '',
          state: retrievedContact.state || '',
          postalCode: retrievedContact.postalCode || '',
          countryName: retrievedContact.countryName || 'United States',
        });
        setIsExistingContactSelected(!!retrievedContact);
      })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preferredContactResponse]);

  if (!rightsOwnerId) return null;
  if (isLoading) return <Skeleton />;

  return (
    <>
      <ComposeLetterExistingContactSelect
        divisionId={divisionId}
        formBag={formBag}
        setIsExistingContactSelected={setIsExistingContactSelected}
      />
      {isExistingContactSelected && <ComposeLetterContactFormFields formBag={formBag} />}
    </>
  );
}
