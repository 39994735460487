import { compose } from 'redux';
import { connect } from 'react-redux';
import withBaseDrawer from 'components/common/Drawers/withBaseDrawer';
import { clearImportCueSheetWorkflow, goToNextStep } from 'redux-core/import-cuesheet/actions';
import { getImportFileNameSelector } from 'redux-core/import-cuesheet/selectors';

const mapStateToProps = (state) => ({
  fileName: getImportFileNameSelector(state),
});

const mapDispatchToProps = {
  clearForms: clearImportCueSheetWorkflow,
  clearImportCueSheetWorkflow,
  goToNextStep,
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withBaseDrawer);
