import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { rootTranslation } from 'utils/format';
import { useFormContext } from 'react-hook-form';

const t = rootTranslation('drawers.productionSetup.tracks');

export function CreateNewButton() {
  const { setValue } = useFormContext();

  return (
    <Button
      variant="outlined"
      color="secondary"
      size="small"
      startIcon={<AddIcon />}
      onClick={() => {
        setValue('contentMode', 'create');
        setValue('artist', '');
      }}
    >
      {t('filter.createNew')}
    </Button>
  );
}
