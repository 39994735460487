/* eslint-disable import/no-cycle */
import makeEntityServiceCalls, { makeServiceCall } from 'redux-core/serviceHelpers';
import partition from 'ramda/src/partition';
import prop from 'ramda/src/prop';
import propEq from 'ramda/src/propEq';
import sortBy from 'ramda/src/sortBy';

export const {
  getAll: getTerritoriesCall,
  get: getTerritoryCall,
  create: createTerritoryPresetCall,
  update: updateTerritoryPresetCall,
  delete: deleteTerritoryPresetCall,
} = makeEntityServiceCalls('territories');

export const getTerritoriesListCall = (payload) => makeServiceCall('territories', 'get-list')(payload);

export const getCountriesCall = async (payload) => {
  const countries = await makeServiceCall('countries', 'get-all')(payload);
  // United States of America at the top and then alphabetize everything underneath
  const [us, rest] = partition(propEq('code', 'US'))(countries);
  return [...us, ...sortBy(prop('name'))(rest)];
};
