/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable import/no-cycle */
import i18next from 'i18next';
import { showSnackbar } from 'redux-core/global-error/actions';
import {
  createCampaignCall,
  createProjectAndCampaignCall,
  updateCampaignCall,
} from 'redux-core/overview/production-marketing/services';
import { getSetupFlowProjectSelector } from 'redux-core/productions/selectors';
import mergeAll from 'ramda/src/mergeAll';
import omit from 'ramda/src/omit';
import pick from 'ramda/src/pick';
import { requestWithError } from 'utils/request';
import { addParams } from 'redux-core/router/actions';
import { getLocationParametersSelector } from 'redux-core/router/selectors';
import { fetchRecentSearchList, fetchBookmarkList } from 'redux-core/header/actions';
import { getSelectedSelector } from '../selectors';
import { setSelected, setProject, refetchProduction } from '../actions';

export const createCampaign = (payload) => async (dispatch, getState) => {
  const state = getState();
  const { projectId, divisionId, tenantId } = getLocationParametersSelector(state);
  const setupInfo = { divisionId, tenantId };
  const project = omit(['projectId'], getSetupFlowProjectSelector(state));
  const createCampaign = async () => {
    if (projectId) {
      const createdCapaign = await createCampaignCall(
        mergeAll([setupInfo, { ...payload, origin: 'qclear' }, { projectId }]),
      );
      return createdCapaign;
    }
    const { campaign: createdCapaign, project: createdProject } = await createProjectAndCampaignCall(
      mergeAll([setupInfo, { project, campaign: { ...payload, origin: 'qclear' } }]),
    );
    dispatch(setProject(createdProject));
    dispatch(addParams({ divisionId, projectId: createdProject.id, tenantId }));
    return createdCapaign;
  };

  const createdCapaign = await requestWithError(createCampaign);
  await dispatch(addParams({ id: createdCapaign.id }));
  dispatch(refetchProduction());
  dispatch(setSelected(createdCapaign));

  const message = i18next.t('productionOverview.tabs.details.notification.campaign.create', { name: payload.name });

  dispatch(showSnackbar({ message }));

  return createdCapaign.id;
};

export const updateCampaign =
  (payload, options = {}) =>
  async (dispatch, getState) => {
    const { patch, refetch } = options;
    const state = getState();
    const setupInfo = pick(['divisionId', 'tentantId', 'projectId'], getLocationParametersSelector(state));
    const selectedProject = getSelectedSelector(state);

    const updatedPayload = patch
      ? mergeAll([setupInfo, pick(['id', 'name', 'projectId', 'releaseId'], selectedProject), payload])
      : mergeAll([setupInfo, selectedProject, payload]);

    if (!updatedPayload.id) {
      return dispatch(setSelected(mergeAll([selectedProject, updatedPayload])));
    }

    const newCampaign = omit(['dirty'], {
      ...selectedProject,
      ...setupInfo,
      ...updatedPayload,
    });

    const updatedCampaign = await requestWithError(updateCampaignCall, newCampaign, {
      message: {
        success: i18next.t('productionOverview.tabs.details.notification.campaign.edit', {
          name: payload.name,
        }),
      },
    });

    // Refetch recent list
    dispatch(fetchRecentSearchList());
    // Refetch bookmarks list
    dispatch(fetchBookmarkList());
    return refetch ? dispatch(refetchProduction) : dispatch(setSelected(updatedCampaign));
  };
