/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { Row, ToggleButton } from 'components/styleguide/index';
import { useRootTranslation } from 'utils/hooks';
import { FormTypeAhead, FormToggleButtonGroup } from 'components/common/Form/Fields';
import { USING_VALUE, FILTERS_LIST_FOR_CUES } from '../constants';

function CuesFilters({ filtersData, loading }) {
  const t = useRootTranslation('drawers.advancedFilters.cuesFilters');
  const tGlobal = useRootTranslation('global');
  const { trackTypes, trackUseTypes, trackStatuses, trackTags, clearanceReps, trackLibraries } = filtersData;

  const sharedProps = {
    placeholder: tGlobal('forms.placeholder.search'),
    isMultiple: true,
    loading,
  };

  return (
    <>
      <Row mb={3}>
        <Grid item xs={8}>
          <FormToggleButtonGroup name="using" testid="advanced-search-using" size="auto">
            <ToggleButton value={USING_VALUE.ALL}>{t(USING_VALUE.ALL)}</ToggleButton>
            <ToggleButton value={USING_VALUE.USING}>{t(USING_VALUE.USING)}</ToggleButton>
            <ToggleButton value={USING_VALUE.NOT_USING}>{t(USING_VALUE.NOT_USING)}</ToggleButton>
          </FormToggleButtonGroup>
        </Grid>
      </Row>
      <Row mb={3}>
        <Grid item xs={8}>
          <FormTypeAhead {...sharedProps} name="trackStatusIds" label={t('trackStatuses')} options={trackStatuses} />
        </Grid>
      </Row>
      <Row mb={3}>
        <Grid item xs={8}>
          <FormTypeAhead {...sharedProps} name="trackTypes" label={t('trackTypes')} options={trackTypes} />
        </Grid>
      </Row>
      <Row mb={3}>
        <Grid item xs={8}>
          <FormTypeAhead {...sharedProps} name="trackUseTypes" label={t('trackUseTypes')} options={trackUseTypes} />
        </Grid>
      </Row>
      <Row mb={3}>
        <Grid item xs={8}>
          <FormTypeAhead {...sharedProps} name="trackTags" label={t('trackTags')} options={trackTags} />
        </Grid>
      </Row>
      <Row mb={3}>
        <Grid item xs={8}>
          <FormTypeAhead {...sharedProps} name="clearanceRepIds" label={t('clearanceRepIds')} options={clearanceReps} />
        </Grid>
      </Row>
      <Row mb={3}>
        <Grid item xs={8}>
          <FormTypeAhead {...sharedProps} name="libraryIds" label={t('libraryIds')} options={trackLibraries} />
        </Grid>
      </Row>
    </>
  );
}

CuesFilters.propTypes = {
  loading: PropTypes.bool,
  filtersData: PropTypes.exact(
    FILTERS_LIST_FOR_CUES.reduce((acc, filter) => ({ ...acc, [filter]: PropTypes.array }), {}),
  ),
};

export default CuesFilters;
