import { createSelector } from 'reselect';
import prop from 'ramda/src/prop';
import { getLocationFiltersSelector } from 'redux-core/router/selectors';

const getTracksRoot = prop('tracks');

export const getTracksSelector = createSelector(getTracksRoot, prop('tracks'));

export const getTagsSelector = createSelector(getTracksRoot, prop('tags'));

export const getSelectedTrackSelector = createSelector(getTracksRoot, prop('selectedTrack'));

export const getClearanceFormDirty = createSelector(getTracksRoot, prop('clearanceFormDirty'));

export const getSelectedQwireTracksId = createSelector(getSelectedTrackSelector, prop('qwireTracksId'));

export const getSelectedTrackId = createSelector(getSelectedTrackSelector, prop('id'));

export const getSelectedTrackObjectId = createSelector(getSelectedTrackSelector, prop('objectId'));

export const getSelectedTrackScore = createSelector(getSelectedTrackSelector, prop('score'));

export const getSelectedTrackPartnerId = createSelector(getSelectedTrackSelector, prop('partnerId'));

export const getSelectedTrackIsEditable = createSelector(getSelectedTrackSelector, prop('isEditable'));

export const getSelectedTrackOneStopCombinedEnabled = createSelector(getSelectedTrackSelector, prop('oneStopCombined'));

export const getHideInactiveSelector = createSelector(getLocationFiltersSelector, prop('hideInactiveCues'));

export const getIsLoadingTracksSelector = createSelector(getTracksRoot, prop('loading'));

export const getTracksPageCountSelector = createSelector(getTracksRoot, prop('pageCount'));

export const getTracksTotalCountSelector = createSelector(getTracksRoot, prop('totalCount'));

export const getTrackSort = createSelector(getTracksRoot, prop('sort'));

export const getTrackParams = createSelector(getLocationFiltersSelector, getTrackSort, (filters, sort) => ({
  filters,
  ...sort,
}));
