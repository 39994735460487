/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { BaseDrawer } from 'components/styleguide';
import EditDeleteItemsList from 'components/common/EditDeleteItemsList/';
import { useRootTranslation } from 'utils/hooks';
import { CommonDrawerPropTypes } from '../constants';

const NetworksDrawer = ({
  name,
  onClose,
  payload: { onSubmit, networks = [], readOnly, rootT = 'drawers.productionSetup.networks' },
}) => {
  const t = useRootTranslation(rootT);
  const handleSubmit = (values) => {
    onSubmit({
      networks: values,
    });
  };
  return (
    <EditDeleteItemsList
      name={name}
      deletePromptContent={(network) => t('delete', network)}
      drawerTitle={`${rootT}.drawerTitle`}
      inputPlaceholderName="drawers.nameForm.placeholder"
      onClose={onClose}
      onSubmit={handleSubmit}
      readOnly={readOnly}
      initialValues={networks}
    />
  );
};

NetworksDrawer.propTypes = {
  name: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  payload: PropTypes.object.isRequired,
  rootT: PropTypes.string.isRequired,
};

const Drawer = ({ open, onClose, ...props }) => (
  <BaseDrawer open={open} onClose={onClose}>
    <NetworksDrawer onClose={onClose} {...props} />
  </BaseDrawer>
);

Drawer.propTypes = CommonDrawerPropTypes;

export default Drawer;
