import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Grid, Skeleton, Typography } from '@mui/material';
import Logo from 'assets/qwire-logo.svg';
import { Topbar } from 'components/styleguide';
import { useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { usePartnerSignupMutation, useVerifyPartnerTokenQuery } from 'services/common/users';
import { rootTranslation } from 'utils/format';
import { yupUserPassword } from 'utils/forms';
import * as Yup from 'yup';
import { CompanyNameField } from './CompanyNameField';
import { PartnerInformation } from './PartnerInformation';
import { PasswordField } from './PasswordField';

export type FormInput = {
  companyName: string;
  password: string;
};

const t = rootTranslation('apmSignup');

export function APMSignUp() {
  const history = useHistory();
  const query = useQuery();
  const token = query.get('token');

  const { data: partnerInfo, isLoading } = useVerifyPartnerTokenQuery({ token });
  const [signupPartner, result] = usePartnerSignupMutation();

  const formBag = useForm<FormInput>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      companyName: '',
      password: '',
    },
    mode: 'onBlur',
  });

  if (isLoading) {
    return <Skeleton />;
  }

  if (partnerInfo.exist) {
    history.push('/');
  }

  if (result.isSuccess) {
    toast.success(t('signUpCompleted'), {
      position: 'top-right',
    });
    history.push('/');
  }

  const onSubmit = async (values: FormInput) => {
    await signupPartner({
      partnerToken: token,
      firstName: partnerInfo.firstName,
      lastName: partnerInfo.lastName,
      partner: partnerInfo.partner,
      email: partnerInfo.email,
      password: values.password,
      companyName: values.companyName,
    });
  };

  return (
    <>
      <Topbar brand={<img src={Logo} alt="qwire" />} noMargin />
      <Grid container alignItems="center" justifyContent="center" height="100%">
        <Grid item xs={10} sm={8} md={6} lg={4}>
          <Typography variant="h2" py={2}>
            {t('title')}
          </Typography>
          <Typography variant="body1" py={2}>
            {t('createAccountDescription')} <a href="/">{t('login')}</a>
          </Typography>
          <FormProvider {...formBag}>
            <form onSubmit={formBag.handleSubmit(onSubmit)}>
              <Grid container spacing={1}>
                <PartnerInformation partnerInfo={partnerInfo} />
                <Grid item xs={12}>
                  <CompanyNameField />
                </Grid>
                <Grid item xs={12}>
                  <PasswordField />
                </Grid>
                <Grid item xs={12} pt={2}>
                  <Typography variant="body1">
                    {t('accept1')} {t('terms')} {t('accept2')} {t('globalPrivacy')}
                  </Typography>
                </Grid>
                <Grid item xs={12} pt={2}>
                  <Button
                    type="submit"
                    variant="contained"
                    disabled={!formBag.formState.isValid || !result.isUninitialized}
                  >
                    {t('createAccount')}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </FormProvider>
        </Grid>
      </Grid>
    </>
  );
}

const validationSchema = Yup.object().shape({
  companyName: Yup.string().required('global.forms.validations.required'),
  password: yupUserPassword(8, true),
});

function useQuery() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}
