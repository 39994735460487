import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
  containerRoot: {
    marginBottom: theme.spacing(),
    '&:hover': {
      '& $deleteButton': {
        display: 'block',
      },
    },
  },
  buttonContainerLast: {
    padding: `${theme.spacing(1, 0.5, 0.5, 0.5)}!important`,
  },
  deleteButton: {
    display: 'none',
  },
}));

export default styles;
