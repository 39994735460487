import PropTypes from 'prop-types';

const AppPermissionsPropTypes = PropTypes.shape({
  clearPermissions: PropTypes.number,
  commonPermissions: PropTypes.number,
  cuePermissions: PropTypes.number,
});

export const withPermissionsPropTypes = PropTypes.shape({
  clearPermissions: AppPermissionsPropTypes,
  cuePermissions: AppPermissionsPropTypes,
  objectId: PropTypes.number,
  permissions: AppPermissionsPropTypes,
});
