import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  message: {
    marginLeft: '43%',
  },
  content: {
    width: '100%',
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(),
    paddingBottom: theme.spacing(),
    '&.embedded': {
      boxShadow: 'none',
    },
  },
  margin: {
    margin: theme.spacing(1),
  },
  root: {
    width: '100%',
    bottom: 0,
    borderRadius: 0,
    '&.embedded': {
      position: 'relative',
    },
  },
}));
