import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
  icon: {
    borderRadius: 5,
    padding: 2,
    minWidth: 34,
    minHeight: 34,
    height: 'auto',
    '&[aria-pressed="true"]': {
      cursor: 'default',
    },
    '&.dense': {
      minWidth: 24,
      minHeight: 24,
    },
  },
  checkIcon: {
    color: theme.palette.green,
  },
  menuIcon: {
    color: theme.palette.action.active,
  },
}));

export default styles;
