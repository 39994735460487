/* eslint-disable react/no-children-prop */
import Grid from '@material-ui/core/Grid';
import compose from 'ramda/src/compose';
import reject from 'ramda/src/reject';
import prop from 'ramda/src/prop';
import nth from 'ramda/src/nth';
import last from 'ramda/src/last';

import { ClearanceStatusLabel } from 'components/styleguide/ClearanceStatusLabel';
import { Text, TrackType, TypeIcon } from 'components/styleguide';
import { concatObjects, concatStrings } from 'utils';
import { formatCurrency } from 'utils/format';
import { getNormalizedProductionType } from 'utils/productions';
import { useRootTranslation } from 'utils/hooks';

import { getUseAndDuration } from '../../utils';
import { SearchUsageChip } from '../../SearchUsageChip';
import useStyles from './styles';

const rootT = 'globalSearch.results';

export const LicensorItem = ({ prodArray, productionType }) => {
  const t = useRootTranslation(rootT);

  const licensorInfo = last(prodArray);
  const trackInfo = prodArray[prodArray.length - 2];
  const artists = compose(concatStrings(', '), prop('artists'))(trackInfo);
  const normalizedProdType = getNormalizedProductionType(productionType);
  const projectName = prodArray[0].name;
  const productionPath = compose(
    concatObjects(' - '),
    reject((item) => ['Project', 'Track', 'Rights Owner'].includes(item.type)),
  )(prodArray);

  const currency = compose(prop('selectedCurrency'), nth(-2))(prodArray);

  const clearanceStatus = licensorInfo.rightsOwnerClearanceStatus;
  const approvedFees = Number(trackInfo.approvedFees);
  const requestedUses = getUseAndDuration(trackInfo.cueContainers);
  const { trackType } = trackInfo;

  const classes = useStyles();

  return (
    <Grid container>
      <Grid container item xs={6}>
        <Grid container item alignItems="center">
          <Text bold children={licensorInfo.name} noWrap />
        </Grid>

        <Grid container item>
          <Grid item xs={6} className={classes.dataWrapper}>
            <Grid container item alignItems="center" spacing={2}>
              <Grid item xs={1} container alignItems="center">
                <TrackType type={trackType} />
              </Grid>
              <Grid container item xs={11} direction="column">
                <Text bold variant="caption" children={trackInfo.name} testId="track-name" noWrap />
                <Text variant="caption" children={artists} noWrap />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={6} className={classes.dataWrapper}>
            <Grid container item alignItems="center">
              <Grid item xs={1} container alignItems="center">
                <TypeIcon type={normalizedProdType} size={24} />
              </Grid>
              <Grid container item xs={11} direction="column">
                <Text bold variant="caption" children={projectName} />
                <Text variant="caption" testId="production-path" children={productionPath} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container item xs={6}>
        <Grid item xs={5} className={classes.dataWrapper}>
          <Text bold variant="caption" children={t('status')} gutterBottom />
          {clearanceStatus && <ClearanceStatusLabel clearanceStatus={clearanceStatus} />}
        </Grid>
        <Grid item xs={3} className={classes.dataWrapper}>
          <Text bold children={t('approvedFees')} gutterBottom variant="caption" />

          <Text children={formatCurrency(approvedFees, currency)} />
        </Grid>
        <Grid item xs={4} className={classes.dataWrapper}>
          <Text bold children={t('requestedUse')} gutterBottom variant="caption" />
          {requestedUses[0] && <SearchUsageChip usage={requestedUses[0]} />}
          {requestedUses[1] && <SearchUsageChip usage={requestedUses[1]} />}
        </Grid>
      </Grid>
    </Grid>
  );
};
