import { FormControlLabel, Switch } from '@mui/material';
import { useWatch, UseFieldArrayRemove, FieldArrayWithId } from 'react-hook-form';
import { FormField } from 'components/common/Form/Fields/FormField/FormField';
import { RIGHTS_OWNER_TYPES } from 'utils/constants';
import { rootTranslation } from 'utils/format';
import { TAddTrackForm } from '../../types';

const t = rootTranslation('drawers.productionSetup.tracks.createLicensors');

export function OneStopCombinedField({ remove }: { remove: UseFieldArrayRemove }) {
  const fields = useWatch({ name: 'rightsOwners' });

  const onToggleOneStopCombined = (value, onChange) => {
    window.freshpaint.track('Toggled One-Stop', { oneStop: !value });
    onChange(!value);

    const indexListToRemove = getIndicesToRemoveOnOneStopChange(fields);
    remove(indexListToRemove);
  };

  return (
    <FormField
      name="oneStopCombined"
      render={({ field }) => (
        <FormControlLabel
          control={
            <Switch
              color="secondary"
              checked={field.value}
              onChange={() => onToggleOneStopCombined(field.value, field.onChange)}
            />
          }
          label={t('one_stop')}
        />
      )}
    />
  );
}

function getIndicesToRemoveOnOneStopChange(fields: FieldArrayWithId<TAddTrackForm, 'rightsOwners', 'id'>[]): number[] {
  return fields.reduce((acc, item, index) => {
    if (item.type !== RIGHTS_OWNER_TYPES.WRITER && item.type !== RIGHTS_OWNER_TYPES.PUBLISHER) {
      acc.push(index);
    }
    return acc;
  }, []);
}
