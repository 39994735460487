/* eslint-disable import/no-cycle */
import makeEntityServiceCalls, { makeServiceCall } from 'redux-core/serviceHelpers';
import { SEARCH_CONTACTS_FIELDS } from 'utils/constants';

export const {
  get: getContactByIdCall,
  create: createContactCall,
  update: updateContactCall,
  getAll: getContactsCall,
  delete: deleteContactCall,
} = makeEntityServiceCalls('contacts', 'clear');

export const searchContactsCall = (payload) =>
  makeServiceCall(
    'contacts',
    'search',
    'clear',
  )({
    useCurrentDivision: Boolean(payload.useCurrentDivision),
    fields: [SEARCH_CONTACTS_FIELDS.EMAIL, SEARCH_CONTACTS_FIELDS.LICENSOR_COMPANY_NAME, SEARCH_CONTACTS_FIELDS.NAME],
    ...payload,
  });

export const getLicensorContacts = makeServiceCall('licensors', 'get-contacts', 'clear');
