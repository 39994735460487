/* eslint-disable import/no-cycle */
import withBaseDrawer from 'components/common/Drawers/withBaseDrawer';
import { connect } from 'react-redux';
import compose from 'ramda/src/compose';
import { refetchCues } from 'redux-core/cueSheet/actions';
import { openPromptAsync } from 'redux-core/prompt/actions';

const mapDispatchToProps = { refetchCues, openPromptAsync };

export default compose(connect(undefined, mapDispatchToProps), withBaseDrawer);
