/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '@material-ui/core/SvgIcon';

const ScenarioIcon = ({ active, ...props }) => (
  <SvgIcon {...props} viewBox="0 0 36 36">
    <path fill="#fff" d="M1 1h27v27H1z" />
    <path
      fillRule="evenodd"
      d="M28.444 0H3.556A3.566 3.566 0 0 0 0 3.556v24.888C0 30.4 1.6 32 3.556 32h24.888C30.4 32 32 30.4 32 28.444V3.556C32 1.6 30.4 0 28.444 0zM10.666 24.89H7.111v-3.556h3.555v3.555zm0-7.113H7.111v-3.555h3.555v3.556zm0-7.11H7.111V7.11h3.555v3.555zM24.89 24.888H12.444v-3.555H24.89v3.555zm0-7.111H12.444v-3.556H24.89v3.556zm0-7.112H12.444V7.111H24.89v3.555z"
      clipRule="evenodd"
    />
    <circle cx="26.5" cy="25.5" r="9.5" fill="#fff" />
    <path
      fill={active ? '#628C61' : '#DBDBDB'}
      fillRule="evenodd"
      d="M26 16c-5.5 0-10 4.5-10 10s4.5 10 10 10 10-4.5 10-10-4.5-10-10-10zm-2 15l-5-5 1.4-1.4 3.6 3.6 7.6-7.6L33 22l-9 9z"
      clipRule="evenodd"
    />
  </SvgIcon>
);

ScenarioIcon.propTypes = {
  active: PropTypes.bool,
};
export default ScenarioIcon;
