/* eslint-disable import/no-cycle */
import { createFile, getUploadAccessCall, uploadToBucketCall } from './services';
/*
  List of most common types considered safe for use on web pages.
  See https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types#Image_types
*/
const allowedImageTypes = {
  gif: 'image/gif',
  jpeg: 'image/jpeg',
  jpg: 'image/jpeg',
  jpe: 'image/jpeg',
  png: 'image/png',
  webp: 'image/webp',
  svg: 'image/svg+xml',
  svgz: 'image/svg+xml',
  ico: 'image/x-icon',
};

const getContentType = (fileName, kind) => {
  if (Object.values(allowedImageTypes).includes(kind) || kind === 'IMAGE') {
    const ext = fileName.split('.').pop();
    const type = allowedImageTypes[ext] || `image/${ext}`;
    return type;
  }

  return 'binary/octet-stream';
};

export const uploadFileCall = async (
  file,
  { kind, resizingOptions } = {
    kind: 'IMAGE',
    resizingOptions: null,
  },
) => {
  const fileName = file.name || file.fileName;
  const contentType = getContentType(fileName, kind);
  // Gets url to upload file to AWS
  const { key, uploadUrl } = await getUploadAccessCall({
    kind,
    contentType,
  });
  // Uploads the image to AWS
  await uploadToBucketCall(uploadUrl, { file, contentType });
  // Saves new image in Backend
  const { id } = await createFile({
    fileName,
    key,
    kind,
    resizingOptions,
  });
  return id;
};
