import { connect } from 'react-redux';
import { getLocationParametersDivisionId } from 'redux-core/router/selectors';
import { showSnackbar, showGenericError } from 'redux-core/global-error/actions';

const mapStateToProps = (state) => ({
  divisionId: getLocationParametersDivisionId(state),
});
const mapDispatchToProps = {
  showSnackbar,
  showGenericError,
};

export default connect(mapStateToProps, mapDispatchToProps);
