/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/no-children-prop */
/* eslint-disable @typescript-eslint/no-shadow */
import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { Text, Row, DrawerHeader, Loading } from 'components/styleguide';
import { useRootTranslation, useFetch } from 'utils/hooks';
import Form from 'components/common/Form';

import { NORMALIZED_PRODUCTION_TYPES } from 'utils/constants';
import { FormRadioGroup, FormButton } from 'components/common/Form/Fields/index';
import { getProductionCurrenciesCall } from 'redux-core/productions/services';
import enhancer from './enhancer';

const DisplayCurrenciesDrawer = ({
  name,
  onClose,
  payload: { masterCurrencyId, selectedCurrencyId },
  updateReleaseOrEpisode,
  updateCampaign,
  productionId,
  params: { type },
}) => {
  const t = useRootTranslation('drawers.productionSetup.currency');
  const [currencies, loading] = useFetch(() => getProductionCurrenciesCall(productionId), [productionId]);

  const handleSubmit = async ({ selectedCurrencyId }) => {
    const payload = {
      id: productionId,
      selectedCurrencyId,
    };
    if (type === NORMALIZED_PRODUCTION_TYPES.MKT) {
      await updateCampaign(payload, { patch: true, refetch: true });
    } else {
      await updateReleaseOrEpisode(payload, {
        type,
        patch: true,
        refetch: true,
      });
    }
    onClose();
  };

  return (
    <Form
      drawerName={name}
      onSubmit={handleSubmit}
      enableReinitialize
      initialValues={{
        selectedCurrencyId: selectedCurrencyId || masterCurrencyId,
      }}
    >
      <DrawerHeader title={t('drawerTitle')} onClose={onClose}>
        <FormButton
          testId="display-currencies-drawer-cancel"
          alwaysEnabled
          variant="destructive"
          size="xs"
          children="global.forms.cancel"
          onClick={onClose}
        />
        <FormButton
          testId="display-currencies-drawer-save"
          children="global.forms.save"
          size="xs"
          type="submit"
          variant="primary"
        />
      </DrawerHeader>
      <Grid container>
        <Row spacing={2}>
          <Grid item xs={6}>
            <Text>{t('table.header.currency')}</Text>
          </Grid>
          <Grid item xs={6}>
            <Text>{t('table.header.exchangeRate')}</Text>
          </Grid>
        </Row>
        {loading ? (
          <Loading />
        ) : (
          <FormRadioGroup
            name="selectedCurrencyId"
            testId="display-currencies-radio-group"
            options={currencies}
            customComponent={(currency) => (
              <Row mb={0} width={1}>
                <Grid item xs={6}>
                  <Text>{currency.code}</Text>
                </Grid>
                <Grid item xs={3}>
                  <Text>{currency.exchangeRate || '--'}</Text>
                </Grid>
              </Row>
            )}
          />
        )}
      </Grid>
    </Form>
  );
};

DisplayCurrenciesDrawer.displayName = 'DisplayCurrenciesDrawer';

DisplayCurrenciesDrawer.propTypes = {
  name: PropTypes.string,
  productionId: PropTypes.number.isRequired,
  updateReleaseOrEpisode: PropTypes.func.isRequired,
  updateCampaign: PropTypes.func.isRequired,
  params: PropTypes.shape({}),
  onClose: PropTypes.func.isRequired,
  payload: PropTypes.shape({
    values: PropTypes.array,
    masterCurrencyId: PropTypes.number.isRequired,
    selectedCurrencyId: PropTypes.number.isRequired,
  }),
};

export default enhancer(DisplayCurrenciesDrawer);
