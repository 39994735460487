/* eslint-disable react/no-children-prop */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import { Text } from 'components/styleguide';
import classnames from 'classnames';
import useStyles from './styles';

const Template = ({ id, name, onSelect, selected }) => {
  const classes = useStyles();

  return (
    <div
      className={classnames(classes.root, { selected })}
      onClick={() => onSelect(selected ? undefined : { id, name })}
      data-testid="cuesheet-template"
    >
      <Text children={name} noWrap />
    </div>
  );
};

Template.propTypes = {
  id: PropTypes.number,
  name: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  selected: PropTypes.bool,
};

export default Template;
