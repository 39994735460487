/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-cycle */
import React from 'react';
import Box from '@material-ui/core/Box';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import MUIListItem from '@mui/material/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

import { Text, TypeIcon } from 'components/styleguide';
import is from 'ramda/src/is';
import head from 'ramda/src/head';
import last from 'ramda/src/last';
import nth from 'ramda/src/nth';
import prop from 'ramda/src/prop';
import dropLast from 'ramda/src/dropLast';
import { concatObjects, concatStrings } from 'utils';
import {
  SEARCH_NORMALIZED_PRODUCTION_TYPES_TO_NORMALIZED_PRODUCTION_TYPES,
  NORMALIZED_PRODUCTION_TYPES,
} from 'utils/constants';

import PropTypes from 'prop-types';

import ImageUploader from 'components/common/ImageUploader';
import classnames from 'classnames';
import { ClearanceStatusLabel } from 'components/styleguide/ClearanceStatusLabel';
import enhancer from './enhancer';
import withStyles from './styles';

const ListItem = ({ classes, navigate, hasImage, hideStatus, row, testId, onDelete }) => {
  const { path, divisionName, tenantName, trackOnly } = row;
  const isTrack = Boolean(prop('qclearTrackId', row));
  const isDeletable = is(Function, onDelete);
  const project = head(path);
  const production = last(path);
  const projectImageId = prop('posterId', project);
  const productionImageId = prop('posterId', production);
  const productionName = prop('name', production);
  const episodeNumber = prop('number', production);
  const trackName = prop('name', row) || prop('title', row);
  const type =
    SEARCH_NORMALIZED_PRODUCTION_TYPES_TO_NORMALIZED_PRODUCTION_TYPES[
      // nth -2 takes the penultimate element in row.path
      trackOnly ? prop('type', nth(-2, row.path)) : prop('type', production)
    ];
  const isTvProduction = type === NORMALIZED_PRODUCTION_TYPES.TV && !trackOnly;

  const clearanceStatus = isTrack || trackOnly ? prop('qclearTrackClearanceStatusId', row) : production.clearanceStatus;

  const productionTitle = concatStrings(' - ')(isTvProduction && episodeNumber, productionName);
  const breadcrumb = `${tenantName} > ${divisionName}`;
  const trackPath = concatObjects(' - ')(path);
  const projectPath = concatObjects(' - ')(dropLast(1)(path));

  const handleClick = () =>
    navigate({
      ...row,
      id: production.id,
      divisionId: production.divisionId,
      projectId: project.id,
      objectId: production.objectId,
      tenantId: production.tenantId,
      type,
    });

  return (
    <MUIListItem
      className={classes.container}
      onClick={handleClick}
      data-testid={concatStrings('-')('list-item', testId)}
    >
      {hasImage && (
        <Box my={1}>
          <ListItemAvatar>
            <ImageUploader
              posterSize={{ height: 69, width: 44 }}
              type={type}
              uploadable={false}
              value={productionImageId || projectImageId}
            />
          </ListItemAvatar>
        </Box>
      )}
      <ListItemText
        primary={
          <span className={classes.breadcrumb}>
            <TypeIcon type={type} />
            <Text color="textPrimary" display="block" noWrap variant="body2">
              {trackOnly ? projectPath : breadcrumb}
            </Text>
          </span>
        }
        secondary={
          <>
            <div
              className={classnames({
                [classes.titleWithClearance]: !isDeletable && clearanceStatus,
              })}
            >
              <Text
                color={trackOnly ? 'primary' : 'textPrimary'}
                display="block"
                noWrap
                semibold={!isTvProduction && !isTrack}
                variant="body2"
              >
                {trackOnly ? trackName : isTrack ? trackPath : projectPath}
              </Text>
            </div>
            <Text color="textPrimary" noWrap semibold={isTvProduction || isTrack} variant="body2">
              {trackOnly ? concatStrings(', ')(production.artists) : isTrack ? trackName : productionTitle}
            </Text>
          </>
        }
      />
      {!hideStatus && clearanceStatus && (
        <Box paddingRight={3}>{clearanceStatus && <ClearanceStatusLabel clearanceStatus={clearanceStatus} />}</Box>
      )}
      {isDeletable && (
        <ListItemSecondaryAction>
          <IconButton edge="end" aria-label="delete" onClick={() => onDelete(row)}>
            <DeleteIcon />
          </IconButton>
        </ListItemSecondaryAction>
      )}
    </MUIListItem>
  );
};

ListItem.propTypes = {
  classes: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  hasImage: PropTypes.bool,
  hideStatus: PropTypes.bool,
  testId: PropTypes.string,
  row: PropTypes.shape({
    path: PropTypes.array,
    divisionName: PropTypes.string,
    tenantName: PropTypes.string,
  }).isRequired,
};

export default enhancer(withStyles(ListItem));
