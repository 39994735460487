import { connect } from 'react-redux';
import { getCanAccessDivisionSelector } from 'redux-core/divisions/selectors';
import { isAuthenticatedSelector } from 'redux-core/auth/selectors';

const mapStateToProps = (state, permissions) => ({
  isAllowed: getCanAccessDivisionSelector(permissions)(state),
  isAuthenticated: isAuthenticatedSelector(state),
});

export default connect(mapStateToProps);
