/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { BaseDrawer, Button, DrawerHeader } from 'components/styleguide';
import { Box, Divider, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import HoverWrapper from 'components/common/EditDeleteMenuWrapperGrid';
import always from 'ramda/src/always';
import remove from 'ramda/src/remove';
import { useDrawer } from 'utils/hooks';
import { updateArrayKey } from 'utils/immutable';
import { useSelector } from 'react-redux';
import enhancer from './enhancer';
// eslint-disable-next-line import/no-named-as-default
import DRAWERS from '../constants';

const rootT = 'drawers.productionSetup.productionCompanies';

const ProductionCompaniesDrawer = ({ onClose, closeDrawer, payload: { onSubmit, getCompaniesSelector, readOnly } }) => {
  const { t } = useTranslation();
  const openDrawer = useDrawer();
  const companies = useSelector((state) => {
    if (!getCompaniesSelector) return [];
    return getCompaniesSelector(state);
  });

  /**
   * onSubmit
   */
  const handleSubmit = async (company) => {
    const values = { productionEntities: [...companies, company] };
    await onSubmit(values);
    closeDrawer();
  };

  /**
   * onUpdate
   */
  const handleUpdate = (index) => async (company) => {
    const newCompanies = updateArrayKey(companies, index)(always(company));
    const values = {
      productionEntities: newCompanies,
    };
    await onSubmit(values);
    closeDrawer();
  };

  /**
   * onDelete
   */
  const handleDelete = (index) => async () => {
    const newCompanies = remove(index, 1)(companies);
    const values = {
      productionEntities: newCompanies,
    };
    await onSubmit(values);
  };

  return (
    <>
      <DrawerHeader onClose={onClose} title={t(`${rootT}.mainTitle`)}>
        {!readOnly && (
          <Button
            testId="production-companies-drawer-add"
            alwaysEnabled
            variant="primary"
            size="xs"
            // eslint-disable-next-line react/no-children-prop
            children={t('global.forms.add')}
            type="button"
            onClick={() =>
              openDrawer(DRAWERS.PRODUCTION_SETUP_COMPANY, {
                add: true,
                onSubmit: handleSubmit,
              })
            }
          />
        )}
      </DrawerHeader>
      <Box ml={0.5} mt={2}>
        {companies &&
          companies.map((company, index) => (
            <Box key={company.id || index}>
              <HoverWrapper
                disableHover={readOnly}
                setEditable={() =>
                  openDrawer(DRAWERS.PRODUCTION_SETUP_COMPANY, {
                    edit: true,
                    onSubmit: handleUpdate(index),
                    ...company,
                  })
                }
                onCancel={(e) => e.stopPropagation()}
                onConfirm={handleDelete(index)}
                promptContent={t(`${rootT}.delete`, company)}
              >
                <Typography variant="body2">{company.companyName}</Typography>
              </HoverWrapper>
              <Divider />
            </Box>
          ))}
      </Box>
    </>
  );
};

const Drawer = ({ open, onClose, ...props }) => (
  <BaseDrawer open={open} onClose={onClose}>
    <ProductionCompaniesDrawer onClose={onClose} {...props} />
  </BaseDrawer>
);

export default enhancer(Drawer);
