import { connect } from 'react-redux';
import withBaseDrawer from 'components/common/Drawers/withBaseDrawer';
import compose from 'ramda/src/compose';
import { deleteTask } from 'redux-core/tasks/actions';

const mapDispatchToProps = {
  deleteTask,
};

export default compose(connect(null, mapDispatchToProps), withBaseDrawer);
