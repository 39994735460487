/* eslint-disable react/no-children-prop */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-shadow */
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { get } from 'utils/object';
import { InputFormat, Text } from 'components/styleguide';
import { useNumberFormat } from 'utils/hooks';
import isNil from 'ramda/src/isNil';
import { withFormField } from 'components/common/Form/utils';
import { convertCurrency, convertCurrencyBack, formatCurrency } from 'utils/format';
import Box from '@material-ui/core/Box';
import { FieldPropTypes } from '../prop-types';

const FormInputCurrency = ({
  field: { value, ...field },
  form: { setFieldValue, touched, errors, isSubmitting },
  disabled,
  displayConversion,
  exchangeRate: rate,
  fullWidth,
  label,
  masterCurrencyCode,
  masterCurrencySymbol,
  onChange,
  placeholder,
  selectedCurrencySymbol,
  prefix,
  ...props
}) => {
  // Safe against null values
  const exchangeRate = rate || 1;
  const [thousand, decimal] = useNumberFormat();
  const { t } = useTranslation();
  const displayValue = convertCurrency(value, exchangeRate, props.decimalScale);

  const handleChange = (value) => {
    if (value === displayValue) return;
    const actualValue = convertCurrencyBack(value, exchangeRate, 8);
    if (onChange) onChange(actualValue);
    else setFieldValue(field.name, actualValue);
  };

  const inputProps = {
    disabled: disabled || isSubmitting,
    error: t(get(field.name)(touched) && get(field.name)(errors)),
    fullWidth,
    label: t(label),
    placeholder: isNil(placeholder) ? undefined : t(placeholder),
  };

  return (
    <>
      <InputFormat
        {...props}
        {...inputProps}
        {...field}
        type="currency"
        value={isNil(value) ? undefined : displayValue}
        separators={[thousand, decimal]}
        onChange={handleChange}
        prefix={prefix ?? (selectedCurrencySymbol || masterCurrencySymbol)}
      />
      {exchangeRate !== 1 && displayConversion && (
        <Box textAlign="right" pr={props.readOnly ? 0 : 2}>
          <Text
            children={formatCurrency(value, masterCurrencyCode, props.decimalScale)}
            color="darkGreen"
            noWrap
            variant="caption"
          />
        </Box>
      )}
    </>
  );
};

FormInputCurrency.propTypes = {
  decimalScale: PropTypes.number,
  disabled: FieldPropTypes.disabled,
  displayConversion: PropTypes.bool,
  exchangeRate: PropTypes.number,
  field: FieldPropTypes.field,
  form: FieldPropTypes.form,
  fullWidth: FieldPropTypes.fullWidth,
  label: FieldPropTypes.label,
  masterCurrencyCode: PropTypes.string.isRequired,
  masterCurrencySymbol: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  placeholder: FieldPropTypes.placeholder,
  readOnly: PropTypes.bool,
  prefix: PropTypes.string,
  selectedCurrencySymbol: PropTypes.string,
};

FormInputCurrency.defaultProps = {
  decimalScale: 2,
  displayConversion: true,
  exchangeRate: 1,
};

FormInputCurrency.displayName = 'FormInputCurrency';

export default withFormField(FormInputCurrency);
