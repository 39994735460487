import * as React from 'react';
import PropTypes from 'prop-types';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { Text } from 'components/styleguide';
import Table from 'components/common/Table';
import prop from 'ramda/src/prop';
import { useFormikContext } from 'formik';
import { useRootTranslation } from 'utils/hooks';
import { NORMALIZED_PRODUCTION_TYPES } from 'utils/constants';
import { appColors } from 'components/styleguide/theme';
import { getColumns, footerColumns } from './columns';
import { getCueRows } from './utils';
import CueInputFormat from './CueInputFormat';

const CuesTable = ({ productionType, cueContainer, track }) => {
  const { values } = useFormikContext();
  const t = useRootTranslation('drawers.clearance.addEdit');
  const cues = values.cues || getCueRows(values);

  if (cues.length === 0) {
    cues.push({
      cueContainer: {
        ...cueContainer,
        number: cueContainer?.currentCueContainerVersion?.version,
      },
      active: true,
      used: track.used,
    });
  }
  const activeCues = cues.filter(prop('active'));
  const { grandTotalFee } = values;

  const cueFooter = React.useCallback(
    () => (
      <>
        <TableRow>
          {footerColumns.map(({ id, ...column }) => (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <TableCell {...column} key={id} size="small">
              {column.renderValue ? column.renderValue(activeCues) : <Text noWrap>{column.label}</Text>}
            </TableCell>
          ))}
        </TableRow>
        <TableRow style={{ backgroundColor: appColors.lightGrey }}>
          <TableCell size="small" align="right" colSpan={4}>
            <Text bold>{t('clearanceGrandTotal')}</Text>
          </TableCell>
          <TableCell size="small" align="left">
            <CueInputFormat
              name="clearanceGrandTotal"
              readOnly
              short
              transparent
              type="currency"
              value={grandTotalFee}
              style={{ width: '15%' }}
            />
          </TableCell>
          <TableCell size="small" colSpan={2} />
        </TableRow>
      </>
    ),
    [activeCues, grandTotalFee, t],
  );

  const columns = getColumns({
    isMarketing: productionType === NORMALIZED_PRODUCTION_TYPES.MKT,
    t,
  });

  return <Table columns={columns} rows={activeCues} elevation={0} hoverable={false} footer={cueFooter} />;
};

CuesTable.propTypes = {
  productionType: PropTypes.string.isRequired,
};

export default CuesTable;
