import { withStyles } from '@material-ui/core';

const styles = () => ({
  container: {
    position: 'relative',
    width: '100%',
    height: 'initial',
  },
  search: {
    width: 700,
  },
});

export default withStyles(styles);
