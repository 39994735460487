import { Skeleton } from '@mui/material';
import {
  useCreateCueSheetStatusMutation,
  useGetCueSheetStatusListQuery,
  useMoveCueSheetStatusMutation,
  useUpdateCueSheetStatusMutation,
} from 'services/cue/cue-sheet-status';
import { CustomStatusItem } from './CustomStatusItem';
import { CustomStatusList } from './CustomStatusList';
import { TCustomStatus } from './types';

export type TCueSheetTabProps = {
  divisionId: number;
};

export const CueSheetTab = ({ divisionId }: TCueSheetTabProps) => {
  const { data: cueSheetStatusList, isLoading } = useGetCueSheetStatusListQuery({ divisionId });
  const [createCueSheetStatus] = useCreateCueSheetStatusMutation();
  const [updateCueSheetStatus] = useUpdateCueSheetStatusMutation();
  const [moveCueSheetStatus] = useMoveCueSheetStatusMutation();

  if (isLoading) return <Skeleton />;

  return (
    <CustomStatusList>
      {cueSheetStatusList
        .filter((s) => s.isParent)
        .map((status) => {
          const customStatus = {
            ...status,
            childrenStatuses: status.cueSheetStatuses as TCustomStatus[],
          };
          return (
            <CustomStatusItem
              key={status.id}
              divisionId={divisionId}
              status={customStatus}
              createCustomStatus={createCueSheetStatus}
              updateCustomStatus={updateCueSheetStatus}
              moveCustomStatus={moveCueSheetStatus}
            />
          );
        })}
    </CustomStatusList>
  );
};
