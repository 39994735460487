import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  dataWrapper: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    marginTop: theme.spacing(0.75),
  },
}));

export default useStyles;
