import { createActions } from 'redux-actions';
import identity from 'ramda/src/identity';

const actionsDefinition = {
  SAVE_FORM: identity,
  SAVE_VALUES: identity,
  UPDATE_IN: (path, value, formId) => ({ path, value, formId }),
  CLEAR_FORM: identity,
  CLEAR_FORMS: identity,
};

export const {
  saveForm,
  saveValues,
  /**
   * Imitating the shape of formik.setFieldValue
   * @param {string|string[]} path
   * @param {any} value
   * @param {string} formId - Optional
   * @example updateIn('my.field.path', newValue[, formId])
   * */
  updateIn,
  clearForm,
  clearForms,
} = createActions(actionsDefinition);
