/* eslint-disable no-unused-expressions */
/* eslint-disable react/jsx-props-no-spreading */
import { Grid, IconButton, Tooltip } from '@mui/material';
import { Delete, Edit, PowerSettingsNew } from '@material-ui/icons';
import { deleteCue, clearancesUpdateActive } from 'redux-core/clearances/services';
import { usePrompt, useDrawer, useRootTranslation } from 'utils/hooks';
import { requestWithError } from 'utils/request';
import { useDispatch } from 'react-redux';
// eslint-disable-next-line import/no-named-as-default
import DRAWERS from 'components/common/Drawers/constants';

const SummaryActionIconWrapper = ({ children, onClick, tooltip }) => (
  <Grid item xs={3}>
    <Tooltip title={tooltip} placement="top">
      <IconButton size="small" color="primary" onClick={onClick}>
        {children}
      </IconButton>
    </Tooltip>
  </Grid>
);

const DeleteIcon = ({ isOnlyOne }) => {
  if (isOnlyOne) return null;
  return <Delete />;
};

const getActiveInactiveTooltip = ({ clearance, t }) => (clearance.active ? t('makeInactive') : t('makeActive'));

const handleOnActiveInactiveClick =
  ({ reload, clearance }) =>
  async (e) => {
    e.stopPropagation();
    await requestWithError(clearancesUpdateActive, {
      id: clearance.id,
      active: !clearance.active,
    });
    reload();
  };

const handleOnClearanceDelete =
  ({ clearance, reload, openPrompt, t }) =>
  async (e) => {
    e.stopPropagation();
    openPrompt({
      content: t('deleteConfirmation', { name: clearance.name }),
      onConfirm: async () => {
        await requestWithError(
          deleteCue,
          { id: clearance.id },
          {
            message: t('deleted', { name: clearance.name }),
          },
        );
        reload();
      },
    });
  };

const handleOnEditClick =
  ({ clearance, divisionId, track, clearancesFetch, cueContainer, objectId, loading, openDrawer }) =>
  (e) => {
    e.stopPropagation();
    clearance.isOption
      ? openDrawer(DRAWERS.CLEARANCE_OPTION, {
          divisionId,
          option: clearance,
          trackId: track.id,
          clearancesFetch,
          cueContainer,
          objectId,
        })
      : openDrawer(DRAWERS.CLEARANCE_ADD_EDIT, {
          divisionId,
          clearance,
          track,
          clearancesFetch,
          cueContainer,
          loading,
          objectId,
        });
  };

const SummaryActionsEventsAndIcons = (props) => [
  {
    key: 'MakeInactiveButton_key_index',
    tooltip: getActiveInactiveTooltip(props),
    icon: <PowerSettingsNew />,
    onClick: handleOnActiveInactiveClick(props),
  },
  {
    key: 'DeleteClearanceButton_key_index',
    tooltip: props.t('deleteClearance'),
    icon: <DeleteIcon isOnlyOne={props.isOnlyOne} />,
    onClick: handleOnClearanceDelete(props),
  },
  {
    key: 'EditClearance_key_index',
    tooltip: props.t('editClearance'),
    icon: <Edit />,
    onClick: handleOnEditClick(props),
  },
];

export const SummaryActions = ({ hover, isAllowedToEdit, ...rest }) => {
  const openPrompt = usePrompt();
  const dispatch = useDispatch();
  const openDrawer = useDrawer();
  const t = useRootTranslation('drawers.clearance.fullList');

  if (!hover || !isAllowedToEdit) return null;

  return SummaryActionsEventsAndIcons({
    openPrompt,
    dispatch,
    openDrawer,
    t,
    ...rest,
  }).map((EventAndIcon) => {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const { icon, ...rest } = EventAndIcon;
    return <SummaryActionIconWrapper {...rest}>{icon}</SummaryActionIconWrapper>;
  });
};
