import * as React from 'react';
import TextField from '@mui/material/TextField';
import Box from '@mui/system/Box';
import { rootTranslation } from 'utils/format';

const tGlobal = rootTranslation('global.actions');

export function EditOptionInput({ option, persistValue }) {
  const [value, setValue] = React.useState(option.name);

  const handleBlur = React.useCallback(() => {
    if (value === option.name || value.trim().length === 0) {
      return;
    }
    persistValue(value);
  }, [option.name, persistValue, value]);

  return (
    <Box>
      <TextField
        autoFocus
        fullWidth
        value={value}
        onChange={(event) => {
          const term = event.target.value;
          setValue(term);
        }}
        onBlur={handleBlur}
        inputProps={{
          'aria-label': tGlobal('editOption'),
        }}
      />
    </Box>
  );
}
