import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import InfoIcon from '@mui/icons-material/Info';

export type TRequestActionMessageProps = {
  message: string;
};

export const ComposeLetterRequestActionMessage = ({ message }: TRequestActionMessageProps) => (
  <Box
    sx={{
      backgroundColor: '#eee',
      border: '1px solid #ddd',
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '24px',
        textAlign: 'center',
        maxWidth: '500px',
        width: '80%',
      }}
    >
      <InfoIcon sx={{ fontSize: 48, color: '#666' }} />
      <Typography variant="h5" sx={{ color: '#333', margin: '16px 0' }}>
        {message}
      </Typography>
    </Box>
  </Box>
);
