import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
  wrapper: {
    display: ({ ignoreContent }) => (ignoreContent ? 'contents' : 'flex'),
    position: 'relative',
    height: '100%',
  },
  dropzoneChildren: {
    display: ({ ignoreContent }) => (ignoreContent ? 'contents' : 'block'),
  },
  dropzoneWrapper: {
    position: ({ showChildrensAndDropzone }) => {
      if (showChildrensAndDropzone) return 'relative';
      return 'absolute';
    },
    zIndex: 1000,
    top: 0,
    left: 0,
    height: ({ isDragOver, fullHeight }) => {
      if (isDragOver) {
        if (fullHeight) return '100%';
        return '70vh'; // Min height when parent doesn't have enough height
      }
      return '100%';
    },
  },
  dropzoneContainer: {
    cursor: 'pointer',
    borderRadius: 3,
    height: '100%',
    maxHeight: '100%',
    alignContent: 'center',
    border: `dashed 3px ${theme.palette.info.main}`,
    backgroundColor: theme.palette.grey[200],
  },
  dropzoneThinContainer: {
    border: `dashed 1px ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
  },
  rounded: {
    borderRadius: 10,
  },
  uploadIconContainer: {
    marginBottom: theme.spacing(1.25),
  },
}));

export default styles;
