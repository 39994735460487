/* eslint-disable react/no-children-prop */
import { Grid, IconButton } from '@material-ui/core';
import { Row } from 'components/styleguide';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import { FieldArray, useFormikContext } from 'formik';
import { FormButton, FormDropdown, FormInputText, StateField, FormTypeAhead } from 'components/common/Form/Fields';
import { PHONE_TYPES_OPTIONS } from 'utils/constants';
import { useRootTranslation } from 'utils/hooks';
import PropTypes from 'prop-types';
import SubtitleWithLine from 'components/common/SubtitleWithLine';

const Communication = ({ isEditing, readOnly, getDefaultPhone, countries = [] }) => {
  const t = useRootTranslation('drawers.contacts');
  const tGlobal = useRootTranslation('global');
  const { values, validateForm } = useFormikContext();
  return (
    <>
      <Row spacing={1} mb={3}>
        <SubtitleWithLine children={t('form.communication')} variant="caption" />
      </Row>

      <Row spacing={1}>
        <Grid item xs={6}>
          <FormInputText name="email" label={t('form.email')} testId="contacts-drawer-email" readOnly={readOnly} />
        </Grid>
      </Row>

      <FieldArray name="additionalData.phones">
        {(arrayHelpers) => (
          <>
            {values.additionalData.phones.map((phone, index, phones) => (
              <Row spacing={1} alignItems="flex-end" key={phone.generatedId}>
                <Grid item xs={2}>
                  <FormDropdown
                    label={t('form.type')}
                    name={`additionalData.phones.${index}.type`}
                    options={PHONE_TYPES_OPTIONS}
                    labelKey="name"
                    testId="contact-drawer-phone-type-dropdown"
                    readOnly={readOnly}
                  />
                </Grid>
                <Grid item xs={3}>
                  <FormInputText
                    readOnly={readOnly}
                    label={t('form.number')}
                    name={`additionalData.phones.${index}.number`}
                    testId="contacts-drawer-phone-type-number"
                  />
                </Grid>
                {isEditing && (
                  <>
                    {phones.length > 1 && (
                      <Grid item>
                        <IconButton size="small" color="secondary">
                          <CloseIcon
                            onClick={() => {
                              arrayHelpers.remove(index);
                              /* @TODO adding setTimeout per https://github.com/jaredpalmer/formik/issues/784 remove it later */
                              setTimeout(() => {
                                validateForm();
                              }, 200);
                            }}
                          />
                        </IconButton>
                      </Grid>
                    )}
                    {index === phones.length - 1 && (
                      <Grid item>
                        <FormButton
                          alwaysEnabled
                          variant="secondary"
                          testId="contacts-drawer-phone-type-add"
                          size="xs"
                          children="global.forms.add"
                          type="button"
                          onClick={() => arrayHelpers.insert(phones.length, getDefaultPhone())}
                        />
                      </Grid>
                    )}
                  </>
                )}
              </Row>
            ))}
          </>
        )}
      </FieldArray>

      <Row spacing={1}>
        <Grid item xs={6}>
          <FormInputText
            name="assistantFirstName"
            label={t('form.assistantFirstName')}
            testId="contacts-drawer-assistantFirstName"
            readOnly={readOnly}
          />
        </Grid>

        <Grid item xs={6}>
          <FormInputText
            name="assistantLastName"
            label={t('form.assistantLastName')}
            testId="contacts-drawer-assistantLastName"
            readOnly={readOnly}
          />
        </Grid>
      </Row>

      <Row spacing={1}>
        <Grid item xs={12}>
          <FormInputText
            name="address1"
            label={t('form.addressLine1')}
            testId="contacts-drawer-addressLine1"
            readOnly={readOnly}
          />
        </Grid>
      </Row>

      <Row spacing={1}>
        <Grid item xs={12}>
          <FormInputText
            name="address2"
            label={t('form.addressLine2')}
            testId="contacts-drawer-addressLine2"
            readOnly={readOnly}
          />
        </Grid>
      </Row>

      <Row spacing={1} alignItems="flex-end">
        <Grid item xs={4}>
          <FormInputText name="city" label={t('form.city')} testId="contacts-drawer-city" readOnly={readOnly} />
        </Grid>

        <Grid item xs={2}>
          <StateField
            label={tGlobal('forms.state')}
            countries={countries}
            testId="contacts-drawer-state"
            readOnly={readOnly}
          />
        </Grid>

        <Grid item xs={2}>
          <FormInputText
            name="postalCode"
            label={t('form.zipCode')}
            testId="contacts-drawer-zipCode"
            readOnly={readOnly}
          />
        </Grid>

        <Grid item xs={4}>
          <FormTypeAhead
            isClearable={false}
            label={tGlobal('forms.country')}
            name="countryName"
            options={countries}
            valueKey="name"
            labelKey="name"
            testId="contacts-drawer-country"
            readOnly={readOnly}
          />
        </Grid>
      </Row>
    </>
  );
};

Communication.propTypes = {
  isEditing: PropTypes.bool.isRequired,
  readOnly: PropTypes.bool,
  getDefaultPhone: PropTypes.func.isRequired,
  countries: PropTypes.array,
};

export default Communication;
