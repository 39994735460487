/* eslint-disable import/no-cycle */
import makeEntityServiceCalls, { makeServiceCall } from 'redux-core/serviceHelpers';

export const {
  getAll: getAllRightsOwnersCall,
  get: getRightsOwnersByIdCall,
  create: createRightsOwners,
  update: updateRightsOwners,
  delete: deleteRightsOwners,
} = makeEntityServiceCalls('rights-owners', 'clear');

export const getSearchRightsOwnersCall = makeServiceCall('rights-owners', 'search', 'clear');

export const getTrackRightsOwnersCall = makeServiceCall('rights-owners', 'get-all', 'clear');

export const bulkUpdateRightsOwnersCall = makeServiceCall('rights-owners', 'bulk-update', 'clear');
