import { styled } from '@mui/material';

export const CustomStatusListItem = styled('li')(
  ({ canHaveChildren, hasParent }: { canHaveChildren?: boolean; hasParent?: boolean }) => ({
    borderTop: '1px solid lightgray',
    borderBottom: '1px solid lightgray',
    marginTop: '-1px',
    padding: '1.5em 1em',
    paddingBottom: canHaveChildren ? '0' : '1.5em',
    '&:last-child': {
      paddingBottom: !hasParent ? 'auto' : '0',
      borderBottom: !hasParent ? 'auto' : 'none',
    },
    '&:first-child': {
      marginTop: hasParent ? '1.5em' : '0',
    },
  }),
);
