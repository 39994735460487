import { useWatch } from 'react-hook-form';
import { Box } from '@mui/system';
import { TextField } from '@mui/material';
import { FormField } from 'components/common/Form/Fields/FormField/FormField';
import { rootTranslation } from 'utils/format';
import { SceneField } from '../fields/SceneField';
import { TrackTypeSelect } from '../fields/TrackTypeSelect';
import { LibraryField } from '../fields/LibraryField';
import { ArtistField } from '../fields/ArtistField';

const t = rootTranslation('drawers.productionSetup.tracks.filter');

export function CreateFormPanel({ useCueContainerPickerTreeView }: { useCueContainerPickerTreeView: boolean }) {
  const type = useWatch({ name: 'type' });

  return (
    <Box
      sx={{
        backgroundColor: 'primary.300',
        display: 'grid',
        gridTemplateColumns: 'repeat(4, 1fr)',
        gap: 2,
        p: 2,
        borderRadius: '4px',
      }}
    >
      <SceneField useCueContainerPickerTreeView={useCueContainerPickerTreeView} />
      <TrackTypeSelect />
      {type === 'library' && <LibraryField />}
      <FormField
        required
        label={t('title')}
        name="title"
        render={({ field, muiprops }) => (
          <TextField {...field} {...muiprops} placeholder={t('trackTitlePlaceholder')} />
        )}
      />
      {type === 'song' && <ArtistField />}
    </Box>
  );
}
