import { makeStyles } from '@material-ui/core';
import { getSplitRadius } from 'components/styleguide/theme';

export default makeStyles(() => ({
  root: () => ({
    '&, & > fieldset': {
      borderRadius: ({ split }) => getSplitRadius({ split }),
    },
    '& > input': {
      border: ({ error }) => error ? '1px solid #C83C44' : 'none',
    },
  }),
  input: ({ textAlign }) => ({
    textAlign,
  }),
}));
