/* eslint-disable no-console */
import React, { Component } from 'react';
import i18next from 'i18next';
import './App.css';
import { ReactComponent as Logo } from 'assets/qwire-logo-light-bg.svg';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

const styles = (theme) => ({
  logo: {
    width: '187px',
    height: '57px',
    border: 'solid 1px #000000',
    backgroundColor: '#ffffff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 20px',
  },
  root: {
    height: '100%',
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  content: {
    margin: '20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    if (process.env.NODE_ENV !== 'production') {
      console.log(error);
    }
    return { hasError: true };
  }

  render() {
    const { classes } = this.props;
    // eslint-disable-next-line react/destructuring-assignment
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className={classes.root}>
          <div className={classes.content}>
            <div className={classes.logo}>
              <Logo />
            </div>
            <div>{i18next.t('global.genericError')}</div>
          </div>
        </div>
      );
    }

    // eslint-disable-next-line react/destructuring-assignment
    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
};

export default compose(withStyles(styles))(ErrorBoundary);
