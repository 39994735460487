import { withStyles } from '@material-ui/core';
import { appColors } from 'components/styleguide';

const styles = {
  greyLine: {
    flexGrow: '1',
    backgroundColor: '#bfbab8',
    height: '1px',
    alignSelf: 'center',
    margin: '0 10px',
  },
  text: { color: appColors.grey },
};

export default withStyles(styles);
