import { baseApi } from 'services';

export type TEpisode = {
  id: number;
  name: string;
};

type TGetAllPayload = {
  archived?: number;
  id?: number;
  projectId?: number;
  parentId?: number;
  type?: string;
  divisionId: number;
};

export type TSearchEpisoesAndScenesRequestDto = {
  seasonReleaseId: number;
  term?: string;
};

export type TSearchEpisodesAndScenesResponseSceneDto = {
  sceneId: number;
  sceneTitle: string;
};

export type TSearchEpisodesAndScenesResponseDto = {
  episodeId: number;
  episodeName: string;
  scenes: TSearchEpisodesAndScenesResponseSceneDto[];
};

export const seasonReleasesApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getEpisodes: builder.query<TEpisode[], TGetAllPayload>({
      query: (body) => ({
        url: 'common/episodes/get-all',
        method: 'POST',
        body,
      }),
    }),
    searchEpisodesAndScenes: builder.query<TSearchEpisodesAndScenesResponseDto[], TSearchEpisoesAndScenesRequestDto>({
      query: (body) => ({
        url: 'common/episodes/search',
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const { useGetEpisodesQuery, useSearchEpisodesAndScenesQuery } = seasonReleasesApi;
