/* eslint-disable consistent-return */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { FormInputText, FormDebouncedTypeAhead } from '../index';

const FormDebouncedTypeAheadWithInput = ({ onCreate, defaultDisplay, ...props }) => {
  /**
   * onCreate
   */
  const [shouldReturnInput, setShouldReturnInput] = useState(defaultDisplay === 'input');
  useEffect(() => {
    setShouldReturnInput(defaultDisplay === 'input');
  }, [defaultDisplay]);
  const handleCreate = (value, form) => {
    if (!onCreate) return;

    if (props.isMultiple) {
      return onCreate(value);
    }

    setShouldReturnInput(true);
    // eslint-disable-next-line no-unused-expressions
    props.name && onCreate && onCreate(value, form);
  };

  const refreshState = useCallback(() => {
    setShouldReturnInput(false);
  }, [setShouldReturnInput]);

  if (shouldReturnInput && onCreate) {
    const { forwardedRef, indicatorTabIndex } = props;

    return (
      <FormInputText
        {...props}
        inputRef={forwardedRef}
        endAdornment={
          <IconButton size="small" tabIndex={indicatorTabIndex}>
            <CloseIcon size={16} onClick={refreshState} />
          </IconButton>
        }
      />
    );
  }

  return <FormDebouncedTypeAhead {...props} onCreate={onCreate ? handleCreate : undefined} />;
};

FormDebouncedTypeAheadWithInput.propTypes = {
  name: PropTypes.string.isRequired,
  call: PropTypes.func.isRequired,
  defaultDisplay: PropTypes.oneOf(['input', 'typeahead']),
  onCreate: PropTypes.func,
  onChange: PropTypes.func,
  isMultiple: PropTypes.bool,
  defaultInput: PropTypes.any,
  noResultsText: PropTypes.string.isRequired,
  forwardedRef: PropTypes.oneOf([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })]),
  indicatorTabIndex: PropTypes.number,
};

FormDebouncedTypeAheadWithInput.displayName = 'FormDebouncedTypeAheadWithInput';

export default FormDebouncedTypeAheadWithInput;
