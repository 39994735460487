import { Provider, useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useAtomCallback } from 'jotai/utils';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import ROUTES from 'components/Routes/routes';
import { BaseDrawer, Button, DrawerHeader } from 'components/styleguide';
import { find } from 'lodash';
import PropTypes from 'prop-types';
import { pick, pipe } from 'ramda';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { COMMON_PERMISSIONS } from 'redux-core/permissions/constants';
import { exportCueSheetSegments } from 'redux-core/productions/actions';
import { getAllSeasons, getProjectById } from 'redux-core/productions/services';
import { goToLink } from 'redux-core/router/actions';
import { PRODUCTION_TYPES, NORMALIZED_PRODUCTION_TYPES, PRODUCTION_TYPES_OPTIONS } from 'utils/constants';
import { useFetch, useSecuredDivisions } from 'utils/hooks';
import { capitalize } from 'utils/object';
import { FormControlLabelWrapper } from 'components/common/Form/Fields/FormField/FormField';
import { MenuItem, Select } from '@mui/material';
import { HydrateAtoms } from 'atomUtils';

import Features from 'assets/features.svg';
import Campaign from 'assets/marketing.svg';
import Tv from 'assets/tv.svg';
import DrawerFormContentGrid from '../DrawerFormContentGrid';
import { ChooseCueSheet } from './ChooseCueSheet';
import { DrawerPrimaryButton } from './DrawerPrimaryButton';
import enhancer from './enhancer';
import { useCurrency, useValidationSchema } from './hooks';
import ProductionDrawerChild from './ProductionDrawerChild';
import { ShowWhereToGoOptions } from './ShowWhereToGoOptions';
import {
  buttonEnabledAtom,
  cueSheetAndParentProductionAtom,
  DrawerSteps,
  existingProjectAtom,
  formStepAtom,
  initialValuesAtom,
  projectNameAtom,
  submittingFormAtom,
} from './drawerStore';
import { ProjectNameInput } from './ProjectNameInput';
import { releaseIdAtom, seasonAtom } from './SeasonDetails/seasonStore';
import { parseFormOutput } from './parseFormOutput';

const QuickSetupProductionDrawer = ({
  onClose,
  quickCreateProject,
  savePosterId,
  keepSetupInfo,
  rights,
  cleanSetupFlow,
  payload,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const divisions = useSecuredDivisions({
    commonPermissions: COMMON_PERMISSIONS.CREATE,
  });
  const formRef = useRef();

  const readCueSheetAndParent = useAtomCallback(useCallback((get) => get(cueSheetAndParentProductionAtom), []));
  const [initialValues, setInitialValues] = useAtom(initialValuesAtom);
  const [formStep, setFormStep] = useAtom(formStepAtom);
  const setButtonEnabled = useSetAtom(buttonEnabledAtom);
  const existingProject = useAtomValue(existingProjectAtom);
  const setProjectName = useSetAtom(projectNameAtom);

  const defaultDivision = payload.division || find(divisions, (d) => d.id === payload.divisionId) || divisions[0];
  const { selectedSegments, showWhereToGoOptions } = payload;

  const [nextOptions, setNextOptions] = useState({
    isNewProduction: true,
    goBack: onClose,
    goToProduction: null,
  });
  const [division, setDivision] = useState(defaultDivision);
  const [productionType, setProductionType] = useState(null);
  const [initArguments, setInitArguments] = useState({});

  const productionExists = typeof initialValues?.project?.project === 'number';

  const drawerTitle = (
    <a
      href="https://www.loom.com/share/7d14910176964033924360f441592bb0?sid=3f54a03a-8e1a-45bf-acc8-ea41ffd9dab2"
      target="_blank"
      style={{ fontSize: 14, color: '#0A91E3' }}
      rel="noreferrer"
    >
      {
        payload.drawerHowCreateProjectTitle ||
        t('drawers.quickSetupProduction.drawerHowCreateProjectTitle')
      }
    </a>
  );

  const validationSchema = useValidationSchema(productionType);
  const [currencies, masterCurrencyId] = useCurrency();

  useEffect(() => {
    if (!payload.production) {
      setProjectName('');
    }
    if (!division) {
      cleanSetupFlow();
      return;
    }

    const values = pipe((d) => ({ ...d, divisionId: d.id }), pick(['tenantId', 'divisionId']))(division);
    keepSetupInfo(division.id, null, division.tenantId);
    setInitArguments(values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [division]);

  const handleDivisionSelect = (divisionId) => {
    setProjectName('');
    cleanSetupFlow();
    setDivision(divisions.find(({ id }) => id === divisionId));
    setButtonEnabled(false);
  };

  const handleProductionTypeSelect = (productionTypeId) => {
    setFormStep(DrawerSteps.SelectProductionType);
    setProjectName('');
    setInitialValues({ ...initialValues, project: undefined });
    cleanSetupFlow();
    setProductionType(productionTypeId);
    setButtonEnabled(false);
  };

  const divisionId = division?.id ?? '';

  const [productionSeasons] = useFetch(async () => {
    if (division && productionExists) {
      const { description } = await getProjectById({
        id: initialValues.project.project,
        tenantId: initArguments.tenantId,
        divisionId: division.Id,
      });
      setInitArguments((state) => ({ ...state, synopsis: description }));
      keepSetupInfo(division.id, initialValues.project.project, division.tenantId);
    } else {
      setInitArguments((state) => ({ ...state, synopsis: '' }));
    }
    return (
      division &&
      productionType === NORMALIZED_PRODUCTION_TYPES.TV &&
      productionExists &&
      getAllSeasons({
        projectId: initialValues?.project?.project,
        type: capitalize(PRODUCTION_TYPES.SEASON),
      })
    );
  }, [division, initialValues]);

  const readOnlyCurrencyPicker = productionType === NORMALIZED_PRODUCTION_TYPES.TV && productionExists;

  const setSubmittingForm = useSetAtom(submittingFormAtom);
  const handleSubmit = async (values) => {
    setSubmittingForm(true);
    const sanitizedValues = parseFormOutput(values);
    const goToProduction = await quickCreateProject({
      ...sanitizedValues,
      exportedSegments: selectedSegments,
      showWhereToGoOptions,
      watchProduction: values.watchProduction === 'true',
    });
    if (showWhereToGoOptions && goToProduction) {
      setNextOptions({ ...nextOptions, goToProduction });
      setFormStep(DrawerSteps.SelectWhereToGo);
    }
    setSubmittingForm(false);
  };

  const exportSegments = async () => {
    const cueSheetAndParentProduction = await readCueSheetAndParent();
    await exportCueSheetSegments(cueSheetAndParentProduction.cueSheetId, selectedSegments);
    const goToProduction = async () => {
      dispatch(
        goToLink(ROUTES.PRODUCTION.CUE_SHEET, {
          id: cueSheetAndParentProduction.parentProduction,
          divisionId,
          projectId: existingProject.id,
          type: productionType,
        }),
      );
      return onClose();
    };
    setNextOptions({ ...nextOptions, isNewProduction: false, goToProduction });
    setFormStep(DrawerSteps.SelectWhereToGo);
  };

  return (
    <>
      {formStep !== DrawerSteps.SelectWhereToGo && (
        <>
          <DrawerHeader title={drawerTitle} onClose={onClose} hideBackButton>
            <Button variant="destructive" size="md" onClick={onClose} type="button">
              {t('global.forms.close')}
            </Button>
            <DrawerPrimaryButton
              selectedSegments={selectedSegments}
              exportSegments={exportSegments}
              divisionId={divisionId}
              productionType={productionType}
              production={initialValues?.project?.project}
              formRef={formRef}
            />
          </DrawerHeader>
          <DrawerFormContentGrid container spacing={2}>
            <Grid item xs={12}>
              <FormControlLabelWrapper name="division" label={t('projects.newProduction.divisionTitle')}>
                <Select
                  name="division"
                  data-testid="typeahead-field-division"
                  value={divisionId}
                  onChange={(event) => handleDivisionSelect(event.target.value)}
                >
                  {divisions.map((option) => (
                    <MenuItem key={option.name} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
                {
                  formStep === DrawerSteps.SelectProductionType && (
                    <ul className="ul-quick-setup-production">
                      <li>{t('projects.newProduction.divisionDescription.belongMoreThanOne')}</li>
                      <li>{t('projects.newProduction.divisionDescription.belongOnlyOne')}</li>
                    </ul>
                  )
                }
              </FormControlLabelWrapper>
            </Grid>
            <Grid item xs={12}>
              <FormControlLabelWrapper
                name="division"
                label={
                  t('projects.newProduction.productionType')}
              >
                <Select
                  name="productionType"
                  data-testid="typeahead-field-productionType"
                  value={productionType}
                  onChange={(event) => handleProductionTypeSelect(event.target.value)}
                >
                  {PRODUCTION_TYPES_OPTIONS.map((option) => (
                    <MenuItem key={option.name} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControlLabelWrapper>
              {
                (formStep === DrawerSteps.SelectProductionType) && (
                  <>
                    <div className="div-quick-setup-production-description">
                      <span>
                        <b>Features</b> - {t('projects.newProduction.productionDescription.features')}
                      </span><br />
                      <span>
                        <b>Marketing</b> - {t('projects.newProduction.productionDescription.marketing')}
                      </span><br />
                      <span>
                        <b>TV</b> - {t('projects.newProduction.productionDescription.tv')}
                      </span><br />
                    </div>
                    <div
                      style={{ padding: 15, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
                    >
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <span style={{ color: '#818181' }}><b>Feature</b></span>
                        <img src={Features} alt="features" />
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <span style={{ color: '#818181' }}><b>Marketing</b></span>
                        <img src={Campaign} alt="compaign" />
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <span style={{ color: '#818181' }}><b>TV</b></span>
                        <img src={Tv} alt="TV" />
                      </div>
                    </div>
                  </>
                )
              }
            </Grid>
            <Grid item xs={12}>
              {formStep !== DrawerSteps.SelectProductionType && (
                <>
                  <ProjectNameInput
                    masterCurrencyId={masterCurrencyId}
                    division={division}
                    initArguments={initArguments}
                    productionType={productionType}
                    productionSeasons={productionSeasons}
                    selectedSegments={payload.selectedSegments}
                  />
                  {formStep !== DrawerSteps.InputProductionDetails && (
                    <ul className="ul-quick-setup-production">
                      <li>{t('projects.newProduction.projectDescription.createNewProject')}</li>
                      <li>{t('projects.newProduction.projectDescription.addAdditionalProduction')}</li>
                      <li>{t('projects.newProduction.projectDescription.viewOrEditProject')}</li>
                    </ul>
                  )}
                </>
              )}
            </Grid>
            <Grid item xs={12}>
              {formStep === DrawerSteps.InputProductionDetails && (
                <ProductionDrawerChild
                  formRef={formRef}
                  validationSchema={validationSchema}
                  divisionId={divisionId}
                  productionType={productionType}
                  rights={rights}
                  currencies={currencies}
                  productionSeasons={productionSeasons}
                  readOnlyCurrencyPicker={readOnlyCurrencyPicker}
                  handleSubmit={handleSubmit}
                  savePosterId={savePosterId}
                  initialValues={initialValues}
                  showComponent
                />
              )}
            </Grid>
            <Grid item xs={12}>
              {formStep === DrawerSteps.ChooseCueSheet && (
                <ChooseCueSheet division={division} productionType={productionType} project={existingProject} />
              )}
            </Grid>
          </DrawerFormContentGrid>
        </>
      )}
      {formStep === DrawerSteps.SelectWhereToGo && (
        <ShowWhereToGoOptions
          isNewProduction={nextOptions.isNewProduction}
          goToProduction={nextOptions.goToProduction}
          goBack={nextOptions.goBack}
        />
      )}
    </>
  );
};

const Drawer = ({ open, onClose, payload, ...props }) => (
  <BaseDrawer open={open} onClose={onClose}>
    <Provider>
      <HydrateAtoms
        initialValues={[
          [cueSheetAndParentProductionAtom, { parentProduction: null, cueSheetId: null }],
          [projectNameAtom, payload.production || ''],
          [buttonEnabledAtom, !!payload.buttonEnabled],
          [submittingFormAtom, false],
          [formStepAtom, DrawerSteps.SelectProductionType],
          [existingProjectAtom, null],
          [seasonAtom, null],
          [releaseIdAtom, null],
        ]}
      >
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <QuickSetupProductionDrawer onClose={onClose} {...props} payload={payload} />
      </HydrateAtoms>
    </Provider>
  </BaseDrawer>
);

QuickSetupProductionDrawer.propTypes = {
  onClose: PropTypes.func,
  quickCreateProject: PropTypes.func,
  keepSetupInfo: PropTypes.func,
  rights: PropTypes.object,
  cleanSetupFlow: PropTypes.func,
  payload: PropTypes.object,
};

Drawer.propTypes = {
  open: PropTypes.func,
  onClose: PropTypes.func,
};

export default enhancer(Drawer);
