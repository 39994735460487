import { baseApi } from 'services';
import { TLetterType, TLicensorContact } from './types';
import { TClearanceStatus } from './clearance-status';

export type TLetterContact = {
  id?: number;
  firstName: string;
  lastName: string;
  email: string;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  state?: string | null;
  postalCode?: string | null;
  countryName?: string | null;
  licensorName?: string | null;
  usedLast?: boolean | null;
};

export type TGetRepEmailsRequest = {
  clearanceBundleLicensorsIds: number[];
};

export type TGetRepEmailsResponse = {
  clearanceRepEmails: string[];
};

export type TGetEmailRequest = {
  letterType: TLetterType;
  templateId: number;
  clearanceBundleLicensorsIds: number[];
  contact: TLetterContact;
};

export type TGetEmailResponse = {
  email: {
    subject: string;
    to: string[];
    cc: string[];
    bcc: string[];
    body: string;
  };
  missedTags: string[];
};

export type TGetLetterRequest = {
  letterType?: TLetterType;
  templateId: number;
  clearanceBundleLicensorsIds: number[];
  contact: TLetterContact;
};

export type TGetLetterResponse = {
  url: string;
  missedTags: string[];
};

export type TSaveLicensorContactRequest = {
  divisionId: number;
  contact: TLicensorContact;
  clearanceBundleLicensorsIds: number[];
};

export type TExistingContact = {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  address1: string | null;
  address2: string | null;
  city: string | null;
  state: string | null;
  postalCode: string | null;
  countryName: string | null;
  licensorName?: string | null;
  usedLast?: boolean | null;
  divisionId: number;
};

export type TLetterFormat = 'PDF' | 'WORD';

export type TAttachmentDto = {
  id: number;
};

export type TLetterEmail = {
  subject: string;
  to: string[];
  cc: string[];
  bcc: string[];
  body: string;
  attachmentName?: string;
  from: string;
  templateId?: number;
};

export type TSaveDraftLetterRequest = {
  id: number;
  divisionId: number;
  letterFormat: TLetterFormat;
  letterTemplateId: number;
  bundle: boolean;
  contact: TExistingContact;
  clearanceBundleLicensorsIds: number[];
  licensorStatus?: number;
  letterUrl: string;
  letterDocxUrl: string;
  extraAttachments: TAttachmentDto[];
  letterType: TLetterType;
  expirationDate: Date;
  email: TLetterEmail;
};

export type TUpdateStatusAndDateRequest = TSaveDraftLetterRequest & {
  setSendDate: boolean;
  setStatus: boolean;
};

export type TSaveDraftLetterResponse = {
  id: number;
};

export type TGetDraftRequest = {
  id: number;
  clearanceBundleLicensorId: number;
};

export type TGetDraftResponse = {
  id: number;
  letterFormat: TLetterFormat;
  letterTemplateId: number;
  qclearBundleId: number;
  letterType: TLetterType;
  licensorStatus: TClearanceStatus;
  extraAttachments: TAttachmentDto[];
  expirationDate: Date;
  documentUrl: string;
  contact: TExistingContact;
  qclearLicensorIds: number[];
  email: TLetterEmail;
};

export type TSendLetterRequest = {
  email: TLetterEmail;
  bundle: boolean;
  contact: TLicensorContact;
  clearanceBundleLicensorsIds: number[];
  licensorStatus?: TClearanceStatus['id'];
  letterUrl: string;
  letterDocxUrl: string;
  extraAttachments: { id: number }[];
  letterType: TLetterType;
  expirationDate?: Date;
};

export type TFindLetterDraftRequest = {
  clearanceBundleLicensorsIds: number[];
  divisionId: number;
  letterType?:
  | 'QUOTE_REQUEST'
  | 'QUOTE_RENEWAL'
  | 'CONFIRMATION'
  | 'LICENSE_REQUEST'
  | 'LICENSE'
  | 'CHECK_REQUEST'
  | 'OTHER';
};

export type TFindLetterDraftResponse = {
  id: number;
  letterFormat?: 'PDF' | 'WORD';
  letterTemplateId: number;
  qclearBundleId: number;
  letterType:
  | 'QUOTE_REQUEST'
  | 'QUOTE_RENEWAL'
  | 'CONFIRMATION'
  | 'LICENSE_REQUEST'
  | 'LICENSE'
  | 'CHECK_REQUEST'
  | 'OTHER';
  licensorStatus: TClearanceStatus;
  expirationDate: Date;
  documentUrl: string;
  contact: {
    firstName: string;
    lastName: string;
    email: string;
    licensorName?: string | null;
  };
  qclearLicensorIds: number[];
};

export type TSendLetterResponse = { id: string };

export const lettersApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getEmail: builder.query<TGetEmailResponse, TGetEmailRequest>({
      query: (body) => ({ url: 'clear/letters/get-email', method: 'POST', body }),
    }),
    findDraft: builder.query<TFindLetterDraftResponse, TFindLetterDraftRequest>({
      query: (body) => ({ url: 'clear/letters/find-draft', method: 'POST', body }),
      keepUnusedDataFor: 0,
    }),
    getRepEmails: builder.query<TGetRepEmailsResponse, TGetRepEmailsRequest>({
      query: (body) => ({ url: 'clear/letters/get-rep-emails', method: 'POST', body }),
    }),
    getDocument: builder.query<TGetLetterResponse, TGetLetterRequest>({
      query: (body) => ({ url: 'clear/letters/get-document', method: 'POST', body }),
      keepUnusedDataFor: 0.0001,
    }),
    saveLicensorContact: builder.mutation<TLicensorContact, TSaveLicensorContactRequest>({
      query: (body) => ({ url: 'clear/letters/save-licensor-contact', method: 'POST', body }),
      invalidatesTags: ['licensorsContacts'],
    }),
    sendLetter: builder.mutation<TSendLetterResponse, TSendLetterRequest>({
      query: (body) => ({ url: 'clear/letters/send', method: 'POST', body }),
      invalidatesTags: ['licensorsGetAll', 'getTrackClearanceStatus', 'getBundleLicenses'],
    }),
    saveLetterDraft: builder.mutation<TSaveDraftLetterResponse, TSaveDraftLetterRequest>({
      query: (body) => ({ url: 'clear/letters/save-draft', method: 'POST', body }),
      invalidatesTags: ['getLetterDraft', 'getTrackClearanceStatus', 'getBundleLicenses'],
    }),
    updateStatusAndDate: builder.mutation<void, TUpdateStatusAndDateRequest>({
      query: (body) => ({ url: 'clear/letters/update-status-and-date', method: 'POST', body }),
      invalidatesTags: ['licensorsGetAll', 'getTrackClearanceStatus', 'getBundleLicenses'],
    }),
    getLetterDraft: builder.query<TGetDraftResponse, TGetDraftRequest>({
      query: (body) => ({ url: 'clear/letters/get-draft', method: 'POST', body }),
      providesTags: ['getLetterDraft'],
    }),
  }),
});

export const {
  useFindDraftQuery,
  useGetEmailQuery,
  useGetRepEmailsQuery,
  useGetDocumentQuery,
  useGetLetterDraftQuery,
  useSendLetterMutation,
  useSaveLetterDraftMutation,
  useSaveLicensorContactMutation,
  useUpdateStatusAndDateMutation,
} = lettersApi;
