/* eslint-disable react/no-children-prop */
import React, { useMemo } from 'react';
import Form from 'components/common/Form';
import { FormButton } from 'components/common/Form/Fields';
import { DrawerHeader, Loading } from 'components/styleguide';
import * as Yup from 'yup';
import { getCampaignAssetCall } from 'redux-core/cueContainers/services';
import { useFetch, useRootTranslation } from 'utils/hooks';
import { yupDate } from 'utils/forms';
import PropTypes from 'prop-types';
import compose from 'ramda/src/compose';
import pathOr from 'ramda/src/pathOr';
import map from 'ramda/src/map';
import find from 'ramda/src/find';
import propEq from 'ramda/src/propEq';
import findIndex from 'ramda/src/findIndex';
import omit from 'ramda/src/omit';
import filter from 'ramda/src/filter';
import pick from 'ramda/src/pick';
import CompleteAssetForm from './CompleteAssetForm';
import enhancer from './enhancer';

const rootT = 'drawers.completeMaterial';

const validationSchema = Yup.object().shape({
  killed: Yup.boolean().required('global.forms.validations.required'),
  dateUsed: yupDate.when('killed', {
    is: (value) => !value,
    then: yupDate.required('global.forms.validations.required'),
    otherwise: yupDate.nullable(),
  }),
  additionalData: Yup.object().shape({
    finishersToAdd: Yup.array().of(
      Yup.object().shape({
        finishingDate: yupDate.nullable(),
        isci: Yup.string().nullable(),
      }),
    ),
  }),
});

const CompleteAssetDrawer = ({ name, onClose, payload: { assetId, viewOnly }, divisionId, completeCampaignAsset }) => {
  const t = useRootTranslation(rootT);

  const [asset, loading] = useFetch(() => assetId && getCampaignAssetCall({ id: assetId, divisionId }), [assetId], {});
  const initialValues = useMemo(
    () => ({
      ...asset,
      additionalData: {
        ...asset.additionalData,
        finishersToAdd: filter((v) => v.finishingDate, pathOr([], ['additionalData', 'finishers'], asset)),
      },
    }),
    [asset],
  );
  const onCancel = () => {
    onClose();
  };
  const handleSubmit = async (values) => {
    const finishersToAdd = compose(
      map((finisher) => ({
        ...finisher,
        // All the finishers to add should have their checkbox checked by default.
        active: true,
      })),
      pathOr([], ['additionalData', 'finishersToAdd']),
    )(values);
    const finishers = map((finisher) => {
      if (find(propEq('name', finisher.name), finishersToAdd)) {
        // use finishersToAdd data
        const idx = findIndex(propEq('name', finisher.name))(finishersToAdd);
        return finishersToAdd[idx];
      }
      // remove finishingData
      return {
        ...finisher,
        active: false,
        isci: null,
        finishingDate: null,
      };
    }, pathOr([], ['additionalData', 'finishers'], values));
    const payload = pick(['id'], values);
    if (values.killed) {
      await completeCampaignAsset({
        ...payload,
        deactivated: pick(['feesApply'], values),
      });
    } else {
      await completeCampaignAsset({
        ...payload,
        used: {
          ...pick(['dateUsed'], values),
          additionalData: {
            ...omit(['finishers', 'finishersToAdd'], values.additionalData),
            finishers,
          },
        },
      });
    }
  };
  if (loading) return <Loading />;
  return (
    <Form
      drawerName={name}
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      <DrawerHeader title={viewOnly ? t('finishes') : t('title')} onClose={onClose}>
        <>
          {viewOnly && (
            <FormButton
              alwaysEnabled
              variant="destructive"
              size="xs"
              children="global.forms.close"
              type="button"
              onClick={onClose}
            />
          )}
          {!viewOnly && (
            <FormButton
              alwaysEnabled
              variant="destructive"
              size="xs"
              children="global.forms.cancel"
              type="button"
              onClick={() => onCancel()}
            />
          )}
          {!viewOnly && <FormButton variant="primary" size="xs" children="global.forms.save" type="submit" />}
        </>
      </DrawerHeader>
      <CompleteAssetForm t={t} viewOnly={viewOnly} />
    </Form>
  );
};

CompleteAssetDrawer.propTypes = {
  name: PropTypes.string,
  payload: PropTypes.shape({
    assetId: PropTypes.number,
    viewOnly: PropTypes.bool,
    callback: PropTypes.func,
  }),
  divisionId: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
  completeCampaignAsset: PropTypes.func.isRequired,
  classes: PropTypes.shape({}).isRequired,
};

export default enhancer(CompleteAssetDrawer);
