/* eslint-disable consistent-return */
/* eslint-disable import/no-cycle */
import { IconButton, Paper } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import classnames from 'classnames';
import map from 'ramda/src/map';
import last from 'ramda/src/last';
import PropTypes from 'prop-types';
import { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { SearchInput } from 'components/styleguide';
import ROUTES from 'components/Routes/routes';
import { useDebounce, useFetch } from 'utils/hooks';
import { SEARCH_NORMALIZED_PRODUCTION_TYPES, SEARCH_FILTERS_TYPES, GLOBAL_SEARCH_TYPES } from 'utils/constants';

import RecentProjects from './RecentProjects';
import SearchResults from './SearchResults';
import enhancer from './enhancer';

export const SEARCH_TYPES = {
  PRODUCTION: [SEARCH_FILTERS_TYPES.CAMPAIGN, SEARCH_FILTERS_TYPES.EPISODE, SEARCH_FILTERS_TYPES.MOVIE],
  TRACK: [SEARCH_FILTERS_TYPES.TRACK],
};

const normalizeSearchResults = map(({ prodArray, title }) => {
  const { divisionName, tenantName, type } = last(prodArray);
  return {
    divisionName,
    path: prodArray,
    tenantName,
    title,
    trackOnly: type === SEARCH_NORMALIZED_PRODUCTION_TYPES.TRACK,
  };
});

const Search = ({
  classes,
  fetchRecentSearchList,
  fetchSearchList,
  recentsList,
  open,
  goToLink,
  setOpen,
  openDrawer,
}) => {
  const inputRef = useRef(null);

  const [search, setSearch] = useState();
  const [searchTypes, setSearchTypes] = useState(SEARCH_TYPES.PRODUCTION);
  const [showInput, setShowInput] = useState(false);
  const divRef = useRef(null);
  const showInputRef = useRef(showInput);
  const debouncedSearch = useDebounce(search);
  const { t } = useTranslation();
  const [, recentsLoading] = useFetch(fetchRecentSearchList, []);

  const [filteredList, loading] = useFetch(async () => {
    if (!debouncedSearch) return;
    const filterBy =
      searchTypes === SEARCH_TYPES.PRODUCTION ? GLOBAL_SEARCH_TYPES.PRODUCTIONS : GLOBAL_SEARCH_TYPES.TRACK;
    const searchList = await fetchSearchList({
      term: debouncedSearch,
      filters: { searchBy: 'all', filterBy, types: searchTypes, showInactive: searchTypes !== SEARCH_TYPES.PRODUCTION },
    });
    return normalizeSearchResults(searchList);
  }, [debouncedSearch, searchTypes]);

  const clickedOutside = (event) => {
    if (divRef.current && !divRef.current.contains(event.target) && showInputRef) {
      setShowInput(false);
      showInputRef.current = false; // this is done to update input in listener since is not possible through state
    }
  };
  useEffect(() => {
    document.addEventListener('mouseup', clickedOutside);
    return () => {
      document.removeEventListener('mousedown', clickedOutside);
    };
  }, [divRef]);

  useEffect(() => {
    if (!open) setSearch('');
  }, [open, setSearch]);

  const handleMouseDown = (event) => event.preventDefault();

  const navigate = ({ trackOnly, ...payload }) => {
    setOpen(false);

    if (!trackOnly) {
      return goToLink(ROUTES.PRODUCTION.OVERVIEW, payload);
    }

    const { id: trackId, trackProductionId, trackProductionObjectId, trackEntityType } = last(payload.path);
    if (trackEntityType === 'QCueTrack') {
      const { divisionId } = payload;
      const { id: projectId } = payload.path[0];

      return goToLink(ROUTES.PRODUCTION.CUE_SHEET, {
        id: trackProductionId,
        divisionId,
        projectId,
        type: payload.type,
      });
    }

    return goToLink(ROUTES.TRACK.CLEARANCES, {
      ...payload,
      id: trackProductionId,
      objectId: trackProductionObjectId,
      trackId,
    });
  };

  const isLoading = recentsLoading || loading;
  const isSearch = Boolean(debouncedSearch?.length);

  return (
    <div className={classes.root}>
      <div
        className={classnames(classes.maskedView, {
          [classes.animate]: showInput,
        })}
        ref={divRef}
      >
        <SearchInput
          clearable
          inputRef={inputRef}
          onFocus={() => setOpen(true)}
          onBlur={() => setOpen(false)}
          onChange={setSearch}
          value={search}
          placeholder={t('topbar.search')}
          testId="search-input"
          autoComplete="off"
        >
          {open && (
            <Paper className={classes.paper} onMouseDown={handleMouseDown}>
              {!isSearch && (
                <RecentProjects
                  isLoading={isLoading}
                  recentsList={recentsList}
                  navigate={navigate}
                  classes={classes}
                  openDrawer={openDrawer}
                />
              )}
              {isSearch && (
                <SearchResults
                  isLoading={isLoading}
                  searchResults={filteredList}
                  navigate={navigate}
                  classes={classes}
                  currentSearchTypes={searchTypes}
                  setSearchTypes={setSearchTypes}
                  openDrawer={openDrawer}
                  search={search}
                />
              )}
            </Paper>
          )}
        </SearchInput>
      </div>
      {!showInput && (
        <div className={classes.magnifyIcon}>
          <IconButton
            color="inherit"
            data-testid="search-icon"
            onClick={() => {
              setShowInput(true);
              showInputRef.current = true; // this is done to update input in listener since is not possible through state
              setTimeout(() => {
                setOpen(true);
                inputRef.current.focus();
              }, 600);
            }}
          >
            <SearchIcon />
          </IconButton>
        </div>
      )}
    </div>
  );
};

Search.propTypes = {
  classes: PropTypes.object.isRequired,
  fetchSearchList: PropTypes.func.isRequired,
  goToLink: PropTypes.func.isRequired,
  setOpen: PropTypes.func.isRequired,
  fetchRecentSearchList: PropTypes.func,
  recentsList: PropTypes.array.isRequired,
  open: PropTypes.bool,
  openDrawer: PropTypes.func.isRequired,
};

export default enhancer(Search);
