/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable react/no-children-prop */
/* eslint-disable @typescript-eslint/no-shadow */
import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import InfoIcon from '@material-ui/icons/Info';
import RightsPanelGridExpanded from 'components/Settings/Tabs/Rights/RightsCollapsePanel/RightsPanelGridExpanded/index';
import RightPresetForm from 'components/Settings/Tabs/Rights/RightsCollapsePanel/RightPresetForm/index';
import { FormButton } from 'components/common/Form/Fields/index';
import { DrawerHeader, Text, Layout } from 'components/styleguide';
import { useRootTranslation } from 'utils/hooks';
import { PathPropTypes, BaseDrawerPropTypes } from 'utils/types';
import propOr from 'ramda/src/propOr';
import pathOr from 'ramda/src/pathOr';
import { getTerm } from 'components/common/RightsPresetPicker/TerritoryDates';
import { formatPresetToRights, processPresets } from 'components/common/RightsPresetPicker/index';
import equals from 'ramda/src/equals';
import c from 'ramda/src/compose';
import { getBreadcrumbString } from 'utils/format';
import enhancer from './enhancer';

const RightPresetDrawer = ({
  name,
  onClose,
  isEditing: isEditingStore /** if you're comming back from the territories drawer, check if editing = true */,
  payload: {
    path = [],
    objectId,
    onSubmit,
    rights,
    showDefaultPreset,
    rightsPresetName,
    readOnly,
    saveInDrawerName,
    hintType,
  },
  saveRight,
}) => {
  const t = useRootTranslation('drawers.rightPreset');

  const [isEditing, setIsEditing] = useState(isEditingStore);

  /**
   * Since we reuse components from Settings -> Division -> Rights Preset / Territory Preset
   * We format the required or requested rights into the same structure as the presets,
   * and then transform it back on save.
   */
  const right = {
    name: rights.name || pathOr('', ['territory', 'name'], rights),
    descriptionOnLetters: rights.descriptionOnLetters,
    territories: propOr([], 'rights', rights).map((right) => ({
      id: right.territory?.id || right.territoryId,
      name: right.territory?.name || right.name,
      otherDetails: right.otherDetails,
      term:
        right.term ||
        getTerm({
          start: propOr(null, 'start', right),
          end: propOr(null, 'end', right),
        }),
    })),
  };

  const handleClose = useCallback(onClose, [onClose]);
  const onCancel = () => {
    handleClose();
  };

  const handleSubmit = async (values) => {
    const newRights = c(formatPresetToRights, processPresets)(values);
    const updatedRights = {
      ...newRights,
      dirty: !equals(newRights, rights),
    };
    if (saveInDrawerName) {
      await saveRight({
        name: saveInDrawerName,
        rights: updatedRights,
      });
    } else {
      await onSubmit(updatedRights);
    }
    await onClose();
  };

  const handleSetIsEditing = useCallback(() => setIsEditing(true), [setIsEditing]);

  return (
    <Box pb={4} width={1}>
      <RightPresetForm
        name={name}
        right={right}
        onSubmit={handleSubmit}
        objectId={objectId}
        spacing={2}
        showDefaultPreset={showDefaultPreset}
        rightsPresetName={rightsPresetName}
        readOnly={readOnly}
      >
        <DrawerHeader
          title={getBreadcrumbString([...path, { name: t('customRight') }], ' > ')}
          onClose={async () => {
            if (saveInDrawerName) {
              await saveRight({
                name: saveInDrawerName,
                rights,
              });
            }
            onClose();
          }}
          subtitle={
            <Box component="span" display="flex" alignItems="center">
              <Box component="span" pr={1}>
                <InfoIcon color="primary" />
              </Box>
              <Text variant="caption" component="span">
                {t(`hints.${hintType}`)}
              </Text>
            </Box>
          }
        >
          {!isEditing ? (
            <FormButton
              alwaysEnabled
              children="global.forms.edit"
              testId="edit"
              size="xs"
              variant="secondary"
              onClick={handleSetIsEditing}
            />
          ) : (
            <>
              <FormButton
                alwaysEnabled
                variant="destructive"
                size="xs"
                testId="right-preset-drawer-cancel"
                children="global.forms.cancel"
                onClick={() => onCancel()}
              />
              <FormButton
                variant="primary"
                testId="right-preset-drawer-save"
                size="xs"
                children={!isEditing ? 'global.forms.add' : 'global.forms.save'}
                type="submit"
              />
            </>
          )}
        </DrawerHeader>
        <Layout pr={0}>
          <RightsPanelGridExpanded showDefaultPreset={showDefaultPreset} isEditing={isEditing} hideSettingsFields />
        </Layout>
      </RightPresetForm>
    </Box>
  );
};

RightPresetDrawer.propTypes = {
  name: PropTypes.string,
  ...BaseDrawerPropTypes,
  payload: PropTypes.shape({
    path: PathPropTypes,
    rightsPresetId: PropTypes.number,
    showDefaultPreset: PropTypes.bool,
    objectId: PropTypes.number,
  }),
};

RightPresetDrawer.defaultProps = {
  isEditing: false,
};

export default enhancer(RightPresetDrawer);
