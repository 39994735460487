import * as React from 'react';
import { useController } from 'react-hook-form';
import Grid from '@mui/system/Unstable_Grid/Grid';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { flow, uniqBy } from 'lodash/fp';
import InputFormatField from 'components/common/Form/Fields/FormInputFormat/InputFormatField';
import { FormControlLabelWrapper } from 'components/common/Form/Fields/FormField/FormField';
import { extractArrayFieldError } from 'components/common/Form/Fields/FormField/extractArrayFieldError';
import { useRootTranslation } from 'utils/hooks';
import { LICENSOR_TYPES } from 'utils/constants';
import { TGetTrackRightsOwnersResponse, TLicensorType, TLicensorWithOneStop } from 'services/tracks/types';
import LicensorField from './LicensorField';
import { normalizeLicensorOutput } from '../normalizeLicensorOutput';
import { getLicensorValue } from '../getLicensorValue';

type LicensorRowFormProps = {
  index: number;
  htmlSectionId: string;
  type: TLicensorWithOneStop;
  removeField: () => void;
};

const transformResponse = (data: TGetTrackRightsOwnersResponse['content']) =>
  flow(
    uniqBy('name'),
    (array: TGetTrackRightsOwnersResponse['content']) => array.filter((it) => !!it.name),
    (array: TGetTrackRightsOwnersResponse['content']) =>
      array.map((item) => ({
        ...item,
        qwireTracksRightsOwnerId: item.id,
      })),
  )(data);

export function LicensorRowForm({ htmlSectionId, type, index, removeField }: LicensorRowFormProps) {
  const typeForSearch = type === LICENSOR_TYPES.ONE_STOP ? ['master', 'sync', 'other'] : [type];
  const t = useRootTranslation();
  const { field, fieldState, formState } = useController({ name: `rightsOwners.${index}` });
  const nameError = extractArrayFieldError({
    fieldState,
    formState,
    arrayFieldName: 'rightsOwners',
    index,
    name: 'name',
  });

  const licensorValue = getLicensorValue(field.value);

  return (
    <>
      <Grid xs={9}>
        <FormControlLabelWrapper name="name" error={Boolean(nameError)} helperText={t(nameError)}>
          <LicensorField
            types={typeForSearch as TLicensorType[]}
            value={licensorValue}
            onChange={(nextValue) => {
              const result = normalizeLicensorOutput(field.value, nextValue);
              field.onChange(result);
            }}
            onCreate={(name: string) => {
              field.onChange({ ...field.value, name, qwireTracksRightsOwnerId: null });
            }}
            htmlSectionId={htmlSectionId}
            transformResponse={transformResponse}
            hasError={Boolean(nameError)}
          />
        </FormControlLabelWrapper>
      </Grid>
      <Grid xs={2}>
        <InputFormatField
          name="share"
          type="percentage"
          decimalScale={2}
          value={field.value?.share}
          onChange={(value) => {
            field.onChange({ ...field.value, share: value });
          }}
          fullWidth
          aria-labelledby={`${htmlSectionId}-share`}
        />
      </Grid>
      <Grid xs={1}>
        <IconButton onClick={removeField} aria-label={t('global.actions.removeButton')}>
          <DeleteIcon />
        </IconButton>
      </Grid>
    </>
  );
}
