/* eslint-disable import/no-cycle */
import makeEntityServiceCalls, { makeServiceCall } from 'redux-core/serviceHelpers';

/**
 * Bookmarks
 */
export const {
  getAll: getBookmarkListCall,
  create: createBookmarkCall,
  delete: deleteBookmarkCall,
} = makeEntityServiceCalls('bookmarks', 'clear');

export const getRecentSearchListCall = makeServiceCall('productions', 'get-all-recent');

/**
 * Search
 */
export const { getAll: getSearchListCall } = makeEntityServiceCalls('search');

export const getProductionsCall = makeServiceCall('search', 'get-productions');

export const getSearchTracksCall = makeServiceCall('tracks', 'search', 'clear');
