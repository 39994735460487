import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.primary.main,
    position: 'relative',
    right: 8,
    cursor: 'pointer',
  },
}));

export default useStyles;
