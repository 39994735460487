import { baseApi } from 'services';

export type TAPMSignUpResponse = {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
};

export type TAPMSignUpRequest = {
  partnerToken: string;
  firstName: string;
  lastName: string;
  password: string;
  email: string;
  partner: string;
  companyName: string;
};

export type TPartnerVerification = {
  partnerToken: string;
  firstName: string;
  lastName: string;
  email: string;
  partner: string;
  exist: boolean;
};

export type TPartnerToken = {
  token: string;
};

export type TProfile = {
  active: boolean;
  displayName: string;
  email: string;
  firstName: string;
  id: number;
  imageId: number;
  isSSOUser: boolean;
  lastName: string;
  phone: string;
  preferredContactDivisionId: number;
  qwireEmployee: boolean;
  title: string;
}

export const usersApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    verifyPartnerToken: builder.query<TPartnerVerification, TPartnerToken>({
      query: (body) => ({
        url: 'common/users/verify-partner-token',
        method: 'POST',
        body,
      }),
    }),
    profile: builder.query<TProfile, void>({
      query: (body) => ({
        url: 'common/users/profile',
        method: 'POST',
        body,
      }),
    }),
    partnerSignup: builder.mutation<TAPMSignUpResponse, TAPMSignUpRequest>({
      query: (body) => ({
        url: 'common/users/partner-signup',
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const { useVerifyPartnerTokenQuery, useProfileQuery, usePartnerSignupMutation } = usersApi;
