import React from 'react';
import { useWatch } from 'react-hook-form';
import Add from '@material-ui/icons/Add';
import { Text } from 'components/styleguide';
import { rootTranslation } from 'utils/format';
import { Button } from '@mui/material';

const t = rootTranslation('drawers.quickSetupProduction');

type AddNewButtonProps = { emptyFields: boolean; addNew: () => void };

export const AddNewButton = React.memo(({ emptyFields, addNew }: AddNewButtonProps) => {
  const release = useWatch({
    name: 'release',
  });
  const season = useWatch({
    name: 'season',
  });

  const buttonDisabled = !release || !season;

  return (
    emptyFields && (
      <Button data-testId="addNew" variant="outlined" onClick={addNew} disabled={buttonDisabled} startIcon={<Add />}>
        <Text variant="button" bold>
          {t('addNewEpisode')}
        </Text>
      </Button>
    )
  );
});
