import React from 'react';
import { Grid } from '@material-ui/core';
import { Row } from 'components/styleguide';
import { rootTranslation, TIME_FORMATS } from 'utils/format';
import InputFormatField from 'components/common/Form/Fields/FormInputFormat/InputFormatField';
import RightsPresetPickerFormField from 'components/common/RightsPresetPicker/RightsPresetPickerFormField';
import { TPresetToRight, TRightPreset } from 'services/clear/rights-presets';
import { FormField } from 'components/common/Form/Fields/FormField/FormField';

import SeasonRelease from './SeasonRelease';
import enhancer from './enhancer';
import { SeasonField } from './SeasonField';
import { CategoryField } from './CategoryField';
import { TProductionSeason } from '../drawerStore';

type SeasonDetailsProps = {
  divisionId: number;
  defaultRightsPreset: TPresetToRight;
  seasonList: TProductionSeason[];
  rightsPresets: TRightPreset[];
};

const t = rootTranslation('drawers.quickSetupProduction');

const SeasonDetails = React.memo(
  ({ divisionId, defaultRightsPreset, seasonList, rightsPresets }: SeasonDetailsProps) => (
    <Grid direction="row" alignItems="center" justify="space-between" container item>
      <Grid container item justify="space-between" spacing={2} xs={9}>
        <Grid item xs={7}>
          <FormField
            required
            label={t('season')}
            name="season"
            render={({ field, muiprops }) => (
              <SeasonField
                {...field}
                {...muiprops}
                seasonList={seasonList}
                rightsPresets={rightsPresets}
                defaultRightsPreset={defaultRightsPreset}
              />
            )}
          />
          <span style={{ color: '#9A57DD', fontSize: 12, fontStyle: 'italic' }}>{t('requiredProduction')}</span>
        </Grid>
        <Grid item xs={12}>
          <FormField
            label={t('releaseTV')}
            name="release"
            required
            render={({ field, muiprops }) => <SeasonRelease {...field} {...muiprops} />}
          />
          <span style={{ color: '#9A57DD', fontSize: 12, fontStyle: 'italic' }}>{t('requiredProduction')}</span>
        </Grid>
        <Grid item xs={12}>
          <FormField
            name="requiredRights"
            required
            label={t('seasonRequiredRights')}
            render={({ field, muiprops }) => (
              <RightsPresetPickerFormField {...field} {...muiprops} divisionId={divisionId} />
            )}
          />
          <span style={{ color: '#9A57DD', fontSize: 12, fontStyle: 'italic' }}>{t('requiredProduction')}</span>
        </Grid>
        <Grid item xs={5}>
          <FormField
            label={t('category')}
            name="categoryId"
            render={({ field, muiprops }) => <CategoryField {...field} {...muiprops} />}
          />
          <span style={{ color: '#9A57DD', fontSize: 12, fontStyle: 'italic' }}>{t('cueSheetOnly')}</span>
        </Grid>
        <Grid item xs={4}>
          <FormField
            label={t('duration')}
            name="duration"
            render={({ field }) => (
              <InputFormatField id="duration" {...field} format={TIME_FORMATS.HHMMSS} type="time" textAlign="left" />
            )}
          />
          <span style={{ color: '#9A57DD', fontSize: 12, fontStyle: 'italic' }}>{t('cueSheetOnly')}</span>
        </Grid>
      </Grid>
      <Grid xs={12} style={{ paddingTop: 17 }}>
        <Row divider />
      </Grid>
    </Grid>
  ),
);

export default enhancer(SeasonDetails);
