import { useFocusAtom } from 'atomUtils';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createTrack } from 'redux-core/tracks/actions';
import { getUseInfoSelector } from 'redux-core/overview/selectors';
import { path } from 'ramda';
import { TSearchTrack } from 'services/tracks/types';
import AddTrackButton from '../AddTrackButton';
import { addTrackDrawerStoreAtom } from '../../addTrackDrawerStore';
import DuplicateTrackMenu from '../DuplicateTrackMenu';

const isUsedInProduction = (id, useInfo) => Boolean(path([id])(useInfo));
const isUsedInScene = (id, useInfo, sceneId) => Boolean(path([id, sceneId])(useInfo));

export function ActionColumn({
  track,
  onCueContainerIdMissing,
 duplicateTrack,
}: {
  track: TSearchTrack;
  onCueContainerIdMissing?: () => void;
  duplicateTrack: () => void
}) {
  const { id, title, artists, writers } = track;
  const dispatch = useDispatch();
  const [cueContainerId] = useFocusAtom(addTrackDrawerStoreAtom, 'cueContainerId');
  const [savingTrack, setSavingTrack] = useState(false);
  const useInfo = useSelector(getUseInfoSelector);

  const onAddTrack = async (payload) => {
    setSavingTrack(true);
    await dispatch(createTrack({ ...payload, cueContainerId }, { isAdding: true }));
    setSavingTrack(false);
    window.freshpaint.track('Added a Track to a Production', { ...payload, cueContainerId });
  };

  return (
    <>
      <AddTrackButton
        onClick={(payload) => onAddTrack({ ...payload, qwireTracksId: id, title, artists, writers })}
        active={isUsedInScene(id, useInfo, cueContainerId) || isUsedInProduction(id, useInfo)}
        isUsedInScene={isUsedInScene(id, useInfo, cueContainerId)}
        disabled={savingTrack}
        cueContainerId={cueContainerId as number}
        onCueContainerIdMissing={onCueContainerIdMissing}
      />
      <DuplicateTrackMenu trackId={id} duplicateTrack={duplicateTrack} />
    </>
  );
}
