import { Box, InputAdornment } from '@mui/material';
import SearchIcon from '@material-ui/icons/Search';
import { InputText } from 'components/styleguide';
import useStyles from './styles';

export const SearchProductionUser = ({ searchTerm, setSearchTerm }) => {
  const { searchFieldContainer } = useStyles();
  return (
    <Box className={searchFieldContainer} mb={1}>
      <InputText
        placeholder="Search users"
        value={searchTerm}
        onChange={({ target }) => setSearchTerm(target.value)}
        startAdornment={
          <InputAdornment>
            <SearchIcon color="primary" fontSize="small" />
          </InputAdornment>
        }
      />
    </Box>
  );
};
