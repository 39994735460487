import { Box, Typography } from '@mui/material';
import { TClearanceStatus } from 'services/clear/clearance-status';

export type TClearanceStatusLabelProps = {
  clearanceStatus: Partial<TClearanceStatus>;
};

export const ClearanceStatusLabel = ({ clearanceStatus }: TClearanceStatusLabelProps) => (
  <Box display="flex" alignItems="center" gap={1}>
    <Box sx={{ borderRadius: '50%', width: '1em', height: '1em', backgroundColor: clearanceStatus.color }} />
    <Typography variant="body2">{clearanceStatus.label}</Typography>
  </Box>
);
