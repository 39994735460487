/* eslint-disable react/no-children-prop */
import React from 'react';
import TracksChart from 'components/Projects/WatchingTab/ProductionCard/ProductionCardContent/Charts/TracksChart';
import Grid from '@material-ui/core/Grid';
import { concatStrings } from 'utils/index';
import { formatCurrency } from 'utils/format';
import { Text } from 'components/styleguide';
import { useRootTranslation } from 'utils/hooks';
import { PRODUCTION_TYPES } from 'utils/constants';
import { ClearanceStatusLabel } from 'components/styleguide/ClearanceStatusLabel';
import useStyles from './styles';

const rootT = 'drawers.watchingAssets';

const AssetInfo = ({ label, value }) => (
  <Grid container direction="column" item>
    <Text bold children={label} gutterBottom noWrap variant="caption" />
    <Text children={value || '--'} gutterBottom noWrap variant="caption" />
  </Grid>
);

const AssetItem = ({
  approvedFees,
  clearanceStatus,
  creativeVendor,
  currency,
  isClearance,
  title,
  tracks,
  type,
  version,
  versionName,
}) => {
  const classes = useStyles();
  const t = useRootTranslation(rootT);

  return (
    <Grid container className={classes.root}>
      <Text bold children={title} gutterBottom variant="body2" />
      <Grid container item direction="column">
        <Text bold variant="caption" children={t('status')} gutterBottom />
        <ClearanceStatusLabel clearanceStatus={clearanceStatus} />
      </Grid>
      <Grid className={classes.infoContainer} container item>
        <Grid container item xs={3} alignContent="space-around">
          <AssetInfo label={t('version')} value={concatStrings(' - ')(version, versionName)} />
          <AssetInfo label={t('assetType')} value={type} />
        </Grid>
        <Grid container item xs={3} alignContent="space-around">
          <AssetInfo label={t('creativeVendor')} value={creativeVendor} />
          <AssetInfo label={t('approvedFees')} value={formatCurrency(approvedFees, currency)} />
        </Grid>
        <Grid container item xs={6}>
          <TracksChart
            isClearance={isClearance}
            isWide
            productionType={PRODUCTION_TYPES.CAMPAIGN}
            progressVariant="caption"
            tracks={tracks}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AssetItem;
