/* eslint-disable react/jsx-props-no-spreading */
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import TVFilter from 'components/common/Drawers/SearchProductionDrawer/TVFilter';
import ProductionFilter from 'components/common/Drawers/SearchProductionDrawer/ProductionFilter';
import React, { useState, useEffect } from 'react';
import cond from 'ramda/src/cond';
import equals from 'ramda/src/equals';
import always from 'ramda/src/always';
import { NORMALIZED_PRODUCTION_TYPES } from 'utils/constants';
import { getProjectWithProductions } from 'redux-core/projects/services';
import ProjectField from 'components/common/Modals/ProductionModal/ProjectField';
import { withFormField } from 'components/common/Form/utils';
import { useFetch, useRootTranslation } from 'utils/hooks';
import { TDivision } from '..';

export enum NormalizedProductionType {
  FEATURES = 'features',
  MKT = 'marketing',
  TV = 'television',
}

export type ProductionSearchProps = {
  type: NormalizedProductionType.TV | NormalizedProductionType.FEATURES | NormalizedProductionType.MKT;
  divisionId: number;
  division: TDivision;
  projectId: number;
  form: FormProps;
};

export type FormPropValue = {
  divisionId: number;
  projectId: number;
  productionType: string;
  projectTitle: string;
  productions: number[];
  seasons: number[];
  subProjects: number[];
};

export type FormProps = {
  values: FormPropValue;
  setFieldValue: (field: string, value: any) => void;
  validateForm: () => void;
};

const ProductionSearch = (props: ProductionSearchProps) => {
  const { type, divisionId, division, form } = props;
  const { values, setFieldValue, validateForm } = form;
  const t = useRootTranslation('drawers.searchProductions');
  const { subProjects, productions, seasons } = values;
  const [projectId, setProjectId] = useState(values.projectId);
  const [selected, setSelected] = useState({
    productionIds: productions || null,
    seasonId: seasons[0] || null,
    releaseId: subProjects[0] || null,
  });

  useEffect(() => {
    setSelected({
      productionIds: productions || null,
      seasonId: seasons[0] || null,
      releaseId: subProjects[0] || null,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, setSelected]);

  const isTV = type === NORMALIZED_PRODUCTION_TYPES.TV;
  const [[project]] = useFetch(() => projectId && getProjectWithProductions({ id: projectId }), [projectId]);
  useEffect(() => {
    if (type) setFieldValue('normalizedProdType', type);
  }, [type, setFieldValue]);

  const data = project
    ? cond([
        [equals(NORMALIZED_PRODUCTION_TYPES.FEATURES), always(project.releases)],
        [equals(NORMALIZED_PRODUCTION_TYPES.MKT), always(project.campaigns)],
        [equals(NORMALIZED_PRODUCTION_TYPES.TV), always(project.seasons)],
      ])(type)
    : [];
  const handleChange = async (payload) => {
    const updatedSelected = { ...selected, ...payload };
    const { productionIds, seasonId, releaseId } = updatedSelected;
    await setFieldValue('productions', productionIds || []);
    await setFieldValue('seasons', seasonId ? [seasonId] : []);
    await setFieldValue('subProjects', releaseId ? [releaseId] : []);
    await validateForm();
    setSelected(updatedSelected);
  };

  return (
    <Grid container spacing={2} direction="column">
      <Grid item xs={6}>
        <Box mb={2}>
          <ProjectField
            onChange={(val) => {
              setProjectId(val);
              setFieldValue('projectId', val);
            }}
            value={projectId}
            projectTitle={values.projectTitle}
            onSelect={(val) => {
              if (val) {
                setFieldValue('projectTitle', val.name);
              }
            }}
            label={t('project')}
            disabled={!divisionId || !type}
            division={division}
          />
        </Box>
        {divisionId && type && (
          <Box mb={2}>
            {isTV ? (
              <TVFilter
                divisionId={divisionId}
                projectId={projectId}
                onChange={handleChange}
              />
            ) : (
              <Box mb={2}>
                <ProductionFilter
                  disabled={!project}
                  productionIds={selected.productionIds}
                  productions={data}
                  onChange={handleChange}
                  type={type}
                />
              </Box>
            )}
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

export default withFormField(ProductionSearch);
