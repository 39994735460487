/* eslint-disable no-use-before-define */
/* eslint-disable func-names */
/* eslint-disable react/no-children-prop */
/* eslint-disable react/no-this-in-sfc */
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { Text } from 'components/styleguide';
import Form from 'components/common/Form';
import { getRoles, sendMultipleInviteCall } from 'redux-core/users/services';
import { useFetch } from 'utils/hooks';
import { requestWithError } from 'utils/request';
import { useCallback } from 'react';
import withBaseDrawer from '../withBaseDrawer';

import { DynamicInviteUsersFields } from './DynamicInviteUsersFields';
import { InviteUsersDrawerHeader } from './InviteUsersDrawerHeader';

const InviteUsersDrawer = ({ name, onClose, payload: { divisionId, divisionEmailsSet, fetchUsers } }) => {
  const { t } = useTranslation();

  const [roles, loading] = useFetch(() => getRoles({ excludeNone: true, excludeDivisionLevel: false }));

  const handleSubmit = async ({ inviteList }) => {
    const body = {
      inviteList,
      divisionId,
    };
    await requestWithError(sendMultipleInviteCall, body, {
      message: {
        success: t('drawers.inviteUsers.notifications.multipleInvite.success'),
        failed: t('drawers.inviteUsers.notifications.multipleInvite.failed'),
      },
    });
    await fetchUsers();
    onClose();
  };

  const onCancel = () => {
    onClose();
  };

  const validateInvitesUsers = useCallback(
    ({ inviteList }) => {
      const emailsOnCurrentDrawer = inviteList.map((invitedUser) => invitedUser.email);

      const inviteListErrors = inviteList.map((invitedUser) => {
        const isEmailValid = Yup.string().email().isValidSync(invitedUser.email);
        const isRoleDefined = !!invitedUser.defaultProjectRole;

        const isDuplicateOnCurrentDrawer =
          emailsOnCurrentDrawer.filter((email) => invitedUser.email === email).length > 1;

        const invalidEmailMessage = !isEmailValid ? 'global.forms.validations.types.email' : null;
        const duplicateOnDivisionError = divisionEmailsSet.has(invitedUser.email)
          ? 'drawers.inviteUsers.repeatedOnDivision'
          : null;
        const duplicateOnDrawerError = isDuplicateOnCurrentDrawer ? 'drawers.inviteUsers.repeatedOnDrawer' : null;

        return {
          email: invalidEmailMessage || duplicateOnDivisionError || duplicateOnDrawerError,
          defaultProjectRole: isRoleDefined ? null : 'global.forms.validations.required',
        };
      });

      const isValidForm = inviteListErrors.every(({ email, defaultProjectRole }) => !email && !defaultProjectRole);
      if (isValidForm) return null;

      return {
        inviteList: inviteListErrors,
      };
    },
    [divisionEmailsSet],
  );

  return (
    <Form
      drawerName={name}
      initialValues={{
        inviteList: [{ transientId: Date.now(), email: null, defaultProjectRole: null }],
      }}
      onSubmit={handleSubmit}
      validate={validateInvitesUsers}
    >
      <InviteUsersDrawerHeader onClose={onClose} onCancel={onCancel} />
      <Text>{t('qwireAdmin.tenants.inviteInfo')}</Text>
      {!loading && <DynamicInviteUsersFields roles={roles} />}
    </Form>
  );
};

export default withBaseDrawer(InviteUsersDrawer);
