import { connect } from 'react-redux';
import { getAdvancedSearchFilterBySelector, makeSearchSelectedItemSelector } from 'redux-core/header/selectors';
import { setSearchItemDetails } from 'redux-core/header/actions';

const makeMapStateToProps = () => {
  const getImportCompareItem = makeSearchSelectedItemSelector();
  const mapStateToProps = (state, props) => ({
    filterBy: getAdvancedSearchFilterBySelector(state),
    selected: getImportCompareItem(state, props),
  });
  return mapStateToProps;
};

const mapDispatchToProps = { setSearchItemDetails };

export default connect(makeMapStateToProps, mapDispatchToProps);
