/* eslint-disable react/no-children-prop */
/* eslint-disable no-unused-vars */
import React from 'react';
import Form from 'components/common/Form';
import { FormButton, FormInputText, FormTypeAhead } from 'components/common/Form/Fields';
import { Grid, Box } from '@material-ui/core';
import { Text, DrawerHeader, Row } from 'components/styleguide';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import LoadingWrapper from 'components/common/LoadingWrapper/LoadingWrapper';
import BaseDrawer from 'components/styleguide/drawers/BaseDrawer/index';
import { useRootTranslation } from 'utils/hooks';
import DrawerFormContentGrid from '../../DrawerFormContentGrid';

import FileNameField from './FileNameField';
import enhancer from './enhancer';

const rootT = 'drawers.fileAttachments';

const validationSchema = Yup.object().shape({
  fileName: Yup.string().required('global.forms.validations.required'),
  extension: Yup.string().required('global.forms.validations.required'),
  plainName: Yup.string().required('global.forms.validations.required'),
  fileType: Yup.string().required('global.forms.validations.required'),
});

const BaseFilesDrawer = ({
  productionTitle,
  hideBackButton,
  name,
  onClose,
  open,
  onSubmit,
  children,
  title,
  initialValues: file,
  setDrawerDirty,
  loading,
  fileTypes,
}) => {
  const t = useRootTranslation(rootT);

  const handleClose = () => {
    onClose();
  };

  const handleSubmit = async ({ plainName, extension, ...payload }) => {
    /** @TODO revise usage of setDrawerDirty @see https://jira.qwire.com/browse/CLR-3718 */
    await setDrawerDirty({ name, dirty: false, submitted: true });
    await onSubmit({ ...payload, fileName: `${plainName}.${extension}` });
  };

  if (loading) return <LoadingWrapper />;

  const initialValues = {
    ...file,
    isParentLoading: false,
  };

  return (
    <Form
      drawerName={name}
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      promptMessage={t('confirmDismiss')}
    >
      {() => (
        <BaseDrawer open={open} onClose={handleClose} name={name}>
          <DrawerHeader title={t('drawerTitle')} hideBackButton={hideBackButton} onClose={onClose}>
            <FormButton
              alwaysEnabled
              variant="destructive"
              testId="close-button"
              size="xs"
              children="global.forms.cancel"
              type="button"
              onClick={handleClose}
            />
            <FormButton
              variant="primary"
              size="xs"
              children="global.forms.save"
              testId="save-button"
              /** @TODO figure out with submit issue @see https://jira.qwire.com/browse/CLR-3718 */
              onClick={handleSubmit}
            />
          </DrawerHeader>
          <DrawerFormContentGrid container>
            {title && (
              <Row>
                <Grid item>
                  <Text variant="caption" bold>
                    {t('fileAttachedTo')}
                  </Text>
                  <Box>
                    {productionTitle && <Text variant="body2">{productionTitle}</Text>}
                    <Text variant="body2" bold>
                      {title}
                    </Text>
                  </Box>
                </Grid>
              </Row>
            )}
            <Row>
              <FileNameField
                label={t('fileName')}
                name="plainName"
                extensionName="extension"
                placeholder={t('chooseFileName')}
              />
            </Row>

            <Row>
              <Grid item xs={8}>
                <FormTypeAhead
                  isClearable={false}
                  label={t('fileType')}
                  name="fileType"
                  placeholder={t('chooseFileType')}
                  options={fileTypes}
                  testId="file-type"
                  required
                />
              </Grid>
            </Row>
            {children}
            <Row>
              <Grid item xs={8}>
                <FormInputText
                  label={t('description')}
                  name="fileDescription"
                  testId="file-description"
                  rows={19}
                  textArea
                />
              </Grid>
            </Row>
          </DrawerFormContentGrid>
        </BaseDrawer>
      )}
    </Form>
  );
};

BaseFilesDrawer.displayName = 'BaseFilesDrawer';

BaseFilesDrawer.defaultProps = {
  fileTypes: [],
};

BaseFilesDrawer.propTypes = {
  hideBackButton: PropTypes.bool,
  name: PropTypes.string,
  productionTitle: PropTypes.string,
  open: PropTypes.bool,
  onSubmit: PropTypes.func,
  children: PropTypes.any,
  onClose: PropTypes.func,
  title: PropTypes.string,
  initialValues: PropTypes.object,
  setDrawerDirty: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  fileTypes: PropTypes.array,
};

export default enhancer(BaseFilesDrawer);
