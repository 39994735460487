/* eslint-disable no-unused-vars */
/* eslint-disable no-plusplus */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-continue */
/* eslint-disable no-case-declarations */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-use-before-define */
import XLSX from 'xlsx';
import always from 'ramda/src/always';
import compose from 'ramda/src/compose';
import concat from 'ramda/src/concat';
import filter from 'ramda/src/filter';
import find from 'ramda/src/find';
import flatten from 'ramda/src/flatten';
import ifElse from 'ramda/src/ifElse';
import is from 'ramda/src/is';
import isNil from 'ramda/src/isNil';
import length from 'ramda/src/length';
import map from 'ramda/src/map';
import mergeDeepLeft from 'ramda/src/mergeDeepLeft';
import mergeDeepWith from 'ramda/src/mergeDeepWith';
import omit from 'ramda/src/omit';
import pluck from 'ramda/src/pluck';
import prop from 'ramda/src/prop';
import propEq from 'ramda/src/propEq';
import propOr from 'ramda/src/propOr';
import reduce from 'ramda/src/reduce';
import replace from 'ramda/src/replace';
import split from 'ramda/src/split';
import splitEvery from 'ramda/src/splitEvery';
import toLower from 'ramda/src/toLower';
import toUpper from 'ramda/src/toUpper';
import trim from 'ramda/src/trim';
import uniq from 'ramda/src/uniq';
import uniqBy from 'ramda/src/uniqBy';
import { concatStrings } from 'utils';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import utc from 'dayjs/plugin/utc';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import {
  RIGHTS_OWNER_ROLE,
  RIGHTS_OWNER_TYPES,
  PUBLISHERS_TYPES,
  TRACK_TYPES,
  getTrackUseTypeName,
} from 'utils/constants';
import { filterIndexed } from 'utils/object';
import { IMPORT_FIELDS, IMPORT_STEPS, IMPORT_RIGHTS_OWNERS_PILLS, IMPORT_REPEATED_ARRAY } from './constants';

dayjs.extend(duration);
dayjs.extend(utc);
dayjs.extend(customParseFormat);

export const formatCueSheetFile = (file) =>
  new Promise((resolve) => {
    const reader = new FileReader();
    const readAsBinary = !!reader.readAsBinaryString;
    reader.onload = (e) => {
      const bstr = e.target.result;
      const workbook = XLSX.read(bstr, {
        cellDates: true,
        cellText: false,
        type: readAsBinary ? 'binary' : 'array',
      });
      const sheet = workbook.Sheets[workbook.SheetNames[0]];
      // Array of arrays
      const data = XLSX.utils.sheet_to_json(sheet, {
        blankrows: false,
        // Excel stores times as dates, which are stored as numbers
        // We need to ask the library to parse back the date fields as human readable text.
        dateNF: 'HH:mm:ss',
        header: 1,
        raw: false,
      });
      /**
       * This complements the work done by the blankrows param we pass to XLSX library.
       * The library considers an empty string as a value, but we don't.
       */
      resolve(data.filter((arr) => arr.some(Boolean)));
    };
    if (readAsBinary) reader.readAsBinaryString(file);
    else reader.readAsArrayBuffer(file);
  });

export const isValidStep = (step, state) => {
  switch (step) {
    case IMPORT_STEPS.IMPORT_FILE:
      return Boolean(state.fileName);
    case IMPORT_STEPS.SELECT_HEADER_ROW:
      return !isNil(state.headerRow);
    case IMPORT_STEPS.SELECT_FIRST_ROW:
      return !isNil(state.firstRow);
    case IMPORT_STEPS.MAP_DATA:
      return Boolean(state.mappedFields?.[IMPORT_FIELDS.CUE_TITLE]);
    default:
      return true;
  }
};

/**
 * Returns a number equal or greater than 0 indicating the max number of columns for a set of data
 * @param {Array} data
 * @returns Number
 */
export const getColumnsNumber = (data) => Math.max(0, ...map(length)(data));

/**
 * Receives an array of pills and returns the same array with a disabled Booelan for every pill
 * @param {Array.<{disabledIf: Array<String>, enabledIf: Array<String>, field: String, isDivider: Boolean}>} pills
 * @param {Object} mappedFields
 * @param {Array<String>} current
 */
export const getPillsStatus = (pills, mappedFields, current) => {
  const fields = current?.length || 0;
  return pills.map(({ disabledIf, enabledIf, isDivider, field, ...rest }) => {
    let disabled;
    const isUsage = field === IMPORT_FIELDS.USAGE;
    const isUsageSelected = current?.includes(IMPORT_FIELDS.USAGE);
    // Order is always field - divider - field - divider - ...
    // We can not have 2 consecutives dividers, and there can not be a divider in the
    // first position. Dividers are placed at even positions greater than 0
    if (isDivider && !isUsageSelected) disabled = fields % 2 === 0;
    // Usage pill will always be disabled if there is already another pill selected
    else if (isUsage && fields) disabled = true;
    // Pills will always be disabled if usage is already selected
    else if (isUsageSelected) disabled = true;
    else {
      disabled =
        // Pills can only be selected once
        mappedFields[field] ||
        // We can not have 2 consecutive pills. Pills are placed at odd positions
        fields % 2 === 1 ||
        // The status of some fields may depend on whether or not other fields are selected.
        // Some fields will be disabled if other fields are already selected
        disabledIf?.some((f) => mappedFields[f]) ||
        // And some other fields will be disabled if other fields are not selected.
        Boolean(enabledIf?.some((f) => !mappedFields[f]));
    }
    return { ...rest, field, disabled };
  });
};

/**
 *
 * @param {*} value
 * @param {Number} max 23 for hours & 59 for minutes/seconds
 * @returns {String}
 */
export const parseTimePosition = (value, max = 23) => {
  const number = parseInt(value, 10);
  // eslint-disable-next-line no-restricted-globals
  if (!isNaN(number)) {
    return Math.min(max, number).toString().padStart(2, '0');
  }
  return '00';
};

export const parseTimeHMS = (hours, minutes, seconds) => {
  const h = parseInt(hours, 10) || 0;
  const m = parseInt(minutes, 10) || 0;
  const s = parseInt(seconds, 10) || 0;
  const totalSeconds = h * 3600 + m * 60 + s;

  const ho = Math.floor(totalSeconds / 3600);
  if (ho > 23) return '23:59:59';
  const min = Math.floor((totalSeconds - ho * 3600) / 60);
  const sec = totalSeconds - ho * 3600 - min * 60;
  return concatStrings(':')([ho, min, sec].map((t) => t.toString().padStart(2, '0')));
};

/**
 *
 * @param {String?} time
 * @returns {String} Time formatted as HH:MM:SS
 * @example <caption>Example usage of this method.</caption>
 * parseHMSFromTime(); // returns '00:00:00'
 * parseHMSFromTime("10"); // returns '00:00:10'
 * parseHMSFromTime("11:10"); // returns '00:11:10'
 * parseHMSFromTime("00:100"); // returns '00:01:40'
 * parseHMSFromTime("13:11:10"); // returns '13:11:10'
 * // For frames parsing:
 * parseHMSFromTime("01:02:03:04"); // returns '01:02:03'
 */
export const parseHMSFromTime = (time) => {
  const units = String(time).split(':');

  if (units.length > 3) {
    // There are scenarios where cue sheets that are imported will include Frames in the time values.
    // Those are formatted as HH:MM:SS:FF.
    // Thus, if there are more than 3 time units, we parse it as frames, from left to right.
    const [h, m, s] = units;
    return parseTimeHMS(h, m, s);
  }
  // Otherwise, we parse it from right to left
  // Which implies that we will consider 01:13 as MM:SS
  const [s, m, h] = units.reverse();
  return parseTimeHMS(h, m, s);
};

/**
 *
 * @param {*} value
 * @param {Array<String>} mappings
 * @param {Object} usageMappings
 * @param {Boolean} asObject If true will return an object with the result. If false an array
 */
export const parseMappings = (value, mappings, usageMappings, asObject) => {
  if (!mappings?.length) return undefined;
  const fields = [];
  const dividers = [];
  mappings.forEach((val, i) => {
    // Order is always field - divider - field - divider - ...
    if (i % 2 === 0) fields.push(val);
    else dividers.push(val);
  });

  const shouldRepeatPattern = getShouldRepeatPattern(fields, dividers);

  const result = [];
  let val = String(value ?? '').trim();
  // If just one field with no dividers and value is truthy, add it to the result
  if (!dividers.length && val) {
    result.push(parseField(val, fields[0], usageMappings));
  } else {
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < dividers.length && val; i++) {
      const divider = dividers[i];
      // Match only the first occurrence of the divider
      const divPosition = val.indexOf(divider);
      // If the divider was found
      if (divPosition !== -1) {
        // Add the splitted value to the result
        result.push(parseField(val.substring(0, divPosition).trim(), fields[i], usageMappings));
        // Keep the rest of the string
        val = val.substring(divPosition + 1).trim();
      } else if (
        // If it is not the last divider
        i + 1 < dividers.length ||
        // Or it is the last divider and the value is not fasly
        (val && i + 1 === dividers.length)
      ) {
        // This is the last value to add. Break loop
        result.push(parseField(val, fields[i], usageMappings));
        val = '';
        break;
      }
    }
    if (fields.length > dividers.length && val) {
      result.push(parseField(val, fields[fields.length - 1], usageMappings));
    } else if (shouldRepeatPattern && val) {
      /**
       * We don't allow the user to input consecutive dividers.
       * Thus, for handling the case in which someone could potentially input something like
       *    NAME (SHARE%), NAME2 (SHARE2%), ...
       * we are flexible enough and remove the fist comma/dash at the begining of the value
       * to recusevily continue with the mapping
       */
      const remainingValue = val.replace(/^\s*[,-]/, '');
      result.push(...parseMappings(remainingValue, mappings, usageMappings));
    }
  }

  if (asObject) {
    const reduceFields = (result) => fields.reduce((prev, field, index) => ({ ...prev, [field]: result[index] }), {});
    if (!shouldRepeatPattern) return reduceFields(result);
    return compose(map(reduceFields), splitEvery(fields.length))(result);
  }
  return result;
};

export const parseField = (value, field, usageMappings) => {
  switch (field) {
    case IMPORT_FIELDS.USAGE:
      return usageMappings[value];
    case IMPORT_FIELDS.TIME_IN:
    case IMPORT_FIELDS.TIME_OUT:
    case IMPORT_FIELDS.DURATION:
      return parseHMSFromTime(value);
    case IMPORT_FIELDS.TIME_IN_HH:
    case IMPORT_FIELDS.TIME_OUT_HH:
      return parseTimePosition(value, 23);
    case IMPORT_FIELDS.WRITER_SHARE:
    case IMPORT_FIELDS.PUBLISHER_SHARE:
    case IMPORT_FIELDS.WRITER_PUBLISHER_SHARE:
      // Safely omit percentage symbol %.

      const share = value ? String(value).replace(/%/g, '') : value;
      if (share === '') {
        return undefined;
      }
      // If can be parsed as a number >=0, return the String representation of that number
      // eslint-disable-next-line no-nested-ternary
      return isNaN(share) ? undefined : (share < 0 ? undefined : String(+share));
    default:
      return value;
  }
};

/**
 *
 * @param {Array<Array<String>>} file
 * @param {Object} mappings
 * @param {Object} mappedFields
 * @param {Object} usageMappings
 */
export const parseFile = (file, mappings, mappedFields, usageMappings) => {
  const cueTitleColumn = getColumnPosition(IMPORT_FIELDS.CUE_TITLE)(mappings);
  const isDurationSplitted = getIsDurationSplitted(mappedFields);
  const isTimeInSplitted = getIsTimeInSplitted(mappedFields);
  const isTimeOutSplitted = getIsTimeOutSplitted(mappedFields);
  const isWriterFullNameMapped = Boolean(mappedFields[IMPORT_FIELDS.WRITER]);
  const isWriterPublisherMapped = getIsWriterPublisherMapped(mappedFields);
  const context = {
    isDurationSplitted,
    isTimeInSplitted,
    isTimeOutSplitted,
    isWriterFullNameMapped,
    isWriterPublisherMapped,
  };

  const cueSheet = [];

  let auxCue = {};
  for (let i = 0; i < file.length; i++) {
    const row = file[i];
    // If the auxiliar cue has a title and the current row too,
    // then it is a new cue. Add the auxiliar to the cue sheet and
    // repeat the same process for the remaining rows.
    if (auxCue.title && row[cueTitleColumn]) {
      cueSheet.push(auxCue);
      auxCue = {};
    }
    // Omit rows at the begining that don't belong to any cue
    if (!auxCue.title && !row[cueTitleColumn]) continue;

    auxCue = mergeDeepWith(
      // If it is a single value field (e.g. Cue Number), we keep the first found value
      // (the one that was first in the spreadsheet. The "left")
      // If it is an array (e.g. Rights Owners), concat
      (left, right) => (is(Array, left) && right ? left.concat(right) : left),
      auxCue,
      parseRow(row, mappings, usageMappings, context),
    );
    if (auxCue.artists?.length) {
      // Guarantees that artists are not repeated among multiple rows
      auxCue.artists = uniq(auxCue.artists);
      // If Artist is included in the mapping, then type=Song
      auxCue.type = TRACK_TYPES.SONG;
      // If Artist is not included in the mapping, then type=Score
    } else auxCue.type = TRACK_TYPES.SCORE;
  }
  // Push the last cue to the array
  if (auxCue.title) cueSheet.push(auxCue);

  return cueSheet;
};

/**
 * Returns a function that recieves the mappings and
 * finds the location of a certain field
 * @param {Number} column
 */
export const getColumnPosition = (column) =>
  compose(
    (pos) => (isNaN(pos) ? undefined : Number(pos)),
    // First array item or undefined
    propOr(undefined, '0'),
    find(([, field]) => field?.includes(column)),
    Object.entries,
  );

export const getIsTimeInSplitted = (mappedFields) =>
  mappedFields[IMPORT_FIELDS.TIME_IN_HH] ||
  mappedFields[IMPORT_FIELDS.TIME_IN_MM] ||
  Boolean(mappedFields[IMPORT_FIELDS.TIME_IN_SS]);

export const getIsTimeOutSplitted = (mappedFields) =>
  mappedFields[IMPORT_FIELDS.TIME_OUT_HH] ||
  mappedFields[IMPORT_FIELDS.TIME_OUT_MM] ||
  Boolean(mappedFields[IMPORT_FIELDS.TIME_OUT_SS]);

export const getIsDurationSplitted = (mappedFields) =>
  mappedFields[IMPORT_FIELDS.DURATION_MM] || Boolean(mappedFields[IMPORT_FIELDS.DURATION_SS]);

export const getIsWriterPublisherMapped = (mappedFields) =>
  mappedFields[IMPORT_FIELDS.WRITER_PUBLISHER] && Boolean(mappedFields[IMPORT_FIELDS.ROLE]);

/**
 *
 * @param {Array<String>} row
 * @param {Object} mappings
 * @param {Object} usageMappings
 * @param {Object} context
 * @returns {Object} Cue correspondent to the parsed row, matching qwire schema
 */
export const parseRow = (row, mappings, usageMappings, context) =>
  compose(
    (obj) => {
      const times = calculateTimes(obj, context);
      const rightsOwners = calculateRightsOwners(obj, context);
      return {
        cueNumber: obj[IMPORT_FIELDS.CUE_NUMBER],
        libraryTrackId: obj[IMPORT_FIELDS.TRACK_ID],
        usage: obj[IMPORT_FIELDS.USAGE],
        title: obj[IMPORT_FIELDS.CUE_TITLE],
        artists: parseStringToArray(obj[IMPORT_FIELDS.ARTIST]),
        rightsOwners,
        ...times,
      };
    },
    reduce((prev, [column, mapping]) => {
      const parsed = parseMappings(row[column], mapping, usageMappings, true);
      if (is(Array, parsed)) {
        /**
         * If after parsing the value we get an array (i.e. it was a recursive value)
         * we merge it deep into ${IMPORT_REPEATED_ARRAY}.
         * This means that if from one of the cells we got
         *    [{ share: 10 }, { share: 20}]
         * and from another cell we got
         *    [{ name: 'Rights Owner 1' }, { name: 'Rights Owner 2'}]
         * we will expect it to be merged together into:
         *    [{ name: 'Rights Owner 1', share: 10 }, { name: 'Rights Owner 2', share: 20}]
         */
        const res = mergeDeepWith(mergeDeepLeft, prev, {
          [IMPORT_REPEATED_ARRAY]: parsed,
        });
        /**
         * Ramda's mergeDeepLeft returns the Object representation of the array.
         * Following the previous example, the result would look like this
         * { 0: { name: 'Rights Owner 1', share: 10 }, 1: { name: 'Rights Owner 2', share: 20}}
         * As we want to keep it as an array, we replace it with Object.values.
         */
        res[IMPORT_REPEATED_ARRAY] = Object.values(res[IMPORT_REPEATED_ARRAY]);
        return res;
      }
      return { ...prev, ...parsed };
    }, {}),
    Object.entries,
  )(mappings);

/**
 *
 * @param {Object} obj
 * @param {<{isTimeInSplitted: Boolean, isTimeOutSplitted: Boolean, isDurationSplitted: Boolean}>} context
 * @returns {Object}
 */
export const calculateTimes = (obj, context) => {
  let timeIn = context.isTimeInSplitted
    ? parseTimeHMS(obj[IMPORT_FIELDS.TIME_IN_HH], obj[IMPORT_FIELDS.TIME_IN_MM], obj[IMPORT_FIELDS.TIME_IN_SS])
    : obj[IMPORT_FIELDS.TIME_IN];

  let timeOut = context.isTimeOutSplitted
    ? parseTimeHMS(obj[IMPORT_FIELDS.TIME_OUT_HH], obj[IMPORT_FIELDS.TIME_OUT_MM], obj[IMPORT_FIELDS.TIME_OUT_SS])
    : obj[IMPORT_FIELDS.TIME_OUT];

  const duration = context.isDurationSplitted
    ? parseTimeHMS(undefined, obj[IMPORT_FIELDS.DURATION_MM], obj[IMPORT_FIELDS.DURATION_SS])
    : obj[IMPORT_FIELDS.DURATION];

  if (timeIn && timeOut) {
    return {
      // If timeIn and timeOut are set, we calculate the duration
      // regardless whether duration was defined by the user or not
      duration: getTimeFromDiff(timeOut, timeIn, 'subtract'),
      timeIn,
      timeOut,
    };
  }
  if ((!timeIn && !timeOut) || !duration) return { timeIn, timeOut, duration };
  if (!timeIn) {
    // At this point we know timeOut and duration are set
    timeIn = getTimeFromDiff(timeOut, duration, 'subtract');
  } else {
    // if we are at this point is because timeIn and duration are set
    // but timeOut is not
    timeOut = getTimeFromDiff(timeIn, duration, 'add');
  }
  return { timeIn, timeOut, duration };
};

/**
 *
 * @param {String} a Valid HH:mm:ss time to add or subtract from
 * @param {String} b Valid HH:mm:ss time to be added or subtracted from the first time
 * @param {String} type defines if the operation is add or subtract
 * @returns {String} Valid time between 00:00:00 and 23:59:59
 */
export const getTimeFromDiff = (a, b, type) => {
  const timeObjectA = a.split(':');
  const timeObjectB = b.split(':');
  const durationMsA = dayjs
    .duration({ hours: timeObjectA[0], minutes: timeObjectA[1], seconds: timeObjectA[2] })
    .asMilliseconds();
  const durationMsB = dayjs
    .duration({ hours: timeObjectB[0], minutes: timeObjectB[1], seconds: timeObjectB[2] })
    .asMilliseconds();
  const added = durationMsA + durationMsB;
  const subtracted = durationMsA - durationMsB;

  const result = type === 'add' ? added : subtracted;
  const dayInMilliseconds = 1000 * 60 * 60 * 24;
  if (result > dayInMilliseconds) return '23:59:59';
  if (result < 0) return '00:00:00';
  // dayjs doesn't like converting milliseconds to time.
  return new Date(result).toISOString().slice(11, 19);
};

/**
 * @param {Object} obj
 * @param {<{isWriterPublisherMapped: Boolean, isWriterFullNameMapped: Boolean}>} context
 * @returns {Array<Object>} Rights owners matching qwire schema
 */
export const calculateRightsOwners = (obj, { isWriterFullNameMapped, isWriterPublisherMapped }) => {
  const rightsOwners = [];
  const role = obj[IMPORT_FIELDS.ROLE];

  const repeatedArray = obj[IMPORT_REPEATED_ARRAY];
  if (repeatedArray?.length) {
    /**
     * If there was a recursive operator in at least one of the cells,
     * then we calculate the rights owners for those generated rows,
     * and push them into the array of Rights Owners.
     *
     * Note that we return the rights owners at this step, but we still analyze
     * the rest of the received object. This is because we might get some fields which
     * were defined recursively and some other which weren't.
     */
    rightsOwners.push(
      ...repeatedArray
        .map((line, index) =>
          calculateRightsOwners(
            // Role is a shared field, meaning that, for every Rights Owner we will default
            // the role to obj.role if it wasn't provided on that specific line.
            // In practise, as we can't have the same pill selected twice,
            // it would be either 'use the same role for everyone' or 'use the role
            // that is indicated in each line', but not a combination of both.
            // Note that if the user mapped a list of Role + other fields in a Publishers related cell
            // and single value cells for a single writer (or vice versa), the role for the writer will
            // be taken from the first element of that list, since the role is independent of the type,
            // and the values are merged into the first row
            {
              // The fields that weren't defined recursively are spread only into the first row
              // for the algorithm to take them into consideration only once.
              ...(index === 0 ? omit([IMPORT_REPEATED_ARRAY])(obj) : {}),
              [IMPORT_FIELDS.ROLE]: role,
              ...line,
            },
            { isWriterFullNameMapped, isWriterPublisherMapped },
          ),
        )
        .flat(),
    );
    return rightsOwners;
  }

  // Some cue sheets, when imported will include 1 column that contains writers and publishers in the same column.
  if (isWriterPublisherMapped) {
    const type = getRightsOwnerTypeFromRole(role);
    // If the type is undefined because the role doesn't match any valid writer/publisher, return the empty array
    if (!type) return rightsOwners;
    const name = obj[IMPORT_FIELDS.WRITER_PUBLISHER];
    // If the rights owner doesn't have a name, skip it and return empty array
    if (!name) return rightsOwners;
    const rightsOwner = {
      name,
      role,
      share:
        obj[IMPORT_FIELDS.WRITER_PUBLISHER_SHARE] ||
        (type === RIGHTS_OWNER_TYPES.WRITER ? obj[IMPORT_FIELDS.WRITER_SHARE] : obj[IMPORT_FIELDS.PUBLISHER_SHARE]),
      type,
      writerAffiliation:
        obj[IMPORT_FIELDS.WRITER_PUBLISHER_PRO] ||
        (type === RIGHTS_OWNER_TYPES.WRITER ? obj[IMPORT_FIELDS.WRITER_PRO] : obj[IMPORT_FIELDS.PUBLISHER_PRO]),
    };
    rightsOwners.push(rightsOwner);
    return rightsOwners;
  }
  const writerName = isWriterFullNameMapped
    ? obj[IMPORT_FIELDS.WRITER]
    : concatStrings(' ')(obj[IMPORT_FIELDS.WRITER_FIRST_NAME], obj[IMPORT_FIELDS.WRITER_LAST_NAME]);
  // If the Writer doesn't have a name, skip it
  if (writerName) {
    const rightsOwnerWriter = {
      name: writerName,
      // If the Writer role is not provided, set it to Composer
      role: role || RIGHTS_OWNER_ROLE.Composer,
      share: obj[IMPORT_FIELDS.WRITER_SHARE] || obj[IMPORT_FIELDS.WRITER_PUBLISHER_SHARE],
      type: RIGHTS_OWNER_TYPES.WRITER,
      writerAffiliation: obj[IMPORT_FIELDS.WRITER_PRO] || obj[IMPORT_FIELDS.WRITER_PUBLISHER_PRO],
    };
    rightsOwners.push(rightsOwnerWriter);
  }

  const publisher = obj[IMPORT_FIELDS.PUBLISHER];
  // If the Publisher doesn't have a name, skip it
  if (publisher) {
    const rightsOwnerPublisher = {
      name: publisher,
      // If the Publisher role is not provided, set it to E - Original Publisher
      role: role || PUBLISHERS_TYPES.E,
      share: obj[IMPORT_FIELDS.PUBLISHER_SHARE] || obj[IMPORT_FIELDS.WRITER_PUBLISHER_SHARE],
      type: RIGHTS_OWNER_TYPES.PUBLISHER,
      writerAffiliation: obj[IMPORT_FIELDS.PUBLISHER_PRO] || obj[IMPORT_FIELDS.WRITER_PUBLISHER_PRO],
    };
    rightsOwners.push(rightsOwnerPublisher);
  }
  return rightsOwners;
};

/**
 * Function that receives a String and returns same string
 * but without special characters nor spaces and uppercased
 */
export const parseAndUpper = compose(toUpper, replace(/[^A-Za-z0-9]/gi, ''));

const WRITER_ROLES = compose(
  map(parseAndUpper),
  concat([
    'Adapter',
    'Arranger',
    'Composer',
    'Composer/Author',
    'Sub Arranger',
    'Sub Author',
    'Associated Performer',
    'Translator',
    'Author, Writer, Lyricist',
  ]),
  Object.values,
)(RIGHTS_OWNER_ROLE);

const PUBLISHER_ROLES = compose(
  map(parseAndUpper),
  concat([
    'Original Publisher',
    'Sub Publisher',
    'Substitute Publisher',
    'Publisher Income Participant',
    'Administrator',
  ]),
  Object.values,
)(PUBLISHERS_TYPES);

/**
 * Determines whether to map the column to a writer or to a publisher.
 * @param {String} role
 */
export const getRightsOwnerTypeFromRole = (role) => {
  if (!role) return undefined;
  const parsedRole = parseAndUpper(String(role));
  if (WRITER_ROLES.includes(parsedRole)) return RIGHTS_OWNER_TYPES.WRITER;
  if (PUBLISHER_ROLES.includes(parsedRole)) return RIGHTS_OWNER_TYPES.PUBLISHER;
  return undefined;
};

/**
 * Receives a comma separated string and returns an array of trimmed values without duplicates
 */
export const parseStringToArray = ifElse(
  Boolean,
  compose(filter(Boolean), uniq, map(trim), split(','), String),
  // Return empty array for falsy values
  always([]),
);

export const shouldGoToTracksCompareStep = compose(
  Boolean,
  length,
  filter(({ artists, type }) => type !== TRACK_TYPES.SCORE && artists.length),
);

export const getTracksCompareFromCueSheet = map(({ artists, rightsOwners, title, libraryTrackId }) => ({
  artists,
  libraryTrackId,
  title,
  writers: compose(uniq, pluck('name'), filter(propEq('type', RIGHTS_OWNER_TYPES.WRITER)))(rightsOwners),
}));

/**
 * Receives an array with N cues with the cue sheet generated at step 4,
 * and another array with N compare items with the user picks from qtracks data.
 * As we don't have any id to unequivocally match cueSheet with tracksCompare,
 * we use the position, which is trustworthy since it comes from the backend
 * in the predefined order
 * @param {Array<Object>} cueSheet
 * @param {Array<{importedData: Object, qtrackSelected: Boolean, qwireTracksData: Object}>} tracksCompare
 */
export const mergeCueSheetWithTracksCompare = (cueSheet, tracksCompare) =>
  cueSheet.map((cue, i) => {
    // If the qtracks side of data wasn't selected, return imported cue
    if (!tracksCompare[i]?.qtrackSelected) return cue;
    // Otherwise return the imported cue data with the qwireTracksId and the correct type
    return { ...cue, type: tracksCompare[i].qwireTracksData.type, qwireTracksId: tracksCompare[i].qwireTracksData.id };
  });

/**
 * @param {Array<Array<String>>} file
 * @param {Number} column
 * Returns all uniq values inside the file for a specific column
 */

export const getAllUniqValues = (file, column) =>
  compose(filter(Boolean), uniqBy(toLower), map(compose(trim, propOr('', column))))(file);

/**
 * Function that receives the mappings object and returns a { [field]: Boolean } object
 * with the mapped fields.
 * Usefull for constructing the mappedFields object with the response we get from
 * the backend.
 */
export const getMappedFieldsFromMappings = ifElse(
  Boolean,
  compose(
    reduce((prev, field) => ({ ...prev, [field]: true }), {}),
    flatten,
    // Omitting dividers
    map(filterIndexed((_, i) => i % 2 === 0)),
    Object.values,
  ),
  always({}),
);

/**
 * Returns usage mappings merging backend response with the values present in the uploaded file
 * @param {Object} mappings
 * @param {Object} usageMappings
 * @param {Array<Array<String>>} file
 */
export const getUsageMappingsFromUsage = (mappings, usageMappings, file) => {
  if (!mappings || !usageMappings) return {};
  const col = compose(
    Number,
    prop('0'),
    find(([_, value]) => value?.includes(IMPORT_FIELDS.USAGE)),
    Object.entries,
  )(mappings);

  if (isNaN(col)) return usageMappings;

  const uniqUsages = getAllUniqValues(file, col);

  return uniqUsages.reduce(
    (prev, curr) => ({
      ...prev,
      [curr]: prev[curr] || getTrackUseTypeName(curr.toUpperCase()),
    }),
    usageMappings,
  );
};

/**
 *
 * @param {Array<String>} fields
 * @param {Array<String>} dividers
 * @returns {Boolean}
 */
export const getShouldRepeatPattern = (fields, dividers) =>
  // There is at least one mapping
  Boolean(fields.length) &&
  // And the last pill is a divider
  fields.length === dividers.length &&
  // And every field is related to Rights Owners information
  fields.every((field) => IMPORT_RIGHTS_OWNERS_PILLS[field]);
