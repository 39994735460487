/* eslint-disable react/jsx-props-no-spreading */
import { Grid } from '@mui/material';
import { Text } from 'components/styleguide';
import { NORMALIZED_PRODUCTION_TYPES } from 'utils/constants';
import { useRootTranslation } from 'utils/hooks';
import { Body } from './Body';

const HeaderText = ({ children, align = 'center' }) => (
  <Text align={align} semibold>
    {children}
  </Text>
);

const Title = ({ children }) => (
  <Text variant="h6" gutterBottom>
    {children}
  </Text>
);

const HeaderRow = ({ productionType }) => {
  const t = useRootTranslation('drawers.clearance.fullList');
  const SceneAssetName = productionType === NORMALIZED_PRODUCTION_TYPES.MKT ? t('assetName') : t('sceneName');
  const SceneAssetNumber = productionType === NORMALIZED_PRODUCTION_TYPES.MKT ? t('assetNumber') : t('sceneNumber');
  return (
    <Grid container item direction="row" style={{ paddingLeft: '8px' }}>
      <Grid item xs={1} />
      <Grid item xs={1}>
        <HeaderText align="left">{SceneAssetNumber}</HeaderText>
      </Grid>
      <Grid item xs={3}>
        <HeaderText align="left">{SceneAssetName}</HeaderText>
      </Grid>
      <Grid item xs={1}>
        <HeaderText>{t('using')}</HeaderText>
      </Grid>
      <Grid item xs={2}>
        <HeaderText>{t('reqUsage')}</HeaderText>
      </Grid>
      <Grid item xs={2}>
        <HeaderText>{t('actUsage')}</HeaderText>
      </Grid>
      <Grid item xs={1}>
        <HeaderText>{t('feeAllocation')}</HeaderText>
      </Grid>
      <Grid item xs={1} />
    </Grid>
  );
};

export const Expanded = ({ cue, productionType, ...rest }) => {
  const t = useRootTranslation('drawers.clearance.fullList');
  const { cues } = cue;

  return (
    <>
      <Title>{t('title')}</Title>
      <Grid container direction="column" spacing={2}>
        <HeaderRow productionType={productionType} />
        <Grid container>
          {cues.map((cueItem, cueIndex) => (
            <Body key={`cueItem${cueItem.id}`} cue={cueItem} index={cueIndex} isOnlyOne={cues.length === 1} {...rest} />
          ))}
        </Grid>
      </Grid>
    </>
  );
};
