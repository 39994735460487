/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-use-before-define */
/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import { getIn } from 'formik';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import compose from 'ramda/src/compose';
import join from 'ramda/src/join';
import map from 'ramda/src/map';
import remove from 'ramda/src/remove';
import toUpper from 'ramda/src/toUpper';
// eslint-disable-next-line import/no-cycle
import { Clear, SeeAll, Text } from 'components/styleguide';

import { concatStrings } from 'utils/index';
import { useRootTranslation } from 'utils/hooks';
import useStyles from './styles';

const defaultRenderChips = map(
  ({ name, title, firstName, lastName }) => name || title || concatStrings(' ')(firstName, lastName),
);

const defaultRenderValues = compose(join(', '), defaultRenderChips);

function Items({ btnLabel, data, dirty, items = [], showCaretLabel, showDivider, readOnly }) {
  const { t } = useTranslation();
  const tGlobal = useRootTranslation('global');

  const buttonLabel = compose(toUpper, tGlobal)(readOnly ? 'seeAll' : btnLabel);

  return (
    <Box marginTop={0.75}>
      {items.map(({ action, hidden, label, name, onDelete, renderValues, withChips, withTextRow, testId }, index) => {
        const getLabels = renderValues || (withChips ? defaultRenderChips : defaultRenderValues);
        if (hidden) return undefined;
        const values = getIn(data, name) || [];
        const seeAllComponent = (
          <SeeAll
            label={buttonLabel}
            showCaretLabel={showCaretLabel}
            onClick={() =>
              action(
                {
                  ...data,
                  dirty: dirty || data.dirty,
                  secondaryOpened: true,
                },
                { readOnly },
              )
            }
          />
        );
        const Component = withTextRow ? TextAndClearRow : withChips ? ChipItem : TextItem;
        return (
          // eslint-disable-next-line react/no-array-index-key
          <Box key={index} marginBottom={withChips ? 1 : 2.5}>
            <Component
              getLabels={getLabels}
              label={t(label)}
              onDelete={onDelete}
              seeAllComponent={seeAllComponent}
              values={values}
              testId={testId}
            />
            {showDivider && <Divider />}
          </Box>
        );
      })}
    </Box>
  );
}

Items.propTypes = {
  btnLabel: PropTypes.oneOf(['seeAll', 'addItem', 'select']),
  data: PropTypes.object,
  dirty: PropTypes.bool,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      name: PropTypes.string,
      action: PropTypes.func.isRequired,
      renderValues: PropTypes.func,
      testId: PropTypes.string,
    }),
  ).isRequired,
  readOnly: PropTypes.bool,
  showCaretLabel: PropTypes.bool.isRequired,
  showDivider: PropTypes.bool.isRequired,
};

Items.defaultProps = {
  btnLabel: 'addItem',
  data: {},
  items: [],
  showCaretLabel: true,
  showDivider: false,
};

export default Items;

function ChipItem({ label, seeAllComponent, getLabels, onDelete, values, testId }) {
  const classes = useStyles();
  const labels = values?.length ? getLabels(values) : [];
  return (
    <Grid container alignItems="center" spacing={1} data-testid={testId}>
      <Grid item xs container>
        <Text variant="formLabel" color="textPrimary">
          {label}
        </Text>
      </Grid>
      <Grid item xs={6}>
        {seeAllComponent}
      </Grid>
      <Grid item container>
        {labels.map((v, i) => (
          <Chip
            key={v}
            className={classes.chip}
            color="primary"
            label={v}
            onDelete={() => onDelete && onDelete(remove(i, 1)(values), values[i])}
          />
        ))}
      </Grid>
    </Grid>
  );
}

function TextItem({ label, getLabels, seeAllComponent, values, testId }) {
  const classes = useStyles();
  const selectedLabel = Boolean(values.length) && getLabels(values);
  return (
    <Grid container data-testid={testId}>
      <Grid item xs={9} className={classes.labelWrapper}>
        <Text variant="formLabel" color="textPrimary" bold>
          {label}
        </Text>
      </Grid>
      <Grid item xs={3}>
        {seeAllComponent}
      </Grid>
      {selectedLabel && (
        <Grid container alignItems="center">
          <Text noWrap variant="caption">
            {selectedLabel}
          </Text>
        </Grid>
      )}
    </Grid>
  );
}

function TextAndClearRow({ label, seeAllComponent, getLabels, onDelete, values, testId }) {
  const labels = values?.length ? getLabels(values) : [];

  return (
    <Grid container alignItems="center" spacing={1} data-testid={testId}>
      <Grid item xs container>
        <Text variant="formLabel" color="textPrimary" bold>
          {label}
        </Text>
      </Grid>
      <Grid item xs={6}>
        {seeAllComponent}
      </Grid>
      {Boolean(labels.length) && <Divider />}
      <Grid item container>
        {labels.map((v, i) => (
          <Grid container key={v} alignItems="center">
            <Grid item xs={10}>
              <Text color="textSecondary">{v}</Text>
            </Grid>
            <Grid item xs={2}>
              <Clear size={20} onClick={() => onDelete && onDelete(remove(i, 1)(values), values[i])} iconOpacity />
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
}

const contentComponentProps = {
  getLabels: PropTypes.func,
  label: PropTypes.string,
  seeAllComponent: PropTypes.element,
  values: PropTypes.array,
};

ChipItem.propTypes = {
  ...contentComponentProps,
  onDelete: PropTypes.func,
};

TextItem.propTypes = contentComponentProps;

TextAndClearRow.propTypes = {
  ...contentComponentProps,
  onDelete: PropTypes.func,
};
