/* eslint-disable no-nested-ternary */
import { makeStyles } from '@material-ui/core';
import { appColors } from '../theme';

export const useExpansionPanelSummaryStyles = makeStyles(() => ({
  root: ({ backgroundColor }) => ({
    backgroundColor:
      backgroundColor === 'gray'
        ? appColors.lightGrey
        : backgroundColor === 'lightGray'
        ? appColors.lightGrey
        : appColors.white,
    '&$expanded': {
      minHeight: '48px',
    },
  }),
  content: ({ compact }) =>
    compact && {
      alignItems: 'center',
      margin: 0,
      '&$expanded': {
        margin: 0,
      },
    },
  expanded: ({ compact }) =>
    compact && {
      margin: 0,
    },
}));

export const useExpansionPanelGridStyles = ({ compact }) => ({
  alignItems: compact ? 'center' : 'initial',
});
