/* eslint-disable react/no-children-prop */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState, useMemo } from 'react';
import Form from 'components/common/Form';
import {
  FormButton,
  FormLeftLabelInput,
  CompaniesSearchField,
  StateField,
  FormTypeAhead,
} from 'components/common/Form/Fields';
import { BaseDrawer, DrawerHeader } from 'components/styleguide';
import { Box, Divider } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useFetch } from 'utils/hooks';
import { getCountriesCall } from 'redux-core/territories/services';
import { getDefaultCountryName } from 'utils/constants';
import PropTypes from 'prop-types';
import prop from 'ramda/src/prop';
import { CommonDrawerPropTypes } from '../constants';

const getLabel = (name) => `drawers.productionSetup.productionCompanies.${name}`;

const validationSchema = Yup.object().shape({
  companyName: Yup.string().required('global.forms.validations.required'),
  email1: Yup.string().email('global.forms.validations.types.email').nullable(),
  email2: Yup.string().email('global.forms.validations.types.email').nullable(),
});

const ProductionCompanyDrawer = ({ name, onClose, payload: { add, edit, onSubmit, ...payload } }) => {
  const [editMode, setEditMode] = useState(false);

  useEffect(() => setEditMode(add || edit), [add, edit]);
  const { t } = useTranslation();
  const sharedProps = {
    placeholder: editMode ? '' : '-',
    readOnly: !editMode,
  };

  const [countries] = useFetch(getCountriesCall);

  const defaultCountryName = useMemo(() => getDefaultCountryName(countries), [countries]);
  const onCancel = () => {
    onClose();
  };

  const fields = [
    {
      label: getLabel('name'),
      name: 'companyName',
      required: true,
      onSelect: (value, { setFieldValue }) => {
        const contactId = prop('contactId')(value);
        setFieldValue('contactId', contactId);
      },
      customComponent: <CompaniesSearchField testId="production-company-drawer-company" />,
    },
    {
      label: getLabel('address1'),
      name: 'address1',
    },
    {
      label: getLabel('address2'),
      name: 'address2',
    },
    {
      label: getLabel('city'),
      name: 'city',
    },
    {
      label: getLabel('state'),
      name: 'state',
      customComponent: <StateField testId="production-company-drawer-state" countries={countries} />,
    },
    {
      label: getLabel('zip'),
      name: 'zip',
    },
    {
      label: getLabel('country'),
      name: 'countryName',
      customComponent: (
        <FormTypeAhead isClearable={false} name="countryName" options={countries} valueKey="name" {...sharedProps} />
      ),
    },
    {
      label: getLabel('phone1'),
      name: 'phone1',
    },
    {
      label: getLabel('phone2'),
      name: 'phone2',
    },
    {
      label: getLabel('email1'),
      name: 'email1',
      type: 'email',
    },
    {
      label: getLabel('email2'),
      name: 'email2',
      type: 'email',
    },
    {
      label: getLabel('notes'),
      name: 'notes',
      textArea: true,
    },
  ];

  const initialValues = {
    countryName: defaultCountryName,
    ...payload,
    // we need that adjustment in order to show the companyName initially on edit
    companyName: payload.companyName ? { id: payload.companyName, companyName: payload.companyName } : '',
  };

  const handleSubmit = ({ companyName, ...values }) => {
    onSubmit({
      ...values,
      // we don't need to provide companyName as an object to the BE on edit
      companyName: companyName.companyName || companyName,
    });
  };

  return (
    <Form
      drawerName={name}
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      <DrawerHeader onClose={onClose} title={payload.name || t(getLabel('addTitle'))}>
        {editMode ? (
          <>
            <FormButton
              alwaysEnabled
              variant="destructive"
              size="xs"
              children="global.forms.cancel"
              type="button"
              onClick={() => onCancel()}
              testId="production-company-drawer-cancel"
            />
            <FormButton
              variant="primary"
              size="xs"
              children="global.forms.save"
              type="submit"
              testId="production-company-drawer-save"
            />
          </>
        ) : (
          <FormButton
            alwaysEnabled
            variant="primary"
            size="xs"
            children="global.forms.edit"
            testId="production-company-drawer-edit"
            type="button"
            onClick={() => setEditMode(true)}
          />
        )}
      </DrawerHeader>
      <Box ml={0.5}>
        {fields.map((props) => (
          <Box key={props.name}>
            <FormLeftLabelInput {...sharedProps} {...props} />
            <Divider />
          </Box>
        ))}
      </Box>
    </Form>
  );
};

ProductionCompanyDrawer.propTypes = {
  name: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  payload: PropTypes.shape({ id: PropTypes.number }),
};

const Drawer = ({ open, onClose, ...props }) => (
  <BaseDrawer open={open} onClose={onClose}>
    <ProductionCompanyDrawer onClose={onClose} {...props} />
  </BaseDrawer>
);

Drawer.propTypes = CommonDrawerPropTypes;

export default Drawer;
