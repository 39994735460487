import PropTypes from 'prop-types';

const customPropTypes = {
  // eslint-disable-next-line consistent-return
  setFieldValue: (props, propName) => {
    if (Object.keys(props).length < 2) {
      return new Error(`Too few arguments for ${propName}`);
    }
  },
};

export const FieldPropTypes = {
  field: PropTypes.any.isRequired,
  form: PropTypes.shape({
    setFieldValue: customPropTypes.setFieldValue,
    touched: PropTypes.object,
    errors: PropTypes.object,
    isSubmitting: PropTypes.bool,
  }),
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  textArea: PropTypes.bool,
  readOnly: PropTypes.bool,
  type: PropTypes.string,
  testId: PropTypes.string,
};
