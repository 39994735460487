import { Autocomplete, Skeleton, TextField } from '@mui/material';
import { useDispatch } from 'react-redux';
import { concatStrings } from 'utils';
import { rootTranslation } from 'utils/format';
import { useFetch } from 'utils/hooks';
import { fetchCueContainersLight } from 'redux-core/cueContainers/actions';

const tGlobal = rootTranslation('global');

type TOption = {
  id: number;
  title: string;
  number: unknown;
  unassignedBin: boolean;
  label: string;
};

type ScenePickerProps = {
  value: TOption['id'];
  onChange: (nextValue: TOption) => void;
  disabled?: boolean;
};

export function ScenePicker({ value, onChange, disabled }: ScenePickerProps) {
  const dispatch = useDispatch();
  const [options, loading] = useFetch(async () => {
    const cueContainers = await dispatch(fetchCueContainersLight());
    return cueContainers?.map((cueContainer) => ({
      ...cueContainer,
      label: concatStrings(' - ')(cueContainer.number, cueContainer.title),
    }));
  }, []);

  const selected = options?.find((it) => it.id === value);

  if (loading) {
    <Skeleton />;
  }

  return (
    <Autocomplete
      id="cueContainerId"
      key={`ScenePicker-${selected?.id}`}
      readOnly={disabled}
      fullWidth
      disableClearable
      disablePortal
      options={options}
      value={selected}
      onChange={(_, nextValue: TOption) => {
        onChange(nextValue);
      }}
      isOptionEqualToValue={(option, optionValue) => option.id === optionValue.id}
      getOptionLabel={(option) => option.title}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={tGlobal('forms.chooseOne')}
          inputProps={{
            ...params.inputProps,
          }}
        />
      )}
      noOptionsText={tGlobal('noResults')}
    />
  );
}
