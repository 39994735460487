import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    backgroundColor: 'transparent',
    width: 338,
    minHeight: 245,
    zIndex: 1000,
  },
  menu: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: 245,
  },
  menuContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(),
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  menuRow: {
    marginLeft: theme.spacing(2),
  },
  menuLinkText: {
    marginLeft: theme.spacing(),
  },
}));

export default styles;
