/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable arrow-body-style */
/* eslint-disable import/no-cycle */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormikContext } from 'formik';
import c from 'ramda/src/compose';
import filter from 'ramda/src/filter';
import find from 'ramda/src/find';
import map from 'ramda/src/map';
import omit from 'ramda/src/omit';
import path from 'ramda/src/path';
import pathOr from 'ramda/src/pathOr';
import propEq from 'ramda/src/propEq';
import whereEq from 'ramda/src/whereEq';
import uniq from 'ramda/src/uniq';
import toUpper from 'ramda/src/toUpper';

import { concatStrings } from 'utils';
import {
  getDefaultTemplates,
  getTemplateLetterType,
  fetchTemplates,
  setActiveStep,
  setActiveDraftLetter,
  checkLettersQueue,
} from 'redux-core/letters/actions';
import { getDownloadURL } from 'components/common/BaseOnlyOffice/utils';
import { getLetterTypeSelector, getLettersSelector, getSelectedLettersForStep3 } from 'redux-core/letters/selectors';
import { isEmptyObject } from 'utils/object';
import { STEPS } from 'components/Letters/constants';
import { TRACK_STATUS, LETTER_TYPES } from 'utils/constants';
// import { LICENSOR_TYPES } from 'utils/constants';
import { useFormSubscription, useRootTranslation, useFetch, useDeepCompareMemoize, usePrevious } from 'utils/hooks';

const replaceSpecialChar = (char = '', val = '') => {
  const regex = new RegExp(`\\${char}`, 'g');
  return (s) => s.replace(regex, val);
};

const getLetterName = (...args) =>
  c(replaceSpecialChar('/', ' '), replaceSpecialChar('.', ' '), concatStrings('-'))(...args);

export const NOT_FORMATED_DOMAIN = 'amazonaws';

export const getFilteredByROId = (letters = [], currentLetter = {}) => {
  if (!letters.length || isEmptyObject(currentLetter)) return [];

  return letters.filter((letter) => {
    // Should filter only options, which belong to the same RO
    return (
      letter.licensor.qwireTracksRightsOwnerId === currentLetter.licensor.qwireTracksRightsOwnerId &&
      // if the selected clearance is already an option, have to exclude it from the linkable options list
      letter.clearanceId !== currentLetter.clearanceId &&
      letter.clearance.option
    );
  });
};

export const getQclearLicensorIds = (letters, optionsQclearLicensorIds, licensorId, isBundle) => {
  const selectedLettersIds = map((letter) => letter.licensorId)(letters);
  const optionalClearancesSelected = c(
    map((clearance) => clearance.licensorId),
    filter((clearance) => clearance.checked),
  )(optionsQclearLicensorIds);

  if (isBundle) return uniq([...selectedLettersIds, ...optionalClearancesSelected]);

  return uniq([licensorId, ...optionalClearancesSelected]);
};

export const getFilteredTemplates = ({ letterType, licensorType, templates }) => {
  if (letterType) return filter(whereEq({ letterType, licensorType }), templates);

  return filter(propEq('licensorType', licensorType))(templates);
};

export const getNextLicensorStatus = (status) => {
  switch (status) {
    case TRACK_STATUS.NEW:
    case TRACK_STATUS.REQUOTE:
    case TRACK_STATUS.QUOTE_REQ_SENT:
      return TRACK_STATUS.QUOTE_REQ_SENT;
    case LETTER_TYPES.QUOTE_REQUEST:
      return TRACK_STATUS.QUOTE_REQ_SENT;
    case LETTER_TYPES.CONFIRMATION:
      return TRACK_STATUS.CONF_SENT;
    case LETTER_TYPES.LICENSE_REQUEST:
      return TRACK_STATUS.LICENSE_REQUEST_SENT;
    case LETTER_TYPES.LICENSE:
      return TRACK_STATUS.LICENSE_SENT;
    case LETTER_TYPES.QUOTE_RENEWAL:
      return TRACK_STATUS.QUOTE_REQ_SENT;
    case LETTER_TYPES.CHECK_REQUEST:
      return TRACK_STATUS.CHECK_REQUEST_SENT;
    default:
      return status;
  }
};

export const getOOUrlsAndFormUpdate = async (letterFormat, setFieldValue) => {
  // DO not use Promise.all for the following lines
  const url = await getDownloadURL();

  // FIXME:  find a better solution than setTimeout
  // Sometimes only office responds with an old value, causing the second call to
  // return the same as the first one. We can bypass it by waiting a couple
  // of ms. 300 is an arbitrary number
  // eslint-disable-next-line no-promise-executor-return
  await new Promise((r) => setTimeout(r, 300));
  const formattedUrl = letterFormat === 'pdf' && (await getDownloadURL(letterFormat));
  setFieldValue('letter.letterUrl', url);
  setFieldValue('letter.formattedUrl', formattedUrl);
  setFieldValue('letter.hasAttachment', '1');
  setFieldValue('letter.isFromStep4', true);
};

export const useLettersBusinessLogic = () => {
  const letterType = useSelector(getLetterTypeSelector);
  const letters = useSelector(getSelectedLettersForStep3);
  const lettersStep1 = useSelector(getLettersSelector);
  const dispatch = useDispatch();
  const tTypes = useRootTranslation('letters.types');
  const [selectedLicensor, setLicensorId] = useState();
  const [, loading] = useFetch(() => dispatch(fetchTemplates()));
  const {
    setFieldValue,
    values: { clearanceId, licensorId },
  } = useFormikContext();
  const prevLicensorId = usePrevious(licensorId);

  const onLicensorChange = async (licensorId, setFieldValue, { resetForm, initialValues }) => {
    resetForm();
    const licensor = letters.find(propEq('licensorId', licensorId)) || {};
    const filteredByROId = getFilteredByROId(lettersStep1, licensor);

    const currentExpiration = path(['licensor', 'dates', 'quoteExpiresAt'], licensor);
    setFieldValue('currentExpiration', currentExpiration);

    if (licensorId !== initialValues.licensorId || !initialValues.expirationDate) {
      setFieldValue('expirationDate', currentExpiration);
    }
    setFieldValue('clearanceId', licensorId ? licensor.clearanceId : undefined);
    setLicensorId(licensorId);
    setFieldValue('licensorId', licensorId);
    setFieldValue('qwireTracksRightsOwnerId', pathOr(null, ['licensor', 'qwireTracksRightsOwnerId'], licensor));
    setFieldValue('optionsQclearLicensorIds', filteredByROId);

    dispatch(setActiveStep(STEPS.CONTACT));
  };
  // Avoid re-calling onLicensorChange when letters dependency change because of
  // a change in the draft id
  const memoLetters = useDeepCompareMemoize(letters.map(omit(['draftId'])));
  useFormSubscription('licensorId', onLicensorChange, [memoLetters]);

  const onLetterChange = async (letterId, setFieldValue) => {
    const licensor = letters.find(propEq('licensorId', licensorId)) || {};

    if (licensor.clearance && letterId) {
      // Business Logic
      const { status } = licensor.clearance;
      const templateLetterType = await dispatch(getTemplateLetterType(letterId));
      const filteredByROId = getFilteredByROId(lettersStep1, licensor);

      setFieldValue('letter.licensorStatus', getNextLicensorStatus(templateLetterType || letterType || status));
      setFieldValue('optionsQclearLicensorIds', filteredByROId);
    }

    const newSelected = find(propEq('licensorId', licensorId))(letters);

    if (newSelected) {
      const attachmentName = getLetterName(
        newSelected.trackTitle,
        newSelected.productionPath[0].name,
        newSelected.licensor.name,
        toUpper(newSelected.licensor.type),
        letterType ? tTypes(letterType) : '',
      );
      setFieldValue('email.attachmentName', attachmentName);
    }
  };

  useFormSubscription('letter.templateId', onLetterChange);

  useFormSubscription('contact.email', (v, setFieldValue) => {
    if (v) setFieldValue('email.to', [v]);
  });

  useEffect(
    () => {
      if (licensorId && clearanceId) dispatch(setActiveDraftLetter(licensorId, clearanceId));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [licensorId, clearanceId],
  );

  useEffect(
    () => {
      // If queue was empty, this will exit the letters workflow
      if (prevLicensorId && !licensorId) dispatch(checkLettersQueue());
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [licensorId],
  );

  useFetch(async () => {
    if (loading || !selectedLicensor) return;

    const [emailTemp, letterTemp] = await dispatch(getDefaultTemplates(selectedLicensor));

    setFieldValue('email.templateId', emailTemp);
    setFieldValue('letter.templateId', letterTemp);
  }, [loading, selectedLicensor]);
};
