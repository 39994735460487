import { baseApi } from 'services';

export type TCategory = {
  id: number;
  name: string;
};

export const categoriesApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getCategories: builder.query<TCategory[], void>({
      query: () => ({
        url: 'common/categories/get-all',
        method: 'POST',
      }),
    }),
  }),
});

export const { useGetCategoriesQuery } = categoriesApi;
