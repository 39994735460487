/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-children-prop */
import React from 'react';
import PropTypes from 'prop-types';
import Form from 'components/common/Form';
import { DrawerHeader } from 'components/styleguide/drawers/index';
import { FormButton } from 'components/common/Form/Fields/index';
import { useRootTranslation, useFetch } from 'utils/hooks';
import Grid from '@material-ui/core/Grid';
import { getAdvancedFiltersOptionsCall } from 'redux-core/cueContainers/services';
import pick from 'ramda/src/pick';
import omit from 'ramda/src/omit';
import {
  pickUsingValue,
  pickCompletedStatusesValue,
  USING_VALUE,
  pickUsingInitialValue,
  pickCompletedStatusesInitialValue,
} from '../Filters/constants';
import enhancer from './enhancer';

export function BaseAdvancedFilters({ children, onClose, validationSchema, params, filters: prevFilters, addParams }) {
  const t = useRootTranslation('drawers.advancedFilters');

  const [filtersData, loading] = useFetch(
    () =>
      getAdvancedFiltersOptionsCall({
        subprojectId: params.id,
      }),
    [params.id],
  );

  const handleResetForm = async (_, __, { resetForm }) => {
    await resetForm();
    // Pick simple filters and remove advanced filters
    const simpleFilters = pick(['query', 'type'], prevFilters);
    await addParams({ filters: simpleFilters }); // reset search
    onClose();
  };

  const handleSubmit = async (filters) => {
    const nextFilters = {
      ...prevFilters,
      ...filters,
      ...pickUsingValue(filters.using),
      ...pickCompletedStatusesValue(filters.completedStatuses, filtersData.completedStatuses),
    };
    await addParams({ filters: nextFilters });
    onClose();
  };

  const initialValues = {
    using: USING_VALUE.ALL,
    // omit simple filters and handle 'using' and 'completedStatuses' separately
    ...omit(['query', 'type', 'using', 'completedStatuses'], prevFilters),
    ...pickUsingInitialValue(prevFilters.using),
    ...pickCompletedStatusesInitialValue(prevFilters.completedStatuses),
  };

  return (
    <Form onSubmit={handleSubmit} initialValues={initialValues} validationSchema={validationSchema}>
      <DrawerHeader title={t('drawerTitle')}>
        <FormButton
          alwaysEnabled
          variant="destructive"
          testId="clearFilters-button"
          size="sm"
          children="global.clearFilters"
          type="button"
          onClick={handleResetForm}
        />
        <FormButton
          variant="primary"
          size="xs"
          children="global.forms.apply"
          testId="apply-button"
          alwaysEnabled
          type="submit"
        />
      </DrawerHeader>
      <Grid container>{children({ filtersData, loading })}</Grid>
    </Form>
  );
}

BaseAdvancedFilters.defaultValues = {
  validationSchema: {},
};

BaseAdvancedFilters.propTypes = {
  children: PropTypes.any.isRequired,
  onClose: PropTypes.func.isRequired,
  addParams: PropTypes.func.isRequired,
  params: PropTypes.object,
  validationSchema: PropTypes.object,
  filters: PropTypes.object.isRequired,
};

export default enhancer(BaseAdvancedFilters);
