import { FormButton, FormInputText } from 'components/common/Form/Fields';
import { SecuredForm } from 'components/common/Secured';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import { Delete } from '@material-ui/icons';
import { Box, Button } from '@mui/material';
import { TSegment } from 'components/ProductionOverview/CueSheet/CueSheetTable/CueSheetTableContent/types';
import { DrawerHeader } from 'components/styleguide';
import { createSegment, deleteSegments, updateSegment } from 'redux-core/cueSheet/actions';
import { getSelectedProductionObjectIdSelector } from 'redux-core/overview/selectors';
import { COMMON_PERMISSIONS, CUE_PERMISSIONS } from 'redux-core/permissions/constants';
import { useSelectedCuesIds } from 'components/ProductionOverview/CueSheet/cueStore';
import { useRootTranslation } from 'utils/hooks';
import withBaseDrawer from '../withBaseDrawer';

export type TCueSheetSegmentUpsertDrawer = {
  onClose: () => void;
  payload: {
    segment?: TSegment;
    clearCueSelection?: () => void;
  };
};

export const CueSheetSegmentUpsertDrawer = withBaseDrawer(
  ({ onClose, payload: { segment, clearCueSelection } }: TCueSheetSegmentUpsertDrawer) => {
    const t = useRootTranslation('drawers.cueSheetSegment');
    const tGlobal = useRootTranslation('global');

    const dispatch = useDispatch();
    const objectId = useSelector<number>(getSelectedProductionObjectIdSelector);
    const selectedCueIds = useSelectedCuesIds();

    const onSubmit = async ({ name }) => {
      if (!segment.id) {
        if (clearCueSelection) clearCueSelection();
        dispatch(createSegment({ name, cuesIds: selectedCueIds }));
      } else {
        dispatch(
          updateSegment({
            id: segment.id,
            name,
          }),
        );
      }
      onClose();
    };

    const deleteHandler = async () => {
      if (segment.id) {
        dispatch(deleteSegments([segment]));
      }
      onClose();
    };

    const drawerTitle = segment.id ? t('editTitle') : t('createTitle');

    return (
      <>
        <SecuredForm
          objectId={objectId}
          onSubmit={onSubmit}
          enableReinitialize
          initialValues={segment}
          validationSchema={validationSchema}
          permissions={COMMON_PERMISSIONS.UPDATE}
          cuePermissions={CUE_PERMISSIONS.CAN_ACCESS_CUE}
          clearPermissions={null}
          disabled={false}
          readOnly={false}
        >
          <DrawerHeader title={drawerTitle} hideBackButton>
            <Button size="small" variant="text" color="error" onClick={onClose}>
              {tGlobal('forms.cancel')}
            </Button>
            <FormButton variant="primary" size="xs" testId="save-button" type="submit">
              {tGlobal('forms.save')}
            </FormButton>
          </DrawerHeader>
          <FormInputText label={t('segmentNameLabel')} name="name" testId="cue-sheet-segment-name" autoFocus />
        </SecuredForm>
        {!!segment.id && (
          <Box pt={4}>
            <Button
              size="small"
              variant="text"
              color="error"
              sx={{
                lineHeight: '25px',
                display: 'flex',
                gap: '10px',
              }}
              onClick={deleteHandler}
            >
              <Delete /> {t('removeSegmentLabel')}
            </Button>
          </Box>
        )}
      </>
    );
  },
  {
    disableEnforceFocus: true,
  },
);

const validationSchema = Yup.object().shape({
  name: Yup.string().required('global.forms.validations.required'),
});
