/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-children-prop */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import classnames from 'classnames';
import { getLocalDate } from 'utils/format';
import { useRootTranslation } from 'utils/hooks';

import { BaseDrawer, DrawerHeader, Text } from 'components/styleguide';
import Form from 'components/common/Form';
import { FormButton, FormInputFormat, FormInputText } from 'components/common/Form/Fields';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import useStyles from './styles';
import enhancer from './enhancer';
import { CommonDrawerPropTypes } from '../constants';
import BudgetHistory from './BudgetHistory';

const rootT = 'drawers.productionSetup.budget';

const validationSchema = Yup.object().shape({
  budgetVersion: Yup.object().shape({
    reasonForChange: Yup.string().trim().required('global.forms.validations.required'),
    productionBudget: Yup.number().required('global.forms.validations.required'),
  }),
});

function BudgetDrawer({
  name,
  payload: { fetchBudget },
  onClose,
  saveProductionBudgetVersion,
  userName,
  versions = [],
}) {
  const classes = useStyles();
  const t = useRootTranslation(rootT);
  const [isEditing, setIsEditing] = useState(false);
  const disabledProps = { disabled: !isEditing, readOnly: !isEditing };
  const isInitialBudget = !versions?.length;
  const isInitialBudgetPreview = !isEditing && isInitialBudget;
  const currencyPrefix = '$';
  const onCancel = () => {
    onClose();
  };

  const initialValues = {
    budgetVersion: {},
  };

  if (isInitialBudget) {
    initialValues.budgetVersion.reasonForChange = t('reasonForChangeDefault');
  }

  return (
    <Form
      drawerName={name}
      onSubmit={async (values) => {
        await saveProductionBudgetVersion(values.budgetVersion);
        await fetchBudget();

        onClose();
      }}
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      <>
        <Grid
          container
          direction="column"
          className={classnames({
            [classes.drawerHeaderSpace]: !isInitialBudgetPreview,
          })}
        >
          <DrawerHeader title={t('drawerTitleBudget')} onClose={onClose} hideBackButton>
            <FormButton
              testId="budget-drawer-cancel"
              alwaysEnabled
              variant="destructive"
              size="xs"
              children="global.forms.cancel"
              onClick={() => onCancel()}
            />
            <FormButton
              alwaysEnabled
              testId="budget-drawer-save"
              children={isEditing ? 'global.forms.save' : 'global.forms.update'}
              size="xs"
              type={isEditing ? 'submit' : 'button'}
              variant="primary"
              onClick={() => setIsEditing(true)}
            />
          </DrawerHeader>
        </Grid>
        {isInitialBudgetPreview && (
          <Grid container className={classes.drawerHeaderSpace}>
            <Text color="textPrimary" variant="formLabel">
              {t('drawerHeaderInitialNote')}
            </Text>
          </Grid>
        )}
        <Grid container direction="column">
          {isEditing && (
            <>
              <Grid item container xs={12} spacing={1} direction="column" key="budgetHistoryData">
                <Grid item container xs={12} className={classes.budgetRow}>
                  <Grid item container xs={3}>
                    <Grid
                      item
                      xs={12}
                      className={classnames(classes.budgetField, {
                        withMargins: !isEditing,
                      })}
                    >
                      <Text color="textPrimary" variant="formLabel" bold>
                        {`${t('budget')}*`}
                      </Text>
                      <div className={classes.budgetInputWrapper}>
                        <FormInputFormat
                          name="budgetVersion.productionBudget"
                          prefix={currencyPrefix}
                          type="currency"
                          {...disabledProps}
                        />
                      </div>
                    </Grid>
                  </Grid>
                  <Grid item container xs={3}>
                    <Grid item xs={10}>
                      <div className={classes.label}>
                        <Text color="textPrimary" variant="formLabel" bold>
                          {t('date')}
                        </Text>
                      </div>
                      <Text color="textPrimary" variant="formLabel">
                        {getLocalDate(new Date())}
                      </Text>
                    </Grid>
                  </Grid>
                  <Grid item container xs={6}>
                    <Grid item xs={10}>
                      <div className={classes.label}>
                        <Text color="textPrimary" variant="formLabel" bold>
                          {t('user')}
                        </Text>
                      </div>
                      <Text color="textPrimary" variant="formLabel">
                        {userName}
                      </Text>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item container xs={8} direction={isEditing ? 'row' : 'column'}>
                  <FormInputText
                    label={`${t('reasonForChange')}*`}
                    name="budgetVersion.reasonForChange"
                    {...disabledProps}
                  />
                </Grid>
              </Grid>
              <Divider className={classes.divider} />
            </>
          )}
          <div className={classnames(classes.budgetHistory, { isEditing })}>
            {versions.map((version, index) => (
              <React.Fragment key={version.id}>
                <BudgetHistory version={version} classes={classes} />
                {versions.length - 1 !== index && <Divider className={classes.divider} />}
              </React.Fragment>
            ))}
          </div>
        </Grid>
      </>
    </Form>
  );
}

function Drawer({ open, onClose, ...props }) {
  return (
    <BaseDrawer open={open} onClose={onClose}>
      <BudgetDrawer onClose={onClose} {...props} />
    </BaseDrawer>
  );
}

BudgetDrawer.propTypes = {
  name: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  payload: PropTypes.object,
  saveProductionBudgetVersion: PropTypes.func.isRequired,
  versions: PropTypes.array.isRequired,
  userName: PropTypes.string.isRequired,
};

Drawer.propTypes = CommonDrawerPropTypes;

export default enhancer(Drawer);
