/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { List } from '@mui/material';

/**
 * Component built following the example of how react-select built their own MenuList component
 * @see https://github.com/JedWatson/react-select/blob/master/packages/react-select/src/components/Menu.js#L367
 */
const MenuList = (props) => {
  const { children, getStyles, innerRef } = props;

  const styles = {
    ...getStyles('menuList', props),
    overflowX: 'hidden',
  };

  return (
    <List {...props} style={styles} ref={innerRef}>
      {children}
    </List>
  );
};

MenuList.propTypes = {
  children: PropTypes.any,
  getStyles: PropTypes.func.isRequired,
  innerRef: PropTypes.func.isRequired,
};

export default MenuList;
