/* eslint-disable react/jsx-props-no-spreading */
import { Autocomplete, createFilterOptions, TextField } from '@mui/material';
import { FormControlLabelWrapper } from 'components/common/Form/Fields/FormField/FormField';
import { getInvitableUsers } from 'redux-core/productions/services';
import { useFetch, useRootTranslation } from 'utils/hooks';
import { User } from 'utils/users';

export type SelectUserFieldProps = {
  value: string | undefined;
  divisionId: number;
  objectId: number;
  onSelectUser: (email: User['email']) => void;
  showLabel: boolean;
  testId: string;
};

type TUserCreatableOption = User | { inputValue: string; displayName: string };

export const SelectUserField = ({
  value,
  divisionId,
  objectId,
  onSelectUser,
  showLabel,
  testId,
}: SelectUserFieldProps) => {
  const t = useRootTranslation();
  const filter = createFilterOptions();

  const [invitableUsersFound, loadingInvitableUsers] = useFetch(() => getInvitableUsers({ divisionId, objectId }));

  const handleFilterOptions = (options, params) => {
    const filtered = filter(options, params);

    const { inputValue } = params;

    const email = inputValue?.trim();
    const emailExists = !email?.length || invitableUsersFound.find((invitableUser) => invitableUser.email === email);
    if (emailExists) {
      return filtered;
    }

    filtered.push({
      inputValue: email,
      displayName: `${t('global.forms.createNew')} '${email}'`,
    });
    return filtered;
  };

  const onInputChange = (event, inputValue) => {
    if (!event) return;
    onSelectUser(inputValue);
  };

  const onChange = (_, newValue: TUserCreatableOption | string) => {
    if (typeof newValue === 'string') {
      onSelectUser(newValue);
    } else if ('inputValue' in newValue) {
      // Create a new value from the user input
      onSelectUser(newValue.inputValue);
    } else {
      onSelectUser(newValue.email);
    }
  };

  return (
    <FormControlLabelWrapper name="search-user" label={showLabel ? t('drawers.shareWithUser.labels.users') : null}>
      <Autocomplete
        loading={loadingInvitableUsers}
        value={value ?? ''}
        options={invitableUsersFound}
        disabled={false}
        onChange={onChange}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={t('drawers.shareWithUser.labels.searchUserPlaceholder')}
            data-testid={`autocomplete-field-${testId}`}
          />
        )}
        filterOptions={handleFilterOptions}
        renderOption={(props: any, option: TUserCreatableOption) => <li {...props}>{option.displayName}</li>}
        onInputChange={onInputChange}
        getOptionLabel={getInvitableUserLabel}
      />
    </FormControlLabelWrapper>
  );
};

const getInvitableUserLabel = (option: TUserCreatableOption | string) => {
  // Value selected with enter, right from the input
  if (typeof option === 'string') {
    return option;
  }
  // Add "xxx" option created dynamically
  if ('inputValue' in option) {
    return option.inputValue;
  }
  // Regular option
  const { displayName, email } = option;
  return `${displayName} - ${email}`;
};
