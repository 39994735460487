import { connect } from 'react-redux';
import { getLocationParametersDivisionId } from 'redux-core/router/selectors';
import { getSelectedTrackId } from 'redux-core/tracks/selectors';
import { getSelectedProductionIdSelector } from 'redux-core/overview/selectors';
import { showSnackbar, showGenericError } from 'redux-core/global-error/actions';

const mapStateToProps = (state) => ({
  productionId: getSelectedProductionIdSelector(state),
  divisionId: getLocationParametersDivisionId(state),
  trackId: getSelectedTrackId(state),
});
const mapDispatchToProps = {
  showSnackbar,
  showGenericError,
};

export default connect(mapStateToProps, mapDispatchToProps);
