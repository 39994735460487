import { connect } from 'react-redux';
import { getWatchingViewSelector } from 'redux-core/watching/selectors';
import compose from 'ramda/src/compose';
import withBaseDrawer from '../withBaseDrawer';

const mapStateToProps = (state) => ({
  watchingView: getWatchingViewSelector(state),
});

export default compose(connect(mapStateToProps), withBaseDrawer);
