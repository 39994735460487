/* eslint-disable import/no-cycle */
import i18next from 'i18next';
import { closeDrawer } from 'redux-core/dialogs/actions';
import { requestWithError } from 'utils/request';
import mergeAll from 'ramda/src/mergeAll';
import pick from 'ramda/src/pick';
import { upsertRights } from 'redux-core/rights/services';
import { getLocationParametersSelector } from 'redux-core/router/selectors';
import { NORMALIZED_PRODUCTION_TYPES } from 'utils/constants';
import {
  completeCampaignAssetCall,
  copyCampaignAssetCall,
  createCampaignAssetVersionCall,
  updateCampaignAssetVersionCall,
} from './services';
import { createCueContainer, getCueContainer, refetchCueContainersById, updateCueContainer } from '../actions';

export const createCampaignAsset = (payload) => async (dispatch) =>
  dispatch(createCueContainer({ ...payload, type: NORMALIZED_PRODUCTION_TYPES.MKT }));

export const getCampaignAsset = (payload) => async (dispatch) =>
  dispatch(getCueContainer({ ...payload, type: NORMALIZED_PRODUCTION_TYPES.MKT }));

export const updateCampaignAsset =
  (payload, prevValues, overwriteExistingClearancesRights = false) =>
  async (dispatch) =>
    dispatch(
      updateCueContainer(
        { ...payload, type: NORMALIZED_PRODUCTION_TYPES.MKT },
        prevValues,
        overwriteExistingClearancesRights,
      ),
    );

export const updateAssetRights = async (payload, prevValues, overwriteExistingClearancesRights = false) => {
  const { requiredRights, id, divisionId } = payload;
  await upsertRights({
    divisionId,
    cueContainerId: id,
    rights: requiredRights,
    overwriteExistingClearancesRights,
  });
};

export const copyCampaignAsset = (payload) => async (_, getState) => {
  const state = getState();
  const params = getLocationParametersSelector(state);
  const setupInfo = pick(['divisionId', 'tenantId'])(params);
  return requestWithError(copyCampaignAssetCall, mergeAll([payload, setupInfo]), {
    message: {
      success: i18next.t('cueContainerVersionManager.notifications.copy.success'),
    },
  });
};

export const updateAssetVersion = (payload) => async (_, getState) => {
  const state = getState();
  const params = getLocationParametersSelector(state);
  const setupInfo = pick(['divisionId', 'tenantId'])(params);
  return requestWithError(updateCampaignAssetVersionCall, mergeAll([payload, setupInfo]), {
    message: {
      success: i18next.t('cueContainerVersionManager.notifications.update.success'),
    },
  });
};

export const createCampaignAssetVersion = (payload) => async (_, getState) => {
  const state = getState();
  const params = getLocationParametersSelector(state);
  const setupInfo = pick(['divisionId'])(params);
  await requestWithError(createCampaignAssetVersionCall, mergeAll([payload, setupInfo]), {
    message: {
      success: i18next.t('drawers.versionManagerAddEdit.notifications.create.success'),
    },
  });
};

export const updateCampaignAssetVersion = (payload) => async (_, getState) => {
  const state = getState();
  const params = getLocationParametersSelector(state);
  const setupInfo = pick(['divisionId', 'tenantId'])(params);
  await requestWithError(updateCampaignAssetVersion, mergeAll([payload, setupInfo]), {
    message: {
      success: i18next.t('drawers.versionManagerAddEdit.notifications.update.success'),
    },
  });
};

export const completeCampaignAsset = (payload) => async (dispatch, getState) => {
  const state = getState();
  const params = getLocationParametersSelector(state);
  const setupInfo = pick(['divisionId', 'tenantId'])(params);
  await requestWithError(completeCampaignAssetCall, mergeAll([payload, setupInfo]), {
    message: {
      success: i18next.t('drawers.completeMaterial.complete.success'),
    },
  });
  dispatch(refetchCueContainersById(payload.id));
  dispatch(closeDrawer());
};
