/* eslint-disable react/no-children-prop */
import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Logo from 'assets/qwire-tracks-logo.svg';
import TrackVersion from 'components/common/TrackVersion';
import { FormCheckbox, FormButton } from 'components/common/Form/Fields/index';
import { Layout, Text } from 'components/styleguide';
import { useRootTranslation } from 'utils/hooks';
import useStyles from './styles';

const rootT = 'drawers.qtracksSync.header';

const Header = ({ qwireTracksId, setVersion, version, onCancel, tenantId }) => {
  const t = useRootTranslation(rootT);
  const classes = useStyles();

  return (
    <Layout className={classes.header} p={3.5} pb={4} container>
      <Grid item container xs={12} justify="space-between">
        <Grid item container xs={6} alignItems="center">
          <img src={Logo} className={classes.logo} alt={t('trackComparision')} />
          <Text variant="h5" children={t('trackComparision')} />
        </Grid>
        <Grid alignItems="center" container item justify="flex-end" spacing={1} xs>
          <Grid item>
            <FormButton
              alwaysEnabled
              children="global.forms.cancel"
              onClick={onCancel}
              size="sm"
              variant="destructive"
            />
          </Grid>
          <Grid item>
            <FormButton children="global.forms.save" size="sm" type="submit" variant="primary" />
          </Grid>
        </Grid>
      </Grid>
      <Grid item container xs={12} spacing={1} alignItems="flex-end">
        <Grid item xs={5}>
          <TrackVersion
            label={t('trackVersion')}
            onChange={setVersion}
            qwireTracksId={qwireTracksId}
            value={version}
            tenantId={tenantId}
          />
        </Grid>
        <Grid item xs>
          <FormCheckbox label={t('ignoreFuture')} name="ignoreFuture" />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Text children={t('title')} color="textPrimary" variant="body2" />
        <Box mb={1.5} />
        <Text bold color="textPrimary" variant="body2">
          {t('helpfulTip')}
        </Text>
        <Text color="textPrimary" variant="body2">
          {t('hint')}
        </Text>
      </Grid>
    </Layout>
  );
};

Header.propTypes = {
  onCancel: PropTypes.func.isRequired,
  qwireTracksId: PropTypes.number,
  setVersion: PropTypes.func.isRequired,
  version: PropTypes.number,
  tenantId: PropTypes.number,
};

export default Header;
