import { MenuItem, Select, Skeleton, TextField } from '@mui/material';
import { FormField } from 'components/common/Form/Fields/FormField/FormField';
import { useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { TLicensorContact } from 'services/clear/types';
import { useGetAllCountriesQuery } from 'services/common/countries';
import { getStatesByCountry } from 'utils';
import { rootTranslation } from 'utils/format';

const t = rootTranslation('composeLetters.selectContact');

export type TComposeLetterContactFormFieldsProps = {
  formBag: UseFormReturn<TLicensorContact>;
};

export function ComposeLetterContactFormFields({ formBag }: TComposeLetterContactFormFieldsProps) {
  const [currentCountryName, setCurrentCountryName] = useState(formBag.getValues('countryName'));
  const { data: countries, isLoading: isLoadingCountries } = useGetAllCountriesQuery({});

  formBag.watch(({ countryName }, { name, type }) => {
    if (name === 'countryName' && type === 'change') {
      setCurrentCountryName(countryName);
    }
  });

  return (
    <>
      <FormField
        name="email"
        label={t('emailLabel')}
        required
        render={({ field, muiprops }) => <TextField {...field} {...muiprops} />}
      />
      <FormField
        name="firstName"
        label={t('firstNameLabel')}
        required
        render={({ field, muiprops }) => <TextField {...field} {...muiprops} />}
      />
      <FormField
        name="lastName"
        label={t('lastNameLabel')}
        required
        render={({ field, muiprops }) => <TextField {...field} {...muiprops} />}
      />
      <FormField
        name="address1"
        label={t('address1Label')}
        render={({ field, muiprops }) => <TextField {...field} {...muiprops} />}
      />
      <FormField
        name="address2"
        label={t('address2Label')}
        render={({ field, muiprops }) => <TextField {...field} {...muiprops} />}
      />
      <FormField
        name="city"
        label={t('cityLabel')}
        render={({ field, muiprops }) => <TextField {...field} {...muiprops} />}
      />
      <div style={{ display: 'flex', gap: '1em' }}>
        <FormField
          name="state"
          label={t('stateLabel')}
          render={({ field, muiprops }) => {
            if (isLoadingCountries) return <Skeleton />;
            const states = getStatesByCountry(countries.find((c) => c.name === currentCountryName));
            return (
              <Select {...field} {...muiprops}>
                {states.map(({ id, name }) => (
                  <MenuItem key={`state-${id}`} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            );
          }}
        />
        <FormField
          name="postalCode"
          label={t('postalCodeLabel')}
          render={({ field, muiprops }) => <TextField {...field} {...muiprops} />}
        />
      </div>
      <FormField
        name="countryName"
        label={t('countryNameLabel')}
        render={({ field, muiprops }) =>
          isLoadingCountries ? (
            <Skeleton />
          ) : (
            <Select {...field} {...muiprops}>
              {countries.map(({ id, name }) => (
                <MenuItem key={`country-${id}`} value={name}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          )
        }
      />
    </>
  );
}
