import { useState } from 'react';
import { Box } from '@mui/material';
import { FormControlLabelWrapper } from 'components/common/Form/Fields/FormField/FormField';
import { useSearchProductionsQuery } from 'services/common/search';
import { rootTranslation } from 'utils/format';
import { AutocompleteBaseField } from './AutocompleteBaseField';

const t = rootTranslation('drawers.searchProductions');

type TItem = {
  id: number;
  name: string;
};

type TSeasonFilterProps = {
  value?: TItem;
  divisionId: number;
  projectId?: number;
  onChange: (nextValue: TItem) => void;
};

export function SeasonFilter({ value, divisionId, projectId, onChange }: TSeasonFilterProps) {
  const [searchTerm, setSearchTerm] = useState('');
  const {
    data: searchResponse,
    isLoading,
    isFetching,
  } = useSearchProductionsQuery({
    term: searchTerm,
    filters: { types: ['season'], divisionId, showInactive: false, searchBy: 'all' },
  });

  const seasons = searchResponse?.data
    .filter((prod) => !projectId || projectId === prod.prodArray[0]?.id)
    .map((prod) => ({ id: prod.productionId, name: prod.rawName }));

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
      <FormControlLabelWrapper name="seasonId" label={t('season')}>
        <AutocompleteBaseField
          value={value}
          onChange={onChange}
          items={seasons}
          idKey="id"
          labelKey="name"
          isLoading={isLoading}
          isFetching={isFetching}
          setSearchTerm={setSearchTerm}
          testId="season-filter"
        />
      </FormControlLabelWrapper>
    </Box>
  );
}
