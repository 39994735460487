/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { get } from 'utils/object';
import { InputFormat } from 'components/styleguide';
import { useNumberFormat } from 'utils/hooks';
import isNil from 'ramda/src/isNil';
import { withFormField } from 'components/common/Form/utils';
import { FieldPropTypes } from '../prop-types';

const FormInputFormat = ({
  field,
  form: { setFieldValue, touched, errors, isSubmitting },
  disabled,
  fullWidth,
  label,
  placeholder,
  onChange,
  onBlur,
  ...props
}) => {
  const [thousand, decimal] = useNumberFormat();

  const fieldProps = (field) => {
    if (disabled) {
      return {
        ...field,
        value: placeholder,
      };
    }
    return field;
  };

  const handleChange = (value) => value !== field.value && setFieldValue(field.name, value);

  const { t } = useTranslation();
  const inputProps = {
    disabled: disabled || isSubmitting,
    error: t(get(field.name)(touched) && get(field.name)(errors)),
    fullWidth,
    label: t(label),
    placeholder: isNil(placeholder) ? undefined : t(placeholder),
  };

  return (
    <InputFormat
      {...props}
      {...inputProps}
      {...fieldProps(field)}
      separators={[thousand, decimal]}
      onChange={onChange || handleChange}
      onBlur={onBlur || field.onBlur}
    />
  );
};

FormInputFormat.propTypes = {
  field: FieldPropTypes.field,
  form: FieldPropTypes.form,
  disabled: FieldPropTypes.disabled,
  fullWidth: FieldPropTypes.fullWidth,
  label: FieldPropTypes.label,
  placeholder: FieldPropTypes.placeholder,
};

FormInputFormat.displayName = 'FormInputFormat';

export default withFormField(FormInputFormat);
