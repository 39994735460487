/* eslint-disable react/no-children-prop */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-shadow */
import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  FormButton,
  FormDropdown,
  FormCheckbox,
  FormImage,
  FormInputText,
  FormTypeAhead,
  FormInputFormat,
} from 'components/common/Form/Fields';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { BaseDrawer, DrawerHeader } from 'components/styleguide';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import {
  getLanguagesCall,
  getVersionsCall,
  getCategoriesCall,
  getCurrenciesCall,
} from 'redux-core/productions/services';
import { getTerritoriesListCall } from 'redux-core/territories/services';
import { useFetch, useDrawerFormFetch, useDrawer } from 'utils/hooks';
import compose from 'ramda/src/compose';
import mergeLeft from 'ramda/src/mergeLeft';
import propOr from 'ramda/src/propOr';
import { TIME_FORMATS } from 'utils/format';
import { yupTimeHHMMSS, yupRightPresetTerritories } from 'utils/forms';
import {
  getDefaultCurrency,
  PRODUCTION_TYPES,
  RATINGS_OPTIONS,
  DISTRIBUTORS,
  NORMALIZED_PRODUCTION_TYPES,
  YEARS_OPTIONS,
} from 'utils/constants';
import SectionTitle from 'components/common/SectionTitle';
import SectionRow from 'components/common/SectionRow';
import {
  SecuredCurrencyPicker,
  SecuredForm,
  SecuredFormItemsList,
  SecuredRightsPresetPicker,
} from 'components/common/Secured';
import { COMMON_ROLES, CLEAR_PERMISSIONS_GROUPS } from 'redux-core/permissions/constants';
import { getRequiredRights, getRightsCall } from 'redux-core/rights/services';
import { RIGHTS_HINT_MESSAGE_TYPE } from 'components/common/Drawers/RightPresetDrawer/constants';
import { updateCurrenciesInEntity } from 'utils/currencies';
import { formatPresetToRights } from 'components/common/RightsPresetPicker';
import useStyles from './styles';
import { productionInformationItems, personnelInformationItems } from './ItemList/items';
import enhancer from './enhancer';
import { CommonDrawerPropTypes } from '../constants';
import DrawerFormContentGrid from '../DrawerFormContentGrid';

const rootT = 'drawers.productionSetup.season';

const validationSchema = Yup.object().shape({
  masterCurrencyId: Yup.number().required('global.forms.validations.required'),
  languageId: Yup.number().nullable(),
  cueSheetPreparedByEmail: Yup.string().nullable().email('global.forms.validations.types.email'),
  productionYear: Yup.number().typeError('global.forms.validations.types.number').nullable(),
  versionId: Yup.number().nullable(),
  episodeDuration: yupTimeHHMMSS,
  number: Yup.string().required('global.forms.validations.required'),
  requiredRights: Yup.object()
    .shape({
      name: Yup.string(),
      rights: yupRightPresetTerritories(),
    })
    .nullable(),
});

const SeasonDrawer = ({
  divisionId,
  name,
  fetchGetSeason,
  updateSeason,
  saveSeason,
  keepSeasonInfo,
  keepPersonnelInfo,
  onClose,
  payload: { id, hideSaveAndAddRelease },
  values: { secondaryOpened, ...valuesRaw },
  savePosterId,
  rights,
}) => {
  const values = {
    ...valuesRaw,
    dirty: valuesRaw.dirty || Boolean(rights),
  };
  const { t } = useTranslation();
  const classes = useStyles();
  const [distributorsOptions, setDistributors] = useState([]);

  const openDrawer = useDrawer();

  const [languages] = useFetch(getLanguagesCall);
  const [versions] = useFetch(getVersionsCall);
  const [categories] = useFetch(getCategoriesCall);
  const [currencies] = useFetch(getCurrenciesCall);
  const [territories] = useFetch(() => divisionId && getTerritoriesListCall({ divisionId }), [divisionId]);
  const [requiredRightsRaw, loadingRights] = useFetch(
    () => id && !rights && getRequiredRights({ subprojectId: id }),
    [id, rights],
  );

  const [rightsPresets] = useFetch(
    () => !id && !rights && divisionId && getRightsCall({ divisionId }),
    [id, rights, divisionId],
  );

  const defaultRightsPreset = useMemo(() => {
    const def = rightsPresets.find((f) => f.default);
    let retDef;
    if (def) {
      retDef = {
        ...formatPresetToRights(def),
        dirty: true,
      };
    }

    return retDef;
  }, [rightsPresets]);

  const masterCurrencyId = useMemo(() => getDefaultCurrency(currencies), [currencies]);

  const requiredRights = defaultRightsPreset || rights || requiredRightsRaw;

  const handleSave = (addRelease) => async (values, setFieldValue, formik) => {
    const call = async () => {
      if (id) {
        return updateSeason(values, {
          addRelease,
        });
      }
      keepSeasonInfo(values);
      return saveSeason(values, {
        addRelease,
      });
    };

    const response = await call();
    if (response?.error) {
      /**
       * actions can be either 2nd or 3rd parameter
       * depending on where the handleSave is called
       * From Submit (save and add release) Button:2nd param is actions and it's passed in common/Form component
       * From Submit (save) Button: 3rd param is provided by <FormButton /> onClick callback
       */
      const actions = addRelease ? setFieldValue : formik;
      actions.setFieldError('number', response.message);
      const shouldValidate = false;
      actions.setFieldTouched('number', true, shouldValidate);
    }
  };

  const handleRatingChange = (value, setFieldValue, { dirty }) => {
    setDistributors(DISTRIBUTORS(value, true));
    if (dirty) {
      const descriptors = DISTRIBUTORS(value, false);
      if (descriptors.length === 1) {
        setFieldValue('descriptors', descriptors);
      } else {
        setFieldValue('descriptors', []);
      }
    }
  };

  const handleSecondarySubmit = async (payload) => {
    const additionalData = compose(mergeLeft(payload), propOr({}, 'additionalData'))(values);

    return updateSeason(
      { additionalData },
      {
        addRelease: false,
        continueEditing: true,
        patch: true,
      },
    );
  };

  const handleCurrencySubmit = async (currencies) =>
    updateCurrenciesInEntity(currencies, updateSeason, {
      addRelease: false,
      continueEditing: true,
      patch: true,
    });

  const onCancel = () => {
    onClose();
  };
  const [, loading] = useDrawerFormFetch({ id, secondaryOpened }, () => fetchGetSeason(id));

  const requiredPerms = {
    objectId: values.objectId,
    permissions: COMMON_ROLES.EDITOR,
  };

  return (
    <SecuredForm
      drawerName={name}
      enableReinitialize
      initialValues={{
        masterCurrencyId,
        ...values,
        requiredRights,
      }}
      onSubmit={handleSave(true)}
      readOnly={loading || loadingRights}
      validationSchema={validationSchema}
      initialRights={requiredRights}
      {...requiredPerms}
    >
      <DrawerHeader title={t(`${rootT}.${id ? 'drawerEditTitle' : 'drawerTitle'}`)} onClose={onClose}>
        <FormButton
          testId="season-drawer-cancel"
          alwaysEnabled
          variant="destructive"
          size="xs"
          children="global.forms.cancel"
          onClick={() => onCancel()}
        />
        <FormButton
          testId="season-drawer-save"
          children="global.forms.save"
          onClick={handleSave(false)}
          size="xs"
          type="button"
          variant="primary"
        />
        {!hideSaveAndAddRelease && (
          <FormButton
            testId="season-drawer-save-and-add-release"
            variant="primary"
            type="submit"
            size="md"
            children={`${rootT}.saveAndAddRelease`}
          />
        )}
      </DrawerHeader>
      <DrawerFormContentGrid container spacing={3} className={classes.scrollable}>
        <Grid item xs={3}>
          <FormImage
            name="posterId"
            resizingOptions={{ width: 128, height: 188 }}
            type={NORMALIZED_PRODUCTION_TYPES.TV}
            onUpload={(id) => {
              savePosterId({
                type: PRODUCTION_TYPES.SEASON,
                posterId: id,
              });
            }}
          />
        </Grid>
        <Grid item xs={9}>
          <Grid item>
            <SectionTitle title={t(`${rootT}.details`)} />
            <SectionRow isLast>
              <Box width={125}>
                <FormInputText label={`${rootT}.seasonNumber`} name="number" required />
              </Box>
            </SectionRow>
          </Grid>

          <Grid item>
            <SectionTitle title={t(`${rootT}.defaultRights`)} />
            <SectionRow isLast>
              <SecuredRightsPresetPicker
                divisionId={divisionId}
                onAdditionalRightsPresetDrawerOpen={(values) => keepSeasonInfo({ ...values, secondaryOpened: true })}
                formId={PRODUCTION_TYPES.SEASON}
                {...requiredPerms}
                clearPermissions={CLEAR_PERMISSIONS_GROUPS.RIGHTS}
                saveInDrawerName={name}
                hintType={RIGHTS_HINT_MESSAGE_TYPE.SEASON}
              />
            </SectionRow>
          </Grid>

          <Grid item>
            <SectionTitle title={t(`${rootT}.currency`)} />
            <Grid item xs={8} spacing={2} alignItems="center">
              <SectionRow isLast>
                <SecuredCurrencyPicker
                  disabled={Boolean(id)}
                  currencies={currencies}
                  onSaveCurrencies={handleCurrencySubmit}
                  onAdditionalCurrencyClick={(values) => keepSeasonInfo({ ...values, secondaryOpened: true })}
                  {...requiredPerms}
                />
              </SectionRow>
            </Grid>
          </Grid>

          <Grid item>
            <SectionTitle title={t(`${rootT}.preparedBy.title`)} />
            <Grid item xs={8}>
              <SectionRow>
                <FormInputText label={`${rootT}.preparedBy.firstName`} name="cueSheetPreparedByName" />
              </SectionRow>
              <SectionRow>
                <FormInputText label={`${rootT}.preparedBy.lastName`} name="cueSheetPreparedByLastName" />
              </SectionRow>
              <SectionRow>
                <FormInputText label={`${rootT}.preparedBy.email`} name="cueSheetPreparedByEmail" />
              </SectionRow>
              <SectionRow isLast>
                <FormInputText label={`${rootT}.preparedBy.phone`} name="cueSheetPreparedByPhone" />
              </SectionRow>
            </Grid>
          </Grid>
          <Grid item>
            <SectionTitle title={t(`${rootT}.defaultRating`)} />
            <Grid item xs={8}>
              <SectionRow>
                <FormDropdown
                  label={`${rootT}.rating`}
                  name="rating"
                  options={RATINGS_OPTIONS}
                  placeholder="global.forms.select"
                />
              </SectionRow>
              <SectionRow isLast>
                <FormDropdown
                  disabled={!distributorsOptions.length}
                  isMultiple
                  label={`${rootT}.ratingDescriptor`}
                  name="descriptors"
                  options={distributorsOptions}
                  placeholder="global.forms.select"
                  subscribeAction={handleRatingChange}
                  subscribeToField="rating"
                />
              </SectionRow>
            </Grid>
          </Grid>
          <Grid item>
            <SectionTitle title={t(`${rootT}.defaultAdditionalInformation`)} />
            <Grid item xs={8}>
              <SectionRow>
                <FormInputText label={`${rootT}.description`} name="description" textArea />
              </SectionRow>
              <SectionRow>
                <Grid container spacing={1}>
                  <Grid item>
                    <Box width={120}>
                      <FormTypeAhead
                        label={t(`${rootT}.productionYear`)}
                        name="productionYear"
                        options={YEARS_OPTIONS}
                      />
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box width={120}>
                      <FormInputFormat
                        format={TIME_FORMATS.HHMMSS}
                        label={`${rootT}.duration`}
                        name="episodeDuration"
                        type="time"
                        textAlign="left"
                      />
                    </Box>
                  </Grid>
                </Grid>
              </SectionRow>
              <SectionRow>
                <FormTypeAhead
                  label={`${rootT}.version`}
                  name="versionId"
                  options={versions}
                  placeholder="global.forms.select"
                />
              </SectionRow>
              <SectionRow>
                <FormTypeAhead
                  label={`${rootT}.category`}
                  name="categoryId"
                  options={categories}
                  placeholder="global.forms.select"
                />
              </SectionRow>
              <SectionRow>
                <FormTypeAhead
                  label={`${rootT}.language`}
                  name="languageId"
                  options={languages}
                  placeholder="global.forms.select"
                />
              </SectionRow>
              <SectionRow>
                <FormTypeAhead
                  label={`${rootT}.territory`}
                  name="territoryId"
                  options={territories}
                  placeholder="global.forms.select"
                />
              </SectionRow>
              <SectionRow>
                <Grid container item>
                  <Grid item xs={5}>
                    <FormCheckbox name="animated" label={`${rootT}.animated`} />
                  </Grid>
                  <Grid item xs={6}>
                    <FormCheckbox name="archivalOnly" label={`${rootT}.archivalOnly`} />
                  </Grid>
                </Grid>
              </SectionRow>
              <SectionRow>
                <FormInputFormat label={t(`${rootT}.internalSeasonProdId`)} name="internalSeasonProdId" type="string" />
              </SectionRow>
              <SectionRow>
                <FormInputText label={`${rootT}.eidr`} name="eidrContentId" />
              </SectionRow>
              <SectionRow isLast>
                <FormInputText label={`${rootT}.isan`} name="isanId" />
              </SectionRow>
            </Grid>
          </Grid>

          <Grid item>
            <SectionTitle title={t(`${rootT}.defaultProductionInformation`)} />
            <Grid item xs={11}>
              <SectionRow isLast>
                <SecuredFormItemsList
                  items={productionInformationItems({
                    keepPersonnelInfo,
                    keepSeasonInfo,
                    onSubmit: handleSecondarySubmit,
                    openDrawer,
                  })}
                  {...requiredPerms}
                />
              </SectionRow>
            </Grid>
          </Grid>
          <Grid item>
            <SectionTitle title={t(`${rootT}.defaultPersonnel`)} />
            <Grid item xs={11}>
              <SecuredFormItemsList
                items={personnelInformationItems({
                  keepPersonnelInfo,
                  keepSeasonInfo,
                  onSubmit: handleSecondarySubmit,
                  openDrawer,
                })}
                {...requiredPerms}
              />
            </Grid>
          </Grid>
        </Grid>
      </DrawerFormContentGrid>
    </SecuredForm>
  );
};

SeasonDrawer.propTypes = {
  hideSaveAndAddRelease: PropTypes.bool,
  divisionId: PropTypes.number.isRequired,
  name: PropTypes.string,
  fetchGetSeason: PropTypes.func.isRequired,
  updateSeason: PropTypes.func.isRequired,
  saveSeason: PropTypes.func.isRequired,
  keepSeasonInfo: PropTypes.func.isRequired,
  keepPersonnelInfo: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  payload: PropTypes.shape({ id: PropTypes.number }),
  values: PropTypes.shape({ secondaryOpened: PropTypes.bool }),
  companies: PropTypes.array,
  savePosterId: PropTypes.func.isRequired,
  rights: PropTypes.shape({}),
};

const Drawer = ({ open, onClose, cleanSetup, ...props }) => {
  const handleClose = async () => {
    await onClose();
    await cleanSetup();
  };
  return (
    <BaseDrawer open={open} onClose={handleClose}>
      <SeasonDrawer onClose={handleClose} {...props} />
    </BaseDrawer>
  );
};

Drawer.propTypes = CommonDrawerPropTypes;

export default enhancer(Drawer);
