/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-expressions */
import React, { useEffect, useState, useCallback } from 'react';
import ArrowBack from '@material-ui/icons/ChevronLeft';
import ArrowForward from '@material-ui/icons/ChevronRight';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import { useRootTranslation, useIntersect } from 'utils/hooks';
import { Text } from 'components/styleguide';
import useStyles from './styles';

const rootT = 'drawers.qtracksSync.slider';

const Icon = (props) => <IconButton size="small" disableRipple disableFocusRipple {...props} />;

const Header = ({ diff }) => {
  const t = useRootTranslation(rootT);
  const [[status], setStatus] = useState([new Map()]);
  const classes = useStyles();

  const [setNodes] = useIntersect((entry) => {
    entry.forEach((a) => {
      const {
        isIntersecting,
        target: { id },
      } = a;
      status.set(id, isIntersecting);
    });
    // If there are no visible elements, keep previous status
    if (Array.from(status.values()).some(Boolean)) setStatus([status]);
  });

  const handleChange = useCallback(
    (pos, forward) => {
      try {
        document.getElementById(diff[pos]).scrollIntoView({ behavior: 'smooth' });
      } catch (e) {
        // If element not found in dom then go to the next one
        if (forward) pos + 1 < diff.length && handleChange(pos + 1, true);
        else pos > 0 && handleChange(pos - 1);
      }
    },
    [diff],
  );

  useEffect(() => {
    if (!diff.length) return;
    const nodes = [];
    const newStatus = new Map();
    diff.forEach((id) => {
      newStatus.set(id, false);
      const node = document.getElementById(id);
      nodes.push(node);
    });
    setStatus([newStatus]);
    setNodes(nodes);
    handleChange(0);
  }, [diff, handleChange, setNodes]);

  const current = Array.from(status.values()).findIndex(Boolean) + 1 || 1;

  if (!diff.length) return null;
  return (
    <Grid alignItems="center" className={classes.slider} container justify="center">
      <Icon className={classes.arrow} disabled={current === 1} onClick={() => handleChange(current - 1 - 1)}>
        <ArrowBack />
      </Icon>
      <Text color="white">{t('changes', { current, total: diff.length })}</Text>
      <Icon className={classes.arrow} disabled={current === diff.length} onClick={() => handleChange(current, true)}>
        <ArrowForward />
      </Icon>
    </Grid>
  );
};

Header.propTypes = {};

export default Header;
