/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable import/no-cycle */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import Search from './Search';
import Bookmarks from './Bookmarks';
import withStyles from './styles';

const TopBarSlot = ({ classes, location, isAuthenticated }) => {
  const [open, setOpen] = useState();
  const handleToggle = (option) => (open) => setOpen(open && option);

  useEffect(() => {
    setOpen(false);
  }, [location]);

  return (
    <Grid
      container
      alignContent="center"
      alignItems="center"
      className={classes.container}
      justify="flex-end"
      wrap="nowrap"
    >
      <Grid item className={classes.search}>
        <Search open={open === 'recent'} setOpen={handleToggle('recent')} />
      </Grid>
      <Grid item>
        {isAuthenticated && <Bookmarks open={open === 'bookmarks'} setOpen={handleToggle('bookmarks')} />}
      </Grid>
    </Grid>
  );
};

TopBarSlot.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.string,
  isAuthenticated: PropTypes.bool,
};

export default withStyles(TopBarSlot);
