/* eslint-disable import/no-cycle */
/* eslint-disable @typescript-eslint/no-shadow */
import { Text, Check } from 'components/styleguide';
import { Card, CardContent, Grid, Box, Tooltip } from '@material-ui/core';
import PropTypes from 'prop-types';
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined';
import join from 'ramda/src/join';
import map from 'ramda/src/map';
import compose from 'ramda/src/compose';
import length from 'ramda/src/length';
import split from 'ramda/src/split';
import classnames from 'classnames';
import { concatStrings } from 'utils';
import { HHMMSStoMMSS } from 'utils/date';
import useStyles from './styles';

const MAX_TITLE_LENGTH = 30;

const TrackCard = ({
  status,
  canDelete,
  onDelete,
  title,
  artist,
  estimatedFees,
  approvedFees,
  uses,
  showUses,
  isUsing,
}) => {
  const classes = useStyles();
  const smallTitle = (title) => <Text variant="inherit">{`${title.slice(0, MAX_TITLE_LENGTH)}...`}</Text>;

  const renderUses = (values) => {
    const text = compose(
      join(', '),
      map((val) => concatStrings(' ')([val.use, HHMMSStoMMSS(val.duration)])),
    )(values);
    const list = () => (
      <p>
        {map((val) => (
          <span key={val}>
            {val}
            <br />
          </span>
        ))(split(',', text))}
      </p>
    );
    return length(text) > MAX_TITLE_LENGTH ? (
      <Tooltip title={list()} placement="bottom-start" style={{ cursor: 'pointer' }} data-testid="track-card-uses">
        <span>{smallTitle(text)}</span>
      </Tooltip>
    ) : (
      <Text variant="inherit">{text}</Text>
    );
  };

  const renderTitle = (title) =>
    length(title) > 30 ? (
      <Tooltip title={title} placement="bottom-start" data-testid="track-card-title">
        <span>{smallTitle(title)}</span>
      </Tooltip>
    ) : (
      <Text variant="inherit">{title}</Text>
    );
  return (
    <Card className={classes.card} data-testid="track-card">
      <CardContent classes={{ root: classes.content }}>
        <Grid item container direction="row">
          <Grid item xs={1} container direction="column">
            <Tooltip title={status.label} placement="bottom-start">
              <Box
                mr={0.5}
                mt={0.5}
                className={classes.circle}
                style={{
                  backgroundColor: status.color,
                }}
                data-testid="track-card-status"
              />
            </Tooltip>
            <Box mt={0.5}>
              <Check
                data-testid="track-card-using"
                fontSize="small"
                className={classnames(classes.circle, isUsing ? classes.using : classes.notUsing)}
              />
            </Box>
          </Grid>
          <Grid item container xs={10} direction="column">
            <Text color="inherit" variant="subtitle2" bold>
              {renderTitle(title)}
            </Text>
            <Text color="inherit" variant="caption">
              {artist}
            </Text>
          </Grid>
          {canDelete && (
            <Grid item container xs={1} onClick={onDelete} data-testid="track-card-delete">
              <Box mt={0.5} ml={0.5}>
                <ClearOutlinedIcon className={classes.clearIcon} />
              </Box>
            </Grid>
          )}
        </Grid>
      </CardContent>
      <Grid item container direction="row" className={classes.totalContainer} justify="space-between">
        {showUses && renderUses(uses)}
        {!showUses && (
          <>
            <Text variant="inherit">{estimatedFees}</Text>
            <Text variant="inherit">{approvedFees}</Text>
          </>
        )}
      </Grid>
    </Card>
  );
};
TrackCard.propTypes = {
  status: PropTypes.object.isRequired,
  canDelete: PropTypes.bool,
  isUsing: PropTypes.bool,
  onDelete: PropTypes.func,
  estimatedFees: PropTypes.string,
  approvedFees: PropTypes.string,
  title: PropTypes.string.isRequired,
  uses: PropTypes.arrayOf(
    PropTypes.shape({
      use: PropTypes.string,
      duration: PropTypes.string,
    }),
  ),
  showUses: PropTypes.bool,
  artist: PropTypes.string,
};

TrackCard.defaultProps = {
  canDelete: true,
  isUsing: false,
  showUses: false,
  estimatedFees: '',
  approvedFees: '',
  uses: [],
  onDelete: () => {},
};
export default TrackCard;
