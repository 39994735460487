/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const TrackIcon = (props) => (
  <SvgIcon {...props} viewBox="0 0 21 23">
    <path d="M21 1.313C21 .588 20.412 0 19.687 0c-.136 0-.26.014-.382.055L7.93 3.555c-.547.177-.93.683-.93 1.257v13.221c-.82-.369-1.723-.533-2.625-.533-1.49 0-4.375.697-4.375 2.625s2.885 2.625 4.375 2.625c1.49 0 4.375-.697 4.375-2.625v-9.693l10.5-3.24v7.341c-.82-.369-1.723-.533-2.625-.533-1.49 0-4.375.697-4.375 2.625s2.885 2.625 4.375 2.625c1.49 0 4.375-.697 4.375-2.625V1.312z" />
  </SvgIcon>
);

export default TrackIcon;
