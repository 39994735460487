/* eslint-disable react/jsx-props-no-spreading */
import { IconButton, InputAdornment } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';

import { FieldPropTypes } from '../prop-types';
import FormInputText from '../FormInputText';

export const Input = ({ endAdornment, ...props }) => {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);

  return (
    <FormInputText
      type={showPassword ? 'text' : 'password'}
      endAdornment={
        endAdornment || (
          <InputAdornment position="end">
            <IconButton
              size="small"
              aria-label={t('global.forms.ariaLabels.password')}
              onClick={() => setShowPassword(!showPassword)}
              onMouseDown={(e) => e.preventDefault()}
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        )
      }
      {...props}
    />
  );
};

Input.propTypes = {
  type: FieldPropTypes.type,
  endAdornment: FieldPropTypes.node,
};

Input.displayName = 'FormPassword';

export default Input;
