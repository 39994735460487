/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { DatePicker } from 'components/styleguide';
import { useTranslation } from 'react-i18next';
import { get } from 'utils/object';
import dayjs from 'dayjs';
import { withFormField } from 'components/common/Form/utils';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import localeData from 'dayjs/plugin/localeData';

dayjs.extend(LocalizedFormat);
dayjs.extend(localeData);

const FormDatePicker = ({
  field,
  form: { errors, isSubmitting, setFieldValue, touched, validateForm },
  disabled,
  label,
  format,
  fullWidth,
  limitedWith,
  placeholder,
  onChange,
  ...props
}) => {
  const { t } = useTranslation();
  const handleChange = async (date) => {
    if (date && date.isValid()) {
      await setFieldValue(field.name, date.toISOString());
    } else await setFieldValue(field.name, date || undefined);
    validateForm();
  };
  return (
    <DatePicker
      disabled={disabled || isSubmitting}
      error={t(get(field.name)(touched) && get(field.name)(errors))}
      fullWidth={fullWidth}
      limitedWith={limitedWith}
      label={t(label)}
      format={t(format)}
      placeholder={t(placeholder)}
      {...field}
      onChange={onChange || handleChange}
      {...props}
    />
  );
};

FormDatePicker.defaultProps = {
  format: dayjs().localeData().longDateFormat('L'),
  placeholder: 'global.forms.select',
  formatDate: (value, format) => dayjs(value).format(format),
};

FormDatePicker.propTypes = {
  limitedWith: PropTypes.bool,
  field: PropTypes.shape({}).isRequired,
  form: PropTypes.shape({}).isRequired,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  format: PropTypes.string,
  fullWidth: PropTypes.bool,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
};

export default withFormField(FormDatePicker);
