/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/no-children-prop */
/* eslint-disable @typescript-eslint/no-shadow */
import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Form from 'components/common/Form';
import { BaseDrawer, DrawerHeader } from 'components/styleguide';
import { FormButton, FormFieldArray } from 'components/common/Form/Fields';
import { useFetch } from 'utils/hooks';
import { getCurrenciesCall } from 'redux-core/productions/services';
import { compose, isNil, map, partition, propEq } from 'ramda';
import * as Yup from 'yup';
import { CommonDrawerPropTypes } from '../constants';
import CurrencyTable from './CurrencyTable';
import DrawerFormContentGrid from '../DrawerFormContentGrid';

const rootT = 'drawers.productionSetup.currency';

const validationSchema = Yup.object().shape({
  currencies: Yup.array().of(
    Yup.object().shape({
      currencyId: Yup.number().required('global.forms.validations.required'),
      exchangeRate: Yup.number()
        .moreThan(0, 'global.forms.validations.moreThan')
        .required('global.forms.validations.required'),
    }),
  ),
});

const CurrenciesDrawer = ({
  name,
  onClose,
  payload: { values = [], isEditing, onSubmit, masterCurrencyId, readOnly },
}) => {
  const { t } = useTranslation();
  const [currencies] = useFetch(async () => {
    const curr = await getCurrenciesCall();
    return curr.map((currency) => ({ ...currency, currencyId: currency.id }));
  });
  const [editableIndex, setEditableIndex] = useState();
  const [editingFieldCache, setEditingFieldCache] = useState(null);
  const [isAdding, setIsAdding] = useState();

  const handleSubmit = async (values, { setFieldValue }) => {
    const selectedCurrencies = map((currency) => {
      const { code, symbol } = currencies.find(propEq('id', currency.currencyId));
      return { ...currency, code, symbol };
    })(values.currencies);

    const newCurrencies = await onSubmit(selectedCurrencies);

    if (newCurrencies) setFieldValue('currencies', newCurrencies);

    setEditableIndex(null);
    setIsAdding(false);
  };

  const [[defaultCurrency = {}], currencyOptions = []] = useMemo(
    () =>
      compose(
        partition(propEq('id', masterCurrencyId)),
        map((c) => ({ ...c, label: `${c.code} (${c.symbol})` })),
      )(currencies),
    [currencies, masterCurrencyId],
  );

  const initialValues = useMemo(() => map((c) => ({ ...c, currencyId: Number(c.currencyId) }))(values), [values]);

  return (
    <Form
      drawerName={name}
      enableReinitialize
      initialValues={{ currencies: initialValues || [] }}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      readOnly={readOnly}
    >
      <FormFieldArray name="currencies">
        {(arrayHelpers, formik) => (
          <>
            <DrawerHeader title={t(`${rootT}.drawerTitle`)} onClose={onClose}>
              <>
                {!isNil(editableIndex) && (
                  <FormButton
                    alwaysEnabled
                    variant="destructive"
                    size="xs"
                    testId="currency-drawer-cancel"
                    children="global.forms.cancel"
                    onClick={() => {
                      isAdding
                        ? arrayHelpers.remove(editableIndex)
                        : arrayHelpers.replace(editableIndex, editingFieldCache);
                      setIsAdding(false);
                      setEditableIndex(null);
                    }}
                  />
                )}
                {isNil(editableIndex) ? (
                  <FormButton
                    children={`${rootT}.addCurrency`}
                    testId="currency-drawer-add"
                    size="sm"
                    type="button"
                    variant="secondary"
                    alwaysEnabled
                    onClick={() => {
                      arrayHelpers.push({});
                      setIsAdding(true);
                      setEditableIndex(formik.values.currencies.length);
                    }}
                  />
                ) : (
                  <FormButton
                    variant="primary"
                    testId="currency-drawer-save"
                    size="xs"
                    children="global.forms.save"
                    type="submit"
                  />
                )}
              </>
            </DrawerHeader>
            <DrawerFormContentGrid container direction="column">
              <CurrencyTable
                defaultCurrency={defaultCurrency}
                editableIndex={editableIndex}
                formik={formik}
                isAdding={isAdding}
                isEditing={isEditing}
                onRemove={arrayHelpers.remove}
                options={currencyOptions}
                readOnly={readOnly}
                setEditableIndex={(index, currency) => {
                  setEditingFieldCache(currency);
                  setEditableIndex(index);
                }}
              />
            </DrawerFormContentGrid>
          </>
        )}
      </FormFieldArray>
    </Form>
  );
};

CurrenciesDrawer.propTypes = {
  name: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  payload: PropTypes.shape({
    currencies: PropTypes.arrayOf(PropTypes.shape({})),
    edit: PropTypes.bool,
    onSubmit: PropTypes.func,
  }),
};

const Drawer = ({ open, onClose, ...props }) => (
  <BaseDrawer open={open} onClose={onClose}>
    <CurrenciesDrawer onClose={onClose} {...props} />
  </BaseDrawer>
);

Drawer.propTypes = CommonDrawerPropTypes;

export default Drawer;
