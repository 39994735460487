/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/no-children-prop */
import React, { useMemo } from 'react';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import { FieldArray, useFormikContext } from 'formik';
import { Row, Text } from 'components/styleguide';
import {
  FormButton,
  FormDropdown,
  FormInputText,
  FormCheckbox,
  FormTypeAhead,
  StateField,
} from 'components/common/Form/Fields';
import TrackCountryDropdown from 'components/TrackLayout/Tabs/TrackDetails/TrackCountryDropdown';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { PHONE_TYPES_OPTIONS, PHONE_TYPES } from 'utils/constants';
import { v4 as uuidv4 } from 'uuid';
import pathOr from 'ramda/src/pathOr';
import map from 'ramda/src/map';
import pick from 'ramda/src/pick';
import uniqBy from 'ramda/src/uniqBy';
import prop from 'ramda/src/prop';
import props from 'ramda/src/props';
import filter from 'ramda/src/filter';
import either from 'ramda/src/either';
import { useRootTranslation } from 'utils/hooks';

export const ADDRESS_FIELDS = ['address1', 'address2', 'city', 'countryName', 'postalCode', 'state'];

type TContactForm = {
  id?: string | number;
  firstName?: string;
  lastName?: string;
  email?: string;
  isCreating?: boolean;
  isCreatingAddress?: boolean;
};

type LabelProps = {
  address1: string;
  address2: string;
  city: string;
  state: string;
  postalCode: string;
  countryName: string;
};

const Label = ({ address1, address2, city, state, postalCode, countryName }: LabelProps) => (
  <Grid container spacing={1} style={{ textTransform: 'initial' }}>
    <Grid item xs={3}>
      <Text variant="body2" noWrap children={address1} />
    </Grid>
    <Grid item xs={2}>
      <Text variant="body2" noWrap children={address2} />
    </Grid>
    <Grid item xs={3}>
      <Text variant="body2" noWrap children={city} />
    </Grid>
    <Grid item xs={2}>
      <Text variant="body2" noWrap children={state} />
    </Grid>
    <Grid item xs={1}>
      <Text variant="body2" noWrap children={postalCode} />
    </Grid>
    <Grid item xs={1}>
      <Text variant="body2" noWrap children={countryName} />
    </Grid>
  </Grid>
);

type ContactFormProps = {
  territories: Array<{ id: number; name: string; readOnly: boolean }>;
  countries: Array<{ id: number; name: string; code: string; tisNumber: number }>;
  currencies: Array<{
    id: number;
    name: string;
    code: string;
    exchangeRate: number;
    symbol: string;
    isMaster: boolean;
  }>;
  rootT: string;
  rootKey: string;
  contactKey: string;
  disabled: boolean;
  licensorContacts: Array<{
    id: number;
    divisionId: number;
    email: string;
    firstName: string;
    lastName: string;
    licensorName: string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    postalCode: string;
    countryName: string;
    usedLast: boolean;
  }>;
};

const ContactForm = ({
  territories,
  countries,
  currencies,
  rootT,
  contactKey,
  rootKey,
  disabled,
  licensorContacts,
}: ContactFormProps) => {
  const getDefaultPhone = () => ({
    type: PHONE_TYPES.PHONE,
    number: '',
    generatedId: uuidv4(),
  });
  const { values, validateForm, setFieldValue } = useFormikContext();
  const contact: TContactForm = pathOr({}, contactKey.split('.'), values);
  const additionalData = pathOr({}, ['additionalData'], contact);
  const contactT = 'drawers.licensorsAddEdit.contact';
  const globalT = useRootTranslation('global');
  const t2 = useRootTranslation();

  const addressDisabled = !contact?.firstName && !contact?.lastName && !contact?.email;

  const contactAddresses = useMemo(() => {
    const filteredContacts = filter(either(prop('address1'), prop('address2')), licensorContacts);
    const mappedContacts = map(pick(ADDRESS_FIELDS), filteredContacts);
    const uniqueContacts = uniqBy(props(ADDRESS_FIELDS), mappedContacts);

    return uniqueContacts;
  }, [licensorContacts]);

  return (
    <Grid item container spacing={2}>
      <Grid item xs={6}>
        <FormInputText name={`${contactKey}.firstName`} label={`${contactT}.firstName`} />
      </Grid>
      <Grid item xs />
      <Grid item xs={6}>
        <FormInputText name={`${contactKey}.lastName`} label={`${contactT}.lastName`} />
      </Grid>
      <Grid item xs />
      <Grid item xs={6}>
        <FormInputText name={`${contactKey}.email`} label={`${contactT}.email`} />
      </Grid>
      {rootKey && (
        <>
          <Grid item xs />
          <Grid item xs={6} container alignItems="flex-end">
            <FormCheckbox
              name={`${rootKey}.providesOwnLicense`}
              label={`${rootT}.providesOwnLicense`}
              testId="basic-info-provides-license"
              disabled={disabled}
            />
          </Grid>
        </>
      )}
      <Grid item xs={12}>
        <FieldArray name={`${contactKey}.additionalData.phones`}>
          {(arrayHelpers) => (
            <>
              {pathOr([getDefaultPhone()], ['phones'], additionalData).map((phone, index, phones) => (
                <Row spacing={1} alignItems="flex-end" key={phone.generatedId}>
                  <Grid item xs={2}>
                    <FormDropdown
                      disabled={disabled || addressDisabled}
                      label={`${contactT}.type`}
                      name={`${contactKey}.additionalData.phones.${index}.type`}
                      options={PHONE_TYPES_OPTIONS}
                      labelKey="name"
                      testId="contact-drawer-phone-type-dropdown"
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <FormInputText
                      disabled={disabled || addressDisabled}
                      label={`${contactT}.number`}
                      name={`${contactKey}.additionalData.phones.${index}.number`}
                      testId="contacts-drawer-phone-type-number"
                    />
                  </Grid>
                  {phones.length > 1 && (
                    <Grid item>
                      <IconButton color="secondary" disabled={disabled || addressDisabled} size="small">
                        <CloseIcon
                          onClick={() => {
                            arrayHelpers.remove(index);
                            setTimeout(() => {
                              validateForm();
                            }, 200);
                          }}
                        />
                      </IconButton>
                    </Grid>
                  )}
                  {index === phones.length - 1 && (
                    <Grid item>
                      <FormButton
                        alwaysEnabled
                        disabled={disabled || addressDisabled}
                        variant="secondary"
                        testId="contacts-drawer-phone-type-add"
                        size="xs"
                        children="global.forms.add"
                        type="button"
                        onClick={() => arrayHelpers.insert(phones.length, getDefaultPhone())}
                      />
                    </Grid>
                  )}
                </Row>
              ))}
            </>
          )}
        </FieldArray>
      </Grid>
      <Grid item xs={5}>
        <FormTypeAhead
          isClearable={false}
          placeholder=""
          label={`${contactT}.territory`}
          options={territories}
          name={`${contactKey}.territoryId`}
          testId="basic-info-territory"
          disabled={disabled}
        />
      </Grid>
      {rootKey && (
        <Grid item xs={2}>
          <FormTypeAhead
            isClearable={false}
            placeholder=""
            label={`${contactT}.currency`}
            options={currencies}
            name={`${rootKey}.currencyId`}
            testId="basic-info-currency"
            disabled={disabled}
          />
        </Grid>
      )}
      <Grid item xs />
      <Grid item container>
        <Box width="100%" my={2}>
          <Divider />
        </Box>
      </Grid>
      <Grid container item spacing={2}>
        <Grid item xs={12}>
          {contact.isCreatingAddress ? (
            <FormInputText
              label={`${contactT}.address1`}
              name={`${contactKey}.address1`}
              testId="basic-info-contact-address1"
              disabled={disabled || addressDisabled}
            />
          ) : (
            <FormTypeAhead
              label={`${contactT}.address1`}
              options={contactAddresses}
              name={`${contactKey}.address1`}
              testId="basic-info-contact-address1"
              disabled={disabled || addressDisabled}
              alwaysRenderValue
              creatableText={globalT('forms.createNew')}
              labelKey="address1"
              valueKey="address1"
              displayValue={prop('address1')}
              formatOptionLabel={({ address1, address2, city, state, postalCode, countryName }) => (
                <Label
                  address1={address1}
                  address2={address2}
                  city={city}
                  state={state}
                  postalCode={postalCode}
                  countryName={countryName}
                />
              )}
              headerLabel={() => (
                <Label
                  address1={t2(`${contactT}.address1`)}
                  address2={t2(`${contactT}.address2`)}
                  city={t2(`${contactT}.city`)}
                  state={t2(`${contactT}.state`)}
                  postalCode={t2(`${contactT}.zipCode`)}
                  countryName={t2(`${contactT}.country`)}
                />
              )}
              onCreate={(value) => {
                setFieldValue(`${contactKey}.address1`, value);
                setFieldValue(`${contactKey}.isCreatingAddress`, true);
              }}
              onSelect={(value) => {
                if (value) {
                  setFieldValue(`${contactKey}.isCreatingAddress`, undefined);
                  ADDRESS_FIELDS.forEach((field) => {
                    setFieldValue(`${contactKey}.${field}`, value[field]);
                  });
                }
              }}
            />
          )}
        </Grid>
        <Grid item xs={12}>
          <FormInputText
            name={`${contactKey}.address2`}
            label={`${contactT}.address2`}
            testId="basic-info-contact-address2"
            disabled={disabled || addressDisabled}
          />
        </Grid>
        <Grid item xs={6}>
          <FormInputText
            name={`${contactKey}.city`}
            label={`${contactT}.city`}
            testId="basic-info-contact-city"
            disabled={disabled || addressDisabled}
          />
        </Grid>

        <Grid item xs={4}>
          <StateField
            name={`${contactKey}.state`}
            countryName={`${contactKey}.countryName`}
            label={`${contactT}.state`}
            countries={countries}
            testId="contacts-drawer-state"
            disabled={disabled || addressDisabled}
          />
        </Grid>
        <Grid item xs={2}>
          <FormInputText
            name={`${contactKey}.postalCode`}
            label={`${contactT}.zipCode`}
            testId="basic-info-contact-zipCode"
            disabled={disabled || addressDisabled}
          />
        </Grid>

        <Grid item xs={6}>
          <TrackCountryDropdown
            name={`${contactKey}.countryName`}
            placeholder=""
            label={`${contactT}.country`}
            options={countries}
            valueKey="name"
            disabled={disabled || addressDisabled}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ContactForm;
