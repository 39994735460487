import { Loading, WatchingOff, WatchingOn } from 'components/styleguide';
import { isBeingWatchedProductionCall } from 'redux-core/watching/services';
import { useFetch, useRootTranslation } from 'utils/hooks';
import { requestWithError } from 'utils/request';

import useStyles from './styles';

const isBeingWatchedProduction = (productionId) => {
  if (!productionId) return null;
  return isBeingWatchedProductionCall({ productionId });
};

export const WatchingToggle = ({ projectId, productionId, refetchWatching, toggleWatchingProduction }) => {
  const { icon } = useStyles();
  const t = useRootTranslation('productionOverview.notifications');

  const [{ isBeingWatched }, loadingIsBeingWatchedResponse, reloadValue] = useFetch(
    () => isBeingWatchedProduction(productionId),
    [productionId],
  );

  if (loadingIsBeingWatchedResponse) {
    return (
      <div>
        <Loading size={18} />
      </div>
    );
  }

  const toggleWatching = async () => {
    await requestWithError(
      toggleWatchingProduction,
      {
        isBeingWatched,
        id: productionId,
        projectId,
      },
      {
        message: {
          success: t(isBeingWatched ? 'unwatch' : 'watch'),
        },
      },
    );
    await refetchWatching();
    await reloadValue();
  };

  return isBeingWatched ? (
    <WatchingOn onClick={toggleWatching} className={icon} color="primary" />
  ) : (
    <WatchingOff onClick={toggleWatching} className={icon} color="secondary" />
  );
};
