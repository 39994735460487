/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable consistent-return */
import React from 'react';
import ConfirmationDialog from 'components/common/ConfirmationDialog';
import enhancer from './enhancer';

const noop = () => undefined;

const PromptDialog = ({
  prompt: { onClose = noop, onConfirm = noop, firstOptionConfirm = noop, secondOptionConfirm = noop, ...prompt },
  closePrompt,
}) => {
  const handleClose = async () => {
    await onClose();
    closePrompt();
  };

  const handleConfirm = async () => {
    await onConfirm();
    closePrompt();
  };
  const handleOptionConfirm = async (callbackAction) => {
    try {
      const opts = await callbackAction();
      // Keeps prompt open. Useful for when you need to open another prompt
      // from inside "callbackAction"
      if (opts?.noClose) return;
      closePrompt();
    } catch (error) {
      closePrompt();
      return Promise.reject(error);
    }
  };
  return (
    <ConfirmationDialog
      {...prompt}
      data-testid="confirmation-dialog"
      onClose={handleClose}
      firstOptionConfirm={() => handleOptionConfirm(firstOptionConfirm)}
      secondOptionConfirm={() => handleOptionConfirm(secondOptionConfirm)}
      onConfirm={handleConfirm}
    />
  );
};

export default enhancer(PromptDialog);
