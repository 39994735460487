/* eslint-disable consistent-return */
/* eslint-disable import/no-cycle */
import { createActions } from 'redux-actions';
import identity from 'ramda/src/identity';
import prop from 'ramda/src/prop';
import {
  getBookmarkListCall,
  getRecentSearchListCall,
  getSearchListCall,
  getSearchTracksCall,
} from 'redux-core/header/services';
import { getAdvancedSearchFiltersSelector, getSearchItemDetailsSelector } from './selectors';

const formatData = (data = []) => data;

const actionsDefinition = {
  LOAD_BOOKMARK_LIST: (bookmarks) => formatData(bookmarks),
  LOAD_RECENT_SEARCH_LIST: identity,
  SET_ADVANCED_SEARCH_FILTERS: identity,
  SET_SEARCH_ITEM_DETAILS: identity,
  SET_SEARCH_RESULTS: identity,
  SET_SEARCH_RESPONSE: identity,
  LOAD_SEARCH_LIST: (list) => formatData(list),
};

export const {
  loadBookmarkList,
  loadRecentSearchList,
  loadSearchList,
  setAdvancedSearchFilters,
  setSearchItemDetails,
  setSearchResults,
  setSearchResponse,
} = createActions(actionsDefinition);

export const fetchRecentSearchList = (term) => async (dispatch) => {
  const payload = await getRecentSearchListCall({ term });
  dispatch(loadRecentSearchList(payload));
};

export const fetchSearchList = (body) => async (dispatch) => {
  if (!prop('term', body)) return;
  const { data: searchList } = await getSearchListCall(body);
  dispatch(loadSearchList(searchList));
  return searchList;
};

export const fetchSearchTracks = (body) => async (dispatch) => {
  if (!prop('term', body)) return;
  const { content: payload } = await getSearchTracksCall(body);
  dispatch(loadSearchList(payload));
};

export const fetchBookmarkList = () => async (dispatch) => {
  const payload = await getBookmarkListCall();

  dispatch(loadBookmarkList(payload));
};

export const updateAdvancedFilterVersion = (version) => (dispatch, getState) => {
  const state = getState();
  const advancedFilters = getAdvancedSearchFiltersSelector(state);
  dispatch(setAdvancedSearchFilters({
    ...advancedFilters,
    version: version || new Date().getTime(),
   }));
};

export const updateSearchResponse = (response) => (dispatch, getState) => {
  const state = getState();
  const searchItemDetails = getSearchItemDetailsSelector(state);

  // we need to check if the selected item is still in the response (it might have been inactivated)
  // it it is not we need to select the first item
  // if it is, we just need to update its state
  const itemInArray = response.find(
    (item) =>
      item?.prodArray[0]?.id === searchItemDetails?.prodArray[0]?.id &&
      item?.prodArray[1]?.id === searchItemDetails?.prodArray[1]?.id &&
      item?.prodArray[2]?.id === searchItemDetails?.prodArray[2]?.id,
  );
  if (!searchItemDetails || !itemInArray) {
    dispatch(setSearchItemDetails(response[0]));
  } else {
    dispatch(setSearchItemDetails(itemInArray));
  }

  dispatch(setSearchResponse(response));
};
