/* eslint-disable no-unused-expressions */
import Grid from '@material-ui/core/Grid';
import { NORMALIZED_PRODUCTION_TYPES } from 'utils/constants';
import Form from 'components/common/Form';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import enhancer from './enhancer';
import FilterFormHeader from './FilterFormHeader';
import FilterForm from './FilterForm';

const validationSchema = Yup.object().shape({
  subProjects: Yup.array().when(['projectId', 'productionType'], {
    is: (val1, val2) => val1 && val2 === NORMALIZED_PRODUCTION_TYPES.TV,
    then: Yup.array().required('global.forms.validations.required'),
    otherwise: Yup.array(),
  }),
  productions: Yup.array().when('projectId', {
    is: (val) => val,
    then: Yup.array().required('global.forms.validations.required'),
    otherwise: Yup.array(),
  }),
});

const LicensingFilter = ({
  name,
  onClose,
  filters,
  divisions,
  divisionId,
  clearFilters,
  setLicensingFilter,
  productionId,
}) => {
  const initialValues = {
    ...filters,
    divisionId: divisions.length === 1 ? divisions[0].id : filters.divisionId,
  };
  const handleSubmit = async (values) => {
    await setLicensingFilter(values);
    onClose();
  };
  const handleClear = () => {
    productionId
      ? setLicensingFilter({
          divisionId,
          productions: [productionId],
          groupByLicensors: filters.groupByLicensors,
        })
      : clearFilters();
    onClose();
  };
  return (
    <Grid container>
      <Form
        drawerName={name}
        onSubmit={handleSubmit}
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        <FilterFormHeader onClose={onClose} />
        <FilterForm divisionId={divisionId} divisions={divisions} onClear={handleClear} productionId={productionId} />
      </Form>
    </Grid>
  );
};

LicensingFilter.propTypes = {
  name: PropTypes.string,
  onClose: PropTypes.func,
  clearFilters: PropTypes.func,
  setLicensingFilter: PropTypes.func,
  productionId: PropTypes.number,
  divisionId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  filters: PropTypes.shape({}),
  divisions: PropTypes.array,
};
export default enhancer(LicensingFilter);
