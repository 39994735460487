import { connect } from 'react-redux';
import compose from 'ramda/src/compose';
import prop from 'ramda/src/prop';
import { getLocationParametersSelector } from 'redux-core/router/selectors';
import { withFormConnected } from 'components/styleguide/HOC';
import { refetchCueContainersById } from 'redux-core/cueContainers/actions';
import { showGenericError } from 'redux-core/global-error/actions';
import { getDialogRightsByNameSelector } from 'redux-core/dialogs/selectors';
import { getSelectedProductionTypeSelector } from 'redux-core/overview/selectors';
// eslint-disable-next-line import/no-named-as-default
import DRAWERS from '../constants';

const mapStateToProps = (state, ownProps) => ({
  projectId: compose(prop('projectId'), getLocationParametersSelector)(state),
  rights: getDialogRightsByNameSelector(state, ownProps.name),
  productionType: getSelectedProductionTypeSelector(state),
});

const mapDispatchToProps = { refetchCueContainersById, showGenericError };

export default compose(connect(mapStateToProps, mapDispatchToProps), (c) =>
  withFormConnected(c, { formId: DRAWERS.CLEARANCE_ADD_EDIT }),
);
