/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-children-prop */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable @typescript-eslint/no-shadow */
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import IconButton from '@material-ui/core/IconButton';
import is from 'ramda/src/is';
import CollapsePanelGrid from './CollapsePanelGrid';
import Expand from '../icons/Expand';
import { useExpansionPanelSummaryStyles, useExpansionPanelGridStyles } from './styles';

const CollapsePanel = ({
  actions,
  children,
  classes,
  details,
  expanded,
  canExpand,
  id,
  onExpandChange,
  actionMenu,
  style,
  compact,
  backgroundColor,
  innerPanel,
  fullWidthDetails,
  firstColumnExtra,
}) => {
  const summaryClasses = useExpansionPanelSummaryStyles({
    compact,
    backgroundColor,
  });
  const gridPanel = useExpansionPanelGridStyles({ compact });

  const handleExpandChange = (e, expanded) => {
    if (!canExpand) return;
    onExpandChange(e, expanded);
  };

  return (
    <ExpansionPanel
      expanded={expanded}
      onChange={handleExpandChange}
      classes={classes}
      style={style}
      data-testid="collapsepanel"
    >
      <ExpansionPanelSummary
        aria-controls={id ? `${id}-content` : undefined}
        id={id}
        style={{ paddingLeft: 0, paddingRight: 0 }}
        classes={summaryClasses}
        data-testid="collapse-expansion-summary"
      >
        <CollapsePanelGrid
          innerPanel={innerPanel}
          style={gridPanel}
          firstColumn={() =>
            canExpand && (
              <>
                {firstColumnExtra && firstColumnExtra()}
                <IconButton size="small" aria-label="collapse">
                  <Expand expanded={expanded} />
                </IconButton>
              </>
            )
          }
          lastColumn={() =>
            actionMenu && (
              <div
                className={classnames(classes.lastColumn)}
                onClick={(e) => e.stopPropagation()}
                children={actionMenu()}
                data-testid="actionMenu-button"
              />
            )
          }
        >
          {/* Collapsed Body */}
          {is(Function, children) ? children({ expanded, id }) : children}
        </CollapsePanelGrid>
      </ExpansionPanelSummary>
      {/* Expanded Body */}
      {Boolean(details) && is(Function, details) && expanded && (
        <ExpansionPanelDetails data-testid="collapsepanel-details">
          <CollapsePanelGrid fullWidth={fullWidthDetails}>{details()}</CollapsePanelGrid>
        </ExpansionPanelDetails>
      )}
      {Boolean(actions) && is(Function, actions) && expanded && (
        <ExpansionPanelActions data-testid="collapsepanel-actions">{actions()}</ExpansionPanelActions>
      )}
    </ExpansionPanel>
  );
};

CollapsePanel.propTypes = {
  actions: PropTypes.func,
  actionMenu: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.any]).isRequired,
  classes: PropTypes.object,
  details: PropTypes.func,
  expanded: PropTypes.bool,
  canExpand: PropTypes.bool,
  innerPanel: PropTypes.bool,
  compact: PropTypes.bool,
  backgroundColor: PropTypes.oneOf(['white', 'gray', 'lightGray']),
  id: PropTypes.string,
  onExpandChange: PropTypes.func,
  style: PropTypes.object,
  fullWidthDetails: PropTypes.bool,
};

CollapsePanel.defaultProps = {
  actionMenu: () => undefined,
  compact: false,
  canExpand: true,
  backgroundColor: 'white',
  innerPanel: false,
  fullWidthDetails: false,
  classes: {},
};

export default CollapsePanel;
