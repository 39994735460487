/* eslint-disable react/no-children-prop */
/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import { FormButton, FormInputText } from 'components/common/Form/Fields';
import Box from '@material-ui/core/Box';
import { DrawerHeader } from 'components/styleguide';
import { useRootTranslation, useFetch } from 'utils/hooks';
import {
  createCuePresetCall,
  getAllCuePresetsCall,
  applyCuePresetCall,
  deleteCuePresetCall,
} from 'redux-core/cueSheet/services';
import { useField } from 'formik';
import Form from 'components/common/Form';
import { requestWithError } from 'utils/request';
import propEq from 'ramda/src/propEq';
import find from 'ramda/src/find';
import prop from 'ramda/src/prop';

import c from 'ramda/src/compose';
import * as Yup from 'yup';
import PresetRows from './PresetRows';
import enhancer from './enhancer';
import { useClearSelectedCues } from '../../../ProductionOverview/CueSheet/cueStore';

export const RIGHT_OWNERS_PRESET_DRAWER_ACTIONS = {
  APPLY: 'APPLY',
  SAVE: 'SAVE',
};

const NewPresetInputText = ({ t }) => {
  const [{ value: selectedPresetId }] = useField('selectedPresetId');

  return (
    <Box maxWidth={330}>
      <FormInputText label={t('newPresetLabel')} name="newPresetName" disabled={selectedPresetId} />
    </Box>
  );
};

NewPresetInputText.propTypes = {
  t: PropTypes.func.isRequired,
};

const CueRightOwnersPresetDrawer = ({
  onClose,
  name,
  payload: { cue, type, projectId },
  refetchCues,
  openPromptAsync,
}) => {
  const [{ presets = [] } = {}, , refetchPresets] = useFetch(
    () => projectId && getAllCuePresetsCall({ projectId }),
    [projectId],
  );
  const t = useRootTranslation('drawers.cueRightOwnersPreset');
  const isSave = type === RIGHT_OWNERS_PRESET_DRAWER_ACTIONS.SAVE;
  const clearCueSelection = useClearSelectedCues();

  const validationSchema = isSave
    ? Yup.object().shape({
        newPresetName: Yup.string().when('selectedPresetId', {
          is: (val) => val !== null,
          then: Yup.string().nullable(),
          otherwise: Yup.string().required('global.forms.validations.required'),
        }),
        selectedPresetId: Yup.number().nullable(),
      })
    : Yup.object().shape({
        selectedPresetId: Yup.number().required('global.forms.validations.required'),
      });

  const handleSubmitSave = async ({ newPresetName, selectedPresetId }, formikBag) => {
    try {
      if (selectedPresetId) {
        await openPromptAsync({
          onCancel: () => formikBag.setSubmitting(false),
          content: t('overwritePreset'),
        });
        const presetName = c(prop('name'), find(propEq('id', selectedPresetId)))(presets);
        await deleteCuePresetCall({ id: selectedPresetId });
        await createCuePresetCall({
          projectId,
          cueId: cue.id,
          name: presetName,
        });
      } else {
        await createCuePresetCall({
          projectId,
          cueId: cue.id,
          name: newPresetName,
        });
      }
    } finally {
      await refetchCues();
      clearCueSelection();
      onClose();
    }
  };
  const handleSubmitApply = async ({ selectedPresetId }) => {
    await requestWithError(applyCuePresetCall, {
      cueIds: Array.isArray(cue) ? cue : [cue.id],
      cuePresetId: selectedPresetId,
    });
    await refetchCues();
    onClose();
  };
  return (
    <Form
      drawerName={name}
      initialValues={{ newPresetName: '', selectedPresetId: null }}
      validationSchema={validationSchema}
      onSubmit={isSave ? handleSubmitSave : handleSubmitApply}
      enableReinitialize
    >
      <DrawerHeader title={isSave ? t('titleSave') : t('titleApply')} hideBackButton onClose={onClose}>
        <FormButton
          alwaysEnabled
          children="global.forms.cancel"
          size="xs"
          variant="destructive"
          onClick={() => {
            onClose();
          }}
        />
        <FormButton
          children={isSave ? 'global.forms.save' : 'global.forms.apply'}
          size="xs"
          type="submit"
          variant="primary"
        />
      </DrawerHeader>
      {isSave && <NewPresetInputText t={t} />}
      <PresetRows presets={presets} refetchPresets={refetchPresets} openPromptAsync={openPromptAsync} />
    </Form>
  );
};

CueRightOwnersPresetDrawer.propTypes = {
  onClose: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  payload: PropTypes.shape({}),
  refetchCues: PropTypes.func.isRequired,
  openPromptAsync: PropTypes.func.isRequired,
};

export default enhancer(CueRightOwnersPresetDrawer);
