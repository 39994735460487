/* eslint-disable react/require-default-props */
/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-cycle */
import React, { useState } from 'react';
import classnames from 'classnames';
import { Typography, Box } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { Check, Add } from '@mui/icons-material';
import { ClickStopper } from 'components/styleguide';
import CuePopover from 'components/common/CuePopover';
import { useLocalStorage } from 'utils/hooks';
import { TRACK_CUE_POPOVER_COOKIE } from 'utils/constants';
import { rootTranslation } from 'utils/format';
import useStyles from './styles';

type AddTrackButtonProps = {
  onClick?: Function;
  dense?: boolean;
  active?: boolean;
  disabled?: boolean;
  isUsedInScene?: boolean;
  cueContainerId?: number;
  onCueContainerIdMissing?: () => void;
};

const t = rootTranslation('drawers.productionSetup.tracks');

const id = 'cue-popover';
function AddTrackButton(props: AddTrackButtonProps) {
  const { onClick, dense, active, disabled, isUsedInScene } = props;
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [hideTrackCuePopover, setTrackCuePopover] = useLocalStorage(TRACK_CUE_POPOVER_COOKIE, false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { onCueContainerIdMissing, cueContainerId } = props;

  const handleSubmit = async ({ hide, ...values }) => {
    setAnchorEl(null);
    if (hide) setTrackCuePopover(true);
    await onClick(values);
  };

  const handleClick = async (event) => {
    event.stopPropagation();

    if (onCueContainerIdMissing != null && cueContainerId == null) {
      onCueContainerIdMissing();
    } else {
      if (isUsedInScene) return;
      if (hideTrackCuePopover) {
        setLoading(true);
        await onClick({});
        setLoading(false);
      }
      setAnchorEl(event.currentTarget);
    }
  };

  return (
    <>
      <ClickStopper bypass={false}>
        <CuePopover anchorEl={anchorEl} id={id} onSubmit={handleSubmit} setAnchorEl={setAnchorEl} />
      </ClickStopper>
      <LoadingButton
        aria-describedby={id}
        data-testid="button-add-track-to-production"
        className={classnames(classes.icon, dense)}
        aria-pressed={active}
        disabled={loading || disabled}
        onClick={handleClick}
        size="small"
        variant="text"
        loading={loading}
      >
        {active ? (
          <Box display="flex" flexDirection="row">
            <Check fontSize="small" sx={{ color: 'success.main' }} />
            <Typography variant="body2" fontWeight="bold" sx={{ color: 'success.main' }}>
              {t('added')}
            </Typography>
          </Box>
        ) : (
          <Box display="flex" flexDirection="row">
            <Add fontSize="small" sx={{ color: 'secondary.main' }} />
            <Typography variant="body2" fontWeight="bold" sx={{ color: 'secondary.main' }}>
              {t('add')}
            </Typography>
          </Box>
        )}
      </LoadingButton>
    </>
  );
}

export default AddTrackButton;
