import makeEntityServiceCalls, { makeServiceCall } from 'redux-core/serviceHelpers';

export const { get: getCueSheetsCall, update: updateCueSheetsCall } = makeEntityServiceCalls('cue-sheets', 'clear');

export const {
  create: createCueSheetVersionCall,
  delete: deleteCueSheetVersionCall,
  get: getCueSheetVersionCall,
  update: updateCueSheetVersionCall,
} = makeEntityServiceCalls('cue-sheet-versions', 'cue');

export const duplicateCueSheetVersionCall = makeServiceCall('cue-sheet-versions', 'duplicate', 'cue');
