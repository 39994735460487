/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useSecuredFilteredList } from 'utils/hooks';
import { withPermissionsPropTypes } from '../types';

const withPermissionsList = (WrappedComponent, { strategy = 'hide', filter: attr = 'options' } = {}) => {
  const Component = ({ [attr]: list = [], ...props }) => {
    const filter = useSecuredFilteredList({
      objectId: props.objectId,
      strategy,
    });
    return <WrappedComponent {...props} {...{ [attr]: filter(list) }} />;
  };

  Component.propTypes = withPermissionsPropTypes;
  Component.displayName = `Secured${WrappedComponent.displayName}`;
  return Component;
};

export default withPermissionsList;
