import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(2),
  },
  title: {
    '& > :first-child': {
      marginLeft: theme.spacing(0.75),
      minWidth: 24,
      textAlign: 'center',
    },
  },
  icon: {
    cursor: 'pointer',
  },
}));

export default useStyles;
