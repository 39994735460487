/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-children-prop */
import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import { Row, Text, Loading } from 'components/styleguide';
import CommentBox from 'components/common/CommentBox/index';
import SubtitleWithLine from 'components/common/SubtitleWithLine';
import { useRootTranslation } from 'utils/hooks';

const TaskCommentList = ({ comments, loading }) => {
  const t = useRootTranslation('drawers.tasks.create');

  return (
    <>
      <Row mb={3}>
        <SubtitleWithLine children={t('comments')} />
      </Row>
      {loading ? (
        <Loading />
      ) : !loading && !comments.length ? (
        <Box width={1} height={60} align="center">
          <Text>{t('noComments')}</Text>
        </Box>
      ) : (
        comments.map(({ id, commentCreator, comment, createdAt }) => (
          <Box mb={2} width={1} key={id}>
            <CommentBox
              image={commentCreator.image}
              userName={commentCreator.displayName}
              message={comment}
              createdAt={createdAt}
            />
          </Box>
        ))
      )}
    </>
  );
};

TaskCommentList.propTypes = {
  comments: PropTypes.array.isRequired,
  loading: PropTypes.bool,
};

TaskCommentList.defaultProps = {
  comments: [],
  loading: false,
};

export default TaskCommentList;
