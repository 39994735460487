import { connect } from 'react-redux';
import { compose } from 'ramda';
import { getDivisionId } from 'redux-core/productions/selectors';
import { completeCampaignAsset } from 'redux-core/cueContainers/assets/actions';
import withBaseDrawer from '../withBaseDrawer';

const mapStateToProps = (state) => ({
  divisionId: getDivisionId(state),
});

const mapDispatchToProps = {
  completeCampaignAsset,
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withBaseDrawer);
