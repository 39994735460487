/* eslint-disable import/no-cycle */
import makeServiceCalls, { makeServiceCall } from 'redux-core/serviceHelpers';

export const {
  delete: deleteEmailTemplate,
  getAll: getEmailTemplates,
  save: saveEmailTemplate,
} = makeServiceCalls('email-templates', 'clear');

export const {
  delete: deleteLetterTemplate,
  getAll: getLetterTemplates,
  save: saveLetterTemplate,
} = makeServiceCalls('letter-templates', 'clear');

export const getLetterCacheUrl = makeServiceCall('letter-templates', 'get-cache-url', 'clear');

export const unnatachScheduleATemplate = makeServiceCall('letter-templates', 'unattach-schedule-a-template', 'clear');

export const getScheduleACacheUrl = makeServiceCall('schedule-a-templates', 'get-cache-url', 'clear');

export const { getAll: getLetterTags } = makeServiceCalls('template-tags', 'clear');
