/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/no-children-prop */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Form from 'components/common/Form';
import { FormButton, FormInputText, FormFieldArray } from 'components/common/Form/Fields';
import { Grid } from '@material-ui/core';
import { DrawerHeader } from 'components/styleguide';
import { useTranslation } from 'react-i18next';
import { propOr, is, remove } from 'ramda';
import * as Yup from 'yup';
import EditDeleteMenuWrapperGrid from '../EditDeleteMenuWrapperGrid';
import { CommonDrawerPropTypes } from '../Drawers/constants';

const validationSchema = Yup.object().shape({
  itemsList: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required('global.forms.validations.required'),
    }),
  ),
});

const EditDeleteItemsList = ({
  name,
  onClose,
  drawerTitle,
  inputPlaceholderName,
  onSubmit,
  initialValues = [],
  deletePromptContent,
  CustomInput,
  CustomHeader,
  readOnly,
}) => {
  const { t } = useTranslation();
  const [editableIndex, setEditableIndex] = useState(null);
  const [editingFieldCache, setEditingFieldCache] = useState(null);
  const [isAdding, setIsAdding] = useState(false);
  const [isConfirming, setIsConfirming] = useState(false);

  const Input = CustomInput || FormInputText;
  const Header = CustomHeader || DrawerHeader;

  return (
    <Form
      drawerName={name}
      onSubmit={(values) => {
        onSubmit(values.itemsList);
        setEditableIndex(null);
        setIsAdding(false);
      }}
      enableReinitialize
      initialValues={{ itemsList: initialValues || [] }}
      readOnly={readOnly}
      validationSchema={validationSchema}
    >
      {(listForm) => (
        <FormFieldArray name="itemsList">
          {(fieldArrayProps) => (
            <>
              <Header title={t(drawerTitle)} onClose={onClose}>
                {editableIndex !== null && !isConfirming ? (
                  <>
                    <FormButton
                      testId="edit-delete-item-list-cancel"
                      alwaysEnabled
                      variant="destructive"
                      size="xs"
                      children="global.forms.cancel"
                      onClick={() => {
                        isAdding
                          ? fieldArrayProps.remove(0)
                          : fieldArrayProps.replace(editableIndex, editingFieldCache);
                        setIsAdding(false);
                        setEditableIndex(null);
                      }}
                    />
                    <FormButton
                      testId="edit-delete-item-list-save"
                      children="global.forms.save"
                      size="xs"
                      type="submit"
                      variant="primary"
                    />
                  </>
                ) : (
                  <FormButton
                    alwaysEnabled
                    testId="edit-delete-item-list-add"
                    children="global.forms.add"
                    size="xs"
                    type="button"
                    variant="primary"
                    onClick={() => {
                      fieldArrayProps.unshift({
                        name: '',
                      });
                      setIsAdding(true);
                      setEditableIndex(0);
                    }}
                  />
                )}
              </Header>
              <Grid container direction="column">
                {listForm.values.itemsList.map((item, index) => (
                  <EditDeleteMenuWrapperGrid
                    disableHover={readOnly || is(Number, editableIndex)}
                    setEditable={() => {
                      setEditingFieldCache(item);
                      setEditableIndex(index);
                    }}
                    key={propOr('id', 'new', item) + index}
                    onConfirm={() => {
                      onSubmit(remove(editableIndex, 1, listForm.values.itemsList));
                      fieldArrayProps.remove(editableIndex);
                      setEditableIndex(null);
                      setIsConfirming(false);
                    }}
                    promptContent={is(Function, deletePromptContent) ? deletePromptContent(item) : deletePromptContent}
                  >
                    <Input
                      readOnly={editableIndex !== index}
                      autofocus
                      value={item.name}
                      name={`itemsList.${index}.name`}
                      placeholder={t(inputPlaceholderName)}
                    />
                  </EditDeleteMenuWrapperGrid>
                ))}
              </Grid>
            </>
          )}
        </FormFieldArray>
      )}
    </Form>
  );
};

EditDeleteItemsList.propTypes = {
  name: PropTypes.string,
  CustomHeader: PropTypes.element,
  CustomInput: PropTypes.element,
  onClose: CommonDrawerPropTypes.onClose,
  drawerTitle: PropTypes.string.isRequired,
  deletePromptContent: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  inputPlaceholderName: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.array.isRequired,
};

export default EditDeleteItemsList;
