import { createSelector } from 'reselect';
import prop from 'ramda/src/prop';
import propOr from 'ramda/src/propOr';
import { getLocationParametersSelector } from 'redux-core/router/selectors';
import { WATCHING_TYPE } from 'components/Projects/WatchingTab/constants';

const getWatchingRoot = prop('watching');

export const getIsLoadingWatchingSelector = createSelector(getWatchingRoot, prop('loading'));

export const getWatchingSelector = createSelector(getWatchingRoot, prop('productions'));

export const getWatchingCountSelector = createSelector(getWatchingRoot, prop('count'));

export const getWatchingFilterSelector = createSelector(getLocationParametersSelector, prop('filters'));

export const getWatchingViewSelector = createSelector(
  getLocationParametersSelector,
  propOr(WATCHING_TYPE.CLEARANCE, 'view'),
);
