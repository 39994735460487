import { createActions } from 'redux-actions';
import identity from 'ramda/src/identity';
import i18next from 'i18next';

const actionsDefinition = {
  SHOW_SNACKBAR: identity,
  CLOSE_SNACKBAR: () => undefined,
};

export const { showSnackbar, closeSnackbar } = createActions(actionsDefinition);

export const showGenericError = (error) => {
  if (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
  showSnackbar({ message: i18next.t('global.genericError') });
};

export const getGenericSuccessMessage = () => i18next.t('global.forms.notification.save');

export const showGenericSuccess = () => showSnackbar({ message: getGenericSuccessMessage() });
