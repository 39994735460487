/* eslint-disable react/no-children-prop */
import React from 'react';
import { ClickStopper, Row, Text, ToggleButton, Clear, InfoTooltip } from 'components/styleguide';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import pathOr from 'ramda/src/pathOr';
import filter from 'ramda/src/filter';
import compose from 'ramda/src/compose';
import { FieldArray, useFormikContext } from 'formik';
import {
  FormButton,
  FormTypeAhead,
  FormInputFormat,
  FormInputText,
  FormDatePicker,
  FormCheckbox,
  FormToggleButtonGroup,
} from 'components/common/Form/Fields';
import { mapIndexed } from 'utils/object';
import append from 'ramda/src/append';
import map from 'ramda/src/map';
import remove from 'ramda/src/remove';
import isNil from 'ramda/src/isNil';
import pick from 'ramda/src/pick';
import find from 'ramda/src/find';
import propEq from 'ramda/src/propEq';
import PropTypes from 'prop-types';

const rootT = 'drawers.completeMaterial';

const CompleteAssetForm = ({ t, viewOnly }) => {
  const { values, setFieldValue } = useFormikContext();
  const formFinishers = pathOr([], ['additionalData', 'finishers'], values);
  const finishers = pathOr([], ['additionalData', 'finishersToAdd'], values);
  const finisherOptions = map(pick(['name']), formFinishers);
  const filteredOptions = compose(
    map(pick(['name'])),
    filter((v) => !find(propEq('name', v.name), finishers)),
  )(finisherOptions);

  const addFinisher = () => {
    setFieldValue(
      'additionalData.finishersToAdd',
      append(
        {
          finishingDate: values.dateUsed,
        },
        finishers,
      ),
    );
  };
  const removeFinisher = (index) => {
    setFieldValue('additionalData.finishersToAdd', remove(index, 1, finishers));
  };

  return (
    <Grid item container>
      <Row mb={3}>
        <Grid item xs={6}>
          <Text variant="formLabel">{t('assetTitle')}</Text>
          <Text variant="body1">{values.title}</Text>
        </Grid>
        <Grid item xs={6}>
          <Text variant="formLabel">{t('version')}</Text>
          <Text variant="body1">{pathOr('', ['currentCueContainerVersion', 'title'], values)}</Text>
        </Grid>
      </Row>
      <Row mb={2}>
        <Grid item xs={12}>
          <Box mb={1}>
            <Text variant="formLabel">{t('chooseAction')}</Text>
          </Box>
        </Grid>
        <Grid item xs={7}>
          <ClickStopper>
            <FormToggleButtonGroup name="killed" testid="material-killed" size="md">
              <ToggleButton value={false} disabled={viewOnly}>
                {t('use')}
              </ToggleButton>
              <ToggleButton value disabled={viewOnly}>
                {t('deactivate')}
              </ToggleButton>
            </FormToggleButtonGroup>
          </ClickStopper>
        </Grid>
      </Row>
      {Boolean(values.killed) && (
        <Row mb={2}>
          <Grid item container xs={12} direction="row" alignItems="center">
            <Grid item xs={3}>
              <FormInputFormat
                disabled
                label={`${rootT}.materialFees`}
                name="currentCueContainerVersion.approvedFees"
                prefix="$"
                type="currency"
              />
            </Grid>
            <Grid item xs={4}>
              <Box ml={2} mt={2}>
                <FormCheckbox name="feesApply" label={`${rootT}.feesApply`} disabled={viewOnly} />
              </Box>
            </Grid>
          </Grid>
        </Row>
      )}
      {!values.killed && !isNil(values.killed) && (
        <>
          <Row mb={2} justify="space-between" alignItems="center">
            <Grid item xs={4}>
              <FormDatePicker label={`${rootT}.dateUsed`} name="dateUsed" disabled={viewOnly} />
            </Grid>
            {!viewOnly && (
              <Grid item xs={3}>
                <Box mt={2}>
                  <FormButton
                    testId="complete-asset-add-finisher"
                    variant="secondary"
                    size="sm"
                    children={`${rootT}.addFinisher`}
                    onClick={addFinisher}
                    alwaysEnabled
                  />
                </Box>
              </Grid>
            )}
          </Row>
          <Row mb={3}>
            <FieldArray name="additionalData.finishersToAdd">
              {() => (
                <>
                  {mapIndexed(
                    (finisher, index) => (
                      <React.Fragment key={`finisher-${index}`}>
                        <Row mb={2} mt={2}>
                          <Divider />
                        </Row>
                        <Row mb={2} spacing={2}>
                          <Grid item container>
                            <Text variant="formLabel">{t('finisher')}</Text>
                            <Box ml={1} display="inline-flex">
                              <InfoTooltip title={t('tooltip')} arrow="right" iconSize="sm" opacity={0.3} />
                            </Box>
                          </Grid>
                          <Grid item xs={11}>
                            <FormTypeAhead
                              disabled={Boolean(finisher.name) || viewOnly}
                              name={`additionalData.finishersToAdd.${index}.name`}
                              options={finisher.name ? finisherOptions : filteredOptions}
                              valueKey="name"
                              valueName="name"
                              placeholder=""
                            />
                          </Grid>
                          {!viewOnly && (
                            <Grid item xs={1}>
                              <Clear onClick={() => removeFinisher(index)} testId="complete-asset-remove-finisher" />
                            </Grid>
                          )}
                        </Row>
                        <Row mb={2}>
                          <Grid item container xs={7} justify="space-between" direction="row">
                            <Grid item xs={5}>
                              <FormDatePicker
                                data-test-id="complete-asset-finishingDate"
                                disabled={viewOnly}
                                label={`${rootT}.finishingDate`}
                                name={`additionalData.finishersToAdd.${index}.finishingDate`}
                              />
                            </Grid>
                            <Grid item xs={5}>
                              <FormInputText
                                data-test-id="complete-asset-isci"
                                disabled={viewOnly}
                                name={`additionalData.finishersToAdd.${index}.isci`}
                                label={`${rootT}.isci`}
                              />
                            </Grid>
                          </Grid>
                        </Row>
                      </React.Fragment>
                    ),
                    finishers,
                  )}
                </>
              )}
            </FieldArray>
          </Row>
        </>
      )}
    </Grid>
  );
};

CompleteAssetForm.propTypes = {
  t: PropTypes.func.isRequired,
  viewOnly: PropTypes.bool,
};
export default CompleteAssetForm;
