/* eslint-disable import/no-cycle */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckIcon from '@material-ui/icons/Check';
import EditIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import Form from 'components/common/Form';
import { useFormikContext } from 'formik';
import { Text } from 'components/styleguide';
import { FormInputText } from 'components/common/Form/Fields';
import { updateCuePresetCall, deleteCuePresetCall } from 'redux-core/cueSheet/services';
import * as Yup from 'yup';
import classnames from 'classnames';
import { requestWithError } from 'utils/request';
import useStyles from './styles';

const validationSchema = Yup.object().shape({});

const PresetRow = ({ preset, selectedPresetId, setSelectedPresetId, refetchPresets, openPromptAsync }) => {
  const [isEditing, setIsEditing] = useState(false);
  const classes = useStyles();
  const isPresetSelected = selectedPresetId === preset.id;
  const containerClasses = classnames(classes.containerHighlight, {
    [classes.containerEditing]: isEditing,
    containerSelected: isPresetSelected, // this class is meant as an inner selector, so no need to have it in the classes
  });
  const onContainerClick = () => (isPresetSelected ? setSelectedPresetId(null) : setSelectedPresetId(preset.id));

  return isEditing ? (
    <Form
      initialValues={preset}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={async ({ id, name }) => {
        await requestWithError(updateCuePresetCall, { id, name });
        await refetchPresets();
        setIsEditing(false);
      }}
    >
      {(form) => (
        <>
          <Grid container alignItems="center" className={containerClasses} onClick={onContainerClick}>
            <Grid item xs={10}>
              <Box maxWidth={350}>
                <FormInputText name="name" />
              </Box>
            </Grid>
            <Grid item xs={1}>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setIsEditing(false);
                }}
                className={classes.iconButton}
              >
                <CancelIcon />
              </IconButton>
            </Grid>
            <Grid item xs={1}>
              <IconButton disabled={!form.isValid} type="submit" className={classes.iconButton}>
                <CheckIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Divider />
        </>
      )}
    </Form>
  ) : (
    <Grid container alignItems="center" className={containerClasses} onClick={onContainerClick}>
      <Grid item xs={10}>
        <Box width={1} pl={1}>
          <Text>{preset.name}</Text>
        </Box>
      </Grid>
      <Grid item xs={1}>
        <IconButton
          onClick={async () => {
            await openPromptAsync();
            await deleteCuePresetCall({ id: preset.id });
            await refetchPresets();
          }}
          className={classes.iconButton}
        >
          <DeleteIcon />
        </IconButton>
      </Grid>
      <Grid item xs={1}>
        <IconButton onClick={() => setIsEditing(true)} className={classes.iconButton}>
          <EditIcon />
        </IconButton>
      </Grid>
      <Divider />
    </Grid>
  );
};

PresetRow.propTypes = {
  preset: PropTypes.shape({}),
  selectedPresetId: PropTypes.number.isRequired,
  setSelectedPresetId: PropTypes.func.isRequired,
  refetchPresets: PropTypes.func,
  openPromptAsync: PropTypes.func,
};

const PresetRows = ({ presets, refetchPresets, openPromptAsync }) => {
  const form = useFormikContext();
  return presets.map((preset) => (
    <Box width={1} mt={1} key={preset.id}>
      <PresetRow
        preset={preset}
        selectedPresetId={form.values.selectedPresetId}
        setSelectedPresetId={(id) => form.setFieldValue('selectedPresetId', id)}
        refetchPresets={refetchPresets}
        openPromptAsync={openPromptAsync}
      />
    </Box>
  ));
};

PresetRows.defaultProps = { presets: [] };

export default PresetRows;
