/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react/no-children-prop */
import React from 'react';
import PropTypes from 'prop-types';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { Text } from 'components/styleguide';
import classnames from 'classnames';
import { getIn, useFormikContext } from 'formik';
import is from 'ramda/src/is';
import isNil from 'ramda/src/isNil';
import useStyles from './styles';

// The DOM API casts values to strings.
const castValue = (value) => (isNil(value) ? '' : JSON.stringify(value));

const DiffItem = ({ bold, field, label, qclearValue, qtValue, hasChanged, position, renderField, t }) => {
  const classes = useStyles();
  const { values, setFieldValue } = useFormikContext(field);
  const value = getIn(values, field);

  const getLabel = (val) => {
    if (renderField) {
      return <Text bold={bold} variant="body2" children={renderField(val)} />;
    }
    const values = is(Array, val) ? val : [val];
    if (!values.length) {
      return (
        <Text bold={bold} variant="body2">
          &nbsp;
        </Text>
      );
    }
    return values.map((v, i) => (
      <Text bold={bold} key={i} variant="body2">
        {v}
      </Text>
    ));
  };

  const handleChange = (e) => {
    const val = e.target.value;
    if (val === castValue(qclearValue)) setFieldValue(field, qclearValue);
    else setFieldValue(field, qtValue);
  };

  return (
    <FormControl classes={{ root: classes.root }} component="fieldset" id={field}>
      <RadioGroup
        aria-label={field}
        classes={{ root: classes.formGroup }}
        name={field}
        onChange={handleChange}
        row
        value={castValue(value)}
      >
        <FormControlLabel
          className={classnames({
            [classes.label]: hasChanged,
            [classes.noChanges]: !hasChanged,
            qTracks: true,
          })}
          control={<Radio classes={{ root: classes.radio }} color="primary" />}
          label={[
            <Text bold={bold} color="textSecondary" key="qtTitle" variant="formLabel">
              {t(label, { position })}
            </Text>,
            getLabel(qtValue),
          ]}
          value={castValue(qtValue)}
        />
        <FormControlLabel
          className={classnames({
            [classes.label]: hasChanged,
            [classes.noChanges]: !hasChanged,
          })}
          control={<Radio classes={{ root: classes.radio }} color="primary" />}
          label={[
            <Text bold={bold} color="textSecondary" key="qclearTitle" variant="formLabel">
              {t(label, { position })}
            </Text>,
            getLabel(qclearValue),
          ]}
          value={castValue(qclearValue)}
        />
      </RadioGroup>
    </FormControl>
  );
};

DiffItem.propTypes = {
  bold: PropTypes.bool,
  field: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  qclearValue: PropTypes.any,
  qtValue: PropTypes.any,
};

export default DiffItem;
