import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
  container: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1),
  },
  search: {
    width: '60%',
  },
}));

export default styles;
