/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { ClickStopper } from 'components/styleguide';
import CuePopover from 'components/common/CuePopover';
import { FormButton } from 'components/common/Form/Fields';
import { useLocalStorage } from 'utils/hooks';
import { useFormikContext } from 'formik';
import { TRACK_CUE_POPOVER_COOKIE } from 'utils/constants';

const id = 'create-track-cue-popover';

const CreateTrackButton = ({ anchorEl, setAnchorEl, onSubmit, ...props }) => {
  const [, setTrackCuePopover] = useLocalStorage(TRACK_CUE_POPOVER_COOKIE, false);
  const { values } = useFormikContext();

  const handleCreate = async ({ hide, ...cueValues }) => {
    // eslint-disable-next-line no-unused-expressions
    hide && setTrackCuePopover(true);
    await onSubmit({ ...values, ...cueValues });
    setAnchorEl(null);
  };

  return (
    <>
      <ClickStopper>
        <CuePopover
          anchorEl={anchorEl}
          id={id}
          onSubmit={handleCreate}
          setAnchorEl={setAnchorEl}
          transformOriginH="right"
        />
      </ClickStopper>
      <FormButton {...props} aria-describedby={id} />
    </>
  );
};

CreateTrackButton.propTypes = {
  anchorEl: PropTypes.element,
  onSubmit: PropTypes.func,
  setAnchorEl: PropTypes.func,
};

export default CreateTrackButton;
