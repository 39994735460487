/* eslint-disable react/no-children-prop */
/* eslint-disable react/no-unstable-nested-components */
import React from 'react';
import PropTypes from 'prop-types';
import { Text } from 'components/styleguide';
import Box from '@material-ui/core/Box';
import { useRootTranslation } from 'utils/hooks';
import { getAllCueSheetTemplates } from 'redux-core/import-cuesheet/services';
import InfiniteScroll from 'components/common/InfiniteScroll';
import prop from 'ramda/src/prop';
import reject from 'ramda/src/reject';
import Template from './Template';
import enhancer from './enhancer';

const rootT = 'drawers.importSaveAsTemplate';

const TEMPLATES_LIMIT = 15;

const TemplatesList = ({ divisionId, onSelect, templateId }) => {
  const t = useRootTranslation(rootT);

  const getTemplates = async (payload) => {
    const res = await getAllCueSheetTemplates(payload);
    const data = reject(prop('locked'))(res.data);
    const pagination = {
      ...res.pagination,
      totalCount: !data.length ? 0 : res.pagination.totalCount,
    };
    return { data, pagination };
  };

  return (
    <Box height="calc(100vh - 330px)" mt={2} overflow="auto" width={1}>
      <InfiniteScroll
        EmptyState={() => <Text children={t('noResults')} testId="templates-no-results" />}
        filters={{ divisionId }}
        id="import-templates"
        limit={TEMPLATES_LIMIT}
        onScroll={getTemplates}
      >
        {({ items }) =>
          items.map(({ id, name }) => (
            <Template id={id} name={name} key={id} onSelect={onSelect} selected={id === templateId} />
          ))
        }
      </InfiniteScroll>
    </Box>
  );
};

TemplatesList.propTypes = {
  divisionId: PropTypes.number.isRequired,
  onSelect: PropTypes.func.isRequired,
  templateId: PropTypes.number,
};

export default enhancer(TemplatesList);
