import split from 'ramda/src/split';
import c from 'ramda/src/compose';
import multiply from 'ramda/src/multiply';
import add from 'ramda/src/add';
import dayjs from 'dayjs';

const getCurrentLocale = () => 'en-US'; // window.navigator.language
export const dateIsAfter = ({ startDate, endDate }) => {
  if (!startDate || !endDate) return false;

  return dayjs(startDate).isAfter(endDate);
};

export const sortByDescendingDate = (dateA, dateB) => {
  if (!dateA && !dateB) return 0;
  if (!dateA) return 1;
  if (!dateB) return -1;
  if (dayjs(dateA).isSame(dateB)) return 0;

  return dateIsAfter({ startDate: dateA, endDate: dateB }) ? -1 : 1;
};

export const formatDate = (date) => {
  if (!date) return '';
  const parsedDate = new Date(date);
  const currentLocale = getCurrentLocale();
  return parsedDate.toLocaleDateString(currentLocale);
};

export const formatDateTime = (date) => {
  const parsedDate = new Date(date);
  const currentLocale = getCurrentLocale();
  return parsedDate.toLocaleString(currentLocale, {
    year: '2-digit',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  });
};

export const secondsToMMSS = (secs) => {
  if (!secs) return '';
  const pad = '00';
  const m = `${Math.floor(secs / 60)}`;
  const s = `${Math.floor(secs % 60)}`;
  const mm = pad.substring(0, pad.length - m.length) + m;
  const ss = pad.substring(0, pad.length - s.length) + s;
  return `${mm}:${ss}`;
};

export const MMSSToSeconds = (mmss) => Math.floor(new Date(`1970-01-01T00:${mmss}Z`).getTime() / 1000);

export const HHMMSStoMMSS = (hhmmss) => {
  if (!hhmmss) return '';
  const addLeadingZero = (str) => str.padStart(2, '0');
  const [hh, mm, seconds] = split(':', hhmmss);
  const minutes = c(String, add(Number(mm)), multiply(60), Number)(hh);
  return `${addLeadingZero(minutes)}:${addLeadingZero(seconds)}`;
};
