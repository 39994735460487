/* eslint-disable react/no-children-prop */
import React from 'react';
import { useRootTranslation } from 'utils/hooks';
import PropTypes from 'prop-types';
import { DrawerHeader, Row, Text } from 'components/styleguide';
import { FormButton, FormInputFormat, FormInputText } from 'components/common/Form/Fields';
import Form from 'components/common/Form';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import * as Yup from 'yup';
import { yupTimeMMSS } from 'utils/forms';
import { TIME_FORMATS, getLocalDate } from 'utils/format';
import mergeAll from 'ramda/src/mergeAll';
import { v4 as uuidv4 } from 'uuid';
import DrawerFormContentGrid from '../DrawerFormContentGrid';
import enhancer from './enhancer';

const rootT = 'drawers.versionManagerAddEdit';

const validationSchema = Yup.object().shape({
  title: Yup.string().required('global.forms.validations.required'),
  duration: yupTimeMMSS,
  version: Yup.string().required('global.forms.validations.required'),
});

const VersionManagerAddEdit = ({
  name,
  onClose,
  payload: { isEditing, version, divisionId, onSubmit, onDelete },
  openPrompt,
}) => {
  const t = useRootTranslation(rootT);
  const defaultVersion = {
    id: `new-${uuidv4()}`,
    divisionId,
    tracks: [],
  };
  const handleDelete = () => {
    onDelete();
    onClose();
  };
  const onCancel = () => {
    onClose();
  };
  const handleSubmit = async (values) => {
    const payload = mergeAll([defaultVersion, values]);
    await onSubmit(payload);
    await onClose();
  };

  return (
    <Form
      drawerName={name}
      enableReinitialize
      initialValues={version}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      <DrawerHeader title={t(`${isEditing ? 'drawerEditTitle' : 'drawerTitle'}`)} onClose={onClose}>
        <>
          {isEditing && !version.isCurrentVersion && (
            <FormButton
              testId="version-manager-add-edit-delete"
              alwaysEnabled
              variant="destructive"
              size="xs"
              children={`${rootT}.delete`}
              onClick={() =>
                openPrompt({
                  content: t('confirmDelete'),
                  onConfirm: handleDelete,
                })
              }
            />
          )}
          <FormButton
            testId="version-manager-add-edit-cancel"
            alwaysEnabled
            variant="destructive"
            size="xs"
            children="global.forms.cancel"
            onClick={() => onCancel()}
          />
          <FormButton
            testId="version-manager-add-edit-save"
            children="global.forms.save"
            size="xs"
            type="submit"
            variant="primary"
          />
        </>
      </DrawerHeader>
      <DrawerFormContentGrid>
        <Row>
          <FormInputText name="title" label={`${rootT}.versionTitle`} data-testid="version-title" required />
        </Row>
        <Row direction="row" spacing={2}>
          <Grid item xs={2}>
            <FormInputFormat data-testid="version" label={`${rootT}.version`} name="version" required type="string" />
          </Grid>
          <Grid item xs={2}>
            <FormInputFormat
              data-testid="duration"
              format={TIME_FORMATS.MMSS}
              label={`${rootT}.duration`}
              name="duration"
              type="time"
            />
          </Grid>
        </Row>
        {version && (
          <Row>
            <Row>
              <Grid item xs={6} container direction="column">
                <Text variant="formLabel">{t('lastUpdatedBy')}</Text>
                <Text variant="subtitle2">{version.updatedByName}</Text>
              </Grid>
              <Grid item xs={6} container justify="flex-end">
                <Box mt={2}>
                  <Text variant="subtitle2">{getLocalDate(version.updatedAt, 'L LT')}</Text>
                </Box>
              </Grid>
            </Row>
            <Row>
              <Grid item xs={6} container direction="column">
                <Text variant="formLabel">{t('createdBy')}</Text>
                <Text variant="subtitle2">{version.createdByName}</Text>
              </Grid>
              <Grid item xs={6} container justify="flex-end">
                <Box mt={2}>
                  <Text variant="subtitle2">{getLocalDate(version.createdAt, 'L LT')}</Text>
                </Box>
              </Grid>
            </Row>
          </Row>
        )}
      </DrawerFormContentGrid>
    </Form>
  );
};

VersionManagerAddEdit.propTypes = {
  name: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  openPrompt: PropTypes.func.isRequired,
  payload: PropTypes.shape({
    isEditing: PropTypes.bool,
    divisionId: PropTypes.number.isRequired,
    version: PropTypes.shape({}),
    onSubmit: PropTypes.func,
  }),
};

VersionManagerAddEdit.defaultProps = {
  payload: {
    isEditing: false,
    version: {},
  },
};
export default enhancer(VersionManagerAddEdit);
