import { baseApi, TQwireAccessToken } from 'services';
import { TCreateRightsPresetRequest, TPresetType, TUpdateRightsPresetRequest } from './types';

type TTerritory = {
  id: number;
  name: string;
  nameOnLetters: string;
  term: 0 | 1 | 2 | 3 | 4;
  otherDetails?: string;
  start?: string;
  end?: string;
  territory?: {
    name: string;
  };
};

export type TRightPreset = {
  id: number;
  name: string;
  territory?: TTerritory;
  start?: string;
  end?: string;
  term?: 0 | 1 | 2 | 3 | 4;
  otherDetails?: string | null;
  rights?: TTerritory[];
  territories: TTerritory[];
  descriptionOnLetters: string;
  default?: boolean;
  type?: TPresetType;
};

type TRequestPayload = TQwireAccessToken & {
  productionObjectId?: number;
};

export type TPresetToRight = {
  name: TRightPreset['name'];
  type?: TPresetType;
  descriptionOnLetters: TRightPreset['descriptionOnLetters'];
  rights: Array<{
    name: string;
    term: 0 | 1 | 2 | 3 | 4;
    otherDetails: string | null;
    territoryId: number;
    start?: string;
    end?: string;
  }>;
};

export const rightsPresetsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getRightPresets: builder.query<TRightPreset[], TRequestPayload>({
      query: (body) => ({
        url: 'clear/rights-presets/get-all',
        method: 'POST',
        body,
      }),
      providesTags: ['getAllRightsPresets'],
    }),
    updateRightPreset: builder.mutation<void, TUpdateRightsPresetRequest & { divisionId: number }>({
      query: (body) => ({
        url: 'clear/rights-presets/update',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['getAllRightsPresets'],
    }),
    createRightPreset: builder.mutation<void, TCreateRightsPresetRequest & { divisionId: number }>({
      query: (body) => ({
        url: 'clear/rights-presets/create',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['getAllRightsPresets'],
    }),
  }),
});

export const { useGetRightPresetsQuery, useUpdateRightPresetMutation, useCreateRightPresetMutation } = rightsPresetsApi;
