/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-multi-assign */
/* eslint-disable no-param-reassign */
import React, { useRef } from 'react';
import { useEventListener } from 'utils/hooks';
import { FormInputText } from '..';

const insertAtCursor = (input, textToInsert) => {
  const isSuccess = document.execCommand('insertText', false, textToInsert);
  // Firefox (non-standard method)
  if (!isSuccess && typeof input.setRangeText === 'function') {
    const start = input.selectionStart;
    input.setRangeText(textToInsert);
    // update cursor to be at the end of insertion
    input.selectionStart = input.selectionEnd = start + textToInsert.length;

    // Notify any possible listeners of the change
    const e = document.createEvent('UIEvent');
    e.initEvent('input', true, false);
    input.dispatchEvent(e);
  }
  if (input.setSelectionRange) {
    setTimeout(() => {
      input.focus();
      input.setSelectionRange(input.selectionEnd, input.selectionEnd);
    }, 500);
  }
};

export const Input = ({ eventName, ...props }) => {
  const ref = useRef();
  useEventListener(eventName, ({ detail }) => {
    if (ref && ref.current === document.activeElement) {
      insertAtCursor(ref.current, detail);
    }
  });
  return <FormInputText inputRef={ref} {...props} />;
};

export default Input;
