/* eslint-disable import/no-cycle */
import { always } from 'ramda';
import { handleActions } from 'redux-actions';

import { clearData } from 'redux-core/auth/actions';

import * as actions from './actions';

const defaultState = {
  show: false,
  message: '',
  linkText: '',
  snackbarLinkRoute: '',
  snackbarProps: {},
};

const reducer = {
  [actions.closeSnackbar]: () => defaultState,
  [actions.showSnackbar]: (_, { payload }) => ({
    ...defaultState,
    show: true,
    ...payload,
  }),
  [clearData]: always(defaultState),
};

export default handleActions(reducer, defaultState);
