/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import { Text } from 'components/styleguide';

const SectionTitle = ({ title, ...boxProps }) => (
  <Box mb={2.5} {...boxProps}>
    <Text variant="h6" bold>
      {title}
    </Text>
  </Box>
);

SectionTitle.propTypes = {
  title: PropTypes.string.isRequired,
  boxProps: PropTypes.object,
};

export default SectionTitle;
