/* eslint-disable react/no-children-prop */
import React from 'react';
import { Text } from 'components/styleguide';
import c from 'ramda/src/compose';
import forEach from 'ramda/src/forEach';
import is from 'ramda/src/is';
import isNil from 'ramda/src/isNil';
import lensProp from 'ramda/src/lensProp';
import map from 'ramda/src/map';
import omit from 'ramda/src/omit';
import prop from 'ramda/src/prop';
import propEq from 'ramda/src/propEq';
import propOr from 'ramda/src/propOr';
import reduce from 'ramda/src/reduce';
import set from 'ramda/src/set';
import sortBy from 'ramda/src/sortBy';
import symmetricDifference from 'ramda/src/symmetricDifference';
import { getIn } from 'formik';
import { isEmptyObject } from 'utils/object';
import { LICENSOR_TYPES, TRACK_TYPES_OPTIONS } from 'utils/constants';
import { concatStrings } from 'utils/index';
import i18n from 'locales/i18n';
import { formatTime } from 'utils/format';

const renderBoolean = (bool) => (bool ? i18n.t('global.yes') : i18n.t('global.no'));

const renderObject = (obj, bold) => <Text bold={bold} variant="body2" children={prop('name')(obj)} />;
const renderTime = (time) => formatTime(time, 'HH:mm');

const renderTrackType = (type) => type && TRACK_TYPES_OPTIONS.find(propEq('id', type)).name;

const renderArray = (arr, bold) => (arr || []).map(({ name }) => <Text bold={bold} variant="body2" children={name} />);

const trackDetailsFields = {
  group: 'trackDetails',
  fields: [
    { field: 'title', label: 'title', bold: true },
    {
      bold: true,
      compareBy: 'name',
      field: 'artists',
      label: 'artists',
      renderField: (v) => renderArray(v, true),
    },
    {
      bold: true,
      compareBy: 'name',
      field: 'albums',
      label: 'albums',
      renderField: (v) => renderArray(v, true),
    },
    { field: 'copyrightTag', label: 'copyright' },
    {
      compareBy: 'name',
      field: 'composers',
      label: 'writers',
      renderField: renderArray,
    },
    {
      compareBy: 'name',
      field: 'arrangers',
      label: 'arrangers',
      renderField: renderArray,
    },
    { field: 'duration', label: 'duration', renderField: renderTime },
    { field: 'year', label: 'year' },
    { field: 'isrc', label: 'isrc', bold: true },
    { field: 'type', label: 'trackType', renderField: renderTrackType },
    {
      compareBy: 'name',
      field: 'library',
      label: 'library',
      renderField: renderObject,
    },
    {
      nested: true,
      group: 'unionDetails',
      fields: [
        { field: 'nonUnion', label: 'nonUnion', renderField: renderBoolean },
      ],
    },
    { field: 'containsSamples', label: 'containsSamples' },
    { field: 'oneStopCombined', label: 'oneStop' },
  ],
};

const getOwnerField = (index, field) => concatStrings('.')(`rightsOwners[${index}]`, field);

const getExtraFields = (arr, group, fields) => {
  if (!arr || !arr.length) return [];
  return arr.map((_, i) => ({
    group,
    position: i + 1,
    nested: true,
    fields: fields.map(({ getField, ...f }) => ({ ...f, field: getField(i) })),
  }));
};

const getMasterFields = (i, { recordLabels }, position) => {
  const extraFields = getExtraFields(recordLabels, 'recordLabel', [
    {
      getField: (pos) => `${getOwnerField(i)}.recordLabels[${pos}].name`,
      label: 'name',
    },
    {
      getField: (pos) => `${getOwnerField(i)}.recordLabels[${pos}].qwireTracksRecordLabelId`,
      label: 'id',
    },
  ]);
  return {
    group: 'master',
    position,
    fields: [
      { field: getOwnerField(i, 'name'), label: 'name', bold: true },
      { field: getOwnerField(i, 'share'), label: 'share', bold: true },
      { field: getOwnerField(i, 'territory'), label: 'territory', bold: true },
      { field: getOwnerField(i, 'contact.address1'), label: 'address1' },
      { field: getOwnerField(i, 'contact.address2'), label: 'address2' },
      { field: getOwnerField(i, 'contact.city'), label: 'city', bold: true },
      { field: getOwnerField(i, 'contact.state'), label: 'state' },
      {
        field: getOwnerField(i, 'contact.postalCode'),
        label: 'zip',
        bold: true,
      },
      { field: getOwnerField(i, 'contact.country'), label: 'country' },
      ...extraFields,
    ],
  };
};

const getSyncFields = (i, { cueSheetPublishers = [] }, position) => {
  const publishers = cueSheetPublishers.map((_, pos) => ({
    field: `${getOwnerField(pos)}.cueSheetPublishers[${pos}].name`,
    label: 'cueSheetPublisher',
    position: pos + 1,
  }));
  return {
    group: 'sync',
    position,
    fields: [
      { field: getOwnerField(i, 'name'), label: 'name', bold: true },
      { field: getOwnerField(i, 'share'), label: 'share', bold: true },
      { field: getOwnerField(i, 'territory'), label: 'territory', bold: true },
      { field: getOwnerField(i, 'contact.address1'), label: 'address1' },
      { field: getOwnerField(i, 'contact.address2'), label: 'address2' },
      { field: getOwnerField(i, 'contact.city'), label: 'city', bold: true },
      { field: getOwnerField(i, 'contact.state'), label: 'state' },
      {
        field: getOwnerField(i, 'contact.postalCode'),
        label: 'zip',
        bold: true,
      },
      { field: getOwnerField(i, 'contact.country'), label: 'country' },
      ...publishers,
      {
        field: getOwnerField(i, 'writerAffiliation'),
        label: 'affiliation',
        bold: true,
      },
      { field: getOwnerField(i, 'writerIpi'), label: 'ipi' },
    ],
  };
};

const getOtherFields = (i, _, position) => ({
  group: 'other',
  position,
  fields: [
    { field: getOwnerField(i, 'name'), label: 'name', bold: true },
    { field: getOwnerField(i, 'share'), label: 'share', bold: true },
    { field: getOwnerField(i, 'territory'), label: 'territory', bold: true },
    {
      field: getOwnerField(i, 'otherType'),
      label: 'licensorType',
      bold: true,
    },
    {
      field: getOwnerField(i, 'otherFunction'),
      label: 'function',
      bold: true,
    },
    { field: getOwnerField(i, 'writerIpi'), label: 'ipi' },
    { field: getOwnerField(i, 'contact.addressLine1'), label: 'address1' },
    {
      field: getOwnerField(i, 'contact.addressLine2'),
      label: 'address2',
    },
    { field: getOwnerField(i, 'contact.city'), label: 'city', bold: true },
    { field: getOwnerField(i, 'contact.state'), label: 'state' },
    {
      field: getOwnerField(i, 'contact.postalCode'),
      label: 'zip',
      bold: true,
    },
    { field: getOwnerField(i, 'contact.country'), label: 'country' },
  ],
});

const hasObjectChanged = (a, b, field) => prop(field)(a) !== prop(field)(b);

// eslint-disable-next-line default-param-last
const hasArrayChanged = (a = [], b = [], field) => {
  const toString = c(JSON.stringify, (arr) => arr.sort(), map(prop(field)));
  return toString(a) !== toString(b);
};

const hasFieldChanged = (a, b, fields) => {
  if (fields) {
    if (!is(Array, a) && !is(Array, b)) return hasObjectChanged(a, b, fields);
    return hasArrayChanged(a, b, fields);
  }
  const getVal = (v) => (isNil(v) ? '' : v);
  // eslint-disable-next-line eqeqeq
  return getVal(a) != getVal(b);
};

const getRightOwnerFields = ({ rightsOwners = [] }) => {
  const masters = [];
  const sync = [];
  const others = [];
  rightsOwners.forEach((owner, i) => {
    const { type } = owner;
    if (type === LICENSOR_TYPES.MASTER) {
      masters.push(getMasterFields(i, owner, masters.length + 1));
    } else if (type === LICENSOR_TYPES.SYNC) {
      sync.push(getSyncFields(i, owner, sync.length + 1));
    } else others.push(getOtherFields(i, owner, others.length + 1));
  });
  return [...masters, ...sync, ...others];
};

export const calculateDiff = (qclearTrack, qtTrack) => {
  if (isEmptyObject(qclearTrack) || isEmptyObject(qtTrack)) return [[], []];
  const changes = [];
  const ownerFields = getRightOwnerFields(qtTrack);

  const mapField = ({ compareBy, ...f }) => {
    // eslint-disable-next-line no-use-before-define
    if (f.group) return mapGroup(f);
    const qclearValue = getIn(qclearTrack, f.field);
    const qtValue = getIn(qtTrack, f.field);
    const hasChanged = hasFieldChanged(qclearValue, qtValue, compareBy);
    if (hasChanged) changes.push(f.field);
    return { ...f, qclearValue, qtValue, hasChanged };
  };

  const mapGroup = (group) => ({
    ...group,
    fields: group.fields.map(mapField),
  });

  const fields = [trackDetailsFields, ...ownerFields].map(mapGroup);
  return [fields, changes];
};

/**
 * Returns the tracks parsed
 * @param {object} qclearTrack
 * @param {object} qtTrack
 */
export const parseTracks = (qclearTrack, qtTrack) => {
  const clearRightsOwners = [...propOr([], 'rightsOwners')(qclearTrack)];
  const qtracksRightsOwners = [...propOr([], 'rightsOwners')(qtTrack)];

  const reduceOwners = (param) =>
    reduce((prev, { [param]: id, type, role }) => ({ ...prev, [id]: { type, role } }), {});
  const clearIds = reduceOwners('qwireTracksRightsOwnerId')(clearRightsOwners);
  const qtrackIds = reduceOwners('id')(qtracksRightsOwners);

  // Build lookup object of id-type
  const typesAndRoles = { ...clearIds, ...qtrackIds };
  // Get symmetric difference of licensors by licensor id
  const diff = symmetricDifference(Object.keys(clearIds).map(Number), Object.keys(qtrackIds).map(Number));

  forEach((id) => {
    if (!clearIds[id]) {
      clearRightsOwners.push({ qwireTracksRightsOwnerId: id, ...typesAndRoles[id] });
    }
    if (!qtrackIds[id]) qtracksRightsOwners.push({ id, ...typesAndRoles[id] });
  })(diff);

  const sort = (param, mapId) => (track) =>
    c(
      (owners) => set(lensProp('rightsOwners'), owners)(track),
      map(({ id, qwireTracksRightsOwnerId: qtId, ...rightsOwner }) => ({
        ...rightsOwner,
        qwireTracksRightsOwnerId: qtId || id,
        ...(mapId ? { id } : {}),
      })),
      sortBy(prop(param)),
    );
  return [
    sort('qwireTracksRightsOwnerId', true)(qclearTrack)(clearRightsOwners),
    sort('id')(qtTrack)(qtracksRightsOwners),
  ];
};

export const unparseTrack = ({ rightsOwners, ...track }) => ({
  ...track,
  rightsOwners: rightsOwners.filter(
    // If it is just a placeholder Rights Owner, then filter it out
    (rightsOwner) => !isEmptyObject(omit(['type', 'id', 'qwireTracksRightsOwnerId'], rightsOwner)),
  ),
});
