/* eslint-disable react/no-children-prop */
/* eslint-disable no-nested-ternary */
import React from 'react';
import { useRootTranslation } from 'utils/hooks';
import PropTypes from 'prop-types';
import { DrawerHeader, Row, Text } from 'components/styleguide';
import { FormButton, FormInputText } from 'components/common/Form/Fields';
import Form from 'components/common/Form';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import * as Yup from 'yup';
import mergeAll from 'ramda/src/mergeAll';
import assoc from 'ramda/src/assoc';
import map from 'ramda/src/map';
import length from 'ramda/src/length';
import pluck from 'ramda/src/pluck';
import prop from 'ramda/src/prop';
import propEq from 'ramda/src/propEq';
import compose from 'ramda/src/compose';
import reject from 'ramda/src/reject';
import { getLocalDate } from 'utils/format';
import { isEmptyObject } from 'utils/object';
import { v4 as uuidv4 } from 'uuid';
import DrawerFormContentGrid from '../DrawerFormContentGrid';
import enhancer from './enhancer';

const rootT = 'drawers.scenarioManagerAddEdit';

const ScenarioManagerAddEdit = ({ name, onClose, payload: { scenario, onSubmit, scenes, scenarios } }) => {
  const scenarioNames = length(scenarios)
    ? !isEmptyObject(scenario)
      ? compose(pluck('name'), reject(propEq('id', scenario.id)))(scenarios)
      : pluck('name')(scenarios)
    : [];
  const t = useRootTranslation(rootT);
  const defaultVersion = {
    id: uuidv4(),
    isNew: true,
    current: false,
    scenes: scenes ? map((scene) => assoc('cues', [], scene), scenes) : [],
  };
  const handleSubmit = async (values) => {
    const payload = prop('id', scenario) ? values : mergeAll([defaultVersion, values]);
    await onSubmit(payload);
    await onClose();
  };
  const onCancel = () => {
    onClose();
  };
  // added validation schema inside to use scenario Names
  const validationSchema = Yup.object().shape({
    name: Yup.string().notOneOf(scenarioNames, 'global.forms.validations.duplicated'),
  });
  return (
    <Form
      drawerName={name}
      enableReinitialize
      initialValues={scenario}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      <DrawerHeader title={t('scenarioDetails')} onClose={onClose}>
        <FormButton
          testId="version-manager-add-edit-cancel"
          alwaysEnabled
          variant="destructive"
          size="xs"
          children="global.forms.cancel"
          onClick={() => onCancel()}
        />
        <FormButton
          testId="version-manager-add-edit-save"
          children="global.forms.save"
          size="xs"
          type="submit"
          variant="primary"
        />
      </DrawerHeader>
      <DrawerFormContentGrid>
        <Row>
          <FormInputText name="name" label={`${rootT}.scenarioTitle`} data-testid="scenario-title" required />
        </Row>
        <Row>
          <FormInputText
            name="description"
            label={`${rootT}.description`}
            data-testid="scenario-description"
            textArea
            rows={10}
          />
        </Row>
        {scenario && (
          <>
            <Row>
              <Grid item xs={6} container direction="column">
                <Text variant="formLabel">{t('lastUpdatedBy')}</Text>
                <Text variant="subtitle2">{scenario.updatedByName}</Text>
              </Grid>
              <Grid item xs={6} container justify="flex-end">
                <Box mt={2}>
                  <Text variant="subtitle2">{getLocalDate(scenario.updatedAt, 'L LT')}</Text>
                </Box>
              </Grid>
            </Row>
            <Row>
              <Grid item xs={6} container direction="column">
                <Text variant="formLabel">{t('createdBy')}</Text>
                <Text variant="subtitle2">{scenario.createdByName}</Text>
              </Grid>
              <Grid item xs={6} container justify="flex-end">
                <Box mt={2}>
                  <Text variant="subtitle2">{getLocalDate(scenario.createdAt, 'L LT')}</Text>
                </Box>
              </Grid>
            </Row>
          </>
        )}
      </DrawerFormContentGrid>
    </Form>
  );
};

ScenarioManagerAddEdit.propTypes = {
  name: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  payload: PropTypes.shape({
    isEditing: PropTypes.bool,
    scenario: PropTypes.shape({}),
    onSubmit: PropTypes.func,
    scenes: PropTypes.array,
  }),
};

ScenarioManagerAddEdit.defaultProps = {
  payload: {
    isEditing: false,
    scenario: {},
    scenes: [],
    scenarios: [],
  },
};
export default enhancer(ScenarioManagerAddEdit);
