import { makeStyles } from '@material-ui/core';
import { appColors } from 'components/styleguide';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    overflow: 'auto',
    '& > *': {
      '&.selected': {
        backgroundColor: appColors.rowhoverBlue,
        border: '2px solid',
        borderColor: theme.palette.primary.main,
      },
    },
    // Last child of the list is the infinite scroll trigger
    '& > :not(:last-child)': {
      borderBottom: '1px solid',
      borderBottomColor: theme.palette.divider,
      cursor: 'pointer',
      padding: theme.spacing(1, 2.5, 1, 2.25),
    },
    '& > #infinite-scroller-advanced-search': {
      width: '90%!important',
    },
  },
  noResults: {
    cursor: 'default!important',
  },
}));

export default useStyles;
