import { useFormContext, useWatch } from 'react-hook-form';
import { isEmpty } from 'lodash';
import { Box } from '@mui/system';
import { Button } from '@mui/material';
import { PUBLISHERS_TYPES, WRITERS_COMPOSERS_TYPES } from 'utils/constants';
import { TLicensorType } from 'services/tracks/types';
import { rootTranslation } from 'utils/format';
import { CreateFormPanel } from './CreateFormPanel';
import { BackToSearchButton } from '../BackToSearchButton';
import { FormToolbar } from '../createLicensors/toolbar/FormToolbar';

const t = rootTranslation('drawers.productionSetup.tracks');

export function CreateHeader({ remove, append, generateOptionId, useCueContainerPickerTreeView }) {
  const { reset } = useFormContext();
  const title = useWatch({ name: 'title' });
  const fields = useWatch({ name: 'rightsOwners' });
  const hideFormToolbar = isEmpty(title) && isEmpty(fields);

  const handleAddItem = (type: TLicensorType) => {
    const id = generateOptionId();
    if (type === 'writer') {
      return append({ id, type, role: WRITERS_COMPOSERS_TYPES.C });
    }
    if (type === 'publisher') {
      return append({ id, type, role: PUBLISHERS_TYPES.E });
    }

    return append({ id, type });
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
      <Box>
        <BackToSearchButton variant="text" />
      </Box>
      <CreateFormPanel useCueContainerPickerTreeView={useCueContainerPickerTreeView} />
      {!hideFormToolbar && (
        <Box sx={{ display: 'flex', p: 2 }}>
          <FormToolbar remove={remove} onAddItem={handleAddItem} />
          <Box sx={{ ml: 'auto' }}>
            <Button
              color="secondary"
              variant="outlined"
              onClick={() => {
                reset({
                  contentMode: 'create',
                  type: 'song',
                  library: null,
                  title: '',
                  artist: null,
                  oneStopCombined: false,
                  rightsOwners: [],
                });
              }}
            >
              {t('reset')}
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
}
