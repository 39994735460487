/* eslint-disable react/no-children-prop */
import React from 'react';
import { useRootTranslation } from 'utils/hooks';
import { withFormField } from 'components/common/Form/utils';
import { DrawerHeader } from 'components/styleguide';
import { FormButton } from 'components/common/Form/Fields';
import PropTypes from 'prop-types';

const rootT = 'drawers.payeesAddEdit';

const PayeeHeader = ({ form: { values }, onClose, trackTitle }) => {
  const { id } = values;
  const t = useRootTranslation(rootT);
  return (
    <DrawerHeader title={id ? t('editTitle') : t('addTitle')} subtitle={trackTitle} hideBackButton>
      <FormButton
        testId="payee-add-edit-cancel"
        variant="destructive"
        color="secondary"
        size="xs"
        children="global.forms.cancel"
        type="button"
        alwaysEnabled
        onClick={onClose}
      />
      <FormButton testId="payee-add-edit-save" variant="primary" size="xs" children="global.forms.save" type="submit" />
    </DrawerHeader>
  );
};
PayeeHeader.propTypes = {
  form: PropTypes.shape({}),
  onClose: PropTypes.func.isRequired,
  trackTitle: PropTypes.string,
};
export default withFormField(PayeeHeader);
