/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable import/no-cycle */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Instructions from 'components/common/Instructions';
import { Text, SearchInput } from 'components/styleguide';
import { TreeView, TreeItem } from '@material-ui/lab';
import { Grid, IconButton } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { getLetterTags } from 'redux-core/templates/services';
import { useFetch, useDebounce, useRootTranslation, useEventEmitter } from 'utils/hooks';
import { groupBy, keys, inc } from 'ramda';
import classnames from 'classnames';
import { filterSearch } from 'utils/object';
import useStyles from './styles';

export const ADD_TAG_EVENT = 'ADD_TAG_EVENT';
const LETTER_COMPOSE_TEMPLATE_COOKIE = 'LETTER_COMPOSE_TEMPLATE_COOKIE';
const EMAIL_COMPOSE_TEMPLATE_COOKIE = 'EMAIL_COMPOSE_TEMPLATE_COOKIE';

const getCookie = (isLetter) => (isLetter ? LETTER_COMPOSE_TEMPLATE_COOKIE : EMAIL_COMPOSE_TEMPLATE_COOKIE);

const rootT = 'templates.creator.tabs.composeTemplate';
const instructions = [
  {
    label: 'what.label',
    text: 'what.text',
  },
  {
    label: 'how.label',
    text: 'how.text',
  },
];

const ComposeTemplate = ({ type }) => {
  const t = useRootTranslation(rootT);
  const classes = useStyles();
  const getLetterTagsPayload = type === 'schedule_a' ? 'schedule_a' : 'regular';
  const [tags] = useFetch(() => getLetterTags({ templateType: getLetterTagsPayload }));
  const [filteredTags, setFilteredTags] = useState(tags);
  const [templateField, setTemplateField] = useState('');
  const addTag = useEventEmitter(ADD_TAG_EVENT);
  /* We're using dynamicTreeViewKey in order to trigger a remount on the TreeView so
  it expands on every category */
  const [dynamicTreeViewKey, setDynamicTreeViewKey] = useState(0);
  const getTagsByCategory = groupBy((tag) => tag.category);
  const formattedTags = getTagsByCategory(filteredTags);
  const categories = keys(formattedTags);
  const debouncedSearch = useDebounce(templateField, 300);
  const isLetterOrScheduleA = type !== 'email';

  const handleSearch = (debouncedSearch) => {
    setFilteredTags(filterSearch({ name: debouncedSearch })(tags));
    setDynamicTreeViewKey(inc(dynamicTreeViewKey));
  };

  useEffect(
      () => handleSearch(debouncedSearch),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [debouncedSearch],
  );

  useEffect(() => setFilteredTags(tags), [tags]);

  const instructionsTranslation = type === 'letter'
      ? 'letterInstructions'
      : type === 'schedule_a'
          ? 'schedule_aInstructions'
          : 'emailInstructions';

  return (
    <Grid container direction="row">
      <Instructions
        cookie={getCookie(isLetterOrScheduleA)}
        instructions={instructions}
        rootT={`${rootT}.${instructionsTranslation}`}
      />
      <Grid container item className={classes.form}>
        <Text variant="pageTitle" bold>
          {t('letterTemplateFields')}
        </Text>
        <SearchInput
          name="field-search"
          value={templateField}
          onChange={setTemplateField}
          handleSearch={handleSearch}
          label={t('search.label')}
          placeholder={t('search.placeholder')}
          aria-label="field-search"
          endAdornment={
            <IconButton size="small" color="secondary">
              <SearchIcon />
            </IconButton>
              }
        />
        <TreeView
          className={classes.treeView}
          key={dynamicTreeViewKey}
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon />}
          defaultExpanded={categories || []}
        >
          {categories.map((tag) => (
            <TreeItem
              nodeId={tag}
              key={tag}
              label={tag}
              className={classes.treeRow}
              classes={{
                      root: classnames({
                        [classes.projectRoot]: true,
                      }),
                      content: classes.allContent,
                      label: classes.allLabel,
                    }}
            >
              {formattedTags[tag].map((subTag) => (
                <TreeItem
                  nodeId={subTag.tag}
                  key={subTag.tag}
                  label={subTag.name}
                  className={classes.nestedItem}
                  classes={{
                            root: classnames({
                              [classes.treeRow]: true,
                            }),
                            content: classes.allContent,
                            label: classes.allLabel,
                          }}
                  onMouseDown={(e) => {
                            e.preventDefault();
                            addTag(subTag.tag);
                          }}
                />
                  ))}
            </TreeItem>
            ))}
        </TreeView>
      </Grid>
    </Grid>
  );
};

ComposeTemplate.propTypes = {
  type: PropTypes.oneOf(['email', 'schedule_a', 'letter']),
};

export default ComposeTemplate;
