import { compose } from 'redux';
import { connect } from 'react-redux';
import withBaseDrawer from 'components/common/Drawers/withBaseDrawer';
import {
  getProjectTenantIdSelector,
  getSelectedProductionIdSelector,
} from 'redux-core/overview/selectors';
import { getSelectedVersionIdSelector } from 'redux-core/cueSheet/selectors';
import { getDivisionId } from 'redux-core/productions/selectors';
import { refetchProduction } from 'redux-core/overview/actions';
import { openPromptAsync } from 'redux-core/prompt/actions';
import { setDrawerDirty } from 'redux-core/dialogs/actions';

const mapStateToProps = (state) => ({
  tenantId: getProjectTenantIdSelector(state),
  cueContainerId: getSelectedVersionIdSelector(state),
  productionId: getSelectedProductionIdSelector(state),
  divisionId: getDivisionId(state),
});

const mapDispatchToProps = {
  refetchProduction,
  openPromptAsync,
  setDrawerDirty,
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withBaseDrawer);
