/* eslint-disable react/no-children-prop */
import { Box } from '@mui/system';
import { Grid } from '@material-ui/core';
import mergeDeepLeft from 'ramda/src/mergeDeepLeft';
import { useEffect, useReducer } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import * as Yup from 'yup';
import { Switch } from '@mui/material';

import AddProjectButton from 'components/Projects/AddProjectButton';
import Form from 'components/common/Form';
import { FormDropdown } from 'components/common/Form/Fields';
import { Text } from 'components/styleguide';
import { COMMON_PERMISSIONS } from 'redux-core/permissions/constants';
import { ALL_OPTION } from 'utils/constants';
import { useRootTranslation, useSecuredDivisions } from 'utils/hooks';

import SearchInput from '../SearchInput';
import { getSearchPayload } from '../../utils';
import enhancer from './enhancer';
import useStyles from './styles';

const validationSchema = Yup.object().shape({});

const filtersReducer = (state, newState) => ({ ...state, ...newState });

const ProductionsFilters = ({ filterBy, term, setAdvancedSearchFilters, setSearchItemDetails, advancedFilters }) => {
  const [filters, setFilters] = useReducer(filtersReducer, {
    term,
    searchBy: ALL_OPTION.id,
    divisionId: null,
    showInactive: false,
  });

  const rootT = 'globalSearch.filters';
  const t = useRootTranslation(rootT);
  const classes = useStyles();

  const onSubmit = (values) => {
    const payload = getSearchPayload({
      term: values.term,
      filters: {
        ...values.filters,
        filterBy,
        showInactive: filters.showInactive,
      },
    });
    setSearchItemDetails(null);
    setAdvancedSearchFilters(payload);
  };

  const defaultValues = {
    term,
    filters: {
      searchBy: ALL_OPTION.id,
      divisionId: ALL_OPTION.id,
      showArchived: false,
    },
    version: new Date().getTime(),
  };

  const presetValues = advancedFilters ? { ...advancedFilters, term } : {};

  const initialValues = mergeDeepLeft(presetValues, defaultValues);

  const divisions = useSecuredDivisions(COMMON_PERMISSIONS.READ);

  const debouncedSearch = useDebouncedCallback(async () => {
    onSubmit({
      ...advancedFilters,
      term: filters.term,
      filters: {
        ...advancedFilters.filters,
        searchBy: filters.searchBy,
        ...(filters.divisionId) && { divisionId: filters.divisionId },
      },
    });
  }, 300);

  useEffect(() => {
    if (!advancedFilters) {
      onSubmit(initialValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Form disablePrompt initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
      <Grid container item justifyContent="space-between">
        <div>
          <Grid container alignItems="flex-end">
            <Box sx={{ width: '40%' }} mr={1}>
              <SearchInput
                disabled={false}
                filterBy={filterBy}
                value={filters.term}
                onChangeFilterBy={(value) => {
                  setFilters({
                    searchBy: value,
                  });
                  debouncedSearch();
                }}
                onChangeTerm={(e) => {
                  setFilters({
                    term: e.target.value,
                  });
                  debouncedSearch();
                }}
              />
            </Box>
            <div className={classes.divisions}>
              <FormDropdown
                label={t('division')}
                name="filters.divisionId"
                options={[ALL_OPTION, ...divisions]}
                onSelect={(value) => {
                  setFilters({
                    divisionId: value,
                  });
                  debouncedSearch();
                }}
              />
            </div>
            <div className={classes.showArchived}>
              <Box display="flex" alignItems="center">
                <Switch
                  name="filters.showArchived"
                  checked={filters.showInactive}
                  data-testId="switch-show-inactive"
                  onChange={() => {
                    setFilters({
                      showInactive: !filters.showInactive,
                    });
                    debouncedSearch();
                  }}
                />
                <Text variant="formLabel" bold>
                  {t('showInactived')}
                </Text>
              </Box>
            </div>
          </Grid>
        </div>
        <div>
          <Box mt={3}>
            <AddProjectButton />
          </Box>
        </div>
      </Grid>
    </Form>
  );
};

export default enhancer(ProductionsFilters);
