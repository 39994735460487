import React from 'react';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';

const SectionRow = ({ isLast, children, width }) => <Box mb={width || isLast ? 6.2 : 2.5}>{children}</Box>;

SectionRow.propTypes = {
  width: PropTypes.number,
  isLast: PropTypes.bool,
  children: PropTypes.any.isRequired,
};

export default SectionRow;
