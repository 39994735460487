import { useCallback } from 'react';
import { useAtom, useSetAtom } from 'jotai';
import { rootTranslation } from 'utils/format';
import { NORMALIZED_PRODUCTION_TYPES } from 'utils/constants';
import AutocompleteProjectField from './AutocompleteProjectField';
import {
  buttonEnabledAtom,
  cueSheetAndParentProductionAtom,
  DrawerSteps,
  existingProjectAtom,
  formStepAtom,
  initialValuesAtom,
  projectNameAtom,
  TDivision,
  TInitArguments,
  TProductionSeason,
} from './drawerStore';

const t = rootTranslation('projects');

function buildFormValues(
  division,
  production,
  productionType,
  initArguments,
  masterCurrencyId,
  defaultReleaseCampaign,
) {
  return {
    divisionId: division?.id || '',
    tenantId: initArguments?.tenantId,
    type: productionType,
    masterCurrencyId,
    project: {
      project: production || '',
      isNew: typeof production === 'string',
    },
    categoryId: division?.rapidCueDefaultCategoryId,
    duration: division?.rapidCueDefaultDuration,
    synopsis: initArguments?.synopsis,
    release: defaultReleaseCampaign,
    campaign: defaultReleaseCampaign,
  };
}

const defaultReleaseCampaignHelper = {
  [NORMALIZED_PRODUCTION_TYPES.FEATURES]: 'Release',
  [NORMALIZED_PRODUCTION_TYPES.TV]: 'Release',
  [NORMALIZED_PRODUCTION_TYPES.MKT]: 'Campaign',
};

function getDefaultReleaseCampaign(type, productionName) {
  if (type && productionName) {
    return `${productionName} ${defaultReleaseCampaignHelper[type]}`;
  }
  return '';
}

type ProjectNameInputProps = {
  masterCurrencyId: number;
  division: TDivision;
  productionType: keyof typeof NORMALIZED_PRODUCTION_TYPES;
  initArguments: TInitArguments;
  productionSeasons: TProductionSeason[];
  selectedSegments: Array<unknown>;
};

export function ProjectNameInput({
  masterCurrencyId,
  division,
  initArguments,
  productionType,
  productionSeasons,
  selectedSegments,
}: ProjectNameInputProps) {
  const [projectName, setProjectName] = useAtom(projectNameAtom);
  const setCueSheetAndParentProduction = useSetAtom(cueSheetAndParentProductionAtom);
  const setInitialValues = useSetAtom(initialValuesAtom);
  const setFormStep = useSetAtom(formStepAtom);
  const setButtonEnabled = useSetAtom(buttonEnabledAtom);
  const [existingProject, setExistingProject] = useAtom(existingProjectAtom);

  const savedOrDefaultMasterCurrencyId = productionSeasons[0]?.masterCurrencyId ?? masterCurrencyId;
  const existingProjectsOnly = !!selectedSegments?.length && division.type === NORMALIZED_PRODUCTION_TYPES.MKT;

  const onAutocompleteProjectChange = useCallback(
    (project) => {
      setCueSheetAndParentProduction(null);

      let productionValue;
      let releaseCampaignDescription = '';

      const selectedProjectExists = typeof project === 'object' && project.id;

      if (selectedProjectExists) {
        setExistingProject(project);
        productionValue = project.id;
        setProjectName(project.name);
        releaseCampaignDescription = getDefaultReleaseCampaign(productionType, project.name);
      } else if (!selectedProjectExists && !existingProjectsOnly) {
        productionValue = typeof project === 'object' ? project.name : project;
        setProjectName(productionValue);
        setExistingProject(null);
        releaseCampaignDescription = getDefaultReleaseCampaign(productionType, productionValue);
      } else {
        const typedValue = typeof project === 'object' ? project.name : project;
        setProjectName(typedValue);
      }

      const newInitialValues = buildFormValues(
        division,
        productionValue,
        productionType,
        initArguments,
        savedOrDefaultMasterCurrencyId,
        releaseCampaignDescription,
      );
      setInitialValues(newInitialValues);
      setFormStep(DrawerSteps.SelectProject);
      setButtonEnabled(false);
    },
    [
      division,
      existingProjectsOnly,
      initArguments,
      productionType,
      savedOrDefaultMasterCurrencyId,
      setButtonEnabled,
      setCueSheetAndParentProduction,
      setExistingProject,
      setFormStep,
      setInitialValues,
      setProjectName,
    ],
  );

  return (
    <AutocompleteProjectField
      label={t('newProduction.projectTitle')}
      testId="project"
      disabled={!division}
      division={division}
      onChange={onAutocompleteProjectChange}
      value={projectName}
      existingProject={existingProject}
      existingOnly={existingProjectsOnly}
    />
  );
}
