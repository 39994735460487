/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Row from '../layout/Row';
/**
 * Simple Grid structure that supports columns at the begining and the end
 * and a 12 columns grid in between
 */
const CollapsePanelGrid = ({ children, firstColumn, lastColumn, innerPanel, fullWidth, ...rowProps }) => (
  <Row mb={0} spacing={2} {...rowProps}>
    {!fullWidth && (
      <Grid container item xs={1} justify={innerPanel ? 'flex-end' : 'center'} alignItems="center">
        {firstColumn ? firstColumn() : null}
      </Grid>
    )}
    <Grid container item xs={!fullWidth ? 10 : 12} spacing={!fullWidth ? 2 : undefined}>
      {children}
    </Grid>
    {!fullWidth && (
      <Grid container item xs={1} justify="center" alignItems="center">
        {lastColumn ? lastColumn() : null}
      </Grid>
    )}
  </Row>
);

CollapsePanelGrid.displayName = 'CollapsePanelGrid';

CollapsePanelGrid.propTypes = {
  children: PropTypes.any.isRequired,
  firstColumn: PropTypes.func,
  lastColumn: PropTypes.func,
  innerPanel: PropTypes.bool,
};

CollapsePanelGrid.defaultProps = {
  fullWidth: false,
};

export default CollapsePanelGrid;
