import { baseApi } from 'services';
import {
  TGetTrack,
  TGetTrackRightsOwnersPayload,
  TGetTrackRightsOwnersResponse,
  TSearchTrackArtistsResponse,
  TSearchTrackLibrariesResponse,
  TSearchTrackPartnersResponse,
  TSearchTracksPayload,
  TSearchTracksResponse,
  TSearchTracksTypes,
} from './types';

export type TSearchTracksFilters = {
  types: TSearchTracksTypes;
  filters: {
    titleTerm?: string;
    artistTerm?: string;
    libraryTerm?: string;
  };
};

export const tracksApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getTrack: builder.query<TGetTrack, { id: number }>({
      query: (body) => ({
        url: 'tracks/tracks/get',
        method: 'POST',
        body,
      }),
    }),
    getTrackRightsOwners: builder.query<TGetTrackRightsOwnersResponse, TGetTrackRightsOwnersPayload>({
      query: (body) => ({
        url: 'tracks/rights-owners/search',
        method: 'POST',
        body,
      }),
      keepUnusedDataFor: 0,
    }),
    searchTracks: builder.query<TSearchTracksResponse, TSearchTracksPayload>({
      query: (body) => ({
        url: 'tracks/tracks/search',
        method: 'POST',
        body,
      }),
      keepUnusedDataFor: 0,
    }),
    searchTracksArtists: builder.query<TSearchTrackArtistsResponse, { term: string }>({
      query: (body) => ({
        url: 'tracks/artists/search',
        method: 'POST',
        body,
      }),
      keepUnusedDataFor: 0,
      providesTags: ['tracksArtistsSearch'],
    }),
    searchTracksLibraries: builder.query<TSearchTrackLibrariesResponse, { term: string }>({
      query: (body) => ({
        url: 'tracks/libraries/search',
        method: 'POST',
        body,
      }),
      keepUnusedDataFor: 0,
    }),
    searchTracksPartners: builder.query<TSearchTrackPartnersResponse, { term: string }>({
      query: (body) => ({
        url: 'tracks/partners/search',
        method: 'POST',
        body,
      }),
      keepUnusedDataFor: 0,
    }),
    createTrackArtist: builder.mutation<{ data: { id: number; name: string } }, { name: string }>({
      query: (body) => ({ url: 'tracks/artists/create', method: 'POST', body }),
      invalidatesTags: ['tracksArtistsSearch'],
    }),
  }),
});

export const {
  useGetTrackQuery,
  useGetTrackRightsOwnersQuery,
  useSearchTracksQuery,
  useSearchTracksArtistsQuery,
  useCreateTrackArtistMutation,
  useSearchTracksLibrariesQuery,
  useSearchTracksPartnersQuery,
} = tracksApi;
