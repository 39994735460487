import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Row, InputText, Button } from 'components/styleguide';
import { useRootTranslation, useKey } from 'utils/hooks';
import pathOr from 'ramda/src/pathOr';
import { requestWithError } from 'utils/request';
import { createTaskCommentCall } from 'redux-core/tasks/services';

const TaskCommentBox = ({ taskId, getComments }) => {
  const t = useRootTranslation('drawers.tasks.create');
  const [comment, setComment] = useState('');

  const handleSetValue = (event) => {
    const newValue = pathOr(comment, ['target', 'value'])(event);
    setComment(newValue);
  };

  /** Submit on pressing Enter */
  const [enterPressed] = useKey(13);
  const [ctrlPressed] = useKey(17);

  const handleSubmitComment = useCallback(async () => {
    await requestWithError(createTaskCommentCall, {
      comment,
      taskId,
    });
    await getComments();
    // Reset
    setComment('');
  }, [comment, setComment, getComments, taskId]);

  useEffect(() => {
    if (ctrlPressed && enterPressed && comment.length) {
      handleSubmitComment();
    }
  }, [ctrlPressed, enterPressed, comment.length, handleSubmitComment]);

  return (
    <>
      <Row>
        <InputText value={comment} onChange={handleSetValue} textArea />
      </Row>
      <Button disabled={!comment} variant={comment ? 'primary' : undefined} onClick={handleSubmitComment}>
        {t('addComment')}
      </Button>
    </>
  );
};

TaskCommentBox.propTypes = {
  taskId: PropTypes.number.isRequired,
  getComments: PropTypes.func.isRequired,
};

export default TaskCommentBox;
