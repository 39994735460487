/* eslint-disable react/no-children-prop */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import { Text, Upload as UploadIcon } from 'components/styleguide';
import { Trans } from 'react-i18next';
import ReactDropzone from 'react-dropzone';
import CircularProgress from '@material-ui/core/CircularProgress';
import classnames from 'classnames';
import { uploadFileCall } from 'redux-core/images/actions';
import { normalizeFile } from 'utils/files';
import { useRootTranslation, useSnackbar } from 'utils/hooks';
import useStyles from './styles';

export const UploadText = ({ rootT = 'files' }) => (
  <Trans i18nKey={`${rootT}.uploadText`}>
    <Text align="center" variant="h5" />
    <Text align="center" variant="h5" bold />
    <Text align="center" variant="h5" />
  </Trans>
);

const UploadZone = ({ children, inputProps, accept, disabled, withIcon }) => (
  <Box minWidth="70px">
    <input {...inputProps} hidden accept={accept} onKeyDown={(e) => e.stopPropagation()} disabled={disabled} />
    <Box mb={withIcon ? 1.25 : 0} display="flex" flexDirection="column" alignItems="center">
      {withIcon && <UploadIcon size={68} />}
      {children}
    </Box>
  </Box>
);

UploadZone.propTypes = {
  children: PropTypes.node.isRequired,
  inputProps: PropTypes.object.isRequired,
  accept: PropTypes.string,
  onUpload: PropTypes.func,
  disabled: PropTypes.bool,
  withIcon: PropTypes.bool,
};

const Dropzone = ({
  accept,
  labelText,
  uploadText,
  onDrop,
  uploadFile,
  returnBlob,
  children,
  fileFieldName,
  rounded,
  thin,
  ignoreContent,
  disabled,
  fullHeight,
  setIsParentLoading,
  withIcon,
  showChildrensAndDropzone,
  ref,
}) => {
  const [loading, setLoading] = useState(false);
  const [isDragOver, setIsDragOver] = useState(false);
  const [dragTarget, setDragTarget] = useState(false);

  const globalT = useRootTranslation('global');
  const openSnackbar = useSnackbar();

  const classes = useStyles({
    isDragOver,
    ignoreContent,
    fullHeight,
    showChildrensAndDropzone,
  });

  const hideUploadZone = (e) => {
    if (!children) return;
    if (dragTarget === e.target) {
      setIsDragOver(false);
    }
  };
  const onDropCallback = async (file) => {
    try {
      let id;
      if (uploadFile) {
        id = await uploadFileCall(file, {
          kind: file.type,
        });
      }

      const filesAsObjectLiteral = normalizeFile(file);
      const uploadedFile = {
        ...filesAsObjectLiteral,
        [fileFieldName]: id,
      };

      await onDrop(uploadedFile, returnBlob ? file : undefined);
      hideUploadZone({ target: dragTarget });
      setLoading(false);
      setIsParentLoading(false);
    } catch (err) {
      setLoading(false);
      setIsParentLoading(false);
      openSnackbar({ message: globalT('fileUploadError') });
    }
  };

  const handleFile = (file) => {
    setLoading(true);
    const reader = new FileReader();

    reader.onload = () => {
      if (onDropCallback) {
        onDropCallback(file, reader);
      }
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleDrop = async (files) => {
    setIsParentLoading(true);
    await handleFile(files[0]);
  };

  /**
   * Save current target for later evaluation
   * @see https://stackoverflow.com/a/12270694
   */
  const showUploadZone = (e) => {
    if (!children) return;
    setDragTarget(e.target);
    setIsDragOver(true);
  };

  if (disabled) {
    return children;
  }

  return (
    <Grid
      container
      data-testid="dropzone"
      item
      onDragEnter={children ? showUploadZone : undefined}
      onDragLeave={children ? hideUploadZone : undefined}
      className={classes.wrapper}
    >
      {labelText && (
        <InputLabel disableAnimation shrink position="top">
          {labelText}
        </InputLabel>
      )}
      {/* a higher zIndex puts the children always above the Dropzone */}
      <Box width={1} className={classes.dropzoneChildren}>
        {children}
      </Box>

      {(!children || isDragOver || showChildrensAndDropzone) && (
        <Box width={1} className={classes.dropzoneWrapper}>
          <ReactDropzone onDrop={handleDrop} accept={accept} onClick={(evt) => evt.preventDefault()}>
            {({ getRootProps, getInputProps }) => (
              <Grid
                container
                {...getRootProps()}
                justify="center"
                alignItems="center"
                className={classnames(classes.dropzoneContainer, {
                  [classes.rounded]: rounded,
                  [classes.dropzoneThinContainer]: thin,
                })}
              >
                {loading ? (
                  <CircularProgress disableShrink />
                ) : (
                  <UploadZone
                    ref={ref}
                    children={uploadText}
                    inputProps={getInputProps()}
                    accept={accept}
                    disabled={isDragOver}
                    withIcon={withIcon}
                  />
                )}
              </Grid>
            )}
          </ReactDropzone>
        </Box>
      )}
    </Grid>
  );
};

Dropzone.defaultProps = {
  uploadText: <UploadText />,
  fileFieldName: 'uploadedFileId',
  setIsParentLoading: () => undefined,
  uploadFile: true,
  withIcon: true,
};

Dropzone.propTypes = {
  accept: PropTypes.array,
  labelText: PropTypes.string,
  uploadText: PropTypes.node,
  onDrop: PropTypes.func,
  fileFieldName: PropTypes.string,
  children: PropTypes.node,
  rounded: PropTypes.bool,
  thin: PropTypes.bool,
  // If false, will not upload the file to the backend. Default true
  uploadFile: PropTypes.bool,
  ignoreContent: PropTypes.bool,
  returnBlob: PropTypes.bool,
  disabled: PropTypes.bool,
  fullHeight: PropTypes.bool,
  /**
   * Flag used to determine if a <FormDropzone /> or any parent
   * has triggered an upload process in a <Form /> component.
   * Calling setIsParentLoading(boolean) will update a <Form /> value and
   * it will change the state of 'dirty' and 'touched' prop
   */
  setIsParentLoading: PropTypes.func,
  withIcon: PropTypes.bool,
  showChildrensAndDropzone: PropTypes.bool,
};

export default Dropzone;
