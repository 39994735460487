/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable import/no-cycle */
import React, { useState, useRef, useEffect } from 'react';
import TableCell from '@material-ui/core/TableCell';
import MuiTableRow from '@material-ui/core/TableRow';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { get } from 'utils/object';
import { Text } from 'components/styleguide';
import Collapse from '@material-ui/core/Collapse';
import useStyles from '../styles';

const TableRow = ({
  columns,
  row,
  hover,
  role,
  tabIndex,
  onClick,
  index,
  noWrap,
  CollapseComponent,
  dark,
  collapseOpenIndex,
  selected,
  shouldRowScrollOnClick,
}) => {
  const classes = useStyles();
  const [isHovered, setIsHovered] = useState(false);
  const ref = useRef();
  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);
  const isExpanded = collapseOpenIndex === row.id;
  const collapseChildren = CollapseComponent && React.cloneElement(CollapseComponent, { open: isExpanded });
  useEffect(() => {
    if (selected && shouldRowScrollOnClick) {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  }, [selected, ref, shouldRowScrollOnClick]);
  return (
    <>
      <MuiTableRow
        ref={ref}
        className={classnames({ [classes.dark]: dark })}
        hover={hover}
        role={role}
        tabIndex={tabIndex}
        key={row.id || index}
        data-testid="table-body-row"
        onClick={onClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        selected={selected}
      >
        {columns.map((column) => {
          const value = get(column.id)(row);
          return (
            <TableCell
              key={column.id}
              data-testid="table-body-cell"
              align={column.align}
              className={classnames(classes.cell, {
                [classes.dense]: column.dense,
                [classes.noPadding]: column.noPadding,
                [classes.numberCell]: !column.align || column.align !== 'left',
              })}
              width={column.width}
              style={column.style}
              size={column.type === 'icon' ? 'small' : 'default'}
            >
              {column.renderValue ? (
                column.renderValue(row, { hover: isHovered, selected, index })
              ) : (
                <Text noWrap={noWrap}>{value}</Text>
              )}
            </TableCell>
          );
        })}
      </MuiTableRow>
      {CollapseComponent && (
        <MuiTableRow>
          <TableCell colSpan={columns.length} className={classnames({ [classes.noPadding]: !isExpanded })}>
            <Collapse mountOnEnter unmountOnExit in={isExpanded} style={{ width: '100%' }}>
              {collapseChildren}
            </Collapse>
          </TableCell>
        </MuiTableRow>
      )}
    </>
  );
};

TableRow.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  row: PropTypes.object.isRequired,
  hover: PropTypes.bool,
  role: PropTypes.string.isRequired,
  index: PropTypes.number,
  tabIndex: PropTypes.number,
  onClick: PropTypes.func.isRequired,
  selected: PropTypes.bool,
  noWrap: PropTypes.bool.isRequired,
  collapseOpenIndex: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  CollapseComponent: PropTypes.element,
  dark: PropTypes.bool,
  shouldRowScrollOnClick: PropTypes.bool,
};

TableRow.defaultProps = {
  noWrap: true,
  dark: false,
  shouldRowScrollOnClick: false,
};

export default TableRow;
