/* eslint-disable import/no-cycle */
import { compose } from 'redux';
import { connect } from 'react-redux';
import { fetchRecentSearchList, fetchSearchList } from 'redux-core/header/actions';
import { getRecentSearchListSelector } from 'redux-core/header/selectors';
import { goToLink } from 'redux-core/router/actions';
import { openDrawer } from 'redux-core/dialogs/actions';
import withStyles from './styles';

const mapDispatchToProps = {
  fetchRecentSearchList,
  fetchSearchList,
  goToLink,
  openDrawer,
};

const mapStateToProps = (state) => ({
  recentsList: getRecentSearchListSelector(state),
});

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles);
