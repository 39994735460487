/* eslint-disable react/require-default-props */
/* eslint-disable react/no-children-prop */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useRootTranslation } from 'utils/hooks';
import { Grid } from '@material-ui/core';
import { MenuItem, Select } from '@mui/material';
import { Button, InputText } from 'components/styleguide';
import { ENTER_KEY, TAB_KEY, TRACK_TYPES, TRACK_TYPES_OPTIONS } from 'utils/constants';
import lensPath from 'ramda/src/lensPath';
import path from 'ramda/src/path';
import pathOr from 'ramda/src/pathOr';
import set from 'ramda/src/set';
import split from 'ramda/src/split';
import { omit } from 'lodash';
import { FormControlLabelWrapper } from 'components/common/Form/Fields/FormField/FormField';
import useStyles from './styles';
import { useSetAddTrackDrawerFilters } from '../addTrackDrawerStore';

const rootT = 'drawers.productionSetup.tracks.filter';
const filteredTrackTypeOptions = TRACK_TYPES_OPTIONS.filter(({ id }) =>
  [TRACK_TYPES.SONG, TRACK_TYPES.LIBRARY].includes(id),
);

function TracksFilter({ filter: tracksFilter }) {
  const currentType = tracksFilter && Object.hasOwn(tracksFilter?.types, 'song') ? 'song' : 'library';

  const classes = useStyles();
  const t = useRootTranslation(rootT);
  const [filter, setFilter] = useState({
    ...tracksFilter,
    type: currentType ?? 'song',
  });
  const setTrackDrawerStore = useSetAddTrackDrawerFilters();

  const handleChange = (name) => (value) => {
    const newValue = pathOr(value, ['target', 'value'])(value);
    const newFilter = set(lensPath(split('.')(name)), newValue)(filter);

    if (filter.type !== newFilter.type) {
      freshpaint.track('Select Track Type', { trackType: newFilter.type });
    }

    setFilter(newFilter);
  };

  const handleSearch = (name) => {
    /** If we filter by TRACK_TYPE.LIBRARY, we are expected to search for SOUND_EFFECTS as well as per CLR-5776 */
    const types =
      filter.type === TRACK_TYPES.LIBRARY
        ? { [TRACK_TYPES.LIBRARY]: true, [TRACK_TYPES.SOUND_EFFECT]: true }
        : { [filter.type]: true };

    const filterWithTypes = {
      ...filter,
      types,
    };

    const filterWithoutType = omit(filterWithTypes, ['type']);

    setTrackDrawerStore({ searchQuery: filterWithoutType });

    if (name === 'filters.artistTerm') {
      freshpaint.track('Searched by Artist', { artistTerm: filter.filters.artistTerm });
    } else if (name === 'filters.titleTerm') {
      freshpaint.track('Searched by Title', { titleTerm: filter.filters.titleTerm });
    }
  };

  const handleKeyDown = (e, name) => {
    if (e.keyCode !== ENTER_KEY && e.keyCode !== TAB_KEY) return false;
    // Handle search on enter or tab pressed
    return handleSearch(name);
  };

  // On mount, search for the titleTerm
  useEffect(
    () => {
      if (filter?.filters?.titleTerm) {
        handleSearch(filter.filters.titleTerm);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(
    () => {
      if (filter.type === TRACK_TYPES.SONG) handleChange('filters.libraryTerm')(undefined);
      else handleChange('filters.artistTerm')(undefined);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filter?.type],
  );

  return (
    <Grid container justify="space-between" className={classes.container} spacing={3}>
      <Grid item container className={classes.search}>
        <Grid item container spacing={3}>
          <Grid item xs={3}>
            <FormControlLabelWrapper name="type" label={t('trackType')} fullWidth>
              <Select
                name="type"
                fullWidth
                value={filter.type}
                onChange={(event) => handleChange('type')(event.target.value)}
              >
                {filteredTrackTypeOptions.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControlLabelWrapper>
          </Grid>
          <Grid item xs={9}>
            <InputText
              disabled={filter.type === TRACK_TYPES.SONG}
              label={t('library')}
              name="filters.libraryTerm"
              onChange={handleChange('filters.libraryTerm')}
              onKeyDown={handleKeyDown}
              value={path(['filters', 'libraryTerm'])(filter)}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item container spacing={2} alignItems="flex-end">
        <Grid item xs={6}>
          <InputText
            autoFocus
            label={t('title')}
            testId="title"
            name="filters.titleTerm"
            onChange={handleChange('filters.titleTerm')}
            onKeyDown={(event) => handleKeyDown(event, 'filters.titleTerm')}
            value={path(['filters', 'titleTerm'])(filter)}
          />
        </Grid>
        <Grid item xs={4}>
          <InputText
            disabled={filter.type !== TRACK_TYPES.SONG}
            label={t('artist')}
            name="filters.artist"
            testId="artist"
            onChange={handleChange('filters.artistTerm')}
            onKeyDown={(event) => handleKeyDown(event, 'filters.artistTerm')}
            value={path(['filters', 'artistTerm'])(filter)}
          />
        </Grid>
        <Grid item xs={2}>
          <Button variant="secondary" children={t('search')} testId="search" size="md" onClick={handleSearch} />
        </Grid>
      </Grid>
    </Grid>
  );
}

TracksFilter.propTypes = {
  filter: PropTypes.shape({}),
};

export default TracksFilter;
