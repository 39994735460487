/* eslint-disable consistent-return */
/* eslint-disable no-return-await */
/* eslint-disable import/no-cycle */
import { genericPutCallRequest, genericGetCallRequest } from 'utils/request';
import { makeServiceCall } from 'redux-core/serviceHelpers';
import { memoizeWith } from 'ramda';
import FileSaver from 'file-saver';

export const getUploadAccessCall = async ({ kind, contentType }) => {
  const call = makeServiceCall('uploads', 'new');
  return call({ kind, contentType });
};

export const uploadToBucketCall = async (uploadUrl, { file }) =>
  await genericPutCallRequest({
    path: uploadUrl,
    data: file,
  });

export const createFile = async ({ fileName, key, kind, resizingOptions }) =>
  makeServiceCall(
    'uploads',
    'create',
  )({
    fileName,
    key,
    kind,
    resizingOptions,
  });

export const getDownloadUrlCall = memoizeWith(String, async (id) => {
  if (!id) return;
  return makeServiceCall('uploads', 'get-download-url')({ id });
});

export const downloadFile = async (id, name) => {
  const url = await getDownloadUrlCall(id);
  const blob = await genericGetCallRequest({
    url,
    responseType: 'blob',
  });
  FileSaver.saveAs(blob, name);
};

export const downloadFromUrl = async (url, name) => {
  const blob = await genericGetCallRequest({
    url,
    responseType: 'blob',
  });
  FileSaver.saveAs(blob, name || url);
};
