import { Box, Checkbox, Divider, Grid, InputLabel, Tooltip, Typography } from '@mui/material';
import { useSetAtom } from 'jotai';
import { useCallback, useState } from 'react';
import { getLocalDate } from 'utils/format';
import { composeLetterAtom } from '../composeLetterStore';

export function ComposeLetterComposeEmailFileAttachment({ id, fileName, createdAt }) {
  const [isAttached, setIsAttached] = useState<boolean>(false);
  const setComposeLetter = useSetAtom(composeLetterAtom);

  const toggleAttachment = useCallback(() => {
    const isAttachedNow = !isAttached;
    setIsAttached(isAttachedNow);

    if (!isAttachedNow) {
      setComposeLetter((prev) => ({
        ...prev,
        email: { ...prev.email, extraAttachments: prev.email.extraAttachments?.filter((a) => a.id !== id) },
      }));
    }

    if (isAttachedNow) {
      setComposeLetter((prev) => ({
        ...prev,
        email: { ...prev.email, extraAttachments: [...prev.email.extraAttachments, { id, name: fileName }] },
      }));
    }
  }, [isAttached, setIsAttached, setComposeLetter, id, fileName]);

  return (
    <Grid item xs={12} container>
      <Grid
        item
        xs={12}
        sx={{
          display: 'flex',
          alignItems: 'center',
          overflow: 'hidden',
        }}
      >
        <Checkbox id={`attach-file-${id}`} onChange={toggleAttachment} value={isAttached} />
        <Box>
          <Tooltip title={fileName} placement="top">
            <InputLabel htmlFor={`attach-file-${id}`} sx={{ overflow: 'hidden', whiteSpace: 'nowrap' }}>
              {fileName}
            </InputLabel>
          </Tooltip>
          <Typography variant="caption" color="text.secondary">
            <label htmlFor={`attach-file-${id}`}>{getLocalDate(createdAt, 'MM/DD/YY LT')}</label>
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} pt={3}>
        <Divider />
      </Grid>
    </Grid>
  );
}
