import { Box } from '@mui/system';

export function EnterKeyListener({ onEnterKey, children }) {
  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      if (typeof onEnterKey === 'function') {
        onEnterKey();
      }
    }
  };

  return <Box onKeyDown={handleKeyDown}>{children}</Box>;
}
