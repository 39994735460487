/* eslint-disable no-use-before-define */
/* eslint-disable import/order */
import { createActions } from 'redux-actions';
import i18next from 'i18next';
import compose from 'ramda/src/compose';
import filter from 'ramda/src/filter';
import flatten from 'ramda/src/flatten';
import identity from 'ramda/src/identity';
import map from 'ramda/src/map';
import prop from 'ramda/src/prop';
import { getUsersCall, sendInviteCall } from './services';
import { getDivisionId } from 'redux-core/productions/selectors';
import { requestWithError } from 'utils/request';
import { getLocationParametersDivisionId } from 'redux-core/router/selectors';
import { CONTEXTUAL_PERMISSION_ACTIONS } from './constants';
import { PERMISSION_TYPES } from 'utils/constants';
import { fuseAll, get } from 'utils/object';

const actionsDefinition = { SEARCH_USERS: identity };

export const { searchUsers } = createActions(actionsDefinition);

export const searchForUsers = (payload) => async (dispatch, getState) => {
  const state = getState();
  const divisionId = prop('divisionId', payload) || getDivisionId(state);
  const defaultValues = { includeLoggedUser: false };
  const extendedPayload = fuseAll([defaultValues, { divisionId }, payload]);

  const users = await requestWithError(getUsersCall, extendedPayload);
  dispatch(searchUsers(users));
  return users;
};

export const sendInviteUser = (payload) => async (dispatch, getState) => {
  const state = getState();
  const { shareList = [] } = payload;
  const divisionId = getLocationParametersDivisionId(state) || payload.divisionId;

  const extendedPayload = {
    email: get('0.userEmail')(shareList),
    divisionId,
    originApp: 'qclear',
    contextualPermissions: getContextualPermissions(payload.objectId)(shareList),
  };

  await requestWithError(sendInviteCall, extendedPayload, {
    message: {
      success: i18next.t('drawers.shareWithUser.notifications.singleInvite.success'),
      failed: i18next.t('drawers.shareWithUser.notifications.singleInvite.failed'),
    },
  });
};

export const getContextualPermissions = (objectId) =>
  compose(
    // Avoid sending falsy permissions in the payload
    filter(prop('permission')),
    flatten,
    map(({ clearPermissions, commonPermissions, cuePermissions, userId }) => {
      const shared = {
        action: CONTEXTUAL_PERMISSION_ACTIONS.GRANT,
        objectId,
        userId,
      };
      return [
        {
          ...shared,
          fieldName: PERMISSION_TYPES.CLEAR,
          permission: clearPermissions,
        },
        {
          ...shared,
          fieldName: PERMISSION_TYPES.COMMON,
          permission: commonPermissions,
        },
        {
          ...shared,
          fieldName: PERMISSION_TYPES.CUE,
          permission: cuePermissions,
        },
      ];
    }),
  );
