/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import ClosedIcon from '@material-ui/icons/ChevronRight';
import OpenIcon from '@material-ui/icons/KeyboardArrowDown';
import Collapse from '@material-ui/core/Collapse';
import IconBaseButton from 'components/ClearanceDetails/IconBaseButton';
import { appColors, Text } from 'components/styleguide';
import { FieldArray, useFormikContext } from 'formik';
import { useRootTranslation, usePrompt } from 'utils/hooks';
import pathOr from 'ramda/src/pathOr';
import prop from 'ramda/src/prop';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { isReadOnly } from 'components/common/Form/utils';
import useStyles from './styles';
import SubLabelForm from '../SubLabelForm';

const SubLabelCollapse = ({ currentIndex, toggleCollapse, index, t, ...props }) => {
  const classes = useStyles();
  const open = index === currentIndex;
  const ToggleIcon = open ? OpenIcon : ClosedIcon;
  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center" className={classes.row}>
        <Box display="flex" direction="row" alignItems="center">
          <IconBaseButton
            Icon={ToggleIcon}
            onClick={() => (open ? toggleCollapse(null) : toggleCollapse(index))}
            color={appColors.darkBlue}
            width={20}
          />
          <Text variant="subtitle2" bold>
            {prop('name', props.subLabel) || t('newSubLabel')}
          </Text>
        </Box>
      </Box>
      <Collapse mountOnEnter unmountOnExit in={open} style={{ width: '100%' }}>
        <Box p={2}>
          <SubLabelForm index={index} {...props} />
        </Box>
      </Collapse>
    </>
  );
};

const SubLabelExpandable = (props) => {
  const { rootKey, isVerifiedTrack } = props;
  const { validateForm, values, ...form } = useFormikContext();
  const openPrompt = usePrompt();
  const subLabel = pathOr([], [rootKey, 'recordLabels'], values);
  const [currentIndex, setCurrentIndex] = useState(0);
  const readOnly = isReadOnly(form) || isVerifiedTrack;
  const t = useRootTranslation('drawers.licensorsAddEdit.subLabel');
  const removeCollapse = (fieldArray, index) => {
    const { remove } = fieldArray;
    openPrompt({
      content: t('promptRemoveSubLabel'),
      onConfirm: async () => {
        await remove(index);
        await validateForm();
      },
    });
  };
  return (
    <Grid container direction="column">
      <FieldArray name={`${rootKey}.recordLabels`}>
        {(fieldArray) => (
          <>
            {!readOnly && (
              <Grid container width="100%" justify="flex-end">
                <IconBaseButton
                  bold
                  Icon={AddCircleIcon}
                  text={t('addNew')}
                  color={appColors.darkBlue}
                  width={120}
                  onClick={() => fieldArray.insert(0, { active: true })}
                />
              </Grid>
            )}

            {subLabel.map((value, index) => (
              <SubLabelCollapse
                key={index}
                currentIndex={currentIndex}
                toggleCollapse={setCurrentIndex}
                subLabel={value}
                index={index}
                remove={() => removeCollapse(fieldArray, index)}
                t={t}
                {...props}
              />
            ))}
          </>
        )}
      </FieldArray>
    </Grid>
  );
};

export default SubLabelExpandable;
