/* eslint-disable import/no-cycle */
import { requestWithError } from 'utils/request';
import { getDivisionId } from 'redux-core/productions/selectors';
import mergeAll from 'ramda/src/mergeAll';
import { userIdSelector } from 'redux-core/auth/selectors';
import { getSelectedProductionObjectIdSelector } from 'redux-core/overview/selectors';
import { getGenericSuccessMessage } from 'redux-core/global-error/actions';
import { getTasksCall, createTaskCall, updateTaskCall, deleteTaskCall } from './services';

/**
 * Get All Tasks
 */
const getTasks = (payload) => requestWithError(getTasksCall, payload);

/** Tab: Inbox */
export const getInboxTasks = (payload) => async (dispatch, getState) => {
  const state = getState();
  const recipientUserId = userIdSelector(state);
  const extendedPayload = mergeAll([{ recipientUserId, completed: false }, payload]);

  return getTasks(extendedPayload);
};

/** Tab: Sent */
export const getSentTasks = (payload) => async (dispatch, getState) => {
  const state = getState();
  const creatorUserId = userIdSelector(state);
  const extendedPayload = mergeAll([{ creatorUserId }, payload]);

  return getTasks(extendedPayload);
};

/** Tab: Completed */
export const getCompletedTasks = (payload) => async () => {
  const extendedPayload = mergeAll([{ completed: true }, payload]);

  return getTasks(extendedPayload);
};

/** Tab: MyTeam */
export const getMyTeamTasks = (payload) => async (dispatch, getState) => {
  const divisionId = getDivisionId(getState());
  const extendedPayload = mergeAll([{ divisionId }, payload]);

  return getTasks(extendedPayload);
};

/**
 * Create a new Task
 */
export const createTask = (payload) => async (dispatch, getState) => {
  const state = getState();
  const productionObjectId = getSelectedProductionObjectIdSelector(state);
  const divisionId = getDivisionId(state);
  const extendedPayload = mergeAll([
    {
      divisionId,
      productionObjectId,
    },
    payload,
  ]);
  const task = await requestWithError(createTaskCall, extendedPayload, {
    message: {
      success: getGenericSuccessMessage(),
    },
  });
  return task;
};

/**
 * Updates a Task
 */
export const updateTask = (payload) => async (dispatch, getState) => {
  const state = getState();
  const divisionId = getDivisionId(state);
  const extendedPayload = mergeAll([
    {
      divisionId,
    },
    payload,
  ]);
  const task = await requestWithError(updateTaskCall, extendedPayload, {
    message: {
      success: getGenericSuccessMessage(),
    },
  });
  return task;
};

/**
 * Delete a Task
 */
export const deleteTask = (payload) => async () => {
  const task = await requestWithError(deleteTaskCall, payload, {
    message: {
      success: getGenericSuccessMessage(),
    },
  });
  return task;
};
