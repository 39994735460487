/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import classnames from 'classnames';
import { concatStrings } from 'utils/index';
import useStyles from './styles';

const StyledIconButton = ({ className, testId, ...props }) => {
  const classes = useStyles();
  return (
    <IconButton
      {...props}
      data-testid={concatStrings('-')('icon-button', testId)}
      className={classnames(className, classes.button)}
    />
  );
};

export default StyledIconButton;
