/* eslint-disable react/no-children-prop */
import React from 'react';
import { DrawerHeader } from 'components/styleguide';
import { useFormikContext } from 'formik';
import { FormButton } from 'components/common/Form/Fields';
import { getPayload } from '../../utils';

const FormHeader = ({
  isEditing,
  t,
  onClose,
  divisionId,
  trackId,
  type,
  createRightsOwners,
  showSnackbar,
  showGenericError,
  clearancesFetch,
  licensorsExist,
}) => {
  const { values, resetForm } = useFormikContext();
  const handleClose = () => {
    onClose();
  };

  const handleSubmitAndAddNew = async () => {
    try {
      const payload = getPayload({
        values,
        isEditing,
        trackId,
        type,
        licensorsExist,
        divisionId,
      });
      await createRightsOwners(payload);
      await resetForm();
      showSnackbar({ message: t('success') });
      await clearancesFetch();
    } catch (error) {
      showGenericError();
    }
  };
  return (
    <DrawerHeader title={isEditing ? t('editTitle') : t('addTitle')} onClose={handleClose} hideBackButton>
      <FormButton
        testId={`${type}-licensor-cancel`}
        variant="destructive"
        color="secondary"
        size="xs"
        children="global.forms.cancel"
        type="button"
        alwaysEnabled
        onClick={() => handleClose()}
      />
      {isEditing ? (
        <FormButton
          testId={`${type}-licensor-save`}
          variant="primary"
          size="xs"
          children="global.forms.save"
          type="submit"
        />
      ) : (
        <>
          <FormButton
            testId={`${type}-licensor-save-and-close`}
            variant="secondary"
            color="primary"
            size="sm"
            children="global.forms.saveAndClose"
            type="submit"
          />
          <FormButton
            testId={`${type}-licensor-save-and-add-new`}
            variant="primary"
            size="sm"
            children="global.forms.saveAndAddNew"
            type="button"
            onClick={handleSubmitAndAddNew}
          />
        </>
      )}
    </DrawerHeader>
  );
};

export default FormHeader;
