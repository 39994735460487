/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { BaseDrawer } from 'components/styleguide';
import { useRootTranslation } from 'utils/hooks';
import { concatStrings } from 'utils/index';
import FormNameDrawer from '../Generic/FormNameDrawer';
import { CommonDrawerPropTypes } from '../constants';

const EditorsDrawer = ({ name, onClose, payload: { onSubmit, editors = [], readOnly } }) => {
  const rootT = 'drawers.productionSetup.editors';
  const t = useRootTranslation(rootT);
  const handleSubmit = (values) => {
    onSubmit({
      editors: values,
    });
  };
  return (
    <FormNameDrawer
      name={name}
      deletePromptContent={({ firstName, lastName }) => t('delete', { name: concatStrings(' ')(firstName, lastName) })}
      onClose={onClose}
      readOnly={readOnly}
      rootT={rootT}
      payload={{
        onSubmit: handleSubmit,
        people: editors,
      }}
    />
  );
};

EditorsDrawer.propTypes = {
  name: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  payload: PropTypes.object.isRequired,
};

const Drawer = ({ open, onClose, ...props }) => (
  <BaseDrawer open={open} onClose={onClose}>
    <EditorsDrawer onClose={onClose} {...props} />
  </BaseDrawer>
);

Drawer.propTypes = CommonDrawerPropTypes;

export default Drawer;
