/* eslint-disable class-methods-use-this */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-underscore-dangle */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import IdleTimerTracker from 'react-idle-timer';
import ROUTES from 'components/Routes/routes';
import { is } from 'ramda';
import enhancer from './enhancer';
import LockedScreenDialog from './LockedScreenDialog';

class IdleTimer extends Component {
  constructor(props) {
    super(props);
    this.idleTimer = null;
    this.onAction = this._onAction.bind(this);
    this.onIdle = this._onIdle.bind(this);

    /**
     * We are using localStorage as a cross-browser alternative
     * to the Broadcast Channel API. This listener get's triggered every time
     * the user changes the local storage in any QWireClear tab or window (but
     * the one emitting the event). Below, in the _onAction method, we
     * briefly create and delete a message in LocalStorage. This way, all tabs and
     * windows in QWireCue are kept from locking if the user performs an action in any
     * of them.
     */
    window.addEventListener('storage', (e) => {
      // we check e.newValue so we don't reset on both creation and deletion of message
      if (e.key === 'userPerformedAction' && e.newValue === 'true') {
        this.idleTimer && this.idleTimer.reset();
      }
    });
  }

  _onAction() {
    /**
     * When the user performs an action, we send a message to the
     * other tabs and windows using localstorage,
     * letting them know they should reset the timer.
     */
    localStorage.setItem('userPerformedAction', 'true');
    localStorage.removeItem('userPerformedAction', 'true');
  }

  _onIdle() {
    const { locked, lockUserOut, logOut } = this.props;
    if (!locked) {
      lockUserOut();
    } else {
      logOut();
    }
  }

  render() {
    const { idleTimeout, locked, location } = this.props;
    return (
      <>
        <LockedScreenDialog
          open={
            locked === true &&
            location !== ROUTES.LOGIN_CALLBACK &&
            location !== ROUTES.LOGIN &&
            location !== ROUTES.PARTNER
          } // don't show locked screen on popup or login
        />

        {is(Number, idleTimeout) && (
          <IdleTimerTracker
            ref={(ref) => {
              this.idleTimer = ref;
            }}
            events={['keydown', 'wheel', 'mousedown', 'touchstart', 'touchmove']}
            element={document}
            onIdle={this.onIdle}
            onAction={this.onAction}
            debounce={250}
            timeout={(locked ? idleTimeout : 14400) * 1000} // if user is locked for two hours and doesn't do anything on the screen, they get logged out
          />
        )}
      </>
    );
  }
}

IdleTimer.propTypes = {
  idleTimeout: PropTypes.number,
  locked: PropTypes.bool,
  location: PropTypes.string.isRequired,
  lockUserOut: PropTypes.func.isRequired,
  logOut: PropTypes.func.isRequired,
};

export default enhancer(IdleTimer);
