import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import isEmpty from 'ramda/src/isEmpty';

export const useRootTranslation = (rootPath: string = '', fallback: string = null) => {
  const { t } = useTranslation();
  return useCallback(
    (s: string | TemplateStringsArray, params?: Parameters<typeof t>) => {
      const path = isEmpty(rootPath) ? s : `${rootPath}.${s}`;
      if (fallback) return t([path, `${fallback}.${s}`], params);
      return t(path, params);
    },
    [t, rootPath, fallback],
  );
};
