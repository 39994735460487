/* eslint-disable import/no-cycle */
import React from 'react';
import classNames from 'classnames';
import is from 'ramda/src/is';
import PropTypes from 'prop-types';
import ImagePlaceholder from '@material-ui/icons/CameraAlt';
import { NORMALIZED_PRODUCTION_TYPES } from 'utils/constants';
import values from 'ramda/src/values';
import { TypeIcon } from 'components/styleguide';
import useStyles from './styles';

/**
 * Sizes
 */
export const posterSizes = {
  xs: 'xs',
  md: 'md',
  lg: 'lg',
  xl: 'xl',
};

const checkSizeString = (size) => is(String, size);
const getSize = (size) => posterSizes[size] || 'xs';

const getPosterClassnames = (size) => (checkSizeString(size) ? { [getSize(size)]: size } : undefined);

const Poster = ({ size, url, type, title, testId }) => {
  const classes = useStyles(checkSizeString(size) ? { url, type } : { url, size, type });
  const isPlaceholder = !url;
  return (
    <div
      className={classNames(classes.image, getPosterClassnames(size), {
        isPlaceholder,
      })}
      data-testid={testId}
      title={title}
    >
      {isPlaceholder &&
        (type ? (
          <TypeIcon
            type={type}
            size="50%"
            color="rgba(0, 0, 0, 0.3)"
            customProps={{
              'data-testid': 'type-placeholder',
            }}
          />
        ) : (
          <ImagePlaceholder data-testid="poster-placeholder" />
        ))}
    </div>
  );
};

Poster.propTypes = {
  size: PropTypes.oneOfType([
    PropTypes.oneOf(Object.keys(posterSizes)),
    PropTypes.shape({
      width: PropTypes.number,
      height: PropTypes.number,
    }),
  ]),
  url: PropTypes.string,
  type: PropTypes.oneOf(values(NORMALIZED_PRODUCTION_TYPES)),
  title: PropTypes.string,
  testId: PropTypes.string,
};

Poster.defaultProps = {
  size: 'md',
  testId: 'poster',
};

export default Poster;
