/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-children-prop */
/* eslint-disable react/jsx-props-no-spreading */
import { useCallback, useState } from 'react';
import { useRootTranslation, useFetch, usePermissions } from 'utils/hooks';
import { getAllClearancesCall, cuesTransfer } from 'redux-core/clearances/services';
import { Grid, Skeleton } from '@mui/material';
import { BaseDrawer, DrawerHeader, Text } from 'components/styleguide';
import { FormButton } from 'components/common/Form/Fields';
import { SecuredForm } from 'components/common/Secured';
import { COMMON_ROLES } from 'redux-core/permissions/constants';
import { DragDropContext } from 'react-beautiful-dnd';
import enhancer from './enhancer';
import { NewOption } from './NewOption';
import { ExpandableClearanceRow } from './ExpandableClearanceRow';

const GridSpacer = ({ space = 1 }) => <Grid item xs={space} />;

const TableHeader = () => (
  <Grid
    container
    direction="row"
    spacing={2}
    justifyContent="flex-start"
    style={{ paddingLeft: '50px', paddingBottom: '16px', paddingRight: '16px' }}
  >
    <Grid item xs={2}>
      <Text align="left" semibold>
        Clearance / Option
      </Text>
    </Grid>
    <Grid item xs={2}>
      <Text align="left" semibold>
        Rights
      </Text>
    </Grid>
    <GridSpacer />
    <Grid item xs={2}>
      <Text align="left" semibold>
        Territory
      </Text>
    </Grid>
    <Grid item xs={2}>
      <Text align="left" semibold>
        Dates
      </Text>
    </Grid>
    <GridSpacer space={3} />
  </Grid>
);

const ClearanceFullListDrawer = ({ name, payload, onClose }) => {
  const { track, objectId, cueContainer } = payload;
  const t = useRootTranslation('clearanceDetails');
  const isAllowedToEdit = usePermissions({
    objectId,
    permissions: COMMON_ROLES.EDITOR,
  });

  const [refetch, setRefetch] = useState(false);
  const toggleRefetch = () => setRefetch((state) => !state);

  const handleOnDragEnd = async ({ draggableId, source, destination }) => {
    await cuesTransfer({
      cueId: Number(draggableId),
      fromClearanceId: Number(source.droppableId),
      toClearanceId: Number(destination.droppableId),
    });
    toggleRefetch();
  };

  const [clearances, loading] = useFetch(
    () =>
      getAllClearancesCall({
        trackId: track.id,
      }),
    [track.id, refetch],
  );

  return (
    <SecuredForm drawerName={name} permissions={COMMON_ROLES.EDITOR}>
      <DrawerHeader title={t('editClearances')} onClose={onClose}>
        <FormButton
          alwaysEnabled
          variant="destructive"
          size="xs"
          testId="clearance-option-drawer-cancel"
          children="global.forms.close"
          onClick={onClose}
        />
      </DrawerHeader>
      <TableHeader />
      {loading ? (
        <Skeleton variant="rectangular" height={60} />
      ) : (
        <DragDropContext onDragEnd={handleOnDragEnd}>
          {clearances.map((clearance, index) => (
            <ExpandableClearanceRow
              {...payload}
              key={`clearance_key${index}`}
              clearance={clearance}
              cueContainer={cueContainer}
              track={track}
              isAllowedToEdit={isAllowedToEdit}
              reload={toggleRefetch}
              isOnlyOne={clearances?.length === 1}
            />
          ))}
        </DragDropContext>
      )}
      {isAllowedToEdit && <NewOption {...payload} />}
    </SecuredForm>
  );
};

const Drawer = ({ open, onClose, clearForms, ...props }) => {
  const handleClose = useCallback(async () => {
    await clearForms();
    onClose();
    props.payload?.clearancesFetch?.();
  }, [clearForms, onClose, props.payload]);

  return (
    <BaseDrawer open={open} onClose={handleClose} xlarge>
      <ClearanceFullListDrawer onClose={handleClose} {...props} />
    </BaseDrawer>
  );
};

export default enhancer(Drawer);
