import { createSelector } from 'reselect';
import find from 'ramda/src/find';
import prop from 'ramda/src/prop';
import propEq from 'ramda/src/propEq';
import { getDivisionListSelector } from 'redux-core/divisions/selectors';

const getSettingsRoot = (state) => state.settings;

export const getActiveDivisionId = createSelector(getSettingsRoot, prop('activeDivisionId'));

export const getActiveDivision = createSelector(getActiveDivisionId, getDivisionListSelector, (id, divisions) =>
  find(propEq('id', id))(divisions),
);

export const getActiveDivisionAccessClear = createSelector(getActiveDivision, prop('canAccessClear'));

export const getActiveDivisionAccessCue = createSelector(getActiveDivision, prop('canAccessCue'));

export const getActiveTenantId = createSelector(getSettingsRoot, prop('activeTenantId'));
