import { useCallback } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Grid } from '@mui/material';
import { useAtomValue, useSetAtom } from 'jotai';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { showSuccessToast } from 'components/common/toastNotifications';
import { useSendLetterMutation } from 'services/clear/letters';
import { TLicensorContact } from 'services/clear/types';
import { ComposeLetterLeftPanel } from '../ComposeLetterLeftPanel';
import { ComposeLetterRightPanel } from '../ComposeLetterRightPanel';
import { composeLetterAtom, TComposeLetterWriteEmailContentStepForm, composeLetterSteps } from '../composeLetterStore';
import { ComposeLetterComposeEmailRightPanel } from './ComposeLetterComposeEmailRightPanel';
import { ComposeLetterComposeEmailLeftPanel } from './ComposeLetterComposeEmailLeftPanel';
import { useGetEmailTemplatesAndSetDefault } from './useGetEmailTemplatesAndSetDefault';
import { useChangeSelectedLicensor } from '../hooks/useChangeSelectedLicensor';

const schema = yup.object().shape({
  templateId: yup.number().required('global.forms.validations.required'),
  clearanceStatusId: yup.number().required('global.forms.validations.required'),
  docxUrl: yup.string(),
  pdfUrl: yup.string(),
  toRecipients: yup
    .array()
    .min(1, 'global.forms.validations.required')
    .of(yup.string().email('Invalid email address'))
    .nullable(),
  ccRecipients: yup.array().of(yup.string().email('Invalid email address')).nullable(),
  from: yup.string().email().required('global.forms.validations.required'),
  subject: yup.string().required('global.forms.validations.required'),
  body: yup.string().required('global.forms.validations.required'),
});

export function ComposeLetterComposeEmailStep() {
  const composeLetter = useAtomValue(composeLetterAtom);
  const updateComposeLetter = useSetAtom(composeLetterAtom);
  const { loadNextLicensor } = useChangeSelectedLicensor();
  const [sendLetter] = useSendLetterMutation();

  const formBag = useForm<TComposeLetterWriteEmailContentStepForm>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: composeLetter.email,
  });

  const onDefaultTemplateChange = useCallback(
    (defaultTemplateId: number) => {
      formBag.setValue('templateId', defaultTemplateId);
    },
    [formBag],
  );

  const { filteredTemplates, isLoadingTemplates } = useGetEmailTemplatesAndSetDefault(onDefaultTemplateChange);

  const goBack = useCallback(async () => {
    updateComposeLetter((prev) => ({
      ...prev,
      currentStep: composeLetterSteps[2],
    }));
  }, [updateComposeLetter]);

  const sendLetterAsEmail = async (email) => {
    const { divisionId } = composeLetter;
    const { letter } = composeLetter;

    const letterUrl = letter.letterFileType === 'docx' ? letter.docxUrl : letter.pdfUrl;

    const sendLetterPayload = {
      divisionId,
      bundle: false,
      contact: {
        ...composeLetter.contact,
        usedLast: true,
        isCreating: null,
      } as TLicensorContact,
      clearanceBundleLicensorsIds: composeLetter.clearanceBundleLicensorsIds,
      licensorStatus: email.clearanceStatusId,
      extraAttachments: composeLetter.email.extraAttachments,
      letterType: composeLetter.letterType,
      email: {
        templateId: email.templateId,
        attachmentName: `${composeLetter.documentTitle}.${letter.letterFileType}`,
        to: email.toRecipients,
        subject: email.subject,
        body: email.body,
        cc: email.ccRecipients,
        bcc: email.ccRecipients,
        from: email.from,
      },
      letterDocxUrl: letter.docxUrl,
      letterUrl,
    };
    await sendLetter(sendLetterPayload).unwrap();
    showSuccessToast('Emails sent successfully');
    loadNextLicensor();
  };

  return (
    <FormProvider {...formBag}>
      <form style={{ height: '100%' }} onSubmit={formBag.handleSubmit(sendLetterAsEmail)}>
        <Grid container flexDirection="row" sx={{ width: '100%', height: '100%' }}>
          <ComposeLetterLeftPanel
            isSubmitting={formBag.formState.isSubmitting || !composeLetter.email?.editorReady}
            goBack={goBack}
          >
            <ComposeLetterComposeEmailLeftPanel
              formBag={formBag}
              emailTemplates={filteredTemplates}
              isLoadingTemplates={isLoadingTemplates}
            />
          </ComposeLetterLeftPanel>
          <ComposeLetterRightPanel>
            <ComposeLetterComposeEmailRightPanel formBag={formBag} />
          </ComposeLetterRightPanel>
        </Grid>
      </form>
    </FormProvider>
  );
}
