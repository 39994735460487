import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { isEmpty } from 'lodash';
import { useState } from 'react';
import { TGetLetterResponse } from 'services/clear/letters';
import { rootTranslation } from 'utils/format';

const t = rootTranslation('letters.generate.missingTags');

type MissingTagsDialogProps = {
  missedTags: TGetLetterResponse['missedTags'];
};

export function MissingTagsDialog({ missedTags = [] }: MissingTagsDialogProps) {
  const hasMissingTags = !isEmpty(missedTags);
  const [open, setOpen] = useState(hasMissingTags);

  const handleClose = () => setOpen(false);

  return hasMissingTags ? (
    <Dialog
      sx={{ '& .MuiDialog-paper': { width: '40%', maxHeight: 435 } }}
      maxWidth="md"
      scroll="paper"
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Box>
        <DialogTitle id="alert-dialog-title">{t('title')}</DialogTitle>
        <DialogContent dividers>
          <Typography variant="h6" id="alert-dialog-description">
            {t('subtitle')}
          </Typography>
          <DialogContentText id="alert-dialog-description">
            <ul>
              {missedTags.map((tag) => (
                <Typography component="li" color="textPrimary">
                  {tag}
                </Typography>
              ))}
            </ul>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus data-testid="missed-tags-continue">
            {t('continue')}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  ) : null;
}
