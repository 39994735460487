import { baseApi } from 'services';
import { TEmailTemplate } from './types';

export type TGetAllEmailTemplatesRequest = {
  divisionId?: number;
};

export const emailTemplatesApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllEmailTemplates: builder.query<TEmailTemplate[], TGetAllEmailTemplatesRequest>({
      query: (body) => ({ url: 'clear/email-templates/get-all', method: 'POST', body }),
    }),
  }),
});

export const { useGetAllEmailTemplatesQuery } = emailTemplatesApi;
