import * as React from 'react';
import { DataGridPremium, DataGridPremiumProps, GridRowId } from '@mui/x-data-grid-premium';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { TSearchTrack } from 'services/tracks/types';
import { TPagination } from 'services';
import { TrackDetails } from '../../TrackDetails/TrackDetails';
import { getColumns } from './tableSchema';
import { useAddTrackDrawerFilters, useSetAddTrackDrawerFilters } from '../../addTrackDrawerStore';

type TracksListProps = {
  tracks?: TSearchTrack[];
  loading?: boolean;
  onRowClick?: (track: TSearchTrack) => void;
  hideAddTrackButton?: boolean;
  pagination: TPagination;
  onCueContainerIdMissing?: () => void;
  duplicateTrack: () => void;
};

export function TrackListTable({
  loading,
  tracks,
  onRowClick,
  hideAddTrackButton,
  pagination,
  onCueContainerIdMissing,
  duplicateTrack,
}: TracksListProps) {
  const disableSelectionOnClick = onRowClick == null;

  const getDetailPanelContent = React.useCallback<NonNullable<DataGridPremiumProps['getDetailPanelContent']>>(
    ({ row }) => <TrackDetails trackId={row.id} />,
    [],
  );

  const filters = useAddTrackDrawerFilters();
  const setFilters = useSetAddTrackDrawerFilters();
  const isLibraryType = Object.hasOwn(filters?.searchQuery?.types, 'library');

  const [expandedRow, setExpandedRow] = React.useState([]);

  const handleDetailPanelExpandedRowIdsChange = (args: GridRowId[]) => {
    setExpandedRow([args.at(-1)]);
  };

  const onCellClick = React.useCallback(
    ({ row }) => {
      onRowClick?.(row);
    },
    [onRowClick],
  );

  const columnsSchema = React.useMemo(
    () => getColumns(hideAddTrackButton, isLibraryType, onCueContainerIdMissing, duplicateTrack),
    [hideAddTrackButton, isLibraryType, onCueContainerIdMissing, duplicateTrack],
  );

  return (
    <DataGridPremium
      initialState={{ detailPanel: { expandedRowIds: [] } }}
      loading={loading}
      columns={columnsSchema}
      rows={tracks ?? []}
      detailPanelExpandedRowIds={expandedRow}
      onDetailPanelExpandedRowIdsChange={handleDetailPanelExpandedRowIdsChange}
      getDetailPanelContent={getDetailPanelContent}
      getDetailPanelHeight={() => 'auto'}
      pagination
      paginationMode="server"
      autoHeight
      pageSize={pagination?.size ?? 20}
      onPageChange={(newPage) => setFilters({ page: newPage })}
      page={filters.page}
      rowCount={pagination?.totalCount}
      onPageSizeChange={(newPageSize) => setFilters({ limit: newPageSize })}
      rowsPerPageOptions={[20]}
      disableColumnMenu
      disableColumnResize
      disableRowGrouping
      disableColumnReorder
      disableSelectionOnClick={disableSelectionOnClick}
      onCellClick={onCellClick}
      components={{
        DetailPanelExpandIcon: ExpandMoreIcon,
        DetailPanelCollapseIcon: ExpandLessIcon,
      }}
    />
  );
}
