/* eslint-disable @typescript-eslint/no-shadow */
import React from 'react';
import PropTypes from 'prop-types';
import { Row } from 'components/styleguide';
import { getTaskCommentsCall, readTaskCommentsCall } from 'redux-core/tasks/services';
import { useFetch } from 'utils/hooks';
import { requestWithError } from 'utils/request';
import TaskCommentBox from './TaskCommentBox';
import TaskCommentList from './TaskCommentList';
import { getUserImagesInComments } from '../../utils';

const TaskComments = ({ taskId, getTabTasks }) => {
  const [comments, loading, getComments] = useFetch(async () => {
    const { comments = [] } = await getTaskCommentsCall({ taskId });
    return getUserImagesInComments(comments);
  });

  const handleReadComments = async () => {
    await requestWithError(readTaskCommentsCall, { taskId });
    await getTabTasks();
  };

  useFetch(handleReadComments);

  return (
    <>
      <Row>
        <TaskCommentList comments={comments} loading={loading} />
      </Row>
      <Row mb={6}>
        <TaskCommentBox taskId={taskId} getComments={getComments} />
      </Row>
    </>
  );
};

TaskComments.propTypes = {
  taskId: PropTypes.number.isRequired,
  getTabTasks: PropTypes.func.isRequired,
};

export default TaskComments;
