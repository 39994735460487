/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-children-prop */
/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-shadow */
import React from 'react';
import PropTypes from 'prop-types';
import Form from 'components/common/Form';
import { FormButton, FormInputText, FormItemsList, FormImage } from 'components/common/Form/Fields';
import { Grid } from '@material-ui/core';
import { BaseDrawer, DrawerHeader } from 'components/styleguide';
import SubtitleWithLine from 'components/common/SubtitleWithLine';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import compose from 'ramda/src/compose';
import identity from 'ramda/src/identity';
import mergeLeft from 'ramda/src/mergeLeft';
import propOr from 'ramda/src/propOr';
import { useCanAccessDivisionSelector, useDrawerFormFetch } from 'utils/hooks';
import { getAdditionalData } from 'utils/forms';
import { getProjectById, searchProductions } from 'redux-core/productions/services';
import propEq from 'ramda/src/propEq';
import { COMMON_PERMISSIONS } from 'redux-core/permissions/constants';
import { PRODUCTION_TYPES } from 'utils/constants';
import { requestWithError } from 'utils/request';
import enhancer from './enhancer';
import DrawerFormContentGrid from '../DrawerFormContentGrid';
// eslint-disable-next-line import/no-named-as-default
import DRAWERS, { CommonDrawerPropTypes } from '../constants';

const rootT = 'drawers.productionSetup.project';

const items = ({ keepProjectInfo, openDrawer, onSubmit }) => [
  {
    label: `${rootT}.items.fkaTitles`,
    name: 'additionalData.fkaTitles',
    testId: 'additionalData-fkaTitles',
    action: (values, { readOnly }) => {
      keepProjectInfo(values);
      openDrawer(DRAWERS.PRODUCTION_SETUP_FKA_TITLES, {
        fkaTitles: getAdditionalData('fkaTitles')(values),
        onSubmit,
        parentName: values.name,
        readOnly,
      });
    },
  },
  {
    label: `${rootT}.items.akaTitles`,
    name: 'additionalData.akaTitles',
    testId: 'additionalData-akaTitles',
    action: (values, { readOnly }) => {
      keepProjectInfo(values);
      openDrawer(DRAWERS.PRODUCTION_SETUP_AKA_TITLES, {
        akaTitles: getAdditionalData('akaTitles')(values),
        onSubmit,
        parentName: values.name,
        readOnly,
      });
    },
  },
];

const validationSchema = Yup.object().shape({
  name: Yup.string().required('global.forms.validations.required'),
  description: Yup.string().nullable(),
  abbreviation: Yup.string().nullable(),
});

export const isProjectNameUniq = async ({ term = '', divisionId, tenantId } = {}) => {
  if (!term) return false;
  const { items } = await requestWithError(searchProductions, {
    term,
    tenantId,
    divisionId,
  });
  const projectName = term.trim();
  const projectExist = items.find(propEq('name', projectName));

  return !projectExist;
};

const REQUIRED_PERMISSIONS = { permissions: COMMON_PERMISSIONS.CREATE };

const ProjectDrawer = ({
  name,
  initialValues: { secondaryOpened, ...providedInitialValues },
  keepProjectInfo,
  fetchUpdateProject,
  openDrawer,
  onClose,
  payload: { gotToNextStep = identity, id, ...payload },
  setupInfo: { tenantId, divisionId },
  savePosterId,
}) => {
  const { t } = useTranslation();
  const readOnly = !useCanAccessDivisionSelector(REQUIRED_PERMISSIONS, false);

  const handleSecondarySubmit = (payload) => {
    const additionalData = compose(mergeLeft(payload), propOr([], 'additionalData'))(providedInitialValues);
    return fetchUpdateProject({ additionalData }, true, true);
  };

  const [values] = useDrawerFormFetch({ id, secondaryOpened }, () => getProjectById({ id, tenantId, divisionId }));

  const initialValues = {
    active: true,
    ...payload,
    ...values,
    ...providedInitialValues,
  };

  const projectNameExist = async (values, actions) => {
    const isProjectUniq = await isProjectNameUniq({
      term: values.name,
      tenantId,
      divisionId,
    });

    if (!isProjectUniq) {
      actions.setFieldError('name', 'drawers.productionSetup.project.validations.duplicateName');
      const value = true;
      const shouldValidate = false;
      actions.setFieldTouched('name', value, shouldValidate);
      return value;
    }
    return false;
  };

  const handleSubmit = async (values, actions) => {
    if (id) {
      if (initialValues.name !== values.name) {
        if (await projectNameExist(values, actions)) {
          return;
        }
      }
      return fetchUpdateProject(values);
    }

    if (await projectNameExist(values, actions)) {
      return;
    }
    keepProjectInfo({ ...values, dirty: true });
    gotToNextStep({ ...values, edit: true });
  };

  const onCancel = () => {
    onClose({ content: t('global.prompt.cancel') });
  };

  return (
    <Form
      drawerName={name}
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      readOnly={readOnly}
    >
      <DrawerHeader
        title={t(`${rootT}.${id ? 'drawerEditTitle' : 'drawerTitle'}`)}
        onClose={onClose}
        testId="project-drawer-header"
      >
        <FormButton
          testId="project-drawer-cancel"
          alwaysEnabled
          variant="destructive"
          size="xs"
          children="global.forms.cancel"
          onClick={() => onCancel()}
        />
        <FormButton
          children={id ? 'global.forms.save' : 'global.forms.next'}
          testId={id ? 'project-drawer-save' : 'project-drawer-next'}
          size="xs"
          type="submit"
          variant="primary"
        />
      </DrawerHeader>
      <DrawerFormContentGrid container spacing={3}>
        <Grid item xs={3}>
          <FormImage
            name="posterId"
            resizingOptions={{ width: 128, height: 188 }}
            testId="posterId"
            onUpload={(id) => {
              savePosterId({
                type: PRODUCTION_TYPES.PROJECT,
                posterId: id,
              });
            }}
          />
        </Grid>

        <Grid item xs={9}>
          <SubtitleWithLine children={`${rootT}.details`} />
          <FormInputText autoFocus label={`${rootT}.name`} name="name" required testId="name" />
          {/**
           * Commented on purpose in CLR-3633
           * @see https://jira.qwire.com/browse/CLR-3633
           */}
          {/* <Grid item xs={7}>
            <FormDropdown
              label={`${rootT}.status`}
              name="active"
              options={[
                { id: true, name: t('global.forms.status.active') },
                { id: false, name: t('global.forms.status.inactive') }
              ]}
            />
          </Grid> */}
          <FormInputText name="description" textArea label={`${rootT}.projectSynopsis`} testId="description" />
          <FormItemsList
            items={items({
              keepProjectInfo,
              openDrawer,
              onSubmit: handleSecondarySubmit,
            })}
            readOnly={readOnly}
          />
          <FormInputText name="abbreviation" label={`${rootT}.abbreviation`} testId="abbreviation" />
          <FormInputText name="notes" textArea label={`${rootT}.notes`} testId="notes" />
        </Grid>
      </DrawerFormContentGrid>
    </Form>
  );
};

ProjectDrawer.propTypes = {
  name: PropTypes.string,
  initialValues: PropTypes.object,
  keepProjectInfo: PropTypes.func.isRequired,
  fetchUpdateProject: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  openDrawer: PropTypes.func.isRequired,
  payload: PropTypes.shape({
    // If id passed, the drawer will open in "edit" mode
    id: PropTypes.number,
  }),
  setupInfo: PropTypes.shape({
    divisionId: PropTypes.number,
    tenantId: PropTypes.number,
  }),
  savePosterId: PropTypes.func.isRequired,
};

const Drawer = ({ open, onClose, cleanSetup, ...props }) => {
  const handleClose = async () => {
    await onClose();
    await cleanSetup();
  };
  return (
    <BaseDrawer open={open} onClose={handleClose}>
      <ProjectDrawer onClose={handleClose} {...props} />
    </BaseDrawer>
  );
};

Drawer.propTypes = CommonDrawerPropTypes;

export default enhancer(Drawer);
