import { makeStyles } from '@material-ui/core';
import { appColors } from 'components/styleguide';
import { InputFieldHeight } from 'components/styleguide/newTheme';

const styles = makeStyles((theme) => ({
  dot: {
    borderRadius: 4,
    height: theme.spacing(),
    marginBottom: 1,
    width: theme.spacing(),
    '&.visible': {
      backgroundColor: appColors.darkBlue,
    },
  },
  option: {
    minHeight: InputFieldHeight.medium,
    '& > *': {
      marginRight: theme.spacing(3),
    },
    '& > :first-child': {
      marginLeft: theme.spacing(0.75),
      marginRight: theme.spacing(0.75),
    },
  },
}));

export default styles;
