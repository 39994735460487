/* eslint-disable import/no-cycle */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import MUIToggleButton from '@mui/material/ToggleButton';
import { buttonSizes } from 'components/styleguide/Button';
import keys from 'ramda/src/keys';
import { concatStrings } from 'utils';
import { ToggleButtonGroupContext } from '../ToggleButtonGroup';

const getSize = (size) => (size === 'auto' ? 'auto' : buttonSizes[size] || buttonSizes.xs);

const ToggleButton = ({ size, testId, ...props }) => {
  const context = useContext(ToggleButtonGroupContext);
  const computedSize = context.size || size;

  const isDisabled = context.disabled || props.disabled;

  const buttonSize = getSize(computedSize);

  return (
    <MUIToggleButton
      {...props}
      disabled={isDisabled}
      size={buttonSize}
      data-testid={concatStrings('-')('mui-togglebutton', testId)}
    />
  );
};

ToggleButton.propTypes = {
  disabled: PropTypes.bool,
  size: PropTypes.oneOf(keys(buttonSizes)),
  testId: PropTypes.string,
};

export default ToggleButton;
