import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
  divider: {
    height: 38,
    margin: theme.spacing(0, 3),
  },
  divisions: {
    width: 220,
  },
  showArchived: {
    width: 230,
  },
}));

export default styles;
