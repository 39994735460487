/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { Row } from 'components/styleguide/index';
import { useRootTranslation } from 'utils/hooks';
import { FormTypeAhead } from 'components/common/Form/Fields';
import { FILTERS_LIST_FOR_ASSETS } from '../constants';

function AssetsFilters({ filtersData, loading }) {
  const t = useRootTranslation('drawers.advancedFilters.assetsFilters');
  const tGlobal = useRootTranslation('global');
  const { assets, assetStatuses, assetTypes, vendors, completedStatuses } = filtersData;

  const sharedProps = {
    placeholder: tGlobal('forms.placeholder.search'),
    isMultiple: true,
    loading,
  };

  return (
    <>
      <Row mb={3}>
        <Grid item xs={8}>
          <FormTypeAhead {...sharedProps} name="assets" label={t('assets')} options={assets} />
        </Grid>
      </Row>
      <Row mb={3}>
        <Grid item xs={8}>
          <FormTypeAhead {...sharedProps} name="assetStatuses" label={t('assetStatuses')} options={assetStatuses} />
        </Grid>
      </Row>
      <Row mb={3}>
        <Grid item xs={8}>
          <FormTypeAhead {...sharedProps} name="assetTypes" label={t('assetTypes')} options={assetTypes} />
        </Grid>
      </Row>
      <Row mb={3}>
        <Grid item xs={8}>
          <FormTypeAhead {...sharedProps} name="vendorIds" label={t('vendors')} options={vendors} />
        </Grid>
      </Row>
      <Row mb={3}>
        <Grid item xs={8}>
          <FormTypeAhead
            {...sharedProps}
            name="completedStatuses"
            label={t('completedStatuses')}
            options={completedStatuses}
            menuWidth={550}
          />
        </Grid>
      </Row>
    </>
  );
}

AssetsFilters.propTypes = {
  loading: PropTypes.bool,
  filtersData: PropTypes.exact(
    FILTERS_LIST_FOR_ASSETS.reduce((acc, filter) => ({ ...acc, [filter]: PropTypes.array }), {}),
  ),
};

export default AssetsFilters;
