import { Box } from '@mui/system';
import { useWatch } from 'react-hook-form';
import { rootTranslation } from 'utils/format';
import { FormField } from 'components/common/Form/Fields/FormField/FormField';
import { TextField } from '@mui/material';
import { SceneField } from '../fields/SceneField';
import { TrackTypeSelect } from '../fields/TrackTypeSelect';
import { EnterKeyListener } from '../EnterKeyListener';
import { LibraryField } from '../fields/LibraryField';

const t = rootTranslation('drawers.productionSetup.tracks.filter');

export function SearchFormPanel({ onSubmit, useCueContainerPickerTreeView }) {
  const type = useWatch({ name: 'type' });
  const searchingLibrary = type === 'library';

  return (
    <Box
      sx={{
        backgroundColor: 'secondary.300',
        display: 'grid',
        gridTemplateColumns: 'repeat(4, 1fr)',
        gap: 2,
        p: 2,
        borderRadius: '4px',
      }}
    >
      <SceneField useCueContainerPickerTreeView={useCueContainerPickerTreeView} />
      <TrackTypeSelect />
      {searchingLibrary ? <LibraryFields onSubmit={onSubmit} /> : <SongFields onSubmit={onSubmit} />}
    </Box>
  );
}

function SongFields({ onSubmit }) {
  return (
    <>
      <EnterKeyListener onEnterKey={onSubmit}>
        <FormField
          label={t('title')}
          name="title"
          render={({ field, muiprops }) => <TextField {...field} {...muiprops} placeholder={t('titlePlaceholder')} />}
        />
      </EnterKeyListener>
      <EnterKeyListener onEnterKey={onSubmit}>
        <FormField
          label={t('artist')}
          name="artist"
          render={({ field, muiprops }) => <TextField {...field} {...muiprops} placeholder={t('artistPlaceholder')} />}
        />
      </EnterKeyListener>
    </>
  );
}

function LibraryFields({ onSubmit }) {
  return (
    <>
      <LibraryField />
      <EnterKeyListener onEnterKey={onSubmit}>
        <FormField
          label={t('search')}
          name="title"
          render={({ field, muiprops }) => <TextField {...field} {...muiprops} placeholder={t('searchPlaceholder')} />}
        />
      </EnterKeyListener>
    </>
  );
}
