import { useGetCacheUrlQuery } from 'services/clear/letter-templates';
import { TLetterContact, useFindDraftQuery, useGetDocumentQuery } from 'services/clear/letters';
import { TLetterType } from 'services/clear/types';

export type TUseGetLetterDocumentUrl = {
  divisionId: number;
  clearanceBundleLicensorsIds: number[];
  contact: TLetterContact;
  letterType: TLetterType;
  templateId?: number;
  ignoreDraft?: boolean;
};

export function useGetLetterDocumentUrl({
  divisionId,
  templateId,
  contact,
  letterType,
  clearanceBundleLicensorsIds,
  ignoreDraft,
}: TUseGetLetterDocumentUrl) {
  const skipEmptyDocument = !!templateId;
  const skipTemplate = !templateId;

  const { data: emptyDocument, isLoading: isLoadingCacheUrl } = useGetCacheUrlQuery(
    { divisionId },
    { skip: skipEmptyDocument },
  );

  const { data: filledTemplated, isLoading: isLoadingDocument } = useGetDocumentQuery(
    {
      contact,
      clearanceBundleLicensorsIds,
      templateId,
      letterType,
    },
    { skip: skipTemplate },
  );

  const { data: letterDraft, isLoading: isLoadingLetterDraft } = useFindDraftQuery({
    divisionId,
    letterType,
    clearanceBundleLicensorsIds,
  }, { skip: ignoreDraft });

  const isLoading = isLoadingCacheUrl || isLoadingDocument || isLoadingLetterDraft;
  const documentUrl = ignoreDraft
    ? filledTemplated?.url || emptyDocument?.url
    : letterDraft?.documentUrl || filledTemplated?.url || emptyDocument?.url;

  return { isLoading, documentUrl, missedTags: filledTemplated?.missedTags || undefined };
}
