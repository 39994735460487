import { createSelector } from 'reselect';
import not from 'ramda/src/not';
import prop from 'ramda/src/prop';

const getLettersRoot = prop('letters');

export const getLettersSelector = createSelector(getLettersRoot, prop('letters'));

export const getLettersIsBundleSelector = createSelector(getLettersRoot, prop('bundle'));

export const getSelectedLettersForStep2 = createSelector(getLettersRoot, prop('selectedLettersForStep2'));

export const getSelectedLettersForStep3 = createSelector(getLettersRoot, prop('selectedLettersForStep3'));

export const getFilterSelector = createSelector(getLettersRoot, prop('filter'));

export const getLetterTypeSelector = createSelector(getFilterSelector, prop('letterType'));

export const getPathFromClearanceSelector = createSelector(getFilterSelector, prop('pathFromClearance'));

export const getDivisionIdSelector = createSelector(getFilterSelector, prop('divisionId'));

export const getLettersCountSelector = createSelector(getLettersSelector, prop('length'));

export const getSelectedLettersForStep2Count = createSelector(getSelectedLettersForStep2, prop('length'));

export const getSelectedLettersForStep3Count = createSelector(getSelectedLettersForStep3, prop('length'));

export const isStep2EmptyQueueSelector = createSelector(getSelectedLettersForStep2Count, Boolean, not);

export const isStep3EmptyQueueSelector = createSelector(getSelectedLettersForStep3Count, Boolean, not);

export const getIsSingleLicensorSelector = createSelector(getFilterSelector, prop('isSingleLicensor'));

export const getFiltersMetadataSelector = createSelector(getLettersRoot, prop('filtersMetadata'));

export const getEmailTemplatesSelector = createSelector(getLettersRoot, prop('emailTemplates'));

export const getLetterTemplatesSelector = createSelector(getLettersRoot, prop('letterTemplates'));

export const getLettersActiveStepSelector = createSelector(getLettersRoot, prop('activeStep'));

export const getLettersActiveDraft = createSelector(getLettersRoot, prop('activeDraft'));
