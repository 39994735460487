import { createSelector } from 'reselect';
import prop from 'ramda/src/prop';
import length from 'ramda/src/length';
import map from 'ramda/src/map';
import path from 'ramda/src/path';
import c from 'ramda/src/compose';
import equals from 'ramda/src/equals';
import filter from 'ramda/src/filter';
import any from 'ramda/src/any';
import not from 'ramda/src/not';
import { getCurrentCueSheeVersionIdSelector, getSelectedSelector } from 'redux-core/overview/selectors';
import { get } from 'utils/object';
import { getLocationParametersSelector } from 'redux-core/router/selectors';

const getCueSheetRoot = prop('cueSheet');

export const getCuesSelector = createSelector(getCueSheetRoot, prop('cues'));

export const getSegmentsSelector = createSelector(getCueSheetRoot, prop('segments'));

export const getIsSyncSelector = createSelector(getCueSheetRoot, prop('isSync'));

export const getCuesLengthSelector = createSelector(getCuesSelector, length);

export const getCueIdsSelector = createSelector(getCuesSelector, map(path(['cue', 'id'])));

export const areNotEditableCueSelected = createSelector(
  getCuesSelector,
  c(
    any((cue) => not(path(['track', 'isEditable'])(cue))),
    filter(prop('selected')),
  ),
);

export const getAllCuesIdsSelector = createSelector(getCuesSelector, map(path(['cue', 'id'])));

export const getExpandedCueIdSelector = createSelector(getCueSheetRoot, prop('expandedCueId'));

export const getCuesPaginationSelector = createSelector(getCueSheetRoot, prop('pagination'));

export const getNewCueSelector = createSelector(getCueSheetRoot, prop('newCue'));

const getCueSheetSelectedVersionIdSelector = createSelector(getCueSheetRoot, prop('selectedVersionId'));

export const getSelectedVersionIdSelector = createSelector(
  getCueSheetSelectedVersionIdSelector,
  getSelectedSelector,
  getLocationParametersSelector,
  (selectedVersionId, production, locationState) => {
    if (locationState.type === 'marketing') return selectedVersionId;
    return selectedVersionId ?? get('currentCueSheetVersion.id')(production);
  },
);

export const getHideInOutSelector = createSelector(getCueSheetRoot, prop('hideInOut'));

export const getIsFormDirtySelector = createSelector(getCueSheetRoot, prop('isFormDirty'));

export const getIsViewingCurrentVersionSelector = createSelector(
  getSelectedVersionIdSelector,
  getCurrentCueSheeVersionIdSelector,
  equals,
);

export const getDraggableItemsSelector = createSelector(getCuesSelector, getSegmentsSelector, (cues, segments) => {
  if (!cues) return [];

  return cues.reduce((computedItems, managedCue, itemIndex) => {
    const draggableItem = {
      type: 'cue',
      cue: managedCue,
      itemIndex,
    };

    const { cueSheetSegmentId } = managedCue.cue;
    const segment = cueSheetSegmentId && segments.find((currentSegment) => currentSegment.id === cueSheetSegmentId);

    if (!segment) {
      computedItems.push(draggableItem);
      return computedItems;
    }

    const previousItem = computedItems[computedItems.length - 1];
    const isPreviousItemSegment = previousItem?.type === 'segment';
    const isProcessingSameSegment = isPreviousItemSegment && segment?.id === previousItem.segment.id;
    const currentSegment = isProcessingSameSegment
      ? previousItem
      : {
          type: 'segment',
          segment: {
            ...segment,
            draggableItems: [],
          },
          itemIndex,
        };
    currentSegment.segment.draggableItems.push(draggableItem);

    if (!isProcessingSameSegment) computedItems.push(currentSegment);
    return computedItems;
  }, []);
});
