import { CSSProperties, useMemo, useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { Field } from 'formik';
import { TRACK_USE_ACTUAL_OPTIONS, TRACK_USE_REQUESTED_OPTIONS } from 'utils/constants';

type TTrackUseType = { id: string; name: string };

type TrackUseTypeSelectProps = {
  usageType?: 'requested' | 'actual';
  value?: string;
  onChange: (nextValue: TTrackUseType) => void;
  listStyles?: CSSProperties;
  id?: string;
  name?: string;
  testId?: string;
};

/**
 * usageType: 'requested' | 'actual' – Only the 'requested' type does show the BIBV option
 */
export function TrackUseTypeSelect({
  usageType = 'requested',
  id,
  name,
  value,
  onChange,
  listStyles,
  testId,
}: TrackUseTypeSelectProps) {
  const options = useMemo(
    () => (usageType === 'requested' ? TRACK_USE_REQUESTED_OPTIONS : TRACK_USE_ACTUAL_OPTIONS),
    [usageType],
  );
  const initialValue = value ? options.find((it) => it.id === value) : null;
  const [selected, setSelected] = useState<TTrackUseType>(initialValue);

  return (
    <Autocomplete
      fullWidth
      key={value}
      id={id}
      value={selected}
      onChange={(_, nextValue: TTrackUseType) => {
        setSelected(nextValue);
        onChange(nextValue);
      }}
      options={options}
      isOptionEqualToValue={(option, optionValue) => option?.id === optionValue?.id}
      getOptionLabel={(option) => option?.name ?? ''}
      renderInput={(params) => (
        <TextField fullWidth {...params} name={name} data-testid={`typeahead-field-${testId || name}`} />
      )}
      ListboxProps={{
        style: listStyles,
      }}
    />
  );
}

type FormTrackUseTypeSelectProps = TrackUseTypeSelectProps & {
  onChange?: (nextValue: TTrackUseType) => void;
};

export function FormTrackUseTypeSelect({ name, onChange, ...props }: FormTrackUseTypeSelectProps) {
  return (
    <Field name={name}>
      {({ field, form: { setFieldValue } }) => (
        <TrackUseTypeSelect
          value={field.value}
          onChange={(nextValue) => {
            setFieldValue(field.name, !nextValue ? null : nextValue.id);
            onChange?.(nextValue);
          }}
          {...props}
        />
      )}
    </Field>
  );
}
