import { connect } from 'react-redux';
import withBaseDrawer from 'components/common/Drawers/withBaseDrawer';
import compose from 'ramda/src/compose';
import { getRightPresetById, createRightPreset, updateRightPreset } from 'redux-core/rights/actions';
import { getRightPresetIsEditingSelector } from 'redux-core/rights/selectors';
import { getDivisionId } from 'redux-core/productions/selectors';
import { saveRight } from 'redux-core/dialogs/actions';

const mapStateToProps = (state) => ({
  divisionId: getDivisionId(state),
  isEditing: getRightPresetIsEditingSelector(state),
});

const mapDispatchToProps = {
  getRightPresetById,
  createRightPreset,
  updateRightPreset,
  saveRight,
};

export default compose(connect(mapStateToProps, mapDispatchToProps), (component) =>
  withBaseDrawer(component, { xlarge: true }),
);
