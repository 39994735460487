/* eslint-disable import/no-cycle */
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Button } from 'components/styleguide';
import { DRAWERS } from 'components/common/Drawers/constants';
import { useDrawer, useRootTranslation, useCanAccessDivisionSelector } from 'utils/hooks';
import { COMMON_PERMISSIONS } from 'redux-core/permissions/constants';

const CREATE_PERMISSIONS = { permissions: COMMON_PERMISSIONS.CREATE };

const AddProjectButton = () => {
  const t = useRootTranslation('projects');
  const canCreate = useCanAccessDivisionSelector(CREATE_PERMISSIONS, false);
  const openDrawer = useDrawer();
  if (!canCreate) return null;
  return (
    <Button
      size="md"
      variant="contained"
      onClick={() => openDrawer(DRAWERS.PRODUCTION_QUICK_SETUP_PRODUCTION)}
      testId="new-project"
      startIcon={<AddCircleIcon />}
    >
      {t('newProject')}
    </Button>
  );
};

export default AddProjectButton;
