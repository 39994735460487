import { connect } from 'react-redux';
import { getIsOpenDialogSelector } from 'redux-core/dialogs/selectors';

type State = {
  isDialogOpen: boolean;
};

const mapStateToProps = (state: State) => ({
  isDialogOpen: getIsOpenDialogSelector(state),
});

export type ReduxProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps);
