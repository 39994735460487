/* eslint-disable consistent-return */
import React, { useEffect } from 'react';
import { Form } from 'formik';
import is from 'ramda/src/is';
import PropTypes from 'prop-types';
import FormPrompt from '../FormPrompt';
import enhancer from './enhancer';
import { CustomFormPropTypes } from '../types';

const shouldDrawerBeDirty = ({ isValid, dirty, isDrawerFormDirty, isDrawerFormSubmitted }) => {
  if (!isValid) return dirty;
  if (isValid && dirty && isDrawerFormSubmitted) return false; // this line allows us to set dirty in the store as false as soon as the form was submitted
  if (isValid && dirty && !isDrawerFormSubmitted) return true;
  if (isValid && !dirty) return isDrawerFormDirty; // this line allows us to keep the state of isDrawerFormDirty when re-entering a form
};

const shouldDrawerBeSubmitted = ({ isValid, dirty, isDrawerFormDirty, isDrawerFormSubmitted }) => {
  /** This means that the drawer was submitted and isDrawerFormDirty was set to false, so we can set submitted back to false */
  if (isValid && dirty && !isDrawerFormDirty && isDrawerFormSubmitted) {
    return false;
  }
  return isDrawerFormSubmitted;
};

const FormikForm = ({
  children,
  drawerName,
  onConfirm,
  promptMessage,
  setDrawerDirty,
  /** setIsDirty is meant to sync the form lifecycle on the dirty prop to a state in the parent */
  setIsDirty,
  isDrawerFormDirty,
  isDrawerFormSubmitted,
  alwaysRenderPrompt,
  testId,
  disablePrompt,
  ...formikAndChildrenProps
}) => {
  const { setStatus, isSubmitting, dirty, readOnly, isValid } = formikAndChildrenProps;

  useEffect(() => {
    setStatus(readOnly ? 'readOnly' : undefined);
  });

  useEffect(
    () => {
      if (drawerName) {
        const isDirty = shouldDrawerBeDirty({
          isValid,
          dirty,
          isDrawerFormDirty,
          isDrawerFormSubmitted,
        });
        const isSubmitted = shouldDrawerBeSubmitted({
          isValid,
          dirty,
          isDrawerFormDirty,
          isDrawerFormSubmitted,
        });
        setDrawerDirty({
          name: drawerName,
          dirty: isDirty,
          submitted: isSubmitted,
        });
      }
    },
    // isDrawerFormSubmitted shouldn't be part of the deps array
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dirty, isValid, setDrawerDirty, drawerName, isDrawerFormDirty],
  );

  useEffect(() => {
    if (setIsDirty) {
      setIsDirty(dirty);
    }
  }, [dirty, setIsDirty]);

  return (
    <Form style={{ width: '100%' }} data-testid={testId}>
      {!disablePrompt && (
        <FormPrompt
          when={alwaysRenderPrompt || (dirty && !isSubmitting)}
          customMessage={promptMessage}
          onConfirm={onConfirm}
        />
      )}
      {is(Function, children) ? children(formikAndChildrenProps) : children}
    </Form>
  );
};

FormikForm.defaultProps = {
  // eslint-disable-next-line react/default-props-match-prop-types
  alwaysRenderPrompt: false,
};

FormikForm.propTypes = {
  children: PropTypes.node.isRequired,
  isSubmitting: PropTypes.bool,
  touched: PropTypes.shape({}),
  ...CustomFormPropTypes,
};

export default enhancer(FormikForm);
