import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
  container: {
    height: '100%',
    overflowX: 'auto',
    backgroundColor: ({ grey }) => (grey ? theme.palette.grey[200] : undefined),
    paddingLeft: ({ pl, px, p, padding = 0 }) => theme.spacing(pl || px || p || padding),
    paddingRight: ({ pr, px, p, padding = 0 }) => theme.spacing(pr || px || p || padding),
    paddingTop: ({ pt, py, p, padding = 0 }) => theme.spacing(pt || py || p || padding),
    paddingBottom: ({ pb, py, p, padding = 0 }) => theme.spacing(pb || py || p || padding),
  },
  views: {
    display: ({ display }) => display ?? 'block',
    flexDirection: ({ flexDirection }) => flexDirection,
    height: ({ height }) => height ?? undefined,
    marginBottom: ({ marginBottom }) => theme.spacing(marginBottom ?? 3),
    minHeight: ({ minHeightThemeMinus }) => `calc(100% - ${minHeightThemeMinus ?? 80}px)`,
  },
  root: {
    height: '100%',
  },
}));

export default styles;
