import React from 'react';
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import { Badge, IconButton } from '@material-ui/core';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import useStyles from './styles';

const Notification = (props) => {
  const { count, onClick, customProps } = props;
  const classes = useStyles();
  return (
    <IconButton className={classnames(classes.root, customProps.className)} onClick={onClick}>
      <Badge invisible={!count} color="error" className={classes.badge}>
        <NotificationsIcon />
      </Badge>
    </IconButton>
  );
};

Notification.propTypes = {
  count: PropTypes.number,
  onClick: PropTypes.func,
  customProps: PropTypes.object,
};

Notification.defaultProps = {
  customProps: {},
};

export default Notification;
