/* eslint-disable react/jsx-props-no-spreading */
import React, { cloneElement } from 'react';
import { PowerSettingsNew, Delete } from '@material-ui/icons';
import { IconButton, Tooltip } from '@mui/material';
import { cuesRemoveUse, cuesUpdateActive } from 'redux-core/clearances/services';
import { usePrompt, useDrawer, useRootTranslation } from 'utils/hooks';
import { useDispatch } from 'react-redux';
import { showSnackbar } from 'redux-core/global-error/actions';

const getActiveInactiveTooltip = ({ cue, t }) => (cue.active ? t('makeInactive') : t('makeActive'));

const SummaryActionIconWrapper = ({ children, onClick, tooltip }) => (
  <Tooltip title={tooltip} placement="top">
    <IconButton size="small" color="primary" onClick={onClick}>
      {children}
    </IconButton>
  </Tooltip>
);

const DeleteIcon = ({ isOnlyOne, ...rest }) => {
  if (isOnlyOne) return null;
  return <Delete {...rest} />;
};

const handleOnActiveInactiveClick =
  ({ cue, openPrompt, reload, dispatch, t }) =>
  async (e) => {
    const { id, active } = cue;
    e.stopPropagation();
    openPrompt({
      content: t(active ? 'deactivateConfirmation' : 'activateConfirmation', {
        name: cue.cueContainer.title,
      }),
      onConfirm: async () => {
        await cuesUpdateActive({
          id,
          active: !active,
        });
        dispatch(
          showSnackbar({
            message: t(active ? 'deactivated' : 'activated', {
              name: cue.cueContainer.title,
            }),
          }),
        );
        reload();
      },
    });
  };

const handleOnCueDelete =
  ({ cue, reload, openPrompt, dispatch, t }) =>
  async (e) => {
    e.stopPropagation();
    openPrompt({
      content: t('deleteConfirmation', { name: cue.cueContainer.title }),
      onConfirm: async () => {
        await cuesRemoveUse({ id: cue.id });
        dispatch(
          showSnackbar({
            message: t('deleted', { name: cue.cueContainer.title }),
          }),
        );
        reload();
      },
    });
  };

const SummaryActionsEventsAndIcons = (props) => [
  {
    key: 'MakeInactiveButton_key_expanded',
    tooltip: getActiveInactiveTooltip(props),
    icon: <PowerSettingsNew />,
    onClick: handleOnActiveInactiveClick(props),
  },
  {
    key: 'DeleteClearanceButton_key_expanded',
    tooltip: 'Delete',
    icon: <DeleteIcon isOnlyOne={props.isOnlyOne} />,
    onClick: handleOnCueDelete(props),
  },
];

export const SummaryActions = ({ hover, isAllowedToEdit = true, ...rest }) => {
  const openPrompt = usePrompt();
  const dispatch = useDispatch();
  const openDrawer = useDrawer();
  const t = useRootTranslation('drawers.clearance.fullList');

  if (!hover || !isAllowedToEdit) return null;

  return SummaryActionsEventsAndIcons({
    openPrompt,
    dispatch,
    openDrawer,
    t,
    ...rest,
  }).map((EventAndIcon) => {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const { icon, ...rest } = EventAndIcon;
    return <SummaryActionIconWrapper {...rest}>{cloneElement(icon, { fontSize: 'small' })}</SummaryActionIconWrapper>;
  });
};
