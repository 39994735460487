import { connect } from 'react-redux';
import { closeDrawerWithPrompt } from 'redux-core/dialogs/actions';
import { getOpenDialogPayloadSelector, getOpenDialogSelector } from 'redux-core/dialogs/selectors';

const mapStateToProps = (state) => ({
  open: getOpenDialogSelector(state),
  payload: getOpenDialogPayloadSelector(state),
});

const mapDispatchToProps = { closeDrawerWithPrompt };

export default connect(mapStateToProps, mapDispatchToProps);
