/* eslint-disable react/no-children-prop */
import last from 'ramda/src/last';

import { Button } from 'components/styleguide';
import ROUTES from 'components/Routes/routes';
import { getNormalizedProductionType } from 'utils/productions';
import { useRootTranslation } from 'utils/hooks';

import { GLOBAL_SEARCH_FILTER_BY } from '../../utils';

export const ViewButton = ({ closeAllDialogs, filterBy, goToLink, selected }) => {
  const t = useRootTranslation('globalSearch.details');

  if (!filterBy || !selected) return null;

  const handleViewTrack = (trackInfo) => {
    const { trackEntityType } = last(selected.prodArray);
    if (trackEntityType === 'QCueTrack') {
      const { trackProductionId } = last(selected.prodArray);
      return goToLink(ROUTES.PRODUCTION.CUE_SHEET, {
        id: trackProductionId,
        divisionId: selected.divisionId,
        projectId: selected.prodArray[0].id,
        type: getNormalizedProductionType(selected.productionType),
      });
    }

    return goToLink(ROUTES.TRACK.CLEARANCES, {
      id: trackInfo.trackProductionId,
      divisionId: selected.divisionId,
      objectId: selected.trackObjectId,
      projectId: selected.prodArray[0].id,
      tenantId: selected.tenantId,
      trackId: trackInfo.id,
      type: getNormalizedProductionType(selected.productionType),
    });
  };

  const onClick = async () => {
    // eslint-disable-next-line default-case
    switch (filterBy) {
      case GLOBAL_SEARCH_FILTER_BY.TRACKS.value:
        await handleViewTrack(last(selected.prodArray));
        break;
      case GLOBAL_SEARCH_FILTER_BY.RIGHTS_OWNERS.value:
        await handleViewTrack(selected.prodArray[selected.prodArray.length - 2]);
        break;
    }
    return closeAllDialogs();
  };

  return <Button children={t('viewTrack')} onClick={onClick} testId="view-track" size="sm" variant="primary" />;
};
