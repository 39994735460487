import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  button: ({ size }) => ({
    '& > *': {
      height: size,
      width: size,
    },
    '& svg': {
      color: theme.palette.text.secondary,
    },
  }),
}));

export default useStyles;
