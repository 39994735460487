import { connect } from 'react-redux';
import { createCueContainer, fetchCueContainersLight, updateCueContainer } from 'redux-core/cueContainers/actions';
import { getLocationKeySelector, getLocationParametersSelector } from 'redux-core/router/selectors';

const mapStateToProps = (state) => ({
  locationKey: getLocationKeySelector(state),
  params: getLocationParametersSelector(state),
});

const mapDispatchToProps = {
  createCueContainer,
  fetchCueContainersLight,
  updateCueContainer,
};

export default connect(mapStateToProps, mapDispatchToProps);
