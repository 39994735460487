/* eslint-disable no-use-before-define */
/* eslint-disable consistent-return */
/* eslint-disable import/no-named-as-default */
/* eslint-disable import/no-cycle */
import { createActions } from 'redux-actions';
import { replace } from 'connected-react-router';
import { batch } from 'react-redux';
import all from 'ramda/src/all';
import append from 'ramda/src/append';
import assoc from 'ramda/src/assoc';
import compose from 'ramda/src/compose';
import identity from 'ramda/src/identity';
import ifElse from 'ramda/src/ifElse';
import isNil from 'ramda/src/isNil';
import lensPath from 'ramda/src/lensPath';
import map from 'ramda/src/map';
import not from 'ramda/src/not';
import path from 'ramda/src/path';
import propOr from 'ramda/src/propOr';
import set from 'ramda/src/set';
import split from 'ramda/src/split';
import last from 'ramda/src/last';
import equals from 'ramda/src/equals';
import toUpper from 'ramda/src/toUpper';
import contains from 'ramda/src/contains';
import { openPromptAsync, openPrompt, closePrompt } from 'redux-core/prompt/actions';
import { goToLink, addParams, removeParams } from 'redux-core/router/actions';
import { Box } from '@mui/system';
import CircularProgress from '@mui/material/CircularProgress';
import ROUTES from 'components/Routes/routes';
import i18n from 'locales/i18n';
import { getLocationSelector, getLocationParametersSelector } from 'redux-core/router/selectors';
import { openDrawer, closeDrawer } from 'redux-core/dialogs/actions';
import DRAWERS from 'components/common/Drawers/constants';
import { VALID_CUESHEET_USE_TYPES } from 'utils/constants';
import { showSnackbar } from 'redux-core/global-error/actions';
// import { closeSnackbar } from 'redux-core/global-error/actions';
import {
  getSelectedProductionIdSelector,
  getSelectedProductionDivisionIdSelector,
} from 'redux-core/overview/selectors';
import { getSelectedVersionIdSelector } from 'redux-core/cueSheet/selectors';
import { getImportFinishedPrompt } from 'components/ImportCueSheet/utils';
import { uploadFileCall } from 'redux-core/images/actions';
import {
  compareTracksImportCall,
  createCueSheetTemplate,
  getCueSheetTemplate,
  importCueSheetCall,
  updateCueSheetTemplate,
  comapareEdlCall,
} from './services';
import {
  getActiveMappedFieldsSelector,
  getImportActiveStepSelector,
  getImportFileHeaderRowSelector,
  getImportFileNameSelector,
  getImportMappedFieldsSelector,
  getImportMappingColumnSelector,
  getImportMappingsSelector,
  getImportTemplateIdSelector,
  getImportFileFirstRowSelector,
  getSelectedColumnUniqueSelector,
  getImportUsageMappingsSelector,
  getImportHeaderSelector,
  getMappedColumnsCountSelector,
  getImportSelectedDataSelector,
  getImportTracksCompareSelector,
  getImportCueSheetSelector,
  getImportTemplateSelector,
  getImportCueSheetVersionId,
  getImportFileDataSelector,
  getImportUploadedImportFileIdSelector,
} from './selectors';
import {
  formatCueSheetFile,
  parseFile,
  parseStringToArray,
  mergeCueSheetWithTracksCompare,
  getTracksCompareFromCueSheet,
  shouldGoToTracksCompareStep,
  getMappedFieldsFromMappings,
  getUsageMappingsFromUsage,
} from './utils';
import { COMPARE_SECTIONS, DUPLICATED_TEMPLATE_MESSAGE, IMPORT_FIELDS, IMPORT_STEPS, IMPORT_ERRORS } from './constants';

const actionsDefinition = {
  CLEAR_IMPORT_CUE_SHEET: identity,
  CLEAR_IMPORT_MAPPINGS: identity,
  SET_IMPORT_CUE_SHEET: identity,
  SET_IMPORT_CUE_SHEET_TEMPLATE: identity,
  SET_IMPORT_FILE_NAME: identity,
  SET_IMPORT_FILE: identity,
  SET_IMPORT_FIRST_ROW: Number,
  SET_IMPORT_HEADER_ROW: Number,
  SET_IMPORT_MAPPED_FIELDS: identity,
  SET_IMPORT_MAPPINGS: identity,
  SET_IMPORT_PREVIEWING_ROW: identity,
  SET_IMPORT_SELECTED_TRACK: identity,
  SET_IMPORT_TRACKS_COMPARE: identity,
  SET_IMPORT_USAGE_MAPPINGS: identity,
  SET_IMPORT_SELECTED_COLUMN: Number,
  SET_TEMPLATE_ID: identity,
  SET_UPLOADED_IMPORT_FILE_ID: identity,
  SET_IMPORT_ERROR: identity,
};

export const {
  clearImportCueSheet,
  clearImportMappings,
  setImportCueSheet,
  setImportCueSheetTemplate,
  setImportFile,
  setImportFileName,
  setImportFirstRow,
  setImportHeaderRow,
  setImportMappedFields,
  setImportMappings,
  setImportSelectedColumn,
  setImportSelectedTrack,
  setImportPreviewingRow,
  setImportTracksCompare,
  setImportUsageMappings,
  setTemplateId,
  setUploadedImportFileId,
  setImportError,
} = createActions(actionsDefinition);

const MAX_ROWS_EDL_FILE = 500;

const containsEDL = compose(contains('EDL'), toUpper);

const isEDLFile = compose(equals('EDL'), toUpper, last, split('.'));

export const uploadCueSheetFile = (file, fileName) => async (dispatch) => {
  const parsedFile = await formatCueSheetFile(file);

  let id;
  if (isEDLFile(fileName)) {
    id = await uploadFileCall(file, {
      kind: 'attachment',
    });
  }

  await batch(() => {
    dispatch(setImportFile(parsedFile));
    dispatch(setImportFileName(fileName));
    dispatch(setUploadedImportFileId(id));
  });
};

export const removeImportedFile = () => async (dispatch) => {
  await batch(() => {
    dispatch(setImportFile([]));
    dispatch(setImportFileName(undefined));
  });
};

export const clearImportCueSheetWorkflow = () => async (dispatch, getState) => {
  const state = getState();
  const fileName = getImportFileNameSelector(state);
  const location = getLocationSelector(state);

  if (fileName) {
    await dispatch(
      openPromptAsync({
        content: i18n.t('drawers.importCueSheet.notifications.cancel'),
      }),
    );
  }
  if (location === ROUTES.PRODUCTION.IMPORT_CUE_SHEET) {
    dispatch(navigateBackFromImport({ activeStep: undefined }));
  }
  await dispatch(clearImportCueSheet());
};

const mapStateStep = () => async (dispatch, getState) => {
  const state = getState();

  const columnCount = getImportHeaderSelector(state).length;
  const mappingsCount = getMappedColumnsCountSelector(state);
  const templateId = getImportTemplateIdSelector(state);
  // Show prompt to user if there are unmapped columns
  if (!templateId && mappingsCount < columnCount) {
    const content = i18n.t('importCueSheet.mapData.unmappedNotification');
    await dispatch(openPromptAsync({ content }));
  }
  const cueSheet = await dispatch(parseImportFile());

  const tracks = getTracksCompareFromCueSheet(cueSheet);

  dispatch(addParams({ isCompareQwireTracksLoading: true }));

  const { processedRecords } = await compareTracksImportCall({
    exactMatch: false,
    tracks,
  });

  dispatch(removeParams(['isCompareQwireTracksLoading']));

  // If the backend could not find a match, skip the Compare step
  if (all(not)(processedRecords)) {
    return dispatch(addParams({ activeStep: IMPORT_STEPS.PREVIEW, omitCompare: true }));
  }

  compose(
    dispatch,
    setImportTracksCompare,
    // QTracks data is selected by default
    map(ifElse(Boolean, assoc('qtrackSelected', true), identity)),
  )(processedRecords);
};

/**
 *
 * @returns {Promise<boolean>} Promise with a boolean value indicating if the action succeeded.
 * This is used for interrupting the action flow and showing the `error` state,
 * as in setImportError.
 */
export const goToNextStep = () => async (dispatch, getState) => {
  const state = getState();
  const activeStep = getImportActiveStepSelector(state);

  if (!activeStep) {
    const templateId = getImportTemplateIdSelector(state);
    const cueSheetVersionId = getSelectedVersionIdSelector(state);

    let nextStep = IMPORT_STEPS.SELECT_HEADER_ROW;

    if (templateId) {
      const divisionId = getSelectedProductionDivisionIdSelector(state);
      const template = await getCueSheetTemplate({
        divisionId,
        id: templateId,
      });
      const cueSheetTemplate = {
        firstRow: template.firstRow,
        headerRow: template.headerRow,
        mappings: template.mapping,
        previewingRow: template.firstRow,
        mappedFields: getMappedFieldsFromMappings(template.mapping),
      };
      const file = getImportFileDataSelector(state);

      const fileName = getImportFileNameSelector(state);
      if (isEDLFile(fileName) && containsEDL(template.name)) {
        const uploadedImportFileId = getImportUploadedImportFileIdSelector(state);

        const { cueSheet, processedRecords } = await comapareEdlCall({
          uploadedFileId: uploadedImportFileId,
        });

        if (cueSheet.length > MAX_ROWS_EDL_FILE) {
          dispatch(setImportError(IMPORT_ERRORS.EDL_MAX_LINES_EXCEEDED));
          return false;
        }

        await dispatch(setImportCueSheet(cueSheet));

        // If the backend could not find a match, skip the Compare step
        if (all(not)(processedRecords)) {
          nextStep = IMPORT_STEPS.PREVIEW;
        } else {
          nextStep = IMPORT_STEPS.QTRACKS_COMPARE;

          compose(
            dispatch,
            setImportTracksCompare,
            // QTracks data is selected by default
            map(ifElse(Boolean, assoc('qtrackSelected', true), identity)),
          )(processedRecords);
        }
      } else if (!file[cueSheetTemplate.headerRow] || !file[cueSheetTemplate.firstRow]) {
        const message = i18n.t('importCueSheet.notifications.templateDoesntMatch');
        dispatch(showSnackbar({ message }));
      } else {
        cueSheetTemplate.usageMappings = getUsageMappingsFromUsage(
          template.mapping,
          template.usage,
          file.slice(template.firstRow, undefined),
        );
        // If there is a safe template that can be used, go to Map Data step
        nextStep = IMPORT_STEPS.QTRACKS_COMPARE;
        dispatch(setImportCueSheetTemplate(cueSheetTemplate));
        await dispatch(mapStateStep());
      }
    }

    dispatch(
      goToLink(ROUTES.PRODUCTION.IMPORT_CUE_SHEET, { activeStep: nextStep, cueSheetVersionId }, { mergeParams: true }),
    );
    return true;
  }

  if (activeStep === IMPORT_STEPS.SELECT_HEADER_ROW) {
    // The row that is immediately below the selected header row is automatically selected by default
    const headerRow = getImportFileHeaderRowSelector(state);
    dispatch(setImportFirstRow(headerRow + 1));
  }

  if (activeStep === IMPORT_STEPS.SELECT_FIRST_ROW) {
    // The first row is also the first previewed row by default
    const firstRow = getImportFileFirstRowSelector(state);
    dispatch(setImportPreviewingRow(firstRow));
  }

  if (activeStep === IMPORT_STEPS.MAP_DATA) {
    await dispatch(mapStateStep());
  }

  if (activeStep === IMPORT_STEPS.QTRACKS_COMPARE) {
    const cueSheet = getImportCueSheetSelector(state);
    const tracksCompare = getImportTracksCompareSelector(state);
    const newCueSheet = mergeCueSheetWithTracksCompare(cueSheet, tracksCompare);
    dispatch(setImportCueSheet(newCueSheet));
  }

  if (activeStep === IMPORT_STEPS.PREVIEW) {
    const templateId = getImportTemplateIdSelector(state);

    if (templateId) {
      const divisionId = getSelectedProductionDivisionIdSelector(state);
      const template = await getCueSheetTemplate({
        divisionId,
        id: templateId,
      });

      const fileName = getImportFileNameSelector(state);

      if (isEDLFile(fileName) && containsEDL(template.name)) {
        dispatch(addParams({ isEDLSavingLoading: true }));
        dispatch(
          openPrompt({
            content: <ImportProgressContent />,
            withoutButtons: true,
          }),
        );
        const { importResult } = await dispatch(saveImportedData());
        dispatch(removeParams(['isEDLSavingLoading']));
        dispatch(closePrompt());
        dispatch(openPrompt(getImportFinishedPrompt(importResult.data.data)));
      } else {
        dispatch(
          openPrompt({
            content: <ImportProgressContent />,
            withoutButtons: true,
          }),
        );
        const { importResult } = await dispatch(saveImportedData());
        dispatch(closePrompt());
        dispatch(openPrompt(getImportFinishedPrompt(importResult.data.data)));
      }

      return;
    }

    const content = i18n.t('importCueSheet.preview.prompt.saveTemplateContent');
    const title = i18n.t('importCueSheet.preview.prompt.saveTemplateTitle');
    const noAnswer = i18n.t('importCueSheet.preview.prompt.noImportFile');
    const yesAnswer = i18n.t('importCueSheet.preview.prompt.yesCreateTemplate');
    return dispatch(
      openPrompt({
        content,
        firstOptionConfirm: async () => {
          const { importResult } = await dispatch(saveImportedData());
          setTimeout(() => {
            dispatch(openPrompt(getImportFinishedPrompt(importResult.data.data)));
          }, 200);
        },
        firstOptionLabel: noAnswer,
        multiOk: true,
        okVariant: 'primary',
        maxWidth: 'sm',
        secondOptionConfirm: () => dispatch(openDrawer(DRAWERS.IMPORT_SAVE_AS_TEMPLATE)),
        secondOptionLabel: yesAnswer,
        title,
      }),
    );
  }

  return dispatch(addParams({ activeStep: activeStep + 1 }));
};

export const goToPrevStep = () => async (dispatch, getState) => {
  const state = getState();
  const activeStep = getImportActiveStepSelector(state);

  if (activeStep === IMPORT_STEPS.SELECT_HEADER_ROW) {
    dispatch(navigateBackFromImport({ activeStep: activeStep - 1 }));
    return dispatch(openDrawer(DRAWERS.IMPORT_CUE_SHEET));
  }
  if (activeStep === IMPORT_STEPS.MAP_DATA) {
    const mappingsCount = getMappedColumnsCountSelector(state);
    // If going back from Map Data step and there are columns mapped, show prompt
    if (mappingsCount) {
      const content = i18n.t('importCueSheet.mapData.goBackNotification');
      await dispatch(openPromptAsync({ content }));
    }
    // Clear map data step information
    dispatch(clearImportMappings());
  }

  if (activeStep === IMPORT_STEPS.QTRACKS_COMPARE) {
    const content = i18n.t('importCueSheet.qtracksCompare.goBackNotification');
    await dispatch(openPromptAsync({ content }));
    batch(() => {
      dispatch(setImportCueSheet([]));
      dispatch(setImportTracksCompare([]));
    });
  }

  if (activeStep === IMPORT_STEPS.PREVIEW) {
    const cueSheet = getImportCueSheetSelector(state);
    const { omitCompare } = getLocationParametersSelector(state);
    // If there was nothing to compare, skip the Compare step
    if (omitCompare || !shouldGoToTracksCompareStep(cueSheet)) {
      return dispatch(addParams({ activeStep: IMPORT_STEPS.MAP_DATA, omitCompare: undefined }));
    }
  }

  return dispatch(addParams({ activeStep: activeStep - 1 }));
};

export const mapField = (field) => async (dispatch, getState) => {
  const state = getState();
  const mappings = getImportMappingsSelector(state);
  const mappingColumn = getImportMappingColumnSelector(state);

  // If the user hasn't yet selected any column to map, skip
  if (isNil(mappingColumn)) {
    const message = i18n.t('importCueSheet.mapData.selectToStart');
    dispatch(showSnackbar({ message }));
    return;
  }

  const newMappings = {
    ...mappings,
    [mappingColumn]: compose(append(field), propOr([], mappingColumn))(mappings),
  };

  const mappedFields = getImportMappedFieldsSelector(state);
  batch(() => {
    dispatch(setImportMappedFields({ ...mappedFields, [field]: true }));
    dispatch(setImportMappings(newMappings));
    if (field === IMPORT_FIELDS.USAGE) {
      const uniqUsages = getSelectedColumnUniqueSelector(state);

      const usageMappings = uniqUsages.reduce(
        (prev, curr) => ({
          ...prev,
          [curr]: VALID_CUESHEET_USE_TYPES[curr.toUpperCase()],
        }),
        {},
      );
      // Setting initial usage mappings
      dispatch(setImportUsageMappings(usageMappings));
    }
  });
};

export const addUsageMapping = (field, usage) => async (dispatch, getState) => {
  const state = getState();
  const usageMappings = getImportUsageMappingsSelector(state);
  const newUsageMappings = {
    ...usageMappings,
    [field]: usage,
  };

  dispatch(setImportUsageMappings(newUsageMappings));
};

export const clearMappedField = () => async (dispatch, getState) => {
  const state = getState();
  const fields = getActiveMappedFieldsSelector(state) || [];
  const mappings = getImportMappingsSelector(state);
  const mappingColumn = getImportMappingColumnSelector(state);
  const newMappings = {
    ...mappings,
    [mappingColumn]: [],
  };

  const mappedFields = getImportMappedFieldsSelector(state);
  const newMapped = fields.reduce((prev, current) => ({ ...prev, [current]: undefined }), mappedFields);
  batch(() => {
    dispatch(setImportMappedFields(newMapped));
    dispatch(setImportMappings(newMappings));
    if (fields.includes(IMPORT_FIELDS.USAGE)) {
      dispatch(setImportUsageMappings({}));
    }
  });
};

export const navigateBackFromImport =
  ({ activeStep }) =>
    async (dispatch, getState) => {
      const state = getState();
      const params = getLocationParametersSelector(state);
      await dispatch(
        replace(ROUTES.PRODUCTION.CUE_SHEET, {
          ...params,
          activeStep,
          // Setting this flag to indicate the Import Cue Sheet workflow router prompt listener,
          // that it doesn't have to block the navigation
          backFromImport: true,
        }),
      );
      // Reset the backFromImport flag when on Cue Sheet page
      await dispatch(addParams({ backFromImport: undefined }));
    };

export const parseImportFile = () => async (dispatch, getState) => {
  const state = getState();
  const file = getImportSelectedDataSelector(state);
  const mappings = getImportMappingsSelector(state);
  const mappedFields = getImportMappedFieldsSelector(state);
  const usageMappings = getImportUsageMappingsSelector(state);

  const cueSheet = parseFile(file, mappings, mappedFields, usageMappings);
  dispatch(setImportCueSheet(cueSheet));
  return cueSheet;
};

export const selectAllTracks = (section) => async (dispatch, getState) => {
  const state = getState();
  const tracksCompare = getImportTracksCompareSelector(state);
  const qtrackSelected = section === COMPARE_SECTIONS.QTRACKS_DATA;
  compose(
    dispatch,
    setImportTracksCompare,
    map(ifElse(Boolean, assoc('qtrackSelected', qtrackSelected), identity)),
  )(tracksCompare);
};

export const selectFromAdvancedSearch =
  ({ position, track }) =>
    async (dispatch, getState) => {
      const state = getState();
      const tracksCompare = getImportTracksCompareSelector(state);
      const newQwireTrack = {
        id: track.id,
        title: track.title,
        artists: parseStringToArray(track.artists),
        writers: parseStringToArray(track.writers),
      };
      compose(
        dispatch,
        setImportTracksCompare,
        set(lensPath([position, 'qtrackSelected']), true),
        set(lensPath([position, 'qwireTracksData']), newQwireTrack),
      )(tracksCompare);
    };

export const saveImportedData =
  ({ name, template } = {}) =>
    async (dispatch, getState) => {
      const state = getState();
      const cues = getImportCueSheetSelector(state);
      const cueSheetVersionId = getImportCueSheetVersionId(state);
      const divisionId = getSelectedProductionDivisionIdSelector(state);
      const subprojectId = getSelectedProductionIdSelector(state);
      const templateId = template?.id;

      if (name || templateId) {
        const templateToSave = getImportTemplateSelector(state);

        if (templateId) {
          const content = i18n.t('drawers.importSaveAsTemplate.notifications.replaceTemplate', { name: template.name });
          // Request confirmation before updating the template
          await dispatch(openPromptAsync({ content }));
          await updateCueSheetTemplate({ ...templateToSave, ...template });
        } else {
          try {
            await createCueSheetTemplate({ ...templateToSave, name });
          } catch (e) {
            const isDuplicated = path(['response', 'data', 'message'], e) === DUPLICATED_TEMPLATE_MESSAGE;
            // If the call failed because there was already another template with the same name
            // handle error, otherwise throw it.
            if (isDuplicated) {
              const message = i18n.t('importCueSheet.notifications.duplicatedTemplate', { name });
              return dispatch(showSnackbar({ message }));
            }
            throw e;
          }
        }
      }

      const importResult = await importCueSheetCall({
        cues,
        cueSheetVersionId,
        divisionId,
        subprojectId,
      });
      dispatch(navigateBackFromImport({ activeStep: undefined }));
      dispatch(clearImportCueSheet());
      dispatch(closeDrawer());
      // Prevent prompt dialog from closing the import results dialog.
      return { noClose: true, importResult };
    };

function ImportProgressContent() {
  return (
    <Box sx={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
      {i18n.t('drawers.importCueSheet.notifications.pleaseWait')} <CircularProgress />
    </Box>
  );
}
