import {
  Box,
  Grid,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  InputAdornment,
  Typography,
  TextField,
  Button,
  Skeleton,
} from '@mui/material';
import { KeyboardArrowUp, KeyboardArrowDown, Search } from '@mui/icons-material';
import { useMemo, useState } from 'react';

import { getEpisodesBySeason } from 'redux-core/productions/services';
import { useFetch } from 'utils/hooks';

import { rootTranslation } from 'utils/format';
import { TVDetailsEpisode } from '../TVDetailsEpisode';

type TProduction = {
  productionId: number;
  tenantId: number;
  productionType: 'features' | 'tv' | 'marketing';
  prodArray: { id: number }[];
  productionObjectId: number;
  divisionId: number;
};

export type TVDetailsProps = {
  selected: TProduction;
  toggleWatchingProduction: (payload: { isBeingWatched: boolean; id: number; projectId: number }) => Promise<void>;
  refetchWatching: () => Promise<void>;
  goToLink: (link: string, payload: unknown, config: unknown) => void;
  closeAllDialogs: () => void;
  keepSetupInfo: (divisionId: number, projectId: number, tenantId: number) => void;
  openModal: (modalName: string, payload: unknown) => void;
  normalizedProdType: 'features' | 'tv' | 'marketing';
  showInactive: boolean;
};

const t = rootTranslation('globalSearch.details.tv');

export const TVDetails = ({
  selected,
  toggleWatchingProduction,
  refetchWatching,
  goToLink,
  closeAllDialogs,
  keepSetupInfo,
  openModal,
  normalizedProdType,
  showInactive,
}: TVDetailsProps) => {
  const [sortEpisodesAscending, setSortEpisodesAscending] = useState(false);
  const [searchTerm, setSearchTerm] = useState(null);

  const SortIcon = sortEpisodesAscending ? KeyboardArrowDown : KeyboardArrowUp;

  const [episodes, loading] = useFetch(async () => {
    const episodesFetched = await getEpisodesBySeason({ releaseId: selected?.productionId, showInactive });
    return episodesFetched || [];
  }, [selected]);

  const filteredEpisodes = useMemo(
    () =>
      episodes
        .sort((e1, e2) => {
          if (!sortEpisodesAscending) return e2.number - e1.number;
          return e1.number - e2.number;
        })
        .filter((episode) => {
          if (!searchTerm?.trim()) return true;
          const searchTermLower = searchTerm.toLowerCase();
          const numberLower = episode.number.toLowerCase();
          const nameLower = episode.name.toLowerCase();
          return numberLower.includes(searchTermLower) || nameLower.includes(searchTermLower);
        }),
    [episodes, searchTerm, sortEpisodesAscending],
  );

  if (loading) {
    return <Skeleton />;
  }

  return (
    <Grid item xs={12}>
      <TextField
        value={searchTerm}
        onChange={({ target }) => setSearchTerm(target.value)}
        placeholder={t('search')}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search color="primary" fontSize="small" />
            </InputAdornment>
          ),
        }}
      />
      <Box mt={1}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ width: '25px' }}> </TableCell>
              <TableCell sx={{ paddingBottom: 0 }} colSpan={3} style={{ paddingLeft: '0.5em' }}>
                <Button onClick={() => setSortEpisodesAscending(!sortEpisodesAscending)}>
                  <Typography variant="caption" color="black">
                    <Grid container alignItems="center">
                      {t('episode')} <SortIcon style={{ marginLeft: '5px' }} />
                    </Grid>
                  </Typography>
                </Button>
              </TableCell>
              <TableCell sx={{ paddingBottom: 0 }} colSpan={3} style={{ paddingLeft: '0.5em' }} />
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredEpisodes.map((episode) => (
              <TVDetailsEpisode
                key={`episode-${episode.id}`}
                episode={episode}
                selected={selected}
                refetchWatching={refetchWatching}
                toggleWatchingProduction={toggleWatchingProduction}
                goToLink={goToLink}
                closeAllDialogs={closeAllDialogs}
                keepSetupInfo={keepSetupInfo}
                openModal={openModal}
                normalizedProdType={normalizedProdType}
              />
            ))}
          </TableBody>
        </Table>
      </Box>
    </Grid>
  );
};
