import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { isEmpty } from 'lodash';
import { useSearchTracksQuery } from 'services/tracks/tracks';
import { TSearchTracksFilters } from 'services/tracks/types';
import { SearchEmptyState } from './SearchEmptyState';
import { TrackListTable } from '../TracksList/table/TrackListTable';

type SearchContentProps = {
  searchTracksQueryResult: ReturnType<typeof useSearchTracksQuery>;
  searchQuery?: TSearchTracksFilters;
  duplicateTrack: any
};

export function SearchContent({ searchTracksQueryResult, searchQuery, duplicateTrack }: SearchContentProps) {
  const { trigger } = useFormContext();
  const { data: tracksInfo, isLoading } = searchTracksQueryResult;
  const emptySearch = isEmpty(searchQuery?.filters?.titleTerm) && isEmpty(searchQuery?.filters?.artistTerm);

  const onCueContainerIdMissing = useCallback(() => {
    trigger('cueContainerId');
  }, [trigger]);

  if (emptySearch) {
    return <SearchEmptyState />;
  }

  return (
    <TrackListTable
      loading={isLoading}
      tracks={tracksInfo?.data}
      pagination={tracksInfo?.pagination}
      onCueContainerIdMissing={onCueContainerIdMissing}
      duplicateTrack={duplicateTrack}
    />
  );
}
