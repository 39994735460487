import { ALL_OPTION } from 'utils/constants';
import filter from 'ramda/src/filter';
import prop from 'ramda/src/prop';

export const USING_VALUE = {
  ALL: ALL_OPTION.id,
  USING: 'using',
  NOT_USING: 'not_using',
};

export const USING_PAYLOADS = {
  [USING_VALUE.ALL]: undefined,
  [USING_VALUE.USING]: true,
  [USING_VALUE.NOT_USING]: false,
};

export const pickUsingValue = (using) => (using ? { using: USING_PAYLOADS[using] } : {});

export const pickUsingInitialValue = (using) => {
  const keys = Object.keys(USING_PAYLOADS);
  /* Pick the USING_PAYLOADS key based on the provided 'using' value */
  const key = keys.find((val) => USING_PAYLOADS[val] === using);
  /* Use this key to get the desired string in the USING_VALUE enum */
  const value = USING_VALUE[key.toUpperCase()];
  return { using: value };
};

/**
 * @param {string[]} formValues - Array of completed statuses id's
 * @param {{}[]} filtersData - Array of completed statuses objects
 */
export const pickCompletedStatusesValue = (prevFilters, filtersData) => {
  if (!prevFilters || !prevFilters.length) return {};

  const completedStatuses = filter(({ id }) => prevFilters.includes(id))(filtersData).map(prop('id'));

  return { completedStatuses };
};

/**
 * @param {object[]} prevFilters - Array of completed statuses objects
 */
export const pickCompletedStatusesInitialValue = (prevFilters) => {
  if (!prevFilters || !prevFilters.length) return {};

  return { completedStatuses: prevFilters };
};

export const FILTERS_LIST_FOR_CUES = [
  'trackTypes',
  'trackUseTypes',
  'trackStatuses',
  'trackTags',
  'clearanceReps',
  'trackLibraries',
];

export const FILTERS_LIST_FOR_ASSETS = ['assets', 'assetStatuses', 'assetTypes', 'vendors', 'completedStatuses'];
