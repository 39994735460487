/* eslint-disable no-use-before-define */
/* eslint-disable react/no-children-prop */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Popover from '@material-ui/core/Popover';
import { MenuItem, Select } from '@mui/material';
import { Button, DatePicker, InputFormat, Text } from 'components/styleguide';

import { getLocalDate, rootTranslation } from 'utils/format';
import dayjs from 'dayjs';
import classnames from 'classnames';
import useStyles from './styles';

const defaultState = { add: 0, changeBy: 'days' };
const reducer = (state = {}, action = {}) => {
  const { name, value } = action;
  return { ...defaultState, ...state, [name]: value };
};

const PopoverPicker = ({ anchorEl, ...props }) => (
  <Popover
    data-testid="popover-datepicker"
    open={Boolean(anchorEl)}
    onClose={() => props.onUpdate()}
    anchorEl={anchorEl}
    TransitionProps={{
      mountOnEnter: true,
      unmountOnExit: true,
    }}
  >
    <PopoverContent {...props} />
  </Popover>
);

PopoverPicker.displayName = 'StaticPickerPopover';

PopoverPicker.propTypes = {
  anchorEl: PropTypes.any,
  label: PropTypes.string,
  minDate: PropTypes.string,
  onUpdate: PropTypes.func.isRequired,
  value: PropTypes.any,
};

export default PopoverPicker;

const t = rootTranslation('global.forms');

const changeByOptions = [
  { id: 'days', name: t('days') },
  { id: 'months', name: t('months') },
];

const PopoverContent = ({ label, minDate, onUpdate, value }) => {
  const classes = useStyles();

  const defaultValue = value ? dayjs(value) : value;
  const [date, setDate] = useState(defaultValue);
  const [values, onChange] = useReducer(reducer, {
    ...defaultState,
    fromDate: defaultValue,
  });

  useEffect(() => {
    const { add = 0, changeBy, fromDate } = values;
    if (!fromDate || !fromDate.isValid()) return;
    const value = fromDate.clone().add(add, changeBy);
    setDate(value);
  }, [values]);

  return (
    <Box px={1} py={2.5}>
      <Box alignItems="center" display="flex" ml={2} mb={2}>
        <Text bold children={label} variant="body2" />
        <Box ml={2} className={classnames({ [classes.withBorder]: date })}>
          <Text children={getLocalDate(date)} variant="body2" />
        </Box>
      </Box>
      <Divider />
      <DatePicker
        minDate={minDate}
        onChange={(value) => onChange({ name: 'fromDate', value })}
        value={values.fromDate}
        variant="static"
      />
      <Divider />
      <Box display="flex" justifyContent="space-around" maxWidth={310} mt={2}>
        <Grid item xs={3}>
          <InputFormat
            disabled={!values.fromDate}
            max={1500}
            onChange={(value) => onChange({ name: 'add', value })}
            type="plain"
            value={values.add}
          />
        </Grid>
        <Grid item xs={4}>
          <Select
            disabled={!values.fromDate}
            value={values.changeBy}
            onChange={(event) => onChange({ name: 'changeBy', value: event.target.value })}
          >
            {changeByOptions.map((option) => (
              <MenuItem value={option.id}>{option.name}</MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={4} style={{ zIndex: 1 }}>
          <Text children={t('from')} display="block" variant="overline" />
          <Text bold children={values.fromDate ? values.fromDate?.format('MMMM Do') : '--'} variant="caption" />
        </Grid>
      </Box>
      <Box display="flex" justifyContent="flex-end" mt={2}>
        <Button children={t('update')} disabled={!date} onClick={() => onUpdate(date)} size="xs" variant="primary" />
      </Box>
    </Box>
  );
};

PopoverContent.displayName = 'StaticPickerPopoverContent';

PopoverContent.propTypes = {
  label: PropTypes.string.isRequired,
  minDate: PropTypes.string,
  onUpdate: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};
