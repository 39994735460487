/* eslint-disable react/no-children-prop */
/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import CheckIcon from '@material-ui/icons/Check';
import useStyles from './styles';

const StepIcon = ({ active, completed, icon }) => {
  const classes = useStyles();
  return (
    <div
      role="status"
      className={classnames(classes.stepIcon, {
        inactive: !active && !completed,
      })}
      children={completed ? <CheckIcon data-testid="step-icon-completed" fontSize="small" /> : icon}
    />
  );
};

StepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
  icon: PropTypes.number,
};

export default StepIcon;
