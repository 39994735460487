import { Box, Button, Typography } from '@mui/material';
import { useRootTranslation } from 'utils/hooks';

export type TShowWhereToGoOptions = {
  isNewProduction: boolean,
  goToProduction: () => Promise<void>,
  goBack: () => void,
}

export const ShowWhereToGoOptions = ({ isNewProduction, goToProduction, goBack }: TShowWhereToGoOptions) => {
  const t = useRootTranslation('drawers.quickSetupProduction.whereNext');
  const continueButtonLabel = isNewProduction ? 'continueToNew' : 'continueToExisting';
  return (
    <Box pt={4} px={16} sx={{ display: 'flex', flexDirection: 'column', gap: 6 }}>
      <Typography align="center" variant="h3" sx={{ fontWeight: 'bold' }}>{t('title')}</Typography>
      <Button
        variant="contained"
        onClick={goToProduction}
      >
        {t(continueButtonLabel)}
      </Button>
      <Button variant="outlined" data-testid="return-to-current" onClick={goBack}>{t('goBack')}</Button>
    </Box>
  );
};
