import { TextField } from '@mui/material';
import { FormField } from 'components/common/Form/Fields/FormField/FormField';
import { rootTranslation } from 'utils/format';

const t = rootTranslation('apmSignup');

export const CompanyNameField = () => (
  <FormField
    fullWidth
    label={t('companyName')}
    name="companyName"
    render={({ field, muiprops }) => (
      <TextField
        {...field}
        {...muiprops}
        autoFocus
        id="company-name"
        data-testid="input-text-company-name"
        placeholder={t('companyName')}
        InputLabelProps={{ shrink: true }}
      />
    )}
  />
);
