import * as React from 'react';
import { v4 as uuidv4 } from 'uuid';
import TextField from '@mui/material/TextField';
import { Box } from '@mui/system';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { IconButton, ListItem } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { rootTranslation } from 'utils/format';
import { TCreateOption, TOption } from './types';
import { EditOptionPopover } from './EditOptionPopover';
import { EditOptionInput } from './EditOptionInput';

const globalT = rootTranslation('global');

type ForwardedAutocompleteProps = Pick<
  React.ComponentProps<typeof Autocomplete>,
  'onInputChange' | 'loading' | 'noOptionsText'
>;

type ForwardedTextFieldProps = Pick<
  React.ComponentProps<typeof TextField>,
  'aria-labelledby' | 'placeholder' | 'error' | 'id'
>;

type AutocompleteWithEditProps = ForwardedAutocompleteProps & {
  options: TOption[];
  value: TOption;
  onChange: (option: TOption | null) => void;
  onCreate: (name: string) => void;
  onEdit?: (newName: string) => void;
  inputProps?: ForwardedTextFieldProps & {
    'data-testid'?: string;
  };
  paperMinWidth?: string;
};

type TAutocompleteValue = TOption | TCreateOption | null;

const filter = createFilterOptions<TOption | TCreateOption>();

export function AutocompleteWithEdit({
  options,
  onChange,
  onCreate,
  onEdit,
  value,
  inputProps,
  paperMinWidth = '100%',
  ...props
}: AutocompleteWithEditProps) {
  const anchorElRef = React.useRef(null);
  const [editPopoverOpen, setEditPopoverOpen] = React.useState(false);
  const [editingOption, setEditingOption] = React.useState(null);
  const { error, placeholder, ...restInputProps } = inputProps;

  const handleClose = () => {
    setEditPopoverOpen(false);
  };

  return (
    <Box ref={anchorElRef}>
      <EditOptionPopover
        open={editPopoverOpen}
        anchorElRef={anchorElRef}
        onClose={handleClose}
        paperMinWidth={paperMinWidth}
      >
        <EditOptionInput option={editingOption} persistValue={onEdit} />
      </EditOptionPopover>
      <Autocomplete<TAutocompleteValue, false, false, true>
        id="autocomplete-editable"
        key={`AutocompleteWithEdit-${value?.id}-${value?.name}`}
        value={value}
        onChange={(_, newValue) => {
          if (newValue == null) {
            // @ts-ignore
            onChange(newValue);
            return;
          }
          if (typeof newValue === 'string') {
            onCreate(newValue);
          } else if ('inputValue' in newValue) {
            // Create a new value from the user input
            onCreate(newValue.inputValue);
          } else {
            onChange(newValue);
          }
        }}
        filterOptions={(currentOptions, params) => {
          const filtered = filter(currentOptions, params);
          const { inputValue } = params;
          // Suggest the creation of a new value
          const isExisting = currentOptions.some((option) => inputValue === option?.name);
          if (inputValue !== '' && !isExisting) {
            filtered.push({
              id: uuidv4(),
              name: `${globalT('forms.createNew')} "${inputValue}"`,
              inputValue,
            });
          }
          return filtered;
        }}
        isOptionEqualToValue={(option, valueOption) => option?.id === valueOption?.id}
        options={options}
        getOptionLabel={(option) => {
          // Value selected with enter, right from the input
          if (typeof option === 'string') {
            return option;
          }
          // Add "xxx" option created dynamically
          if ('inputValue' in option) {
            return option?.inputValue;
          }
          // Regular option
          return option?.name;
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={placeholder}
            error={error}
            inputProps={{
              ...params.inputProps,
              ...restInputProps,
              'data-testid': inputProps['data-testid'] ?? 'autocomplete-editable',
            }}
          />
        )}
        renderOption={(optionProps, option, state) => {
          const renderEditAction = onEdit != null && state.selected;

          const handleEdit = (event: React.MouseEvent<HTMLButtonElement>) => {
            /**
             * Stop propagation used to prevent calling the option onChange when clicking the edit button
             */
            event.stopPropagation();
            setEditingOption(option);
            setEditPopoverOpen(true);
          };

          return (
            <ListItem
              {...optionProps}
              secondaryAction={
                renderEditAction && (
                  <IconButton aria-label="edit option" onClick={handleEdit}>
                    <EditIcon />
                  </IconButton>
                )
              }
            >
              <Box
                title={option.name}
                sx={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  width: renderEditAction ? 'calc(100% - 40px)' : '100%',
                }}
              >
                {option.name}
              </Box>
            </ListItem>
          );
        }}
        fullWidth
        freeSolo
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        componentsProps={{
          paper: {
            sx: {
              minWidth: paperMinWidth,
              width: '100%',
            },
          },
        }}
        {...props}
      />
    </Box>
  );
}
