/* eslint-disable no-unused-expressions */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Prompt } from 'react-router-dom';
import { useFormikContext } from 'formik';

import { usePrompt, useRootTranslation } from 'utils/hooks';
import enhancer from './enhancer';

const FormPrompt = ({ when, goToLink, customMessage, onConfirm }) => {
  const t = useRootTranslation('global.forms');
  const openPrompt = usePrompt();
  const formik = useFormikContext();
  const [shouldHidePrompt, setShouldHidePrompt] = useState(false);

  const handleBlockedNavigation = ({ pathname, state }) => {
    openPrompt({
      content: customMessage || t('unsavedChanges'),
      onConfirm: async () => {
        await setShouldHidePrompt(true);
        onConfirm && (await onConfirm(formik));
        goToLink(pathname, state);
      },
    });

    return false;
  };

  return <Prompt when={when && !shouldHidePrompt} message={handleBlockedNavigation} data-testid="FormikForm-Prompt" />;
};

FormPrompt.propTypes = {
  when: PropTypes.bool.isRequired,
  customMessage: PropTypes.string,
  goToLink: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default enhancer(FormPrompt);
