import { Typography } from '@mui/material';
import { getLocalDate } from 'utils/format';
import { RIGHTS_TERRITORIES_TERM, RIGHTS_TERRITORIES_TERM_ENUM } from 'components/Settings/Tabs/Rights/constants';
import { useRootTranslation } from 'utils/hooks';
import { getTerm } from 'components/common/RightsPresetPicker/TerritoryDates';

export const RightsDateDetails = ({ rights }) => {
  const t = useRootTranslation('rights.row');
  const { start, end, otherDetails } = rights;
  const term = getTerm({ start, end });
  let datesText = `${getLocalDate(start)} - ${getLocalDate(end)}`;

  if (term === RIGHTS_TERRITORIES_TERM_ENUM.ONE_DAY) {
    datesText = getLocalDate(start);
  }
  if (term === RIGHTS_TERRITORIES_TERM_ENUM.INPERPETUITY) {
    datesText = start
      ? `${getLocalDate(start)} - ${t(RIGHTS_TERRITORIES_TERM.INPERPETUITY)}`
      : t(RIGHTS_TERRITORIES_TERM.INPERPETUITY);
  }

  return (
    <Typography align="left" variant="body2">
      {otherDetails || datesText}
    </Typography>
  );
};
