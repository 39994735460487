/* eslint-disable import/no-cycle */
import { handleActions } from 'redux-actions';
import { clearData } from 'redux-core/auth/actions';
import always from 'ramda/src/always';
import set from 'ramda/src/set';
import lensPath from 'ramda/src/lensPath';
import * as actions from './actions';

const defaultState = {
  bookmarks: [],
  recentSearch: [],
  search: [],
};

const headerReducer = {
  [actions.loadRecentSearchList]: (state, { payload }) => set(lensPath(['recentSearch']), payload)(state),
  [actions.loadSearchList]: (state, { payload }) => set(lensPath(['search']), payload)(state),
  [actions.loadBookmarkList]: (state, { payload }) => set(lensPath(['bookmarks']), payload)(state),
  [actions.setAdvancedSearchFilters]: (state, { payload }) => set(lensPath(['advancedFilters']), payload)(state),
  [actions.setSearchItemDetails]: (state, { payload }) => set(lensPath(['searchItem']), payload)(state),
  [actions.setSearchResults]: (state, { payload }) => set(lensPath(['searchResults']), payload)(state),
  [actions.setSearchResponse]: (state, { payload }) => set(lensPath(['searchResponse']), payload)(state),
  [clearData]: always(defaultState),
};

export default handleActions(headerReducer, defaultState);
