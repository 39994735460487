import * as React from 'react';
import { Skeleton } from '@mui/material';
import { useDebouncedCallback } from 'use-debounce';
import { rootTranslation } from 'utils/format';
import { TGetTrackRightsOwnersResponse, TLicensorType } from 'services/tracks/types';
import { useGetTrackRightsOwnersQuery } from 'services/tracks/tracks';
import enhancer from './enhancer';
import { AutocompleteHiddenLicensors } from './AutoCompleteHiddenLicensors';

const tGlobal = rootTranslation('global');

type TRightOwner = TGetTrackRightsOwnersResponse['content'][number];

type ForwardedAutocompleteWithEditProps = Pick<React.ComponentProps<typeof AutocompleteHiddenLicensors>, 'onEdit'>;

type FieldProps = ForwardedAutocompleteWithEditProps & {
  value: Pick<TRightOwner, 'id' | 'name'>;
  onChange: (nextValue: TRightOwner) => void;
  types: TLicensorType[];
  htmlSectionId: string;
  onCreate: (name: string) => void;
  transformResponse?: (data: TGetTrackRightsOwnersResponse['content']) => TGetTrackRightsOwnersResponse['content'];
  hasError?: boolean;
  divisionId?: number;
};

const mapHiddenOptionsByDivision = (options, divisionId) => options.map((item) => {
    const qwireTracksRightsOwnersHiddenByDivision = item?.qwireTracksRightsOwnersHiddenByDivision?.filter(
      (i) => i.divisionId === divisionId)[0];

    return {
      id: item?.id,
      name: item?.name,
      pro: item?.pro,
      qwireTracksRightsOwnerId: item?.qwireTracksRightsOwnerId,
      hidden: qwireTracksRightsOwnersHiddenByDivision ? qwireTracksRightsOwnersHiddenByDivision.isHidden : false,
    };
  });

function LicensorField({
  value,
  onChange,
  types,
  htmlSectionId,
  onCreate,
  onEdit,
  transformResponse,
  hasError,
  divisionId,
}: FieldProps) {
  const [searchTerm, setSearchTerm] = React.useState('');
  const { data, isLoading, isFetching } = useGetTrackRightsOwnersQuery({
    term: searchTerm,
    types,
  });

  const options = transformResponse ? transformResponse(data?.content) as any : data?.content;

  const debouncedSetSearch = useDebouncedCallback((_, term) => {
    setSearchTerm(term);
  }, 300);

  if (isLoading) {
    return <Skeleton />;
  }

  return (
    <AutocompleteHiddenLicensors
      options={mapHiddenOptionsByDivision(options, divisionId) ?? []}
      value={value}
      onChange={(nextValue: TRightOwner) => {
        onChange(nextValue);
      }}
      onCreate={onCreate}
      onInputChange={debouncedSetSearch}
      loading={isFetching}
      noOptionsText={tGlobal('noResults')}
      onEdit={onEdit}
      inputProps={{
        'aria-labelledby': `${htmlSectionId}-name`,
        placeholder: tGlobal('startTyping'),
        error: hasError,
      }}
      paperMinWidth="580px"
    />
  );
}

export default enhancer(LicensorField);
