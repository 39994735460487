/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/no-children-prop */
/* eslint-disable no-nested-ternary */
import { BaseDrawer, DrawerHeader, Row, ToggleButton } from 'components/styleguide';
import { Grid } from '@material-ui/core';
import map from 'ramda/src/map';
import pathOr from 'ramda/src/pathOr';
import propOr from 'ramda/src/propOr';
import React, { useState, useMemo } from 'react';
import * as Yup from 'yup';

import { FormButton, FormToggleButtonGroup, FormInputText } from 'components/common/Form/Fields/index';
import { maxLength } from 'utils/forms';
import { useRootTranslation, useFetch } from 'utils/hooks';
import Form from 'components/common/Form';
import PropTypes from 'prop-types';
import SubtitleWithLine from 'components/common/SubtitleWithLine';

import { getDefaultCountryName, PHONE_TYPES } from 'utils/constants';
import { getCountriesCall } from 'redux-core/territories/services';
import { v4 as uuidv4 } from 'uuid';
import Communication from './Communication';
import PersonalInformation from './PersonalInformation';
import enhancer from './enhancer';
import useStyles from './styles';

const getDefaultPhone = () => ({
  type: PHONE_TYPES.PHONE,
  number: '',
  generatedId: uuidv4(),
});

const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .nullable()
    .when(['lastName', 'companyName'], {
      is: (lastName, companyName) => !lastName && !companyName,
      then: Yup.string().required('global.forms.validations.requiredOneOfNameFields'),
    }),
  email: Yup.string().nullable().email('global.forms.validations.types.email'),
  additionalData: Yup.object().shape({
    phones: Yup.array().of(
      Yup.object().shape({
        type: Yup.string(),
        number: Yup.string(),
      }),
    ),
  }),
  rightsOwnerType: Yup.string()
    .nullable()
    .when('isNewRightsOwner', {
      is: true,
      then: Yup.string().required('global.forms.validations.requiredOneOfNameFields'),
    }),
  dba: maxLength(255).nullable(),
  tin: maxLength(11).nullable(),
});

const ContactsDrawer = ({ name, payload: { contact, isAdding, onSubmit }, onClose, activeDivisionId }) => {
  const t = useRootTranslation('drawers.contacts');
  const tGlobal = useRootTranslation('global');
  const classes = useStyles();
  const [isEditing, setIsEditing] = useState(Boolean(isAdding));
  const [showCompanyTypeAhead, setShowCompanyTypeAhead] = useState(true);
  const [isNewCompanyName, setIsNewCompanyName] = useState(false);

  const [countries] = useFetch(getCountriesCall);

  const defaultCountryName = useMemo(() => getDefaultCountryName(countries), [countries]);
  const additionalData = propOr({}, 'additionalData')(contact);
  const phones = pathOr([], ['phones'], additionalData);
  const initialValues = useMemo(
    () => ({
      countryName: defaultCountryName,
      ...contact,
      // we need that adjustment in order to show the companyName initially on edit
      companyName: contact?.companyName ? { id: contact.companyName, name: contact.companyName } : '',
      isNewRightsOwner: false,
      additionalData: {
        ...additionalData,
        phones: phones.length ? map((phone) => ({ ...phone, generatedId: uuidv4() }), phones) : [getDefaultPhone()],
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [contact, defaultCountryName],
  );
  const handleCancel = () => {
    onClose({ content: tGlobal('prompt.cancel') });
  };

  const handleSubmit = async ({ email, companyName, ...values }) => {
    await onSubmit({
      ...values,
      divisionId: activeDivisionId,
      // we don't need to provide companyName as an object to the BE on edit
      companyName: companyName ? companyName.name ?? companyName : companyName,
      email: email || null,
    });
    onClose();
  };

  return (
    <Form
      drawerName={name}
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      <Grid container direction="column" className={classes.root}>
        <DrawerHeader
          title={t(isAdding ? 'drawerTitle' : isEditing ? 'drawerEditTitle' : 'drawerViewTitle')}
          onClose={onClose}
        >
          {isEditing ? (
            <>
              <FormButton
                alwaysEnabled
                variant="destructive"
                size="xs"
                testId="contacts-drawer-cancel"
                children="global.forms.cancel"
                onClick={handleCancel}
              />
              <FormButton
                variant="primary"
                testId="contacts-drawer-save"
                size="xs"
                children="global.forms.save"
                type="submit"
              />
            </>
          ) : (
            <FormButton
              alwaysEnabled
              variant="primary"
              testId="contacts-drawer-edit"
              size="xs"
              children="global.forms.edit"
              type="button"
              onClick={() => setIsEditing(true)}
            />
          )}
        </DrawerHeader>

        <PersonalInformation
          isAdding={isAdding}
          contact={contact}
          isNewCompanyName={isNewCompanyName}
          setIsNewCompanyName={setIsNewCompanyName}
          showCompanyTypeAhead={showCompanyTypeAhead}
          setShowCompanyTypeAhead={setShowCompanyTypeAhead}
          readOnly={!isEditing}
        />

        <Communication
          isEditing={isEditing}
          readOnly={!isEditing}
          getDefaultPhone={getDefaultPhone}
          countries={countries}
        />

        <Row spacing={1} mb={3}>
          <SubtitleWithLine children={t('form.payee.title')} variant="caption" />
        </Row>

        <Row spacing={1} alignItems="flex-end">
          <Grid item>
            <FormToggleButtonGroup
              className={classes.toggleButtonGroup}
              name="payeeType"
              label={t('form.payee.type.label')}
              data-test-id="type"
              size="sm"
              readOnly={!isEditing}
            >
              <ToggleButton value={tGlobal('payeeTypes.COMPANY')} disabled={!isEditing}>
                {t('form.payee.type.company')}
              </ToggleButton>
              <ToggleButton value={tGlobal('payeeTypes.INDIVIDUAL')} disabled={!isEditing}>
                {t('form.payee.type.individual')}
              </ToggleButton>
            </FormToggleButtonGroup>
          </Grid>

          <Grid item xs={5}>
            <FormInputText
              name="payeeCompanyName"
              label={t('form.payee.companyName')}
              testId="contacts-drawer-payee-companyName"
              readOnly={!isEditing}
            />
          </Grid>
        </Row>

        <Row spacing={1}>
          <Grid item xs={8}>
            <FormInputText
              name="payeeDba"
              label={t('form.payee.dba')}
              testId="contacts-drawer-payee-dba"
              readOnly={!isEditing}
            />
          </Grid>
          <Grid item xs={4}>
            <FormInputText
              name="payeeTinNumber"
              label={t('form.payee.tin')}
              testId="contacts-drawer-payee-tin"
              readOnly={!isEditing}
            />
          </Grid>
        </Row>
      </Grid>
    </Form>
  );
};

ContactsDrawer.defaultProps = {
  payload: {},
};

ContactsDrawer.propTypes = {
  name: PropTypes.string,
  payload: PropTypes.object,
  contact: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  openPrompt: PropTypes.func.isRequired,
  activeDivisionId: PropTypes.number.isRequired,
};

ContactsDrawer.displayName = 'ContactsDrawer';

const Drawer = ({ open, onClose, ...props }) => (
  <BaseDrawer open={open} onClose={onClose}>
    <ContactsDrawer onClose={onClose} {...props} />
  </BaseDrawer>
);

Drawer.propTypes = ContactsDrawer;

export default enhancer(Drawer);
