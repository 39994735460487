import { createSelector } from 'reselect';
import mergeAll from 'ramda/src/mergeAll';
import pick from 'ramda/src/pick';
import path from 'ramda/src/path';
import prop from 'ramda/src/prop';
import pathOr from 'ramda/src/pathOr';
import { getSelectedProductionDivisionIdSelector, getSelectedSelector } from 'redux-core/overview/selectors';
import { getLocationParametersSelector, getLocationSelector } from 'redux-core/router/selectors';
import { getActiveDivisionId } from 'redux-core/settings/selectors';
import { ROUTE_TO_VIEW_MODE } from 'components/ProductionOverview/components/constants';
import { getSelectedTrackSelector } from 'redux-core/tracks/selectors';

const getProductionsRoot = (state) => state.productions;

export const getHierarchyUpdateProject = createSelector(getProductionsRoot, prop('hierarchyUpdateProject'));

export const getSetupFlowInfoSelector = createSelector(getProductionsRoot, path(['setupInfo']));

export const getSetupFlowSeasonSelector = createSelector(getProductionsRoot, path(['setupFlow', 'season']));

export const getSetupFlowSeasonReleaseSelector = createSelector(getProductionsRoot, path(['setupFlow', 'release']));

export const getSetupFlowPrCompaniesSelector = createSelector(
  getSetupFlowSeasonSelector,
  pathOr([], ['additionalData', 'productionEntities']),
);

export const getSeasonCurrenciesSelector = createSelector(getSetupFlowSeasonSelector, path(['currencies']));

export const getSetupFlowProjectSelector = createSelector(getProductionsRoot, path(['setupFlow', 'project']));

export const getSetupFlowKeyPersonnelListSelector = createSelector(
  getProductionsRoot,
  path(['setupFlow', 'keyPersonnel']),
);

export const getKeyPersonnelFormSelector = createSelector(getProductionsRoot, path(['setupFlow', 'keyPersonnelForm']));

export const getProductionsListSelector = createSelector(getProductionsRoot, path(['productionsList']));

export const getDivisionId = createSelector(
  getSetupFlowInfoSelector,
  getSetupFlowSeasonReleaseSelector,
  getSelectedProductionDivisionIdSelector,
  getActiveDivisionId,
  getLocationParametersSelector,
  (setupFlow, setupFlowRelease, selectedDivisionId, activeDivisionId, locationParams) =>
    prop('divisionId', setupFlowRelease) ||
    prop('divisionId', setupFlow) ||
    selectedDivisionId ||
    activeDivisionId ||
    prop('divisionId', locationParams),
);

export const getTenantId = createSelector(
  getSetupFlowInfoSelector,
  getLocationParametersSelector,
  (setupFlow, locationParams) => prop('tenantId', setupFlow) || prop('tenantId', locationParams),
);

/**
 * Get relevant data to send as request payload
 * that will be placed as request.headers
 */
export const getSetupFlowInfoHeadersSelector = createSelector(
  getSetupFlowInfoSelector,
  pick(['divisionId', 'tenantId']),
);

export const getViewModeSelector = createSelector(getLocationSelector, (location) =>
  prop(location, ROUTE_TO_VIEW_MODE),
);

const getCurrencyInfo = pick([
  'exchangeRate',
  'masterCurrencyCode',
  'masterCurrencySymbol',
  'selectedCurrencyCode',
  'selectedCurrencySymbol',
]);
export const getProductionCurrencySelector = createSelector(
  getSelectedSelector,
  getSelectedTrackSelector,
  (production, track) => {
    const { exchangeRate, ...data } = mergeAll([getCurrencyInfo(track), getCurrencyInfo(production)]);
    return {
      ...data,
      exchangeRate: Number(exchangeRate) || 1,
    };
  },
);

export const getProductionMasterCurrencySelector = createSelector(
  getProductionCurrencySelector,
  prop('masterCurrencyCode'),
);

export const getPosterIdsSelector = createSelector(getProductionsRoot, prop('posterIds'));
