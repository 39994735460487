import { ALL_OPTION, translatedOptions } from 'utils/constants';
import clone from 'ramda/src/clone';
import { concatStrings } from 'utils';
import { HHMMSStoMMSS } from 'utils/date';
import { All } from 'services/clear/clearance-status';

export const GLOBAL_SEARCH_FILTER_BY = {
  PRODUCTIONS: {
    value: 'productions',
    sortBy: 'projectName',
    sortByDirection: 'ASC',
  },
  RIGHTS_OWNERS: {
    value: 'rightsowners',
    sortBy: 'relevance',
    sortByDirection: 'DESC',
  },
  TRACKS: {
    value: 'tracks',
    sortBy: 'relevance',
    sortByDirection: 'DESC',
  },
};

export const GLOBAL_TRACK_SEARCH = {
  ARTIST: 'trackArtist',
  LIBRARY_NAME: 'trackLibrary',
  PUBLISHER_NAME: 'trackPublisher',
  TRACK_TITLE: 'trackTitle',
  WRITER_NAME: 'trackWriter',
};

export const GLOBAL_PRODUCTION_SEARCH = {
  PROJECT_NAME: 'projectName',
  EPISODE_NAME: 'episodeName',
  PRODUCTION_NUMBER: 'productionNumber',
  CUE_SHEET_SEGMENT_NAME: 'cueSheetSegmentName',
};

export const GLOBAL_LICENSOR_SEARCH = {
  CONTACT_NAME: 'rightsOwnerContact',
  LICENSOR_NAME: 'rightsOwnerName',
  SUB_LABEL_NAME: 'rightsOwnerSubLabel',
};

export const SEARCH_DATE_TYPES = {
  ALL: 'IntlAndUsRelease',
  INTL_RELEASE: 'IntlRelease',
  US_RELEASE: 'UsRelease',
};

export const GLOBAL_SEARCH_LIMIT = 30;

export const GLOBAL_PRODUCTION_SEARCH_OPTIONS = [ALL_OPTION].concat(
  translatedOptions('globalSearch.filters.productionSearch')(GLOBAL_PRODUCTION_SEARCH),
);

export const GLOBAL_TRACK_SEARCH_OPTIONS = [ALL_OPTION].concat(
  translatedOptions('globalSearch.filters.trackSearch')(GLOBAL_TRACK_SEARCH),
);

export const GLOBAL_LICENSOR_SEARCH_OPTIONS = [ALL_OPTION].concat(
  translatedOptions('globalSearch.filters.licensorSearch')(GLOBAL_LICENSOR_SEARCH),
);

export const SEARCH_DATE_TYPES_OPTIONS = translatedOptions('globalSearch.filters.dateTypes')(SEARCH_DATE_TYPES);

export const getSearchPayload = ({ term, ...values }) => {
  const filters = clone(values.filters);

  const payload = { term, filters };

  if (filters.dateStart || filters.dateEnd) {
    if (filters.dateStart && filters.dateEnd) filters.dateRange = 'Between';
    else if (filters.dateStart) filters.dateRange = 'After';
    else filters.dateRange = 'Before';
  } else {
    delete filters.dateStart;
    delete filters.dateEnd;
  }

  if (filters.divisionId === ALL_OPTION.id) delete filters.divisionId;
  if (filters.clearanceStatusId === All.id) delete filters.clearanceStatusId;
  if (filters.dateType === ALL_OPTION.id) delete filters.dateType;

  if (filters.searchBy && filters.searchBy !== ALL_OPTION.id) {
    filters[filters.searchBy] = term;
    delete payload.term;
  }

  if (filters.filterBy.value === GLOBAL_SEARCH_FILTER_BY.RIGHTS_OWNERS.value) {
    filters.rightsOwnerTypes = ['master', 'sync', 'other'];
  }

  if (filters.filterBy.value === GLOBAL_SEARCH_FILTER_BY.PRODUCTIONS.value) {
    filters.types = ['movie', 'season_release', 'campaign'];
  }

  payload.sortBy = filters.filterBy.sortBy;
  payload.sortByDirection = filters.filterBy.sortByDirection;

  filters.filterBy = filters.filterBy.value;

  return payload;
};

/**
 *
 * @param {Array} cueContainers
 * @param {String} type One of "requested" or "actual"
 */
export const getUseAndDuration = (cueContainers, type = 'requested') => {
  if (!cueContainers) return [];
  return cueContainers
    .filter(({ cues }) => !!cues)
    .map(({ cues }) => cues
        .map((usage) => {
          const isFullDuration = !!usage[`${type}UseDurationFull`];
          const duration = isFullDuration ? 'Full' : HHMMSStoMMSS(usage[`${type}UseDuration`]);
          return concatStrings(' / ')(duration, usage[`${type}UseType`]);
        })
        .filter(Boolean))
    .flat();
};
