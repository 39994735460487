/* eslint-disable import/no-cycle */
import makeEntityServiceCalls from 'redux-core/serviceHelpers';
import { normalizeFile } from 'utils/files';

export const {
  get: getFileAttachmentByIdPureCall,
  getAll: getFileAttachmentsPureCall,
  create: createFileAttachmentCall,
  update: updateFileAttachmentCall,
  delete: deleteFileAttachmentCall,
} = makeEntityServiceCalls('production-attachments', 'clear');

export const getFileAttachmentByIdCall = async (payload) => {
  const { data, ...response } = await getFileAttachmentByIdPureCall(payload);

  const result = {
    ...response,
    data: normalizeFile(data),
  };
  return result;
};

export const getFileAttachmentsCall = async (payload) => {
  const { data, ...response } = await getFileAttachmentsPureCall(payload);
  const result = {
    ...response,
    data: data.map(normalizeFile),
  };
  return result;
};

export const { getAll: getMailsCall } = makeEntityServiceCalls('letters', 'clear');
