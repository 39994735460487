/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import TrackItem from './TrackItem';
import { TRACK_OPTIONS } from '..';

const TrackRow = ({ clearTrack, cueTrack, usageMatch, durationMatch, index, setCueSelections, selectedInRow }) => (
  <Grid container>
    <Grid item xs={6}>
      <TrackItem
        track={clearTrack}
        index={index}
        setCueSelections={setCueSelections}
        type={TRACK_OPTIONS.CLEAR}
        selectedInRow={selectedInRow}
        usageMatch={usageMatch}
        durationMatch={durationMatch}
      />
    </Grid>
    <Grid item xs={6}>
      <TrackItem
        track={cueTrack}
        index={index}
        setCueSelections={setCueSelections}
        type={TRACK_OPTIONS.CUE}
        hideCheckbox={!clearTrack && cueTrack}
        selectedInRow={selectedInRow}
        usageMatch={usageMatch}
        durationMatch={durationMatch}
      />
    </Grid>
  </Grid>
);

TrackRow.propTypes = {
  clearTrack: PropTypes.shape({}).isRequired,
  cueTrack: PropTypes.shape({}),
  usageMatch: PropTypes.bool,
  durationMatch: PropTypes.bool,
  index: PropTypes.number,
  setCueSelections: PropTypes.func,
  selectedInRow: PropTypes.string.isRequired,
};

export default TrackRow;
