/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { connect, FieldArray } from 'formik';

const CustomFormFieldArray = ({ children, ...props }) => (
  <FieldArray {...props} render={(arrayHelpers) => children(arrayHelpers, props.formik)} />
);

CustomFormFieldArray.propTypes = {
  children: PropTypes.any,
  render: PropTypes.func,
  formik: PropTypes.object.isRequired,
};

CustomFormFieldArray.defaultProps = {
  formik: {},
};

export default connect(CustomFormFieldArray);
