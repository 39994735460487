/* eslint-disable no-use-before-define */
/* eslint-disable no-promise-executor-return */
import { createActions } from 'redux-actions';
import findIndex from 'ramda/src/findIndex';
import propEq from 'ramda/src/propEq';
import assoc from 'ramda/src/assoc';
import identity from 'ramda/src/identity';
import { openPrompt } from 'redux-core/prompt/actions';
import {
  getDialogsRoot,
  getIsOpenedDialogFormDirty,
  getDialogByNameSelector,
  getDialogIndexByNameSelector,
} from './selectors';

const noop = () => undefined;

const actionsDefinition = {
  CLOSE_ALL_DIALOGS_HANDLER: noop,
  CLOSE_DRAWER: noop,
  CLOSE_MODAL: noop,
  OPEN_DRAWER: (name, payload) => ({ name, payload }),
  OPEN_MODAL: (name, payload) => ({ name, payload }),
  UPDATE_DIALOG: identity,
  SAVE_RIGHT: identity,
};

export const { closeAllDialogsHandler, closeDrawer, closeModal, openDrawer, openModal, updateDialog, saveRight } =
  createActions(actionsDefinition);

export const closeDrawerWithPrompt =
  ({ isCloseSingle = true, content } = {}) =>
  async (dispatch, getState) => {
    const closeAction = isCloseSingle ? closeDrawer : closeAllDialogs;

    const openPromptAsync = () =>
      new Promise((r) =>
        dispatch(
          openPrompt({
            content,
            onConfirm: async () => {
              await dispatch(closeAction());
              await r();
            },
          }),
        ),
      );

    const isOpenedDialogDirty = getIsOpenedDialogFormDirty(getState());

    if (isOpenedDialogDirty) await openPromptAsync();
    else await dispatch(closeAction());

    return openPromptAsync;
  };

export const closeAllDialogs =
  (isCallPrompt = false) =>
  async (dispatch) => {
    if (isCallPrompt) {
      await dispatch(closeDrawerWithPrompt({ isCloseSingle: false }));
    } else {
      await dispatch(closeAllDialogsHandler());
    }
  };

export const setDrawerDirty =
  ({ name, dirty, submitted }) =>
  async (dispatch, getState) => {
    const state = getState();
    const drawerIndex = findIndex(propEq('name', name))(getDialogsRoot(state));
    const currentDrawer = {
      ...getDialogsRoot(state)[drawerIndex],
      isDirty: dirty,
      submitted: Boolean(submitted),
    };

    if (drawerIndex !== -1) {
      await dispatch(
        updateDialog({
          index: drawerIndex,
          drawer: currentDrawer,
        }),
      );
    }
  };

export const openDocument360Tab = () => () => {
  window.open('https://qwire.document360.io');
};

export const updatePayloadByName =
  ({ name, payload }) =>
  (dispatch, getState) => {
    const state = getState();
    const dialog = getDialogByNameSelector(state, name);
    const index = getDialogIndexByNameSelector(state, name);
    const updatedDialog = assoc('payload', payload, dialog);
    // Update drawer only if drawer was found. -- OPEN ADVANCED SEARCH MODAL ON CUE SHEET FIX --
    if (index > -1) {
      dispatch(updateDialog({ index, drawer: updatedDialog }));
    }
  };
