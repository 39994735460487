import dayjs from 'dayjs';
import curry from 'ramda/src/curry';

/**
 * Used mainly for mocking data
 */
export const generateRandomDate = () =>
  dayjs()
    .subtract(Math.round(Math.random() * 99999), 'seconds')
    // We assume it is going to be an ISOString
    .toISOString();

export const generateRandomNumber = curry((min, max) => Math.ceil(Math.random() * (+max - +min) + +min));

export const generateRandomString = (length = 20) => {
  let result = '';
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789_*-' +
    // Adding multiple blank spaces to have more chances to be part of the random string
    '          ';
  const charactersLength = characters.length;
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};
