/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import useStyles from './styles';
import Text from '../Text';

const LINE_TYPES = { CENTER: 'center', LEFT: 'left', RIGHT: 'right' };

const TextWithLine = ({ textPosition, ...textProps }) => {
  const classes = useStyles();
  return (
    <h3
      className={classnames(classes.wrapper, {
        [textPosition]: LINE_TYPES[textPosition] || LINE_TYPES.CENTER,
      })}
    >
      <Text {...textProps} component="span" />
    </h3>
  );
};

TextWithLine.propTypes = {
  textPosition: PropTypes.oneOf(Object.values(LINE_TYPES)),
};

TextWithLine.defaultProps = {
  textPosition: LINE_TYPES.CENTER,
};

export default TextWithLine;
