/* eslint-disable react/no-children-prop */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-param-reassign */
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Form from 'components/common/Form';
import { useRootTranslation, useFetch } from 'utils/hooks';
import { getAllClearancesWithUsesAndDuration, updateClearancesCues } from 'redux-core/clearances/services';
import { FormButton } from 'components/common/Form/Fields';
import { DrawerHeader, Text, Loading } from 'components/styleguide';
import * as Yup from 'yup';
import { concatStrings } from 'utils';
import map from 'ramda/src/map';
import flatten from 'ramda/src/flatten';
import c from 'ramda/src/compose';
import filter from 'ramda/src/filter';
import propOr from 'ramda/src/propOr';
import uniqBy from 'ramda/src/uniqBy';
import prop from 'ramda/src/prop';
import dissoc from 'ramda/src/dissoc';
import uniq from 'ramda/src/uniq';
import { yupTimeMMSS } from 'utils/forms';
import Clearances from './Clearances';
import enhancer from './enhancer';

const validationSchema = Yup.object().shape({
  cues: Yup.array().of(
    Yup.object().shape({
      requestedUseDuration: yupTimeMMSS,
      requestedUseType: Yup.string().nullable(),
      actualUseDuration: yupTimeMMSS,
      actualUseType: Yup.string().nullable(),
    }),
  ),
});

const getCues = (track) => {
  if (!track?.clearances) return [];
  /** Get all the cues in the track */
  const cuesRaw = flatten(
    track.clearances.map((clearance) =>
      clearance.containers.map((container) => {
        container.cues.forEach((cue) => {
          cue.qclearClearanceId = clearance.id;
        });
        return container.cues;
      }),
    ),
  );
  /** For each cue, create cueContainerIds as an array of
   * all the containers the cue is in, then remove duplicates */
  const cues = c(
    uniqBy(prop('id')),
    map((cue) => ({
      ...dissoc('cueContainerId', cue),
      /** Get all the cueContainerIds the cue belongs to and remove duplicates */
      cueContainerIds: uniq(
        cuesRaw.reduce((acc, c) => {
          if (c.cueContainerId === cue.cueContainerId) {
            return uniq([...acc, c.cueContainerId]);
          }
          return acc;
        }, []),
      ),
    })),
  )(cuesRaw);
  return cues;
};

const ClearanceUsesAndDurationsDrawer = ({
  name,
  onClose,
  payload: { trackId, clearanceBundleId, onSubmit, readOnly, cueContainerId },
  divisionId,
  ...formConnectedProps
}) => {
  const [track, loading, fetch] = useFetch(
    () => trackId && getAllClearancesWithUsesAndDuration({ trackId, clearanceBundleId }),
    [],
    {},
  );
  const cues = getCues(track);
  const initialValues = {
    dirty: formConnectedProps.savedValues?.dirty,
    cues: formConnectedProps.savedValues?.cues ?? cues,
  };

  const t = useRootTranslation('drawers.clearanceUsesAndDurations');
  const onCancel = () => {
    onClose();
  };

  const refetch = () => fetch({ trackId, clearanceBundleId });

    const handleSubmit = async ({ cues: cuesRaw }) => {
    const cues = c(
      filter(({ id, deleted }) => !(id === null && deleted)) /** Remove NEW cues that were deleted */,
      flatten,
      map((cue) =>
        cue.cueContainerIds.map((id) => ({
          /** cue.cueContainerIds is an array of the containers the cue is at, create a new cue for each container */
          ...dissoc('cueContainerIds', cue),
          cueContainerId: id,
          actualUseDuration: cue.actualUseDuration || null,
          id: /newCue/.test(cue.id)
            ? null
            : cue.id /** If regex pass, it means it is a NEW cue and shouldn't carry an id */,
        })),
      ),
    )(cuesRaw);
    await updateClearancesCues({ trackId, cues, divisionId });
    await onSubmit();
    // await onClose();
      fetch({ trackId, clearanceBundleId });
  };
  if (loading) return <Loading />;

  return (
    <Form
      drawerName={name}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      enableReinitialize
      initialValues={initialValues}
      readOnly={readOnly}
    >
      <DrawerHeader title={t('title')} hideBackButton onClose={onClose}>
        <FormButton
          alwaysEnabled
          children="global.forms.cancel"
          size="xs"
          variant="destructive"
          onClick={() => onCancel()}
        />
        <FormButton children="global.forms.save" size="xs" type="submit" variant="primary" />
      </DrawerHeader>
      <Grid container>
        <Grid item xs={12}>
          <Box mb={3} mt={1}>
            <Text bold variant="h6">
              {track.title}
            </Text>
            <Text>{concatStrings(', ')(track.artists)}</Text>
          </Box>
        </Grid>
        <Clearances
          clearances={propOr([], 'clearances', track)}
          readOnly={readOnly}
          trackId={trackId}
          qTrackId={track.qwireTracksId}
          cueContainerId={cueContainerId}
          refetch={refetch}
        />
      </Grid>
    </Form>
  );
};

ClearanceUsesAndDurationsDrawer.propTypes = {
  divisionId: PropTypes.number,
  name: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  payload: PropTypes.shape({
    trackId: PropTypes.number,
    clearanceBundleId: PropTypes.number,
    onSubmit: PropTypes.func,
    readOnly: PropTypes.bool,
  }),
  searchForUsers: PropTypes.func.isRequired,
  savedValues: PropTypes.shape({
    dirty: PropTypes.bool,
    cues: PropTypes.array,
  }),
};

export default enhancer(ClearanceUsesAndDurationsDrawer);
