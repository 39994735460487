import { createSelector } from 'reselect';
import path from 'ramda/src/path';
import prop from 'ramda/src/prop';

const getHeaderRoot = (state) => state.header;

export const getRecentSearchListSelector = createSelector(getHeaderRoot, prop('recentSearch'));

export const getSearchListSelector = createSelector(getHeaderRoot, prop('search'));

export const getBookmarkListSelector = createSelector(getHeaderRoot, prop('bookmarks'));

export const getAdvancedSearchFiltersSelector = createSelector(getHeaderRoot, prop('advancedFilters'));

export const getAdvancedSearchFilterBySelector = createSelector(
  getAdvancedSearchFiltersSelector,
  path(['filters', 'filterBy']),
);

export const getAdvancedSearchShowInactiveSelector = createSelector(
  getAdvancedSearchFiltersSelector,
  path(['filters', 'showInactive']),
);

export const getSearchItemDetailsSelector = createSelector(getHeaderRoot, prop('searchItem'));

export const getSearchResultsSelector = createSelector(getHeaderRoot, prop('searchResults'));

const getSearchCompareItem = (state, props) => {
  const itemDetails = getSearchItemDetailsSelector(state);
  const thisItem = props.item;

  return (
    itemDetails?.prodArray[0]?.id === thisItem?.prodArray[0]?.id &&
    itemDetails?.prodArray[1]?.id === thisItem?.prodArray[1]?.id &&
    itemDetails?.prodArray[2]?.id === thisItem?.prodArray[2]?.id
  );
};

export const makeSearchSelectedItemSelector = () => createSelector(getSearchCompareItem, Boolean);
