import { makeStyles } from '@material-ui/core';
import { topBarHeight } from 'components/styleguide/Topbar/styles';

const useStyles = makeStyles((theme) => ({
  panelContainer: {
    height: `calc(100vh - 285px - ${topBarHeight})`,
    '& > *': {
      border: '1px solid',
      borderColor: '#DDDDDD',
    },
  },
  productionsHeader: {
    padding: theme.spacing(1, 2.5, 1, 2.25),
    border: 'none',
  },
  counterLabelHeader: {
    padding: theme.spacing(0, 2.5, 0, 2.25),
    border: 'none',
    '& > *': {
      fontSize: '1.5rem',
    },
  },
}));

export default useStyles;
