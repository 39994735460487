/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useMemo } from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { Tabs, Loading } from 'components/styleguide';
import { useRootTranslation, useFetch } from 'utils/hooks';
import compose from 'ramda/src/compose';
import find from 'ramda/src/find';
import prop from 'ramda/src/prop';
import map from 'ramda/src/map';
import propEq from 'ramda/src/propEq';
import PropTypes from 'prop-types';
import { concatStrings } from 'utils/index';
import { getCountriesCall, getTerritoriesListCall } from 'redux-core/territories/services';
import { getProductionCurrenciesCall } from 'redux-core/productions/services';
import { getRightsOwnersByIdCall, updateRightsOwners, createRightsOwners } from 'redux-core/rights-owners/services';
import { getLicensorContacts } from 'redux-core/contacts/services';

import { SecuredForm } from 'components/common/Secured';
import { COMMON_ROLES } from 'redux-core/permissions/constants';
import { useDispatch } from 'react-redux';
import { getSmartFlags } from 'redux-core/overview/actions';
import { getPayload, getMasterValidationSchema } from '../utils';
import FormHeader from '../common/FormHeader';
import enhancer from './enhancer';
import SubLabelExpandable from '../common/SubLabelExpandable';
import BasicInfoForm from '../common/BasicInfoForm';

const getMasterValue = compose(prop('id'), find(propEq('isMaster', true)));

const MasterOtherLicensorDrawer = ({
  name,
  showSnackbar,
  showGenericError,
  onClose,
  isEditing,
  licensorId,
  productionId,
  divisionId,
  clearancesFetch,
  type,
  trackId,
  originalType,
  isVerifiedTrack,
  licensorsExist,
  readOnly,
}) => {
  const [territories] = useFetch(() => divisionId && getTerritoriesListCall({ divisionId }), [divisionId]);
  const [countries] = useFetch(getCountriesCall);
  const [currencies] = useFetch(() => getProductionCurrenciesCall(productionId), [productionId]);
  const currenciesOptions = map(
    (currency) => ({
      ...currency,
      name: concatStrings(' ')(currency.code, currency.symbol),
    }),
    currencies,
  );

  const dispatch = useDispatch();

  const [contacts] = useFetch(
    () =>
      licensorId &&
      getLicensorContacts({
        qclearRightsOwnerId: licensorId,
        divisionId,
      }),
    [licensorId],
  );

  const t = useRootTranslation(`drawers.licensorsAddEdit.${type}`);
  const [currentTab, setCurrentTab] = useState(0);
  const tabList = [t('basicInfo'), t('subLabel')];
  const masterCurrencyId = useMemo(() => getMasterValue(currencies), [currencies]);

  const [licensor, loading, refetchLicensor] = useFetch(
    () => licensorId && getRightsOwnersByIdCall({ id: licensorId }),
    [licensorId],
    {},
  );
  const handleSubmit = async (values) => {
    try {
      const payload = getPayload({
        values,
        trackId,
        isEditing,
        type,
        originalType,
        divisionId,
        licensorsExist,
      });

      if (isEditing) {
        await updateRightsOwners(payload);
      } else {
        await createRightsOwners(payload);
        await dispatch(getSmartFlags());
      }
      showSnackbar({ message: t('success') });
      await clearancesFetch();
      onClose();
    } catch (error) {
      showGenericError();
    }
  };

  const sharedInfo = {
    clearancesFetch,
    currencies: currenciesOptions,
    countries,
    territories,
    onClose,
    divisionId,
    type,
    rootKey: 'licensor',
    contactKey: 'licensor.contact',
    licensorContacts: contacts,
    licensorContact: {
      id: licensor?.contact?.id,
      name: `${licensor?.contact?.firstName} ${licensor?.contact?.lastName}`,
    },
    isVerifiedTrack,
  };
  const initialValues = {
    licensor: {
      ...licensor,
      currencyId: licensorId ? licensor.currencyId : masterCurrencyId,
      qTrack: {
        name: licensor.name,
        qwireTracksRightsOwnerId: licensor.qwireTracksRightsOwnerId,
      },
    },
  };

  const validationSchema = useMemo(
    () =>
      getMasterValidationSchema({
        initialRightsOwnerId: licensor.qwireTracksRightsOwnerId,
        licensorId,
        trackId,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [licensor.qwireTracksRightsOwnerId],
  );

  if (loading) return <Loading />;
  return (
    <Grid container>
      <Box width="100%" mb={10}>
        <SecuredForm
          drawerName={name}
          onSubmit={handleSubmit}
          enableReinitialize
          initialValues={initialValues}
          objectId={licensor.objectId}
          permissions={COMMON_ROLES.EDITOR}
          validationSchema={validationSchema()}
          // Override default Form prop
          isInitialValid={undefined}
        >
          <FormHeader
            onClose={onClose}
            isEditing={isEditing}
            t={t}
            showSnackbar={showSnackbar}
            trackId={trackId}
            clearancesFetch={clearancesFetch}
            createRightsOwners={createRightsOwners}
            showGenericError={showSnackbar}
            type={type}
            divisionId={divisionId}
          />
          <Tabs currentTab={currentTab} onTabChange={setCurrentTab} tabList={tabList}>
            <Box mt={4}>
              {currentTab === 0 && (
                <BasicInfoForm
                  {...sharedInfo}
                  refetchLicensor={refetchLicensor}
                  isEditingLicensor={isEditing}
                  readOnly={readOnly}
                />
              )}
              {currentTab === 1 && <SubLabelExpandable {...sharedInfo} />}
            </Box>
          </Tabs>
        </SecuredForm>
      </Box>
    </Grid>
  );
};

MasterOtherLicensorDrawer.propTypes = {
  isEditing: PropTypes.bool,
  name: PropTypes.string,
  showGenericError: PropTypes.func,
  showSnackbar: PropTypes.func,
  onClose: PropTypes.func,
  clearancesFetch: PropTypes.func,
  licensorId: PropTypes.number,
  trackId: PropTypes.number,
  divisionId: PropTypes.number,
  originalType: PropTypes.string,
  productionId: PropTypes.number.isRequired,
  type: PropTypes.string,
  isVerifiedTrack: PropTypes.bool,
  licensorsExist: PropTypes.bool,
  readOnly: PropTypes.bool,
};
export default enhancer(MasterOtherLicensorDrawer);
