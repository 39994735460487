import { Box, Typography } from '@mui/material';

export type TCueSheetStatusProps = {
  color: string;
  label: string;
  testId?: string;
};

export function CueSheetStatus({ color, label, testId }: TCueSheetStatusProps) {
  return (
    <Box display="flex" flexDirection="row" alignItems="center" gap={1}>
      <Box
        sx={{
          width: '16px',
          height: '16px',
          borderRadius: '50%',
          backgroundColor: color,
        }}
      />
      <Typography variant="body2" data-testid={testId}>{label}</Typography>
    </Box>
  );
}
