/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-cycle */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import Form from 'components/common/Form';
import * as Yup from 'yup';
import { lowerThan } from 'utils/forms';
import { blankTerritoryRow } from 'redux-core/rights/actions';
import { v4 as uuidv4 } from 'uuid';
import assoc from 'ramda/src/assoc';
import pick from 'ramda/src/pick';
import { getRightPresetInitialValues } from '../utils';
import enhancer from './enhancer';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('global.forms.validations.required'),
  territories: Yup.array()
    .of(
      Yup.object().shape({
        id: Yup.number().required('global.forms.validations.required'),
        term: Yup.number().required('global.forms.validations.required'),
      }),
    )
    .min(1, lowerThan(1)),
});

const RightPresetForm = ({
  name,
  right,
  onSubmit,
  children,
  preset,
  alwaysRenderValue,
  rightsPresetName,
  ...props
}) => {
  const handleSubmit = (values) => {
    const validRequestValues = pick(
      ['id', 'name', 'descriptionOnLetters', 'default', 'productionObjectId', 'territories'],
      values,
    );
    onSubmit(validRequestValues);
  };

  const initialValues = useMemo(() => {
    const values = getRightPresetInitialValues({
      right,
      alwaysRenderValue,
      rightsPresetName,
    });
    /**
     * using uuidv4() will prevent duplicated Key issues while trying to add a new territory
     * when a previous "Worldwide" territory exist (since both of them will share the same id = 1)
     *
     * [blankTerritoryRow] always begin with at least an empty territory
     */
    const territories = (values.territories.length ? values.territories : [blankTerritoryRow]).map(
      assoc('key', uuidv4()),
    );
    return {
      ...values,
      territories,
      ...preset,
    };
  }, [right, preset, alwaysRenderValue, rightsPresetName]);

  return (
    <Box width={1}>
      <Form
        drawerName={name}
        initialValues={initialValues}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        {...props}
      >
        {children}
      </Form>
    </Box>
  );
};

RightPresetForm.propTypes = {
  name: PropTypes.string,
  right: PropTypes.shape({}),
  onSubmit: PropTypes.func.isRequired,
  onConfirm: PropTypes.func,
  preset: PropTypes.shape({}).isRequired,
  children: PropTypes.any.isRequired,
  alwaysRenderValue: PropTypes.bool,
  rightsPresetName: PropTypes.string,
};

RightPresetForm.defaultProps = {
  right: {},
};

export default enhancer(RightPresetForm);
