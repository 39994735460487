/* eslint-disable react/no-unstable-nested-components */
import React from 'react';
import ChipInput from 'material-ui-chip-input';
import Chip from '@material-ui/core/Chip';
import { remove } from 'ramda';
import { FormInputPropTypes } from 'components/styleguide/fields/types';
import withFormControl from 'components/styleguide/fields/withFormControl';
import useStyles from './styles';

const InputChip = ({ disabled, name, onBlur, onChange, onFocus, placeholder, value }) => {
  const values = value || [];
  const classes = useStyles();
  const onAdd = (v) => onChange([...values, v]);
  const onDelete = (_, i) => onChange(remove(i, 1, values));

  const handlePaste = (e) => {
    const pastedData = e.clipboardData.getData('text/plain') || '';
    e.preventDefault();
    const newValues = pastedData.replace(/,/g, ';').split(';');
    onChange([...new Set([...values, ...newValues])]);
  };

  return (
    <ChipInput
      blurBehavior="ignore"
      chipRenderer={({ handleClick, handleDelete, chip }) => (
        <Chip
          key={chip}
          className={classes.chip}
          color="primary"
          label={chip}
          onClick={handleClick}
          onDelete={!disabled ? handleDelete : undefined}
          size="small"
        />
      )}
      classes={classes}
      disabled={disabled}
      InputProps={{ id: name, name }}
      newChipKeyCodes={[9, 13, 188, 32]}
      onAdd={onAdd}
      onBlur={onBlur}
      onDelete={onDelete}
      onFocus={onFocus}
      onPaste={handlePaste}
      placeholder={placeholder}
      value={values}
      variant="outlined"
    />
  );
};

InputChip.propTypes = {
  ...FormInputPropTypes,
};

export default withFormControl(InputChip);
