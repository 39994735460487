/* eslint-disable react/no-children-prop */
import React from 'react';
import PropTypes from 'prop-types';
import { useProductionCurrency } from 'utils/hooks';
import { Text } from 'components/styleguide';

const CueInputFormat = ({ value }) => {
  const convert = useProductionCurrency();
  return <Text noWrap variant="label" align="right" children={convert(value)} />;
};

CueInputFormat.propTypes = {
  value: PropTypes.number.isRequired,
};

export default CueInputFormat;
