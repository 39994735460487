/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const Notes = (props) => (
  <SvgIcon {...props} viewBox="0 0 20 25">
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M2.5 0A2.507 2.507 0 0 0 0 2.5v20C0 23.875 1.125 25 2.5 25h15c1.375 0 2.5-1.125 2.5-2.5v-15L12.5 0h-10z"
      clipRule="evenodd"
    />
    <path
      fillRule="evenodd"
      d="M4 3c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V9l-6-6H4zm7 7V4.5l5.5 5.5H11z"
      clipRule="evenodd"
    />
  </SvgIcon>
);

export default Notes;
