/* eslint-disable import/no-cycle */
import React from 'react';

import { formatCurrency } from 'utils/format';
import { Text } from 'components/styleguide';

import Bar from '../Bar';

function TextBar({
  barColor = 'cerulean',
  barSize = 'medium',
  textColor = 'white',
  textVariant = 'caption',
  currency,
  barTotal,
  barValue,
  textValue,
  disabled,
  placeholder,
}) {
  return (
    <div>
      <Bar color={barColor} size={barSize} total={barTotal} value={barValue} disabled={disabled}>
        <Text
          bold
          // eslint-disable-next-line react/no-children-prop
          children={formatCurrency(placeholder || textValue, currency)}
          color={textColor}
          variant={textVariant}
        />
      </Bar>
    </div>
  );
}

TextBar.displayName = 'TextBar';

export default TextBar;
