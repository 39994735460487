import { compose } from 'redux';
import { connect } from 'react-redux';
import { updateCue } from 'redux-core/cueSheet/actions';
import withBaseDrawer from '../withBaseDrawer';

const mapDispatchToProps = {
  updateCue,
};

export default compose(connect(null, mapDispatchToProps), withBaseDrawer);
