/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable import/no-cycle */
import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Button, zIndexLevels } from 'components/styleguide';
import { getRightsCall } from 'redux-core/rights/services';
import { useFetch, useDrawer, useRootTranslation } from 'utils/hooks';
import { FormTypeAhead } from 'components/common/Form/Fields';
import Box from '@material-ui/core/Box';
import { useFormikContext } from 'formik';
import { useLocation } from 'react-router';
import equals from 'ramda/src/equals';
import { RIGHTS_TERRITORIES_TERM_ENUM } from 'components/Settings/Tabs/Rights/constants';
// eslint-disable-next-line import/no-named-as-default
import DRAWERS from '../Drawers/constants';
import TerritoryDates from './TerritoryDates';
import enhancer from './enhancer';

type Territory = {
  id?: string;
  name?: string;
  start?: string;
  end?: string;
};

type Right = {
  id?: string;
  name?: string;
  territory?: Territory;
};

type InitialRightProps = {
  name?: string;
  rights?: Right[];
};

type RightsPresetPickerProps = {
  divisionId?: string;
  required?: boolean;
  setRightPreset?: (value) => void;
  getRightsCustomCall?: (params) => Promise<any>;
  onAdditionalRightsPresetDrawerOpen?: (values) => void;
  datesRequired?: boolean;
  formId?: string;
  readOnly?: boolean;
  saveForm?: (values) => void;
  rightsLabel?: string;
  showDefaultPreset?: boolean;
  testId?: string;
  saveInDrawerName?: string;
  hintType?: string;
  initialRights?: InitialRightProps;
  detailsButtonDisabled?: boolean;
};

type FormikContextForm = {
  productionObjectId?: string;
  path?: string;
  requiredRights?: Right;
};

const getDirty = (updatedRights, initialRights: InitialRightProps = {}) => {
  /** Since RightsPreset and Rights obtained from the BE differ in their structure,
   * normalize both and compare to check if dirty */
  const first = {
    name: updatedRights.name,
    rights: updatedRights.rights.map(({ name, territoryId, start, end }) => ({
      name,
      territoryId,
      start,
      end,
    })),
  };
  const second = {
    name: initialRights.name,
    rights: initialRights.rights?.map(({ territory: { id: territoryId, name, start, end } = {} }) => ({
      territoryId,
      name,
      start,
      end,
    })),
  };

  return !equals(first, second);
};

export const formatPresetToRights = (value) => ({
  name: value.name,
  type: value.type,
  descriptionOnLetters: value.descriptionOnLetters,
  rights: value.territories.map(({ id, term, name, otherDetails }) => ({
    name,
    term,
    otherDetails: term === RIGHTS_TERRITORIES_TERM_ENUM.OTHER ? otherDetails : null,
    territoryId: id,
  })),
});

export const processPresets = (values) => {
  const preset = {
    name: values.name,
    type: values.type,
    descriptionOnLetters: values.descriptionOnLetters,
    territories: values.territories,
  };
  return preset;
};

const RightsPresetPicker = (props: RightsPresetPickerProps) => {
  const {
    divisionId,
    required,
    setRightPreset,
    getRightsCustomCall,
    onAdditionalRightsPresetDrawerOpen,
    datesRequired,
    formId,
    readOnly,
    saveForm,
    rightsLabel,
    showDefaultPreset = true,
    testId,
    saveInDrawerName,
    hintType,
    initialRights,
    detailsButtonDisabled,
  } = props;
  const tGlobal = useRootTranslation('global');
  const t = useRootTranslation('drawers.clearance.rightsPresetPicker');
  const openDrawer = useDrawer();
  const { state = {} } = useLocation();
  const { setFieldValue, values } = useFormikContext<FormikContextForm>();
  const { path, requiredRights } = values;

  const rights: Right = requiredRights || {};
  const fieldName = 'requiredRights';

  const objectId = state.objectId || values.productionObjectId;

  const presetToRights = (value) => {
    const updatedRights = formatPresetToRights(value);
    /** This check is in case you selected the same Rights Preset that are stored in the DB */
    const dirty = getDirty(updatedRights, initialRights);
    if (dirty) {
      setFieldValue(fieldName, { ...updatedRights, dirty });
    }
  };

  const [rightsPresets, loading] = useFetch(() => {
    if (getRightsCustomCall) {
      return getRightsCustomCall({ divisionId, objectId });
    }
    return divisionId && getRightsCall({ divisionId, productionObjectId: objectId });
  }, [divisionId, objectId]);

  const openRightPresetDrawer = async () => {
    if (onAdditionalRightsPresetDrawerOpen) {
      onAdditionalRightsPresetDrawerOpen(values);
    }
    /** Remove Right Preset from the Store before mounting drawer */
    await setRightPreset({});
    /** Save form data in the store */
    await saveForm({ formId, values });

    openDrawer(DRAWERS.RIGHT_PRESET, {
      path,
      showDefaultPreset,
      objectId,
      rights,
      fieldName,
      divisionId,
      readOnly,
      saveInDrawerName,
      hintType,
      onSubmit: (values) => setFieldValue(fieldName, values),
    });
  };

  return (
    <>
      <Grid container alignItems="flex-end" spacing={1} data-testid={testId}>
        <Grid item xs={8}>
          <FormTypeAhead
            isClearable={false}
            required={required}
            name="rightsPresetId"
            label={rightsLabel || t('label')}
            value={rights.name}
            alwaysRenderValue
            placeholder={t('placeholder')}
            options={rightsPresets}
            disabled={loading}
            loading={loading}
            readOnly={readOnly}
            maxVisibleItems={5}
            displayValue={() => rights.name}
            onSelect={presetToRights}
            testId="requiredRights"
          />
        </Grid>
        {!detailsButtonDisabled && (
          <Grid container style={{ zIndex: zIndexLevels.two, padding: '0.25rem' }} xs={3}>
            <Button variant="secondary" onClick={openRightPresetDrawer} testId="rightsDetails">
              {tGlobal('forms.details')}
            </Button>
          </Grid>
        )}
      </Grid>
      <Box mt={1} width={1}>
        <TerritoryDates required={datesRequired} readOnly={readOnly} fieldName={fieldName} />
      </Box>
    </>
  );
};

export default enhancer(RightsPresetPicker);
