/* eslint-disable import/no-cycle */
import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles((theme) => ({
  iconButton: { color: theme.palette.primary.main },
  containerHighlight: {
    boxSizing: 'border-box',
    paddingRight: theme.spacing(),
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
    border: '2px solid transparent',
    '&.containerSelected': {
      // no need to use the $ for variables since we are using the exact string as classname
      borderColor: theme.palette.primary.main,
    },
  },
  containerEditing: {
    backgroundColor: theme.palette.primary.main,
  },
}));

export default styles;
