import { withStyles } from '@material-ui/core';

const styles = (theme) => ({
  actionWrapper: {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 5,
    position: 'absolute',
    height: 'calc(100% - 6px)',
    width: 'calc(100% + 5px)',
    top: 3,
    right: 0,
    opacity: '0',
    '&:hover': {
      opacity: '1',
    },
    zIndex: theme.zIndex.two,
  },
  actionMenu: {
    paddingRight: theme.spacing(),
    paddingTop: '3px',
    height: '100%',
    backgroundColor: 'white',
    borderRadius: '0 5px 5px 0',
    width: '100px',
  },
  container: {
    position: 'relative',
    minHeight: theme.spacing(6),
  },
});

export default withStyles(styles);
