import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  button: ({ size }) => ({
    '& > *': {
      height: size,
      width: size,
    },
    '& > svg': {
      color: ({ color }) => color || theme.palette.text.primary,
    },
  }),
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export default useStyles;
