import ROUTES from 'components/Routes/routes';
import { useSelector } from 'react-redux';
import { getIsQwireEmployeeSelector } from 'redux-core/auth/selectors';

import { CLEAR_PERMISSIONS, COMMON_PERMISSIONS } from 'redux-core/permissions/constants';
import { getPermissionSelector } from 'redux-core/permissions/selectors';
import { hasPermissions } from 'utils';

import SearchDetailsSplitButton from './SearchDetailsSplitButton';

export const FeaturesAndMarketingDetailsSplitButton = ({
  closeAllDialogs,
  goToLink,
  keepSetupInfo,
  openModal,
  normalizedProdType,
  selected,
}) => {
  const { productionObjectId } = selected;
  const userPermissionsOnObject = useSelector(getPermissionSelector(productionObjectId));
  const isQwireEmployee = useSelector(getIsQwireEmployeeSelector);

  const splitButtonMainLabel = 'options.viewProduction';

  const splitButtonMainAction = async () => {
    const hasClearPermission = hasPermissions(
      { clearPermissions: CLEAR_PERMISSIONS.CAN_ACCESS_CLEAR },
      userPermissionsOnObject,
      true,
      isQwireEmployee,
    );

    const productionView = hasClearPermission ? ROUTES.PRODUCTION.OVERVIEW : ROUTES.PRODUCTION.CUE_SHEET;
    await goToLink(productionView, {
      id: selected.productionId,
      type: normalizedProdType,
      divisionId: selected.divisionId,
      projectId: selected.prodArray[0].id,
    });
    return closeAllDialogs();
  };
  return (
    <SearchDetailsSplitButton
      tenantId={selected.tenantId}
      productionId={selected?.prodArray[1]?.id}
      productionActive={selected?.prodArray[1]?.active}
      productionType={selected.productionType}
      projectId={selected.prodArray[0].id}
      objectId={selected.productionObjectId}
      divisionId={selected.divisionId}
      keepSetupInfo={keepSetupInfo}
      openModal={openModal}
      mainAction={splitButtonMainAction}
      mainLabel={splitButtonMainLabel}
      mainPermissions={COMMON_PERMISSIONS.READ}
      size="small"
    />
  );
};
