import { Link as RouterLink, useHistory } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import React from 'react';
import SnackbarContent from '@material-ui/core/SnackbarContent';

import PropTypes from 'prop-types';

const DefaultSnackbarContent = ({
  classes,
  snackbarMessage,
  snackbarLinkText,
  snackbarLinkAction,
  snackbarLinkRoute,
}) => {
  const history = useHistory();

  const onClickNavigation = (event) => {
    history.push(snackbarLinkAction);
    event.preventDefault();
  };

  const linkRoute = snackbarLinkAction ? undefined : snackbarLinkRoute;

  return (
    <SnackbarContent
      classes={{
        message: classes.message,
      }}
      message={
        <div style={{ display: 'flex' }}>
          <div>{snackbarMessage}</div>
          {snackbarLinkText ? (
            <div className={classes.snackbarLink}>
              <Link component={RouterLink} to={linkRoute} onClick={onClickNavigation}>
                {snackbarLinkText}
              </Link>
            </div>
          ) : null}
        </div>
      }
    />
  );
};

DefaultSnackbarContent.propTypes = {
  classes: PropTypes.object.isRequired,
  snackbarMessage: PropTypes.string.isRequired,
  snackbarLinkAction: PropTypes.object,
  snackbarLinkText: PropTypes.string,
  snackbarLinkRoute: PropTypes.string,
};

export default DefaultSnackbarContent;
